import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ReportEditTableIcon = (props: PosIconProps) => {
  return (
    // It's import for the viewbox fo this SVG to stay 20 else it won't resize properly
    <SvgWrapper
      width="17"
      height="16"
      aria-label="ReportEditTableIcon"
      {...props}
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_11178_5212)">
        <rect
          x="3.53125"
          width="13"
          height="2"
          rx="1"
          fill={props.fill || '#3F1D75'}
        />
        <rect
          x="0.53125"
          y="3"
          width="2"
          height="12"
          rx="1"
          fill={props.fill || '#3F1D75'}
        />
        <path
          d="M10.0741 6.55291L11.1727 5.23457C11.2927 5.09064 11.5137 5.09064 11.6337 5.23457L12.7323 6.55291C12.8951 6.74831 12.7562 7.04496 12.5018 7.04496H10.3046C10.0502 7.04496 9.91128 6.74831 10.0741 6.55291Z"
          fill={props.fill || '#3F1D75'}
        />
        <path
          d="M7.08318 12.1984L5.76484 11.0997C5.62091 10.9798 5.62091 10.7587 5.76484 10.6388L7.08318 9.54018C7.27858 9.37735 7.57524 9.5163 7.57524 9.77065L7.57524 11.9679C7.57524 12.2222 7.27858 12.3612 7.08318 12.1984Z"
          fill={props.fill || '#3F1D75'}
        />
        <path
          d="M7.57422 10.8683H9.40031C10.5049 10.8683 11.4003 9.97289 11.4003 8.86832V7.04224"
          stroke={props.fill || '#3F1D75'}
          strokeWidth="0.695652"
        />
      </g>
      <defs>
        <clipPath id="clip0_11178_5212">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.53125)"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
