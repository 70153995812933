import { useFormContext } from 'react-hook-form';
import { LinkMarketplacePaymentToSaleInput } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog/LinkMarketplacePaymentToSaleDialog';
import { useGetRemainingBalance } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog/useGetRemainingBalance';
import { SaleLineItemModal } from 'src/modals/SaleDetails/components/PaymentSection/SaleLineItemModal';
import { AddLineItemModalBodyProps as SaleLineItemModalBodyProps } from 'src/modals/SaleDetails/components/PaymentSection/SaleLineItemModal/SaleLineItemModal.types';

type WrapperProps = SaleLineItemModalBodyProps;

export const SaleLineItemModalWrapper = (props: WrapperProps) => {
  const { remainingAmount, isCredit } = useGetRemainingBalance();
  const { watch } = useFormContext<LinkMarketplacePaymentToSaleInput>();

  const marketplacePaymentLine = watch('marketplacePaymentLine');
  return (
    <SaleLineItemModal
      {...props}
      remainingBalance={(isCredit ? 1 : -1) * remainingAmount}
      costDate={marketplacePaymentLine.recvDate}
    />
  );
};
