import { clsx } from 'clsx';
import { ReactNode, useMemo } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { ListingNoteBadge } from 'src/components/Listings/ListingNoteBadge/index';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { shared, vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { GenericDisclosuresIcon, InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  ListingNote,
  ListingNoteType,
  ListingPriceCalculation,
  Seating,
} from 'src/WebApiController';

import * as styles from './ComparableListingDisplay.css';
import { ComparableListingDisplayTooltip } from './ComparableListingDisplayTooltip';

export type ComparableListingDisplayProps = {
  /**
   * Whether this is the display that represents the current listing.
   */
  listingId: number;
  /**
   * Whether this is the selected listing.
   *
   * This will show an active background state.
   */
  isCurrentListing?: boolean;
  /**
   * Whether this is a listing owned by the current account.
   *
   * This will show a "Your Listing" badge.
   */
  isYourListing?: boolean;
  isOutlier?: boolean;
  isAnchor?: boolean;
  isSelectedWithListingGroup?: boolean;
  title?: ReactNode;
  price?: ReactNode;
  validPurchaseQuantities?: number[] | null;
  listingNotes?: ListingNote[];
  seating?: Seating;
  listingPriceCalc?: ListingPriceCalculation | null;
};

export function ComparableListingDisplay({
  listingId,
  isCurrentListing = false,
  isYourListing = false,
  isOutlier = false,
  isAnchor = false,
  isSelectedWithListingGroup = false,
  title,
  price,
  validPurchaseQuantities,
  listingNotes,
  seating,
  listingPriceCalc,
}: ComparableListingDisplayProps) {
  const hasShowIdFeature = useUserHasFeature(Feature.ListingIdForCompListings);
  const hasYourListingInComps = useUserHasFeature(Feature.YourListingsInComps);
  const hasIntelligiblePricePreviewFeature = useUserHasFeature(
    Feature.IntelligiblePricePreview
  );
  const hasListingGroupIntelligiblePricePreviewFeature = useUserHasFeature(
    Feature.IntelligiblePricePreviewForListingGroup
  );

  const hasCondensedTabsFeature = useUserHasFeature(
    Feature.CondensedPreviewTabsAndSingleListing
  );
  const hasCompListingDisplayUpdateFeature = useUserHasFeature(
    Feature.CompListingDisplayUpdate
  );

  const { isDarkMode } = useSiteTheme();

  const shouldGrayOut =
    isOutlier || (!isCurrentListing && isYourListing && hasYourListingInComps);

  const displayQuantity = useMemo(() => {
    if (!validPurchaseQuantities) {
      return 0;
    }

    return Math.max(...validPurchaseQuantities);
  }, [validPurchaseQuantities]);

  const quantitySplits = useMemo(() => {
    if (!validPurchaseQuantities || validPurchaseQuantities.length === 0) {
      return '0';
    }

    const splits = [...validPurchaseQuantities]
      .sort((a, b) => b - a)
      .filter((q) => q < 10);

    const hasSplitsLargerThanTen = validPurchaseQuantities.some((q) => q > 10);

    return [...(hasSplitsLargerThanTen ? ['10+'] : []), ...splits].join(', ');
  }, [validPurchaseQuantities]);

  const listingNoteDefects = useMemo(() => {
    return listingNotes
      ?.filter(
        (note) =>
          note.type === ListingNoteType.Defect ||
          note.type === ListingNoteType.RestrictionOnUse
      )
      .map((note) => note.listingNoteText);
  }, [listingNotes]);

  const listingNotePerks = useMemo(() => {
    return listingNotes
      ?.filter(
        (note) =>
          note.type === ListingNoteType.Perk ||
          note.type === ListingNoteType.Information
      )
      .map((note) => note.listingNoteText);
  }, [listingNotes]);

  return (
    <div
      className={clsx(styles.compListingContainer, {
        [styles.compListingContainerUpdated]:
          hasCompListingDisplayUpdateFeature,
        [styles.highlighted]: isCurrentListing,
        [styles.highlightedInternalComp]: !isCurrentListing && isYourListing,
        [styles.highlightedOutlier]: isOutlier && !isYourListing,
        [styles.highlightedOutlierUpdated]:
          isOutlier && !isYourListing && hasCompListingDisplayUpdateFeature,
      })}
    >
      <Stack direction="column" className={styles.left}>
        <Stack>
          <span
            className={clsx(shared.typography.subtitle1, {
              [styles.grayedTile]: shouldGrayOut,
            })}
          >
            {title ?? (
              <SeatingInfo
                section={seating?.section ?? ''}
                row={seating?.row ?? ''}
                showSectionPrefix
                fontSize={
                  hasCondensedTabsFeature
                    ? vars.typography.fontSize.sm
                    : vars.typography.fontSize.base
                }
                // purposely leaving these out
                seatFr={''}
                seatTo={''}
              />
            )}
          </span>
        </Stack>
        <Stack direction="row" alignItems="center" gap="l">
          <Stack
            className={clsx(styles.splitInfo, {
              [styles.grayedTile]: shouldGrayOut,
              [styles.smallSplitInfoText]: hasCondensedTabsFeature,
            })}
            direction="row"
            alignItems="center"
            gap="s"
          >
            <span>
              <Content id={ContentId.QuantityAbbreviated} /> {displayQuantity}
            </span>

            <span>•</span>

            <span>
              <Content id={ContentId.Split} /> {quantitySplits}
            </span>

            {listingNoteDefects?.length || listingNotePerks?.length ? (
              <span>•</span>
            ) : null}

            {listingNoteDefects?.length ? (
              <ListingNoteBadge
                icon={<InfoOutlineIcon />}
                listingNotes={listingNoteDefects}
                type={ListingNoteType.Defect}
              />
            ) : null}

            {listingNotePerks?.length ? (
              <ListingNoteBadge
                icon={<GenericDisclosuresIcon />}
                listingNotes={listingNotePerks}
                type={ListingNoteType.Perk}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        className={clsx(styles.middle, {
          [styles.condensedMiddle]: hasCondensedTabsFeature,
          [styles.middleUpdated]: hasCompListingDisplayUpdateFeature,
        })}
        direction="column"
        alignItems="center"
        gap="s"
      >
        {isYourListing ? (
          <span className={styles.yourListingBadge}>
            {hasIntelligiblePricePreviewFeature &&
            (!isSelectedWithListingGroup ||
              hasListingGroupIntelligiblePricePreviewFeature) ? (
              <ComparableListingDisplayTooltip
                seating={seating}
                price={price}
                listingPriceCalc={listingPriceCalc}
              />
            ) : (
              <Content id={ContentId.YourListing} />
            )}
          </span>
        ) : isOutlier ? (
          <span
            className={clsx(styles.outlierBadge, {
              [styles.outlierBadgeUpdated]: hasCompListingDisplayUpdateFeature,
            })}
          >
            <Content id={ContentId.Outlier} />
          </span>
        ) : isAnchor ? (
          <span className={styles.referenceListingBadge}>
            <Content id={ContentId.PriceAgainst} />
          </span>
        ) : null}
      </Stack>
      <Stack
        className={clsx(styles.right, {
          [styles.rightUpdated]: hasCompListingDisplayUpdateFeature,
        })}
        alignItems="center"
        justifyContent="center"
        gap="s"
        direction="column"
      >
        <span
          className={clsx(styles.price, {
            [styles.highlightedPrice]: isCurrentListing,
            [styles.darkHighlightedPrice]: isDarkMode,
            [styles.highlightedPriceOutlier]: shouldGrayOut,
            [styles.condensedPrice]: hasCondensedTabsFeature,
          })}
        >
          {price}
        </span>
        {hasShowIdFeature && (
          <div
            className={clsx(styles.listingIdBadge, {
              [styles.grayedTile]: shouldGrayOut,
              [styles.condensedListingIdBadge]: hasCondensedTabsFeature,
            })}
          >
            {listingId}
          </div>
        )}
      </Stack>
    </div>
  );
}
