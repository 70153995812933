import { RenderTreeNodePayload, Tree, TreeNodeData } from '@mantine/core';
import clsx from 'clsx';
import React, { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { vars } from 'src/core/themes';
import { mergeProps } from 'src/core/utils';
import { ChevronDownIcon, ChevronRightIcon, MinusIcon } from 'src/svgs/Viagogo';

import { Stack } from '../Stack';
import * as styles from './DocumentTree.css';

export const DocumentTree = forwardRef<
  typeof Tree,
  ComponentPropsWithoutRef<typeof Tree> & {
    onNodeClick?: (e: React.MouseEvent, value: TreeNodeData) => void;
  }
>(function Trigger({ onNodeClick, ...props }, ref) {
  const renderNode = ({
    node,
    expanded,
    hasChildren,
    elementProps,
  }: RenderTreeNodePayload): ReactNode => {
    return (
      <Stack
        alignItems="center"
        gap="m"
        {...mergeProps(
          { className: clsx(styles.node, elementProps.className) },
          elementProps
        )}
        onClick={(e) => {
          elementProps.onClick(e);
          onNodeClick?.(e, node);
        }}
      >
        {!hasChildren ? (
          <span>
            <MinusIcon fill={vars.color.textPrimary} size={vars.iconSize.xs} />
          </span>
        ) : !expanded ? (
          <ChevronRightIcon
            fill={vars.color.textPrimary}
            size={vars.iconSize.xs}
          />
        ) : (
          <ChevronDownIcon
            fill={vars.color.textPrimary}
            size={vars.iconSize.xs}
          />
        )}
        {node.label}
      </Stack>
    );
  };

  return (
    <Tree
      {...mergeProps({ className: clsx(styles.root, props.className) }, props)}
      renderNode={renderNode}
    />
  );
});
