import {
  functionalUpdate,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useUserGroups } from 'src/hooks/useUserGroups';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { UserGroup } from 'src/WebApiController';
import { TEAMS_TABLE_COLUMNS_CONFIG } from './TeamsTableColumnsConfig';

export const PAGE_SIZE = 10;

export const TeamsTable = () => {
  const teamsText = useContent(ContentId.Teams);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);
  const { userGroups } = useUserGroups();

  const data = useMemo(() => {
    return Object.values(userGroups ?? {});
  }, [userGroups]);

  const options: Partial<TableOptions<UserGroup | null>> = {
    data,
    columns: TEAMS_TABLE_COLUMNS_CONFIG,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: (sortingUpdaterFn) => {
      const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
      setSorting(newSortVal);
    },
  };

  return (
    <DialogProvider>
      {data.length > 0 ? (
        <Table options={options} withOuterPadding={true} roundBorders={true} />
      ) : (
        <NoData>
          <FormatContent
            id={FormatContentId.NoDataAvailable}
            params={teamsText}
          />
        </NoData>
      )}
    </DialogProvider>
  );
};
