import { useCallback, useRef, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkChangeEventDialog } from 'src/dialogs/BulkEdits/BulkChangeEventDialog';
import { TicketGroupIntentionDialog } from 'src/dialogs/TicketGroupIntentionDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { EmptyListingQuery } from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  TicketGroupEditReason,
} from 'src/WebApiController';

import { LaunchChangeEvent } from './LaunchChangeEvent';

export const useBulkChangeEventAction = (
  event: Event,
  affectedVisibleListingIds: number[],

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = `inventory-changeEvent-event-${event.viagVirtualId}`;
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();
  const [changeEventNote, setChangeEventNote] = useState<string | undefined>();
  const deallocateSalesRef = useRef(true);

  const { showErrorDialog } = useErrorBoundaryContext();

  const ticketGroupIntentionDialog = useBasicDialog();

  const {
    openDialog: openBulkChangeEventDialog,
    closeDialog: closeBulkChangeEventDialog,
  } = useDialog(DialogId.BulkChangeEvent, BulkChangeEventDialog);

  const onSubmitChangeEvent = useCallback(
    async (
      newSelectedEvent: Event | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);

      if (onPreviewReceived) {
        const activeQuery = {
          ...EmptyListingQuery,
          eventOrMappingIds: affectedVisibleListingIds.length
            ? null
            : [event.viagVirtualId],
          entityIds: affectedVisibleListingIds,
        };
        await tryInvokeApi(
          async () => {
            const client = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const preview = await client.bulkChangeEventPreview(activeQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { activeQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (newSelectedEvent) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkChangeEvent(
              preview!.preview,
              newSelectedEvent.posIds[0],
              newSelectedEvent.viagId,
              newSelectedEvent.mappingId,
              deallocateSalesRef.current,
              changeEventNote ?? '',
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                refreshCatalog();
                setListItemExpansion(true, [event.viagVirtualId]);
              }

              closeBulkChangeEventDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitChangeEvent', error, {
              trackErrorData: {
                newSelectedEvent,
                preview,
                changeEventNote,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      affectedVisibleListingIds,
      changeEventNote,
      closeBulkChangeEventDialog,
      event.viagVirtualId,
      refreshCatalog,
      setIsLoading,
      setListItemExpansion,
      showErrorDialog,
      updateKey,
    ]
  );

  const onChangeEventClick = useCallback(
    (changeEventNote?: string) => {
      setChangeEventNote(changeEventNote);

      openBulkChangeEventDialog({
        entityType: ActionOutboxEntityType.Listing,
        changeEventNote,
        updateKey,
        onOkay: onSubmitChangeEvent,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkChangeEventDialog,
      });
    },
    [
      closeBulkChangeEventDialog,
      onSubmitChangeEvent,
      openBulkChangeEventDialog,
      setIsLoading,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchChangeEvent
        key="ChangeEvent"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          ticketGroupIntentionDialog.launchDialog();
        }}
        disabled={isLoading}
      />
    ),
    dialog: (
      <>
        <TicketGroupIntentionDialog
          {...ticketGroupIntentionDialog.dialogProps}
          intention={{
            ticketGroupId: -1, // negative since this will be applied to ticket groups in bulk
            reason: TicketGroupEditReason.EditEvent,
            additionalInfo: '',
          }}
          onClosed={ticketGroupIntentionDialog.closeDialog}
          onSave={(i, deallocateSales) => {
            deallocateSalesRef.current = deallocateSales == true;
            onChangeEventClick(i.additionalInfo);
            ticketGroupIntentionDialog.closeDialog();
          }}
          saveButtonContentId={ContentId.Save}
        />
      </>
    ),
  };
};
