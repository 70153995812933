import { sum } from 'lodash-es';
import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { BroadcastIconInverted } from 'src/svgs';
import { ClockIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Listing, ListingGroup, ListingStatus } from 'src/WebApiController';

import * as styles from './ListingGroupStatusSummary.css';

export const ListingGroupStatusSummary = ({
  listingGroup,
}: {
  listingGroup: ListingGroup;
}) => {
  return (
    <div className={styles.groupStatusIconContainer}>
      <IconButton
        icon={
          <div className={styles.groupStatusIconStyle}>
            <BroadcastIconInverted size={vars.iconSize.m} />
            {sum(
              listingGroup.groupItems
                .flatMap((l) =>
                  l.isLtGrp ? (l as ListingGroup).groupItems : []
                )
                .filter(
                  (l) =>
                    !l.isLtGrp &&
                    ((l as Listing).status === ListingStatus.Listed ||
                      (l as Listing).status === ListingStatus.ListingPending)
                )
                .map((l) => l.availQty)
            )}
          </div>
        }
        style={{ padding: '2px 1px' }}
        titleContentId={ContentId.ListingGroupSummaryActiveAndActive}
      />

      <IconButton
        icon={
          <div className={styles.groupStatusIconStyle}>
            <ClockIcon size={vars.iconSize.m} noOutline />
            {sum(
              listingGroup.groupItems
                .flatMap((l) =>
                  l.isLtGrp ? (l as ListingGroup).groupItems : []
                )
                .filter(
                  (l) =>
                    !l.isLtGrp &&
                    !(
                      (l as Listing).status === ListingStatus.Listed ||
                      (l as Listing).status === ListingStatus.ListingPending
                    )
                )
                .map((l) => l.availQty)
            )}
          </div>
        }
        style={{ padding: '2px' }}
        titleContentId={ContentId.ListingGroupSummaryQueued}
      />
    </div>
  );
};
