import { useMemo } from 'react';
import { EventItemHeader } from 'src/components/Events/EventItemHeader';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { vars } from 'src/core/themes';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { ContentId } from 'src/utils/constants/contentId';
import { Listing } from 'src/WebApiController';

import * as styles from '../MergeListings.css';
import { getFlattenedListings } from '../utils';
import { EventMergeSuggestionsAccordionProps } from './BulkMergeListings.types';
import { isEqualIgnoringOrder } from './BulkMergeListings.utils';
import { MergeSuggestionsDisplay } from './MergeSuggestionsDisplay';

export const EventMergeSuggestionsAccordion = ({
  eventWithMergeSuggestion,
  onSuggestionsSelectedChange,
  selectedListingIds,
  disabled,
  selected,
  partialSelected,
  onSelectedChange,
}: EventMergeSuggestionsAccordionProps) => {
  const { event, mergeSuggestions } = eventWithMergeSuggestion;

  const mergeText = useContent(ContentId.Merge).toLowerCase();
  const mergesText = useContent(ContentId.Merges);

  const {
    eventsExpansion: { setListItemExpansion },
    eventsTransformed,
  } = useCatalogDataContext();
  const { venue, performer } = useGetEventFullInfo(event);

  const eventData = useMemo(
    () => eventsTransformed?.find((ev) => ev.event.viagId === event.viagId),
    [event, eventsTransformed]
  );

  const [flattenedListings, groupIdNameMap] = getFlattenedListings(
    eventData?.entities?.listings
  );

  const isSelected = (listingIds: number[]) => {
    return selectedListingIds.some((l) => isEqualIgnoringOrder(l, listingIds));
  };

  const listings = flattenedListings;

  return (
    <PosCollapsible.Root
      key={event.name}
      onOpenChange={(open) => {
        if (open) {
          setListItemExpansion(true, [event.viagVirtualId]);
        }
      }}
      style={{ marginBottom: vars.spacing['lg'] }}
    >
      <div>
        <PosCollapsible.Trigger>
          <div className={styles.eventHeaderContainer}>
            <Checkbox
              checked={!!selected}
              indeterminate={!!partialSelected}
              disabled={disabled}
              onChange={() =>
                // If partially selected, we want to select all
                onSelectedChange?.(!selected || !!partialSelected)
              }
              onClick={(e) => e.stopPropagation()}
            />
            <div className={styles.eventItemHeader}>
              <EventItemHeader
                event={event}
                performer={performer}
                venue={venue}
                metrics={
                  <span className={styles.mergeSummary}>{`${
                    mergeSuggestions.length
                  } ${
                    mergeSuggestions.length > 1 ? mergesText : mergeText
                  }`}</span>
                }
              />
            </div>
          </div>
        </PosCollapsible.Trigger>
      </div>
      <PosCollapsible.Content>
        <div className={styles.mergeSuggestionsContainer}>
          {mergeSuggestions.map((ms, i) => (
            <MergeSuggestionsDisplay
              key={`${event.name}-mergesuggestions-${i}`}
              listings={
                listings != null
                  ? (ms.listingIds
                      .map((lid) => listings?.find((l) => l.id === lid))
                      .filter((l) => l) as Listing[])
                  : undefined
              }
              listingGroupMap={groupIdNameMap}
              listingCount={ms.listingIds.length}
              selected={isSelected(ms.listingIds)}
              onSelectedChange={(selected) =>
                onSuggestionsSelectedChange(selected, ms.listingIds)
              }
            />
          ))}
        </div>
      </PosCollapsible.Content>
    </PosCollapsible.Root>
  );
};
