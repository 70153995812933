import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog/useConfirmDialog';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { SaleLineItemModal } from 'src/modals/SaleDetails/components/PaymentSection/SaleLineItemModal';
import { PaymentSectionTable } from 'src/modals/SaleDetails/components/PaymentSectionV2/PaymentSectionTable/PaymentSectionTable';
import { useShouldHideForWastedSale } from 'src/modals/SaleDetails/hooks/useShouldHideForWastedSale';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  MarketplacePaymentLineType,
  MarketplacePaymentState,
  SaleCostType,
  SaleDetails,
  SaleInput,
  SaleLineItem,
  SiteMarketplacePaymentLineInput,
} from 'src/WebApiController';

import { MarketplacePaymentLineDialog } from '../MarketplacePaymentSection/MarketplacePaymentLineDialog';
import { EMPTY_MARKETPLACE_PAYMENT_LINE } from '../MarketplacePaymentSection/MarketplacePaymentLineDialog/MarketplacePaymentLineDialog.utils';

interface PaymentSectionV2Props {
  sale?: SaleDetails | null | undefined;
  lineItems: SaleLineItem[] | null | undefined;
  onLineItemChange?: (item: SaleLineItem, isDelete?: boolean) => void;
}

export const PaymentSectionV2 = ({
  sale,
  lineItems,
  onLineItemChange,
}: PaymentSectionV2Props) => {
  const { loginContext } = useAppContext();
  const costDialog = useBasicDialog();

  const [curItem, setCurItem] = useState<SaleLineItem | undefined>();

  const { setValue, watch } = useFormContext<SaleInput>();
  const [currentMarketplacePaymentLine, setCurrentMarketplacePaymentLine] =
    useState<SiteMarketplacePaymentLineInput | undefined>();

  const paymentReceived = watch('paymentReceived')?.value ?? false;
  const marketplacePaymentLines = watch('marketplacePaymentLines')?.value;

  const {
    openConfirmDialog: launchRemovePaymentReceivedDialog,
    closeConfirmDialog: closeRemovePaymentReceivedDialog,
  } = useConfirmDialog();
  const addSalePaymentDialog = useBasicDialog();

  const hideForWastedSale = useShouldHideForWastedSale(sale);

  const onAddNewCostCancel = useCallback(() => {
    costDialog.closeDialog();
    setCurItem(undefined);
  }, [costDialog]);

  const onAddNewCostSave = useCallback(
    (item: SaleLineItem) => {
      onLineItemChange?.(item);
      costDialog.closeDialog();
    },
    [costDialog, onLineItemChange]
  );

  const onSetSaleLineItem = useCallback(
    (item?: SaleLineItem) => {
      setCurItem(item);
      costDialog.launchDialog();
    },
    [costDialog]
  );

  const onDeleteSaleLineItem = useCallback(
    (saleLineItem: SaleLineItem) => {
      onLineItemChange?.(saleLineItem, true);
    },
    [onLineItemChange]
  );

  const setPaymentReceivedFields = useCallback(
    (marketplacePaymentLine: SiteMarketplacePaymentLineInput) => {
      if (curItem?.id != null) {
        onLineItemChange?.(
          {
            ...curItem,
            paymentReceived: true,
            paymentReferenceId: marketplacePaymentLine.externalPaymentId,
            costDate: marketplacePaymentLine.paymentDate,
          },
          false
        );
      } else {
        setValue(
          'paymentReferenceId',
          posChangedField(marketplacePaymentLine.externalPaymentId),
          {
            shouldDirty: true,
          }
        );
        setValue('paymentReceived', posChangedField(true), {
          shouldDirty: true,
        });
        setValue(
          'lastPaymentDate',
          posChangedField(marketplacePaymentLine.paymentDate),
          {
            shouldDirty: true,
          }
        );
      }
    },
    [curItem, onLineItemChange, setValue]
  );

  const onRemovePaymentReceivedFields = useCallback(
    (curItem?: SaleLineItem) => {
      if (curItem?.id != null) {
        onLineItemChange?.(
          {
            ...curItem,
            paymentReceived: false,
            paymentReferenceId: null,
            marketplacePaymentLineId: null,
          },
          false
        );
      } else {
        setValue('paymentReceived', posChangedField(false), {
          shouldDirty: true,
        });
        setValue('paymentReferenceId', posChangedField(null), {
          shouldDirty: true,
        });
        setValue('lastPaymentDate', posChangedField(null), {
          shouldDirty: true,
        });
      }

      closeRemovePaymentReceivedDialog();
    },
    [closeRemovePaymentReceivedDialog, onLineItemChange, setValue]
  );

  const onCreateSalePayment = useCallback(
    (formMarketplacePaymentLineInput: SiteMarketplacePaymentLineInput) => {
      setValue(
        'marketplacePaymentLines',
        posChangedField([
          ...(marketplacePaymentLines ?? []),
          formMarketplacePaymentLineInput,
        ]),
        {
          shouldDirty: true,
        }
      );
      setPaymentReceivedFields(formMarketplacePaymentLineInput);
      addSalePaymentDialog.closeDialog();
      setCurItem(undefined);
    },
    [
      addSalePaymentDialog,
      marketplacePaymentLines,
      setPaymentReceivedFields,
      setValue,
    ]
  );

  const onPaymentReceivedToggleClicked = useCallback(() => {
    if (paymentReceived) {
      launchRemovePaymentReceivedDialog({
        size: 'm',
        headerText: <Content id={ContentId.MarkAsUnpaid} />,
        bodyText: <Content id={ContentId.MarkProceedsAsUnpaidConfirmation} />,
        onOkay: onRemovePaymentReceivedFields,
        onCancel: closeRemovePaymentReceivedDialog,
        okText: ContentId.Yes,
        cancelText: ContentId.No,
      });
    } else {
      const existingMarketplacePaymentProceedsLine =
        marketplacePaymentLines?.find(
          (line) => line.paymentLineType === MarketplacePaymentLineType.Proceeds
        );
      if (existingMarketplacePaymentProceedsLine != null) {
        setPaymentReceivedFields(existingMarketplacePaymentProceedsLine);
      } else {
        // Prompt user to enter a new one
        setCurrentMarketplacePaymentLine({
          ...EMPTY_MARKETPLACE_PAYMENT_LINE,
          externalPaymentLineId: `paymentline-${new Date().getTime()}`,
          externalSaleId: sale!.idOnMkp!,
          saleId: sale!.id,
          currency: sale!.currency,
          paymentLineType: MarketplacePaymentLineType.Proceeds,
          amount: sale!.mkpSaleTtlNetProcs?.amt ?? 0,
          paymentState: MarketplacePaymentState.Paid,
        });
        addSalePaymentDialog.launchDialog();
      }
    }
  }, [
    addSalePaymentDialog,
    closeRemovePaymentReceivedDialog,
    launchRemovePaymentReceivedDialog,
    marketplacePaymentLines,
    onRemovePaymentReceivedFields,
    paymentReceived,
    sale,
    setPaymentReceivedFields,
  ]);

  const onSaleLineItemPaymentReceivedToggleClicked = useCallback(
    (lineItem: SaleLineItem) => {
      if (lineItem.paymentReceived) {
        launchRemovePaymentReceivedDialog({
          size: 'm',
          headerText: <Content id={ContentId.MarkAsUnpaid} />,
          bodyText: <Content id={ContentId.MarkLineItemAsUnpaidConfirmation} />,
          onOkay: () => onRemovePaymentReceivedFields(lineItem),
          onCancel: closeRemovePaymentReceivedDialog,
          okText: ContentId.Yes,
          cancelText: ContentId.No,
        });
      } else {
        setCurItem(lineItem);
        // Prompt user to enter a new one
        setCurrentMarketplacePaymentLine({
          ...EMPTY_MARKETPLACE_PAYMENT_LINE,
          externalPaymentLineId: `paymentline-${new Date().getTime()}`,
          externalSaleId: sale!.idOnMkp!,
          saleId: sale!.id,
          currency: lineItem.currencyCode,
          paymentLineType:
            lineItem.costType === SaleCostType.Charge
              ? MarketplacePaymentLineType.Charge
              : MarketplacePaymentLineType.Credit,
          amount: lineItem.cost,
        });
        addSalePaymentDialog.launchDialog();
      }
    },
    [
      addSalePaymentDialog,
      closeRemovePaymentReceivedDialog,
      launchRemovePaymentReceivedDialog,
      onRemovePaymentReceivedFields,
      sale,
    ]
  );

  if (!sale) {
    return null;
  }

  return (
    <>
      <Stack justifyContent="end">
        <Button
          onClick={() => onSetSaleLineItem()}
          variant="textPlain"
          disabled={hideForWastedSale}
        >
          <PlusIcon
            withHoverEffect
            size={vars.iconSize.m}
            fill={IconsFill.currentColor}
          />
          <Content id={ContentId.AddChargeOrCredit} />
        </Button>
      </Stack>
      <PaymentSectionTable
        sale={sale}
        lineItems={lineItems}
        onEditSaleLineItem={onSetSaleLineItem}
        onDeleteSaleLineItem={onDeleteSaleLineItem}
        onPaymentReceivedToggleClicked={onPaymentReceivedToggleClicked}
        onSaleLineItemPaymentReceivedToggleClicked={
          onSaleLineItemPaymentReceivedToggleClicked
        }
        hidePartsForWastedSale={hideForWastedSale}
      />
      <SaleLineItemModal
        item={curItem}
        isOpen={costDialog.dialogProps.isOpen}
        onClose={onAddNewCostCancel}
        onSave={onAddNewCostSave}
        currencyCode={
          sale?.currency ??
          loginContext?.user?.activeAccount.currencyCode ??
          'USD'
        }
      />
      {addSalePaymentDialog.dialogProps.isOpen && (
        <MarketplacePaymentLineDialog
          {...addSalePaymentDialog.dialogProps}
          siteMarketplacePaymentLineInput={currentMarketplacePaymentLine}
          canSelectProceeds={true}
          sale={sale}
          onSave={onCreateSalePayment}
          onCancel={() => {
            addSalePaymentDialog.closeDialog();
            setCurItem(undefined);
          }}
          disabledPaymentTypeInput={true}
          disabledAmountInput={!!currentMarketplacePaymentLine?.amount}
        />
      )}
    </>
  );
};
