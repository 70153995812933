import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useOnSaleEvents } from 'src/hooks/api/useOnSaleEvents';
import { OnSaleEventWithMetrics } from 'src/navigations/Routes/OnSaleEvent/OnSaleEventView.types';
import { OnSaleEventMetrics, OnSaleEventQuery } from 'src/WebApiController';

import { useFilterQueryContext } from '../FilterQueryContext';

export interface IEventDiscoveryContext {
  onSaleEvents: OnSaleEventWithMetrics[];
  isLoading: boolean;
  searchText: string | undefined;
  setSearchText: (value: string | null) => void;
}

const emptyContext: IEventDiscoveryContext = {
  onSaleEvents: [],
  isLoading: false,
  setSearchText: () => {},
  searchText: undefined,
};

const EventDiscoveryContext =
  createContext<IEventDiscoveryContext>(emptyContext);

export const useEventDiscoveryContext = () => useContext(EventDiscoveryContext);

export const EventDiscoveryContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { filterQuery, setFilterQuery } =
    useFilterQueryContext<OnSaleEventQuery>();
  const { onSaleEventMetrics, onSaleEvents, isLoading } =
    useOnSaleEvents(filterQuery);

  const mergedEvents = useMemo(() => {
    if (!onSaleEvents) {
      return [];
    }
    const { events, performers, venues } = onSaleEvents;
    const mergedEvents: OnSaleEventWithMetrics[] = Object.entries(events).map(
      ([_, { event }]) => {
        return {
          event: event,
          performer: event.perfId ? performers[event.perfId] : undefined,
          venue: event.venueId ? venues[event.venueId] : undefined,
        };
      }
    );

    if (onSaleEventMetrics) {
      const metricsById = onSaleEventMetrics.reduce(
        (res, metrics) => {
          res[metrics.eventId] = metrics;
          return res;
        },
        {} as Record<number, OnSaleEventMetrics>
      );
      for (const onSaleEvent of mergedEvents) {
        if (onSaleEvent.event.viagId) {
          const metrics = metricsById[onSaleEvent.event.viagId];
          if (metrics) {
            Object.assign(onSaleEvent, {
              metrics,
            });
          }
        }
      }
    }

    return mergedEvents;
  }, [onSaleEventMetrics, onSaleEvents]);

  const setSearchText = useCallback(
    (text: string | null) => {
      setFilterQuery({
        ...filterQuery,
        searchText: text,
      });
    },
    [filterQuery, setFilterQuery]
  );

  return (
    <EventDiscoveryContext.Provider
      value={{
        isLoading,
        onSaleEvents: mergedEvents,
        setSearchText,
        searchText: filterQuery.searchText ?? undefined,
      }}
    >
      {children}
    </EventDiscoveryContext.Provider>
  );
};
