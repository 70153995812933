import { MouseEvent } from 'react';
import { useToggle } from 'react-use';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { PurchasesGlobalBulkActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/PurchasesGlobalBulkActions';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import { SetPurchasesFlattenedViewSelectionCountLabel } from 'src/components/Purchases/SelectedItemsLabel';
import { Content } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { PurchaseColumnSettingsModal } from 'src/modals/ColumnSettings/PurchaseColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { PurchaseViewMode } from 'src/WebApiController';

import { container } from './PurchaseSearchToolbar.css';

export const PurchaseSearchToolbar = ({ disabled }: { disabled?: boolean }) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <div style={{ flex: 1 }}>
        <PurchaseGlobalActionsPermissionsWrapper>
          <SetPurchasesFlattenedViewSelectionCountLabel>
            {!disabled && (
              <MultiSelectActionBar
                style={{ padding: 0 }}
                groupId={NO_GROUP_ID}
                displayMode="count-only"
                showHideOnSelection
              >
                <PurchasesGlobalBulkActions />
              </MultiSelectActionBar>
            )}
          </SetPurchasesFlattenedViewSelectionCountLabel>
        </PurchaseGlobalActionsPermissionsWrapper>
      </div>

      <Button
        variant="textPlain"
        style={{ paddingRight: vars.spacing.xxs }}
        onClick={onColumnSettingButtonClickHandler}
      >
        <LayoutIcon size={vars.iconSize.m} />
        <Content id={ContentId.Columns} />
      </Button>

      {isColumnModalOpen ? (
        <PurchaseColumnSettingsModal
          sectionType={SectionType.Purchases}
          onClose={toggleColumnModal}
          viewMode={PurchaseViewMode.FlattenedView}
        />
      ) : null}
    </Stack>
  );
};
