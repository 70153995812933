import { ComponentProps, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { getCatalogData as getCatalogDataForListing } from 'src/navigations/Routes/Inventory';
import { getCatalogData as getCatalogDataForSales } from 'src/navigations/Routes/Sales';
import {
  DefaultListingQuery,
  DefaultSaleQuery,
  EmptyListingQuery,
  EmptySaleQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { ReportTypes } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  ListingQuery,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import { ReportBuilderDialogBody } from './ReportBuilderDialogBody';

export type ReportBuilderDialogProps = {
  report: ReportConfig;
  onSave: (data: ReportConfig) => void;
} & ComponentProps<typeof RSModal>;

export function ReportBuilderDialog<S extends ReportTypes>({
  report,
  ...rest
}: ReportBuilderDialogProps) {
  const methods = useForm<ReportConfig>({
    defaultValues: report,
  });

  useEffect(() => {
    methods.reset(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reportId]);

  if (report.reportType === ReportTypes.Inventory) {
    return (
      <FilterQueryContextProvider<ListingQuery>
        initialQuery={DefaultListingQuery}
        emptyQuery={EmptyListingQuery}
        viewModeSettingId={UserSetting.InventoryPageViewMode}
      >
        <CatalogDataContextProvider<ListingQuery>
          entityType={ActionOutboxEntityType.Listing}
          queryKey="getCatalogForListing"
          getCatalogData={(c, f) => getCatalogDataForListing(c, f, false)}
          transformEventData={transformData}
        >
          <FormProvider {...methods}>
            <ReportBuilderDialogBody<S>
              {...methods}
              {...rest}
              reportType={report.reportType}
            />
          </FormProvider>
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    );
  }

  return (
    <FilterQueryContextProvider<SaleQuery>
      initialQuery={DefaultSaleQuery}
      emptyQuery={EmptySaleQuery}
      viewModeSettingId={UserSetting.SalePageViewMode}
    >
      <CatalogDataContextProvider<SaleQuery>
        entityType={ActionOutboxEntityType.Sale}
        queryKey="getCatalogForSales"
        getCatalogData={(c, f) => getCatalogDataForSales(c, f, false)}
        transformEventData={transformData}
      >
        <FormProvider {...methods}>
          <ReportBuilderDialogBody<S>
            {...methods}
            {...rest}
            reportType={report.reportType}
          />
        </FormProvider>
      </CatalogDataContextProvider>
    </FilterQueryContextProvider>
  );
}
