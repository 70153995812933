import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './CondensedVenueMapQtyFilter.css';

export const CondensedVenueMapQtyFilter = () => {
  const { compQuantityFilters, setCompQuantityFilters, splitChoices } =
    useInventoryEventDetailContext();

  return (
    <PosMultiSelect
      placeholderText={ContentId.FilterQty}
      values={compQuantityFilters}
      onChange={setCompQuantityFilters}
      valueOptionsContent={splitChoices}
      showSelectedOptionsFirst={false}
      contentHeight={'400px'}
      pluralDisplayFormatContentId={FormatContentId.NSelected}
      triggerProps={{
        className: styles.triggerContent,
      }}
    />
  );
};
