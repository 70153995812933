import * as Sentry from '@sentry/react';
import { createBrowserRouter, Navigate, redirect } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { Activity } from 'src/navigations/Routes/Activity';
import { AdCampaign } from 'src/navigations/Routes/AdCampaign';
import { AdGroupListings } from 'src/navigations/Routes/AdGroupListings';
import { Email, EmailNavPageNameHandle } from 'src/navigations/Routes/Email';
import { HelpCenter } from 'src/navigations/Routes/HelpCenter';
import { Home } from 'src/navigations/Routes/Home';
import { Inventory } from 'src/navigations/Routes/Inventory';
import { InventoryEvent } from 'src/navigations/Routes/InventoryEvent';
import { InventoryEventPageRedirect } from 'src/navigations/Routes/InventoryEvent/InventoryEventPageRedirect';
import { InventoryEventV2 } from 'src/navigations/Routes/InventoryEvent/InventoryEventV2';
import { InventorySearch } from 'src/navigations/Routes/InventorySearch';
import {
  Login,
  LoginCallback,
  LoginFailure,
  Logout,
} from 'src/navigations/Routes/Login';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { Notifications } from 'src/navigations/Routes/Notifications';
import { OnSaleEvent } from 'src/navigations/Routes/OnSaleEvent';
import { Payments } from 'src/navigations/Routes/Payments';
import { PurchaseEvent } from 'src/navigations/Routes/PurchaseEvent/PurchaseEvent';
import { Purchases } from 'src/navigations/Routes/Purchases';
import { PurchaseSearch } from 'src/navigations/Routes/PurchaseSearch/PurchaseSearch';
import { RedirectToStubHubUk } from 'src/navigations/Routes/RedirectToStubHubUk';
import { ReleaseNotes } from 'src/navigations/Routes/ReleaseNotes';
import { Reports, ReportsV2 } from 'src/navigations/Routes/Reports';
import { ReportsCommission } from 'src/navigations/Routes/ReportsCommission';
import { ReportsInventory } from 'src/navigations/Routes/ReportsInventory';
import { ReportsInventoryV2 } from 'src/navigations/Routes/ReportsInventory/ReportsInventoryV2';
import { ReportsSale } from 'src/navigations/Routes/ReportsSale';
import { ReportsSaleV2 } from 'src/navigations/Routes/ReportsSale/ReportsSaleV2';
import { Sales } from 'src/navigations/Routes/Sales';
import { SaleSearch } from 'src/navigations/Routes/SaleSearch';
import { SalesEvent } from 'src/navigations/Routes/SalesEvent/SalesEvent';
import { Settings, SettingsViews } from 'src/navigations/Routes/Settings';
import { SettingTab } from 'src/navigations/Routes/Settings/constants';
import { SyncCenter } from 'src/navigations/Routes/Settings/Views/SyncCenter';
import { SignUp } from 'src/navigations/Routes/SignUp';
import { SignUpPathname } from 'src/navigations/Routes/SignUp/constants';
import { SponsoredListings } from 'src/navigations/Routes/SponsoredListings';
import { SaleAlertIcon } from 'src/svgs';
import { PaymentsIcon } from 'src/svgs/PaymentsIcon';
import {
  ChatIcon,
  ConnectedIcon,
  DollarIcon,
  HomeOutlineIcon,
  PaperTicketsOutlineIcon,
  SearchSolidIcon,
  SellOutlineIcon,
  SettingsIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { DeviceAwareContentWrapper } from 'src/utils/contentUtils';
import { RootErrorPage } from 'src/views';

import App from './App';

export const AppRouteConfigs = {
  [MainRoute.Home]: {
    view: MainRoute.Home,
    path: '/home',
    label: <Content id={ContentId.Home} />,
    icon: HomeOutlineIcon,
  },
  [MainRoute.Inventory]: {
    view: MainRoute.Inventory,
    path: '/inventory',
    label: <Content id={ContentId.Inventory} />,
    icon: PaperTicketsOutlineIcon,
  },
  [MainRoute.Sales]: {
    view: MainRoute.Sales,
    path: '/sales',
    label: (
      <DeviceAwareContentWrapper
        defaultId={ContentId.Sales}
        mobileId={ContentId.Sales}
      />
    ),
    icon: SaleAlertIcon,
  },
  [MainRoute.Purchases]: {
    view: MainRoute.Purchases,
    path: '/purchases',
    label: <Content id={ContentId.Purchases} />,
    icon: SellOutlineIcon,
  },
  [MainRoute.Payments]: {
    view: MainRoute.Payments,
    path: '/payments',
    label: <Content id={ContentId.Payments} />,
    icon: PaymentsIcon,
  },
  [MainRoute.Reports]: {
    view: MainRoute.Reports,
    path: '/reports',
    label: <Content id={ContentId.Reports} />,
    icon: ConnectedIcon,
  },
  [MainRoute.ReportsV2]: {
    view: MainRoute.ReportsV2,
    path: '/reports/v2',
    label: 'Reports V2', // no need to translate - once we release - we will need to use ContentId.Report again
    icon: ConnectedIcon,
  },
  [MainRoute.SponsoredListings]: {
    view: MainRoute.SponsoredListings,
    path: '/sponsoredlistings',
    label: <Content id={ContentId.SponsoredListings} />,
    icon: DollarIcon,
  },
  [MainRoute.Discovery]: {
    view: MainRoute.Discovery,
    path: '/discovery',
    label: <Content id={ContentId.EventDiscovery} />,
    icon: SearchSolidIcon,
  },
};

export const MenuItems = {
  [MainRoute.Email]: {
    view: MainRoute.Email,
    path: '/messages',
    label: <Content id={ContentId.Messages} />,
    icon: ChatIcon,
  },
  [MainRoute.Settings]: {
    view: MainRoute.Settings,
    path: '/settings',
    label: <Content id={ContentId.Settings} />,
    icon: SettingsIcon,
  },
} as const;

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: <App />,
    errorElement: (
      <div>
        <RootErrorPage />
      </div>
    ),
    children: [
      {
        path: '/',
        loader() {
          return redirect('/start');
        },
      },
      {
        path: '/start',
        element: <SignUp />,
      },
      {
        path: SignUpPathname,
        element: <SignUp />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/login/callback',
        element: <LoginCallback />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/unauthorized',
        element: <LoginFailure />,
      },
      {
        path: AppRouteConfigs[MainRoute.Home].path,
        element: <Home />,
      },
      {
        path: AppRouteConfigs[MainRoute.Inventory].path,
        element: <Inventory />,
      },
      {
        path: '/inventory/event/v2',
        element: <InventoryEventV2 />,
      },
      {
        path: '/inventory/event',
        element: <InventoryEvent />,
      },
      {
        path: '/inventory/event/:eventId',
        element: <InventoryEventPageRedirect />,
      },
      {
        path: '/inventory/search',
        element: <InventorySearch />,
      },
      {
        path: AppRouteConfigs[MainRoute.Discovery].path,
        element: <OnSaleEvent />,
      },
      {
        path: AppRouteConfigs[MainRoute.Sales].path,
        element: <Sales />,
      },
      {
        path: '/sales/event',
        element: <SalesEvent />,
      },
      {
        path: '/sales/search',
        element: <SaleSearch />,
      },
      {
        path: AppRouteConfigs[MainRoute.Purchases].path,
        element: <Purchases />,
      },
      {
        path: AppRouteConfigs[MainRoute.Payments].path,
        element: <Payments />,
      },
      {
        path: '/purchases/event',
        element: <PurchaseEvent />,
      },
      {
        path: '/purchases/search',
        element: <PurchaseSearch />,
      },
      {
        path: '/redirect-uk',
        element: <RedirectToStubHubUk />,
      },
      {
        path: '/notifications',
        element: <Notifications />,
      },
      {
        path: '/release-notes',
        element: <ReleaseNotes />,
      },
      {
        path: '/help',
        element: <HelpCenter />,
      },
      {
        path: '/reports/v2',
        element: <ReportsV2 />,
      },
      {
        path: '/reports',
        element: <Reports />,
      },
      {
        path: '/reports/inventory/:reportId',
        element: <ReportsInventory />,
      },
      {
        path: '/reports/sale/:reportId',
        element: <ReportsSale />,
      },
      {
        path: '/reports/inventory/v2/:reportId',
        element: <ReportsInventoryV2 />,
      },
      {
        path: '/reports/commission/v2/:reportId',
        element: <ReportsCommission />,
      },
      {
        path: '/reports/sale/v2/:reportId',
        element: <ReportsSaleV2 />,
      },
      {
        path: '/activity',
        element: <Activity />,
      },
      {
        path: '/sync-center',
        element: <SyncCenter />,
      },
      {
        path: AppRouteConfigs[MainRoute.SponsoredListings].path,
        element: <SponsoredListings />,
      },
      {
        path: '/sponsoredlistings/adcampaign',
        element: <AdCampaign />,
      },
      {
        path: '/sponsoredlistings/adgrouplistings',
        element: <AdGroupListings />,
      },
      /**
       * Settings Routes
       */
      {
        path: '/settings',
        element: <Settings />,
        children: [
          {
            index: true,
            element: (
              <Navigate to={`/settings/${SettingTab.MyAccount}`} replace />
            ),
          },
          {
            path: SettingTab.MyAccount,
            element: <SettingsViews.MyAccount />,
          },
          {
            path: SettingTab.CurrencyConversion,
            element: <SettingsViews.CurrencyConversion />,
          },
          {
            path: SettingTab.FulfillmentProviders,
            element: <SettingsViews.FulfillmentProviders />,
          },
          {
            path: SettingTab.PurchaseOrder,
            element: <SettingsViews.PurchaseOrderSetting />,
          },
          {
            path: SettingTab.Accounting,
            element: <SettingsViews.AccountingSetting />,
          },
          {
            path: SettingTab.UserManagement,
            element: <SettingsViews.UsersManagement />,
            children: [
              {
                path: '',
                element: (
                  <Stack direction="column" gap="l">
                    <SettingsViews.Users />
                    <SettingsViews.Roles />
                    <SettingsViews.Teams />
                  </Stack>
                ),
              },
              {
                path: SettingTab.UserManagement_Users,
                element: <SettingsViews.Users />,
              },
              {
                path: SettingTab.UserManagement_Roles,
                element: <SettingsViews.Roles />,
              },
              {
                path: SettingTab.UserManagement_Teams,
                element: <SettingsViews.Teams />,
              },
            ],
          },
          {
            path: SettingTab.VendorManagement,
            element: <SettingsViews.VendorManagement />,
            children: [
              {
                path: '',
                element: (
                  <Stack direction="column" gap="l">
                    <SettingsViews.Payments />
                    <SettingsViews.Vendors />
                  </Stack>
                ),
              },
              {
                path: SettingTab.VendorManagement_Vendors,
                element: <SettingsViews.Vendors />,
              },
              {
                path: SettingTab.VendorManagement_Payments,
                element: <SettingsViews.Payments />,
              },
            ],
          },
          {
            path: SettingTab.SyncCenter,
            element: <SettingsViews.SyncCenter />,
          },
          {
            path: SettingTab.SellerAccount,
            element: <SettingsViews.SellerAccount />,
          },
          {
            path: SettingTab.AutoPricing,
            element: <SettingsViews.AccountAutoPricingSettings />,
          },
        ],
      },
      {
        path: '/messages',
        element: <Email />,
        children: [
          {
            children: [
              { index: true },
              { path: 'support/:sellerSupportCaseId' },
              { path: ':inboundEmailId' },
            ],
            handle: {
              pageName: 'inbox',
            } satisfies EmailNavPageNameHandle,
          },
          {
            path: 'uncategorized',
            children: [{ index: true }, { path: ':inboundEmailId' }],
            handle: {
              pageName: 'uncategorized',
            } satisfies EmailNavPageNameHandle,
          },
          {
            path: 'recently-categorized',
            children: [{ index: true }, { path: ':inboundEmailId' }],
            handle: {
              pageName: 'recentlyCategorized',
            } satisfies EmailNavPageNameHandle,
          },
        ],
      },
    ],
  },
]);
