import { useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface UseInventoryFlattedViewSelectedLegendProps {
  singleElementLabel: string;
  multipleElementsLabel: string;
}

export const useEventFlattedViewSelectedLegend = ({
  singleElementLabel,
  multipleElementsLabel,
}: UseInventoryFlattedViewSelectedLegendProps) => {
  const { selectionMode, totalItemsSelected, itemSelection } =
    useMultiSelectionContext();
  const selectedText = useContent(ContentId.Selected);

  const isAllGroupsMode = selectionMode?.mode === MultiSelectScope.AllGroups;
  const isNoGroupSelection = !!itemSelection?.[NO_GROUP_ID];
  const noItemsSelected = useGetNoSelectedItemsLabel();

  return useMemo<string>(() => {
    if (!isAllGroupsMode || !totalItemsSelected || !isNoGroupSelection) {
      return noItemsSelected;
    }

    const listingCount = itemSelection[NO_GROUP_ID].items.filter(
      (itemId) => Number(itemId) > 0
    ).length;

    const selectedListingsLabel = `${listingCount} ${
      listingCount === 1 ? singleElementLabel : multipleElementsLabel
    }`;

    return `${selectedListingsLabel} ${selectedText}`.toLocaleLowerCase();
  }, [
    isAllGroupsMode,
    totalItemsSelected,
    isNoGroupSelection,
    itemSelection,
    singleElementLabel,
    multipleElementsLabel,
    selectedText,
    noItemsSelected,
  ]);
};
