import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient, PurchasePaymentMethod } from 'src/WebApiController';

export type UsePurchasePaymentMethodSelectorOptions = {
  disabled?: boolean;
  additionalOptions?: Record<string, string>;
  additionalOptionsPosition?: 'top' | 'bottom';
};

const SEARCHABLE_OPTIONS_THRESHOLD = 10;

export function usePurchasePaymentMethodSelector({
  disabled,
  additionalOptions,
  additionalOptionsPosition = 'bottom',
}: UsePurchasePaymentMethodSelectorOptions): {
  query: UseQueryResult<PurchasePaymentMethod[] | null, Error>;
  posSelectProps: Pick<
    PosSelectProps,
    'placeholderText' | 'valueOptionsContent' | 'searchable' | 'loading'
  >;
} {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;
  const query = useQuery({
    queryKey: [
      'PurchaseClient.getAccessiblePurchasePaymentMethods',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessiblePurchasePaymentMethods();
        },
        (error) => {
          trackError(
            'PurchaseClient.getAccessiblePurchasePaymentMethods',
            error
          );
        }
      );
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: 'offlineFirst',
    meta: {
      persist: false, // we do not want this thing persisted between sessions
    },
  });

  const paymentMethodOptions = useMemo(() => {
    const paymentMethods =
      query.data?.sort((a, b) => {
        return a.display.localeCompare(b.display);
      }) ?? [];

    const options: Record<string, string> = {};
    for (const { id, name, display } of paymentMethods) {
      options[id] = `${name} (${display})`;
    }
    return {
      ...(additionalOptionsPosition === 'top' ? additionalOptions : {}),
      ...options,
      ...(additionalOptionsPosition === 'bottom' ? additionalOptions : {}),
    };
  }, [query.data, additionalOptions, additionalOptionsPosition]);
  return {
    query,
    posSelectProps: {
      placeholderText: ContentId.ChoosePaymentMethodPlaceholder,
      valueOptionsContent: paymentMethodOptions,
      searchable:
        Object.keys(paymentMethodOptions).length > SEARCHABLE_OPTIONS_THRESHOLD,
      loading: query.isLoading,
    },
  };
}
