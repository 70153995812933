import { clsx } from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { PosLink } from 'src/core/POS/PosLink';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack, Switch } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DeleteIcon, EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SALE_LINE_ITEM_CHARGE_REASON_TO_CID,
  SALE_LINE_ITEM_CREDIT_REASON_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { getMarketplacePaymentRelativeUrl } from 'src/utils/marketplacePaymentUtils';
import { formatCurrency } from 'src/utils/numberFormatter';
import {
  Feature,
  MarketplacePaymentLineType,
  Permission,
  SaleCostType,
  SaleDetails,
  SaleInput,
  SaleLineItem,
} from 'src/WebApiController';

import * as tableStyles from '../../../SaleDetailsTable.css';
import * as styles from './PaymentSectionTable.css';

interface PaymentSectionTableProps {
  sale: SaleDetails;
  lineItems: SaleLineItem[] | null | undefined;
  onEditSaleLineItem: (lineItem: SaleLineItem) => void;
  onDeleteSaleLineItem: (lineItem: SaleLineItem) => void;
  onPaymentReceivedToggleClicked: () => void;
  onSaleLineItemPaymentReceivedToggleClicked: (lineItem: SaleLineItem) => void;
  hidePartsForWastedSale: boolean;
}

export const PaymentSectionTable = ({
  sale,
  lineItems,
  onEditSaleLineItem,
  onDeleteSaleLineItem,
  onPaymentReceivedToggleClicked,
  onSaleLineItemPaymentReceivedToggleClicked,
  hidePartsForWastedSale,
}: PaymentSectionTableProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const { watch } = useFormContext<SaleInput>();
  const paymentReceived = watch('paymentReceived')?.value ?? false;
  const paymentReferenceId = watch('paymentReferenceId')?.value ?? '';
  const lastPaymentDate = watch('lastPaymentDate')?.value;
  const marketplacePaymentLines = watch('marketplacePaymentLines')?.value;
  const hasEditSaleLineItemsFeature = useUserHasFeature(
    Feature.SaleEditChargesAndCredits
  );

  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  let originalProceeds = null;
  if (sale.ttlNetProcs && sale.mkpSaleTtlNetProcs) {
    if (sale.ttlNetProcs.currency !== sale.mkpSaleTtlNetProcs.currency) {
      originalProceeds = `(${sale.mkpSaleTtlNetProcs.disp})`;
    }
  }

  const totalProfitAmount = useMemo(() => {
    let sum = hasTotalNetProceedsPermissions ? sale?.ttlNetProcs?.amt ?? 0 : 0;

    if (sale?.soldTktCost != null) {
      sum -= sale?.soldTktCost.amt;
    }

    if (hasEditSaleLineItemsFeature) {
      lineItems?.forEach((li) => {
        // Per James - charges subtracts and credits add
        sum += (li.costType === SaleCostType.Charge ? -1 : 1) * li.cost;
      });
    }

    return sum;
  }, [
    hasEditSaleLineItemsFeature,
    hasTotalNetProceedsPermissions,
    lineItems,
    sale?.soldTktCost,
    sale?.ttlNetProcs?.amt,
  ]);

  const getMarketplacePaymentId = useCallback(
    (saleLineItem: SaleLineItem) => {
      return (marketplacePaymentLines ?? []).find(
        (mktPaymentLine) =>
          mktPaymentLine.marketplacePaymentLineId ===
          saleLineItem.marketplacePaymentLineId
      );
    },
    [marketplacePaymentLines]
  );

  const proceedsPaymentLine = useMemo(() => {
    return (marketplacePaymentLines ?? []).find(
      (mktPaymentLine) =>
        mktPaymentLine.paymentLineType === MarketplacePaymentLineType.Proceeds
    );
  }, [marketplacePaymentLines]);

  return (
    <div className={tableStyles.tableWrapper}>
      <SimpleTable.Table className={tableStyles.table}>
        <SimpleTable.Thead showBottomBorder={false}>
          <SimpleTable.Tr className={tableStyles.tableRow}>
            <SimpleTable.Th className={tableStyles.tableHeader}>
              <Content id={ContentId.Overview} />
            </SimpleTable.Th>
            <SimpleTable.Th
              className={clsx(tableStyles.alignRight, tableStyles.tableHeader)}
            >
              <Content id={ContentId.Amount} />
            </SimpleTable.Th>
            <SimpleTable.Th className={tableStyles.tableHeader}>
              <Content id={ContentId.Received} />
            </SimpleTable.Th>
            <SimpleTable.Th className={tableStyles.tableHeader}>
              <Content id={ContentId.Reference} />
            </SimpleTable.Th>
            <SimpleTable.Th className={tableStyles.tableHeader}>
              <Content id={ContentId.PaymentDate} />
            </SimpleTable.Th>
            <SimpleTable.Th className={tableStyles.tableHeader}>
              <Content id={ContentId.Actions} />
            </SimpleTable.Th>
          </SimpleTable.Tr>
        </SimpleTable.Thead>
        <SimpleTable.Tbody>
          <SimpleTable.Tr className={tableStyles.tableRow}>
            <SimpleTable.Td className={tableStyles.tableCell}>
              <Content id={ContentId.Proceed} />
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.alignRight}>
              {originalProceeds && (
                <span className={styles.originalProceeds}>
                  {originalProceeds}{' '}
                </span>
              )}
              {sale.ttlNetProcs?.disp}
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell}>
              <Switch
                disabled={hidePartsForWastedSale}
                checked={hidePartsForWastedSale ? false : paymentReceived}
                onChange={(e) => e.stopPropagation()}
                onClick={onPaymentReceivedToggleClicked}
              />
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell}>
              {proceedsPaymentLine?.marketplacePaymentId ? (
                <PosLink
                  variant="brand"
                  to={getMarketplacePaymentRelativeUrl(
                    proceedsPaymentLine.marketplacePaymentId
                  )}
                >
                  {paymentReferenceId}
                </PosLink>
              ) : (
                paymentReferenceId
              )}
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell}>
              {lastPaymentDate &&
                formatInTimeZone(
                  stringToUtcDate(lastPaymentDate),
                  timeZone,
                  'MMM d, yyyy',
                  {
                    locale: getLocaleFromLanguageOrCurrent(),
                  }
                )}
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell}></SimpleTable.Td>
          </SimpleTable.Tr>
          {(lineItems ?? []).map((saleLineItem) => {
            const {
              id,
              costType,
              cost,
              costDate,
              currencyCode,
              saleLineItemChargeReason,
              saleLineItemCreditReason,
              paymentReceived,
              paymentReferenceId,
            } = saleLineItem;
            const marketplacePayment = getMarketplacePaymentId(saleLineItem);
            const isCharge = costType === SaleCostType.Charge;
            const lineTypeContentId = isCharge
              ? ContentId.Charge
              : ContentId.Credit;
            const costWithSign = isCharge ? cost * -1 : cost;
            const reasonContentId = isCharge
              ? saleLineItemChargeReason
                ? SALE_LINE_ITEM_CHARGE_REASON_TO_CID[saleLineItemChargeReason]
                : null
              : saleLineItemCreditReason
              ? SALE_LINE_ITEM_CREDIT_REASON_TO_CID[saleLineItemCreditReason]
              : null;
            return (
              <SimpleTable.Tr key={id} className={tableStyles.tableRow}>
                <SimpleTable.Td className={tableStyles.tableCell}>
                  <Stack direction="column">
                    <Content id={lineTypeContentId} />
                    {reasonContentId && (
                      <span className={styles.chargeOrCreditReason}>
                        <Content id={reasonContentId} />
                      </span>
                    )}
                  </Stack>
                </SimpleTable.Td>
                <SimpleTable.Td
                  className={clsx(
                    tableStyles.alignRight,
                    tableStyles.tableCell
                  )}
                >
                  {formatCurrency(costWithSign, currencyCode)}
                </SimpleTable.Td>
                <SimpleTable.Td className={tableStyles.tableCell}>
                  <Switch
                    checked={!!paymentReceived}
                    disabled={
                      saleLineItem.costType !== SaleCostType.Charge &&
                      saleLineItem.costType !== SaleCostType.Credit
                    }
                    onChange={(e) => e.stopPropagation()}
                    onClick={() =>
                      onSaleLineItemPaymentReceivedToggleClicked(saleLineItem)
                    }
                  />
                </SimpleTable.Td>
                <SimpleTable.Td className={tableStyles.tableCell}>
                  {paymentReferenceId &&
                  marketplacePayment?.marketplacePaymentId ? (
                    <PosLink
                      variant="brand"
                      to={getMarketplacePaymentRelativeUrl(
                        marketplacePayment.marketplacePaymentId
                      )}
                    >
                      {paymentReferenceId}
                    </PosLink>
                  ) : (
                    paymentReferenceId
                  )}
                </SimpleTable.Td>
                <SimpleTable.Td className={tableStyles.tableCell}>
                  {costDate &&
                    formatInTimeZone(
                      stringToUtcDate(costDate),
                      timeZone,
                      'MMM d, yyyy',
                      {
                        locale: getLocaleFromLanguageOrCurrent(),
                      }
                    )}
                </SimpleTable.Td>
                <SimpleTable.Td className={tableStyles.tableCell}>
                  <Stack>
                    <IconButton
                      onClick={() => onEditSaleLineItem(saleLineItem)}
                      icon={
                        <EditIcon
                          withHoverEffect
                          fill={IconsFill.currentColor}
                          size={vars.iconSize.m}
                        />
                      }
                      titleContentId={ContentId.Edit}
                    />
                    <IconButton
                      onClick={() => onDeleteSaleLineItem(saleLineItem)}
                      icon={
                        <DeleteIcon
                          fill={IconsFill.currentColor}
                          withHoverEffect
                          size={vars.iconSize.m}
                        />
                      }
                      titleContentId={ContentId.Remove}
                    />
                  </Stack>
                </SimpleTable.Td>
              </SimpleTable.Tr>
            );
          })}
          <SimpleTable.Tr
            className={clsx(styles.costCell, tableStyles.tableRow)}
          >
            <SimpleTable.Td className={tableStyles.tableCell}>
              <Content id={ContentId.TotalCost} />
            </SimpleTable.Td>
            <SimpleTable.Td
              className={clsx(tableStyles.alignRight, tableStyles.tableCell)}
            >
              {sale?.soldTktCost == null ? (
                <Content id={ContentId.NA} />
              ) : (
                sale.soldTktCost.disp
              )}
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
          </SimpleTable.Tr>
          <SimpleTable.Tr
            className={clsx(styles.profitCell, tableStyles.tableRow)}
          >
            <SimpleTable.Td className={tableStyles.tableCell}>
              <Content id={ContentId.Profit} />
            </SimpleTable.Td>
            <SimpleTable.Td
              className={clsx(tableStyles.alignRight, tableStyles.tableCell)}
            >
              {formatCurrency(totalProfitAmount, sale?.currency)}
            </SimpleTable.Td>
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
            <SimpleTable.Td className={tableStyles.tableCell} />
          </SimpleTable.Tr>
        </SimpleTable.Tbody>
      </SimpleTable.Table>
    </div>
  );
};
