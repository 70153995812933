import { useMemo } from 'react';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { ReportBuilderDialog } from 'src/dialogs/ReportBuilderDialog';
import { ReportBuilderDialogV2 } from 'src/dialogs/ReportBuilderDialog/ReportBuilderDialogV2';
import { ReportHistoryDialog } from 'src/dialogs/ReportHistoryDialog';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ReportsTableContainer } from 'src/tables/ReportsTable/ReportsTableContainer';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypes, ReportTypesV2 } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { Feature, Permission } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Reports.css';
import { useReportV1Actions } from './useReportV1Actions';
import { useReportV2Actions } from './useReportV2Actions';

export function Reports() {
  const reportsTitle = useContent(ContentId.Reports);
  let canCreateReport = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );
  const hasReportV2BuilderFeature = useUserHasFeature(Feature.ReportV2Builder);
  const hasReportSinglePagedV1V2Feature = useUserHasFeature(
    Feature.ReportSinglePagedV1V2
  );

  if (hasReportSinglePagedV1V2Feature) {
    canCreateReport &&= hasReportV2BuilderFeature;
  }

  const {
    onAddNewReport,
    currentReport,
    setCurrentReport,
    onReportSaveSubmit,
    onReportShareSubmit,
    onReportDelete,
    onReportAction: onReportActionV1,
    reportBuilderDialogInventory,
    reportBuilderDialogSale,
    shareDialog,
    deleteDialog,
    reportHistoryDialog,
  } = useReportV1Actions();

  const {
    currentReport: currentReportV2,
    setCurrentReport: setCurrentReportV2,
    onAddNewReport: onAddNewReportV2,
    onReportSaveSubmit: onReportSaveSubmitV2,
    onReportAction: onReportActionV2,
    onReportShareSubmit: onReportShareSubmitV2,
    onReportDelete: onReportDeleteV2,
    reportBuilderDialog: reportBuilderDialogV2,
    deleteDialog: deleteDialogV2,
    shareDialog: shareDialogV2,
  } = useReportV2Actions();

  const onReportAction = (
    reportConfig: ReportConfig | undefined,
    reportConfigV2: ReportConfigV2 | undefined,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => {
    if (reportConfig) {
      onReportActionV1(reportConfig, actionType, event);
    } else if (reportConfigV2) {
      onReportActionV2(reportConfigV2, actionType, event);
    }
  };

  return (
    <LayoutContent
      mainRoute={MainRoute.Reports}
      routeTitle={reportsTitle}
      rightContent={
        canCreateReport && (
          <>
            {hasReportSinglePagedV1V2Feature ? (
              <Button
                style={{ height: 'min-content', width: 'fit-content' }}
                onClick={onAddNewReportV2}
              >
                <Content id={ContentId.CreateNewReport} />
              </Button>
            ) : (
              <PosDropdown
                key="create-report-dropdown"
                trigger={
                  <Button style={{ height: 'min-content', width: '100%' }}>
                    <Content id={ContentId.CreateNewReport} />
                  </Button>
                }
                align="end"
              >
                <PosDropdownItem
                  key="AddInventoryReport"
                  onClick={() => onAddNewReport(ReportTypes.Inventory)}
                >
                  <Content id={ContentId.CreateNewInventoryReport} />
                </PosDropdownItem>
                <PosDropdownItem
                  key="AddSalesReport"
                  onClick={() => onAddNewReport(ReportTypes.Sale)}
                >
                  <Content id={ContentId.CreateNewSaleReport} />
                </PosDropdownItem>
              </PosDropdown>
            )}
          </>
        )
      }
    >
      <ReportsPage onReportAction={onReportAction} />

      {currentReport && (
        <>
          <ReportBuilderDialog<ReportTypes.Inventory>
            {...reportBuilderDialogInventory.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogInventory.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ReportBuilderDialog<ReportTypes.Sale>
            {...reportBuilderDialogSale.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogSale.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ShareReportDialog
            {...shareDialog.dialogProps}
            reportName={currentReport?.reportName ?? ''}
            roleIdsShared={currentReport?.roleIdsToShare ?? []}
            sellerUserIdsShared={currentReport?.sellerUserIdsToShare ?? []}
            onOkay={onReportShareSubmit}
            onClosed={() => {
              setCurrentReport(undefined);
              shareDialog.closeDialog();
            }}
          />
        </>
      )}
      {currentReportV2 && (
        <>
          <ReportBuilderDialogV2
            {...reportBuilderDialogV2.dialogProps}
            unmountOnClose
            report={currentReportV2}
            onClosed={() => {
              setCurrentReportV2(undefined);
              reportBuilderDialogV2.closeDialog();
            }}
            onSave={onReportSaveSubmitV2}
          />
          <ShareReportDialog
            {...shareDialogV2.dialogProps}
            reportName={currentReportV2?.reportName ?? ''}
            roleIdsShared={currentReportV2?.roleIdsToShare ?? []}
            sellerUserIdsShared={currentReportV2?.sellerUserIdsToShare ?? []}
            onOkay={onReportShareSubmitV2}
            onClosed={() => {
              setCurrentReportV2(undefined);
              shareDialogV2.closeDialog();
            }}
          />
        </>
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteReport} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onReportDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
      <ReportHistoryDialog
        {...reportHistoryDialog.dialogProps}
        reportConfig={currentReport}
        onClosed={() => {
          setCurrentReport(undefined);
          reportHistoryDialog.closeDialog();
        }}
      />
      <ConfirmDialog
        {...deleteDialogV2.dialogProps}
        headerText={<Content id={ContentId.DeleteReport} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onReportDeleteV2}
        onCancel={deleteDialogV2.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
    </LayoutContent>
  );
}

function ReportsPage(props: {
  onReportAction: (
    report: ReportConfig | undefined,
    reportV2: ReportConfigV2 | undefined,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
}) {
  const hasReportSinglePagedV1V2Feature = useUserHasFeature(
    Feature.ReportSinglePagedV1V2
  );

  const {
    reportConfigs: reportConfigsInventory,
    isLoading: reportInventoryLoading,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });
  const { reportConfigs: reportConfigsSale, isLoading: reportSaleLoading } =
    useReportConfigs<ReportTypes.Sale>({
      reportType: ReportTypes.Sale,
    });

  const reports: ReportConfig[] = useMemo(() => {
    const reportsInventoryWithType = reportConfigsInventory.map((r) => ({
      ...r,
      reportType: ReportTypes.Inventory,
    }));
    const reportsSaleWithType = reportConfigsSale.map((r) => ({
      ...r,
      reportType: ReportTypes.Sale,
    }));
    return [...reportsInventoryWithType, ...reportsSaleWithType];
  }, [reportConfigsInventory, reportConfigsSale]);

  const {
    reportConfigs: reportConfigsV2Inventory,
    isLoading: reportV2InventoryLoading,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });
  const { reportConfigs: reportConfigsV2Sale, isLoading: reportV2SaleLoading } =
    useReportConfigsV2<ReportTypesV2.SaleV2>({
      reportType: ReportTypesV2.SaleV2,
    });
  const {
    reportConfigs: reportConfigsV2Commission,
    isLoading: reportV2SaleCommission,
  } = useReportConfigsV2<ReportTypesV2.Commission>({
    reportType: ReportTypesV2.Commission,
  });

  const reportsV2: ReportConfigV2[] = useMemo(() => {
    const reportsV2InventoryWithType = reportConfigsV2Inventory.map((r) => ({
      ...r,
      reportType: ReportTypesV2.InventoryV2,
    }));
    const reportsV2SaleWithType = reportConfigsV2Sale.map((r) => ({
      ...r,
      reportType: ReportTypesV2.SaleV2,
    }));
    const reportsV2CommissionWithType = reportConfigsV2Commission.map((r) => ({
      ...r,
      reportType: ReportTypesV2.Commission,
    }));
    return [
      ...reportsV2InventoryWithType,
      ...reportsV2SaleWithType,
      ...reportsV2CommissionWithType,
    ];
  }, [
    reportConfigsV2Commission,
    reportConfigsV2Inventory,
    reportConfigsV2Sale,
  ]);

  const isLoading =
    reportInventoryLoading ||
    reportSaleLoading ||
    reportV2InventoryLoading ||
    reportV2SaleLoading ||
    reportV2SaleCommission;

  return (
    <div className={styles.root}>
      {isLoading ? (
        <PosSpinner />
      ) : (
        <ReportsTableContainer
          reports={reports}
          reportsV2={hasReportSinglePagedV1V2Feature ? reportsV2 : undefined}
          {...props}
        />
      )}
    </div>
  );
}
