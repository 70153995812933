import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import {
  ModalBody,
  ModalBodyDataContainer,
} from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  SellerAccountClient,
  SellerRoleInput,
} from 'src/WebApiController';

import * as styles from '../UsersManagement.css';
import { SellerRoleDetails } from './components/SellerRoleDetails';
import { SellerRoleModalProps } from './SellerRoleModal.types';
import { SellerRoleModalFooter } from './SellerRoleModalFooter';

export const SellerRoleModalBody = ({
  disabled,
  roleInfo,
  cancelTo,
  formState,
  handleSubmit,
  watch,
  getValues,
  setError,
  clearErrors,
}: Omit<
  ComponentProps<typeof FormProvider<SellerRoleInput, unknown>>,
  'children'
> & { disabled?: boolean } & SellerRoleModalProps) => {
  const { allPermissions, refetchSellerRoleInfos } = useSellerRoleContext();
  const { refetchUserInfos } = useSellerAccountContext();

  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { closeModal, setModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;

  const onSubmit = useCallback(
    async (formValue: SellerRoleInput) => {
      const usersHasChanged = !isEqual(
        formState.defaultValues?.userIds?.toSorted(),
        formValue.userIds?.toSorted()
      );

      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new SellerAccountClient(activeAccountWebClientConfig);

          const result =
            formValue.id > 0
              ? await client.updateSellerRole(formValue)
              : await client.createSellerRole(formValue);

          if (isSuccess(result)) {
            refetchSellerRoleInfos();
            if (usersHasChanged) {
              refetchUserInfos();
            }
            closeModal(true);
          } else {
            showErrorDialog(
              formValue.id > 0
                ? 'SellerAccountClietn.updateSellerRole'
                : 'SellerAccountClient.createSeatSaver',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => cancelTo && setModal(cancelTo),
                trackErrorData: formValue,
              }
            );
          }
        },
        (error) => {
          showErrorDialog(
            formValue.id > 0
              ? 'SellerAccountClietn.updateSellerRole'
              : 'SellerAccountClient.createSeatSaver',
            error,
            {
              trackErrorData: formValue,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      formState.defaultValues?.userIds,
      genericError,
      refetchSellerRoleInfos,
      refetchUserInfos,
      setModal,
      showErrorDialog,
    ]
  );

  const hasChanges = useCallback(
    (formValue: SellerRoleInput) => {
      if (
        roleInfo?.isDeleted ||
        (formState.defaultValues?.name ?? '') !== (formValue.name ?? '') ||
        (formState.defaultValues?.details ?? '') !==
          (formValue.details ?? '') ||
        !isEqual(
          formState.defaultValues?.permissions?.toSorted(),
          formValue.permissions?.toSorted()
        ) ||
        !isEqual(
          formState.defaultValues?.userIds?.toSorted(),
          formValue.userIds?.toSorted()
        )
      ) {
        return true;
      }

      return false;
    },
    [
      formState.defaultValues?.details,
      formState.defaultValues?.name,
      formState.defaultValues?.permissions,
      formState.defaultValues?.userIds,
      roleInfo?.isDeleted,
    ]
  );

  const permissionErrorText = useContent(ContentId.PermissionsRequiredMessage);

  const onSubmitHandler = useCallback(() => {
    const formValues = getValues();

    const changed = hasChanges(formValues);
    if (!changed) return;

    let hasErrors = false;

    const visiblePermissions = formValues.permissions?.filter(
      (p) => allPermissions?.[p]
    );
    if (!visiblePermissions?.length) {
      setError('permissions', { message: permissionErrorText });
      hasErrors = true;
    } else {
      clearErrors('permissions');
    }

    if (!hasErrors) {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    hasChanges,
    allPermissions,
    setError,
    permissionErrorText,
    clearErrors,
    handleSubmit,
    onSubmit,
  ]);

  const formHasChanges = hasChanges(watch());

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isSubmitting || isLoading}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={
            <Stack gap="m" alignItems="center">
              <Content id={ContentId.BuildACustomRole} />
              {roleInfo?.isDeleted && (
                <span className={styles.inactiveBadge}>
                  <Content id={ContentId.Deactivated} />
                </span>
              )}
            </Stack>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyDataContainer>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <SellerRoleDetails
              roleInfo={roleInfo}
              disabled={disabled || isSubmitting || isLoading}
            />
          )}
        </ModalBodyDataContainer>
      </ModalBody>

      <SellerRoleModalFooter
        roleInfo={roleInfo}
        onSubmit={onSubmitHandler}
        disabled={disabled || isSubmitting || isLoading}
        hasChanges={hasChanges(getValues())}
      />
    </>
  );
};
