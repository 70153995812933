import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const RecenterIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 14 14" fill="#677383">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2H2V5H0V1C0 0.447715 0.447715 0 1 0H5V2ZM14 1V5H12V2H9V0H13C13.5523 0 14 0.447715 14 1ZM12 9V12H9V14H13C13.5523 14 14 13.5523 14 13V9H12ZM2 12V9H0V13C0 13.5523 0.447715 14 1 14H5V12H2ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z"
      />
    </SvgWrapper>
  );
};
