import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { VerticalDivider } from 'src/components/Listings/ListingsCarousel/ListingsCarousel.styled';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { Ticket } from 'src/WebApiController';

import * as styles from '../TicketGroupInputTable/TicketGroupInputTable.css';
type SeatRangeInputModalProps = ComponentProps<typeof RSModal> & {
  onOkay: () => void;
  onCancel: () => void;
  rowIndex: number | null;
};

export const SeatRangeInputModal = ({
  rowIndex,
  onCancel,
  onClosed,
  ...rest
}: SeatRangeInputModalProps) => {
  const { setValue, getValues, clearErrors } =
    useFormContext<PurchaseTicketsInput>();
  const ticketRow = getValues(`ticketGroups.${rowIndex || 0}`);
  const qty = ticketRow?.numberOfTickets?.value;

  const [localTickets, setLocalTickets] = useState<Ticket[] | null>(
    ticketRow.tickets?.value || []
  );

  useEffect(() => {
    return () => {
      setLocalTickets(null);
    };
  }, []);

  useEffect(() => {
    if (rowIndex !== null) {
      if (ticketRow) {
        setLocalTickets(ticketRow.tickets?.value || []);
      }
    } else {
      setLocalTickets(null);
    }
  }, [rowIndex, qty, ticketRow]);

  const errorMessage = useMemo(() => {
    const seatSet = new Set();
    let hasFilledSeat = false;
    let hasMissingSeat = false;
    for (const t of localTickets || []) {
      if (t.seat) {
        hasFilledSeat = true;
        if (seatSet.has(t.seat)) {
          return 'Duplicate seats are not allowed';
        }
        seatSet.add(t.seat);
      } else {
        hasMissingSeat = true;
      }
    }

    if (hasFilledSeat && hasMissingSeat) {
      return 'Please fill in all the seats';
    }
    return null;
  }, [localTickets]);

  const isDisabled = Boolean(errorMessage);

  const handleSeatChange = (index: number, value: string) => {
    if (localTickets) {
      const updatedTickets = [...localTickets];
      updatedTickets[index].seat = value;
      setLocalTickets(updatedTickets);
    }
  };

  if (!localTickets || !qty) {
    return null;
  }

  const formattedValue = localTickets
    ? localTickets.length >= 1
      ? `${localTickets[0].seat || ''} - ${
          localTickets[localTickets.length - 1].seat || ''
        }`
      : ''
    : '';

  const SeatRangeInfo = (
    <Stack
      direction="column"
      style={{ justifyContent: 'start', marginBottom: '16px' }}
    >
      <Stack
        justifyContent="spaceBetween"
        alignItems="center"
        direction="row"
        width="full"
      >
        <Stack direction="column">
          <span className={styles.seatRangeInputSectionTitle}>
            {ticketRow?.section?.value}
          </span>
          <div className={styles.seatRangeInputSectionSubtitle}>
            <Content id={ContentId.Section} />
          </div>
        </Stack>
        <VerticalDivider />
        <Stack direction="column">
          <span className={styles.seatRangeInputSectionTitle}>
            {ticketRow?.row?.value}
          </span>
          <div className={styles.seatRangeInputSectionSubtitle}>
            <Content id={ContentId.Row} />
          </div>
        </Stack>
        <VerticalDivider />

        <Stack direction="column">
          <span className={styles.seatRangeInputSectionTitle}>
            {ticketRow?.numberOfTickets?.value}
          </span>
          <div className={styles.seatRangeInputSectionSubtitle}>
            <Content id={ContentId.QuantityAbbreviated} />
          </div>
        </Stack>
        <VerticalDivider />

        <Stack direction="column">
          <span className={styles.seatRangeInputSectionTitle}>
            {formattedValue}
          </span>
          <div className={styles.seatRangeInputSectionSubtitle}>
            <Content id={ContentId.Seat} />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );

  const footer = (
    <Stack justifyContent="spaceBetween" width="full">
      <Button
        variant={'text'}
        onClick={() => {
          const tempTickets = [...localTickets];
          tempTickets?.forEach((t) => {
            t.seat = '';
          });
          setLocalTickets(tempTickets);
        }}
      >
        <Content id={ContentId.Reset} />
      </Button>
      <Button
        variant={'regular'}
        onClick={() => {
          if (rowIndex == null) {
            return;
          }
          setValue(
            `ticketGroups.${rowIndex}.tickets`,
            posChangedField(localTickets)
          );
          setLocalTickets(null);
          clearErrors(`ticketGroups.${rowIndex}.tickets`);
          onCancel();
        }}
        disabled={isDisabled}
      >
        <Content id={ContentId.Save} />
      </Button>
    </Stack>
  );

  return (
    <GenericDialog
      {...rest}
      header={<Content id={ContentId.Seats} />}
      footer={footer}
      onCancel={() => {
        onClosed?.();
      }}
      onClosed={onClosed}
    >
      {SeatRangeInfo}
      {errorMessage && (
        <div style={{ color: 'red', fontSize: '14px', marginBottom: '16px' }}>
          {errorMessage}
        </div>
      )}
      <Stack
        width="full"
        style={{
          paddingBottom: '8px',
        }}
      >
        <div
          className={styles.seatRangeInputColumnHeader}
          style={{ width: '20%' }}
        >
          <Content id={ContentId.Ticket} />
        </div>
        <div className={styles.seatRangeInputColumnHeader}>
          <Content id={ContentId.Seat} />
        </div>
      </Stack>
      {localTickets?.map((t, i) => {
        return (
          <Stack
            key={i}
            alignItems="center"
            style={{ marginTop: i !== 0 ? 8 : 0 }}
          >
            <div style={{ width: '20%' }}>{i + 1}</div>
            <PosTextField
              value={t.seat}
              onChange={(e) => handleSeatChange(i, e.target.value.trim())}
            />
          </Stack>
        );
      })}
    </GenericDialog>
  );
};
