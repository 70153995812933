import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SellerUserClient } from 'src/WebApiController';

export const useGetHelpData = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const shouldQueryAvailableData = useMemo(
    () => activeAccountWebClientConfig.activeAccountId != null,
    [activeAccountWebClientConfig.activeAccountId]
  );

  const allAvailableData = useQuery({
    queryKey: ['allAvailableData'],
    queryFn: async () => {
      if (!shouldQueryAvailableData) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          const client = new SellerUserClient(activeAccountWebClientConfig);
          const data = await client.getAllTutorialItems(true);

          return data;
        },
        (error) => {
          showErrorDialog('SellerUserClient.getAllTutorialItems', error, {
            trackErrorData: {
              activeAccountWebClientConfig,
            },
          });
        }
      );
    },
    staleTime: 0, // we will refetch on mount
    refetchOnWindowFocus: false,
    enabled: shouldQueryAvailableData,
  });

  const getDataContent = useCallback(
    (itemId: number) => {
      if (!allAvailableData.data) {
        return null;
      }

      const existingItem = allAvailableData.data.find(
        (i) => i.tutorialItemId === itemId
      );

      if (!existingItem) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const client = new SellerUserClient(activeAccountWebClientConfig);
          const data = await client.getTutorialItemContents([itemId]);

          return data[0];
        },
        (error) => {
          showErrorDialog('SellerUserClient.getTutorialItemContents', error, {
            trackErrorData: { activeAccountWebClientConfig, itemId },
          });
        }
      );
    },
    [activeAccountWebClientConfig, allAvailableData.data, showErrorDialog]
  );

  return {
    isLoading: allAvailableData.isLoading,
    allAvailableKbs: allAvailableData.data,
    refreshData: allAvailableData.refetch,
    getDataContent,
  };
};
