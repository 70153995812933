import { isEqual } from 'lodash-es';
import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import {
  EventAccordionItemProps,
  SaleEventsEmpty,
  SalesEventsAccordion,
  translateEventToSaleAccordionItem,
} from 'src/components/Accordions';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { SetSalesEventAccordionSelectionCountLabel } from 'src/components/Events/EventListing/SalesEventListing/SetSalesEventAccordionSelectionCountLabel';
import { SetSalesFlattenedViewSelectionCountLabel } from 'src/components/Events/EventListing/SalesEventListing/SetSalesFlattenedViewSelectionCountLabel';
import { SalesBulkActionsPermissions } from 'src/components/Sales/BulkActions/SalesBulkActionsPermissions';
import { SalesGlobalBulkActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/SalesGlobalBulkActions';
import { SubfilterGroup } from 'src/components/SubfilterGroup';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import {
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useQuickSubfilters } from 'src/hooks/useQuickSubfilters';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DateRangePresetName,
  getDateRangePresets,
} from 'src/utils/dateTimeUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { SomethingWentWrong } from 'src/views';
import {
  DateTimeRangeWithRelative,
  Sale,
  SaleQuery,
  SalesViewMode,
} from 'src/WebApiController';

import * as styles from '../EventListing.css';
import { SalesFlattenedView } from './SalesFlattenedView';

export function SalesEventListing({
  onItemsRendered,
}: {
  onItemsRendered?: (items: EventAccordionItemProps<Sale>[]) => void;
}) {
  const {
    isLoading,
    errorInfo,
    eventsTransformed,
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();

  const onSalesEventExpanded = useCallback(
    async (viagVirtualId: string) => {
      toggleListItemExpansion([viagVirtualId]);
    },
    [toggleListItemExpansion]
  );
  const { filterQuery, initialQuery, setFilterQuery } =
    useFilterQueryContext<SaleQuery>();

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const quickSubfiltersInit = useMemo(() => {
    const [todayPreset] = getDateRangePresets([DateRangePresetName.Today]);
    const [last7DaysPreset] = getDateRangePresets([
      DateRangePresetName.Last7Days,
    ]);
    const [last30DaysPreset] = getDateRangePresets([
      DateRangePresetName.Last30Days,
    ]);
    return {
      recentlySold: [
        {
          id: 'soldToday',
          name: <Content id={ContentId.Today} />,
          data: {
            ...initialQuery,
            eventTimeFrameFilter: null,
            saleDates: {
              start: todayPreset.range.start.toISOString(),
              end: todayPreset.range.end.toISOString(),
            } as DateTimeRangeWithRelative,
          },
        },
        {
          id: 'soldLast7Days',
          name: <Content id={ContentId.LastWeek} />,
          data: {
            ...initialQuery,
            eventTimeFrameFilter: null,
            saleDates: {
              start: last7DaysPreset.range.start.toISOString(),
              end: last7DaysPreset.range.end.toISOString(),
            } as DateTimeRangeWithRelative,
          },
        },
        {
          id: 'soldLast30Days',
          name: <Content id={ContentId.Last30Days} />,
          data: {
            ...initialQuery,
            eventTimeFrameFilter: null,
            saleDates: {
              start: last30DaysPreset.range.start.toISOString(),
              end: last30DaysPreset.range.end.toISOString(),
            } as DateTimeRangeWithRelative,
          },
        },
      ],
    };
  }, [initialQuery]);
  const { quickSubfilters } = useQuickSubfilters({
    quickSubfilters: quickSubfiltersInit,
    initialQuery,
  });

  function Subfilters() {
    if (quickSubfilters.length <= 0) {
      return null;
    }
    return (
      <SubfilterGroup>
        {quickSubfilters.map(({ id, name, data: query }) => (
          <SubfilterGroup.Item
            key={id}
            name={name}
            isSelected={isEqual(filterQuery, query)}
            onClick={() => {
              setFilterQuery(query);
            }}
          />
        ))}
      </SubfilterGroup>
    );
  }

  const { setAllGroupsIds, clearShiftKeySelection, setSelectionMode } =
    useMultiSelectionContext();

  const salesItems = useMemo(() => {
    return (
      eventsTransformed?.map((e, i) =>
        translateEventToSaleAccordionItem(e, i)
      ) || []
    );
  }, [eventsTransformed]);

  useEffect(() => {
    if (filterQuery.viewMode === SalesViewMode.FlattenedView) {
      setAllGroupsIds([NO_GROUP_ID]);
    } else {
      setAllGroupsIds(salesItems.map((saleItem) => saleItem.itemId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesItems, filterQuery.viewMode]);

  useEffect(() => {
    clearShiftKeySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.viewMode]);

  // Reset and close selection mode when changing view
  useEffect(() => {
    setSelectionMode();
  }, [filterQuery.viewMode, setSelectionMode]);

  return (
    <>
      <Subfilters />
      {isLoading && <PosSpinner />}
      {errorInfo && (
        <SomethingWentWrong
          header={errorInfo.errorHeader}
          message={<Content id={ContentId.FailToLoadListContent} />}
        />
      )}
      {!isLoading &&
        !errorInfo &&
        (eventsTransformed && eventsTransformed.length > 0 ? (
          <div className={styles.eventsListContainer}>
            {filterQuery.viewMode === SalesViewMode.FlattenedView ? (
              <SalesFlattenedView
                topListItemBefore={
                  <Stack
                    gap="m"
                    alignItems="center"
                    justifyContent="spaceBetween"
                    className={styles.bulkToolbarContainer}
                  >
                    <div style={{ flex: 1 }}>
                      <SalesBulkActionsPermissions>
                        <SetSalesFlattenedViewSelectionCountLabel>
                          <MultiSelectActionBar style={{ padding: 0 }}>
                            <SalesGlobalBulkActions />
                          </MultiSelectActionBar>
                        </SetSalesFlattenedViewSelectionCountLabel>
                      </SalesBulkActionsPermissions>
                    </div>
                    <Button
                      variant="textPlain"
                      style={{ paddingRight: vars.spacing.xxs }}
                      onClick={onColumnSettingButtonClickHandler}
                    >
                      <LayoutIcon size={vars.iconSize.m} />
                      <Content id={ContentId.Columns} />
                    </Button>
                  </Stack>
                }
                enableColumnFilters
              />
            ) : (
              <SalesEventsAccordion
                topListItemBefore={
                  <SalesBulkActionsPermissions>
                    <SetSalesEventAccordionSelectionCountLabel>
                      <MultiSelectActionBar>
                        <SalesGlobalBulkActions />
                      </MultiSelectActionBar>
                    </SetSalesEventAccordionSelectionCountLabel>
                  </SalesBulkActionsPermissions>
                }
                disablePagination
                items={salesItems}
                selectedIds={expandedListItems}
                onSelect={onSalesEventExpanded}
                onItemsRendered={onItemsRendered}
                tableActions={
                  <Button
                    variant="textPlain"
                    onClick={onColumnSettingButtonClickHandler}
                  >
                    <LayoutIcon size={vars.iconSize.m} />
                    <Content id={ContentId.Columns} />
                  </Button>
                }
              />
            )}
          </div>
        ) : (
          <SaleEventsEmpty />
        ))}
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomSalesColumn>
          sectionType={
            filterQuery.viewMode === SalesViewMode.FlattenedView
              ? SectionType.SalesFlattened
              : SectionType.Sales
          }
          onClose={toggleColumnModal}
        />
      ) : null}
    </>
  );
}
