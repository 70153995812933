import { isEmpty, isEqual } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TableVirtuoso } from 'react-virtuoso';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { ListingDeliveryTypeDisplay } from 'src/components/Listings/ListingDeliveryTypeDisplay';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOption } from 'src/modals/EditTableColumns';
import { ListingGroupIcon } from 'src/svgs';
import { ShuffleIcon } from 'src/svgs/ShuffleIcon';
import { ResetIcon } from 'src/svgs/Viagogo';
import { randomizeInPlace } from 'src/utils/arrayUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { sortSeating } from 'src/utils/tableUtils';
import { BucketType, EventWithData, GroupingType } from 'src/WebApiController';
import {
  Feature,
  IListingGroupItem,
  Listing,
  ListingGroupItemInput,
  MergeListingGroupInput,
} from 'src/WebApiController';

import * as styles from '../GroupListings.css';
import { GroupingBucketOptions } from './GroupingBucketOptions';
import {
  GroupListingSortingCriteria,
  ListingGroupBy,
  ListingGroupingTypesNotSupportingAdditionalGroupBys,
  MergeListingGroupInputListFields,
} from './groupingTypes';
import {
  flattenListingGroup,
  getAvailableGroupingTypes,
  getListingGroupsMap,
  getQuantitiesOptions,
  quantityMatches,
} from './groupingUtils';
import {
  groupPrioritySort,
  useGroupSortingCriteria,
} from './ListingGroupInput.hook';

/**
 * The comparator function for sorting the grouped listings.
 *
 * We first sort by the admin hold status, then by the priority.
 * And always lock the remaining ticket quantity same to bottom ticket quantity at the bottom.
 *
 * @param idToSortingCriteria
 * @param deprioritizedQuantities
 * @returns the sorting function
 */
const prioritySort =
  (
    idToSortingCriteria: Record<number, GroupListingSortingCriteria>,
    deprioritizedQuantities: string[] | null
  ) =>
  <T extends { listingId: number; priority: number }>(a: T, b: T) => {
    const criteriaA = idToSortingCriteria[a.listingId];
    const criteriaB = idToSortingCriteria[b.listingId];
    if (!isEmpty(deprioritizedQuantities)) {
      const matchesA = quantityMatches(
        criteriaA.availableQuantity,
        deprioritizedQuantities
      );
      const matchesB = quantityMatches(
        criteriaB.availableQuantity,
        deprioritizedQuantities
      );
      if (matchesA || matchesB) {
        if (matchesA) {
          return 1;
        }
        if (matchesB) {
          return -1;
        }
      }
    }
    if (criteriaA.isAdminHold !== criteriaB.isAdminHold) {
      return criteriaA.isAdminHold ? 1 : -1;
    }
    return a.priority - b.priority;
  };

export const ListingTableForGroups = ({
  event,
  eventIndex,
}: {
  event: EventWithData;
  eventIndex: number;
}) => {
  const { watch, setValue, getValues } =
    useFormContext<MergeListingGroupInputListFields>();

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(
    event.event
  );

  const hasBulkActionGroupListingsFeature = useUserHasFeature(
    Feature.BulkActionGroupListings
  );

  const eventListings = useMemo(() => {
    const selectedListingIds = filterQueryWithEventIds.entityIds ?? [];
    if (
      selectedListingIds.length == 0 ||
      event.entities.listings == null ||
      !hasBulkActionGroupListingsFeature
    ) {
      return event.entities.listings;
    }

    return event.entities.listings.filter((l) =>
      selectedListingIds.includes(l.id)
    );
  }, [
    filterQueryWithEventIds.entityIds,
    event.entities.listings,
    hasBulkActionGroupListingsFeature,
  ]);

  const mergeListingGroupInputs = watch('mergeListingGroupInputs');
  const noneStr = useContent(ContentId.None);

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasGroupingDripFeature = useUserHasFeature(Feature.GroupingDrip);
  const hasListingGroupingMethodsFeature = useUserHasFeature(
    Feature.ListingGroupingMethods
  );

  const { getUiCurrency } = useLocalizationContext();
  const firstListing = useMemo(() => {
    if (eventListings != null && eventListings.length > 0) {
      return eventListings[0] ?? undefined;
    }
  }, [eventListings]);
  const currency = getUiCurrency(firstListing?.currency ?? 'USD');

  // Purposely not using useMemo for these eventMergeListingGroupInputs and availableGroups
  // because the useMemo will not detect a change to mergeListingGroupInputs
  const eventMergeListingGroupInputs = mergeListingGroupInputs.filter(
    (m) =>
      event.event.viagVirtualId ===
      (m.viagogoEventId?.toString() ?? m.viagogoMappingId)
  );
  const availableGroups = eventMergeListingGroupInputs.reduce(
    (r, g) => {
      r[g.listingGroupId] = g.name;
      return r;
    },
    { None: noneStr } as Record<string, string>
  );

  // Following the pattern above, we still want to memoize the options to
  // avoid unnecessary re-renders
  const availableGroupsSerialized = useMemo(
    () => JSON.stringify(availableGroups),
    [availableGroups]
  );

  const availableGroupsMemo = useMemo(
    () => availableGroups,
    // useMemo uses referential equality to determine if the value has changed
    // so we need to serialize the object to a string to ensure that it is
    // the same object

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableGroupsSerialized]
  );

  const groupBys = watch(`eventSettings.${eventIndex}.groupBys`);
  const desiredActiveListings = watch(
    `eventSettings.${eventIndex}.desiredActiveListings`
  );
  const deprioritizedQuantities = watch(
    `eventSettings.${eventIndex}.deprioritizedQuantities`
  );
  const templateUndercutAbsoluteAmount = watch(
    'templateSettings.templateRankPremiums.undercutAbsoluteAmount'
  );
  const templateUndercutRelativeAmount = watch(
    'templateSettings.templateRankPremiums.undercutRelativeAmount'
  );
  const templateMinActiveListings = watch('templateSettings.minActiveListings');
  const templateMaxActiveListings = watch('templateSettings.maxActiveListings');
  const templateTargetPercentage = watch('templateSettings.targetPercentage');

  const contentContext = useContentContext();

  const newGroupName = useContent(ContentId.NewGroup);

  // This is used only for ordering of the grouped listings
  const idToSortingCriteria = useGroupSortingCriteria(eventListings ?? []);

  const onSortingListing = useCallback(
    (
      groupIndex: number,
      overrideQuantities: string[] | null,
      additionalInput?: ListingGroupItemInput,
      listingIdsToRemove?: Set<number> | null
    ) => {
      if (groupIndex >= mergeListingGroupInputs.length) {
        return;
      }
      const group = mergeListingGroupInputs[groupIndex];

      // Sort based on the group level deprioritizedQuantities default to event level value
      const quantities = overrideQuantities ?? deprioritizedQuantities;
      const updatedListingGroupItems = group.listingGroupItems
        .filter((gl) => !listingIdsToRemove?.has(gl.listingId))
        .concat(additionalInput || [])
        .map((gl, i) => ({ ...gl, priority: i + 1 }))
        .sort(prioritySort(idToSortingCriteria, quantities))
        .map((gl, i) => ({ ...gl, priority: i + 1 }));

      setValue(
        `mergeListingGroupInputs.${groupIndex}.listingGroupItems`,
        updatedListingGroupItems
      );
    },
    [
      mergeListingGroupInputs,
      deprioritizedQuantities,
      idToSortingCriteria,
      setValue,
    ]
  );

  // Sort the group listings on add the new listing to the group
  const onListingIdsSelected = useCallback(
    (listing: Listing, newGroupId: string | null) => {
      const allSelectedListings = flattenListingGroup(listing);

      allSelectedListings.forEach((l) => {
        const oldGroupIndex = mergeListingGroupInputs.findIndex((g) =>
          g.listingGroupItems.some((gl) => gl.listingId === l.id)
        );

        if (oldGroupIndex >= 0) {
          const overrideQuantities = getValues(
            `mergeListingGroupInputs.${oldGroupIndex}.deprioritizedQuantities`
          );

          const listingIdsToRemove = new Set(
            allSelectedListings.map((l) => l.id)
          );

          onSortingListing(
            oldGroupIndex,
            overrideQuantities,
            undefined,
            listingIdsToRemove
          );
        }

        const groupIndex = mergeListingGroupInputs.findIndex(
          (g) => g.listingGroupId === newGroupId
        );
        if (groupIndex >= 0) {
          const overrideQuantities = getValues(
            `mergeListingGroupInputs.${groupIndex}.deprioritizedQuantities`
          );
          onSortingListing(groupIndex, overrideQuantities, {
            listingId: l.id,
            priority: -1,
            groupId: l.ltGrpId,
            undercutSetting: null,
          });
        }
      });
    },
    [getValues, mergeListingGroupInputs, onSortingListing]
  );

  const { venueMapInfo, activeConfigOverride } = useEventMapContext();

  const rowIdToRowOrdinalMap = useMemo(() => {
    return venueMapInfo?.sections.reduce(
      (acc, section) => {
        section.rows.forEach((row) => {
          if (row.id != null && row.ordinal != null) {
            acc[row.id] = row.ordinal;
          }
        });
        return acc;
      },
      {} as Record<number, number>
    );
  }, [venueMapInfo]);

  // In order to be able to cluster by rows, we need to have the row ordinals available for all listings
  const canClusterByRows = useMemo(() => {
    if (!eventListings?.length || rowIdToRowOrdinalMap == null) {
      return false;
    }

    const listingsForGrouping = eventListings.flatMap(flattenListingGroup);
    return listingsForGrouping.every((l) => {
      const rowOrdinal =
        rowIdToRowOrdinalMap !== undefined && l.seating?.rowId != null
          ? (rowIdToRowOrdinalMap[l.seating?.rowId] ?? undefined)
          : undefined;

      return rowOrdinal !== undefined;
    });
  }, [eventListings, rowIdToRowOrdinalMap]);

  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const getGroupedListings = useCallback(
    (groupBys: ListingGroupBy[]) => {
      if (!eventListings?.length) {
        return [];
      }

      const listingsForGrouping = eventListings.flatMap(flattenListingGroup);

      const listingGroupsMap = getListingGroupsMap(
        groupBys,
        contentContext,
        listingsForGrouping,
        scoreOverrides,
        venueMapInfo?.sections,
        rowIdToRowOrdinalMap,
        canClusterByRows
      );

      return !listingGroupsMap
        ? []
        : Object.values(listingGroupsMap)
            .filter((group) => group.data.length)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => {
              const mappedListings = group.data
                .map((l, i) => ({
                  // listingId: number
                  listingId: l.id,
                  // priority: number
                  priority: i + 1,
                  groupId: (l as IListingGroupItem).ltGrpId,
                }))
                .sort(
                  prioritySort(idToSortingCriteria, deprioritizedQuantities)
                )
                .map((l, i) => ({
                  listingId: l.listingId,
                  priority: i + 1,
                  groupId: l.groupId,
                }));

              const groupingMethods = groupBys.map((groupBy, index) => {
                return {
                  groupingOrder: index,
                  groupingType: groupBy.groupingType,
                  groupingValue: group.values?.[index] ?? null,
                  numberOfBucket: groupBy.numOfClusters ?? null,
                  bucketType: groupBy.bucketOption ?? null,
                };
              });

              return {
                groupingName: group.name,
                mappedListings,
                viagogoEventId: event.event.viagId,
                viagogoMappingId: event.event.mappingId,
                groupingMethods: hasListingGroupingMethodsFeature
                  ? {
                      groupingMethods,
                    }
                  : null,
              };
            });
    },
    [
      eventListings,
      event.event.viagId,
      event.event.mappingId,
      contentContext,
      scoreOverrides,
      venueMapInfo?.sections,
      rowIdToRowOrdinalMap,
      canClusterByRows,
      idToSortingCriteria,
      deprioritizedQuantities,
      hasListingGroupingMethodsFeature,
    ]
  );

  const updateGroupedListings = useCallback(
    (localGroupBys: ListingGroupBy[]) => {
      if (!localGroupBys?.length || isEqual(localGroupBys, groupBys)) {
        return;
      }

      setValue(`eventSettings.${eventIndex}.groupBys`, [...localGroupBys]);
      const deprioritizedQuantities = getValues(
        `eventSettings.${eventIndex}.deprioritizedQuantities`
      );

      switch (localGroupBys[0].groupingType) {
        case GroupingType.Custom:
          if (eventMergeListingGroupInputs.length === 0) {
            setValue('mergeListingGroupInputs', [
              ...mergeListingGroupInputs,
              {
                listingGroupId: newGuidId(),
                name: `${newGroupName} 1`,
                listingGroupItems: [],
                desiredActiveListings: 0,
                minActiveListings: 0,
                maxActiveListings: 0,
                targetPercentage: 0,
                deprioritizedQuantities,
                viagogoEventId: event.event.viagId,
                viagogoMappingId: event.event.mappingId,
                marketplaceSettings: null,
                groupUndercutSetting: {
                  undAbsAmt: null,
                  undRelAmt: null,
                  actRankUndAbsAmt: null,
                  actRankUndRelAmt: null,
                },
                groupingMethods: null,
              } as MergeListingGroupInput,
            ]);
          }

          break;
        default:
          {
            const nonEventGroupInputs = mergeListingGroupInputs.filter(
              (m) =>
                event.event.viagVirtualId !==
                (m.viagogoEventId?.toString() ?? m.viagogoMappingId)
            );
            const groups = getGroupedListings(localGroupBys);
            setValue('mergeListingGroupInputs', [
              ...nonEventGroupInputs,
              ...groups.map(
                ({
                  groupingName,
                  mappedListings,
                  viagogoEventId,
                  viagogoMappingId,
                  groupingMethods,
                }) =>
                  ({
                    listingGroupId: newGuidId(),
                    name: groupingName,
                    listingGroupItems: mappedListings,
                    desiredActiveListings: 0,
                    deprioritizedQuantities,
                    marketplaceSettings: null,
                    viagogoEventId,
                    viagogoMappingId,
                    groupUndercutSetting: {
                      undAbsAmt: null,
                      undRelAmt: null,
                      actRankUndAbsAmt: null,
                      actRankUndRelAmt: null,
                    },
                    groupingMethods,
                  }) as MergeListingGroupInput
              ),
            ]);
          }
          break;
      }
    },
    [
      event.event.mappingId,
      event.event.viagId,
      event.event.viagVirtualId,
      eventIndex,
      eventMergeListingGroupInputs.length,
      getGroupedListings,
      getValues,
      groupBys,
      mergeListingGroupInputs,
      newGroupName,
      setValue,
    ]
  );

  const onGroupingTypeChange = useCallback(
    (
      groupByIndex: number,
      newGroupType: GroupingType,
      newNumOfClusters?: number,
      newBucketOption?: BucketType
    ) => {
      const newGroupBys = groupBys.map((gb) => ({ ...gb }));
      if (groupByIndex >= groupBys.length) {
        newGroupBys.push({
          groupingType: newGroupType,
          numOfClusters: newNumOfClusters,
          bucketOption: newBucketOption,
        } as ListingGroupBy);
      } else {
        newGroupBys[groupByIndex].groupingType = newGroupType;
        newGroupBys[groupByIndex].numOfClusters = newNumOfClusters;
        newGroupBys[groupByIndex].bucketOption = newBucketOption;
      }

      updateGroupedListings(newGroupBys);
    },
    [groupBys, updateGroupedListings]
  );

  const onRandomizeRanks = useCallback(() => {
    const newMlgis = mergeListingGroupInputs.map((lg) => {
      randomizeInPlace(lg.listingGroupItems); // randomize the order and set the new order as the new priority

      const sortedListings = lg.listingGroupItems
        .sort(prioritySort(idToSortingCriteria, deprioritizedQuantities))
        .map((l, i) => ({ ...l, priority: i + 1 }));

      return { ...lg, listingGroupItems: sortedListings };
    });
    setValue('mergeListingGroupInputs', newMlgis);
  }, [
    deprioritizedQuantities,
    idToSortingCriteria,
    mergeListingGroupInputs,
    setValue,
  ]);

  const onResetAllGroups = useCallback(() => {
    const otherGroups = mergeListingGroupInputs.filter(
      (m) =>
        event.event.viagVirtualId !==
        (m.viagogoEventId?.toString() ?? m.viagogoMappingId)
    );
    setValue('mergeListingGroupInputs', otherGroups);
    setValue(`eventSettings.${eventIndex}`, {
      groupBys: [
        {
          groupingType: GroupingType.Custom,
        },
        {
          groupingType: GroupingType.None,
        },
      ],
      desiredActiveListings: 0,
      minActiveListings: 0,
      maxActiveListings: 0,
      targetPercentage: 0,
      deprioritizedQuantities: [],
    });
  }, [
    event.event.viagVirtualId,
    eventIndex,
    mergeListingGroupInputs,
    setValue,
  ]);

  const renderHeaderContent = useCallback(() => {
    return (
      <SimpleTable.Tr style={{ backgroundColor: vars.color.backgroundPrimary }}>
        <SimpleTable.Th
          className={styles.tableCell}
          style={{ width: '0%' }} // this makes this column width fit-content
        >
          <Content id={ContentId.Group} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.Seating} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.Type} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.Remaining} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.Original} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.UnitCost} />
        </SimpleTable.Th>
      </SimpleTable.Tr>
    );
  }, []);

  const renderGroupDripTemplate = useCallback(() => {
    if (!hasGroupingDripFeature) {
      return null;
    }

    return (
      <Stack direction="row" gap="l" alignItems="center">
        <PosFormField
          label={<Content id={ContentId.MinActiveListings} />}
          style={{ width: '155px' }}
        >
          <PosTextField
            value={templateMinActiveListings ?? '0'}
            type="number"
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const v = parseInt(e.target.value);
              if (!isNaN(v)) {
                setValue('templateSettings.minActiveListings', v);

                if (v > (templateMaxActiveListings ?? 0)) {
                  setValue('templateSettings.maxActiveListings', v);
                }

                let hasChanged = false;
                mergeListingGroupInputs.forEach((t) => {
                  if (t.minActiveListings !== v) {
                    t.minActiveListings = v;
                    hasChanged = true;
                  }

                  if (
                    v > (templateMaxActiveListings ?? 0) &&
                    t.maxActiveListings !== v
                  ) {
                    t.maxActiveListings = v;
                    hasChanged = true;
                  }
                });
                if (hasChanged) {
                  setValue('mergeListingGroupInputs', [
                    ...mergeListingGroupInputs,
                  ]);
                }
              }
            }}
          />
        </PosFormField>
        <PosFormField
          label={<Content id={ContentId.MaxActiveListings} />}
          style={{ width: '155px' }}
        >
          <PosTextField
            value={templateMaxActiveListings ?? '0'}
            type="number"
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const v = parseInt(e.target.value);
              if (!isNaN(v)) {
                setValue('templateSettings.maxActiveListings', v);

                if (v < (templateMinActiveListings ?? 0)) {
                  setValue('templateSettings.minActiveListings', v);
                }

                let hasChanged = false;
                mergeListingGroupInputs.forEach((t) => {
                  if (
                    v < (templateMinActiveListings ?? 0) &&
                    t.minActiveListings !== v
                  ) {
                    t.minActiveListings = v;
                    hasChanged = true;
                  }

                  if (t.maxActiveListings !== v) {
                    t.maxActiveListings = v;
                    hasChanged = true;
                  }
                });

                if (hasChanged) {
                  setValue('mergeListingGroupInputs', [
                    ...mergeListingGroupInputs,
                  ]);
                }
              }
            }}
          />
        </PosFormField>
        <PosFormField
          label={<Content id={ContentId.TargetPercentage} />}
          style={{ flex: '0.5' }}
        >
          <PosTextField
            rootProps={{
              style: { width: '100px' },
            }}
            type="number"
            postfixDisplay="%"
            value={
              templateTargetPercentage != null
                ? roundToPrecision(Math.abs(templateTargetPercentage) * 100, 8)
                : ''
            }
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              let target = v;
              if (v >= 0 && v <= Number.MAX_VALUE) {
                target = Math.min(v, 100) / 100;
              }

              setValue('templateSettings.targetPercentage', target);

              let hasChanged = false;
              mergeListingGroupInputs.forEach((t) => {
                if (t.targetPercentage !== target) {
                  t.targetPercentage = target;
                  hasChanged = true;
                }
              });

              if (hasChanged) {
                setValue('mergeListingGroupInputs', [
                  ...mergeListingGroupInputs,
                ]);
              }
            }}
          />
        </PosFormField>
      </Stack>
    );
  }, [
    hasGroupingDripFeature,
    mergeListingGroupInputs,
    setValue,
    templateMaxActiveListings,
    templateMinActiveListings,
    templateTargetPercentage,
  ]);

  const renderRowContent = useCallback(
    (l: Listing, ltGrpId: string | undefined) => {
      return (
        <>
          <SimpleTable.Td
            className={styles.tableCell}
            style={{ width: '0%' }} // this makes this column width fit-content
          >
            <PosSelect
              value={ltGrpId || 'None'}
              size="md"
              style={{ width: '100%' }}
              contentProps={{ align: 'end' }}
              placeholderText={ContentId.Select}
              onChange={(newGroupId: string | null) => {
                onListingIdsSelected(l, newGroupId);
              }}
              valueOptionsContent={availableGroupsMemo}
            />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            <Stack direction="row" gap="s">
              {l.isLtGrp && (
                <ListingGroupIcon
                  stroke={vars.color.textBrand}
                  size={vars.iconSize.m}
                />
              )}
              <SeatingInfo
                {...l.seating}
                showAdminHold={true}
                isAdminHold={l.isAdminHold}
              />
            </Stack>
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            <ListingDeliveryTypeDisplay
              listing={l}
              userDefinedPrecision={FormatOption.EnumDisplay_Icon}
            />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {l.availQty}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {l.ticketCnt}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {l.unitCst?.disp ?? l.faceValue?.disp}
          </SimpleTable.Td>
        </>
      );
    },
    [availableGroupsMemo, onListingIdsSelected]
  );

  const eventListingsData = useMemo(
    () => eventListings?.sort((l1, l2) => sortSeating(l1.seating, l2.seating)),
    [eventListings]
  );

  return (
    <>
      <Stack direction="row" gap="l" justifyContent="spaceBetween">
        <Stack direction="column" gap="l">
          {groupBys
            .filter((_, i) =>
              i == 0
                ? true // If index 0, any is ok
                : !ListingGroupingTypesNotSupportingAdditionalGroupBys.includes(
                    // else require first one to be Custom or None
                    groupBys[0].groupingType
                  )
            )
            .map((gb, i) => (
              <Stack
                direction="row"
                gap="xl"
                key={gb.groupingType}
                justifyContent="spaceBetween"
              >
                <PosFormField
                  label={
                    <Content
                      id={
                        i === 0 ? ContentId.GroupingDefault : ContentId.ThenBy
                      }
                    />
                  }
                  style={{ width: 'max-content' }}
                >
                  <PosEnumSelect
                    disabled={!eventListings?.length}
                    value={gb.groupingType}
                    defaultValue={GroupingType.Custom}
                    onChange={(v) =>
                      v && onGroupingTypeChange(i, v, gb.numOfClusters)
                    }
                    valueOptionsContent={getAvailableGroupingTypes(
                      i === 0
                        ? []
                        : groupBys
                            .map((gb) => gb.groupingType)
                            .filter((_, prevI) => prevI < i)
                    )}
                  />
                </PosFormField>
                <GroupingBucketOptions
                  index={i}
                  groupBy={gb}
                  canClusterByRows={canClusterByRows}
                  onGroupingTypeChange={onGroupingTypeChange}
                />
              </Stack>
            ))}
        </Stack>
        {hasAutoPricingFeature ? (
          <Stack direction="row" gap="l">
            <PosFormField
              label={<Content id={ContentId.RankPremium} />}
              style={{ flex: '0.5' }}
            >
              <PosCurrencyField
                rootProps={{
                  style: { width: '100px' },
                }}
                uiCurrency={currency}
                value={templateUndercutAbsoluteAmount ?? undefined}
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    setValue(
                      `templateSettings.templateRankPremiums.undercutAbsoluteAmount`,
                      v
                    );

                    let hasChanged = false;
                    mergeListingGroupInputs.forEach((t) => {
                      if (
                        t.groupUndercutSetting != null &&
                        t.groupUndercutSetting.undAbsAmt !== v
                      ) {
                        t.groupUndercutSetting.undAbsAmt = v;
                        hasChanged = true;
                      }
                    });

                    if (hasChanged) {
                      setValue('mergeListingGroupInputs', [
                        ...mergeListingGroupInputs,
                      ]);
                    }
                  }
                }}
              />

              <PosTextField
                rootProps={{
                  style: { width: '100px' },
                }}
                type="number"
                postfixDisplay="%"
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    const relativeValue = Math.min(v, 100) / 100;
                    setValue(
                      `templateSettings.templateRankPremiums.undercutRelativeAmount`,
                      relativeValue
                    );

                    let hasChanged = false;
                    mergeListingGroupInputs.forEach((t) => {
                      if (
                        t.groupUndercutSetting != null &&
                        t.groupUndercutSetting.undRelAmt !== relativeValue
                      ) {
                        t.groupUndercutSetting.undRelAmt = relativeValue;
                        hasChanged = true;
                      }
                    });

                    if (hasChanged) {
                      setValue('mergeListingGroupInputs', [
                        ...mergeListingGroupInputs,
                      ]);
                    }
                  }
                }}
                value={
                  templateUndercutRelativeAmount != null
                    ? roundToPrecision(
                        Math.abs(templateUndercutRelativeAmount) * 100,
                        8
                      )
                    : ''
                }
              />
            </PosFormField>
            {hasGroupingDripFeature == false && (
              <PosFormField
                label={<Content id={ContentId.DesiredActiveListings} />}
                style={{ width: 'fit-content' }}
              >
                <PosTextField
                  value={desiredActiveListings ?? '0'}
                  type="number"
                  min={0}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const v = parseInt(value);
                    if (v >= 0) {
                      if (v !== desiredActiveListings) {
                        setValue(
                          `eventSettings.${eventIndex}.desiredActiveListings`,
                          v
                        );
                      }

                      let hasChanged = false;
                      mergeListingGroupInputs.forEach((t) => {
                        if (t.desiredActiveListings !== v) {
                          t.desiredActiveListings = v;
                          hasChanged = true;
                        }
                      });

                      if (hasChanged) {
                        setValue('mergeListingGroupInputs', [
                          ...mergeListingGroupInputs,
                        ]);
                      }
                    }
                  }}
                />
              </PosFormField>
            )}
            <PosFormField
              label={<Content id={ContentId.QuantitiesForcedToBottom} />}
              style={{ width: 'fit-content' }}
            >
              <PosMultiSelect
                align="start"
                triggerProps={{ style: { width: '100%' } }}
                values={deprioritizedQuantities ?? []}
                onChange={(newValues) => {
                  if (!isEqual(newValues, deprioritizedQuantities)) {
                    setValue(
                      `eventSettings.${eventIndex}.deprioritizedQuantities`,
                      newValues
                    );
                  }
                  mergeListingGroupInputs.forEach((t, index) => {
                    if (!isEqual(t.deprioritizedQuantities, newValues)) {
                      t.deprioritizedQuantities = newValues;
                      const sortedListings = (t.listingGroupItems || [])
                        .filter((l) => l.listingId > 0)
                        .map((l, i) => ({ ...l, priority: i + 1 }))
                        .sort(groupPrioritySort(idToSortingCriteria, newValues))
                        .map((l, i) => ({ ...l, priority: i + 1 }));
                      t.listingGroupItems = [...sortedListings];

                      setValue(`mergeListingGroupInputs.${index}`, {
                        ...t,
                        deprioritizedQuantities: newValues,
                        listingGroupItems: sortedListings,
                      });
                    }
                  });
                }}
                valueOptionsContent={getQuantitiesOptions()}
              />
            </PosFormField>
            <PosFormField
              label={<Content id={ContentId.Actions} />}
              style={{ width: 'fit-content' }}
            >
              <Stack gap="m" alignItems="center" style={{ height: 40 }}>
                <ShuffleIcon
                  onClick={onRandomizeRanks}
                  title={getContent(ContentId.ShuffleRanks, contentContext)}
                  fill={vars.color.textPrimary}
                  withHoverEffect
                />
                <ResetIcon
                  onClick={onResetAllGroups}
                  title={getContent(ContentId.ResetAll, contentContext)}
                  fill={vars.color.textPrimary}
                  withHoverEffect
                />
              </Stack>
            </PosFormField>
          </Stack>
        ) : null}
      </Stack>
      {renderGroupDripTemplate()}
      <div className={styles.tableContainerVirtuoso}>
        {!eventListingsData ? (
          <PosSpinner />
        ) : (
          <TableVirtuoso
            data={eventListingsData}
            style={{ width: '100%', height: '100%' }}
            overscan={{ main: 500, reverse: 500 }}
            components={{
              Table: SimpleTable.Table,
              TableHead: SimpleTable.Thead,
              TableBody: SimpleTable.Tbody,
              TableRow: SimpleTable.Tr,
            }}
            fixedHeaderContent={renderHeaderContent}
            itemContent={(index, l) => {
              const flattendListings = flattenListingGroup(l).map(
                (fl) => fl.id
              );
              const ltGrpId = mergeListingGroupInputs.find((m) =>
                Boolean(
                  m.listingGroupItems.some((ml) =>
                    flattendListings.includes(ml.listingId)
                  )
                )
              )?.listingGroupId;

              return renderRowContent(l, ltGrpId);
            }}
          />
        )}
      </div>
    </>
  );
};
