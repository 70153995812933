import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { Feature, SaleInput } from 'src/WebApiController';

interface AutoSourceTicketsProps {
  isSeatSaver?: boolean | null;
}

export const AutoSourceTickets = ({ isSeatSaver }: AutoSourceTicketsProps) => {
  const { watch, setValue } = useFormContext<SaleInput>();
  const disableAutoSourcing = watch('disableAutoSourcing');

  const allowDisableAutoSourcing = useUserHasFeature(
    Feature.DisableAutoSourcing
  );

  if (!isSeatSaver || !allowDisableAutoSourcing) {
    return null;
  }

  return (
    <DetailGroup>
      <Detail
        label={<Content id={ContentId.AutoSourceTickets} />}
        detail={
          <Stack>
            <Switch
              checked={disableAutoSourcing?.value ?? false}
              onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
              onChange={(e) => {
                // by default, AutoSource Tickets is set to true
                // if not checked, seller wants to disable auto sourcing.
                const isChecked = e.currentTarget.checked;
                setValue('disableAutoSourcing', posChangedField(isChecked), {
                  shouldDirty: true,
                });
              }}
            />
          </Stack>
        }
      />
    </DetailGroup>
  );
};
