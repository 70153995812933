import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { SortableMetricsOptionsListItem } from './MetricsOptionsListItem';
import * as styles from './ReportsTableEditor.css';

export const MetricsInputDroppable = ({
  id,
  placeholderContentId,
  valueOptionsContent,
  selectedValues,
  disabled,
}: {
  id: string;
  placeholderContentId?: ContentId;
  valueOptionsContent: Record<string, ContentId>;
  disabled?: boolean;
  selectedValues: string[];
}) => {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <SortableContext items={selectedValues}>
      <Stack
        direction="column"
        height="full"
        width="full"
        ref={setNodeRef}
        style={{ overflowY: 'auto' }}
      >
        <Stack direction="column" height="full" width="full" gap="s">
          {selectedValues.map((value) => (
            <SortableMetricsOptionsListItem
              key={value}
              id={value}
              disabled={disabled}
            >
              <Content id={valueOptionsContent[value]} />
            </SortableMetricsOptionsListItem>
          ))}
          {placeholderContentId && (
            <Stack
              style={{ opacity: isOver ? 0.5 : 1 }}
              className={styles.droppablePlaceholder}
            >
              <span>
                <Content id={placeholderContentId} />
              </span>
            </Stack>
          )}
        </Stack>
      </Stack>
    </SortableContext>
  );
};
