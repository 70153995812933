import { Path } from '@remix-run/router/history';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRouteConfigs, MenuItems } from 'src/app/AppRoutes';
import { MainRoute } from 'src/navigations/Routes/MainRoute';

import { EventIdQueryParam } from './constants/constants';

const QUERIES_TO_KEEP_FOR_DEEPLINK = [EventIdQueryParam];

export const getDeepLinkUrlPart = (
  queryParamName: string,
  id?: string | number
): [string, string] | undefined => {
  return id ? [queryParamName, id.toString()] : undefined;
};

export const getDeepLinkIdFromUrl = (queryParamName: string, url?: string) => {
  if (url) {
    const urlObj = new URL(url);
    const queryParam = urlObj.searchParams.get(queryParamName);
    if (queryParam) {
      return queryParam;
    }
  }

  return '';
};

/**
 *
 * @param deepLinkKey
 * @param deepLinkValue
 * @param navigate react-router-dom navigate function. Used to update useSearchParams values
 * * when needed.
 */
export const addDeepLinkUrlPart = (
  deepLinkKey: string,
  deepLinkValue: string,
  navigate?: (fullPathname: string) => void
) => {
  const url = new URL(window.location.href);
  // If we have a deep link for this modal, and the url currently doesn't already include it,
  if (
    deepLinkKey &&
    deepLinkValue &&
    url.searchParams.get(deepLinkKey) !== deepLinkValue
  ) {
    url.searchParams.set(deepLinkKey, deepLinkValue);
    if (navigate) {
      navigate(url.href.substring(location.origin.length));
    } else {
      window.history.pushState('', '', url.toString());
    }
  }
};

export const getUrlWithDeepLink = (deepLinkKey: string) => {
  const url = new URL(window.location.href);
  if (deepLinkKey) {
    const deepLinkValue = url.searchParams.get(deepLinkKey);
    const queriesToKeep: [string, string][] = [];
    QUERIES_TO_KEEP_FOR_DEEPLINK.forEach((k) => {
      const val = url.searchParams.get(k);
      if (val) {
        queriesToKeep.push([k, val]);
      }
    });
    if (deepLinkValue) {
      //Get href without Query Params
      url.search = '';
      url.searchParams.set(deepLinkKey, deepLinkValue);
      queriesToKeep.forEach(([k, v]) => {
        url.searchParams.set(k, v);
      });
    }
  }
  return url;
};

export const useRemoveDeepLinkUrlPart = () => {
  const navigate = useNavigate();

  return useCallback(
    (deepLinkKey: string) => {
      if (deepLinkKey) {
        const url = new URL(window.location.href);
        url.searchParams.delete(deepLinkKey);
        const path: Path = {
          hash: url.hash,
          search: url.search,
          pathname: url.pathname,
        };
        navigate(path);
      }
    },
    [navigate]
  );
};

export const removeDeepLinkUrlPartsFromRelativeUrl = (
  relativeUrl: string,
  deepLinkKeys: string[]
) => {
  if (relativeUrl && deepLinkKeys.length) {
    const url = new URL(
      window.location.protocol + '//' + window.location.host + relativeUrl
    );
    deepLinkKeys.forEach((key) => {
      url.searchParams.delete(key);
    });
    return url.pathname + url.search + url.hash;
  }

  return undefined;
};

export const removeDeepLinkUrlPartFromRelativeUrl = (
  relativeUrl?: string,
  deepLinkKey?: string
) => {
  if (relativeUrl && deepLinkKey) {
    const url = new URL(
      window.location.protocol + '//' + window.location.host + relativeUrl
    );
    url.searchParams.delete(deepLinkKey);
    return url.pathname + url.search + url.hash;
  }

  return undefined;
};

export const getPathFromMainRoute = (mainRoute: MainRoute) => {
  return Object.values(AppRouteConfigs).find((r) => r.view === mainRoute)!
    .path!;
};

export const getFullUrl = (
  mainRoute: MainRoute,
  deepLinkKey?: string,
  deepLinkValue?: string | number
) => {
  let relativeUrl = getPathFromMainRoute(mainRoute);
  relativeUrl =
    relativeUrl?.length > 0 && relativeUrl[0] !== '/'
      ? '/' + relativeUrl
      : relativeUrl;

  const returnUrl = new URL(
    window.location.protocol + '//' + window.location.host + relativeUrl
  );

  if (deepLinkKey && deepLinkValue) {
    returnUrl.searchParams.set(deepLinkKey, deepLinkValue.toString());
  }

  return returnUrl;
};

export const getCurrentViewPath = (route: MainRoute) => {
  let result = Object.values(AppRouteConfigs).find((r) => r.view === route);
  if (result) {
    return result.path!;
  }

  result = Object.values(MenuItems).find((r) => r.view === route);
  if (result) {
    return result.path!;
  }

  return null;
};

export const navigateToMainRoute = (
  mainRouteAccess: MainRoute,
  navigate: (url: string) => void
) => {
  const url = getCurrentViewPath(mainRouteAccess);
  if (url) {
    if (url.startsWith('https://')) {
      // to be safe we should only redirect to full HTTPS
      window.location.assign(url);
    } else {
      navigate(url);
    }
  }
};

// Check if the current page is in full event view
export const isInFullPageEventView = (mainRoute: MainRoute) => {
  return (
    mainRoute === MainRoute.SalesEvent ||
    mainRoute === MainRoute.PurchasesEvent ||
    mainRoute === MainRoute.InventoryEvent
  );
};
