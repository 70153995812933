import { useMemo } from 'react';
import { PerformerCategoryIcon } from 'src/components/common/PerformerCategoryIcon';
import { SummaryTile } from 'src/components/common/SummaryTile';
import {
  AverageTicketPriceDisplay,
  SalesAndMarginDisplay,
  TicketsListedAndSoldDisplay,
} from 'src/components/Listings/InventorySummaryWidgets';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { groupAndAggregateMetrics } from 'src/contexts/CatalogMetricsContext/CatalogMetrics.utils';
import { useGeSummaryMetricsQuery } from 'src/contexts/CatalogMetricsContext/useGetSummaryMetricsQuery';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { hideMobile } from 'src/core/themes/shared.css';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getCatalogSummaryMetrics } from 'src/navigations/Routes/Inventory';
import { ContentId } from 'src/utils/constants/contentId';
import {
  addListingMetrics,
  getInventorySummaryListingMetricsDisplayStrings,
} from 'src/utils/ticketMetricUtils';
import {
  Feature,
  ListingMetrics,
  ListingQuery,
  Performer,
} from 'src/WebApiController';

import * as styles from './InventoryEventListing.css';

const mergeCatalogMetrics = (metrics: ListingMetrics[]) =>
  groupAndAggregateMetrics(metrics, addListingMetrics);

export const InventoryEventSummaryMetrics = () => {
  const { data } = useCatalogDataContext();
  const hasBlockInventorySummaryStatsFeature = useUserHasFeature(
    Feature.BlockInventorySummaryStats
  );

  const { filterQuery } = useFilterQueryContext<ListingQuery>();
  const { loginContext } = useAppContext();

  const { summaryMetrics } = useGeSummaryMetricsQuery<ListingMetrics>(
    'ListingMetrics',
    filterQuery.performerIds,
    filterQuery.venueIds,
    mergeCatalogMetrics,
    getCatalogSummaryMetrics
  );

  const currencyCode = loginContext?.user?.activeAccount?.currencyCode;
  const formattedTotalMetrics = useMemo(() => {
    return summaryMetrics
      ? getInventorySummaryListingMetricsDisplayStrings(
          summaryMetrics,
          currencyCode
        )
      : null;
  }, [currencyCode, summaryMetrics]);

  const selectedEntity = (() => {
    const { performerIds, venueIds } = filterQuery;
    const selectedPerformersCount = performerIds?.length ?? 0;
    const selectedVenuesCount = venueIds?.length ?? 0;
    // return if only 1 entity is selected
    if (selectedPerformersCount + selectedVenuesCount === 1) {
      if (performerIds?.length === 1) {
        return data?.performers[performerIds[0]];
      }
      if (venueIds?.length === 1) {
        return data?.venues[venueIds[0]];
      }
    }
  })();

  if (hasBlockInventorySummaryStatsFeature) {
    return null;
  }

  return (
    <div className={`${styles.tilesSection} ${hideMobile}`}>
      <SummaryTile.Root>
        <SummaryTile.Body>
          <SummaryTile.Title>
            {selectedEntity?.name ?? <Content id={ContentId.Summary} />}
            {selectedEntity && (
              <SummaryTile.Description>
                {(selectedEntity as Performer).categ && (
                  <>
                    <PerformerCategoryIcon
                      performer={selectedEntity as Performer}
                    />
                    &nbsp;
                  </>
                )}
                {selectedEntity?.descr}
              </SummaryTile.Description>
            )}
          </SummaryTile.Title>
          <SummaryTile.MetricGroup>
            <SummaryTile.Metric
              name={<Content id={ContentId.TotalValueListed} />}
              showLoadingPlaceholders={!formattedTotalMetrics}
            >
              {formattedTotalMetrics?.formattedTotalListPrice}
            </SummaryTile.Metric>
          </SummaryTile.MetricGroup>
        </SummaryTile.Body>
      </SummaryTile.Root>
      <Stack gap="xl" flexWrap="wrap">
        <TicketsListedAndSoldDisplay
          formattedTotalMetrics={formattedTotalMetrics}
        />
        <SalesAndMarginDisplay formattedTotalMetrics={formattedTotalMetrics} />
        <AverageTicketPriceDisplay
          formattedTotalMetrics={formattedTotalMetrics}
        />
      </Stack>
    </div>
  );
};
