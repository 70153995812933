import { ReactNode, useEffect, useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData } from 'src/WebApiController';

interface SetInventoryMetricsSelectionCountLabelProps {
  children: ReactNode;
  events: EventWithData[];
}

export const SetInventoryMetricsSelectionCountLabel = ({
  children,
  events,
}: SetInventoryMetricsSelectionCountLabelProps) => {
  const {
    itemSelection,
    selectionMode,
    totalItemsSelected,
    setSelectedCountLabel,
    setUsingCustomSelectedCountLabel,
  } = useMultiSelectionContext();
  const isAllGroupsMode = selectionMode?.mode === MultiSelectScope.AllGroups;

  const listingText = useContent(ContentId.Listing);
  const listingsText = useContent(ContentId.Listings);
  const eventText = useContent(ContentId.Event);
  const eventsText = useContent(ContentId.Events);
  const selectedFromText = useContent(ContentId.SelectedFrom);
  const noItemsSelected = useGetNoSelectedItemsLabel();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const eventsMap = useMemo<Record<string, EventWithData>>(() => {
    const map: Record<string, EventWithData> = {};
    events.map((e) => {
      const eventId = e.event.viagVirtualId;
      map[eventId] = e;
    });
    return map;
  }, [events]);

  const selectedLabel = useMemo(() => {
    if (!totalItemsSelected || !isAllGroupsMode) {
      return noItemsSelected;
    }

    const selectedEventsIds = Object.keys(itemSelection);
    const eventsCount = selectedEventsIds.length;

    let listingsCount = 0;
    selectedEventsIds.forEach((id) => {
      listingsCount += eventsMap[id]?.counts?.listCnt ?? 0;
    });

    const selectedEventsLabel = eventsCount === 1 ? eventText : eventsText;

    const selectedListingsLabel =
      listingsCount === 1 ? listingText : listingsText;

    return `${listingsCount} ${selectedListingsLabel} ${selectedFromText} ${eventsCount} ${selectedEventsLabel}`.toLocaleLowerCase();
  }, [
    eventText,
    eventsMap,
    eventsText,
    isAllGroupsMode,
    itemSelection,
    listingText,
    listingsText,
    noItemsSelected,
    selectedFromText,
    totalItemsSelected,
  ]);

  useEffect(() => {
    setSelectedCountLabel(selectedLabel);
  }, [selectedLabel, setSelectedCountLabel]);

  return <>{children}</>;
};
