import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_SHORT } from 'src/utils/constants/constants';
import {
  EventSupplyMetricsTimePeriod,
  EventWebsiteMetricsTimePeriod,
  InventoryEventDashboardClient,
  InventoryMetricsQueryId,
  SellerEventDashboardMetricsTimePeriod,
} from 'src/WebApiController';

export const useEventSupplyMetrics = (
  posEventId: string | null,
  viagogoEventId: number | null,
  isOnSaleEvent?: boolean,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [timePeriod, setTimePeriod] = useState<EventSupplyMetricsTimePeriod>(
    EventSupplyMetricsTimePeriod.LastSevenDays
  );

  const queryKey = useMemo(
    () => [
      'InventoryEventDashboardClient.getEventSupplyMetrics',
      posEventId,
      viagogoEventId,
      isOnSaleEvent,
      timePeriod,
    ],
    [posEventId, viagogoEventId, isOnSaleEvent, timePeriod]
  );

  const shouldQuery =
    !disabled &&
    !!activeAccountWebClientConfig.activeAccountId &&
    Boolean(!!posEventId || (isOnSaleEvent && !!viagogoEventId));
  const supplyMetricsQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) return null;

      const queryId: InventoryMetricsQueryId = {
        posEventId: posEventId,
        viagogoEventId: isOnSaleEvent ? viagogoEventId : null,
      };
      const supplyMetrics = await new InventoryEventDashboardClient(
        activeAccountWebClientConfig
      ).getEventSupplyMetrics(queryId, timePeriod);

      return supplyMetrics;
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'InventoryEventDashboardClient.getEventSupplyMetrics',
          error,
          {
            posEventId,
          }
        );
      },
    },
  });

  const onUpdateTimePeriod = useCallback(
    (timePeriod: EventSupplyMetricsTimePeriod | null) => {
      setTimePeriod(timePeriod ?? EventSupplyMetricsTimePeriod.LastSevenDays);
    },
    []
  );

  return {
    supplyMetrics: supplyMetricsQuery.data,
    isLoading: supplyMetricsQuery.isLoading,
    timePeriod,
    onUpdateTimePeriod,
  };
};

export const useSellerEventMetrics = (
  posEventId: string | null,
  viagogoEventId: number | null,
  isOnSaleEvent?: boolean,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [timePeriod, setTimePeriod] =
    useState<SellerEventDashboardMetricsTimePeriod | null>(
      SellerEventDashboardMetricsTimePeriod.AllTime
    );

  const shouldQuery =
    !disabled &&
    !!activeAccountWebClientConfig.activeAccountId &&
    Boolean(!!posEventId || (isOnSaleEvent && !!viagogoEventId));

  const queryKey = useMemo(
    () => [
      'InventoryEventDashboardClient.getSellerEventDashboardMetrics',
      posEventId,
      viagogoEventId,
      isOnSaleEvent,
      timePeriod,
    ],
    [posEventId, viagogoEventId, isOnSaleEvent, timePeriod]
  );

  const { isLoading, data: sellerPerformanceMetrics } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) return null;

      const queryId: InventoryMetricsQueryId = {
        posEventId: posEventId,
        viagogoEventId: isOnSaleEvent ? viagogoEventId : null,
      };
      const data = await new InventoryEventDashboardClient(
        activeAccountWebClientConfig
      ).getSellerEventDashboardMetrics(queryId, timePeriod);

      return data;
    },
    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'InventoryEventDashboardClient.getSellerEventDashboardMetrics',
          error,
          {
            posEventId,
          }
        );
      },
    },
  });

  const onUpdateTimePeriod = useCallback(
    (timePeriod: SellerEventDashboardMetricsTimePeriod | null) => {
      setTimePeriod(
        timePeriod ?? SellerEventDashboardMetricsTimePeriod.AllTime
      );
    },
    []
  );

  return {
    sellerPerformanceMetrics: sellerPerformanceMetrics,
    isLoading,
    timePeriod,
    onUpdateTimePeriod,
  };
};

export const useCrossSellerEventMetrics = (
  posEventId: string | null,
  viagogoEventId: number | null,
  isOnSaleEvent?: boolean,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled &&
    !!activeAccountWebClientConfig.activeAccountId &&
    Boolean(!!posEventId || (isOnSaleEvent && !!viagogoEventId));

  const queryKey = useMemo(
    () => [
      'InventoryEventDashboardClient.getBuyerEventDashboardMetrics',
      posEventId,
      viagogoEventId,
      isOnSaleEvent,
    ],
    [isOnSaleEvent, posEventId, viagogoEventId]
  );

  const { isLoading, data: eventPerformanceMetrics } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) return null;

      const queryId: InventoryMetricsQueryId = {
        posEventId: posEventId,
        viagogoEventId: isOnSaleEvent ? viagogoEventId : null,
      };
      const data = await new InventoryEventDashboardClient(
        activeAccountWebClientConfig
      ).getBuyerEventDashboardMetrics(queryId);

      return data;
    },
    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'InventoryEventDashboardClient.getBuyerEventDashboardMetrics',
          error,
          {
            posEventId,
          }
        );
      },
    },
  });

  return {
    eventPerformanceMetrics,
    isLoading,
  };
};

export const useEventWebsiteMetrics = (
  posEventId: string | null,
  viagogoEventId: number | null,
  isOnSaleEvent?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [timePeriod, setTimePeriod] = useState<EventWebsiteMetricsTimePeriod>(
    EventWebsiteMetricsTimePeriod.OneHour
  );

  const queryKey = useMemo(
    () => [
      'InventoryEventDashboardClient.getEventWebsiteMetrics',
      activeAccountWebClientConfig.activeAccountId,
      posEventId,
      viagogoEventId,
      isOnSaleEvent,
      timePeriod,
    ],
    [
      activeAccountWebClientConfig.activeAccountId,
      isOnSaleEvent,
      posEventId,
      timePeriod,
      viagogoEventId,
    ]
  );

  const shouldQuery =
    !!activeAccountWebClientConfig.activeAccountId &&
    Boolean(!!posEventId || (isOnSaleEvent && !!viagogoEventId));

  const { data: websiteMetrics, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const client = new InventoryEventDashboardClient(
        activeAccountWebClientConfig
      );
      const queryId: InventoryMetricsQueryId = {
        posEventId: posEventId,
        viagogoEventId: isOnSaleEvent ? viagogoEventId : null,
      };
      const data = await client.getEventWebsiteMetrics(queryId, timePeriod);

      return data;
    },
    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'InventoryEventDashboardClient.getEventWebsiteMetrics',
          error,
          {
            posEventId,
          }
        );
      },
    },
  });

  const onUpdateTimePeriod = useCallback(
    (timePeriod: EventWebsiteMetricsTimePeriod | null) => {
      setTimePeriod(timePeriod ?? EventWebsiteMetricsTimePeriod.OneHour);
    },
    []
  );

  return {
    websiteMetrics,
    timePeriod,
    onUpdateTimePeriod,
    isLoading,
  };
};
