import { UseQueryResult } from '@tanstack/react-query';
import { isEmpty, noop, union, uniq } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { SidePanelTab } from 'src/components/Listings/InventoryEventPage/MarketListings/MarketListingHeader';
import { useAutoPricingPreview } from 'src/components/Listings/MapWithCompListingsPreview/MapWithCompListingsPreview.hooks';
import { useComparableListingsVenueMapColor } from 'src/hooks/useComparableListingsVenueMapColor';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import {
  CompListing,
  Event,
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsPricingUpdates,
  ListingPriceCalculation,
  SectionInfo,
  VenueMapInfo,
} from 'src/WebApiController';

import { useEventMapContext } from '../EventMapContext';

export type IInventoryEventDetailContext = {
  event?: Event | undefined;
  eventListings?: Listing[] | null;
  selectedListingIds: number[];
  selectedListing?: Listing | undefined;
  selectedGroup?: Listing | undefined;
  selectedTab: SidePanelTab;
  onTabClick: (tab: SidePanelTab) => void;
  shouldRepaint: boolean;
  recenterMap: () => void;
  shouldReset: boolean;
  setShouldReset: React.Dispatch<React.SetStateAction<boolean>>;
  compQuantityFilters: string[];
  setCompQuantityFilters: React.Dispatch<React.SetStateAction<string[]>>;
  venueMapInfo: VenueMapInfo | null;
  priceCalcQuery: UseQueryResult<ListingPriceCalculation | null, Error> | null;
  compListings?: CompListing[] | undefined;
  filterSections: SectionInfo[];
  compListingSectionIds: number[];
  getColor: NonNullable<VenueMapContentProps['getColor']>;
  splitChoices: Record<string, string>;
  isLoading: boolean;
};

const InventoryEventDetailContext = createContext<IInventoryEventDetailContext>(
  {
    event: undefined,
    eventListings: null,
    selectedListingIds: [],
    selectedListing: undefined,
    selectedGroup: undefined,
    selectedTab: SidePanelTab.LISTINGS,
    onTabClick: (tab: SidePanelTab) => {},
    shouldRepaint: false,
    recenterMap: () => {},
    shouldReset: false,
    setShouldReset: () => {},
    compQuantityFilters: [],
    setCompQuantityFilters: () => {},
    venueMapInfo: null,
    priceCalcQuery: null,
    compListings: [],
    filterSections: [],
    compListingSectionIds: [],
    getColor: noop,
    splitChoices: {},
    isLoading: false,
  }
);

export const useInventoryEventDetailContext = () =>
  useContext(InventoryEventDetailContext);

export const InventoryEventDetailContextProvider = ({
  event,
  eventListings,
  selectedListingIds,
  selectedListing,
  selectedGroup,
  children,
}: {
  event: Event;
  eventListings?: Listing[] | null;
  selectedListingIds: number[];
  selectedListing: Listing | undefined;
  selectedGroup: Listing | undefined;
  children: ReactNode;
}) => {
  // Contexts
  const { venueMapInfo } = useEventMapContext();

  // State
  const [shouldRepaint, setShouldRepaint] = useState<boolean>(false);
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<SidePanelTab>(
    SidePanelTab.LISTINGS
  );
  const [compQuantityFilters, setCompQuantityFilters] = useState<string[]>([]);

  const recenterMap = useCallback(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        setShouldRepaint(true);

        setTimeout(() => {
          setShouldRepaint(false);
        }, 200);
      }, 50);
    });
  }, []);

  useEffect(() => {
    setShouldReset(true);
    requestAnimationFrame(() => {
      setTimeout(() => {
        setShouldReset(true);

        setTimeout(() => {
          setShouldReset(false);
        }, 200);
      }, 50);
    });
  }, [selectedListing?.id]);

  // Custom hooks and component data
  const onTabClick = useCallback(
    (tab: SidePanelTab) => {
      setSelectedTab(tab);
      recenterMap();
    },
    [recenterMap]
  );

  const { watch } = useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { compListingSelectedSectionSettings } = watch();
  const input = watch();

  const sanitizedListingInput = useMemo((): ListingDetailsPricingUpdates => {
    const {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } = input ?? {};

    return {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } as ListingDetailsPricingUpdates;
  }, [input]);

  const { priceCalcQuery } = useAutoPricingPreview(
    event?.viagId ?? null,
    selectedListing?.id,
    sanitizedListingInput
  );

  const { compListingsQuery, compListingsGroupQuery } = useGetCompListings(
    event,
    selectedListing?.id,
    selectedListing?.currency,
    selectedGroup?.ltGrpId
  );

  const formSectionFilterIds = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionIdFilter ?? [];
  }, [compListingSelectedSectionSettings?.sectionIdFilter]);

  const compListings = useMemo(() => {
    if (selectedListing?.id) {
      return priceCalcQuery?.data?.compListings;
    }
    if (isEmpty(formSectionFilterIds)) {
      if (selectedGroup?.ltGrpId) {
        return compListingsGroupQuery.data?.compListings;
      }
      return compListingsQuery.data ?? undefined;
    }

    if (selectedGroup?.ltGrpId) {
      return compListingsGroupQuery.data?.compListings?.filter((l) => {
        const { section } = getMatchingSectionRow(l, venueMapInfo?.sections);
        return section && formSectionFilterIds.includes(section.id);
      });
    }
    return compListingsQuery.data?.filter((l) => {
      const { section } = getMatchingSectionRow(l, venueMapInfo?.sections);
      return section && formSectionFilterIds.includes(section.id);
    });
  }, [
    selectedListing?.id,
    formSectionFilterIds,
    venueMapInfo?.sections,
    selectedGroup?.ltGrpId,
    compListingsQuery.data,
    priceCalcQuery?.data?.compListings,
    compListingsGroupQuery.data?.compListings,
  ]);

  const filterSections = useMemo(() => {
    const selectedSectionId =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections?.filter(({ id }) =>
        selectedSectionId.includes(id)
      ) ?? []
    );
  }, [
    compListingSelectedSectionSettings?.sectionIdFilter,
    venueMapInfo?.sections,
  ]);

  const compListingSectionIds = useMemo(() => {
    if (isEmpty(selectedListingIds)) {
      return formSectionFilterIds;
    }

    const sectionNames = (compListings ?? []).map((l) => l.section);

    const sectionIds = (venueMapInfo?.sections ?? [])
      .filter((section) => sectionNames.includes(section.name))
      .map(({ id }) => id);

    return union(sectionIds, formSectionFilterIds);
  }, [
    selectedListingIds,
    compListings,
    venueMapInfo?.sections,
    formSectionFilterIds,
  ]);

  const canSetPrice = useUserCanSetPrice(selectedListing, false);

  const compListingsFiltered = useMemo(() => {
    if (isEmpty(compQuantityFilters)) {
      return compListings;
    }

    return compListings?.filter(({ validPurchaseQuantities }) => {
      if (isEmpty(validPurchaseQuantities)) {
        return true;
      }
      return validPurchaseQuantities.some(
        (quantity) => compQuantityFilters.indexOf(quantity + '') > -1
      );
    });
  }, [compListings, compQuantityFilters]);

  const getColor = useComparableListingsVenueMapColor(
    eventListings ?? [],
    compListingsFiltered,
    compListings
  );

  const splitChoices = useMemo(() => {
    return uniq(
      (compListings ?? []).flatMap(
        (listing) => listing.validPurchaseQuantities ?? []
      )
    ).reduce(
      (res, quantity) => {
        const stringQuantity = quantity + '';
        res[stringQuantity] = stringQuantity;
        return res;
      },
      {} as Record<string, string>
    );
  }, [compListings]);

  const isLoading = useMemo(
    () =>
      compListingsQuery.isLoading ||
      priceCalcQuery.isLoading ||
      compListingsGroupQuery.isLoading,
    [
      compListingsGroupQuery.isLoading,
      compListingsQuery.isLoading,
      priceCalcQuery.isLoading,
    ]
  );

  if (!canSetPrice) {
    return null;
  }

  return (
    <InventoryEventDetailContext.Provider
      value={{
        event,
        eventListings,
        selectedListingIds,
        selectedListing,
        selectedGroup,
        selectedTab,
        onTabClick,
        shouldRepaint,
        recenterMap,
        shouldReset,
        setShouldReset,
        compQuantityFilters,
        setCompQuantityFilters,
        venueMapInfo,
        priceCalcQuery,
        compListings: compListingsFiltered,
        filterSections,
        compListingSectionIds,
        getColor,
        splitChoices,
        isLoading,
      }}
    >
      {children}
    </InventoryEventDetailContext.Provider>
  );
};
