import { once } from 'lodash-es';
import React, { PropsWithChildren } from 'react';
import {
  ActionOutboxEntityType,
  CatalogClient,
  DateTimeRange,
  EntityWithTicketsQuery,
} from 'src/WebApiController';

export const MAX_ENTITIES_IN_GROUP_FOR_LOAD = 1000;

export type EventIdGroup = {
  viagId: number;
  viagVirtualId: string;
  performerId: string | null;
  venueId: number;
  name: string;
  date: DateTimeRange;
};

export type ICatalogMetricsContext<
  TMetrics extends { currency: string | null },
> = {
  isLoading: boolean;
  eventDetailedMetrics?: Record<string, TMetrics>;
  eventMetrics?: Record<string, TMetrics>;
  performerMetrics?: Record<number, TMetrics>;
  venueMetrics?: Record<number, TMetrics>;
  refreshMetrics: () => void;
};

export type CatalogMetricsContextProviderProps<
  TMetrics extends { currency: string | null },
  TQuery extends EntityWithTicketsQuery,
> = PropsWithChildren<{
  queryKey: string;
  entityType: ActionOutboxEntityType;
  getCatalogMetrics?: (
    client: CatalogClient,
    filterQuery: TQuery,
    rowVersion?: number | null
  ) => Promise<{
    events: Record<string, TMetrics>;
    performers?: Record<number, TMetrics>;
    venues?: Record<number, TMetrics>;
    eventsDetailed?: Record<string, TMetrics>;
    maxRowVersion?: number | null;
  }>;
  /**
   * Only for V1 (where metrics are split into batches)
   * Needs to be provided if getCatalogMetrics is provided
   */
  addCatalogMetrics?: (metricA: TMetrics, metricB: TMetrics) => TMetrics;
  disabled?: boolean;
  isDetailed?: boolean;
}>;

export type CatalogMetricsResults<
  TMetrics extends { currency: string | null },
  TQuery extends EntityWithTicketsQuery,
> = {
  filter: TQuery;
  eventsDetailed: Record<string, TMetrics>;
  events: Record<string, TMetrics>;
  performers?: Record<number, TMetrics>;
  venues?: Record<number, TMetrics>;
  maxRowVersion?: number | null;
};

export const createCatalogMetricsContextV1 = once(<
  TMetrics extends { currency: string | null },
>() => React.createContext({} as ICatalogMetricsContext<TMetrics>));

export const createCatalogMetricsContextV2 = once(<
  TMetrics extends { currency: string | null },
>() => React.createContext({} as ICatalogMetricsContext<TMetrics>));
