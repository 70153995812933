import { useContentContext } from 'src/contexts/ContentContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { Feature, ListingNote } from 'src/WebApiController';

import * as styles from './ListingDisplay.css';

export const ListingNotesDisplay = ({
  seatTraits,
  className,
}: {
  seatTraits: ListingNote[] | null | undefined;
  className?: string;
}) => {
  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );

  if (!seatTraits?.length) {
    return null;
  }

  return (
    <HorizontalTextList
      className={className ?? styles.listingNotes}
      preIcon={getListingNoteIcon(
        seatTraits[0],
        contentContext,
        hasListingNotesAdditionalInfoFeature
      )}
      texts={seatTraits.map((n) => n.listingNoteText)}
      maxTextsToShowOnHover={seatTraits.length}
    />
  );
};
