import { format } from 'date-fns';
import { useMemo } from 'react';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { UpdatedByBannerWrapper } from 'src/modals/common/Purchase/PaymentDetailsTable/PaymentDetailsTable.styled';
import { ConvertCurrencyIcon } from 'src/svgs/ConvertCurrencyIcon';
import { DeleteIcon, EditIcon, IconsFill, PaymentSize } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency } from 'src/utils/numberFormatter';
import { getPaymentMethodIcon } from 'src/utils/paymentMethodUtils';
import {
  Feature,
  PurchasePayment,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
} from 'src/WebApiController';

import * as styles from '../PurchaseModal.css';
import {
  PaymentStatusTextContainer,
  PurchaseDetailsDataTable,
  PurchaseDetailsDataTableCell,
} from '../PurchaseModal.styled';

type PaymentDetailsTableEventHandler = (payment: PurchasePayment) => void;

export type PaymentDetailsTableProps = {
  currencyCode: string;
  payments?: PurchasePayment[] | null;
  disabled?: boolean;
  onSetAsPaid: PaymentDetailsTableEventHandler;
  onEditPayment: PaymentDetailsTableEventHandler;
  onRemovePayment: PaymentDetailsTableEventHandler;
  onConvertCurrency: PaymentDetailsTableEventHandler;
};

export function PaymentDetailsTable({
  currencyCode,
  payments,
  disabled,
  onSetAsPaid,
  onEditPayment,
  onRemovePayment,
  onConvertCurrency,
}: PaymentDetailsTableProps) {
  const canConvertPaymentLines = useUserHasFeature(Feature.ConvertPaymentLines);
  const convertedByUsersIds = useMemo(() => {
    return (payments
      ?.filter((payment) => !!payment.conversionUserId)
      .map((payment) => payment.conversionUserId) ?? []) as string[];
  }, [payments]);
  const userInfos = useGetUserInfos(convertedByUsersIds);

  return (
    <PurchaseDetailsDataTable>
      {payments?.length ? (
        <>
          <thead>
            <tr>
              <th>
                <Content id={ContentId.Status} />
              </th>
              <th>
                <Content id={ContentId.Amount} />
              </th>
              {canConvertPaymentLines && (
                <>
                  <th>
                    <Content id={ContentId.ConvertedTo} />
                  </th>
                  <th>
                    <Content id={ContentId.ConversionRate} />
                  </th>
                </>
              )}
              <th>
                <Content id={ContentId.PaymentDate} />
              </th>
              <th>
                <Content id={ContentId.PaymentMethod} />
              </th>
              <th>
                <Content id={ContentId.Actions} />
              </th>
            </tr>
          </thead>
          <tbody>
            {payments?.map((payment) => {
              /**
               * Payment is past due date if the current date is past PaymentDueDate.
               */
              const isPaymentPastDueDate =
                payment.paymentStatus !== PurchasePaymentStatus.Paid &&
                Date.now() > new Date(payment.paymentDueDate).getTime();
              /**
               * Payment is refunded if the PaymentAmount is negative.
               */
              const isPaymentRefunded =
                payment.paymentStatus === PurchasePaymentStatus.Paid &&
                payment.paymentAmount.amt < 0;
              const {
                paymentId,
                paymentStatus,
                paymentDueDate,
                paymentDate,
                paymentAmount,
                paymentMethod,
                convertedPaymentAmount,
                convertedCurrencyCode,
                conversionRate,
                conversionDate,
                conversionUserId,
              } = payment;

              const updatedByBanner =
                canConvertPaymentLines && userInfos.data && conversionUserId ? (
                  <tr>
                    <td colSpan={20}>
                      <UpdatedByBannerWrapper>
                        <Content id={ContentId.UpdatedBy} />{' '}
                        {userInfos.data[conversionUserId]?.name}{' '}
                        <Content id={ContentId.On} />{' '}
                        {format(new Date(conversionDate!), 'MMM d, yyyy')}
                      </UpdatedByBannerWrapper>
                    </td>
                  </tr>
                ) : null;

              return (
                <>
                  <tr key={paymentId}>
                    <td>
                      <PurchaseDetailsDataTableCell>
                        <PaymentStatusTextContainer
                          purchasePaymentStatus={paymentStatus}
                          isPaymentPastDueDate={isPaymentPastDueDate}
                          isPaymentRefunded={isPaymentRefunded}
                        >
                          <PaymentStatusText
                            paymentStatus={paymentStatus}
                            isPaymentPastDueDate={isPaymentPastDueDate}
                            isPaymentRefunded={isPaymentRefunded}
                          />
                        </PaymentStatusTextContainer>
                      </PurchaseDetailsDataTableCell>
                    </td>
                    <td>
                      <PurchaseDetailsDataTableCell>
                        <span
                          style={{
                            visibility: isPaymentRefunded
                              ? 'visible'
                              : 'hidden',
                          }}
                        >
                          -
                        </span>
                        {(
                          paymentAmount.disp ||
                          formatCurrency(
                            paymentAmount.amt,
                            paymentAmount.currency ?? currencyCode,
                            paymentAmount.dec
                          )
                        ).replace(/^-/, '')}
                      </PurchaseDetailsDataTableCell>
                    </td>
                    {canConvertPaymentLines && (
                      <>
                        <td>
                          {convertedPaymentAmount?.disp != null ||
                          convertedPaymentAmount?.currency != null ||
                          convertedCurrencyCode != null
                            ? convertedPaymentAmount?.disp ||
                              formatCurrency(
                                convertedPaymentAmount?.amt,
                                convertedPaymentAmount?.currency ??
                                  convertedCurrencyCode,
                                convertedPaymentAmount?.dec
                              )
                            : null}
                        </td>
                        <td>{conversionRate}</td>
                      </>
                    )}
                    <td>
                      <PurchaseDetailsDataTableCell>
                        {(paymentStatus === PurchasePaymentStatus.Paid ||
                          paymentStatus ===
                            PurchasePaymentStatus.RefundNeeded) &&
                        paymentDate
                          ? new Date(paymentDate).toISOString()
                          : new Date(paymentDueDate).toISOString()}
                      </PurchaseDetailsDataTableCell>
                    </td>
                    <td>
                      <PurchaseDetailsDataTableCell>
                        <div className={styles.purchaseMethodIconContainer}>
                          <PurchaseMethodIcon
                            paymentMethod={paymentMethod.type}
                          />
                        </div>
                        {paymentStatus === PurchasePaymentStatus.Paid ||
                        paymentStatus === PurchasePaymentStatus.RefundNeeded ? (
                          paymentMethod.display
                        ) : (
                          <Button
                            variant={'link'}
                            disabled={disabled}
                            onClick={() => onSetAsPaid(payment)}
                          >
                            <Content id={ContentId.SetAsPaid} />
                          </Button>
                        )}
                      </PurchaseDetailsDataTableCell>
                    </td>
                    {!disabled && (
                      <td>
                        <PurchaseDetailsDataTableCell>
                          {canConvertPaymentLines && (
                            <IconButton
                              icon={<ConvertCurrencyIcon withHoverEffect />}
                              onClick={() => onConvertCurrency(payment)}
                              titleContentId={ContentId.ConvertCurrency}
                            />
                          )}
                          <IconButton
                            icon={
                              <EditIcon
                                withHoverEffect
                                fill={IconsFill.currentColor}
                              />
                            }
                            onClick={() => onEditPayment(payment)}
                            titleContentId={ContentId.Edit}
                          />
                          <IconButton
                            icon={
                              <DeleteIcon
                                fill={IconsFill.textNegative}
                                withHoverEffect
                              />
                            }
                            onClick={() => onRemovePayment(payment)}
                            titleContentId={ContentId.Remove}
                          />
                        </PurchaseDetailsDataTableCell>
                      </td>
                    )}
                  </tr>
                  {updatedByBanner}
                </>
              );
            })}
          </tbody>
        </>
      ) : (
        <Content id={ContentId.None} />
      )}
    </PurchaseDetailsDataTable>
  );
}

function PaymentStatusText({
  paymentStatus,
  isPaymentPastDueDate,
  isPaymentRefunded,
}: Pick<PurchasePayment, 'paymentStatus'> & {
  isPaymentPastDueDate: boolean;
  isPaymentRefunded: boolean;
}) {
  switch (paymentStatus) {
    case PurchasePaymentStatus.Paid:
      return isPaymentRefunded ? (
        <Content id={ContentId.Refunded} />
      ) : (
        <Content id={ContentId.Paid} />
      );
    case PurchasePaymentStatus.Unpaid:
      return isPaymentPastDueDate ? (
        <Content id={ContentId.Due} />
      ) : (
        <Content id={ContentId.Unpaid} />
      );
    case PurchasePaymentStatus.Failed:
      return <Content id={ContentId.Failed} />;
    case PurchasePaymentStatus.RefundNeeded:
      return <Content id={ContentId.RefundNeeded} />;
    default:
      return null;
  }
}

function PurchaseMethodIcon({
  paymentMethod,
}: {
  paymentMethod: PurchasePaymentMethodType;
}) {
  const Icon = getPaymentMethodIcon(paymentMethod);
  return <Icon size={PaymentSize.s} />;
}
