import { useCallback, useMemo } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkEditMarketplacePricesDialog } from 'src/dialogs/BulkEdits/BulkEditMarketplacePricesDialog';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsUpdateInput,
  ListingPricesUpdateInput,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_MARKETPLACE_PRICES_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchUpdateMarketplacePrices } from './LaunchChangeMarketplacePrice';

export const useBulkUpdateMarketplacePrices = (
  filterQueryWithEventIds: ListingQuery,
  affectedVisibleListingIds: number[],
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `marketplacePrices-event-${event?.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_MARKETPLACE_PRICES_UPDATE_KEY;

  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditMarketplaceDialog,
    closeDialog: closeBulkEditMarketplaceDialog,
  } = useDialog(
    DialogId.BulkEditMarketplacePrices,
    BulkEditMarketplacePricesDialog
  );

  const onSubmit = useCallback(
    async (
      listingUpdate: ListingDetailsUpdateInput | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateMarketplacePricesPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateMarketplacePricesPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (listingUpdate) {
        await tryInvokeApi(
          async () => {
            const client = await new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const input = {
              item1: preview!.preview,
              item2: {
                id: listingUpdate.id,
                allInPrice: listingUpdate.allInPrice,
                listPrice: listingUpdate.listPrice,
                netProceedsCeiling: listingUpdate.netProceedsCeiling,
                netProceedsFloor: listingUpdate.netProceedsFloor,
                autoPricingEnabled: listingUpdate.autoPricingEnabled,
                marketplacePriceUpdates: listingUpdate.marketplacePriceUpdates,
              } as ListingPricesUpdateInput,
            };
            const succeeded = await client.bulkUpdateMarketplacePrices(
              input,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditMarketplaceDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateMarketplacePrices', error, {
              trackErrorData: { preview, listingUpdate },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditMarketplaceDialog,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const listings = useMemo(() => {
    return (eventsTransformed ?? [])
      .flatMap((ev) => ev.entities.listings)
      .flatMap(flattenListingGroup)
      .filter(({ id, isLtGrp }) => id > 0 && !isLtGrp) as Listing[];
  }, [eventsTransformed]);

  return {
    dropDown: (
      <LaunchUpdateMarketplacePrices
        key={'LaunchUpdateMarketplacePrices'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditMarketplaceDialog({
            event,
            updateKey,
            listings,
            selectedListingIds: affectedVisibleListingIds,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditMarketplaceDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
