import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { SummaryTile } from 'src/components/common/SummaryTile';
import {
  EventNameDisplay,
  VenueNameDisplay,
} from 'src/components/Events/EventInfo';
import { EventStatus } from 'src/components/Events/EventStatus';
import { InventoryEventActionDropdown } from 'src/components/Listings/InventoryActionDropdown';
import { InventoryEventPageHeaderCondense } from 'src/components/Listings/InventoryEventPage/InventoryEventPageHeaderCondense';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content, isContentId } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { MarketplaceEventEditDialog } from 'src/dialogs/MarketplaceEventEditDialog';
import { useEventMapConfigMetadata } from 'src/hooks/api/useEventMapConfigMetadata';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { MAPPING_REJECTION_REASON_ID_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  compareMarketplace,
  getFormattedEventName,
  getIsInternationalEvent,
} from 'src/utils/eventWithDataUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { getInventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Event,
  EventConfigClient,
  Feature,
  Listing,
  ListingMetrics,
  Marketplace,
  Performer,
  Permission,
  SellerEventMarketplaceEventInfo,
  TopLevelCategory,
  Venue,
} from 'src/WebApiController';

import { PerformerCategoryIcon } from '../../common/PerformerCategoryIcon';
import { NewEventMapBanner } from './Banners/NewEventMapBanner';
import { EventVenueMapModal } from './EventVenueMap/EventVenueMapModal';
import { EventVenueMapPopup } from './EventVenueMap/EventVenueMapPopup';
import * as styles from './InventoryEventPage.css';

const visibleMarketPlaces: Readonly<Marketplace[]> = [
  Marketplace.StubHub,
  Marketplace.VividSeats,
  Marketplace.Ticketmaster,
  Marketplace.SeatGeek,
];

export const InventoryEventPageHeader = ({
  event,
  venue: eventVenue,
  performer: eventPerformer,
  inventoryAccordionItem,
  isOnSaleEvent = false,
}: {
  event: Event;
  venue?: Venue;
  performer?: Performer;
  inventoryAccordionItem?: EventAccordionItemProps<Listing>;
  isOnSaleEvent?: boolean;
}) => {
  const locale = getLocaleFromLanguageOrCurrent();

  const hasEventsEditPermission = useUserHasAnyOfPermissions(
    Permission.Events_Edit
  );

  const hasEventStatusDisplay = useUserHasFeature(Feature.EventStatusDisplay);
  const hasCondensedPreviewTabsAndSingleListingFeature = useUserHasFeature(
    Feature.CondensedPreviewTabsAndSingleListing
  );
  const hasBlockInventorySummaryStatsFeature = useUserHasFeature(
    Feature.BlockInventorySummaryStats
  );

  const { loginContext } = useAppContext();

  const { venue, performer } = useGetEventFullInfo(
    event,
    eventPerformer,
    eventVenue
  );

  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const { isCollapsedView } = useCollapsableViewContext();

  const eventDate = event?.dates.start
    ? new Date(event.dates.start)
    : undefined;

  const eventMappingRejectionReason = event?.mapRejRsnId
    ? (MAPPING_REJECTION_REASON_ID_TO_CID[event.mapRejRsnId] ??
      event?.mapRejDftTxt)
    : undefined;

  const [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
    performer,
  });
  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<ListingMetrics>();

  const eventMetrics = eventMetricsMap?.[event?.viagVirtualId ?? ''];
  const formattedTotalMetrics = eventMetrics
    ? getInventorySummaryListingMetricsDisplayStrings(
        eventMetrics,
        loginContext?.user?.activeAccount?.currencyCode
      )
    : undefined;

  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    activeAccountWebClientConfig?.activeAccountId != null &&
    event.viagVirtualId != null;

  const marketplaceEventInfoQuery = useQuery({
    queryKey: [
      'EventConfigClient.getSellerEventMarketplaceEventInfo',
      activeAccountWebClientConfig.activeAccountId,
      event?.viagVirtualId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const result = await new EventConfigClient(
            activeAccountWebClientConfig
          ).getSellerEventMarketplaceEventInfo(event.viagId, event.mappingId);

          if (result) {
            return result.reduce(
              (r, m) => {
                r[m.marketplace] = m;
                return r;
              },
              {} as Record<string, SellerEventMarketplaceEventInfo>
            );
          }

          return {};
        },
        (error) => {
          trackError(
            'EventConfigClient.getSellerEventMarketplaceEventInfo',
            error,
            {
              viagogoEntityId: event.viagId,
              viagogoMappingId: event.mappingId,
            }
          );
        }
      );
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();
  const { setModal } = useContext(ModalContext);

  const onEventIdsEditClick = useCallback(() => {
    launchDialog();
  }, [launchDialog]);

  const openEventMapView = useCallback(() => {
    setModal({
      children: <EventVenueMapModal event={event} />,
      size: 'fullscreen',
      fullscreen: true,
    });
  }, [event, setModal]);

  const mkpEventInfos = useMemo(
    () =>
      loginContext?.user?.activeAccount.marketplaceSettings
        .filter((m) => m.mkp !== Marketplace.Automatiq) // Automatiq is not an actual Marketplace for Event viewing
        .map((m) => {
          const minfo = marketplaceEventInfoQuery.data?.[m.mkp];
          return (
            minfo ??
            ({
              marketplace: m.mkp,
              viagogoEventId: event.viagId,
              mappedEventId:
                m.mkp === Marketplace.StubHub ? event.viagId : undefined,
            } as SellerEventMarketplaceEventInfo)
          );
        }),
    [
      event.viagId,
      loginContext?.user?.activeAccount.marketplaceSettings,
      marketplaceEventInfoQuery.data,
    ]
  );

  const marketPlaceLogos = useMemo(() => {
    {
      return mkpEventInfos
        ?.filter((marketplace) =>
          visibleMarketPlaces.includes(marketplace.marketplace)
        )
        .sort((a, b) => compareMarketplace(a.marketplace, b.marketplace))
        .map((minfo) => {
          const hasEventId =
            minfo.marketplace == Marketplace.StubHub ||
            Boolean(minfo?.eventIdOverride || minfo?.mappedEventId) ||
            (Marketplace.Ticketmaster && minfo.websiteEventId);

          if (
            minfo.marketplace !== Marketplace.StubHub &&
            isInternationalEvent
          ) {
            // We only show other marketplaces when the Event is US
            return null;
          }

          const onMkpClick =
            hasEventId || !hasEventsEditPermission
              ? undefined
              : onEventIdsEditClick;

          if (isCollapsedView) {
            return (
              <PosDropdownItem key={minfo.marketplace} onClick={onMkpClick}>
                {minfo.marketplace}
              </PosDropdownItem>
            );
          }

          return (
            <MarketplaceLogo
              key={minfo.marketplace}
              marketplace={minfo.marketplace}
              event={event!}
              isViagogo={false}
              grayScaleColor={!hasEventId}
              marketplaceEventInfo={minfo}
              onClick={onMkpClick}
            />
          );
        });
    }
  }, [
    event,
    hasEventsEditPermission,
    isCollapsedView,
    isInternationalEvent,
    mkpEventInfos,
    onEventIdsEditClick,
  ]);

  const multiSelectToggle = inventoryAccordionItem && (
    <MultiSelectionToggleGroup
      groupId={inventoryAccordionItem.event.viagVirtualId}
      enableHotkey={true}
    />
  );

  const inventoryEventDropdown = useMemo(() => {
    return (
      inventoryAccordionItem && (
        <InventoryEventActionDropdown
          {...inventoryAccordionItem}
          hideYearFromDate={false}
          isEventPage
          extraDropdownItems={isCollapsedView && marketPlaceLogos}
        />
      )
    );
  }, [inventoryAccordionItem, isCollapsedView, marketPlaceLogos]);

  const marketplaceEventEditDialog = (
    <MarketplaceEventEditDialog
      {...dialogProps}
      event={event}
      mkpEventInfos={mkpEventInfos}
      onClosed={() => {
        marketplaceEventInfoQuery.refetch();
        closeDialog();
      }}
    />
  );

  const { eventMapConfigQuery } = useEventMapConfigMetadata({
    viagogoEventId: event.viagId,
  });

  if (isCollapsedView) {
    return (
      <>
        <div className={styles.tilesSection}>
          <InventoryEventPageHeaderCondense
            eventDate={eventDate}
            eventName={
              <EventNameDisplay
                eventName={eventName}
                eventNameConnector={eventNameConnector}
                eventSubName={eventSubName}
                boldSubName={event?.genre === TopLevelCategory.Sports}
              />
            }
            eventVenue={
              venue && (
                <div className={styles.eventVenue}>
                  <VenueNameDisplay venueName={venue.name} />
                </div>
              )
            }
            metricValue={formattedTotalMetrics?.formattedTotalListPrice}
            actions={
              <>
                {multiSelectToggle}
                {inventoryEventDropdown}
              </>
            }
          />
        </div>
        {marketplaceEventEditDialog}
      </>
    );
  }

  return (
    <div className={styles.tilesSection}>
      {!isOnSaleEvent && (
        <NewEventMapBanner
          openEventMapView={openEventMapView}
          eventMapConfigQuery={eventMapConfigQuery}
        />
      )}
      <SummaryTile.Root>
        {eventDate && <SummaryTile.DateDisplay date={eventDate} />}
        <SummaryTile.Body>
          <SummaryTile.Title>
            <EventNameDisplay
              eventName={eventName}
              eventNameConnector={eventNameConnector}
              eventSubName={eventSubName}
              boldSubName={event?.genre === TopLevelCategory.Sports}
              useBigStyle={true}
            />
            {venue && (
              <div className={styles.eventVenue}>
                <VenueNameDisplay
                  venueName={venue.name}
                  venueLocation={venue.descr}
                />
              </div>
            )}
            <div className={styles.eventMetadata}>
              {eventDate && (
                <div className={styles.eventTime}>
                  {format(eventDate, 'h:mm aaa', { locale })}
                </div>
              )}{' '}
              {performer && (
                <SummaryTile.Description>
                  {(performer as Performer).categ && (
                    <>
                      <PerformerCategoryIcon
                        performer={performer as Performer}
                      />
                      &nbsp;
                    </>
                  )}
                  {performer?.descr}
                </SummaryTile.Description>
              )}
            </div>
            {eventMappingRejectionReason && (
              <div className={styles.eventMetadata}>
                <WarningMessage
                  style={{ alignItems: 'flex-start' }}
                  message={
                    <Stack direction="column" gap="s">
                      <span>
                        <Content id={ContentId.RejectionReasonInfoText1} />{' '}
                        {isContentId(eventMappingRejectionReason) ? (
                          <Content id={eventMappingRejectionReason} />
                        ) : (
                          eventMappingRejectionReason
                        )}
                      </span>
                      <span>
                        <Content id={ContentId.RejectionReasonInfoText2} />
                      </span>
                    </Stack>
                  }
                />
              </div>
            )}
          </SummaryTile.Title>
          <Stack
            direction="column"
            style={{ flex: 1, overflow: 'hidden', minWidth: '250px' }}
          >
            <SummaryTile.MetricGroup style={{ columnGap: vars.spacing['lg'] }}>
              {inventoryAccordionItem && (
                <Stack alignItems="center" gap="l">
                  <BulkEditStatusPopover
                    entityType={ActionOutboxEntityType.Listing}
                  />
                  {!hasCondensedPreviewTabsAndSingleListingFeature && (
                    <EventVenueMapPopup
                      onClick={openEventMapView}
                      eventMapConfigQuery={eventMapConfigQuery}
                    />
                  )}
                  {multiSelectToggle}
                  {inventoryEventDropdown}
                </Stack>
              )}
              <Stack>
                {hasEventStatusDisplay && event.eventStatus && (
                  <div className={styles.eventStatus}>
                    <EventStatus status={event.eventStatus} />
                  </div>
                )}
                {!isOnSaleEvent && !hasBlockInventorySummaryStatsFeature && (
                  <SummaryTile.Metric
                    name={<Content id={ContentId.TotalValueListed} />}
                    showLoadingPlaceholders={!formattedTotalMetrics}
                  >
                    {formattedTotalMetrics?.formattedTotalListPrice}
                  </SummaryTile.Metric>
                )}
              </Stack>
            </SummaryTile.MetricGroup>

            {!isOnSaleEvent && (
              <Stack
                direction="row"
                gap="m"
                justifyContent="end"
                style={{ flex: 1 }}
                alignItems="center"
              >
                <ScrollableToolbar>
                  <Stack
                    direction="row"
                    gap="l"
                    justifyContent="end"
                    style={{ flex: 1 }}
                  >
                    {marketPlaceLogos}
                  </Stack>
                </ScrollableToolbar>
                <div style={{ display: 'flex', flexShrink: 0 }}>
                  {hasEventsEditPermission && (
                    <EditIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      onClick={onEventIdsEditClick}
                    />
                  )}
                </div>
              </Stack>
            )}
          </Stack>
        </SummaryTile.Body>
      </SummaryTile.Root>
      {marketplaceEventEditDialog}
    </div>
  );
};
