import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { vars } from 'src/core/themes';
import { Switch, Tooltip } from 'src/core/ui';
import { Stack } from 'src/core/ui';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

import { AutoPricingCompQuantitiesSelector } from '../AutoPricingCompQuantitiesSelector/AutoPricingCompQuantitiesSelector';
import { AutoPricingSectionRowIdFilter } from '../components/AutoPricingSectionRowIdFilter';

type SimplifiedAutopricingSectionProps = {
  listing: Listing | undefined;
};

export const SimplifiedAutopricingSection = ({
  listing,
}: SimplifiedAutopricingSectionProps) => {
  // Hooks
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSections, setShowSections] = useState(false);

  const { showErrorDialog } = useErrorBoundaryContext();

  const { activeAccountWebClientConfig } = useAppContext();

  const hasIntelligibleAutoPricingSettingsFeature = useUserHasFeature(
    Feature.IntelligibleAutoPricingSettings
  );

  // React-hook-form
  const { setValue, getValues, reset } =
    useFormContext<ListingDetailsUpdateInput>();

  const {
    hasChanges,
    autoPricingEnabled,
    canAutoPrice,
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange,
  } = useListingAutoPricingSettings(listing);

  // Mutations
  const {
    updateItemInEvent,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { onUpdateListing } = useEventHubContext();

  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const submitPricingSettingsChange = useCallback(() => {
    setIsSubmitting(true);
    const listForm = getValues();

    if (!listing) {
      return;
    }

    tryInvokeApi(
      async () => {
        const client = new ListingClient(activeAccountWebClientConfig);

        const input: ListingDetailsAutoPricingSectionUpdates = {
          ...listForm,
          id: listing.id,
          rowVersion: null,
          autoPriceFrequency: null,
          compListingQuantityFilters: null,
          compListingQuantityScoreAdjustmentOverrides: null,
          scoreModel: null,
          autoPricingDefaultQuantityFiltersJson: null,
          venueZoneOverrideId: null,
          venueZoneConfigType: null,
        };
        await client.updateListingAutoPricingSection(input);

        // Refersh listing
        const updated = await client.getListingByListingId(
          listing.id,
          hasSectionalLoadDataFeature ? (listing as ListingDetails) : undefined,
          hasSectionalLoadDataFeature
            ? [
                ListingDetailDataField.Pricing, // To refresh pricing related listing fields, i.e. auto pricing enabled
                ListingDetailDataField.AutoPricing,
              ]
            : null
        );
        if (updated) {
          updateItemInEvent(updated, ActionOutboxEntityType.Listing);
          onUpdateListing(updated.id);
        }
      },
      (error) => {
        showErrorDialog(
          'ListingClient.updateListingAutoPricingSection',
          error,
          {
            trackErrorData: {
              item1: listing.id,
              item2: listForm,
            },
          }
        );
      },
      () => {
        setIsSubmitting(false);
        reset(listForm);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getValues,
    listing,
    activeAccountWebClientConfig,
    refreshExpandedListItems,
    showErrorDialog,
  ]);

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange
  );

  const showSave = listing != null && hasChanges(getValues());

  return canAutoPrice && listing ? (
    <Stack direction="column" gap="m" alignItems="start" width="full">
      <Stack gap="l" alignItems="center" width="full">
        <Stack gap="s" alignItems="center">
          {hasIntelligibleAutoPricingSettingsFeature ? (
            <>
              <Content id={ContentId.QuantityAbbreviated} />

              <AutoPricingCompQuantitiesSelector
                disabled={false}
                rootStyle={{ width: '90px' }}
              />
            </>
          ) : (
            <>
              <Content id={ContentId.QuantityAbbreviated} />
              <PosMultiSelect
                disabled={listing == null || isSubmitting}
                align="start"
                values={quantitiesFilterValue ?? []}
                onChange={onUpdateQuantitiesFilter}
                valueOptionsContent={quantitiesFilterOptions}
              />
            </>
          )}
        </Stack>
        <Stack
          gap="s"
          alignItems="center"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          <Tooltip
            triggerContent={
              <Stack gap="s" alignItems="center">
                <Content id={ContentId.ShowSections} />
                <InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />
              </Stack>
            }
            contentVariant="dark"
          >
            <Content id={ContentId.ShowSectionsInfo} />
          </Tooltip>
          <Switch
            disabled={listing == null || isSubmitting}
            checked={showSections}
            onChange={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              setShowSections(isChecked);
            }}
          />
        </Stack>
        <Stack gap="s" alignItems="center">
          <Tooltip
            triggerContent={
              <Stack gap="s" alignItems="center">
                <Content id={ContentId.Autopricing} />
                <InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />
              </Stack>
            }
            contentVariant="dark"
          >
            <Content id={ContentId.AutopricingInfo} />
          </Tooltip>
          <Switch
            disabled={listing == null || isSubmitting}
            checked={Boolean(autoPricingEnabled)}
            onChange={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              setValue('autoPricingEnabled', isChecked);
            }}
          />
        </Stack>
        {showSave && (
          <OkButton
            onClick={() => {
              submitPricingSettingsChange();
            }}
            textContentId={ContentId.Save}
            disabled={isSubmitting}
          />
        )}
      </Stack>

      {showSections && (
        <AutoPricingSectionRowIdFilter
          listing={listing}
          disabled={listing == null || isSubmitting}
        />
      )}
    </Stack>
  ) : null;
};
