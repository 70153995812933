import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox/Checkbox';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './TicketGroupInputTable.css';

export const TicketGroupInputTableHeader = ({
  isTotalCostChecked,
  setIsTotalCostChecked,
}: {
  isTotalCostChecked: boolean;
  setIsTotalCostChecked: (value: React.SetStateAction<boolean>) => void;
}) => {
  return (
    <div className={styles.tableHeader}>
      <Content id={ContentId.TicketGroups} />
      <div className={styles.headerCheckboxSection}>
        <Checkbox
          checked={isTotalCostChecked}
          onChange={() => {
            setIsTotalCostChecked((prev) => !prev);
          }}
          className={styles.headerCheckbox}
        />
        Add Total Costs
      </div>
    </div>
  );
};
