import { useState } from 'react';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext/Content';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { DeliveryType } from 'src/WebApiController';

import * as styles from './TicketGroupInputTable.css';

export const TicketGroupInputTableFooter = ({
  append,
}: {
  append: UseFieldArrayAppend<PurchaseTicketsInput, 'ticketGroups'>;
}) => {
  const { getValues } = useFormContext<PurchaseTicketsInput>();

  const [numOfAddRows, setNumOfAddRows] = useState(3);

  return (
    <div className={styles.tableFooter}>
      <PosTextField
        rootProps={{
          style: {
            width: 60,
            padding: `0 8px`,
            fontSize: vars.typography.fontSize.base,
          },
        }}
        type="number"
        value={numOfAddRows}
        onChange={(e) => setNumOfAddRows(Number(e.target.value))}
      />
      <Button
        variant="textPlain"
        onClick={() => {
          const { ticketGroups } = getValues();
          const newRows = Array.from({ length: numOfAddRows }, (_, index) => {
            const id = newBigIntId() + index;
            return {
              ...ticketGroups[0],
              ticketGroupId: id,
              section: posChangedField(''),
              sectionId: posChangedField(null),
              row: posChangedField(null),
              listingNotes: posChangedField(null),
              numberOfTickets: posChangedField(null),
              tickets: posChangedField(null),
              unitCost: posChangedField(null),
              totalCost: posChangedField(null),
              faceValueCost: posField(null),
              expectedValue: posChangedField(null),
              deliveryType: posChangedField(DeliveryType.InApp),
            };
          });

          append(newRows);
        }}
        style={{ fontSize: vars.typography.fontSize.base }}
      >
        <Content id={ContentId.AddRow} />
      </Button>
    </div>
  );
};
