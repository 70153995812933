import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useUnmount } from 'react-use';
import {
  Content,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  FirstTimeFeature,
  useServerUserSetting,
} from 'src/hooks/useUserSetting';
import { MapIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { VENUE_ZONE_CONFIG_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  EventMapConfigMetadata,
  Feature,
  UserSetting,
  VenueZoneConfigType,
} from 'src/WebApiController';

import * as styles from './NewEventMapBanner.css';

export const NewEventMapBanner = ({
  openEventMapView,
  eventMapConfigQuery,
}: {
  openEventMapView: () => void;
  eventMapConfigQuery: UseQueryResult<EventMapConfigMetadata | null, Error>;
}) => {
  const hasEventMapRibbonYoloFeature = useUserHasFeature(
    Feature.EventMapRibbonYolo
  );
  const { value: firstTimeFeaturesViewed, setUserSetting } =
    useServerUserSetting<FirstTimeFeature[]>({
      id: UserSetting.FirstTimeFeaturesViewed,
    });
  const configTypeContentId = useMemo(() => {
    if (!eventMapConfigQuery.data) {
      return VENUE_ZONE_CONFIG_TYPE_TO_CID[
        VenueZoneConfigType.SportsOrConcertsWith360
      ];
    }
    return VENUE_ZONE_CONFIG_TYPE_TO_CID[
      eventMapConfigQuery.data.selectedVenueMapConfigType ??
        eventMapConfigQuery.data.defaultVenueZoneConfigType
    ];
  }, [eventMapConfigQuery.data]);

  const configTypeString = useContent(configTypeContentId);
  const configTypeFormatted = useMemo(() => {
    if (!eventMapConfigQuery?.data) {
      return null;
    }

    if (
      eventMapConfigQuery.data.selectedVenueMapConfigType != null &&
      eventMapConfigQuery.data.selectedVenueMapConfigType !=
        VenueZoneConfigType.SportsOrConcertsWith360
    ) {
      return `${configTypeString}`;
    }

    return `${configTypeString}°`;
  }, [configTypeString, eventMapConfigQuery]);

  const defaultConfigType = useFormattedContent(
    FormatContentId.FirstTimeVenueEventSetup,
    configTypeFormatted ?? ''
  ) as string;

  const showBanner = useMemo(() => {
    if (
      hasEventMapRibbonYoloFeature &&
      firstTimeFeaturesViewed &&
      firstTimeFeaturesViewed.includes(FirstTimeFeature.EventMapBanner)
    ) {
      // already viewed
      return false;
    }
    // Wait for the event map config to be loaded
    if (eventMapConfigQuery.isLoading || eventMapConfigQuery.isError) {
      return false;
    }

    // There is not yet an event map config override
    return (
      eventMapConfigQuery.data &&
      eventMapConfigQuery.data.selectedOverrideId == null &&
      eventMapConfigQuery.data.selectedVenueMapConfigType == null
    );
  }, [
    eventMapConfigQuery.data,
    eventMapConfigQuery.isError,
    eventMapConfigQuery.isLoading,
    firstTimeFeaturesViewed,
    hasEventMapRibbonYoloFeature,
  ]);

  useUnmount(() => {
    if (showBanner && hasEventMapRibbonYoloFeature) {
      // mark viewed when banner presents
      setUserSetting(
        Array.from(
          new Set([
            ...(firstTimeFeaturesViewed ?? []),
            FirstTimeFeature.EventMapBanner,
          ])
        )
      );
    }
  });

  if (!showBanner) {
    return null;
  }

  return (
    <div className={styles.bannerContainer}>
      <Stack alignItems="center" justifyContent="spaceBetween">
        <Stack alignItems="center" gap="m">
          <MapIcon size={vars.iconSize.m} />
          <span>{defaultConfigType}</span>
        </Stack>

        <Button variant="link" onClick={openEventMapView}>
          <Content id={ContentId.ViewEventMap} />
        </Button>
      </Stack>
    </div>
  );
};
