import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  ReportMetricsResponse,
  ReportValueTypesReponse,
} from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';

import { commissionReportTableColumnDef } from './configs/CommissionReportTableColumnsConfig';

type CommissionReportTableColumnDefContextType = {
  displayedColumnsConfig: ColumnDef<ReportMetricsResponse | null>[] | undefined;
};

const CommissionReportTableColumnDefContext =
  createContext<CommissionReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const CommissionReportTableColumnDefContextV2 = ({
  report,
  reportValueTypes,
  children,
}: PropsWithChildren<{
  report: ReportConfigV2;
  reportValueTypes: ReportValueTypesReponse | undefined;
}>) => {
  const displayedColumnsConfig = useMemo(() => {
    const columns = [
      ...(report.request.rowGroupings?.map((c) => c.columnName) ?? []),
      ...(report.request.aggregations?.map((c) => c.columnName) ?? []),
    ];

    return columns.map((columnId) =>
      commissionReportTableColumnDef(
        columnId,
        reportValueTypes?.[columnId.toUpperCase()]
      )
    );
  }, [
    report.request.aggregations,
    report.request.rowGroupings,
    reportValueTypes,
  ]);

  return (
    <CommissionReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: displayedColumnsConfig,
      }}
    >
      {children}
    </CommissionReportTableColumnDefContext.Provider>
  );
};

export const useCommissionReportTableColumnDefV2 = () =>
  useContext(CommissionReportTableColumnDefContext);
