import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ContentId } from 'src/utils/constants/contentId';
import { GridActionType } from 'src/utils/tableUtils';

import { ReportsTable } from './ReportsTable';
import * as styles from './ReportsTableContainer.css';

export function ReportsTableContainer({
  reports,
  reportsV2,
  onReportAction,
}: {
  reports?: ReportConfig[];
  reportsV2?: ReportConfigV2[];
  onReportAction: (
    report: ReportConfig | undefined,
    reportV2: ReportConfigV2 | undefined,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
}) {
  const reportsV2Default = reportsV2?.filter((r) => r.globalReportTypeId) ?? [];
  const reportsV2Custom = reportsV2?.filter((r) => !r.globalReportTypeId) ?? [];

  const reportsDefault =
    reports?.filter(
      (r) =>
        r.globalReportTypeId &&
        !reportsV2Default.some(
          (rv2) => rv2.globalReportTypeId === r.globalReportTypeId
        ) // Avoid duplicates in V2 reports. Show v2 reports when possible
    ) ?? [];
  const reportsCustom = reports?.filter((r) => !r.globalReportTypeId) ?? [];

  return (
    <div className={styles.mainContent}>
      <div className={styles.reportTableContainer}>
        {reportsDefault.length + reportsV2Default.length > 0 && (
          <Stack direction="column">
            <h2
              className={styles.subHeader}
              style={{
                paddingTop: vars.spacing['lg'],
                paddingBottom: vars.spacing['lg'],
              }}
            >
              <Content id={ContentId.DefaultReports} />
            </h2>
            <ReportsTable
              reports={reportsDefault}
              reportsV2={reportsV2Default}
              onReportAction={onReportAction}
              isGlobalSection
              initState={{
                sorting: [{ id: 'reportName', desc: false }],
              }}
            />
          </Stack>
        )}

        <Stack direction="column">
          <h2
            className={styles.subHeader}
            style={{
              paddingTop: vars.spacing['xl'],
              paddingBottom: vars.spacing['lg'],
            }}
          >
            <Content id={ContentId.CustomReports} />
          </h2>
          <ReportsTable
            reports={reportsCustom}
            reportsV2={reportsV2Custom}
            onReportAction={onReportAction}
            initState={{
              sorting: [{ id: 'reportName', desc: false }],
            }}
          />
        </Stack>
      </div>
    </div>
  );
}
