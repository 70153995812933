import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getDefaultReturnUrl } from 'src/components/Events/EventPage/utils';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { PurchaseEventPage } from 'src/components/Purchases/PurchaseEventPage/PurchaseEventPage';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { queryIdSearchParam } from 'src/navigations/Routes/InventoryEvent/constants';
import {
  EventIdQueryParam,
  PurchaseDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  PURCHASE_USER_SETTINGS,
  purchaseQueryValueTransformFromUrl,
  purchaseQueryValueTransformToUrl,
} from 'src/utils/purchaseUtils';
import { addTicketGroupMetrics } from 'src/utils/ticketMetricUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import {
  ActionOutboxEntityType,
  Feature,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  TicketGroupMetrics,
  UserSetting,
} from 'src/WebApiController';

import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  POS_EVENT_IDS_SEPARATOR,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_SEPARATOR,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';

export function PurchaseEvent() {
  const [searchParams] = useSearchParams();
  const oldPosEventId = searchParams.get(queryIdSearchParam);
  const viagEventId = searchParams.get(EventIdQueryParam);

  const activeEventIds = searchParams.get(ACTIVE_EVENT_IDS_QUERY_PARAM);
  const tabEventIds = searchParams.get(TAB_EVENT_IDS_QUERY_PARAM);

  const activeEventIds2 = searchParams.get(ACTIVE_EVENT_IDS_2_QUERY_PARAM);
  const tabEventIds2 = searchParams.get(TAB_EVENT_IDS_2_QUERY_PARAM);

  const location = useLocation();

  const posEventIds = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      (tabEventIds != null
        ? activeEventIds?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)
        : oldPosEventId?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)) ?? [],
    [activeEventIds, oldPosEventId, tabEventIds]
  );

  const viagogoVirtualId = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      tabEventIds2 != null
        ? activeEventIds2?.toLowerCase()
        : viagEventId?.toLowerCase(),
    [activeEventIds2, tabEventIds2, viagEventId]
  );

  const initialQuery = useMemo(() => {
    if (posEventIds.length || tabEventIds?.length) {
      const oldPosEventIds = tabEventIds
        ? tabEventIds
            .split(TAB_EVENT_IDS_SEPARATOR)
            .flatMap((eids) => eids.split(POS_EVENT_IDS_SEPARATOR))
        : posEventIds;
      return { ...EmptyPurchaseQuery, oldPosEventIds };
    } else if (tabEventIds2?.length || viagogoVirtualId) {
      const eventOrMappingIds = tabEventIds2
        ? tabEventIds2.split(TAB_EVENT_IDS_SEPARATOR)
        : [viagogoVirtualId!];
      return { ...EmptyPurchaseQuery, eventOrMappingIds };
    }

    return { ...EmptyPurchaseQuery };
  }, [posEventIds, tabEventIds, tabEventIds2, viagogoVirtualId]);

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: PurchaseOrderQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('PurchaseClient.getTicketGroupsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  // Only use value provided at the first time (should be from '/purchases')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('purchases', viagEventId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      PurchaseDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state, viagEventId]);

  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  if (
    !posEventIds.length &&
    !tabEventIds?.length &&
    !tabEventIds2?.length &&
    !viagogoVirtualId
  ) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={PURCHASE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<PurchaseOrderQuery>
        initialQuery={initialQuery}
        emptyQuery={EmptyPurchaseQuery}
        viewModeSettingId={UserSetting.PurchasePageViewMode}
        saveQueryInUrl
        queryValueTransformToUrl={purchaseQueryValueTransformToUrl}
        queryValueTransformFromUrl={purchaseQueryValueTransformFromUrl}
      >
        <CatalogDataContextProvider<PurchaseOrderQuery>
          entityType={ActionOutboxEntityType.Purchase}
          queryKey="getCatalogForPurchaseOrders"
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          transformEventData={transformData}
        >
          <CatalogMetricsContextProvider<TicketGroupMetrics, PurchaseOrderQuery>
            queryKey="getCatalogTicketGroupMetrics"
            entityType={ActionOutboxEntityType.Purchase}
            getCatalogMetrics={(c, f, r) =>
              getCatalogMetrics(c, f, r, hasMetricsV2Feature)
            }
            addCatalogMetrics={addTicketGroupMetrics}
          >
            <ActivePosEntityProvider<PurchaseOrderDetails>
              entityType={ActionOutboxEntityType.Purchase}
              getActivePosEntity={getActivePosEntity}
            >
              <CatalogMultiSelectionContextProvider type="ticketGroup">
                <MultiSelectionContextProvider>
                  <DialogProvider>
                    <LayoutContent
                      mainRoute={MainRoute.PurchasesEvent}
                      routeTitle=""
                    >
                      <MainFilterBar
                        isEventPage
                        returnUrl={returnUrl}
                        posEventId={posEventIds[0]}
                      />
                      <PurchaseEventPage
                        posEventIds={posEventIds}
                        viagVirtualId={viagogoVirtualId}
                        returnUrl={returnUrl}
                      />
                    </LayoutContent>
                  </DialogProvider>
                </MultiSelectionContextProvider>
              </CatalogMultiSelectionContextProvider>
            </ActivePosEntityProvider>
          </CatalogMetricsContextProvider>
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}
