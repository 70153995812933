import { useReportMetricsContextV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ListingReportTableV2 } from 'src/tables/ListingTable/ListingReportTableV2';

import { ReportsPageLayout } from '../Reports/ReportsPageLayout';
import * as styles from './ReportsInventoryPage.css';

export function ReportsInventoryPageV2({
  reportConfig,
}: {
  reportConfig: ReportConfigV2;
}) {
  const { reportMetrics, reportSummary, reportValueTypes } =
    useReportMetricsContextV2();

  return (
    <ReportsPageLayout
      reportConfig={reportConfig}
      reportTable={
        <ListingReportTableV2
          className={styles.table}
          report={reportConfig}
          metricsData={reportMetrics}
          summaryData={reportSummary}
          reportValueTypes={reportValueTypes}
        />
      }
    />
  );
}
