import { MouseEvent } from 'react';
import { useToggle } from 'react-use';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { InventoryGlobalBulkActions } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions';
import { SetInventoryFlattenedViewSelectionCountLabel } from 'src/components/Events/EventListing/InventoryEventListing/SetInventoryFlattenedViewSelectionCountLabel';
import { Content } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { InventorySearchResult } from 'src/WebApiController';

import { container } from './InventorySearchToolbar.css';

export const InventorySearchToolbar = ({
  disabled,
}: {
  disabled?: boolean;
  setDisabled?: (d: boolean) => void;
  activeSearchConfig?: InventorySearchResult;
}) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <div style={{ flex: 1 }}>
        <SetInventoryFlattenedViewSelectionCountLabel>
          {!disabled && (
            <MultiSelectActionBar
              style={{ padding: 0 }}
              groupId={NO_GROUP_ID}
              displayMode="count-only"
              showHideOnSelection
            >
              <InventoryGlobalBulkActions />
            </MultiSelectActionBar>
          )}
        </SetInventoryFlattenedViewSelectionCountLabel>
      </div>

      <Stack gap="m" alignItems="center" justifyContent="end">
        <Button
          variant="textPlain"
          style={{ paddingRight: vars.spacing.xxs }}
          onClick={onColumnSettingButtonClickHandler}
        >
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomListingColumn>
          onClose={toggleColumnModal}
          sectionType={SectionType.ListingsFlattened}
          showTicketClassColor
        />
      ) : null}
    </Stack>
  );
};
