import { Row } from '@tanstack/react-table';
import { useRef } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useKeyPress } from 'src/hooks/useKeyPress';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';

export const useSingleTableShortcutSelection = <T,>({
  isSideTable,
}: {
  isSideTable?: boolean;
}) => {
  const { mainRoute } = useAppContext();
  const inFullPageEventView = isInFullPageEventView(mainRoute);

  const lastSelectedRowRefIndex = useRef<number | null>(null);
  const isShiftKeyPressed = useKeyPress('Shift');
  const isCtrlKeyPressed = useKeyPress('Control');
  const isCmdKeyPressed = useKeyPress('Meta');

  const isCtrlOrCmdKeyPressed = isCtrlKeyPressed || isCmdKeyPressed;

  function updateSelectionForRowAndSubRows({
    row,
    rowId,
    newIsSelected,
    newRowSelection,
  }: {
    row: Row<T | null>;
    rowId: string;
    newIsSelected: boolean;
    newRowSelection: Record<string, boolean>;
  }) {
    newRowSelection[rowId] = newIsSelected;

    if (row.subRows && row.subRows.length > 0) {
      row.subRows.forEach((subRow) => {
        updateSelectionForRowAndSubRows({
          row: subRow,
          rowId: subRow.id,
          newIsSelected,
          newRowSelection,
        });
      });
    }
  }

  return {
    allowShiftClickSelection: inFullPageEventView && !isSideTable,
    isShiftKeyPressed,
    isCtrlOrCmdKeyPressed,
    lastSelectedRowRefIndex,
    updateSelectionForRowAndSubRows,
  };
};
