import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  ListingClient,
  ListingDetailsPricingUpdates,
} from 'src/WebApiController';

import { useAutoPricingSidePanelContext } from '../../../AutoPricingSidePanel';

type AutoPricingPreviewQueryKeyType =
  | {
      listingId: number | undefined;
      eventId: number | null;
      priceUpdates: ListingDetailsPricingUpdates;
    }
  | undefined;

export const useAutoPricingPreview = (
  viagogoEventId: number | null,
  listingId?: number | undefined
) => {
  const { metadata } = useAutoPricingSidePanelContext();

  const priceUpdates = useMemo(() => {
    const {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } = metadata.input ?? {};

    return {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } as ListingDetailsPricingUpdates;
  }, [metadata.input]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [queryKey, setQueryKey] =
    useState<AutoPricingPreviewQueryKeyType>(undefined);

  useDebounce(
    () =>
      setQueryKey({
        eventId: viagogoEventId,
        listingId,
        priceUpdates: priceUpdates,
      }),
    300,
    [viagogoEventId, listingId, JSON.stringify(priceUpdates)]
  );

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId != null &&
      queryKey?.eventId &&
      queryKey?.listingId &&
      queryKey?.priceUpdates
  );

  const priceCalcQuery = useQuery({
    queryKey: ['ListingClient.getListingPriceCalculation', queryKey],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const r = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingPriceCalculation(
        queryKey?.eventId ?? undefined,
        queryKey?.listingId ?? undefined,
        queryKey?.priceUpdates!
      );
      return r;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError(
          'ListingClient.getListingPriceCalculation',
          error,
          priceUpdates
        );
      },
    },
  });

  return { priceCalcQuery, priceUpdates };
};
