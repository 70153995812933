import { EventAccordionItemProps } from 'src/components/Accordions';
import { FormatContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetMarketTransactionsForEvent } from 'src/hooks/api/useGetMarketTransactionsForEvent';
import { MarketTransactionsTable } from 'src/tables/MarketTransactionsTable';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventWithData, Listing } from 'src/WebApiController';

import * as styles from '../InventoryEventPage.css';

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps<Listing>;
};

export function InventoryEventTransactionsSection({
  accordionItemProps,
  inventoryAccordionItem,
}: InventoryEventContentProps) {
  const { BodyComponent } = inventoryAccordionItem || {};

  const event = accordionItemProps?.event;

  const { transactionsQuery } = useGetMarketTransactionsForEvent(event);

  return (
    <div className={styles.tableSection}>
      {BodyComponent && accordionItemProps && (
        <div className={styles.tableContainer}>
          {transactionsQuery.isLoading ? (
            <PosSpinner />
          ) : (
            <>
              <MarketTransactionsTable
                marketTransactions={transactionsQuery.data}
              />
              {transactionsQuery.data?.length ? (
                <FormatContent
                  id={FormatContentId.TheseAreThePastSalesOnTheWebsiteAndApp}
                  params={[(transactionsQuery.data?.length ?? 0).toString()]}
                />
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
}
