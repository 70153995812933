import { useCallback, useContext } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useEventMapConfigMetadata } from 'src/hooks/api/useEventMapConfigMetadata';
import { EventPricingSeatMap } from 'src/modals/EventPricingSeatMap';
import { CompanionIcon, ConfigureIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';

import { EventVenueMapModal } from '../../../EventVenueMap/EventVenueMapModal';
import { EventVenueMapPopup } from '../../../EventVenueMap/EventVenueMapPopup';
import { SidePanelTab } from '../../../MarketListings/MarketListingHeader';
import * as styles from './MapHeader.css';

type MapHeaderProps = {
  useVerticalView: boolean;
};

export const MapHeader: React.FC<MapHeaderProps> = ({ useVerticalView }) => {
  const { event, selectedTab, onTabClick } = useInventoryEventDetailContext();
  const { setModal } = useContext(ModalContext);

  const { onStartSession, onCancelSession, sessionRole, enabled } =
    useEventHubContext();

  const onToggleComapnionMode = useCallback(() => {
    if (sessionRole) {
      onCancelSession();
    } else {
      onStartSession();
    }
  }, [onStartSession, onCancelSession, sessionRole]);

  const marketListingsText = useContent(ContentId.MarketListings);
  const salesText = useContent(ContentId.Sales);
  const purchasesText = useContent(ContentId.Purchases);
  const notesText = useContent(ContentId.Notes);
  const enterCompanionModeText = useContent(ContentId.EnterCompanionMode);

  const tabs = [
    { label: marketListingsText, value: SidePanelTab.LISTINGS },
    { label: salesText, value: SidePanelTab.SALES },
    { label: purchasesText, value: SidePanelTab.PURCHASES },
    { label: notesText, value: SidePanelTab.NOTES },
  ];

  const openEventMapView = useCallback(() => {
    setModal({
      children: <EventVenueMapModal event={event!} />,
      size: 'fullscreen',
      fullscreen: true,
    });
  }, [event, setModal]);

  const { eventMapConfigQuery } = useEventMapConfigMetadata({
    viagogoEventId: event?.viagId ?? null,
  });

  return (
    <div className={styles.headerContainer}>
      <Stack alignItems="center" gap="s">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            onClick={() => onTabClick(tab.value)}
            className={`${styles.tabButton} ${
              selectedTab === tab.value ? styles.activeTab : ''
            }`}
          >
            {tab.label}
          </button>
        ))}
      </Stack>

      {event && selectedTab == SidePanelTab.LISTINGS && (
        <Stack gap="s" alignItems="center">
          {event && (
            <EventVenueMapPopup
              onClick={openEventMapView}
              eventMapConfigQuery={eventMapConfigQuery}
              inCondensedView
              iconOnly={useVerticalView}
            />
          )}
          <CompanionIcon
            withHoverEffect
            size={vars.iconSize.xl}
            className={styles.companionIcon}
            onClick={onToggleComapnionMode}
            title={enterCompanionModeText}
          />
          <ConfigureIcon
            withHoverEffect
            size={vars.iconSize.xl}
            className={styles.configureIcon}
            onClick={() =>
              setModal({
                children: <EventPricingSeatMap event={event} />,
                clickOutsideToClose: true,
                size: 'slide-in',
                keyboard: false,
              })
            }
          />
        </Stack>
      )}
    </div>
  );
};
