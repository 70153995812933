import { ComponentProps, PropsWithChildren } from 'react';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';

import { ActionOutboxEntityTypeForBulkEdit } from './bulkEditUtils';
import { BulkEditErrorList } from './components/BulkEditErrorList';
import {
  BulkEditGettingPreview,
  BulkEditPreviewDisplay,
} from './components/BulkEditPreviewDisplay';
import { BulkEditProgressDisplay } from './components/BulkEditProgressDisplay';
import { BulkEditWarningList } from './components/BulkEditWarningList/BulkEditWarningList';

export type BulkEditStatusProps = {
  isLoading?: boolean;
  entityType: ActionOutboxEntityTypeForBulkEdit;
  updateKey: string;
  entityUpdatesPreview?: React.ReactNode;
} & Pick<ComponentProps<typeof Stack>, 'height'>;

export const BulkEditStatus = ({
  children,
  height,
  entityType,
  isLoading,
  entityUpdatesPreview,
}: PropsWithChildren<BulkEditStatusProps>) => {
  const {
    progress,
    progressBeforeRetry,
    errors,
    warnings,
    preview,
    retryableEntities,
    stage,
  } = useBulkEditHubContext();

  return (
    <Stack direction="column" gap="l" height={height}>
      {stage === BulkEditStage.GettingPreview ? (
        <BulkEditGettingPreview progress={progress} />
      ) : preview && stage === BulkEditStage.Preview ? (
        <BulkEditPreviewDisplay
          entityUpdatesPreview={entityUpdatesPreview}
          entityType={entityType}
          preview={preview}
        />
      ) : stage === BulkEditStage.Idle &&
        retryableEntities &&
        progressBeforeRetry ? (
        errors?.length ? (
          <>
            <BulkEditProgressDisplay
              entityType={entityType}
              // Report the progress before the retry
              {...progressBeforeRetry}
              retryableEntities={null}
            />
            <BulkEditErrorList errors={errors!} />
          </>
        ) : (
          <BulkEditProgressDisplay
            entityType={entityType}
            // Report the progress before the retry
            {...progressBeforeRetry}
          />
        )
      ) : progress != null ? (
        <>
          <BulkEditProgressDisplay {...progress} />

          {Boolean(warnings?.length) && (
            <BulkEditWarningList warnings={warnings!} />
          )}

          {Boolean(errors?.length) && <BulkEditErrorList errors={errors!} />}
        </>
      ) : isLoading ? (
        <PosSpinner />
      ) : (
        // Only display children if there's no progress
        children
      )}
    </Stack>
  );
};
