import { useAppContext } from 'src/contexts/AppContext';
import {
  hasPermissionForPurchase,
  PurchaseInfoForPermission,
} from 'src/utils/userPermissionUtils';
import { Permission } from 'src/WebApiController';

export const useUserHasPurchasePermission = (
  purchase: PurchaseInfoForPermission | null | undefined,
  root: Permission,
  owned?: Permission,
  allowForNewCreate = true
) => {
  const { loginContext } = useAppContext();

  const canUpdate = hasPermissionForPurchase(
    loginContext?.user,
    purchase,
    root,
    owned,
    allowForNewCreate
  );

  return canUpdate;
};

export const useUserCanUpdatePurchase = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned
  );
};

export const useUserCanUpdatePurchaseTags = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_UpdateTags,
    Permission.Purchases_UpdateTagsOwned
  );
};

export const useUserCanUpdatePurchaseCommission = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_UpdateBuyerCommission,
    Permission.Purchases_UpdateBuyerCommissionOwned
  );
};

export const useUserCanLockPurchaseCommission = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_LockUnlockCommission,
    Permission.Purchases_LockUnlockCommissionOwned
  );
};

export const useUserCanUpdatePurchaseDeliveryType = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_UpdateDeliveryType,
    Permission.Purchases_UpdateDeliveryTypeOwned
  );
};

export const useUserCanUpdatePurchaseCustomDeliveryType = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_UpdateCustomDeliveryType,
    Permission.Purchases_UpdateCustomDeliveryTypeOwned,
    false // allowForNewCreate
  );
};

export const useUserCanUpdatePurchaseSeatTraits = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Purchases_UpdateSeatTraits,
    Permission.Purchases_UpdateSeatTraitsOwned
  );
};

export const useUserCanUpdateListingTicketTypePriorityFromPurchase = (
  purchase: PurchaseInfoForPermission | null | undefined
) => {
  return useUserHasPurchasePermission(
    purchase,
    Permission.Inventory_UpdateDeliveryStrategy,
    Permission.Inventory_UpdateDeliveryStrategyOnPurchasedBy
  );
};
