import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE_LOCAL } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext.constants';
import {
  FormatOption,
  FormatOption_Percent,
} from 'src/modals/EditTableColumns/EditTableColumns.constants';

import { stringToUtcDate } from './dateTimeUtils';
import { getCurrentLanguage } from './localeUtils';

// From .NET
export const INT_MAX_VALUE = 2147483647;

/**
 * @param n Number to round
 * @param decimalPlaces number of decimal places to keep
 * @returns
 */
export const roundToPrecision = (n: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(n * factor) / factor;
};

export const formatNumber = (
  n: number,
  decimal = 0,
  locale: string = getCurrentLanguage(),
  isUnbounded?: boolean
) => {
  return n.toLocaleString(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: !isUnbounded ? decimal : undefined,
    style: 'decimal',
  });
};

export const formatPercent = (
  n: number,
  decimal = 0,
  locale: string = getCurrentLanguage(),
  isUnbounded?: boolean
) => {
  return n.toLocaleString(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: !isUnbounded ? decimal : undefined,
    style: 'percent',
  });
};

export const formatCurrency = (
  n: number | null | undefined,
  currencyCode?: string | null,
  decimal?: number | null,
  locale?: string | null,
  isUnbounded?: boolean
) => {
  n = n ?? 0;
  const result = n.toLocaleString(locale ?? getCurrentLanguage(), {
    minimumFractionDigits: decimal ?? 2,
    maximumFractionDigits: !isUnbounded ? decimal ?? 2 : undefined,
    style: 'currency',
    currency: currencyCode ?? 'USD',
  });

  return result;
};

const precisionOptionMappings: Partial<
  Record<
    FormatOption,
    { suffix?: string; value?: number; roundToDigits?: number }
  >
> = {
  [FormatOption.Millions]: {
    suffix: 'M',
    value: 1_000_000,
    roundToDigits: 1,
  },
  [FormatOption.Thousands]: {
    suffix: 'k',
    value: 1_000,
    roundToDigits: 1,
  },
  [FormatOption.Whole]: { roundToDigits: 0 },
  [FormatOption.Precise]: { roundToDigits: 2 },
};

type DecimalOption = {
  displayAsDecimal?: boolean;
  roundToDigits: number;
};

const DECIMAL_OPTION_MAPPINGS: Record<FormatOption_Percent, DecimalOption> = {
  [FormatOption.Percent_RoundToTwo]: { roundToDigits: 2 },
  [FormatOption.Percent_RoundToOne]: { roundToDigits: 1 },
  [FormatOption.Percent_RoundToWhole]: { roundToDigits: 0 },
  [FormatOption.Percent_AsDecimal_RoundToOne]: {
    displayAsDecimal: true,
    roundToDigits: 1,
  },
  [FormatOption.Percent_AsDecimal_RoundToTwo]: {
    displayAsDecimal: true,
    roundToDigits: 2,
  },
  [FormatOption.Percent_AsDecimal_RoundToThree]: {
    displayAsDecimal: true,
    roundToDigits: 3,
  },
};

/**
 * Supported options types: `DateTime`, `Percent`, `Standard`
 * Note: using NumericalPrecisionOption.DateTime_RelativeDateTime will result in unlocalized text,
 * currently hardcoded as English translations. Invoke getTimeDateDifferenceAsShorthand directly in this case.
 */
export const applyNumberFormatting = ({
  inputNumber,
  isDateTime,
  currencyCode,
  currencyDecimalDigits,
  isPercent,
  enableUtcFallback,
  localeText,
  precision = isDateTime
    ? FormatOption.DateTime_MonthDD_Time
    : isPercent
    ? FormatOption.Percent_RoundToTwo
    : FormatOption.Precise,
  timeZone,
}: {
  inputNumber?: number | string | null;
  precision?: FormatOption;
  currencyCode?: string | null;
  currencyDecimalDigits?: number | null;
  isPercent?: boolean;
  isDateTime?: boolean;
  enableUtcFallback?: boolean;
  localeText?: {
    /**
     * `Ago` text
     */
    ago: string;
    /**
     * `In` text
     */
    in: string;
    /**
     * `Now` text
     */
    now: string;
  };
  timeZone?: string;
}) => {
  if (inputNumber == null) return inputNumber;

  if (isDateTime) {
    const inputDate = enableUtcFallback
      ? stringToUtcDate(String(inputNumber))
      : new Date(String(inputNumber));

    let formatStr = '';

    switch (precision) {
      case FormatOption.DateTime_MMDD:
        formatStr = 'MM/dd';
        break;
      case FormatOption.DateTime_MMDD_Time:
        formatStr = 'MM/dd h:mma';
        break;
      case FormatOption.DateTime_MMDD_Time_Day:
        formatStr = 'MM/dd h:mma, EEE';
        break;
      case FormatOption.DateTime_MonthDD:
        formatStr = 'MMM d';
        break;
      case FormatOption.DateTime_MonthDD_Time:
        formatStr = 'MMM d, h:mma';
        break;
      case FormatOption.DateTime_MonthDD_Time_Day:
        formatStr = 'MMM d, h:mma, EEE';
        break;
      case FormatOption.DateTime_MMDDYY:
        formatStr = 'MM/dd/yy';
        break;
      case FormatOption.DateTime_Month_DD_YY:
        formatStr = "MMM d ''yy";
        break;
      case FormatOption.DateTime_Month_DD_YY_Time:
        formatStr = "MMM d ''yy, h:mma";
        break;
      case FormatOption.DateTime_Month_DD_YY_Time_Day:
        formatStr = "MMM d ''yy, h:mma, EEE";
        break;
      default:
        formatStr = 'MM/dd';
    }

    return formatInTimeZone(
      inputDate,
      timeZone ?? DEFAULT_TIMEZONE_LOCAL,
      formatStr
    );
  }

  if (isPercent) {
    const defaultDecimalOption: DecimalOption = {
      displayAsDecimal: true,
      roundToDigits: 1,
    };
    const { displayAsDecimal = false, roundToDigits } =
      DECIMAL_OPTION_MAPPINGS[precision as FormatOption_Percent] ??
      defaultDecimalOption;

    // Display as decimal means to not interpret as a percentage
    const value = displayAsDecimal ? +inputNumber : +inputNumber * 100;
    const formattedNumber = formatNumber(value, roundToDigits);
    const suffix = displayAsDecimal ? '' : '%';

    return `${formattedNumber}${suffix}`;
  }

  const {
    suffix = '',
    value: precisionValue = 1,
    roundToDigits,
  } = precisionOptionMappings[precision] ?? {};

  const value = +inputNumber / precisionValue;

  if (currencyCode && precision === FormatOption.Precise) {
    return (
      formatCurrency(
        value,
        currencyCode,
        precision === FormatOption.Precise
          ? currencyDecimalDigits ?? undefined
          : roundToDigits
      ) + suffix
    );
  }

  // TODO: currently covers whole + thousands + millions, logic to be extended once product provides more info
  if (currencyCode && precision) {
    return formatCurrency(value, currencyCode, roundToDigits) + suffix;
  }

  // TODO: apply number modifications based on input
  return formatNumber(value, roundToDigits, undefined) + suffix;
};

/**
 * Returns a shorthand version of the difference between two dates (or one date and now)
 */
type GetTimeDateDifferenceAsShorthand = (arg: {
  /**
   * Input UTC Date
   */
  inputDate?: Date | null;
  /**
   * Date to compare to- defaults as now
   */
  dateToCompareTo?: Date;
  /**
   * Whether to display just the number and time unit vs relative term (ago/in)
   *
   * @example
   * `false` would show `1m ago`, `true` would show just `1m`
   */
  hideRelativeTerms?: boolean;
  /**
   * When showRelativeTerms is true or undefined, localeText must be provided
   */
  localeText?: {
    /**
     * `Ago` text
     */
    ago: string;
    /**
     * `In` text
     */
    in: string;
    /**
     * `Now` text
     */
    now: string;
  };
}) => string | null;

const MILLISECONDS_IN_SECOND = 1_000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;

export const getTimeDateDifferenceAsShorthand: GetTimeDateDifferenceAsShorthand =
  ({
    inputDate,
    dateToCompareTo = new Date(),
    hideRelativeTerms = false,
    localeText = { ago: 'ago', in: 'in', now: 'just now' },
  }) => {
    if (!inputDate) return null;
    const { ago: agoText, in: inText, now: nowText } = localeText;
    const difference = inputDate.getTime() - dateToCompareTo.getTime();
    const isDateInFuture = difference > 0;
    const relativeSeconds = Math.abs(difference) / MILLISECONDS_IN_SECOND;
    const relativeMinutes = relativeSeconds / SECONDS_IN_MINUTE;
    const relativeHours = relativeMinutes / MINUTES_IN_HOUR;
    const relativeDays = relativeHours / HOURS_IN_DAY;
    const relativeWeeks = relativeDays / DAYS_IN_WEEK;
    // TODO: add months by doing month + year calculations

    const returnWrapper = (value: string) => {
      if (hideRelativeTerms) return value;
      if (isDateInFuture) return `${inText} `.concat(value);
      return value.concat(` ${agoText}`);
    };

    // 0-60 seconds (now)
    if (relativeSeconds < 60) return nowText;
    // 1-120 minutes (1m-120m) - note that it's rounded and not floored
    if (relativeMinutes < 120)
      return returnWrapper(`${Math.round(relativeMinutes)}m`);
    // 1-47 hours (1h-48h)
    if (relativeHours < 48)
      return returnWrapper(`${Math.round(relativeHours)}h`);
    // 1-13 days (1d-14d)
    if (relativeDays < 14) return returnWrapper(`${Math.round(relativeDays)}d`);
    // weeks (2w-_unboundedw)
    return returnWrapper(`${Math.round(relativeWeeks)}w`);
  };

export const numberToOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
