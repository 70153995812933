import { createColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { defaultColumnHelperAccessor } from 'src/tables/ReportTableCommon/DefaultColumnHelperAccessor';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { reportV2AccessorFn } from 'src/tables/ReportTableCommon/ReportTableCommon.utils';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import {
  ListingReportTableGroupingColumnId,
  ListingReportTableValueColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import {
  LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID,
  LISTING_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { ReportValueType } from 'src/WebApiController';

const SECTION_TYPE = SectionType.ListingsReport;

const columnHelper = createColumnHelper<ReportMetricsResponse | null>();

export const LISTING_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG = {
  [ListingReportTableGroupingColumnId.EventId]: columnHelper.accessor(
    reportV2AccessorFn(ListingReportTableGroupingColumnId.EventId),
    {
      id: ListingReportTableGroupingColumnId.EventId,
      header: (params) => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                ListingReportTableGroupingColumnId.EventId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return <TextCell {...params} align={'left'} />;
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableGroupingColumnId.LocalEventDateTime]:
    columnHelper.accessor(
      reportV2AccessorFn(ListingReportTableGroupingColumnId.LocalEventDateTime),
      {
        id: ListingReportTableGroupingColumnId.LocalEventDateTime,
        header: (params) => (
          <TableHeaderCellDiv align={'left'}>
            <Content
              id={
                LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                  ListingReportTableGroupingColumnId.LocalEventDateTime
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_XL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
        }) {
          const value = getValue<string>();
          const { userDefinedPrecision = FormatOption.DateTime_MMDD } =
            useColumnUserSetting(columnId, SECTION_TYPE);

          if (!original) {
            return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
          }

          const dateValue = value ? new Date(value).toISOString() : value;

          return (
            <DateCell
              precision={userDefinedPrecision}
              date={dateValue}
              align="left"
              enableUtcFallback
              hideRelativeTerms
              useSiteTimeZone={false}
            />
          );
        },
      }
    ),
  [ListingReportTableGroupingColumnId.ListingId]: columnHelper.accessor(
    reportV2AccessorFn(ListingReportTableGroupingColumnId.ListingId),
    {
      id: ListingReportTableGroupingColumnId.ListingId,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                ListingReportTableGroupingColumnId.ListingId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const listingId = getValue<string>();

        if (!listingId) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const url = getListingDetailsRelativeUrl(listingId!);
        return (
          <LinkCell
            text={`#${listingId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
} as const;

export const listingReportV2TableColumnDef = (
  columnId: string,
  valueType: ReportValueType | undefined
) => {
  const specialConfig = Object.entries(
    LISTING_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG
  ).find(([key]) => key === columnId)?.[1];

  if (specialConfig) {
    return specialConfig;
  }

  const headerContentId =
    LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
      columnId as ListingReportTableGroupingColumnId
    ] ??
    LISTING_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID[
      columnId as ListingReportTableValueColumnId
    ];

  return defaultColumnHelperAccessor(
    columnHelper,
    columnId,
    headerContentId,
    valueType
  );
};
