import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from 'src/core/interim/dialogs/ConfirmDialog';
import { Stack } from 'src/core/ui';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ContentId } from 'src/utils/constants/contentId';
import { UserSetting } from 'src/WebApiController';

import { contentWrapper } from './AlertWithSuppressionDialog.css';

export type AlertSuppressionSetting = {
  suspiciousPriceSuppressionTimestamp?: Date;
  manualPriceDisableAutoPricingTimestamp?: Date;
  manualPriceAdjustFloorOrCeilingTimestamp?: Date;
  deliveryInfoChangeSuppressionTimestamp?: Date;
  bulkUpdateTicketGroupSuppressionTimestamp?: Date;
  cancelTicketGroupSuppressionTimestamp?: Date;
  mergedGroupListingTimestamp?: Date;
};

export type Alert = {
  bodyText: React.ReactNode;
  key: keyof AlertSuppressionSetting;
  onOkay?: () => void;
  onCancel?: () => void;
};

export type AlertSuppressionSettingProps = Omit<
  ConfirmDialogProps,
  'bodyText'
> & {
  alerts?: Alert[];
};

export type AlertDialogPropsWithTimestamp = {
  dialogProps: AlertSuppressionSettingProps;
  lastTimeStamp: Date | undefined;
};

export const AlertWithSuppressionDialog = ({
  onOkay,
  onCancel,
  alerts,
  headerText,
  okText,
  cancelText,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bodyText,
  ...props
}: AlertSuppressionSettingProps) => {
  const { value, setUserSetting } =
    useServerUserSetting<AlertSuppressionSetting>({
      id: UserSetting.SuspiciousPriceChange,
    });

  const [suppressWarning, setSuppressWarning] = useState<
    Record<keyof AlertSuppressionSetting, boolean>
  >({} as Record<keyof AlertSuppressionSetting, boolean>);
  return (
    <ConfirmDialog
      size="md"
      headerText={headerText}
      bodyText={
        <div className={contentWrapper}>
          {alerts?.map(({ key, bodyText }) => (
            <Stack direction="column" gap="l" key={key}>
              {bodyText}
              <Checkbox
                checked={suppressWarning[key]}
                onChange={(e) => {
                  const s = e.target.checked;
                  setSuppressWarning({ ...suppressWarning, [key]: s });
                }}
                labelPosition="right"
                label={<Content id={ContentId.SuppressFor1HourV2} />}
              />
            </Stack>
          ))}
        </div>
      }
      onOkay={() => {
        const newValue = { ...(value ?? {}) };
        alerts?.forEach((a) => {
          a.onOkay?.();
          newValue[a.key] = suppressWarning[a.key] ? new Date() : undefined;
        });

        if (!isEqual(newValue, value)) {
          setUserSetting(newValue);
        }

        onOkay?.();
      }}
      onCancel={() => {
        alerts?.forEach((a) => {
          a.onCancel?.();
        });
        onCancel?.();
      }}
      okText={okText ?? ContentId.OK}
      cancelText={cancelText ?? ContentId.Cancel}
      {...props}
    />
  );
};
