import { createContext, useContext } from 'react';
import {
  EventAccordionItemBodyComponentType,
  EventAccordionItemProps,
  MinRowsForUsingVirtuoso,
  SaleEventsEmpty,
  SalesEventsAccordion,
} from 'src/components/Accordions';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import {
  EventItemHeader,
  EventItemHeaderType,
} from 'src/components/Events/EventItemHeader';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';
import { EventWithData } from 'src/WebApiController';
import { Sale } from 'src/WebApiController';

import * as styles from './SaleSearchAccordionList.css';

const SaleSearchAccordionListContext = createContext<{
  onSelectSale?: (sale: Sale) => void;
}>({});

/**
 * Component for displaying sales in ClassifyEmail modal when classifying sales.
 * @param props
 * @returns
 */
export function SaleSearchAccordionList({
  onSelectSale,
}: {
  onSelectSale?: (sale: Sale) => void;
}) {
  const {
    isLoading,
    errorInfo,
    eventsTransformed,
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();
  if (isLoading) {
    return <PosSpinner />;
  }
  if (errorInfo) {
    return (
      <SomethingWentWrong
        header={errorInfo.errorHeader}
        message={<Content id={ContentId.FailToLoadListContent} />}
      />
    );
  }
  const items = eventsTransformed?.map(translateEventToSaleAccordionItem) ?? [];
  return (
    <SaleSearchAccordionListContext.Provider value={{ onSelectSale }}>
      {items.length > 0 ? (
        <SalesEventsAccordion
          items={items}
          selectedIds={expandedListItems}
          onSelect={(id) => toggleListItemExpansion([id])}
          disablePagination
        />
      ) : (
        <SaleEventsEmpty />
      )}
    </SaleSearchAccordionListContext.Provider>
  );
}

function translateEventToSaleAccordionItem(
  props: EventWithData,
  index: number
): EventAccordionItemProps<Sale> {
  return {
    index,
    itemId: props.event.viagVirtualId,
    HeaderComponent: SaleAccordionItemHeader,
    BodyComponent: SaleAccordionItemBody,
    usingVirtuoso: (props.counts.salesCnt ?? 0) > MinRowsForUsingVirtuoso,
    ...props,
    entities: props.entities.sales ?? [],
    entityCount: props.counts.salesCnt ?? 0,
  };
}

function SaleAccordionItemHeader({ event, ...rest }: EventItemHeaderType) {
  const { performer, venue } = useGetEventFullInfo(event);

  return (
    <EventItemHeader
      {...rest}
      event={event}
      performer={performer}
      venue={venue}
    />
  );
}

function SaleAccordionItemBody({
  entities: sales,
}: EventAccordionItemBodyComponentType<Sale>) {
  const { onSelectSale } = useContext(SaleSearchAccordionListContext);
  return (
    <div className={styles.saleSearchAccordionItemBodyContainer}>
      {sales?.map((sale) => (
        <Stack key={sale.id} alignItems="center" justifyContent="spaceBetween">
          <SeatingInfo {...sale.seating} />
          <Button
            variant="text"
            shape="inline"
            size="md"
            onClick={() => {
              onSelectSale?.(sale);
            }}
          >
            <Content id={ContentId.Select} />
          </Button>
        </Stack>
      ))}
    </div>
  );
}
