export const coalesceUndefinedOnly = <T>(
  x: T | null | undefined,
  y: T | null | undefined
) => {
  return x === undefined ? y : x;
};

export const removeDuplicates = <T = unknown>(arr: T[]): T[] => {
  return [...new Set(arr)];
};

export const compareNumber = (
  numA: number | undefined | null,
  numB: number | undefined | null
) => {
  if (numA == null && numB != null) return 1;
  if (numA != null && numB == null) return -1;

  if (numA != null && numB != null) {
    if (numA !== numB) {
      return numA < numB ? -1 : 1;
    }
  }
  return 0;
};
