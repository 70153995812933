import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReportsInventoryPage } from 'src/components/ReportsInventoryPage';
import { Content } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useReportConfigs } from 'src/hooks/useReportConfigs';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypes } from 'src/utils/reportsUtils';

import { ReportsPageToolbar } from '../../../components/Reports/ReportsPageToolbar';
import { MainRoute } from '../MainRoute';
import { ReportsInventoryContextProvider } from './ReportsInventoryContextProvider';

export function ReportsInventory() {
  return (
    <LayoutContent mainRoute={MainRoute.Reports}>
      <ReportsInventoryContent />
    </LayoutContent>
  );
}

function ReportsInventoryContent() {
  const { reportId } = useParams();
  const location = useLocation();

  const { reportConfigs, isLoading } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });

  const reportConfig = reportConfigs?.find(
    (r) => r.reportId?.toString() === reportId
  );

  // Only use value provided at the first time (should be from '/reports')
  const returnUrl = useMemo(() => {
    return (location.state as { returnUrl?: string })?.returnUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportConfig == null) {
    return isLoading ? (
      <PosSpinner />
    ) : (
      <Stack
        width="full"
        direction="column"
        alignItems="center"
        style={{ padding: vars.spacing.xxl }}
      >
        <WarningMessage
          message={<Content id={ContentId.ReportNotAvailable} />}
        />
      </Stack>
    );
  }

  return (
    <ReportsInventoryContextProvider reportConfig={reportConfig}>
      <DialogProvider>
        <ReportsPageToolbar
          reportConfig={reportConfig}
          returnUrl={returnUrl ?? '/reports'}
        />
        <ReportsInventoryPage reportConfig={reportConfig} />{' '}
      </DialogProvider>
    </ReportsInventoryContextProvider>
  );
}
