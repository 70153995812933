import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  YES_NO_ENUM_FILTER_TO_CID,
  YES_NO_INVERTED_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { InhandDateRangePresetNames } from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  ActionOutboxEntityType,
  Feature,
  ListingQuery,
  UIReportV2ListingQuery,
} from 'src/WebApiController';

import {
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
} from '../Filters';
import { RowFilter } from '../Filters/RowFilter';
import { SectionFilter } from '../Filters/SectionFilter';
import { PriceUpdatedDateStackedFilter } from '../Filters/StackedFilter/PriceUpdatedDateStackedFilter';
import { CurrencyFilterMultiSelector } from '../Selectors/CurrencyFilterSelector';
import { useCommonEventFilters } from './useCommonEventFilters';

export const listingsReportMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
];

export function useListingReportFilters({
  isHorizontalLayout,
  showCatalogFilters,
}: {
  isHorizontalLayout?: boolean;
  showCatalogFilters?: boolean;
}) {
  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<UIReportV2ListingQuery>();

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const hasAutoFulfillSetting = useUserHasFeature(Feature.AutoFulfillSetting);

  const autoPricingFilter = useMemo<FilterToolbarItem[]>(
    () => [
      {
        filterId: 'isAutoPricingEnabled',
        labelContentId: ContentId.AutoPricingEnabled,
        filterQueryKeys: ['isAutoPricingEnabled'],
        filterItem: (
          <PosEnumSelect
            style={{ width: '100%' }}
            value={ToYesNoEnum(tempQuery.isAutoPricingEnabled)}
            placeholderText={ContentId.All}
            enableEmptySelection
            onChange={(yesNoEnumValue) => {
              if (
                yesNoEnumValue !== ToYesNoEnum(tempQuery.isAutoPricingEnabled)
              ) {
                setTempQuery({
                  ...tempQuery,
                  isAutoPricingEnabled: FromYesNoEnum(yesNoEnumValue),
                });
              }
            }}
            valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
          />
        ),
      },
    ],
    [setTempQuery, tempQuery]
  );

  const adminHoldFilter = useMemo<FilterToolbarItem[]>(
    () =>
      [
        {
          filterId: 'isAdminHold',
          labelContentId: ContentId.Hold,
          filterQueryKeys: ['isAdminHold'],
          filterItem: (
            <PosEnumSelect
              style={{ width: '100%' }}
              value={ToYesNoEnum(tempQuery.isAdminHold)}
              placeholderText={ContentId.All}
              enableEmptySelection
              onChange={(yesNoEnumValue) => {
                if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isAdminHold)) {
                  setTempQuery({
                    ...tempQuery,
                    isAdminHold: FromYesNoEnum(yesNoEnumValue),
                  });
                }
              }}
              valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
            />
          ),
        },
      ] as FilterToolbarItem[],
    [setTempQuery, tempQuery]
  );

  const seatingFilters = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Seating,
      type: 'row',
      items: [
        {
          filterId: 'sectionContains',
          labelContentId: ContentId.Section,
          filterQueryKeys: ['sectionEquals', 'sectionContains'],
          filterItem: (
            <SectionFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
        {
          filterId: 'rowContains',
          labelContentId: ContentId.Row,
          filterQueryKeys: ['rowEquals', 'rowContains'],
          filterItem: (
            <RowFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
      ],
    }),
    [isHorizontalLayout, setTempQuery, tempQuery]
  );

  const eventFilters = useCommonEventFilters({
    showCatalogFilters,
    additionalFilters: [],
  });

  const notesFilters = useInternalNotesFilter<ListingQuery>({
    entityType: ActionOutboxEntityType.Listing,
    isHorizontalLayout,
  });

  const filters = useMemo<FilterToolbarGroup[]>(
    () =>
      [
        eventFilters,
        {
          titleContentId: ContentId.Pricing,
          type: 'row',
          items: [
            ...(hasAutoPricingFeature ? autoPricingFilter : []),

            {
              filterId: 'lastPriceUpdateDates' as FilterToolbarItemId,
              filterQueryKeys: [
                'lastPriceUpdateDates',
                'lastPriceNoUpdateDates',
              ] as FilterToolbarItemId[],
              labelContentId: ContentId.PriceDateFilterTitle,
              filterItem: <PriceUpdatedDateStackedFilter />,
            },

            {
              labelContentId: ContentId.IsPricedByMarketplace,
              filterId: 'isPricedByMarketplace' as FilterToolbarItemId,
              filterQueryKeys: [
                'isPricedByMarketplace',
              ] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isPricedByMarketplace)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !==
                      ToYesNoEnum(tempQuery.isPricedByMarketplace)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        isPricedByMarketplace: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },

            {
              filterId: 'currencyCodes' as FilterToolbarItemId,
              labelContentId: ContentId.Currency,
              filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
              filterItem: (
                <CurrencyFilterMultiSelector
                  values={tempQuery.currencyCodes ?? []}
                  triggerProps={{ style: { width: '100%' } }}
                  placeholderText={ContentId.All}
                  onChange={(newCurrencyCodes) => {
                    if (!isEqual(tempQuery.currencyCodes, newCurrencyCodes)) {
                      setTempQuery({
                        ...tempQuery,
                        currencyCodes: newCurrencyCodes?.length
                          ? newCurrencyCodes
                          : null,
                      });
                    }
                  }}
                  enableEmptySelection
                />
              ),
            },
          ],
        },

        {
          titleContentId: ContentId.Broadcast,
          type: 'row',
          items: [
            {
              filterId: 'isListed' as FilterToolbarItemId,
              labelContentId: ContentId.Broadcasted,
              filterQueryKeys: ['isListed'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isListed)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isListed)) {
                      setTempQuery({
                        ...tempQuery,
                        isListed: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            ...adminHoldFilter,
            {
              filterId: 'isDuplicate' as FilterToolbarItemId,
              labelContentId: ContentId.IsDuplicate,
              filterQueryKeys: ['isDuplicate'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isDuplicate)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isDuplicate)) {
                      setTempQuery({
                        ...tempQuery,
                        isDuplicate: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ],
        },
        {
          titleContentId: ContentId.Fulfillment,
          type: 'row',
          items: [
            {
              filterId: 'isPredelivered' as FilterToolbarItemId,
              labelContentId: ContentId.PreDelivered,
              type: 'row',
              filterQueryKeys: ['isPredelivered'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isPredelivered)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !== ToYesNoEnum(tempQuery.isPredelivered)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        isPredelivered: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'inHandDates' as const,
              labelContentId: ContentId.InHand,
              filterQueryKeys: ['inHandDates'] as FilterToolbarItemId[],
              filterItem: (
                <DateRangeSelector
                  useRelativePresets
                  presetNames={InhandDateRangePresetNames}
                  value={tempQuery.inHandDates}
                  defaultValue={initialQuery.inHandDates}
                  onBlur={(value) =>
                    setTempQuery({
                      ...tempQuery,
                      inHandDates: value,
                    })
                  }
                />
              ),
            },
            {
              filterId: 'isSeatSaver' as const,
              labelContentId: ContentId.Placeholder,
              filterQueryKeys: ['isSeatSaver'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isSeatSaver)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)) {
                      setTempQuery({
                        ...tempQuery,
                        isSeatSaver: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ],
        },
        {
          titleContentId: ContentId.Mappings,
          type: 'row',
          items: [
            {
              filterId: 'isMapped' as FilterToolbarItemId,
              labelContentId: ContentId.EventMapped,
              filterQueryKeys: ['isMapped'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isMapped)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isMapped)) {
                      setTempQuery({
                        ...tempQuery,
                        isMapped: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'isSeatingUnmapped' as FilterToolbarItemId,
              labelContentId: ContentId.SeatingMapped,
              filterQueryKeys: ['isSeatingUnmapped'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isSeatingUnmapped)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !==
                      ToYesNoEnum(tempQuery.isSeatingUnmapped)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        isSeatingUnmapped: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_INVERTED_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ],
        },
        seatingFilters,
        notesFilters,
      ]
        .filter((f) => f)
        .map((f) => f!),
    [
      eventFilters,
      hasAutoPricingFeature,
      autoPricingFilter,
      tempQuery,
      adminHoldFilter,
      initialQuery.inHandDates,
      seatingFilters,
      notesFilters,
      setTempQuery,
    ]
  );

  return { filters };
}
