import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkMarkSaleAsPaidDialog,
  MarkSaleAsPaidForm,
} from 'src/dialogs/BulkEdits/BulkMarkSaleAsPaidDialog';
import { lookupEventInCatalog } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditSaleClient,
  Event,
  SaleQuery,
} from 'src/WebApiController';

import { SALES_BULK_MARK_SALE_AS_PAID_UPDATE_KEY } from '../../SalesActionDropdown.constants';
import { LaunchBulkMarkSaleAsPaid } from './LaunchBulkMarkSaleAsPaid';

export const useBulkMarkSaleAsPaid = (
  filterQueryWithEventIds: SaleQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `markSaleAsPaid-event-${event?.viagVirtualId}`
    : SALES_BULK_MARK_SALE_AS_PAID_UPDATE_KEY;

  const {
    data,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkMarkSaleAsDialog,
    closeDialog: closeBulkMarkSaleAsPaidDialog,
  } = useDialog(DialogId.BulkMarkSaleAsPaid, BulkMarkSaleAsPaidDialog);

  const onSubmit = useCallback(
    async (
      markSaleAsPaidForm: MarkSaleAsPaidForm | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditSaleClient(
              activeAccountWebClientConfig
            ).bulkMarkSaleAsPaidPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkMarkSaleAsPaidPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (markSaleAsPaidForm) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditSaleClient(
              activeAccountWebClientConfig
            ).bulkMarkSaleAsPaid(
              preview!.preview,
              markSaleAsPaidForm.paymentReference,
              markSaleAsPaidForm.paymentDate,
              markSaleAsPaidForm.marketplace,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshExpandedListItems();
              }

              closeBulkMarkSaleAsPaidDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkMarkSaleAsPaid', error, {
              trackErrorData: { event, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkMarkSaleAsPaidDialog,
      event,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkMarkSaleAsPaid
        key="markSaleAsPaid"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkMarkSaleAsDialog({
            event,
            sales: event
              ? lookupEventInCatalog(data, event.viagVirtualId)
                  ?.entities?.sales?.filter((s) => s != null)
                  ?.map((s) => s!)
              : [],
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkMarkSaleAsPaidDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
