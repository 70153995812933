import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EntitySearchConfigsToolbar,
  EntitySearchRefetcher,
} from 'src/components/EntitySearch';
import { useEntitySearch } from 'src/components/EntitySearch';
import { InventoryFlattenedView } from 'src/components/Events/EventListing/InventoryEventListing/InventoryFlattenedView';
import { ListingSearchBox } from 'src/components/Listings/ListingFilterBar/ListingSearchBox';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import {
  MultiSelectionContextProvider,
  MultiSelectScope,
  NO_GROUP_ID,
} from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { DividerLine } from 'src/core/ui';
import { useGetActiveAccountClientConfig } from 'src/hooks/useGetActiveAccountClientConfig';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultListingQuery,
  EmptyListingQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogResults,
  Feature,
  InventorySearchResult,
  InventoryViewMode,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingQuery,
} from 'src/WebApiController';

import {
  getCatalogData,
  getCatalogDataExpanded,
  listingQueryValueTransformFromUrl,
  listingQueryValueTransformToUrl,
} from '../Inventory';
import {
  getDefaultReturnUrl,
  INVENTORY_USER_SETTINGS,
} from '../InventoryEvent/constants';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';
import * as styles from './InventorySearch.css';
import { InventorySearchFilterBar } from './InventorySearchFilterBar';
import { InventorySearchToolbar } from './InventorySearchToolbar';

export function InventorySearch() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={INVENTORY_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<ListingQuery>
        initialQuery={{
          ...DefaultListingQuery,
          viewMode: InventoryViewMode.FlattenedView,
        }}
        emptyQuery={EmptyListingQuery}
        queryValueTransformToUrl={listingQueryValueTransformToUrl}
        queryValueTransformFromUrl={listingQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <InventorySearchPage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

function InventorySearchPage() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const [activeSearchConfig, setActiveSearchConfig] =
    useState<InventorySearchResult>();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], f: ListingQuery) => {
      // Check to see if if is empty filter, query
      if (
        activeSearchConfig == null || // do not start query for items if there is no activeSearchConfig
        (f.searchConfigId && !f.entityIds?.length) // we don't want to query when the filter has the search config id but no entity ids
      ) {
        return Promise.resolve({});
      }

      // If we're querying by ids - we want to get the latest greatest
      // We're only using readonly-db if we're filtering
      const useReadOnlyDb = f.entityIds?.length ? false : true;
      return getCatalogDataExpanded(
        ids,
        f,
        {
          activeAccountWebClientConfig,
          onError: (error) => {
            trackError('ListingClient.getListingsForEvents', error, {
              ...f,
              eventIds: ids,
            });
          },
        },
        useReadOnlyDb,
        true /* doNotProcessListingGroups */
      );
    },
    [activeAccountWebClientConfig, activeSearchConfig, trackError]
  );

  const getCatalogDataCallback = useCallback(
    async (c: CatalogClient, f: ListingQuery) => {
      // Check to see if if is empty filter, query
      if (
        f.searchConfigId &&
        !f.entityIds?.length // we don't want to query when the filter has the search config id but no entity ids
      ) {
        // If there is no filter (empty query) then don't try to query for anything
        return {
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
        } as CatalogResults;
      }

      return await getCatalogData(c, f, true);
    },
    []
  );

  return (
    <CatalogDataContextProvider<ListingQuery>
      entityType={ActionOutboxEntityType.Listing}
      queryKey="getCatalogForListingOrders"
      getCatalogData={getCatalogDataCallback}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
      disableAutoRefresh
      ignoreMaxCount
    >
      <CatalogMultiSelectionContextProvider type={'sale'}>
        <MultiSelectionContextProvider
          isFlattenedView
          forceSelectionMode={{
            mode: MultiSelectScope.AllGroups,
            groupId: NO_GROUP_ID,
          }}
        >
          <InventorySearchContent
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={setActiveSearchConfig}
          />
        </MultiSelectionContextProvider>
      </CatalogMultiSelectionContextProvider>
    </CatalogDataContextProvider>
  );
}

function InventorySearchContent({
  activeSearchConfig,
  setActiveSearchConfig,
}: {
  activeSearchConfig: InventorySearchResult | undefined;
  setActiveSearchConfig: (a: InventorySearchResult | undefined) => void;
}) {
  const searchTitle = useContent(ContentId.SearchInventory);
  const hasColumnFilterSearchViewFeature = useUserHasFeature(
    Feature.ColumnFilterSearchView
  );

  const location = useLocation();
  const {
    errorInfo,
    isLoading: isCatalogDataLoading,
    isItemsLoading,
    eventsTransformed,
  } = useCatalogDataContext();

  const {
    selectedFilters,
    setSelectedFilters,
    onSetActiveSearchConfig,
    onSaveSearchResult,
    onDeleteSearchResult,
    onRequeryForTheResult,
    refetchData,
    disabled,
    setDisabled,
  } = useEntitySearch(
    activeSearchConfig,
    setActiveSearchConfig,
    InventoryViewMode.FlattenedView,
    (client, id) => client.getInventorySearchResult(id),
    (client, r) => client.saveInventorySearchResult(r)
  );

  // Only use value provided at the first time (should be from '/purchases')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('purchases');

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      InventoryDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state]);

  const getActClientCfg = useGetActiveAccountClientConfig(
    'Inventory.ListingClient.getListingByListingId'
  );
  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const getActivePosEntity = useCallback(
    async (
      posEntityId: number,
      curEntity?: ListingDetails | null,
      dataToGet?: string[] | null
    ) => {
      const activeClientCf = await getActClientCfg();

      const listingDetail = await new ListingClient(
        activeClientCf
      ).getListingByListingId(
        posEntityId,
        hasSectionalLoadDataFeature ? (curEntity ?? undefined) : undefined,
        hasSectionalLoadDataFeature
          ? (dataToGet?.map((s) => s as ListingDetailDataField) ?? [])
          : undefined
      );

      if (listingDetail) {
        return {
          posEntityId: listingDetail.id,
          posEntity: listingDetail,
          posEntityDisplayId: listingDetail.idOnMkp,
        };
      }

      return {};
    },
    [getActClientCfg, hasSectionalLoadDataFeature]
  );

  const dataIsLoading = isCatalogDataLoading;

  return (
    <ActivePosEntityProvider<ListingDetails>
      entityType={ActionOutboxEntityType.Listing}
      getActivePosEntity={getActivePosEntity}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.InventorySearch}
          routeTitle={searchTitle}
          returnUrl={returnUrl}
          rightContent={
            <EntitySearchRefetcher
              activeSearchConfig={activeSearchConfig}
              disabled={disabled}
              onRequeryForTheResult={onRequeryForTheResult}
            />
          }
        >
          <EntitySearchConfigsToolbar
            disabled={disabled}
            setDisabled={setDisabled}
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={onSetActiveSearchConfig}
            saveActiveSearchConfig={onSaveSearchResult}
            deleteActiveSearchConfig={onDeleteSearchResult}
            getSearchConfigs={(client, includeAllUser) =>
              client.getInventorySearchConfigs(includeAllUser)
            }
            getSearchResult={(client, id) =>
              client.getInventorySearchResult(id)
            }
            additionalActions={<ListingSearchBox />}
          />
          <DividerLine style={{ margin: 0 }} />
          <InventorySearchFilterBar
            activeSearchConfig={activeSearchConfig}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            onSubmitFilter={(filter) => {
              if (!activeSearchConfig) {
                // Setting an empty search config so it starts to filter
                onSetActiveSearchConfig({
                  id: null,
                  name: null,
                  query: filter,
                  ownerUserId: null,
                  count: null,
                  entityIds: null,
                  lastFetchDate: null,
                });
              }
              refetchData(filter);
            }}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <InventorySearchToolbar
            disabled={disabled}
            activeSearchConfig={activeSearchConfig}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <div className={styles.contentContainer}>
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {(dataIsLoading || isItemsLoading) && !eventsTransformed?.length ? (
              <PosSpinner />
            ) : (
              <InventoryFlattenedView
                alwaysShowCheckbox
                showSelectAllOnHeader
                doNotShowToolbar
                enableColumnFilters={hasColumnFilterSearchViewFeature}
                ignoreMaxCount
              />
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
