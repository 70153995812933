import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProvider } from 'src/contexts/ReportMetricsContext';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { DefaultSaleQuery, EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { isReportFilterOverriden } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  ReportClient,
  ReportGroupBy,
  SaleQuery,
  SaleReportMetrics,
  UserSetting,
} from 'src/WebApiController';

import { getCatalogData } from '../Sales';

export function ReportsSaleContextProvider({
  reportConfig,
  children,
  applyMaxRowLimitByDefault = true,
}: PropsWithChildren<{
  reportConfig: ReportConfig;
  applyMaxRowLimitByDefault?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (
      filterQuery: SaleQuery,
      groupBy: ReportGroupBy,
      maxResultSize?: number
    ) => {
      return new ReportClient(
        activeAccountWebClientConfig
      ).getSaleReportMetrics(
        {
          query: filterQuery,
          groupBy,
          globalReportTypeId: reportConfig.globalReportTypeId,
          isUsingFilterOverride:
            reportConfig.hasFilterOverride ||
            isReportFilterOverriden(filterQuery, reportConfig.filter),
          arAgingTimeBuckets: reportConfig.arAgingTimeBuckets ?? [],
        },
        maxResultSize
      );
    },
    [
      activeAccountWebClientConfig,
      reportConfig.arAgingTimeBuckets,
      reportConfig.filter,
      reportConfig.globalReportTypeId,
      reportConfig.hasFilterOverride,
    ]
  );

  return (
    <FilterQueryContextProvider<SaleQuery>
      initialQuery={(reportConfig.filter as SaleQuery) ?? DefaultSaleQuery}
      emptyQuery={EmptySaleQuery}
      loadQueryFromUrl={false}
      viewModeSettingId={UserSetting.SalePageViewMode}
    >
      <CatalogDataContextProvider<SaleQuery>
        entityType={ActionOutboxEntityType.Sale}
        queryKey="getCatalogForSale"
        getCatalogData={(c, f) => getCatalogData(c, f, false)}
        transformEventData={transformData}
      >
        <ReportMetricsContextProvider<SaleReportMetrics, SaleQuery>
          queryKey="getReportSaleMetrics"
          getReportMetrics={(f, g, _, m) => getReportMetrics(f, g, m)}
          groupBy={reportConfig.groupBy}
          applyMaxRowLimitByDefault={applyMaxRowLimitByDefault}
        >
          {children}
        </ReportMetricsContextProvider>
      </CatalogDataContextProvider>
    </FilterQueryContextProvider>
  );
}
