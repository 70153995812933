import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Event, Feature, Listing } from 'src/WebApiController';

import { InventoryEventDetail } from './InventoryEventDetail/InventoryEventDetail';
import { InventoryEventTabs } from './InventoryEventPage';
import { MarketListingsForm } from './MarketListings/MarketListings';
import { StubHubListingsWithManualPricing } from './MarketListings/StubHubListingsWithManualPricing';

type InventoryEventPageRightPanelProps = {
  event: Event;
  listings: Listing[] | null | undefined;
  selectedListingIds: number[];
  activeTab: InventoryEventTabs;
};

export const InventoryEventPageRightPanel = ({
  event,
  listings,
  selectedListingIds,
  activeTab,
}: InventoryEventPageRightPanelProps) => {
  const hasCondensedTabsFeature = useUserHasFeature(
    Feature.CondensedPreviewTabsAndSingleListing
  );

  if (activeTab === InventoryEventTabs.ManualPricing) {
    return (
      <StubHubListingsWithManualPricing
        event={event}
        eventListings={listings}
        selectedListingIds={selectedListingIds}
      />
    );
  }

  return hasCondensedTabsFeature ? (
    <InventoryEventDetail
      event={event}
      eventListings={listings}
      selectedListingIds={selectedListingIds}
    />
  ) : (
    <MarketListingsForm
      event={event}
      eventListings={listings}
      selectedListingIds={selectedListingIds}
    />
  );
};
