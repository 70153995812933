import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SearchClient, SearchResult } from 'src/WebApiController';

import { EntitySearchConfigActions } from '../EntitySearchConfigActions';
import { container } from './EntitySearchConfigsToolbar.css';

export type EntitySearchConfigsToolbarProps<T extends SearchResult> = {
  disabled?: boolean;
  setDisabled?: (disabled: boolean) => void;
  activeSearchConfig?: T;
  setActiveSearchConfig: (config?: T) => void;
  saveActiveSearchConfig: (config: T) => void;
  deleteActiveSearchConfig: (config: T) => void;
  getSearchConfigs: (
    client: SearchClient,
    includeAllUsers: boolean
  ) => Promise<T[]>;
  getSearchResult: (client: SearchClient, id: string) => Promise<T>;
  additionalActions?: React.ReactNode;
};

export const EntitySearchConfigsToolbar = <T extends SearchResult>({
  disabled,
  setDisabled,
  activeSearchConfig,
  setActiveSearchConfig,
  saveActiveSearchConfig,
  deleteActiveSearchConfig,
  getSearchConfigs,
  getSearchResult,
  additionalActions,
}: EntitySearchConfigsToolbarProps<T>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;

  const userSearchConfigsQuery = useQuery({
    queryKey: [
      'SearchClient.getSearchConfigsForUser',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const client = new SearchClient(activeAccountWebClientConfig);

      const r = await getSearchConfigs(client, false);
      if (r) {
        return r.reduce(
          (r, c) => {
            r[c.id!] = c;
            return r;
          },
          {} as Record<string, T>
        );
      }

      return {} as Record<string, T>;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getSearchConfigsForUser', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  const accountSearchConfigsQuery = useQuery({
    queryKey: [
      'SearchClient.getSearchConfigsForAccount',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const client = new SearchClient(activeAccountWebClientConfig);

      const r = await getSearchConfigs(client, true);
      if (r) {
        return r.reduce(
          (r, c) => {
            r[c.id!] = c;
            return r;
          },
          {} as Record<string, T>
        );
      }

      return {} as Record<string, T>;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getSearchConfigsForAccount', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  const onSearchConfigClick = useCallback(
    (searchResult: T) => {
      setDisabled?.(true);
      tryInvokeApi(
        async () => {
          if (searchResult.id === activeSearchConfig?.id) {
            // We're clicking on the same item - this is meant for a de-select
            setActiveSearchConfig(undefined);
            return;
          }
          const client = new SearchClient(activeAccountWebClientConfig);

          // set a mock search result while waiting for the main result
          setActiveSearchConfig(searchResult);

          const result = await getSearchResult(client, searchResult.id!);

          if (result) {
            setActiveSearchConfig(result);
          }
        },
        (error) => {
          showErrorDialog('SearchClient.getSearchResult', error, {
            trackErrorData: {
              searchResult,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      activeAccountWebClientConfig,
      activeSearchConfig?.id,
      getSearchResult,
      setActiveSearchConfig,
      setDisabled,
      showErrorDialog,
    ]
  );

  const otherConfigs = useMemo(() => {
    return Object.values(accountSearchConfigsQuery.data ?? {}).reduce(
      (r, c) => {
        // Only show if it's not already shown as this user's search result

        if (!userSearchConfigsQuery.data?.[c.id!]) {
          r[c.id!] = c.name!;
        }

        return r;
      },
      {} as Record<string, string>
    );
  }, [accountSearchConfigsQuery.data, userSearchConfigsQuery.data]);

  const otherConfig = useMemo(
    () =>
      activeSearchConfig?.id
        ? otherConfigs[activeSearchConfig.id]
          ? activeSearchConfig.id // we only show selection if the active search config is one of the available items
          : undefined
        : undefined,
    [activeSearchConfig?.id, otherConfigs]
  );

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <Stack gap="m" alignItems="center">
        <ToolbarButton disabled style={{ margin: `${vars.spacing['sm']} 0` }}>
          <Content id={ContentId.Saved} />:
        </ToolbarButton>
        <ScrollableToolbar>
          <Stack gap="m" direction="row">
            <ToolbarButton
              isSelected={!activeSearchConfig?.id}
              disabled={disabled}
            >
              <Button
                variant="textPlain"
                size="unset"
                disabled={disabled}
                onClick={() => setActiveSearchConfig(undefined)}
              >
                <Content id={ContentId.New} />
              </Button>
              {!activeSearchConfig?.id && (
                <EntitySearchConfigActions
                  activeSearchConfig={activeSearchConfig}
                  onSaveSearchResult={saveActiveSearchConfig}
                  onDeleteSearchResult={deleteActiveSearchConfig}
                />
              )}
            </ToolbarButton>

            {Object.values(userSearchConfigsQuery.data ?? {})
              .sort((x, y) => (x.name ?? '').localeCompare(y.name ?? ''))
              .map((item, i) => {
                const isSelected = activeSearchConfig?.id === item.id;
                return (
                  <ToolbarButton
                    key={i}
                    isSelected={isSelected}
                    disabled={disabled}
                  >
                    <Button
                      variant="textPlain"
                      size="unset"
                      disabled={disabled}
                      onClick={() =>
                        isSelected ? null : onSearchConfigClick(item)
                      }
                    >
                      {item.name}
                    </Button>
                    {activeSearchConfig?.id === item.id && (
                      <EntitySearchConfigActions
                        activeSearchConfig={activeSearchConfig}
                        onSaveSearchResult={saveActiveSearchConfig}
                        onDeleteSearchResult={deleteActiveSearchConfig}
                      />
                    )}
                  </ToolbarButton>
                );
              })}
          </Stack>
          {Boolean(Object.entries(otherConfigs).length) && (
            <ToolbarButton
              style={{ padding: '2' }}
              isSelected={Boolean(
                activeSearchConfig?.id
                  ? otherConfigs[activeSearchConfig.id]
                    ? activeSearchConfig.id // we only show selection if the active search config is one of the available items
                    : undefined
                  : undefined
              )}
            >
              <PosSelect
                variant="textPlain"
                shape="pill"
                size="unset"
                placeholderText={ContentId.Other}
                value={otherConfig}
                onChange={(id) => {
                  const newConfig = accountSearchConfigsQuery.data?.[id];
                  if (newConfig) {
                    onSearchConfigClick(newConfig);
                  }
                }}
                valueOptionsContent={otherConfigs}
              />
              {otherConfig && (
                <EntitySearchConfigActions
                  activeSearchConfig={activeSearchConfig}
                  onSaveSearchResult={saveActiveSearchConfig}
                  onDeleteSearchResult={deleteActiveSearchConfig}
                />
              )}
            </ToolbarButton>
          )}
        </ScrollableToolbar>
      </Stack>

      {additionalActions}
    </Stack>
  );
};
