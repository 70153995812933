import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';
import React from 'react';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { Tooltip } from 'src/core/ui';

import * as styles from './PosFormField.css';

export type PosFormFieldProps = React.PropsWithChildren<{
  errors?: string | string[];
  warnings?: string | string[];
}>;

export const PosFormFieldCell = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & PosFormFieldProps
>(function PosFormField({ errors, warnings, children }, ref): ReactElement {
  const isArray = Array.isArray(errors);
  const errorsDiv = errors && (
    <div className={styles.errorsContainer}>
      {isArray ? (
        (errors as string[]).map((er, i) => (
          <div key={`${er}-${i}`} className={styles.error}>
            {er}
          </div>
        ))
      ) : (
        <div className={styles.error}>{errors}</div>
      )}
    </div>
  );

  return (
    <div ref={ref} className={styles.formFieldCellContainer}>
      {errors ? (
        <Tooltip
          triggerContent={
            <span style={{ height: '40px' }}>
              {React.cloneElement(children as React.ReactElement, {
                style: {
                  backgroundColor: errors ? Colors.negative500 : 'transparent',
                  border: errors
                    ? `1px solid ${vars.color.borderError}`
                    : 'none',
                },
              })}
            </span>
          }
        >
          {errorsDiv}
        </Tooltip>
      ) : (
        children
      )}
    </div>
  );
});
