import { set } from 'date-fns';
import { range } from 'lodash-es';
import {
  ControllerFieldState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { Ticket } from 'src/WebApiController';

const getIncrement = (
  from: number,
  to: number,
  quantity: number
): number | null => {
  if (quantity <= 1) return null;
  return (to - from) / (quantity - 1);
};

const generateSeats = (
  from: number,
  to: number | null,
  quantity: number,
  isNumeric: boolean
): (string | number)[] => {
  if (!to) {
    // If only `from` is defined
    return Array.from({ length: quantity }, (_, i) =>
      i === 0 ? (isNumeric ? from : String.fromCharCode(from)) : ''
    );
  }

  const increment = getIncrement(from, to, quantity);
  if (increment === null) {
    return [isNumeric ? from : String.fromCharCode(from)];
  }

  return Array.from({ length: quantity }, (_, i) =>
    isNumeric
      ? Math.round(from + i * increment)
      : String.fromCharCode(Math.round(from + i * increment))
  );
};

export function assignTicketGroupSeats(
  seatFrom: string | number | null,
  seatTo: string | number | null,
  quantity: number | null,
  tickets: Ticket[] | null,
  setValue: UseFormSetValue<PurchaseTicketsInput>,
  setError: UseFormSetError<PurchaseTicketsInput>,
  clearErrors: UseFormClearErrors<PurchaseTicketsInput>,
  fieldState: ControllerFieldState,
  rowIndex: number,
  skipError?: string
) {
  if (!quantity) return;

  let seats: (string | number)[] = [];
  // No seats case: No seatFrom and no seatTo
  if (!seatFrom && !seatTo) {
    clearErrors(`ticketGroups.${rowIndex}.tickets`);
    seats = Array.from({ length: quantity }, () => '');
  }
  //  Numeric Case: Both seatFrom and seatTo are numbers (or numeric strings)
  else if (!isNaN(Number(seatFrom)) && !isNaN(Number(seatTo))) {
    const seatFromNum = Number(seatFrom);
    const seatToNum = Number(seatTo);

    if (
      !validateSeatRange(
        seatFromNum,
        seatToNum,
        tickets,
        quantity,
        rowIndex,
        setError,
        clearErrors,
        fieldState,
        skipError
      )
    ) {
      // If validation fails, adjust existing tickets if available.
      if (tickets) {
        if (quantity < tickets.length) {
          setValue(
            `ticketGroups.${rowIndex}.tickets`,
            posChangedField(tickets.slice(0, quantity))
          );
          return;
        }
        if (quantity > tickets.length && tickets[0]) {
          const startOrdinal = tickets[0].ord || 0;
          const initialTickets = tickets[0];
          const newTickets = range(tickets.length, quantity).map((i) => ({
            ...initialTickets,
            id: startOrdinal + i,
            ord: startOrdinal + i,
            lstOrd: startOrdinal + i,
            seat: '',
          }));
          setValue(
            `ticketGroups.${rowIndex}.tickets`,
            posChangedField([...tickets, ...newTickets])
          );
          return;
        }
      }
    }
    seats = generateSeats(seatFromNum, seatToNum, quantity, true);
  }
  // Character Case: Both seatFrom and seatTo are single letters
  else if (
    typeof seatFrom === 'string' &&
    typeof seatTo === 'string' &&
    seatFrom.length === 1 &&
    seatTo.length === 1
  ) {
    const fromCharCode = seatFrom.toUpperCase().charCodeAt(0);
    const toCharCode = seatTo.toUpperCase().charCodeAt(0);

    if (
      !validateSeatRange(
        fromCharCode,
        toCharCode,
        tickets,
        quantity,
        rowIndex,
        setError,
        clearErrors,
        fieldState,
        skipError
      )
    ) {
      return;
    }
    seats = generateSeats(fromCharCode, toCharCode, quantity, false);
  }
  //  Only seatFrom provided (Numeric or Char)
  else if (seatFrom) {
    if (typeof seatFrom === 'string') {
      const fromCharCode = seatFrom.toUpperCase().charCodeAt(0);
      seats = generateSeats(fromCharCode, null, quantity, false);
    } else if (typeof seatFrom === 'number') {
      seats = generateSeats(seatFrom, null, quantity, true);
    }
  }
  // Only seatTo provided (Numeric or Char)
  else if (seatTo) {
    if (typeof seatTo === 'string') {
      const toCharCode = seatTo.toUpperCase().charCodeAt(0);
      seats = generateSeats(toCharCode, null, quantity, false);
    } else if (typeof seatTo === 'number') {
      seats = generateSeats(seatTo, null, quantity, true);
    }
  }

  // Assign seats to tickets. If tickets do not exist, create new ones.
  if (!tickets) {
    tickets = seats.map((seat, i) => ({
      id: i,
      ord: i,
      lstOrd: i,
      seat: seat.toString(),
    })) as Ticket[];
  }

  if (tickets) {
    if (tickets.length > quantity) {
      // If there are too many tickets, slice the array down.
      tickets = tickets.slice(0, quantity);
    } else if (tickets.length < quantity) {
      // If there are fewer tickets than the new quantity,
      // use the first ticket to create additional tickets.
      const origTicket = tickets[0];
      const startOrdinal = origTicket?.ord ?? 0;
      const additionalTickets = range(tickets.length, quantity).map((i) => ({
        ...origTicket, // clone the template (if available) or create a new ticket if needed
        id: startOrdinal + i,
        ord: startOrdinal + i,
        lstOrd: startOrdinal + i,
        seat: '', // initialize seat as blank
      }));
      tickets = [...tickets, ...additionalTickets];
    }

    seats.forEach((seat, i) => {
      if (tickets![i]) {
        tickets![i].seat = seat.toString();
      }
    });
  }

  setValue(`ticketGroups.${rowIndex}.tickets`, posChangedField(tickets));
}

// Validate the seat range and quantity. Set errors if necessary.
export function validateSeatRange(
  seatFrom: number,
  seatTo: number,
  tickets: Ticket[] | null,
  quantity: number,
  rowIndex: number,
  setError: UseFormSetError<PurchaseTicketsInput>,
  clearErrors: UseFormClearErrors<PurchaseTicketsInput>,
  fieldState: ControllerFieldState,
  skipError?: string
): boolean {
  if (quantity !== tickets?.length) {
    setError(`ticketGroups.${rowIndex}.tickets`, {
      message:
        'Quantity does not match existing tickets. Please re-enter seat range or change quantity',
    });
    return false;
  }

  if (quantity === 1 && seatFrom !== seatTo) {
    const hasDuplicates = false;
    if (tickets) {
      const seatSet = new Set();
      for (const t of tickets) {
        if (!t.seat) {
          setError(`ticketGroups.${rowIndex}.tickets`, {
            message: 'Seats cannot be empty',
          });
          return false;
        }
        if (seatSet.has(t.seat)) {
          setError(`ticketGroups.${rowIndex}.tickets`, {
            message: 'Duplicate seats are not allowed',
          });
          return false;
        }
        seatSet.add(t.seat);
      }
    }

    setError(`ticketGroups.${rowIndex}.tickets`, {
      message: 'Unable to calculate seating. Please manually allocate seats',
    });
    return false;
  }
  if (seatFrom > seatTo) {
    setError(`ticketGroups.${rowIndex}.tickets`, {
      message: 'End seat must be greater than start seat',
    });
    return false;
  }

  const totalRange = seatTo - seatFrom + 1;
  if (quantity > totalRange) {
    setError(`ticketGroups.${rowIndex}.tickets`, {
      message: 'Quantity not within range',
    });
    return false;
  }

  if (
    fieldState.error?.message &&
    // Prevent clearing the error if the error is set at the TicketGroup level
    (!skipError || !fieldState.error?.message.includes(skipError))
  ) {
    clearErrors(`ticketGroups.${rowIndex}.tickets`);
  }

  return true;
}
