import { isEmpty, isEqual, pickBy } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useListingGroupPricingSettings } from 'src/hooks/api/useListingGroupPricingSettings';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
  DEFAULT_COMP_LISTING_FLOOR,
  DEFAULT_UNDERCUT_ABSOLUTE_AMT,
  DEFAULT_UNDERCUT_RELATIVE_AMT,
} from 'src/utils/autoPricingUtils';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import { coalesceUndefinedOnly } from 'src/utils/miscUtils';
import {
  mapToSelectedSectionSettings,
  SelectedSectionSettingsWithOptionals,
} from 'src/utils/types/selectedSectionSettingsWithOptionals';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  Feature,
  Listing,
  ListingDetailsUpdateInput,
  SectionInfo,
} from 'src/WebApiController';

import { useHasListingPricePermissions } from './useHasListingPricePermissions';

/**
 * @deprecated Use the `src/modals/ListingDetails/ListingForms/AutoPricingForm/useAutoPricingSettings.tsuseAutoPricingSettings`
 * instead.
 */
export const useListingAutoPricingSettings = (
  listing?: Listing | null,
  isBulkEdit?: boolean
) => {
  const { setValue, watch, formState, clearErrors } =
    useFormContext<ListingDetailsUpdateInput>();
  const { canSetPrice, canAutoPrice } = useHasListingPricePermissions(
    listing,
    isBulkEdit
  );

  const hasDefaultFilterRowsFeature = useUserHasFeature(
    Feature.DefaultFilterRows
  );
  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  const defaultCompListingMode = useMemo(() => {
    if (hasAllowSeatScoreCompListingModeFeature) {
      return AutoPricingCompListingMode.QualityScore;
    }
    return AutoPricingCompListingMode.SameEvent;
  }, [hasAllowSeatScoreCompListingModeFeature]);

  const updateInput = watch();
  const {
    autoPricingEnabled,
    currencyCode,

    skipAutoPricing,
    skipAdvancePricing,
    skipCompListing,
    skipPriceProtection,
    skipUndercut,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingCeiling,
    compListingFloor,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingQuantityScoreAdjustmentEnabled,
    compListingExcludeFanInventory,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,
    compListingExcludeDefects,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,

    netProceedsFloor,

    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,

    listPrice: unitNetProceeds,
  } = updateInput;

  const { loginContext } = useAppContext();

  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ?? loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );
  const currencyNumberFormat = useMemo(
    () =>
      new Intl.NumberFormat(getCurrentLanguage(), {
        style: 'currency',
        currency: uiCurrency.code,
      }),
    [uiCurrency.code]
  );

  useEffect(() => {
    // Setting this null so it will use the originalListing.Currency (for bulk-edit)
    if (isBulkEdit && currencyCode != null) {
      setValue('currencyCode', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkEdit]);

  const { event } = useEventMapContext();
  const { pricingSettings: groupPricingSettings } =
    useListingGroupPricingSettings(listing?.ltGrpId, event);
  const { pricingSettings: eventPricingSettings } =
    useGetEventAutoPricingSettings(event!, false);
  const { pricingSettings: accountPricingSettings } =
    useGetAccountAutoPricingSettings();

  const onResetSettings = useCallback(() => {
    setValue('undercutMode', null);
    setValue('undercutAbsoluteAmount', null);
    setValue('undercutRelativeAmount', null);

    setValue('compListingMode', null);
    setValue('compListingFloor', null);
    setValue('compListingCeiling', null);
    setValue('compListingSelectedSectionSettings', null);
    setValue('compListingQuantityScoreAdjustmentOverrideJson', null);
    setValue('compListingOnlyForSameZoneEnabled', null);
    setValue('compListingOnlyForSelectedSectionsEnabled', null);
  }, [setValue]);

  const onResetAdvanceSettings = useCallback(() => {
    setValue('compListingQuantityScoreAdjustmentEnabled', null);
    setValue('compListingExcludeFanInventory', null);
    setValue('compListingExcludeDefects', null);

    setValue('circuitBreakerRelativeCeiling', null);
    setValue('circuitBreakerRelativeFloor', null);
    setValue('circuitBreakerMaxDiscountVelocityPercent', null);
    setValue('circuitBreakerMaxDiscountVelocityTicksInHours', null);
    setValue('circuitBreakerMinCompListingCount', null);

    setValue('outlierMode', null);
    setValue('outlierStandardDeviations', null);
    setValue('outlierKthLowestLimit', null);
    setValue('outlierKthLowestLimitRelativeSpacing', null);
    setValue('outlierKthLowestLimitAbsoluteSpacing', null);
  }, [setValue]);

  const circuitBreakerSettings = useMemo(() => {
    if (
      circuitBreakerMaxDiscountVelocityPercent != null ||
      circuitBreakerMaxDiscountVelocityTicksInHours != null ||
      circuitBreakerMinCompListingCount != null ||
      circuitBreakerRelativeCeiling != null ||
      circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent,
        circuitBreakerMaxDiscountVelocityTicksInHours,
        circuitBreakerMinCompListingCount,
        circuitBreakerRelativeCeiling,
        circuitBreakerRelativeFloor,
      };
    }

    if (
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityPercent != null ||
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours !=
        null ||
      groupPricingSettings?.circuitBreakerMinCompListingCount != null ||
      groupPricingSettings?.circuitBreakerRelativeCeiling != null ||
      groupPricingSettings?.circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent:
          groupPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
          null,
        circuitBreakerMaxDiscountVelocityTicksInHours:
          groupPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
          null,
        circuitBreakerMinCompListingCount:
          groupPricingSettings?.circuitBreakerMinCompListingCount ?? null,
        circuitBreakerRelativeCeiling:
          groupPricingSettings?.circuitBreakerRelativeCeiling ?? null,
        circuitBreakerRelativeFloor:
          groupPricingSettings?.circuitBreakerRelativeFloor ?? null,
      };
    }

    if (
      eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent != null ||
      eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours !=
        null ||
      eventPricingSettings?.circuitBreakerMinCompListingCount != null ||
      eventPricingSettings?.circuitBreakerRelativeCeiling != null ||
      eventPricingSettings?.circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent:
          eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
          null,
        circuitBreakerMaxDiscountVelocityTicksInHours:
          eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
          null,
        circuitBreakerMinCompListingCount:
          eventPricingSettings?.circuitBreakerMinCompListingCount ?? null,
        circuitBreakerRelativeCeiling:
          eventPricingSettings?.circuitBreakerRelativeCeiling ?? null,
        circuitBreakerRelativeFloor:
          eventPricingSettings?.circuitBreakerRelativeFloor ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      circuitBreakerMaxDiscountVelocityPercent:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
        null,
      circuitBreakerMaxDiscountVelocityTicksInHours:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
        null,
      circuitBreakerMinCompListingCount:
        accountPricingSettings?.circuitBreakerMinCompListingCount ?? null,
      circuitBreakerRelativeCeiling:
        accountPricingSettings?.circuitBreakerRelativeCeiling ?? null,
      circuitBreakerRelativeFloor:
        accountPricingSettings?.circuitBreakerRelativeFloor ?? null,
    };
  }, [
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    accountPricingSettings?.circuitBreakerMinCompListingCount,
    accountPricingSettings?.circuitBreakerRelativeCeiling,
    accountPricingSettings?.circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    eventPricingSettings?.circuitBreakerMinCompListingCount,
    eventPricingSettings?.circuitBreakerRelativeCeiling,
    eventPricingSettings?.circuitBreakerRelativeFloor,
    groupPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    groupPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    groupPricingSettings?.circuitBreakerMinCompListingCount,
    groupPricingSettings?.circuitBreakerRelativeCeiling,
    groupPricingSettings?.circuitBreakerRelativeFloor,
  ]);

  const undercutSettings = useMemo(() => {
    if (
      undercutMode != null ||
      undercutAbsoluteAmount != null ||
      undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode,
        undercutAbsoluteAmount,
        undercutRelativeAmount,
      };
    }

    if (
      groupPricingSettings?.undercutMode != null ||
      groupPricingSettings?.undercutAbsoluteAmount != null ||
      groupPricingSettings?.undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode: groupPricingSettings?.undercutMode ?? null,
        undercutAbsoluteAmount:
          groupPricingSettings?.undercutAbsoluteAmount ?? null,
        undercutRelativeAmount:
          groupPricingSettings?.undercutRelativeAmount ?? null,
      };
    }

    if (
      eventPricingSettings?.undercutMode != null ||
      eventPricingSettings?.undercutAbsoluteAmount != null ||
      eventPricingSettings?.undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode: eventPricingSettings?.undercutMode ?? null,
        undercutAbsoluteAmount:
          eventPricingSettings?.undercutAbsoluteAmount ?? null,
        undercutRelativeAmount:
          eventPricingSettings?.undercutRelativeAmount ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      undercutMode:
        accountPricingSettings?.undercutMode ?? AutoPricingUndercutMode.Simple,
      undercutAbsoluteAmount:
        accountPricingSettings?.undercutAbsoluteAmount ??
        DEFAULT_UNDERCUT_ABSOLUTE_AMT,
      undercutRelativeAmount:
        accountPricingSettings?.undercutRelativeAmount ??
        DEFAULT_UNDERCUT_RELATIVE_AMT,
    };
  }, [
    accountPricingSettings?.undercutAbsoluteAmount,
    accountPricingSettings?.undercutMode,
    accountPricingSettings?.undercutRelativeAmount,
    eventPricingSettings?.undercutAbsoluteAmount,
    eventPricingSettings?.undercutMode,
    eventPricingSettings?.undercutRelativeAmount,
    groupPricingSettings?.undercutAbsoluteAmount,
    groupPricingSettings?.undercutMode,
    groupPricingSettings?.undercutRelativeAmount,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const compListingSettings = useMemo(() => {
    if (
      compListingMode != null ||
      compListingFloor != null ||
      compListingCeiling != null ||
      compListingOnlyForSameZoneEnabled != null ||
      compListingOnlyForSelectedSectionsEnabled != null ||
      compListingExcludeFanInventory != null ||
      compListingExcludeDefects != null ||
      compListingSelectedSectionSettings != null ||
      compListingQuantityScoreAdjustmentEnabled != null ||
      compListingQuantityScoreAdjustmentOverrideJson != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode,
        compListingFloor: compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          compListingCeiling ??
          (compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabled,
        compListingExcludeFanInventory,
        compListingExcludeDefects,
        compListingSelectedSectionSettings,
        compListingQuantityScoreAdjustmentEnabled,
        compListingQuantityScoreAdjustmentOverrideJson,
      };
    }

    if (
      groupPricingSettings?.compListingMode != null ||
      groupPricingSettings?.compListingFloor != null ||
      groupPricingSettings?.compListingCeiling != null ||
      groupPricingSettings?.compListingOnlyForSameZoneEnabled != null ||
      groupPricingSettings?.compListingOnlyForSelectedSectionsEnabled != null ||
      groupPricingSettings?.compListingExcludeFanInventory != null ||
      groupPricingSettings?.compListingExcludeDefects != null ||
      groupPricingSettings?.compListingQuantityScoreAdjustmentEnabled != null ||
      groupPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson !=
        null ||
      groupPricingSettings?.compListingSelectedSectionSettings != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode: groupPricingSettings?.compListingMode ?? null,
        compListingFloor:
          groupPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          groupPricingSettings?.compListingCeiling ??
          (groupPricingSettings?.compListingFloor ??
            DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled:
          groupPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
        compListingOnlyForSelectedSectionsEnabled:
          groupPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
          null,
        compListingQuantityScoreAdjustmentEnabled:
          groupPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
          null,
        compListingExcludeFanInventory:
          groupPricingSettings?.compListingExcludeFanInventory ?? null,
        compListingExcludeDefects:
          groupPricingSettings?.compListingExcludeDefects ?? null,
        compListingQuantityScoreAdjustmentOverrideJson:
          groupPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
          null,
        compListingSelectedSectionSettings:
          groupPricingSettings?.compListingSelectedSectionSettings ?? null,
      };
    }

    if (
      eventPricingSettings?.compListingMode != null ||
      eventPricingSettings?.compListingFloor != null ||
      eventPricingSettings?.compListingCeiling != null ||
      eventPricingSettings?.compListingOnlyForSameZoneEnabled != null ||
      eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled != null ||
      eventPricingSettings?.compListingExcludeFanInventory != null ||
      eventPricingSettings?.compListingExcludeDefects != null ||
      eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled != null ||
      eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson !=
        null ||
      eventPricingSettings?.compListingSelectedSectionSettings != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode: eventPricingSettings?.compListingMode ?? null,
        compListingFloor:
          eventPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          eventPricingSettings?.compListingCeiling ??
          (eventPricingSettings?.compListingFloor ??
            DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled:
          eventPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
        compListingOnlyForSelectedSectionsEnabled:
          eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
          null,
        compListingQuantityScoreAdjustmentEnabled:
          eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
          null,
        compListingExcludeFanInventory:
          eventPricingSettings?.compListingExcludeFanInventory ?? null,
        compListingExcludeDefects:
          eventPricingSettings?.compListingExcludeDefects ?? null,
        compListingQuantityScoreAdjustmentOverrideJson:
          eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
          null,
        compListingSelectedSectionSettings:
          eventPricingSettings?.compListingSelectedSectionSettings ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      compListingMode:
        accountPricingSettings?.compListingMode ?? defaultCompListingMode,
      compListingFloor:
        accountPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
      compListingCeiling:
        accountPricingSettings?.compListingCeiling ??
        (accountPricingSettings?.compListingFloor ??
          DEFAULT_COMP_LISTING_FLOOR) +
          DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
      compListingOnlyForSameZoneEnabled:
        accountPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
      compListingOnlyForSelectedSectionsEnabled:
        accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
        null,
      compListingQuantityScoreAdjustmentEnabled:
        accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
        null,
      compListingExcludeFanInventory:
        accountPricingSettings?.compListingExcludeFanInventory ?? null,
      compListingExcludeDefects:
        accountPricingSettings?.compListingExcludeDefects ?? null,
      compListingQuantityScoreAdjustmentOverrideJson:
        accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
        null,
      compListingSelectedSectionSettings:
        accountPricingSettings?.compListingSelectedSectionSettings ?? null,
    };
  }, [
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingSelectedSectionSettings,
    compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,
    groupPricingSettings?.compListingMode,
    groupPricingSettings?.compListingFloor,
    groupPricingSettings?.compListingCeiling,
    groupPricingSettings?.compListingOnlyForSameZoneEnabled,
    groupPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    groupPricingSettings?.compListingExcludeFanInventory,
    groupPricingSettings?.compListingExcludeDefects,
    groupPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    groupPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    groupPricingSettings?.compListingSelectedSectionSettings,
    eventPricingSettings?.compListingMode,
    eventPricingSettings?.compListingFloor,
    eventPricingSettings?.compListingCeiling,
    eventPricingSettings?.compListingOnlyForSameZoneEnabled,
    eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    eventPricingSettings?.compListingExcludeFanInventory,
    eventPricingSettings?.compListingExcludeDefects,
    eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    eventPricingSettings?.compListingSelectedSectionSettings,
    accountPricingSettings?.compListingMode,
    accountPricingSettings?.compListingFloor,
    accountPricingSettings?.compListingCeiling,
    accountPricingSettings?.compListingOnlyForSameZoneEnabled,
    accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    accountPricingSettings?.compListingExcludeFanInventory,
    accountPricingSettings?.compListingExcludeDefects,
    accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    accountPricingSettings?.compListingSelectedSectionSettings,
    defaultCompListingMode,
  ]);

  const outlierSettings = useMemo(() => {
    if (
      outlierMode != null ||
      outlierStandardDeviations != null ||
      outlierKthLowestLimit != null
    ) {
      // if it has any of these set - use the entire area
      return {
        outlierMode,
        outlierStandardDeviations,
        outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacing,
      };
    }

    if (
      groupPricingSettings?.outlierMode != null ||
      groupPricingSettings?.outlierStandardDeviations != null
      // TODO - add rest of settings here
    ) {
      // if it has any of these set - use the entire area
      return {
        outlierMode: groupPricingSettings?.outlierMode ?? null,
        outlierStandardDeviations:
          groupPricingSettings?.outlierStandardDeviations ?? null,
      };
    }

    if (
      eventPricingSettings?.outlierMode != null ||
      eventPricingSettings?.outlierStandardDeviations != null
      // TODO - add rest of settings here
    ) {
      // if it has any of these set - use the entire area
      return {
        outlierMode: eventPricingSettings?.outlierMode ?? null,
        outlierStandardDeviations:
          eventPricingSettings?.outlierStandardDeviations ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      outlierMode: accountPricingSettings?.outlierMode ?? null,
      outlierStandardDeviations:
        accountPricingSettings?.outlierStandardDeviations ?? null,
      // TODO - add rest of settings here
    };
  }, [
    accountPricingSettings?.outlierMode,
    accountPricingSettings?.outlierStandardDeviations,
    eventPricingSettings?.outlierMode,
    eventPricingSettings?.outlierStandardDeviations,
    groupPricingSettings?.outlierMode,
    groupPricingSettings?.outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierMode,
    outlierStandardDeviations,
  ]);

  const onUndercutChange = useCallback(
    (
      undercutModeNew?: AutoPricingUndercutMode | null,
      undercutAbsoluteAmountNew?: number | null,
      undercutRelativeAmountNew?: number | null
    ) => {
      if (
        undercutModeNew !== undefined ||
        undercutAbsoluteAmountNew !== undefined ||
        undercutRelativeAmountNew !== undefined
      ) {
        setValue(
          'undercutMode',
          coalesceUndefinedOnly(undercutModeNew, undercutSettings.undercutMode)!
        );
        setValue(
          'undercutAbsoluteAmount',
          coalesceUndefinedOnly(
            undercutAbsoluteAmountNew,
            undercutSettings.undercutAbsoluteAmount
          )!
        );
        setValue(
          'undercutRelativeAmount',
          coalesceUndefinedOnly(
            undercutRelativeAmountNew,
            undercutSettings.undercutRelativeAmount
          )!
        );
      }
    },
    [
      setValue,
      undercutSettings.undercutAbsoluteAmount,
      undercutSettings.undercutMode,
      undercutSettings.undercutRelativeAmount,
    ]
  );

  const onCompListingChange = useCallback(
    (input: {
      compListingModeNew?: AutoPricingCompListingMode | null;
      compListingFloorNew?: number | null;
      compListingCeilingNew?: number | null;
      compListingOnlyForSameZoneEnabledNew?: boolean | null;
      compListingOnlyForSelectedSectionsEnabledNew?: boolean | null;
      compListingQuantityScoreAdjustmentEnabledNew?: boolean | null;
      compListingExcludeFanInventoryNew?: boolean | null;
      compListingExcludeDefectsNew?: boolean | null;
      compListingQuantityScoreAdjustmentOverrideJsonNew?: string | null;
      compListingSelectedSectionSettingsNew?: SelectedSectionSettingsWithOptionals | null;
    }) => {
      const {
        compListingModeNew,
        compListingFloorNew,
        compListingCeilingNew,
        compListingOnlyForSameZoneEnabledNew,
        compListingOnlyForSelectedSectionsEnabledNew,
        compListingQuantityScoreAdjustmentEnabledNew,
        compListingExcludeFanInventoryNew,
        compListingExcludeDefectsNew,
        compListingQuantityScoreAdjustmentOverrideJsonNew,
        compListingSelectedSectionSettingsNew,
      } = input;

      if (
        compListingModeNew !== undefined ||
        compListingFloorNew !== undefined ||
        compListingCeilingNew !== undefined ||
        compListingOnlyForSameZoneEnabledNew !== undefined ||
        compListingOnlyForSelectedSectionsEnabledNew !== undefined ||
        compListingQuantityScoreAdjustmentEnabledNew !== undefined ||
        compListingExcludeFanInventoryNew !== undefined ||
        compListingExcludeDefectsNew !== undefined ||
        compListingQuantityScoreAdjustmentOverrideJsonNew !== undefined ||
        compListingSelectedSectionSettingsNew !== undefined
      ) {
        const sanitizedCompListingOnlyForSameZoneEnabledNew =
          compListingOnlyForSelectedSectionsEnabledNew
            ? false
            : compListingOnlyForSameZoneEnabledNew;
        const sanitizedCompListingOnlyForSelectedSectionsEnabledNew =
          compListingOnlyForSameZoneEnabledNew
            ? false
            : compListingOnlyForSelectedSectionsEnabledNew;

        setValue(
          'compListingMode',
          coalesceUndefinedOnly(
            compListingModeNew,
            compListingSettings.compListingMode
          )!
        );
        setValue(
          'compListingFloor',
          coalesceUndefinedOnly(
            compListingFloorNew,
            compListingSettings.compListingFloor
          )!
        );
        setValue(
          'compListingCeiling',
          coalesceUndefinedOnly(
            compListingCeilingNew,
            compListingSettings.compListingCeiling
          )!
        );
        setValue(
          'compListingOnlyForSameZoneEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSameZoneEnabledNew,
            compListingSettings.compListingOnlyForSameZoneEnabled
          )!
        );
        setValue(
          'compListingOnlyForSelectedSectionsEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSelectedSectionsEnabledNew,
            compListingSettings.compListingOnlyForSelectedSectionsEnabled
          )!
        );
        setValue(
          'compListingQuantityScoreAdjustmentEnabled',
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentEnabledNew,
            compListingSettings.compListingQuantityScoreAdjustmentEnabled
          )!
        );
        setValue(
          'compListingExcludeFanInventory',
          coalesceUndefinedOnly(
            compListingExcludeFanInventoryNew,
            compListingSettings.compListingExcludeFanInventory
          )!
        );
        setValue(
          'compListingExcludeDefects',
          coalesceUndefinedOnly(
            compListingExcludeDefectsNew,
            compListingSettings.compListingExcludeDefects
          )!
        );
        setValue(
          'compListingQuantityScoreAdjustmentOverrideJson',
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentOverrideJsonNew,
            compListingSettings.compListingQuantityScoreAdjustmentOverrideJson
          )!
        );
        setValue(
          'compListingSelectedSectionSettings',
          coalesceUndefinedOnly(
            mapToSelectedSectionSettings(compListingSelectedSectionSettingsNew),
            compListingSettings.compListingSelectedSectionSettings
          )!
        );
      }
    },
    [
      compListingSettings.compListingCeiling,
      compListingSettings.compListingFloor,
      compListingSettings.compListingMode,
      compListingSettings.compListingOnlyForSameZoneEnabled,
      compListingSettings.compListingOnlyForSelectedSectionsEnabled,
      compListingSettings.compListingQuantityScoreAdjustmentEnabled,
      compListingSettings.compListingExcludeFanInventory,
      compListingSettings.compListingExcludeDefects,
      compListingSettings.compListingQuantityScoreAdjustmentOverrideJson,
      compListingSettings.compListingSelectedSectionSettings,
      setValue,
    ]
  );

  const onCircuitBreakerChange = useCallback(
    (
      circuitBreakerRelativeCeilingNew?: number | null,
      circuitBreakerRelativeFloorNew?: number | null,
      circuitBreakerMaxDiscountVelocityPercentNew?: number | null,
      circuitBreakerMaxDiscountVelocityTicksInHoursNew?: number | null,
      circuitBreakerMinCompListingCountNew?: number | null
    ) => {
      if (
        circuitBreakerRelativeCeilingNew !== undefined ||
        circuitBreakerRelativeFloorNew !== undefined ||
        circuitBreakerMaxDiscountVelocityPercentNew !== undefined ||
        circuitBreakerMaxDiscountVelocityTicksInHoursNew !== undefined ||
        circuitBreakerMinCompListingCountNew !== undefined
      ) {
        setValue(
          'circuitBreakerRelativeCeiling',
          coalesceUndefinedOnly(
            circuitBreakerRelativeCeilingNew,
            circuitBreakerSettings.circuitBreakerRelativeCeiling
          ) ?? null
        );
        setValue(
          'circuitBreakerRelativeFloor',
          coalesceUndefinedOnly(
            circuitBreakerRelativeFloorNew,
            circuitBreakerSettings.circuitBreakerRelativeFloor
          ) ?? null
        );
        setValue(
          'circuitBreakerMaxDiscountVelocityPercent',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityPercentNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent
          ) ?? null
        );
        setValue(
          'circuitBreakerMaxDiscountVelocityTicksInHours',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityTicksInHoursNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours
          ) ?? null
        );
        setValue(
          'circuitBreakerMinCompListingCount',
          coalesceUndefinedOnly(
            circuitBreakerMinCompListingCountNew,
            circuitBreakerSettings.circuitBreakerMinCompListingCount
          ) ?? null
        );
      }
    },
    [
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerSettings.circuitBreakerMinCompListingCount,
      circuitBreakerSettings.circuitBreakerRelativeCeiling,
      circuitBreakerSettings.circuitBreakerRelativeFloor,
      setValue,
    ]
  );

  const onOutlierChange = useCallback(
    (input: {
      outlierModeNew?: AutoPricingOutlierMode | null;
      outlierStandardDeviationsNew?: number | null;
      outlierKthLowestLimitNew?: number | null;
      outlierKthLowestLimitRelativeSpacingNew?: number | null;
      outlierKthLowestLimitAbsoluteSpacingNew?: number | null;
    }) => {
      const {
        outlierModeNew,
        outlierStandardDeviationsNew,
        outlierKthLowestLimitNew,
        outlierKthLowestLimitRelativeSpacingNew,
        outlierKthLowestLimitAbsoluteSpacingNew,
      } = input;

      // Covers both null and undefined cases
      if (outlierModeNew !== undefined) {
        setValue(
          'outlierMode',
          coalesceUndefinedOnly(outlierModeNew, outlierSettings.outlierMode) ??
            null
        );

        switch (outlierModeNew) {
          case AutoPricingOutlierMode.StandardDeviations:
            setValue(
              'outlierStandardDeviations',
              coalesceUndefinedOnly(
                outlierStandardDeviationsNew,
                outlierSettings.outlierStandardDeviations
              ) ?? null
            );
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
            break;
          case AutoPricingOutlierMode.KthLowest:
            setValue('outlierStandardDeviations', null);
            setValue(
              'outlierKthLowestLimit',
              coalesceUndefinedOnly(
                outlierKthLowestLimitNew,
                outlierSettings.outlierKthLowestLimit
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitRelativeSpacingNew,
                outlierSettings.outlierKthLowestLimitRelativeSpacing
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitAbsoluteSpacingNew,
                outlierSettings.outlierKthLowestLimitAbsoluteSpacing
              ) ?? null
            );
            break;
          case AutoPricingOutlierMode.SkipLogic:
            setValue('outlierStandardDeviations', null);
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
        }
      } else {
        setValue('outlierMode', null);
        setValue('outlierStandardDeviations', null);
        setValue('outlierKthLowestLimit', null);
        setValue('outlierKthLowestLimitRelativeSpacing', null);
        setValue('outlierKthLowestLimitAbsoluteSpacing', null);
      }
    },
    [
      outlierSettings.outlierKthLowestLimit,
      outlierSettings.outlierKthLowestLimitAbsoluteSpacing,
      outlierSettings.outlierKthLowestLimitRelativeSpacing,
      outlierSettings.outlierMode,
      outlierSettings.outlierStandardDeviations,
      setValue,
    ]
  );

  const setAutoPricingDefaults = useCallback(() => {
    if (!netProceedsFloor) {
      const proposedFloor = unitNetProceeds
        ? // If we have a non-zero list-price, we want the min of listprice or unitCost
          Math.min(
            unitNetProceeds,
            listing?.unitCst?.amt ??
              listing?.faceValue?.amt ??
              Number.MAX_SAFE_INTEGER
          )
        : // Else just use the unitCost
          (listing?.unitCst?.amt ?? listing?.faceValue?.amt ?? null);

      setValue('netProceedsFloor', proposedFloor);
    }

    // If this or any higher level don't have, set the default
    if (
      !undercutMode &&
      !eventPricingSettings?.undercutMode &&
      !accountPricingSettings?.undercutMode
    ) {
      setValue('undercutMode', undercutMode);
      setValue('undercutAbsoluteAmount', undercutAbsoluteAmount);
      setValue('undercutRelativeAmount', undercutRelativeAmount);
    }

    // If this or any higher level don't have, set the default
    if (
      !compListingMode &&
      !eventPricingSettings?.compListingMode &&
      !accountPricingSettings?.compListingMode
    ) {
      setValue('compListingMode', compListingMode);
      setValue('compListingFloor', compListingFloor);
      setValue('compListingCeiling', compListingCeiling);
    }
  }, [
    accountPricingSettings?.compListingMode,
    accountPricingSettings?.undercutMode,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    eventPricingSettings?.compListingMode,
    eventPricingSettings?.undercutMode,
    listing?.faceValue?.amt,
    listing?.unitCst?.amt,
    netProceedsFloor,
    setValue,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
    unitNetProceeds,
  ]);

  const applySectionRowFilterCompListingChanges = useCallback(
    (
      newSectionIdFilter: number[],
      sections: SectionInfo[],
      compListingSelectedSectionSettingsNew?:
        | SelectedSectionSettingsWithOptionals
        | null
        | undefined
    ) => {
      // If sectionIdFilter is empty, we should disable the compListingOnlyForSelectedSectionsEnabled
      // and revert back to QualitScore mode. When users add section filters, we should enable
      // compListingOnlyForSelectedSectionsEnabled and put the mode to SameEvent only on when the first
      // section is added. If the user swaps the comp mode after the first section we don't want to keep
      // forcing them back into SameEvent mode.
      if (newSectionIdFilter.length === 0 && sections.length > 0) {
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingModeNew: defaultCompListingMode,
          compListingOnlyForSelectedSectionsEnabledNew: false,
        });
      } else {
        if (newSectionIdFilter.length === 1 && sections.length === 0) {
          onCompListingChange({
            compListingSelectedSectionSettingsNew,
            compListingModeNew: AutoPricingCompListingMode.SameEvent,
            compListingOnlyForSelectedSectionsEnabledNew: true,
          });

          return;
        }

        // Otherwise, just update the section filters and ensure the selectedSections flag is true
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingOnlyForSelectedSectionsEnabledNew: true,
        });
      }
    },
    [defaultCompListingMode, onCompListingChange]
  );

  const onCompListingSectionIdFilterChange = useCallback(
    (
      sectionId: number,
      selectedSections: SectionInfo[],
      section?: SectionInfo,
      listing?: Listing
    ) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.rowIdFilter ?? [];

      let newSectionRowIdFilter =
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

      if (newSectionIdFilter.includes(sectionId)) {
        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => id !== sectionId
        );

        // Remove all the associated row ids for this section
        const section = selectedSections.find((s) => s.id === sectionId);
        if (section) {
          const rowIds = section.rows.map((r) => r.id);
          newRowIdFilter = newRowIdFilter.filter((id) => !rowIds.includes(id));
        }

        // Remove the sectionRow mapping so we don't display it in pills
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [sectionId]: omitted, ...updatedSectionRowIdFilter } =
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

        newSectionRowIdFilter = updatedSectionRowIdFilter;
      } else {
        newSectionIdFilter.push(sectionId);

        // If a section is present, add all the rows associated with the newly selected section
        if (
          hasDefaultFilterRowsFeature &&
          listing &&
          section &&
          listing.seating?.row
        ) {
          const { seating: listingSeating } = listing;
          const listingRow = listingSeating.row!;

          const matchingRow = section.rows.find(
            ({ id, name }) =>
              id === listingSeating.rowId ||
              name?.toLowerCase() === listingRow.toLowerCase()
          );

          if (matchingRow) {
            // Remove all the row filters associated with the section id
            const sectionRowIds = section.rows.map((r) => r.id);
            newRowIdFilter = [
              ...newRowIdFilter.filter(
                (rowId) => !sectionRowIds.includes(rowId)
              ),
            ];

            // Exclude all rows from section if the matching row has no ordinal
            if (matchingRow.ordinal == null) {
              newSectionRowIdFilter[section.id] = matchingRow.id.toString();
              return;
            }

            // Push all the row ids with an ordinal less than or equal to the matching row
            section.rows.forEach((row) => {
              if (row.ordinal == null || matchingRow.ordinal == null) return;
              if (row.ordinal <= matchingRow.ordinal) {
                newRowIdFilter.push(row.id);
              }
            });

            // Only store the highest ordinal row id to display in the pill
            newSectionRowIdFilter[section.id] = matchingRow.id.toString();
          }
        } else if (section) {
          // If listing or seating info is not present, add all the section rows as a filter
          const newRowIds = section.rows.map((r) => r.id);
          newRowIds.forEach((newRowId) => {
            if (!newRowIdFilter.includes(newRowId)) {
              newRowIdFilter.push(newRowId);
            }
          });
        }
      }

      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        selectedSections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
      hasDefaultFilterRowsFeature,
    ]
  );

  const onCompListingSectionZoneChange = useCallback(
    (sections: SectionInfo[], flipOnSameSections = true) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.rowIdFilter ?? [];

      const newSectionRowIdFilter =
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

      // If every section passed in already exists in prevSections then we should remove them entirely
      const completelyOverlap = sections.every((section) =>
        newSectionIdFilter.includes(section.id)
      );

      if (completelyOverlap && flipOnSameSections) {
        const sectionIdsToRemove = new Set(
          sections.map((section) => section.id)
        );

        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => !sectionIdsToRemove.has(id)
        );

        // Remove all associated rows as well
        const rowIdsToRemove = sections
          .map((section) => section.rows.map((r) => r.id))
          .flat();

        const rowIdsToRemoveSet = new Set(rowIdsToRemove);

        newRowIdFilter = newRowIdFilter.filter(
          (rowId) => !rowIdsToRemoveSet.has(rowId)
        );

        // Update sectionRowIdFilters to remove the sections that have been removed
        sections.forEach((section) => {
          if (section.id in newSectionRowIdFilter) {
            delete newSectionRowIdFilter[section.id];
          }
        });
      } else {
        // Upsert of the new sections
        sections.forEach((section) => {
          if (section.id in newSectionRowIdFilter) {
            return;
          }

          newSectionIdFilter.push(section.id);
          if (hasDefaultFilterRowsFeature && listing && listing.seating?.row) {
            const { seating: listingSeating } = listing;
            const listingRow = listingSeating.row!;

            const matchingRow = section.rows.find(
              ({ id, name }) =>
                id === listingSeating.rowId ||
                name?.toLowerCase() === listingRow.toLowerCase()
            );

            if (matchingRow) {
              // Remove all the row filters associated with the section id
              const sectionRowIds = section.rows.map((r) => r.id);
              newRowIdFilter = [
                ...newRowIdFilter.filter(
                  (rowId) => !sectionRowIds.includes(rowId)
                ),
              ];

              // Exclude all rows from section if the matching row has no ordinal
              if (matchingRow.ordinal == null) {
                newSectionRowIdFilter[section.id] = matchingRow.id.toString();
                return;
              }

              // Push all the row ids with an ordinal less than or equal to the matching row
              section.rows.forEach((row) => {
                if (row.ordinal == null || matchingRow.ordinal == null) return;
                if (row.ordinal <= matchingRow.ordinal) {
                  newRowIdFilter.push(row.id);
                }
              });

              // Only store the highest ordinal row id to display in the pill
              newSectionRowIdFilter[section.id] = matchingRow.id.toString();
            }
          } else {
            // If listing or seating info is not present, add all the section rows as a filter
            const newRowIds = section.rows.map((r) => r.id);
            newRowIds.forEach((newRowId) => {
              if (!newRowIdFilter.includes(newRowId)) {
                newRowIdFilter.push(newRowId);
              }
            });
          }
        });
      }

      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        sections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
      hasDefaultFilterRowsFeature,
      listing,
    ]
  );

  const hasChanges = useCallback(
    (listingForm: ListingDetailsUpdateInput) => {
      // Make sure we only send back the settable props
      if (
        formState.defaultValues?.autoPricingEnabled !==
          listingForm.autoPricingEnabled ||
        formState.defaultValues?.netProceedsFloor !==
          listingForm.netProceedsFloor ||
        formState.defaultValues?.compListingMode !==
          listingForm.compListingMode ||
        formState.defaultValues?.compListingFloor !==
          listingForm.compListingFloor ||
        formState.defaultValues?.compListingCeiling !==
          listingForm.compListingCeiling ||
        formState.defaultValues?.compListingQuantityScoreAdjustmentEnabled !==
          listingForm.compListingQuantityScoreAdjustmentEnabled ||
        formState.defaultValues
          ?.compListingQuantityScoreAdjustmentOverrideJson !==
          listingForm.compListingQuantityScoreAdjustmentOverrideJson ||
        formState.defaultValues?.compListingOnlyForSameZoneEnabled !==
          listingForm.compListingOnlyForSameZoneEnabled ||
        formState.defaultValues?.compListingOnlyForSelectedSectionsEnabled !==
          listingForm.compListingOnlyForSelectedSectionsEnabled ||
        formState.defaultValues?.compListingExcludeFanInventory !==
          listingForm.compListingExcludeFanInventory ||
        formState.defaultValues?.compListingExcludeDefects !==
          listingForm.compListingExcludeDefects ||
        !isEqual(
          formState.defaultValues?.compListingSelectedSectionSettings,
          listingForm?.compListingSelectedSectionSettings
        ) ||
        formState.defaultValues?.undercutMode !== listingForm.undercutMode ||
        formState.defaultValues?.undercutAbsoluteAmount !==
          listingForm.undercutAbsoluteAmount ||
        formState.defaultValues?.undercutRelativeAmount !==
          listingForm.undercutRelativeAmount ||
        formState.defaultValues?.circuitBreakerMaxDiscountVelocityPercent !==
          listingForm.circuitBreakerMaxDiscountVelocityPercent ||
        formState.defaultValues
          ?.circuitBreakerMaxDiscountVelocityTicksInHours !==
          listingForm.circuitBreakerMaxDiscountVelocityTicksInHours ||
        formState.defaultValues?.circuitBreakerMinCompListingCount !==
          listingForm.circuitBreakerMinCompListingCount ||
        formState.defaultValues?.circuitBreakerRelativeCeiling !==
          listingForm.circuitBreakerRelativeCeiling ||
        formState.defaultValues?.circuitBreakerRelativeFloor !==
          listingForm.circuitBreakerRelativeFloor ||
        formState.defaultValues?.outlierMode !== listingForm.outlierMode ||
        formState.defaultValues?.outlierStandardDeviations !==
          listingForm.outlierStandardDeviations ||
        formState.defaultValues?.outlierKthLowestLimit !==
          listingForm.outlierKthLowestLimit ||
        formState.defaultValues?.outlierKthLowestLimitRelativeSpacing !==
          listingForm.outlierKthLowestLimitRelativeSpacing ||
        formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing !==
          listingForm.outlierKthLowestLimitAbsoluteSpacing
      ) {
        return true;
      }
    },
    [
      formState.defaultValues?.autoPricingEnabled,
      formState.defaultValues?.netProceedsFloor,
      formState.defaultValues?.compListingMode,
      formState.defaultValues?.compListingFloor,
      formState.defaultValues?.compListingCeiling,
      formState.defaultValues?.compListingQuantityScoreAdjustmentEnabled,
      formState.defaultValues?.compListingQuantityScoreAdjustmentOverrideJson,
      formState.defaultValues?.compListingOnlyForSameZoneEnabled,
      formState.defaultValues?.compListingOnlyForSelectedSectionsEnabled,
      formState.defaultValues?.compListingExcludeFanInventory,
      formState.defaultValues?.compListingExcludeDefects,
      formState.defaultValues?.compListingSelectedSectionSettings,
      formState.defaultValues?.undercutMode,
      formState.defaultValues?.undercutAbsoluteAmount,
      formState.defaultValues?.undercutRelativeAmount,
      formState.defaultValues?.circuitBreakerMaxDiscountVelocityPercent,
      formState.defaultValues?.circuitBreakerMaxDiscountVelocityTicksInHours,
      formState.defaultValues?.circuitBreakerMinCompListingCount,
      formState.defaultValues?.circuitBreakerRelativeCeiling,
      formState.defaultValues?.circuitBreakerRelativeFloor,
      formState.defaultValues?.outlierMode,
      formState.defaultValues?.outlierStandardDeviations,
      formState.defaultValues?.outlierKthLowestLimit,
      formState.defaultValues?.outlierKthLowestLimitRelativeSpacing,
      formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing,
    ]
  );

  const onCompListingModeChange = useCallback(
    (mode: AutoPricingCompListingMode | null) => {
      clearErrors('compListingFloor');

      if (!mode) {
        onCompListingChange({
          compListingModeNew: null,
          compListingFloorNew: null,
          compListingCeilingNew: null,
        });
        return;
      }

      onCompListingChange({
        compListingModeNew: mode,
        // Reset flag on mode change
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: false,
      });

      if (mode === AutoPricingCompListingMode.ParentListing) {
        onUndercutChange(AutoPricingUndercutMode.Simple);
      }
    },
    [clearErrors, onCompListingChange, onUndercutChange]
  );

  const onSelectedSectionsChange = useCallback(
    (selectedSections: SectionInfo[]) => {
      // Restructure the id filters.
      const sectionIdFilter = selectedSections.map((s) => s.id);
      const selectedRowIds = selectedSections
        .flatMap(({ rows }) => rows)
        .map(({ id }) => id);
      const rowIdFilter = [];
      if (isEmpty(compListingSelectedSectionSettings?.rowIdFilter)) {
        rowIdFilter.push(...selectedRowIds);
      } else {
        rowIdFilter.push(
          ...(compListingSelectedSectionSettings?.rowIdFilter?.filter((rowId) =>
            selectedRowIds.includes(rowId)
          ) ?? [])
        );
      }
      const sectionRowIdFilter = pickBy(
        Object.assign(
          {},
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
        ),
        (_, key) => sectionIdFilter.includes(parseInt(key))
      );
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter,
        rowIdFilter,
        sectionRowIdFilter,
      };
      onCompListingChange({
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: true,
        compListingSelectedSectionSettingsNew,
      });
    },
    [compListingSelectedSectionSettings, onCompListingChange]
  );

  return {
    canSetPrice,
    canAutoPrice,
    uiCurrency,
    currencyNumberFormat,
    showAutoPricingSettings: !(listing?.isDeleted || !canSetPrice),

    autoPricingEnabled,
    onResetSettings,
    onResetAdvanceSettings,
    setAutoPricingDefaults,
    eventPricingSettings,
    accountPricingSettings,
    event,

    ...circuitBreakerSettings,
    ...compListingSettings,
    ...undercutSettings,
    ...outlierSettings,

    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
    onCompListingSectionIdFilterChange,
    onCompListingSectionZoneChange,
    onCompListingModeChange,
    onSelectedSectionsChange,

    skipAdvancePricing,
    skipAutoPricing,
    skipCompListing,
    skipPriceProtection,
    skipUndercut,
    hasChanges,
  };
};
