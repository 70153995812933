import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EventAccordionItemProps,
  translateEventToInventoryAccordionItem,
} from 'src/components/Accordions';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { BackSection } from 'src/components/common/BackSection';
import { StubHubListingManualPricingContextProvider } from 'src/components/Listings/InventoryEventPage/MarketListings/StubHubListingsManualPricingContext';
import { ActiveFocusContextProvider } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { SidePanelProvider } from 'src/contexts/SidePanelContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DemandDashboardEventIdQueryParam,
  DemandDashboardIsOnSaleEventQueryParam,
} from 'src/utils/constants/constants';
import {
  EventWithData,
  Feature,
  Listing,
  Performer,
  Venue,
} from 'src/WebApiController';

import * as styles from './InventoryEventPage.css';
import { InventoryEventPageHeader } from './InventoryEventPageHeader';
import { useInventoryDashboardEvent } from './InventoryEventPageV2.hooks';
import { InventoryEventDashboardSection } from './Sections/InventoryEventDashboardSection';

export function InventoryEventPageV2({
  viagVirtualId,
  returnUrl,
}: {
  viagVirtualId: string;
  returnUrl?: string;
}) {
  const { eventsTransformed } = useCatalogDataContext();
  const [searchParams, _] = useSearchParams();
  const hasEventDiscoveryPageFeature = useUserHasFeature(
    Feature.EventDiscoveryPage
  );
  const isOnSaleEvent =
    Boolean(searchParams.get(DemandDashboardIsOnSaleEventQueryParam)) &&
    hasEventDiscoveryPageFeature;
  const onSaleEventId = Number(
    searchParams.get(DemandDashboardEventIdQueryParam)
  );

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.find(
        (e) =>
          e.event.viagVirtualId.toLowerCase() === viagVirtualId.toLowerCase()
      ),

    [eventsTransformed, viagVirtualId]
  );

  const {
    event: eventWithData,
    venue,
    performer,
  } = useInventoryDashboardEvent(
    accordionItemProps,
    isOnSaleEvent,
    onSaleEventId
  );

  const inventoryAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToInventoryAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  return (
    <SidePanelProvider sidePanelId="inventory-event">
      <ActiveFocusContextProvider>
        <EventMapContextProvider event={eventWithData?.event}>
          <InputPriceFocusProvider
            disablePagination
            pageSize={eventWithData?.counts?.listCnt ?? 0}
          >
            <StubHubListingManualPricingContextProvider
              groupId={eventWithData?.event.viagVirtualId}
            >
              <Stack direction="column" height="full">
                <InventoryEventContent
                  accordionItemProps={eventWithData ?? undefined}
                  inventoryAccordionItem={inventoryAccordionItem}
                  venue={venue}
                  performer={performer}
                  returnUrl={returnUrl}
                  isOnSaleEvent={isOnSaleEvent}
                />
              </Stack>
            </StubHubListingManualPricingContextProvider>
          </InputPriceFocusProvider>
        </EventMapContextProvider>
      </ActiveFocusContextProvider>
    </SidePanelProvider>
  );
}

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps<Listing>;
  venue?: Venue;
  performer?: Performer;
  returnUrl?: string;
  isOnSaleEvent?: boolean;
};

export const enum InventoryEventV2Tabs {
  Dashboard = 'dashboard',
  None = 'none',
}

function InventoryEventContent({
  accordionItemProps,
  inventoryAccordionItem,
  venue,
  performer,
  returnUrl,
  isOnSaleEvent,
}: InventoryEventContentProps) {
  const { isLoading } = useCatalogDataContext();
  const isMobile = useMatchMedia('mobile');

  const event = accordionItemProps?.event;

  if (isLoading || !event) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.leftContainer} style={{ height: '100%' }}>
        {isMobile && (
          <Stack
            justifyContent="spaceBetween"
            alignItems="center"
            style={{
              padding: `0 ${vars.spacing['xl']} ${vars.spacing['lg']} ${vars.spacing['xl']}`,
            }}
          >
            <BackSection
              returnUrl={returnUrl ?? '/inventory'}
              state={{
                fromPosEventId: event.viagVirtualId,
              }}
            />
            <SwiperButton dir="right" />
          </Stack>
        )}
        <InventoryEventPageHeader
          event={event}
          venue={venue ?? undefined}
          performer={performer ?? undefined}
          inventoryAccordionItem={inventoryAccordionItem}
          isOnSaleEvent={isOnSaleEvent}
        />
        <InventoryEventDashboardSection
          accordionItemProps={accordionItemProps}
          isOnSaleEvent={isOnSaleEvent}
        />
      </div>
    </div>
  );
}
