import { ScrollArea, Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { sortSeating } from 'src/utils/tableUtils';
import { Listing } from 'src/WebApiController';

import { ListingsCarouselCard } from '../ListingsCarouselCard/ListingsCarouselCard';
import * as styles from './ListingsCarousel.css';
import { VerticalDivider } from './ListingsCarousel.styled';

export type ListingsCarouselProps = {
  listings: Listing[];
  selectedListing: Listing | null;
  setSelectedListing: (listing: Listing | null) => void;
};

const MY_LISTINGS_KEY = 0;
const DIVIDER_LINE_KEY = 1;

export const ListingsCarousel = ({
  listings,
  selectedListing,
  setSelectedListing,
}: ListingsCarouselProps) => {
  return (
    <Stack direction="column" gap="s" className={styles.root}>
      <ScrollArea showScrollEdges>
        <Stack
          direction="row"
          gap="m"
          alignItems="center"
          className={styles.cardContainer}
        >
          <ListingsCarouselCard
            key={MY_LISTINGS_KEY}
            listingsCount={listings.length}
            isSelected={!selectedListing}
            onClick={() => setSelectedListing?.(null)}
          />

          <VerticalDivider key={DIVIDER_LINE_KEY} />

          {listings
            .sort((a, b) => sortSeating(a.seating, b.seating))
            .map((l, i) => {
              const index = i + 2;
              return (
                <ListingsCarouselCard
                  key={index}
                  section={l.seating.section}
                  row={l.seating.row}
                  isSelected={l === selectedListing}
                  onClick={() => setSelectedListing?.(l)}
                />
              );
            })}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};
