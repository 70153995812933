import { SelectedSectionSettings } from 'src/WebApiController';

export type SelectedSectionSettingsWithOptionals = Omit<
  SelectedSectionSettings,
  'sectionIdFilterFromZoneMap' | 'includeBetterZones' | 'rowOffset'
> & {
  sectionIdFilterFromZoneMap?: boolean;
  includeBetterZones?: boolean;
  rowOffset?: number | null;
};

export const mapToSelectedSectionSettings = (
  settings: SelectedSectionSettingsWithOptionals | null | undefined
) => {
  if (!settings) {
    return settings;
  }

  return {
    ...settings,
    sectionIdFilterFromZoneMap: settings?.sectionIdFilterFromZoneMap ?? false,
    includeBetterZones: settings?.includeBetterZones ?? false,
    rowOffset: settings?.rowOffset ?? null,
  } as SelectedSectionSettings;
};
