import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { CatalogClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useCatalogMetricsContext } from './useCatalogMetricsContext';

export function useGeSummaryMetricsQuery<
  TMetrics extends { currency: string | null },
>(
  queryKey: string,
  performerIds: number[] | null | undefined,
  venueIds: number[] | null | undefined,
  mergeCatalogMetrics: (metrics: TMetrics[]) => TMetrics[],
  getCatalogSummaryMetrics?: (
    client: CatalogClient,
    allMetrics: TMetrics[],
    mergeCatalogMetrics: (metrics: TMetrics[]) => TMetrics[]
  ) => Promise<TMetrics>,
  disabled?: boolean
) {
  const { trackError } = useErrorBoundaryContext();
  const { eventsTransformed } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const eventIdsToGetSummaryMetricsFor = useMemo(
    () =>
      (eventsTransformed ?? [])
        .map((ev) => ({
          eventId: ev.event.viagVirtualId,
          performerId: ev.event.perfId,
          venueId: ev.event.venueId,
        }))
        .sort((ev1, ev2) => ev1.eventId.localeCompare(ev2.eventId)),
    [eventsTransformed]
  );

  const { eventDetailedMetrics, eventMetrics } =
    useCatalogMetricsContext<TMetrics>();

  const metricsToSummarize = useMemo(() => {
    const detailedMetrics = Object.entries(eventDetailedMetrics ?? {});
    const normalMetrics = Object.entries(eventMetrics ?? {});
    const metrics = (
      detailedMetrics.length ? detailedMetrics : normalMetrics
    ).map(([evId, evMetrics]) => {
      if (performerIds?.length) {
        if (
          eventIdsToGetSummaryMetricsFor.find(
            (eid) =>
              eid.eventId === evId &&
              eid.performerId != null &&
              performerIds.includes(eid.performerId)
          )
        ) {
          return evMetrics;
        }
      } else if (venueIds?.length) {
        if (
          eventIdsToGetSummaryMetricsFor.find(
            (eid) => eid.eventId === evId && venueIds.includes(eid.venueId)
          )
        ) {
          return evMetrics;
        }
      } else {
        return evMetrics;
      }

      return null;
    });

    return mergeCatalogMetrics(metrics.filter((m) => m != null).map((m) => m!));
  }, [
    eventDetailedMetrics,
    eventIdsToGetSummaryMetricsFor,
    eventMetrics,
    mergeCatalogMetrics,
    performerIds,
    venueIds,
  ]);

  const shouldQuery =
    !(
      !getCatalogSummaryMetrics || !activeAccountWebClientConfig.activeAccountId
    ) &&
    metricsToSummarize.length > 0 &&
    !disabled;

  const summaryMetricsQuery = useQuery({
    queryKey: [
      `${queryKey}-summary`,
      metricsToSummarize,
      activeAccountWebClientConfig,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () =>
          getCatalogSummaryMetrics!(
            new CatalogClient(activeAccountWebClientConfig),
            metricsToSummarize,
            mergeCatalogMetrics
          ),
        (error) => {
          // Since this is just loading metrics, we have the data so we'll just ignore that metrics error
          trackError(
            `${CatalogClient.name}.${CatalogClient.prototype.getSummaryMetricsForListings.name}`,
            error,
            {
              eventIds: eventIdsToGetSummaryMetricsFor,
              metricsToSummarize: metricsToSummarize,
            }
          );
        }
      );
    },

    enabled: shouldQuery,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return {
    summaryMetrics: summaryMetricsQuery.data ?? undefined,
    isLoading: summaryMetricsQuery.isLoading,
    refreshMetrics: () => summaryMetricsQuery.refetch(),
  };
}
