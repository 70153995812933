import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { EventWithData, GroupingType } from 'src/WebApiController';

import IconBulletList from '~icons/pos/bulletList';

import {
  GroupingTemplate,
  GroupingTemplateSeating,
  MergeListingGroupInputListFields,
} from '../groupingTypes';
import { flattenListingGroup } from '../groupingUtils';
import * as styles from './GroupingTemplates.css';
import { GroupTemplateTable } from './GroupTemplateTable';
import { SeatingSelectionTable } from './SeatingSelectionTable';

export const GroupingTemplates = ({ events }: { events: EventWithData[] }) => {
  const { watch, setValue } =
    useFormContext<MergeListingGroupInputListFields>();

  const groupBy = watch('templateSettings.groupBy');
  const groupTemplates = watch('templateSettings.groupTemplates');
  const desiredActiveListings = watch('templateSettings.desiredActiveListings');
  const deprioritizedQuantities = watch(
    'templateSettings.deprioritizedQuantities'
  );

  const uniqueSeatings = useMemo(
    () =>
      Object.values(
        events.reduce(
          (result, ev) => {
            ev.entities.listings?.flatMap(flattenListingGroup).forEach((l) => {
              const section = l.seating.section;
              const row = l.seating.row;
              let availQty = null;
              let unitCst = null;
              let faceValue = null;
              let key = `${section}__${row}`;

              if (groupBy.groupingType === GroupingType.Quantity) {
                key = `${l.availQty}`;
                availQty = l.availQty;
              } else if (groupBy.groupingType === GroupingType.UnitCost) {
                key = `${l.unitCst?.amt || l.faceValue?.amt || 0}`;
                unitCst = l.unitCst;
                faceValue = l.faceValue;
              }

              if (!result[key] || !result[key].seating.rowId) {
                result[key] = {
                  id: key,
                  seating: l.seating,
                  availQty,
                  unitCst,
                  faceValue,
                };
              }
            });

            return result;
          },
          {} as Record<string, GroupingTemplateSeating>
        )
      ),
    [events, groupBy.groupingType]
  );

  const onGroupTemplateChange = useCallback(
    (template: GroupingTemplate, remove?: boolean) => {
      if (remove) {
        setValue('templateSettings.groupTemplates', [
          ...groupTemplates.filter((t) => t.groupId !== template.groupId),
        ]);
      } else {
        setValue('templateSettings.groupTemplates', [
          ...groupTemplates.filter((t) => t.groupId !== template.groupId),
          template,
        ]);
      }
    },
    [setValue, groupTemplates]
  );

  const newGroupName = useContent(ContentId.NewGroup);

  const onAddNewGroupTemplate = useCallback(() => {
    groupTemplates.push({
      groupId: newGuidId(),
      groupName: `${newGroupName} ${groupTemplates.length + 1}`,
      desiredActiveListings,
      deprioritizedQuantities,
      seatings: [],
    });

    setValue('templateSettings.groupTemplates', [...groupTemplates]);
  }, [
    deprioritizedQuantities,
    desiredActiveListings,
    groupTemplates,
    newGroupName,
    setValue,
  ]);

  const groupTemplateData = useMemo(
    () => groupTemplates.sort((g1, g2) => g1.groupId.localeCompare(g2.groupId)),
    [groupTemplates]
  );

  return (
    <Stack
      direction="column"
      gap="xl"
      height="full"
      className={styles.groupingTablesVirtuosoContainer}
    >
      <Virtuoso
        data={groupTemplateData}
        totalCount={groupTemplateData.length}
        components={{
          Header: () => (
            <Stack direction="column" gap="xl" width="full">
              <SeatingSelectionTable uniqueSeatings={uniqueSeatings} />
            </Stack>
          ),
          Footer: () => (
            <>
              {!groupTemplateData.length && (
                <EmptySectionContent.Root icon={<IconBulletList />}>
                  <Content id={ContentId.NoGroupingAvailableForDefault} />
                </EmptySectionContent.Root>
              )}
              <div className={styles.groupToolbar}>
                <Button onClick={onAddNewGroupTemplate} variant="text">
                  + <Content id={ContentId.AddGroup} />
                </Button>
              </div>
            </>
          ),
        }}
        itemContent={(index, grt) => (
          <GroupTemplateTable
            groupBy={groupBy}
            key={grt.groupId}
            template={grt}
            onTemplateChange={onGroupTemplateChange}
            allowDelete={groupTemplates.length > 1}
            style={{ marginTop: vars.spacing['xl'] }}
          />
        )}
      />
    </Stack>
  );
};
