import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeMultiSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { Content } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SellerRoleInfo, SellerRoleInput } from 'src/WebApiController';

import * as styles from '../SellerRoleModal.css';
import { SellerUserDisplay } from './SellerUserDisplay';

export const SellerRoleUsersSection = ({
  disabled,
}: {
  roleInfo?: SellerRoleInfo | null;
  disabled?: boolean;
}) => {
  const { watch, setValue } = useFormContext<SellerRoleInput>();
  const userIds = watch('userIds');

  const { allActiveUserInfos } = useSellerAccountContext();
  const usersOfRole = useMemo(
    () =>
      Object.values(allActiveUserInfos ?? {}).filter((u) =>
        userIds?.includes(u.userId)
      ),
    [allActiveUserInfos, userIds]
  );

  const onUserIdsChange = useCallback(
    (newUserIds: string[]) => {
      if (!isEqual(userIds?.toSorted(), newUserIds.toSorted()))
        setValue('userIds', newUserIds);
    },
    [setValue, userIds]
  );

  return (
    <Stack direction="column" width="full" gap="xl">
      <PosFormField label={<Content id={ContentId.AddUserToRole} />}>
        <SellerAccountEmployeeMultiSelector
          triggerProps={{ style: { width: '100%' } }}
          disabled={disabled}
          showEmail
          values={userIds ?? []}
          onChange={onUserIdsChange}
          placeholderText={ContentId.SelectUsersAndGroups}
        />
      </PosFormField>
      <span className={styles.label}>
        <Content id={ContentId.UsersWithAccess} />
      </span>
      <Stack direction="column" width="full" gap="s">
        {usersOfRole.map((u) => (
          <SellerUserDisplay
            key={u.userId}
            user={u}
            userIds={userIds}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
};
