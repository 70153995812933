import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  CatalogClient,
  CatalogResults,
  Sale,
  SaleClient,
} from 'src/WebApiController';

export function useGetSaleByAmbiguousIdWithCatalogData(id?: string | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: ['useGetSaleByAmbiguousIdWithCatalogData', id],
    async queryFn() {
      if (!id) return null;

      let sale: Sale | null = null;
      let catalog: CatalogResults | null = null;
      const saleId = Number(id);
      if (!isNaN(saleId)) {
        try {
          sale = await new SaleClient(
            activeAccountWebClientConfig
          ).getSaleBySaleId(saleId);
        } catch (err: unknown) {
          trackError('SaleClient.getSaleBySaleId', err as ErrorTypes, {
            saleId,
          });
          throw err;
        }
      }

      if (sale == null) {
        try {
          const sales = await new SaleClient(
            activeAccountWebClientConfig
          ).getSalesByMarketplaceSaleId(id);

          // there is rare chance there are multiple POS sale returned on the same marketplace sale id
          // return the first one as default for now
          if (sales != null && sales.length > 0) {
            sale = sales[0];
          }
        } catch (err: unknown) {
          trackError('SaleClient.getSaleBySaleId', err as ErrorTypes, {
            saleId,
          });
          throw err;
        }
      }

      if (sale == null) {
        return { sale, catalog };
      }

      try {
        catalog = await new CatalogClient(
          activeAccountWebClientConfig
        ).getCatalogForSaleBySaleId(sale.id);
      } catch (err: unknown) {
        trackError(
          'CatalogClient.getCatalogForSaleBySaleId',
          err as ErrorTypes,
          {
            saleId,
          }
        );
        throw err;
      }
      return { sale, catalog };
    },
    refetchOnWindowFocus: false,
  });
}
