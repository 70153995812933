import { Virtualizer } from '@tanstack/react-virtual';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { CellsSelection } from './useSpreadsheetSelection';

/**
 * Represents the position and dimensions of a selection box.
 *
 * @typedef {Object} SelectionPos
 * @property {number} top - The top position in pixels.
 * @property {number} left - The left position in pixels.
 * @property {number} width - The width of the selection box in pixels.
 * @property {number} height - The height of the selection box in pixels.
 */
export type SelectionPos = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const useSelectionPos = (
  scrollContainerRef: RefObject<HTMLElement>,
  selection: CellsSelection | null,
  virtualizer: Virtualizer<HTMLDivElement, Element>,
  rowHeight: number
): SelectionPos | null => {
  const [selectionPos, setSelectionPos] = useState<SelectionPos | null>(null);
  const prevFirstRect = useRef<DOMRect | null>(null);
  const prevLastRect = useRef<DOMRect | null>(null);

  const updatePosition = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!selection || !container) {
      setSelectionPos(null);
      return;
    }
    const minRow = Math.min(selection.start.rowIndex, selection.end.rowIndex);
    const maxRow = Math.max(selection.start.rowIndex, selection.end.rowIndex);
    const minCol = Math.min(selection.start.colIndex, selection.end.colIndex);
    const maxCol = Math.max(selection.start.colIndex, selection.end.colIndex);

    const renderedRange = virtualizer.calculateRange();

    let firstCell = container.querySelector(
      `[data-row="${minRow}"][data-col="${minCol}"]`
    ) as HTMLElement | null;
    let lastCell = container.querySelector(
      `[data-row="${maxRow}"][data-col="${maxCol}"]`
    ) as HTMLElement | null;

    if (
      firstCell &&
      renderedRange?.startIndex &&
      (minRow < renderedRange?.startIndex || minRow > renderedRange?.endIndex)
    ) {
      firstCell = null;
    }
    const containerRect = container.getBoundingClientRect();

    if (
      lastCell &&
      renderedRange?.endIndex &&
      (maxRow < renderedRange?.startIndex || maxRow > renderedRange?.endIndex)
    ) {
      lastCell = null;
    }

    const scrollTop = container.scrollTop;
    const scrollLeft = container.scrollLeft;

    if (!firstCell && !lastCell) {
      setSelectionPos(null);
      return;
    }

    // If the first cell is not rendered, use the previous rect to calculate the position
    let firstRect: DOMRect;
    if (firstCell) {
      firstRect = firstCell.getBoundingClientRect();
      prevFirstRect.current = firstRect;
    } else if (prevFirstRect.current) {
      firstRect = {
        ...prevFirstRect.current,
        left: prevFirstRect.current.left,
        right: prevFirstRect.current.right,
        bottom: prevFirstRect.current.bottom,
        top: containerRect.top + minRow * rowHeight - scrollTop,
      };
    } else {
      firstRect = containerRect;
    }

    let lastRect: DOMRect;
    if (lastCell) {
      lastRect = lastCell.getBoundingClientRect();
      prevLastRect.current = lastRect;
    } else if (prevLastRect.current) {
      lastRect = {
        ...prevLastRect.current,
        left: prevLastRect.current.left,
        right: prevLastRect.current.right,
        bottom: containerRect.bottom + maxRow * rowHeight - scrollTop,
        top: prevLastRect.current.top,
      };
    } else {
      lastRect = containerRect;
    }

    const top = firstRect.top - containerRect.top + scrollTop;
    const left = firstRect.left - containerRect.left + scrollLeft;
    const width = lastRect.right - firstRect.left;
    const height = lastRect.bottom - firstRect.top;
    setSelectionPos({ top, left, width, height });
  }, [scrollContainerRef, selection, virtualizer, rowHeight]);

  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const handleScroll = () => {
      requestAnimationFrame(() => {
        updatePosition();
      });
    };

    container.addEventListener('scroll', handleScroll, { passive: true });
    return () => container.removeEventListener('scroll', handleScroll);
  }, [scrollContainerRef, updatePosition]);

  return selectionPos;
};
