import { head, uniq } from 'lodash-es';
import { useCallback } from 'react';
import { SectionInfo, VenueMapInfo } from 'src/WebApiController';

export const useEventVenueMapSectionHandler = (
  selectedSectionIds?: number[],
  setSelectedSectionIds?: (selectedSectionIds: number[]) => void,
  venueMapInfo?: VenueMapInfo | null
) => {
  const onSectionIdsIncludedOrExcluded = useCallback(
    (
      updatedSectionIds: number[] | null | undefined,
      excludeUpdatedSectionIds: boolean | undefined
    ) => {
      const newSelectedSectionIds = excludeUpdatedSectionIds
        ? selectedSectionIds?.filter(
            (id) => !updatedSectionIds?.includes(id)
          ) ?? []
        : uniq([...(selectedSectionIds ?? []), ...(updatedSectionIds ?? [])]);
      setSelectedSectionIds?.(newSelectedSectionIds);
    },
    [selectedSectionIds, setSelectedSectionIds]
  );

  const onShiftSelect = useCallback(
    (sectionInfo: SectionInfo) => {
      // We are using the ticketClassId to group the sections
      const selectedClassId = head(
        sectionInfo?.rows
          ?.map(({ tktClass: ticketClass }) => ticketClass?.id)
          .filter((id) => !!id)
      );
      const updatedSectionIds = venueMapInfo?.sections
        ?.filter(
          ({ rows }: SectionInfo) =>
            rows?.some(
              ({ tktClass: ticketClass }) => ticketClass?.id === selectedClassId
            )
        )
        ?.map((s: SectionInfo) => s.id);

      onSectionIdsIncludedOrExcluded(
        updatedSectionIds,
        Boolean(selectedSectionIds?.includes(sectionInfo.id))
      );
    },
    [onSectionIdsIncludedOrExcluded, selectedSectionIds, venueMapInfo?.sections]
  );

  const onSingleSelect = useCallback(
    (sectionInfo: SectionInfo) =>
      onSectionIdsIncludedOrExcluded(
        [sectionInfo.id],
        Boolean(selectedSectionIds?.includes(sectionInfo.id))
      ),
    [onSectionIdsIncludedOrExcluded, selectedSectionIds]
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, sectionInfo: SectionInfo) => {
      if (e.shiftKey) {
        onShiftSelect(sectionInfo);
        return;
      }

      onSingleSelect(sectionInfo);
    },
    [onShiftSelect, onSingleSelect]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      onSectionIdsIncludedOrExcluded(
        mirrors.map((s) => s.id),
        exclude
      );
    },
    [onSectionIdsIncludedOrExcluded]
  );

  const setSelectedSections = useCallback(
    (sections: SectionInfo[]) => {
      setSelectedSectionIds?.(sections.map(({ id }) => id));
    },
    [setSelectedSectionIds]
  );

  return {
    onSectionClicked,
    onToggleMirrors,
    setSelectedSections,
  };
};
