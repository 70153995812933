/**
 * Used as an enum and the path associated with that enum
 *
 * N.B. Changes here should also reflect in `contentIdMaps.ts` and `AppRoutes.tsx`
 *
 * Planned based on the mocks: (contentIds already generated for these)
 * - Vendors
 * - Automations
 * - Pricing
 * - Venues
 * - Tickets
 */
export const enum SettingTab {
  MyAccount = 'my-account',
  UserManagement = 'user-management',
  UserManagement_Users = 'users',
  UserManagement_Roles = 'roles',
  UserManagement_Teams = 'teams',
  SyncCenter = 'sync-center',
  CurrencyConversion = 'currency-conversion',
  PurchaseOrder = 'purchase-order',
  Accounting = 'accounting',
  SellerAccount = 'seller-account', // legacy: will be split out into separate views
  VendorManagement = 'vendor-management',
  VendorManagement_Vendors = 'vendors',
  VendorManagement_Payments = 'payments',
  FulfillmentProviders = 'fulfillment-providers',
  AutoPricing = 'auto-pricing',
}

export const SettingsTree: Record<string, string> = {
  [SettingTab.MyAccount]: '',
  [SettingTab.UserManagement]: '',
  [SettingTab.SyncCenter]: '',
  [SettingTab.AutoPricing]: '',
  [SettingTab.PurchaseOrder]: '',
  [SettingTab.Accounting]: '',
  [SettingTab.SellerAccount]: '',
  [SettingTab.CurrencyConversion]: '',
  [SettingTab.UserManagement_Users]: SettingTab.UserManagement + '/',
  [SettingTab.UserManagement_Roles]: SettingTab.UserManagement + '/',
  [SettingTab.UserManagement_Teams]: SettingTab.UserManagement + '/',
  [SettingTab.VendorManagement]: '',
  [SettingTab.VendorManagement_Vendors]: SettingTab.VendorManagement + '/',
  [SettingTab.VendorManagement_Payments]: SettingTab.VendorManagement + '/',
  [SettingTab.FulfillmentProviders]: '',
};
