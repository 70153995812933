export enum PurchasesTableColumnId {
  Checkbox = 'checkbox',
  AccordianToggle = 'accordianToggle',
  Id = 'id',
  BuyerUserId = 'buyerUserId',
  VendorOrderId = 'vendorOrderId',
  PurchaseDate = 'purchaseDate',
  Cost = 'cost',
  Payment = 'payment',
  PaymentStatus = 'paymentStatus',
  QualityControlState = 'qualityControlState',
  Orders = 'orders',
  VendorAccountEmail = 'vendorAccountEmail',
  CreatedBy = 'createdBy',
  // Columns usable by single ticket group
  Event = 'event',
  Venue = 'venue',
  EventDate = 'eventDate',
  Seating = 'seating',
  Row = 'row',
  Seats = 'seats',
  SeatTraits = 'seatTraits',
  Tickets = 'tickets',
  Shipment = 'shipment',
  TotalCost = 'totalCost',
  UnitCost = 'unitCost',
  Actions = 'actions',
  TotalPayment = 'totalPayment',
  TotalConvertedPayment = 'totalConvertedPayment',
  PaymentConversionRate = 'paymentConversionRate',
  Commissions = 'commissions',
}

export enum PurchaseTableColumnGroupId {
  TicketGroup = 'ticketGroup',
}
