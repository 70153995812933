import {
  EventAccordionItemBodyComponentType,
  MinRowsForUsingVirtuoso,
} from 'src/components/Accordions';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { SalesTable } from 'src/tables/SalesTable';
import { SaleTiles } from 'src/tiles/SaleTiles';
import { Sale } from 'src/WebApiController';

type EventSaleBodyProps = EventAccordionItemBodyComponentType<Sale>;

export const EventSaleBody = (props: EventSaleBodyProps) => {
  const isMobile = useMatchMedia('mobile');
  const Body = isMobile ? SaleTiles : SalesTable;
  const usingVirtuoso = props.entityCount > MinRowsForUsingVirtuoso;

  return <Body {...props} useVirtuoso={usingVirtuoso} enableColumnFilters />;
};
