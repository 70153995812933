import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';

import * as styles from './PurchaseTicketGroupPage.css';
export const PurchaseTicketGroupPageAdditionalInput = () => {
  const { setValue, getValues } = useFormContext<PurchaseTicketsInput>();
  const ticketGroups = getValues('ticketGroups');
  const [isChecked, setIsChecked] = useState(
    ticketGroups?.every((tg) => !tg.taxPaid || tg.taxPaid?.value === 0)
  );

  return (
    <div className={styles.TaxCheckbox}>
      <Checkbox
        labelPosition="right"
        label={<Content id={ContentId.ThisWasTaxExempt} />}
        checked={isChecked}
        onChange={(e) => {
          const isChecked = e.target.checked;

          ticketGroups.forEach((_, rowIndex) =>
            setValue(
              `ticketGroups.${rowIndex}.taxPaid`,
              posChangedField(isChecked ? 0 : 1)
            )
          );
          setIsChecked(isChecked);
        }}
      />
    </div>
  );
};
