import { isEqual } from 'lodash';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { CityIcon } from 'src/svgs/CityIcon';
import { CountryIcon } from 'src/svgs/CountryIcon';
import { DayIcon } from 'src/svgs/DayIcon';
import { EventIcon } from 'src/svgs/EventIcon';
import { MonthIcon } from 'src/svgs/MonthIcon';
import { PerformerIcon } from 'src/svgs/PerformerIcon';
import { SaleIcon } from 'src/svgs/SaleIcon';
import { VenueIcon } from 'src/svgs/VenueIcon';
import {
  PaperTicketsOutlineIcon,
  ResellAnytimeIcon,
  SeatIcon,
  SellerTraderOutlineIcon,
  SellOutlineIcon,
} from 'src/svgs/Viagogo';
import { WeekIcon } from 'src/svgs/WeekIcon';
import {
  EntityWithTicketsQuery,
  Event,
  ListingQuery,
  ListingReportMetricColumn,
  ListingReportMetrics,
  Performer,
  ReportGroupBy,
  SaleQuery,
  SaleReportMetrics,
  Venue,
} from 'src/WebApiController';

import { ListingReportTableColumnId } from './columns/inventory/inventoryColumnUtils.types';

export enum ReportTypes {
  Inventory = 'listing',
  Sale = 'sale',
}

export type ArTimeBucketWithData = Pick<
  SaleReportMetrics,
  'timeBkt' | 'ttlOutsBal'
>;

export enum ReportTypesV2 {
  InventoryV2 = 'listingV2',
  SaleV2 = 'saleV2',
  Commission = 'commission',
}

export type SaleReportMetricsWithGroupBy = SaleReportMetrics & {
  performer?: Performer;
  venue?: Venue;
  event?: Event;
  timeBucketsWithData?: ArTimeBucketWithData[];
};

export type ListingReportMetricsWithGroupBy = ListingReportMetrics & {
  performer?: Performer;
  venue?: Venue;
  event?: Event;
};

export const GetIconByGroupByType = (groupByType: ReportGroupBy) => {
  const { isDarkMode } = useSiteTheme();

  switch (groupByType) {
    case ReportGroupBy.Performer:
      return (
        <PerformerIcon
          fill={isDarkMode ? vars.color.textBrand : 'transparent'}
        />
      );
    case ReportGroupBy.Event:
    case ReportGroupBy.EventGenre:
      return (
        <EventIcon fill={isDarkMode ? vars.color.textBrand : 'transparent'} />
      );
    case ReportGroupBy.EventCity:
      return (
        <CityIcon fill={isDarkMode ? vars.color.textBrand : 'transparent'} />
      );
    case ReportGroupBy.EventCountry:
      return (
        <CountryIcon fill={isDarkMode ? vars.color.textBrand : 'transparent'} />
      );
    case ReportGroupBy.SeatingRow:
    case ReportGroupBy.SeatingSection:
      return <SeatIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.SaleDay:
      return <DayIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.SaleWeek:
      return <WeekIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.SaleMonth:
      return <MonthIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.SaleId:
      return <SaleIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.ListingId:
      return <PaperTicketsOutlineIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.PurchaseId:
      return <SellOutlineIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.SaleMarketplace:
    case ReportGroupBy.Vendor:
      return <ResellAnytimeIcon fill={vars.color.textBrand} />;
    case ReportGroupBy.PurchasedBy:
    case ReportGroupBy.PricedBy:
    case ReportGroupBy.FulfilledBy:
      return <SellerTraderOutlineIcon fill={vars.color.textBrand} />;
    default:
      return (
        <VenueIcon fill={isDarkMode ? vars.color.textBrand : 'transparent'} />
      );
  }
};

export const listingMetricsToReportMetricRequest = (metric: string) => {
  if (metric === ListingReportTableColumnId.Zone.toString()) {
    return ListingReportMetricColumn.Zone;
  }

  // TODO complete this on https://thestubhub.atlassian.net/browse/POS-3290
  return undefined;
};

export const isReportFilterOverriden = (
  filterQuery: EntityWithTicketsQuery,
  filter: ListingQuery | SaleQuery | null | undefined
) => {
  const filterQueryToCompare = {
    ...filterQuery,
    eventIds: null,
    eventOrMappingIds: null,
    performerIds: null,
    venueIds: null,
    isSortDescending: null,
    sortBy: null,
    searchText: null,
    viewMode: null,
  };
  const reportConfigFilterToCompare = {
    ...filter,
    eventIds: null,
    eventOrMappingIds: null,
    performerIds: null,
    venueIds: null,
    isSortDescending: null,
    searchText: null,
    sortBy: null,
    viewMode: null,
  };

  return !isEqual(filterQueryToCompare, reportConfigFilterToCompare);
};
