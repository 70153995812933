import { ReactNode, useEffect } from 'react';
import {
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { Stack } from 'src/core/ui';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import { PurchaseOrderTableProps } from 'src/tables/PurchaseTable/PurchaseTable.types';
import {
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

type PurchaseFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
} & Pick<
  PurchaseOrderTableProps,
  | 'alwaysShowCheckbox'
  | 'showSelectAllOnHeader'
  | 'enableColumnFilters'
  | 'ignoreMaxCount'
>;

export const PurchaseFlattenedView = ({
  before,
  topListItemBefore,
  ...rest
}: PurchaseFlattenedViewProps) => {
  const { allPurchasesQuery, allPurchasesFiltered, purchaseCount } =
    usePurchaseDataContext();
  const { setAllGroupsIds } = useMultiSelectionContext();

  useEffect(() => {
    setAllGroupsIds([NO_GROUP_ID]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="column" height="full" width="full">
      {before}
      {topListItemBefore}
      <PurchaseOrderTable
        {...rest}
        groupId={NO_GROUP_ID}
        useVirtuoso
        disablePagination
        entityCount={purchaseCount}
        entities={(allPurchasesFiltered ?? []).map(
          (p) => p as PurchaseOrderTicketGroup
        )}
        getDataFail={Boolean(allPurchasesQuery.error)}
        isItemsLoading={allPurchasesQuery.isLoading}
        viewMode={PurchaseViewMode.FlattenedView}
      />
    </Stack>
  );
};
