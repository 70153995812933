import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PricingClient } from 'src/WebApiController';

export const usePricingConfigFromEventIds = (
  eventIds: number[],
  disable: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disable &&
    activeAccountWebClientConfig.activeAccountId != null &&
    eventIds.length > 0;

  return useQuery({
    queryKey: [
      'getPricingConfigFromEventIds',
      activeAccountWebClientConfig.activeAccountId,
      eventIds.sort(),
      disable,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new PricingClient(
        activeAccountWebClientConfig
      ).getPricingConfigFromEventIds(eventIds);
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getPricingConfigFromEventIds', error, {
          eventIds,
        });
      },
    },
  });
};
