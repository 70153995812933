import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { ConfirmButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Stack, Switch } from 'src/core/ui';
import { useGetListingMergeSuggestions } from 'src/hooks/api/useGetListingMergeSuggestions';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_SORT_TO_CID } from 'src/utils/constants/contentIdMaps';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { getEventSort, getEventUiSort } from 'src/utils/eventQueryUtils';
import {
  sortEventsByDate,
  sortEventsByName,
} from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkMergeListingsInput,
  EventSort,
  Feature,
  ListingClient,
  ListingMetrics,
} from 'src/WebApiController';

import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import {
  EventMergeSuggestionsAccordionProps,
  UIEventWithDataAndMergeSuggestions,
} from './BulkMergeListings/BulkMergeListings.types';
import { isEqualIgnoringOrder } from './BulkMergeListings/BulkMergeListings.utils';
import { EventMergeSuggestionsAccordion } from './BulkMergeListings/EventMergeSuggestionsAccordion';
import * as styles from './MergeListings.css';
import { MainLabel } from './MergeListings.styled';
import { useMergeGroupedListingAlertDialog } from './useMergeGroupedListingsDialog';

export const BulkMergeListingsModal = () => {
  const methods = useForm<BulkMergeListingsInput>({
    defaultValues: {
      listingIdLists: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <BulkMergeListingsModalContent {...methods} />
    </FormProvider>
  );
};

const BulkMergeListingsModalContent = ({
  formState,
  handleSubmit,
  watch,
  setValue,
  getValues,
}: Omit<
  ComponentProps<typeof FormProvider<BulkMergeListingsInput, unknown>>,
  'children'
>) => {
  const { closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();
  const isMobile = useMatchMedia('mobile');

  const hasDefaultNotToMergeHoldListingsFeature = useUserHasFeature(
    Feature.DefaultNotToMergeHoldListings
  );

  const [excludeHeldListings, setExcludeHeldListings] = useState<boolean>(
    hasDefaultNotToMergeHoldListingsFeature
  );
  const { mergeSuggestions, isLoading: mergeSuggestionsIsLoading } =
    useGetListingMergeSuggestions(excludeHeldListings);

  const selectedText = useContent(ContentId.Selected);
  const selectAllText = useContent(ContentId.SelectAll);
  const deselectAllText = useContent(ContentId.DeselectAll);

  const [isLoading, setIsLoading] = useState(false);
  const { isDirty, isSubmitting } = formState;
  const [eventSortQuery, setEventSortQuery] = useState<{
    sortBy: EventSort | null;
    isSortDescending: boolean | null;
  }>({ sortBy: null, isSortDescending: false });

  const listingIdLists = watch('listingIdLists');

  const eventsWithMergeSuggestions: UIEventWithDataAndMergeSuggestions[] =
    useMemo(() => {
      if (!mergeSuggestions || !eventsTransformed) return [];

      const viagogoEventIdsWithMergeSuggestions = mergeSuggestions.map(
        (m) => m.eventId
      );

      return eventsTransformed
        .filter((ev) =>
          viagogoEventIdsWithMergeSuggestions.includes(ev.event.viagId)
        )
        .map((ev) => ({
          ...ev,
          mergeSuggestions: mergeSuggestions!.filter(
            (m) => ev.event.viagId === m.eventId
          ),
        }));
    }, [eventsTransformed, mergeSuggestions]);

  const mergeListings = useCallback(
    async (
      formData: BulkMergeListingsInput,
      closeModalAfterMerge?: boolean
    ) => {
      // If this is called, there are no form errors (else onSubmit is never called)
      setIsLoading(true);

      return await tryInvokeApi(
        async () => {
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).bulkMergeListings(formData);

          if (result) {
            await refreshExpandedListItems();
            refreshMetrics?.();
          }

          if (closeModalAfterMerge) {
            closeModal(true);
          }
        },
        (error) => {
          showErrorDialog('ListingClient.bulkMergeListings', error, {
            trackErrorData: formData,
          });
        },
        () => setIsLoading(false)
      );
    },
    [
      activeAccountWebClientConfig,
      closeModal,
      refreshExpandedListItems,
      refreshMetrics,
      showErrorDialog,
    ]
  );

  const {
    setAlerts,
    warningDialog,
    mergeGroupedListingHookProps,
    onSubmit,
    AlertDialog,
  } = useMergeGroupedListingAlertDialog(handleSubmit, mergeListings);

  const onSubmitHandler = useCallback(() => {
    const formData = getValues();
    const selectedListingIds = formData.listingIdLists.flatMap((l) => l);

    const listingIdsSet = new Set<number>();
    eventsTransformed?.forEach((event) => {
      event.entities.listings?.forEach((listing) => {
        listingIdsSet.add(listing.id);
      });
    });

    const hasListingFromGroup = selectedListingIds.some(
      (formDataListingId) => !listingIdsSet.has(formDataListingId)
    );

    if (
      hasListingFromGroup &&
      isDatePassedHours(mergeGroupedListingHookProps.lastTimeStamp, 1)
    ) {
      setAlerts([mergeGroupedListingHookProps]);
      warningDialog.launchDialog();
    } else {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    eventsTransformed,
    mergeGroupedListingHookProps,
    setAlerts,
    warningDialog,
    handleSubmit,
    onSubmit,
  ]);

  const allListingIds = useMemo(() => {
    return eventsWithMergeSuggestions.reduce<number[][]>(
      (acc, e) => acc.concat(e.mergeSuggestions.map((m) => m.listingIds)),
      []
    );
  }, [eventsWithMergeSuggestions]);

  const isAllSelected = useMemo(() => {
    const mergeSuggestionCount = eventsWithMergeSuggestions.reduce(
      (acc, e) => acc + e.mergeSuggestions.length,
      0
    );

    return listingIdLists.length === mergeSuggestionCount;
  }, [eventsWithMergeSuggestions, listingIdLists.length]);

  const toggleSelectAll = useCallback(() => {
    if (isAllSelected) {
      setValue('listingIdLists', []);
    } else {
      setValue('listingIdLists', allListingIds);
    }
  }, [allListingIds, isAllSelected, setValue]);

  const onSingleSuggestionSelectedChange = useCallback(
    (selected: boolean, listingIds: number[]) => {
      const isSelected = listingIdLists.some((l) =>
        isEqualIgnoringOrder(l, listingIds)
      );
      if (selected) {
        if (!isSelected) {
          setValue('listingIdLists', [...listingIdLists, listingIds]);
        }
      } else {
        if (isSelected) {
          setValue(
            'listingIdLists',
            listingIdLists.filter((l) => !isEqualIgnoringOrder(l, listingIds))
          );
        }
      }
    },
    [listingIdLists, setValue]
  );

  const onEventSuggestionsSelectedChange = useCallback(
    (
      selected: boolean,
      eventWithMergeSuggestion: UIEventWithDataAndMergeSuggestions
    ) => {
      const listingIds = eventWithMergeSuggestion.mergeSuggestions.map(
        (m) => m.listingIds
      );
      if (selected) {
        setValue(
          'listingIdLists',
          listingIdLists.concat(
            listingIds.filter(
              (l) => !listingIdLists.some((s) => isEqualIgnoringOrder(l, s))
            )
          )
        );
      } else {
        setValue(
          'listingIdLists',
          listingIdLists.filter(
            (s) => !listingIds.some((l) => isEqualIgnoringOrder(l, s))
          )
        );
      }
    },
    [listingIdLists, setValue]
  );

  const isEventPartialSelected = useCallback(
    (eventWithMergeSuggestion: UIEventWithDataAndMergeSuggestions) => {
      const listingIds = eventWithMergeSuggestion.mergeSuggestions.map(
        (m) => m.listingIds
      );
      return (
        listingIds.some((l) =>
          listingIdLists.some((s) => isEqualIgnoringOrder(l, s))
        ) &&
        !listingIds.every((l) =>
          listingIdLists.some((s) => isEqualIgnoringOrder(l, s))
        )
      );
    },
    [listingIdLists]
  );

  const isEventSelected = useCallback(
    (eventWithMergeSuggestion: UIEventWithDataAndMergeSuggestions) => {
      const listingIds = eventWithMergeSuggestion.mergeSuggestions.map(
        (m) => m.listingIds
      );
      return listingIds.some((l) =>
        listingIdLists.some((s) => isEqualIgnoringOrder(l, s))
      );
    },
    [listingIdLists]
  );

  const eventAccordionProps: EventMergeSuggestionsAccordionProps[] =
    useMemo(() => {
      if (
        !eventsWithMergeSuggestions ||
        eventsWithMergeSuggestions.length === 0
      )
        return [];

      const result = [] as EventMergeSuggestionsAccordionProps[];
      if (eventSortQuery.sortBy === EventSort.MainDisplay) {
        sortEventsByName(
          eventsWithMergeSuggestions,
          eventSortQuery.isSortDescending
        );
      } else {
        sortEventsByDate(
          eventsWithMergeSuggestions,
          eventSortQuery.isSortDescending
        );
      }

      eventsWithMergeSuggestions.forEach((eventWithMergeSuggestion) => {
        result.push({
          eventWithMergeSuggestion,
          onSuggestionsSelectedChange: onSingleSuggestionSelectedChange,
          selectedListingIds: listingIdLists,
          selected: isEventSelected(eventWithMergeSuggestion),
          partialSelected: isEventPartialSelected(eventWithMergeSuggestion),
          onSelectedChange: (selected: boolean) =>
            onEventSuggestionsSelectedChange(
              selected,
              eventWithMergeSuggestion
            ),
        });
      });
      return result;
    }, [
      eventsWithMergeSuggestions,
      eventSortQuery.sortBy,
      eventSortQuery.isSortDescending,
      onSingleSuggestionSelectedChange,
      listingIdLists,
      isEventSelected,
      isEventPartialSelected,
      onEventSuggestionsSelectedChange,
    ]);

  const hasSuggestions = eventAccordionProps.length > 0;

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || isSubmitting}
        showDialogOnCancel={isDirty}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.MergeListings} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        <ModalBodyDataContainer>
          {isLoading || mergeSuggestionsIsLoading ? (
            <PosSpinner />
          ) : hasSuggestions ? (
            <Stack direction="column" gap="l" height="full">
              <MainLabel>
                <Content id={ContentId.AllAvailableMerges} />
                {hasDefaultNotToMergeHoldListingsFeature && (
                  <Stack
                    direction="row"
                    gap="s"
                    alignItems="center"
                    style={{
                      fontSize: vars.typography.fontSize.base,
                      fontWeight: 500,
                    }}
                  >
                    <Switch
                      checked={!excludeHeldListings}
                      onChange={(e) => e.stopPropagation()}
                      onCheckedChange={(isChecked) => {
                        if (isChecked === excludeHeldListings) {
                          setExcludeHeldListings(!isChecked);
                        }
                        setValue('listingIdLists', []);
                      }}
                    />
                    <Content id={ContentId.IncludeHeldListings} />
                  </Stack>
                )}
              </MainLabel>
              <div className={styles.buttonsContainer}>
                <Button
                  onClick={toggleSelectAll}
                  variant="link"
                  style={{ padding: '0' }}
                >
                  <span>{`${isAllSelected ? deselectAllText : selectAllText} (${
                    listingIdLists.length
                  } ${selectedText})`}</span>
                </Button>
                <PosEnumSelect
                  variant="outline"
                  shape="pill"
                  value={
                    !isMobile
                      ? getEventUiSort(
                          eventSortQuery.sortBy,
                          eventSortQuery.isSortDescending
                        )
                      : null
                  }
                  defaultValue={
                    isMobile ? null : getEventUiSort(EventSort.Date, true)
                  }
                  onChange={(sort) => {
                    const [sortBy, isSortDescending] = getEventSort(sort);
                    if (
                      sortBy !== eventSortQuery.sortBy ||
                      isSortDescending !== eventSortQuery.isSortDescending
                    ) {
                      setEventSortQuery({
                        ...eventSortQuery,
                        sortBy,
                        isSortDescending,
                      });
                    }
                  }}
                  valueOptionsContent={EVENT_SORT_TO_CID}
                />
              </div>
              <Virtuoso
                style={{ width: '100%' }}
                data={eventAccordionProps}
                overscan={window.innerHeight}
                itemContent={(i, props) => (
                  <EventMergeSuggestionsAccordion
                    key={`eventWithMergeSuggestion-${i}`}
                    {...props}
                  />
                )}
              />
            </Stack>
          ) : (
            <Content id={ContentId.NoMergesAvailable} />
          )}
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading || isSubmitting}
          showDialogOnCancel={isDirty}
        >
          <ConfirmButton
            onClick={onSubmitHandler}
            disabled={isLoading || isSubmitting || !listingIdLists?.length}
            textContentId={ContentId.MergeSelected}
          />
        </CancellableFormFooter>
      </ModalFooter>
      {AlertDialog}
    </>
  );
};
