import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { once } from 'lodash-es';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import {
  ReportMetricsSummaryV2,
  ReportMetricsV2,
  ReportValueType,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useFilterQueryContext } from '../FilterQueryContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReportMetricsResponse = { [key: string]: any };

export type ReportValueTypesReponse = { [key: string]: ReportValueType };

export type IReportMetricsContext = {
  isLoading: boolean;
  reportMetrics?: ReportMetricsResponse[];
  reportSummary?: ReportMetricsResponse;
  reportValueTypes?: ReportValueTypesReponse;
  refreshMetrics: () => void;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isReportConfigEmpty?: boolean;
};

export const createReportMetricsContextV2 = once(() =>
  React.createContext({} as IReportMetricsContext)
);

export function useReportMetricsContextV2() {
  return useContext(createReportMetricsContextV2());
}

export function ReportMetricsContextProviderV2<
  TQuery extends QueryWithViewMode,
>({
  reportConfig,
  queryKey,
  getReportMetrics,
  getReportMetricsSummary,
  children,
  disabled,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  queryKey: string;
  getReportMetrics?: (
    query: TQuery,
    pageNumber: number
  ) => Promise<ReportMetricsV2>;
  getReportMetricsSummary?: (query: TQuery) => Promise<ReportMetricsSummaryV2>;
  disabled?: boolean;
}>) {
  const ReportMetricsContext = createReportMetricsContextV2();

  const { trackError } = useErrorBoundaryContext();

  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();

  const isReportConfigEmpty = useMemo(() => {
    return (
      reportConfig.request.orderBy == null ||
      !reportConfig.request.aggregations?.length ||
      (!reportConfig.request.rowGroupings?.length &&
        !reportConfig.request.columnGroupings?.length)
    );
  }, [
    reportConfig.request.aggregations?.length,
    reportConfig.request.columnGroupings?.length,
    reportConfig.request.orderBy,
    reportConfig.request.rowGroupings?.length,
  ]);

  const shouldQuery =
    !disabled &&
    !(!getReportMetrics || !activeAccountWebClientConfig.activeAccountId) &&
    !isReportConfigEmpty;

  const reportMetricsQuery = useInfiniteQuery({
    queryKey: [
      `${queryKey}-report-metrics`,
      activeAccountWebClientConfig,
      filterQuery,
      disabled,
      reportConfig.request.aggregations,
      reportConfig.request.rowGroupings,
      reportConfig.request.columnGroupings,
    ],
    queryFn: async ({ pageParam: pageNumber = 0 }) => {
      if (!shouldQuery) {
        return null;
      }

      const reportMetricsData = await getReportMetrics!(
        filterQuery,
        pageNumber
      );

      return {
        reportMetricsData,
        pageNumber,
      };
    },
    getNextPageParam(lastPage) {
      return lastPage?.reportMetricsData.rows.length
        ? lastPage.pageNumber + 1
        : undefined;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    initialPageParam: 0,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('getReportMetrics', error, {
          ...filterQuery,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const reportMetricsSummaryQuery = useQuery({
    queryKey: [
      `${queryKey}-report-metrics-summary`,
      activeAccountWebClientConfig,
      filterQuery,
      disabled,
      reportConfig.request.aggregations,
      reportConfig.request.rowGroupings,
      reportConfig.request.columnGroupings,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const reportMetricsData = await getReportMetricsSummary!(filterQuery);

      return {
        reportMetricsData,
      };
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('getReportMetricsSummary', error, {
          ...filterQuery,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const refreshMetrics = useCallback(
    () => reportMetricsQuery.refetch(),
    [reportMetricsQuery]
  );

  const flattenedData = useMemo(
    () =>
      reportMetricsQuery.data?.pages?.flatMap(
        (m) => m?.reportMetricsData?.rows ?? []
      ),
    [reportMetricsQuery.data]
  );

  return (
    <ReportMetricsContext.Provider
      value={{
        isLoading: reportMetricsQuery.isLoading,
        reportMetrics: flattenedData,
        reportSummary:
          reportMetricsSummaryQuery.data?.reportMetricsData?.row ?? undefined,
        reportValueTypes:
          reportMetricsQuery.data?.pages?.[0]?.reportMetricsData?.valueTypes ??
          reportMetricsSummaryQuery?.data?.reportMetricsData?.valueTypes,
        refreshMetrics: refreshMetrics,
        fetchNextPage: reportMetricsQuery.fetchNextPage,
        hasNextPage: reportMetricsQuery.hasNextPage,
        isFetchingNextPage: reportMetricsQuery.isFetchingNextPage,
        isReportConfigEmpty,
      }}
    >
      {children}
    </ReportMetricsContext.Provider>
  );
}
