import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { createGetColor } from 'src/components/Listings/InventoryEventPage/utils/colorUtils';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useGetActiveVenueZoneMapSectionGroups } from 'src/contexts/EventMapContext/useGetActiveVenueZoneMapSectionGroups';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useComparableListingsVenueMapColor } from 'src/hooks/useComparableListingsVenueMapColor';
import { useEventVenueMapSectionHandler } from 'src/hooks/useEventVenueMapSectionHandler';
import { quantityFiltersFromJson } from 'src/utils/autoPricingUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  BulkEditListingClient,
  CompListing,
  Event,
  Listing,
  ListingDetailsAutoPricingSectionUpdatesV2,
  ListingPriceCalculationsInEventInput,
  NetProceedsFloorAdjustment,
  SelectedSectionSettings,
  UiMoney,
} from 'src/WebApiController';

export const useBulkGetListingPriceCalculationsInEvent = (
  event: Event,
  selectedListings: Listing[]
) => {
  const { activeAccountWebClientConfig, loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const uiCurrency = useMemo(
    () => getUiCurrency(loginContext?.user?.activeAccount?.currencyCode),
    [getUiCurrency, loginContext?.user?.activeAccount?.currencyCode]
  );

  const listingIds = useMemo(
    () => selectedListings.map((l) => l.id),
    [selectedListings]
  );

  const { watch } = useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();
  const formValues = watch();

  // TODO: The bulk edit form should just be ListingPriceCalculationsInEventInput directly
  const autoPricingSettingsUpdates: ListingPriceCalculationsInEventInput =
    useMemo(
      () => ({
        compListingSetting: {
          mode: formValues.compListingMode,
          selectedSectionSettings: formValues.deriveCompsFromVenueZoneConfig
            ? {
                value: {
                  sectionIdFilterFromZoneMap: true,
                  includeBetterZones: formValues.includeBetterZones,
                  rowOffset: formValues.rowCompOffset,
                } as SelectedSectionSettings,
                hasChanged: true,
              }
            : formValues.compListingSelectedSectionSettings,
          includeExistingSelectedSectionSettings: {
            value: formValues.includeExistingSelectedSections,
            hasChanged: formValues.includeExistingSelectedSections !== null,
          },
          onlyForSelectedSectionsEnabled:
            formValues.compListingOnlyForSelectedSectionsEnabled,
          quantityFilters:
            formValues.compListingQuantityScoreAdjustmentOverrideJson
              ? {
                  value: quantityFiltersFromJson(
                    formValues.compListingQuantityScoreAdjustmentOverrideJson
                      .value
                  ),
                  hasChanged:
                    formValues.compListingQuantityScoreAdjustmentOverrideJson
                      .hasChanged,
                }
              : null,
          excludeFanInventory: formValues.compListingExcludeFanInventory,
          excludeDefects: formValues.compListingExcludeDefects,
        },
        underCutSetting: {
          underCutAbsoluteAmount: formValues.undercutAbsoluteAmount
            ? {
                value: formValues.undercutAbsoluteAmount.value
                  ? ({
                      amt: formValues.undercutAbsoluteAmount.value,
                      currency: uiCurrency.code,
                    } as UiMoney)
                  : null,
                hasChanged: formValues.undercutAbsoluteAmount.hasChanged,
              }
            : null,
          underCutRelativeAmount: formValues.undercutRelativeAmount,
        },
        outlierSetting: {
          mode: formValues.outlierMode,
          standardDeviations: formValues.outlierStandardDeviations,
          outlierLimit: formValues.outlierKthLowestLimit,
          absoluteOutlierSpacing:
            formValues.outlierKthLowestLimitAbsoluteSpacing
              ? {
                  value: formValues.outlierKthLowestLimitAbsoluteSpacing.value
                    ? ({
                        amt: formValues.outlierKthLowestLimitAbsoluteSpacing
                          .value,
                        currency: uiCurrency.code,
                      } as UiMoney)
                    : null,
                  hasChanged:
                    formValues.outlierKthLowestLimitAbsoluteSpacing.hasChanged,
                }
              : null,
          relativeOutlierSpacing:
            formValues.outlierKthLowestLimitRelativeSpacing,
        },
        netProceedsFloor: formValues.netProceedsFloor?.hasChanged
          ? {
              value: {
                absoluteAmount: formValues.netProceedsFloor?.value
                  ? ({
                      amt: formValues.netProceedsFloor.value,
                      currency: uiCurrency.code,
                    } as UiMoney)
                  : null,
                relativeAdjustmentType: null,
                relativeAmount: null,
                relativePercentage: null,
              } as NetProceedsFloorAdjustment,
              hasChanged: true,
            }
          : formValues.netProceedsFloorAdjustmentAmount != null &&
            formValues.netProceedsFloorAdjustmentRelative != null &&
            !!formValues.netProceedsFloorAdjustmentType
          ? {
              value: {
                absoluteAmount: null,
                relativeAmount: !formValues.netProceedsFloorAdjustmentRelative
                  ? {
                      amt: formValues.netProceedsFloorAdjustmentAmount,
                      currency: uiCurrency.code,
                    }
                  : null,
                relativePercentage:
                  formValues.netProceedsFloorAdjustmentRelative
                    ? formValues.netProceedsFloorAdjustmentAmount
                    : null,
                relativeAdjustmentType:
                  formValues.netProceedsFloorAdjustmentType,
              } as NetProceedsFloorAdjustment,
              hasChanged: true,
            }
          : null,
        netProceedsCeiling: formValues.netProceedsCeiling
          ? {
              value: formValues.netProceedsCeiling.value
                ? ({
                    amt: formValues.netProceedsCeiling.value,
                    currency: uiCurrency.code,
                  } as UiMoney)
                : null,
              hasChanged: formValues.netProceedsCeiling.hasChanged,
            }
          : null,
      }),
      [
        formValues.compListingMode,
        formValues.compListingSelectedSectionSettings,
        formValues.deriveCompsFromVenueZoneConfig,
        formValues.includeBetterZones,
        formValues.includeExistingSelectedSections,
        formValues.compListingOnlyForSelectedSectionsEnabled,
        formValues.compListingQuantityScoreAdjustmentOverrideJson,
        formValues.compListingExcludeFanInventory,
        formValues.compListingExcludeDefects,
        formValues.undercutAbsoluteAmount,
        formValues.undercutRelativeAmount,
        formValues.outlierMode,
        formValues.outlierStandardDeviations,
        formValues.outlierKthLowestLimit,
        formValues.outlierKthLowestLimitAbsoluteSpacing,
        formValues.outlierKthLowestLimitRelativeSpacing,
        formValues.netProceedsFloor?.hasChanged,
        formValues.netProceedsFloor?.value,
        formValues.netProceedsFloorAdjustmentAmount,
        formValues.netProceedsFloorAdjustmentRelative,
        formValues.netProceedsFloorAdjustmentType,
        formValues.netProceedsCeiling,
        formValues.rowCompOffset,
        uiCurrency.code,
      ]
    );

  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId &&
      event.viagId &&
      listingIds &&
      autoPricingSettingsUpdates
  );
  const listingCalculationsQuery = useQuery({
    queryKey: [
      'BulkEditListingClient.BulkGetListingPriceCalculationsInEvent',
      activeAccountWebClientConfig.activeAccountId,
      event.viagId,
      listingIds,
      autoPricingSettingsUpdates,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const pricingSettings = await new BulkEditListingClient(
        activeAccountWebClientConfig
      ).bulkGetListingPriceCalculationsInEvent(event.viagId!, {
        item1: listingIds,
        item2: autoPricingSettingsUpdates!,
      });

      return pricingSettings;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('PricingClient.getListingAutoPricingSettings', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
            viagogoEventId: event.viagId,
            listingIds,
            autoPricingSettingsUpdates,
          },
        });
      },
    },
  });

  return {
    listingCalculationsQuery,
    bulkListingPriceCalculationResult: listingCalculationsQuery.data,
    isLoading: listingCalculationsQuery.isLoading,
  };
};

export const useBulkEditAutoPricingSettingsSidePanelEventMapProps = (
  viagogoEventId: number,
  listings: Listing[],
  compListings: CompListing[] | undefined
) => {
  const { sectionGroupsBySectionId } =
    useGetActiveVenueZoneMapSectionGroups(viagogoEventId);

  const { setValue, watch } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();
  const { compListingSelectedSectionSettings, deriveCompsFromVenueZoneConfig } =
    watch();

  const selectedSectionIds = useMemo(
    () =>
      deriveCompsFromVenueZoneConfig
        ? []
        : compListingSelectedSectionSettings?.value?.sectionIdFilter ??
          undefined,
    [
      compListingSelectedSectionSettings?.value?.sectionIdFilter,
      deriveCompsFromVenueZoneConfig,
    ]
  );

  const setSelectedSectionIds = useCallback(
    (sectionIds: number[]) => {
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings?.value,
        sectionIdFilter: sectionIds,
        rowIdFilter:
          compListingSelectedSectionSettings?.value?.rowIdFilter ?? [],
        sectionRowIdFilter:
          compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {},
        sectionIdFilterFromZoneMap: false,
        includeBetterZones: false,
        rowOffset: null,
      };
      setValue(
        'compListingSelectedSectionSettings',
        posChangedField(compListingSelectedSectionSettingsNew)
      );
    },
    [compListingSelectedSectionSettings?.value, setValue]
  );

  const { venueMapInfo } = useEventMapContext();

  const { onSectionClicked, onToggleMirrors, setSelectedSections } =
    useEventVenueMapSectionHandler(
      selectedSectionIds,
      setSelectedSectionIds,
      venueMapInfo
    );

  const compListingsVenueMapColor = useComparableListingsVenueMapColor(
    listings,
    undefined,
    compListings
  );

  const getColor = useMemo(
    () =>
      deriveCompsFromVenueZoneConfig
        ? createGetColor(sectionGroupsBySectionId)
        : compListingsVenueMapColor,
    [
      compListingsVenueMapColor,
      deriveCompsFromVenueZoneConfig,
      sectionGroupsBySectionId,
    ]
  );

  return {
    getColor,
    onSectionClicked,
    onToggleMirrors,
    selectedSectionIds,
    setSelectedSections,
    disableHoverEffect: Boolean(deriveCompsFromVenueZoneConfig),
  };
};
