import { isEqual } from 'lodash-es';

/**
 * Check if two arrays are equal ignoring order
 * @param a array of numbers
 * @param b array of numbers
 * @returns boolean
 */
export const isEqualIgnoringOrder = (a: number[], b: number[]) => {
  return isEqual(a.toSorted(), b.toSorted());
};
