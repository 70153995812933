import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQueryV2 } from 'src/utils/getServerSideOnlyMetricsQuery';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CatalogClient,
  EntityWithTicketsQuery,
  EventWithData,
  Feature,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { getEventsWithNonZeroCounts } from '../CatalogDataContext/CatalogDataContext.utils';
import { useFilterQueryContext } from '../FilterQueryContext';
import {
  CatalogMetricsContextProviderProps,
  CatalogMetricsResults,
  createCatalogMetricsContextV2,
  ICatalogMetricsContext,
} from './CatalogMetrics.type';

export function CatalogMetricsContextV2Provider<
  TMetrics extends { currency: string | null },
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  entityType,
  getCatalogMetrics,
  children,
  disabled,
  isDetailed,
}: CatalogMetricsContextProviderProps<TMetrics, TQuery>) {
  const CatalogMetricsContext = createCatalogMetricsContextV2<TMetrics>();

  const { trackError } = useErrorBoundaryContext();

  const { data } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();
  const transformedQuery = useMemo(
    () => getServerSideOnlyMetricsQueryV2(filterQuery),
    [filterQuery]
  );

  const hasCatalogV2Feature = useUserHasFeature(Feature.CatalogCacheStrategyV2);

  const [metrics, setMetrics] =
    useState<CatalogMetricsResults<TMetrics, TQuery>>();
  const [lastRefetchTime, setLastRefetchTime] = useState<Date>();

  const getEventIdsToGetMetrics = (events: EventWithData[]) => {
    const eventIds = events
      .map((ev) => ev.counts?.viagVirtId ?? ev.event?.viagVirtualId)
      .filter((id: string | null): id is string => id != null);
    const performerIds = events
      .map((ev) => ev.counts?.perfId ?? ev.event?.perfId)
      .filter((id: number | null): id is number => id != null);
    const venueIds = events
      .map((ev) => ev.counts?.venId ?? ev.event?.venueId)
      .filter((id: number | null): id is number => id != null);

    return {
      eventIds,
      performerIds,
      venueIds,
      maxRowVersions: undefined,
    };
  };

  const eventsMissingMetrics = useMemo(() => {
    if (!data?.events) {
      return {}; // don't need any metrics
    }
    const eventsWithdata = Object.values(data.events);
    const events = hasCatalogV2Feature
      ? getEventsWithNonZeroCounts(entityType, eventsWithdata)
      : eventsWithdata;

    if (!events.length) {
      return {}; // don't need any metrics
    }

    if (
      !metrics ||
      !isEqual(metrics?.filter, transformedQuery.dataChangingFilter)
    ) {
      // no metrics, or metrics filter changed, need to re-get for all events
      return getEventIdsToGetMetrics(events);
    }

    // metrics filter didn't change, so just check if any event are missing metrics
    // if they are missing, go get them
    const results = events.filter((ev) =>
      isDetailed
        ? !metrics.eventsDetailed[
            ev.counts?.viagVirtId ?? ev.event?.viagVirtualId
          ]
        : !metrics.events[ev.counts?.viagVirtId ?? ev.event?.viagVirtualId]
    );

    if (results.length) {
      // We have some missing metrics, so we need to get them
      return getEventIdsToGetMetrics(results);
    }

    // We don't have any missing metrics, but we want to get all if the time has ellapsed
    if (
      lastRefetchTime &&
      new Date().getTime() - lastRefetchTime.getTime() >
        DATA_REFRESH_RATE_IN_MILLIS_LONG
    ) {
      const maxRowVersions = metrics?.maxRowVersion; // if we're refreshing, we only want to get the new ones
      const eventIds = Object.keys(metrics?.events ?? {});
      const performerIds = Object.keys(metrics?.performers ?? {});
      const venueIds = Object.keys(metrics?.venues ?? {});

      return {
        eventIds,
        performerIds,
        venueIds,
        maxRowVersions: maxRowVersions ?? undefined,
      };
    }

    return {};
  }, [
    data?.events,
    entityType,
    hasCatalogV2Feature,
    isDetailed,
    lastRefetchTime,
    metrics,
    transformedQuery.dataChangingFilter,
  ]);

  const shouldQuery = useMemo(
    () =>
      !disabled &&
      Boolean(eventsMissingMetrics.eventIds?.length) &&
      !(!activeAccountWebClientConfig.activeAccountId || !getCatalogMetrics),
    [
      activeAccountWebClientConfig.activeAccountId,
      disabled,
      eventsMissingMetrics?.eventIds?.length,
      getCatalogMetrics,
    ]
  );

  const metricsQueryKey = useMemo(
    () => [
      queryKey,
      eventsMissingMetrics.eventIds?.toSorted(),
      transformedQuery.dataChangingFilter,
      activeAccountWebClientConfig.activeAccountId,
    ],
    [
      queryKey,
      eventsMissingMetrics,
      transformedQuery.dataChangingFilter,
      activeAccountWebClientConfig.activeAccountId,
    ]
  );

  const metricsQuery = useQuery({
    queryKey: metricsQueryKey,
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        () => {
          return getCatalogMetrics!(
            new CatalogClient(activeAccountWebClientConfig),
            {
              ...transformedQuery.dataChangingFilter,
              // We are sending back the event ids we need metrics for - which can be a lot
              // TODO (SAPI-2030) - in the case where there's a lot of these, we should just send back
              // the event effecting filters and let the server figure out the events
              // Also - we need to send a row-version so refresh only update the changed entities
              eventOrMappingIds: eventsMissingMetrics.eventIds,
              performerIds: eventsMissingMetrics.performerIds,
              venueIds: eventsMissingMetrics.venueIds,
            },
            eventsMissingMetrics.maxRowVersions
          );
        },
        (error: ErrorTypes) => {
          trackError('CatalogClient.getCatalogMetrics', error, {
            ...transformedQuery,
          });
          // Since this is just loading metrics, we have the data so we'll just ignore that metrics error
        }
      );
    },
    enabled: shouldQuery,
    staleTime: Infinity,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  useEffect(() => {
    if (metricsQuery.data) {
      const newMetrics = {
        filter: transformedQuery.dataChangingFilter,
        maxRowVersion: metricsQuery.data.maxRowVersion,
        eventsDetailed: {
          ...(metrics?.eventsDetailed ?? {}),
          ...metricsQuery.data.eventsDetailed,
        },
        events: {
          ...(metrics?.events ?? {}),
          ...metricsQuery.data.events,
        },
        performers: {
          ...(metrics?.performers ?? {}),
          ...metricsQuery.data.performers,
        },
        venues: {
          ...(metrics?.venues ?? {}),
          ...metricsQuery.data.venues,
        },
      };
      if (!isEqual(metrics, newMetrics)) {
        setLastRefetchTime(new Date());
        setMetrics(newMetrics);
      }
    }
  }, [
    metrics,
    metricsQuery.data,
    setMetrics,
    transformedQuery.dataChangingFilter,
  ]);

  const refreshMetrics = useCallback(async () => {
    setMetrics(undefined); // clear the cached metrics
    await queryClient.invalidateQueries({
      queryKey: metricsQueryKey,
    });
  }, [metricsQueryKey, queryClient, setMetrics]);

  const contextValues = useMemo<ICatalogMetricsContext<TMetrics>>(() => {
    return {
      isLoading: metricsQuery.isLoading,
      eventDetailedMetrics: metrics?.eventsDetailed,
      eventMetrics: metrics?.events,
      performerMetrics: metrics?.performers,
      venueMetrics: metrics?.venues,
      refreshMetrics: refreshMetrics,
    };
  }, [
    metrics?.events,
    metrics?.eventsDetailed,
    metrics?.performers,
    metrics?.venues,
    metricsQuery.isLoading,
    refreshMetrics,
  ]);

  return (
    <CatalogMetricsContext.Provider value={contextValues}>
      {children}
    </CatalogMetricsContext.Provider>
  );
}
