import { TableOptions } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { ListingExperiment, ListingGroup } from 'src/WebApiController';

import { getTableColumnDefinitions } from './configs/ListingExperimentTableColumnsConfig';

export type ListingExpeirmentTableProps = {
  isLoading: boolean;
  experiments: ListingExperiment[] | null | undefined;
  listingGroups: ListingGroup[];
  onStopExperiment: (listingGroupId: string | null) => Promise<void>;
  onResumeExperiment: (listingGroupId: string | null) => void;
  onRestartExperiment: (listingGroupId: string | null) => void;
};

export const ListingExperimentTable: React.FC<ListingExpeirmentTableProps> = ({
  isLoading,
  experiments,
  listingGroups,
  onStopExperiment,
  onResumeExperiment,
  onRestartExperiment,
}) => {
  const experimentsText = useContent(ContentId.Experiments);

  const options: Partial<TableOptions<ListingExperiment>> = useMemo(
    () => ({
      data: experiments ?? [],
      columns: getTableColumnDefinitions(
        onStopExperiment,
        onResumeExperiment,
        onRestartExperiment,
        listingGroups
      ),
      state: {},
    }),
    [
      experiments,
      onStopExperiment,
      onResumeExperiment,
      onRestartExperiment,
      listingGroups,
    ]
  );

  if (isLoading) {
    return <PosSpinner />;
  }

  return experiments?.length ? (
    <Table options={options} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={experimentsText}
      />
    </NoData>
  );
};
