import { useMemo } from 'react';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import {
  getEventDisplayText,
  getPerformerAndVenueForEvent,
} from 'src/utils/eventWithDataUtils';
import { EventWithData } from 'src/WebApiController';

type AdGroupEventSelectorProps = Omit<PosSelectProps, 'valueOptionsContent'> & {
  filterCallback: (event?: EventWithData) => boolean;
};

export const AdGroupEventSelector = ({
  filterCallback,
  placeholderText,
  ...props
}: AdGroupEventSelectorProps) => {
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const allEvents = useMemo(() => {
    if (!catalogData?.events) return {};
    const events = catalogData.events;

    const results = Object.values(events).reduce(
      (acc, event) => {
        if (event.event.viagId && filterCallback(event)) {
          const { venue } = getPerformerAndVenueForEvent(
            event?.event,
            catalogData
          );

          acc[event.event.viagId.toString()] = getEventDisplayText(
            event.event,
            venue
          );
        }
        return acc;
      },
      {} as Record<string, string>
    );

    if (Object.values(results).length == 0) {
      results[EmptySelectionKey] = 'N/A';
    }
    return results;
  }, [catalogData, filterCallback]);

  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(allEvents).length > 10
      }
      loading={catalogData == null}
      placeholderText={placeholderText}
      valueOptionsContent={allEvents}
    />
  );
};
