import { clsx } from 'clsx';
import { ComponentProps, forwardRef, ReactNode } from 'react';

import * as styles from './ToolbarButton.css';

export type ToolbarButtonProps = ComponentProps<'div'> & {
  disabled?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
};

export const ToolbarButton = forwardRef<HTMLDivElement, ToolbarButtonProps>(
  function ToolbarButton(
    { isSelected, children, className, disabled, ...buttonProps },
    ref
  ) {
    return (
      <div
        ref={ref}
        className={clsx(
          styles.button[
            isSelected ? 'selected' : disabled ? 'disabled' : 'unselected'
          ],
          className
        )}
        {...buttonProps}
      >
        {children}
      </div>
    );
  }
);
