import { createColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { defaultColumnHelperAccessor } from 'src/tables/ReportTableCommon/DefaultColumnHelperAccessor';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { reportV2AccessorFn } from 'src/tables/ReportTableCommon/ReportTableCommon.utils';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import { CommissionReportTableValueColumnId } from 'src/utils/columns/commission/commissionColumnUtils.types';
import { CommissionReportTableGroupingColumnId } from 'src/utils/columns/commission/commissionColumnUtils.types';
import {
  COMMISSION_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID,
  COMMISSION_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import {
  createSaleEventPageUrl,
  getSaleDetailsRelativeUrl,
} from 'src/utils/saleUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { ReportValueType } from 'src/WebApiController';

const columnHelper = createColumnHelper<ReportMetricsResponse | null>();

const SECTION_TYPE = SectionType.SalesReport;

export const COMMISSION_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG = {
  [CommissionReportTableGroupingColumnId.EventDateLocal]: columnHelper.accessor(
    reportV2AccessorFn(CommissionReportTableGroupingColumnId.EventDateLocal),
    {
      id: CommissionReportTableGroupingColumnId.EventDateLocal,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              COMMISSION_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                CommissionReportTableGroupingColumnId.EventDateLocal
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MMDD } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const dateValue = value ? new Date(value).toISOString() : value;

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            align="left"
            enableUtcFallback
            hideRelativeTerms
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [CommissionReportTableGroupingColumnId.SaleId]: columnHelper.accessor(
    reportV2AccessorFn(CommissionReportTableGroupingColumnId.SaleId),
    {
      id: CommissionReportTableGroupingColumnId.SaleId,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              COMMISSION_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                CommissionReportTableGroupingColumnId.SaleId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleId = getValue<string | null>();

        if (!saleId) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const saleIds = [Number(saleId)];

        return (
          <LinkCell
            text={saleIds.map((saleId) => `#${saleId}`)}
            url={saleIds.map((saleId) => getSaleDetailsRelativeUrl(saleId))}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [CommissionReportTableGroupingColumnId.EventId]: columnHelper.accessor(
    reportV2AccessorFn(CommissionReportTableGroupingColumnId.EventId),
    {
      id: CommissionReportTableGroupingColumnId.EventId,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              COMMISSION_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                CommissionReportTableGroupingColumnId.EventId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.getValue<number | null>();
        if (!eventId) {
          return <TextCell {...params} align={'left'} />;
        }
        const eventUrl = createSaleEventPageUrl(eventId.toString());

        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
          />
        );
      },
    }
  ),
} as const;

export const commissionReportTableColumnDef = (
  columnId: string,
  valueType: ReportValueType | undefined
) => {
  const specialConfig = Object.entries(
    COMMISSION_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG
  ).find(([key]) => key === columnId)?.[1];

  if (specialConfig) {
    return specialConfig;
  }

  const headerContentId =
    COMMISSION_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
      columnId as unknown as CommissionReportTableGroupingColumnId
    ] ||
    COMMISSION_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID[
      columnId as unknown as CommissionReportTableValueColumnId
    ];

  return defaultColumnHelperAccessor(
    columnHelper,
    columnId,
    headerContentId,
    valueType
  );
};
