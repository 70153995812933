import { useState } from 'react';
import { vars } from 'src/core/themes';
import { TableFilterIcon } from 'src/svgs/TableFilterIcon';

export function ColumnFilterButton({ isActive }: { isActive?: boolean }) {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <TableFilterIcon
      size={vars.iconSize.s}
      isActive={isActive || isPressed}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => {
        setIsPressed(false);
      }}
    />
  );
}
