import { type AsyncStorage } from '@tanstack/react-query-persist-client';
import { clear, del, get, set } from 'idb-keyval';
import { logToServer } from 'src/utils/clientLogUtils';
import { LogLevel } from 'src/WebApiController';

export async function clearIDBCache() {
  try {
    await clear();
  } catch (e) {
    console.warn(e);
    logToServer(
      LogLevel.Warning,
      'QueryClient-index-db-clear',
      `Failed to clear the index-db`,
      e instanceof Object && e.constructor === Object ? e : {}
    );
  }
}

export function createIDBAsyncStorage(): AsyncStorage {
  return {
    getItem: async (key) => {
      try {
        return await get(key);
      } catch (e) {
        console.warn(e);
        logToServer(
          LogLevel.Warning,
          'QueryClient-index-db-get',
          `Failed to get ${key} from index-db`,
          e instanceof Object && e.constructor === Object ? e : {}
        );
      }
    },
    setItem: async (key, data) => {
      try {
        return await set(key, data);
      } catch (e) {
        console.warn(e);
        logToServer(
          LogLevel.Warning,
          'QueryClient-index-db-set',
          `Failed to set ${key} / ${data} to index-db`,
          e instanceof Object && e.constructor === Object ? e : {}
        );
      }
    },
    removeItem: async (key: string) => {
      try {
        return await del(key);
      } catch (e) {
        console.warn(e);
        logToServer(
          LogLevel.Warning,
          'QueryClient-index-db-del',
          `Failed to delete ${key} from index-db`,
          e instanceof Object && e.constructor === Object ? e : {}
        );
      }
    },
  };
}
