import { useMutation } from '@tanstack/react-query';
import genPassword from 'browser-generate-password';
import {Marketplace, PosUiActionResult, SellerAccountClient} from 'src/WebApiController';
import {isSuccess} from "src/utils/errorUtils";

async function handleResponse(response: PosUiActionResult) {
  return isSuccess(response) ? response : Promise.reject(new Error(`Response failure ${response.status} ${response.message}`));
}

/**
 * https://app.ticketmaster.com/tradedeskapi/user/docs/index.html
 * @returns Mutation
 */
export function useTradeDeskSendResetPasswordCodeMutation(activeAccountId?: string) {
  return useMutation({
    async mutationFn({ username }: { username: string }) {
      if (!activeAccountId) {
        throw new Error('activeAccountId is undefined');
      }

      return new SellerAccountClient({activeAccountId}).initiateTradeDeskReset(username).then(handleResponse);
    },
  });
}

/**
 * https://app.ticketmaster.com/tradedeskapi/user/docs/index.html
 * @returns Mutation
 */
export function useTradeDeskResetPasswordMutation(activeAccountId?: string) {
  return useMutation({
    async mutationFn({
      username,
      oldPassword,
      newPassword = genPassword(10, true),
      code,
    }: {
      username: string;
      oldPassword?: string;
      /**
       * New password is automatically generated if not passed in.
       */
      newPassword?: string;
      code?: string;
    }) {
      if (!activeAccountId) {
        throw new Error('activeAccountId is undefined');
      }

      if (!code)
      {
        throw new Error('Code is required');
      }

      var sellerAccountClient: SellerAccountClient = new SellerAccountClient({activeAccountId});

      const resp = await sellerAccountClient.resetTradeDeskPassword({
        username: username,
        oldPassword: oldPassword ?? null,
        newPassword: newPassword,
        code: code
      }).then(handleResponse);

      await sellerAccountClient.updateMarketplaceSellerAccountPassword(
        Marketplace.Ticketmaster,
        newPassword
      );
      return resp;
    },
  });
}
