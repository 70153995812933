import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';
import { Feature } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';

export const PAYMENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  MarketplacePaymentsTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [MarketplacePaymentsTableColumnId.PaymentReferenceID]: {},
  [MarketplacePaymentsTableColumnId.PaymentAmount]: {},
  [MarketplacePaymentsTableColumnId.PaymentDate]: {},
  [MarketplacePaymentsTableColumnId.State]: {},
  [MarketplacePaymentsTableColumnId.CreditAmount]: {},
  [MarketplacePaymentsTableColumnId.ProceedsAmount]: {},
  [MarketplacePaymentsTableColumnId.ChargeAmount]: {},
  [MarketplacePaymentsTableColumnId.Marketplace]: {},
  [MarketplacePaymentsTableColumnId.ConvertedPaymentsAmount]: {
    requiredFeatures: [Feature.MarketplacePaymentConversion],
  },
  [MarketplacePaymentsTableColumnId.Actions]: {
    isConfigurable: false,
    isRequired: true,
  },
};

export const PAYMENTS_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG: Partial<ColumnPersonalizationFlags> =
  {
    isHiddenByDefault: true,
    isNumeric: true,
  };
