import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { matchPath, NavLink, Outlet, useLocation } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { UnauthorizedAccessMessageContainer } from 'src/navigations/LayoutContent/LayoutContent.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { SETTINGS_VIEWS_TO_CONTENT_ID_MAP } from 'src/utils/constants/contentIdMaps';
import { Feature, Permission } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as reportsStyles from '../Reports/Reports.css';
import { SettingsTree, SettingTab } from './constants';
import * as styles from './Settings.css';

export const Settings = () => {
  const { appContext } = useAppContext();

  const settingsText = useContent(ContentId.Settings);
  const { pathname } = useLocation();

  const hasSiteVersionFeature = useUserHasFeature(Feature.DisplayBuildVersion);
  const hasPurchaseSettingsTabFeature = useUserHasFeature(
    Feature.PurchaseSettingsTab
  );
  const hasTeamManagementFeature = useUserHasFeature(Feature.TeamManagement);
  const hasCurrencyConversionSettingsTabFeature = useUserHasFeature(
    Feature.CurrencyConversionSettingsTab
  );
  const hasAdminPermission = useUserHasAnyOfPermissions(
    Permission.SellerAccount_Admin
  );
  const hasFulfillmentProvidersFeature = useUserHasFeature(
    Feature.FulfillmentProviders
  );

  const hasVendorAccountV2 = useUserHasFeature(Feature.VendorAccountV2);

  const hasAutoPricingTabFeature = useUserHasAutopricingFeature(
    Feature.AutoPricingTabInAccountSettings
  );

  const hasVendorManagementPermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned,
    Permission.Purchases_EditVendor
  );

  const isAuthorized = useMemo(
    () => ({
      [SettingTab.UserManagement]: hasAdminPermission,
      [SettingTab.UserManagement_Users]: hasAdminPermission,
      [SettingTab.UserManagement_Roles]: hasAdminPermission,
      [SettingTab.UserManagement_Teams]:
        hasAdminPermission && hasTeamManagementFeature,
      [SettingTab.SellerAccount]: !hasVendorAccountV2 && hasAdminPermission,
      [SettingTab.VendorManagement]:
        hasVendorAccountV2 && hasVendorManagementPermission,
      [SettingTab.VendorManagement_Payments]:
        hasVendorAccountV2 && hasVendorManagementPermission,
      [SettingTab.VendorManagement_Vendors]:
        hasVendorAccountV2 && hasVendorManagementPermission,
      [SettingTab.MyAccount]: true,
      [SettingTab.SyncCenter]: hasAdminPermission,
      [SettingTab.CurrencyConversion]:
        hasAdminPermission && hasCurrencyConversionSettingsTabFeature,
      [SettingTab.PurchaseOrder]:
        hasAdminPermission && hasPurchaseSettingsTabFeature,
      [SettingTab.FulfillmentProviders]:
        hasAdminPermission && hasFulfillmentProvidersFeature,
      [SettingTab.AutoPricing]: hasAdminPermission && hasAutoPricingTabFeature,
      [SettingTab.Accounting]: hasAdminPermission,
    }),
    [
      hasAdminPermission,
      hasAutoPricingTabFeature,
      hasCurrencyConversionSettingsTabFeature,
      hasFulfillmentProvidersFeature,
      hasPurchaseSettingsTabFeature,
      hasTeamManagementFeature,
      hasVendorAccountV2,
      hasVendorManagementPermission,
    ]
  );

  const isPathActive = useCallback(
    (id: SettingTab) =>
      matchPath(pathname, `/settings/${SettingsTree[id]}${id}`),
    [pathname]
  );

  const hasPermissionsForActiveView = useMemo(() => {
    const activePath = Object.keys(isAuthorized).find((key) =>
      isPathActive(key as SettingTab)
    );

    const isRootPathActive = matchPath(pathname, '/settings');

    const hasPermissionsForActiveView =
      isRootPathActive || (isAuthorized[activePath as SettingTab] ?? false);

    return hasPermissionsForActiveView;
  }, [isAuthorized, pathname, isPathActive]);

  return (
    <LayoutContent
      mainRoute={MainRoute.Settings}
      routeTitle={settingsText}
      rightContent={
        hasSiteVersionFeature && appContext ? (
          <div className={styles.versionNumber}>
            <Content id={ContentId.AppVersion} />: <b>{appContext.version}</b>
          </div>
        ) : null
      }
    >
      <div className={reportsStyles.root}>
        <div className={styles.mainContent}>
          <div
            className={clsx(styles.tabsContainer, styles.tabsContainerWidth)}
          >
            {(
              Object.entries(SETTINGS_VIEWS_TO_CONTENT_ID_MAP) as [
                SettingTab,
                ContentId,
              ][]
            ).map(([id, contentId]) => {
              const parent = SettingsTree[id as string];
              return isAuthorized[id] ? (
                <NavLink
                  key={id}
                  to={`/settings/${parent}${id}`}
                  className={({ isActive }) => {
                    return clsx(
                      reportsStyles.navTitle,
                      reportsStyles.textPrimary,
                      {
                        [reportsStyles.navTitleActive]: isActive,
                        [reportsStyles.navTitleSecondary]: parent !== '',
                      }
                    );
                  }}
                >
                  <Content id={contentId} />
                </NavLink>
              ) : null;
            })}
          </div>
          <div
            className={clsx(styles.viewContainer, {
              [styles.myAccount]: isPathActive(SettingTab.MyAccount),
            })}
          >
            {hasPermissionsForActiveView ? (
              <Outlet />
            ) : (
              <UnauthorizedAccessMessageContainer>
                <Content id={ContentId.AccessToViewUnauthorized} />
              </UnauthorizedAccessMessageContainer>
            )}
          </div>
        </div>
      </div>
    </LayoutContent>
  );
};
