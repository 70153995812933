import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
import { PerformerVenueList } from 'src/components/Events/PerformerVenueList';
import { FilterDropdownItem } from 'src/components/Filters';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import {
  FilterToolbarEmbedded,
  FilterToolbarEmbeddedProps,
} from 'src/components/FilterToolbarEmbedded';
import {
  commissionMandatoryFiltersToShow,
  useCommissionFilters,
} from 'src/components/MainFilterBar/useCommissionFilters';
import { listingsMandatoryFiltersToShow } from 'src/components/MainFilterBar/useListingFilters';
import {
  listingsReportMandatoryFiltersToShow,
  useListingReportFilters,
} from 'src/components/MainFilterBar/useListingReportFilters';
import { saleMandatoryFiltersToShow } from 'src/components/MainFilterBar/useSaleFilters';
import {
  saleReportMandatoryFiltersToShow,
  useSaleReportFilters,
} from 'src/components/MainFilterBar/useSaleReportFilters';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Button, Popover, Stack } from 'src/core/ui';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultCommissionQuery,
  DefaultReportV2ListingQuery,
  DefaultReportV2SaleQuery,
} from 'src/utils/eventQueryUtils';
import {
  ReportFilterEditability,
  ReportPerformerVenueFilterItemId,
} from 'src/utils/reportsFilterUtils';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  UIReportCommissionQuery,
  UIReportV2ListingQuery,
  UIReportV2SaleQuery,
} from 'src/WebApiController';

import * as styles from './ReportBuilderDialog.css';
import {
  getEditability,
  updateFilterItemIds,
} from './ReportBuilderFilterInput.utils';

type ReportFilterInputProps = {
  onSubmitFilter?: () => void;
} & Pick<
  FilterToolbarEmbeddedProps,
  | 'direction'
  | 'performerVenueFilter'
  | 'filtersListHeader'
  | 'addFilterDropdownProps'
  | 'flexWrap'
  | 'viewerMode'
  | 'disabled'
>;

const FILTERS_FIELD = 'request.filters';

export function ReportBuilderFilterInputV2({
  reportType,
  direction,
  ...filterInputProps
}: {
  reportType: ReportTypesV2;
} & ReportFilterInputProps) {
  const { watch } = useFormContext<ReportConfigV2>();

  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const performerFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.PerformerIds
  );
  const venueFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.VenueIds
  );

  // const hidePerformerVenueFilter =
  //   embeddedDisplayOnly &&
  //   (performerFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) ===
  //     ReportFilterEditability.Hidden &&
  //   (venueFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) ===
  //     ReportFilterEditability.Hidden;

  return (
    <Stack
      direction={direction}
      alignItems={direction === 'column' ? 'start' : 'center'}
      gap="l"
      style={{
        flexGrow: '1',
      }}
    >
      {reportType === ReportTypesV2.InventoryV2 ? (
        <>
          <ListingFilterInput direction={direction} {...filterInputProps} />
        </>
      ) : reportType === ReportTypesV2.Commission ? (
        <>
          <CommissionFilterInput
            direction={direction}
            performerVenueFilter={undefined}
            {...filterInputProps}
          />
        </>
      ) : (
        <>
          {/* {!embeddedDisplayOnly && (
            <PerformerVenuePopover
              entityType={ActionOutboxEntityType.Sale}
              onSubmitFilter={onSubmitFilter}
            />
          )} */}
          <SaleFilterInput
            direction={direction}
            {...filterInputProps}
            // performerVenueFilter={
            //   hidePerformerVenueFilter ? undefined : (
            //     <PerformerVenuePopover
            //       entityType={ActionOutboxEntityType.Sale}
            //       onSubmitFilter={onSubmitFilter}
            //       embeddedDisplayOnly={embeddedDisplayOnly}
            //     />
            //   )
            // }
          />
        </>
      )}
    </Stack>
  );
}

const ListingFilterInput = ({
  onSubmitFilter,
  ...filterToolbarProps
}: ReportFilterInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfigV2>();

  const formFilter = watch(FILTERS_FIELD);
  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const [selectedFilters, setSelectedFilters] =
    useState<FilterDropdownItem[]>();

  const {
    initialQuery,
    tempQuery,
    filterQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<UIReportV2ListingQuery>();
  const { filters } = useListingReportFilters({});

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  useEffect(() => {
    if (
      formFilter != null &&
      !isEqual(formFilter as UIReportV2ListingQuery, tempQuery)
    ) {
      setTempQuery(formFilter as UIReportV2ListingQuery);
      setFilterQuery(formFilter as UIReportV2ListingQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSubmitFilterHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuery]);

  useEffect(() => {
    if (!isEqual(formFilter as UIReportV2ListingQuery, filterQuery)) {
      setValue(FILTERS_FIELD, filterQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: listingsReportMandatoryFiltersToShow,
    initialQuery: DefaultReportV2ListingQuery,
  });

  const filtersWithEditability = useMemo(
    () =>
      filters.map((filterGroup) => ({
        ...filterGroup,
        items: filterGroup.items.map((filterItem) => ({
          ...filterItem,
          reportFilterEditability: getEditability(
            editableFilterItemIds,
            viewableFilterItemIds,
            hiddenFilterItemIds,
            filterItem.filterId
          ),
        })),
      })),
    [editableFilterItemIds, filters, hiddenFilterItemIds, viewableFilterItemIds]
  );

  const onEditabilityChange = useCallback(
    (filterId: string | string[], editability: ReportFilterEditability) => {
      const filterIds = Array.isArray(filterId) ? filterId : [filterId];

      const {
        newHiddenFilterItemIds,
        newViewableFilterItemIds,
        newEditableFilterItemIds,
      } = updateFilterItemIds(
        editableFilterItemIds,
        viewableFilterItemIds,
        hiddenFilterItemIds,
        filterIds,
        editability
      );

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds);
      setValue('viewableFilterItemIds', newViewableFilterItemIds);
      setValue('editableFilterItemIds', newEditableFilterItemIds);
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <FilterToolbarEmbedded
      onResetAll={() => {
        setTempQuery(DefaultReportV2ListingQuery);
        setFilterQuery(DefaultReportV2ListingQuery);
      }}
      filters={filtersWithEditability}
      appliedFilterIds={appliedFilters}
      mandatoryFiltersToShow={listingsMandatoryFiltersToShow}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      onEditabilityChange={onEditabilityChange}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      {...filterToolbarProps}
    />
  );
};

const SaleFilterInput = ({
  onSubmitFilter,
  ...filterToolbarProps
}: ReportFilterInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfigV2>();

  const formFilter = watch(FILTERS_FIELD);
  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const [selectedFilters, setSelectedFilters] =
    useState<FilterDropdownItem[]>();

  const {
    initialQuery,
    tempQuery,
    filterQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<UIReportV2SaleQuery>();
  const { filters } = useSaleReportFilters({});

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  useEffect(() => {
    if (
      formFilter != null &&
      !isEqual(formFilter as UIReportV2SaleQuery, tempQuery)
    ) {
      setTempQuery(formFilter as UIReportV2SaleQuery);
      setFilterQuery(formFilter as UIReportV2SaleQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSubmitFilterHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuery]);

  useEffect(() => {
    if (!isEqual(formFilter as UIReportV2SaleQuery, filterQuery)) {
      setValue(FILTERS_FIELD, filterQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: saleReportMandatoryFiltersToShow,
    initialQuery: DefaultReportV2SaleQuery,
  });
  const filtersWithEditability = useMemo(
    () =>
      filters.map((filterGroup) => ({
        ...filterGroup,
        items: filterGroup.items.map((filterItem) => ({
          ...filterItem,
          reportFilterEditability: getEditability(
            editableFilterItemIds,
            viewableFilterItemIds,
            hiddenFilterItemIds,
            filterItem.filterId
          ),
        })),
      })),
    [editableFilterItemIds, filters, hiddenFilterItemIds, viewableFilterItemIds]
  );

  const onEditabilityChange = useCallback(
    (filterId: string | string[], editability: ReportFilterEditability) => {
      const filterIds = Array.isArray(filterId) ? filterId : [filterId];

      const {
        newHiddenFilterItemIds,
        newViewableFilterItemIds,
        newEditableFilterItemIds,
      } = updateFilterItemIds(
        editableFilterItemIds,
        viewableFilterItemIds,
        hiddenFilterItemIds,
        filterIds,
        editability
      );

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds);
      setValue('viewableFilterItemIds', newViewableFilterItemIds);
      setValue('editableFilterItemIds', newEditableFilterItemIds);
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <FilterToolbarEmbedded
      onResetAll={() => {
        setTempQuery(DefaultReportV2SaleQuery);
        setFilterQuery(DefaultReportV2SaleQuery);
      }}
      filters={filtersWithEditability}
      appliedFilterIds={appliedFilters}
      mandatoryFiltersToShow={saleMandatoryFiltersToShow}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      onEditabilityChange={onEditabilityChange}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      {...filterToolbarProps}
    />
  );
};

const CommissionFilterInput = ({
  onSubmitFilter,
  ...filterToolbarProps
}: ReportFilterInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfigV2>();

  const formFilter = watch(FILTERS_FIELD);
  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const [selectedFilters, setSelectedFilters] =
    useState<FilterDropdownItem[]>();

  const {
    initialQuery,
    tempQuery,
    filterQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<UIReportCommissionQuery>();
  const { filters } = useCommissionFilters();

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  useEffect(() => {
    if (
      formFilter != null &&
      !isEqual(formFilter as UIReportCommissionQuery, tempQuery)
    ) {
      setTempQuery(formFilter as UIReportCommissionQuery);
      setFilterQuery(formFilter as UIReportCommissionQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSubmitFilterHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuery]);

  useEffect(() => {
    if (!isEqual(formFilter as UIReportCommissionQuery, filterQuery)) {
      setValue(FILTERS_FIELD, filterQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: commissionMandatoryFiltersToShow,
    initialQuery: DefaultCommissionQuery,
  });
  const filtersWithEditability = useMemo(
    () =>
      filters.map((filterGroup) => ({
        ...filterGroup,
        items: filterGroup.items.map((filterItem) => ({
          ...filterItem,
          reportFilterEditability: getEditability(
            editableFilterItemIds,
            viewableFilterItemIds,
            hiddenFilterItemIds,
            filterItem.filterId
          ),
        })),
      })),
    [editableFilterItemIds, filters, hiddenFilterItemIds, viewableFilterItemIds]
  );

  const onEditabilityChange = useCallback(
    (filterId: string | string[], editability: ReportFilterEditability) => {
      const filterIds = Array.isArray(filterId) ? filterId : [filterId];

      const {
        newHiddenFilterItemIds,
        newViewableFilterItemIds,
        newEditableFilterItemIds,
      } = updateFilterItemIds(
        editableFilterItemIds,
        viewableFilterItemIds,
        hiddenFilterItemIds,
        filterIds,
        editability
      );

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds);
      setValue('viewableFilterItemIds', newViewableFilterItemIds);
      setValue('editableFilterItemIds', newEditableFilterItemIds);
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <FilterToolbarEmbedded
      onResetAll={() => {
        setTempQuery(DefaultCommissionQuery);
        setFilterQuery(DefaultCommissionQuery);
      }}
      filters={filtersWithEditability}
      appliedFilterIds={appliedFilters}
      mandatoryFiltersToShow={saleMandatoryFiltersToShow}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      onEditabilityChange={onEditabilityChange}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      {...filterToolbarProps}
    />
  );
};

const PerformerVenuePopover = ({
  entityType,
  onSubmitFilter,
  embeddedDisplayOnly,
}: {
  entityType: ActionOutboxEntityType.Listing | ActionOutboxEntityType.Sale;
  onSubmitFilter?: () => void;
  embeddedDisplayOnly?: boolean;
}) => {
  const { setValue, watch } = useFormContext<ReportConfigV2>();

  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const performerFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.PerformerIds
  );
  const venueFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.VenueIds
  );

  const onEditabilityChange = useCallback(
    (reportFilterId: string, editability: ReportFilterEditability) => {
      const newHiddenFilterItemIds = hiddenFilterItemIds
        ? hiddenFilterItemIds.filter((id) => id !== reportFilterId)
        : [];
      const newViewableFilterItemIds = viewableFilterItemIds
        ? viewableFilterItemIds.filter((id) => id !== reportFilterId)
        : [];
      const newEditableFilterItemIds = editableFilterItemIds
        ? editableFilterItemIds.filter((id) => id !== reportFilterId)
        : [];

      if (editability === ReportFilterEditability.Hidden) {
        newHiddenFilterItemIds.push(reportFilterId);
      } else if (editability === ReportFilterEditability.View) {
        newViewableFilterItemIds.push(reportFilterId);
      } else if (editability === ReportFilterEditability.Edit) {
        newEditableFilterItemIds.push(reportFilterId);
      }

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds.toSorted());
      setValue('viewableFilterItemIds', newViewableFilterItemIds.toSorted());
      setValue('editableFilterItemIds', newEditableFilterItemIds.toSorted());
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <Popover.Root onOpenChange={onSubmitFilter}>
      <Popover.Trigger asChild>
        <Button className={styles.selectGroupingButton} variant={'text'}>
          <Content id={ContentId.PerformerAndVenue} />
        </Button>
      </Popover.Trigger>
      <Popover.Content align="start">
        <div className={styles.mainGroupingContainer}>
          <PerformerVenueList
            entityType={entityType}
            // Don't need onItemsRendered to do anything as we're not showing metrics here
            onItemsRendered={() => {}}
            hideMetrics
            // Have to update tempQuery instead of filterQuery to sync with the main filter bar here
            updateTempQuery
            onEditabilityChange={onEditabilityChange}
            performerFilterEditability={performerFilterEditability}
            venueFilterEditability={venueFilterEditability}
            embeddedDisplayOnly={embeddedDisplayOnly}
          />
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
