import { flexRender, Header } from '@tanstack/react-table';
import { vars } from 'src/core/themes';
import { SortStatus } from 'src/core/ui/SortButton/SortButton.utils';
import { ArrowDownIcon, ArrowUpIcon } from 'src/svgs/Viagogo';

import { PosTableData } from '../Table';
import * as styles from './SpreadsheetTable.css';

export const SpreadsheetTableHeader = <T extends PosTableData>({
  header,
}: {
  header: Header<T, unknown>;
}) => {
  const canSort = header.column.getCanSort();
  const sortOrder = header.column.getIsSorted();

  let sortStatus = SortStatus.UNSORTED;

  sortStatus =
    canSort && sortOrder === 'asc' ? SortStatus.ASC : SortStatus.DESC;

  return (
    <th key={header.id} style={{ width: `${header.getSize()}px` }}>
      <div className={styles.tableHeaderCellContainer}>
        {flexRender(header.column.columnDef.header, header.getContext())}
        {canSort && (
          <div
            className={styles.tableHeaderCell}
            onClick={() => {
              header.column.toggleSorting();
            }}
          >
            <div className={styles.tableHeaderCellSortButton}>
              <ArrowUpIcon
                size={vars.iconSize.xxs}
                withHoverEffect
                className={
                  sortOrder === 'asc' ? styles.sortActive : styles.sortInactive
                }
              />
              <ArrowDownIcon
                size={vars.iconSize.xxs}
                withHoverEffect
                className={
                  sortOrder === 'desc' ? styles.sortActive : styles.sortInactive
                }
              />
            </div>
          </div>
        )}
      </div>
    </th>
  );
};
