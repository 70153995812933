import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { vars } from 'src/core/themes';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { ListingNote, TicketGroupInput } from 'src/WebApiController';

function TicketGroupInputSeatTraitComponent({
  cell,
  valueOptionsContent,
  listingNotesMap,
  control,
}: CellContext<TicketGroupInput, unknown> & {
  valueOptionsContent: Record<string, ContentId | string>;
  listingNotesMap?: Record<string, ListingNote> | null;
  control: Control<PurchaseTicketsInput, any>;
}) {
  const { setValue, watch } = useFormContext<PurchaseTicketsInput>();
  const listingNotes = watch(`ticketGroups.${cell.row.index}.listingNotes`);

  return (
    <PosMultiSelect
      values={
        listingNotes?.value?.map((ln) => ln.listingNoteId.toString()) ?? []
      }
      triggerProps={{
        className: clsx(
          styles.tableCell[
            !listingNotes?.value || listingNotes?.value?.length === 0
              ? 'disabled'
              : 'active'
          ]
        ),
      }}
      style={{
        fontSize: vars.typography.fontSize.sm,
      }}
      searchable
      onChange={(lnIds: string[]) => {
        if (!listingNotesMap) return;

        const listingNotes = lnIds.map((lnId) => {
          const listingNoteId = parseInt(lnId);
          return listingNotesMap[listingNoteId];
        });
        setValue(
          `ticketGroups.${cell.row.index}.listingNotes`,
          posChangedField(listingNotes)
        );
      }}
      valueOptionsContent={valueOptionsContent}
    />
  );
}

export const TicketGroupInputSeatTrait = React.memo(
  TicketGroupInputSeatTraitComponent
) as (
  props: CellContext<TicketGroupInput, unknown> & {
    valueOptionsContent?: Record<string, ContentId | string>;
    listingNotesMap?: Record<string, ListingNote> | null;
    control: Control<PurchaseTicketsInput, any>;
  }
) => JSX.Element;
