import { Colors } from 'src/core/themes/constants/colors';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const DragHandleIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper width="4" height="11" {...props} viewBox="0 0 4 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 1C0.223858 1 0 1.22386 0 1.5C0 1.77614 0.223858 2 0.5 2C0.776142 2 1 1.77614 1 1.5C1 1.22386 0.776142 1 0.5 1ZM0.5 5C0.223858 5 0 5.22386 0 5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5C1 5.22386 0.776142 5 0.5 5ZM0 9.5C0 9.22386 0.223858 9 0.5 9C0.776142 9 1 9.22386 1 9.5C1 9.77614 0.776142 10 0.5 10C0.223858 10 0 9.77614 0 9.5ZM3.5 1C3.22386 1 3 1.22386 3 1.5C3 1.77614 3.22386 2 3.5 2C3.77614 2 4 1.77614 4 1.5C4 1.22386 3.77614 1 3.5 1ZM3.5 5C3.22386 5 3 5.22386 3 5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5C4 5.22386 3.77614 5 3.5 5ZM3 9.5C3 9.22386 3.22386 9 3.5 9C3.77614 9 4 9.22386 4 9.5C4 9.77614 3.77614 10 3.5 10C3.22386 10 3 9.77614 3 9.5Z"
        fill={props.fill || Colors.neutral200}
      />
    </SvgWrapper>
  );
};
