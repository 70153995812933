import { ComponentProps, PropsWithChildren, useCallback, useMemo } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { Content, useContent } from 'src/contexts/ContentContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { shared, vars } from 'src/core/themes';
import { Button, Radio, RadioGroup, Stack } from 'src/core/ui';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultReportV2ListingQuery,
  DefaultReportV2SaleQuery,
  EmptyReportV2ListingQuery,
  EmptyReportV2SaleQuery,
} from 'src/utils/eventQueryUtils';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import {
  UIReportV2ListingQuery,
  UIReportV2SaleQuery,
} from 'src/WebApiController';

import * as styles from './ReportBuilderDialog.css';
import { ReportBuilderFilterInputV2 } from './ReportBuilderFilterInputV2';

export type ReportBuilderDialogProps = {
  report: ReportConfigV2;
  onSave: (data: ReportConfigV2) => void;
} & ComponentProps<typeof RSModal>;

export function ReportBuilderDialogBodyV2({
  onSave,
  onClosed,
  register,
  setError,
  setValue,
  handleSubmit,
  formState,
  watch,
  resetField,
  ...rest
}: Omit<ReportBuilderDialogProps, 'report'> &
  Omit<
    ComponentProps<typeof FormProvider<ReportConfigV2, unknown>>,
    'children' | 'trigger' | 'reset' | 'unregister'
  >) {
  const { reportConfigs: existingInventoryReports } =
    useReportConfigsV2<ReportTypesV2.InventoryV2>({
      reportType: ReportTypesV2.InventoryV2,
    });
  const { reportConfigs: existingSaleReports } =
    useReportConfigsV2<ReportTypesV2.SaleV2>({
      reportType: ReportTypesV2.SaleV2,
    });

  const requiredMsg = useContent(ContentId.Required);
  const duplicateNameMsg = useContent(ContentId.DuplicatedReportName);

  const nameError = formState.errors.reportName?.message;
  const filtersError = formState.errors.request?.filters?.message;

  const reportId = watch('reportId');
  const reportName = watch('reportName');
  const reportType = watch('reportType');

  const existingReportNames = useMemo(() => {
    return existingInventoryReports
      ?.concat(existingSaleReports ?? [])
      .filter((r) => r.reportId !== reportId)
      .map((r) => r.reportName);
  }, [existingInventoryReports, existingSaleReports, reportId]);

  const onSubmit = useCallback(() => {
    let hasErrors = false;
    if (!reportName) {
      setError('reportName', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (
      existingReportNames.some(
        (name) => name.toLocaleUpperCase() === reportName.toLocaleUpperCase()
      )
    ) {
      setError(
        'reportName',
        { message: duplicateNameMsg },
        { shouldFocus: true }
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      handleSubmit(onSave)();
    }
  }, [
    reportName,
    existingReportNames,
    setError,
    requiredMsg,
    duplicateNameMsg,
    handleSubmit,
    onSave,
  ]);

  return (
    <>
      <GenericDialog
        {...rest}
        size="md"
        header={
          <Content
            id={
              reportId == null
                ? ContentId.NewCustomReport
                : ContentId.EditReport
            }
          />
        }
        footer={
          <Button
            style={{
              width: '100%',
              margin: `${vars.spacing.m} ${vars.spacing.lg}`,
            }}
            variant={'regular'}
            onClick={onSubmit}
            disabled={formState.isSubmitting}
          >
            {reportId == null ? (
              <Content id={ContentId.BuildReport} />
            ) : (
              <Content id={ContentId.Save} />
            )}
          </Button>
        }
        onCancel={onClosed}
      >
        <div className={styles.reportBuilderDialogContainer}>
          <FieldWrapper>
            <PosFormField errors={nameError} label={<></>}>
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(nameError),
                }}
                disabled={formState.isSubmitting}
                {...register('reportName')}
                spellCheck={false}
                trimOnBlur={true}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField label={<></>}>
              <RadioGroup
                disabled={
                  formState.isSubmitting ||
                  // We don't support changing the report type of an existing report yet
                  reportId != null
                }
                style={{ width: '100%' }}
                onValueChange={(value) => {
                  const newValue = value as ReportTypesV2;
                  if (newValue === reportType) {
                    // Nothing to do
                    return;
                  }

                  if (formState.defaultValues?.reportType === newValue) {
                    const defaultValues =
                      formState.defaultValues as ReportConfigV2;
                    const defaultRequest = defaultValues.request;
                    // Restore filters and metrics
                    setValue(
                      'request.aggregations',
                      defaultRequest.aggregations
                    );
                    setValue(
                      'request.columnGroupings',
                      defaultRequest.columnGroupings
                    );
                    setValue(
                      'request.rowGroupings',
                      defaultRequest.rowGroupings
                    );
                    setValue('request.filters', defaultRequest.filters);
                    setValue(
                      'hiddenFilterItemIds',
                      defaultValues.hiddenFilterItemIds
                    );
                    setValue(
                      'viewableFilterItemIds',
                      defaultValues.viewableFilterItemIds
                    );
                    setValue(
                      'editableFilterItemIds',
                      defaultValues.editableFilterItemIds
                    );
                  } else {
                    // Clear filters and metrics
                    setValue('request.aggregations', []);
                    setValue('request.columnGroupings', []);
                    setValue('request.rowGroupings', []);
                    setValue('hiddenFilterItemIds', []);
                    setValue('viewableFilterItemIds', []);
                    setValue('editableFilterItemIds', []);

                    if (newValue === ReportTypesV2.InventoryV2) {
                      setValue('request.filters', DefaultReportV2ListingQuery);
                    } else {
                      // SaleV2
                      setValue('request.filters', DefaultReportV2SaleQuery);
                    }
                  }
                  setValue('reportType', newValue);
                }}
                value={reportType}
              >
                <Stack direction="column" gap="m">
                  <Radio
                    value={ReportTypesV2.InventoryV2}
                    containerClassName={styles.reportTypeLabel}
                    disabled={
                      formState.isSubmitting ||
                      // We don't support changing the report type of an existing report yet
                      reportId != null
                    }
                    label={
                      <Stack direction="column">
                        <span>
                          <Content id={ContentId.Listings} />
                        </span>
                        <span className={styles.reportTypeSubLabel}>
                          <Content id={ContentId.InventoryReportDescription} />
                        </span>
                      </Stack>
                    }
                    variant="outline"
                  />
                  <Radio
                    value={ReportTypesV2.SaleV2}
                    containerClassName={styles.reportTypeLabel}
                    disabled={
                      formState.isSubmitting ||
                      // We don't support changing the report type of an existing report yet
                      reportId != null
                    }
                    label={
                      <Stack direction="column">
                        <span>
                          <Content id={ContentId.Sales} />
                        </span>
                        <span className={styles.reportTypeSubLabel}>
                          <Content id={ContentId.SaleReportDescription} />
                        </span>
                      </Stack>
                    }
                    variant="outline"
                  />
                </Stack>
              </RadioGroup>
            </PosFormField>
          </FieldWrapper>

          <Stack direction="column">
            <PosFormField errors={filtersError} label={<></>} />
            <div>
              <FilterInput disabled={formState.isSubmitting} />
            </div>
          </Stack>
        </div>
      </GenericDialog>
    </>
  );
}

const FilterInput = ({ disabled }: { disabled?: boolean }) => {
  const { watch } = useFormContext<ReportConfigV2>();
  const reportType = watch('reportType');

  return (
    <FilterInputContextWrapper>
      <ReportBuilderFilterInputV2
        reportType={reportType}
        direction="column"
        filtersListHeader={
          <span className={shared.typography.body1}>
            <Content id={ContentId.FilterReportOptional} />
          </span>
        }
        addFilterDropdownProps={{
          triggerShape: 'pill',
          triggerVariant: 'outline',
          triggerClassName: styles.addFilterButton,
        }}
        disabled={disabled}
      />
    </FilterInputContextWrapper>
  );
};

const FilterInputContextWrapper = ({ children }: PropsWithChildren<object>) => {
  const { watch } = useFormContext<ReportConfigV2>();
  const reportType = watch('reportType');
  const reportFilter = watch('request.filters');

  if (reportType === ReportTypesV2.InventoryV2) {
    return (
      <FilterQueryContextProvider<UIReportV2ListingQuery>
        initialQuery={reportFilter as UIReportV2ListingQuery}
        emptyQuery={EmptyReportV2ListingQuery}
      >
        {children}
      </FilterQueryContextProvider>
    );
  }

  return (
    <FilterQueryContextProvider<UIReportV2SaleQuery>
      initialQuery={reportFilter as UIReportV2SaleQuery}
      emptyQuery={EmptyReportV2SaleQuery}
    >
      {children}
    </FilterQueryContextProvider>
  );
};
