import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';

import { SidePanelTab } from '../../../MarketListings/MarketListingHeader';
import * as styles from './DetailPanelBody.css';
import { ListingDetailPanel } from './ListingDetailPanel';
import { NotesDetailPanel } from './NotesDetailPanel';
import { PurchaseDetailPanel } from './PurchaseDetailPanel';
import { SaleDetailPanel } from './SaleDetailPanel';

type DetailPanelBodyProps = {
  useVerticalView: boolean;
};

export const DetailPanelBody: React.FC<DetailPanelBodyProps> = ({
  useVerticalView,
}) => {
  const { selectedTab } = useInventoryEventDetailContext();

  const renderBody = () => {
    switch (selectedTab) {
      case SidePanelTab.LISTINGS:
        return <ListingDetailPanel useVerticalView={useVerticalView} />;
      case SidePanelTab.SALES:
        return <SaleDetailPanel />;
      case SidePanelTab.PURCHASES:
        return <PurchaseDetailPanel />;
      case SidePanelTab.NOTES:
        return <NotesDetailPanel />;
    }
  };

  return <div className={styles.bodyContainer}>{renderBody()}</div>;
};
