import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { ReportExportOptionsV2 } from 'src/components/Reports/ReportsExport/ReportsExport.types';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { vars } from 'src/core/themes';
import { DropdownMenu, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useReportPermissions } from 'src/hooks/useReportPermissions';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CopyIcon } from 'src/svgs';
import { ReportEditTableIcon } from 'src/svgs/ReportEditTableIcon';
import {
  IconsFill,
  MoreIcon,
  ProcessingIcon,
  ShareIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Feature, Permission } from 'src/WebApiController';

import { ReportsInventoryExportV2 } from '../ReportsExport/ReportsInventoryExportV2';
import { ReportsSaleExportV2 } from '../ReportsExport/ReportsSaleExportV2';
import * as styles from './ReportsPageToolbar.css';

export const ReportsPageToolbarV2 = ({
  reportConfig,
  isEditTable,
  setIsEditTable,
}: {
  reportConfig: ReportConfigV2 | undefined;
  isEditTable: boolean;
  setIsEditTable: (isEditTable: boolean) => void;
}) => {
  const shareDialog = useBasicDialog();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { filterQuery } = useFilterQueryContext<QueryWithViewMode>();

  const { canUpdateReport } = useReportPermissions(reportConfig);
  const hasExportInventoryFeature = useUserHasFeature(Feature.ExportInventory);
  const hasReportingV2Feature = useUserHasFeature(Feature.ReportingV2);
  const hasCreateReportFeature = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );

  const [reportExportOptions, setReportExportOptions] =
    useState<ReportExportOptionsV2 | null>(null);

  const onReportExported = () => {
    setReportExportOptions(null);
  };

  const reportConfigWithTempQuery = useMemo(() => {
    if (!reportConfig) {
      return reportConfig;
    }
    return {
      ...reportConfig,
      request: {
        ...reportConfig.request,
        filters: filterQuery,
      },
    } as ReportConfigV2;
  }, [filterQuery, reportConfig]);

  const {
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });

  const {
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigsV2<ReportTypesV2.SaleV2>({
    reportType: ReportTypesV2.SaleV2,
  });

  const invokeUpsertReport = useCallback(
    async (report: ReportConfigV2) => {
      return await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypesV2.InventoryV2) {
            return await upsertReportConfigInventory(report.reportId, report);
          } else {
            // report.reportType === ReportTypesV2.SaleV2
            return await upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportClient.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...reportConfig!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await invokeUpsertReport(updatedReport);
      shareDialog.closeDialog();
    },
    [reportConfig, invokeUpsertReport, shareDialog]
  );

  const onReportDuplicateSubmit = useCallback(async () => {
    const newReport = {
      ...reportConfigWithTempQuery!,
      reportId: null,
      reportName:
        reportConfig!.reportType === ReportTypesV2.InventoryV2
          ? getReportConfigNameForDuplicateInventory(reportConfig!.reportName)
          : getReportConfigNameForDuplicateSale(reportConfig!.reportName),
    } as ReportConfigV2;
    const newReportId = await invokeUpsertReport(newReport);
    if (newReportId) {
      if (reportConfig!.reportType === ReportTypesV2.InventoryV2) {
        window.open(
          `/reports/inventory/${newReportId}`,
          '_blank',
          'noreferrer'
        );
      } else {
        // report.reportType === ReportTypesV2.SaleV2
        window.open(`/reports/sale/${newReportId}`, '_blank', 'noreferrer');
      }
    }
  }, [
    reportConfigWithTempQuery,
    reportConfig,
    getReportConfigNameForDuplicateInventory,
    getReportConfigNameForDuplicateSale,
    invokeUpsertReport,
  ]);

  return (
    <>
      <div className={styles.toolbar}>
        {reportConfig?.reportName ? (
          <h1 className={styles.pageName}>{reportConfig.reportName}</h1>
        ) : null}
        <Stack gap="l">
          {Boolean(!isEditTable && canUpdateReport) && (
            <ButtonWithIcon
              variant="outline"
              shape="rect"
              icon={<ReportEditTableIcon size={vars.iconSize.s} />}
              textContentId={ContentId.EditTable}
              onClick={() => setIsEditTable(true)}
            />
          )}
          {reportConfig &&
            hasReportingV2Feature && // If they come from V1, we don't allow any actions yet
            reportConfig.reportType != ReportTypesV2.Commission && (
              <>
                {reportExportOptions ? (
                  <RotatingWrapper>
                    <ProcessingIcon
                      fill={IconsFill.textBrand}
                      size={vars.iconSize.l}
                    />
                  </RotatingWrapper>
                ) : (
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <div style={{ padding: '0 8px 0 4px' }}>
                        <MoreIcon
                          withHoverEffect
                          fill={IconsFill.textBrand}
                          align="middle"
                        />
                      </div>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                      <DropdownMenu.Content align="end">
                        {reportConfig.isOwner && (
                          <>
                            <DropdownMenu.Item
                              key="ShareReport"
                              onClick={shareDialog.launchDialog}
                            >
                              <Stack alignItems="center" gap="m" width="full">
                                <ShareIcon
                                  withHoverEffect
                                  fill={IconsFill.textBrand}
                                  size={vars.iconSize.m}
                                />
                                <Content id={ContentId.Share} />
                              </Stack>
                            </DropdownMenu.Item>
                          </>
                        )}
                        {/* {(hasExportInventoryFeature ||
                          reportConfig.request.rowGroupings?.some(
                            (g) =>
                              g.columnName ===
                              ListingReportTableColumnId.ListingId
                          )) && (
                          <DropdownMenu.Group>
                            <DropdownMenu.Label key="DownloadReport">
                              <Stack alignItems="center" gap="m" width="full">
                                <DownloadIcon
                                  withHoverEffect
                                  fill={IconsFill.textBrand}
                                  size={vars.iconSize.m}
                                />
                                <Content id={ContentId.Download} />
                              </Stack>
                            </DropdownMenu.Label>
                            <DropdownMenu.Item
                              key="DownloadCsv"
                              onClick={() =>
                                setReportExportOptions({
                                  reportToExport: reportConfigWithTempQuery!,
                                  fileType: ReportFileType.CSV,
                                })
                              }
                            >
                              <Content id={ContentId.CommaSeparatedValuesExt} />
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              key="DownloadExcel"
                              onClick={() =>
                                setReportExportOptions({
                                  reportToExport: reportConfigWithTempQuery!,
                                  fileType: ReportFileType.XLSX,
                                })
                              }
                            >
                              <Content id={ContentId.MicrosoftExcelExt} />
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator />
                          </DropdownMenu.Group>
                        )} */}
                        {hasCreateReportFeature && (
                          <DropdownMenu.Item
                            key="Duplicate"
                            onClick={onReportDuplicateSubmit}
                          >
                            <Stack alignItems="center" gap="m" width="full">
                              <CopyIcon
                                withHoverEffect
                                fill={vars.color.textBrand}
                                size={vars.iconSize.l}
                              />
                              <Content id={ContentId.Duplicate} />
                            </Stack>
                          </DropdownMenu.Item>
                        )}
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                )}
              </>
            )}
        </Stack>
      </div>
      <ShareReportDialog
        {...shareDialog.dialogProps}
        reportOwnerName={reportConfig?.ownerDisplayName ?? ''}
        reportName={reportConfig?.reportName ?? ''}
        roleIdsShared={reportConfig?.roleIdsToShare ?? []}
        sellerUserIdsShared={reportConfig?.sellerUserIdsToShare ?? []}
        onOkay={onReportShareSubmit}
        onClosed={() => {
          shareDialog.closeDialog();
        }}
        centered
      />
      {reportConfig && (
        <>
          {reportConfig.reportType === ReportTypesV2.InventoryV2 ? (
            <ReportsInventoryExportV2
              reportExportOptions={reportExportOptions}
              onReportExported={onReportExported}
            />
          ) : (
            <ReportsSaleExportV2
              reportExportOptions={reportExportOptions}
              onReportExported={onReportExported}
            />
          )}
        </>
      )}
    </>
  );
};
