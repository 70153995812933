import clsx from 'clsx';
import { MinRowsForUsingVirtuoso } from 'src/components/Accordions';
import { EventSaleBody } from 'src/components/Accordions/SalesEventsAccordion/EventSaleBody';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { multiActionToolBarNoHorizontalPadding } from 'src/components/Events/EventPage/EventPage.css';
import { SalesBulkActionsPermissions } from 'src/components/Sales/BulkActions/SalesBulkActionsPermissions';
import { SalesMonthlyBulkActions } from 'src/components/Sales/BulkActions/SalesEventBulkActions/SalesEventBulkActions';
import { Content } from 'src/contexts/ContentContext';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData, Sale } from 'src/WebApiController';

import {
  EventAccordionItemProps,
  EventsAccordion,
  VirtualizedEventAccordionProps,
} from '../EventsAccordion';
import { EventSaleHeader } from './EventSaleHeader';

export function translateEventToSaleAccordionItem(
  props: EventWithData,
  index: number,
  noPadding?: boolean
): EventAccordionItemProps<Sale> {
  return {
    index,
    itemId: props.event.viagVirtualId,
    HeaderComponent: EventSaleHeader,
    BodyComponent: EventSaleBody,
    AfterHeaderComponent: () => (
      <SalesBulkActionsPermissions>
        <MultiSelectActionBar
          groupId={props.event.viagVirtualId}
          className={clsx({
            [multiActionToolBarNoHorizontalPadding]: noPadding,
          })}
        >
          <SalesMonthlyBulkActions event={props.event} />
        </MultiSelectActionBar>
      </SalesBulkActionsPermissions>
    ),
    usingVirtuoso: (props.counts.salesCnt ?? 0) > MinRowsForUsingVirtuoso,
    ...props,
    entities: props.entities.sales ?? [],
    entityCount: props.counts.salesCnt ?? 0,
  };
}

export function SalesEventsAccordion(
  props: VirtualizedEventAccordionProps<Sale>
) {
  return <EventsAccordion {...props} />;
}

export function SaleEventsEmpty() {
  return (
    <EmptySectionContent.Root
      icon={
        <EmptySectionContent.SolidIconContainer>
          <SearchSolidIcon />
        </EmptySectionContent.SolidIconContainer>
      }
    >
      <EmptySectionContent.Label>
        <Content id={ContentId.NoResultFound} />
      </EmptySectionContent.Label>
      <EmptySectionContent.DetailMessage>
        <Content id={ContentId.NoEventsMatching} />
      </EmptySectionContent.DetailMessage>
    </EmptySectionContent.Root>
  );
}
