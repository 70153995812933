import { EventAccordionItemProps } from 'src/components/Accordions';
import { useAppContext } from 'src/contexts/AppContext';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { Listing, Sale } from 'src/WebApiController';

import * as styles from './EventListing.css';
import { InventoryEventListing } from './InventoryEventListing';
import { SalesEventListing } from './SalesEventListing';

export function EventListing<T extends Listing | Sale>({
  onItemsRendered,
}: {
  onItemsRendered?: (items: EventAccordionItemProps<T>[]) => void;
}) {
  const { mainRoute } = useAppContext();

  return (
    <div className={styles.eventsContainer}>
      {mainRoute === MainRoute.Inventory ? (
        <InventoryEventListing
          onItemsRendered={(items) =>
            onItemsRendered?.(items as unknown as EventAccordionItemProps<T>[])
          }
        />
      ) : (
        <SalesEventListing
          onItemsRendered={(items) =>
            onItemsRendered?.(items as unknown as EventAccordionItemProps<T>[])
          }
        />
      )}
    </div>
  );
}
