import {
  ColumnDef,
  functionalUpdate,
  GroupingState,
  InitialTableState,
  PaginationState,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { NoData, Table, VirtuosoScrollEvent } from 'src/tables/Table';
import {
  defaultEventsColumnsConfig,
  filterColumnsByFeatures,
  getEventsColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  EntityWithTicketsQuery,
  EventWithData,
  Feature,
  ListingDetailedMetrics,
  UserSetting,
} from 'src/WebApiController';

import { isCustomEventsColumn } from '../../utils/columns/events/eventsCustomColumnUtils';
import {
  customColumnDef,
  EVENTS_TABLE_COLUMNS_CONFIG,
  EventWithDataAndMetrics,
  tagColumnDef,
} from './configs/EventsTableColumnsConfig';

export type EventsTableProps = {
  events: EventWithData[];
  highlightedId?: string;
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  useVirtuoso?: boolean;
  usePagination?: boolean;
  useDataGrouping?: boolean;
  withOuterPadding?: boolean;
  onScroll?: (virtuosoScrollEvent: VirtuosoScrollEvent) => void;
  tableHeadStyle?: CSSProperties;
};

const PAGE_SIZE = 100;

const getRowId = (originalRow: EventWithDataAndMetrics | null): string => {
  return (originalRow as EventWithDataAndMetrics)?.event.viagVirtualId ?? '';
};

export const EventsTable = ({
  events,
  highlightedId,
  failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
  useVirtuoso,
  usePagination = true,
  useDataGrouping,
  withOuterPadding,
  onScroll,
  tableHeadStyle,
}: EventsTableProps) => {
  const eventsText = useContent(ContentId.Events);
  const { appContext, loginContext } = useAppContext();
  const { getSelection, selectionMode, setGroupStates } =
    useMultiSelectionContext();
  const listingSelection = getSelection();
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { eventDetailedMetrics: detailedMetrics } = useCatalogMetricsContext();

  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  const { data: catalogData } = useCatalogDataContext();

  const singlePerformer = filterQuery.performerIds?.length === 1;

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const [grouping, setGrouping] = useState<GroupingState>(
    initState?.grouping || []
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const data = useMemo(
    () =>
      events.map((event) => {
        const metrics = detailedMetrics?.[
          event.event.viagVirtualId
        ] as ListingDetailedMetrics;
        const { performer, venue } = getPerformerAndVenueForEvent(
          event.event,
          catalogData
        );
        return {
          ...event,
          performer,
          venue,
          isSinglePerformerView: singlePerformer,
          metrics,
        };
      }),
    [catalogData, detailedMetrics, events, singlePerformer]
  );

  // TODO: handle main columns filter
  const { value: storedEventsColumnOrderSetting = defaultEventsColumnsConfig } =
    useServerUserSetting<EventsTableColumnId[]>({
      id: UserSetting.EventsColumnOrder,
    });
  const { value: storedEventsColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: UserSetting.EventsColumnsEnabled,
  });
  const { value: customEventsColumns = [] } = useServerUserSetting<
    CustomEventsColumn[]
  >({
    id: UserSetting.EventsCustomColumns,
  });

  const { tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const displayedColumnsConfig = useMemo(() => {
    const eventsColumnOrder = storedEventsColumnOrderSetting.filter(
      // If storedEventsColumnsEnabledSetting has never been set, default to true
      (c) => storedEventsColumnsEnabledSetting?.includes(c) ?? true
    );

    if (
      !hasTablePinActionColumnFeature &&
      !eventsColumnOrder.includes(EventsTableColumnId.Action)
    ) {
      eventsColumnOrder.push(EventsTableColumnId.Action);
    }
    const columns = new Set([
      // Add unconfigurable columns first
      ...Object.values(EVENTS_TABLE_COLUMNS_CONFIG)
        .filter((column) => {
          const columnDef = getEventsColumnConfigById(
            column.id as EventsTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          let isConfigurable = columnDef.isConfigurable;
          if (
            !hasTablePinActionColumnFeature &&
            column.id === EventsTableColumnId.Action
          ) {
            isConfigurable = true;
          }

          return !isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as EventsTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        eventsColumnOrder,
        SectionType.Events,
        customEventsColumns,
        loginContext?.user,
        appContext?.features
      ),
    ]);

    return Array.from(columns).reduce<
      ColumnDef<EventWithDataAndMetrics | null>[]
    >((acc, columnId) => {
      if (tagsMetadata?.find((t) => t.key === columnId)) {
        acc.push(tagColumnDef(columnId));
      } else if (isCustomEventsColumn(columnId)) {
        acc.push(
          customColumnDef(
            columnId,
            customEventsColumns.find((c) => c.id === columnId)?.formula
          )
        );
      } else {
        const columnConfig = Object.values(EVENTS_TABLE_COLUMNS_CONFIG).find(
          (column) => column.id === columnId
        );
        if (columnConfig) acc.push(columnConfig);
      }

      return acc;
    }, []);
  }, [
    appContext?.features,
    customEventsColumns,
    hasTablePinActionColumnFeature,
    loginContext?.user,
    storedEventsColumnOrderSetting,
    storedEventsColumnsEnabledSetting,
    tagsMetadata,
  ]);

  const rowSelection = useMemo(() => {
    if (!selectionMode && highlightedId) {
      return {
        [highlightedId]: true,
      };
    }
    return listingSelection.groupIds.reduce<Record<string, boolean>>(
      (acc, eventId) => {
        acc[eventId] = true;
        return acc;
      },
      {}
    );
  }, [highlightedId, listingSelection.groupIds, selectionMode]);

  const onRowSelectionChanged = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );
      if (!selectionMode) {
        newSelectedIds.forEach((id) => {
          if (rowSelection[id] && newSelections[id]) {
            // if both of these are the same value and is true, we need to set it to false
            newSelections[id] = false;
          }
        });
      }
      setGroupStates(newSelections);
    },
    [rowSelection, selectionMode, setGroupStates]
  );

  const showCheckbox = selectionMode?.mode === MultiSelectScope.AllGroups;

  const options: Partial<TableOptions<EventWithDataAndMetrics | null>> =
    useMemo(
      () => ({
        data,
        columns: displayedColumnsConfig,
        state: {
          expanded: useDataGrouping ? true : undefined,
          grouping,
          pagination: usePagination ? pagination : undefined,
          sorting,
          rowSelection,
          columnVisibility: {
            checkbox: showCheckbox,
          },
          columnPinning: {
            right: hasTablePinActionColumnFeature
              ? [EventsTableColumnId.Action]
              : undefined,
          },
        },
        onGroupingChange: setGrouping,
        getRowId: getRowId,
        onRowSelectionChange: onRowSelectionChanged,
        onPaginationChange: usePagination ? setPagination : undefined,
        onSortingChange: (sortingUpdaterFn: any) => {
          const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
          setSorting(newSortVal);
        },
        enableRowSelection: showCheckbox,
        enableMultiRowSelection: showCheckbox,
        enableGrouping: !!useDataGrouping,
      }),
      [
        data,
        displayedColumnsConfig,
        useDataGrouping,
        grouping,
        usePagination,
        pagination,
        sorting,
        rowSelection,
        showCheckbox,
        hasTablePinActionColumnFeature,
        onRowSelectionChanged,
      ]
    );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : data?.length > 0 ? (
    <Table
      options={options}
      tableLayout="fixed"
      tableHeadStyle={{ top: '56px', ...tableHeadStyle }}
      useVirtuoso={useVirtuoso}
      usePaginationFooter={usePagination}
      useDataGrouping={useDataGrouping}
      withOuterPadding={withOuterPadding}
      onVirtuosoTableScroll={onScroll}
    />
  ) : (
    // We should never see this - because the events are filtered to only those that has sales
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent id={FormatContentId.NoDataAvailable} params={eventsText} />
    </NoData>
  );
};
