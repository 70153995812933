import { Updater } from '@tanstack/react-table';

export type CustomColumnWidthsType = Record<string, number>;

export type ColumnResizingContextType = {
  isResizeEnabled: boolean;
  customColumnWidths: CustomColumnWidthsType;
  onColumnSizingChange?: (x: Updater<CustomColumnWidthsType>) => void;
};

export const COLUMN_DEFAULT_SIZE_XS = 12;
export const COLUMN_DEFAULT_SIZE_ICON = 40;
export const COLUMN_DEFAULT_SIZE_SM = 50;
export const COLUMN_DEFAULT_SIZE_BASE = 70;
export const COLUMN_DEFAULT_SIZE_LG = 130;
export const COLUMN_DEFAULT_SIZE_L = 180;
export const COLUMN_DEFAULT_SIZE_XL = 220;
export const COLUMN_DEFAULT_SIZE_XXL = 300;
export const COLUMN_MIN_SIZE = 45;
export const COLUMN_MAX_SIZE = 750;
