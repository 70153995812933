import { useCallback, useState } from 'react';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PRICE_UPDATED_DATE_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  Feature,
  ListingQuery,
} from 'src/WebApiController';

import { StackedFilter } from './StackedFilter';

export enum PriceUpdatedFilterEnum {
  PriceUpdatedDate = 'PriceUpdatedDate',
  PriceNotUpdatedDate = 'PriceNotUpdatedDate',
}

export const PriceUpdatedDateStackedFilter = () => {
  const { tempQuery, setTempQuery } = useFilterQueryContext<ListingQuery>();
  const [mode, setMode] = useState<PriceUpdatedFilterEnum>(() => {
    // Set the initial mode based on tempQuery
    return tempQuery.lastPriceUpdateDates
      ? PriceUpdatedFilterEnum.PriceUpdatedDate
      : tempQuery.lastPriceNoUpdateDates
      ? PriceUpdatedFilterEnum.PriceNotUpdatedDate
      : PriceUpdatedFilterEnum.PriceUpdatedDate;
  });

  const setDateFilter = useCallback(
    (mode: PriceUpdatedFilterEnum, date: DateTimeRangeWithRelative | null) => {
      const newQuery: ListingQuery = {
        ...tempQuery,
        lastPriceUpdateDates:
          mode === PriceUpdatedFilterEnum.PriceUpdatedDate ? date : null,
        lastPriceNoUpdateDates:
          mode === PriceUpdatedFilterEnum.PriceNotUpdatedDate ? date : null,
      };

      setTempQuery(newQuery);
      setMode(mode);
    },
    [tempQuery, setTempQuery]
  );

  return (
    <StackedFilter
      FilterComponents={[
        <PosEnumSelect
          key="topFilter"
          value={mode}
          valueOptionsContent={PRICE_UPDATED_DATE_FILTER_TO_CID}
          enableEmptySelection={false}
          onChange={(mode) => {
            if (mode === PriceUpdatedFilterEnum.PriceUpdatedDate) {
              setDateFilter(mode, tempQuery['lastPriceUpdateDates']);
            } else if (mode === PriceUpdatedFilterEnum.PriceNotUpdatedDate) {
              setDateFilter(mode, tempQuery['lastPriceNoUpdateDates']);
            }
          }}
        />,
        <DateRangeSelector
          key="dateRangeSelector"
          useRelativePresets
          presetNames={StandardDateRangePresetNames}
          value={
            tempQuery['lastPriceUpdateDates'] ||
            tempQuery['lastPriceNoUpdateDates']
          }
          defaultValue={null}
          onBlur={(value) => {
            setDateFilter(mode, value);
          }}
          disabledDay={(d) =>
            getDisabledDatesForTimeFrameFilter(
              d,
              tempQuery.eventTimeFrameFilter
            )
          }
          isEndDateFixed
          displayTextStyle={{ maxWidth: '260px' }}
        />,
      ]}
    />
  );
};
