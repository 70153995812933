import React from 'react';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';

import { SidePanelTab } from '../../../MarketListings/MarketListingHeader';
import { CompPricingSettings } from '../PricingSettings/CompPricingSettings';
import * as styles from './MapFooter.css';
import { SelectedListingMapStats } from './SelectedListingMapStats';

type MapFooterProps = {
  useVerticalView: boolean;
};

export const MapFooter: React.FC<MapFooterProps> = ({ useVerticalView }) => {
  const { selectedListing, selectedTab } = useInventoryEventDetailContext();

  return (
    <div className={styles.footerContainer}>
      {selectedListing != null && selectedTab == SidePanelTab.LISTINGS ? (
        <CompPricingSettings useVerticalView={useVerticalView} />
      ) : (
        <SelectedListingMapStats />
      )}
    </div>
  );
};
