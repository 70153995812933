import { useFormContext } from 'react-hook-form';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import * as styles from 'src/modals/SaleDetails/SaleDetails.css';
import { SALE_CANCELLATION_REASON_CID } from 'src/utils/constants/contentIdMaps';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  Feature,
  PointOfSaleSaleStatus,
  SaleInput,
} from 'src/WebApiController';

interface SaleCancellationReasonProps {
  status: PointOfSaleSaleStatus | undefined | null;
}

export const SaleCancellationReason = ({
  status,
}: SaleCancellationReasonProps) => {
  const { watch, setValue } = useFormContext<SaleInput>();
  const userHasSaleCancellationReason = useUserHasFeature(
    Feature.SaleCancellationReason
  );

  const cancellationReason = watch('cancellationReason');

  const isRejectedState =
    status === PointOfSaleSaleStatus.Rejected ||
    status === PointOfSaleSaleStatus.PendingRejection;

  if (!isRejectedState || !userHasSaleCancellationReason) {
    return null;
  }

  return (
    <PosEnumSelect
      className={styles.cancellationReasonSelector}
      valueOptionsContent={SALE_CANCELLATION_REASON_CID}
      value={cancellationReason?.value}
      onChange={(selectedCancellationReason) => {
        setValue(
          'cancellationReason',
          posChangedField(selectedCancellationReason),
          {
            shouldDirty: true,
          }
        );
      }}
    />
  );
};
