import { ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { FormatOption } from 'src/modals/EditTableColumns';
import { TableShimmeringDiv } from 'src/tables/Table';
import { DateCell } from 'src/tables/Table/TableCells';
import { ContentId } from 'src/utils/constants/contentId';
import { toExperimentName } from 'src/utils/listingExperimentUtils';
import { ListingExperiment, ListingGroup } from 'src/WebApiController';

import { ActionCell } from './ActionCell';

export const getTableColumnDefinitions = (
  onStopExperiment: (listingGroupId: string | null) => Promise<void>,
  onResumeExperiment: (listingGroupId: string | null) => void,
  onRestartExperiment: (listingGroupId: string | null) => void,
  listingGroups: ListingGroup[]
): ColumnDef<ListingExperiment>[] => [
  {
    id: 'name',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.ExperimentName} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue() as ListingExperiment;
      if (!original) {
        return <TableShimmeringDiv />;
      }
      return (
        <TableCellDiv align="center" style={{ textTransform: 'none' }}>
          {toExperimentName(value)}
        </TableCellDiv>
      );
    },
  },
  {
    id: 'listingGroupId',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.Group} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data.listingGroupId,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue() as string | null;
      if (!original) {
        return <TableShimmeringDiv />;
      }
      const listingGroup = listingGroups.find(
        ({ ltGrpId }) => ltGrpId === value
      );
      return (
        <TableCellDiv align="center" style={{ textTransform: 'none' }}>
          {listingGroup ? listingGroup.groupName : ''}
        </TableCellDiv>
      );
    },
  },
  {
    id: 'startDate',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.StartDateTime} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data.startDate,
    cell: ({ getValue, row: { original } }) => {
      if (!original) {
        return <TableShimmeringDiv />;
      }
      const value = getValue() as string;
      const dateValue = value ? new Date(value).toISOString() : value;

      return (
        <DateCell
          precision={FormatOption.DateTime_MMDDYY}
          date={dateValue}
          enableUtcFallback
          hideRelativeTerms
          align="center"
          useSiteTimeZone={false}
        />
      );
    },
  },
  {
    id: 'endDate',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.EndDateTime} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data.endDate,
    cell: ({ getValue, row: { original } }) => {
      if (!original) {
        return <TableShimmeringDiv />;
      }
      const value = getValue() as string;
      const dateValue = value ? new Date(value).toISOString() : value;

      return (
        <DateCell
          precision={FormatOption.DateTime_MMDDYY}
          date={dateValue}
          enableUtcFallback
          hideRelativeTerms
          align="center"
          useSiteTimeZone={false}
        />
      );
    },
  },
  {
    id: 'carts',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.Carts} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data,
    cell: ({ row: { original } }) => {
      if (!original) {
        return <TableShimmeringDiv />;
      }
      return <TableCellDiv align="center">Coming Soon</TableCellDiv>;
    },
  },
  {
    id: 'sales',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.Sales} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data,
    cell: ({ row: { original } }) => {
      if (!original) {
        return <TableShimmeringDiv />;
      }
      return <TableCellDiv align="center">Coming Soon</TableCellDiv>;
    },
  },
  {
    id: 'discount',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.Discount} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data.discountPercentage,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue() as number;
      if (!original) {
        return <TableShimmeringDiv />;
      }
      if (!value) {
        return null;
      }
      return <TableCellDiv align="center">{`${value}%`}</TableCellDiv>;
    },
  },
  {
    id: 'actions',
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content id={ContentId.Actions} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => data,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue() as ListingExperiment;
      if (!original) {
        return <TableShimmeringDiv />;
      }
      return (
        <TableCellDiv align="center">
          <ActionCell
            experiment={value}
            onResumeExperiment={onResumeExperiment}
            onRestartExperiment={onRestartExperiment}
            onStopExperiment={onStopExperiment}
          />
        </TableCellDiv>
      );
    },
  },
];
