import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { FilterToolbarItemId } from 'src/components/Filters';
import { CurrencyFilterMultiSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { PurchaseDateRangePresetNames } from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  Feature,
  Marketplace,
  MarketplacePaymentQuery,
} from 'src/WebApiController';

export const useMarketplacePaymentsFilters = () => {
  const { loginContext } = useAppContext();
  const { tempQuery, setTempQuery } =
    useFilterQueryContext<MarketplacePaymentQuery>();

  const hasMarketplacePaymentFilterUnlinkedFeature = useUserHasFeature(
    Feature.MarketplacePaymentFilterUnlinked
  );

  return useMemo(() => {
    return {
      filters: [
        {
          titleContentId: ContentId.Payment,
          type: 'row',
          items: [
            {
              labelContentId: ContentId.PaymentDate,
              filterId: 'paymentDates' as FilterToolbarItemId,
              filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
              filterItem: (
                <DateRangeSelector
                  presetNames={PurchaseDateRangePresetNames}
                  value={tempQuery.paymentDates}
                  onBlur={(value) =>
                    setTempQuery({
                      ...tempQuery,
                      paymentDates: value,
                    })
                  }
                  useRelativePresets
                />
              ),
            },
            ...(hasMarketplacePaymentFilterUnlinkedFeature
              ? [
                  {
                    labelContentId: ContentId.HasUnmappedSales,
                    filterId:
                      'hasPaymentLineUnmappedToSale' as FilterToolbarItemId,
                    filterQueryKeys: [
                      'hasPaymentLineUnmappedToSale',
                    ] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={ToYesNoEnum(
                          tempQuery.hasPaymentLineUnmappedToSale
                        )}
                        placeholderText={ContentId.All}
                        enableEmptySelection
                        onChange={(yesNoEnumValue) => {
                          if (
                            yesNoEnumValue !==
                            ToYesNoEnum(tempQuery.hasPaymentLineUnmappedToSale)
                          ) {
                            setTempQuery({
                              ...tempQuery,
                              hasPaymentLineUnmappedToSale:
                                FromYesNoEnum(yesNoEnumValue),
                            });
                          }
                        }}
                        valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      />
                    ),
                  },
                  {
                    labelContentId: ContentId.HasUnlinkedChargesCredits,
                    filterId:
                      'hasPaymentLineUnmappedToSaleLineItem' as FilterToolbarItemId,
                    filterQueryKeys: [
                      'hasPaymentLineUnmappedToSaleLineItem',
                    ] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={ToYesNoEnum(
                          tempQuery.hasPaymentLineUnmappedToSaleLineItem
                        )}
                        placeholderText={ContentId.All}
                        enableEmptySelection
                        onChange={(yesNoEnumValue) => {
                          if (
                            yesNoEnumValue !==
                            ToYesNoEnum(
                              tempQuery.hasPaymentLineUnmappedToSaleLineItem
                            )
                          ) {
                            setTempQuery({
                              ...tempQuery,
                              hasPaymentLineUnmappedToSaleLineItem:
                                FromYesNoEnum(yesNoEnumValue),
                            });
                          }
                        }}
                        valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      />
                    ),
                  },
                ]
              : []),
            {
              filterId: 'marketplace' as FilterToolbarItemId,
              labelContentId: ContentId.Marketplace,
              filterQueryKeys: ['marketplace'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={tempQuery.marketplace}
                  placeholderText={ContentId.AllMerchants}
                  enableEmptySelection
                  onChange={(marketplace) => {
                    if (marketplace !== tempQuery.marketplace) {
                      setTempQuery({
                        ...tempQuery,
                        marketplace,
                      });
                    }
                  }}
                  valueOptionsContent={(
                    loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                      (m) => m.mkp
                    ) ?? [Marketplace.StubHub]
                  )
                    .concat(Marketplace.Offline)
                    .reduce(
                      (cur, m) => {
                        cur[m] = MARKET_PLACE_TO_CID[m];
                        return cur;
                      },
                      {} as Record<Marketplace, string>
                    )}
                />
              ),
            },
            {
              filterId: 'marketplacePaymentState' as FilterToolbarItemId,
              labelContentId: ContentId.PaymentState,
              filterQueryKeys: [
                'marketplacePaymentState',
              ] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={tempQuery.marketplacePaymentState}
                  enableEmptySelection
                  onChange={(marketplacePaymentState) => {
                    if (
                      marketplacePaymentState !==
                      tempQuery.marketplacePaymentState
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        marketplacePaymentState,
                      });
                    }
                  }}
                  valueOptionsContent={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID}
                />
              ),
            },
            {
              filterId: 'currencyCodes' as FilterToolbarItemId,
              labelContentId: ContentId.Currency,
              filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
              filterItem: (
                <CurrencyFilterMultiSelector
                  values={tempQuery.currencyCodes ?? []}
                  triggerProps={{ style: { width: '100%' } }}
                  placeholderText={ContentId.All}
                  onChange={(newCurrencyCodes) => {
                    if (!isEqual(tempQuery.currencyCodes, newCurrencyCodes)) {
                      setTempQuery({
                        ...tempQuery,
                        currencyCodes: newCurrencyCodes?.length
                          ? newCurrencyCodes
                          : null,
                      });
                    }
                  }}
                  enableEmptySelection
                />
              ),
            },
          ],
        },
      ],
    };
  }, [
    hasMarketplacePaymentFilterUnlinkedFeature,
    loginContext?.user?.activeAccount?.marketplaceSettings,
    setTempQuery,
    tempQuery,
  ]);
};
