import { HubConnectionState } from '@microsoft/signalr';
import { useQueryClient } from '@tanstack/react-query';
import { ComponentProps, useCallback, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { DownloadButton } from 'src/components/Buttons/DownloadButton';
import {
  bulkEditInProgress,
  BulkEditStatus,
} from 'src/components/common/BulkActions/BulkEditStatus';
import { PURCHASE_BULK_UPLOAD_PURCHASE_KEY } from 'src/components/Purchases/PurchaseActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY } from 'src/contexts/PurchaseDataContext/PurchaseDataContext.constants';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DnDFileUploader } from 'src/core/POS/DnDFileUploader';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import {
  ListingCatalogMetricsQueryKey,
  ListingCatalogQueryKey,
} from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditProgress,
  BulkEditPurchaseClient,
  BulkEditStep,
  Feature,
  PurchaseClient,
} from 'src/WebApiController';

import { BulkEditHeader } from '../common/BulkEditHeader';
import { RequestEventDetailsView } from './components/RequestEventDetailsView';

export type BulkUploadPurchaseDialogProps = ComponentProps<typeof RSModal> & {
  onCancel: () => void;
};

export function BulkUploadPurchaseDialog({
  onCancel,
  ...rest
}: BulkUploadPurchaseDialogProps) {
  const queryClient = useQueryClient();

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadeFile] = useState<File | undefined>();
  const [isRequestingEventDetails, setIsRequestingEventDetails] =
    useState(false);
  const { setActivePosEntity } = useActivePosEntityContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const onBulkEditProgressDone = useCallback(async () => {
    setActivePosEntity(0);
    queryClient.refetchQueries({
      queryKey: [ListingCatalogQueryKey],
      type: 'all',
    });
    queryClient.refetchQueries({
      queryKey: [ListingCatalogQueryKey],
      type: 'all',
    });
    queryClient.refetchQueries({
      queryKey: [ListingCatalogMetricsQueryKey],
      type: 'all',
    });
    queryClient.refetchQueries({
      queryKey: [
        PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY,
        activeAccountWebClientConfig.activeAccountId,
      ],
      type: 'all',
    });

    if (refreshData) {
      await refreshData(true);
    }
    if (refreshExpandedListItems) {
      await refreshCatalog();
      await refreshExpandedListItems();
    }
  }, [
    activeAccountWebClientConfig.activeAccountId,
    queryClient,
    refreshCatalog,
    refreshData,
    refreshExpandedListItems,
    setActivePosEntity,
  ]);

  const onCancelDialog = useCallback(async () => {
    setIsRequestingEventDetails(false);

    setIsRefreshing(true);
    setActivePosEntity(0);
    if (refreshData) {
      await refreshData(true);
    }
    if (refreshExpandedListItems) {
      await refreshCatalog();
      await refreshExpandedListItems();
    }
    setIsRefreshing(false);
    // progress kept to allow the BulkEditStatusPopover to show the result

    onCancel();
  }, [
    onCancel,
    refreshCatalog,
    refreshData,
    refreshExpandedListItems,
    setActivePosEntity,
  ]);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        onCancelDialog();
      }
    },
    [
      onCancelDialog,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        await onBulkEditProgressDone();
        onClose(doneProgress, true);
      }
    },
    [onBulkEditProgressDone, onClose]
  );

  const { bulkEditHub, progress, errors, warnings, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Purchase,
    BulkActionType.UploadPurchaseFile,
    PURCHASE_BULK_UPLOAD_PURCHASE_KEY,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;
  const hasBulkDownloadEventPromptFeature = useUserHasFeature(
    Feature.BulkDownloadEventPrompt
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onUploadPurchaseFile = useCallback(() => {
    if (!uploadedFile) {
      return;
    }

    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const result = await new BulkEditPurchaseClient(
          activeAccountWebClientConfig
        ).importPurchaseFromFile(
          PURCHASE_BULK_UPLOAD_PURCHASE_KEY,
          hasBackgroundBulkEditFeature,
          {
            data: uploadedFile,
            fileName: uploadedFile!.name,
          }
        );

        if (!hasBackgroundBulkEditFeature) {
          if (result.success) {
            onCancel();
          } else {
            showErrorDialog(
              'PurchaseClient.importPurchaseFromFile',
              {
                message: result.errors.join('\n'),
              },
              {
                trackErrorData: { uploadedFile },
              }
            );
          }
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.importPurchaseFromFile', error, {
          trackErrorData: { uploadedFile },
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    hasBackgroundBulkEditFeature,
    onCancel,
    showErrorDialog,
    uploadedFile,
  ]);

  const onDownloadTemplateClick = useCallback(() => {
    setIsRequestingEventDetails(true);
  }, []);

  const onDownloadTemplateClickNoPrompt = useCallback(() => {
    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const file = await new PurchaseClient(
          activeAccountWebClientConfig
        ).downloadPurchaseFileTemplate(true);

        if (file) {
          downloadFileFromBlob(
            file.fileName ?? 'purchase-creation-template.csv',
            file.data
          );
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.downloadPurchaseFileTemplate', error);
      },
      () => setIsLoading(false)
    );
  }, [activeAccountWebClientConfig, showErrorDialog]);

  return (
    <>
      <GenericDialog
        {...rest}
        size="md"
        onOpened={() => {
          initJob();
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.UploadPurchaseFile} />}
          />
        }
        footer={
          <>
            <CancelButton
              onClick={onCancelDialog}
              disabled={isLoading || isRefreshing}
              textContentId={
                hasBackgroundBulkEditFeature && progress
                  ? ContentId.Close
                  : ContentId.Cancel
              }
            />
            {(progress?.step !== BulkEditStep.Done ||
              Boolean(progress?.failureCount)) && (
              <Button
                disabled={
                  uploadedFile == null ||
                  isRefreshing ||
                  bulkEditInProgress(
                    hasBackgroundBulkEditFeature,
                    isLoading,
                    progress
                  )
                }
                onClick={() => {
                  onUploadPurchaseFile();
                }}
              >
                {progress?.failureCount ? (
                  <Content id={ContentId.Retry} />
                ) : (
                  <Content id={ContentId.Save} />
                )}
              </Button>
            )}
          </>
        }
        onCancel={isLoading ? undefined : onCancelDialog}
      >
        {hasBulkDownloadEventPromptFeature ? (
          <BulkEditStatus
            entityType={ActionOutboxEntityType.Purchase}
            isLoading={isLoading}
            updateKey={PURCHASE_BULK_UPLOAD_PURCHASE_KEY}
          >
            {isLoading && !isRequestingEventDetails ? (
              <PosSpinner />
            ) : (
              <Stack direction="column" gap="m">
                {isRequestingEventDetails ? (
                  <RequestEventDetailsView
                    isRequestingEventDetails={isRequestingEventDetails}
                    setIsRequestingEventDetails={setIsRequestingEventDetails}
                    setIsLoading={setIsLoading}
                  />
                ) : (
                  <DownloadButton
                    disabled={isRefreshing || isLoading}
                    variant={'outline'}
                    textContentId={ContentId.DownloadTemplate}
                    onClick={onDownloadTemplateClick}
                  />
                )}
                <DnDFileUploader
                  disabled={isRefreshing || isLoading}
                  onChange={(files) => files[0] && setUploadeFile(files[0])}
                  multiple={false}
                  acceptedFileTypes={['.csv']}
                />
              </Stack>
            )}
          </BulkEditStatus>
        ) : (
          <BulkEditStatus
            entityType={ActionOutboxEntityType.Purchase}
            isLoading={isRefreshing || isLoading}
            updateKey={PURCHASE_BULK_UPLOAD_PURCHASE_KEY}
          >
            {isLoading ? (
              <PosSpinner />
            ) : (
              <Stack direction="column" gap="m">
                <DownloadButton
                  disabled={isRefreshing || isLoading}
                  variant={'outline'}
                  textContentId={ContentId.DownloadTemplate}
                  onClick={onDownloadTemplateClickNoPrompt}
                />
                <DnDFileUploader
                  disabled={isRefreshing || isLoading}
                  onChange={(files) => files[0] && setUploadeFile(files[0])}
                  multiple={false}
                  acceptedFileTypes={['.csv']}
                />
              </Stack>
            )}
          </BulkEditStatus>
        )}
      </GenericDialog>
    </>
  );
}
