import { useMemo } from 'react';
import { useMeasure } from 'react-use';
import { MetricTile } from 'src/components/Metrics';
import { Content } from 'src/contexts/ContentContext';
import { SellerEventPerformanceMetrics } from 'src/contexts/EventMetricsContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { Stack } from 'src/core/ui';
import { MARKETPLACE_TO_BACKGROUND_COLOR } from 'src/utils/constants/colorMaps';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Marketplace,
  MarketplaceMarketShare,
  TicketPricePercentiles,
} from 'src/WebApiController';

import { DashboardWidgetTile } from '../DashboardWidgetTile/DashboardWidgetTile';
import {
  DistributionEntry,
  DistributionPieChartWidget,
} from '../DistributionPieChart';
import * as styles from './SellerEventPerformanceMetricWidgetsContainer.css';

const TicketsListedAndSoldWidget = ({
  performanceMetrics,
  isLoading,
}: {
  performanceMetrics: SellerEventPerformanceMetrics;
  isLoading: boolean;
}) => {
  const progressBarValue = isLoading
    ? 0
    : (performanceMetrics.totalSoldPercentage ?? 0) * 100;
  const progressBarLabel = isLoading
    ? undefined
    : performanceMetrics.formattedTotalSoldPercentage;

  return (
    <DashboardWidgetTile titleContentId={ContentId.TicketsListedAndSold}>
      <div className={styles.metricWithVisualContainer}>
        <MetricTile.CircleProgressBar size={64} value={progressBarValue}>
          {progressBarLabel}
        </MetricTile.CircleProgressBar>
        {isLoading ? (
          <ContentPlaceholder height="4rem" width="40px" />
        ) : (
          <MetricTile.Metric isCompact>
            <MetricTile.FractionMetricText
              numerator={performanceMetrics.formattedTotalSoldQuantity}
              denominator={performanceMetrics.formattedTotalTicketQuantity}
            />
          </MetricTile.Metric>
        )}
      </div>
    </DashboardWidgetTile>
  );
};

const SalesAndLiftWidget = ({
  performanceMetrics,
  isLoading,
}: {
  performanceMetrics: SellerEventPerformanceMetrics;
  isLoading: boolean;
}) => {
  const progressBarValue = isLoading
    ? 0
    : (performanceMetrics.totalGrossMargin ?? 0) * 100;
  const progressBarLabel = isLoading
    ? undefined
    : performanceMetrics.formattedTotalGrossMargin;

  return (
    <DashboardWidgetTile titleContentId={ContentId.SalesAndLift}>
      <div className={styles.metricWithVisualContainer}>
        <MetricTile.CircleProgressBar size={64} value={progressBarValue}>
          {progressBarLabel}
        </MetricTile.CircleProgressBar>
        {isLoading ? (
          <ContentPlaceholder height="4rem" width="80px" />
        ) : (
          <div className={styles.salesAndLiftValueContainer}>
            <MetricTile.Metric>
              {performanceMetrics?.formattedTotalNetProceeds}
            </MetricTile.Metric>
            <div className={styles.salesAndLiftProfitContainer}>
              <Content id={ContentId.Profit} />
              {': ' + performanceMetrics?.formattedProfitAndLoss}
            </div>
          </div>
        )}
      </div>
    </DashboardWidgetTile>
  );
};

const TempAverageTicketPriceWidget = ({
  ticketPricePercentiles,
  isLoading,
}: {
  ticketPricePercentiles: Partial<TicketPricePercentiles>;
  isLoading: boolean;
}) => {
  return (
    <DashboardWidgetTile titleContentId={ContentId.AverageTicketPrice}>
      {isLoading ? (
        <ContentPlaceholder height="2rem" width="80px" />
      ) : (
        <MetricTile.Metric showLoadingPlaceholders={isLoading}>
          {ticketPricePercentiles?.p50}
        </MetricTile.Metric>
      )}
    </DashboardWidgetTile>
  );
};

const ZERO_STUBHUB_MARKETPLACE_SHARE: MarketplaceMarketShare = {
  mktPlc: Marketplace.StubHub,
  share: 0,
};

const MarketShareDistributionChart = ({
  marketShares,
  isLoading,
}: {
  marketShares: MarketplaceMarketShare[];
  isLoading: boolean;
}) => {
  const [measureRef, container] = useMeasure<HTMLDivElement>();

  const marketplaceSharesToRender = useMemo(() => {
    if (isLoading) return [];
    const shares = marketShares;
    if (!shares.map((ms) => ms.mktPlc).includes(Marketplace.StubHub)) {
      shares.push(ZERO_STUBHUB_MARKETPLACE_SHARE);
    }
    return shares;
  }, [isLoading, marketShares]);

  return (
    <DashboardWidgetTile
      ref={measureRef}
      titleContentId={ContentId.MarketShare}
      type="ditribution"
    >
      <DistributionPieChartWidget
        titleContentId={ContentId.MarketShare}
        distributions={
          marketplaceSharesToRender.map<DistributionEntry>((ms) => {
            return {
              label: ms.mktPlc,
              value: ms.share,
              color: MARKETPLACE_TO_BACKGROUND_COLOR[ms.mktPlc],
            };
          }) ?? []
        }
        container={container}
      />
    </DashboardWidgetTile>
  );
};

export const SellerEventPerformanceMetricWidgetsBody = ({
  performanceMetrics,
  isLoading,
}: {
  performanceMetrics: SellerEventPerformanceMetrics;
  isLoading: boolean;
}) => {
  return (
    <Stack direction="responsive" gap="xl" width="full">
      <TicketsListedAndSoldWidget
        performanceMetrics={performanceMetrics}
        isLoading={isLoading}
      />

      <SalesAndLiftWidget
        performanceMetrics={performanceMetrics}
        isLoading={isLoading}
      />

      <TempAverageTicketPriceWidget
        ticketPricePercentiles={performanceMetrics.ticketPricePercentiles}
        isLoading={isLoading}
      />

      <MarketShareDistributionChart
        marketShares={performanceMetrics.marketShares}
        isLoading={isLoading}
      />
    </Stack>
  );
};
