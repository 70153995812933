import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';
import {
  FormProvider,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { vars } from 'src/core/themes';
import { PurchaseWizardFooter, WizardFooter } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import {
  PurchaseTicketsInput,
  validateTicketGroups,
} from 'src/utils/purchaseUtils';
import {
  DeliveryType,
  EventListingConstraints,
  ListingNote,
  SectionInfo,
  TicketGroupInput,
} from 'src/WebApiController';

import { PurchaseOrderInfoForTicketGroupInput } from '../PurchaseTicketsFlow/TicketGroupInput.types';
import { PurchaseTicketGroupHeader } from './PurchaseTicketGroupHeader';
import * as styles from './PurchaseTicketGroupPage.css';
import { PurchaseTicketGroupPageAdditionalInput } from './PurchaseTicketGroupPageAdditionalInput';
import { PurchaseTicketGroupVenueMap } from './PurchaseTicketGroupVenueMap';
import { useTicketGroupListingNotes } from './TicketGroupInputTable/hooks/useTicketGroupListingNotes';
import { TicketGroupInputTable } from './TicketGroupInputTable/TicketGroupInputTable';

const PurchaseTicketGroupPage = ({
  ticketGroups,
  listingConstraints,
  purchaseOrderInfo,
  onSubmitWrapper,
  startIndex,
  onNextFromTicketGroupInputStep,
  isSingleInputModeForMultiEvent,
  currentGroupIndex,
  currentGroupTicketsCount,
  onPrevFromTicketGroupInputStep,
  showPredeliverySection,
}: {
  ticketGroups: TicketGroupInput[];
  listingConstraints?: EventListingConstraints | null;
  onSubmitWrapper: () => void;
  purchaseOrderInfo: PurchaseOrderInfoForTicketGroupInput | null | undefined;
  startIndex: number;
  onNextFromTicketGroupInputStep: (tgs: TicketGroupInput[]) => void;
  isSingleInputModeForMultiEvent?: boolean;
  currentGroupIndex: number;
  currentGroupTicketsCount: number;
  onPrevFromTicketGroupInputStep: () => void;
  showPredeliverySection: boolean;
}) => {
  const { valueOptionsContent, listingNotesMap } = useTicketGroupListingNotes({
    listingConstraints,
  });

  const methods = useForm({
    defaultValues: {
      ticketGroups,
    },
  });

  const purchaseTicketGroupInputField = useFieldArray({
    control: methods.control,
    name: 'ticketGroups',
  });

  useEffect(() => {
    methods.reset({ ticketGroups });
  }, [methods, ticketGroups]);

  return (
    <EventMapContextProvider
      event={ticketGroups[0]?.event || null}
      doNotGetScoreOverrides
    >
      <FormProvider {...methods}>
        <div style={{ margin: vars.spacing.xl }}>
          <PurchaseTicketGroupPageInternal
            ticketGroups={ticketGroups}
            valueOptionsContent={valueOptionsContent}
            listingNotesMap={listingNotesMap}
            purchaseTicketGroupInputField={purchaseTicketGroupInputField}
            purchaseOrderInfo={purchaseOrderInfo}
            startIndex={startIndex}
            onSubmitWrapper={onSubmitWrapper}
            onNextFromTicketGroupInputStep={onNextFromTicketGroupInputStep}
            isSingleInputModeForMultiEvent={isSingleInputModeForMultiEvent}
            currentGroupIndex={currentGroupIndex}
            currentGroupTicketsCount={currentGroupTicketsCount}
            onPrevFromTicketGroupInputStep={onPrevFromTicketGroupInputStep}
            showPredeliverySection={showPredeliverySection}
          />
        </div>
      </FormProvider>
    </EventMapContextProvider>
  );
};

const PurchaseTicketGroupPageInternal = ({
  ticketGroups,
  listingNotesMap,
  valueOptionsContent,
  purchaseTicketGroupInputField,
  purchaseOrderInfo,
  onSubmitWrapper,
  startIndex,
  onNextFromTicketGroupInputStep,
  isSingleInputModeForMultiEvent,
  currentGroupIndex,
  currentGroupTicketsCount,
  onPrevFromTicketGroupInputStep,
  showPredeliverySection,
}: {
  ticketGroups: TicketGroupInput[];
  valueOptionsContent: Record<string, string>;
  listingNotesMap?: Record<string, ListingNote>;
  purchaseTicketGroupInputField: UseFieldArrayReturn<
    PurchaseTicketsInput,
    'ticketGroups',
    'id'
  >;
  purchaseOrderInfo: PurchaseOrderInfoForTicketGroupInput | null | undefined;
  onSubmitWrapper: () => void;
  startIndex: number;
  onNextFromTicketGroupInputStep: (tgs: TicketGroupInput[]) => void;
  isSingleInputModeForMultiEvent?: boolean;
  currentGroupIndex: number;
  currentGroupTicketsCount: number;
  onPrevFromTicketGroupInputStep: () => void;
  showPredeliverySection: boolean;
}) => {
  const { venueMapInfo } = useEventMapContext();
  const methods = useFormContext<PurchaseTicketsInput>();
  const requiredMsg = useContent(ContentId.Required);
  const duplicateSeatMsg = useContent(ContentId.DuplicateSeat);
  const eventRequiredMsg = useContent(ContentId.SearchAndSelectAnEvent);
  const inHandDateMustBeforeEventDateMsg = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );
  const autoBroadcastRequireFaceValueMsg = useContent(
    ContentId.AutoBroadcastRequiredFaceValue
  );

  const onNext = useCallback(() => {
    const formTicketGroups = methods.getValues().ticketGroups;
    formTicketGroups.forEach((tg, number) => {
      const inHandAt = methods.getValues(
        `ticketGroups.${number}.inHandDate.value`
      );
      const daysBeforeEvent = methods.getValues('daysBeforeEvent');

      if (!inHandAt && tg.event?.dates?.start) {
        const inHandDate = getInHandDateBasedOnEvent(tg.event, daysBeforeEvent);

        methods.setValue(
          `ticketGroups.${number}.inHandDate`,
          posChangedField(inHandDate)
        );
      }
    });
    const isValidTgs = validateTicketGroups(
      true,
      methods.clearErrors,
      methods.setError,
      formTicketGroups,
      requiredMsg,
      duplicateSeatMsg,
      inHandDateMustBeforeEventDateMsg,
      autoBroadcastRequireFaceValueMsg,
      true
    );
    if (!isValidTgs) {
      return;
    }
    onNextFromTicketGroupInputStep(formTicketGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, onNextFromTicketGroupInputStep]);

  const onSectionClicked = useCallback(
    (e: MouseEvent, sectionInfo: SectionInfo) => {
      const sectionId = sectionInfo.id;
      if (!venueMapInfo) {
        return;
      }
      const section = venueMapInfo.sections.find(
        (section) => section.id === sectionId
      );

      purchaseTicketGroupInputField.append({
        ...ticketGroups[0],
        tickets: posField(null),
        ticketGroupId: newBigIntId(),
        section: posField(section?.name || ''),
        sectionId: posField(sectionId),
        row: posField(null),
        listingNotes: posField(null),
        numberOfTickets: posField(null),
        unitCost: posField(null),
        totalCost: posField(null),
        faceValueCost: posField(null),
        expectedValue: posField(null),
        deliveryType: posField(DeliveryType.InApp),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [venueMapInfo, purchaseTicketGroupInputField]
  );

  return (
    <>
      <PurchaseTicketGroupHeader
        ticketGroups={ticketGroups}
        isSingleInputModeForMultiEvent={isSingleInputModeForMultiEvent}
        currentGroupIndex={currentGroupIndex}
        currentGroupTicketsCount={currentGroupTicketsCount}
      />
      <div className={styles.PurchaseTicketGroupGroupEventMapContainer}>
        <PurchaseTicketGroupVenueMap
          control={methods.control}
          onSectionClicked={onSectionClicked}
        />
      </div>
      <TicketGroupInputTable
        purchaseTicketGroupInputField={purchaseTicketGroupInputField}
        valueOptionsContent={valueOptionsContent}
        listingNotesMap={listingNotesMap}
        purchaseOrderInfo={purchaseOrderInfo}
      />
      <PurchaseTicketGroupPageAdditionalInput />
      <PurchaseWizardFooter
        className={styles.PurchaseTicketGroupFooter}
        hasChanges={
          !showPredeliverySection &&
          currentGroupIndex === startIndex &&
          !isEqual(methods.formState.defaultValues?.ticketGroups, ticketGroups)
        }
        onNext={onNext}
        onNextLabel={
          currentGroupIndex === ticketGroups.length - 1
            ? ContentId.Save
            : ContentId.Next
        }
        onPrevious={onPrevFromTicketGroupInputStep}
        onPreviousLabel={
          currentGroupIndex === startIndex ? ContentId.Cancel : ContentId.Back
        }
      />
    </>
  );
};

export default React.memo(PurchaseTicketGroupPage);
