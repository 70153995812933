import { Getter } from '@tanstack/react-table';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';

import { SHIMMERING_DIV_HEIGHT_REPORT } from './ReportTableCommon.constants';

/**
 * Use this component to render any cell with text, as long as getValue() returns string.
 * @param param0
 * @returns
 */
export function TextCell<T>({
  getValue,
  row: { original },
  align,
  style,
}: {
  getValue: Getter<string>;
  row: { original: T | null };
  align: 'left' | 'right' | 'center';
  style?: React.CSSProperties;
}) {
  if (!original) {
    return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
  }

  return (
    <TableCellDiv align={align} style={style}>
      {getValue()}
    </TableCellDiv>
  );
}
