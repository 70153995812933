import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMeasure } from 'react-use';
import {
  InventoryEventDetailContextProvider,
  useInventoryEventDetailContext,
} from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { Stack } from 'src/core/ui';
import { useGetListingAutoPricingSettings } from 'src/hooks/useGetListingAutoPricingSettings';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import {
  Event,
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
  ListingGroup,
} from 'src/WebApiController';

import { SidePanelTab } from '../MarketListings/MarketListingHeader';
import { DetailPanelBody } from './components/DetailPanel/DetailPanelBody';
import { MapBody } from './components/Map/MapBody';
import { MapFooter } from './components/Map/MapFooter';
import { MapHeader } from './components/Map/MapHeader';
import * as styles from './InventoryEventDetail.css';

export const InventoryEventDetail = ({
  event,
  eventListings,
  selectedListingIds,
}: {
  event: Event;
  eventListings?: Listing[] | null;
  selectedListingIds: number[];
}) => {
  const selectedListing = useMemo(() => {
    if (selectedListingIds.length <= 1) {
      let listing = eventListings?.find((l) => l.id === selectedListingIds[0]);
      if (!listing) {
        listing = eventListings
          ?.flatMap((l) => flattenListingGroup(l))
          .find((l) => l.isFull && l.id === selectedListingIds[0]) as Listing;
      }

      if (!listing?.isLtGrp) {
        return listing;
      }
    }
    return undefined;
  }, [eventListings, selectedListingIds]);

  const selectedGroup = useMemo(() => {
    return eventListings?.find((l) => {
      const lg = l as ListingGroup;

      return (
        lg.isLtGrp &&
        lg.groupType == 'Leader' &&
        selectedListingIds.includes(lg.id)
      );
    });
  }, [eventListings, selectedListingIds]);

  const {
    pricingSettings: listingPricingSettings,
    loaded: listingSettingLoaded,
  } = useGetListingAutoPricingSettings(selectedListing, event);

  const methods = useForm<ListingDetailsAutoPricingSectionUpdates>({
    defaultValues: {},
  });

  useEffect(() => {
    if (listingSettingLoaded) {
      methods.reset(listingPricingSettings ?? {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingPricingSettings, listingSettingLoaded]);

  return (
    <FormProvider {...methods}>
      <InventoryEventDetailContextProvider
        event={event}
        eventListings={eventListings}
        selectedListingIds={selectedListingIds}
        selectedListing={selectedListing}
        selectedGroup={selectedGroup}
      >
        <InventoryEventDetailContent />
      </InventoryEventDetailContextProvider>
    </FormProvider>
  );
};

export const InventoryEventDetailContent = () => {
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const { selectedTab, recenterMap } = useInventoryEventDetailContext();

  const shouldUseVertical = useMemo(() => {
    return (
      selectedTab === SidePanelTab.PURCHASES ||
      selectedTab === SidePanelTab.SALES ||
      width < 815
    );
  }, [selectedTab, width]);

  useEffect(() => {
    recenterMap();
  }, [recenterMap, shouldUseVertical]);

  return (
    <Stack
      ref={containerRef}
      width="full"
      height="full"
      gap="m"
      className={clsx(styles.detailContainer, {
        [styles.detailContainerVertical]: shouldUseVertical,
      })}
    >
      <Stack
        direction="column"
        gap="m"
        height="full"
        width="full"
        className={clsx(styles.mapContainer, {
          [styles.mapContainerVertical]: shouldUseVertical,
        })}
      >
        <MapHeader useVerticalView={shouldUseVertical} />
        <MapBody useVerticalView={shouldUseVertical} />
        {(selectedTab === SidePanelTab.LISTINGS ||
          selectedTab === SidePanelTab.NOTES) && (
          <MapFooter useVerticalView={shouldUseVertical} />
        )}
      </Stack>
      <Stack
        width="full"
        height="full"
        className={clsx(styles.tilesContainer, {
          [styles.tilesContainerVertical]: shouldUseVertical,
        })}
      >
        <DetailPanelBody useVerticalView={shouldUseVertical} />
      </Stack>
    </Stack>
  );
};
