import {
  EventAccordionItemBodyComponentType,
  MinRowsForUsingVirtuoso,
} from 'src/components/Accordions';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ListingTable } from 'src/tables/ListingTable';
import { ListingTiles } from 'src/tiles/ListingTiles';
import { Listing } from 'src/WebApiController';

type InventoryEventsBodyProps = EventAccordionItemBodyComponentType<Listing>;

export const InventoryEventsBody = (props: InventoryEventsBodyProps) => {
  const isMobile = useMatchMedia('mobile');
  const Body = isMobile ? ListingTiles : ListingTable;
  const hasVirtuosoMinRows = props.entityCount > MinRowsForUsingVirtuoso;
  const { isEnabled } = useCollapsableViewContext();
  return (
    <Body
      {...props}
      useVirtuoso={isEnabled || hasVirtuosoMinRows}
      tableWithOuterPadding={!isEnabled}
      enableColumnFilters
    />
  );
};
