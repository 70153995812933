import { Feature, Permission } from 'src/WebApiController';

import { ReportConfigV2 } from './useReportConfigsV2';
import { useUserHasAnyOfPermissions } from './useUserHasAnyOfPermissions';
import { useUserHasFeature } from './useUserHasFeature';

export const useReportPermissions = (reportConfig?: ReportConfigV2) => {
  const hasUpdateReportPermission = useUserHasAnyOfPermissions(
    Permission.Reports_UpdateReports
  );

  const hasReportingV2Feature = useUserHasFeature(Feature.ReportingV2);
  const hasReportSinglePagedV1V2Feature = useUserHasFeature(
    Feature.ReportSinglePagedV1V2
  );

  const hasViewReportPermissions = useUserHasAnyOfPermissions(
    Permission.Reports_ViewAll,
    Permission.Reports_ViewGlobalReports,
    Permission.Reports_ViewInventoryReports,
    Permission.Reports_ViewSalesReports
  );

  const hasUpdateOwnReportPermission =
    useUserHasAnyOfPermissions(Permission.Reports_UpdateReportsOwned) &&
    (reportConfig?.isOwner ?? false);

  const isGlobalReport = reportConfig?.globalReportTypeId !== undefined;

  const canUpdateReport =
    reportConfig != null &&
    (hasUpdateReportPermission || hasUpdateOwnReportPermission) &&
    !isGlobalReport;

  const hasAccessToReportingV2 =
    hasReportingV2Feature &&
    hasViewReportPermissions &&
    !hasReportSinglePagedV1V2Feature;

  return {
    canUpdateReport,
    canViewReport: hasViewReportPermissions,
    hasAccessToReportingV2,
    hasReportSinglePagedV1V2Feature,
  };
};
