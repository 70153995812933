import {
  functionalUpdate,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActiveSortTableColumnContext } from 'src/contexts/ActiveSortTableColumnContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ReportMetricsResponse,
  useReportMetricsContextV2,
} from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { NoData, Table, VirtuosoScrollEvent } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';

import { ReportTableV2Props } from '../ReportTableCommon/ReportTableCommon.types';
import {
  CommissionReportTableColumnDefContextV2,
  useCommissionReportTableColumnDefV2,
} from './CommissionReportTableColumnDefContextV2';

export const CommissionReportTableV2 = ({
  report,
  reportValueTypes,
  ...rest
}: ReportTableV2Props) => {
  return (
    <CommissionReportTableColumnDefContextV2
      report={report}
      reportValueTypes={reportValueTypes}
    >
      <ReportTableInternal report={report} {...rest} />
    </CommissionReportTableColumnDefContextV2>
  );
};

const ReportTableInternal = ({
  metricsData,
  summaryData,
  report,
  onMount,
  onUnmount,
  initState,
  className,
}: ReportTableV2Props) => {
  const {
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isReportConfigEmpty,
  } = useReportMetricsContextV2();

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const { activeSortTableColumn, setActiveSortTableColumn } =
    useActiveSortTableColumnContext();
  const [sorting, setSorting] = useState<SortingState>(
    activeSortTableColumn || initState?.sorting || []
  );

  const data = useMemo(() => {
    const shimmeringDivCount = 500;
    return metricsData ?? Array(shimmeringDivCount).fill(null);
  }, [metricsData]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: Math.max(1, data.length),
  });

  useEffect(() => {
    if (pagination.pageSize !== data.length) {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: data.length,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, report]);

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const { displayedColumnsConfig = [] } = useCommissionReportTableColumnDefV2();

  const onScroll = useCallback(
    (virtuosoScrollEvent: VirtuosoScrollEvent) => {
      const { element } = virtuosoScrollEvent;
      const { scrollHeight, scrollTop, clientHeight } = element;
      // once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
      // TODO change this to constant
      if (
        scrollHeight - scrollTop - clientHeight < 500 &&
        !isFetchingNextPage &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  const options: Partial<TableOptions<ReportMetricsResponse | null>> = useMemo(
    () => ({
      data,
      meta: {
        summaryData,
      },
      columns: displayedColumnsConfig,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
        setActiveSortTableColumn(newSortVal);
      },
      enableRowSelection: true,
    }),
    [
      data,
      summaryData,
      displayedColumnsConfig,
      pagination,
      sorting,
      setActiveSortTableColumn,
    ]
  );

  return isReportConfigEmpty ? (
    <></>
  ) : data?.length > 0 ? (
    <ColumnResizingContextProvider>
      <Table
        options={options}
        className={className}
        tableLayout="fixed"
        withOuterPadding
        useVirtuoso
        onVirtuosoTableScroll={onScroll}
        disableColumnVirtualization={true} // TODO: remove when we have a fix for column virtualization
        useStickyFooter
      />
    </ColumnResizingContextProvider>
  ) : (
    <NoData>
      <Content id={ContentId.NoReportText} />
    </NoData>
  );
};

export default CommissionReportTableV2;
