import { useContext } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import {
  CatalogDataContextV1,
  CatalogDataContextV2,
} from './CatalogDataContext.types';

export const useCatalogDataContext = () => {
  const hasCatalogV2Feature = useUserHasFeature(Feature.CatalogCacheStrategyV2);

  return useContext(
    hasCatalogV2Feature ? CatalogDataContextV2 : CatalogDataContextV1
  );
};
