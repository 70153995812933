import { CellContext } from '@tanstack/react-table';
import React, { ReactNode, useCallback } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { PosSelect } from 'src/core/POS/PosSelect';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { DeliveryType, TicketGroupInput } from 'src/WebApiController';

import * as styles from './TicketGroupInputTable.css';

function TicketGroupInputDeliveryTypeComponent({
  row: { index: rowIndex },
  deliveryTypeMapping,
  deliveryTypeMappingOverride,
  deliverTypeOptionsAlwaysShow,
  cell,
  control,
}: CellContext<TicketGroupInput, unknown> & {
  deliveryTypeMapping: Record<DeliveryType, ReactNode>;
  deliveryTypeMappingOverride: Record<DeliveryType, ReactNode>;
  deliverTypeOptionsAlwaysShow: Record<DeliveryType, boolean>;
  control: Control<PurchaseTicketsInput, any>;
}) {
  const { setValue } = useFormContext<PurchaseTicketsInput>();

  const displayComponent = useCallback(
    (value: string) => {
      return <>{deliveryTypeMapping[value as DeliveryType]}</>;
    },
    [deliveryTypeMapping]
  );

  const deliveryTypeValue = useWatch({
    name: `ticketGroups.${rowIndex}.deliveryType.value`,
  });

  return (
    <Controller
      name={`ticketGroups.${cell.row.index}.deliveryType.value`}
      control={control}
      render={({ field: { ...field } }) => {
        return (
          <PosSelect
            className={styles.deliveryTypeInput}
            variant="outline"
            valueOptionsIcon={deliveryTypeMapping}
            valueOptionsContent={{}}
            displayComponent={displayComponent}
            placeholderText={' '}
            prefixDisplay={<></>}
            value={deliveryTypeValue as DeliveryType}
            valueOptionsContentOverride={deliveryTypeMappingOverride}
            valueOptionsAlwaysShow={deliverTypeOptionsAlwaysShow}
            onChange={(value) =>
              setValue(
                `ticketGroups.${rowIndex}.deliveryType`,
                posChangedField(value as DeliveryType)
              )
            }
          />
        );
      }}
    />
  );
}

export const TicketGroupInputDeliveryType = React.memo(
  TicketGroupInputDeliveryTypeComponent
) as (
  props: CellContext<TicketGroupInput, unknown> & {
    deliveryTypeMapping: Record<DeliveryType, ReactNode>;
    deliveryTypeMappingOverride: Record<DeliveryType, ReactNode>;
    deliverTypeOptionsAlwaysShow: Record<DeliveryType, boolean>;
    control: Control<PurchaseTicketsInput, any>;
  }
) => JSX.Element;
