import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EntitySearchConfigsToolbar,
  EntitySearchRefetcher,
  useEntitySearch,
} from 'src/components/EntitySearch';
import { PurchaseSearchBox } from 'src/components/Purchases/PurchaseFilterBar/PurchaseSearchBox';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import {
  MultiSelectionContextProvider,
  MultiSelectScope,
  NO_GROUP_ID,
} from 'src/contexts/MultiSelectionContext';
import {
  PurchaseDataContextProvider,
  usePurchaseDataContext,
} from 'src/contexts/PurchaseDataContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosNoResultsFound } from 'src/core/POS/PosNoResultsFound';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { DividerLine } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultPurchaseQuery,
  EmptyPurchaseQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  getCatalogData,
  PURCHASE_USER_SETTINGS,
  purchaseQueryValueTransformFromUrl,
  purchaseQueryValueTransformToUrl,
} from 'src/utils/purchaseUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogResults,
  Feature,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseSearchResult,
  PurchaseViewMode,
} from 'src/WebApiController';

import { getDefaultReturnUrl } from '../InventoryEvent/constants';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';
import { PurchaseFlattenedView } from '../Purchases/PurchaseFlattenedView';
import * as styles from './PurchaseSearch.css';
import { PurchaseSearchFilterBar } from './PurchaseSearchFilterBar';
import { PurchaseSearchToolbar } from './PurchaseSearchToolbar';

export function PurchaseSearch() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={PURCHASE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<PurchaseOrderQuery>
        initialQuery={{
          ...DefaultPurchaseQuery,
          viewMode: PurchaseViewMode.FlattenedView,
        }}
        emptyQuery={EmptyPurchaseQuery}
        queryValueTransformToUrl={purchaseQueryValueTransformToUrl}
        queryValueTransformFromUrl={purchaseQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <PurchaseSearchPage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

function PurchaseSearchPage() {
  const [activeSearchConfig, setActiveSearchConfig] =
    useState<PurchaseSearchResult>();

  const getCatalogDataExpandedCallback = useCallback(
    (_1: string[], _2: PurchaseOrderQuery) => {
      return Promise.resolve({});
    },
    []
  );

  const getCatalogDataCallback = useCallback(
    async (c: CatalogClient, f: PurchaseOrderQuery) => {
      // Check to see if if is empty filter, query
      if (
        f.searchConfigId &&
        !f.entityIds?.length // we don't want to query when the filter has the search config id but no entity ids
      ) {
        // If there is no filter (empty query) then don't try to query for anything
        return {
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
        } as CatalogResults;
      }

      return await getCatalogData(c, f, true);
    },
    []
  );

  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();

  // If we're querying by ids - we want to get the latest greatest
  // We're only using readonly-db if we're filtering
  const useReadonlyDB = filterQuery.entityIds?.length ? false : true;

  return (
    <CatalogDataContextProvider<PurchaseOrderQuery>
      entityType={ActionOutboxEntityType.Purchase}
      queryKey="getCatalogForPurchaseOrders"
      getCatalogData={getCatalogDataCallback}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
      disableAutoRefresh
      ignoreMaxCount
    >
      <PurchaseDataContextProvider
        viewModeOverride={PurchaseViewMode.FlattenedView}
        useReadonlyDB={useReadonlyDB}
        ignoreMaxCount
        doNotQueryForPurchases={activeSearchConfig == null}
      >
        <CatalogMultiSelectionContextProvider type={'purchase'}>
          <MultiSelectionContextProvider
            isFlattenedView
            forceSelectionMode={{
              mode: MultiSelectScope.AllGroups,
              groupId: NO_GROUP_ID,
            }}
            isHiddenCheckboxesFeatureEnabled={true}
          >
            <PurchaseSearchContent
              activeSearchConfig={activeSearchConfig}
              setActiveSearchConfig={setActiveSearchConfig}
            />
          </MultiSelectionContextProvider>
        </CatalogMultiSelectionContextProvider>
      </PurchaseDataContextProvider>
    </CatalogDataContextProvider>
  );
}

function PurchaseSearchContent({
  activeSearchConfig,
  setActiveSearchConfig,
}: {
  activeSearchConfig: PurchaseSearchResult | undefined;
  setActiveSearchConfig: (a: PurchaseSearchResult | undefined) => void;
}) {
  const purchaseSearchTitle = useContent(ContentId.SearchPurchases);

  const { activeAccountWebClientConfig } = useAppContext();
  const location = useLocation();
  const { isLoading: isCatalogDataLoading } = useCatalogDataContext();

  const {
    selectedFilters,
    setSelectedFilters,
    onSetActiveSearchConfig,
    onSaveSearchResult,
    onDeleteSearchResult,
    onRequeryForTheResult,
    refetchData,
    disabled,
    setDisabled,
  } = useEntitySearch(
    activeSearchConfig,
    setActiveSearchConfig,
    PurchaseViewMode.FlattenedView,
    (client, id) => client.getPurchaseSearchResult(id),
    (client, r) => client.savePurchaseSearchResult(r)
  );

  const hasColumnFilterSearchViewFeature = useUserHasFeature(
    Feature.ColumnFilterSearchView
  );

  // Only use value provided at the first time (should be from '/purchases')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('purchases');

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      PurchaseDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state]);

  const {
    allPurchasesQuery,
    errorInfo,
    onSetActivePurchaseOrderCallback,
    allPurchasesFiltered,
  } = usePurchaseDataContext();

  const hasPurchasesResults = (allPurchasesFiltered ?? []).length > 0;

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  const dataIsLoading = isCatalogDataLoading;

  return (
    <ActivePosEntityProvider<PurchaseOrderDetails>
      entityType={ActionOutboxEntityType.Purchase}
      getActivePosEntity={getActivePosEntity}
      onSetActiveItemCompleteCallback={onSetActivePurchaseOrderCallback}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.PurchaseSearch}
          routeTitle={purchaseSearchTitle}
          returnUrl={returnUrl}
          rightContent={
            <EntitySearchRefetcher
              activeSearchConfig={activeSearchConfig}
              disabled={disabled}
              onRequeryForTheResult={onRequeryForTheResult}
            />
          }
        >
          <EntitySearchConfigsToolbar
            disabled={disabled}
            setDisabled={setDisabled}
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={onSetActiveSearchConfig}
            saveActiveSearchConfig={onSaveSearchResult}
            deleteActiveSearchConfig={onDeleteSearchResult}
            getSearchConfigs={(client, includeAllUser) =>
              client.getPurchaseSearchConfigs(includeAllUser)
            }
            getSearchResult={(client, id) => client.getPurchaseSearchResult(id)}
            additionalActions={<PurchaseSearchBox />}
          />
          <DividerLine
            style={{
              margin: 0,
              border: 'none',
              borderBottom: `1px solid ${vars.color.border}`,
            }}
          />
          <PurchaseSearchFilterBar
            activeSearchConfig={activeSearchConfig}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            onSubmitFilter={(filter) => {
              if (!activeSearchConfig) {
                // Setting an empty search config so it starts to filter
                onSetActiveSearchConfig({
                  id: null,
                  name: null,
                  query: filter,
                  ownerUserId: null,
                  count: null,
                  entityIds: null,
                  lastFetchDate: null,
                });
              }
              refetchData(filter, allPurchasesQuery.refetch);
            }}
          />
          <DividerLine
            style={{
              margin: 0,
              border: 'none',
              borderBottom: `1px solid ${vars.color.borderLight}`,
            }}
          />
          <PurchaseSearchToolbar disabled={disabled} />
          <DividerLine
            style={{
              margin: 0,
              border: 'none',
              borderBottom: `1px solid ${vars.color.borderLight}`,
            }}
          />
          <div className={styles.contentContainer}>
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {(dataIsLoading || allPurchasesQuery.isLoading) &&
            !allPurchasesFiltered?.length ? (
              <PosSpinner />
            ) : (
              !errorInfo && (
                <>
                  {hasPurchasesResults ? (
                    <PurchaseFlattenedView
                      alwaysShowCheckbox
                      showSelectAllOnHeader
                      enableColumnFilters={hasColumnFilterSearchViewFeature}
                      ignoreMaxCount
                    />
                  ) : (
                    <PosNoResultsFound
                      detailMessage={ContentId.AddFilterToShowResult}
                    />
                  )}
                </>
              )
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
