import clsx from 'clsx';
import { useEffect } from 'react';
import { EventTicketGroupBody } from 'src/components/Accordions/TicketGroupsEventsAccordion/EventTicketGroupBody';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { multiActionToolBarNoHorizontalPadding } from 'src/components/Events/EventPage/EventPage.css';
import { PurchasesBulkActionsPermissions } from 'src/components/Purchases/BulkActions/PurchasesBulkActionsPermissions';
import { PurchasesTileBulkActions } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/PurchasesTileBulkActions';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData, PurchaseOrderTicketGroup } from 'src/WebApiController';

import { MinRowsForUsingVirtuoso } from '../constants';
import {
  EventAccordionItemProps,
  EventsAccordion,
  VirtualizedEventAccordionProps,
} from '../EventsAccordion';
import { EventTicketGroupHeader } from './EventTicketGroupHeader';

export function translateEventToTicketGroupAccordionItem(
  props: EventWithData,
  index: number,
  noPadding?: boolean
): EventAccordionItemProps<PurchaseOrderTicketGroup> {
  return {
    index,
    itemId: props.event.viagVirtualId,
    HeaderComponent: EventTicketGroupHeader,
    BodyComponent: EventTicketGroupBody,
    AfterHeaderComponent: () => (
      <PurchasesBulkActionsPermissions>
        <MultiSelectActionBar
          groupId={props.event.viagVirtualId}
          className={clsx({
            [multiActionToolBarNoHorizontalPadding]: noPadding,
          })}
        >
          <PurchasesTileBulkActions
            groupId={props.event.viagVirtualId}
            isEventView
          />
        </MultiSelectActionBar>
      </PurchasesBulkActionsPermissions>
    ),
    usingVirtuoso: (props.counts.tktGrpsCnt ?? 0) > MinRowsForUsingVirtuoso,
    ...props,
    entities: props.entities.ticketGroups ?? [],
    entityCount: props.counts.tktGrpsCnt ?? 0,
  };
}

export function TicketGroupsEventsAccordion(
  props: VirtualizedEventAccordionProps<PurchaseOrderTicketGroup>
) {
  const { setAllGroupsIds } = useMultiSelectionContext();

  useEffect(() => {
    const itemsIds = props.items.map((item) => item.event.viagVirtualId);
    setAllGroupsIds(itemsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EventsAccordion {...props} />;
}

export function TicketGroupEventsEmpty() {
  return (
    <EmptySectionContent.Root
      icon={
        <EmptySectionContent.SolidIconContainer>
          <SearchSolidIcon />
        </EmptySectionContent.SolidIconContainer>
      }
    >
      <EmptySectionContent.Label>
        <Content id={ContentId.NoResultFound} />
      </EmptySectionContent.Label>
      <EmptySectionContent.DetailMessage>
        <Content id={ContentId.NoEventsMatching} />
      </EmptySectionContent.DetailMessage>
    </EmptySectionContent.Root>
  );
}
