import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { Content } from 'src/contexts/ContentContext';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

interface NoResultsFoundProps {
  detailMessage?: ContentId;
}

export const PosNoResultsFound = ({ detailMessage }: NoResultsFoundProps) => {
  return (
    <EmptySectionContent.Root
      icon={
        <EmptySectionContent.SolidIconContainer>
          <SearchSolidIcon />
        </EmptySectionContent.SolidIconContainer>
      }
    >
      <EmptySectionContent.Label>
        <Content id={ContentId.NoResultFound} />
      </EmptySectionContent.Label>
      {detailMessage && (
        <EmptySectionContent.DetailMessage>
          <Content id={detailMessage} />
        </EmptySectionContent.DetailMessage>
      )}
    </EmptySectionContent.Root>
  );
};
