import { useMemo } from 'react';
import { useListingNotesSelector } from 'src/components/Selectors/ListingNotesSelector/useListingNotesSelector';
import { ContentId } from 'src/utils/constants/contentId';
import { EventListingConstraints, ListingNote } from 'src/WebApiController';

export const useTicketGroupListingNotes = ({
  listingConstraints,
}: {
  listingConstraints?: EventListingConstraints | null;
}) => {
  const {
    query,
    multiSelectorProps: { valueOptionsContent },
  } = useListingNotesSelector({
    disabled: false,
    validListingNoteIds: listingConstraints?.validListingNoteIds,
    placeholderText: ContentId.ChooseSeatingNotes,
  });

  const listingNotesMap = useMemo(
    () =>
      query.data
        ?.filter((l) => (l.listingNoteText || l.listingNoteUntranslated).trim())
        .reduce(
          (r, l) => {
            r[l.listingNoteId.toString()] = l;
            return r;
          },
          {} as Record<string, ListingNote>
        ),
    [query.data]
  );

  return {
    listingNotesMap,
    valueOptionsContent,
  };
};
