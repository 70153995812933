import { getFlattenedListings } from 'src/modals/MergeListings/utils';
import {
  ActionOutboxEntityType,
  CatalogResults,
  EventEntities,
  EventEntityCounts,
  EventWithData,
  Listing,
} from 'src/WebApiController';

import { ExpandedEventData } from './CatalogDataContext.types';

export const updateCatalogResults = (
  catalogResults: CatalogResults,
  expandedEventsData: ExpandedEventData[]
): CatalogResults => {
  if (!expandedEventsData || !expandedEventsData?.length) {
    return catalogResults;
  }

  const finalCatalogResults: CatalogResults = {
    ...catalogResults,
    events: { ...catalogResults.events },
  };

  for (const expandedEventData of expandedEventsData) {
    for (const eventId in expandedEventData) {
      const curEvent = finalCatalogResults.events[eventId];
      if (curEvent) {
        // Update listings
        const listings = expandedEventData[eventId].listings;
        let listGrpCnt = 0;
        let ungrListCnt = 0;
        listings?.forEach((listing: Listing) => {
          listing.isLtGrp ? listGrpCnt++ : ungrListCnt++;
        });

        const listCnt =
          listGrpCnt > 0
            ? getFlattenedListings(listings)[0].length // if has groups, get flattened listings
            : listings?.length; // else just use the item count

        const sales = expandedEventData[eventId].sales;
        const salesCnt = sales?.length;

        const ticketGroups = expandedEventData[eventId].ticketGroups;
        const tktGrpsCnt = ticketGroups?.length;

        if (listings || sales || ticketGroups) {
          finalCatalogResults.events[eventId] = {
            ...curEvent,
            entities: {
              ...(curEvent.entities ?? {}),
              listings,
              sales,
              ticketGroups,
            } as EventEntities,
            counts: {
              ...(curEvent.counts ?? {}),
              listCnt,
              listGrpCnt,
              ungrListCnt,
              salesCnt,
              tktGrpsCnt,
            } as EventEntityCounts,
          };
        }
      }
    }
  }

  return finalCatalogResults;
};

export const getEventsWithNonZeroCounts = (
  entityType: ActionOutboxEntityType,
  data?: EventWithData[] | null,
  requireEventNotNull?: boolean
) => {
  if (!data) {
    return [];
  }
  const filteredEvents = data.filter((ev) => {
    if (ev.counts == null) {
      return false;
    }

    if (requireEventNotNull && ev.event == null) {
      return false;
    }

    const count =
      entityType === ActionOutboxEntityType.Listing
        ? ev.counts.listCnt
        : entityType === ActionOutboxEntityType.Sale
          ? ev.counts.salesCnt
          : ev.counts.tktGrpsCnt || ev.counts.poCnt;

    return Boolean(count);
  });

  return filteredEvents;
};
