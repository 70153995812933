import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { Switch } from 'src/core/ui';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { AutoPricingStrategySection } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingStrategySection';
import { useHasListingPricePermissions } from 'src/modals/ListingDetails/components/useHasListingPricePermissions';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

import { SimplifiedAutopricingGuidedPrice } from './SimplifiedAutopricingGuidedPrice';

type SimplifiedAutopricingSectionProps = {
  listing: Listing | undefined;
};

/**
 * @deprecated - Use SimplifiedAutopricingSectionV2 instead
 */
export const SimplifiedAutopricingSection = ({
  listing,
}: SimplifiedAutopricingSectionProps) => {
  // Hooks
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { canSetPrice, canAutoPrice } = useHasListingPricePermissions(listing);

  const { activeAccountWebClientConfig } = useAppContext();

  // React-hook-form
  const { setValue, getValues, reset } =
    useFormContext<ListingDetailsUpdateInput>();

  const { hasChanges, autoPricingEnabled } =
    useListingAutoPricingSettings(listing);

  // Mutations
  const {
    updateItemInEvent,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { onUpdateListing } = useEventHubContext();

  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const submitPricingSettingsChange = useCallback(() => {
    setIsSubmitting(true);
    const listForm = getValues();

    if (!listing) {
      return;
    }

    tryInvokeApi(
      async () => {
        const client = new ListingClient(activeAccountWebClientConfig);

        const input: ListingDetailsAutoPricingSectionUpdates = {
          ...listForm,
          id: listing.id,
          rowVersion: null,
          autoPriceFrequency: null,
          compListingQuantityFilters: null,
          compListingQuantityScoreAdjustmentOverrides: null,
          scoreModel: null,
          autoPricingDefaultQuantityFiltersJson: null,
          venueZoneOverrideId: null,
          venueZoneConfigType: null,
        };
        await client.updateListingAutoPricingSection(input);

        // Refersh listing
        const updated = await client.getListingByListingId(
          listing.id,
          hasSectionalLoadDataFeature ? (listing as ListingDetails) : undefined,
          hasSectionalLoadDataFeature
            ? [
                ListingDetailDataField.Pricing, // To refresh pricing related listing fields, i.e. auto pricing enabled
                ListingDetailDataField.AutoPricing,
              ]
            : null
        );
        if (updated) {
          updateItemInEvent(updated, ActionOutboxEntityType.Listing);
          onUpdateListing(updated.id);
        }
      },
      (error) => {
        showErrorDialog(
          'ListingClient.updateListingAutoPricingSection',
          error,
          {
            trackErrorData: {
              item1: listing.id,
              item2: listForm,
            },
          }
        );
      },
      () => {
        setIsSubmitting(false);
        reset(listForm);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getValues,
    listing,
    activeAccountWebClientConfig,
    refreshExpandedListItems,
    showErrorDialog,
  ]);

  return canSetPrice && listing ? (
    <Stack direction="column" gap="l" alignItems="start" width="full">
      <Stack
        direction="row"
        gap="xxl"
        justifyContent="spaceBetween"
        width="full"
        alignItems="center"
      >
        {canAutoPrice && (
          <Stack direction="row" gap="m" alignItems="center">
            <Content id={ContentId.Autopricing} />
            <Switch
              disabled={isSubmitting}
              checked={Boolean(autoPricingEnabled)}
              onChange={(e) => e.stopPropagation()}
              onCheckedChange={(isChecked) => {
                setValue('autoPricingEnabled', isChecked);
              }}
            />
            <Content id={autoPricingEnabled ? ContentId.On : ContentId.Off} />
          </Stack>
        )}

        <AutoPricingStrategySection
          listing={listing}
          disabled={listing == null || isSubmitting}
          isMarketListingsView={true}
        />

        <OkButton
          onClick={() => {
            submitPricingSettingsChange();
          }}
          textContentId={ContentId.Save}
          disabled={listing == null || isSubmitting || !hasChanges(getValues())}
        />
      </Stack>
      <SimplifiedAutopricingGuidedPrice
        listing={listing!}
        listingHasParent={!!listing?.ltGrpParentId}
        disabled={listing == null || isSubmitting}
        isMarketListingsView={true}
      />
    </Stack>
  ) : null;
};
