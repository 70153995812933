import { useEffect } from 'react';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { InventoryGlobalActionDropdown } from 'src/components/Listings/InventoryActionDropdown';
import {
  listingsMandatoryFiltersToShow,
  ViewModeSelector,
} from 'src/components/MainFilterBar';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { INVENTORY_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  ActionOutboxEntityType,
  Feature,
  InventoryViewMode,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import { SidePanelButton } from '../../Buttons/SidePanelButton';
import { SwiperButton } from '../../Buttons/SwiperButton';
import { BackSection } from '../../common/BackSection';
import { useListingFilters } from '../../MainFilterBar';
import * as styles from '../../MainFilterBar/MainFilterBar.css';
import { useDefaultListingFilter } from './hooks/useDefaultListingFilter';
import { INVENTORY_VIEW_MODE_TO_ICON } from './ListingFilterBar.types';
import { ListingSearchBox } from './ListingSearchBox';

export function ListingFilterBar({
  isSearchOnly,
  isEventPage = false,
  returnUrl,
  posEventId,
  swiperRef,
}: {
  isSearchOnly?: boolean;
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const isMobile = useMatchMedia('mobile');
  const { filters } = useListingFilters({
    isEventPage,
    showMetricsIndicationOnFilters: true,
  });

  const hasNewSearchFeature = useUserHasFeature(Feature.SearchView_Inventory);

  const {
    filterQuery: listingQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<ListingQuery>();

  const defaultQuickFilters = useDefaultListingFilter({
    initialQuery,
    listingQuery,
  });

  useEffect(() => {
    setTempQuery(listingQuery);
  }, [listingQuery, setTempQuery]);

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<InventoryViewMode>({
      id: UserSetting.InventoryPageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateInventory,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomInventory,
    defaultQuickFilters,
    currentQuery: listingQuery,
    initialQuery,
    onSelect: ({ query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }

      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: listingQuery,
    mandatoryFiltersToShow: listingsMandatoryFiltersToShow,
    initialQuery,
  });

  const filterToolbar = listingQuery && (
    <FilterToolbar
      {...filterToolbarProps}
      filterAppliedCounts={appliedFilters.length}
      filterAppliedIds={appliedFilters}
      onSubmitFilter={() => setFilterQuery(tempQuery)}
      filters={filters}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      mandatoryFiltersToShow={listingsMandatoryFiltersToShow}
    />
  );

  return isSearchOnly ? (
    <div className={styles.mainFilterBarDiv}>
      <div className={styles.dockLeftDiv}>
        <div className={styles.mainFilterBarLeftChild}>
          <SwiperButton dir="left" swiperRef={swiperRef} />
        </div>
      </div>
      <div className={styles.dockRightDiv}>
        <div className={styles.mainFilterBarRightChild}>
          <BulkEditStatusPopover entityType={ActionOutboxEntityType.Listing} />
          {!isMobile && (
            <ViewModeSelector
              query={listingQuery}
              initialQuery={initialQuery}
              setFilterQuery={setFilterQuery}
              viewModeSettingsId={UserSetting.InventoryPageViewMode}
              viewModeCidMap={INVENTORY_VIEW_MODE_TO_CID}
              viewModeIconMap={INVENTORY_VIEW_MODE_TO_ICON}
            />
          )}
          <MultiSelectionToggleGlobal />
          {!isEventPage && (
            <>
              <InventoryGlobalActionDropdown />

              <ListingSearchBox />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.mainFilterBarDiv}>
      {isMobile ? (
        filterToolbar
      ) : (
        <>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              {isEventPage && (
                <BackSection
                  returnUrl={returnUrl ?? '/inventory'}
                  state={{
                    fromPosEventId: posEventId,
                  }}
                />
              )}
              {listingQuery.viewMode === InventoryViewMode.MetricView && (
                <SidePanelButton position="relative" />
              )}
              {filterToolbar}
            </div>
          </div>
          <div className={styles.dockRightDiv}>
            <div className={styles.mainFilterBarRightChild}>
              {!isEventPage && (
                <>
                  <BulkEditStatusPopover
                    entityType={ActionOutboxEntityType.Listing}
                  />
                  <ViewModeSelector
                    query={listingQuery}
                    initialQuery={initialQuery}
                    setFilterQuery={setFilterQuery}
                    viewModeSettingsId={UserSetting.InventoryPageViewMode}
                    viewModeCidMap={INVENTORY_VIEW_MODE_TO_CID}
                    viewModeIconMap={INVENTORY_VIEW_MODE_TO_ICON}
                  />
                  <MultiSelectionToggleGlobal />
                  <InventoryGlobalActionDropdown />
                </>
              )}
              {(!hasNewSearchFeature || isEventPage) && (
                <ListingSearchBox isEventPage={isEventPage} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
