import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MetricsIcon = (props: Omit<PosIconProps, 'viewBox'>) => {
  return (
    <SvgWrapper viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 0H11.4828C11.3047 0 11.2154 0.215428 11.3414 0.341421L12.8882 1.88818L0.544447 12.4685C0.250918 12.7201 0.216925 13.162 0.468521 13.4556C0.720116 13.7491 1.16203 13.7831 1.45555 13.5315L13.8811 2.88105L15.6586 4.65858C15.7846 4.78457 16 4.69534 16 4.51716V0.4C16 0.179086 15.8209 0 15.6 0ZM13.2 6.7C13.2 6.3134 13.5134 6 13.9 6C14.2866 6 14.6 6.3134 14.6 6.7V16H13.2V6.7ZM3 14.7C3 14.3134 3.3134 14 3.7 14C4.0866 14 4.4 14.3134 4.4 14.7V16H3V14.7ZM7.09999 12C6.71339 12 6.39999 12.3134 6.39999 12.7V16H7.79999V12.7C7.79999 12.3134 7.48659 12 7.09999 12ZM9.79999 9.7C9.79999 9.3134 10.1134 9 10.5 9C10.8866 9 11.2 9.3134 11.2 9.7V16H9.79999V9.7Z"
        fill={props.fill ?? '#677383'}
      />
    </SvgWrapper>
  );
};
