import { UseFormSetValue } from 'react-hook-form';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput } from 'src/WebApiController';

import { CellsSelection } from './hooks/useSpreadsheetSelection';

export function isCellInRange(
  cells: CellsSelection | null,
  rowIndex: number,
  colIndex: number
) {
  if (!cells) return false;

  const minRow = Math.min(cells?.start.rowIndex, cells?.end.rowIndex);
  const maxRow = Math.max(cells?.start.rowIndex, cells?.end.rowIndex);
  const minCol = Math.min(cells?.start.colIndex, cells?.end.colIndex);
  const maxCol = Math.max(cells?.start.colIndex, cells?.end.colIndex);

  return (
    rowIndex >= minRow &&
    rowIndex <= maxRow &&
    colIndex >= minCol &&
    colIndex <= maxCol
  );
}

export function isPosField<T>(
  value: unknown
): value is { value: T; hasChanged: boolean } {
  return (
    value != null &&
    typeof value === 'object' &&
    'value' in value &&
    'hasChanged' in value
  );
}

export function calculateTicketCostUnitCost(
  currentData: TicketGroupInput[],
  setValue: UseFormSetValue<PurchaseTicketsInput>,
  rowIndex: number,
  colIds: (keyof TicketGroupInput)[],
  columnId: keyof TicketGroupInput
) {
  const quantity = currentData[rowIndex]['numberOfTickets']?.value;
  const unitCost = currentData[rowIndex]['unitCost']?.value;
  const totalCost = currentData[rowIndex]['totalCost']?.value;

  // If the column is numberOfTickets, we need to calculate the totalCost or unitCosts
  if (columnId === 'numberOfTickets' && quantity != null) {
    if (colIds.includes('unitCost') && unitCost != null) {
      if (unitCost != null) {
        setValue(
          `ticketGroups.${rowIndex}.totalCost`,
          posChangedField(parseFloat((quantity * unitCost).toFixed(2)))
        );
      }
    } else if (colIds.includes('totalCost') && totalCost != null) {
      setValue(
        `ticketGroups.${rowIndex}.unitCost`,
        posChangedField(parseFloat((totalCost / quantity).toFixed(2)))
      );
    }
  } else {
    if (columnId === 'unitCost' && quantity != null && unitCost != null) {
      setValue(
        `ticketGroups.${rowIndex}.totalCost`,
        posChangedField(parseFloat((quantity * unitCost).toFixed(2)))
      );
    }

    if (columnId === 'totalCost' && quantity != null && totalCost != null) {
      setValue(
        `ticketGroups.${rowIndex}.unitCost`,
        posChangedField(parseFloat((totalCost / quantity).toFixed(2)))
      );
    }
  }
}
