import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useMergedTagFilters } from 'src/components/MainFilterBar/Tags/useMergedTagFilters';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Slider } from 'src/core/ui/Slider';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MetricsIcon } from 'src/svgs/MetricsIcon';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALES_STATUS_TO_CID,
  TICKET_TYPE_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  InhandDateRangePresetNames,
  SaleDateRangeRelativePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import { formatNumber } from 'src/utils/numberFormatter';
import { ReportTypes } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Marketplace,
  Permission,
  SaleQuery,
} from 'src/WebApiController';

import { RowFilter } from '../Filters/RowFilter';
import { SectionFilter } from '../Filters/SectionFilter';
import {
  CurrencyFilterMultiSelector,
  CurrencyFilterSelector,
} from '../Selectors/CurrencyFilterSelector';
import { SellerAccountEmployeeSelector } from '../Selectors/SellerAccountEmployeeSelector';
import { useCommonEventFilters } from './useCommonEventFilters';
import { usePurchaseInfoFilters } from './usePurchaseInfoFilters';

const MAX_NUMBER_OF_TICKETS_HELD = 100_000;

export const saleMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
  'saleStatuses',
];

export function useSaleFilters({
  isReportsPage,
  isHorizontalLayout,
  showCatalogFilters,
  showMetricsIndicationOnFilters,
}: {
  isReportsPage?: boolean;
  isHorizontalLayout?: boolean;
  showCatalogFilters?: boolean;
  showMetricsIndicationOnFilters?: boolean;
}) {
  const { loginContext } = useAppContext();

  const hasFilterByCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByCurrencyCodeMulti
  );
  const hasFilterByCurrencyCodeFeature = !hasFilterByCurrencyCodeMultiFeature;
  const metricsIconNote = useContent(ContentId.ThisFilterWillChangeMetrics);
  const hasAutoFulfillSetting = useUserHasFeature(Feature.AutoFulfillSetting);
  const hasHideSalePaymentReceivedFilterFeature = useUserHasFeature(
    Feature.HideSalePaymentReceivedFilter
  );

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<SaleQuery>();

  const purchaseInfoFiltersBase = usePurchaseInfoFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    reportType: isReportsPage ? ReportTypes.Sale : undefined,
    showMetricsIndicationOnFilters: showMetricsIndicationOnFilters,
  });

  const tagsFilter = useMergedTagFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    entityType: ActionOutboxEntityType.Sale,
  });

  const purchaseInfoFilters = useMemo(() => {
    return {
      ...purchaseInfoFiltersBase,
      items: [
        ...purchaseInfoFiltersBase.items,
        {
          filterId: 'purchaseVendorOrderId' as FilterToolbarItemId,
          labelContentId: ContentId.ExternalPurchaseId,
          filterQueryKeys: ['purchaseVendorOrderId'] as FilterToolbarItemId[],
          filterItem: (
            <PosTextField
              style={{ width: '100%' }}
              value={tempQuery.purchaseVendorOrderId ?? ''}
              type="text"
              onChange={(e) => {
                const newValue = e.target.value?.length
                  ? e.target.value.trim()
                  : null;
                if (tempQuery.purchaseVendorOrderId !== newValue) {
                  setTempQuery({
                    ...tempQuery,
                    purchaseVendorOrderId: newValue,
                  });
                }
              }}
              maxLength={200}
            />
          ),
        },
      ],
    };
  }, [purchaseInfoFiltersBase, setTempQuery, tempQuery]);

  const notesFilters = useInternalNotesFilter({
    entityType: ActionOutboxEntityType.Sale,
    isHorizontalLayout,
  });

  const seatingFilters = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Seating,
      type: 'row',
      items: [
        {
          filterId: 'sectionContains',
          labelContentId: ContentId.Section,
          filterQueryKeys: [
            'sectionEquals',
            'sectionContains',
            'sectionNotContains',
          ],
          filterItem: (
            <SectionFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
        {
          filterId: 'rowContains',
          labelContentId: ContentId.Row,
          filterQueryKeys: ['rowEquals', 'rowContains', 'rowNotContains'],
          filterItem: (
            <RowFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
      ],
    }),
    [isHorizontalLayout, setTempQuery, tempQuery]
  );

  const eventFilters = useCommonEventFilters({
    showCatalogFilters,
    additionalFilters: [
      {
        filterId: 'lastSaleDates' as FilterToolbarItemId,
        labelContentId: ContentId.LastSaleDates,
        filterQueryKeys: ['lastSaleDates'] as FilterToolbarItemId[],
        filterItem: (
          <DateRangeSelector
            useRelativePresets
            presetNames={StandardDateRangePresetNames}
            value={tempQuery.lastSaleDates}
            defaultValue={initialQuery.lastSaleDates}
            onBlur={(value) =>
              setTempQuery({
                ...tempQuery,
                lastSaleDates: value,
              })
            }
            disabledDay={(d) =>
              getDisabledDatesForTimeFrameFilter(
                d,
                tempQuery.eventTimeFrameFilter
              )
            }
          />
        ),
      },
      {
        filterId: 'numberOfTicketsHeld' as FilterToolbarItemId,
        labelContentId: ContentId.NumberOfTicketsHeld,
        filterQueryKeys: ['numberOfTicketsHeld'] as FilterToolbarItemId[],
        filterItem: (
          <Slider.Root
            min={0}
            max={MAX_NUMBER_OF_TICKETS_HELD}
            step={1}
            value={[
              tempQuery.numberOfTicketsHeld?.start ?? 0,
              tempQuery.numberOfTicketsHeld?.end ?? MAX_NUMBER_OF_TICKETS_HELD,
            ]}
            renderHandle={(n) => (
              <Slider.Handle>{formatNumber(n)}</Slider.Handle>
            )}
            onValueChange={([start, end]: [number, number]) => {
              if (
                start !== (tempQuery.numberOfTicketsHeld?.start || 0) ||
                end !== tempQuery.numberOfTicketsHeld?.end
              ) {
                const newQuery = {
                  ...tempQuery,
                  numberOfTicketsHeld: {
                    start: start === 0 ? null : start,
                    end: end === MAX_NUMBER_OF_TICKETS_HELD ? null : end,
                  },
                };
                setTempQuery(newQuery);
              }
            }}
          />
        ),
      },
    ],
  });

  return {
    filters: [
      eventFilters,
      {
        titleContentId: ContentId.Fulfillment,
        items: [
          {
            filterId: 'saleStatuses' as FilterToolbarItemId,
            labelContentId: ContentId.Status,
            filterQueryKeys: ['saleStatuses'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumMultiSelect
                triggerProps={{
                  style: { width: '100%' },
                }}
                values={tempQuery.saleStatuses ?? []}
                placeholderText={ContentId.AllSaleStatuses}
                valueOptionsContent={SALES_STATUS_TO_CID}
                onChange={(saleStatuses) => {
                  setTempQuery({
                    ...tempQuery,
                    saleStatuses,
                  });
                }}
              />
            ),
          },
          {
            filterId: 'inHandDates' as FilterToolbarItemId,
            labelContentId: ContentId.InHand,
            filterQueryKeys: ['inHandDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={InhandDateRangePresetNames}
                value={tempQuery.inHandDates}
                defaultValue={initialQuery.inHandDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    inHandDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'ticketType' as FilterToolbarItemId,
            labelContentId: ContentId.TicketType,
            filterQueryKeys: ['ticketType'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.ticketType}
                placeholderText={ContentId.AllTicketTypes}
                enableEmptySelection
                onChange={(ticketType) => {
                  if (ticketType !== tempQuery.ticketType) {
                    setTempQuery({
                      ...tempQuery,
                      ticketType: ticketType,
                    });
                  }
                }}
                valueOptionsContent={TICKET_TYPE_TO_CID}
              />
            ),
          },
          {
            filterId: 'fulfillerSellerUserId' as FilterToolbarItemId,
            labelContentId: ContentId.FulfilledBy,
            filterQueryKeys: ['fulfillerSellerUserId'] as FilterToolbarItemId[],
            postfixIcon: showMetricsIndicationOnFilters ? (
              <MetricsIcon
                title={metricsIconNote}
                size={vars.iconSize.s}
                fill={vars.color.textBrand}
              />
            ) : undefined,
            filterItem: (
              <SellerAccountEmployeeSelector
                value={tempQuery.fulfillerSellerUserId ?? undefined}
                enableEmptySelection
                style={{ width: '100%' }}
                onChange={(newId) => {
                  if (newId !== tempQuery.fulfillerSellerUserId) {
                    setTempQuery({
                      ...tempQuery,
                      fulfillerSellerUserId: newId,
                    });
                  }
                }}
              />
            ),
          },
          {
            filterId: 'isSeatSaver' as FilterToolbarItemId,
            labelContentId: ContentId.Placeholder,
            filterQueryKeys: ['isSeatSaver'] as FilterToolbarItemId[],
            postfixIcon: showMetricsIndicationOnFilters ? (
              <MetricsIcon
                title={metricsIconNote}
                size={vars.iconSize.s}
                fill={vars.color.textBrand}
              />
            ) : undefined,
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={ToYesNoEnum(tempQuery.isSeatSaver)}
                placeholderText={ContentId.All}
                enableEmptySelection
                onChange={(yesNoEnumValue) => {
                  if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)) {
                    setTempQuery({
                      ...tempQuery,
                      isSeatSaver: FromYesNoEnum(yesNoEnumValue),
                    });
                  }
                }}
                valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
              />
            ),
          },
          {
            filterId: 'fulfillmentDates' as FilterToolbarItemId,
            labelContentId: ContentId.FulfillmentDate,
            filterQueryKeys: ['fulfillmentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.fulfillmentDates}
                defaultValue={initialQuery.fulfillmentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    fulfillmentDates: value,
                  })
                }
              />
            ),
          },
          ...(hasAutoFulfillSetting
            ? [
                {
                  filterId: 'isNoFulfill' as FilterToolbarItemId,
                  labelContentId: ContentId.AutoFulfill,
                  type: 'row',
                  filterQueryKeys: ['isNoFulfill'] as FilterToolbarItemId[],
                  filterItem: (
                    <PosEnumSelect
                      style={{ width: '100%' }}
                      value={ToYesNoEnum(
                        // the ui is the inverse of isNoFulfil
                        tempQuery.isNoFulfill == null
                          ? null
                          : !tempQuery.isNoFulfill
                      )}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(yesNoEnumValue) => {
                        if (
                          yesNoEnumValue !==
                          ToYesNoEnum(
                            // the ui is the inverse of isNoFulfil
                            tempQuery.isNoFulfill == null
                              ? null
                              : !tempQuery.isNoFulfill
                          )
                        ) {
                          const v = FromYesNoEnum(yesNoEnumValue);
                          setTempQuery({
                            ...tempQuery,
                            isNoFulfill: v == null ? null : !v, // the ui is the inverse of isNoFulfil
                          });
                        }
                      }}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                    />
                  ),
                },
              ]
            : []),
          {
            filterId: 'cancellationDates' as FilterToolbarItemId,
            labelContentId: ContentId.CancellationDate,
            filterQueryKeys: ['cancellationDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.cancellationDates}
                defaultValue={initialQuery.cancellationDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    cancellationDates: value,
                  })
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.WhenAndWhere,
        type: 'row',
        items: [
          {
            filterId: 'soldOnMarketplace' as FilterToolbarItemId,
            labelContentId: ContentId.Marketplace,
            filterQueryKeys: ['soldOnMarketplace'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.soldOnMarketplace}
                placeholderText={ContentId.AllMerchants}
                enableEmptySelection
                onChange={(soldOnMarketplace) => {
                  if (soldOnMarketplace !== tempQuery.soldOnMarketplace) {
                    setTempQuery({
                      ...tempQuery,
                      soldOnMarketplace,
                    });
                  }
                }}
                valueOptionsContent={(
                  loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                    (m) => m.mkp
                  ) ?? [Marketplace.StubHub]
                )
                  .concat(Marketplace.Offline)
                  .reduce(
                    (cur, m) => {
                      cur[m] = MARKET_PLACE_TO_CID[m];
                      return cur;
                    },
                    {} as Record<Marketplace, string>
                  )}
              />
            ),
          },
          {
            filterId: 'saleDates' as FilterToolbarItemId,
            labelContentId: ContentId.SaleDate,
            filterQueryKeys: ['saleDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                presetNames={SaleDateRangeRelativePresetNames}
                value={tempQuery.saleDates}
                defaultValue={initialQuery.saleDates}
                onBlur={(value) => {
                  setTempQuery({
                    ...tempQuery,
                    saleDates: value,
                  });
                }}
                useRelativePresets
              />
            ),
          },
          {
            filterId: 'listingInsertBy' as FilterToolbarItemId,
            labelContentId: ContentId.OriginatedBy,
            filterQueryKeys: ['listingInsertBy'] as FilterToolbarItemId[],
            postfixIcon: showMetricsIndicationOnFilters ? (
              <MetricsIcon
                title={metricsIconNote}
                size={vars.iconSize.s}
                fill={vars.color.textBrand}
              />
            ) : undefined,
            filterItem: (
              <SellerAccountEmployeeSelector
                value={tempQuery.listingInsertBy ?? undefined}
                enableEmptySelection
                style={{ width: '100%' }}
                onChange={(newId) => {
                  if (newId !== tempQuery.listingInsertBy) {
                    setTempQuery({
                      ...tempQuery,
                      listingInsertBy: newId,
                    });
                  }
                }}
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Payment,
        requiredPermissions: [Permission.Sales_ViewPaymentState],
        type: 'row',
        items: [
          {
            filterId: 'marketplacePaymentState' as FilterToolbarItemId,
            labelContentId: ContentId.Payment,
            filterQueryKeys: [
              'marketplacePaymentState',
            ] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.marketplacePaymentState}
                placeholderText={ContentId.AllPaymentStates}
                enableEmptySelection
                onChange={(marketplacePaymentState) => {
                  if (
                    marketplacePaymentState !==
                    tempQuery.marketplacePaymentState
                  ) {
                    setTempQuery({
                      ...tempQuery,
                      marketplacePaymentState: marketplacePaymentState,
                    });
                  }
                }}
                valueOptionsContent={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
          // POS-5575: We want to deprecate the paymentReceived filter
          // so it's intentional to NOT show it when the feature flag is enabled
          ...(hasHideSalePaymentReceivedFilterFeature
            ? []
            : [
                {
                  filterId: 'paymentReceived' as FilterToolbarItemId,
                  labelContentId: ContentId.PaymentReceived,
                  filterQueryKeys: ['paymentReceived'] as FilterToolbarItemId[],
                  filterItem: (
                    <PosEnumSelect
                      style={{ width: '50%' }}
                      value={ToYesNoEnum(tempQuery.paymentReceived)}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(yesNoEnumValue) => {
                        if (
                          yesNoEnumValue !==
                          ToYesNoEnum(tempQuery.paymentReceived)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            paymentReceived: FromYesNoEnum(yesNoEnumValue),
                          });
                        }
                      }}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                    />
                  ),
                },
              ]),
          {
            filterId: 'paymentReferenceId' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentReference,
            filterQueryKeys: ['paymentReferenceId'] as FilterToolbarItemId[],
            filterItem: (
              <PosTextField
                style={{ width: '50%' }}
                value={tempQuery.paymentReferenceId ?? ''}
                type="text"
                onChange={(e) => {
                  const newValue = e.target.value ?? null;
                  if (tempQuery.paymentReferenceId !== newValue) {
                    setTempQuery({
                      ...tempQuery,
                      paymentReferenceId: newValue,
                    });
                  }
                }}
                maxLength={200}
              />
            ),
          },
          ...(hasFilterByCurrencyCodeFeature
            ? [
                {
                  filterId: 'currencyCode' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCode'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterSelector
                      style={{ width: '100%' }}
                      value={tempQuery.currencyCode ?? ''}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCode) => {
                        if (tempQuery.currencyCode !== newCurrencyCode) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCode: newCurrencyCode?.length
                              ? newCurrencyCode
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterByCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'currencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      triggerProps={{ style: { width: '100%' } }}
                      values={tempQuery.currencyCodes ?? []}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCodes) => {
                        if (
                          !isEqual(tempQuery.currencyCodes, newCurrencyCodes)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCodes: newCurrencyCodes?.length
                              ? newCurrencyCodes
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      seatingFilters,
      purchaseInfoFilters,
      ...(notesFilters ? [notesFilters] : []),
      ...tagsFilter,
    ],
  };
}
