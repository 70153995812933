import { OnSaleEventsTableColumnId } from 'src/navigations/Routes/OnSaleEvent/OnSaleEventView.types';

import { ColumnPersonalizationFlags } from '../columnUtils.types';
import { EventsTableColumnId } from './eventsColumnUtils.types';

export const EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  EventsTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [EventsTableColumnId.Checkbox]: { isConfigurable: false },
  [EventsTableColumnId.ATP]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.Action]: { isConfigurable: false, isRequired: true },
  [EventsTableColumnId.ActualSTR]: { isNumeric: true, isPercent: true },
  [EventsTableColumnId.Date]: { isDateTime: true },
  [EventsTableColumnId.Event]: { isHiddenByDefault: false },
  [EventsTableColumnId.Venue]: { isHiddenByDefault: false },
  [EventsTableColumnId.Location]: {},
  [EventsTableColumnId.LiftPercentage]: { isNumeric: true, isPercent: true },
  [EventsTableColumnId.ListedAtp]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.ListedQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.Profit]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.Revenue]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.SoldCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.SoldQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.TotalQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.UnsoldQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.UnsoldProceeds]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.UnsoldCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.TotalCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.LastPriceUpdatedDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
};

export const ON_SALE_EVENTS_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  OnSaleEventsTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [OnSaleEventsTableColumnId.Checkbox]: { isConfigurable: false },
  [OnSaleEventsTableColumnId.Event]: {
    isHiddenByDefault: false,
    isConfigurable: false,
    isRequired: true,
  },
  [OnSaleEventsTableColumnId.Venue]: {
    isHiddenByDefault: false,
    isConfigurable: false,
    isRequired: true,
  },
  [OnSaleEventsTableColumnId.Location]: {
    isConfigurable: false,
    isRequired: true,
  },
  [OnSaleEventsTableColumnId.OnSaleDate]: {
    isDateTime: true,
    isConfigurable: true,
    isRequired: true,
  },
  [OnSaleEventsTableColumnId.RawPreSaleDate]: {
    isDateTime: true,
    isConfigurable: true,
    isHiddenByDefault: true,
  },
  [OnSaleEventsTableColumnId.Date]: {
    isDateTime: true,
    isConfigurable: true,
    isRequired: true,
  },
  [OnSaleEventsTableColumnId.ListingCount]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.TicketCount]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.VisitsLastHour]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.VisitsLastDay]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.VisitsLast7Days]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.VisitsLast3Days]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.TransactionsLastHour]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.TransactionsLastDay]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.TransactionsLast7Days]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.TransactionsLast3Days]: {
    isNumeric: true,
    isInteger: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsMin]: {
    isNumeric: true,
    isCurrency: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsP25]: {
    isNumeric: true,
    isCurrency: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsP50]: {
    isNumeric: true,
    isCurrency: true,
    isConfigurable: true,
  },
  [OnSaleEventsTableColumnId.Action]: {
    isConfigurable: false,
    isRequired: true,
  },
};
