import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { SectionSelector } from 'src/components/Selectors/SectionRowSelector';
import { useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
function TicketGroupInputVenueInputComponent<T, V>({
  cell,
  control,
  style,
}: CellContext<T, V> & {
  control: Control<PurchaseTicketsInput, any>;
  style?: React.CSSProperties;
}) {
  const { setValue, watch, getFieldState, clearErrors } =
    useFormContext<PurchaseTicketsInput>();
  const { venueMapInfo } = useEventMapContext();
  const chooseSection = useContent(ContentId.ChooseSection);
  const section = watch(`ticketGroups.${cell.row.index}.section`);
  const hasErrors = getFieldState(
    `ticketGroups.${cell.row.index}.section`
  )?.error;

  return venueMapInfo?.sections?.length ? (
    <Controller
      name={`ticketGroups.${cell.row.index}.section`}
      control={control}
      render={({ field, fieldState, formState }) => {
        const value = section?.value;
        return (
          <SectionSelector
            className={clsx(styles.tableCell[value ? 'active' : 'disabled'])}
            style={style}
            value={value ?? '-1'}
            displayText={value || chooseSection}
            sections={venueMapInfo?.sections}
            onChange={(selectedSectionId: string) => {
              if (hasErrors && selectedSectionId !== '') {
                clearErrors(`ticketGroups.${cell.row.index}.section`);
              }
              const id = parseInt(selectedSectionId);
              const section = venueMapInfo?.sections?.find((s) => s.id === id);
              const sectionName = section?.name ?? selectedSectionId;
              setValue(
                `ticketGroups.${cell.row.index}.section`,
                posChangedField(sectionName)
              );
              setValue(
                `ticketGroups.${cell.row.index}.sectionId`,
                posChangedField(id)
              );
            }}
          />
        );
      }}
    />
  ) : (
    <PosTextField
      rootProps={{
        className: clsx(
          styles.tableCell[section?.value ? 'disabled' : 'active']
        ),
        style: style,
      }}
      spellCheck={false}
      value={section?.value || ''}
      onChange={(e) => {
        setValue(
          `ticketGroups.${cell.row.index}.section`,
          posChangedField(e.target.value)
        );
      }}
      onBlur={(e) => {
        if (hasErrors && e.target.value !== '') {
          clearErrors(`ticketGroups.${cell.row.index}.section`);
        }
      }}
    />
  );
}

export const TicketGroupInputVenueInput = React.memo(
  TicketGroupInputVenueInputComponent
) as <T, V>(
  props: CellContext<T, V> & {
    control: Control<PurchaseTicketsInput, any>;
    style?: React.CSSProperties;
  }
) => JSX.Element;
