import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { QuickFilter } from 'src/hooks/useQuickFilters';
import { ContentId } from 'src/utils/constants/contentId';
import { getNextDateRange } from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  EventTimeFrameFilter,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';

export const useDefaultListingFilter = ({
  initialQuery,
  listingQuery,
}: {
  initialQuery: ListingQuery;
  listingQuery: ListingQuery;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const defaultQuickFilters = useMemo(() => {
    const next2WeeksRange = getNextDateRange({ weeks: 2 }, new Date());
    const filters: QuickFilter<UserSetting.QuickFiltersCustomInventory>[] = [
      {
        id: 'unsoldTickets',
        name: <Content id={ContentId.UnsoldEvents} />,
        data: {
          ...initialQuery,
          eventHasUnsoldTickets: true,
        },
      },
      {
        id: 'next2Weeks',
        name: <Content id={ContentId.Next2Weeks} />,
        data: {
          ...initialQuery,
          eventTimeFrameFilter: EventTimeFrameFilter.Future,
          eventDates: {
            start: next2WeeksRange.start.toISOString(),
            end: next2WeeksRange.end.toISOString(),
          } as DateTimeRangeWithRelative,
        },
      },
    ];
    if (activeAccountWebClientConfig.activeAccountId) {
      filters.push({
        id: 'imPricing',
        name: <Content id={ContentId.ImPricing} />,
        data: {
          ...initialQuery,
          pricerSellerUserId: activeAccountWebClientConfig.activeAccountId,
        },
      });
    }
    filters.push({
      id: 'broadcastError',
      name: <Content id={ContentId.BroadcastError} />,
      data: {
        ...initialQuery,
        hasBroadcastError: true,
      },
    });

    // Default filters aren't supposed to change view mode
    filters.forEach(
      (f: QuickFilter<UserSetting.QuickFiltersCustomInventory>) => {
        f.data.viewMode = listingQuery.viewMode;
      }
    );
    return filters;
  }, [initialQuery, listingQuery, activeAccountWebClientConfig]);

  return defaultQuickFilters;
};
