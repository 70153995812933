import 'react-autocomplete-input/dist/bundle.css';

import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { CancelButton, IconButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  isContentId,
  useContent,
  useContentContext,
  useContents,
} from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { typography } from 'src/core/themes/shared.css';
import { Button, Popover } from 'src/core/ui';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { OperatorsIcon } from 'src/svgs/OperatorsIcon';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { EventsFormulaFields } from 'src/tables/EventsTable/configs/EventsTableColumnsConfig';
import { SaleFormulaFields } from 'src/tables/SalesTable/SalesTable.type';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import {
  editableFormulaToTokens,
  FORMULA_DISPLAY_ALLOWED_INSERT_CHARS,
  FORMULA_OPERATORS_AND_NUMBERS_REGEXP_START,
  fromExecutableFormula,
  tagKeyToFieldName,
  toExecutableFormula,
} from 'src/utils/columns/customColumnUtils';
import {
  findDependentEventsTableColumnIds,
  validateFormula as validateFormulaEvents,
} from 'src/utils/columns/events/eventsCustomColumnUtils';
import { FORMULA_FIELD_TO_COLUMN_ID as FORMULA_FIELD_TO_COLUMN_ID_EVENT } from 'src/utils/columns/events/eventsCustomColumnUtils.constants';
import {
  CustomEventsColumn,
  CustomOnSaleEventsColumn,
} from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import {
  findDependentListingTableColumnIds as findDependentListingTableColumnIdsListing,
  FORMULA_FIELD_TO_COLUMN_ID as FORMULA_FIELD_TO_COLUMN_ID_LISTING,
  validateFormula as validateFormulaListing,
} from 'src/utils/columns/inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import {
  findDependentListingTableColumnIds as findDependentListingTableColumnIdsListingReport,
  FORMULA_FIELD_TO_COLUMN_ID as FORMULA_FIELD_TO_COLUMN_ID_LISTING_REPORT,
  validateFormula as validateFormulaListingReport,
} from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { CustomMarketplacePaymentsColumn } from 'src/utils/columns/payments/paymentsCustomColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import {
  findDependentSaleTableColumnIds,
  FORMULA_FIELD_TO_COLUMN_ID as FORMULA_FIELD_TO_COLUMN_ID_SALE,
  validateFormula as validateFormulaSales,
} from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import {
  EVENTS_FORMULA_FIELDS_TO_CID,
  EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_FORMULA_FIELDS_TO_CID,
  LISTING_REPORT_FORMULA_FIELDS_TO_CID,
  LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
  ListingFormulaFieldsListing,
  ListingFormulaFieldsListingReportMetrics,
  ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID,
  PAYMENTS_TABLE_COLUMN_ID_TO_CONTENT_ID,
  PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_FORMULA_FIELDS_TO_CID,
  SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { ActionOutboxEntityType, UserSetting } from 'src/WebApiController';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { CustomColumnInstruction } from './CustomColumnInstruction';
import * as styles from './TableColumns.css';

export type CustomColumnType = {
  id: string;
  name: string;
  formula: string;
  dependency: string[];
};

export function TableCustomColumnModal<
  T extends
    | CustomListingColumn
    | CustomSalesColumn
    | CustomEventsColumn
    | CustomPurchasesColumn
    | CustomMarketplacePaymentsColumn
    | CustomOnSaleEventsColumn,
>({
  onClose,
  onSave,
  columnToEditId,
  sectionType,
}: {
  onClose: () => void;
  onSave: (columns: T[]) => void;
  columnToEditId?: string;
  sectionType: SectionType;
}) {
  const [isOpen, toggleIsOpen] = useToggle(true);
  const [showInfo, setShowInfo] = useState(false);
  const [showMetricSelector, setShowMetricSelector] = useState(false);
  const { appContext, loginContext } = useAppContext();
  const { contentResolver } = useContentContext();

  const nameColumnPlaceholder = useContent(ContentId.NameColumnPlaceholder);
  const requiredMsg = useContent(ContentId.Required);
  const invalidFormulaMsg = useContent(ContentId.InvalidFormula);
  const columnWithSameNameExistsMsg = useContent(
    ContentId.ColumnWithSameNameExists
  );

  const allPresetColumnsMap = {
    [SectionType.Events]: EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.Listings]: LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.ListingsFlattened]: {
      ...LISTING_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
      ...LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID,
    },
    [SectionType.ListingsReport]: LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.InventorySideTable]: LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.Sales]: SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.SalesFlattened]: {
      ...SALE_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
      ...SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    },
    [SectionType.SalesReport]: SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.SalesSideTable]: SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.Purchases]: PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.PurchaseEvent]: PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.PurchaseSideTable]: PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.MarketplacePaymentsTable]:
      PAYMENTS_TABLE_COLUMN_ID_TO_CONTENT_ID,
    [SectionType.OnSaleEvents]: ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID,
  }[sectionType] as Record<string, ContentId>;

  const allPresetColumnCid = new Set([...Object.values(allPresetColumnsMap)]);
  const allPresetColumnNames = useContents(
    Array.from(allPresetColumnCid.values())
  );

  const actionOutboxEntityType = useMemo(() => {
    switch (sectionType) {
      case SectionType.Events:
        return ActionOutboxEntityType.SellerEvent;
      case SectionType.Listings:
      case SectionType.ListingsFlattened:
      case SectionType.ListingsReport:
      case SectionType.InventorySideTable:
        return ActionOutboxEntityType.Listing;
      case SectionType.Sales:
      case SectionType.SalesFlattened:
      case SectionType.SalesReport:
      case SectionType.SalesSideTable:
        return ActionOutboxEntityType.Sale;
      case SectionType.Purchases:
      case SectionType.PurchaseEvent:
      case SectionType.PurchaseSideTable:
        return ActionOutboxEntityType.Purchase;
      default:
        return undefined;
    }
  }, [sectionType]);

  const { tagsMetadataNumeric } = useTagsForEntityType(
    actionOutboxEntityType,
    true
  );

  const {
    findDependentListingTableColumnIds,
    validateFormula,
    fieldOptionsPreset,
  } =
    sectionType === SectionType.Listings
      ? {
          findDependentListingTableColumnIds:
            findDependentListingTableColumnIdsListing,
          validateFormula: validateFormulaListing,
          fieldOptionsPreset: LISTING_FORMULA_FIELDS_TO_CID,
        }
      : sectionType === SectionType.ListingsReport
      ? {
          findDependentListingTableColumnIds:
            findDependentListingTableColumnIdsListingReport,
          validateFormula: validateFormulaListingReport,
          fieldOptionsPreset: LISTING_REPORT_FORMULA_FIELDS_TO_CID,
        }
      : sectionType === SectionType.Sales
      ? {
          findDependentListingTableColumnIds: findDependentSaleTableColumnIds,
          validateFormula: validateFormulaSales,
          fieldOptionsPreset: SALE_FORMULA_FIELDS_TO_CID,
        }
      : sectionType === SectionType.SalesReport
      ? {
          findDependentListingTableColumnIds: findDependentSaleTableColumnIds,
          validateFormula: validateFormulaSales,
          fieldOptionsPreset: SALE_FORMULA_FIELDS_TO_CID,
        }
      : {
          findDependentListingTableColumnIds: findDependentEventsTableColumnIds,
          validateFormula: validateFormulaEvents,
          fieldOptionsPreset: EVENTS_FORMULA_FIELDS_TO_CID,
        };

  const filteredFormulaFields = useMemo(
    () =>
      Object.entries(fieldOptionsPreset)
        .filter(([field]) => {
          const columnId =
            sectionType === SectionType.Listings
              ? FORMULA_FIELD_TO_COLUMN_ID_LISTING[
                  field as ListingFormulaFieldsListing
                ]
              : sectionType === SectionType.ListingsReport
              ? FORMULA_FIELD_TO_COLUMN_ID_LISTING_REPORT[
                  field as ListingFormulaFieldsListingReportMetrics
                ]
              : sectionType === SectionType.Sales
              ? FORMULA_FIELD_TO_COLUMN_ID_SALE[
                  field as keyof SaleFormulaFields
                ]
              : sectionType === SectionType.SalesReport
              ? FORMULA_FIELD_TO_COLUMN_ID_SALE[
                  field as keyof SaleFormulaFields
                ]
              : FORMULA_FIELD_TO_COLUMN_ID_EVENT[
                  field as keyof EventsFormulaFields
                ];

          const columnDef = getColumnPersonalization({
            id: columnId ?? '',
            sectionType,
          });
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );
          return hasFeatureForColumn;
        })
        .reduce<Record<string, ContentId>>(
          (acc, [field, contentId]) => ({
            ...acc,
            [field]: contentId,
          }),
          {}
        ),
    [appContext?.features, fieldOptionsPreset, loginContext?.user, sectionType]
  );

  const fieldOptions = useMemo(
    () => ({
      ...filteredFormulaFields,
      ...tagsMetadataNumeric?.reduce<Record<string, string>>(
        (acc, tag) => ({
          ...acc,
          [tagKeyToFieldName(tag.key)]: tag.key,
        }),
        {}
      ),
    }),
    [filteredFormulaFields, tagsMetadataNumeric]
  );

  const fieldOptionsReverse = useMemo(() => {
    return Object.entries(fieldOptions).reduce<Record<string, string>>(
      (acc, entry) => {
        const [key, value] = entry;
        if (!isContentId(value)) {
          acc[value] = key;
          return acc;
        }

        const content = contentResolver(
          ContentIds[value as ContentId]
        ) as string;
        acc[content] = key;
        return acc;
      },
      {}
    );
  }, [contentResolver, fieldOptions]);

  const fieldOptionsDisplay = useMemo(() => {
    const options: Record<string, string> = {};
    Object.keys(fieldOptionsReverse).forEach((key) => {
      options[fieldOptionsReverse[key]] = key;
    });
    return options;
  }, [fieldOptionsReverse]);

  const autoCompleteOptions = useMemo(
    () => Object.keys(fieldOptionsReverse),
    [fieldOptionsReverse]
  );

  const { value: customEventsColumns = [] } = useServerUserSetting<
    CustomEventsColumn[]
  >({
    id: UserSetting.EventsCustomColumns,
  });
  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: UserSetting.InventoryCustomColumns,
  });
  const { value: customSaleColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.SaleCustomColumns,
  });
  const { value: customPurchaseColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.PurchaseCustomColumns,
  });
  const { value: customPaymentsColumns = [] } = useServerUserSetting<
    CustomMarketplacePaymentsColumn[]
  >({
    id: UserSetting.MarketplacePaymentsCustomColumns,
  });
  const { value: customOnSaleEventColumns = [] } = useServerUserSetting<
    CustomOnSaleEventsColumn[]
  >({
    id: UserSetting.OnSaleEventCustomColumns,
  });

  const customColumns = {
    [SectionType.Events]: customEventsColumns,
    [SectionType.Listings]: customListingColumns,
    [SectionType.ListingsFlattened]: customListingColumns,
    [SectionType.ListingsReport]: customListingColumns,
    [SectionType.InventorySideTable]: customListingColumns,
    [SectionType.Sales]: customSaleColumns,
    [SectionType.SalesFlattened]: customSaleColumns,
    [SectionType.SalesReport]: customSaleColumns,
    [SectionType.SalesSideTable]: customSaleColumns,
    [SectionType.Purchases]: customPurchaseColumns,
    [SectionType.PurchaseEvent]: customPurchaseColumns,
    [SectionType.PurchaseSideTable]: customPurchaseColumns,
    [SectionType.MarketplacePaymentsTable]: customPaymentsColumns,
    [SectionType.OnSaleEvents]: customOnSaleEventColumns,
  }[sectionType] as T[];

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm<CustomColumnType>({
    defaultValues: columnToEditId
      ? customColumns.find((c) => c.id === columnToEditId)
      : { id: nanoid() },
  });

  const name = watch('name');
  const [formulaTokens, setFormulaTokens] = useState<string[]>([]);
  const [formulaCaretPosition, setFormulaCaretPosition] = useState<number>(-1);

  const tokenRefs = useRef<HTMLSpanElement[]>([]);

  const validateNewCustomColumn = useCallback(
    (name: string, formula: string) => {
      let hasError = false;

      // check against not only custom column but predefined columns
      const allColumnNames = [
        ...allPresetColumnNames,
        ...customColumns.map((c) => c.name),
      ];
      if (
        allColumnNames.some(
          (n) => n.toLocaleLowerCase() === name.toLocaleLowerCase()
        )
      ) {
        if (
          !columnToEditId ||
          (columnToEditId &&
            customColumns.find((c) => c.id === columnToEditId)?.name !== name)
        ) {
          setError(
            'name',
            { message: columnWithSameNameExistsMsg },
            { shouldFocus: true }
          );
          hasError = true;
        }
      }

      if (!validateFormula(formula, tagsMetadataNumeric)) {
        setError(
          'formula',
          { message: invalidFormulaMsg },
          { shouldFocus: true }
        );
        hasError = true;
      }
      // check empty
      if (!name) {
        setError('name', { message: requiredMsg }, { shouldFocus: true });
        hasError = true;
      }

      if (!formula) {
        setError('formula', { message: requiredMsg }, { shouldFocus: true });
        hasError = true;
      }

      return !hasError;
    },
    [
      allPresetColumnNames,
      columnToEditId,
      columnWithSameNameExistsMsg,
      customColumns,
      invalidFormulaMsg,
      requiredMsg,
      setError,
      tagsMetadataNumeric,
      validateFormula,
    ]
  );

  const onSaveHandler = (newCustomColumn: CustomColumnType) => {
    newCustomColumn.name = newCustomColumn.name.trim();
    newCustomColumn.formula = toExecutableFormula(formulaTokens ?? []).trim();

    if (!validateNewCustomColumn(newCustomColumn.name, newCustomColumn.formula))
      return;

    const editableFormula = fromExecutableFormula(newCustomColumn.formula);
    newCustomColumn.dependency = findDependentListingTableColumnIds(
      editableFormula ?? ''
    );

    onSave([
      ...customColumns.filter((c) =>
        columnToEditId ? c.id !== columnToEditId : true
      ),
      newCustomColumn,
    ] as T[]);

    toggleIsOpen();
  };

  const formulaTokensWithCaret = useMemo(() => {
    if (formulaCaretPosition === -1) return formulaTokens;
    const result = [...formulaTokens];
    result.splice(formulaCaretPosition, 0, '|');
    return result;
  }, [formulaCaretPosition, formulaTokens]);

  const keyUpHandler = useCallback(
    (e: KeyboardEvent) => {
      // Keys are ignored if the editor is out of focus (no caret)
      if (formulaCaretPosition === -1) return;
      if (FORMULA_DISPLAY_ALLOWED_INSERT_CHARS.includes(e.key)) {
        if (
          // Number pressed and the previous token is number --> append
          /[0-9]/.test(e.key) &&
          formulaCaretPosition > 0 &&
          /[0-9]/.test(formulaTokens[formulaCaretPosition - 1])
        ) {
          const newTokens = [...formulaTokens];
          newTokens[formulaCaretPosition - 1] =
            formulaTokens[formulaCaretPosition - 1] + e.key;
          setFormulaTokens(newTokens);
        } else if (
          // Number pressed and the previous next is number --> prepend
          /[0-9]/.test(e.key) &&
          formulaCaretPosition < formulaTokens.length &&
          /[0-9]/.test(formulaTokens[formulaCaretPosition])
        ) {
          const newTokens = [...formulaTokens];
          newTokens[formulaCaretPosition] =
            e.key + formulaTokens[formulaCaretPosition];
          setFormulaTokens(newTokens);
        } else if (e.key === '@') {
          // @ --> open the metric selector dropdown
          setShowMetricSelector(true);
        } else {
          // else: replace the caret with the typed char (number or operator) and set new caret
          const newTokens = [...formulaTokensWithCaret].map((token) =>
            token === '|' ? e.key : token
          );
          setFormulaTokens(newTokens);
          setFormulaCaretPosition(formulaCaretPosition + 1);
        }
      } else if (e.key === 'ArrowLeft') {
        // move caret left
        setFormulaCaretPosition(formulaCaretPosition - 1);
      } else if (e.key === 'ArrowRight') {
        // move caret right
        setFormulaCaretPosition(formulaCaretPosition + 1);
      } else if (e.key === 'Backspace') {
        // delete one token before the caret
        const newTokens = [...formulaTokens];
        newTokens.splice(formulaCaretPosition - 1, 1);
        setFormulaTokens(newTokens);
        setFormulaCaretPosition(formulaCaretPosition - 1);
      } else if (e.key === 'Delete') {
        // delete one token after the caret
        const newTokens = [...formulaTokens];
        newTokens.splice(formulaCaretPosition, 1);
        setFormulaTokens(newTokens);
      }
    },
    [formulaCaretPosition, formulaTokens, formulaTokensWithCaret]
  );

  useEffect(() => {
    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [keyUpHandler]);

  useEffect(() => {
    // Set the text value on load
    if (columnToEditId) {
      const formula = customColumns.find((c) => c.id === columnToEditId)
        ?.formula;
      if (formula && autoCompleteOptions.length) {
        setFormulaTokens(
          editableFormulaToTokens(
            fromExecutableFormula(formula),
            fieldOptionsReverse
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnToEditId, customColumns, autoCompleteOptions.length]);

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  useEffect(() => {
    // this ensures keeping refs up-to-date for click tracking & caret positioning
    tokenRefs.current = Array(formulaTokens.length).fill(null);
    clearErrors('formula');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaTokens]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleIsOpen}
      unmountOnClose
      centered
      size="sm"
      onClick={() => {
        // hide caret
        setFormulaCaretPosition(-1);
        setShowMetricSelector(false);
      }}
    >
      <ModalHeader onClose={toggleIsOpen}>
        <div className={styles.headerBar}>
          <h5 className={clsx(typography.title5, styles.headerText)}>
            {columnToEditId ? (
              <Content id={ContentId.EditACustomColumn} />
            ) : (
              <Content id={ContentId.CreateACustomColumn} />
            )}
          </h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={styles.bodyContainer}>
          <div className={styles.title}>
            <Content id={ContentId.Name} />
          </div>
          <PosFormField errors={errors.name?.message}>
            <PosTextField
              value={name}
              placeholder={nameColumnPlaceholder}
              {...register('name')}
              onChange={(e) => {
                clearErrors('name');
                setValue('name', e.target.value);
              }}
            />
          </PosFormField>
          <div className={styles.title}>
            <Content id={ContentId.FormulaBuilder} />

            <Popover.Root open={showInfo}>
              <Popover.Trigger asChild>
                <div
                  className={styles.iconContainer}
                  onClick={() => setShowInfo((prev) => !prev)}
                >
                  <InfoOutlineIcon withHoverEffect />
                </div>
              </Popover.Trigger>
              <Popover.Content align="center">
                <CustomColumnInstruction />
              </Popover.Content>
            </Popover.Root>
          </div>
          <div className={styles.formulaTokenButtonsWrapper}>
            <PosDropdown
              trigger={
                <Button variant="text" style={{ height: '38px' }}>
                  <div style={{ width: vars.iconSize.xl }}>
                    <IconButton icon={<OperatorsIcon />}></IconButton>
                  </div>
                </Button>
              }
              style={{
                display: 'block',
                margin: 'auto',
                padding: vars.spacing.sm,
              }}
            >
              {['+', '-', '*', '/', '(', ')'].map((label, i) => (
                <div
                  key={i}
                  className={styles.tokenButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    // add to tokens at caret position
                    const newTokens = [...formulaTokensWithCaret].map(
                      (token) => (token === '|' ? label : token)
                    );
                    setFormulaTokens(newTokens);
                    setFormulaCaretPosition(formulaCaretPosition + 1);
                  }}
                >
                  {label}
                </div>
              ))}
            </PosDropdown>
            <PosDropdown
              trigger={
                <Button variant="text">
                  <Content id={ContentId.Metrics} />
                </Button>
              }
              style={{
                display: 'block',
                margin: 'auto',
                padding: vars.spacing.sm,
                width: '40%',
              }}
            >
              {autoCompleteOptions.map((label, i) => (
                <div
                  key={i}
                  className={styles.tokenButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    const tokenToAdd = fieldOptionsReverse[label] ?? '|';
                    // add to tokens at caret position
                    const newTokens = [...formulaTokensWithCaret].map(
                      (token) => (token === '|' ? tokenToAdd : token)
                    );
                    setFormulaTokens(newTokens);
                    setFormulaCaretPosition(formulaCaretPosition + 1);
                  }}
                >
                  {label}
                </div>
              ))}
            </PosDropdown>
          </div>
          <PosFormField errors={errors.formula?.message}>
            <div className={styles.autoCompleteInputWrapper}>
              <div
                contentEditable={false}
                className={styles.formulaEditor}
                onClick={(e) => {
                  e.stopPropagation();
                  const tokenRowRange = formulaTokens
                    .map((token, i) => ({
                      i,
                      token,
                    }))
                    .filter((tokenItem) => {
                      const tokenElement = tokenRefs.current[tokenItem.i];
                      if (!tokenElement) return false;
                      const rect = tokenElement.getBoundingClientRect();
                      return (
                        e.clientY >= rect.y && e.clientY <= rect.y + rect.height
                      );
                    });
                  if (tokenRowRange.length === 0) {
                    setFormulaCaretPosition(formulaTokens.length);
                    return;
                  }
                  const position = tokenRowRange.find((tokenItem) => {
                    const tokenElement = tokenRefs.current[tokenItem.i];
                    if (!tokenElement) return false;
                    const rect = tokenElement.getBoundingClientRect();
                    return rect.x > e.clientX;
                  });
                  if (position) {
                    setFormulaCaretPosition(position.i);
                  } else {
                    // none of them were left of the click
                    const lastInRow = tokenRowRange.at(-1) as {
                      i: number;
                      token: string;
                    };
                    setFormulaCaretPosition(lastInRow.i + 1);
                  }
                }}
              >
                {formulaTokensWithCaret.map((token, i) =>
                  token === '|' ? (
                    showMetricSelector ? (
                      <PosEnumSelect
                        variant="textPlain"
                        shape="none"
                        rootProps={{
                          defaultOpen: true,
                        }}
                        onChange={(metric) => {
                          if (!metric) return;
                          const metricToken = fieldOptionsReverse[metric];
                          if (!metricToken) return;
                          const newTokens = [...formulaTokensWithCaret].map(
                            (token) => (token === '|' ? metricToken : token)
                          );
                          setFormulaTokens(newTokens);
                          setFormulaCaretPosition(formulaCaretPosition + 1);
                          setShowMetricSelector(false);
                        }}
                        searchable
                        valueOptionsContent={autoCompleteOptions.reduce(
                          (options, current) => ({
                            ...options,
                            [current]: current,
                          }),
                          {}
                        )}
                      />
                    ) : (
                      <div key={i} className={styles.caret}>
                        |
                      </div>
                    )
                  ) : (
                    <span
                      key={i}
                      ref={(el: HTMLSpanElement) => {
                        tokenRefs.current[i] = el;
                      }}
                      className={styles.formulaToken}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newCaretPosition =
                          formulaTokens.findIndex((t) => t === token) + 1;
                        setFormulaCaretPosition(newCaretPosition);
                      }}
                    >
                      {FORMULA_OPERATORS_AND_NUMBERS_REGEXP_START.test(token)
                        ? token
                        : fieldOptionsDisplay[token]}
                    </span>
                  )
                )}
              </div>
            </div>
          </PosFormField>
        </div>
      </ModalBody>
      <ModalFooter>
        <CancelButton textContentId={ContentId.Cancel} onClick={toggleIsOpen} />
        <OkButton
          onClick={handleSubmit(onSaveHandler)}
          textContentId={ContentId.Save}
        />
      </ModalFooter>
    </Modal>
  );
}
