import { UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';
import {
  InstructionsList,
  InstructionsListItem,
} from 'src/components/InstructionsList';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { shared, vars } from 'src/core/themes';
import { Button } from 'src/core/ui/Button';
import {
  useTradeDeskResetPasswordMutation,
  useTradeDeskSendResetPasswordCodeMutation,
} from 'src/hooks/api/useTradeDeskResetPasswordMutation';
import { ContentId } from 'src/utils/constants/contentId';

import * as syncCenterStyles from './SyncCenter.css';
import * as styles from './TradeDeskApiResetInstructions.css';
import {useAppContext} from "src/contexts/AppContext";

export function TradeDeskApiResetInstructions({
  marketplaceUserName,
}: {
  marketplaceUserName: string;
}) {
  const { activeAccountWebClientConfig } = useAppContext();

  const enterResetCodeFromEmailText = useContent(
    ContentId.EnterResetCodeFromEmail
  );

  const sendResetPasswordCodeMutation =
    useTradeDeskSendResetPasswordCodeMutation(activeAccountWebClientConfig.activeAccountId);
  const resetPasswordMutation = useTradeDeskResetPasswordMutation(activeAccountWebClientConfig.activeAccountId);
  const [resetCode, setResetCode] = useState('');
  return (
    <div>
      <div className={styles.summary}>
        <span className={shared.typography.subtitle1}>
          <Content id={ContentId.TradeDeskResetSummaryTitle} />
        </span>
        <br />
        <Content id={ContentId.TradeDeskResetSummaryBody} />
      </div>
      <InstructionsList>
        <InstructionsListItem
          containerClassName={styles.instructionListItemContainer}
        >
          <div>
            <Content id={ContentId.TradeDeskResetInstructionsStep1} />{' '}
            <span className={styles.username}>{marketplaceUserName}</span>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              disabled={sendResetPasswordCodeMutation.isPending}
              className={styles.button}
              variant="outline"
              onClick={() => {
                sendResetPasswordCodeMutation.mutate({
                  username: marketplaceUserName,
                });
              }}
            >
              <Content id={ContentId.RequestPasswordResetEmail} />
            </Button>
            <MutationStatus mutation={sendResetPasswordCodeMutation} />
          </div>
        </InstructionsListItem>
        <InstructionsListItem
          containerClassName={styles.instructionListItemContainer}
        >
          <div>
            <Content id={ContentId.TradeDeskResetInstructionsStep2} />
          </div>
          <div className={syncCenterStyles.textField}>
            <PosTextField
              placeholder={enterResetCodeFromEmailText}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setResetCode(value);
              }}
            />
          </div>
        </InstructionsListItem>
        <InstructionsListItem
          containerClassName={styles.instructionListItemContainer}
        >
          <div>
            <Content id={ContentId.TradeDeskResetInstructionsStep3} />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              variant="outline"
              disabled={!resetCode}
              onClick={() => {
                resetPasswordMutation.mutate({
                  username: marketplaceUserName,
                  code: resetCode,
                });
              }}
            >
              <Content id={ContentId.RenewAccessToken} />
            </Button>
            <MutationStatus mutation={resetPasswordMutation} />
          </div>
        </InstructionsListItem>
        <InstructionsListItem
          containerClassName={styles.instructionListItemContainer}
        >
          <Content id={ContentId.TradeDeskResetInstructionsStep4} />
        </InstructionsListItem>
      </InstructionsList>
    </div>
  );
}

function MutationStatus({
  mutation,
}: {
  mutation: Pick<
    UseMutationResult,
    'isPending' | 'isSuccess' | 'isError' | 'error'
  >;
}) {
  return mutation.isPending ? (
    <PosSpinner size={vars.iconSize.xxs} centered={false} />
  ) : mutation.isError && mutation.error instanceof Error ? (
    <div className={styles.failure}>{mutation.error.message}</div>
  ) : mutation.isSuccess ? (
    <div className={styles.success}>
      <Content id={ContentId.Success} />
    </div>
  ) : null;
}
