import {
  ListingDetails,
  SplitListingInput,
  TicketByListingNumber,
} from 'src/WebApiController';

export function getSplitListingToOriginalInput(
  listing: ListingDetails | null | undefined
): SplitListingInput | null {
  if (!listing?.tickets?.length) {
    return null;
  }

  const ticketsByListingNumber: TicketByListingNumber[] = [];

  (listing.tickets ?? []).forEach((t) => {
    if (ticketsByListingNumber.length === 0) {
      ticketsByListingNumber.push({ ticket: t, listingNumber: 1 });
    } else {
      const prev = ticketsByListingNumber[ticketsByListingNumber.length - 1];
      if (prev.ticket.tgId !== t.tgId) {
        ticketsByListingNumber.push({
          ticket: t,
          listingNumber: prev.listingNumber + 1,
        });
      } else {
        ticketsByListingNumber.push({
          ticket: t,
          listingNumber: prev.listingNumber,
        });
      }
    }
  });

  if (ticketsByListingNumber.length < 2) {
    return null;
  }

  const numOfListings =
    ticketsByListingNumber[ticketsByListingNumber.length - 1].listingNumber;

  return {
    sourceListingId: listing.id,
    numOfListings: numOfListings,
    ticketsByListingNumber: ticketsByListingNumber,
    alwaysUnbroadcastListing: true,
    useOriginalTicketGroupInfoForNewListings: true,
  };
}
