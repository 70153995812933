import clsx from 'clsx';
import { vars } from 'src/core/themes';
import { EventWithData } from 'src/WebApiController';

import { DashboardSection } from '../DashboardSection';
import * as styles from '../InventoryEventPage.css';

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  isOnSaleEvent?: boolean;
};

export function InventoryEventDashboardSection({
  accordionItemProps,
  isOnSaleEvent,
}: InventoryEventContentProps) {
  return (
    <div className={styles.tableSection}>
      {accordionItemProps && (
        <div
          className={clsx(
            styles.tableContainer,
            styles.inventoryEventPageTableContainer
          )}
          style={{ gap: vars.spacing['lg'] }}
        >
          <DashboardSection
            event={accordionItemProps}
            isOnSaleEvent={isOnSaleEvent}
          />
        </div>
      )}
    </div>
  );
}
