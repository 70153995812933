import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { PosTableData } from 'src/tables/Table';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput } from 'src/WebApiController';

import { calculateTicketCostUnitCost, isPosField } from '../utils';
import { CellsSelection } from './useSpreadsheetSelection';

type ClipboardEntry<T> = {
  rowOffset: number;
  colOffset: number;
  value: T;
};

export const useSpreadsheetCopyPaste = <T extends PosTableData>({
  selectedCells,
  setSelectedCells,
  columns,
}: {
  selectedCells: CellsSelection | null;
  setSelectedCells: React.Dispatch<React.SetStateAction<CellsSelection | null>>;
  columns: (ColumnDef<T, unknown> & { id: keyof T })[];
}) => {
  const [clipboardData, setClipboardData] = useState<
    ClipboardEntry<string | number | string[] | number[]>[]
  >([]);
  const [isCopyActive, setIsCopyActive] = useState(false);
  const [copiedCells, setCopiedCells] = useState<CellsSelection | null>(null);
  const { getValues, setValue } = useFormContext<PurchaseTicketsInput>();

  useHotkeys(['mod+c'], () => handleCopy(), { enableOnFormTags: true });
  useHotkeys(['mod+v'], () => handlePaste());

  const handleCopy = () => {
    if (!selectedCells) return;

    setCopiedCells(selectedCells);
    setSelectedCells(null);
    setIsCopyActive(true);
    setClipboardData(clipboardData);
  };

  const handlePaste = () => {
    if (!clipboardData || !selectedCells || !copiedCells) return;
    const currentData = getValues('ticketGroups');

    const numColumns = columns.length;

    const targetRow = Math.min(
      selectedCells?.start.rowIndex,
      selectedCells?.end.rowIndex
    );
    const targetCol = Math.min(
      selectedCells?.start.colIndex,
      selectedCells?.end.colIndex
    );

    const minCopiedRow = Math.min(
      copiedCells.start.rowIndex,
      copiedCells.end.rowIndex
    );

    const maxCopiedRow = Math.max(
      copiedCells.start.rowIndex,
      copiedCells.end.rowIndex
    );

    const minCopiedCol = Math.min(
      copiedCells.start.colIndex,
      copiedCells.end.colIndex
    );

    const maxCopiedCol = Math.max(
      copiedCells.start.colIndex,
      copiedCells.end.colIndex
    );

    let maxUpdatedRow = targetRow;
    let maxUpdatedCol = targetCol;

    const colIds = columns.map((col) => col.id as keyof TicketGroupInput);

    for (let r = minCopiedRow; r <= maxCopiedRow; r++) {
      for (let c = minCopiedCol; c <= maxCopiedCol; c++) {
        const rowOffset = r - minCopiedRow;
        const colOffset = c - minCopiedCol;
        const columnId = columns[c].id as keyof TicketGroupInput;

        const value = currentData[r][columnId];
        const pasteRow = targetRow + rowOffset;
        const pasteCol = targetCol + colOffset;

        if (value && pasteRow < currentData.length && pasteCol < numColumns) {
          const columnId = columns[pasteCol].id as keyof TicketGroupInput;
          const pasteVerificationHandler =
            columns[pasteCol].meta?.pasteVerificationHandler;

          const valueIsPosField = isPosField(value);
          if (!valueIsPosField) {
            continue;
          }
          if (pasteVerificationHandler) {
            const isValidType = pasteVerificationHandler(value.value);
            if (!isValidType) {
              continue;
            }
          }

          // TODO: Move this logic out of this hook
          setValue(
            `ticketGroups.${pasteRow}.${columnId}`,
            posChangedField(value.value) as TicketGroupInput[typeof columnId]
          );

          calculateTicketCostUnitCost(
            currentData,
            setValue,
            pasteRow,
            colIds,
            columnId
          );

          maxUpdatedRow = Math.max(maxUpdatedRow, pasteRow);
          maxUpdatedCol = Math.max(maxUpdatedCol, pasteCol);
        }
      }
    }
    setSelectedCells({
      start: {
        rowIndex: targetRow,
        colIndex: targetCol,
      },
      end: {
        rowIndex: maxUpdatedRow,
        colIndex: maxUpdatedCol,
      },
    });

    setIsCopyActive(false);

    setCopiedCells(null);
  };

  return useMemo(
    () => ({
      isCopyActive,
      copiedCells,
    }),
    [isCopyActive, copiedCells]
  );
};
