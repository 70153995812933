import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MapIcon = (props: Omit<PosIconProps, 'viewBox'>) => {
  return (
    <SvgWrapper viewBox="0 0 16 16" {...props}>
      <path
        d="M10.3468 14.4L5.67121 12.7466L2.48899 14.0089C2.28751 14.1155 2.08899 14.1096 1.89343 13.9911C1.69788 13.8726 1.6001 13.6948 1.6001 13.4578V3.53775C1.6001 3.38368 1.64454 3.24738 1.73343 3.12886C1.82232 3.01035 1.93788 2.92146 2.0801 2.8622L5.67121 1.59998L10.3468 3.23553L13.5112 1.97331C13.7127 1.87849 13.9112 1.88738 14.1068 1.99998C14.3023 2.11257 14.4001 2.28738 14.4001 2.52442V12.5689C14.4001 12.6992 14.3557 12.8118 14.2668 12.9066C14.1779 13.0015 14.0682 13.0726 13.9379 13.12L10.3468 14.4ZM9.74232 13.0666V4.08886L6.25788 2.91553V11.8933L9.74232 13.0666ZM10.809 13.0666L13.3334 12.2311V3.12886L10.809 4.08886V13.0666ZM2.66676 12.8533L5.19121 11.8933V2.91553L2.66676 3.75109V12.8533Z"
        fill={props.fill ?? '#677383'}
      />
    </SvgWrapper>
  );
};
