import { Cell, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { PosFormFieldCell } from 'src/core/POS/PosFormField/PosFormFieldCell';

import { PosTableData } from '../Table';
import * as styles from './SpreadsheetTable.css';

function SpreadsheetTdComponent<T extends PosTableData>({
  cell,
  rowIndex,
  handleMouseDown,
  handleMouseEnter,
  isInitialSelected,
  isCellSelected,
  isCellCopied,
  isSingleCellSelected,
  showAnchor,
  setAnchorDragged,
  setIsAnchorSelected,
  fieldError,
}: {
  cell: Cell<T, unknown>;
  rowIndex: number;
  handleMouseDown: (rowIndex: number, colIndex: number) => void;
  handleMouseEnter: (rowIndex: number, colIndex: number) => void;
  isInitialSelected: boolean;
  isCellSelected: boolean;
  isCellCopied: boolean;
  isSingleCellSelected: boolean;
  showAnchor: boolean;
  setAnchorDragged: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAnchorSelected: React.Dispatch<React.SetStateAction<boolean>>;
  fieldError: any;
}) {
  const columnIndex = cell.column.getIndex();
  const backgroundClass = clsx(
    !isInitialSelected && isCellSelected ? styles.backgroundSelected : '',
    isCellSelected && !isSingleCellSelected ? styles.backgroundSelected : '',
    !isCellSelected && !isCellCopied ? styles.backgroundDefault : ''
  );

  return (
    <td
      key={cell.id}
      data-row={rowIndex}
      data-col={columnIndex}
      className={clsx(styles.tableTdBase, backgroundClass)}
      onMouseDown={() =>
        handleMouseDown(cell.row.index, cell.column.getIndex())
      }
      onMouseEnter={(e) =>
        e.buttons === 1 &&
        handleMouseEnter(cell.row.index, cell.column.getIndex())
      }
    >
      <PosFormFieldCell key={cell.id} errors={fieldError.error?.message}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </PosFormFieldCell>

      {showAnchor && (
        <div
          className={styles.anchorWrapper}
          onMouseDown={(e) => {
            e.stopPropagation();
            setAnchorDragged(true);
            setIsAnchorSelected(true);
          }}
        >
          <div className={styles.anchor}></div>
        </div>
      )}
    </td>
  );
}

export const SpreadsheetTd = React.memo(SpreadsheetTdComponent) as <T>(
  props: Parameters<typeof SpreadsheetTdComponent<T>>[0]
) => JSX.Element;
