import { useQuery } from '@tanstack/react-query';
import { PricingClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';

export const useGetActiveVenueZoneMapSectionGroups = (
  viagogoEventId: number
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId != null && viagogoEventId
  );

  const activeVenueZoneMapSectionGroupsQuery = useQuery({
    queryKey: [
      'PricingClient.getActiveVenueZoneMapSectionGroups',
      activeAccountWebClientConfig.activeAccountId,
      viagogoEventId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await new PricingClient(
        activeAccountWebClientConfig
      ).getActiveVenueZoneMapSectionGroups(viagogoEventId);
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getActiveVenueZoneMapSectionGroups', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
            viagogoEventId: viagogoEventId,
          },
        });
      },
    },
  });

  return {
    activeVenueZoneMapSectionGroupsQuery,
    sectionGroupsBySectionId: activeVenueZoneMapSectionGroupsQuery.data,
    isLoading: activeVenueZoneMapSectionGroupsQuery.isLoading,
  };
};
