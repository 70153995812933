import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  PURCHASE_TABLE_COLUMNS_CONFIG,
  tagColumnDef,
} from 'src/tables/PurchaseTable/configs/PurchaseTableColumnsConfig';
import {
  defaultPurchaseEventEnabledColumnsConfig,
  defaultPurchasesColumnsConfig,
  filterColumnsByFeatures,
  getPurchasesColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import {
  ActionOutboxEntityType,
  Feature,
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
  UserSetting,
} from 'src/WebApiController';

interface UseGetPurchasesTableColumnsProps {
  groupId: string; // First of month
  viewMode: PurchaseViewMode;
  columnVisibilityOverride?: PurchasesTableColumnId[];
  isSideTable?: boolean;
  showCheckbox?: boolean;
  hideActionOnCheckbox?: boolean;
}

export const useGetPurchasesTableColumns = ({
  viewMode,
  columnVisibilityOverride,
  isSideTable,
  showCheckbox,
  hideActionOnCheckbox = true,
}: UseGetPurchasesTableColumnsProps) => {
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  const { appContext, loginContext } = useAppContext();

  const {
    value: storedPurchaseColumnOrderSetting = viewMode ===
    PurchaseViewMode.EventTileView
      ? defaultPurchaseEventEnabledColumnsConfig
      : defaultPurchasesColumnsConfig,
  } = useServerUserSetting<PurchasesTableColumnId[]>({
    id: isSideTable
      ? UserSetting.PurchaseSideTableColumnOrder
      : viewMode === PurchaseViewMode.EventTileView
      ? UserSetting.PurchaseEventColumnOrder
      : UserSetting.PurchaseColumnOrder,
  });
  const {
    value: storedPurchaseColumnsEnabledSetting = viewMode ===
    PurchaseViewMode.EventTileView
      ? defaultPurchaseEventEnabledColumnsConfig
      : undefined,
  } = useServerUserSetting<string[]>({
    id: isSideTable
      ? UserSetting.PurchaseSideTableColumnsEnabled
      : viewMode === PurchaseViewMode.EventTileView
      ? UserSetting.PurchaseEventColumnsEnabled
      : UserSetting.PurchaseColumnsEnabled,
  });

  const { tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  return useMemo(() => {
    const customPurchaseColumnsFiltered: CustomPurchasesColumn[] = [];

    const purchaseColumnOrder = columnVisibilityOverride
      ? columnVisibilityOverride
      : storedPurchaseColumnOrderSetting.filter(
          // If storedPurchaseColumnsEnabledSetting has never been set, default to true
          (c) => storedPurchaseColumnsEnabledSetting?.includes(c) ?? true
        );

    const columns = new Set(
      columnVisibilityOverride
        ? columnVisibilityOverride
            .map(
              (cid) => PURCHASE_TABLE_COLUMNS_CONFIG.find((c) => c.id === cid)!
            )

            .map((item) => item.id!)
        : [
            // Add unconfigurable columns first
            ...Object.values(PURCHASE_TABLE_COLUMNS_CONFIG)
              .filter((column) => {
                const columnDef = getPurchasesColumnConfigById(
                  column.id as PurchasesTableColumnId
                ).personalization;
                const hasFeatureForColumn =
                  columnDef.requiredFeatures.length === 0 ||
                  hasFeatures(
                    loginContext?.user,
                    appContext?.features,
                    columnDef.requiredFeatures
                  );

                const visibleInCurrentViewMode =
                  columnDef.visibleViewModes.length === 0 ||
                  columnDef.visibleViewModes.includes(viewMode);

                let isConfigurable = columnDef.isConfigurable;
                if (
                  !hasTablePinActionColumnFeature &&
                  column.id === PurchasesTableColumnId.Actions
                ) {
                  isConfigurable = true;
                }

                return (
                  !isConfigurable &&
                  hasFeatureForColumn &&
                  visibleInCurrentViewMode
                );
              })
              .map((item) => item.id as PurchasesTableColumnId),
            // Add user defined columns next
            ...filterColumnsByFeatures(
              purchaseColumnOrder,
              SectionType.Purchases,
              customPurchaseColumnsFiltered,
              loginContext?.user,
              appContext?.features,
              tagsMetadata ?? [],
              undefined,
              viewMode
            ),
          ]
    );

    if (!columnVisibilityOverride) {
      if (showCheckbox) {
        if (hideActionOnCheckbox) {
          columns.delete(PurchasesTableColumnId.Actions);
        }
      } else {
        columns.delete(PurchasesTableColumnId.Checkbox);
      }
    }

    return Array.from(columns).reduce<
      ColumnDef<PurchaseOrderTicketGroup | null>[]
    >((acc, columnId) => {
      if (tagsMetadata?.find((t) => t.key === columnId)) {
        acc.push(tagColumnDef(columnId));
      } else {
        const columnConfig = Object.values(PURCHASE_TABLE_COLUMNS_CONFIG).find(
          (column) => column.id === columnId
        );
        if (columnConfig) {
          acc.push(columnConfig);
        }
      }
      return acc;
    }, []);
  }, [
    appContext?.features,
    columnVisibilityOverride,
    hasTablePinActionColumnFeature,
    hideActionOnCheckbox,
    loginContext?.user,
    showCheckbox,
    storedPurchaseColumnOrderSetting,
    storedPurchaseColumnsEnabledSetting,
    tagsMetadata,
    viewMode,
  ]);
};
