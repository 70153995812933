import { useMemo } from 'react';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import {
  MetricsDisplay,
  MetricsDisplayPlaceholder,
} from 'src/components/Metrics';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { ContentId } from 'src/utils/constants/contentId';
import { getSalesSaleMetrics } from 'src/utils/ticketMetricUtils';
import { SaleMetrics } from 'src/WebApiController';

import {
  EventItemHeader,
  EventItemHeaderType,
  METRICS_PLACEHOLDER_DIMS,
} from '../../Events/EventItemHeader';
import * as styles from './EventSaleHeader.css';
import { ItemsCountDiv } from './EventSaleHeader.styled';
import { FullPageEventAction } from './FullPageEventAction';

export const EventSaleHeader = (props: EventItemHeaderType) => {
  const { eventMetrics } = useCatalogMetricsContext<SaleMetrics>();

  const { event, entityCount: salesCount, ...rest } = props;
  const metrics = useMemo(
    () => getSalesSaleMetrics(eventMetrics?.[event.viagVirtualId]),
    [eventMetrics, event]
  );
  const { performer, venue } = useGetEventFullInfo(event);

  const {
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();

  const onToggleGroup = (groupId: string) => {
    if (!expandedListItems.includes(groupId)) {
      toggleListItemExpansion([groupId]);
    }
  };

  return (
    <EventItemHeader
      showYear={undefined}
      {...rest}
      event={event}
      performer={performer}
      venue={venue}
      actions={[
        <FullPageEventAction key="FullEventPageToggle" event={event} />,
        <MultiSelectionToggleGroup
          key="SalesEventSelectionGroupToggle"
          groupId={props.event.viagVirtualId}
          onToggle={onToggleGroup}
        />,
      ]}
      headerMetrics={
        metrics && (
          <MetricsDisplay
            key="headermetrics"
            mainMetrics={metrics.headerMetrics}
          />
        )
      }
      metrics={
        <div className={styles.eventSaleMetricsContainer}>
          {!metrics ? (
            <MetricsDisplayPlaceholder
              key="metrics-placeholder"
              {...METRICS_PLACEHOLDER_DIMS}
            />
          ) : (
            <MetricsDisplay
              key="metrics"
              mainMetrics={null}
              subMetrics={metrics.subMetrics}
            />
          )}
          <ItemsCountDiv key="items-count">
            {`${salesCount} `}
            <Content id={salesCount === 1 ? ContentId.Sale : ContentId.Sales} />
          </ItemsCountDiv>
        </div>
      }
    />
  );
};
