import { MainFilterBar } from 'src/components/MainFilterBar';
import { useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { EventDiscoveryContextProvider } from 'src/contexts/EventDiscoveryContext/EventDiscoveryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultOnSaleEventQuery,
  EmptyOnSaleEventQuery,
} from 'src/utils/eventQueryUtils';
import { OnSaleEventQuery, UserSetting } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import { OnSaleEventView } from './OnSaleEventView';

export const DISCOVERY_USER_SETTINGS = [
  UserSetting.QuickFiltersStateOnSaleEvent,
  UserSetting.QuickFiltersCustomOnSaleEvent,
  UserSetting.OnSaleEventColumnEditability,
  UserSetting.OnSaleEventColumnNumberPrecision,
  UserSetting.OnSaleEventColumnsEnabled,
  UserSetting.OnSaleEventColumnsOrder,
  UserSetting.OnSaleEventColumnsWidths,
  UserSetting.OnSaleEventCustomColumns,
];

export function OnSaleEvent() {
  const title = useContent(ContentId.EventDiscovery);
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={DISCOVERY_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<OnSaleEventQuery>
        initialQuery={DefaultOnSaleEventQuery}
        emptyQuery={EmptyOnSaleEventQuery}
        saveQueryInUrl
      >
        <DialogProvider>
          <LayoutContent mainRoute={MainRoute.Discovery} routeTitle={title}>
            <MultiSelectionContextProvider>
              <EventDiscoveryContextProvider>
                <MainFilterBar />
                <OnSaleEventView />
              </EventDiscoveryContextProvider>
            </MultiSelectionContextProvider>
          </LayoutContent>
        </DialogProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}
