import { useQuery } from '@tanstack/react-query';
import { ComponentProps, ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EntityWithRealTicketsPartial } from 'src/components/UploadArtifacts/UploadETicketsV2/views/UploadETicketsSeatAssignmentBody';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  DocumentProcessorClient,
  DocumentType,
  DocumentUploadInfo,
  FileParameter,
  PosClientConfig,
  PosUiActionResult,
  TicketType,
  UserDocument,
  UserDocumentLinks,
} from 'src/WebApiController';

import { DocumentFileUploadInfo, ETicketsForm } from './UploadETickets.utils';

export type UploadETicketFormProps = {
  entityWithTickets: EntityWithRealTicketsPartial;
  renderContent: (
    eTicketUploadInfos: DocumentFileUploadInfo[],
    setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void,
    onNext: () => void,
    onCancel: () => void,
    disabled?: boolean
  ) => ReactNode;
  onComplete: (isCancelled: boolean) => void;
  onUploadDocuments: (
    client: DocumentProcessorClient,
    docUploadInfo: DocumentUploadInfo,
    file: FileParameter
  ) => Promise<void>;
  onUploadETickets: (
    posClientConfig: PosClientConfig,
    entityId: number,
    ticketAssignments: UserDocumentLinks[]
  ) => Promise<PosUiActionResult>;
  ticketType: TicketType.ETicket | TicketType.QRCode;
};

export const UploadETicketForm = (props: UploadETicketFormProps) => {
  const methods = useForm<ETicketsForm>({
    defaultValues: {
      ticketAssignments: props.entityWithTickets.tickets.map((t) => ({
        entityId: t.id,
        pageId:
          (props.ticketType === TicketType.ETicket
            ? t.eTicket?.pageId
            : t.qrCode?.pageId) ?? '',
        documentId:
          (props.ticketType === TicketType.ETicket
            ? t.eTicket?.documentId
            : t.qrCode?.documentId) ?? '',
        pageNumber:
          (props.ticketType === TicketType.ETicket
            ? t.eTicket?.pageNumber
            : t.qrCode?.pageNumber) ?? 0,
        pageUri:
          (props.ticketType === TicketType.ETicket
            ? t.eTicket?.pageUri
            : t.qrCode?.pageUri) ?? '',
        documentUri:
          (props.ticketType === TicketType.ETicket
            ? t.eTicket?.documentUri
            : t.qrCode?.documentUri) ?? '',
        documentType:
          props.ticketType === TicketType.ETicket
            ? (t.eTicket?.documentType ?? DocumentType.ETicket)
            : (t.qrCode?.documentType ?? DocumentType.QRCode),
      })),
    },
  });
  return (
    <FormProvider {...methods}>
      <UploadETicketsContent {...props} {...methods} />
    </FormProvider>
  );
};

const UploadETicketsContent = ({
  entityWithTickets,
  ticketType,
  renderContent,
  onComplete,
  onUploadDocuments,
  onUploadETickets,
  setError,
  clearErrors,
  watch,
  handleSubmit,
}: UploadETicketFormProps &
  Omit<
    ComponentProps<typeof FormProvider<ETicketsForm, unknown>>,
    'children'
  >) => {
  // Source of truth
  const [eTicketUploadInfos, setETicketUploadInfos] = useState<
    DocumentFileUploadInfo[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getIncompleteUploadInfos = (infos?: DocumentFileUploadInfo[]) =>
    infos?.filter(
      (f) => f.file && f.uploadInfo && (!f.pages || f.pages.length === 0)
    ) || [];

  const requiredMsg = useContent(ContentId.Required);

  const query = useQuery({
    queryKey: [
      'pollingForETicketUploadFiles',
      eTicketUploadInfos.map((ui) => ui.file?.name || '').toSorted(),
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      setIsLoading(true);

      const client = new DocumentProcessorClient(activeAccountWebClientConfig);
      let infoChanged = false;

      const results = eTicketUploadInfos.map(async (f) => {
        if (f.pages && f.pages.length > 0) {
          return f; // We already gotten data for this or doesn't have upload info yet, just return
        }

        if (f.file) {
          // If we don't have upload info yet, get it, and upload the file
          if (!f.uploadInfo) {
            // The upload has been deleted and needing to be re-upload
            const uploadInfo = await client.getDocumentUploadInfo(
              entityWithTickets.id,
              ticketType === TicketType.ETicket
                ? DocumentType.ETicket
                : DocumentType.QRCode,
              f.file.type
            );

            await onUploadDocuments(client, uploadInfo, {
              data: f.file,
              fileName: f.file.name,
            });

            f.uploadInfo = uploadInfo;
            infoChanged = true;
          } else {
            try {
              // if we do have upload info, we want to query for the pages
              // but we do not want to do this immediately after the upload, hence, this is an else
              // meaning another refresh cycle has ran in useQuery
              const eTicketPages = await client.getDocumentPages(
                f.uploadInfo.entityId,
                f.uploadInfo.entityType,
                f.uploadInfo.documentType,
                f.uploadInfo.blobName,
                f.uploadInfo.documentId,
                f.uploadInfo.contentType
              );

              infoChanged = true;
              return { ...f, pages: eTicketPages } as DocumentFileUploadInfo;
            } catch (e) {
              // Remove the file with errors
              setETicketUploadInfos(
                eTicketUploadInfos.filter(
                  (documentFileUpload) => documentFileUpload !== f
                )
              );
              showErrorDialog(
                'DocumentProcessorClient.getDocumentPages',
                { message: (e as Error)?.message ?? '' },
                {
                  trackErrorData: {
                    eTicketUploadInfos,
                  },
                }
              );
            }
          }
        }

        return f;
      });

      let allResults = await Promise.all(results);

      const fileWithNoPages = getIncompleteUploadInfos(allResults);
      const isAllLoaded = fileWithNoPages.length === 0;
      if (isAllLoaded) {
        setIsLoading(false);
      }

      const emptyEntries = allResults.filter(
        (u) =>
          u.file === undefined &&
          u.uploadInfo === undefined &&
          u.pages === undefined
      );
      if (emptyEntries.length !== 1) {
        const nonEmptyEntries = allResults.filter(
          (u) =>
            !(
              u.file === undefined &&
              u.uploadInfo === undefined &&
              u.pages === undefined
            )
        );
        // Always ensure there's an extra empty-upload-info
        // This allow the seat-assignment to add additional files
        nonEmptyEntries.push({
          file: undefined,
          uploadInfo: undefined,
          pages: undefined,
        } as DocumentFileUploadInfo);

        allResults = nonEmptyEntries;
        infoChanged = true;
      }

      if (infoChanged) {
        setETicketUploadInfos(allResults);
      }
      return allResults;
    },

    staleTime: Infinity, // We don't want useQuery to auto-refresh base on stale, we control our own refetch
    gcTime: 0, // we don't want to cache this data
    refetchOnWindowFocus: false,
    networkMode: 'always',
    refetchInterval: () => {
      if (!eTicketUploadInfos) {
        return 1000;
      }
      const fileWithNoPages = getIncompleteUploadInfos(eTicketUploadInfos);
      return fileWithNoPages.length > 0 ? 1000 : false; // keep on refreshing until all files in prev (uploadInfos) has pages
    },
    meta: {
      persist: false, // we do not want this thing persisted between sessions
    },
  });

  if (query.failureReason) {
    showErrorDialog(
      'DocumentProcessorClient.pollingForETicketUploadFiles',
      { message: query.failureReason.message },
      {
        trackErrorData: {
          eTicketUploadInfos,
        },
      }
    );
  }

  const validateTicketAssignment = useCallback(
    (t: UserDocument, index: number) => {
      if (!t.documentId || !t.pageId) {
        setError(
          `ticketAssignments.${index}`,
          {
            message: requiredMsg,
          },
          { shouldFocus: true }
        );

        return false;
      } else {
        clearErrors(`ticketAssignments.${index}`);

        return true;
      }
    },
    [clearErrors, requiredMsg, setError]
  );

  const onSubmit = useCallback(
    async ({ ticketAssignments }: ETicketsForm) => {
      // Validate first
      let isValid = true;
      ticketAssignments.forEach((t, i) => {
        isValid = isValid && validateTicketAssignment(t, i);
      });

      if (!isValid) {
        return;
      }

      tryInvokeApi(
        async () => {
          setIsLoading(true);
          const result = await onUploadETickets?.(
            activeAccountWebClientConfig,
            entityWithTickets.id,
            ticketAssignments
          );
          if (!isSuccess(result)) {
            showErrorDialog(
              'SaleClient.saveETicketAssignments',
              {
                message: result!.message ?? genericError,
                status: result!.status!,
              } as ApiException,
              {
                onDismissError: () => onComplete(true),
                trackErrorData: {
                  entityId: entityWithTickets.id,
                  ticketAssignments,
                },
              }
            );
          } else {
            onComplete(false);
          }
        },
        (error) => {
          showErrorDialog('SaleClient.saveETicketAssignments', error, {
            trackErrorData: {
              entityId: entityWithTickets.id,
              ticketAssignments,
            },
          });
        },
        () => setIsLoading(false)
      );
    },
    [
      validateTicketAssignment,
      onUploadETickets,
      activeAccountWebClientConfig,
      entityWithTickets.id,
      showErrorDialog,
      genericError,
      onComplete,
    ]
  );

  const onCancel = () => {
    setETicketUploadInfos([]);
    setIsLoading(false);
    onComplete(true);
  };

  const onNext = useCallback(async () => {
    if (!isLoading) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isLoading, onSubmit]);

  return renderContent(
    eTicketUploadInfos,
    setETicketUploadInfos,
    onNext,
    onCancel,
    isLoading
  );
};
