import { ComponentProps, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';

import { ReportBuilderDialogBodyV2 } from './ReportBuilderDialogBodyV2';

export type ReportBuilderDialogV2Props = {
  report: ReportConfigV2;
  onSave: (data: ReportConfigV2) => void;
} & ComponentProps<typeof RSModal>;

export function ReportBuilderDialogV2({
  report,
  ...rest
}: ReportBuilderDialogV2Props) {
  const methods = useForm<ReportConfigV2>({
    defaultValues: report,
  });

  useEffect(() => {
    methods.reset(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reportId]);

  return (
    <FormProvider {...methods}>
      <ReportBuilderDialogBodyV2 {...methods} {...rest} />
    </FormProvider>
  );
}
