import { FormEvent, useCallback } from 'react';
import { UpDownComboButton } from 'src/core/ui';
import { SectionInfo } from 'src/WebApiController';

export type SectionFilterRowNudgerProps = {
  disabled?: boolean;
  selectedSections: SectionInfo[];
  rowIdFilters?: number[];
  sectionRowIdFilters?: { [key: string]: string };
  isCondensedView?: boolean;
  applyRowFilterChanges: (
    rowIdFiltersNew: number[],
    sectionRowIdFiltersNew: {
      [key: string]: string;
    }
  ) => void;
};
export const SectionFilterRowNudger = ({
  disabled,
  selectedSections,
  rowIdFilters,
  sectionRowIdFilters,
  isCondensedView,
  applyRowFilterChanges,
}: SectionFilterRowNudgerProps) => {
  const handleNudgeRow = useCallback(
    (isUp: boolean) => {
      let newRowIdFilters = [...(rowIdFilters ?? [])];
      const newSectionRowIdFilters = { ...(sectionRowIdFilters ?? {}) };

      let hasChanged = false;

      // for each section row id filters...
      Object.entries(newSectionRowIdFilters).map(([sectionId, rowId]) => {
        const sid = Number(sectionId);
        const rid = Number(rowId);

        const section = selectedSections.find((s) => s.id === sid);

        if (section) {
          const row = section.rows.find((r) => r.id === rid);
          if (row && row.ordinal != null) {
            const nextOrdinal = isUp ? row.ordinal + 1 : row.ordinal - 1;

            if (nextOrdinal >= 0) {
              // We purposely not checking for nextOrdinal < section.rows.length in case the ordinal is not 0 or 1 base
              // it's more robust to just find the row matching the next ordinal at this point

              // try to find the next row
              const nextRow = section.rows.find(
                (r) => r.ordinal === nextOrdinal
              );

              if (nextRow) {
                // Great, we found it, updating the rowIdFilters and sectionRowIdFilters

                if (isUp) {
                  // we only need to add nextrow id if we're going up
                  newRowIdFilters.push(nextRow.id);
                } else {
                  // we only need to remove row ids if we're going down
                  newRowIdFilters = newRowIdFilters.filter(
                    (rid) => rid != row.id
                  );
                }

                newSectionRowIdFilters[sectionId] = nextRow.id.toString();

                hasChanged = true;
              }
            }
          }
        }
      });

      if (hasChanged) {
        applyRowFilterChanges(newRowIdFilters, newSectionRowIdFilters);
      }
    },
    [applyRowFilterChanges, rowIdFilters, sectionRowIdFilters, selectedSections]
  );

  const handleNudgeUp = useCallback(
    (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleNudgeRow(true);
    },
    [handleNudgeRow]
  );

  const handleNudgeDown = useCallback(
    (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleNudgeRow(false);
    },
    [handleNudgeRow]
  );

  return (
    <UpDownComboButton
      disabled={disabled}
      isCondensedView={isCondensedView}
      onUpClick={handleNudgeUp}
      onDownClick={handleNudgeDown}
    />
  );
};
