import { isEmpty } from 'lodash-es';
import { getContent, IContentContext } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { LISTING_GROUPING_TO_CID } from 'src/utils/constants/contentIdMaps';
import { newGuidId } from 'src/utils/idUtils';
import { kmeans } from 'src/utils/kmeans';
import { getSeatScore } from 'src/utils/seatScoreUtils';
import { findTicketClass } from 'src/utils/venueConfigUtils';
import { BucketType, EventWithData, GroupingType } from 'src/WebApiController';
import {
  Event,
  GroupType,
  IListingGroupItem,
  Listing,
  ListingGroup,
  ListingMarketplaceSetting,
  MergeListingGroupInput,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import {
  GroupingTemplateSeating,
  ListingGroupBy,
  ListingGroupingTypesWithClusteringOptions,
} from './groupingTypes';

export const getGroupByData = (
  listing: IListingGroupItem | GroupingTemplateSeating,
  groupingType: GroupingType,
  listingToGroupKey: Record<string, { key: string | number; name: string }>,
  contentContext: IContentContext,
  scoreOverrides?: SectionScoreOverride[] | null,
  sections?: SectionInfo[] | null
) => {
  const prevKey = listingToGroupKey[listing.id];
  const prevKeyPrefix = prevKey ? `${prevKey.key}|` : '';
  const prevNamePrefix = prevKey ? `${prevKey.name} - ` : '';
  const nameContent = getNameContent(groupingType, contentContext);

  switch (groupingType) {
    case GroupingType.Section:
      return {
        key: prevKeyPrefix + listing.seating.section,
        name: prevNamePrefix + `${nameContent} ${listing.seating.section}`,
        value: listing.seating.section,
      };
    case GroupingType.Row:
      return {
        key: listing.seating.rowId
          ? prevKeyPrefix + listing.seating.rowId
          : prevKeyPrefix +
            `${listing.seating.section}__${listing.seating.row}`,
        name: prevNamePrefix + `${nameContent} ${listing.seating.row}`,
        value: listing.seating.row,
      };

    case GroupingType.Zone: {
      const tc = findTicketClass(listing.seating, sections);
      return {
        key: prevKeyPrefix + (tc?.id ?? 0),
        name: prevNamePrefix + `${nameContent} ${tc?.name || ''}`,
        value: tc?.name || '',
      };
    }
    case GroupingType.Quantity:
      return {
        key: prevKeyPrefix + listing.availQty,
        name: prevNamePrefix + `${nameContent} ${listing.availQty}`,
        value: listing.availQty + '',
      };
    case GroupingType.UnitCost:
      return {
        key:
          prevKeyPrefix +
          (listing.unitCst?.amt || listing?.faceValue?.amt || 0),
        name:
          prevNamePrefix +
          `${nameContent} ${
            listing.unitCst?.disp ??
            listing?.faceValue?.disp ??
            (listing.unitCst?.amt || listing?.faceValue?.amt)
          }`,
        value: (listing.unitCst?.amt || listing?.faceValue?.amt || 0) + '',
      };
    case GroupingType.SeatScore: {
      const score =
        getSeatScore(listing.seating, scoreOverrides, sections) ?? 0;
      return {
        key: prevKeyPrefix + score,
        name: prevNamePrefix + `${nameContent} ${score}`,
        value: score + '',
      };
    }
  }

  return {
    key: prevKeyPrefix + 0,
    name: prevNamePrefix + nameContent,
    value: nameContent,
  };
};

export const getNameContent = (
  groupingType: GroupingType,
  contentContext: IContentContext
) => {
  switch (groupingType) {
    case GroupingType.Section:
      return getContent(ContentId.Section, contentContext);
    case GroupingType.Row:
      return getContent(ContentId.Row, contentContext);
    case GroupingType.Zone:
      return getContent(ContentId.Zone, contentContext);
    case GroupingType.Quantity:
      return getContent(ContentId.UnsoldQty, contentContext);
    case GroupingType.UnitCost:
      return getContent(ContentId.UnitCost, contentContext);
    case GroupingType.SeatScore:
      return getContent(ContentId.SeatScore, contentContext);
  }

  return '';
};

export const getClusteringData = (
  listing: IListingGroupItem | GroupingTemplateSeating,
  groupingType: GroupingType,
  scoreOverrides?: SectionScoreOverride[] | null,
  sections?: SectionInfo[] | null,
  rowIdToRowOrdinalMap?: Record<number, number> | undefined
) => {
  switch (groupingType) {
    case GroupingType.Quantity:
      return listing.availQty;
    case GroupingType.UnitCost:
      return listing.unitCst?.amt ?? 0;
    case GroupingType.SeatScore: {
      const score =
        getSeatScore(listing.seating, scoreOverrides, sections) ?? 0;
      return score;
    }
    case GroupingType.Row:
      // At this point, we should never have a case where listing.seating.rowId is null or
      // a null rowIdToRowOrdinalMap. However, we should have the safety guards in place in case
      return rowIdToRowOrdinalMap?.[listing.seating.rowId ?? 0] ?? 0;
  }
};

export const flattenListingGroup = (l?: Listing | null) =>
  l == null
    ? []
    : [
        l,
        ...((l as ListingGroup).groupItems ?? []),
        ...((l as ListingGroup)?.groupItems ?? []).flatMap(
          (l2) => (l2 as ListingGroup)?.groupItems ?? []
        ),
      ].filter((l) => !l.isLtGrp);

export const filterAndflattenListingGroup =
  (groupFilter = (l: IListingGroupItem) => true) =>
  (l?: Listing | null) =>
    l == null
      ? []
      : [
          l,
          ...((l as ListingGroup).groupItems ?? []),
          ...((l as ListingGroup)?.groupItems ?? [])
            .filter(groupFilter)
            .flatMap((l2) => (l2 as ListingGroup)?.groupItems ?? [])
            .filter(groupFilter),
        ].filter((l) => !l.isLtGrp);

export const getListingsForMergeListingGroupInput = (
  x: MergeListingGroupInput | null | undefined,
  events: EventWithData[]
) => {
  const ev = events.find(
    (ev) =>
      ev.event.viagVirtualId ===
      (x?.viagogoEventId?.toString() ?? x?.viagogoMappingId)
  );

  return ev?.entities?.listings ?? [];
};

export const generateMergeListingGroupInputsForEvents = (
  events: Event[] | null | undefined,
  namePrefix: string,
  listingMarketplaceSettings?: {
    [key: string]: ListingMarketplaceSetting;
  } | null
) => {
  return (
    events?.map(
      (ev, i) =>
        ({
          listingGroupId: newGuidId(),
          name: `${namePrefix} ${i + 1}`,
          listingGroupItems: [],
          viagogoEventId: ev.viagId,
          viagogoMappingId: ev.mappingId,
          desiredActiveListings: 0,
          minActiveListings: null,
          maxActiveListings: null,
          targetPercentage: null,
          deprioritizedQuantities: null,
          marketplaceSettings: listingMarketplaceSettings
            ? { listingMarketplaceSettings }
            : null,
          groupUndercutSetting: {
            undAbsAmt: null,
            undRelAmt: null,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          },
          groupingMethods: {
            groupingMethods: [],
          },
        }) as MergeListingGroupInput
    ) ?? []
  );
};

export const getAvailableGroupingTypes = (
  prevGroupingTypes: GroupingType[]
) => {
  const listingGroupTypes = {
    ...LISTING_GROUPING_TO_CID,
  } as Record<string, ContentId>;

  // Do not allow duplicate group-bys
  if (prevGroupingTypes.length) {
    prevGroupingTypes.forEach((t) => {
      delete listingGroupTypes[t];
    });

    // Custom is not allowed as follow-up group-bys
    delete listingGroupTypes[GroupingType.Custom];
  } else {
    // None is not allowed as the top group-by
    delete listingGroupTypes[GroupingType.None];
  }

  return listingGroupTypes;
};

export const getListingGroupsMap = <T extends GroupingTemplateSeating>(
  groupBys: ListingGroupBy[],
  contentContext: IContentContext,
  listingsForGrouping: T[],
  scoreOverrides?: SectionScoreOverride[] | null,
  sections?: SectionInfo[] | null,
  rowIdToRowOrdinalMap?: Record<number, number> | undefined,
  canClusterByRows = false
) => {
  const result: Record<
    string,
    { name: string; data: T[]; values: (string | null)[] }
  > = {};

  if (listingsForGrouping == null || !listingsForGrouping.length) {
    return result;
  }

  const listingToGroupKey: Record<
    string,
    { key: string; name: string; values: (string | null)[] }
  > = {};
  const listingGroupState: Record<
    string,
    {
      listingsInBucket: number;
      bucketCounter: number;
      data: GroupingTemplateSeating[];
    }
  > = {};

  const nonKmeansGrouping = (groupBy: ListingGroupBy) => {
    return listingsForGrouping.map((l) => {
      const key = getGroupByData(
        l,
        groupBy.groupingType,
        listingToGroupKey,
        contentContext,
        scoreOverrides,
        sections
      );
      return {
        data: [l],
        ...key,
      };
    });
  };

  const kmeansGrouping = (groupBy: ListingGroupBy) => {
    const dataset = listingsForGrouping.map((l) => {
      const value =
        getClusteringData(
          l,
          groupBy.groupingType,
          scoreOverrides,
          sections,
          rowIdToRowOrdinalMap
        ) ?? 0;
      return {
        vector: [value],
        data: l,
        value: value + '',
      };
    });
    return kmeans<IListingGroupItem | GroupingTemplateSeating>(
      dataset,
      groupBy.numOfClusters ?? 1 // defaults to 1
    ).clusters.flatMap((c, i) => {
      const data = c.vectors.map((d) => d.data);
      if (Object.keys(listingToGroupKey).length === 0) {
        return [
          {
            data: data,
            key: i.toString(),
            name:
              getNameContent(groupBy.groupingType, contentContext) + ' ' + i,
            value: c.value + '',
          },
        ];
      }

      // Each cluster needs to permutate with the previous key
      // ie, if the prev group is A, B, C and the new clustering has 1,2, then you will end up with
      // A1, A2, B1, B2, and C1, C2 and then which ones are empty - ignore
      const resultMap = data.reduce(
        (r, l) => {
          const prevKey = listingToGroupKey[l.id];
          const prevKeyPrefix = prevKey ? `${prevKey.key}|` : '';
          const key = prevKeyPrefix + i.toString();
          const name = (prevKey ? `${prevKey.name} - ` : '') + i;

          if (!r[key]) {
            r[key] = { name: name, data: [l] };
          } else {
            r[key].data.push(l);
          }

          return r;
        },
        {} as Record<
          string,
          {
            name: string;
            data: (IListingGroupItem | GroupingTemplateSeating)[];
          }
        >
      );

      return Object.keys(resultMap).map((k) => ({
        data: resultMap[k].data,
        key: k,
        name: resultMap[k].name,
        value: c.value + '',
      }));
    });
  };

  const sameSizeGrouping = (groupBy: ListingGroupBy) => {
    // The UI has a guard against this, but adding a check here to ensure no division by zero
    let numberOfBuckets = groupBy.numOfClusters ?? 1;
    if (numberOfBuckets < 1) {
      numberOfBuckets = 1;
    }

    // We take ceiling because we want the last bucket to have the remainder
    // i.e. 9 listings, 2 buckets. Bucket 1 should have 5, bucket 2 should have 4
    let listingsPerBucket = Math.ceil(
      listingsForGrouping.length / numberOfBuckets
    );

    let bucketCounter = 0;
    return listingsForGrouping.map((l, i) => {
      const prevKey = listingToGroupKey[l.id];
      const prevKeyPrefix = prevKey ? `${prevKey.key}|` : '';
      const prevNamePrefix = prevKey ? `${prevKey.name} - ` : '';

      // If prevKey is set then we are in "ThenBy" cluase. We should operate on the subset
      // of listings, and not the entire group. This means that we need to keep track of the
      // following values on a per group basis:
      // 1. The number of listings in the group
      // 2. The number of listings we've added to our group sub-bucket so far
      // 3. The group specific bucket counter
      if (prevKey) {
        const listingsInGroupCount = listingGroupState[prevKey.key].data.length;
        listingsPerBucket = Math.ceil(listingsInGroupCount / numberOfBuckets);
        const listingsInBucket =
          listingGroupState[prevKey.key].listingsInBucket;

        // Make sure we increment the right bucket counter
        if (listingsInBucket % listingsPerBucket === 0) {
          listingGroupState[prevKey.key].bucketCounter++;
        }

        // Increment the number of listings in the bucket
        listingGroupState[prevKey.key].listingsInBucket++;

        // Bucket counter state should be maintained per group
        bucketCounter = listingGroupState[prevKey.key].bucketCounter;
      } else {
        // Top level fixed size bucketing scenario. A lot less information we need to keep track of
        if (i % listingsPerBucket === 0) {
          bucketCounter++;
        }
      }
      return {
        key: prevKeyPrefix + bucketCounter,
        name: prevNamePrefix + `Bucket ${bucketCounter}`,
        data: [l],
        value: `${numberOfBuckets}`,
      };
    });
  };

  groupBys.forEach((groupBy) => {
    if (groupBy.groupingType === GroupingType.None) return;

    const bucketOption = groupBy.bucketOption;

    const shouldUseNormalCluster =
      !ListingGroupingTypesWithClusteringOptions.includes(
        groupBy.groupingType
      ) ||
      (groupBy.groupingType === GroupingType.Row && !canClusterByRows);

    const clusters = shouldUseNormalCluster
      ? nonKmeansGrouping(groupBy)
      : bucketOption == BucketType.SameSize
      ? sameSizeGrouping(groupBy)
      : kmeansGrouping(groupBy);

    clusters.forEach((c) => {
      c.data.forEach((l) => {
        if (!listingToGroupKey[l.id]) {
          listingToGroupKey[l.id] = {
            key: c.key.toString(),
            name: c.name,
            values: [c.value],
          };
        } else {
          listingToGroupKey[l.id].values.push(c.value);
        }

        if (!listingGroupState[c.key]) {
          listingGroupState[c.key] = {
            listingsInBucket: 0,
            bucketCounter: 0,
            data: [l],
          };
        } else {
          listingGroupState[c.key].data.push(l);
        }
      });
    });
  });

  const listingGroupsMap = listingsForGrouping.reduce(
    (acc, l) => {
      const key = listingToGroupKey[l.id];

      if (key) {
        if (!acc[key.key]) {
          acc[key.key] = { name: key.name, data: [l], values: key.values };
        } else {
          acc[key.key].data.push(l);
        }
      }
      return acc;
    },
    {} as Record<string, { name: string; data: T[]; values: (string | null)[] }>
  );

  return listingGroupsMap;
};

export const quantityMatches = (
  quantity: number,
  desiredQuantities: string[] | null
): boolean => {
  if (isEmpty(desiredQuantities)) return false;

  return (
    desiredQuantities?.some((temp) => {
      if (temp.includes('+')) {
        return quantity >= parseInt(temp.replace('+', ''));
      }
      return quantity === parseInt(temp);
    }) ?? false
  );
};

export const getQuantitiesOptions = () =>
  ['1', '2', '3', '4', '5', '6', '7', '8+'].reduce(
    (acc, q) => ({
      ...acc,
      [q]: q,
    }),
    {} as Record<string, string>
  );

export const sortGroupType = (a: GroupType, b: GroupType) => {
  const order = {
    Invisible: 1,
    FullySold: 2,
    Inactive: 3,
    Active: 4,
    Leader: 5,
  };

  // Sort as the reverse order
  return order[b] - order[a];
};
