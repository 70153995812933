import { vars } from 'src/core/themes';
import { PurchasePaymentStatus } from 'src/WebApiController';
import styled from 'styled-components/macro';

export const Title = styled.h2<{ showOnMobile?: boolean }>`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title3', 'regular')};
  display: ${(props) => (props.showOnMobile ? 'flex' : 'none')};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      display: flex;
      `
    )}`}

  justify-content: center;
  padding-block-end: ${() => vars.spacing.xl};
`;

export const WizardFooter = styled.footer`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: ${() => vars.spacing.xxl};
  justify-content: space-between;
  padding: ${() => vars.spacing.xl};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      margin-bottom: 0;
      `
    )}`}
`;

export const EmailPreviewContainer = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.xl}`};
  display: flex;
  flex-direction: column;
`;

export const EmailBodyWrapper = styled.div`
  flex: 1;
  width: 300px;
  border: 2px solid ${(props) => props.theme.colors.borderPrimary};
  overflow-y: scroll;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.lg,
      `
      width: 500px;
      `
    )}`}
`;

export const EmailPreviewTitle = styled.div`
  ${(props) => props.theme.typography.getStyles('subtitle1')};
  margin: 0;
  padding-block-end: ${() => vars.spacing.sm};
`;

export const StyledPurchaseWizardBody = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
`;

export const BodySectionTitle = styled.div`
  ${(props) => props.theme.typography.getStyles('subtitle1')};
  color: ${(props) => props.theme.colors.textBrand};
  text-transform: uppercase;
  margin: 0;
  padding-block-end: ${() => vars.spacing.lg};
`;

export const TicketsDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-end: ${() => vars.spacing.sm};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('subtitle1', 'regular')};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding-block-end: ${vars.spacing.lg};
      `
    )}`}
`;

export const TicketDetailRowPaymentList = styled(TicketsDetailRow)`
  overflow-x: auto;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${() => vars.spacing.lg};

  label {
    min-width: min-content;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      flex-direction: column;
      `
    )}`}
  > * {
    width: max-content !important;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const FieldWrapper = styled.div<{
  width?: string;
  minWidth?: string;
  addFlexGrow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block-end: ${() => vars.spacing.m};
  width: ${(props) => (props.width != null ? props.width : '100%')};
  min-width: ${(props) => (props.minWidth != null ? props.minWidth : 'auto')};
  ${({ addFlexGrow }) => (addFlexGrow ? 'flex-grow: 1;' : null)};

  label {
    width: 100%;
    margin-bottom: 0;
  }
`;
export const FieldLabel = styled.div<{ isCheckbox?: boolean }>`
  text-transform: capitalize;
  font-size: ${() => vars.typography.fontSize['lg']};
  font-weight: 400;
  padding-left: ${(props) => (props.isCheckbox ? vars.spacing.sm : 0)};
`;

export const HeaderFieldLabel = styled.div<{ isCheckbox?: boolean }>`
  text-transform: capitalize;
  font-size: ${() => vars.typography.fontSize['sm']};
  font-weight: 400;
  padding-left: ${(props) => (props.isCheckbox ? vars.spacing.sm : 0)};
`;

export const FieldError = styled.div`
  color: ${(props) => props.theme.colors.textNegative};
  font-size: ${() => vars.typography.fontSize['sm']};
  margin-top: ${() => vars.spacing.xxs};
  margin-bottom: ${() => vars.spacing.xxs};
`;

export const FormRow = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  gap: ${() => vars.spacing.lg};
`;
export const PurchaseDetailsDataTable = styled.table`
  border-spacing: 8px 0;
  border-collapse: separate;

  svg {
    display: block;
  }

  td {
    padding-right: ${() => vars.spacing.xl};
  }
`;

export const PurchaseDetailsDataTableCell = styled.div<{
  align?: 'left' | 'center' | 'right';
}>`
  border-spacing: ${() => vars.spacing.lg} 0;
  border-collapse: separate;
  vertical-align: middle;
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.align === 'center'
      ? 'center'
      : props.align === 'right'
      ? 'flex-end'
      : 'flex-start'};
`;

export const PurchaseDetailsDataTableFooter = styled(
  PurchaseDetailsDataTableCell
)`
  border-top: 2px solid ${(props) => props.theme.colors.borderDark};
  margin-top: ${() => vars.spacing.sm};
`;

export const BodySectionTitleTicketGroup = styled(BodySectionTitle)`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${() => vars.spacing.sm};
  }
`;

export const PaymentStatusTextContainer = styled.span<{
  purchasePaymentStatus: PurchasePaymentStatus;
  isPaymentPastDueDate: boolean;
  isPaymentRefunded: boolean;
}>`
  color: ${(props) => {
    switch (props.purchasePaymentStatus) {
      case PurchasePaymentStatus.Paid:
        return props.isPaymentRefunded
          ? props.theme.colors.textInfo
          : props.theme.colors.textSuccess;
      case PurchasePaymentStatus.Unpaid:
        return props.isPaymentPastDueDate
          ? props.theme.colors.textNegative
          : props.theme.colors.textPrimary;
      case PurchasePaymentStatus.Failed:
        return props.theme.colors.textNegative;
      default:
        return props.theme.colors.textPrimary;
    }
  }};
`;
export const PurchasePaymentStatusTextContainer = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
  background: ${(props) => props.theme.colors.backgroundSecondary};
  font-size: ${() => vars.typography.fontSize['xs']};
  padding: ${() =>
    `${vars.spacing.xs} ${vars.spacing.sm} ${vars.spacing.xs} ${vars.spacing.sm}`};
  float: right;
  border-radius: ${() => vars.spacing.sm};

  &.Paid {
    color: ${(props) => props.theme.colors.textSuccess};
    background: ${(props) => props.theme.colors.backgroundSuccess};
  }

  &.Failed {
    color: ${(props) => props.theme.colors.textNegative};
    background: ${(props) => props.theme.colors.backgroundNegative};
  }
`;
