import { useCallback, useMemo, useState } from 'react';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { getAllInPriceFromListPriceForListing } from 'src/utils/inventoryUtils';
import {
  BulkListingPriceCalculation,
  CompListing,
  Listing,
  ListingPriceCalculation,
} from 'src/WebApiController';

import { ComparableListing, ComparableListings } from '../ComparableListings';
import { ListingsCarousel } from '../ListingsCarousel/ListingsCarousel';
import * as styles from './CompListingsPreview.css';

export type CompListingsPreviewProps = {
  isLoading: boolean;
  listings: Listing[];
  listingPriceCalculations: BulkListingPriceCalculation[];
  compListings: CompListing[];
  sellerCompListings: CompListing[];
};

export const CompListingsPreview = ({
  isLoading,
  listings,
  listingPriceCalculations,
  compListings,
  sellerCompListings,
}: CompListingsPreviewProps) => {
  const [selectedListing, setSelectedListing] = useState<Listing | null>(
    listings.length == 1 ? listings[0] : null
  );

  const listingPriceCalculationsByListingId = useMemo(() => {
    const record: Record<number, BulkListingPriceCalculation> = {};
    listingPriceCalculations.forEach((x) => {
      record[x.listingId] = x;
    });

    return record;
  }, [listingPriceCalculations]);

  const selectedListingPriceCalculation = useMemo(
    () =>
      selectedListing
        ? listingPriceCalculationsByListingId[selectedListing.id]
        : null,
    [listingPriceCalculationsByListingId, selectedListing]
  );

  const mapListingToComparableListing = useCallback(
    (listing: Listing): ComparableListing => {
      const websitePrice =
        listingPriceCalculationsByListingId[listing.id]?.websitePrice?.amt ??
        getAllInPriceFromListPriceForListing(listing);

      return {
        listingId: listing.id,
        section: listing.seating.section,
        row: listing.seating.row ?? undefined,
        seatFr: listing.seating.seatFr ?? undefined,
        seatTo: listing.seating.seatTo ?? undefined,
        price: websitePrice,
        currencyCode: listing.currency,
        listingNotes: listing.seatTraits ?? undefined,
        // Price assuming same fees as seller’s account
        sellerNetProceeds: listing.listPrice,
        isYourListing: true,
        availableTickets: listing.availQty,
        validPurchaseQuantities: listing.vldPurQty,
        isSelectedWithListingGroup: false,
      };
    },
    [listingPriceCalculationsByListingId]
  );

  const isComparableToSelectedListing = useCallback(
    (cl: CompListing) => {
      if (cl.isSellerCompListing) {
        return (
          selectedListingPriceCalculation?.sellerCompStubHubListingIds.includes(
            cl.listingId
          ) ?? false
        );
      }

      const isComparableListing =
        selectedListingPriceCalculation?.compStubHubListingIds.includes(
          cl.listingId
        ) ?? false;
      const isOutlierListing =
        selectedListingPriceCalculation?.outlierStubHubListingIds.includes(
          cl.listingId
        ) ?? false;

      return !selectedListing || isComparableListing || isOutlierListing;
    },
    [
      selectedListing,
      selectedListingPriceCalculation?.compStubHubListingIds,
      selectedListingPriceCalculation?.outlierStubHubListingIds,
      selectedListingPriceCalculation?.sellerCompStubHubListingIds,
    ]
  );

  const mapCompListingToComparableListing = useCallback(
    (cl: CompListing): ComparableListing => {
      const isAnchor =
        selectedListingPriceCalculation?.underCutChosenListingId ==
        cl.listingId;
      const isOutlier =
        selectedListingPriceCalculation?.outlierStubHubListingIds.includes(
          cl.listingId
        ) ?? false;

      return {
        listingId: cl.listingId,
        section: cl.section ?? undefined,
        row: cl.row ?? undefined,
        seatFr: cl.seatFrom ?? undefined,
        seatTo: cl.seatTo ?? undefined,
        price: cl.sellerAllInPrice?.amt,
        currencyCode: cl.sellerAllInPrice?.currency ?? undefined,
        listingNotes: cl.listingNotes ?? undefined,
        // Price assuming same fees as seller’s account
        sellerNetProceeds: cl.sellerNetProceeds?.amt ?? undefined,
        isAnchor: isAnchor,
        isOutlier: isOutlier,
        isYourListing: cl.isSellerCompListing ?? false,
        availableTickets: cl.availableTickets ?? undefined,
        validPurchaseQuantities: cl.validPurchaseQuantities ?? undefined,
        isSelectedWithListingGroup: false,
      };
    },
    [
      selectedListingPriceCalculation?.outlierStubHubListingIds,
      selectedListingPriceCalculation?.underCutChosenListingId,
    ]
  );

  const currentListings = useMemo(() => {
    if (selectedListing) {
      return [mapListingToComparableListing(selectedListing)];
    }

    return listings.map((l) => mapListingToComparableListing(l));
  }, [listings, mapListingToComparableListing, selectedListing]);

  const currentCompListings = useMemo(
    () =>
      [...compListings, ...sellerCompListings].filter(
        isComparableToSelectedListing
      ),
    [compListings, isComparableToSelectedListing, sellerCompListings]
  );

  const currentComparableListings = useMemo(
    () => currentCompListings.map(mapCompListingToComparableListing),
    [currentCompListings, mapCompListingToComparableListing]
  );

  const listingPriceCalcWithComps = useMemo(
    (): ListingPriceCalculation | null =>
      selectedListingPriceCalculation
        ? {
            ...selectedListingPriceCalculation,
            compListings: currentCompListings,
          }
        : null,
    [currentCompListings, selectedListingPriceCalculation]
  );

  return isLoading ? (
    <PosSpinner />
  ) : (
    <Stack direction="column" className={styles.root}>
      {listings.length > 1 && (
        <ListingsCarousel
          listings={listings}
          selectedListing={selectedListing}
          setSelectedListing={setSelectedListing}
        />
      )}

      <ComparableListings
        currentListings={currentListings}
        comparableListings={currentComparableListings}
        isLtGrp={true}
        sortBy="price"
        showAutoPriceResults={!!selectedListing}
        listingPriceCalc={listingPriceCalcWithComps}
        floorPrice={selectedListingPriceCalculation?.cmpFlr?.amt}
        ceilingPrice={selectedListingPriceCalculation?.cmpCeil?.amt}
      />
    </Stack>
  );
};
