import { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { VenueRowInput } from 'src/components/Input/VenueRowInput';
import { VenueSectionInput } from 'src/components/Input/VenueSectionInput';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useGetSectionRowFromVenueMapConfig } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Switch } from 'src/core/interim/Switch';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Colors } from 'src/core/themes/constants/colors';
import { Button } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanUpdateListing } from 'src/hooks/useUserHasListingPermissions';
import {
  Detail,
  DetailGroup,
  DetailSection,
  SectionContent,
} from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { SPLIT_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import { INT_MAX_VALUE } from 'src/utils/numberFormatter';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import {
  Feature,
  ListingDetails,
  ListingDetailsTicketSectionUpdates,
  SplitType,
  Ticket,
} from 'src/WebApiController';

import { LaunchViewAllSeats } from '../../actions/LaunchViewAllSeats';

const MAX_SEATS_DISPLAY = 10;

export const getSeatDisplay = (ticket: Ticket, navigate?: NavigateFunction) => {
  const { saleId, seat, ord } = ticket;

  if (ticket.saleId) {
    return (
      <Button
        key={ticket.id}
        variant={'link'}
        onClick={() =>
          navigate ? navigate(getSaleDetailsRelativeUrl(saleId!)) : null
        }
      >
        {seat ? (
          <>{seat}</>
        ) : (
          <FormatContent
            id={FormatContentId.SeatNumber}
            params={ord.toString()}
          />
        )}
      </Button>
    );
  } else {
    return seat ? (
      <>{seat}</>
    ) : (
      <FormatContent
        key={ticket.id}
        id={FormatContentId.SeatNumber}
        params={ord.toString()}
      />
    );
  }
};

export const TicketsSection = ({
  listing,
  disabled,
  isBulkEdit,
}: {
  listing?: ListingDetails | undefined;
  disabled?: boolean;
  isBulkEdit?: boolean;
}) => {
  const navigate = useNavigate();
  const {
    setValue,
    watch,
    clearErrors,
    formState: { defaultValues, errors },
  } = useFormContext<ListingDetailsTicketSectionUpdates>();

  const userHasWasteSaleFeature = useUserHasFeature(Feature.WasteSale);

  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ??
          loginContext?.user?.activeAccount?.currencyCode ??
          'USD'
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const splitType = watch('splitType');
  const hideSeats = watch('hideSeatsFromMarketplace');
  const manualMaxDisplayQuantity = watch('manualMaxDisplayQuantity');
  const cancelTo = getListingDetailsModalConfigWithDeepLink(listing?.id);

  const isMobile = useMatchMedia('mobile');

  const { tickets, ticketCnt, seating } = listing ?? {};

  const availableSeats = useMemo(() => {
    if (!tickets?.length || !listing) {
      return [];
    }
    const ticketsAvailable = tickets.filter((t) => !t.saleId);

    const ticketsElement = ticketsAvailable
      .slice(0, MAX_SEATS_DISPLAY)
      .map((t) => <Fragment key={t.id}>{getSeatDisplay(t)}</Fragment>)
      .flatMap((value, index, array) =>
        array.length - 1 !== index
          ? [value, <Fragment key={`comma_${index}`}>{', '}</Fragment>]
          : value
      );

    if (ticketsAvailable.length > MAX_SEATS_DISPLAY) {
      ticketsElement.push(
        <Fragment key="viewAllSeats">
          {' ... '}
          <LaunchViewAllSeats
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            cancelTo={cancelTo}
            seeAvailableSeats
          />
        </Fragment>
      );
    }
    return ticketsElement;
  }, [tickets, listing, cancelTo]);

  const soldSeats = useMemo(() => {
    if (!tickets?.length || !listing) {
      return [];
    }
    const ticketsSold = tickets.filter((t) => t.saleId && !t.isWasted);

    const ticketsElement = ticketsSold
      .slice(0, MAX_SEATS_DISPLAY)
      .map((t) => <Fragment key={t.id}>{getSeatDisplay(t, navigate)}</Fragment>)
      .flatMap((value, index, array) =>
        array.length - 1 !== index
          ? [value, <Fragment key={`comma_${index}`}>{', '}</Fragment>]
          : value
      );

    if (ticketsSold.length > MAX_SEATS_DISPLAY) {
      ticketsElement.push(
        <Fragment key="viewAllSeats">
          {' ... '}
          <LaunchViewAllSeats
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            cancelTo={cancelTo}
          />
        </Fragment>
      );
    }
    return ticketsElement;
  }, [tickets, listing, navigate, cancelTo]);

  const wastedTickets = useMemo(() => {
    if (!tickets?.length || !listing || !userHasWasteSaleFeature) {
      return [];
    }
    const wastedTickets = tickets.filter((t) => !!t.saleId && t.isWasted);

    const wastedTicketsElements = wastedTickets
      .slice(0, MAX_SEATS_DISPLAY)
      .map((t) => <Fragment key={t.id}>{getSeatDisplay(t, navigate)}</Fragment>)
      .flatMap((value, index, array) =>
        array.length - 1 !== index
          ? [value, <Fragment key={`comma_${index}`}>{', '}</Fragment>]
          : value
      );
    if (wastedTicketsElements.length > MAX_SEATS_DISPLAY) {
      wastedTicketsElements.push(
        <Fragment key="viewAllWastedSeats">
          {' ... '}
          <LaunchViewAllSeats
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            cancelTo={cancelTo}
            seeWastedSeats={true}
          />
        </Fragment>
      );
    }
    return wastedTicketsElements;
  }, [cancelTo, listing, navigate, tickets, userHasWasteSaleFeature]);

  const canUpdate = useUserCanUpdateListing(listing, isBulkEdit);

  const isDisabled = disabled || listing?.isDeleted || !canUpdate;

  const onShownQuantityChange = (
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseInt(changeEvent?.target?.value);
    if (newValue > 0 && newValue < INT_MAX_VALUE) {
      const validatedValue = Math.min(newValue, listing?.availQty ?? 0) || null;
      setValue('manualMaxDisplayQuantity', validatedValue);
    } else {
      setValue('manualMaxDisplayQuantity', null);
    }
  };

  const section = watch('section');
  const sectionId = watch('sectionId');
  const row = watch('row');
  const rowId = watch('rowId');
  const faceValueCost = watch('faceValue');

  const { section: selectedSection, row: selectedRow } =
    useGetSectionRowFromVenueMapConfig(rowId, sectionId, section, row);

  return (
    <DetailSection name={<Content id={ContentId.Tickets} />}>
      <SectionContent numOfColumns={isMobile ? 3 : 5}>
        <DetailGroup style={{ gridColumn: isMobile ? '1' : '1/2' }}>
          <Detail
            label={<Content id={ContentId.SplitType} />}
            detail={
              <PosEnumSelect
                valueOptionsContent={SPLIT_TYPE_TO_CID}
                disabled={isDisabled}
                enableEmptySelection={isBulkEdit}
                placeholderText={ContentId.DontChange}
                value={
                  splitType || isBulkEdit
                    ? splitType
                    : availableSeats.length <= 1
                    ? SplitType.Any
                    : SplitType.AvoidOne
                }
                defaultValue={defaultValues?.splitType}
                onChange={(newSplitType: SplitType | null) => {
                  if (newSplitType !== splitType) {
                    setValue(
                      'splitType',
                      newSplitType || isBulkEdit
                        ? newSplitType
                        : SplitType.AvoidOne
                    );
                  }
                }}
              />
            }
          />
        </DetailGroup>
        <DetailGroup>
          <Detail
            label={<Content id={ContentId.HideSeats} />}
            detail={
              <Switch
                checked={hideSeats || false}
                disabled={isDisabled}
                onChange={(e) => {
                  const newHideSeats = e.target.checked;
                  if (newHideSeats !== hideSeats) {
                    setValue('hideSeatsFromMarketplace', newHideSeats);
                  }
                }}
              />
            }
          />
        </DetailGroup>
        {!isBulkEdit && listing && seating && (
          <>
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.ShownQuantity} />}
                detail={
                  <PosTextField
                    type="number"
                    disabled={isDisabled}
                    value={manualMaxDisplayQuantity ?? ''}
                    onChange={onShownQuantityChange}
                    min={0}
                    max={listing.availQty}
                  />
                }
                style={{
                  width: '8em',
                }}
              />
            </DetailGroup>
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.UnsoldQuantity} />}
                detail={<span>{listing.unsoldQty}</span>}
              />
            </DetailGroup>
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.TotalQuantity} />}
                detail={<span>{ticketCnt}</span>}
              />
            </DetailGroup>
            <DetailGroup style={{ gridColumn: '1' }}>
              <PosFormField
                label={<Content id={ContentId.Section} />}
                errors={errors.section?.message}
              >
                {!listing.isSeatSaver ? (
                  <span>{section}</span>
                ) : (
                  <VenueSectionInput
                    fieldError={errors.section}
                    section={section}
                    selectedSection={selectedSection}
                    disabled={disabled}
                    onChange={(sectionName, section) => {
                      clearErrors('section');
                      if (section) {
                        setValue('section', section.name);
                        setValue('sectionId', section.id);

                        if (section.specRow?.id) {
                          // Setting the section automatically set the speculative row id
                          setValue('rowId', section.specRow?.id);
                          setValue('row', '');
                        }
                      } else {
                        setValue('section', sectionName ?? '');
                        setValue('sectionId', null);

                        // Free-text section do not have speculative row info
                        setValue('rowId', null);
                        setValue('row', '');
                      }
                    }}
                  />
                )}
              </PosFormField>
            </DetailGroup>
            <DetailGroup>
              <PosFormField label={<Content id={ContentId.Row} />}>
                {!listing.isSeatSaver ? (
                  <span>{row}</span>
                ) : (
                  <VenueRowInput
                    row={row}
                    selectedRow={selectedRow}
                    selectedSection={selectedSection}
                    disabled={disabled}
                    onChange={(rowName, row) => {
                      clearErrors('row');
                      if (row) {
                        setValue('row', row.isSpeculative ? '' : row.name);
                        setValue('rowId', row.id);
                      } else {
                        // If we can't find the row - that mean they typed it in
                        // so just keep with they type as the row
                        setValue('row', rowName ?? '');
                        setValue('rowId', null);
                      }
                    }}
                  />
                )}
              </PosFormField>
            </DetailGroup>
            <DetailGroup style={{ gridColumn: isMobile ? '1' : '3/5' }}>
              <Detail
                label={<Content id={ContentId.Seats} />}
                detail={
                  <>
                    <div style={{ marginBottom: '5px' }}>
                      <span style={{ color: Colors.gray400 }}>
                        <Content id={ContentId.Available} />
                        {': '}
                      </span>
                      {availableSeats}
                    </div>
                    <div>
                      <span style={{ color: Colors.gray400 }}>
                        <Content id={ContentId.Sold} />
                        {': '}
                      </span>
                      {soldSeats}
                    </div>
                    {userHasWasteSaleFeature && (
                      <div>
                        <span style={{ color: Colors.gray400 }}>
                          <Content id={ContentId.Wasted} />
                          {': '}
                        </span>
                        {wastedTickets}
                      </div>
                    )}
                  </>
                }
              />
            </DetailGroup>

            {listing?.isSeatSaver && (
              <DetailGroup style={{ gridColumn: isMobile ? '1' : '5' }}>
                <PosFormField label={<Content id={ContentId.FaceValue} />}>
                  <PosCurrencyField
                    disabled={disabled}
                    uiCurrency={uiCurrency}
                    value={faceValueCost ?? ''}
                    onChange={(e) => {
                      const v = parseFloat(e.target.value) || 0;
                      if (v >= 0 && v <= Number.MAX_VALUE) {
                        setValue('faceValue', v);
                      } else {
                        setValue('faceValue', null);
                      }
                    }}
                  />
                </PosFormField>
              </DetailGroup>
            )}
          </>
        )}
      </SectionContent>
    </DetailSection>
  );
};
