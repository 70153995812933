import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

export const LaunchBulkMarkSaleAsPaid = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasBulkMarkSaleAsPaidFeature = useUserHasFeature(
    Feature.BulkMarkSalesAsPaid
  );

  if (!hasBulkMarkSaleAsPaidFeature) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="mark-sale-as-paid"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <span style={{ textAlign: 'left' }}>
            <Content id={ContentId.MarkAsPaid} />
          </span>
        </Stack>
      </PosDropdownItem>
    </>
  );
};
