import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContentContext } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Feature, ListingNote, ListingNoteClient } from 'src/WebApiController';

import { MultiSelectDropBoxWithPillsProps } from '../../../core/POS/MultiSelectDropBoxWithPills';

export type UseListingNotesSelectorOptions = {
  disabled?: boolean;
  additionalOptions?: Record<string, string>;
  additionalOptionsPosition?: 'top' | 'bottom';
  validListingNoteIds?: number[] | null;
  placeholderText?: string;
};

export const useListingNotesSelector = ({
  disabled,
  additionalOptions,
  additionalOptionsPosition = 'bottom',
  validListingNoteIds,
  placeholderText = ContentId.ChooseSeatingNotes,
}: UseListingNotesSelectorOptions): {
  query: UseQueryResult<ListingNote[] | null>;
  multiSelectorProps: Pick<
    MultiSelectDropBoxWithPillsProps,
    | 'searchable'
    | 'allowSearchAsInput'
    | 'placeholderText'
    | 'valueOptionsContent'
    | 'valueOptionsIcon'
  >;
} => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;

  const query = useQuery({
    queryKey: [
      'getAccessibleListingNotes',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new ListingNoteClient(
            activeAccountWebClientConfig
          ).getAccessibleListingNotes();
        },
        (error) => {
          trackError('ListingNoteClient.getAccessibleListingNotes', error);
        }
      );
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      persist: false, // we do not want this thing persisted between sessions
    },
  });

  const hasAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );
  const availableNotes = useMemo(() => {
    const dataSorted =
      query.data?.sort((a, b) => {
        return a.listingNoteText.localeCompare(b.listingNoteText);
      }) ?? [];

    const options: Record<string, string> = {};
    for (const ln of dataSorted ?? []) {
      if (
        ln.listingNoteText.trim() ||
        ln.listingNoteUntranslated.trim() ||
        (ln.stubhubListingNoteId &&
          validListingNoteIds?.includes(ln.stubhubListingNoteId))
      ) {
        options[ln.listingNoteId] =
          ln.listingNoteText.trim() ||
          ln.listingNoteUntranslated.trim() ||
          ln.stubhubListingNoteId?.toString() ||
          '';
      }
    }
    return {
      ...(additionalOptionsPosition === 'top' ? additionalOptions : {}),
      ...(options ?? {}),
      ...(additionalOptionsPosition === 'bottom' ? additionalOptions : {}),
    };
  }, [
    query.data,
    additionalOptionsPosition,
    additionalOptions,
    validListingNoteIds,
  ]);

  const contentContext = useContentContext();
  const noteIcons = useMemo(() => {
    const optionIcons: Record<string, ReactNode> = {};
    for (const ln of query.data ?? []) {
      const icon = getListingNoteIcon(ln, contentContext, true);

      optionIcons[ln.listingNoteId] = icon;
    }
    return optionIcons;
  }, [contentContext, query.data]);

  return {
    query,
    multiSelectorProps: {
      searchable: true,
      allowSearchAsInput: true,
      placeholderText: placeholderText,
      valueOptionsContent: availableNotes,
      valueOptionsIcon: hasAdditionalInfoFeature ? noteIcons : undefined,
    },
  };
};
