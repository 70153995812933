import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReportMetricsContextV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ReportsV2LocationState } from 'src/navigations/Routes/Reports/ReportsV2.types';

import { ReportsFilterToolbarV2 } from '../ReportsFilterToolbar/ReportsFilterToolbarV2';
import { ReportsPageToolbarV2 } from '../ReportsPageToolbar/ReportsPageToolbarV2';
import { ReportsTableEditor } from '../ReportsTableEditor';
import * as styles from './ReportsPageLayout.css';

export const ReportsPageLayout = ({
  reportConfig,
  reportTable,
}: {
  reportConfig: ReportConfigV2;
  reportTable: ReactNode;
}) => {
  const { isLoading } = useReportMetricsContextV2();
  const [isEditTable, setIsEditTable] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Check if the location state has a value for isCreateNewReport
    const isCreateNewReport = (location.state as ReportsV2LocationState)
      ?.isCreateNewReport;

    if (isCreateNewReport) {
      setIsEditTable(true);
    } else {
      // Default to false if no state is provided
      setIsEditTable(false);
    }
  }, [location]);

  return (
    <div className={styles.root} style={{ flexDirection: 'row' }}>
      <Stack direction="column" className={styles.mainContent}>
        <ReportsPageToolbarV2
          reportConfig={reportConfig}
          isEditTable={isEditTable}
          setIsEditTable={setIsEditTable}
        />

        <ReportsFilterToolbarV2 reportConfig={reportConfig} />
        {isLoading ? (
          <PosSpinner />
        ) : (
          <div className={styles.tableContainer}>{reportTable}</div>
        )}
      </Stack>
      {isEditTable && (
        <ReportsTableEditor
          reportConfig={reportConfig}
          onClose={() => setIsEditTable(false)}
        />
      )}
    </div>
  );
};
