import { TreeNodeData, useTree } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import {
  FormatContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { DocumentTree } from 'src/core/ui/DocumentTree';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { NoData } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  TutorialItem,
  TutorialItemContent,
  TutorialItemType,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './HelpCenter.css';
import { useGetHelpData } from './useGetHelpData';

type TutorialItemTreeNode = TreeNodeData & {
  meta: TutorialItem;
  content?: TutorialItemContent;
};

export function HelpCenter() {
  const helpCenterText = useContent(ContentId.HelpCenter);

  const { isLoading, allAvailableKbs, getDataContent } = useGetHelpData();
  const { contentResolver } = useContentContext();

  const [treeContent, setTreeContent] = useState<
    Record<number, TutorialItemContent>
  >({});
  const [displayContentIsLoading, setDisplayContentIsLoading] = useState(false);
  const [displayContent, setDisplayContent] = useState<TutorialItemContent>();
  const [searchText, setSearchText] = useState<string>();

  const extractTitle = useCallback(
    (item: TutorialItem) => {
      return (
        (item.titleContentId
          ? contentResolver(
              ContentIds[`cid$#${item.titleContentId}#` as ContentId]
            )
          : undefined) || item.title
      );
    },
    [contentResolver]
  );

  const recursiveSearch = useCallback(
    (ti: TutorialItemTreeNode): boolean => {
      if (searchText) {
        const title = extractTitle(ti.meta);
        if (
          (title as string).toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }

        // check children
        if (ti.children?.length) {
          return ti.children.some((child) =>
            recursiveSearch(child as TutorialItemTreeNode)
          );
        }

        return false;
      }

      return true;
    },
    [extractTitle, searchText]
  );

  const treeData = useMemo(() => {
    if (!allAvailableKbs) {
      return [];
    }

    const allTreeNodes = allAvailableKbs
      .map(
        (i) =>
          ({
            label: extractTitle(i),
            value: i.tutorialItemId.toString(),
            meta: i,
            content: treeContent[i.tutorialItemId],
          }) as TutorialItemTreeNode
      )
      .sort((a, b) => {
        const sortVal =
          (a.meta.tutorialItemType === TutorialItemType.Folder ? 1 : 2) -
          (b.meta.tutorialItemType === TutorialItemType.Folder ? 1 : 2);

        if (sortVal !== 0) {
          return sortVal;
        }

        return a.meta.title.localeCompare(b.meta.title);
      });

    const allTreeNodesMap = {} as Record<number, TutorialItemTreeNode>;
    let tree = [] as TutorialItemTreeNode[];
    allTreeNodes.forEach((i) => {
      if (i.meta.parentTutorialItemId == null) {
        allTreeNodesMap[i.meta.tutorialItemId] = i;
        tree.push(i);
      } else if (i.meta.parentTutorialItemId) {
        if (!allTreeNodesMap[i.meta.parentTutorialItemId]) {
          const parent = allTreeNodes.find(
            (p) => i.meta.tutorialItemId === p.meta.parentTutorialItemId
          );
          if (parent) {
            allTreeNodesMap[parent.meta.tutorialItemId] = parent;
            tree.push(parent);

            parent.children = [...(parent.children || []), i];
          } else {
            console.error('HelpCenter: parent not found for', i);
          }
        } else {
          const parent = allTreeNodesMap[i.meta.parentTutorialItemId];
          parent.children = [...(parent.children || []), i];
        }
      }
    });

    tree = tree.filter((ti) => recursiveSearch(ti));

    return tree;
  }, [allAvailableKbs, extractTitle, recursiveSearch, treeContent]);

  const onDocumentClick = useCallback(
    async (_: React.MouseEvent, node: TreeNodeData) => {
      const tutorialNode = node as TutorialItemTreeNode;
      if (tutorialNode.meta) {
        let data: TutorialItemContent | null =
          treeContent[tutorialNode.meta.tutorialItemId];
        if (!data) {
          setDisplayContentIsLoading(true);
          try {
            data = await getDataContent(tutorialNode.meta.tutorialItemId);
            if (data) {
              setTreeContent((prev) => ({
                ...prev,
                [data!.tutorialItemId]: data!,
              }));
              setDisplayContent(data);
            }
          } finally {
            setDisplayContentIsLoading(false);
          }
        } else {
          setDisplayContent(data);
        }
      }
    },
    [getDataContent, treeContent]
  );

  const treeHook = useTree();

  const onSearchChange = useCallback(
    (value: string) => {
      value = value.trim();
      if (value !== searchText) {
        setSearchText(value);

        // Search will expand all to show the search results
        if (
          value &&
          Object.values(treeHook.expandedState).filter((b) => !b).length > 0 // there are unexpanded nodes
        ) {
          treeHook.expandAllNodes();
        } else if (
          // Clear search will collapse all
          !value &&
          Object.values(treeHook.expandedState).filter((b) => b).length > 0 // there are expanded nodes
        ) {
          treeHook.collapseAllNodes();
        }
      }
    },
    [searchText, treeHook]
  );

  return (
    <LayoutContent
      mainRoute={MainRoute.HelpCenter}
      routeTitle={helpCenterText}
      rightContent={
        <PosSearchBox maxWidth={350} onSearchChange={onSearchChange} />
      }
    >
      <Stack
        gap="l"
        style={{
          flex: 1,
          height: 0 /* to fill the remaining space using only flex-grow */,
          marginTop: vars.spacing.xl,
        }}
      >
        {isLoading ? (
          <PosSpinner />
        ) : (
          <>
            <div className={styles.mainContent}>
              {allAvailableKbs ? (
                <DocumentTree
                  data={treeData}
                  selectOnClick
                  clearSelectionOnOutsideClick
                  allowRangeSelection={false}
                  onNodeClick={onDocumentClick}
                  tree={treeHook}
                />
              ) : (
                <NoData>
                  <FormatContent
                    id={FormatContentId.NoDataAvailable}
                    params={'information'}
                  />
                </NoData>
              )}
            </div>

            <div className={styles.viewContainer}>
              {displayContent == null && displayContentIsLoading ? (
                <PosSpinner />
              ) : displayContent != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: displayContent.contentHtml,
                  }}
                />
              ) : null}
            </div>
          </>
        )}
      </Stack>
    </LayoutContent>
  );
}
