import React from 'react';
import { VenueNameDisplay } from 'src/components/Events/EventInfo';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import * as styles from 'src/tables/Table/Table.css';
import {
  Event,
  Performer,
  TopLevelCategory,
  Venue,
} from 'src/WebApiController';

type VenueDisplayCellProps = {
  event: Event;
  performer?: Performer;
  venue?: Venue;
  isSinglePerformerView?: boolean;
};

export const VenueDisplayCell: React.FC<VenueDisplayCellProps> = ({
  event,
  performer,
  venue,
  isSinglePerformerView,
}) => {
  const lowerCasedEventName = event.name.toLocaleLowerCase();
  const lowerCasedPerformer = performer?.name?.toLocaleLowerCase();
  const doNotShowVenue =
    event.genre === TopLevelCategory.Sports && isSinglePerformerView;
  const displayVenueOnly =
    isSinglePerformerView &&
    (event.genre === TopLevelCategory.Concert ||
      event.genre === TopLevelCategory.Theatre) &&
    lowerCasedEventName === lowerCasedPerformer;
  return (
    <TableCellDiv align="left" className={styles.basicFont} title={venue?.name}>
      {(!doNotShowVenue || displayVenueOnly) && (
        <VenueNameDisplay
          venueName={venue?.shortName || venue?.name}
          slimMode
          showTitle={false}
        />
      )}
    </TableCellDiv>
  );
};
