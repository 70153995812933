import React, { useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { compareNumber } from 'src/utils/miscUtils';
import {
  Feature,
  ListingNote,
  ListingPriceCalculation,
} from 'src/WebApiController';

import {
  ComparableListingItem,
  ComparableListingItemProps,
  DisplayType,
} from './ComparableListingItem';
import * as styles from './ComparableListings.css';

const calculateDealScore = (
  sellerNetProceeds: number | undefined | null,
  seatScore: number | undefined | null
) => {
  if (sellerNetProceeds == null || !seatScore) {
    return undefined;
  }

  return sellerNetProceeds / seatScore;
};

export type ComparableListing = {
  listingId: number;
  section?: string;
  row?: string;
  seatFr?: string;
  seatTo?: string;
  price?: number | null;
  sellerNetProceeds?: number;
  currencyCode?: string;
  listingNotes?: ListingNote[];
  isYourListing?: boolean;
  isOutlier?: boolean;
  isAnchor?: boolean;
  seatScore?: number | null;
  availableTickets?: number | null;
  validPurchaseQuantities?: number[] | null;
  isSelectedWithListingGroup?: boolean;
};

export type ComparableListingsProps = {
  currentListings?: ComparableListing[];
  comparableListings: ComparableListing[];
  sortBy?: 'price' | 'dealScore';
  isLtGrp?: boolean;
  isSortDescending?: boolean;
  floorPrice?: number | null;
  ceilingPrice?: number | null;
  showAutoPriceResults?: boolean;
  listingPriceCalc?: ListingPriceCalculation | null;
};

export const ComparableListings: React.FC<ComparableListingsProps> = ({
  currentListings,
  comparableListings,
  isLtGrp,
  sortBy,
  isSortDescending,
  floorPrice,
  ceilingPrice,
  showAutoPriceResults,
  listingPriceCalc,
}) => {
  const { getUiCurrency } = useLocalizationContext();

  const hasIntelligiblePricePreviewFeature = useUserHasFeature(
    Feature.IntelligiblePricePreview
  );

  const firstCurrentListing = currentListings?.[0];
  const currentListingIds = useMemo(() => {
    return currentListings?.map((listing) => listing.listingId);
  }, [currentListings]);

  const floorPriceUICurrency = getUiCurrency(firstCurrentListing?.currencyCode);

  const addFloorCeilingLine = useCallback(
    (
      items: ComparableListingItemProps[],
      type: DisplayType.FloorDisplay | DisplayType.CeilingDisplay,
      price: number
    ) => {
      if (!hasIntelligiblePricePreviewFeature) return false;

      items.push({
        displayType: type,
        itemProps: {
          displayType: type,
          floorCeilingPrice: price,
          currency: floorPriceUICurrency,
        },
      });

      return true;
    },
    [floorPriceUICurrency, hasIntelligiblePricePreviewFeature]
  );

  const allListings = useMemo(() => {
    const all: ComparableListing[] = [];
    if (currentListings) {
      all.push(...currentListings);
    }
    all.push(...comparableListings);

    return all.sort((a, b) => {
      let cmpRes = 0;
      if (sortBy === 'dealScore') {
        const dealScoreA = calculateDealScore(a.sellerNetProceeds, a.seatScore);
        const dealScoreB = calculateDealScore(b.sellerNetProceeds, b.seatScore);
        cmpRes = compareNumber(dealScoreA, dealScoreB);
      } else if (sortBy === 'price') {
        cmpRes = compareNumber(a.price, b.price);
      }
      return cmpRes * (isSortDescending ? -1 : 1);
    });
  }, [comparableListings, currentListings, sortBy, isSortDescending]);

  const hasCurrentListingAndAnchor = useMemo(() => {
    return (
      firstCurrentListing &&
      comparableListings.some((listing) => listing.isAnchor)
    );
  }, [comparableListings, firstCurrentListing]);

  // this is always set to true if the feature is present
  showAutoPriceResults =
    showAutoPriceResults || hasIntelligiblePricePreviewFeature;

  const showCurrentWithAnchor = useMemo(() => {
    if (isLtGrp || hasIntelligiblePricePreviewFeature) {
      // we do not show anchor for listing-group or the new IntelligiblePricePreview feature
      return false;
    }
    return showAutoPriceResults && hasCurrentListingAndAnchor;
  }, [
    hasCurrentListingAndAnchor,
    hasIntelligiblePricePreviewFeature,
    isLtGrp,
    showAutoPriceResults,
  ]);

  const listingItems = useMemo(() => {
    const items: ComparableListingItemProps[] = [];
    let floorPriceShown = false;
    let ceilingPriceShown = false;

    // Determine if all listings are above floor/ceiling
    if (sortBy === 'price') {
      if (floorPrice != null) {
        const anyPriceLessThanFloor = allListings.some(
          (l) => l.price != null && l.price < floorPrice
        );
        if (!anyPriceLessThanFloor) {
          // If there are no listing less than floor, show floor first
          floorPriceShown = addFloorCeilingLine(
            items,
            DisplayType.FloorDisplay,
            floorPrice
          );
        }
      }
      if (ceilingPrice != null) {
        const anyPriceLessThanCeiling = allListings.some(
          (l) => l.price != null && l.price < ceilingPrice
        );
        if (!anyPriceLessThanCeiling) {
          // If there are no listing less than ceiling, show ceiling first
          ceilingPriceShown = addFloorCeilingLine(
            items,
            DisplayType.CeilingDisplay,
            ceilingPrice
          );
        }
      }
    }

    allListings.forEach((listing) => {
      if (listing.listingId === firstCurrentListing?.listingId) {
        if (showCurrentWithAnchor) {
          return;
        }
      }

      if (
        sortBy === 'price' &&
        !floorPriceShown &&
        floorPrice != null &&
        listing.price != null
      ) {
        if (
          (isSortDescending && listing.price < floorPrice) ||
          (!isSortDescending && listing.price >= floorPrice)
        ) {
          floorPriceShown = addFloorCeilingLine(
            items,
            DisplayType.FloorDisplay,
            floorPrice
          );
        }
      }

      if (
        sortBy === 'price' &&
        !ceilingPriceShown &&
        ceilingPrice != null &&
        listing.price != null
      ) {
        if (
          (isSortDescending && listing.price < ceilingPrice) ||
          (!isSortDescending && listing.price >= ceilingPrice)
        ) {
          ceilingPriceShown = addFloorCeilingLine(
            items,
            DisplayType.CeilingDisplay,
            ceilingPrice
          );
        }
      }

      if (showCurrentWithAnchor && listing.isAnchor) {
        items.push({
          displayType: DisplayType.AutopricerDisplay,
          itemProps: {
            isCurrentListing: currentListingIds?.includes(listing.listingId),
            currentListing: firstCurrentListing!,
            anchorListing: listing,
            listingPriceCalc,
          },
        });
      } else if (listing.listingId === firstCurrentListing?.listingId) {
        if (showAutoPriceResults && !showCurrentWithAnchor) {
          items.push({
            displayType: DisplayType.AutopricerDisplay,
            itemProps: {
              isCurrentListing: currentListingIds?.includes(listing.listingId),
              currentListing: firstCurrentListing!,
              listingPriceCalc,
            },
          });
        }
      } else {
        items.push({
          displayType: DisplayType.ComparableDisplay,
          itemProps: {
            isCurrentListing: currentListingIds?.includes(listing.listingId),
            listing,
          },
        });
      }
    });

    // Determine if all listings are below floor/ceiling
    if (sortBy === 'price') {
      if (floorPrice != null && !floorPriceShown) {
        // If there are no listing greater than floor, show floor last
        addFloorCeilingLine(items, DisplayType.FloorDisplay, floorPrice);
      }
      if (ceilingPrice != null && !ceilingPriceShown) {
        // If there are no listing greater than ceiling, show ceiling last
        addFloorCeilingLine(items, DisplayType.CeilingDisplay, ceilingPrice);
      }
    }

    return items;
  }, [
    addFloorCeilingLine,
    allListings,
    ceilingPrice,
    currentListingIds,
    firstCurrentListing,
    floorPrice,
    isSortDescending,
    listingPriceCalc,
    showAutoPriceResults,
    showCurrentWithAnchor,
    sortBy,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.comparableListings}>
        <Virtuoso
          className={styles.virtuosoListings}
          data={listingItems}
          itemContent={(_, item) => <ComparableListingItem {...item} />}
        />
      </div>
    </div>
  );
};
