import { ReactNode, useEffect, useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface SetPurchasesFlattenedViewSelectionCountLabelProps {
  children: ReactNode;
}

export const SetPurchasesFlattenedViewSelectionCountLabel = ({
  children,
}: SetPurchasesFlattenedViewSelectionCountLabelProps) => {
  const purchaseText = useContent(ContentId.Purchase);
  const purchasesText = useContent(ContentId.Purchases);
  const selectedText = useContent(ContentId.Selected);
  const {
    setSelectedCountLabel,
    totalItemsSelected,
    setUsingCustomSelectedCountLabel,
  } = useMultiSelectionContext();

  const noSelectedItemsLabel = useGetNoSelectedItemsLabel();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedCountLabel = useMemo(() => {
    if (!totalItemsSelected) {
      return noSelectedItemsLabel;
    }

    const selectedItemsLabel =
      totalItemsSelected === 1 ? purchaseText : purchasesText;
    return `${totalItemsSelected} ${selectedItemsLabel} ${selectedText}`.toLocaleLowerCase();
  }, [
    noSelectedItemsLabel,
    purchaseText,
    purchasesText,
    selectedText,
    totalItemsSelected,
  ]);

  useEffect(() => {
    setSelectedCountLabel(selectedCountLabel);
  }, [selectedCountLabel, setSelectedCountLabel]);

  return <>{children}</>;
};
