import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkCancelPurchaseDialog } from 'src/dialogs/BulkEdits/BulkCancelPurchaseDialog';
import { PurchaseCancellationType } from 'src/dialogs/CancelPurchaseDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_CANCEL_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchBulkCancelPurchase } from './LaunchBulkCancelPurchase';

export const useBulkCancelPurchase = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = groupId
    ? `cancelPurchases-event-${groupId}`
    : PURCHASE_BULK_CANCEL_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext();

  const {
    openDialog: openBulkCancelDialog,
    closeDialog: closeBulkCancelDialog,
  } = useDialog(DialogId.BulkCancelPurchase, BulkCancelPurchaseDialog);

  const onSubmit = useCallback(
    async (
      cancellationType: PurchaseCancellationType | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const preview =
              await client.bulkDeletePurchaseOrderPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (cancellationType) {
        await tryInvokeApi(
          async () => {
            const succeeded =
              cancellationType === 'cancel'
                ? await new BulkEditPurchaseClient(
                    activeAccountWebClientConfig
                  ).bulkCancelPurchaseOrder(
                    {
                      item1: preview!.preview, 
                      item2: null
                    },
                    updateKey,
                    supportBackgroundProcess,
                    null
                  )
                : await new BulkEditPurchaseClient(
                    activeAccountWebClientConfig
                  ).bulkVoidPurchaseOrder(
                    preview!.preview,
                    updateKey,
                    supportBackgroundProcess
                  );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                // Metrics/events may have changed with TGs being cancelled, so refresh the whole catalog
                refreshMetrics();
                await refreshCatalog();
                await refreshExpandedListItems();
              }

              closeBulkCancelDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitCancelPurchase', error, {
              trackErrorData: { preview, cancellationType },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkCancelDialog,
      filterQuery,
      refreshCatalog,
      refreshData,
      refreshExpandedListItems,
      refreshMetrics,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkCancelPurchase
        key="bulkCancelPurchase"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkCancelDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkCancelDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
