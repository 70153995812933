import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Permission, SellerUserClient } from 'src/WebApiController';

import { useUserHasAnyOfPermissions } from './useUserHasAnyOfPermissions';

export const HAS_RELEASE_NOTES_UNREAD_QUERY_KEY =
  'SellerUserClient.getHasReleaseNotesUnread';

export const useHasReleaseNotesUnread = ({
  currentLoginUserOnly,
  disabled,
}: {
  /* whether to use the active account's user or the login user (which includes impersonating user) Id */
  currentLoginUserOnly?: boolean;
  disabled?: boolean;
}) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const canViewReleaseNotes = useUserHasAnyOfPermissions(
    Permission.General_ViewReleaseNotes
  );
  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    !disabled &&
    canViewReleaseNotes;

  const hasReleaseNotesUnreadQuery = useQuery({
    queryKey: [
      HAS_RELEASE_NOTES_UNREAD_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      currentLoginUserOnly,
      shouldQuery,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new SellerUserClient(
        activeAccountWebClientConfig
      ).getHasReleaseNotesUnread(currentLoginUserOnly ?? false);
    },

    enabled: shouldQuery,
    staleTime: Infinity, // seldom change data and we invalidate on reload
    refetchOnWindowFocus: false,
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError('SellerUserClient.getHasReleaseNotesUnread', error, {
          currentLoginUserOnly,
        });
      },
    },
  });

  return {
    hasReleaseNotesUnread: hasReleaseNotesUnreadQuery.data ?? false,
  };
};
