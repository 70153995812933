import { Content } from 'src/contexts/ContentContext';
import { shared } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './ReportsTableEditor.css';
import { ReportsTableEditorForm } from './ReportsTableEditorForm';

export const ReportsTableEditor = ({
  reportConfig,
  disabled,
  onClose,
}: {
  reportConfig: ReportConfigV2;
  disabled?: boolean;
  onClose: () => void;
}) => {
  return (
    <Stack direction="column" className={styles.editorRoot}>
      <Stack
        direction="row"
        justifyContent="spaceBetween"
        className={styles.header}
      >
        <span className={shared.typography.title6} style={{ fontWeight: 400 }}>
          <Content id={ContentId.TableEditor} />
        </span>
        <CrossIcon withHoverEffect onClick={onClose} />
      </Stack>
      <ReportsTableEditorForm reportConfig={reportConfig} disabled={disabled} />
    </Stack>
  );
};
