import {
  MarketplacePaymentLineType,
  MarketplacePaymentState,
  SiteMarketplacePaymentLineInput,
} from 'src/WebApiController';

export const EMPTY_MARKETPLACE_PAYMENT_LINE: SiteMarketplacePaymentLineInput = {
  marketplacePaymentLineId: null,
  marketplacePaymentId: null,
  externalPaymentId: '',
  externalPaymentLineId: '',
  externalSaleId: '',
  saleId: null,
  currency: '',
  amount: 0,
  isCredit: false,
  paymentLineType: MarketplacePaymentLineType.Proceeds,
  paymentDate: null,
  receivedDate: null,
  paymentState: MarketplacePaymentState.Paid,
};
