import { format } from 'date-fns';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { IconsFill, ProcessingIcon, ReloadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SearchResult } from 'src/WebApiController';

export const EntitySearchRefetcher = ({
  activeSearchConfig,
  disabled,
  onRequeryForTheResult,
}: {
  activeSearchConfig?: SearchResult;
  disabled?: boolean;
  onRequeryForTheResult: () => void;
}) => {
  if (activeSearchConfig?.lastFetchDate == null) return null;

  return (
    <Stack gap="s" alignItems="center">
      <span>
        <Content id={ContentId.CompletedOn} />:
      </span>
      <span>
        {format(new Date(activeSearchConfig.lastFetchDate), 'M/d/yyyy h:mm a')}.
      </span>
      <Button
        disabled={disabled}
        variant="textPlain"
        size="unset"
        shape="inline"
        onClick={onRequeryForTheResult}
      >
        <Content id={ContentId.RefreshNow} />
        {disabled ? (
          <RotatingWrapper>
            <ProcessingIcon
              fill={IconsFill.textPrimary}
              size={vars.iconSize.s}
            />
          </RotatingWrapper>
        ) : (
          <ReloadIcon
            withHoverEffect
            size={vars.iconSize.s}
            fill={IconsFill.textPrimary}
          />
        )}
      </Button>
    </Stack>
  );
};
