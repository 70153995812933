import {
  ColumnDef,
  ExpandedState,
  functionalUpdate,
  getExpandedRowModel,
  InitialTableState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActiveSortTableColumnContext } from 'src/contexts/ActiveSortTableColumnContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useInputPriceFocusContext } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import {
  MultiSelectScope,
  NO_GROUP_ID,
  SelectionMode,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { useSortListingTableSeatingColumn } from 'src/hooks/useSortListingTableSeatingColumn';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOptionEntries } from 'src/modals/EditTableColumns';
import { NoData, Table } from 'src/tables/Table';
import {
  defaultListingFlattenedColumnsConfig,
  filterColumnsByFeatures,
  getListingColumnConfigById,
} from 'src/utils/columns/columnUtils';
import {
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { filterCustomColumnsForListing } from 'src/utils/columns/inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  Feature,
  GroupType,
  ListingGroup,
  UserSetting,
} from 'src/WebApiController';

import { usePaginationSettings } from '../Table/hooks/usePaginationSettings';
import { RowWrapper } from '../Table/Table.types';
import { ListingPricingForm } from './configs/ListingPriceForm';
import {
  customColumnDef,
  getListingTableColumnConfigs,
  tagColumnDef,
} from './configs/ListingTableColumnsConfig';
import { LISTING_TABLE_FLATTENED_COLUMNS_CONFIG } from './configs/ListingTableFlattenedColumnsConfig';
import { ListingWithEvent } from './ListingTable.types';

export type ListingTableFlattenedProps = {
  listings?: ListingWithEvent[];
  listingCount: number;
  ungroupedListingCount: number;
  listingGroupCount: number;
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  selectionMode?: SelectionMode | null;
  selectedIds?: number[];
  onItemSelectionsChanged?: (newSelectedIds: number[]) => void;
  disablePagination?: boolean;
  useVirtuoso?: boolean;
  columnVisibilityOverride?: (
    | ListingTableColumnId
    | ListingTableFlattenedColumnId
  )[];
  formatOptionOverrides?: FormatOptionEntries;
  withOuterPadding?: boolean;
  disableSelection?: boolean;
  alwaysShowCheckbox?: boolean;
  showSelectAllOnHeader?: boolean;
  enableColumnFilters?: boolean;
  ignoreMaxCount?: boolean;
};

const PAGE_SIZE = 100;

export const ListingTableFlattened = ({
  listings,
  listingCount,
  ungroupedListingCount,
  listingGroupCount,
  failedToRetrieveData,
  selectedIds,
  selectionMode: defaultSelectionMode,
  onItemSelectionsChanged,
  disablePagination,
  onMount,
  onUnmount,
  initState,
  useVirtuoso,
  columnVisibilityOverride,
  formatOptionOverrides,
  withOuterPadding = true,
  disableSelection,
  alwaysShowCheckbox,
  showSelectAllOnHeader,
  enableColumnFilters,
  ignoreMaxCount,
}: ListingTableFlattenedProps) => {
  const { appContext, loginContext } = useAppContext();

  const { onScroll } = useCollapsableViewContext();

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const { activeSortTableColumn, setActiveSortTableColumn } =
    useActiveSortTableColumnContext();
  const {
    selectionMode: selectionModeOverride,
    setGroupItems,
    getSelection,
    getGroupToggleState,
    isAllSelected,
    updateGroupFilteredIds,
  } = useMultiSelectionContext();

  const selectionMode = selectionModeOverride ?? defaultSelectionMode;

  const listingSelection = getSelection(NO_GROUP_ID);
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const hasSortListingsInGroupFeature = useUserHasFeature(
    Feature.SortListingsInGroup
  );
  const hasSectionalLoadInListingTableFeature = useUserHasFeature(
    Feature.SectionalLoadInListingTable
  );

  const [sorting, setSorting] = useState<SortingState>(
    activeSortTableColumn ||
      initState?.sorting || [
        { id: 'actions', desc: false },
        { id: 'seating', desc: false },
      ]
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  const [expandedState, setExpandedState] = useState<ExpandedState>(
    initState?.expanded ?? {}
  );

  // This is to pre-expand all the groups on first-load
  useEffect(() => {
    if (
      Object.keys(expandedState).length === 0 &&
      listings?.some((l) => l.isLtGrp)
    ) {
      setExpandedState(
        listings?.reduce(
          (r, l) => {
            if (l.isLtGrp) {
              r[l.listing!.id.toString()] = true;
            }

            return r;
          },
          {} as Record<string, boolean>
        ) ?? {}
      );
    }
  }, [expandedState, listings]);

  const { inputPriceFocusContext } = useInputPriceFocusContext();
  const { isItemsLoading } = useCatalogDataContext();
  const currentPage = useMemo(() => {
    return inputPriceFocusContext &&
      inputPriceFocusContext.rowIndex < listingCount
      ? inputPriceFocusContext.currentPageIndex
      : -1;
  }, [inputPriceFocusContext, listingCount]);

  const { data, hasNoDataLoad } = useMemo(() => {
    const data =
      listings == null || isItemsLoading
        ? (generateShimmeringRows(
            ungroupedListingCount,
            listingGroupCount
          ) as (ListingWithEvent | null)[])
        : listings;

    const hasNoDataLoad =
      !ignoreMaxCount && listingCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;
    return { data, hasNoDataLoad };
  }, [
    listings,
    isItemsLoading,
    ungroupedListingCount,
    listingGroupCount,
    ignoreMaxCount,
    listingCount,
  ]);

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    currentPage,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const rowSelection = useMemo(() => {
    return data
      ?.flatMap((d) => [
        d?.listing,
        ...((d?.listing as ListingGroup)?.groupItems ?? []),
        ...((d?.listing as ListingGroup)?.groupItems ?? []).flatMap(
          (lg) => (lg as ListingGroup).groupItems ?? []
        ),
      ])
      ?.reduce<Record<string, boolean>>((result, listing) => {
        if (listing?.id) {
          result[listing.id] = isAllSelected
            ? true
            : (getGroupToggleState(NO_GROUP_ID).isGroupSelected ||
                (listingSelection?.items.itemIds ?? selectedIds)?.includes(
                  String(listing?.id)
                )) ??
              false;
        }
        return result;
      }, {});
  }, [
    data,
    getGroupToggleState,
    isAllSelected,
    listingSelection?.items.itemIds,
    selectedIds,
  ]);

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      if (disableSelection) return;

      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );
      if (selectionMode?.mode === 'singleItem') {
        newSelectedIds.forEach((id) => {
          if (rowSelection[id] && newSelections[id]) {
            // if both of these are the same value and is true, we need to set it to false
            newSelections[id] = false;
          }
        });
      }
      setGroupItems(NO_GROUP_ID, newSelections);
      onItemSelectionsChanged?.(
        newSelectedIds
          .filter((id) => newSelections[id])
          .map((id) => parseInt(id))
      );
    },
    [
      disableSelection,
      rowSelection,
      selectionMode?.mode,
      setGroupItems,
      onItemSelectionsChanged,
    ]
  );

  const {
    value:
      storedInventoryFlattenedColumnOrderSetting = defaultListingFlattenedColumnsConfig,
  } = useServerUserSetting<ListingTableColumnId[]>({
    id: UserSetting.InventoryFlattenedColumnOrder,
  });
  const { value: storedInventoryColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: UserSetting.InventoryFlattenedColumnsEnabled,
  });

  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: UserSetting.InventoryCustomColumns,
  });
  const { tagsMetadata, tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Listing,
    true
  );

  const customListingColumnsFiltered = filterCustomColumnsForListing(
    customListingColumns,
    tagsMetadataNumeric
  );

  const showCheckbox = useMemo(
    () =>
      alwaysShowCheckbox ||
      selectionMode?.mode === MultiSelectScope.AllGroups ||
      listingSelection.isSingleGroupMultiSelect,
    [
      alwaysShowCheckbox,
      listingSelection.isSingleGroupMultiSelect,
      selectionMode?.mode,
    ]
  );

  const allColumnConfigs = useMemo(
    () =>
      getListingTableColumnConfigs(
        hasSortListingsInGroupFeature,
        false,
        hasSectionalLoadInListingTableFeature
      ),
    [hasSectionalLoadInListingTableFeature, hasSortListingsInGroupFeature]
  );

  const seatingColumnSorting = useSortListingTableSeatingColumn(
    SectionType.ListingsFlattened
  );

  const displayedColumnsConfig = useMemo(() => {
    const inventoryColumnOrder = columnVisibilityOverride
      ? columnVisibilityOverride
      : storedInventoryFlattenedColumnOrderSetting.filter(
          // If storedInventoryColumnsEnabledSetting has never been set, default to true
          (c) => storedInventoryColumnsEnabledSetting?.includes(c) ?? true
        );

    if (
      !columnVisibilityOverride &&
      !hasTablePinActionColumnFeature &&
      !inventoryColumnOrder.includes(ListingTableColumnId.Actions)
    ) {
      inventoryColumnOrder.push(ListingTableColumnId.Actions);
    }

    const listingTableColumnConfigs = Object.values(allColumnConfigs);
    const listingTableFlattenedColumnConfigs = Object.values(
      LISTING_TABLE_FLATTENED_COLUMNS_CONFIG
    );

    const columns = new Set(
      columnVisibilityOverride
        ? columnVisibilityOverride
            .map(
              (cid) =>
                listingTableColumnConfigs.find((c) => c.id === cid) ??
                listingTableFlattenedColumnConfigs.find((c) => c.id === cid)!
            )

            .map((item) => item.id!)
        : [
            // Add unconfigurable columns first
            ...listingTableFlattenedColumnConfigs
              .filter((column) => {
                const columnDef = getListingColumnConfigById(
                  column.id as ListingTableFlattenedColumnId
                ).personalization;
                const hasFeatureForColumn =
                  columnDef.requiredFeatures.length === 0 ||
                  hasFeatures(
                    loginContext?.user,
                    appContext?.features,
                    columnDef.requiredFeatures
                  );

                return !columnDef.isConfigurable && hasFeatureForColumn;
              })
              .map((item) => item.id!),
            ...listingTableColumnConfigs
              .filter((column) => {
                const columnDef = getListingColumnConfigById(
                  column.id as ListingTableColumnId
                ).personalization;
                const hasFeatureForColumn =
                  columnDef.requiredFeatures.length === 0 ||
                  hasFeatures(
                    loginContext?.user,
                    appContext?.features,
                    columnDef.requiredFeatures
                  );

                let isConfigurable = columnDef.isConfigurable;
                if (
                  !hasTablePinActionColumnFeature &&
                  column.id === ListingTableColumnId.Actions
                ) {
                  isConfigurable = true;
                }

                return !isConfigurable && hasFeatureForColumn;
              })
              .map((item) => item.id!),
            // Add user defined columns next
            ...filterColumnsByFeatures(
              inventoryColumnOrder,
              SectionType.ListingsFlattened,
              customListingColumnsFiltered,
              loginContext?.user,
              appContext?.features,
              tagsMetadata ?? []
            ),
          ]
    );

    if (!columnVisibilityOverride) {
      if (showCheckbox) {
        if (!alwaysShowCheckbox) {
          columns.delete(ListingTableColumnId.Actions);
        }
      } else {
        columns.delete(ListingTableColumnId.Checkbox);
      }
    }

    return Array.from(columns).reduce<ColumnDef<ListingWithEvent | null>[]>(
      (acc, columnId) => {
        if (
          !columnVisibilityOverride &&
          tagsMetadata?.find((t) => t.key === columnId)
        ) {
          acc.push(tagColumnDef(columnId));
        } else if (
          !columnVisibilityOverride &&
          customListingColumnsFiltered.find((c) => c.id === columnId)
        ) {
          acc.push(
            customColumnDef(
              columnId,
              customListingColumnsFiltered.find((c) => c.id === columnId)
                ?.formula
            )
          );
        } else {
          const columnConfig1 = listingTableColumnConfigs.find(
            (column) => column.id === columnId
          );
          if (columnConfig1) {
            acc.push(columnConfig1 as ColumnDef<ListingWithEvent | null>);
          }
          const columnConfig2 = listingTableFlattenedColumnConfigs.find(
            (column) => column.id === columnId
          );
          if (columnConfig2) {
            acc.push(columnConfig2 as ColumnDef<ListingWithEvent | null>);
          }
        }

        return acc;
      },
      []
    );
  }, [
    columnVisibilityOverride,
    storedInventoryFlattenedColumnOrderSetting,
    hasTablePinActionColumnFeature,
    allColumnConfigs,
    customListingColumnsFiltered,
    loginContext?.user,
    appContext?.features,
    tagsMetadata,
    storedInventoryColumnsEnabledSetting,
    showCheckbox,
    alwaysShowCheckbox,
  ]);

  const options: Partial<TableOptions<ListingWithEvent | null>> = useMemo(
    () => ({
      data,
      columns: displayedColumnsConfig,
      state: {
        pagination,
        sorting,
        rowSelection,
        expanded: expandedState,
        columnVisibility: {
          [ListingTableColumnId.TicketClassColor]: false,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [ListingTableColumnId.Actions]
            : undefined,
        },
      },
      meta: {
        sectionType: SectionType.ListingsFlattened,
        formatOptionOverrides,
        showSelectAllCheckbox: showSelectAllOnHeader,
      },
      getRowId: (originalRow: ListingWithEvent | null, index: number) => {
        return (originalRow?.listing?.id ?? index).toString();
      },
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
        setActiveSortTableColumn(newSortVal);
      },
      onExpandedChange: setExpandedState,
      getSubRows: (originalRow) => {
        if (!originalRow?.listing || !originalRow.listing.isLtGrp) {
          return [];
        }

        const listingGroup = originalRow.listing as ListingGroup;
        const result =
          [...(listingGroup.groupItems ?? [])]
            .sort((a, b) => {
              if (a.availQty <= 0 && b.availQty >= 0) {
                return 1;
              } else if (a.availQty >= 0 && b.availQty <= 0) {
                return -1;
              }
              return (a.ltGrpPrior ?? -1) - (b.ltGrpPrior ?? -1);
            })
            ?.map(
              (l, i) =>
                ({
                  ...originalRow,
                  rowIndex: i,
                  listing: l,
                }) as ListingWithEvent
            ) ?? [];

        return result;
      },
      getRowCanExpand: (originalRow) => {
        return Boolean(
          originalRow.original?.listing?.isLtGrp &&
            (originalRow.original?.listing as ListingGroup).groupType !==
              GroupType.Invisible
        );
      },
      getExpandedRowModel: (table) => getExpandedRowModel()(table),
      paginateExpandedRows: false,
      enableRowSelection: showCheckbox,
      enableMultiRowSelection: showCheckbox,
      onRowSelectionChange,
      enableSubRowSelection: showCheckbox,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      displayedColumnsConfig,
      pagination,
      sorting,
      rowSelection,
      expandedState,
      hasTablePinActionColumnFeature,
      formatOptionOverrides,
      showSelectAllOnHeader,
      setPagination,
      showCheckbox,
      onRowSelectionChange,
      seatingColumnSorting,
      setActiveSortTableColumn,
    ]
  );

  const onRowWrapper: RowWrapper<ListingWithEvent | null> = useCallback(
    (rowData, tableRow, rowIndex) => {
      return (
        <ListingPricingForm
          key={rowIndex}
          rowData={
            rowData && {
              ...rowData,
              rowIndex,
              currentPageIndex: pagination.pageIndex,
            }
          }
          tableRow={tableRow}
        />
      );
    },
    [pagination.pageIndex]
  );

  const onFilteredRowsChange = useCallback(
    (filteredRows: Row<ListingWithEvent | null>[] | undefined) => {
      const filteredItemIds = filteredRows
        ?.filter((r) => !r.original?.isLtGrp && !r.original?.isCompListing)
        .map((r) => r.original?.listing?.id)
        .filter((lid) => lid != null) as number[] | undefined;
      updateGroupFilteredIds(NO_GROUP_ID, filteredItemIds);
    },
    [updateGroupFilteredIds]
  );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : hasNoDataLoad ? (
    <NoData>
      <WarningMessage
        message={
          <FormatContent
            id={FormatContentId.ApplyFilterToReduceCountsToSeeData}
            params={[MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS.toString()]}
          />
        }
      />
    </NoData>
  ) : data?.length > 0 ? (
    <ColumnResizingContextProvider
      userSettingId={UserSetting.InventoryColumnWidths}
    >
      <Table
        withOuterPadding={withOuterPadding}
        options={options}
        rowWrapper={onRowWrapper}
        tableLayout="fixed"
        tableStyle={
          disablePagination
            ? {
                maxHeight: '60vh',
              }
            : undefined
        }
        tableHeadStyle={
          disablePagination
            ? {
                position: 'sticky',
                top: '0',
                zIndex: '10',
              }
            : undefined
        }
        tableCellStyle={{ overflow: 'hidden' }}
        useVirtuoso={useVirtuoso}
        onVirtuosoTableScroll={onScroll}
        enableColumnFilters={enableColumnFilters}
        onFilteredRowsChange={
          enableColumnFilters ? onFilteredRowsChange : undefined
        }
      />
    </ColumnResizingContextProvider>
  ) : null;
};
