import * as Sentry from '@sentry/react';
import {
  ColumnDef,
  functionalUpdate,
  GroupingState,
  InitialTableState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { NoData, Table } from 'src/tables/Table';
import {
  defaultOnSaleEventsColumnsConfig,
  filterColumnsByFeatures,
  getOnSaleEventsColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import { Feature, UserSetting } from 'src/WebApiController';

import { ON_SALE_EVENTS_TABLE_COLUMNS_CONFIG } from './OnSaleEventView.constants';
import {
  OnSaleEventsTableColumnId,
  OnSaleEventWithMetrics,
} from './OnSaleEventView.types';

export type OnSaleEventTableProps = {
  highlightedId?: string;
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  useVirtuoso?: boolean;
  useDataGrouping?: boolean;
  withOuterPadding?: boolean;
  tableHeadStyle?: CSSProperties;
  isLoading?: boolean;
  events: OnSaleEventWithMetrics[];
};

const getRowId = (originalRow: OnSaleEventWithMetrics | null): string => {
  return (originalRow as OnSaleEventWithMetrics)?.event?.viagVirtualId ?? '';
};

export const OnSaleEventTable = ({
  highlightedId,
  failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
  useVirtuoso,
  useDataGrouping,
  withOuterPadding,
  tableHeadStyle,
  events,
  isLoading,
}: OnSaleEventTableProps) => {
  const { appContext, loginContext } = useAppContext();
  const { getSelection, selectionMode, setGroupStates } =
    useMultiSelectionContext();
  const eventSelection = getSelection();

  const viewedEventIdsRef = useRef<number[]>([]);

  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const eventsText = useContent(ContentId.Events);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [grouping, setGrouping] = useState<GroupingState>(
    initState?.grouping || []
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ sorting });
  }, [sorting, onUnmount]);

  const {
    value: storedEventsColumnOrderSetting = defaultOnSaleEventsColumnsConfig,
  } = useServerUserSetting<OnSaleEventsTableColumnId[]>({
    id: UserSetting.OnSaleEventColumnsOrder,
  });
  const { value: storedEventsColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: UserSetting.OnSaleEventColumnsEnabled,
  });

  const displayedColumnsConfig = useMemo(() => {
    const eventsColumnOrder = storedEventsColumnOrderSetting.filter(
      // If storedEventsColumnsEnabledSetting has never been set, default to true
      (c) => storedEventsColumnsEnabledSetting?.includes(c) ?? true
    );

    if (
      !hasTablePinActionColumnFeature &&
      !eventsColumnOrder.includes(OnSaleEventsTableColumnId.Action)
    ) {
      eventsColumnOrder.push(OnSaleEventsTableColumnId.Action);
    }
    const columns = new Set([
      // Add unconfigurable columns first
      ...Object.values(ON_SALE_EVENTS_TABLE_COLUMNS_CONFIG)
        .filter((column) => {
          const columnDef = getOnSaleEventsColumnConfigById(
            column.id as OnSaleEventsTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          let isConfigurable = columnDef.isConfigurable;
          if (
            !hasTablePinActionColumnFeature &&
            column.id === OnSaleEventsTableColumnId.Action
          ) {
            isConfigurable = true;
          }

          return !isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as OnSaleEventsTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        eventsColumnOrder,
        SectionType.Events,
        undefined,
        loginContext?.user,
        appContext?.features
      ),
    ]);

    return Array.from(columns).reduce<
      ColumnDef<OnSaleEventWithMetrics | null>[]
    >((acc, columnId) => {
      const columnConfig = Object.values(
        ON_SALE_EVENTS_TABLE_COLUMNS_CONFIG
      ).find((column) => column.id === columnId);
      if (columnConfig) acc.push(columnConfig);
      return acc;
    }, []);
  }, [
    appContext?.features,
    hasTablePinActionColumnFeature,
    loginContext?.user,
    storedEventsColumnOrderSetting,
    storedEventsColumnsEnabledSetting,
  ]);

  const rowSelection = useMemo(() => {
    if (!selectionMode && highlightedId) {
      return {
        [highlightedId]: true,
      };
    }
    return eventSelection.groupIds.reduce<Record<string, boolean>>(
      (acc, eventId) => {
        acc[eventId] = true;
        return acc;
      },
      {}
    );
  }, [highlightedId, eventSelection.groupIds, selectionMode]);

  const onRowSelectionChanged = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );
      if (!selectionMode) {
        newSelectedIds.forEach((id) => {
          if (rowSelection[id] && newSelections[id]) {
            // if both of these are the same value and is true, we need to set it to false
            newSelections[id] = false;
          }
        });
      }
      setGroupStates(newSelections);
    },
    [rowSelection, selectionMode, setGroupStates]
  );

  const showCheckbox = selectionMode?.mode === MultiSelectScope.AllGroups;

  const options: Partial<TableOptions<OnSaleEventWithMetrics | null>> = useMemo(
    () => ({
      data: events,
      columns: displayedColumnsConfig,
      state: {
        expanded: useDataGrouping ? true : undefined,
        grouping,
        sorting,
        rowSelection,
        columnVisibility: {
          checkbox: showCheckbox,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [OnSaleEventsTableColumnId.Action]
            : undefined,
        },
      },
      onGroupingChange: setGrouping,
      getRowId: getRowId,
      onRowSelectionChange: onRowSelectionChanged,
      onSortingChange: (sortingUpdaterFn: any) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      enableRowSelection: showCheckbox,
      enableMultiRowSelection: showCheckbox,
      enableGrouping: !!useDataGrouping,
    }),
    [
      events,
      displayedColumnsConfig,
      useDataGrouping,
      grouping,
      sorting,
      rowSelection,
      showCheckbox,
      hasTablePinActionColumnFeature,
      onRowSelectionChanged,
    ]
  );

  const allEventMetricsLoaded = useMemo(
    () => events.every((event) => event.metrics != null),
    [events]
  );

  const onItemsRendered = useCallback(
    (rows: Row<OnSaleEventWithMetrics | null>[]) => {
      // This callback is rendered on the change of what user sees in the vituosso
      // which can be changed with the sorting, even before all metrics are loaded
      // Avoid logging sentry spans when event are not fully loaded
      if (!allEventMetricsLoaded) return;

      const viewedEventIds = viewedEventIdsRef.current;
      // Ensure to only log the different event ids
      const renderedEventIds = rows
        .map((row) => row?.original?.event?.viagId ?? null)
        .filter((id: number | null): id is number => id != null)
        .filter((id) => !viewedEventIds.includes(id));
      if (isEmpty(renderedEventIds)) return;

      const span = Sentry.startSpan(
        { name: 'Event Discovery', op: 'event_viewed' },
        (span: Sentry.Span) => {
          span.setAttribute('event_ids', Array.from(new Set(renderedEventIds)));
          return span;
        }
      );
      viewedEventIdsRef.current = [...viewedEventIds, ...renderedEventIds];
      span.end();
    },
    [allEventMetricsLoaded]
  );

  if (failedToRetrieveData) {
    return (
      <SomethingWentWrong
        message={<Content id={ContentId.FailToLoadListContent} />}
      />
    );
  }

  if (isLoading) {
    return <PosSpinner />;
  }

  return events.length > 0 ? (
    <Table
      options={options}
      tableLayout="fixed"
      tableHeadStyle={{ top: '56px', ...tableHeadStyle }}
      useVirtuoso={useVirtuoso}
      usePaginationFooter={false}
      useDataGrouping={useDataGrouping}
      withOuterPadding={withOuterPadding}
      itemsRendered={onItemsRendered}
    />
  ) : (
    // We should never see this - because the events are filtered to only those that has sales
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent id={FormatContentId.NoDataAvailable} params={eventsText} />
    </NoData>
  );
};
