import { CellContext } from '@tanstack/react-table';
import { debounce } from 'lodash-es';
import React, { useCallback } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { RowSelector } from 'src/components/Selectors/SectionRowSelector';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { vars } from 'src/core/themes';
import { SpreadsheetCell } from 'src/tables/SpreadsheetTable/SpreadsheetCell/SpreadsheetCell';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';

function TicketGroupInputRowSelectorComponent<T, V>(
  props: CellContext<T, V> & {
    control: Control<PurchaseTicketsInput, any>;
    style?: React.CSSProperties;
  }
) {
  const {
    register,
    setValue,
    getValues,
    getFieldState,
    formState,
    clearErrors,
  } = useFormContext<PurchaseTicketsInput>();
  const { cell, control, getValue, style = {} } = props;
  const { venueMapInfo } = useEventMapContext();

  const [sectionId, section] = useWatch({
    name: [
      `ticketGroups.${cell.row.index}.sectionId.value`,
      `ticketGroups.${cell.row.index}.section.value`,
    ],
  });

  const rowValue = getValues(`ticketGroups.${cell.row.index}.row.value`);
  const hasError = getFieldState(
    `ticketGroups.${cell.row.index}.row`,
    formState
  )?.error;

  const selectedSection = venueMapInfo?.sections?.find((s) =>
    sectionId ? s.id === sectionId : s.name === section
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRowForTickets = useCallback(
    debounce((row: string) => {
      const tickets = getValues(`ticketGroups.${cell.row.index}.tickets.value`);
      if (tickets) {
        setValue(
          `ticketGroups.${cell.row.index}.tickets`,
          posChangedField(tickets.map((t) => ({ ...t, row: row })) ?? null)
        );
      }
    }, 600),
    [setValue]
  );

  return selectedSection?.rows?.filter((r) => !r.isSpeculative)?.length ? (
    <Controller
      name={`ticketGroups.${cell.row.index}.row`}
      control={control}
      render={({ field, fieldState }) => {
        const selectedRow = selectedSection?.rows?.find(
          (r) => r.name === rowValue
        );
        return (
          <RowSelector
            className={
              styles.tableCell[rowValue == null ? 'disabled' : 'active']
            }
            style={style}
            value={selectedRow?.id?.toString() ?? '-1'}
            displayText={selectedRow?.name || rowValue || undefined}
            selectedSection={selectedSection}
            onChange={(rowId) => {
              const id = parseInt(rowId);

              if (hasError && id) {
                clearErrors(`ticketGroups.${cell.row.index}.row`);
              }
              const row = selectedSection?.rows?.find((r) => r.id === id);
              // If the row is not found, it means the user selected the placeholder option
              if (row == undefined) {
                field.onChange(posChangedField(rowId));
                setValue(`ticketGroups.${cell.row.index}.rowId`, null);
                return;
              }

              field.onChange(posChangedField(row.name));
              setValue(
                `ticketGroups.${cell.row.index}.rowId`,
                posChangedField(id)
              );
              setRowForTickets(row.name || '');
            }}
            placeholderText=""
          />
        );
      }}
    />
  ) : (
    <SpreadsheetCell.InputCell<T, unknown, PurchaseTicketsInput>
      register={register}
      fieldName={`ticketGroups.${cell.row.index}.row`}
      {...props}
      setValue={setValue}
    />
  );
}

export const TicketGroupInputRowSelector = React.memo(
  TicketGroupInputRowSelectorComponent
) as <T, V>(
  props: CellContext<T, V> & {
    control: Control<PurchaseTicketsInput, any>;
    style?: React.CSSProperties;
  }
) => JSX.Element;
