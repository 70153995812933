import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { SplitListingToOriginalDialog } from 'src/dialogs/SplitListingToOriginalDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SplitListingModal } from 'src/modals/SplitListing';
import { SplitIcon } from 'src/svgs/SplitIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  SplitListingInput,
} from 'src/WebApiController';

export const LaunchSplitListingToOriginal = ({
  listingId,
  cancelTo,
  variant,
  disabled,
  iconOnlyMode,
  ...buttonProps
}: {
  listingId: number;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof SplitListingModal>) => {
  const hasSplitListingToOriginalFeature = useUserHasFeature(
    Feature.SplitListingToOriginal
  );
  const { closeModal } = useContext(ModalContext);
  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const { openDialog, closeDialog } = useDialog(
    DialogId.Confirm,
    SplitListingToOriginalDialog
  );

  const { showErrorDialog } = useErrorBoundaryContext();

  const splitListing = useCallback(
    async (splitListingInput: SplitListingInput) => {
      return await tryInvokeApi(
        async () => {
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).splitListings(splitListingInput!);

          if (result) {
            refreshExpandedListItems();

            // After we split - the original listing is gone - so we don't want to cancel back to it
            // so we just close the main modal
            closeModal(true);
          }
        },
        (error) => {
          showErrorDialog('ListingClient.splitListings', error);
        },
        () => {
          closeDialog();
        }
      );
    },
    [
      activeAccountWebClientConfig,
      closeDialog,
      closeModal,
      refreshExpandedListItems,
      showErrorDialog,
    ]
  );

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!listing?.tickets?.length) {
        // If we haven't pulled tickets, pull it
        setActivePosEntity(listingId, null, false, [
          ListingDetailDataField.Basic,
          ListingDetailDataField.Tickets,
        ]);
      }

      openDialog({
        onOkay: async (splitListingInput) => {
          await splitListing(splitListingInput);
          closeDialog();
        },
        onCancel: () => {
          closeDialog();
        },
      });
    },
    [
      listing?.tickets?.length,
      openDialog,
      setActivePosEntity,
      listingId,
      splitListing,
      closeDialog,
    ]
  );

  if (!hasSplitListingToOriginalFeature) {
    return null;
  }

  return (
    <ButtonWithIcon
      {...buttonProps}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onLaunchDialog}
      textContentId={ContentId.ResetToOriginal}
      disabled={disabled}
      icon={
        <SplitIcon
          withHoverEffect
          stroke={
            iconOnlyMode || (variant && variant !== 'regular')
              ? vars.color.textBrand
              : vars.color.textInverted
          }
          size={vars.iconSize.m}
        />
      }
    />
  );
};
