import { isEqual } from 'lodash-es';
import { FilterToolbarItem } from 'src/components/Filters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_STATUS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { EntityWithTicketsQuery } from 'src/WebApiController';

export const useEventStateFilters = (): FilterToolbarItem[] => {
  const { tempQuery, setTempQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();

  return [
    {
      filterId: 'eventStatuses',
      labelContentId: ContentId.EventState,
      filterQueryKeys: ['eventStatuses'],
      filterItem: (
        <PosEnumMultiSelect
          triggerProps={{ style: { width: '100%' } }}
          values={tempQuery.eventStatuses || []}
          placeholderText={ContentId.All}
          enableEmptySelection
          onChange={(eventStatuses) => {
            const isChanged = !isEqual(eventStatuses, tempQuery.eventStatuses);

            setTempQuery({
              ...tempQuery,
              eventStatuses: isChanged ? eventStatuses : [],
            });
          }}
          valueOptionsContent={EVENT_STATUS_TO_CID}
        />
      ),
    },
  ];
};
