import {
  EventAccordionItemBodyComponentType,
  MinRowsForUsingVirtuoso,
} from 'src/components/Accordions';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import {
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

type EventTicketGroupBodyProps =
  EventAccordionItemBodyComponentType<PurchaseOrderTicketGroup>;

export const EventTicketGroupBody = (props: EventTicketGroupBodyProps) => {
  const Body = PurchaseOrderTableEventAccordionWrapper;

  return <Body {...props} />;
};

const PurchaseOrderTableEventAccordionWrapper = (
  props: EventTicketGroupBodyProps
) => {
  const {
    entities: ticketGroups,
    entityCount: ticketGrpsCnt,
    getDataFail,
    ...rest
  } = props;

  const hasVirtuosoMinRows = ticketGrpsCnt > MinRowsForUsingVirtuoso;

  const { isEnabled } = useCollapsableViewContext();

  return (
    <PurchaseOrderTable
      {...rest}
      useVirtuoso={isEnabled || hasVirtuosoMinRows}
      entityCount={ticketGrpsCnt}
      entities={ticketGroups}
      groupId={props.event.viagVirtualId}
      viewMode={PurchaseViewMode.EventTileView}
      enableColumnFilters
    />
  );
};
