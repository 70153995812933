import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { groupAndAggregateMetrics } from 'src/contexts/CatalogMetricsContext/CatalogMetrics.utils';
import { useGeSummaryMetricsQuery } from 'src/contexts/CatalogMetricsContext/useGetSummaryMetricsQuery';
import { Content } from 'src/contexts/ContentContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { PosFloatingSpinner } from 'src/core/POS/PosFloatingSpinner';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { getCatalogSummaryMetrics } from 'src/navigations/Routes/Inventory';
import ListingReportTable from 'src/tables/ListingTable/ListingReportTable';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingCatalogMetricsQueryKey } from 'src/utils/inventoryUtils';
import { ListingReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import {
  addListingMetrics,
  getInventorySummaryListingMetricsDisplayStrings,
} from 'src/utils/ticketMetricUtils';
import {
  ActionOutboxEntityType,
  ListingMetrics,
  ListingQuery,
  ListingReportMetrics,
  ReportWidgetTypesCommission,
  ReportWidgetTypesInventory,
  ReportWidgetTypesSale,
} from 'src/WebApiController';

import {
  AverageTicketPriceDisplay,
  SalesAndMarginDisplay,
  TicketsListedAndSoldDisplay,
} from '../Listings/InventorySummaryWidgets';
import { ReportsFilterToolbar } from '../Reports/ReportsFilterToolbar';
import * as styles from './ReportsInventoryPage.css';
import { mapReportMetrics } from './ReportsInventoryPage.utils';

export function ReportsInventoryPage({
  reportConfig,
}: {
  reportConfig: ReportConfig;
}) {
  const {
    reportMetrics,
    reportSummary,
    totalCount,
    isLoading,
    isRefreshing,
    refreshMetrics,
  } = useReportMetricsContext<ListingReportMetrics>();
  const { data, eventsTransformed } = useCatalogDataContext();

  const reportMetricsMapped: ListingReportMetricsWithGroupBy[] | undefined =
    useMemo(
      () => mapReportMetrics(reportMetrics, eventsTransformed, data),
      [data, eventsTransformed, reportMetrics]
    );

  const initSorting = useMemo(() => {
    const sortBy =
      reportConfig.sortBy ??
      GROUP_BY_TO_PRIMARY_COLUMN_ID[reportConfig.groupBy];

    return [
      {
        id: sortBy,
        desc: reportConfig.isSortDescending ?? false,
      },
    ];
  }, [
    reportConfig.groupBy,
    reportConfig.isSortDescending,
    reportConfig.sortBy,
  ]);

  return (
    <div className={styles.root}>
      {reportConfig.reportName && (
        <h1 className={styles.pageName}>{reportConfig.reportName}</h1>
      )}
      <ReportsFilterToolbar reportConfig={reportConfig} />
      <WidgetSection widgets={reportConfig.widgets} />
      {isLoading ? (
        <PosSpinner />
      ) : (
        <div className={styles.tableContainer}>
          <ListingReportTable
            className={styles.table}
            metricsData={reportMetricsMapped}
            summaryData={reportSummary}
            totalCount={totalCount}
            report={reportConfig}
            refreshMetrics={refreshMetrics}
            initState={{
              sorting: initSorting,
            }}
          />
          {isRefreshing && (
            <PosFloatingSpinner
              message={<Content id={ContentId.DataIsRefreshing} />}
            />
          )}
        </div>
      )}
    </div>
  );
}

export const WidgetSection = ({
  widgets,
}: {
  widgets?:
    | ReportWidgetTypesInventory[]
    | ReportWidgetTypesSale[]
    | ReportWidgetTypesCommission[]
    | null;
}) => {
  if (!widgets?.length) {
    return null;
  }

  return (
    <CatalogMetricsContextProvider<ListingMetrics, ListingQuery>
      queryKey={ListingCatalogMetricsQueryKey}
      entityType={ActionOutboxEntityType.Listing}
      getCatalogMetrics={(c, f) => c.getCatalogListingMetrics(f)}
      addCatalogMetrics={addListingMetrics}
    >
      <WidgetSectionContent widgets={widgets} />
    </CatalogMetricsContextProvider>
  );
};

export const WidgetSectionContent = ({
  widgets,
}: {
  widgets?:
    | ReportWidgetTypesInventory[]
    | ReportWidgetTypesSale[]
    | ReportWidgetTypesCommission[]
    | null;
}) => {
  const { eventsTransformed } = useCatalogDataContext();
  const { loginContext } = useAppContext();

  const { summaryMetrics, isLoading } =
    useGeSummaryMetricsQuery<ListingMetrics>(
      'ListingMetrics',
      eventsTransformed
        ?.map((ev) => ev.event.perfId)
        .filter((id) => id != null)
        .map((id) => id!),
      eventsTransformed?.map((ev) => ev.event.venueId),
      (metrics: ListingMetrics[]) =>
        groupAndAggregateMetrics(metrics, addListingMetrics),
      getCatalogSummaryMetrics
    );

  const formattedTotalMetrics = summaryMetrics
    ? getInventorySummaryListingMetricsDisplayStrings(
        summaryMetrics,
        loginContext?.user?.activeAccount?.currencyCode
      )
    : null;

  if (
    !widgets?.length ||
    (eventsTransformed != null && !isLoading && !summaryMetrics)
  ) {
    return null;
  }
  widgets = widgets as ReportWidgetTypesInventory[];

  return (
    <div className={styles.tilesSection}>
      <Stack gap="xl" flexWrap="wrap">
        {widgets.includes(ReportWidgetTypesInventory.TicketsListedAndSold) && (
          <TicketsListedAndSoldDisplay
            formattedTotalMetrics={formattedTotalMetrics}
          />
        )}
        {widgets.includes(ReportWidgetTypesInventory.SalesAndMargin) && (
          <SalesAndMarginDisplay
            formattedTotalMetrics={formattedTotalMetrics}
          />
        )}
        {widgets.includes(ReportWidgetTypesInventory.AverageTicketPrice) && (
          <AverageTicketPriceDisplay
            formattedTotalMetrics={formattedTotalMetrics}
          />
        )}
      </Stack>
    </div>
  );
};
