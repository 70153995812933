import { useMemo } from 'react';
import {
  useFilterQueryState,
  UseFilterQueryStateType,
} from 'src/components/MainFilterBar/useFilterQueryState';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { EventWithData } from 'src/WebApiController';
import { ActionOutboxEntityType, Sale, SaleQuery } from 'src/WebApiController';

interface UseSalesBulkActionsState {
  isAllSalesSelected: boolean;
  affectedVisibleSaleIds: number[];
  filterQueryWithEventIds: UseFilterQueryStateType<SaleQuery>;
}

export const useSalesBulkActionsState = (
  event?: EventWithData['event']
): UseSalesBulkActionsState => {
  const { isAllSelected } = useMultiSelectionContext();

  const { filterQueryWithEventIds } = useFilterQueryState<SaleQuery>(
    ActionOutboxEntityType.Sale,
    event?.viagVirtualId ? [event?.viagVirtualId] : []
  );

  const { eventsTransformed } = useCatalogDataContext();

  const sales = useMemo(() => {
    return (eventsTransformed ?? [])
      .flatMap((ev) => ev.entities.sales)
      .filter((s) => s != null) as Sale[];
  }, [eventsTransformed]);

  const affectedVisibleSaleIds = useMemo(() => {
    // Else we must respect only the entity Ids and event Ids
    const ids = [...(filterQueryWithEventIds.entityIds ?? [])];
    ids.push(
      ...sales
        .filter(
          (s) =>
            filterQueryWithEventIds.eventOrMappingIds?.includes(s.viagVirtualId)
        )
        .map((s) => s.id)
    );
    return ids;
  }, [
    filterQueryWithEventIds.entityIds,
    filterQueryWithEventIds.eventOrMappingIds,
    sales,
  ]);

  return {
    isAllSalesSelected: isAllSelected,
    affectedVisibleSaleIds,
    filterQueryWithEventIds,
  };
};
