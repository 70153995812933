import { ScrollAreaProps } from '@radix-ui/react-scroll-area';
import { PropsWithChildren } from 'react';
import { ScrollArea } from 'src/core/ui';
import { ScrollAreaRefActions } from 'src/core/ui/ScrollArea/ScrollArea';

import * as styles from './ScrollableToolbar.css';

export function ScrollableToolbar({
  children,
  scrollAreaRefActions,
  ...props
}: PropsWithChildren<ScrollAreaProps> & ScrollAreaRefActions) {
  return (
    <ScrollArea
      showScrollEdges
      {...props}
      scrollAreaRefActions={scrollAreaRefActions}
    >
      <div className={styles.root}>{children}</div>
    </ScrollArea>
  );
}
