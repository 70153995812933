import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { PosTextField } from 'src/core/POS/PosTextField';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput } from 'src/WebApiController';

function TicketGroupInputQuantityComponent({
  cell,
  ...inputProps
}: CellContext<TicketGroupInput, unknown> & {
  control: Control<PurchaseTicketsInput, any>;
  style?: React.CSSProperties;
}) {
  const {
    register,
    setValue,
    getValues,
    getFieldState,
    formState,
    clearErrors,
  } = useFormContext<PurchaseTicketsInput>();

  const hasError = getFieldState(
    `ticketGroups.${cell.row.index}.numberOfTickets`,
    formState
  )?.error;

  return (
    <PosTextField
      {...inputProps}
      min={1}
      type={'number'}
      {...register(`ticketGroups.${cell.row.index}.numberOfTickets.value`, {
        valueAsNumber: true,
        onBlur: (e) => {
          const quantity = e.target.value;
          if (hasError && quantity !== '') {
            clearErrors(`ticketGroups.${cell.row.index}.numberOfTickets`);
          }
        },
        onChange: (e) => {
          const quantity = e.target.value;
          if (quantity === '') {
            return;
          }

          const unitCost = getValues(
            `ticketGroups.${cell.row.index}.unitCost.value`
          );

          const totalCost = getValues(
            `ticketGroups.${cell.row.index}.totalCost.value`
          );

          if (!unitCost && totalCost) {
            const calculatedUnitCost = parseFloat(
              (totalCost / quantity).toFixed(2)
            );

            setValue(
              `ticketGroups.${cell.row.index}.unitCost`,
              posChangedField(calculatedUnitCost)
            );
            return;
          }

          if (!unitCost) {
            return;
          }

          const calculatedTotalCost = parseFloat(
            (unitCost * quantity).toFixed(2)
          );

          setValue(
            `ticketGroups.${cell.row.index}.totalCost`,
            posChangedField(calculatedTotalCost)
          );
        },
      })}
      rootProps={{
        className: clsx(styles.tableCell['active']),
        style: {
          ...inputProps.style,
        },
      }}
      style={{
        textAlign: 'right',
      }}
    />
  );
}

export const TicketGroupInputQuantity = React.memo(
  TicketGroupInputQuantityComponent
) as (
  props: CellContext<TicketGroupInput, unknown> & {
    control: Control<PurchaseTicketsInput, any>;
    style?: React.CSSProperties;
  }
) => JSX.Element;
