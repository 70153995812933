import { FormatContent } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { FormatOption } from 'src/modals/EditTableColumns';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  BulkEditPreviewWithDetails,
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

export const BulkEditPreviewPurchaseDetails = ({
  preview,
}: {
  preview?: BulkEditPreviewWithDetails | null;
}) => {
  if (!preview?.purchases) {
    return null;
  }

  return (
    <Stack
      direction="column"
      style={{ height: '50vh' }}
      gap="l"
      alignItems="center"
    >
      {preview.purchases.length <
        (preview?.preview?.bulkEditCountPreview?.Purchase?.totalCount ?? 0) && (
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.BulkEditMaxItemPreviewDisplayMessage}
              params={[
                `${preview.purchases.length}`,
                `${preview?.preview?.bulkEditCountPreview?.Purchase?.totalCount}`,
              ]}
            />
          }
        />
      )}
      <PurchaseOrderTable
        groupId={NO_GROUP_ID}
        useVirtuoso
        disablePagination
        disableSelection
        entityCount={preview.purchases.length}
        entities={(preview.purchases ?? []).map(
          (p) => p as PurchaseOrderTicketGroup
        )}
        getDataFail={false}
        isItemsLoading={false}
        viewMode={PurchaseViewMode.FlattenedView}
        columnVisibilityOverride={[
          PurchasesTableColumnId.VendorOrderId,
          PurchasesTableColumnId.PurchaseDate,
          PurchasesTableColumnId.VendorAccountEmail,
          PurchasesTableColumnId.BuyerUserId,
          PurchasesTableColumnId.Actions,
        ]}
        formatOptionOverrides={{
          [PurchasesTableColumnId.Actions]: FormatOption.Actions_LinkOnly,
        }}
        withOuterPadding={false}
      />
    </Stack>
  );
};
