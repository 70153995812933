import { isEqual } from 'lodash-es';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import {
  DEFAULT_COMP_LISTING_FLOOR,
  DEFAULT_UNDERCUT_ABSOLUTE_AMT,
  DEFAULT_UNDERCUT_RELATIVE_AMT,
  PricingSettingsField,
} from 'src/utils/autoPricingUtils';
import { hasChanged, posChangedField, posField } from 'src/utils/posFieldUtils';
import {
  AutoPricingCompListingMode,
  Listing,
  ListingDetailsAutoPricingSectionUpdatesV2,
} from 'src/WebApiController';

import {
  AutoPricingSettingsInput,
  PricingSettingsUpdateKey,
} from './BulkEditAutoPricingSettingsDialog.types';

export const validateCompListingSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsAutoPricingSectionUpdatesV2>,
  setError: UseFormSetError<ListingDetailsAutoPricingSectionUpdatesV2>,
  setValue: UseFormSetValue<ListingDetailsAutoPricingSectionUpdatesV2>,
  listForm: ListingDetailsAutoPricingSectionUpdatesV2,
  compListingFloorCeilingError: string,
  requiredMsg: string,
  errorField?: PricingSettingsField,
  isStrategy?: boolean
) => {
  let hasErrors = false;

  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('compListingFloor');
    clearErrors('compListingSelectedSectionSettings');
  }

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSelectedSectionsEnabled,
    compListingSelectedSectionSettings,
    autoPricingEnabled,
  } = listForm;

  const skipCompListing = !(
    compListingMode?.hasChanged ||
    compListingFloor?.hasChanged ||
    compListingCeiling?.hasChanged ||
    compListingOnlyForSelectedSectionsEnabled?.hasChanged ||
    compListingSelectedSectionSettings?.hasChanged
  );

  if (!autoPricingEnabled || skipCompListing) {
    return true;
  }

  if (
    compListingMode?.value === AutoPricingCompListingMode.QualityScore ||
    compListingMode?.value === AutoPricingCompListingMode.SameSection
  ) {
    if (!compListingFloor?.value) {
      listForm.compListingFloor = compListingFloor;
      setValue('compListingFloor', posChangedField(DEFAULT_COMP_LISTING_FLOOR));
    } else if (compListingCeiling && compListingFloor >= compListingCeiling) {
      setError(
        errorField ?? 'compListingFloor',
        {
          message: compListingFloorCeilingError,
        },
        { shouldFocus: true }
      );

      hasErrors = true;
    }
  }

  if (
    compListingMode?.value === AutoPricingCompListingMode.QualityScore ||
    compListingMode?.value === AutoPricingCompListingMode.SameEvent ||
    compListingMode?.value === AutoPricingCompListingMode.SameZone
  ) {
    if (
      compListingOnlyForSelectedSectionsEnabled?.value &&
      !isStrategy &&
      !compListingSelectedSectionSettings?.value?.sectionIdFilter?.length
    ) {
      setError(
        errorField ?? 'compListingSelectedSectionSettings',
        {
          message: requiredMsg,
        },
        { shouldFocus: true }
      );

      hasErrors = true;
    }
  }

  return !hasErrors;
};

export const validateUndercutSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsAutoPricingSectionUpdatesV2>,
  setValue: UseFormSetValue<ListingDetailsAutoPricingSectionUpdatesV2>,
  listForm: ListingDetailsAutoPricingSectionUpdatesV2,
  errorField?: PricingSettingsField
) => {
  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('undercutAbsoluteAmount');
    clearErrors('undercutRelativeAmount');
  }

  const {
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,
    autoPricingEnabled,
  } = listForm;

  if (
    !autoPricingEnabled ||
    !(
      undercutMode?.hasChanged ||
      undercutAbsoluteAmount?.hasChanged ||
      undercutRelativeAmount?.hasChanged
    )
  ) {
    return true;
  }

  if (undercutMode?.value) {
    if (undercutAbsoluteAmount?.value == null) {
      listForm.undercutAbsoluteAmount = posChangedField(
        DEFAULT_UNDERCUT_ABSOLUTE_AMT
      );
      setValue(
        'undercutAbsoluteAmount',
        posChangedField(DEFAULT_UNDERCUT_ABSOLUTE_AMT)
      );
    }

    if (undercutRelativeAmount?.value == null) {
      listForm.undercutRelativeAmount = posChangedField(
        DEFAULT_UNDERCUT_RELATIVE_AMT
      );
      setValue(
        'undercutRelativeAmount',
        posChangedField(DEFAULT_UNDERCUT_RELATIVE_AMT)
      );
    }
  }

  return true;
};

export const validateNetProceedsSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsAutoPricingSectionUpdatesV2>,
  setError: UseFormSetError<ListingDetailsAutoPricingSectionUpdatesV2>,
  listForm: ListingDetailsAutoPricingSectionUpdatesV2,
  floorMustBeLessThanCeilingError: string,
  errorField?: PricingSettingsField
) => {
  let hasErrors = false;

  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('netProceedsCeiling');
    clearErrors('netProceedsFloor');
  }

  const { netProceedsFloor, netProceedsCeiling } = listForm;

  if (
    (netProceedsFloor?.value ?? 0) > 0 &&
    (netProceedsCeiling?.value ?? 0) > 0
  ) {
    if (netProceedsFloor! >= netProceedsCeiling!) {
      setError(errorField ?? 'netProceedsFloor', {
        message: floorMustBeLessThanCeilingError,
      });
      hasErrors = true;
    }
  }

  return !hasErrors;
};

export const validateAutoPricingSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsAutoPricingSectionUpdatesV2>,
  setError: UseFormSetError<ListingDetailsAutoPricingSectionUpdatesV2>,
  setValue: UseFormSetValue<ListingDetailsAutoPricingSectionUpdatesV2>,
  listForm: ListingDetailsAutoPricingSectionUpdatesV2,
  floorMustBeLessThanCeilingError: string,
  compListingFloorCeilingError: string,
  requiredMsg: string,
  isStrategy?: boolean
) => {
  let hasErrors = false;

  if (
    !validateCompListingSettings(
      clearErrors,
      setError,
      setValue,
      listForm,
      compListingFloorCeilingError,
      requiredMsg,
      undefined,
      isStrategy
    )
  ) {
    hasErrors = true;
  }

  if (!validateUndercutSettings(clearErrors, setValue, listForm)) {
    hasErrors = true;
  }

  if (
    !isStrategy &&
    !validateNetProceedsSettings(
      clearErrors,
      setError,
      listForm,
      floorMustBeLessThanCeilingError
    )
  ) {
    hasErrors = true;
  }

  return !hasErrors;
};

export const getSettingsInputChanged = (
  input: ListingDetailsAutoPricingSectionUpdatesV2,
  initial?: ListingDetailsAutoPricingSectionUpdatesV2,
  settingsInput?: AutoPricingSettingsInput
): Partial<
  Record<keyof ListingDetailsAutoPricingSectionUpdatesV2, boolean | undefined>
> => {
  const changedFields: Partial<
    Record<keyof ListingDetailsAutoPricingSectionUpdatesV2, boolean | undefined>
  > = {
    compListingFloor: hasChanged(
      input.compListingFloor,
      initial?.compListingFloor
    ),
    compListingCeiling: hasChanged(
      input.compListingCeiling,
      initial?.compListingCeiling
    ),
    undercutAbsoluteAmount: hasChanged(
      input.undercutAbsoluteAmount,
      initial?.undercutAbsoluteAmount
    ),
    undercutRelativeAmount: hasChanged(
      input.undercutRelativeAmount,
      initial?.undercutRelativeAmount
    ),
    netProceedsCeiling: hasChanged(
      input.netProceedsCeiling,
      initial?.netProceedsCeiling
    ),
    netProceedsFloor: hasChanged(
      input.netProceedsFloor,
      initial?.netProceedsFloor
    ),
    undercutMode: hasChanged(input.undercutMode, initial?.undercutMode),
    netProceedsFloorAdjustmentAmount:
      input.netProceedsFloorAdjustmentAmount != null &&
      input.netProceedsFloorAdjustmentType != null &&
      input.netProceedsFloorAdjustmentRelative != null,
    compListingMode: hasChanged(
      input.compListingMode,
      initial?.compListingMode
    ),
    id: false,
    rowVersion: false,
    autoPricingEnabled: hasChanged(
      input.autoPricingEnabled,
      initial?.autoPricingEnabled
    ),
    compListingQuantityScoreAdjustmentEnabled: hasChanged(
      input.compListingQuantityScoreAdjustmentEnabled,
      initial?.compListingQuantityScoreAdjustmentEnabled
    ),
    compListingQuantityScoreAdjustmentOverrideJson: hasChanged(
      input.compListingQuantityScoreAdjustmentOverrideJson,
      initial?.compListingQuantityScoreAdjustmentOverrideJson
    ),
    compListingOnlyForSameZoneEnabled: hasChanged(
      input.compListingOnlyForSameZoneEnabled,
      initial?.compListingOnlyForSameZoneEnabled
    ),
    compListingOnlyForSelectedSectionsEnabled: hasChanged(
      input.compListingOnlyForSelectedSectionsEnabled,
      initial?.compListingOnlyForSelectedSectionsEnabled
    ),
    compListingExcludeFanInventory: hasChanged(
      input.compListingExcludeFanInventory,
      initial?.compListingExcludeFanInventory
    ),
    compListingExcludeDefects: hasChanged(
      input.compListingExcludeDefects,
      initial?.compListingExcludeDefects
    ),
    compListingSelectedSectionSettings:
      hasChanged(
        input.compListingSelectedSectionSettings,
        initial?.compListingSelectedSectionSettings
      ) ||
      (settingsInput?.compListingSelectedSectionSettings?.hasConflict &&
        !input.includeExistingSelectedSections),
    deriveCompsFromVenueZoneConfig:
      input.deriveCompsFromVenueZoneConfig !=
      initial?.deriveCompsFromVenueZoneConfig,
    includeBetterZones: input.includeBetterZones != initial?.includeBetterZones,
    rowCompOffset: input.rowCompOffset != initial?.rowCompOffset,
    venueZoneMapName: input.venueZoneMapName != initial?.venueZoneMapName,
    circuitBreakerRelativeCeiling: hasChanged(
      input.circuitBreakerRelativeCeiling,
      initial?.circuitBreakerRelativeCeiling
    ),
    circuitBreakerRelativeFloor: hasChanged(
      input.circuitBreakerRelativeFloor,
      initial?.circuitBreakerRelativeFloor
    ),
    circuitBreakerMaxDiscountVelocityPercent: hasChanged(
      input.circuitBreakerMaxDiscountVelocityPercent,
      initial?.circuitBreakerMaxDiscountVelocityPercent
    ),
    circuitBreakerMaxDiscountVelocityTicksInHours: hasChanged(
      input.circuitBreakerMaxDiscountVelocityTicksInHours,
      initial?.circuitBreakerMaxDiscountVelocityTicksInHours
    ),
    circuitBreakerMinCompListingCount: hasChanged(
      input.circuitBreakerMinCompListingCount,
      initial?.circuitBreakerMinCompListingCount
    ),
    outlierMode: hasChanged(input.outlierMode, initial?.outlierMode),
    outlierStandardDeviations: hasChanged(
      input.outlierStandardDeviations,
      initial?.outlierStandardDeviations
    ),
    outlierKthLowestLimit: hasChanged(
      input.outlierKthLowestLimit,
      initial?.outlierKthLowestLimit
    ),
    outlierKthLowestLimitRelativeSpacing: hasChanged(
      input.outlierKthLowestLimitRelativeSpacing,
      initial?.outlierKthLowestLimitRelativeSpacing
    ),
    outlierKthLowestLimitAbsoluteSpacing: hasChanged(
      input.outlierKthLowestLimitAbsoluteSpacing,
      initial?.outlierKthLowestLimitAbsoluteSpacing
    ),
    currencyCode: false,
  };

  return changedFields;
};

export const getAutoPricingSettingsUpdateInput = (
  listing: AutoPricingSettingsInput,
  accountCurrency: string | null,
  allowSeatScoreCompListingMode: boolean,
  hasBulkEditPricingSettingsUpdateFeature: boolean
): ListingDetailsAutoPricingSectionUpdatesV2 => {
  let compListingMode;
  if (hasBulkEditPricingSettingsUpdateFeature) {
    compListingMode =
      !allowSeatScoreCompListingMode &&
      listing.compListingMode.value === AutoPricingCompListingMode.QualityScore
        ? posField(AutoPricingCompListingMode.SameEvent, true)
        : posField(listing.compListingMode.value);
  } else {
    // Bulk edit only supports SameEvent
    compListingMode = posField(AutoPricingCompListingMode.SameEvent, true);
  }
  return {
    id: -1,
    rowVersion: null,
    currencyCode: listing.currencyCode.value ?? accountCurrency,
    netProceedsFloorAdjustmentType: null,
    netProceedsFloorAdjustmentAmount: null,
    netProceedsFloorAdjustmentRelative: false,
    netProceedsFloor: posField(listing.netProceedsFloor.value),
    netProceedsCeiling: posField(listing.netProceedsCeiling.value),
    autoPricingEnabled: posField(listing.autoPricingEnabled.value),
    compListingFloor: posField(listing.compListingFloor.value),
    compListingCeiling: posField(listing.compListingCeiling.value),
    compListingMode,
    compListingOnlyForSameZoneEnabled: posField(
      listing.compListingOnlyForSameZoneEnabled.value
    ),
    compListingOnlyForSelectedSectionsEnabled: posField(
      listing.compListingOnlyForSelectedSectionsEnabled.value
    ),
    compListingExcludeFanInventory: posField(
      listing.compListingExcludeFanInventory.value
    ),
    compListingExcludeDefects: posField(
      listing.compListingExcludeDefects.value
    ),
    compListingQuantityScoreAdjustmentEnabled: posField(
      listing.compListingQuantityScoreAdjustmentEnabled.value
    ),
    compListingQuantityScoreAdjustmentOverrideJson: posField(
      listing.compListingQuantityScoreAdjustmentOverrideJson.value
    ),
    compListingSelectedSectionSettings: posField(
      listing.compListingSelectedSectionSettings.value
    ),
    deriveCompsFromVenueZoneConfig: false,
    includeBetterZones: false,
    rowCompOffset: 0,
    venueZoneMapName: null,
    includeExistingSelectedSections:
      !listing.compListingSelectedSectionSettings.value, // If no common sections are selected, include existing sections
    undercutMode: posField(listing.undercutMode.value),
    undercutAbsoluteAmount: posField(listing.undercutAbsoluteAmount.value),
    undercutRelativeAmount: posField(listing.undercutRelativeAmount.value),
    circuitBreakerMaxDiscountVelocityPercent: posField(
      listing.circuitBreakerMaxDiscountVelocityPercent.value
    ),
    circuitBreakerMaxDiscountVelocityTicksInHours: posField(
      listing.circuitBreakerMaxDiscountVelocityTicksInHours.value
    ),
    circuitBreakerMinCompListingCount: posField(
      listing.circuitBreakerMinCompListingCount.value
    ),
    circuitBreakerRelativeCeiling: posField(
      listing.circuitBreakerRelativeCeiling.value
    ),
    circuitBreakerRelativeFloor: posField(
      listing.circuitBreakerRelativeFloor.value
    ),
    outlierMode: posField(listing.outlierMode.value),
    outlierStandardDeviations: posField(
      listing.outlierStandardDeviations.value
    ),
    outlierKthLowestLimit: posField(listing.outlierKthLowestLimit.value),
    outlierKthLowestLimitRelativeSpacing: posField(
      listing.outlierKthLowestLimitRelativeSpacing.value
    ),
    outlierKthLowestLimitAbsoluteSpacing: posField(
      listing.outlierKthLowestLimitAbsoluteSpacing.value
    ),
  };
};

export const generateDefaultSettings = (
  listings: Listing[],
  multiEvents: boolean,
  hasBulkEditPricingSettingsUpdateFeature: boolean
): AutoPricingSettingsInput => {
  const currencyCode = new Set(listings.map((l) => l.currency));
  const autoPricingEnabled = new Set(listings.map((l) => l.isAutoPrc));
  const netProceedsFloor = new Set(listings.map((l) => l.procsFloor));
  const netProceedsCeiling = new Set(listings.map((l) => l.procsCeil));
  const compListingFloor = new Set(listings.map((l) => l.compFloor));
  const compListingCeiling = new Set(listings.map((l) => l.compCeil));
  const compListingSelectedSectionSettings = listings.map(
    (l) => l.compSectionSettings
  );
  const compListingOnlyForSameZoneEnabled = new Set(
    listings.map((l) => l.isCompForSameZone)
  );
  const compListingOnlyForSelectedSectionsEnabled = new Set(
    listings.map((l) => l.isCompSelectedSections)
  );
  const compListingExcludeFanInventory = new Set(
    listings.map((l) => l.compExclFanInv)
  );
  const compListingExcludeDefects = new Set(
    listings.map((l) => l.compExclDefects)
  );
  const compListingQuantityScoreAdjustmentEnabled = new Set(
    listings.map((l) => l.isCompQtyScrAdj)
  );
  const compListingQuantityScoreAdjustmentOverrideJson = new Set(
    listings.map((l) => l.compQtyScrAdjJson)
  );
  const undercutMode = new Set(listings.map((l) => l.undMode));
  const undercutAbsoluteAmount = new Set(listings.map((l) => l.undAbsAmt));
  const undercutRelativeAmount = new Set(listings.map((l) => l.undRelAmt));
  const circuitBreakerMaxDiscountVelocityPercent = new Set(
    listings.map((l) => l.cirBrMaxDiscVelocPercent)
  );
  const circuitBreakerMaxDiscountVelocityTicksInHours = new Set(
    listings.map((l) => l.cirBrMaxDiscVelocTicksInHrs)
  );
  const circuitBreakerMinCompListingCount = new Set(
    listings.map((l) => l.cirBrMinCompCount)
  );

  const circuitBreakerRelativeCeiling = new Set(
    listings.map((l) => l.cirBrRelCeil)
  );
  const circuitBreakerRelativeFloor = new Set(
    listings.map((l) => l.cirBrRelFloor)
  );
  const outlierMode = new Set(listings.map((l) => l.outMode));
  const outlierStandardDeviations = new Set(listings.map((l) => l.outlStdDev));
  const outlierKthLowestLimit = new Set(listings.map((l) => l.outKthLowestLim));
  const outlierKthLowestLimitRelativeSpacing = new Set(
    listings.map((l) => l.outKthLowestLimRelSpc)
  );
  const outlierKthLowestLimitAbsoluteSpacing = new Set(
    listings.map((l) => l.outKthLowestLimAbsSpc)
  );
  const compListingMode = new Set(listings.map((l) => l.compMode));

  return {
    currencyCode: toSettingInput(currencyCode, multiEvents),
    autoPricingEnabled: toSettingInput(autoPricingEnabled, multiEvents),
    netProceedsFloor: toSettingInput(netProceedsFloor, multiEvents),
    netProceedsCeiling: toSettingInput(netProceedsCeiling, multiEvents),
    compListingMode: hasBulkEditPricingSettingsUpdateFeature
      ? toSettingInput(compListingMode, multiEvents)
      : {
          value: AutoPricingCompListingMode.SameEvent,
          hasConflict: false,
        }, // Bulk edit only supports SameEvent
    compListingFloor: toSettingInput(compListingFloor, multiEvents),
    compListingCeiling: toSettingInput(compListingCeiling, multiEvents),
    compListingSelectedSectionSettings: toSettingInputFromArray(
      compListingSelectedSectionSettings,
      multiEvents
    ),
    compListingOnlyForSameZoneEnabled: toSettingInput(
      compListingOnlyForSameZoneEnabled,
      multiEvents
    ),
    compListingOnlyForSelectedSectionsEnabled: toSettingInput(
      compListingOnlyForSelectedSectionsEnabled,
      multiEvents
    ),
    compListingExcludeFanInventory: toSettingInput(
      compListingExcludeFanInventory,
      multiEvents
    ),
    compListingExcludeDefects: toSettingInput(
      compListingExcludeDefects,
      multiEvents
    ),
    compListingQuantityScoreAdjustmentEnabled: toSettingInput(
      compListingQuantityScoreAdjustmentEnabled,
      multiEvents
    ),
    compListingQuantityScoreAdjustmentOverrideJson: toSettingInput(
      compListingQuantityScoreAdjustmentOverrideJson,
      multiEvents
    ),
    undercutMode: toSettingInput(undercutMode, multiEvents),
    undercutAbsoluteAmount: toSettingInput(undercutAbsoluteAmount, multiEvents),
    undercutRelativeAmount: toSettingInput(undercutRelativeAmount, multiEvents),
    circuitBreakerMaxDiscountVelocityPercent: toSettingInput(
      circuitBreakerMaxDiscountVelocityPercent,
      multiEvents
    ),
    circuitBreakerMaxDiscountVelocityTicksInHours: toSettingInput(
      circuitBreakerMaxDiscountVelocityTicksInHours,
      multiEvents
    ),
    circuitBreakerMinCompListingCount: toSettingInput(
      circuitBreakerMinCompListingCount,
      multiEvents
    ),
    circuitBreakerRelativeCeiling: toSettingInput(
      circuitBreakerRelativeCeiling,
      multiEvents
    ),
    circuitBreakerRelativeFloor: toSettingInput(
      circuitBreakerRelativeFloor,
      multiEvents
    ),
    outlierMode: toSettingInput(outlierMode, multiEvents),
    outlierStandardDeviations: toSettingInput(
      outlierStandardDeviations,
      multiEvents
    ),
    outlierKthLowestLimit: toSettingInput(outlierKthLowestLimit, multiEvents),
    outlierKthLowestLimitRelativeSpacing: toSettingInput(
      outlierKthLowestLimitRelativeSpacing,
      multiEvents
    ),
    outlierKthLowestLimitAbsoluteSpacing: toSettingInput(
      outlierKthLowestLimitAbsoluteSpacing,
      multiEvents
    ),
  };
};

const toSettingInput = <T>(values: Set<T>, multiEvents = false) => {
  const isUnique = values.size === 1;
  // Default to hasConflict if there are multiple events
  const hasConflict = values.size > 1 || multiEvents;
  return {
    value: isUnique ? Array.from(values)[0] ?? null : null,
    hasConflict,
  };
};

const toSettingInputFromArray = <T>(values: T[], multiEvents: boolean) => {
  const allEqual = values.every((v) => isEqual(v, values[0]));
  return {
    value: allEqual ? values[0] ?? null : null,
    // Default to hasConflict if there are multiple events
    hasConflict: !allEqual || multiEvents,
  };
};

export const santizedPricingSettingsUpdateInput = (
  input: ListingDetailsAutoPricingSectionUpdatesV2,
  updateInput?: Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>
): ListingDetailsAutoPricingSectionUpdatesV2 => {
  return {
    id: input.id,
    rowVersion: input.rowVersion,
    currencyCode: input.currencyCode,
    netProceedsFloorAdjustmentType: input.netProceedsFloorAdjustmentType,
    netProceedsFloorAdjustmentAmount: input.netProceedsFloorAdjustmentAmount,
    netProceedsFloorAdjustmentRelative:
      input.netProceedsFloorAdjustmentRelative,
    netProceedsFloor: posField(
      input.netProceedsFloor?.value ?? null,
      input.netProceedsFloor?.hasChanged && updateInput?.netProceedsFloor
    ),
    netProceedsCeiling: posField(
      input.netProceedsCeiling?.value ?? null,
      input.netProceedsCeiling?.hasChanged && updateInput?.netProceedsCeiling
    ),
    autoPricingEnabled: posField(
      input.autoPricingEnabled?.value ?? null,
      input.autoPricingEnabled?.hasChanged && updateInput?.autoPricingEnabled
    ),
    undercutMode: input.undercutMode,
    undercutAbsoluteAmount: posField(
      input.undercutAbsoluteAmount?.value ?? null,
      input.undercutAbsoluteAmount?.hasChanged &&
        updateInput?.undercutAbsoluteAmount != null
    ),
    undercutRelativeAmount: posField(
      input.undercutRelativeAmount?.value ?? null,
      input.undercutRelativeAmount?.hasChanged &&
        updateInput?.undercutRelativeAmount
    ),
    compListingMode: posField(
      input.compListingMode?.value ?? null,
      input.compListingMode?.hasChanged && updateInput?.compListingMode
    ),
    compListingFloor: posField(
      input.compListingFloor?.value ?? null,
      input.compListingFloor?.hasChanged && updateInput?.compListingFloor
    ),
    compListingCeiling: posField(
      input.compListingCeiling?.value ?? null,
      input.compListingCeiling?.hasChanged && updateInput?.compListingCeiling
    ),
    compListingQuantityScoreAdjustmentEnabled: posField(
      input.compListingQuantityScoreAdjustmentEnabled?.value ?? null,
      input.compListingQuantityScoreAdjustmentEnabled?.hasChanged &&
        updateInput?.compListingQuantityScoreAdjustmentEnabled
    ),
    compListingQuantityScoreAdjustmentOverrideJson: posField(
      input.compListingQuantityScoreAdjustmentOverrideJson?.value ?? null,
      input.compListingQuantityScoreAdjustmentOverrideJson?.hasChanged &&
        updateInput?.compListingQuantityScoreAdjustmentOverrideJson
    ),
    compListingOnlyForSameZoneEnabled: posField(
      input.compListingOnlyForSameZoneEnabled?.value ?? null,
      input.compListingOnlyForSameZoneEnabled?.hasChanged &&
        updateInput?.compListingOnlyForSameZoneEnabled
    ),
    compListingOnlyForSelectedSectionsEnabled: posField(
      input.compListingOnlyForSelectedSectionsEnabled?.value ?? null,
      input.compListingOnlyForSelectedSectionsEnabled?.hasChanged &&
        updateInput?.compListingOnlyForSelectedSectionsEnabled
    ),
    compListingExcludeFanInventory: posField(
      input.compListingExcludeFanInventory?.value ?? null,
      input.compListingExcludeFanInventory?.hasChanged &&
        updateInput?.compListingExcludeFanInventory
    ),
    compListingExcludeDefects: posField(
      input.compListingExcludeDefects?.value ?? null,
      input.compListingExcludeDefects?.hasChanged &&
        updateInput?.compListingExcludeDefects
    ),
    compListingSelectedSectionSettings: posField(
      input.compListingSelectedSectionSettings?.value ?? null,
      (input.compListingSelectedSectionSettings?.hasChanged ||
        !input.includeExistingSelectedSections) && // When not include existing listings, always update
        updateInput?.compListingSelectedSectionSettings
    ),
    deriveCompsFromVenueZoneConfig: input.deriveCompsFromVenueZoneConfig,
    includeBetterZones: input.includeBetterZones,
    rowCompOffset: input.rowCompOffset,
    venueZoneMapName: input.venueZoneMapName,
    includeExistingSelectedSections:
      (input.includeExistingSelectedSections &&
        updateInput?.compListingSelectedSectionSettings) ??
      null,
    circuitBreakerRelativeCeiling: posField(
      input.circuitBreakerRelativeCeiling?.value ?? null,
      input.circuitBreakerRelativeCeiling?.hasChanged &&
        updateInput?.circuitBreakerRelativeCeiling
    ),
    circuitBreakerRelativeFloor: posField(
      input.circuitBreakerRelativeFloor?.value ?? null,
      input.circuitBreakerRelativeFloor?.hasChanged &&
        updateInput?.circuitBreakerRelativeFloor
    ),
    circuitBreakerMaxDiscountVelocityPercent: posField(
      input.circuitBreakerMaxDiscountVelocityPercent?.value ?? null,
      input.circuitBreakerMaxDiscountVelocityPercent?.hasChanged &&
        updateInput?.circuitBreakerMaxDiscountVelocityPercent
    ),
    circuitBreakerMaxDiscountVelocityTicksInHours: posField(
      input.circuitBreakerMaxDiscountVelocityTicksInHours?.value ?? null,
      input.circuitBreakerMaxDiscountVelocityTicksInHours?.hasChanged &&
        updateInput?.circuitBreakerMaxDiscountVelocityTicksInHours
    ),
    circuitBreakerMinCompListingCount: posField(
      input.circuitBreakerMinCompListingCount?.value ?? null,
      input.circuitBreakerMinCompListingCount?.hasChanged &&
        updateInput?.circuitBreakerMinCompListingCount
    ),
    outlierMode: posField(
      input.outlierMode?.value ?? null,
      input.outlierMode?.hasChanged && updateInput?.outlierMode
    ),
    outlierStandardDeviations: posField(
      input.outlierStandardDeviations?.value ?? null,
      input.outlierStandardDeviations?.hasChanged &&
        updateInput?.outlierStandardDeviations
    ),
    outlierKthLowestLimit: posField(
      input.outlierKthLowestLimit?.value ?? null,
      input.outlierKthLowestLimit?.hasChanged &&
        updateInput?.outlierKthLowestLimit
    ),
    outlierKthLowestLimitRelativeSpacing: posField(
      input.outlierKthLowestLimitRelativeSpacing?.value ?? null,
      input.outlierKthLowestLimitRelativeSpacing?.hasChanged &&
        updateInput?.outlierKthLowestLimitRelativeSpacing
    ),
    outlierKthLowestLimitAbsoluteSpacing: posField(
      input.outlierKthLowestLimitAbsoluteSpacing?.value ?? null,
      input.outlierKthLowestLimitAbsoluteSpacing?.hasChanged &&
        updateInput?.outlierKthLowestLimitAbsoluteSpacing
    ),
  };
};
