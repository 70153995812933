import clsx from 'clsx';
import ReactDOM from 'react-dom';

import { SelectionPos } from './hooks/useSelectionPos';
import * as styles from './SpreadsheetTable.css';

export const SpreadsheetSelectionBox = ({
  classNames,
  scrollerRef,
  selectionBoxPos,
}: {
  classNames: string;
  scrollerRef: React.RefObject<HTMLElement>;
  selectionBoxPos: SelectionPos;
}) => {
  if (!scrollerRef.current) return null;

  return ReactDOM.createPortal(
    <div
      className={clsx(styles.selectionBoxBase, classNames)}
      style={{
        top: selectionBoxPos.top,
        left: selectionBoxPos.left,
        width: selectionBoxPos.width,
        height: selectionBoxPos.height,
      }}
    />,
    scrollerRef.current
  );
};
