import { FieldError } from 'react-hook-form';
import { SectionSelector } from 'src/components/Selectors/SectionRowSelector';
import { useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

export const VenueSectionInput = ({
  section,
  selectedSection,
  disabled,
  fieldError,
  onChange,
  selectionSelectorStyle,
}: {
  section?: string | null;
  selectedSection?: SectionInfo | null;
  disabled?: boolean;
  fieldError?: string | string[] | FieldError | FieldError[];
  onChange: (sectionName?: string | null, section?: SectionInfo | null) => void;
  selectionSelectorStyle?: React.CSSProperties;
}) => {
  const { venueMapInfo } = useEventMapContext();
  selectedSection =
    selectedSection ?? venueMapInfo?.sections?.find((s) => s.name === section);

  const chooseSection = useContent(ContentId.ChooseSection);

  return venueMapInfo?.sections?.length ? (
    <SectionSelector
      style={{ width: '100%', ...selectionSelectorStyle }}
      disabled={disabled}
      hasErrors={Boolean(fieldError)}
      value={selectedSection?.id?.toString() ?? '-1'}
      displayText={section || chooseSection}
      sections={venueMapInfo?.sections}
      onChange={(selectedSectionId) => {
        const id = parseInt(selectedSectionId);
        const section = venueMapInfo?.sections?.find((s) => s.id === id);
        onChange(section?.name ?? selectedSectionId, section);
      }}
    />
  ) : (
    <PosTextField
      disabled={disabled}
      rootProps={{
        state: getTextFieldState(fieldError),
      }}
      spellCheck={false}
      value={selectedSection?.name || section || ''}
      onChange={(e) => {
        const v = e.target.value;
        onChange(v);
      }}
    />
  );
};
