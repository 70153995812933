import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  PERMISSION_TO_CID,
  ROLE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  Feature,
  Permission,
  Role,
  SellerAccountClient,
  SellerPermissionInfo,
  SellerRoleInfo,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { getContent, useContentContext } from '../ContentContext';

const PermissionsRequiringFeatures: Record<string, Feature[]> = {
  [Permission.Inventory_AddSeatSaver]: [Feature.SeatSaver],
  [Permission.Inventory_ManageSeatSaver]: [Feature.SeatSaver],
  [Permission.Inventory_ManageSeatSaverCreatedBy]: [Feature.SeatSaver],
};

type ISellerRoleContext = {
  allSellerRoles?: Record<number, SellerRoleInfo> | null;
  allPermissions?: Record<Permission, SellerPermissionInfo> | null;
  allSellerRolesForSelection?: Record<string, string> | null;
  allPermissionsForSelection?: Record<Permission, Permission> | null;
  isLoading?: boolean;
  getSellerRole: (id: number) => SellerRoleInfo | null;
  refetchSellerRoleInfos: () => void;
};

export const SellerRoleContext = createContext<ISellerRoleContext>({
  allSellerRoles: {},
  allSellerRolesForSelection: {},
  allPermissions: {} as Record<Permission, SellerPermissionInfo>,
  allPermissionsForSelection: {} as Record<Permission, Permission>,
  isLoading: false,
  getSellerRole: () => null,
  refetchSellerRoleInfos: () => {},
});

export const useSellerRoleContext = () => useContext(SellerRoleContext);

/**
 * Adds a modal to the page where the provider is added
 * Provides utils to set all or update part of the modal
 * The modal is assumed to be open when it contains content
 */
export const SellerRoleContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { loginContext, activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const contentContext = useContentContext();
  const showAutoPricerRole = useUserHasFeature(Feature.AutoPricing);

  const rolesQuery = useQuery({
    queryKey: [
      'SellerAccountClient.getSelleRolesForSellerAccount',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const results = await new SellerAccountClient(
        activeAccountWebClientConfig
      ).getSelleRolesForSellerAccount();

      const resultsMap = results?.reduce<Record<number, SellerRoleInfo>>(
        (r, cur) => {
          if (cur.prefinedRole) {
            if (cur.prefinedRole == Role.AutoPricer && !showAutoPricerRole) {
              return r;
            }

            const roleContentId = ROLE_TO_CID[cur.prefinedRole];
            // In case a predefined role (one that has no SellerAccountId) was created without proper Enum and translation, just use it raw
            if (roleContentId?.length === 2) {
              const roleText = getContent(roleContentId[0], contentContext);
              cur.name = roleText ?? cur.name;

              const roleDetails = getContent(roleContentId[1], contentContext);
              cur.details = roleDetails ?? cur.details;
            }
          }

          r[cur.id] = cur;
          return r;
        },
        {}
      );

      return resultsMap;
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('SellerAccountClient.getSelleRolesForSellerAccount', error);
      },
    },
  });

  const permissionsQuery = useQuery({
    queryKey: ['SellerAccountClient.getAllSellerPermissions'],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      const results = await new SellerAccountClient(
        activeAccountWebClientConfig
      ).getAllSellerPermissions();

      const resultsMap = results?.reduce(
        (r, cur) => {
          const permContentId = PERMISSION_TO_CID[cur.permission];
          const permText = getContent(permContentId[0], contentContext);
          cur.name = permText;

          r[cur.permission] = cur;
          return r;
        },
        {} as Record<Permission, SellerPermissionInfo>
      );

      return resultsMap;
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // permissions don't change without redeploying, so it's ok to set Infinity here
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('SellerAccountClient.getAllSellerPermissions', error);
      },
    },
  });

  const getSellerRole = useCallback(
    (roleId: number) => {
      if (!roleId || !rolesQuery.data) {
        return null;
      }

      return rolesQuery.data[roleId];
    },
    [rolesQuery.data]
  );

  const allSellerRolesForSelection = useMemo(
    () =>
      Object.values(rolesQuery.data ?? {})
        .filter((r) => !r.isDeleted)
        .reduce(
          (r, cur) => {
            r[cur.id] = cur.name;
            return r;
          },
          {} as Record<string, string>
        ),
    [rolesQuery.data]
  );

  const allPermissionsFiltered = useMemo(
    () =>
      Object.values(permissionsQuery.data ?? {}).reduce(
        (r, cur) => {
          const features = PermissionsRequiringFeatures[cur.permission];
          let hasAllFeatures = true;
          if (features) {
            hasAllFeatures = features.every((f) =>
              loginContext?.user?.features.includes(f)
            );
          }

          if (hasAllFeatures) {
            r[cur.permission] = cur;
          }
          return r;
        },
        {} as Record<Permission, SellerPermissionInfo>
      ),
    [loginContext?.user?.features, permissionsQuery.data]
  );

  const allPermissionsForSelection = useMemo(
    () =>
      Object.values(allPermissionsFiltered ?? {}).reduce(
        (r, cur) => {
          r[cur.permission] = cur.permission;

          return r;
        },
        {} as Record<Permission, Permission>
      ),
    [allPermissionsFiltered]
  );

  return (
    <SellerRoleContext.Provider
      value={{
        allSellerRoles: rolesQuery.data,
        allSellerRolesForSelection: allSellerRolesForSelection,
        allPermissions: allPermissionsFiltered,
        allPermissionsForSelection: allPermissionsForSelection,
        isLoading: rolesQuery.isLoading || permissionsQuery.isLoading,
        getSellerRole: getSellerRole,
        refetchSellerRoleInfos: rolesQuery.refetch,
      }}
    >
      {children}
    </SellerRoleContext.Provider>
  );
};
