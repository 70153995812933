import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALES_STATUS_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  InhandDateRangePresetNames,
  SaleDateRangeRelativePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Marketplace,
  Permission,
  UIReportV2SaleQuery,
} from 'src/WebApiController';

import { RowFilter } from '../Filters/RowFilter';
import { SectionFilter } from '../Filters/SectionFilter';
import { CurrencyFilterMultiSelector } from '../Selectors/CurrencyFilterSelector';
import { SellerAccountEmployeeSelector } from '../Selectors/SellerAccountEmployeeSelector';
import { useCommonEventFilters } from './useCommonEventFilters';

export const saleReportMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
  'saleStatuses',
];

export function useSaleReportFilters({
  isHorizontalLayout,
  showCatalogFilters,
}: {
  isHorizontalLayout?: boolean;
  showCatalogFilters?: boolean;
}) {
  const { loginContext } = useAppContext();

  const hasAutoFulfillSetting = useUserHasFeature(Feature.AutoFulfillSetting);

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<UIReportV2SaleQuery>();

  const notesFilters = useInternalNotesFilter({
    entityType: ActionOutboxEntityType.Sale,
    isHorizontalLayout,
  });

  const seatingFilters = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Seating,
      type: 'row',
      items: [
        {
          filterId: 'sectionContains',
          labelContentId: ContentId.Section,
          filterQueryKeys: ['sectionEquals', 'sectionContains'],
          filterItem: (
            <SectionFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
        {
          filterId: 'rowContains',
          labelContentId: ContentId.Row,
          filterQueryKeys: ['rowEquals', 'rowContains'],
          filterItem: (
            <RowFilter
              query={tempQuery}
              setQuery={setTempQuery}
              isHorizontalLayout={isHorizontalLayout}
            />
          ),
        },
      ],
    }),
    [isHorizontalLayout, setTempQuery, tempQuery]
  );

  const eventFilters = useCommonEventFilters({
    showCatalogFilters,
    additionalFilters: [],
  });

  return {
    filters: [
      eventFilters,
      {
        titleContentId: ContentId.Fulfillment,
        items: [
          {
            filterId: 'saleStatuses' as FilterToolbarItemId,
            labelContentId: ContentId.Status,
            filterQueryKeys: ['saleStatuses'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumMultiSelect
                triggerProps={{
                  style: { width: '100%' },
                }}
                values={tempQuery.saleStatuses ?? []}
                placeholderText={ContentId.AllSaleStatuses}
                valueOptionsContent={SALES_STATUS_TO_CID}
                onChange={(saleStatuses) => {
                  setTempQuery({
                    ...tempQuery,
                    saleStatuses,
                  });
                }}
              />
            ),
          },
          {
            filterId: 'inHandDates' as FilterToolbarItemId,
            labelContentId: ContentId.InHand,
            filterQueryKeys: ['inHandDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={InhandDateRangePresetNames}
                value={tempQuery.inHandDates}
                defaultValue={initialQuery.inHandDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    inHandDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'fulfillerId' as FilterToolbarItemId,
            labelContentId: ContentId.FulfilledBy,
            filterQueryKeys: ['fulfillerSellerUserId'] as FilterToolbarItemId[],
            filterItem: (
              <SellerAccountEmployeeSelector
                value={tempQuery.fulfillerSellerUserId ?? undefined}
                enableEmptySelection
                style={{ width: '100%' }}
                onChange={(newId) => {
                  if (newId !== tempQuery.fulfillerSellerUserId) {
                    setTempQuery({
                      ...tempQuery,
                      fulfillerSellerUserId: newId,
                    });
                  }
                }}
              />
            ),
          },
          {
            filterId: 'isSeatSaver' as FilterToolbarItemId,
            labelContentId: ContentId.Placeholder,
            filterQueryKeys: ['isSeatSaver'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={ToYesNoEnum(tempQuery.isSeatSaver)}
                placeholderText={ContentId.All}
                enableEmptySelection
                onChange={(yesNoEnumValue) => {
                  if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)) {
                    setTempQuery({
                      ...tempQuery,
                      isSeatSaver: FromYesNoEnum(yesNoEnumValue),
                    });
                  }
                }}
                valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
              />
            ),
          },
          {
            filterId: 'fulfillmentDates' as FilterToolbarItemId,
            labelContentId: ContentId.FulfillmentDate,
            filterQueryKeys: ['fulfillmentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.fulfillmentDates}
                defaultValue={initialQuery.fulfillmentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    fulfillmentDates: value,
                  })
                }
              />
            ),
          },
          ...(hasAutoFulfillSetting
            ? [
                {
                  filterId: 'isNoFulfill' as FilterToolbarItemId,
                  labelContentId: ContentId.AutoFulfill,
                  type: 'row',
                  filterQueryKeys: ['isNoFulfill'] as FilterToolbarItemId[],
                  filterItem: (
                    <PosEnumSelect
                      style={{ width: '100%' }}
                      value={ToYesNoEnum(
                        // the ui is the inverse of isNoFulfil
                        tempQuery.isNoFulfill == null
                          ? null
                          : !tempQuery.isNoFulfill
                      )}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(yesNoEnumValue) => {
                        if (
                          yesNoEnumValue !==
                          ToYesNoEnum(
                            // the ui is the inverse of isNoFulfil
                            tempQuery.isNoFulfill == null
                              ? null
                              : !tempQuery.isNoFulfill
                          )
                        ) {
                          const v = FromYesNoEnum(yesNoEnumValue);
                          setTempQuery({
                            ...tempQuery,
                            isNoFulfill: v == null ? null : !v, // the ui is the inverse of isNoFulfil
                          });
                        }
                      }}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                    />
                  ),
                },
              ]
            : []),
          {
            filterId: 'cancellationDates' as FilterToolbarItemId,
            labelContentId: ContentId.CancellationDate,
            filterQueryKeys: ['cancellationDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.cancellationDates}
                defaultValue={initialQuery.cancellationDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    cancellationDates: value,
                  })
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.WhenAndWhere,
        type: 'row',
        items: [
          {
            filterId: 'soldOnMarketplace' as FilterToolbarItemId,
            labelContentId: ContentId.Marketplace,
            filterQueryKeys: ['soldOnMarketplace'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.soldOnMarketplace}
                placeholderText={ContentId.AllMerchants}
                enableEmptySelection
                onChange={(soldOnMarketplace) => {
                  if (soldOnMarketplace !== tempQuery.soldOnMarketplace) {
                    setTempQuery({
                      ...tempQuery,
                      soldOnMarketplace,
                    });
                  }
                }}
                valueOptionsContent={(
                  loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                    (m) => m.mkp
                  ) ?? [Marketplace.StubHub]
                )
                  .concat(Marketplace.Offline)
                  .reduce(
                    (cur, m) => {
                      cur[m] = MARKET_PLACE_TO_CID[m];
                      return cur;
                    },
                    {} as Record<Marketplace, string>
                  )}
              />
            ),
          },
          {
            filterId: 'saleDates' as FilterToolbarItemId,
            labelContentId: ContentId.SaleDate,
            filterQueryKeys: ['saleDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                presetNames={SaleDateRangeRelativePresetNames}
                value={tempQuery.saleDates}
                defaultValue={initialQuery.saleDates}
                onBlur={(value) => {
                  setTempQuery({
                    ...tempQuery,
                    saleDates: value,
                  });
                }}
                useRelativePresets
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Payment,
        requiredPermissions: [Permission.Sales_ViewPaymentState],
        type: 'row',
        items: [
          {
            filterId: 'marketplacePaymentState' as FilterToolbarItemId,
            labelContentId: ContentId.Payment,
            filterQueryKeys: [
              'marketplacePaymentState',
            ] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.marketplacePaymentState}
                placeholderText={ContentId.AllPaymentStates}
                enableEmptySelection
                onChange={(marketplacePaymentState) => {
                  if (
                    marketplacePaymentState !==
                    tempQuery.marketplacePaymentState
                  ) {
                    setTempQuery({
                      ...tempQuery,
                      marketplacePaymentState: marketplacePaymentState,
                    });
                  }
                }}
                valueOptionsContent={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'currencyCodes' as FilterToolbarItemId,
            labelContentId: ContentId.Currency,
            filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
            filterItem: (
              <CurrencyFilterMultiSelector
                triggerProps={{ style: { width: '100%' } }}
                values={tempQuery.currencyCodes ?? []}
                placeholderText={ContentId.All}
                onChange={(newCurrencyCodes) => {
                  if (!isEqual(tempQuery.currencyCodes, newCurrencyCodes)) {
                    setTempQuery({
                      ...tempQuery,
                      currencyCodes: newCurrencyCodes?.length
                        ? newCurrencyCodes
                        : null,
                    });
                  }
                }}
                enableEmptySelection
              />
            ),
          },
        ],
      },
      seatingFilters,
      ...(notesFilters ? [notesFilters] : []),
    ],
  };
}
