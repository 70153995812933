import { ReactNode } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import { Feature } from 'src/WebApiController';
import Swiper from 'swiper';

import { ListingFilterBar } from '../Listings/ListingFilterBar';
import { OnSaleEventFilterBar } from '../OnSaleEvent/OnSaleEventFilterBar';
import { PurchaseFilterBar } from '../Purchases/PurchaseFilterBar/PurchaseFilterBar';
import { SaleFilterBar } from '../Sales/SaleFilterBar';
import * as styles from './MainFilterBar.css';

export function MainFilterBar(props: {
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const { isEventPage, returnUrl, posEventId, swiperRef, ...sharedProps } =
    props;
  const { mainRoute } = useAppContext();

  const hasHideNavAndFilterBarsFeature = useUserHasFeature(
    Feature.HideNavAndFilterBars
  );

  if (hasHideNavAndFilterBarsFeature && isInFullPageEventView(mainRoute)) {
    return null;
  }

  return mainRoute === MainRoute.Inventory ||
    mainRoute === MainRoute.InventoryEvent ? (
    <ListingFilterBar
      {...sharedProps}
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
      swiperRef={swiperRef}
    />
  ) : mainRoute == MainRoute.Discovery ? (
    <OnSaleEventFilterBar />
  ) : mainRoute === MainRoute.Purchases ||
    mainRoute === MainRoute.PurchasesEvent ? (
    <PurchaseFilterBar
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
    />
  ) : (
    <SaleFilterBar
      {...sharedProps}
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
      swiperRef={swiperRef}
    />
  );
}

type MainFilterBarDivProps = { children: ReactNode };
export const MainFilterBarDiv = ({ children }: MainFilterBarDivProps) => {
  return <div className={styles.mainFilterBarDiv}>{children}</div>;
};
