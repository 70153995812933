import { ReactNode, useMemo } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Stack, Tooltip } from 'src/core/ui';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency, formatPercent } from 'src/utils/numberFormatter';
import { ListingPriceCalculation, Seating } from 'src/WebApiController';

import * as styles from './ComparableListingDisplayTooltip.css';
import { DividerLine } from './DividerLine';

export type ComparableListingDisplayTooltipProps = {
  seating?: Seating;
  price?: ReactNode;
  listingPriceCalc?: ListingPriceCalculation | null;
};

export function ComparableListingDisplayTooltip({
  seating,
  price,
  listingPriceCalc,
}: ComparableListingDisplayTooltipProps) {
  const { getUiCurrency } = useLocalizationContext();
  const { isDarkMode } = useSiteTheme();

  const uiCurrency = useMemo(
    () => getUiCurrency(listingPriceCalc?.websitePrice?.currency ?? 'USD'),
    [getUiCurrency, listingPriceCalc?.websitePrice?.currency]
  );
  const pricingAgainst = listingPriceCalc?.compListings?.find(
    (x) => x.listingId === listingPriceCalc?.underCutChosenListingId
  );
  const content = (
    <Stack gap="s" justifyContent="center" alignItems="center">
      <Content id={ContentId.YourListing} />
      <InfoOutlineIcon
        withHoverEffect
        size={vars.iconSize.s}
        fill={vars.color.textInverted}
      />
    </Stack>
  );

  const hasRelative = Boolean(
    listingPriceCalc?.udrRel || listingPriceCalc?.udrRelPrm
  );
  const hasAbsolute = Boolean(
    listingPriceCalc?.udrAbs || listingPriceCalc?.udrAbsPrm
  );

  return (
    <Tooltip
      triggerContent={content}
      contentVariant={isDarkMode ? 'light' : 'darkGray'}
      contentStyle={{ padding: 0 }}
      align="center"
    >
      <Stack direction="column" className={styles.container}>
        <Stack
          justifyContent="spaceBetween"
          gap="xxl"
          className={styles.section}
        >
          <span>
            <Content id={ContentId.PricingAgainst} />
          </span>
          {pricingAgainst?.sellerAllInPrice?.disp ?? (
            <Content id={ContentId.NA} />
          )}
        </Stack>
        {hasRelative && (
          <>
            <DividerLine darkBackground={!isDarkMode}>-</DividerLine>

            <div className={styles.section}>
              {!!listingPriceCalc?.udrRel && (
                <Stack justifyContent="spaceBetween" gap="xxl">
                  <span>
                    % <Content id={ContentId.DiscountApplied} />
                  </span>
                  {formatPercent(listingPriceCalc.udrRel, 0)}
                </Stack>
              )}
              {!!listingPriceCalc?.udrRelPrm && (
                <Stack justifyContent="spaceBetween" gap="xxl">
                  <span>
                    % <Content id={ContentId.RankPremium} />
                  </span>
                  {formatPercent(listingPriceCalc.udrRelPrm, 0)}
                </Stack>
              )}
            </div>
          </>
        )}
        {hasAbsolute && (
          <>
            <DividerLine darkBackground={!isDarkMode}>+</DividerLine>

            <div className={styles.section}>
              {!!listingPriceCalc?.udrAbs && (
                <Stack justifyContent="spaceBetween" gap="xxl">
                  <span>
                    {uiCurrency.sym} <Content id={ContentId.DiscountApplied} />
                  </span>
                  {formatCurrency(
                    listingPriceCalc.udrAbs,
                    uiCurrency.code,
                    uiCurrency.dec
                  )}
                </Stack>
              )}
              {!!listingPriceCalc?.udrAbsPrm && (
                <Stack justifyContent="spaceBetween" gap="xxl">
                  <span>
                    {uiCurrency.sym} <Content id={ContentId.RankPremium} />
                  </span>
                  {formatCurrency(
                    listingPriceCalc.udrAbsPrm,
                    uiCurrency.code,
                    uiCurrency.dec
                  )}
                </Stack>
              )}
            </div>
          </>
        )}
        <DividerLine darkBackground={!isDarkMode}>=</DividerLine>
        <Stack
          justifyContent="spaceBetween"
          gap="xxl"
          className={styles.section}
        >
          <SeatingInfo
            darkBackground={!isDarkMode}
            lightBackground={isDarkMode}
            section={seating?.section ?? ''}
            row={seating?.row ?? ''}
            showSectionPrefix
            // purposely leaving these out
            seatFr={''}
            seatTo={''}
          />
          {price}
        </Stack>
      </Stack>
    </Tooltip>
  );
}
