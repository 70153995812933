import clsx from 'clsx';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { Stack, Tooltip } from 'src/core/ui';
import { useOverflowX } from 'src/hooks/useOverflowX';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './ListingsCarouselCard.css';

interface ListingsCarouselCardSingleListingProps {
  section: string;
  row?: string | null;
}

interface ListingsCarouselCardMyListingsProps {
  listingsCount: number;
}

export type ListingsCarouselCardProps = (
  | ListingsCarouselCardSingleListingProps
  | ListingsCarouselCardMyListingsProps
) & {
  isSelected: boolean;
  onClick?: () => void;
};

export const ListingsCarouselCard = ({
  isSelected,
  onClick,
  ...rest
}: ListingsCarouselCardProps) => {
  const { section, row } = rest as ListingsCarouselCardSingleListingProps;
  const { listingsCount } = rest as ListingsCarouselCardMyListingsProps;

  return (
    <Stack
      direction="column"
      className={clsx(styles.card, {
        [styles.cardSelected]: isSelected,
      })}
      onClick={onClick}
    >
      {section ? (
        <IndividualListingCard
          section={section}
          row={row}
          isSelected={isSelected}
        />
      ) : (
        <MyListingsCard listingsCount={listingsCount} isSelected={isSelected} />
      )}
    </Stack>
  );
};

const IndividualListingCard = ({
  section,
  row,
  isSelected,
}: ListingsCarouselCardSingleListingProps & { isSelected: boolean }) => {
  const { ref, isOverflowX } = useOverflowX();

  const valueLabelClassName = clsx(styles.valueLabel, {
    [styles.valueLabelSelected]: isSelected,
  });
  return (
    <>
      <div
        className={clsx(styles.sectionContainer, {
          [styles.sectionContainerWithNoRow]: !row,
        })}
        ref={ref}
      >
        {isOverflowX ? (
          <Tooltip
            triggerContent={
              <span className={valueLabelClassName}>{section}</span>
            }
            contentVariant="theme"
          >
            <SeatingInfo
              section={section}
              row={row ?? ''}
              seatFr={''}
              seatTo={''}
            />
          </Tooltip>
        ) : (
          <span className={valueLabelClassName}>{section}</span>
        )}
      </div>

      {row && (
        <Stack gap="s">
          <div
            className={clsx(styles.attrLabel, {
              [styles.attrLabelSelected]: isSelected,
            })}
          >
            <Content id={ContentId.Row} />
          </div>
          <span className={valueLabelClassName}>{row}</span>
        </Stack>
      )}
    </>
  );
};

const MyListingsCard = ({
  listingsCount,
  isSelected,
}: ListingsCarouselCardMyListingsProps & { isSelected: boolean }) => {
  const attrLabelClassName = clsx(styles.attrLabel, {
    [styles.attrLabelSelected]: isSelected,
  });
  return (
    <>
      <div className={styles.sectionContainer}>
        <span className={attrLabelClassName}>
          {<Content id={ContentId.MyListings} />}
        </span>
      </div>

      <Stack
        className={clsx(styles.listingCountContainer, {
          [styles.listingCountContainerSelected]: isSelected,
        })}
      >
        <span className={attrLabelClassName}>{listingsCount}</span>
      </Stack>
    </>
  );
};
