import clsx from 'clsx';
import { vars } from 'src/core/themes';
import { TableSortingIcon } from 'src/svgs/TableSortingIcon';

import * as styles from './SortButton.css';
import {
  getNextSortStatus,
  SortButtonProps,
  SortStatus,
} from './SortButton.utils';

export function SortButtonV2({
  onClick,
  sortStatus,
  disabled = false,
  allowUnsorted = true,
}: SortButtonProps) {
  return (
    <div
      onClick={() => {
        !disabled && onClick?.(getNextSortStatus(sortStatus, allowUnsorted));
      }}
      className={styles.sortIconContainerV2}
    >
      <TableSortingIcon
        size={vars.iconSize.s}
        isActive={sortStatus !== SortStatus.UNSORTED}
        className={clsx({
          [styles.upsideDown]: sortStatus === SortStatus.DESC,
        })}
      />
    </div>
  );
}
