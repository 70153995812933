import { MouseEvent } from 'react';
import { useToggle } from 'react-use';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { SetSalesFlattenedViewSelectionCountLabel } from 'src/components/Events/EventListing/SalesEventListing/SetSalesFlattenedViewSelectionCountLabel';
import { SalesBulkActionsPermissions } from 'src/components/Sales/BulkActions/SalesBulkActionsPermissions';
import { SalesGlobalBulkActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/SalesGlobalBulkActions';
import { Content } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { SaleSearchResult } from 'src/WebApiController';

import { container } from './SaleSearchToolbar.css';

export const SaleSearchToolbar = ({
  disabled,
}: {
  disabled?: boolean;
  setDisabled?: (d: boolean) => void;
  activeSearchConfig?: SaleSearchResult;
}) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <div style={{ flex: 1 }}>
        <SalesBulkActionsPermissions>
          <SetSalesFlattenedViewSelectionCountLabel>
            {!disabled && (
              <MultiSelectActionBar
                style={{ padding: 0 }}
                groupId={NO_GROUP_ID}
                displayMode="count-only"
                showHideOnSelection
              >
                <SalesGlobalBulkActions />
              </MultiSelectActionBar>
            )}
          </SetSalesFlattenedViewSelectionCountLabel>
        </SalesBulkActionsPermissions>
      </div>

      <Stack gap="m" alignItems="center" justifyContent="end">
        <Button
          variant="textPlain"
          style={{ paddingRight: vars.spacing.xxs }}
          onClick={onColumnSettingButtonClickHandler}
        >
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomSalesColumn>
          onClose={toggleColumnModal}
          sectionType={SectionType.SalesFlattened}
          showTicketClassColor
        />
      ) : null}
    </Stack>
  );
};
