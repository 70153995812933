import { isEmpty } from 'lodash-es';
import { FilterToolbarItemId } from 'src/components/Filters';
import { ExpandedEventData } from 'src/contexts/CatalogDataContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import {
  filterAndflattenListingGroup,
  flattenListingGroup,
} from 'src/modals/GroupListings/components/groupingUtils';
import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  InhandDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CatalogClient,
  DateTimeRange,
  GroupType,
  IListingGroupItem,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingGroup,
  ListingMetrics,
  ListingMetricsInput,
  ListingQuery,
  PosClientConfig,
  SectionalListingsForEventsInput,
} from 'src/WebApiController';

export const getCatalogData = async (
  client: CatalogClient,
  filterQuery: ListingQuery,
  includeCounts: boolean
) => {
  return await client.getCatalogForListing(filterQuery, includeCounts);
};

// Don't load sectional data for listing in invisible groups
const filterInvisibleGroups = (l: IListingGroupItem): boolean => {
  if (l == null) {
    return false;
  }
  if (l.isLtGrp) {
    return (l as ListingGroup).groupType !== GroupType.Invisible;
  }
  return true;
};

const deepOverrideListing = (
  listing: Listing,
  overrides: Record<number, Listing>,
  dataToOverride: ListingDetailDataField[]
) => {
  const groupItems = (listing as ListingGroup).groupItems;
  if (!isEmpty(groupItems)) {
    Object.assign(listing, {
      groupItems: groupItems.map((item) =>
        deepOverrideListing(item as Listing, overrides, dataToOverride)
      ),
    });
    return listing;
  }
  const override = overrides[listing.id];
  if (override) {
    if (
      dataToOverride.includes(ListingDetailDataField.Purchase) &&
      override.dataIsLoaded?.[ListingDetailDataField.Purchase]
    ) {
      Object.assign(listing, {
        isCurUserPo: override.isCurUserPo,
        poBys: override.poBys,
        poCreatedBys: override.poCreatedBys,
        poDate: override.poDate,
        poId: override.poId,
        poOrderId: override.poOrderId,
        poVendAccEmail: override.poVendAccEmail,
        dataIsLoaded: {
          ...listing.dataIsLoaded,
          [ListingDetailDataField.Purchase]: true,
        },
      } as Partial<Listing>);
    }

    if (
      dataToOverride.includes(ListingDetailDataField.Tags) &&
      override.dataIsLoaded?.[ListingDetailDataField.Tags]
    ) {
      Object.assign(listing, {
        tags: override.tags,
        dataIsLoaded: {
          ...listing.dataIsLoaded,
          [ListingDetailDataField.Tags]: true,
        },
      } as Partial<Listing>);
    }
  }
  return listing;
};

const mergeCatalogListings = (
  listings: Listing[],
  overrides: Listing[],
  dataToOverride: ListingDetailDataField[]
) => {
  if (overrides.length === 0 || dataToOverride.length === 0) {
    return listings;
  }

  const overrideById = overrides
    .flatMap(flattenListingGroup)
    .map((listing) => listing as Listing)
    .reduce(
      (map, listing) => {
        map[listing.id] = listing;
        return map;
      },
      {} as Record<number, Listing>
    );

  return listings.map((listing) =>
    deepOverrideListing(listing, overrideById, dataToOverride)
  );
};

export const getCatalogSectionalDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: ListingQuery,
  dataToInclude: ListingDetailDataField[],
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  },
  currentData?: ExpandedEventData
) => {
  const emptyResults = viagogoVirtualIds.reduce((results, id) => {
    results[id] = {
      sales: null,
      listings: null,
      ticketGroups: null,
      failedToRetrieveData: false,
    };
    return results;
  }, {} as ExpandedEventData);
  const result = await tryInvokeApi(async () => {
    // Only process listing group when basic data is included
    const processListingGroup = dataToInclude.includes(
      ListingDetailDataField.Basic
    );
    // No current data, we are loading for the first time
    if (currentData == null) {
      const input: SectionalListingsForEventsInput = {
        listingQuery: {
          ...filterQuery,
          // Is is to support the legacy event ID urls
          oldPosEventIds: viagogoVirtualIds
            .filter((id) => id.startsWith('old:'))
            .map((id) => id.substring(4)),
          eventOrMappingIds: viagogoVirtualIds.filter(
            (id) => !id.startsWith('old:')
          ),
        },
        curListings: null,
      };
      // Listing groups will be loaded in the first call
      // TODO: we should add guard to make sure basic data is loaded the first time
      const allListings = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingsSectionalDataForEvents(
        input,
        dataToInclude,
        processListingGroup
      );
      return Promise.resolve(
        Object.entries(allListings).reduce((results, [id, listings]) => {
          results[id] = {
            sales: null,
            listings: listings,
            ticketGroups: null,
            failedToRetrieveData: false,
          };
          return results;
        }, emptyResults)
      );
    } else {
      const curListings = Object.entries(currentData ?? {})
        .flatMap(([_, data]) => data.listings ?? [])
        .flatMap(filterAndflattenListingGroup(filterInvisibleGroups))
        .map((listing) => listing as Listing)
        .map(
          (listing) =>
            ({
              id: listing.id,
              actions: listing.actions,
              seating: listing.seating,
              currency: listing.currency,
              viagVirtualId: listing.viagVirtualId,
              vldPurQty: listing.vldPurQty,
            }) as Listing
        );
      const input: SectionalListingsForEventsInput = {
        listingQuery: {
          ...filterQuery,
          // Is is to support the legacy event ID urls
          oldPosEventIds: viagogoVirtualIds
            .filter((id) => id.startsWith('old:'))
            .map((id) => id.substring(4)),
          eventOrMappingIds: viagogoVirtualIds.filter(
            (id) => !id.startsWith('old:')
          ),
        },
        curListings,
      };
      // Skip processing listing group in the follow up sectional calls
      const overrideListings = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingsSectionalDataForEvents(
        input,
        dataToInclude,
        processListingGroup
      );
      return Promise.resolve(
        Object.entries(currentData).reduce((results, [id, data]) => {
          const override = overrideListings?.[id] ?? [];
          results[id] = {
            ...data,
            listings: mergeCatalogListings(
              data.listings ?? [],
              override,
              dataToInclude
            ),
          };
          return results;
        }, emptyResults)
      );
    }
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

export const getCatalogDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: ListingQuery,
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  },
  useReadonlyDB = false,
  doNotProcessListingGroups = false
) => {
  const result = await tryInvokeApi(async () => {
    const allListings = await new ListingClient(
      activeAccountWebClientConfig
    ).getListingsForEvents(
      {
        ...filterQuery,
        // Is is to support the legacy event ID urls
        oldPosEventIds: viagogoVirtualIds
          .filter((id) => id.startsWith('old:'))
          .map((id) => id.substring(4)),
        eventOrMappingIds: viagogoVirtualIds.filter(
          (id) => !id.startsWith('old:')
        ),
      },
      useReadonlyDB,
      doNotProcessListingGroups
    );

    const emptyResults = viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        sales: null,
        listings: null,
        ticketGroups: null,
        failedToRetrieveData: false,
      };
      return results;
    }, {} as ExpandedEventData);

    return Promise.resolve(
      Object.entries(allListings).reduce((results, [id, listings]) => {
        results[id] = {
          sales: null,
          listings: listings,
          ticketGroups: null,
          failedToRetrieveData: false,
        };
        return results;
      }, emptyResults)
    );
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

export const getCatalogMetrics = (
  client: CatalogClient,
  filterQuery: ListingQuery,
  rowVersion?: number | null,
  hasMetricsV2Feature?: boolean
) => {
  return hasMetricsV2Feature
    ? client.getCatalogMetricsForListings(filterQuery, rowVersion ?? undefined)
    : client.getCatalogListingMetrics(filterQuery);
};

export const getCatalogSummaryMetrics = (
  client: CatalogClient,
  metrics: ListingMetrics[],
  mergeCatalogMetrics: (metrics: ListingMetrics[]) => ListingMetrics[]
) => {
  const resultMetrics = mergeCatalogMetrics(metrics);
  if (resultMetrics?.length === 1) {
    return Promise.resolve(resultMetrics[0]);
  }
  return client.getSummaryMetricsForListings(
    resultMetrics.map(
      (m) =>
        ({
          totalTicketQuantity: m.tktQty,
          totalSoldQuantity: m.soldQty,
          totalUnsoldQuantity: m.unsoldQty,
          totalListedQuantity: m.listQty,
          totalUnlistedQuantity: m.unlistQty,
          currencyCode: m.currency,
          /** Total cost of all tickets */
          totalCost: {
            amount: m.ttlCst.amt,
            currencyCode: m.ttlCst.currency ?? m.currency,
            decimalDigits: m.ttlCst.dec,
            display: m.ttlCst.disp,
          },
          /** Total cost of sold tickets */
          totalSoldCost: {
            amount: m.soldCst.amt,
            currencyCode: m.soldCst.currency ?? m.currency ?? m.currency,
            decimalDigits: m.soldCst.dec,
            display: m.soldCst.disp,
          },
          /** Total cost of unsold tickets */
          totalUnsoldCost: {
            amount: m.unsoldCst.amt,
            currencyCode: m.unsoldCst.currency ?? m.currency,
            decimalDigits: m.unsoldCst.dec,
            display: m.unsoldCst.disp,
          },
          /** Total list price of all tickets */
          totalListPrice: {
            amount: m.ttlListPrc.amt,
            currencyCode: m.ttlListPrc.currency ?? m.currency,
            decimalDigits: m.ttlListPrc.dec,
            display: m.ttlListPrc.disp,
          },
          /** Total value of all listed tickets */
          // This is un-used and only exists for backward compatibility
          totalListedValue: {
            amount: 0,
            currencyCode: m.currency,
            decimalDigits: 2,
            display: '',
          },
          /** Total list price of unsold tickets */
          totalUnsoldListPrice: {
            amount: m.ttlUnsoldListPrc.amt,
            currencyCode: m.ttlUnsoldListPrc.currency ?? m.currency,
            decimalDigits: m.ttlUnsoldListPrc.dec,
            display: m.ttlUnsoldListPrc.disp,
          },
          /** Total net proceeds of sold tickets */
          totalNetProceeds: {
            amount: m.netProcs.amt,
            currencyCode: m.netProcs.currency ?? m.currency,
            decimalDigits: m.netProcs.dec,
            display: m.netProcs.disp,
          },
          /** Total gross profit of sold tickets */
          totalGrossProfit: {
            amount: m.pandL.amt,
            currencyCode: m.pandL.currency ?? m.currency,
            decimalDigits: m.pandL.dec,
            display: m.pandL.disp,
          },
        }) as ListingMetricsInput
    )
  );
};

export const getEventDetailedMetrics = (
  client: CatalogClient,
  filterQuery: ListingQuery,
  rowVersion?: number | null,
  hasMetricsV2Feature?: boolean
) =>
  hasMetricsV2Feature
    ? client.getCatalogDetailedMetricsForListings(filterQuery, rowVersion)
    : client.getEventDetailedListingMetrics(filterQuery);

export const listingQueryValueTransformFromUrl = (
  key: string,
  value: string
) => {
  if (!value) {
    return value;
  }

  const listingsKey = key as keyof ListingQuery;
  if (listingsKey === 'inHandDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      InhandDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};

export const listingQueryValueTransformToUrl = (
  key: FilterToolbarItemId,
  value: unknown
) => {
  if (!value) {
    return value;
  }

  const listingsKey = key as keyof ListingQuery;

  if (listingsKey === 'inHandDates') {
    const preset = getPresetFromUiDateTimeRange(value as DateTimeRange);
    if (preset && InhandDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};
