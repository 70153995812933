import differenceWith from 'lodash-es/differenceWith';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { MAX_COMMISSIONERS } from 'src/dialogs/SaleCommissionOverrideDialog/SaleCommissionOverrideDialog.constants';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { FieldError, FieldWrapper } from 'src/modals/common';
import { PurchaseCommissionInput } from 'src/modals/common/Purchase/CommissionSection';
import { DeleteIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { Commission } from 'src/WebApiController';

import * as styles from './SetCommissions.css';

enum FormErrorKey {
  TotalCommissionsValueExceeded = 'totalCommissionsValueExceeded',
  UserAlreadySelected = 'userAlreadySelected',
  MinValue = 'minValue',
}

const validateMaxCommissions = (fields: Commission[]): string | undefined => {
  const total = fields.reduce((acc, curr) => {
    return acc + curr.commissionPercentage;
  }, 0);
  return total <= 1 ? undefined : FormErrorKey.TotalCommissionsValueExceeded;
};

interface SaleCommissionOverrideContentProps {
  onSave?: (commissions: Commission[]) => void;
  actionsRef?: MutableRefObject<SetCommissionsRefActions | null>;
  footerActions?: ReactNode;
  disableForm?: boolean;
}

export interface SetCommissionsRefActions {
  onSave: () => void;
  addCommissionAction: () => void;
}

export type SetCommissionForm = Pick<PurchaseCommissionInput, 'commissions'>;

export const SetCommissionsForm = ({
  onSave,
  actionsRef,
  footerActions,
  disableForm,
}: SaleCommissionOverrideContentProps) => {
  const isMobile = useMatchMedia('mobile');
  const methods = useFormContext<SetCommissionForm>();

  const { control, formState, trigger, watch, setValue } = methods;
  const formValues = watch();
  const formErrors = formState.errors;
  const formRootErrors = formErrors?.['commissions']?.['value']?.['root'];
  const isFormValid = formState.isValid && !formRootErrors;
  const requiredText = useContent(ContentId.Required);
  const minCommissionValueText = useContent(ContentId.MinCommissionValue);

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: 'commissions.value',
    rules: {
      validate: {
        [FormErrorKey.TotalCommissionsValueExceeded]: validateMaxCommissions,
      },
    },
  });

  const maxCommissionersReached = fields.length >= MAX_COMMISSIONERS;

  const { allActiveUserInfos } = useSellerAccountContext();
  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const add = useCallback(() => {
    if (maxCommissionersReached) {
      return;
    }

    const firstAvailableUserId = differenceWith(
      activeUserIds,
      formValues.commissions?.value ?? [],
      (activeUserId, commission) => activeUserId === commission.buyerUserId
    )[0];

    if (!firstAvailableUserId) {
      return;
    }

    append({ buyerUserId: firstAvailableUserId, commissionPercentage: 0 });
  }, [
    activeUserIds,
    append,
    formValues.commissions?.value,
    maxCommissionersReached,
  ]);

  const onSubmit = useCallback(
    (value: SetCommissionForm) => {
      if (value.commissions?.value?.length === 0 || !isFormValid) {
        return;
      }
      onSave?.(value.commissions?.value!);
    },
    [isFormValid, onSave]
  );

  useImperativeHandle(
    actionsRef,
    () => {
      return {
        onSave: methods.handleSubmit(onSubmit),
        addCommissionAction: add,
      };
    },
    [add, methods, onSubmit]
  );

  return (
    <form
      className={styles.form}
      onSubmit={methods.handleSubmit(onSubmit)}
      noValidate={true}
    >
      {fields.map((field, index) => (
        <Stack key={field.id} gap="l">
          <FieldWrapper
            width={isMobile ? '55%' : '68%'}
            style={{ flexShrink: 0 }}
          >
            <Controller
              control={control}
              rules={{
                required: true,
                validate: {
                  [FormErrorKey.UserAlreadySelected]: (
                    selectedUserId: string
                  ) => {
                    const otherFieldsWithSameUserId = (
                      formValues.commissions?.value ?? []
                    ).filter((f) => {
                      return f.buyerUserId === selectedUserId;
                    });
                    return otherFieldsWithSameUserId.length > 1
                      ? FormErrorKey.UserAlreadySelected
                      : undefined;
                  },
                },
              }}
              name={`commissions.value.${index}.buyerUserId`}
              render={({ field, fieldState }) => {
                return (
                  <div>
                    <SellerAccountEmployeeSelector
                      placeholderText={ContentId.Commissioner}
                      enableEmptySelection={false}
                      style={{ width: '100%' }}
                      hasErrors={
                        fieldState.isTouched && Boolean(fieldState.error)
                      }
                      {...field}
                      onChange={(userId) => {
                        setValue(field.name, userId);
                        setValue('commissions.hasChanged', true);
                      }}
                      disabled={disableForm}
                    />
                    {fieldState.error && (
                      <>
                        {fieldState.error.type === 'required' && (
                          <FieldError>
                            <Content id={ContentId.Required} />
                          </FieldError>
                        )}
                        {fieldState.error.type ===
                          FormErrorKey.UserAlreadySelected && (
                          <FieldError>
                            <Content id={ContentId.UserAlreadySelected} />
                          </FieldError>
                        )}
                      </>
                    )}
                  </div>
                );
              }}
            />
          </FieldWrapper>
          <FieldWrapper width="auto" minWidth="85px">
            <Controller
              control={control}
              rules={{
                required: true,
                max: 100,
                validate: {
                  [FormErrorKey.MinValue]: (v) =>
                    v && v > 0 ? undefined : minCommissionValueText,
                },
              }}
              name={`commissions.value.${index}.commissionPercentage`}
              render={({ field, fieldState }) => {
                const { onChange, value, ...restField } = field;
                return (
                  <PosFormField
                    errors={
                      fieldState.error?.type === 'required' ||
                      fieldState.error?.type === 'min'
                        ? requiredText
                        : fieldState?.error?.type === FormErrorKey.MinValue
                        ? minCommissionValueText
                        : undefined
                    }
                  >
                    <PosTextField
                      type="number"
                      postfixDisplay="%"
                      inputMode="decimal"
                      onChange={(e) => {
                        const changedValue = e.target.value || '0';
                        const commissionValue = parseFloat(changedValue);

                        if (isNaN(commissionValue)) {
                          return;
                        }

                        const cleanValueToSet = parseFloat(
                          (commissionValue / 100).toFixed(4) // 4 decimals, 0.0001
                        );

                        onChange(cleanValueToSet);
                        setValue('commissions.hasChanged', true, {
                          shouldDirty: true,
                        });
                      }}
                      rootProps={{
                        state:
                          fieldState.isTouched && fieldState.isDirty
                            ? getTextFieldState(fieldState.error)
                            : undefined,
                      }}
                      min={0}
                      max={100}
                      {...restField}
                      disabled={disableForm}
                      ref={(e) => {
                        if (e && !fieldState.isDirty) {
                          /**
                           * The value entered by the user is being transformed to a value
                           * between 0 & 1 by diving by 100. If the user enters a comma or dot,
                           * depending on the locale, it can produce null values have incorrect validations.
                           * Best approach is to use an uncontrolled input and let the browser
                           * handle the decimal parts, so we just set the initial value to the input
                           * if the user hasn't touched it.
                           * */
                          e.value = roundToPrecision(value * 100, 2).toString();
                        }
                        restField.ref(e);
                      }}
                    />
                  </PosFormField>
                );
              }}
            />
          </FieldWrapper>
          <FieldWrapper width="auto">
            <div className={styles.removeIcon}>
              <DeleteIcon
                fill={IconsFill.currentColor}
                withHoverEffect
                disabled={disableForm}
                onClick={() => {
                  remove(index);
                  setTimeout(trigger, 1);
                  setValue('commissions.hasChanged', true);
                }}
              />
            </div>
          </FieldWrapper>
        </Stack>
      ))}
      {formRootErrors &&
        formRootErrors.type === FormErrorKey.TotalCommissionsValueExceeded && (
          <FieldError>
            <Content id={ContentId.CantAllocateMore100Commission} />
          </FieldError>
        )}

      {footerActions ? (
        footerActions
      ) : (
        <Button
          variant="link"
          onClick={add}
          className={styles.addCommissionOverrideButton}
          disabled={maxCommissionersReached || disableForm}
        >
          <PlusIcon size={vars.iconSize.m} fill={IconsFill.textBrand} />
          <Content id={ContentId.AddCommission} />
        </Button>
      )}
    </form>
  );
};
