import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { FilterToolbarItemId } from 'src/components/Filters';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  SALES_STATUS_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  SaleDateRangeRelativePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  EventTimeFrameFilter,
  Marketplace,
  Permission,
  UIReportCommissionQuery,
} from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import { SellerAccountEmployeeSelector } from '../Selectors/SellerAccountEmployeeSelector';

export const commissionMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
];

export function useCommissionFilters() {
  const { loginContext } = useAppContext();

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<UIReportCommissionQuery>();

  const commissionFilters = useMemo(() => {
    return {
      titleContentId: ContentId.Commission,
      type: 'row',
      items: [
        {
          filterId: 'purchaseCommissionUserId' as FilterToolbarItemId,
          labelContentId: ContentId.Commissioner,
          filterQueryKeys: [
            'purchaseCommissionUserId',
          ] as FilterToolbarItemId[],
          filterItem: (
            <SellerAccountEmployeeSelector
              value={tempQuery.purchaseCommissionUserId ?? undefined}
              enableEmptySelection
              style={{ width: '100%' }}
              onChange={(purchaseCommissionUserId) => {
                if (
                  purchaseCommissionUserId !==
                  tempQuery.purchaseCommissionUserId
                ) {
                  setTempQuery({
                    ...tempQuery,
                    purchaseCommissionUserId,
                  });
                }
              }}
            />
          ),
        },
        {
          filterId: 'isCommissionEligible' as const,
          labelContentId: ContentId.CommissionEligible,
          filterQueryKeys: ['isCommissionEligible'] as FilterToolbarItemId[],
          filterItem: (
            <PosEnumSelect
              style={{ width: '100%' }}
              value={ToYesNoEnum(tempQuery.isCommissionEligible)}
              placeholderText={ContentId.All}
              enableEmptySelection
              onChange={(yesNoEnumValue) => {
                if (
                  yesNoEnumValue !== ToYesNoEnum(tempQuery.isCommissionEligible)
                ) {
                  setTempQuery({
                    ...tempQuery,
                    isCommissionEligible: FromYesNoEnum(yesNoEnumValue),
                  });
                }
              }}
              valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
            />
          ),
        },
        {
          filterId: 'commissionEligibilityDates' as FilterToolbarItemId,
          labelContentId: ContentId.CommissionEligibilityDate,
          filterQueryKeys: [
            'commissionEligibilityDates',
          ] as FilterToolbarItemId[],
          filterItem: (
            <DateRangeSelector
              useRelativePresets
              presetNames={StandardDateRangePresetNames}
              value={tempQuery.commissionEligibilityDates}
              defaultValue={initialQuery.commissionEligibilityDates}
              onBlur={(value) =>
                setTempQuery({
                  ...tempQuery,
                  commissionEligibilityDates: value,
                })
              }
            />
          ),
        },
      ],
    };
  }, [initialQuery.commissionEligibilityDates, setTempQuery, tempQuery]);

  const eventFilters = useMemo(() => {
    return {
      titleContentId: ContentId.Events,
      items: [
        {
          filterId: 'eventDates' as FilterToolbarItemId,
          labelContentId: ContentId.EventDate,
          filterQueryKeys: [
            'eventDates',
            'eventTimeFrameFilter',
          ] as FilterToolbarItemId[],
          filterItem: (
            <EventDateSelector
              useRelativePresets
              eventTimeFrame={tempQuery.eventTimeFrameFilter}
              value={tempQuery.eventDates}
              onChange={(eventTimeFrameFilter, dateRange) => {
                if (
                  eventTimeFrameFilter !== tempQuery.eventTimeFrameFilter ||
                  !isEqual(dateRange, tempQuery.eventDates)
                ) {
                  let isSortDescending = tempQuery.isSortDescending;
                  if (eventTimeFrameFilter !== EventTimeFrameFilter.Future) {
                    // When sorting by past or by all - we do not want to sort by date asc (as that would put the oldest event first)
                    // Changing this to desc
                    isSortDescending = true;
                  } else {
                    isSortDescending = initialQuery.isSortDescending;
                  }

                  setTempQuery({
                    ...tempQuery,
                    eventTimeFrameFilter: eventTimeFrameFilter ?? null,
                    eventDates: dateRange ?? null,
                    isSortDescending,
                  });
                }
              }}
            />
          ),
        },
        {
          filterId: 'lastSaleDates' as FilterToolbarItemId,
          labelContentId: ContentId.LastSaleDates,
          filterQueryKeys: ['lastSaleDates'] as FilterToolbarItemId[],
          filterItem: (
            <DateRangeSelector
              useRelativePresets
              presetNames={StandardDateRangePresetNames}
              value={tempQuery.saleDates}
              defaultValue={initialQuery.saleDates}
              onBlur={(value) =>
                setTempQuery({
                  ...tempQuery,
                  saleDates: value,
                })
              }
              disabledDay={(d) =>
                getDisabledDatesForTimeFrameFilter(
                  d,
                  tempQuery.eventTimeFrameFilter
                )
              }
            />
          ),
        },
      ],
    };
  }, [
    initialQuery.isSortDescending,
    initialQuery.saleDates,
    setTempQuery,
    tempQuery,
  ]);

  return {
    filters: [
      eventFilters,
      {
        titleContentId: ContentId.Fulfillment,
        items: [
          {
            filterId: 'saleStatuses' as FilterToolbarItemId,
            labelContentId: ContentId.Status,
            filterQueryKeys: ['saleStatuses'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumMultiSelect
                triggerProps={{
                  style: { width: '100%' },
                }}
                values={tempQuery.saleStatuses ?? []}
                placeholderText={ContentId.AllSaleStatuses}
                valueOptionsContent={SALES_STATUS_TO_CID}
                onChange={(saleStatuses) => {
                  setTempQuery({
                    ...tempQuery,
                    saleStatuses,
                  });
                }}
              />
            ),
          },
          {
            filterId: 'fulfillmentDates' as FilterToolbarItemId,
            labelContentId: ContentId.FulfillmentDate,
            filterQueryKeys: ['fulfillmentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.fulfillmentDates}
                defaultValue={initialQuery.fulfillmentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    fulfillmentDates: value,
                  })
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.WhenAndWhere,
        type: 'row',
        items: [
          {
            filterId: 'soldOnMarketplace' as FilterToolbarItemId,
            labelContentId: ContentId.Marketplace,
            filterQueryKeys: ['soldOnMarketplace'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.soldOnMarketplace}
                placeholderText={ContentId.AllMerchants}
                enableEmptySelection
                onChange={(soldOnMarketplace) => {
                  if (soldOnMarketplace !== tempQuery.soldOnMarketplace) {
                    setTempQuery({
                      ...tempQuery,
                      soldOnMarketplace,
                    });
                  }
                }}
                valueOptionsContent={(
                  loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                    (m) => m.mkp
                  ) ?? [Marketplace.StubHub]
                )
                  .concat(Marketplace.Offline)
                  .reduce(
                    (cur, m) => {
                      cur[m] = MARKET_PLACE_TO_CID[m];
                      return cur;
                    },
                    {} as Record<Marketplace, string>
                  )}
              />
            ),
          },
          {
            filterId: 'saleDates' as FilterToolbarItemId,
            labelContentId: ContentId.SaleDate,
            filterQueryKeys: ['saleDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                presetNames={SaleDateRangeRelativePresetNames}
                value={tempQuery.saleDates}
                defaultValue={initialQuery.saleDates}
                onBlur={(value) => {
                  setTempQuery({
                    ...tempQuery,
                    saleDates: value,
                  });
                }}
                useRelativePresets
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Payment,
        requiredPermissions: [Permission.Sales_ViewPaymentState],
        type: 'row',
        items: [
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
        ],
      },
      commissionFilters,
    ],
  };
}
