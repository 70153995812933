import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { ContentId } from 'src/utils/constants/contentId';
import { getEventDateDisplayStrings } from 'src/utils/dateTimeUtils';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { TicketGroupInput } from 'src/WebApiController';

import * as styles from './PurchaseTicketGroupPage.css';

export const PurchaseTicketGroupHeader = ({
  ticketGroups,
  isSingleInputModeForMultiEvent,
  currentGroupIndex,
  currentGroupTicketsCount,
}: {
  ticketGroups: TicketGroupInput[];
  isSingleInputModeForMultiEvent?: boolean;
  currentGroupIndex: number;
  currentGroupTicketsCount: number;
}) => {
  const { event, performer, venue } = ticketGroups[0];

  const [eventName] = getFormattedEventName({
    event,
    performer,
    venue,
    slimMode: false,
  });

  const { name, descr } = venue || {};

  const { formattedEventDate, formattedEventSubDate } =
    getEventDateDisplayStrings(event?.dates.start || '', {
      dateFormat: 'MMM d, y',
    });

  return (
    <div className={styles.PurchaseTicketGroupHeader}>
      {isSingleInputModeForMultiEvent ? (
        <div className={styles.PurchaseTicketGroupEventName}>
          <Content id={ContentId.Group} /> {`${currentGroupIndex + 1}`}
        </div>
      ) : (
        <div className={styles.PurchaseTicketGroupEventName}>{eventName}</div>
      )}
      <div className={styles.PurchaseTicketGroupVenueDateContainer}>
        {formattedEventDate && (
          <div className={styles.PurchaseTicketGroupHeaderInnerContainer}>
            <>
              <span
                className={styles.PurchaseTicketGroupHeaderInnerText}
                style={{ color: vars.color.textStrong }}
              >
                {formattedEventDate}&nbsp;
              </span>
              <span
                className={styles.PurchaseTicketGroupHeaderInnerText}
                style={{ color: vars.color.textPrimary }}
              >
                {formattedEventSubDate}
              </span>
            </>
          </div>
        )}
        <div className={styles.PurchaseTicketGroupHeaderInnerContainer}>
          {venue && (
            <>
              <span
                className={styles.PurchaseTicketGroupHeaderInnerText}
                style={{ color: '#239394' }}
              >
                {name}
              </span>
              <span
                className={styles.PurchaseTicketGroupHeaderInnerText}
                style={{ color: '#677383' }}
              >
                &nbsp;{'-'}&nbsp;
                {descr}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
