import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  ReportMetricsResponse,
  ReportValueTypesReponse,
} from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';

import { listingReportV2TableColumnDef } from './configs/ListingReportTableColumnsConfigV2';

type ListingReportTableColumnDefContextType = {
  displayedColumnsConfig: ColumnDef<ReportMetricsResponse | null>[] | undefined;
};

const ListingReportTableColumnDefContext =
  createContext<ListingReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const ListingReportTableColumnDefContextProviderV2 = ({
  report,
  reportValueTypes,
  children,
}: PropsWithChildren<{
  report: ReportConfigV2;
  reportValueTypes: ReportValueTypesReponse | undefined;
}>) => {
  const displayedColumnsConfig = useMemo(() => {
    const columns = [
      ...(report.request.rowGroupings?.map((c) => c.columnName) ?? []),
      ...(report.request.aggregations?.map((c) => c.columnName) ?? []),
    ];

    return columns.map((columnId) =>
      listingReportV2TableColumnDef(
        columnId as ListingReportTableColumnId,
        reportValueTypes?.[columnId.toUpperCase()]
      )
    );
  }, [
    report.request.aggregations,
    report.request.rowGroupings,
    reportValueTypes,
  ]);

  return (
    <ListingReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: displayedColumnsConfig,
      }}
    >
      {children}
    </ListingReportTableColumnDefContext.Provider>
  );
};

export const useListingReportTableColumnDefV2 = () =>
  useContext(ListingReportTableColumnDefContext);
