import { CSSProperties, MouseEvent, ReactNode, useEffect } from 'react';
import { useToggle } from 'react-use';
import { useEventFlattedViewSelectedLegend } from 'src/components/Events/EventListing/useEventFlattedViewSelectedLegend';
import { ActiveSortTableColumnProvider } from 'src/contexts/ActiveSortTableColumnContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useEventDiscoveryContext } from 'src/contexts/EventDiscoveryContext/EventDiscoveryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomOnSaleEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { UserSetting } from 'src/WebApiController';

import { OnSaleEventTable } from './OnSaleEventTable';
import * as styles from './OnSaleEventView.css';
import { OnSaleEventsTableColumnId } from './OnSaleEventView.types';

type OnSaleEventViewProps = {};

export const OnSaleEventView: React.FC<OnSaleEventViewProps> = () => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <ColumnResizingContextProvider
      userSettingId={UserSetting.OnSaleEventColumnsWidths}
    >
      <ActiveSortTableColumnProvider>
        {isColumnModalOpen ? (
          <ColumnSettingsModal<CustomOnSaleEventsColumn>
            onClose={toggleColumnModal}
            sectionType={SectionType.OnSaleEvents}
          />
        ) : null}

        <OnSaleEventTableWithInnerGrouping
          columnSettingButton={
            <Button
              variant="textPlain"
              onClick={onColumnSettingButtonClickHandler}
            >
              <LayoutIcon size={vars.iconSize.m} />
              <Content id={ContentId.Columns} />
            </Button>
          }
        />
      </ActiveSortTableColumnProvider>
    </ColumnResizingContextProvider>
  );
};

const posTableHeadStyle: CSSProperties = {
  position: 'sticky',
  top: '0',
};

interface EventsTableWithInnerGroupingProps {
  columnSettingButton?: ReactNode;
}

const OnSaleEventTableWithInnerGrouping = ({
  columnSettingButton,
}: EventsTableWithInnerGroupingProps) => {
  const { isLoading, onSaleEvents } = useEventDiscoveryContext();
  return (
    <Stack direction="column" height="full" width="full">
      {/* <SetOnSaleEventViewSelectionCountLabel>
        <MultiSelectActionBar>
              <MultiSelectActionsContainer>
              </MultiSelectActionsContainer>
        </MultiSelectActionBar>
      </SetOnSaleEventViewSelectionCountLabel> */}
      <Stack
        justifyContent="end"
        alignItems="center"
        className={styles.header}
        gap="m"
      >
        {columnSettingButton}
      </Stack>
      <OnSaleEventTable
        events={onSaleEvents}
        isLoading={isLoading}
        failedToRetrieveData={false}
        useVirtuoso
        useDataGrouping
        withOuterPadding
        tableHeadStyle={posTableHeadStyle}
        initState={{
          sorting: [
            {
              id: OnSaleEventsTableColumnId.VisitsLastDay,
              desc: true,
            },
          ],
          grouping: [OnSaleEventsTableColumnId.VisitsLastDay],
        }}
      />
    </Stack>
  );
};

export const SetOnSaleEventViewSelectionCountLabel = ({
  children,
}: React.PropsWithChildren) => {
  const eventText = useContent(ContentId.Event);
  const eventsText = useContent(ContentId.Events);
  const { setSelectedCountLabel, setUsingCustomSelectedCountLabel } =
    useMultiSelectionContext();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedLegend = useEventFlattedViewSelectedLegend({
    singleElementLabel: eventText,
    multipleElementsLabel: eventsText,
  });

  useEffect(() => {
    setSelectedCountLabel(selectedLegend);
  }, [selectedLegend, setSelectedCountLabel]);

  return <>{children}</>;
};
