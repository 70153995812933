import * as Sentry from '@sentry/react';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { ExpandedEventData } from 'src/contexts/CatalogDataContext';
import { AutoPoPartialPurchaseOrderFields } from 'src/contexts/EmailPurchaseOrderContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import { ModalProps } from 'src/modals/Modal';
import { TicketGroupEditContext } from 'src/modals/PurchaseWizard/PurchaseWizard.types';
import { PurchaseWizardModalConfig } from 'src/modals/PurchaseWizard/PurchaseWizardModalConfig';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import {
  EventIdQueryParam,
  PurchaseDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { removeFilters } from 'src/utils/eventQueryUtils';
import {
  CatalogClient,
  CatalogResults,
  DateTimeRange,
  DeliveryType,
  PosClientConfig,
  PosFieldOfNullableDateTime,
  PosFieldOfPurchaseVendor,
  PosFieldOfPurchaseVendorAccount,
  PosFieldOfString,
  PurchaseClient,
  PurchaseOrderDeal,
  PurchaseOrderDealInput,
  PurchaseOrderDetails,
  PurchaseOrderDetailsInput as ServerPurchaseOrderDetailsInput,
  PurchaseOrderQuery,
  PurchaseOrderState,
  PurchasingIdInUseResultType,
  Ticket,
  TicketGroup,
  TicketGroupEditReason,
  TicketGroupInput,
  TicketGroupIntention,
  UserSetting,
} from 'src/WebApiController';

import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  PurchaseDateRangePresetNames,
  StandardDateRangePresetNames,
} from './dateTimeUtils';
import {
  addDeepLinkUrlPart,
  getDeepLinkIdFromUrl,
  getFullUrl,
  getPathFromMainRoute,
} from './deepLinkUtils';
import { getEventPerformerVenue } from './eventWithDataUtils';
import { newBigIntId } from './idUtils';
import { posChangedField, posField } from './posFieldUtils';
import { fitsSeatIncrementType, getNextTicketBy } from './ticketUtils';
import { tryInvokeApi } from './tryExecuteUtils';

export type PurchaseOrderDetailsInput = ServerPurchaseOrderDetailsInput & {
  ticketGroupIntentions: Record<string, TicketGroupIntention>;
  ticketGroupFromPartialPurchaseOrder?: TicketGroupInput | null;
  eventSearchTextFromPartialPurchaseOrder?: string | null;
  updateSalesOfListings?: boolean | null;
  updatePredeliveryETicketArtifacts?: boolean | null;
};

export type PurchaseTicketsInput = Pick<
  PurchaseOrderDetailsInput,
  | 'ticketGroups'
  | 'ticketGroupFromPartialPurchaseOrder'
  | 'eventSearchTextFromPartialPurchaseOrder'
  | 'updateSalesOfListings'
  | 'updatePredeliveryETicketArtifacts'
> & {
  daysBeforeEvent?: number;
};

export const toPurchaseOrderDetailsInput = (
  purchase?: PurchaseOrderDetails | null,
  accountUserId?: string | null,
  accountCurrencyCode?: string | null,
  catalog?: CatalogResults
) => {
  if (!purchase) {
    return null;
  }

  const isNewPurchase = purchase.id <= 0;
  return {
    id: purchase.id > 0 ? purchase.id : newBigIntId(),
    purchaseDate: posField(
      purchase.poDate ?? new Date().toISOString(),
      isNewPurchase
    ),
    buyerUserId: posField(purchase.buyerId ?? accountUserId, isNewPurchase),
    currencyCode: posField(
      purchase.currency ?? accountCurrencyCode,
      isNewPurchase
    ),
    vendorOrderId: posField(purchase?.vendOrdId, isNewPurchase),
    commissions: posField(purchase?.commissions ?? [], isNewPurchase),
    vendor: posField(purchase?.vendor, isNewPurchase),
    vendorAccount: posField(purchase?.vendAcc, isNewPurchase),
    secondaryVendorAccount: posField(purchase?.secVendAcc, isNewPurchase),
    secondaryVendor: posField(purchase?.secVendor, isNewPurchase),
    paymentStatus: posField(purchase?.pmtStat, isNewPurchase),
    additionalCosts: posField(purchase?.additionalCosts, isNewPurchase),
    payments: posField(purchase?.payments, isNewPurchase),
    transferId: posField(purchase?.transferId, isNewPurchase),
    tags: posField(purchase?.tags, isNewPurchase),
    privateNotes: posField(purchase?.privNotes, isNewPurchase),
    qcState: posField(purchase?.qcState, isNewPurchase),
    ticketGroupCount: purchase?.tktGrpCnt,
    ticketCount: purchase?.ticketCnt,
    isAutoPo: purchase?.isAutoPo,
    ticketGroups: purchase?.ticketGroups.map((tg) =>
      toTicketGroupInput(tg, catalog, isNewPurchase)
    ),
    ticketGroupIntentions: {},
    deal: purchase?.deal
      ? posField(toDealInput(purchase?.deal, isNewPurchase), isNewPurchase)
      : null,
    isCommissionsLocked: posField(purchase?.isCommissionsLocked, isNewPurchase),
  } as PurchaseOrderDetailsInput;
};

export const toTicketGroupInput = (
  tg: TicketGroup,
  catalog?: CatalogResults,
  isNewTicketGroup?: boolean
) => {
  const viagogoVirtualId = tg.viagVirtualId;

  const { event, performer, venue } = getEventPerformerVenue(
    viagogoVirtualId,
    catalog
  );

  return {
    ticketGroupId: tg.id,
    purchaseOrderId: tg.poId,
    viagogoEventId: tg.viagEvId ?? event?.event?.viagId,
    eventMappingId: tg.viagEvMapId ?? event?.event?.mappingId,
    viagogoVirtualId: viagogoVirtualId,
    posEventId: tg.posEvId ?? event?.event?.posIds?.[0],
    event: event?.event,
    venue,
    performer,
    canGetDocArts: tg.canGetDocArts,
    unitCost: posField(tg.unitCst?.amt, isNewTicketGroup),
    inHandDate: posField(tg.inHandAt, isNewTicketGroup),
    expectedValue: posField(tg.expectedVal?.amt, isNewTicketGroup),
    isFaceValueCost: posField(tg.isFaceVal, isNewTicketGroup),
    faceValueCost: posField(tg.faceVal?.amt, isNewTicketGroup),
    deliveryType: posField(tg.delivType, isNewTicketGroup),
    totalCost: posField(tg.totalCst?.amt, isNewTicketGroup),
    taxPaid: posField(tg.taxPaid?.amt, isNewTicketGroup),
    deliveryCost: posField(tg.delivCst?.amt, isNewTicketGroup),
    currencyCode: posField(tg.currency, isNewTicketGroup),
    section: posField(tg.seating.section, isNewTicketGroup),
    sectionId: posField(tg.seating.sectionId, isNewTicketGroup),
    rowId: posField(tg.seating.rowId, isNewTicketGroup),
    row: posField(tg.seating.row, isNewTicketGroup),
    tickets: posField(tg.tickets, isNewTicketGroup),
    numberOfTickets: posField(tg.ticketCnt, isNewTicketGroup),
    /** Note - TicketGroup doesn't have InternalNotes as a field, but they are passed in from API to be set onto Listing */
    internalNotes: posField(tg.privNotes, isNewTicketGroup),
    listingNotes: posField(tg.seatTraits, isNewTicketGroup),
    webPrice: tg.webPrice,
    autoBroadcastCreatedListing: false,
  } as TicketGroupInput;
};

export const toDealInput = (
  deal: PurchaseOrderDeal | null,
  isNewDeal: boolean
) => {
  return {
    dealId: deal?.dealId,
    dealType: deal?.dealType,
    dealDetails: {
      dealConfigId: deal?.dealDetails?.dealConfigId,
      dealFallbackConfig: deal?.dealDetails?.dealFallbackConfig,
      dealConfigs: posField(deal?.dealDetails?.dealConfigs, isNewDeal),
      dealStartDate: deal?.dealDetails?.dealStartDate,
      dealEndDate: deal?.dealDetails?.dealEndDate,
      configSplitType: posField(deal?.dealDetails?.configSplitType, isNewDeal),
      guaranteedAmountPaid: deal?.dealDetails?.guaranteedAmountPaid,
    },
  } as PurchaseOrderDealInput;
};

export const formatConversionRate = (
  rate: number | null,
  fromCurrency?: string | null,
  toCurrency?: string | null
) => {
  if (rate == null) {
    return null;
  }

  return `1 ${fromCurrency == null ? '' : ' '}${fromCurrency} → ${rate.toFixed(
    4
  )}${toCurrency == null ? '' : ' '}${toCurrency}`;
};

export const toPartialPurchaseOrderToPurchaseOrderDetailsInput = (
  partialPurchaseOrder?: AutoPoPartialPurchaseOrderFields | null,
  accountUserId?: string | null,
  accountCurrencyCode?: string | null
): PurchaseOrderDetailsInput | null => {
  if (!partialPurchaseOrder) {
    return null;
  }

  let ticketGroup = null;
  let eventSearchText = null;
  if (
    partialPurchaseOrder.eventName != null ||
    partialPurchaseOrder.venueName != null ||
    partialPurchaseOrder.section != null ||
    partialPurchaseOrder.row != null ||
    partialPurchaseOrder.seatFrom != null ||
    partialPurchaseOrder.seatTo != null ||
    partialPurchaseOrder.quantity != null ||
    partialPurchaseOrder.faceValue != null
  ) {
    const tgId = newBigIntId();
    const tickets = [] as Ticket[];
    let seatIncrementType = null;
    // Assemble tickets
    if (partialPurchaseOrder.quantity) {
      const seatValues = [] as string[];
      if (
        partialPurchaseOrder.seatFrom != null ||
        partialPurchaseOrder.seatTo != null
      ) {
        for (let incr = 1; incr <= 2; incr++) {
          if (
            fitsSeatIncrementType(
              partialPurchaseOrder.seatFrom,
              partialPurchaseOrder.seatTo,
              partialPurchaseOrder.quantity,
              incr
            )
          ) {
            seatIncrementType = incr;
            break;
          }
        }
      }

      let i;
      if (seatIncrementType) {
        let seatValue = partialPurchaseOrder.seatFrom;
        seatValues.push(seatValue);
        for (i = 1; i < partialPurchaseOrder.quantity; i++) {
          seatValue = getNextTicketBy(seatValue, seatIncrementType).toString();
          seatValues.push(seatValue);
        }
      } else {
        for (i = 0; i < partialPurchaseOrder.quantity; i++) {
          if (i === 0) seatValues.push(partialPurchaseOrder.seatFrom);
          else if (i === partialPurchaseOrder.quantity - 1)
            seatValues.push(partialPurchaseOrder.seatTo);
          else seatValues.push('');
        }
      }

      for (i = 0; i < partialPurchaseOrder.quantity; i++) {
        tickets.push({
          id: -i,
          tgId: tgId,
          ord: i,
          lstOrd: i,
          row: partialPurchaseOrder.row,
          seat: seatValues[i],
        } as Ticket);
      }
    }

    eventSearchText =
      partialPurchaseOrder.eventName ?? partialPurchaseOrder.venueName;
    // Strip chars other than blank, alphanumeric, quote
    // Sometimes there's difference of special chars like ':' vs '-' making it hard to search
    if (eventSearchText != null) {
      eventSearchText = eventSearchText.replace(/[^\p{L}\p{N}' ]/giu, '');
    }

    ticketGroup = {
      ticketGroupId: tgId,
      currencyCode: posChangedField(accountCurrencyCode),
      section: posChangedField(partialPurchaseOrder.section ?? ''),
      row: posChangedField(partialPurchaseOrder.row),
      numberOfTickets: posChangedField(partialPurchaseOrder.quantity ?? 0),
      tickets: posChangedField(tickets),
      deliveryType: posChangedField(DeliveryType.InApp),
      seatIncrementType: posChangedField(seatIncrementType),
      allocateSeats: posChangedField(
        partialPurchaseOrder.row != null ||
          partialPurchaseOrder.seatFrom != null ||
          partialPurchaseOrder.seatTo != null
      ),
      faceValueCost: posChangedField(partialPurchaseOrder.faceValue),
      isFaceValueCost: posChangedField(partialPurchaseOrder.faceValue != null),
    } as unknown as TicketGroupInput;
  }

  return {
    id: newBigIntId(),
    vendorOrderId: posChangedField(partialPurchaseOrder.orderId),
    purchaseDate: posChangedField(
      partialPurchaseOrder.purchaseOrderDate ?? new Date().toISOString()
    ),
    buyerUserId: posChangedField(accountUserId),
    vendorAccount: posChangedField(partialPurchaseOrder.vendorAccount),
    vendor: posChangedField(partialPurchaseOrder.vendor),
    currencyCode: posChangedField(accountCurrencyCode),
    ticketGroupIntentions: {},
    ticketGroupFromPartialPurchaseOrder: ticketGroup,
    eventSearchTextFromPartialPurchaseOrder: eventSearchText,
    deal: null,
  } as PurchaseOrderDetailsInput;
};

export const getPurchaseOrderDeepLinkUrl = (deepLinkId?: string | number) => {
  return getFullUrl(
    MainRoute.Purchases,
    PurchaseDeeplinkQueryParam,
    deepLinkId
  );
};

export const getPurchaseOrderRelativeUrl = (
  deepLinkId?: string | number,
  viagVirtualId?: string
) => {
  let navigateUrl = `${getPathFromMainRoute(
    MainRoute.Purchases
  )}?${PurchaseDeeplinkQueryParam}=${deepLinkId}`;

  if (deepLinkId === 0 && viagVirtualId) {
    // We only honor eventQueryId if the ID is 0 (which is for adding a new Purchase)
    navigateUrl += `&${EventIdQueryParam}=${viagVirtualId}`;
  }

  return navigateUrl;
};

export const getPurchaseOrderDetailsModalConfigWithDeepLink = (
  deepLinkId?: string | number
): ModalProps => {
  return {
    ...PurchaseWizardModalConfig,
    deepLinkValue: deepLinkId,
  };
};

export const validateEventSelection = (
  clearErrors: UseFormClearErrors<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  setError: UseFormSetError<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  ticketGroups: TicketGroupInput[] | null,
  requiredMsg: string
) => {
  clearErrors('ticketGroups');
  if (!ticketGroups?.length) {
    setError('ticketGroups', { message: requiredMsg });
    return false;
  }

  return true;
};

export const validateVendorInfo = (
  purchaseId: number,
  clearErrors: UseFormClearErrors<PurchaseOrderDetailsInput>,
  purchaseDate: PosFieldOfNullableDateTime | null,
  requiredMsg: string,
  orderIdUsedErrorMsg: string,
  setError: UseFormSetError<PurchaseOrderDetailsInput>,
  vendor: PosFieldOfPurchaseVendor | null,
  vendorAccount: PosFieldOfPurchaseVendorAccount | null,
  vendorOrderId: PosFieldOfString | null,
  purchasingIdsInUseResult: PurchasingIdInUseResultType | null | undefined
) => {
  let hasErrors = false;

  clearErrors('vendor');
  clearErrors('vendorAccount');
  clearErrors('secondaryVendorAccount');
  clearErrors('vendorOrderId');
  clearErrors('purchaseDate');

  if (
    !vendor?.value ||
    !vendorAccount?.value ||
    !vendorOrderId?.value ||
    !purchaseDate?.value
  ) {
    !purchaseDate?.value &&
      setError('purchaseDate', { message: requiredMsg }, { shouldFocus: true });

    !vendorOrderId?.value &&
      setError(
        'vendorOrderId', // for this we use register so we put error on the value
        { message: requiredMsg },
        { shouldFocus: true }
      );

    !vendorAccount?.value &&
      setError(
        'vendorAccount',
        { message: requiredMsg },
        { shouldFocus: true }
      );

    !vendor?.value &&
      setError('vendor', { message: requiredMsg }, { shouldFocus: true });

    hasErrors = true;
  }

  // Only validate OrderId when we're having new purchase
  if (
    purchaseId < 0 &&
    purchasingIdsInUseResult ===
      PurchasingIdInUseResultType.SameOrderIdAndVendorId
  ) {
    setError(
      'vendorOrderId',
      { message: orderIdUsedErrorMsg },
      { shouldFocus: true }
    );
    hasErrors = true;
  }

  return !hasErrors;
};

export const validateTicketGroup = (
  clearErrors: UseFormClearErrors<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  setError: UseFormSetError<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  curTicketGroup: TicketGroupInput,
  index: number,
  requiredMsg: string,
  duplicateSeatMsg: string,
  inhandDateMustBeforeEventDateMsg: string,
  autoBroadcastRequireFaceValueMsg: string,
  isMultiTgTableFeature?: boolean
) => {
  // Validate the current ticket-group first
  const errors = [];

  const { event } = curTicketGroup;

  const isNewTicketGroup = curTicketGroup.ticketGroupId < 0;

  clearErrors(`ticketGroups.${index}`);
  clearErrors(`ticketGroups.${index}.deliveryType`);
  clearErrors(`ticketGroups.${index}.unitCost`);
  clearErrors(`ticketGroups.${index}.currencyCode`);
  clearErrors(`ticketGroups.${index}.numberOfTickets`);
  clearErrors(`ticketGroups.${index}.inHandDate`);
  clearErrors(`ticketGroups.${index}.faceValueCost`);

  if (
    (curTicketGroup.deliveryType?.hasChanged || isNewTicketGroup) &&
    !curTicketGroup.deliveryType?.value
  ) {
    errors.push('Delivery Type: ' + requiredMsg); // TODO - how to get content-id here
    setError(
      `ticketGroups.${index}.deliveryType`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  }

  if (
    (curTicketGroup.unitCost?.hasChanged || isNewTicketGroup) &&
    !Number.isFinite(curTicketGroup.unitCost?.value)
  ) {
    errors.push('Unit Cost: ' + requiredMsg);
    setError(
      `ticketGroups.${index}.unitCost`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  }

  if (
    (curTicketGroup.currencyCode?.hasChanged || isNewTicketGroup) &&
    !curTicketGroup.currencyCode?.value
  ) {
    errors.push('Currency Code: ' + requiredMsg);
    setError(
      `ticketGroups.${index}.currencyCode`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  }

  if (
    (curTicketGroup.numberOfTickets?.hasChanged || isNewTicketGroup) &&
    !curTicketGroup.numberOfTickets?.value
  ) {
    errors.push('Quantity: ' + requiredMsg);
    setError(
      `ticketGroups.${index}.numberOfTickets`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  } else {
    const uniqueSeats: Record<string, number> = {};
    let ticketsWithSeats = 0;
    curTicketGroup.tickets?.value?.forEach((t, ti) => {
      if (t.seat) {
        ticketsWithSeats++;
        clearErrors(`ticketGroups.${index}.tickets.value.${ti}.seat`);

        if (uniqueSeats[t.seat] >= 0) {
          errors.push(`${t.seat}: ${duplicateSeatMsg}`);
          setError(
            `ticketGroups.${index}.tickets.value.${ti}.seat`,
            {
              message: duplicateSeatMsg,
            },
            { shouldFocus: true }
          );
        } else {
          uniqueSeats[t.seat] = ti;
        }
      }
    });

    if (curTicketGroup.allocateSeats || isMultiTgTableFeature) {
      if (
        (curTicketGroup.row?.hasChanged || isNewTicketGroup) &&
        !curTicketGroup.row?.value
      ) {
        errors.push(`Row: ${requiredMsg}`);
        setError(
          `ticketGroups.${index}.row`,
          {
            message: requiredMsg,
          },
          { shouldFocus: true }
        );
      }

      if (
        (curTicketGroup.tickets?.hasChanged || isNewTicketGroup) &&
        ticketsWithSeats > 0 && // If any ticket has seat, then total number needs to match ticket-count
        ticketsWithSeats !== curTicketGroup.numberOfTickets?.value
      ) {
        curTicketGroup.tickets?.value?.forEach((t, ti) => {
          if (!t.seat) {
            errors.push(`Seats: ${requiredMsg}`);
            setError(
              `ticketGroups.${index}.tickets.value.${ti}.seat`,
              {
                message: requiredMsg,
              },
              { shouldFocus: true }
            );
          }
        });
      }
    }
  }

  if (
    (curTicketGroup.section?.hasChanged || isNewTicketGroup) &&
    !curTicketGroup.section?.value
  ) {
    errors.push(`Section: ${requiredMsg}`);
    setError(
      `ticketGroups.${index}.section`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  }

  if (
    (curTicketGroup.inHandDate?.hasChanged || isNewTicketGroup) &&
    !curTicketGroup.inHandDate?.value
  ) {
    errors.push(`In-Hand Date: ${requiredMsg}`);
    setError(
      `ticketGroups.${index}.inHandDate`,
      {
        message: requiredMsg,
      },
      { shouldFocus: true }
    );
  } else if (
    curTicketGroup.inHandDate?.hasChanged &&
    new Date(curTicketGroup.inHandDate!.value!) >= new Date(event!.dates.start!)
  ) {
    errors.push(`In-Hand Date: ${inhandDateMustBeforeEventDateMsg}`);
    setError(
      `ticketGroups.${index}.inHandDate`,
      {
        message: inhandDateMustBeforeEventDateMsg,
      },
      { shouldFocus: true }
    );
  }

  if (curTicketGroup.faceValueCost?.hasChanged) {
    if (!Number.isFinite(curTicketGroup.faceValueCost.value)) {
      errors.push(`Face Value: ${autoBroadcastRequireFaceValueMsg}`);
      setError(
        `ticketGroups.${index}.faceValueCost`,
        {
          message: autoBroadcastRequireFaceValueMsg,
        },
        { shouldFocus: true }
      );
    }
  }
  return errors;
};

export const validateTicketGroups = (
  isAtSingleTicketGroupInputLevel: boolean,
  clearErrors: UseFormClearErrors<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  setError: UseFormSetError<
    Partial<{ ticketGroups: TicketGroupInput[] | null }>
  >,
  ticketGroups: TicketGroupInput[],
  requiredMsg: string,
  duplicateSeatMsg: string,
  inhandDateMustBeforeEventDateMsg: string,
  autoBroadcastRequireFaceValueMsg: string,
  isMultiTgTableFeature?: boolean
) => {
  let isValid = true;
  const seatingByEvents = {} as Record<string, Record<string, number>>;
  ticketGroups.forEach((tg, i) => {
    if (tg.tickets?.hasChanged && tg.tickets.value) {
      const eventKey = tg.event?.viagVirtualId ?? tg.viagogoVirtualId;
      const curEvent = seatingByEvents[eventKey];
      if (!curEvent) {
        seatingByEvents[eventKey] = tg.tickets.value
          .filter((t) => t.seat)
          .reduce(
            (r, t) => {
              r[`${tg.section?.value}|${tg.row?.value}|${t.seat}`] = t.id;
              return r;
            },
            {} as Record<string, number>
          );
      } else {
        tg.tickets.value.forEach((t, ti) => {
          if (t.seat) {
            const newSeat = `${tg.section?.value}|${tg.row?.value}|${t.seat}`;
            if (curEvent[newSeat] >= 0) {
              isValid = false;
              if (isMultiTgTableFeature) {
                setError(
                  `ticketGroups.${i}.tickets`,
                  {
                    message: `${duplicateSeatMsg}: ${tg.section?.value} Row ${tg.row?.value} Seat ${t.seat}`,
                  },
                  { shouldFocus: true }
                );
              } else {
                setError(
                  `ticketGroups.${i}.tickets.value.${ti}.seat`,
                  {
                    message: `${duplicateSeatMsg}: ${tg.section?.value} Row ${tg.row?.value} Seat ${t.seat}`,
                  },
                  { shouldFocus: true }
                );
              }
            }
            // Add seat to array
            curEvent[newSeat] = t.id;
          }
        });
      }
    }
  });

  if (isMultiTgTableFeature && isValid) {
    let isValidTicketGroups = true;
    ticketGroups.forEach((tg, i) => {
      const errors = validateTicketGroup(
        clearErrors,
        setError,
        tg,
        i,
        requiredMsg,
        duplicateSeatMsg,
        inhandDateMustBeforeEventDateMsg,
        autoBroadcastRequireFaceValueMsg,
        isMultiTgTableFeature
      );
      if (errors.length) {
        if (!isAtSingleTicketGroupInputLevel) {
          setError(
            `ticketGroups.${i}`,
            {
              message: errors.join(' | '),
            },
            { shouldFocus: true }
          );
        }
        isValidTicketGroups = false;
      }
    });

    return isValidTicketGroups;
  }

  return (
    isValid &&
    ticketGroups.every((tg, i) => {
      const errors = validateTicketGroup(
        clearErrors,
        setError,
        tg,
        i,
        requiredMsg,
        duplicateSeatMsg,
        inhandDateMustBeforeEventDateMsg,
        autoBroadcastRequireFaceValueMsg
      );
      if (errors.length) {
        if (!isAtSingleTicketGroupInputLevel) {
          // Only report at the ticketGroups (collection) if not at single-ticket-group level
          setError(
            `ticketGroups.${i}`,
            {
              message: errors.join(' | '),
            },
            { shouldFocus: true }
          );
        }
        return false; // invalid
      }

      return true; // valid
    })
  );
};

const CombinedTicketGroupSeparator = '|';

export const isCancelledState = (poState?: PurchaseOrderState | null) =>
  poState === PurchaseOrderState.Cancelled ||
  poState === PurchaseOrderState.Void;

/**
 * Removes all TicketGroupContext data except
 * for the ticketGroupId and set it in the URL
 * @param queryParamName
 */
export const cleanTicketGroupContextFromDeepLink = (queryParamName: string) => {
  const deepLinkValue = getDeepLinkIdFromUrl(
    queryParamName,
    window.location.href
  );
  const ticketGroup = splitOutTicketGroupId(deepLinkValue);
  const { id: purchaseId, ticketGroupId } = ticketGroup;
  const ticketGroupEditContext: Partial<TicketGroupEditContext> = {
    ticketGroupId,
  };

  if (!purchaseId) {
    return;
  }

  addDeepLinkUrlPart(
    queryParamName,
    combineWithTicketGroupId(purchaseId, ticketGroupEditContext)
  );
};

type SplitOutTicketGroupIdReturnType = {
  id: number | string;
  ticketGroupId: number | undefined;
  ticketGroupEditReason: TicketGroupEditReason | undefined;
  ticketGroupEditContext: Partial<TicketGroupEditContext>;
};

export const splitOutTicketGroupId = (
  purchaseIdDeepLinkValue: string
): SplitOutTicketGroupIdReturnType => {
  let id: number | string = purchaseIdDeepLinkValue;
  let ticketGroupId: number | undefined = undefined;
  let ticketGroupEditReason: TicketGroupEditReason | undefined = undefined;
  let ticketGroupEditContext: Partial<TicketGroupEditContext> = {};

  const ticketGroupSeparatorIndex = purchaseIdDeepLinkValue.indexOf(
    CombinedTicketGroupSeparator
  );
  if (ticketGroupSeparatorIndex > 0) {
    id = purchaseIdDeepLinkValue.substring(0, ticketGroupSeparatorIndex);
    try {
      const dataStr = purchaseIdDeepLinkValue.substring(
        String(id).length + CombinedTicketGroupSeparator.length
      );
      ticketGroupEditContext = JSON.parse(
        dataStr
      ) as Partial<TicketGroupEditContext>;
      ticketGroupId = ticketGroupEditContext.ticketGroupId ?? undefined;
      ticketGroupEditReason = ticketGroupEditContext?.editIntention;
    } catch (e) {
      console.error(e);
      try {
        Sentry.captureException(e);
      } catch (err) {
        console.warn('Sentry log request failed', err);
      }
    }
  } else {
    const indexOfTgBracket = id.indexOf('[');
    const indexOfTgBracketEnd = id.indexOf(']');

    if (indexOfTgBracket > 0 && indexOfTgBracketEnd > 0) {
      id = purchaseIdDeepLinkValue.substring(0, indexOfTgBracket);
      const ticketGroupIdStr = purchaseIdDeepLinkValue.substring(
        indexOfTgBracket + 1,
        indexOfTgBracketEnd
      );

      const n = parseInt(ticketGroupIdStr);
      if (!Number.isNaN(n)) {
        ticketGroupId = n;
      }
    }
  }

  return { id, ticketGroupId, ticketGroupEditReason, ticketGroupEditContext };
};

export const combineWithTicketGroupIdSimple = (
  purchaseId: number | string,
  ticketGroupId?: number | null
) => {
  return purchaseId + (ticketGroupId ? '[' + ticketGroupId + ']' : '');
};

export const combineWithTicketGroupId = (
  purchaseId: number | string,
  ticketGroupEditContext?: Partial<TicketGroupEditContext>
) => {
  return `${purchaseId}${CombinedTicketGroupSeparator}${JSON.stringify(
    ticketGroupEditContext || {}
  )}`;
};

export const filterPurchaseOrderTicketGroupsByEventId = (
  tgs: TicketGroup[],
  allEventIds:
    | {
        viagVirtualId: string;
        performerId: number | null;
        venueId: number;
      }[]
    | undefined
) => {
  tgs = tgs.filter((tg) => {
    return (
      allEventIds?.find((e) => e.viagVirtualId === tg.viagVirtualId) != null
    );
  });

  return tgs;
};

export const createPurchaseEventPageUrl = (
  eventId: string,
  queryParamsToRemove?: string[] // queryParams to remove from final URL
): string => {
  let searchParams = '';
  const url = new URL(window.location.href);
  url.searchParams.set(EventIdQueryParam, eventId);
  (queryParamsToRemove ?? []).forEach((param) =>
    url.searchParams.delete(param)
  );
  searchParams = `?${removeFilters(url.searchParams.toString(), [
    'searchText',
  ])}`;
  return `/purchases/event${searchParams}`;
};

export const getCatalogData = async (
  client: CatalogClient,
  filterQuery: PurchaseOrderQuery,
  includeCounts: boolean
) => {
  return await client.getCatalogForPurchaseOrders(filterQuery, includeCounts);
};

export const PURCHASE_USER_SETTINGS = [
  UserSetting.PurchaseColumnEditability,
  UserSetting.PurchaseColumnNumberPrecision,
  UserSetting.PurchaseColumnOrder,
  UserSetting.PurchaseColumnsEnabled,
  UserSetting.PurchaseCustomColumns,
  UserSetting.QuickFiltersStatePurchases,
  UserSetting.PurchaseColumnWidths,
  UserSetting.PurchasePageViewMode,
  UserSetting.PurchaseEventColumnOrder,
  UserSetting.PurchaseEventColumnsEnabled,
  UserSetting.PurchaseEventColumnEditability,
  UserSetting.PurchaseEventColumnNumberPrecision,
  UserSetting.PurchaseEventColumnWidths,
  UserSetting.PurchasesDefaultTab,
];

export const purchaseQueryValueTransformToUrl = (
  key: string,
  value: DateTimeRange
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof PurchaseOrderQuery;

  if (queryKey === 'purchaseDates') {
    const preset = getPresetFromUiDateTimeRange(value);
    if (preset && PurchaseDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  } else if (queryKey === 'paymentDates') {
    const preset = getPresetFromUiDateTimeRange(value);
    if (preset && StandardDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};

export const purchaseQueryValueTransformFromUrl = (
  key: string,
  value: string
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof PurchaseOrderQuery;
  if (queryKey === 'purchaseDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      PurchaseDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  } else if (queryKey === 'paymentDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      StandardDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};

export const getCatalogDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: PurchaseOrderQuery,
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  }
) => {
  const result = await tryInvokeApi(async () => {
    const allTicketGroups = await new PurchaseClient(
      activeAccountWebClientConfig
    ).getTicketGroupsForEvents({
      ...filterQuery,
      // Is is to support the legacy event ID urls
      oldPosEventIds: viagogoVirtualIds
        .filter((id) => id.startsWith('old:'))
        .map((id) => id.substring(4)),
      eventOrMappingIds: viagogoVirtualIds.filter(
        (id) => !id.startsWith('old:')
      ),
    });

    const emptyResults = viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        sales: null,
        listings: null,
        ticketGroups: null,
        failedToRetrieveData: false,
      };
      return results;
    }, {} as ExpandedEventData);

    return Promise.resolve(
      Object.entries(allTicketGroups).reduce((results, [id, ticketGroups]) => {
        results[id] = {
          sales: null,
          listings: null,
          ticketGroups: ticketGroups,
          failedToRetrieveData: false,
        };
        return results;
      }, emptyResults)
    );
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

export const getCatalogMetrics = (
  client: CatalogClient,
  filterQuery: PurchaseOrderQuery,
  rowVersion?: number | null,
  hasMetricsV2Feature?: boolean
) => {
  return hasMetricsV2Feature
    ? client.getCatalogMetricsForTicketGroups(filterQuery, rowVersion)
    : client.getCatalogTicketGroupMetrics(filterQuery);
};
