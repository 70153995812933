import { useQuery } from '@tanstack/react-query';
import { ComponentProps, useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BackButton } from 'src/components/Buttons/BackButton';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  checkBarcodeErrors,
  checkDuplicateBarcodes,
} from 'src/components/UploadArtifacts/UploadBarcodes';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import {
  Content,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import {
  DATA_REFRESH_RATE_IN_MILLIS_FAST,
  TICKETMASTER_APP_ID,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { TransferTicketsForm } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  Marketplace,
  SaleClient,
  SaleDetails,
  SaleMetrics,
  TransferTicketType,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { ImageProofBody } from './views/ImageProof';
import { ProofTypeSelectionBody } from './views/ProofTypeSelection';
import { TransferIdProofBody } from './views/TransferIdProof';
import { TransferTicketmasterBarcodesBody } from './views/TransferTicketmasterBarcodes';
import { TransferTicketsRecipientBody } from './views/TransferTicketsRecipientBody';
import { UrlProofBody } from './views/UrlProof';

type TransferTicketProps = {
  cancelTo?: ModalProps;
  eTicketUrlOnly?: boolean;
};

export const TransferTickets = (props: TransferTicketProps) => {
  const { loadingState } = useEventItemLoadingDisplay<SaleDetails>(
    FormatContentId.LoadingSaleId,
    FormatContentId.SearchingForSaleId,
    FormatContentId.CouldNotFindSaleId
  );

  return loadingState || <TransferTicketsContent {...props} />;
};

export const TransferTicketsContent = (props: TransferTicketProps) => {
  const { loginContext } = useAppContext();
  const { posEntity: sale } = useActivePosEntityContext<SaleDetails>();

  const hasTicketmasterEnabled =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) =>
        m.mkp === Marketplace.Ticketmaster &&
        m.isEnabled &&
        (m.hasApiToken || m.hasApiRefreshToken) &&
        !m.tokenExpired
    );
  const indexOfTM = sale?.transSrc?.findIndex(
    (ti) => ti.appId === TICKETMASTER_APP_ID
  );

  const supportTicketmasterBarcodeTransfer =
    hasTicketmasterEnabled != null && indexOfTM != null && indexOfTM >= 0;

  const methods = useForm<TransferTicketsForm>({
    defaultValues: {
      transferType: props.eTicketUrlOnly
        ? TransferTicketType.URL
        : supportTicketmasterBarcodeTransfer &&
          sale?.tickets?.every((t) => t.barcode)
        ? TransferTicketType.ApiTransferTicketmaster // if this support TM transfer and has barcodes, default to selecting TM transfer
        : TransferTicketType.ImageProof,
      acceptanceUrls: sale?.transferUrls,
      acceptanceUrlInputs: sale?.transferUrls?.map((url, i) => ({
        id: i,
        url: url,
      })),
      uploadProofDocuments: sale?.transferProofDocs,
      transferProofId: sale?.transferProofId,
      barcodes: sale?.tickets?.map((t) => ({
        ticketId: t.id,
        barcode: t.barcode ?? '',
      })),
    },
  });

  return (
    <FormProvider {...methods}>
      <TransferTicketsModal
        {...props}
        {...methods}
        supportTicketmasterBarcodeTransfer={supportTicketmasterBarcodeTransfer}
      />
    </FormProvider>
  );
};

enum TransferTicketBody {
  Recipient,
  ProofType,
  ImageProof,
  UrlProof,
  IdProof,
  TicketmasterBarcodesViaOneTicket,
  TicketmasterBarcodesViaTradeDesk,
}

const TransferTicketsModal = ({
  cancelTo,
  formState,
  handleSubmit,
  watch,
  setError,
  getValues,
  eTicketUrlOnly,
  supportTicketmasterBarcodeTransfer,
}: TransferTicketProps & {
  supportTicketmasterBarcodeTransfer: boolean;
} & Omit<
    ComponentProps<typeof FormProvider<TransferTicketsForm, unknown>>,
    'children'
  >) => {
  const [currentBody, setCurrentBody] = useState<TransferTicketBody>(
    eTicketUrlOnly ? TransferTicketBody.UrlProof : TransferTicketBody.Recipient
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, closeModal } = useContext(ModalContext);

  const { trackError, showErrorDialog, genericError } =
    useErrorBoundaryContext();

  const { isDirty, isSubmitting } = formState;

  const [isLoading, setIsLoading] = useState(false);

  const {
    posEntity: sale,
    event,
    setActivePosEntity,
    updateActivePosEntityInfo,
  } = useActivePosEntityContext<SaleDetails>();

  const shouldQuery = sale?.id != null && sale?.transDest == null;

  useQuery({
    queryKey: ['SaleClient.getSaleRecipient', sale?.id],
    queryFn: async () => {
      if (!shouldQuery) return null;

      const saleDetails = await new SaleClient(
        activeAccountWebClientConfig
      ).getSaleBySaleId(sale.id);

      if (saleDetails?.transDest) {
        // Only update the Sale details if we get the recipient info
        updateActivePosEntityInfo({
          event: event,
          posEntity: saleDetails,
          posEntityId: saleDetails.id,
          posEntityDisplayId: saleDetails.idOnMkp,
        });
      }

      return saleDetails;
    },

    enabled: shouldQuery,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError('SaleClient.getSaleRecipient', error, { saleId: sale?.id });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_FAST,
  });

  const { refreshMetrics } = useCatalogMetricsContext<SaleMetrics>();

  const requiredMsg = useContent(ContentId.Required);
  const atleastOneUrlRequired = useContent(ContentId.AtLeastOneUrlRequired);
  const anUploadedDocumentIsRequired = useContent(
    ContentId.AnUploadedDocumentIsRequired
  ); // 'An upload image or document is required.'

  const watchTransferType = watch('transferType');

  const isMobile = useMatchMedia('mobile');

  const onNext = useCallback(async () => {
    if (eTicketUrlOnly) {
      setCurrentBody(TransferTicketBody.UrlProof);
    }

    switch (currentBody) {
      case TransferTicketBody.Recipient:
        setCurrentBody(TransferTicketBody.ProofType);
        break;
      case TransferTicketBody.ProofType:
        if (watchTransferType === TransferTicketType.ImageProof) {
          setCurrentBody(TransferTicketBody.ImageProof);
        } else if (watchTransferType === TransferTicketType.URL) {
          setCurrentBody(TransferTicketBody.UrlProof);
        } else if (watchTransferType === TransferTicketType.IdProof) {
          setCurrentBody(TransferTicketBody.IdProof);
        } else if (
          watchTransferType === TransferTicketType.ApiTransferTicketmaster
        ) {
          setCurrentBody(TransferTicketBody.TicketmasterBarcodesViaTradeDesk);
        } else if (
          watchTransferType === TransferTicketType.ApiTransfer1Ticket
        ) {
          setCurrentBody(TransferTicketBody.TicketmasterBarcodesViaOneTicket);
        }
        break;
      default:
        console.log('Unhandled TransferTicketBody onNext: ' + currentBody);
        break;
    }
  }, [currentBody, eTicketUrlOnly, watchTransferType]);

  const onBack = useCallback(async () => {
    if (eTicketUrlOnly) {
      if (cancelTo) {
        setModal(cancelTo);
      } else {
        closeModal();
      }
    }
    switch (currentBody) {
      case TransferTicketBody.Recipient:
        if (cancelTo) {
          setModal(cancelTo);
        } else {
          closeModal();
        }
        break;
      case TransferTicketBody.ProofType:
        setCurrentBody(TransferTicketBody.Recipient);
        break;
      case TransferTicketBody.ImageProof:
      case TransferTicketBody.UrlProof:
      case TransferTicketBody.IdProof:
      case TransferTicketBody.TicketmasterBarcodesViaOneTicket:
      case TransferTicketBody.TicketmasterBarcodesViaTradeDesk:
        setCurrentBody(TransferTicketBody.ProofType);
        break;
    }
  }, [cancelTo, closeModal, currentBody, eTicketUrlOnly, setModal]);

  const { ticketCnt } = sale!;
  const barcodes = watch('barcodes');
  const barcodesProvided =
    barcodes?.map((b) => b.barcode).filter((b) => b?.length) ?? [];

  const duplicateMsg = useContent(ContentId.DuplicateBarcode);
  const missingMsg = useFormattedContent(
    FormatContentId.MissingBarcodes,
    `${ticketCnt - barcodesProvided.length}`
  );

  const extraMsg = useFormattedContent(
    FormatContentId.ExtraBarcodes,
    `${barcodesProvided.length - ticketCnt}`
  );

  const [alreadyReportedUsedBarcodes, setAlreadyReportedUsedBarcodes] =
    useState<string[]>([]);
  const barcodeAlreadyUsedWarning = useContent(ContentId.BarcodeAlreadyInUsed);

  const onSubmit = useCallback(
    async (data: TransferTicketsForm) => {
      tryInvokeApi(
        async () => {
          setIsLoading(true);

          const client = new SaleClient(activeAccountWebClientConfig);

          data.saleId = sale!.id;
          const result = await client.saveTicketTransferInfo({
            saleId: data.saleId,
            transferType: data.transferType,
            acceptanceUrls:
              data.transferType === TransferTicketType.URL
                ? data.acceptanceUrlInputs.map((url) => url.url)
                : null,
            uploadProofDocuments:
              data.transferType === TransferTicketType.ImageProof
                ? data.uploadProofDocuments
                : null,
            transferProofId:
              data.transferType === TransferTicketType.IdProof
                ? data.transferProofId
                : null,
            barcodes:
              data.transferType === TransferTicketType.ApiTransfer1Ticket ||
              data.transferType === TransferTicketType.ApiTransferTicketmaster
                ? data.barcodes
                : null,
          });
          if (!isSuccess(result)) {
            showErrorDialog(
              'SaleClient.saveTicketTransferInfo',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => cancelTo && setModal(cancelTo),
                trackErrorData: data,
              }
            );
          } else {
            // Refresh the active data so the SaleDetail dialog and table will have the new content
            await setActivePosEntity(sale!.id, sale!.idOnMkp, true);
            refreshMetrics?.();

            if (cancelTo) {
              setModal(cancelTo);
            } else {
              closeModal(true);
            }
          }
        },
        (error) => {
          showErrorDialog('SaleClient.saveTicketTransferInfo', error, {
            trackErrorData: data,
          });
        },
        () => setIsLoading(false)
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      genericError,
      refreshMetrics,
      sale,
      setActivePosEntity,
      setModal,
      showErrorDialog,
    ]
  );

  const onTransferClicked = useCallback(async () => {
    const data = getValues();
    if (data.transferType === TransferTicketType.URL) {
      if (!data.acceptanceUrlInputs?.length) {
        setError(
          'acceptanceUrlInputs',
          {
            message: atleastOneUrlRequired,
          },
          { shouldFocus: true }
        );
        return;
      } else {
        let hasErrors = false;
        data.acceptanceUrlInputs.forEach((url, i) => {
          if (!url?.url?.length) {
            setError(
              `acceptanceUrlInputs.${i}.url`,
              {
                message: requiredMsg,
              },
              { shouldFocus: true }
            );
            hasErrors = true;
          }
        });

        if (hasErrors) return;
        data.acceptanceUrls = data.acceptanceUrlInputs.map((t) => t.url);
      }
    } else if (data.transferType === TransferTicketType.ImageProof) {
      if (!data.uploadProofDocuments?.length) {
        setError('uploadProofDocuments', {
          message: anUploadedDocumentIsRequired,
        });
        return;
      }
    } else if (data.transferType === TransferTicketType.IdProof) {
      if (!data.transferProofId) {
        setError('transferProofId', {
          message: requiredMsg,
        });

        return;
      }
    } else if (
      data.transferType === TransferTicketType.ApiTransferTicketmaster ||
      data.transferType === TransferTicketType.ApiTransfer1Ticket
    ) {
      let hasErrors = checkBarcodeErrors(
        data.isBulkUpload,
        data.barcodes ?? [],
        ticketCnt,
        setError,
        requiredMsg,
        missingMsg as string,
        extraMsg as string,
        duplicateMsg
      );

      if (hasErrors) {
        return;
      }

      hasErrors = Boolean(
        await checkDuplicateBarcodes(
          sale!.id,
          barcodes ?? [],
          activeAccountWebClientConfig,
          alreadyReportedUsedBarcodes,
          setAlreadyReportedUsedBarcodes,
          (index) =>
            setError(`barcodes.${index}.barcode`, {
              type: 'warning',
              message: barcodeAlreadyUsedWarning,
            }),
          () => setIsLoading(true),
          () => setIsLoading(false),
          showErrorDialog,
          sale!.viagVirtualId
        )
      );

      if (hasErrors) {
        return;
      }
    }

    handleSubmit(onSubmit)();
  }, [
    activeAccountWebClientConfig,
    alreadyReportedUsedBarcodes,
    anUploadedDocumentIsRequired,
    atleastOneUrlRequired,
    barcodeAlreadyUsedWarning,
    barcodes,
    duplicateMsg,
    extraMsg,
    getValues,
    handleSubmit,
    missingMsg,
    onSubmit,
    requiredMsg,
    sale,
    setError,
    showErrorDialog,
    ticketCnt,
  ]);

  const getBody = useCallback(() => {
    switch (currentBody) {
      case TransferTicketBody.Recipient:
        return <TransferTicketsRecipientBody />;
      case TransferTicketBody.ProofType:
        return (
          <ProofTypeSelectionBody
            supportTicketmasterBarcodeTransfer={
              supportTicketmasterBarcodeTransfer
            }
          />
        );
      case TransferTicketBody.ImageProof:
        return <ImageProofBody setIsSaveDisabled={setIsLoading} />;
      case TransferTicketBody.UrlProof:
        return <UrlProofBody disabled={isSubmitting || isLoading} />;
      case TransferTicketBody.IdProof:
        return <TransferIdProofBody disabled={isSubmitting || isLoading} />;
      case TransferTicketBody.TicketmasterBarcodesViaTradeDesk:
        return (
          <TransferTicketmasterBarcodesBody
            disabled={isSubmitting || isLoading}
            instructionsContentId={
              ContentId.TicketmasterBarcodeTransferViaTradeDeskInstruction
            }
          />
        );
      case TransferTicketBody.TicketmasterBarcodesViaOneTicket:
        return (
          <TransferTicketmasterBarcodesBody
            disabled={isSubmitting || isLoading}
            instructionsContentId={
              ContentId.TicketmasterBarcodeTransferViaOneTicketInstruction
            }
          />
        );
    }
  }, [
    currentBody,
    isLoading,
    isSubmitting,
    supportTicketmasterBarcodeTransfer,
  ]);

  const LocalModalFooter = () => {
    return (
      <>
        {currentBody !== TransferTicketBody.Recipient && (
          <BackButton
            onClick={onBack}
            variant={'outline'}
            disabled={isSubmitting}
          />
        )}
        {currentBody === TransferTicketBody.ImageProof ||
        currentBody === TransferTicketBody.UrlProof ||
        currentBody === TransferTicketBody.IdProof ||
        currentBody === TransferTicketBody.TicketmasterBarcodesViaOneTicket ||
        currentBody === TransferTicketBody.TicketmasterBarcodesViaTradeDesk ||
        watchTransferType === TransferTicketType.None ? (
          <OkButton
            onClick={onTransferClicked}
            disabled={
              isSubmitting || isLoading || (!eTicketUrlOnly && !sale?.transDest)
            }
            textContentId={ContentId.Save}
          />
        ) : (
          <OkButton
            onClick={onNext}
            disabled={
              isSubmitting || isLoading || (!eTicketUrlOnly && !sale?.transDest)
            }
            textContentId={ContentId.Next}
          />
        )}
      </>
    );
  };

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isSubmitting}
        showDialogOnCancel={isDirty}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.Transfer} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={sale!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          {isSubmitting && <PosSpinner />}
          {!isSubmitting && getBody()}
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        {isMobile || currentBody !== TransferTicketBody.Recipient ? (
          <LocalModalFooter />
        ) : (
          <CancellableFormFooter
            cancelTo={cancelTo}
            disabled={isSubmitting}
            showDialogOnCancel={isDirty}
          >
            <LocalModalFooter />
          </CancellableFormFooter>
        )}
      </ModalFooter>
    </>
  );
};
