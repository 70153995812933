import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { PosTextField, PosTextFieldProps } from 'src/core/POS/PosTextField';
import { posChangedField } from 'src/utils/posFieldUtils';

interface InputCellProps<T, V, TFormValues extends FieldValues>
  extends CellContext<T, V>,
    PosTextFieldProps {
  fieldName: FieldPath<TFormValues>;
  setValue: UseFormSetValue<TFormValues>;
  register: UseFormRegister<TFormValues>;
}

const InputCellComponent = function InputCell<
  T,
  V,
  TFormValues extends FieldValues,
>({
  table,
  fieldName,
  register,
  setValue,
  ...inputProps
}: InputCellProps<T, V, TFormValues>) {
  const { clearErrors, getFieldState, formState } =
    useFormContext<TFormValues>();
  const inputType = inputProps.type ?? 'text';
  const hasError = getFieldState(fieldName, formState)?.error;

  return (
    <PosTextField
      {...inputProps}
      type={inputType}
      {...register(`${fieldName}.value` as FieldPath<TFormValues>, {
        valueAsNumber: inputProps.type === 'number',
        onChange: (e) => {
          setValue(
            fieldName,
            posChangedField(e.target.value) as PathValue<
              TFormValues,
              FieldPath<TFormValues>
            >
          );
          inputProps.onChange?.(e);
        },
        onBlur: (e) => {
          if (hasError && e.target.value !== '') {
            clearErrors(fieldName as Path<TFormValues>);
          }
        },
      })}
      rootProps={{
        style: {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: '0px',
          width: '100%',
          height: '100%',
          padding: '0 4px',
          ...inputProps.style,
        },
      }}
      style={{
        textAlign: inputType === 'text' ? 'left' : 'right',
        padding: '0 4px',
      }}
    />
  );
};

export const InputCell = memo(InputCellComponent) as <
  T,
  V,
  TFormValues extends FieldValues,
>(
  props: InputCellProps<T, V, TFormValues>
) => JSX.Element;
