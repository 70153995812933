import { EntitySearchFilterBar } from 'src/components/EntitySearch';
import { FilterDropdownItem } from 'src/components/Filters';
import { usePurchaseFilters } from 'src/components/MainFilterBar/usePurchaseFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  Feature,
  PurchaseOrderQuery,
  PurchaseSearchResult,
} from 'src/WebApiController';

export const PurchaseSearchFilterBar = (props: {
  activeSearchConfig?: PurchaseSearchResult;
  onSubmitFilter?: (query: PurchaseOrderQuery) => void;
  selectedFilters?: FilterDropdownItem[];
  setSelectedFilters: (sf: FilterDropdownItem[]) => void;
}) => {
  const hasPurchaseCommissionFilterInSearchViewFeature = useUserHasFeature(
    Feature.PurchaseCommissionFilterInSearchView
  );
  const hasConsignmentFilterPurchaseSearchViewFeature = useUserHasFeature(
    Feature.ConsignmentFilterPurchaseSearchView
  );
  const hasConsignmentDealFeature = useUserHasFeature(Feature.ConsignmentDeal);

  const filters = usePurchaseFilters({
    isHorizontalLayout: true,
    showCommissionerFilter: hasPurchaseCommissionFilterInSearchViewFeature,
    showConsignmentFilter:
      hasConsignmentDealFeature &&
      hasConsignmentFilterPurchaseSearchViewFeature,
    showCatalogFilters: true,
  });

  return (
    <EntitySearchFilterBar
      {...props}
      filters={filters}
      mandatoryFiltersToShow={['purchaseDates']}
    />
  );
};
