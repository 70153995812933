import {
  functionalUpdate,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { ReportsMaxSizeMessage } from 'src/components/Reports/ReportsMaxSizeMessage';
import { useActiveSortTableColumnContext } from 'src/contexts/ActiveSortTableColumnContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { Stack } from 'src/core/ui';
import { Table } from 'src/tables/Table';
import { ListingReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import {
  ListingQuery,
  ListingReportMetrics,
  ReportGroupBy,
  UserSetting,
} from 'src/WebApiController';

import TableStateWrapper from '../Table/TableStateWrapper';
import { ListingReportTableProps } from './ListingReportTable.types';
import {
  ListingReportTableColumnDefContextProvider,
  useListingReportTableColumnDef,
} from './ListingReportTableColumnDefContext';

export const ListingReportTable = ({
  report,
  ...rest
}: ListingReportTableProps) => {
  return (
    <ListingReportTableColumnDefContextProvider report={report}>
      <ListingReportTableInternal report={report} {...rest} />
    </ListingReportTableColumnDefContextProvider>
  );
};

const ListingReportTableInternal = ({
  metricsData,
  summaryData,
  totalCount,
  report,
  refreshMetrics,
  onMount,
  onUnmount,
  initState,
  className,
}: ListingReportTableProps) => {
  const { data: catalogData } = useCatalogDataContext();
  const { hasTimeout: metricsHasTimeout, hasUnknownError: metricsHasError } =
    useReportMetricsContext<ListingReportMetrics>();

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const { activeSortTableColumn, setActiveSortTableColumn } =
    useActiveSortTableColumnContext();
  const [sorting, setSorting] = useState<SortingState>(
    activeSortTableColumn || initState?.sorting || []
  );

  const data = useMemo(() => {
    const performerCount = Object.keys(catalogData?.performers ?? {}).length;
    const venueCount = Object.keys(catalogData?.venues ?? {}).length;
    const eventCount = Object.keys(catalogData?.events ?? {}).length;

    const shimmeringDivCount =
      report.groupBy === ReportGroupBy.Performer
        ? performerCount
        : report.groupBy === ReportGroupBy.Venue
        ? venueCount
        : eventCount;
    return metricsData ?? Array(shimmeringDivCount).fill(null);
  }, [
    catalogData?.events,
    catalogData?.performers,
    catalogData?.venues,
    metricsData,
    report.groupBy,
  ]);

  const disablePagination = report.numberOfItemsPerPage == null;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: Math.max(
      1,
      disablePagination ? data.length : report.numberOfItemsPerPage!
    ),
  });

  useEffect(() => {
    if (disablePagination) {
      if (pagination.pageSize !== data.length) {
        setPagination((prevState) => ({
          ...prevState,
          pageSize: data.length,
        }));
      }
    } else {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: report.numberOfItemsPerPage!,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, report]);

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const { displayedColumnsConfig = [], isLoading: tableColumnIsLoading } =
    useListingReportTableColumnDef();

  const options: Partial<TableOptions<ListingReportMetricsWithGroupBy | null>> =
    useMemo(
      () => ({
        data,
        meta: {
          summaryData,
        },
        columns: displayedColumnsConfig,
        state: {
          pagination,
          sorting,
        },
        onPaginationChange: setPagination,
        onSortingChange: (sortingUpdaterFn) => {
          const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
          setSorting(newSortVal);
          setActiveSortTableColumn(newSortVal);
        },
        enableRowSelection: true,
      }),
      [
        data,
        summaryData,
        displayedColumnsConfig,
        pagination,
        sorting,
        setActiveSortTableColumn,
      ]
    );

  return (
    <TableStateWrapper<ListingQuery>
      hasData={data?.length > 0}
      isLoading={tableColumnIsLoading || false}
      hasError={metricsHasError || false}
      hasTimeout={metricsHasTimeout || false}
    >
      <Stack gap="l" direction="column" width="full" height="full">
        <ReportsMaxSizeMessage
          totalCount={totalCount}
          count={data?.length}
          onBypassLimit={() => refreshMetrics(true)}
        />
        <ColumnResizingContextProvider
          userSettingId={UserSetting.InventoryReportColumnWidths}
        >
          <Table
            options={options}
            className={className}
            tableLayout="fixed"
            withOuterPadding
            useVirtuoso
            useStickyFooter
            tableHeadStyle={
              disablePagination
                ? {
                    position: 'sticky',
                    top: '0',
                    zIndex: '10',
                  }
                : undefined
            }
          />
        </ColumnResizingContextProvider>
      </Stack>
    </TableStateWrapper>
  );
};

export default ListingReportTable;
