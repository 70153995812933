import { useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getEventDisplayText,
  getPerformerAndVenueForEvent,
} from 'src/utils/eventWithDataUtils';
import { EventWithData } from 'src/WebApiController';

type EventSelectorProps = Omit<PosMultiSelectProps, 'valueOptionsContent'> & {
  filterCallback?: (event?: EventWithData) => boolean;
};

export const EventSelector = ({
  filterCallback,
  placeholderText,
  ...props
}: EventSelectorProps) => {
  // TODO - remove all these logic once Catalog v2 feature is available
  const { data: catalogData, isLoading } = useCatalogDataContext();

  const allEvents = useMemo(() => {
    if (!catalogData?.events) return {};

    const events = catalogData?.events;

    const results = Object.values(events!).reduce(
      (acc, event) => {
        if (
          event.event.viagVirtualId &&
          (filterCallback == null || filterCallback(event))
        ) {
          const { venue, performer } = getPerformerAndVenueForEvent(
            event?.event,
            catalogData
          );

          const name = getEventDisplayText(event.event, venue, performer, true);

          acc[event.event.viagVirtualId] = name;
        }
        return acc;
      },
      {} as Record<string, string>
    );

    return results;
  }, [catalogData, filterCallback]);

  const hasNoEvent = Object.values(allEvents).length == 0;

  return (
    <PosMultiSelect
      {...props}
      searchable
      loading={isLoading}
      placeholderText={
        hasNoEvent ? ContentId.SearchEmptyResult : placeholderText
      }
      useVirtualWindow
      valueOptionsContent={allEvents}
    />
  );
};
