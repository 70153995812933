/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import clsx from 'clsx';
import { ChangeEvent, ComponentProps, ReactNode, useRef } from 'react';
import { vars } from 'src/core/themes';
import { CheckIcon, IconsFill, MinusIcon } from 'src/svgs/Viagogo';

import * as styles from './Checkbox.css';

type CheckboxProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  labelPosition?: 'left' | 'right';
  name?: string;
  label?: ReactNode | null;
  error?: string | (() => string | null | undefined);
  small?: boolean;
} & Omit<ComponentProps<'div'>, 'onChange' | 'disabled'>;

const getRenderedMessage = (message: CheckboxProps['error']) =>
  typeof message === 'function' ? message() : message;

export const Checkbox = ({
  onChange,
  checked = false,
  indeterminate = false,
  disabled = false,
  labelPosition = 'left',
  name = '',
  label,
  error,
  small,
  ...props
}: CheckboxProps) => {
  const errorMessage = getRenderedMessage(error);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div
        className={styles.root}
        data-is-checkbox={true}
        {...props}
        aria-label="checkbox"
        onClick={() => {
          if (disabled) return;

          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        <input
          ref={inputRef}
          className={styles.browserCheckbox}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          name={name}
        />
        {labelPosition === 'left' && label != null && <div>{label}</div>}
        <span
          className={clsx(styles.checkbox, {
            [styles.isChecked]: checked && !disabled,
            [styles.checkboxDisabled]: disabled,
            [styles.checkboxSmall]: small,
          })}
        >
          {checked && !indeterminate && (
            <CheckIcon
              size={vars.iconSize.s}
              withHoverEffect
              fill={disabled ? IconsFill.textDisabled : IconsFill.currentColor}
            />
          )}
          {checked && indeterminate && (
            <MinusIcon
              size={vars.iconSize.s}
              withHoverEffect
              fill={disabled ? IconsFill.textDisabled : IconsFill.currentColor}
            />
          )}
        </span>
        {labelPosition === 'right' && label != null && (
          <div
            style={{ color: disabled ? vars.color.textDisabled : undefined }}
          >
            {label}
          </div>
        )}
      </div>
      {error && <div className={styles.errorMessage}>{errorMessage}</div>}
    </>
  );
};
