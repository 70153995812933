import { IconsFill } from 'src/svgs/Viagogo';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { DeliveryType } from 'src/WebApiController';

const createDeliveryTypeMapping = <T,>(
  transform: (value: DeliveryType) => T
): Record<DeliveryType, T> =>
  Object.entries(DeliveryType).reduce(
    (result, [_, value]) => {
      if (value === DeliveryType.Wallet) {
        return result;
      }
      result[value] = transform(value);
      return result;
    },
    {} as Record<DeliveryType, T>
  );

export const useTicketGroupDeliveryTypes = () => {
  const deliveryTypeMapping = createDeliveryTypeMapping((value) =>
    getDeliveryTypeIcon(value, null, IconsFill.currentColor)
  );

  const deliveryTypeMappingOverride = createDeliveryTypeMapping((value) => (
    <div key={value}>{value}</div>
  ));

  const deliverTypeOptionsAlwaysShow = createDeliveryTypeMapping(() => true);

  return {
    deliveryTypeMapping,
    deliveryTypeMappingOverride,
    deliverTypeOptionsAlwaysShow,
  };
};
