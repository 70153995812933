import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { SearchResult } from 'src/WebApiController';

export type EntitySearchConfigDialogProps<T extends SearchResult> = {
  searchConfig?: T;
  onSave: (searchConfig: T) => void;
} & ComponentProps<typeof RSModal>;

export const EntitySearchConfigDialog = <T extends SearchResult>({
  searchConfig,
  ...rest
}: EntitySearchConfigDialogProps<T>) => {
  const methods = useForm<SearchResult>({
    defaultValues: searchConfig ?? { id: newGuidId() },
  });

  useEffect(() => {
    if (
      searchConfig &&
      !isEqual(searchConfig, methods.formState.defaultValues)
    ) {
      methods.reset(searchConfig);
    }
  }, [methods, searchConfig]);

  return (
    <FormProvider {...methods}>
      <EntitySearchConfigDialogBody {...methods} {...rest} />
    </FormProvider>
  );
};

const EntitySearchConfigDialogBody = <T extends SearchResult>({
  onSave,
  onClosed,
  getValues,
  setValue,
  formState,
  clearErrors,
  setError,
  handleSubmit,
  register,
  reset,
  watch,
  ...rest
}: Omit<EntitySearchConfigDialogProps<T>, 'searchConfig'> &
  Omit<
    ComponentProps<typeof FormProvider<SearchResult, unknown>>,
    'children'
  >) => {
  const { loginContext } = useAppContext();
  const requiredMsg = useContent(ContentId.Required);
  const name = watch('name');
  const ownerUserId = watch('ownerUserId');

  const isNotOwned = ownerUserId && ownerUserId !== loginContext?.user?.userId;

  const handleClose = useCallback(() => {
    reset();
    onClosed?.();
  }, [onClosed, reset]);

  const onSubmit = useCallback(
    (saveCopy?: boolean) => {
      clearErrors();

      let hasErrors = false;
      if (!name) {
        setError('name', { message: requiredMsg }, { shouldFocus: true });
        hasErrors = true;
      }
      if (!hasErrors) {
        if (!ownerUserId || isNotOwned || saveCopy) {
          setValue('id', newGuidId());
        }
        handleSubmit(onSave)();
      }
    },
    [
      clearErrors,
      handleSubmit,
      isNotOwned,
      name,
      onSave,
      ownerUserId,
      requiredMsg,
      setError,
      setValue,
    ]
  );

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.SaveSearch} />}
      footer={
        <>
          {onClosed && <CancelButton onClick={handleClose} />}

          {formState.defaultValues?.ownerUserId && (
            <Button
              variant={isNotOwned ? 'regular' : 'outline'}
              onClick={() => onSubmit(true)}
              disabled={formState.isSubmitting}
            >
              <Content id={ContentId.SaveCopy} />
            </Button>
          )}
          {!isNotOwned && (
            <Button
              variant={'regular'}
              onClick={() => onSubmit(false)}
              disabled={formState.isSubmitting}
            >
              <Content id={ContentId.Save} />
            </Button>
          )}
        </>
      }
      onCancel={handleClose}
      onClosed={handleClose}
    >
      <PosFormField
        errors={formState.errors.name?.message}
        label={<Content id={ContentId.Name} />}
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(formState.errors.name),
          }}
          {...register('name', {
            required: requiredMsg,
          })}
        />
      </PosFormField>
    </GenericDialog>
  );
};
