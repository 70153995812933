export enum SortStatus {
  'UNSORTED',
  'ASC',
  'DESC',
}

export enum ArrowType {
  'UP',
  'DOWN',
}

export function getNextSortStatus(
  status: SortStatus,
  allowUnsorted: boolean
): SortStatus {
  switch (status) {
    case SortStatus.UNSORTED:
      return SortStatus.ASC;
    case SortStatus.ASC:
      return SortStatus.DESC;
    case SortStatus.DESC:
    default: {
      if (allowUnsorted) {
        return SortStatus.UNSORTED;
      }

      return SortStatus.ASC;
    }
  }
}

export type SortButtonProps = {
  sortStatus: SortStatus;
  disabled?: boolean;
  onClick?: (status: SortStatus) => void;
  allowUnsorted?: boolean;
};
