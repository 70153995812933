import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { ScrollAreaActions } from 'src/core/ui/ScrollArea/ScrollArea';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

import {
  mapFiltersToFiltersState,
  useFilterFiltersByPermission,
} from '../Filters';
import { AddFilterDropdown, AddFilterDropdownProps } from './AddFilterDropdown';
import { FiltersList, FiltersListProp } from './FilterList';

export type FilterToolbarEmbeddedProps = {
  filters: FilterToolbarGroup[];
  selectedFilters?: FilterDropdownItem[];
  setSelectedFilters: (sf: FilterDropdownItem[]) => void;
  onResetAll: () => void;
  resetTempQuery?: (keys?: FilterToolbarItemId[]) => void; // TODO: make this not optional
  tempQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  initialQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  appliedFilterIds?: FilterToolbarItemId[];
  mandatoryFiltersToShow?: FilterToolbarItemId[];
  performerVenueFilter?: JSX.Element;
  addFilterDropdownProps?: {
    triggerVariant?: AddFilterDropdownProps['triggerVariant'];
    triggerShape?: AddFilterDropdownProps['triggerShape'];
    triggerClassName?: AddFilterDropdownProps['triggerClassName'];
  };
} & Pick<
  FiltersListProp,
  | 'onEditabilityChange'
  | 'direction'
  | 'filtersListHeader'
  | 'flexWrap'
  | 'viewerMode'
  | 'disabled'
>;

export function FilterToolbarEmbedded({
  filters,
  selectedFilters = [],
  setSelectedFilters,
  onResetAll,
  appliedFilterIds,
  resetTempQuery,
  initialQuery = {},
  mandatoryFiltersToShow = [],
  performerVenueFilter,
  onEditabilityChange,
  direction,
  addFilterDropdownProps,
  filtersListHeader,
  flexWrap,
  viewerMode,
  disabled,
}: FilterToolbarEmbeddedProps) {
  const scrollAreaRefActions = useRef<ScrollAreaActions | null>(null);
  const filteredByPermissionsFilters = useFilterFiltersByPermission({
    filters,
  });

  const nonHiddenFilterIds = useMemo(
    () =>
      filteredByPermissionsFilters
        .flatMap((group) => group.items)
        .filter(
          (item) =>
            (item.reportFilterEditability ??
              DEFAULT_REPORT_FILTER_EDITABILITY) !==
            ReportFilterEditability.Hidden
        )
        .map((item) => item.filterId),
    [filteredByPermissionsFilters]
  );

  const appliedFilterIdsWithNonHidden = useMemo(
    () =>
      Array.from(new Set([...(appliedFilterIds ?? []), ...nonHiddenFilterIds])),
    [appliedFilterIds, nonHiddenFilterIds]
  );

  useEffect(() => {
    if (selectedFilters.length === 0) {
      const newSelectedFilters = mapFiltersToFiltersState({
        filters: filteredByPermissionsFilters,
        appliedFilterIds: appliedFilterIdsWithNonHidden,
        mandatoryFiltersToShow,
      });

      setSelectedFilters(Object.values(newSelectedFilters).flat());
    }
  }, [
    appliedFilterIds,
    appliedFilterIdsWithNonHidden,
    filteredByPermissionsFilters,
    mandatoryFiltersToShow,
    selectedFilters,
    setSelectedFilters,
  ]);

  const hasNonMandatorySelectedFilters = useMemo(() => {
    return selectedFilters.some(
      (dropdownItem) =>
        !mandatoryFiltersToShow.includes(dropdownItem.filterId) &&
        !initialQuery[dropdownItem.filterId] != null
    );
  }, [initialQuery, mandatoryFiltersToShow, selectedFilters]);

  const clearAll = useCallback(() => {
    // Clear all and keep the mandatory fields
    const cleanSelectedFilters: FilterDropdownItem[] = [];
    selectedFilters.forEach((filterDropdownItem) => {
      if (
        mandatoryFiltersToShow.includes(filterDropdownItem.filterId) ||
        initialQuery[filterDropdownItem.filterId] != null
      ) {
        cleanSelectedFilters.push(filterDropdownItem);
      }
    });
    setSelectedFilters(cleanSelectedFilters);
    resetTempQuery?.();
    onResetAll();
  }, [
    initialQuery,
    mandatoryFiltersToShow,
    onResetAll,
    resetTempQuery,
    selectedFilters,
    setSelectedFilters,
  ]);

  const onFilterRemoved = useCallback(
    (removedFilterDropdownItem: FilterDropdownItem) => {
      const titleContentId = removedFilterDropdownItem.titleContentId as string;

      const filterToolbarGroup = filters.find(
        (group) => group.titleContentId === titleContentId
      );

      if (!filterToolbarGroup) {
        return;
      }

      const filtersToRemove = filterToolbarGroup.items.filter(
        (filter) => filter.filterId === removedFilterDropdownItem.filterId
      );

      const filterIdsToRemove = filtersToRemove.map((f) => f.filterId);
      const filteredDropdownItems = (selectedFilters ?? []).filter(
        (dropdownItem) => !filterIdsToRemove.includes(dropdownItem.filterId)
      );

      // all keys to remove from tempQuery
      const filterQueryKeysToRemove = filtersToRemove.flatMap(
        (f) => f.filterQueryKeys ?? []
      );

      resetTempQuery?.(filterQueryKeysToRemove);
      setSelectedFilters(filteredDropdownItems);
      onEditabilityChange?.(
        filtersToRemove.map((f) => f.filterId),
        ReportFilterEditability.Hidden
      );
    },
    [
      filters,
      onEditabilityChange,
      resetTempQuery,
      selectedFilters,
      setSelectedFilters,
    ]
  );

  const onFilterSelected = useCallback(
    (filterDropdownItems: FilterDropdownItem[]) => {
      const updatedSelectedFilters: FilterDropdownItem[] = [...selectedFilters];

      let addedNewFilter = false;
      filterDropdownItems.forEach((filterDropdownItem) => {
        const isSelected = selectedFilters?.some(
          (selectedFilter) =>
            selectedFilter.filterId === filterDropdownItem.filterId
        );
        if (!isSelected) {
          updatedSelectedFilters.push(filterDropdownItem);
          addedNewFilter = true;
        }
      });

      if (addedNewFilter) {
        setSelectedFilters(updatedSelectedFilters);
      }
      // On next render, scroll
      setTimeout(() => {
        scrollAreaRefActions.current?.startScroll('right');
      }, 10);
    },
    [selectedFilters, setSelectedFilters]
  );

  return (
    <FiltersList
      filters={filteredByPermissionsFilters}
      selectedDropdownItems={selectedFilters ?? []}
      onFilterRemoved={onFilterRemoved}
      mandatoryFiltersToShow={mandatoryFiltersToShow}
      performerVenueFilter={performerVenueFilter}
      resetButton={
        <>
          {viewerMode ? null : (
            <Button
              variant="text"
              onClick={clearAll}
              style={{ height: 'fit-content', whiteSpace: 'nowrap' }}
              disabled={disabled}
            >
              <Content
                id={
                  mandatoryFiltersToShow.length
                    ? ContentId.Reset
                    : ContentId.Clear
                }
              />
            </Button>
          )}
        </>
      }
      addFilterButton={
        <>
          {viewerMode ? null : (
            <AddFilterDropdown
              filters={filteredByPermissionsFilters}
              onFilterSelected={onFilterSelected}
              hideIcon={direction === 'column'}
              disabled={disabled}
              hideLabel={
                hasNonMandatorySelectedFilters && direction !== 'column'
              }
              {...addFilterDropdownProps}
            />
          )}
        </>
      }
      scrollAreaRefActions={scrollAreaRefActions}
      onEditabilityChange={onEditabilityChange}
      direction={direction}
      filtersListHeader={filtersListHeader}
      flexWrap={flexWrap}
      viewerMode={viewerMode}
      disabled={disabled}
    />
  );
}
