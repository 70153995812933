import { format } from 'date-fns';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { PurchasesBulkActionsPermissions } from 'src/components/Purchases/BulkActions/PurchasesBulkActionsPermissions';
import { Content } from 'src/contexts/ContentContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  TimePeriodContainingPurchaseOrderResult,
  TimePeriodType,
} from 'src/WebApiController';

import * as styles from './PurchaseDateAccordion.css';
import {
  ItemsCountDiv,
  PurchaseItemHeaderDateDisplay,
  TotalCostDiv,
} from './PurchaseDateAccordion.styled';

type PurchaseDateItemHeaderProps = TimePeriodContainingPurchaseOrderResult & {
  onToggleMultiSelect: (groupId: string, isChecked: boolean) => void;
};

export const PurchaseDateItemHeader = (props: PurchaseDateItemHeaderProps) => {
  const { firstOfTimePeriod, purchaseCount, numOfTickets, totalCost } = props;

  const { purchaseGroupTimePeriod } = usePurchaseDataContext();

  const date = new Date(firstOfTimePeriod);
  const dateDisplay =
    purchaseGroupTimePeriod === TimePeriodType.Monthly
      ? format(date, 'MMM, yyyy')
      : format(date, 'MMM dd, yyyy');

  return (
    <Stack justifyContent="spaceBetween" gap="l" alignItems="center">
      <PurchaseItemHeaderDateDisplay>
        {dateDisplay}
      </PurchaseItemHeaderDateDisplay>
      <Stack gap="l" alignItems="center">
        <PurchasesBulkActionsPermissions>
          <MultiSelectionToggleGroup
            groupId={firstOfTimePeriod}
            onToggle={props.onToggleMultiSelect}
          />
        </PurchasesBulkActionsPermissions>
        <div className={styles.purchaseItemMetrics}>
          <TotalCostDiv key="total-cost">{totalCost?.disp}</TotalCostDiv>
          <ItemsCountDiv key="items-count">
            <div>
              {purchaseCount}{' '}
              <Content
                id={
                  purchaseCount === 1 ? ContentId.Purchase : ContentId.Purchases
                }
              />
            </div>
            <div>
              {numOfTickets}{' '}
              <Content
                id={numOfTickets === 1 ? ContentId.Ticket : ContentId.Tickets}
              />
            </div>
          </ItemsCountDiv>
        </div>
      </Stack>
    </Stack>
  );
};
