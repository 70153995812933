import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  EventAccordionItemProps,
  translateEventToSaleAccordionItem,
} from 'src/components/Accordions';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { BackSection } from 'src/components/common/BackSection';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import {
  EventPageTabs,
  TabItem,
} from 'src/components/Events/EventPage/EventPageTabs';
import { SidePanel } from 'src/components/Events/EventPage/SidePanel/SidePanel';
import { TagsSection } from 'src/components/Listings/InventoryEventPage/Sections/TagsSection';
import { ActiveFocusContextProvider } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import {
  EventLastReviewedContextProvider,
  useEventLastReviewedContext,
} from 'src/contexts/SellerEventLastReviewContext/SellerEventLastReviewContext';
import {
  SidePanelPage,
  SidePanelProvider,
} from 'src/contexts/SidePanelContext';
import { ExplorerLayout } from 'src/core/layouts';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useQuickFilterSearchParam } from 'src/hooks/useQuickFilters';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { getAppliedFilterCounts } from 'src/utils/eventQueryUtils';
import { getSaleDetailsModalConfigWithDeepLink } from 'src/utils/saleUtils';
import {
  EventWithData,
  Sale,
  SaleDetails,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import * as styles from '../../Events/EventPage/EventPage.css';
import { SaleEventInfoSection } from './SaleEventInfoSection';
import { SaleEventPageHeader } from './SaleEventPageHeader';

export function SaleEventPage({
  viagVirtualId,
  posEventIds,
  returnUrl,
}: {
  posEventIds?: string[];
  viagVirtualId?: string;
  returnUrl?: string;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsTransformed,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  useEffect(() => {
    if (!viagVirtualId && posEventIds?.length) {
      setListItemExpansion(
        true,
        posEventIds.map((id) => `old:${id}`)
      );
    } else if (viagVirtualId) {
      setListItemExpansion(true, [viagVirtualId]);
    }
  }, [
    activeAccountWebClientConfig,
    posEventIds,
    setListItemExpansion,
    viagVirtualId,
  ]);

  const { setAllGroupsIds } = useMultiSelectionContext();

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.length
        ? eventsTransformed.find(
            (ev) =>
              ev.event.viagVirtualId === viagVirtualId ||
              ev.event.posIds.some((id) => posEventIds?.includes(id))
          )
        : undefined,
    [eventsTransformed, posEventIds, viagVirtualId]
  );

  const salesAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToSaleAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  useEffect(() => {
    if (salesAccordionItem) {
      setAllGroupsIds([salesAccordionItem.itemId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesAccordionItem]);

  return (
    <SidePanelProvider sidePanelId="sale-event">
      <ActiveFocusContextProvider>
        <EventMapContextProvider event={accordionItemProps?.event}>
          <EventLastReviewedContextProvider>
            <Stack direction="column" height="full">
              <SaleEventContent
                accordionItemProps={accordionItemProps}
                saleAccordionItem={salesAccordionItem}
                returnUrl={returnUrl}
              />
            </Stack>
          </EventLastReviewedContextProvider>
        </EventMapContextProvider>
      </ActiveFocusContextProvider>
    </SidePanelProvider>
  );
}

type SalesEventContentProps = {
  accordionItemProps?: EventWithData;
  saleAccordionItem?: EventAccordionItemProps<Sale>;
  returnUrl?: string;
};

export const enum SaleEventTabs {
  Sales = 'sales',
  Tags = 'tags',
  None = 'none',
}

function SaleEventContent({
  accordionItemProps,
  saleAccordionItem,
  returnUrl,
}: SalesEventContentProps) {
  const intialActiveTab = SaleEventTabs.Sales;

  const [selectedSaleIds, setSelectedSaleIds] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState(intialActiveTab);

  const { isLoading } = useCatalogDataContext();
  const isMobile = useMatchMedia('mobile');

  const quickFilterSearchParams = useQuickFilterSearchParam();
  const { tempQuery, initialQuery, filterQuery } =
    useFilterQueryContext<SaleQuery>();

  const { setModal } = useContext(ModalContext);
  const { posEntity: sale, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();

  const {
    value: defaultSalesTabUserSetting,
    setUserSetting: setDefaultSalesTabUserSetting,
    isLoading: defaultSalesTabLoading,
  } = useServerUserSetting<SaleEventTabs>({
    id: UserSetting.SalesDefaultTab,
  });

  const activeId = getDeepLinkIdFromUrl(
    SaleDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length && !sale) {
      setActivePosEntity(parseInt(activeId), undefined, true);
      setModal(getSaleDetailsModalConfigWithDeepLink(activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const [defaultTab, setDefaultTab] = useState<SaleEventTabs | undefined>(
    defaultSalesTabUserSetting
  );

  useEffect(() => {
    if (
      defaultSalesTabUserSetting != null &&
      defaultSalesTabUserSetting !== activeTab &&
      defaultSalesTabUserSetting !== SaleEventTabs.None &&
      defaultSalesTabLoading === false
    ) {
      setActiveTab(defaultSalesTabUserSetting);
    }
    if (
      defaultSalesTabUserSetting != null &&
      defaultSalesTabUserSetting !== defaultTab &&
      defaultSalesTabUserSetting !== SaleEventTabs.None &&
      defaultSalesTabLoading === false
    ) {
      setDefaultTab(defaultSalesTabUserSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSalesTabLoading, defaultSalesTabUserSetting]);

  const onSetDefaultTabClick = useCallback(
    async (tab: SaleEventTabs) => {
      if (tab !== activeTab && tab !== SaleEventTabs.None) {
        setActiveTab(tab);
      }
      if (tab !== defaultTab) {
        setDefaultTab(tab);
      }
      setDefaultSalesTabUserSetting(tab);
    },
    [activeTab, defaultTab, setDefaultSalesTabUserSetting]
  );

  const swiperRef = useRef<Swiper>();

  const event = accordionItemProps?.event;

  const { onSettingVirtualIds } = useEventLastReviewedContext();

  useEffect(() => {
    onSettingVirtualIds(event?.viagVirtualId ? [event?.viagVirtualId] : []);
  }, [event?.viagVirtualId, onSettingVirtualIds]);

  const tabs: TabItem<SaleEventTabs>[] = [
    {
      value: SaleEventTabs.Sales,
      label: <Content id={ContentId.Sales} />,
      content: (
        <SaleEventInfoSection
          accordionItemProps={accordionItemProps}
          saleAccordionItem={saleAccordionItem}
          selectedSaleIds={selectedSaleIds}
          setSelectedSaleIds={setSelectedSaleIds}
        />
      ),
      className: styles.tabContent,
    },
    {
      value: SaleEventTabs.Tags,
      label: <Content id={ContentId.Tags} />,
      content: event?.viagVirtualId && (
        <TagsSection eventId={event?.viagVirtualId} />
      ),
    },
  ];

  if (!event) {
    if (
      quickFilterSearchParams ||
      getAppliedFilterCounts(tempQuery, initialQuery)
    )
      return (
        <EmptySectionContent.Root
          icon={
            <EmptySectionContent.SolidIconContainer>
              <SearchSolidIcon />
            </EmptySectionContent.SolidIconContainer>
          }
        >
          <EmptySectionContent.Label>
            <Content id={ContentId.NoResultFound} />
          </EmptySectionContent.Label>
          <EmptySectionContent.DetailMessage>
            <Content id={ContentId.NoFilteredNotifications} />
          </EmptySectionContent.DetailMessage>
        </EmptySectionContent.Root>
      );
  }

  if (isLoading || !event) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.root}>
      <ExplorerLayout
        swiperRef={swiperRef}
        defaultRatio={0.35}
        maxPanelPercent={0.5}
        showExpandButton
        sidePanelPage={SidePanelPage.SaleEvent}
        rightPanelContent={
          <SidePanel
            event={event}
            pageType="Sales"
            selectedEntityIds={selectedSaleIds}
            filterQuery={filterQuery}
          />
        }
      >
        <div className={styles.leftContainer}>
          {isMobile && (
            <Stack
              justifyContent="spaceBetween"
              alignItems="center"
              style={{
                padding: `0 ${vars.spacing['xl']} ${vars.spacing['lg']} ${vars.spacing['xl']}`,
              }}
            >
              <BackSection
                returnUrl={returnUrl ?? '/sales'}
                state={{
                  fromPosEventId: event.viagVirtualId,
                }}
              />
              <SwiperButton dir="right" />
            </Stack>
          )}
          <CollapsableViewProvider isEnabled={true}>
            <SaleEventPageHeader
              event={event}
              saleAccordionItem={saleAccordionItem}
            />
            <EventPageTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onSetDefaultTabClick={onSetDefaultTabClick}
            />
          </CollapsableViewProvider>
        </div>
      </ExplorerLayout>
    </div>
  );
}
