import {
  closestCorners,
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { debounce } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Content,
  getContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { shared } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useReportConfigActionsV2 } from 'src/hooks/useReportConfigActionsV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { SalesReportTableValueColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID,
  LISTING_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID,
  SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID,
  SALES_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { ReportColumn } from 'src/WebApiController';

import { MetricsInputDroppable } from './MetricsInputDroppable';
import { MetricsOptionsList } from './MetricsOptionsList';
import { MetricsOptionsListItem } from './MetricsOptionsListItem';
import * as styles from './ReportsTableEditor.css';

export const ReportsTableEditorForm = ({
  reportConfig,
  disabled,
}: {
  reportConfig: ReportConfigV2;
  disabled?: boolean;
}) => {
  const methods = useForm<ReportConfigV2>({
    defaultValues: reportConfig,
  });

  // Report config can be changed elsewhere like the filter bar
  useEffect(() => {
    if (!isEqual(methods.formState.defaultValues, reportConfig)) {
      methods.reset(reportConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportConfig]);

  return (
    <FormProvider {...methods}>
      <ReportsTableEditorContent {...methods} disabled={disabled} />
    </FormProvider>
  );
};

const ROWS_INPUT_KEY = 'rows-input';
const VALUES_INPUT_KEY = 'values-input';
const OPTIONS_LIST_KEY = 'metrics-options-list';

const ReportsTableEditorContent = ({
  watch,
  formState,
  handleSubmit,
  setValue,
  disabled,
}: Omit<
  ComponentProps<typeof FormProvider<ReportConfigV2, unknown>>,
  'children' | 'trigger' | 'reset' | 'unregister'
> & {
  disabled?: boolean;
}) => {
  const metricsError = formState.errors.request?.aggregations?.message;
  const contentContext = useContentContext();

  const groupbyField = 'request.rowGroupings';
  const sortByField = 'request.orderBy';
  const metricsField = 'request.aggregations';

  const reportType = watch('reportType');
  const groupBy = watch(groupbyField);
  const metrics = watch(metricsField);
  const sortBy = watch(sortByField);

  const { upsertReportConfig } = useReportConfigActionsV2();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpsertReportConfig = useCallback(
    debounce((report: ReportConfigV2) => {
      if (disabled) {
        return;
      }
      upsertReportConfig(report);
    }, 1000),
    [disabled, upsertReportConfig]
  );

  const onSubmit = useCallback(
    async (report: ReportConfigV2) => {
      if (disabled) {
        return;
      }

      if (
        !isEqual(
          report.request.rowGroupings,
          formState.defaultValues?.request?.rowGroupings
        ) ||
        !isEqual(
          report.request.orderBy,
          formState.defaultValues?.request?.orderBy
        ) ||
        !isEqual(
          report.request.columnGroupings,
          formState.defaultValues?.request?.columnGroupings
        ) ||
        !isEqual(
          report.request.aggregations,
          formState.defaultValues?.request?.aggregations
        )
      ) {
        await debouncedUpsertReportConfig(report);
      }
    },
    [
      debouncedUpsertReportConfig,
      disabled,
      formState.defaultValues?.request?.aggregations,
      formState.defaultValues?.request?.columnGroupings,
      formState.defaultValues?.request?.orderBy,
      formState.defaultValues?.request?.rowGroupings,
    ]
  );

  useEffect(() => {
    handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy, metrics]);

  const groupingOptions = useMemo(() => {
    if (reportType === ReportTypesV2.InventoryV2) {
      return LISTING_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID;
    } else {
      return SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID;
    }
  }, [reportType]);

  const valueOptions = useMemo(() => {
    if (reportType === ReportTypesV2.InventoryV2) {
      return LISTING_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID;
    } else {
      return SALES_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID;
    }
  }, [reportType]);

  const allOptions = useMemo(() => {
    const groupingOptionsSortedByContent = Object.entries(groupingOptions)
      .sort(([, a], [, b]) =>
        getContent(a, contentContext).localeCompare(
          getContent(b, contentContext)
        )
      )
      .map(([key, value]) => key);

    const valueOptionsSortedByContent = Object.entries(valueOptions)
      .sort(([, a], [, b]) =>
        getContent(a, contentContext).localeCompare(
          getContent(b, contentContext)
        )
      )
      .map(([key, value]) => key);

    return [...groupingOptionsSortedByContent, ...valueOptionsSortedByContent];
  }, [contentContext, groupingOptions, valueOptions]);

  const [items, setItems] = useState<Record<string, string[]>>({
    [ROWS_INPUT_KEY]: [],
    [VALUES_INPUT_KEY]: [],
    [OPTIONS_LIST_KEY]: [],
  });

  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    setItems({
      [ROWS_INPUT_KEY]: (groupBy || []).map((c: ReportColumn) => c.columnName),
      [VALUES_INPUT_KEY]: (metrics || []).map(
        (c: ReportColumn) => c.columnName
      ),
      [OPTIONS_LIST_KEY]: allOptions.filter(
        (columnName) =>
          !(
            (groupBy || []).some(
              (g: ReportColumn) => g.columnName === columnName
            ) ||
            (metrics || []).some(
              (m: ReportColumn) => m.columnName === columnName
            )
          )
      ),
    });
  }, [metrics, groupBy, allOptions]);

  const findContainerKey = useCallback(
    (overId: string) => {
      if (items[ROWS_INPUT_KEY].includes(overId) || overId === ROWS_INPUT_KEY) {
        return ROWS_INPUT_KEY;
      }
      if (
        items[VALUES_INPUT_KEY].includes(overId) ||
        overId === VALUES_INPUT_KEY
      ) {
        return VALUES_INPUT_KEY;
      }
      if (
        items[OPTIONS_LIST_KEY].includes(overId) ||
        overId === OPTIONS_LIST_KEY
      ) {
        return OPTIONS_LIST_KEY;
      }
      return null;
    },
    [items]
  );

  const moveOver = (activeId: string, overId: string) => {
    const overContainerKey = findContainerKey(overId);
    if (!overContainerKey) {
      return;
    }
    const activeContainerKey = findContainerKey(activeId);
    if (!activeContainerKey) {
      return;
    }

    // Stop-gap to prevent moving grouping options to values and vice versa
    if (activeContainerKey !== overContainerKey) {
      if (
        overContainerKey === ROWS_INPUT_KEY &&
        !Object.keys(groupingOptions).includes(activeId)
      ) {
        return; // Prevent moving non-grouping options to rows
      }

      if (
        overContainerKey === VALUES_INPUT_KEY &&
        !Object.keys(valueOptions).includes(activeId)
      ) {
        return; // Prevent moving non-value options to values
      }
    }

    const newItems = { ...items };
    const activeIndex = newItems[activeContainerKey].indexOf(activeId);
    const activeItems = newItems[activeContainerKey].filter(
      (item) => item !== activeId
    );
    newItems[activeContainerKey] = activeItems;

    let overIndex = newItems[overContainerKey].indexOf(overId);
    if (overIndex === -1) {
      // If overId is the container itself, set it to the end of the list
      overIndex = newItems[overContainerKey].length;
    }

    // Move down the overIndex if the active item is before it in the same container
    if (activeIndex <= overIndex && activeContainerKey === overContainerKey) {
      overIndex++;
    }

    const overItems = [
      ...newItems[overContainerKey].slice(0, overIndex),
      activeId,
      ...newItems[overContainerKey].slice(overIndex),
    ];
    newItems[overContainerKey] = overItems;

    setItems(newItems);
  };

  const recalculateReportColumns = useCallback(() => {
    const newGroupBy = items[ROWS_INPUT_KEY].map((columnName) => ({
      columnName,
      columnValue: columnName, // Assume columnValue is the same as columnName
    }));

    const newSortBy = items[ROWS_INPUT_KEY][0];
    if (sortBy !== newSortBy) {
      setValue(sortByField, newSortBy);
    }

    if (!isEqual(newGroupBy, groupBy)) {
      setValue(groupbyField, newGroupBy);
    }

    const newMetrics = items[VALUES_INPUT_KEY].map((columnName) => {
      const columnValue =
        columnName.toLocaleLowerCase() ===
        SalesReportTableValueColumnId.Margin.toLocaleLowerCase()
          ? columnName
          : `SUM(${columnName})`;
      return { columnName, columnValue };
    });

    if (!isEqual(newMetrics, metrics)) {
      setValue(metricsField, newMetrics);
    }
  }, [items, sortBy, groupBy, metrics, setValue]);

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!active || !over) {
      return;
    }

    const activeId = active.id as string;
    const overId = over.id as string;

    // Prevent dragging over itself
    if (activeId === overId) {
      return;
    }

    moveOver(activeId, overId);
  };

  const onDragEnd = (event: DragOverEvent) => {
    recalculateReportColumns();
    setActiveId(null);
  };

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    if (!active) {
      return;
    }

    const activeId = active.id as string;
    setActiveId(activeId);
  };

  return (
    <DndContext
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      collisionDetection={closestCorners}
    >
      <Stack direction="row" gap="s" style={{ overflowY: 'auto' }}>
        <div className={styles.pivotGridContainer}>
          <Stack className={styles.pivotGridItem} direction="column">
            <PosFormField
              errors={metricsError}
              label={
                <span className={shared.typography.body2}>
                  <Content id={ContentId.RowsGroupBy} />
                </span>
              }
            />
            <div className={styles.pivotGridItemInner}>
              <MetricsInputDroppable
                id={ROWS_INPUT_KEY}
                valueOptionsContent={groupingOptions}
                selectedValues={items[ROWS_INPUT_KEY]}
                placeholderContentId={ContentId.AddGroup}
              />
            </div>
          </Stack>
          <Stack className={styles.pivotGridItem} direction="column">
            <PosFormField
              label={
                <span className={shared.typography.body2}>
                  <Content id={ContentId.Values} />
                </span>
              }
            />
            <div className={styles.pivotGridItemInner}>
              <MetricsInputDroppable
                id={VALUES_INPUT_KEY}
                valueOptionsContent={valueOptions}
                selectedValues={items[VALUES_INPUT_KEY]}
                placeholderContentId={ContentId.AddValues}
              />
            </div>
          </Stack>
        </div>
        <MetricsOptionsList
          id={OPTIONS_LIST_KEY}
          availableValues={items[OPTIONS_LIST_KEY]}
          valueOptionsContent={valueOptions}
          groupByOptionsContent={groupingOptions}
        />
      </Stack>
      <DragOverlay>
        {activeId ? (
          <MetricsOptionsListItem
            key={`active-${activeId}`}
            id={activeId}
            disabled={disabled}
            isDragging={true}
          >
            <Content
              id={
                (valueOptions as Record<string, ContentId>)[activeId] ||
                (groupingOptions as Record<string, ContentId>)[activeId]
              }
            />
          </MetricsOptionsListItem>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
