import { useCallback, useContext, useMemo } from 'react';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { AddToGroupDialog } from 'src/dialogs/BulkEdits/AddToGroupDialog';
import { AddToGroupListingsModal } from 'src/modals/GroupListings';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData, ListingGroup } from 'src/WebApiController';

export const LaunchAddToGroup = ({
  eventData,
  disabled,
}: {
  eventData: EventWithData;
  disabled?: boolean;
}) => {
  const { setModal } = useContext(ModalContext);

  const {
    openDialog: openAddToGroupDialog,
    closeDialog: closeAddToGroupDialog,
  } = useDialog(DialogId.AddToGroup, AddToGroupDialog);

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(
    eventData.event
  );

  const selectedListingIds = useMemo(
    () => filterQueryWithEventIds.entityIds ?? [],
    [filterQueryWithEventIds]
  );

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const filteredListings = eventData?.entities?.listings?.filter(
        (l) => l.isLtGrp && l.ltGrpId != null
      ) as ListingGroup[];

      const listingGroups =
        filteredListings.reduce(
          (acc, curr: ListingGroup) => {
            acc[curr.ltGrpId!] = curr.groupName;
            return acc;
          },
          {} as Record<string, string>
        ) ?? {};

      openAddToGroupDialog({
        groupOptions: listingGroups,
        onOkay: (groupId) => {
          closeAddToGroupDialog();
          setModal({
            children: (
              <AddToGroupListingsModal
                selectedListingIds={selectedListingIds}
                listingGroupId={groupId ?? undefined}
                viagVirtualId={eventData.event.viagVirtualId}
              />
            ),
            clickOutsideToClose: true,
            ...{ size: 'slide-in' },
          });
        },
        onCancel: () => {
          closeAddToGroupDialog();
        },
      });
    },
    [
      closeAddToGroupDialog,
      eventData.event.viagVirtualId,
      eventData?.entities?.listings,
      openAddToGroupDialog,
      selectedListingIds,
      setModal,
    ]
  );

  return (
    <PosDropdownItem
      key="AddToGroup"
      onClick={onLaunchDialog}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.AddToGroup} />
      </Stack>
    </PosDropdownItem>
  );
};
