import { Content } from 'src/contexts/ContentContext';
import { DetailSection } from 'src/modals/common';
import { AutoSourceTickets } from 'src/modals/SaleDetails/components/AutoSourceTickets';
import { useShouldHideForWastedSale } from 'src/modals/SaleDetails/hooks/useShouldHideForWastedSale';
import { AllocatedListing } from 'src/modals/SaleDetailsV2/components/SaleTickets/AllocatedListing';
import { OriginalListing } from 'src/modals/SaleDetailsV2/components/SaleTickets/OriginalListing';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

interface SeatsSoldAsProps {
  sale: SaleDetails | undefined | null;
}

export const SaleTicketsSection = ({ sale }: SeatsSoldAsProps) => {
  const hideForWastedSale = useShouldHideForWastedSale(sale);

  if (!sale) {
    return null;
  }

  return (
    <DetailSection name={<Content id={ContentId.Tickets} />}>
      {!hideForWastedSale && sale.tickets.length > 0 && (
        <AllocatedListing sale={sale} />
      )}

      <OriginalListing sale={sale} />
      <AutoSourceTickets isSeatSaver={sale?.isSeatSaver} />
    </DetailSection>
  );
};
