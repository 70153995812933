import clsx from 'clsx';
import { cloneElement, PropsWithChildren, useMemo } from 'react';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { ReportFilterEditabilityDropdown } from 'src/components/FilterToolbar/FilterDialogV2/FiltersList/ReportFilterEditabilityDropdown';
import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack, StackProps } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { ScrollAreaRefActions } from 'src/core/ui/ScrollArea/ScrollArea';
import { CrossIcon } from 'src/svgs/Viagogo';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

import {
  buttonsContainer,
  buttonsContainerRow,
  filterContainer,
  filterContainerHorizontal,
  filterItemLabel,
  filterItemLabelVertical,
  filterItemValueVertical,
  filterItemWrapper,
  filterToolbarListContainerVertical,
  filterValue,
} from './FiltersList.css';

export interface FiltersListProp {
  selectedDropdownItems: FilterDropdownItem[];
  filters: FilterToolbarGroup[];
  onFilterRemoved: (filterDropdownItem: FilterDropdownItem) => void;
  mandatoryFiltersToShow: FilterToolbarItemId[];
  filtersListHeader?: React.ReactNode;
  performerVenueFilter?: React.ReactNode;
  resetButton?: React.ReactNode;
  addFilterButton?: React.ReactNode;
  onEditabilityChange?: (
    filterId: FilterToolbarItemId | FilterToolbarItemId[],
    editability: ReportFilterEditability
  ) => void;
  direction?: 'row' | 'column';
  flexWrap?: StackProps['flexWrap'];
  // Used for reporting currently. Display filters according to `reportFilterEditability`
  viewerMode?: boolean;
  disabled?: boolean;
}

export const FiltersList = ({
  filters,
  onFilterRemoved,
  mandatoryFiltersToShow,
  selectedDropdownItems,
  performerVenueFilter,
  filtersListHeader,
  resetButton,
  addFilterButton,
  scrollAreaRefActions,
  onEditabilityChange,
  direction = 'row',
  flexWrap,
  viewerMode,
  disabled,
}: FiltersListProp & ScrollAreaRefActions) => {
  const filterItemsToShow = useMemo(() => {
    return selectedDropdownItems
      .map((dropdownItem) => {
        const group = filters.find((filterToolbarGroup: FilterToolbarGroup) => {
          return (
            filterToolbarGroup.titleContentId === dropdownItem.titleContentId
          );
        });

        if (group) {
          const item = group.items.find(
            (item) => item.filterId === dropdownItem.filterId
          );
          if (item) {
            return {
              group,
              item,
            };
          }
        }
        return null;
      })
      .filter(
        (filterItem) =>
          !!filterItem &&
          (viewerMode
            ? (filterItem.item.reportFilterEditability ??
                DEFAULT_REPORT_FILTER_EDITABILITY) !==
              ReportFilterEditability.Hidden
            : true)
      );
  }, [filters, selectedDropdownItems, viewerMode]);

  return (
    <FiltersListLayout
      direction={direction}
      addFilterButton={<>{addFilterButton && <div>{addFilterButton}</div>}</>}
      resetButton={
        <>
          {resetButton &&
            (performerVenueFilter != null || filterItemsToShow.length > 0) && (
              <div>{resetButton}</div>
            )}
        </>
      }
      scrollAreaRefActions={scrollAreaRefActions}
      filtersListHeader={filtersListHeader}
      flexWrap={flexWrap}
    >
      {Boolean(filterItemsToShow?.length) && (
        <Stack
          gap="l"
          direction={direction}
          className={clsx({
            [filterToolbarListContainerVertical]: direction === 'column',
          })}
          flexWrap={flexWrap}
        >
          {performerVenueFilter != null && performerVenueFilter}
          {filterItemsToShow.map((item, i) => (
            <>
              {item && (
                <FilterToolbarListItem
                  filtersListDirection={direction}
                  item={item.item}
                  key={`fi-embedded-${i}`}
                  onFilterRemoved={onFilterRemoved}
                  mandatoryFiltersToShow={mandatoryFiltersToShow}
                  selectedDropdownItems={selectedDropdownItems}
                  onEditabilityChange={onEditabilityChange}
                  viewerMode={viewerMode}
                  disabled={disabled}
                />
              )}
            </>
          ))}
        </Stack>
      )}
    </FiltersListLayout>
  );
};

const FiltersListLayout = ({
  direction,
  children,
  addFilterButton,
  resetButton,
  scrollAreaRefActions,
  filtersListHeader,
  flexWrap,
}: PropsWithChildren<{
  filtersListHeader: FiltersListProp['filtersListHeader'];
  direction: FiltersListProp['direction'];
  addFilterButton: FiltersListProp['addFilterButton'];
  resetButton: FiltersListProp['resetButton'];
  scrollAreaRefActions: ScrollAreaRefActions['scrollAreaRefActions'];
  flexWrap?: StackProps['flexWrap'];
}>) => {
  if (direction === 'column') {
    return (
      <Stack
        className={filterContainer}
        direction="column"
        gap="m"
        alignItems="start"
        width="full"
      >
        <Stack
          direction="row"
          justifyContent="spaceBetween"
          width="full"
          alignItems="center"
        >
          {filtersListHeader}
          <Stack className={clsx(buttonsContainer)} gap="m" width="fit">
            {resetButton}
            {addFilterButton}
          </Stack>
        </Stack>
        {children}
      </Stack>
    );
  }

  if (flexWrap === 'wrap') {
    return (
      <Stack
        className={filterContainerHorizontal}
        direction="row"
        gap="l"
        alignItems="center"
        flexWrap="wrap"
      >
        {children}
        {addFilterButton}
        {resetButton}
      </Stack>
    );
  }

  return (
    <Stack
      className={filterContainerHorizontal}
      direction="row"
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
    >
      {children ? (
        <ScrollableToolbar scrollAreaRefActions={scrollAreaRefActions}>
          {children}
        </ScrollableToolbar>
      ) : null}
      <Stack
        className={clsx(buttonsContainer, buttonsContainerRow)}
        gap="m"
        alignItems="center"
        justifyContent="spaceBetween"
      >
        {addFilterButton}
        {resetButton}
      </Stack>
    </Stack>
  );
};

const FilterToolbarListItem = ({
  item,
  filtersListDirection,
  onFilterRemoved,
  mandatoryFiltersToShow,
  selectedDropdownItems,
  onEditabilityChange,
  viewerMode,
  disabled,
}: {
  item: FilterToolbarItem;
  filtersListDirection: FiltersListProp['direction'];
  selectedDropdownItems: FiltersListProp['selectedDropdownItems'];
  mandatoryFiltersToShow: FiltersListProp['mandatoryFiltersToShow'];
  onFilterRemoved: FiltersListProp['onFilterRemoved'];
  onEditabilityChange: FiltersListProp['onEditabilityChange'];
  viewerMode: FiltersListProp['viewerMode'];
  disabled: FiltersListProp['disabled'];
}) => {
  const filterDisabled =
    viewerMode && item.reportFilterEditability !== ReportFilterEditability.Edit;

  const filterItem =
    disabled || filterDisabled
      ? cloneElement(item.filterItem, { disabled: true })
      : item.filterItem;

  const canBeRemoved =
    !viewerMode && !mandatoryFiltersToShow.includes(item.filterId);

  const onRemoveFilterHandler = () => {
    const filterDropdownItem = selectedDropdownItems.find(
      (filterDropdownItem) => filterDropdownItem.filterId === item.filterId
    );
    if (filterDropdownItem) {
      onFilterRemoved(filterDropdownItem);
    }
  };

  const contentText = useContent(item.labelContentId);

  const showReportFilterEditabilityDropdown =
    onEditabilityChange != null && !viewerMode;

  return (
    <Stack className={filterItemWrapper}>
      <Stack
        className={clsx(filterItemLabel, {
          [filterItemLabelVertical]: filtersListDirection === 'column',
        })}
        alignItems="center"
        gap="m"
        justifyContent="spaceBetween"
        title={contentText}
      >
        <span>
          <Content id={item.labelContentId} />
        </span>

        {canBeRemoved && (
          <CrossIcon
            onClick={onRemoveFilterHandler}
            withHoverEffect
            size={vars.iconSize.s}
          />
        )}
      </Stack>
      <Stack
        gap="s"
        direction="row"
        alignItems="center"
        className={clsx({
          [filterItemValueVertical]: filtersListDirection === 'column',
        })}
      >
        <Stack gap="s" width="full" className={filterValue}>
          {filterItem}
        </Stack>
      </Stack>

      {showReportFilterEditabilityDropdown && (
        <ReportFilterEditabilityDropdown
          filterId={item.filterId}
          reportFilterEditability={item.reportFilterEditability}
          onEditabilityChange={onEditabilityChange}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
