import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { CopyIcon, DiskSaveIcon } from 'src/svgs';
import { DeleteIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SearchResult } from 'src/WebApiController';

import { EntitySearchConfigDialog } from '../EntitySearchConfigDialog';

export const EntitySearchConfigActions = <T extends SearchResult>({
  activeSearchConfig,
  disabled,
  onSaveSearchResult,
  onDeleteSearchResult,
}: {
  activeSearchConfig?: T;
  disabled?: boolean;
  onSaveSearchResult: (searchResult: T) => void;
  onDeleteSearchResult: (searchResult: T) => void;
}) => {
  const { loginContext } = useAppContext();
  const saveSearchResultDlg = useBasicDialog();
  const deleteConfirmDlg = useBasicDialog();

  const isNotOwned =
    activeSearchConfig?.ownerUserId &&
    activeSearchConfig.ownerUserId !== loginContext?.user?.userId;

  const saveCopyText = useContent(ContentId.SaveCopy);
  const saveText = useContent(ContentId.SaveSearch);
  const deleteText = useContent(ContentId.Delete);

  return (
    <Stack gap="m" alignItems="center">
      <Button
        variant="textPlain"
        size="unset"
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          saveSearchResultDlg.launchDialog();
        }}
      >
        {isNotOwned ? (
          <CopyIcon
            size={vars.iconSize.s}
            withHoverEffect
            title={saveCopyText}
          />
        ) : (
          <DiskSaveIcon
            size={vars.iconSize.s}
            withHoverEffect
            title={saveText}
            fill={vars.color.textPrimary}
          />
        )}
      </Button>

      {activeSearchConfig?.id && !isNotOwned && (
        <Button
          variant="textPlain"
          size="unset"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            deleteConfirmDlg.launchDialog();
          }}
        >
          <DeleteIcon
            size={vars.iconSize.s}
            withHoverEffect
            title={deleteText}
          />
        </Button>
      )}

      <EntitySearchConfigDialog
        searchConfig={activeSearchConfig}
        onSave={(r) => {
          onSaveSearchResult(r);
          saveSearchResultDlg.closeDialog();
        }}
        {...saveSearchResultDlg.dialogProps}
        onClosed={saveSearchResultDlg.closeDialog}
      />
      <ConfirmDialog
        {...deleteConfirmDlg.dialogProps}
        size={'m'}
        headerText={<Content id={ContentId.AreYouSure} />}
        bodyText={
          <Stack gap="xl">
            <Content id={ContentId.DeleteSearchResultConfirm} />
            <b>{activeSearchConfig?.name}</b>
          </Stack>
        }
        onOkay={() => {
          if (activeSearchConfig?.id) {
            onDeleteSearchResult(activeSearchConfig);
          }
          deleteConfirmDlg.closeDialog();
        }}
        onCancel={deleteConfirmDlg.closeDialog}
      />
    </Stack>
  );
};
