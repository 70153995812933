import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { CompListingsPreview } from 'src/components/Listings/CompListingsPreview';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { Event, Listing } from 'src/WebApiController';

import * as styles from './BulkEditAutoPricingSettingsSidePanel.css';
import {
  useBulkEditAutoPricingSettingsSidePanelEventMapProps,
  useBulkGetListingPriceCalculationsInEvent,
} from './BulkEditAutoPricingSettingsSidePanel.hooks';

export const BulkEditAutoPricingSettingsSidePanel = ({
  event,
  selectedListings,
  show,
}: {
  event: Event;
  selectedListings: Listing[];
  show?: boolean;
}) => {
  const { bulkListingPriceCalculationResult, isLoading } =
    useBulkGetListingPriceCalculationsInEvent(event, selectedListings);

  const eventVenueMapProps =
    useBulkEditAutoPricingSettingsSidePanelEventMapProps(
      event.viagId!,
      selectedListings,
      bulkListingPriceCalculationResult?.compListings
    );

  if (!show) {
    return null;
  }

  return (
    <Stack direction="column" className={styles.mainContainerFull}>
      <Stack gap={'xl'} className={styles.sectionContainer}>
        <div className={styles.mapContainerFull}>
          <EventVenueMap
            {...eventVenueMapProps}
            infoIconContent={<Content id={ContentId.AutoPricingDisclaimer} />}
          />
        </div>
        <Stack direction="column" className={styles.compsContainerFull}>
          <Stack className={styles.compsContainerHeader}>
            <FormatContent
              id={FormatContentId.MarketplaceListings}
              params={[selectedListings.length.toString()]}
            />
          </Stack>
          <CompListingsPreview
            isLoading={isLoading}
            listings={selectedListings}
            listingPriceCalculations={
              bulkListingPriceCalculationResult?.listingPriceCalculations ?? []
            }
            compListings={bulkListingPriceCalculationResult?.compListings ?? []}
            sellerCompListings={
              bulkListingPriceCalculationResult?.sellerCompListings ?? []
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
