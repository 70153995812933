import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useContent } from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType } from 'src/WebApiController';

import { TagValueFilterInput } from './TagValueFilterInput';

export type TagInfoFilter = {
  tagName: string | null;
  tagValue: string | null;
};

export const useTagFilters = <TFilter extends TagInfoFilter>(
  query: TFilter,
  setQuery: (query: TFilter) => void,
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale
): FilterToolbarGroup => {
  const { tagsMetadata: existingTagsMetadata = [] } =
    useTagsForEntityType(entityType);

  const noTagsMsg = useContent(ContentId.NoTagsExist);

  const selectedTagMetadata = useMemo(() => {
    return (existingTagsMetadata ?? []).find((r) => r.key === query.tagName);
  }, [existingTagsMetadata, query.tagName]);

  const keyOptionsContent = useMemo(() => {
    const keyOptions = (existingTagsMetadata ?? [])
      .sort((r1, r2) => r1.key.localeCompare(r2.key))
      .reduce(
        (re, r) => {
          re[r.key] = r.key;
          return re;
        },
        {} as Record<string, string>
      );

    return keyOptions ?? {};
  }, [existingTagsMetadata]);

  const noTagsExist = useMemo(() => {
    return (existingTagsMetadata ?? []).length === 0;
  }, [existingTagsMetadata]);

  const titleContentId =
    entityType === ActionOutboxEntityType.Listing
      ? ContentId.InventoryTag
      : entityType === ActionOutboxEntityType.Sale
      ? ContentId.SaleTag
      : ContentId.PurchaseTag;

  return {
    titleContentId: titleContentId,
    type: 'row',
    items: [
      {
        filterId: 'tagName' as const,
        labelContentId: ContentId.Name,
        filterQueryKeys: ['tagName'] as FilterToolbarItemId[],
        filterItem: (
          <PosSelect
            style={{ width: '100%' }}
            onChange={(newValue: string) => {
              if (query.tagName !== newValue) {
                setQuery({
                  ...query,
                  tagName: newValue,
                  tagValue: null,
                });
              }
            }}
            value={query.tagName ?? ''}
            displayText={noTagsExist ? noTagsMsg : query.tagName ?? ''}
            searchable
            disabled={noTagsExist}
            valueOptionsContent={keyOptionsContent}
            placeholderText={ContentId.None}
            enableEmptySelection
            shape="rectFull"
          />
        ),
      },
      {
        filterId: 'tagValue' as const,
        labelContentId: ContentId.Value,
        filterQueryKeys: ['tagValue'] as FilterToolbarItemId[],
        filterItem: (
          <TagValueFilterInput
            valueType={selectedTagMetadata?.valueType}
            disabled={selectedTagMetadata == null || noTagsExist}
            value={query.tagValue ?? ''}
            onChange={(newValue) => {
              if (query.tagValue !== newValue) {
                setQuery({
                  ...query,
                  tagValue: newValue,
                });
              }
            }}
          />
        ),
      },
    ].filter((f) => f!),
  };
};
