import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ReactAppContext } from 'src/WebApiController';

export const initSentry = (appContext: ReactAppContext) => {
  Sentry.init({
    dsn: 'https://530f5d7e8881f403994c55895d5b1248@o4507805351280640.ingest.us.sentry.io/4508122587529216',
    environment: appContext?.environment || 'unknown-env', // DEV, QA, BETA, PROD,
    release: appContext?.version || 'unknown', // UI release number
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.5,
    profilesSampleRate: 0.5,

    // Session Replay
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  });
};
