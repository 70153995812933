import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PosTableData } from 'src/dialogs/SectionRowTableDialog/components/SectionRowTable';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput } from 'src/WebApiController';

import { calculateTicketCostUnitCost, isPosField } from '../utils';

export type CellPosition = {
  rowIndex: number;
  colIndex: number;
};

export type CellsSelection = {
  start: CellPosition;
  end: CellPosition;
};

export const useSpreadsheetSelection = <T extends PosTableData>({
  columns,
  nonInteractiveColumns,
}: {
  columns: (ColumnDef<T, unknown> & { id: keyof T })[];
  nonInteractiveColumns?: string[];
}) => {
  const [selectedCells, setSelectedCells] = useState<CellsSelection | null>(
    null
  );
  const [isAnchorSelected, setIsAnchorSelected] = useState(false);
  const [anchorDragged, setAnchorDragged] = useState(false);
  const lastEnteredCell = useRef<CellPosition | null>(null);
  const { getValues, setValue } = useFormContext<PurchaseTicketsInput>();

  const isShiftPressedRef = useRef(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        isShiftPressedRef.current = true;
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        isShiftPressedRef.current = false;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const handleMouseUp = () => {
      if (!isAnchorSelected || !selectedCells) {
        return;
      }
      const { rowIndex: startRow, colIndex: startCol } = selectedCells.start;
      const { rowIndex: endRow, colIndex: endCol } = selectedCells.end;

      if (endRow > startRow) {
        const currentData = getValues('ticketGroups');
        const colIds = columns.map((col) => col.id as keyof TicketGroupInput);

        for (let row = startRow + 1; row <= endRow; row++) {
          for (let col = Math.min(startCol, endCol); col <= endCol; col++) {
            const columnId = columns[col].id as keyof TicketGroupInput;
            if (nonInteractiveColumns?.includes(columnId as string)) {
              continue;
            }

            const value = currentData[startRow][columnId];
            const pasteVerificationHandler =
              columns[col].meta?.pasteVerificationHandler;
            const valueIsPosField = isPosField(value);
            if (!valueIsPosField) {
              continue;
            }
            if (pasteVerificationHandler) {
              const isValidType = pasteVerificationHandler(value.value);
              if (!isValidType) {
                continue;
              }
            }

            // TODO: Move this logic out of this hook
            setValue(
              `ticketGroups.${row}.${columnId}`,
              posChangedField(value.value) as TicketGroupInput[typeof columnId]
            );

            calculateTicketCostUnitCost(
              currentData,
              setValue,
              row,
              colIds,
              columnId
            );
          }
        }
      }
      setIsAnchorSelected(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorDragged, isAnchorSelected, nonInteractiveColumns, selectedCells]);

  const handleMouseDown = useCallback(
    (rowIndex: number, colIndex: number) => {
      const colId = columns[colIndex].id;
      if (isAnchorSelected || nonInteractiveColumns?.includes(colId)) {
        return;
      }
      setAnchorDragged(false);
      setSelectedCells({
        start: { rowIndex, colIndex },
        end: { rowIndex, colIndex },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleMouseEnter = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (
        (!isShiftPressedRef.current && !isAnchorSelected) ||
        (lastEnteredCell?.current?.rowIndex == rowIndex &&
          lastEnteredCell?.current?.colIndex == colIndex)
      ) {
        return;
      }
      const colId = columns[colIndex].id;
      lastEnteredCell.current = { rowIndex, colIndex };
      if (isAnchorSelected && selectedCells) {
        const minCol = Math.min(
          selectedCells?.start.colIndex,
          selectedCells?.end.colIndex
        );
        const maxCol = Math.max(
          selectedCells?.start.colIndex,
          selectedCells?.end.colIndex
        );

        if (colIndex < minCol || colIndex > maxCol) {
          return;
        }

        setSelectedCells((prev) => {
          if (!prev) return null;
          return {
            start: prev.start,
            end: { rowIndex, colIndex: maxCol },
          };
        });
      } else {
        if (nonInteractiveColumns?.includes(colId)) {
          return;
        }
        setSelectedCells((prev) => {
          if (!prev) return null;
          return {
            start: prev.start,
            end: { rowIndex, colIndex },
          };
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCells, isAnchorSelected]
  );

  const lastCellPosition: CellPosition | null = useMemo(() => {
    if (!selectedCells) return null;
    return {
      rowIndex: Math.max(
        selectedCells.start.rowIndex,
        selectedCells.end.rowIndex
      ),
      colIndex: Math.max(
        selectedCells.start.colIndex,
        selectedCells.end.colIndex
      ),
    };
  }, [selectedCells]);

  return {
    selectedCells,
    setSelectedCells,
    handleMouseEnter,
    handleMouseDown,
    isAnchorSelected,
    setIsAnchorSelected,
    anchorDragged,
    setAnchorDragged,
    lastCellPosition,
  };
};
