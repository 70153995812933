import { useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

export const useGetNoSelectedItemsLabel = (): string => {
  const selectedText = useContent(ContentId.Selected);

  return useMemo(() => {
    return `0 ${selectedText}`.toLocaleLowerCase();
  }, [selectedText]);
};
