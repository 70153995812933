import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack, Switch } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import {
  OUTLIER_MODE_KTH_LOWEST_STRATEGY,
  OutlierModeKthLowestStrategy,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { STANDARD_DEVIATIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { posField } from 'src/utils/posFieldUtils';
import {
  AutoPricingOutlierMode,
  Feature,
  ListingDetailsAutoPricingSectionUpdatesV2,
} from 'src/WebApiController';

import { AutoPricingSettingsInput } from '../../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../../useBulkAutoPricingSettings';
import { PricingSetting } from '../PricingSetting';
import { SettingsSubheader } from '../PricingSubheader';
import * as styles from './PricingAdvancedSettings.css';
type PricingAdvancedSettingsProps = {
  origin: Pick<
    AutoPricingSettingsInput,
    | 'outlierMode'
    | 'outlierKthLowestLimit'
    | 'outlierStandardDeviations'
    | 'outlierKthLowestLimitRelativeSpacing'
    | 'outlierKthLowestLimitAbsoluteSpacing'
    | 'compListingExcludeDefects'
    | 'compListingExcludeFanInventory'
    | 'circuitBreakerMaxDiscountVelocityPercent'
    | 'circuitBreakerMaxDiscountVelocityTicksInHours'
  >;
  outlierModes: Record<string, ContentId>;
  outlierModesDisabled: Record<string, ContentId | undefined>;
};

export const PricingAdvancedSettings: React.FC<
  PricingAdvancedSettingsProps
> = ({ origin, outlierModes, outlierModesDisabled }) => {
  const {
    outlierMode,
    outlierKthLowestLimit,
    outlierStandardDeviations,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    onOutlierChange,
    onCircuitBreakerChange,
    onCompListingChange,
  } = useAutoPricingSettings();
  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const hasExcludeFanInventoryFeature = useUserHasAutopricingFeature(
    Feature.ExcludeFanInventory
  );
  const hasBulkEditPricingSettingsUpdateFeature = useUserHasAutopricingFeature(
    Feature.BulkEditPricingSettingsUpdate
  );

  const [outlierKthLowestStrategy, setKthLowestOutlierStrategy] = useState<
    OutlierModeKthLowestStrategy | undefined
  >(OutlierModeKthLowestStrategy.Relative);

  const outlierModeHasChange = useMemo(
    () => outlierMode?.value !== origin.outlierMode.value,
    [outlierMode?.value, origin.outlierMode.value]
  );
  const onResetOutlierMode = useCallback(() => {
    setValue('outlierMode', posField(origin.outlierMode.value));
    setValue(
      'outlierStandardDeviations',
      posField(origin.outlierStandardDeviations.value)
    );
    setValue(
      'outlierKthLowestLimit',
      posField(origin.outlierKthLowestLimit.value)
    );
    setValue(
      'outlierKthLowestLimitRelativeSpacing',
      posField(origin.outlierKthLowestLimitRelativeSpacing.value)
    );
    setValue(
      'outlierKthLowestLimitAbsoluteSpacing',
      posField(origin.outlierKthLowestLimitAbsoluteSpacing.value)
    );
  }, [
    origin.outlierKthLowestLimit.value,
    origin.outlierKthLowestLimitAbsoluteSpacing.value,
    origin.outlierKthLowestLimitRelativeSpacing.value,
    origin.outlierMode.value,
    origin.outlierStandardDeviations.value,
    setValue,
  ]);

  const standardDeviationsHasChange = useMemo(
    () =>
      origin.outlierStandardDeviations.value !==
      (outlierStandardDeviations?.value ?? null),
    [origin.outlierStandardDeviations?.value, outlierStandardDeviations?.value]
  );
  const onResetStandardDeviations = useCallback(() => {
    setValue('outlierMode', posField(origin.outlierMode.value));
    setValue(
      'outlierStandardDeviations',
      posField(origin.outlierStandardDeviations.value)
    );
  }, [
    origin.outlierMode.value,
    origin.outlierStandardDeviations.value,
    setValue,
  ]);

  const kthLowestOutlierHasChange = useMemo(
    () =>
      origin.outlierKthLowestLimit.value !== outlierKthLowestLimit?.value ||
      origin.outlierKthLowestLimitRelativeSpacing.value !==
        outlierKthLowestLimitRelativeSpacing?.value ||
      origin.outlierKthLowestLimitAbsoluteSpacing.value !==
        outlierKthLowestLimitAbsoluteSpacing?.value,
    [
      origin.outlierKthLowestLimit.value,
      origin.outlierKthLowestLimitAbsoluteSpacing.value,
      origin.outlierKthLowestLimitRelativeSpacing.value,
      outlierKthLowestLimit?.value,
      outlierKthLowestLimitAbsoluteSpacing?.value,
      outlierKthLowestLimitRelativeSpacing?.value,
    ]
  );
  const onResetKthLowestOutlier = useCallback(() => {
    setValue(
      'outlierKthLowestLimit',
      posField(origin.outlierKthLowestLimit.value)
    );
    setValue(
      'outlierKthLowestLimitRelativeSpacing',
      posField(origin.outlierKthLowestLimitRelativeSpacing.value)
    );
    setValue(
      'outlierKthLowestLimitAbsoluteSpacing',
      posField(origin.outlierKthLowestLimitAbsoluteSpacing.value)
    );
  }, [
    origin.outlierKthLowestLimit.value,
    origin.outlierKthLowestLimitAbsoluteSpacing.value,
    origin.outlierKthLowestLimitRelativeSpacing.value,
    setValue,
  ]);

  const circuitBreakerHasChange = useMemo(
    () =>
      origin.circuitBreakerMaxDiscountVelocityPercent.value !==
        circuitBreakerMaxDiscountVelocityPercent?.value ||
      origin.circuitBreakerMaxDiscountVelocityTicksInHours.value !==
        circuitBreakerMaxDiscountVelocityTicksInHours?.value,
    [
      circuitBreakerMaxDiscountVelocityPercent?.value,
      circuitBreakerMaxDiscountVelocityTicksInHours?.value,
      origin.circuitBreakerMaxDiscountVelocityPercent.value,
      origin.circuitBreakerMaxDiscountVelocityTicksInHours.value,
    ]
  );
  const onResetCircuitBreaker = useCallback(() => {
    setValue(
      'circuitBreakerMaxDiscountVelocityPercent',
      posField(origin.circuitBreakerMaxDiscountVelocityPercent.value)
    );
    setValue(
      'circuitBreakerMaxDiscountVelocityTicksInHours',
      posField(origin.circuitBreakerMaxDiscountVelocityTicksInHours.value)
    );
  }, [
    origin.circuitBreakerMaxDiscountVelocityPercent.value,
    origin.circuitBreakerMaxDiscountVelocityTicksInHours.value,
    setValue,
  ]);

  return (
    <>
      <SettingsSubheader title={ContentId.AdvancedSettings} />
      {hasBulkEditPricingSettingsUpdateFeature && (
        <>
          {hasExcludeFanInventoryFeature && (
            <PricingSetting
              header={<Content id={ContentId.ExcludeFanInventory} />}
              detail={
                <Switch
                  checked={compListingExcludeFanInventory?.value ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    const compListingExcludeFanInventoryNew = isChecked;
                    if (
                      compListingExcludeFanInventoryNew !==
                      compListingExcludeFanInventory?.value
                    ) {
                      onCompListingChange({
                        compListingExcludeFanInventoryNew,
                      });
                    }
                  }}
                />
              }
              hasChange={
                compListingExcludeFanInventory?.value !==
                origin.compListingExcludeFanInventory.value
              }
              hasConflict={origin.compListingExcludeFanInventory?.hasConflict}
              onReset={() =>
                setValue(
                  'compListingExcludeFanInventory',
                  posField(origin.compListingExcludeFanInventory.value)
                )
              }
            />
          )}
          <PricingSetting
            header={<Content id={ContentId.IncludeDefects} />}
            detail={
              <Switch
                checked={!(compListingExcludeDefects?.value ?? true)}
                onClick={(e) => e.stopPropagation()}
                onCheckedChange={(isChecked) => {
                  const compListingExcludeDefectsNew = !isChecked;
                  if (
                    compListingExcludeDefectsNew !==
                    compListingExcludeDefects?.value
                  ) {
                    onCompListingChange({ compListingExcludeDefectsNew });
                  }
                }}
              />
            }
            hasChange={
              compListingExcludeDefects?.value !==
              origin.compListingExcludeDefects.value
            }
            hasConflict={origin.compListingExcludeDefects?.hasConflict}
            onReset={() =>
              setValue(
                'compListingExcludeDefects',
                posField(origin.compListingExcludeDefects.value)
              )
            }
          />
        </>
      )}

      <PricingSetting
        header={<Content id={ContentId.OutlierMode} />}
        detail={
          <PosEnumSelect
            value={outlierMode?.value}
            valueOptionsContent={outlierModes}
            valueOptionsDisabled={outlierModesDisabled}
            enableEmptySelection={false}
            placeholderText={ContentId.SkipLogic}
            onClick={(e) => e.stopPropagation()}
            onChange={(v) => {
              onOutlierChange({
                outlierModeNew: v as AutoPricingOutlierMode,
              });
            }}
            style={{ width: '50%' }}
          />
        }
        hasChange={outlierModeHasChange}
        hasConflict={origin.outlierMode?.hasConflict}
        onReset={onResetOutlierMode}
      />
      {outlierMode?.value === AutoPricingOutlierMode.StandardDeviations ? (
        <PricingSetting
          header={<Content id={ContentId.OutlierFiltering} />}
          detail={
            <PosEnumSelect
              value={outlierStandardDeviations?.value?.toString()}
              valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
              enableEmptySelection={true}
              onClick={(e) => e.stopPropagation()}
              onChange={(v) => {
                const value = parseInt(v ?? '');
                if (value != null && !isNaN(value)) {
                  onOutlierChange({
                    outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
                    outlierStandardDeviationsNew: value,
                  });
                } else {
                  onOutlierChange({
                    outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
                    outlierStandardDeviationsNew: null,
                  });
                }
              }}
              style={{ width: '50%' }}
            />
          }
          hasChange={standardDeviationsHasChange}
          hasConflict={origin.outlierStandardDeviations?.hasConflict}
          onReset={onResetStandardDeviations}
        />
      ) : outlierMode?.value == AutoPricingOutlierMode.KthLowest ? (
        <PricingSetting
          header={<Content id={ContentId.IgnoreTheFirst} />}
          detail={
            <Stack gap="l" alignItems="center">
              <PosTextField
                value={outlierKthLowestLimit?.value ?? ''}
                type="number"
                min={0}
                max={100}
                rootProps={{
                  className: styles.inputInline,
                }}
                className={styles.inputInline}
                onChange={(e) => {
                  const v = parseInt(e.target.value);
                  onOutlierChange({
                    outlierModeNew: AutoPricingOutlierMode.KthLowest,
                    outlierKthLowestLimitNew: v,
                  });
                }}
              />
              <Content id={ContentId.MoreThan} />
              {outlierKthLowestStrategy ==
              OutlierModeKthLowestStrategy.Relative ? (
                <PosTextField
                  type="number"
                  min={-1}
                  max={100}
                  rootProps={{
                    className: styles.kthLowestInputField,
                  }}
                  className={styles.kthLowestInputField}
                  value={outlierKthLowestLimitRelativeSpacing?.value ?? ''}
                  onChange={(e) => {
                    let v = parseInt(e.target.value);
                    if (v < 0) {
                      v = 0;
                    } else if (v > 100) {
                      v = 100;
                    }

                    if (!isNaN(v)) {
                      onOutlierChange({
                        outlierModeNew: AutoPricingOutlierMode.KthLowest,
                        outlierKthLowestLimitNew: undefined,
                        outlierKthLowestLimitAbsoluteSpacingNew: null,
                        outlierKthLowestLimitRelativeSpacingNew: v,
                      });
                    }
                  }}
                />
              ) : (
                <PosTextField
                  type="number"
                  rootProps={{
                    className: styles.kthLowestInputField,
                  }}
                  className={styles.kthLowestInputField}
                  value={outlierKthLowestLimitAbsoluteSpacing?.value ?? ''}
                  onChange={(e) => {
                    const v = parseInt(e.target.value);
                    onOutlierChange({
                      outlierModeNew: AutoPricingOutlierMode.KthLowest,
                      outlierKthLowestLimitNew: undefined,
                      outlierKthLowestLimitAbsoluteSpacingNew: v,
                      outlierKthLowestLimitRelativeSpacingNew: null,
                    });
                  }}
                />
              )}
              <ToggleGroup
                options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
                value={
                  outlierKthLowestStrategy ??
                  OutlierModeKthLowestStrategy.Relative
                }
                onValueChange={(value) => {
                  if (!value) {
                    return;
                  }

                  const strat = value as OutlierModeKthLowestStrategy;
                  setKthLowestOutlierStrategy(strat);
                }}
              />
              <Content id={ContentId.TheNextCheapest} />
            </Stack>
          }
          hasChange={kthLowestOutlierHasChange}
          hasConflict={
            origin.outlierKthLowestLimitAbsoluteSpacing?.hasConflict ||
            origin.outlierKthLowestLimitRelativeSpacing?.hasConflict
          }
          onReset={onResetKthLowestOutlier}
        />
      ) : null}
      {!hasBulkEditPricingSettingsUpdateFeature && (
        <PricingSetting
          header={<Content id={ContentId.DonotDropMoreThan} />}
          detail={
            <Stack gap="m" alignItems="center">
              <PosTextField
                value={
                  circuitBreakerMaxDiscountVelocityPercent?.value != null
                    ? roundToPrecision(
                        circuitBreakerMaxDiscountVelocityPercent.value * 100,
                        2
                      )
                    : ''
                }
                type="number"
                min={0}
                max={100}
                postfixDisplay="%"
                rootProps={{
                  className: styles.inputInline,
                }}
                className={styles.inputInline}
                onChange={(e) => {
                  const v = parseFloat(e.target.value) / 100;
                  onCircuitBreakerChange(
                    undefined,
                    undefined,
                    v,
                    undefined,
                    undefined
                  );
                }}
              />
              <Content id={ContentId.MaxDiscountInputPrompt2} />
              <PosTextField
                type="number"
                min={1}
                max={24}
                rootProps={{
                  className: styles.inputInline,
                }}
                className={styles.inputInline}
                value={
                  circuitBreakerMaxDiscountVelocityTicksInHours?.value ?? ''
                }
                onChange={(e) => {
                  const v = parseInt(e.target.value);
                  onCircuitBreakerChange(
                    undefined,
                    undefined,
                    undefined,
                    v,
                    undefined
                  );
                }}
              />
              <Content id={ContentId.MaxDiscountInputPrompt3} />
            </Stack>
          }
          hasChange={circuitBreakerHasChange}
          hasConflict={
            origin.circuitBreakerMaxDiscountVelocityPercent?.hasConflict ||
            origin.circuitBreakerMaxDiscountVelocityTicksInHours?.hasConflict
          }
          onReset={onResetCircuitBreaker}
        />
      )}
    </>
  );
};
