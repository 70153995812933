import { createColumnHelper } from '@tanstack/react-table';
import { formatInTimeZone } from 'date-fns-tz';
import {
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { defaultColumnHelperAccessor } from 'src/tables/ReportTableCommon/DefaultColumnHelperAccessor';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { TableHeader } from 'src/tables/ReportTableCommon/TableHeader';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import { InHandCell } from 'src/tables/Table/TableCells';
import {
  SalesReportTableGroupingColumnId,
  SalesReportTableValueColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import {
  SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID,
  SALES_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { PointOfSaleSaleStatus, ReportValueType } from 'src/WebApiController';

const SECTION_TYPE = SectionType.SalesReport;

const columnHelper = createColumnHelper<ReportMetricsResponse | null>();

const accessorFn = (columnId: SalesReportTableGroupingColumnId) => {
  return (data: ReportMetricsResponse | null) =>
    data?.[columnId.toUpperCase()] ?? '';
};

const SALE_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG = {
  [SalesReportTableGroupingColumnId.SaleMonth]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.SaleMonth),
    {
      id: SalesReportTableGroupingColumnId.SaleMonth,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const dateValue = getValue<string | null | undefined>();

        return (
          <TableCellDiv align="left">
            <div>
              {/* Already converted to account timezone */}
              {dateValue
                ? formatInTimeZone(new Date(dateValue), 'UTC', 'MMMM yyyy')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.SaleWeek]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.SaleWeek),
    {
      id: SalesReportTableGroupingColumnId.SaleWeek,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const dateValue = getValue<string | null | undefined>();

        const dateEndOfWeek = dateValue ? new Date(dateValue) : null;
        if (dateEndOfWeek) {
          dateEndOfWeek.setDate(dateEndOfWeek.getDate() + 6);
        }

        return (
          <TableCellDiv align="left">
            <div>
              {/* Already converted to account timezone */}
              {dateValue && dateEndOfWeek
                ? formatInTimeZone(new Date(dateValue), 'UTC', 'yyyy/MM/dd') +
                  ' - ' +
                  formatInTimeZone(dateEndOfWeek, 'UTC', 'yyyy/MM/dd')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.SaleDay]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.SaleDay),
    {
      id: SalesReportTableGroupingColumnId.SaleDay,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const dateValue = getValue<string | null | undefined>();

        return (
          <TableCellDiv align="left">
            <div>
              {/* Already converted to account timezone */}
              {dateValue
                ? formatInTimeZone(new Date(dateValue), 'UTC', 'yyyy/MM/dd')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.EventId]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.EventId),
    {
      id: SalesReportTableGroupingColumnId.EventId,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                SalesReportTableGroupingColumnId.EventId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return <TextCell {...params} align={'left'} />;
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
          />
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.LocalEventDateTime]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.LocalEventDateTime),
    {
      id: SalesReportTableGroupingColumnId.LocalEventDateTime,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                SalesReportTableGroupingColumnId.LocalEventDateTime
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MMDD_Time_Day } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }
        const dateValue = value ? new Date(value).toISOString() : value;

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            enableUtcFallback
            hideRelativeTerms
            align="left"
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.SaleId]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.SaleId),
    {
      id: SalesReportTableGroupingColumnId.SaleId,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                SalesReportTableGroupingColumnId.SaleId
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleId = getValue<string>();

        if (!saleId) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }

        const url = getSaleDetailsRelativeUrl(saleId!);
        return (
          <LinkCell
            text={`#${saleId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [SalesReportTableGroupingColumnId.InHandDate]: columnHelper.accessor(
    accessorFn(SalesReportTableGroupingColumnId.InHandDate),
    {
      id: SalesReportTableGroupingColumnId.InHandDate,
      header: () => (
        <TableHeaderCellDiv align={'left'}>
          <Content
            id={
              SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
                SalesReportTableGroupingColumnId.InHandDate
              ]
            }
          />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | null>();
        if (!original) {
          return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
        }
        return (
          <TableCellDiv align="left">
            <InHandCell
              date={value ? new Date(value) : null}
              needsToFulfill={Boolean(
                original?.saleStatus &&
                  ![
                    PointOfSaleSaleStatus.ProcessingFulfillment,
                    PointOfSaleSaleStatus.Fulfilled,
                    PointOfSaleSaleStatus.CancelledHold,
                    PointOfSaleSaleStatus.Rejected,
                    PointOfSaleSaleStatus.PendingRejection,
                  ].includes(original?.saleStatus)
              )}
              handleFulfillmentState
            />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    }
  ),
} as const;

export const saleReportV2TableColumnDef = (
  columnId: string,
  valueType: ReportValueType | undefined
) => {
  const specialConfig = Object.entries(
    SALE_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG
  ).find(([key]) => key === columnId)?.[1];

  if (specialConfig) {
    return specialConfig;
  }

  const headerContentId =
    SALES_REPORT_TABLE_GROUPING_COLUMN_ID_TO_CONTENT_ID[
      columnId as SalesReportTableGroupingColumnId
    ] ??
    SALES_REPORT_TABLE_VALUE_COLUMN_ID_TO_CONTENT_ID[
      columnId as SalesReportTableValueColumnId
    ];

  return defaultColumnHelperAccessor(
    columnHelper,
    columnId,
    headerContentId,
    valueType
  );
};
