import { EntitySearchFilterBar } from 'src/components/EntitySearch';
import { FilterDropdownItem } from 'src/components/Filters';
import { useListingFilters } from 'src/components/MainFilterBar';
import { InventorySearchResult, ListingQuery } from 'src/WebApiController';

export const InventorySearchFilterBar = (props: {
  activeSearchConfig?: InventorySearchResult;
  onSubmitFilter?: (query: ListingQuery) => void;
  selectedFilters?: FilterDropdownItem[];
  setSelectedFilters: (sf: FilterDropdownItem[]) => void;
}) => {
  const { filters } = useListingFilters({
    isHorizontalLayout: true,
    showCatalogFilters: true,
  });

  return (
    <EntitySearchFilterBar
      {...props}
      filters={filters}
      mandatoryFiltersToShow={['eventDates']}
    />
  );
};
