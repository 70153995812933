import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  ReportMetricsResponse,
  ReportValueTypesReponse,
} from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';

import { saleReportV2TableColumnDef } from './configs/SaleReportTableColumnsConfigV2';

type SaleReportTableColumnDefContextType = {
  displayedColumnsConfig: ColumnDef<ReportMetricsResponse | null>[] | undefined;
};

const SaleReportTableColumnDefContext =
  createContext<SaleReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const SaleReportTableColumnDefContextProviderV2 = ({
  report,
  reportValueTypes,
  children,
}: PropsWithChildren<{
  report: ReportConfigV2;
  reportValueTypes: ReportValueTypesReponse | undefined;
}>) => {
  const displayedColumnsConfig = useMemo(() => {
    const columns = [
      ...(report.request.rowGroupings?.map((c) => c.columnName) ?? []),
      ...(report.request.aggregations?.map((c) => c.columnName) ?? []),
    ];

    return columns.map((columnId) =>
      saleReportV2TableColumnDef(
        columnId as SalesReportTableColumnId,
        reportValueTypes?.[columnId.toUpperCase()]
      )
    );
  }, [
    report.request.aggregations,
    report.request.rowGroupings,
    reportValueTypes,
  ]);

  return (
    <SaleReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: displayedColumnsConfig,
      }}
    >
      {children}
    </SaleReportTableColumnDefContext.Provider>
  );
};

export const useSaleReportTableColumnDefV2 = () =>
  useContext(SaleReportTableColumnDefContext);
