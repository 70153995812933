import { useCallback, useMemo } from 'react';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { CONTAINS_EQUALS_ENUM_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import { TextFilterEnum } from 'src/utils/eventQueryUtils';
import { EntityWithTicketsQuery } from 'src/WebApiController';

import { StackedFilter } from '../StackedFilter/StackedFilter';

export type TextFilterQuery = Pick<
  EntityWithTicketsQuery,
  | 'internalNotesContains'
  | 'internalNotesEquals'
  | 'internalNotesNotContains'
  | 'rowContains'
  | 'rowNotContains'
  | 'rowEquals'
  | 'sectionEquals'
  | 'sectionNotContains'
  | 'sectionContains'
>;

type KeysOfUnion<T> = T extends T ? keyof T : never;

export const TextFilter = <
  TQuery extends TextFilterQuery,
  TQueryKey extends KeysOfUnion<TextFilterQuery>,
>({
  query,
  setQuery,
  keys,
  isHorizontalLayout,
}: {
  query: TQuery;
  setQuery: (q: TQuery) => void;
  keys: {
    textContainsKey: TQueryKey;
    textEqualsKey: TQueryKey;
    textNotContainsKey: TQueryKey;
  };
  isHorizontalLayout?: boolean;
}) => {
  const { textContainsKey, textNotContainsKey, textEqualsKey } = keys;

  // Contains will win over Equals for defaults
  const mode = useMemo(
    () =>
      query[textContainsKey] ||
      (query[textNotContainsKey] == null && query[textEqualsKey] == null)
        ? TextFilterEnum.Contains
        : query[textNotContainsKey] ||
          (query[textContainsKey] == null && query[textEqualsKey] == null)
        ? TextFilterEnum.NotContains
        : TextFilterEnum.Equals,
    [query, textContainsKey, textEqualsKey, textNotContainsKey]
  );
  const filterText = useMemo(
    () =>
      mode === TextFilterEnum.Contains
        ? query[textContainsKey]
        : mode === TextFilterEnum.NotContains
        ? query[textNotContainsKey]
        : query[textEqualsKey],
    [mode, query, textContainsKey, textEqualsKey, textNotContainsKey]
  );

  const setRowFilter = useCallback(
    (mode: TextFilterEnum, text: string) => {
      if (mode === TextFilterEnum.Contains) {
        setQuery({
          ...query,
          [textContainsKey]: text,
          [textEqualsKey]: null,
          [textNotContainsKey]: null,
        });
      } else if (mode === TextFilterEnum.NotContains) {
        setQuery({
          ...query,
          [textNotContainsKey]: text,
          [textEqualsKey]: null,
          [textContainsKey]: null,
        });
      } else {
        setQuery({
          ...query,
          [textEqualsKey]: text,
          [textContainsKey]: null,
          [textNotContainsKey]: null,
        });
      }
    },
    [query, setQuery, textContainsKey, textEqualsKey, textNotContainsKey]
  );

  return (
    <StackedFilter
      isHorizontalLayout={isHorizontalLayout}
      FilterComponents={[
        <PosEnumSelect
          key="topFilter"
          value={mode}
          valueOptionsContent={CONTAINS_EQUALS_ENUM_FILTER_TO_CID}
          enableEmptySelection={false}
          onChange={(value) => {
            if (value) {
              const text =
                value === TextFilterEnum.Contains
                  ? query[textEqualsKey] ||
                    query[textNotContainsKey] ||
                    query[textContainsKey] ||
                    ''
                  : value === TextFilterEnum.NotContains
                  ? query[textEqualsKey] ||
                    query[textContainsKey] ||
                    query[textNotContainsKey] ||
                    ''
                  : query[textContainsKey] ||
                    query[textNotContainsKey] ||
                    query[textEqualsKey] ||
                    '';

              setRowFilter(value, text);
            }
          }}
          shape="rectFull"
        />,
        <PosTextField
          key="textFilter"
          value={filterText ?? ''}
          onChange={(e) => {
            setRowFilter(mode, e.target.value);
          }}
        />,
      ]}
    />
  );
};
