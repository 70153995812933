import { EntitySearchFilterBar } from 'src/components/EntitySearch';
import { FilterDropdownItem } from 'src/components/Filters';
import { useSaleFilters } from 'src/components/MainFilterBar';
import { SaleQuery, SaleSearchResult } from 'src/WebApiController';

export const SaleSearchFilterBar = (props: {
  activeSearchConfig?: SaleSearchResult;
  onSubmitFilter?: (query: SaleQuery) => void;
  selectedFilters?: FilterDropdownItem[];
  setSelectedFilters: (sf: FilterDropdownItem[]) => void;
}) => {
  const { filters } = useSaleFilters({
    showCatalogFilters: true,
    isHorizontalLayout: true,
  });

  return (
    <EntitySearchFilterBar
      {...props}
      filters={filters}
      mandatoryFiltersToShow={['eventDates']}
    />
  );
};
