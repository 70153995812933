import { ComponentProps, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useReportConfigActionsV2 } from 'src/hooks/useReportConfigActionsV2';
import {
  REPORT_CONFIG_V2_SCHEMA_VERSION,
  ReportConfigV2,
} from 'src/hooks/useReportConfigsV2';
import { DefaultReportV2ListingQuery } from 'src/utils/eventQueryUtils';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';

import { ReportsV2LocationState } from './ReportsV2.types';

const emptyReport: ReportConfigV2 = {
  schemaVersion: REPORT_CONFIG_V2_SCHEMA_VERSION,
  reportId: null,
  reportName: '',
  request: {
    groupings: [],
    aggregations: [],
    orderBy: '',
    orderByAsc: true,
    pageNumber: 0,
    filters: DefaultReportV2ListingQuery,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any,
  reportType: ReportTypesV2.InventoryV2,
  ownerDisplayName: '',
  widgets: null,
  isOwner: true,
  roleIdsToShare: [] as number[],
  sellerUserIdsToShare: [] as string[],
  hasMetricsOverride: null,
  globalReportTypeId: null,
  viewableFilterItemIds: null,
  editableFilterItemIds: null,
  hiddenFilterItemIds: null,
};

const getReportNavUrl = (reportType: ReportTypesV2, reportId: number) => {
  if (reportType === ReportTypesV2.InventoryV2) {
    return `/reports/inventory/v2/${reportId}`;
  } else if (reportType === ReportTypesV2.Commission) {
    return `/reports/commission/v2/${reportId}`;
  } else if (reportType === ReportTypesV2.SaleV2) {
    return `/reports/sale/v2/${reportId}`;
  }
  return '';
};

export function useReportV2Actions() {
  const { loginContext } = useAppContext();
  const [currentReport, setCurrentReport] = useState<ReportConfigV2>();

  const navigate = useNavigate();

  const reportBuilderDialog = useBasicDialog();
  const deleteDialog = useBasicDialog();
  const shareDialog = useBasicDialog();
  const reportHistoryDialog = useBasicDialog();

  const { showErrorDialog } = useErrorBoundaryContext();

  const onAddNewReport = useCallback(() => {
    setCurrentReport({
      ...emptyReport,
      ownerDisplayName: loginContext?.user?.displayName ?? '',
      request: {
        ...emptyReport.request,
        groupings: [],
        aggregations: [],
        orderByAsc: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    });

    reportBuilderDialog.launchDialog();
  }, [loginContext?.user?.displayName, reportBuilderDialog]);

  const {
    upsertReportConfig,
    deleteReportConfig,
    getReportConfigNameForDuplicate,
  } = useReportConfigActionsV2();

  const onReportSaveSubmit = useCallback(
    async (report: ReportConfigV2) => {
      const isNewReport = report.reportId == null;
      const reportId = await upsertReportConfig(report);
      reportBuilderDialog.closeDialog();
      if (isNewReport && reportId != null) {
        const navUrl = getReportNavUrl(report.reportType, reportId);
        navigate(navUrl, {
          state: {
            isCreateNewReport: true,
          } satisfies ReportsV2LocationState,
        });
      }
    },
    [upsertReportConfig, reportBuilderDialog, navigate]
  );

  const onReportAction = (
    reportConfig: ReportConfigV2,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => {
    switch (actionType) {
      case GridActionType.Select:
        if (reportConfig.reportId != null) {
          const navUrl = getReportNavUrl(
            reportConfig.reportType,
            reportConfig.reportId!
          );
          if (event?.metaKey || event?.ctrlKey) {
            // Open in new tab when holding down the meta/control key
            window.open(navUrl, '_blank', 'ref=noopener noreferrer');
          } else {
            navigate(navUrl);
          }
        }
        break;
      case GridActionType.Edit:
        setCurrentReport(reportConfig);
        reportBuilderDialog.launchDialog();
        break;
      case GridActionType.Delete:
        setCurrentReport(reportConfig);
        deleteDialog.launchDialog();
        break;
      case GridActionType.Share:
        setCurrentReport(reportConfig);
        shareDialog.launchDialog();
        break;
      case GridActionType.Duplicate:
        upsertReportConfig({
          ...reportConfig,
          reportId: null,
          reportName: getReportConfigNameForDuplicate(
            reportConfig.reportType,
            reportConfig.reportName
          ),
          isOwner: true,
          globalReportTypeId: null,
        });
        break;
      case GridActionType.History:
        setCurrentReport(reportConfig);
        reportHistoryDialog.launchDialog();
        break;
      default:
        break;
    }
  };

  const onReportDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        if (currentReport) {
          deleteReportConfig(currentReport.reportType, currentReport.reportId!);
        }
        deleteDialog.closeDialog();
      },
      (error) => {
        showErrorDialog('UserSetting.deleteReport', error, {
          trackErrorData: currentReport,
        });
      }
    );
  }, [currentReport, deleteDialog, deleteReportConfig, showErrorDialog]);

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...currentReport!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await upsertReportConfig(updatedReport);
      shareDialog.closeDialog();
    },
    [currentReport, upsertReportConfig, shareDialog]
  );

  return {
    currentReport,
    setCurrentReport,
    onAddNewReport,
    onReportSaveSubmit,
    onReportAction,
    onReportShareSubmit,
    onReportDelete,
    reportBuilderDialog,
    deleteDialog,
    shareDialog,
    reportHistoryDialog,
  };
}
