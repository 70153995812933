import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { SectionInfo } from 'src/WebApiController';

export const PurchaseTicketGroupVenueMap = ({
  control,
  onSectionClicked,
}: {
  control: Control<PurchaseTicketsInput, any>;
  onSectionClicked: (e: MouseEvent, sectionInfo: SectionInfo) => void;
}) => {
  const { venueMapInfo } = useEventMapContext();

  const ticketGroups = useWatch({
    control: control,
    name: 'ticketGroups',
  });

  const sectionIds = useMemo(() => {
    if (!ticketGroups) return [];
    return ticketGroups.reduce((acc, data) => {
      // If sectionId is not provided, try to derive it from venueMapInfo.
      if (!data.sectionId?.value) {
        if (!venueMapInfo) return acc;
        const sectionId = venueMapInfo.sections.find(
          (section) => section.name === data.section?.value
        )?.id;
        if (sectionId) {
          acc.push(sectionId);
        }
        return acc;
      }
      if (data.sectionId.value) {
        acc.push(data.sectionId.value);
      }
      return acc;
    }, [] as number[]);
  }, [ticketGroups, venueMapInfo]);

  return (
    <EventVenueMap
      onSectionClicked={onSectionClicked}
      selectedSectionIds={sectionIds}
      allowDrawLine={false}
    />
  );
};
