import { Row } from '@tanstack/react-table';
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import countryList from 'react-select-country-list';
import { ExpandedEventData } from 'src/contexts/CatalogDataContext';
import { getEventsWithNonZeroCounts } from 'src/contexts/CatalogDataContext/CatalogDataContext.utils';
import { removeSpecialChars } from 'src/utils/stringUtils';
import {
  ActionOutboxEntityType,
  AutoPricingCompListingMode,
  AutoPricingInputs,
  CatalogResults,
  DateTimeRange,
  Event,
  EventConfigScoreOverride,
  EventSort,
  EventTimeFrameFilter,
  EventWithData,
  GroupType,
  IListingGroupItem,
  Listing,
  ListingGroup,
  Marketplace,
  Performer,
  TopLevelCategory,
  Venue,
} from 'src/WebApiController';

import {
  DEFAULT_COMP_LISTING_FLOOR,
  DEFAULT_UNDERCUT_ABSOLUTE_AMT,
  DEFAULT_UNDERCUT_RELATIVE_AMT,
} from './autoPricingUtils';
import { US_COUNTRY_CODES } from './constants/constants';
import { getEventDateDisplayStrings } from './dateTimeUtils';
import { compareLocaleLowercased } from './localeUtils';

export const EVENT_TYPE_PARKING = 'Parking';

export const getEventPerformerVenue = (
  viagVirtId?: string | null,
  catalog?: Pick<
    CatalogResults,
    | 'events'
    | 'performers'
    | 'venues'
    | 'viagIdToLatestIdLookup'
    | 'posIdToViagIdLookup'
  > | null
) => {
  if (!catalog || !viagVirtId) {
    return { event: undefined, performer: undefined, venue: undefined } as {
      event?: EventWithData | null;
      performer?: Performer | null;
      venue?: Venue | null;
    };
  }

  const event = lookupEventInCatalog(catalog, viagVirtId);
  const { performer, venue } = getPerformerAndVenueForEvent(
    event?.event,
    catalog
  );

  return { event, performer, venue };
};

export function getPerformerAndVenueForEvent(
  event?: Event | null,
  catalog?: Pick<CatalogResults, 'performers' | 'venues'> | null
) {
  if (!event) {
    return { performer: undefined, venue: undefined };
  }

  const performer = event.perfId && catalog?.performers[event.perfId];
  const venue = catalog?.venues[event.venueId];

  return { performer: performer || undefined, venue };
}

export const getIsInternationalEvent = (countryCode?: string | null) => {
  if (!countryCode) {
    return false;
  }

  const countryName = countryList().getLabel(countryCode);
  if (!countryName) {
    return false;
  }

  // Only say it's international if it is not null/empty, a valid country, and is not US/CA
  return !US_COUNTRY_CODES.includes(countryCode.toUpperCase());
};

export const getFormattedEventName = ({
  event,
  performer,
  venue,
  slimMode,
  useShortName,
}: {
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;
  slimMode?: boolean;
  useShortName?: boolean;
}) => {
  if (!event) {
    return ['', '', ''];
  }

  const eventName = useShortName ? event?.shortName || event.name : event.name;
  let boldText = eventName;
  let dimText = '';
  let normalText = '';
  const vsText = ' vs ';
  const atText = ' at ';

  if (performer) {
    const cat = event.genre;
    const lowerCasedEventName = eventName.toLocaleLowerCase();
    const lowerCasedPerformer = performer.name.toLocaleLowerCase();

    if (slimMode) {
      if (
        cat === TopLevelCategory.Concert ||
        cat === TopLevelCategory.Theatre
      ) {
        if (
          venue?.name &&
          venue?.descr &&
          lowerCasedEventName === lowerCasedPerformer
        ) {
          // If the event name just repeat the performer, skip it and just display the venue
          return [venue.name, ' - ', venue.descr];
        } else {
          return [eventName.replace(performer.name, '')];
        }
      }
    }

    if (cat === TopLevelCategory.Sports) {
      // Legacy display of VS
      let indexOfVs = lowerCasedEventName.indexOf(vsText);
      if (indexOfVs >= 0) {
        const team1 = lowerCasedEventName.slice(0, indexOfVs);
        const team2 = lowerCasedEventName.slice(indexOfVs + vsText.length);

        if (team1 === lowerCasedPerformer || team2 === lowerCasedPerformer) {
          const team1Text = eventName.slice(0, indexOfVs);
          const team2Text = eventName.slice(indexOfVs + vsText.length);
          const homeTeam =
            team1 === lowerCasedPerformer ? team1Text : team2Text;
          const awayTeam =
            team1 === lowerCasedPerformer ? team2Text : team1Text;

          boldText = awayTeam;
          dimText = atText;
          normalText = homeTeam;
        }
      } else {
        indexOfVs = lowerCasedEventName.indexOf(atText);
        if (indexOfVs >= 0) {
          const awayTeam = eventName.slice(0, indexOfVs);
          const homeTeam = eventName.slice(indexOfVs + vsText.length);

          boldText = awayTeam;
          dimText = slimMode ? vsText : atText;
          normalText = homeTeam;
        }
      }
    }
  }

  return [boldText, dimText, normalText];
};

export const getEventDisplayText = (
  event: Event,
  venue?: Venue,
  performer?: Performer,
  useShortName?: boolean
): string => {
  let eventName: string | null = null;
  let eventNameConnector: string | null = null;
  let eventSubName: string | null = null;
  let eventDisplayName = '';

  [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
    venue,
    performer,
    useShortName,
  });

  if (venue) {
    eventDisplayName += `${venue.name} `;
  }

  eventDisplayName += `${eventName} ${eventNameConnector} ${eventSubName}`;

  if (event.dates?.start) {
    const { formattedEventDate, formattedEventSubDate } =
      getEventDateDisplayStrings(event.dates?.start, {
        dateFormat: undefined,
        subDateFormat: ' - h:mm aaa, eee',
      });

    eventDisplayName += ` - ${formattedEventDate}${formattedEventSubDate}`;
  }
  return eventDisplayName;
};

export function searchEvents(
  data: CatalogResults,
  searchText?: string | null,
  performerIds?: number[] | null,
  venueIds?: number[] | null,
  eventTimeFrameFilter?: EventTimeFrameFilter | null
) {
  let filteredData = Object.values(data.events).filter((e) => e.event != null);

  if (eventTimeFrameFilter) {
    const now = new Date();
    filteredData = filteredData.filter((e) => {
      const { start, end } = e.event.dates;
      if (start) {
        let startDateObj = new Date(start);
        startDateObj = new Date(
          startDateObj.setHours(startDateObj.getHours() + 2)
        );
        const endDateObj = end ? new Date(end) : null;

        if (eventTimeFrameFilter === EventTimeFrameFilter.Future) {
          return (endDateObj || startDateObj) >= now;
        } else if (eventTimeFrameFilter === EventTimeFrameFilter.Past) {
          return (endDateObj || startDateObj) < now;
        }
      }

      return false;
    });
  }

  if (performerIds && performerIds.length > 0) {
    filteredData = filteredData.filter(
      (e) => e.event.perfId && performerIds.includes(e.event.perfId)
    );
  }

  if (venueIds && venueIds.length > 0) {
    filteredData = filteredData.filter((e) =>
      venueIds.includes(e.event.venueId)
    );
  }

  if (searchText && searchText.length > 0) {
    const formattedSearchText = removeSpecialChars(searchText).toUpperCase();
    const searchTerms = formattedSearchText.split(/\s/); // any whitespace
    const formattedVenuesNames: Record<number, string> = {};

    filteredData = filteredData.filter((e) => {
      const { performer } = getPerformerAndVenueForEvent(e.event, data);

      const formattedEventName = removeSpecialChars(
        getFormattedEventName({
          event: e.event,
          performer,
        }).join(' ')
      ).toUpperCase();

      const { venueId } = e.event;
      let formattedVenueName = formattedVenuesNames[venueId];
      if (!formattedVenueName) {
        formattedVenueName = removeSpecialChars(
          data.venues[e.event.venueId]?.name ?? ''
        ).toUpperCase();
        formattedVenuesNames[venueId] = formattedVenueName;
      }

      return searchTerms.every(
        (term) =>
          formattedEventName.includes(term) || formattedVenueName.includes(term)
      );
    });
  }

  return filteredData;
}

export const sortEvents = (
  data: CatalogResults,
  filteredData: EventWithData[],
  sortBy?: EventSort | null,
  isSortDescending?: boolean | null
) => {
  if (sortBy === EventSort.MainDisplay) {
    // NOTE - For main-grouping, we will sort in-memory because the list is not paged,
    // but if it is paged, we will need to sort server side
    filteredData.sort((a, b) => {
      const { performer: ap } = getPerformerAndVenueForEvent(a.event, data);
      const { performer: bp } = getPerformerAndVenueForEvent(b.event, data);
      const aText = getFormattedEventName({
        event: a.event,
        performer: ap,
      }).join(' ');
      const bText = getFormattedEventName({
        event: b.event,
        performer: bp,
      }).join(' ');
      const result = compareLocaleLowercased(aText, bText);
      return isSortDescending ? -1 * result : result;
    });
  } else {
    sortEventsByDate(filteredData, isSortDescending);
  }

  return filteredData;
};

export const sortEventsByDate = (
  events: EventWithData[],
  isSortDescending?: boolean | null
) => {
  events.sort((a, b) => {
    const aStart = a.event.dates.start
      ? new Date(a.event.dates.start)
      : new Date();
    const bStart = b.event.dates.start
      ? new Date(b.event.dates.start)
      : new Date();
    const result = aStart < bStart ? -1 : aStart === bStart ? 0 : 1;
    return isSortDescending ? -1 * result : result;
  });
};

export const sortEventsByName = (
  events: EventWithData[],
  isSortDescending?: boolean | null
) => {
  events.sort((a, b) => {
    const result = compareLocaleLowercased(a.event.name, b.event.name);
    return isSortDescending ? -1 * result : result;
  });
};

export const transformData = (
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.TicketGroup
    | ActionOutboxEntityType.Purchase,
  data: EventWithData[],
  expandedEventData?: ExpandedEventData | null,
  isExpandedDataLoading?: boolean,
  filterZeroCounts?: boolean
) => {
  const translatedData = (
    filterZeroCounts ? getEventsWithNonZeroCounts(entityType, data, true) : data
  ).map((e) =>
    setExpandedEventData(expandedEventData, e, isExpandedDataLoading)
  );

  return translatedData;
};

const setExpandedEventData = (
  expandedEventData: ExpandedEventData | undefined | null,
  e: EventWithData,
  isExpandedDataLoading?: boolean
) => {
  const id = e.counts?.viagVirtId ?? e.event?.viagVirtualId;
  const eventData = expandedEventData?.[id];
  if (eventData) {
    e.entities = {
      sales: eventData.sales,
      listings: eventData.listings,
      ticketGroups: eventData.ticketGroups,
      getDataFail: eventData.failedToRetrieveData,
    };
  } else {
    e.entities = {
      sales: isExpandedDataLoading
        ? e.entities.sales?.length
          ? e.entities.sales
          : null
        : [],
      listings: isExpandedDataLoading
        ? e.entities.listings?.length
          ? e.entities.listings
          : null
        : [],
      ticketGroups: isExpandedDataLoading
        ? e.entities.ticketGroups?.length
          ? e.entities.ticketGroups
          : null
        : [],
      getDataFail: false,
    };
  }

  return { ...e };
};

export const lookupEventInCatalog = (
  data:
    | Pick<
        CatalogResults,
        | 'events'
        | 'performers'
        | 'venues'
        | 'viagIdToLatestIdLookup'
        | 'posIdToViagIdLookup'
      >
    | undefined,
  viagVirtualId: string | null | undefined,
  ...posEvIds: (string | null | undefined)[]
) => {
  if (!viagVirtualId) {
    return null;
  }

  let realId = data?.viagIdToLatestIdLookup?.[viagVirtualId];
  if (!realId && posEvIds?.length) {
    realId = posEvIds
      .filter((id) => id != null)
      .map((id) => data?.posIdToViagIdLookup?.[id!])
      .filter((id) => id != null)[0];
  }
  return realId ? data?.events?.[realId] : null;
};

export const removeDuplicateListings = (listings: Listing[] | null) => {
  if (!listings?.length) {
    return null;
  }

  const result = listings.reduce(
    (r, l) => {
      if (l.isLtGrp && r[l.id]) {
        // We need to handle listing-group differently
        // The same listing group will appear multiple times each carrying different listings
        // from different events, and so we need to merge them together
        const curGroup = r[l.id] as ListingGroup;
        const newGroup = l as ListingGroup;

        mergeListingGroup(curGroup, newGroup);
      } else {
        r[l.id] = l; // last one wins
      }

      return r;
    },
    {} as Record<number, Listing>
  );

  return Object.values(result);
};

const mergeListingGroup = (
  targetGroup: ListingGroup,
  newGroup: ListingGroup
) => {
  let activeSubgroup: ListingGroup | undefined;
  let queuedSubgroup: ListingGroup | undefined;
  let soldSubgroup: ListingGroup | undefined;
  let invisibleSubgroup: ListingGroup | undefined;
  let activeSubgroupItems = {} as Record<number, IListingGroupItem>;
  let queuedSubgroupItems = {} as Record<number, IListingGroupItem>;
  let soldSubgroupItems = {} as Record<number, IListingGroupItem>;
  let invisibleSubgroupItems = {} as Record<number, IListingGroupItem>;

  const groupItems = targetGroup.groupItems.reduce(
    (r, l) => {
      r[l.id] = l;

      if (l.isLtGrp) {
        const subGroup = l as ListingGroup;
        if (subGroup.groupType === GroupType.Active) {
          activeSubgroupItems = subGroup.groupItems.reduce(
            (r, l) => {
              r[l.id] = l;
              return r;
            },
            {} as Record<number, IListingGroupItem>
          );
          activeSubgroup = subGroup;
        } else if (subGroup.groupType === GroupType.Inactive) {
          queuedSubgroupItems = subGroup.groupItems.reduce(
            (r, l) => {
              r[l.id] = l;
              return r;
            },
            {} as Record<number, IListingGroupItem>
          );
          queuedSubgroup = subGroup;
        } else if (subGroup.groupType === GroupType.FullySold) {
          soldSubgroupItems = subGroup.groupItems.reduce(
            (r, l) => {
              r[l.id] = l;
              return r;
            },
            {} as Record<number, IListingGroupItem>
          );
          soldSubgroup = subGroup;
        } else if (subGroup.groupType === GroupType.Invisible) {
          invisibleSubgroupItems = subGroup.groupItems.reduce(
            (r, l) => {
              r[l.id] = l;
              return r;
            },
            {} as Record<number, IListingGroupItem>
          );
          invisibleSubgroup = subGroup;
        }

        r[subGroup.id] = l;
      }
      return r;
    },
    {} as Record<number, IListingGroupItem>
  );
  newGroup.groupItems.forEach((i) => {
    if (i.isLtGrp) {
      // This is either the inactive or invisible sub-group
      const subGroup = i as ListingGroup;
      if (subGroup.groupType === GroupType.Active) {
        if (activeSubgroup == null) {
          // If target doesn't have this subGroup, then just copy it over
          activeSubgroup = subGroup;
          groupItems[subGroup.id] = subGroup;
        }

        subGroup.groupItems.forEach((j) => {
          if (!activeSubgroupItems[j.id]) {
            // If i doesn't exists in the group yet, just add/replace it
            activeSubgroupItems[j.id] = j;

            if (invisibleSubgroupItems[j.id]) {
              // If the targetGroup has this item j as an invisible item, delete it because it's already in the inactive item
              delete invisibleSubgroupItems[j.id];
            }
          }
        });
      } else if (subGroup.groupType === GroupType.Inactive) {
        if (queuedSubgroup == null) {
          // If target doesn't have this subGroup, then just copy it over
          queuedSubgroup = subGroup;
          groupItems[subGroup.id] = subGroup;
        }

        subGroup.groupItems.forEach((j) => {
          if (!queuedSubgroupItems[j.id]) {
            // If i doesn't exists in the group yet, just add/replace it
            queuedSubgroupItems[j.id] = j;

            if (invisibleSubgroupItems[j.id]) {
              // If the targetGroup has this item j as an invisible item, delete it because it's already in the inactive item
              delete invisibleSubgroupItems[j.id];
            }
          }
        });
      } else if (subGroup.groupType === GroupType.FullySold) {
        if (soldSubgroup == null) {
          // If target doesn't have this subGroup, then just copy it over
          soldSubgroup = subGroup;
          groupItems[subGroup.id] = subGroup;
        }

        subGroup.groupItems.forEach((j) => {
          if (!soldSubgroupItems[j.id]) {
            // If i doesn't exists in the group yet, just add/replace it
            soldSubgroupItems[j.id] = j;

            if (invisibleSubgroupItems[j.id]) {
              // If the targetGroup has this item j as an invisible item, delete it because it's already in the inactive item
              delete invisibleSubgroupItems[j.id];
            }
          }
        });
      } else if (subGroup.groupType === GroupType.Invisible) {
        if (invisibleSubgroup == null) {
          // If target doesn't have this subGroup, then just copy it over
          invisibleSubgroup = subGroup;
          groupItems[subGroup.id] = subGroup;
        }

        subGroup.groupItems.forEach((k) => {
          if (
            !groupItems[k.id] &&
            !activeSubgroupItems[k.id] &&
            !queuedSubgroupItems[k.id] &&
            !soldSubgroupItems[k.id] &&
            !invisibleSubgroupItems[k.id]
          ) {
            // If i doesn't exists in any of the group yet, just add/replace it
            invisibleSubgroupItems[k.id] = k;
          }
        });
      }
    } else if (!groupItems[i.id]) {
      // If i doesn't exists in the group yet, just add/replace it
      groupItems[i.id] = i;

      if (invisibleSubgroupItems[i.id]) {
        // If the targetGroup has this item i as an invisible item, delete it because it's already in the inactive item
        delete invisibleSubgroupItems[i.id];
      }
    }
  });

  // now that we have the updated active, queued, sold, and invisible group, set it back
  const activeItems = Object.values(activeSubgroupItems);
  if (activeSubgroup != null) {
    activeSubgroup.groupItems = activeItems;
  }

  const queuedItems = Object.values(queuedSubgroupItems);
  if (queuedSubgroup != null) {
    queuedSubgroup.groupItems = queuedItems;
  }

  const soldItems = Object.values(soldSubgroupItems);
  if (soldSubgroup != null) {
    soldSubgroup.groupItems = soldItems;
  }

  const invisibleItems = Object.values(invisibleSubgroupItems);
  if (invisibleSubgroup != null) {
    invisibleSubgroup.groupItems = invisibleItems;
  }

  targetGroup.groupItems = Object.values(groupItems).filter((i) =>
    // We only want Listing or non-empty sub-groups
    i.isLtGrp ? (i as ListingGroup).groupItems.length > 0 : true
  );
};

export const compareEventsByDateStr = <T,>(
  e1: Row<T | null>,
  e2: Row<T | null>,
  colId: string
) => {
  const d1 = e1.getValue(colId) as string;
  const d2 = e2.getValue(colId) as string;

  if (!d1 || !d2) {
    return 0;
  }

  const aStart = new Date(d1);
  const bStart = new Date(d2);
  return aStart < bStart ? -1 : aStart === bStart ? 0 : 1;
};

export const compareEventsByDate = <T,>(
  e1: Row<T | null>,
  e2: Row<T | null>,
  colId: string
) => {
  const d1 = e1.getValue(colId) as DateTimeRange;
  const d2 = e2.getValue(colId) as DateTimeRange;

  if (!d1 || !d2) {
    return 0;
  }

  const aStart = d1.start ? new Date(d1.start) : new Date();
  const bStart = d2.start ? new Date(d2.start) : new Date();
  return aStart < bStart ? -1 : aStart === bStart ? 0 : 1;
};

type EventWithPerformerAndVenue = {
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;
};

export function compareEventsByName<T>(
  e1: Row<T | null>,
  e2: Row<T | null>,
  colId: string
) {
  const data1 = e1.getValue(colId) as EventWithPerformerAndVenue;
  const data2 = e2.getValue(colId) as EventWithPerformerAndVenue;

  const name1 = getFormattedEventName({
    event: data1.event,
    performer: data1.performer,
    venue: data1.venue,
    useShortName: true,
  });
  const name2 = getFormattedEventName({
    event: data2.event,
    performer: data2.performer,
    venue: data2.venue,
    useShortName: true,
  });

  return name1.join(' ').localeCompare(name2.join(' '));
}

export function compareEventsByVenue<T>(
  e1: Row<T | null>,
  e2: Row<T | null>,
  colId: string
) {
  const data1 = e1.getValue(colId) as EventWithPerformerAndVenue;
  const data2 = e2.getValue(colId) as EventWithPerformerAndVenue;

  const venueName1 = data1.venue?.name ?? '';
  const venueName2 = data2.venue?.name ?? '';

  return venueName1.localeCompare(venueName2);
}

export function compareEventsByLocation<T>(
  e1: Row<T | null>,
  e2: Row<T | null>,
  colId: string
) {
  const data1 = e1.getValue(colId) as EventWithPerformerAndVenue;
  const data2 = e2.getValue(colId) as EventWithPerformerAndVenue;

  const locationName1 = data1.venue?.descr ?? '';
  const locationName2 = data2.venue?.descr ?? '';

  return locationName1.localeCompare(locationName2);
}

export const getInHandDateBasedOnEvent = (
  event?: Event | null,
  daysBeforeEvent?: number
) => {
  if (!event?.dates?.start) {
    return new Date().toISOString();
  }

  const eventStartDate = new Date(event.dates.start); // get the current date
  const threeDaysAgo = new Date(eventStartDate); // create a new date object with the current date
  threeDaysAgo.setDate(eventStartDate.getDate() - (daysBeforeEvent ?? 3)); // subtract days (default 3) from the current date

  return threeDaysAgo.toISOString();
};

export type EventPricingSeatMapForm = {
  eventAutoPricing?: AutoPricingInputs | null;
  eventScoreOverride?: EventConfigScoreOverride | null;
  eventWideAutoPricing?: boolean | null;
};

export const validateAutoPricingSettings = (
  clearErrors: UseFormClearErrors<EventPricingSeatMapForm>,
  setError: UseFormSetError<EventPricingSeatMapForm>,
  setValue: UseFormSetValue<EventPricingSeatMapForm>,
  eventForm: EventPricingSeatMapForm,
  compListingFloorCeilingError: string,
  requiredMsg: string
) => {
  let hasErrors = false;

  clearErrors('eventAutoPricing.compListingFloor');
  clearErrors('eventAutoPricing.undercutAbsoluteAmount');
  clearErrors('eventAutoPricing.undercutRelativeAmount');
  clearErrors('eventAutoPricing.compListingSelectedSectionSettings');

  const eventAutoPricing = eventForm.eventAutoPricing;

  if (eventAutoPricing == null) return true;

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    compListingOnlyForSelectedSectionsEnabled,
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,
  } = eventAutoPricing;

  if (
    compListingMode === AutoPricingCompListingMode.QualityScore ||
    compListingMode === AutoPricingCompListingMode.SameSection
  ) {
    if (!compListingFloor) {
      eventAutoPricing.compListingFloor = compListingFloor;
      setValue('eventAutoPricing.compListingFloor', DEFAULT_COMP_LISTING_FLOOR);
    } else if (compListingCeiling && compListingFloor >= compListingCeiling) {
      setError('eventAutoPricing.compListingFloor', {
        message: compListingFloorCeilingError,
      });

      hasErrors = true;
    }

    if (compListingMode === AutoPricingCompListingMode.QualityScore) {
      if (
        compListingOnlyForSelectedSectionsEnabled &&
        !compListingSelectedSectionSettings?.sectionIdFilter?.length
      ) {
        setError(
          'eventAutoPricing.compListingSelectedSectionSettings',
          {
            message: requiredMsg,
          },
          { shouldFocus: true }
        );

        hasErrors = true;
      }
    }
  }

  if (undercutMode) {
    if (undercutAbsoluteAmount == null) {
      eventAutoPricing.undercutAbsoluteAmount = DEFAULT_UNDERCUT_ABSOLUTE_AMT;
      setValue(
        'eventAutoPricing.undercutAbsoluteAmount',
        DEFAULT_UNDERCUT_ABSOLUTE_AMT
      );
    }

    if (undercutRelativeAmount == null) {
      eventAutoPricing.undercutRelativeAmount = DEFAULT_UNDERCUT_RELATIVE_AMT;
      setValue(
        'eventAutoPricing.undercutRelativeAmount',
        DEFAULT_UNDERCUT_RELATIVE_AMT
      );
    }
  }

  return !hasErrors;
};

export const compareMarketplace = (
  a?: Marketplace | null,
  b?: Marketplace | null
) => {
  if (a === Marketplace.StubHub && b === Marketplace.StubHub) {
    return 0; // always sort first
  }

  if (a === Marketplace.StubHub) {
    return -1; // always sort first
  }

  if (b === Marketplace.StubHub) {
    return 1;
  }

  return (a ?? '').localeCompare(b ?? '');
};
