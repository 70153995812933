import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { shared, vars } from 'src/core/themes';
import { Stack, Switch } from 'src/core/ui';
import { useGetListingMergeSuggestionsByEvent } from 'src/hooks/api/useGetListingMergeSuggestionsByEvent';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldError } from 'src/modals/common/Purchase';
import { ListingTable } from 'src/tables/ListingTable';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData, ListingGroup } from 'src/WebApiController';
import { Feature, Listing, MergeListingsInput } from 'src/WebApiController';

import {
  MultiSelectionContextProvider,
  MultiSelectScope,
} from '../../contexts/MultiSelectionContext/MultiSelectionContext';
import * as styles from './MergeListings.css';
import { MainLabel } from './MergeListings.styled';
import { MergeListingsSuggestionsDisplay } from './MergeListingsSuggestionsDisplay';
import { getFlattenedListings } from './utils';

export type MergeListingsBodyProps = EventWithData & {
  disabled?: boolean;
  onMergeSuggestions: (listingIds: number[]) => void;
  footer?: React.ReactElement;
};

export const MergeListingsBody = ({
  event,
  entities,
  footer,
  ...rest
}: MergeListingsBodyProps) => {
  const listings = entities.listings;

  const { watch, setValue, clearErrors, formState } =
    useFormContext<MergeListingsInput>();

  const hasDefaultNotToMergeHoldListingsFeature = useUserHasFeature(
    Feature.DefaultNotToMergeHoldListings
  );

  const [excludeHeldListings, setExcludeHeldListings] = useState<boolean>(
    hasDefaultNotToMergeHoldListingsFeature
  );

  const hasAdminHoldListings = useMemo(
    () => !isEmpty(listings?.filter((l) => l.isAdminHold)),
    [listings]
  );

  const eligibleListings = useMemo(
    (): (Listing | ListingGroup)[] =>
      (listings?.filter((l) => {
        let eligible = !l.isSeatSaver;
        if (excludeHeldListings) {
          eligible = eligible && !l.isAdminHold;
        }

        return eligible;
      }) || []) as (Listing | ListingGroup)[],
    [excludeHeldListings, listings]
  );

  const listingIds = watch('listingIds');

  const [ineligibilityCount, setIneligibilityCount] = useState(0);

  const onListingIdsSelected = (selectedIds: number[]) => {
    clearErrors('listingIds');
    setValue('listingIds', selectedIds);
  };

  const { mergeSuggestions } = useGetListingMergeSuggestionsByEvent(
    event,
    excludeHeldListings
  );

  const sectionDifMsg = useContent(ContentId.MergeListings_SectionDifference);
  const rowDifMsg = useContent(ContentId.MergeListings_RowDifference);
  const deliveryDifMsg = useContent(ContentId.MergeListings_DeliveryDifference);
  const groupedListingMsg = useContent(ContentId.MergeListings_GroupedListings);
  const mismatchListingMsg = useContent(
    ContentId.MergeListings_ListingMismatchWarning
  );

  const getListingIneiligibleReason = useCallback(
    (firstListing: Listing, l: Listing) => {
      return l.seating.section !== firstListing.seating.section
        ? sectionDifMsg
        : (l.seating.row || null) !== (firstListing.seating.row || null)
        ? rowDifMsg
        : l.delivType !== firstListing.delivType
        ? deliveryDifMsg
        : l.isLtGrp
        ? groupedListingMsg
        : undefined;
    },
    [deliveryDifMsg, groupedListingMsg, rowDifMsg, sectionDifMsg]
  );

  const getListingNotEligibleForSelection = useCallback(
    (listings?: Listing[] | null) => {
      const selectedListings = (listings ?? []).filter(
        (l) => listingIds?.includes(l.id)
      );

      if (selectedListings?.length) {
        const firstListing = selectedListings[0];
        const listingsWithIneligibleReasons = (listings ?? [])!.map((l) => ({
          listing: l,
          ineligibleReason: undefined,
        }));

        const newIneligibilityCount = listingsWithIneligibleReasons.filter(
          (l) => l.ineligibleReason
        ).length;

        if (newIneligibilityCount !== ineligibilityCount) {
          setIneligibilityCount(newIneligibilityCount);
        }
        return listingsWithIneligibleReasons;
      }
    },
    [ineligibilityCount, listingIds]
  );

  const mergeMismatchListingWarning = useMemo(() => {
    const selectedListings = (eligibleListings ?? []).filter(
      (l) => listingIds?.includes(l.id)
    );

    if (selectedListings.length) {
      const firstListing = selectedListings[0];
      const hasMismatch = selectedListings.some((l, index) => {
        return (
          index > 0 && getListingIneiligibleReason(firstListing, l) != null
        );
      });

      if (hasMismatch) {
        return mismatchListingMsg;
      }
    }

    return undefined;
  }, [
    getListingIneiligibleReason,

    listingIds,
    mismatchListingMsg,
    eligibleListings,
  ]);

  const mergeSuggestionsListingIds = useMemo(() => {
    if (!mergeSuggestions) {
      return [];
    }

    return mergeSuggestions.map((s) => s.listingIds);
  }, [mergeSuggestions]);

  const [flattenedEligibleListings, groupIdNameMap] =
    getFlattenedListings(eligibleListings);

  return (
    <Stack direction="column" gap="l">
      <MainLabel>
        <Content id={ContentId.SelectListingsToMerge} />
        {hasAdminHoldListings && hasDefaultNotToMergeHoldListingsFeature && (
          <Stack
            direction="row"
            gap="s"
            alignItems="center"
            style={{ fontSize: vars.typography.fontSize.base, fontWeight: 500 }}
          >
            <Switch
              checked={!excludeHeldListings}
              onChange={(e) => e.stopPropagation()}
              onCheckedChange={(isChecked) => {
                if (isChecked === excludeHeldListings) {
                  setExcludeHeldListings(!isChecked);
                }
              }}
            />
            <Content id={ContentId.IncludeHeldListings} />
          </Stack>
        )}
      </MainLabel>
      <CatalogMultiSelectionContextProvider type="listing">
        <MultiSelectionContextProvider
          initialSelectionMode={{
            mode: MultiSelectScope.SingleGroup,
            groupId: event.viagVirtualId,
          }}
        >
          <Stack direction="column" gap="m" className={styles.tableContainer}>
            <ListingTable
              {...rest}
              event={event}
              entities={flattenedEligibleListings}
              entityCount={flattenedEligibleListings.length}
              selectedIds={listingIds}
              hideUnselectableListings
              showMainColumnsOnly
              onItemSelectionsChanged={onListingIdsSelected}
              getListingNotEligibleForSelection={
                getListingNotEligibleForSelection
              }
              listingGroupMap={groupIdNameMap}
            />

            {mergeMismatchListingWarning && (
              <WarningMessage message={mergeMismatchListingWarning} />
            )}

            <FieldError>{formState.errors.listingIds?.message}</FieldError>
          </Stack>
        </MultiSelectionContextProvider>
      </CatalogMultiSelectionContextProvider>
      {footer}
      {ineligibilityCount > 0 ? (
        <Stack direction="column" gap="l">
          <div className={styles.subLabel}>
            <Content
              id={ContentId.IncompatibleForMergingWithSelectedListings}
            />
          </div>
          <div className={styles.tableContainer}>
            <ListingTable
              {...rest}
              event={event}
              entities={eligibleListings}
              entityCount={eligibleListings.length}
              selectedIds={listingIds}
              showUnselectableListingsOnly
              showMainColumnsOnly
              getListingNotEligibleForSelection={
                getListingNotEligibleForSelection
              }
            />
          </div>
        </Stack>
      ) : null}
      {mergeSuggestionsListingIds.length > 0 && (
        <Stack direction="column" gap="l">
          <span className={shared.typography.title6}>
            <Content id={ContentId.Suggestions} />
          </span>
          {mergeSuggestionsListingIds.map((s, i) => (
            <MergeListingsSuggestionsDisplay
              key={`merge-suggestions-${i}`}
              {...rest}
              event={event}
              listings={flattenedEligibleListings}
              listingIds={s}
              groupIdNameMap={groupIdNameMap}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
