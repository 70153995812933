import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  Permission,
  ReleaseNote,
  SellerUserClient,
  UserSetting,
} from 'src/WebApiController';

import { HAS_RELEASE_NOTES_UNREAD_QUERY_KEY } from './useHasReleaseNotesUnread';
import { useUserHasAnyOfPermissions } from './useUserHasAnyOfPermissions';
import { useServerUserSetting } from './useUserSetting';

export const useReleaseNotes = ({
  getUnreadOnly,
  currentLoginUserOnly,
  disabled,
}: {
  getUnreadOnly?: boolean;
  /* whether to use the active account's user or the login user (which includes impersonating user) Id */
  currentLoginUserOnly?: boolean;
  disabled?: boolean;
}) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();

  const canViewReleaseNotes = useUserHasAnyOfPermissions(
    Permission.General_ViewReleaseNotes
  );

  const { value: releaseNoteIdsRead, setUserSetting } = useServerUserSetting<
    number[]
  >({
    id: UserSetting.ReleaseNoteIdsRead,
    currentLoginUserOnly: currentLoginUserOnly ?? false,
  });

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    !disabled &&
    canViewReleaseNotes;

  const mainQueryKey = [
    'SellerUserClient.getAvailableReleaseNoteInfos',
    activeAccountWebClientConfig.activeAccountId,
    currentLoginUserOnly,
    shouldQuery,
  ];

  const unreadQueryKey = [...mainQueryKey, true /* getUnreadOnly */];

  const releaseNotesQuery = useQuery({
    queryKey: [...mainQueryKey, getUnreadOnly],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new SellerUserClient(
        activeAccountWebClientConfig
      ).getAvailableReleaseNoteInfos(
        currentLoginUserOnly ?? false,
        getUnreadOnly ?? false
      );
    },

    enabled: shouldQuery,
    staleTime: Infinity, // seldom change data and we invalidate on reload
    refetchOnWindowFocus: false,
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError('SellerUserClient.getAvailableReleaseNoteInfos', error, {
          currentLoginUserOnly,
          getUnreadOnly,
        });
      },
    },
  });

  // Mutation to update release note id and refresh related queries
  const setReleaseNoteIdsReadMutation = useMutation({
    mutationFn: async ({ value }: { value: number[] }) => {
      return await setUserSetting(value);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey: unreadQueryKey });
      const prevValue = queryClient.getQueryData<ReleaseNote[] | null>(
        unreadQueryKey
      );

      const newValue = prevValue?.filter((p) => !value.includes(p.id));

      queryClient.setQueryData<ReleaseNote[]>(unreadQueryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(unreadQueryKey, context?.prevValue);
      trackError('SellerUserClient.getAvailableReleaseNoteInfos', err, {
        value,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: unreadQueryKey });
      queryClient.invalidateQueries({
        queryKey: [HAS_RELEASE_NOTES_UNREAD_QUERY_KEY],
      });
    },
  });

  return {
    releaseNoteInfos: releaseNotesQuery.data,
    isLoading: releaseNotesQuery.isPending,
    releaseNoteIdsRead,
    setReleaseNoteIdsRead: async (noteIds: number[]) => {
      return await setReleaseNoteIdsReadMutation.mutateAsync({
        value: noteIds,
      });
    },
  };
};
