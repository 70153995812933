import { MantineProvider } from '@mantine/core';
import { Worker } from '@react-pdf-viewer/core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import clsx from 'clsx';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Outlet } from 'react-router-dom';
import { dataThemeAttributeName } from 'src/app/constants';
import { DocumentInfo } from 'src/components/SignUp/DocumentInfo';
import { SignUpRedirect } from 'src/components/SignUp/SignUpRedirect';
import {
  AppContextProvider,
  AppTelemetryProvider,
  useAppContext,
} from 'src/contexts/AppContext';
import { BulkEditHubContextProvider } from 'src/contexts/BulkEditHubContext';
import { SiteContentProvider } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { EmailPurchaseOrderContextProvider } from 'src/contexts/EmailPurchaseOrderContext/EmailPurchaseOrderContext';
import { ErrorBoundaryContextProvider } from 'src/contexts/ErrorBoundaryContext';
import { GenericDialogProvider } from 'src/contexts/GenericDialogContext/GenericDialogContext';
import { LocalizationContextProvider } from 'src/contexts/LocalizationContext';
import { MenuContextProvider } from 'src/contexts/MenuContext/MenuContext';
import { NotificationsContextProvider } from 'src/contexts/NotificationsContext';
import { ScreenSizeProvider } from 'src/contexts/ScreenSizeContext/ScreenSizeContext';
import { SellerAccountContextProvider } from 'src/contexts/SellerAccountContext';
import { SellerRoleContextProvider } from 'src/contexts/SellerRoleContext';
import { SignUpContextProvider } from 'src/contexts/SignUpContext/SignUpContext';
import {
  SiteThemeContextProvider,
  useSiteTheme,
} from 'src/contexts/SiteTheme/SiteThemeContext';
import { SiteTimezoneContextProvider } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import {
  PurpleLegacyTheme,
  purpleTheme,
  TicketUtilLegacyTheme,
  ticketUtilTheme,
} from 'src/core/themes';
import { DarkLegacyTheme } from 'src/core/themes/darkLegacyTheme';
import { darkTheme } from 'src/core/themes/darkTheme.css';
import { useMemoryTelemetry } from 'src/hooks/useMemoryTelemetry';
import { useNetworkState } from 'src/hooks/useNetworkState';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { Offline } from 'src/navigations/Routes/Offline';
import { CoBrandId } from 'src/WebApiController';
import { ThemeProvider } from 'styled-components/macro';

import * as styles from './App.css';
import { AppLayout } from './App.styled';
import { MainQueryClient } from './QueryClients';

const touchBackendOptions = { enableMouseEvents: true };

function AppContent() {
  const { appContext, cobrandId, mainRoute } = useAppContext();
  const isOnline = useNetworkState();
  const { isDarkMode } = useSiteTheme();
  useMemoryTelemetry();

  useEffect(() => {
    document.body.setAttribute(dataThemeAttributeName, 'light');

    if (cobrandId === CoBrandId.TicketUtil) {
      document.body.classList.toggle(ticketUtilTheme, true);
      document.body.classList.remove(purpleTheme);
      document.body.classList.remove(darkTheme);
    } else if (isDarkMode) {
      document.body.classList.toggle(darkTheme, true);
      document.body.setAttribute(dataThemeAttributeName, 'dark');
      document.body.classList.remove(ticketUtilTheme);
      document.body.classList.remove(purpleTheme);
    } else {
      document.body.classList.toggle(purpleTheme, true);
      document.body.classList.remove(ticketUtilTheme);
      document.body.classList.remove(darkTheme);
    }
  }, [cobrandId, isDarkMode]);

  if (!appContext) {
    return null;
  }

  return (
    <ThemeProvider
      theme={
        cobrandId === CoBrandId.TicketUtil
          ? TicketUtilLegacyTheme
          : isDarkMode
            ? DarkLegacyTheme
            : PurpleLegacyTheme
      }
    >
      <MantineProvider>
        <ScreenSizeProvider>
          <ErrorBoundaryContextProvider>
            <LocalizationContextProvider>
              <SellerAccountContextProvider>
                <SellerRoleContextProvider>
                  <EmailPurchaseOrderContextProvider>
                    {/* See https://github.com/react-dnd/react-dnd/issues/3257#issuecomment-1239254032 */}
                    <DndProvider
                      backend={TouchBackend}
                      options={touchBackendOptions}
                    >
                      <NotificationsContextProvider
                        loadActiveNotifications={
                          mainRoute === MainRoute.Home ||
                          mainRoute === MainRoute.Notifications
                        }
                      >
                        <BulkEditHubContextProvider>
                          <DialogProvider>
                            <GenericDialogProvider>
                              <DocumentInfo>
                                {/* Required for pdf viewer to work https://react-pdf-viewer.dev/docs/basic-usage/ */}
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                  <AppLayout
                                    className={clsx(styles.root, {
                                      [styles.darkModeRoot]: isDarkMode,
                                    })}
                                  >
                                    <MenuContextProvider>
                                      <SignUpContextProvider>
                                        <SignUpRedirect>
                                          {appContext === undefined ? (
                                            isOnline ? (
                                              <div
                                                className={
                                                  styles.spinnerContainer
                                                }
                                              >
                                                <PosSpinner />
                                              </div>
                                            ) : (
                                              <Offline />
                                            )
                                          ) : (
                                            <Outlet />
                                          )}
                                        </SignUpRedirect>
                                      </SignUpContextProvider>
                                    </MenuContextProvider>
                                  </AppLayout>
                                </Worker>
                              </DocumentInfo>
                            </GenericDialogProvider>
                          </DialogProvider>
                        </BulkEditHubContextProvider>
                      </NotificationsContextProvider>
                    </DndProvider>
                  </EmailPurchaseOrderContextProvider>
                </SellerRoleContextProvider>
              </SellerAccountContextProvider>
            </LocalizationContextProvider>
            <ReactQueryDevtools />
          </ErrorBoundaryContextProvider>
        </ScreenSizeProvider>
      </MantineProvider>
    </ThemeProvider>
  );
}

export function App() {
  return (
    <AppTelemetryProvider>
      <QueryClientProvider {...MainQueryClient}>
        <AppContextProvider>
          <SiteContentProvider>
            <SiteThemeContextProvider>
              <SiteTimezoneContextProvider>
                <AppContent />
              </SiteTimezoneContextProvider>
            </SiteThemeContextProvider>
          </SiteContentProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </AppTelemetryProvider>
  );
}

export default App;
