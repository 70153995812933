import { Stack } from 'src/core/ui';

import * as styles from './StatLabel.css';

export const StatLabel = ({
  title,
  stat,
}: {
  title: string | React.ReactNode;
  stat: string;
}) => {
  return (
    <Stack
      direction="column"
      alignItems="start"
      justifyContent="center"
      gap="s"
      className={styles.statLabel}
    >
      <span className={styles.statStyle}>{stat}</span>
      <span className={styles.titleStyle}>{title}</span>
    </Stack>
  );
};
