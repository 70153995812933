import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';

import { ListingDetailsModalV2 } from './ListingDetailsModalV2';

export const ListingDetailsModalConfig = {
  children: <ListingDetailsModalV2 />,
  clickOutsideToClose: true,
  deepLinkKey: InventoryDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in-full',
};
