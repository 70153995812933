import { useQueries, useQuery } from '@tanstack/react-query';
import { chunk } from 'lodash-es';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  CatalogClient,
  CatalogResults,
  OnSaleEventQuery,
} from 'src/WebApiController';

export const useOnSaleEvents = (
  query: OnSaleEventQuery,
  disable = false,
  eventOnly = false
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { searchText, ...rest } = query;

  const { trackError } = useErrorBoundaryContext();

  const shouldQueryForSearch =
    !disable &&
    activeAccountWebClientConfig.activeAccountId != null &&
    searchText != null;
  const searchEventsQuery = useQuery({
    queryKey: [
      'searchEvents',
      activeAccountWebClientConfig.activeAccountId,
      searchText,
      disable,
    ],
    queryFn: () => {
      if (!shouldQueryForSearch) {
        return null;
      }
      return new CatalogClient(activeAccountWebClientConfig).searchOnSaleEvents(
        query
      );
    },
    enabled: shouldQueryForSearch,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('CatalogClient.searchOnSaleEvents', error, {
          searchText,
        });
      },
    },
  });

  const shouldQueryOnSaleEvents =
    !disable &&
    activeAccountWebClientConfig.activeAccountId != null &&
    !shouldQueryForSearch &&
    rest != null;

  const onSaleEventsQuery = useQuery({
    queryKey: [
      'getCatalogForOnSaleEvents',
      activeAccountWebClientConfig.activeAccountId,
      JSON.stringify(rest),
      disable,
    ],
    queryFn: () => {
      if (!shouldQueryOnSaleEvents) {
        return null;
      }
      return new CatalogClient(
        activeAccountWebClientConfig
      ).getCatalogForOnSaleEvents(query!);
    },

    enabled: shouldQueryOnSaleEvents,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('CatalogClient.getCatalogForOnSaleEvents', error, {
          query,
        });
      },
    },
  });

  const isLoading = useMemo(
    () => onSaleEventsQuery.isLoading || searchEventsQuery.isLoading,
    [onSaleEventsQuery.isLoading, searchEventsQuery.isLoading]
  );

  const onSaleEvents = useMemo((): CatalogResults | null => {
    const events = searchEventsQuery.data ?? onSaleEventsQuery.data ?? null;
    for (const key in events?.events ?? {}) {
      if (isNaN(parseInt(key))) {
        delete events?.events[key];
      }
    }
    return events;
  }, [onSaleEventsQuery.data, searchEventsQuery.data]);

  const eventIdGroups = useMemo(
    () =>
      chunk(
        Object.keys(onSaleEvents?.events ?? {})
          .map((id) => parseInt(id))
          .filter((id) => !isNaN(id)),
        1000
      ),
    [onSaleEvents]
  );

  const queries = useMemo(() => {
    const client = new CatalogClient(activeAccountWebClientConfig);
    return {
      queries: eventIdGroups.map((idGroup) => {
        const shouldQuery =
          !disable &&
          !eventOnly &&
          idGroup.length > 0 &&
          !!activeAccountWebClientConfig.activeAccountId;

        return {
          queryKey: [
            'getCatalogMetricsForOnSaleEvents',
            activeAccountWebClientConfig.activeAccountId,
            JSON.stringify(idGroup),
            disable,
            eventOnly,
          ],
          queryFn: async () => {
            if (!shouldQuery) {
              return null;
            }

            const input: OnSaleEventQuery = {
              ...query,
              eventIds: Array.from(idGroup),
            };

            return await client.getCatalogOnSaleEventMetrics(input);
          },
          enabled: shouldQuery,
          refetchOnWindowFocus: false,
          onError: (error: ErrorTypes) => {
            trackError(
              'CatalogClient.getCatalogMetricsForOnSaleEvents',
              error,
              {
                eventIds: idGroup,
              }
            );
          },
        };
      }),
    };
  }, [activeAccountWebClientConfig, eventIdGroups, query, trackError]);

  const metricsQueriesFromHook = useQueries(queries);

  return {
    onSaleEvents,
    onSaleEventMetrics: metricsQueriesFromHook.flatMap((q) => q.data ?? []),
    isLoading,
  };
};
