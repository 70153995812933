export enum MainRoute {
  SignUp = 'SignUp',
  Login = 'Login',
  LoginCallback = 'LoginCallback',
  Logout = 'Logout',
  LoginFailure = 'LoginFailure',
  Home = 'Home',
  Inventory = 'Inventory',
  InventorySearch = 'InventorySearch',
  Sales = 'Sales',
  SalesSearch = 'SalesSearch',
  Purchases = 'Purchases',
  PurchaseSearch = 'PurchaseSearch',
  RedirectToUK = 'RedirectToUK',
  SellerAccountSetting = 'SellerAccountSetting',
  Activity = 'Activity',
  Notifications = 'Notifications',
  ReleaseNotes = 'ReleaseNotes',
  Reports = 'Reports',
  ReportsV2 = 'ReportsV2',
  SyncCenter = 'SyncCenter',
  Email = 'Email',
  Settings = 'Settings',
  SponsoredListings = 'SponsoredListings',
  Payments = 'Payments',
  Discovery = 'Discovery',
  HelpCenter = 'HelpCenter',
  InventoryEvent = 'InventoryEvent',
  PurchasesEvent = 'PurchasesEvent',
  SalesEvent = 'SalesEvent',
}
