import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CompanionIcon = (props: Omit<PosIconProps, 'viewBox'>) => {
  return (
    <SvgWrapper
      viewBox="0 0 14 14"
      {...props}
      fill="none"
      stroke={props.stroke ?? '#677383'}
    >
      <path
        d="M12.25 8.4V11.0833C12.25 11.7277 11.7277 12.25 11.0833 12.25H2.91667C2.27233 12.25 1.75 11.7277 1.75 11.0833V2.91667C1.75 2.27233 2.27233 1.75 2.91667 1.75H5.6M12.25 5.65833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75H8.34167"
        strokeWidth="1.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12354 5.59998L11.6235 2.09998"
        strokeWidth="1.225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
