import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode, useEffect } from 'react';

/**
 * Extends functionality of any component to inherit sort functionality
 */
export const DropWrapper = ({
  children,
  id,
  disabled,
  onDragStart,
  onDragEnd,
}: {
  children: ReactNode;
  id: UniqueIdentifier;
  disabled?: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  useEffect(() => {
    if (isDragging) {
      onDragStart?.();
    } else {
      onDragEnd?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <div
      ref={setNodeRef}
      style={{
        cursor: 'move',
        transform: CSS.Translate.toString(transform),
        transition: transition,
        ...(isDragging
          ? {
              opacity: 0.5,
            }
          : {}),
        position: 'relative',
        zIndex: isDragging ? 10 : 0, // Ensure dragged item is above others
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};
