import { debounce, noop } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { EventEntitySearchBox } from 'src/components/Accordions/EventEntitySearchBox';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { OnSaleEventQuery } from 'src/WebApiController';

export function OnSaleEventSearchBox() {
  const [searchText, setSearchText] = useState('');

  const {
    filterQuery: onSaleEventQuery,
    tempQuery,
    setFilterQuery,
  } = useFilterQueryContext<OnSaleEventQuery>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== onSaleEventQuery.searchText) {
        setFilterQuery({
          ...onSaleEventQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [onSaleEventQuery]
  );

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  useEffect(() => {
    setSearchText(onSaleEventQuery.searchText ?? '');
  }, [onSaleEventQuery.searchText]);

  return (
    <EventEntitySearchBox
      minWidth={350}
      placeholderContentId={ContentId.SearchEventPlaceholderText}
      onIdLookup={noop}
      onEventSearch={(value) => debouncedOnSearchChange(value)}
      searchText={searchText}
      setSearchText={setSearchText}
      clearSearchEntity={clearSearchEntity}
    />
  );
}
