import { isEmpty } from 'lodash-es';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { BLOCKED_PRIORITY } from 'src/utils/ticketTypeUtils';
import {
  DeliveryType,
  SyncCenterSettings,
  TicketType,
  TicketTypePriorityOrder,
  TicketTypeRule,
} from 'src/WebApiController';

import { SyncCenterFieldValues } from './SyncCenter.types';

export const getTicketTypePriorityOptions = (len: number) => {
  const options: { [key: string]: string } = {
    [ContentId.ResetAll]: ContentId.ResetAll,
    [ContentId.OnlyThis]: ContentId.OnlyThis,
    [`${BLOCKED_PRIORITY}`]: ContentId.Blocked,
  };
  for (let i = 1; i <= len; i++) {
    const order = i.toString();
    options[order] = order;
  }
  return options;
};

export const getListingPackQuantityOptions = (len: number) => {
  const options: { [key: string]: string } = {};
  for (let i = 1; i <= len; i++) {
    options[i] = `${i}+`;
  }
  return options;
};

export function toSyncCenterFieldValues({
  ticketTypeRules,
  ...settings
}: SyncCenterSettings): SyncCenterFieldValues {
  const fieldValues: SyncCenterFieldValues = { ...settings };
  fieldValues.ticketTypeRules = {};
  if (ticketTypeRules) {
    for (const { deliveryType, ticketTypePriorityOrders } of ticketTypeRules) {
      if (deliveryType != null) {
        const ticketTypePriorityOrderMap: {
          [key in TicketType]?: number;
        } = {};
        for (const {
          ticketType,
          priorityOrder,
          blocked = false,
        } of ticketTypePriorityOrders) {
          if (blocked) {
            ticketTypePriorityOrderMap[ticketType] = BLOCKED_PRIORITY;
          } else if (priorityOrder != null) {
            ticketTypePriorityOrderMap[ticketType] = priorityOrder;
          }
        }
        fieldValues.ticketTypeRules[deliveryType] = ticketTypePriorityOrderMap;
      }
    }
  }
  /* JavaScript has a bug where 0.07 * 100 = 7.00000000001 (and various similar problems exists)
    The way is to realize how many maximum decimals we need and just round about that out
  */
  if (fieldValues.priceFloor) {
    fieldValues.priceFloor = roundToPrecision(fieldValues.priceFloor! * 100, 2);
  }
  if (fieldValues.marketplaceSettings) {
    for (let i = 0; i < fieldValues.marketplaceSettings.length; i++) {
      if (fieldValues.marketplaceSettings[i].markup) {
        fieldValues.marketplaceSettings[i].markup = roundToPrecision(
          fieldValues.marketplaceSettings[i].markup! * 100,
          3
        );
      }

      if (fieldValues.marketplaceSettings[i].sellerFee) {
        fieldValues.marketplaceSettings[i].sellerFee = roundToPrecision(
          fieldValues.marketplaceSettings[i].sellerFee! * 100,
          3
        );
      }
    }
  }

  return fieldValues;
}

export function toSyncCenterSettings({
  ticketTypeRules,
  ...fieldValues
}: SyncCenterFieldValues): SyncCenterSettings {
  const settingsTicketTypeRules: TicketTypeRule[] = [];
  if (ticketTypeRules) {
    for (const [key, ticketTypePriorityOrderMap] of Object.entries(
      ticketTypeRules
    )) {
      if (ticketTypePriorityOrderMap) {
        const ticketTypePriorityOrders: TicketTypePriorityOrder[] = [];
        for (const [key, value] of Object.entries(ticketTypePriorityOrderMap)) {
          if (value) {
            ticketTypePriorityOrders.push({
              ticketType: key as TicketType,
              priorityOrder: value === -1 ? null : value,
              blocked: value === -1 ? true : null,
            });
          }
        }
        if (ticketTypePriorityOrders.length > 0) {
          settingsTicketTypeRules.push({
            deliveryType: key as DeliveryType,
            ticketTypePriorityOrders,
          });
        }
      }
    }
  }
  /* Again, JavaScript has a bug where 0.7 * 0.01 = 0.006999999999999999 (and various similar problems exists)
    The way is to realize how many maximum decimals we need (e.g. check in DB) and just round about that out
  */
  if (fieldValues.priceFloor) {
    fieldValues.priceFloor = roundToPrecision(
      fieldValues.priceFloor! * 0.01,
      4
    );
  }
  if (fieldValues.marketplaceSettings) {
    for (let i = 0; i < fieldValues.marketplaceSettings.length; i++) {
      if (fieldValues.marketplaceSettings[i].markup) {
        fieldValues.marketplaceSettings[i].markup = roundToPrecision(
          fieldValues.marketplaceSettings[i].markup! * 0.01,
          5
        );
      }
      if (fieldValues.marketplaceSettings[i].sellerFee) {
        fieldValues.marketplaceSettings[i].sellerFee = roundToPrecision(
          fieldValues.marketplaceSettings[i].sellerFee! * 0.01,
          5
        );
      }
    }
  }

  const settings: SyncCenterSettings = {
    ...fieldValues,
    ticketTypeRules: settingsTicketTypeRules,
  };
  return settings;
}

export const setValueAsNumber = (value: string) =>
  value === '' || value == null ? null : Number(value);

export const setValueAsDecimalNumber = (value: string) =>
  value === '' || value == null ? null : Number(value) / 100;
