import {
  QueryObserverResult,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import {
  PurchaseOrder,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
  TimePeriodContainingPurchaseOrderResult,
  TimePeriodType,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { ErrorTypes } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import {
  PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY,
  PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY,
} from './PurchaseDataContext.constants';
import { useGetAllPurchasesQuery } from './useGetAllPurchasesQuery';
import { useGetTimePeriodPurchasesQuery } from './useGetTimePeriodPurchasesQuery';

export type IPurchaseDataContext = {
  timePeriodPurchasesQuery: UseQueryResult<
    TimePeriodContainingPurchaseOrderResult[] | null,
    ErrorTypes
  >;
  allPurchasesQuery: UseQueryResult<PurchaseOrder[] | null>;
  allPurchasesFiltered: PurchaseOrder[] | null | undefined;
  purchaseCount: number;
  refreshData: (
    refetchAll?: boolean
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  onSelect: (targetId: string, forceSelect?: boolean) => void;
  selectedIds: string[];
  onSetActivePurchaseOrderCallback: (
    item: PurchaseOrderDetails,
    forceRefresh?: boolean
  ) => Promise<void>;
  scrollToId?: string;
  setScrollToId: (id?: string) => void;
  isFlattenedView: boolean;
  purchaseGroupTimePeriod: Exclude<TimePeriodType, TimePeriodType.Daily>;
  setPurchaseGroupTimePeriod: (
    timePeriod: Exclude<TimePeriodType, TimePeriodType.Daily>
  ) => void;
};

export const PurchaseDataContext = createContext<IPurchaseDataContext>({
  timePeriodPurchasesQuery: {} as UseQueryResult<
    TimePeriodContainingPurchaseOrderResult[] | null
  >,
  allPurchasesQuery: {} as UseQueryResult<PurchaseOrder[] | null>,
  allPurchasesFiltered: [],
  purchaseCount: 0,
  refreshData: () =>
    Promise.resolve({} as QueryObserverResult<unknown, unknown>),
  selectedIds: [],
  onSelect: () => {},
  onSetActivePurchaseOrderCallback: () => Promise.resolve(),
  setScrollToId: () => {},
  isFlattenedView: false,
  purchaseGroupTimePeriod: TimePeriodType.Weekly,
  setPurchaseGroupTimePeriod: () => {},
});

export const usePurchaseDataContext = () => useContext(PurchaseDataContext);

export const PurchaseDataContextProvider = ({
  useReadonlyDB,
  viewModeOverride,
  ignoreMaxCount,
  children,
  doNotQueryForPurchases,
}: {
  children: React.ReactNode;
  useReadonlyDB?: boolean;
  viewModeOverride?: PurchaseViewMode;
  ignoreMaxCount?: boolean;
  doNotQueryForPurchases?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();
  const activeViewMode = useMemo(
    () => viewModeOverride ?? filterQuery.viewMode ?? PurchaseViewMode.TileView,
    [filterQuery.viewMode, viewModeOverride]
  );

  const { totalPurchaseCount } = useCatalogDataContext();

  const { activeAccountWebClientConfig } = useAppContext();
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [scrollToId, setScrollToId] = useState<string | undefined>();
  const [purchaseGroupTimePeriod, setPurchaseGroupTimePeriod] = useState<
    Exclude<TimePeriodType, TimePeriodType.Daily>
  >(TimePeriodType.Weekly);

  const onSelect = useCallback(
    (targetId: string, forceSelect?: boolean) => {
      const indexOf = selectedIds.indexOf(targetId);

      if (indexOf >= 0) {
        // if force-select, we won't de-select if it's already selected
        if (forceSelect) {
          setScrollToId(targetId);
        } else {
          setSelectedIds(selectedIds.filter((id) => id !== targetId));
        }
      } else {
        setSelectedIds([...selectedIds, targetId]);
        setScrollToId(targetId);
      }
    },
    [selectedIds]
  );

  const timePeriodPurchasesQuery = useGetTimePeriodPurchasesQuery(
    selectedIds,
    setSelectedIds,
    setErrorInfo,
    purchaseGroupTimePeriod,
    activeViewMode !== PurchaseViewMode.TileView // disabled
  );

  const purchaseCount = useMemo(() => {
    let purchaseCount = 0;

    if (activeViewMode === PurchaseViewMode.TileView) {
      (timePeriodPurchasesQuery.data ?? []).forEach((ev) => {
        purchaseCount += ev.purchaseCount;
      });
    } else if (activeViewMode === PurchaseViewMode.FlattenedView) {
      purchaseCount = totalPurchaseCount ?? 0;
    }

    return purchaseCount;
  }, [activeViewMode, timePeriodPurchasesQuery.data, totalPurchaseCount]);

  const { allPurchasesQuery, allPurchasesFiltered } = useGetAllPurchasesQuery(
    useReadonlyDB,
    activeViewMode,
    ignoreMaxCount,
    doNotQueryForPurchases
  );

  const onSetActivePurchaseOrderCallback = useCallback(
    async (item: PurchaseOrderDetails, forceRefresh?: boolean) => {
      if (item.poDate) {
        if (forceRefresh) {
          // Refetch the month accordions
          const refetchQuery = await timePeriodPurchasesQuery.refetch();

          const targetTimePeriodIds = refetchQuery.data
            ?.filter((d) => d.firstOfTimePeriod <= item.poDate!)
            .map((d) => d.firstOfTimePeriod)
            .toSorted();
          if (targetTimePeriodIds?.length) {
            const targetTimePeriodId =
              targetTimePeriodIds[targetTimePeriodIds.length - 1];
            onSelect(targetTimePeriodId, true);
          }
        }
      }
    },
    [timePeriodPurchasesQuery, onSelect]
  );

  const refreshData = useCallback(
    async (refetchAll?: boolean) => {
      if (refetchAll) {
        queryClient.invalidateQueries({
          queryKey: [
            PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY,
            activeAccountWebClientConfig.activeAccountId,
          ],
        });
        // For the months one we want to just query all - since this is each month ()
        queryClient.invalidateQueries({
          queryKey: [PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY, true],
          type: 'all',
        });
      }

      await allPurchasesQuery.refetch();
      return timePeriodPurchasesQuery.refetch();
    },
    [
      activeAccountWebClientConfig.activeAccountId,
      allPurchasesQuery,
      timePeriodPurchasesQuery,
      queryClient,
    ]
  );

  if (timePeriodPurchasesQuery.failureReason) {
    const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
      null,
      timePeriodPurchasesQuery.failureReason.message
    );
    setErrorInfo({
      errorHeader: headerDisplay,
      errorMessage: messageDisplay,
    });
  }

  return (
    <PurchaseDataContext.Provider
      value={{
        timePeriodPurchasesQuery,
        allPurchasesQuery,
        allPurchasesFiltered,
        purchaseCount,
        refreshData,
        errorInfo,
        selectedIds,
        onSelect,
        scrollToId,
        setScrollToId,
        onSetActivePurchaseOrderCallback,
        isFlattenedView: activeViewMode === PurchaseViewMode.FlattenedView,
        purchaseGroupTimePeriod,
        setPurchaseGroupTimePeriod,
      }}
    >
      {children}
    </PurchaseDataContext.Provider>
  );
};
