import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { Stack } from 'src/core/ui';
import { FieldError } from 'src/modals/common';
import { PurchaseEventItemHeader } from 'src/modals/common/Purchase/PurchaseEventItemHeader';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency } from 'src/utils/numberFormatter';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { getTicketGroupInputTotalCost } from 'src/utils/ticketUtils';
import {
  Event,
  Performer,
  TicketGroupEditReason,
  TicketGroupInput,
  Venue,
} from 'src/WebApiController';

import { TicketGroupWithHandlers } from './configs/TicketGroupTableColumnsConfig';
import * as styles from './PurchaseTicketTable.css';
import {
  PurchaseEventItemHeaderWrapper,
  PurchaseTicketTableContainer,
} from './PurchaseTicketTable.styled';
import { TicketGroupTable } from './TicketGroupTable';

export function PurchaseTicketTable({
  currencyCode,
  disabled,
  ticketGroups,
  highlightTicketGroupIds,
  onTicketGroupAction,
}: {
  disabled?: boolean;
  currencyCode: string;
  ticketGroups?: TicketGroupInput[];
  highlightTicketGroupIds?: number[];
} & Pick<TicketGroupWithHandlers, 'onTicketGroupAction'>) {
  const { getFieldState } = useFormContext<PurchaseOrderDetailsInput>();
  const uniqueEvents = useMemo(
    () =>
      ticketGroups?.reduce(
        (events, tg) => {
          const eventId =
            tg.event?.viagVirtualId ??
            tg.viagogoEventId?.toString() ??
            tg.eventMappingId;
          if (eventId && !events[eventId]) {
            events[eventId] = {
              event: tg.event!,
              performer: tg.performer,
              venue: tg.venue,
            };
          }

          return events;
        },
        {} as Record<
          string,
          { event: Event; performer?: Performer | null; venue?: Venue | null }
        >
      ),
    [ticketGroups]
  );

  const events = Object.values(uniqueEvents || []);

  return (
    <>
      {events.length ? (
        events
          .filter((ev) => ev.event)
          .map((ev) => {
            const eventTicketGroups = ticketGroups
              ?.map((tg, i) => ({ tg, i }))
              .filter(
                (tg) => tg.tg.event?.viagVirtualId === ev.event.viagVirtualId
              );
            const totalCost =
              eventTicketGroups?.reduce((sum, tg) => {
                sum += getTicketGroupInputTotalCost(tg.tg);
                return sum;
              }, 0) || 0;

            return (
              <PurchaseTicketTableContainer key={ev.event.viagVirtualId}>
                <PurchaseEventItemHeaderWrapper>
                  <PurchaseEventItemHeader
                    removeMetrics
                    disabled={disabled}
                    event={ev.event}
                    performer={ev.performer}
                    venue={ev.venue}
                    hideYearFromDate
                    numOfTicketGroups={eventTicketGroups?.length || 0}
                    numOfTickets={(eventTicketGroups ?? []).reduce(
                      (r, tg) => r + (tg.tg.numberOfTickets?.value ?? 0),
                      0
                    )}
                    cost={formatCurrency(totalCost, currencyCode)}
                    onAddTicketsForEvent={
                      onTicketGroupAction
                        ? (e, p, v) => {
                            onTicketGroupAction({
                              ticketGroupId: -1,
                              action: TicketGroupEditReason.AddTickets,
                              additionalData: {
                                event: e,
                                performer: p,
                                venue: v,
                              },
                            });
                          }
                        : undefined
                    }
                  />
                </PurchaseEventItemHeaderWrapper>
                <div className={styles.purchaseTicketGroupsWrapper}>
                  <TicketGroupTable
                    groupId={NO_GROUP_ID}
                    disabled={disabled}
                    entities={eventTicketGroups?.map((tg) => tg.tg) ?? []}
                    entityCount={eventTicketGroups?.length ?? 0}
                    highlightTicketGroupIds={highlightTicketGroupIds}
                    onTicketGroupAction={onTicketGroupAction}
                    event={ev.event}
                    performer={ev.performer}
                    venue={ev.venue}
                    getDataFail={false}
                  />
                  <Stack direction="column">
                    {eventTicketGroups?.flatMap((tg) => {
                      const errors = [];
                      const tgFieldState = getFieldState(
                        `ticketGroups.${tg.i}`
                      );
                      if (tgFieldState.error?.message) {
                        errors.push(
                          <FieldError key={tg.i}>
                            {tgFieldState.error.message}
                          </FieldError>
                        );
                      }
                      tg.tg.tickets?.value?.forEach((t, j) => {
                        const seatField = getFieldState(
                          `ticketGroups.${tg.i}.tickets.value.${j}.seat`
                        );
                        if (seatField.error?.message) {
                          errors.push(
                            <FieldError key={`${tg.i}-${j}`}>
                              {seatField.error.message}
                            </FieldError>
                          );
                        }
                      });

                      return errors;
                    })}
                  </Stack>
                </div>
              </PurchaseTicketTableContainer>
            );
          })
      ) : (
        <Content id={ContentId.None} />
      )}
    </>
  );
}
