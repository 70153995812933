import { useCallback } from 'react';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { FilterToolbarEmbedded } from 'src/components/FilterToolbarEmbedded';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { EntityWithTicketsQuery, SearchResult } from 'src/WebApiController';

import * as styles from './EntitySearchFilterBar.css';

export const EntitySearchFilterBar = <T extends EntityWithTicketsQuery>({
  filters,
  activeSearchConfig,
  onSubmitFilter,
  selectedFilters,
  mandatoryFiltersToShow,
  ...rest
}: {
  filters: FilterToolbarGroup[];
  activeSearchConfig?: SearchResult;
  onSubmitFilter?: (query: T) => void;
  selectedFilters?: FilterDropdownItem[];
  setSelectedFilters: (sf: FilterDropdownItem[]) => void;
  mandatoryFiltersToShow: FilterToolbarItemId[];
}) => {
  const {
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<QueryWithViewMode & T>();

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.(tempQuery);
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow,
    initialQuery,
  });

  return (
    <Stack
      gap="m"
      alignItems="center"
      className={styles.container}
      width="full"
    >
      <FilterToolbarEmbedded
        onResetAll={() => {
          setTempQuery(initialQuery);
        }}
        filters={filters}
        appliedFilterIds={appliedFilters}
        mandatoryFiltersToShow={mandatoryFiltersToShow}
        resetTempQuery={resetTempQuery}
        tempQuery={tempQuery}
        selectedFilters={selectedFilters}
        {...rest}
      />
      <Button
        onClick={onSubmitFilterHandler}
        disabled={!Object.keys(selectedFilters ?? {}).length}
      >
        <Content id={ContentId.Search} />
      </Button>
    </Stack>
  );
};
