import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { Colors } from 'src/core/themes/constants/colors';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function TableSortingIcon({
  width,
  height,
  isActive,
  ...props
}: PosIconProps & {
  isActive?: boolean;
}) {
  const { isDarkMode } = useSiteTheme();
  const fill = isDarkMode
    ? isActive
      ? Colors.indigo600
      : Colors.neutral075
    : isActive
    ? Colors.indigo100
    : Colors.neutral500;

  const stroke = isDarkMode
    ? isActive
      ? Colors.neutral025
      : Colors.neutral100
    : isActive
    ? Colors.white
    : Colors.neutral100;

  return (
    <SvgWrapper
      {...props}
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="2" fill={fill} />
      <path
        d="M8 4.99976L11 7.99976M8 4.99976V11M8 4.99976L5 7.99976"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
}
