import { debounce } from 'lodash-es';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { FilterDropdownItem, FilterToolbarGroup } from 'src/components/Filters';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { vars } from 'src/core/themes';
import { Button, ButtonProps, Stack } from 'src/core/ui';
import { PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AddFilterDropdown.css';

export interface AddFilterDropdownProps {
  filters: FilterToolbarGroup[];
  onFilterSelected: (filterDropdownItem: FilterDropdownItem[]) => void;
  hideLabel?: boolean;
  hideIcon?: boolean;
  triggerVariant?: ButtonProps['variant'];
  triggerShape?: ButtonProps['shape'];
  triggerClassName?: string;
  disabled?: boolean;
}

const triggerStyles: CSSProperties = {
  width: '100%',
};

export const AddFilterDropdown = ({
  filters,
  onFilterSelected,
  hideLabel,
  hideIcon,
  triggerVariant = 'regular',
  triggerShape = 'rect',
  triggerClassName,
  disabled,
}: AddFilterDropdownProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const searchPlaceholder = useContent(ContentId.Search);
  const contentContext = useContentContext();

  // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
  const updateSearchText = useCallback(
    debounce((value: string) => {
      setSearchText(value);
    }, 200),
    []
  );

  const filteredFilters = useMemo<FilterToolbarGroup[]>(() => {
    if (!searchText) {
      return filters;
    }
    const lowercaseSearchText = searchText.toLocaleLowerCase();
    return filters
      .map((filter) => {
        const items = filter.items.filter((filterItem) => {
          const labelValue = getContent(
            filterItem.labelContentId,
            contentContext
          );
          return (
            labelValue.toLocaleLowerCase().indexOf(lowercaseSearchText) !== -1
          );
        });
        return {
          ...filter,
          items,
        };
      })
      .filter((filter) => filter.items.length > 0);
  }, [contentContext, filters, searchText]);

  const clearSearch = useCallback(() => {
    setSearchText('');
  }, []);

  return (
    <PosDropdown
      key="event-action-dropdown"
      trigger={
        <Button
          variant={triggerVariant}
          shape={triggerShape}
          onClick={clearSearch}
          className={triggerClassName}
          disabled={disabled}
        >
          <Stack gap="m" alignItems="center">
            {!hideIcon && (
              <PlusIcon
                withHoverEffect
                size={vars.iconSize.s}
                fill={vars.color.textInverted}
              />
            )}

            {!hideLabel && <Content id={ContentId.AddFilter} />}
          </Stack>
        </Button>
      }
      triggerProps={{
        style: triggerStyles,
        disabled: disabled,
      }}
      align="start"
    >
      <PosDropdownItem
        keepOpenAfterSelection={true}
        className={styles.searchDropdownItem}
      >
        <PosSearchBox
          placeholder={searchPlaceholder}
          width="100%"
          onSearchChange={updateSearchText}
          rootProps={{
            shape: 'square',
          }}
          focusOnMount={true}
        />
      </PosDropdownItem>

      {filteredFilters.flatMap((filtersToolbarGroup, i) => {
        const items = filtersToolbarGroup.items.map((filterItem) => {
          return (
            <PosDropdownItem
              key={filterItem.filterId}
              onClick={() => {
                onFilterSelected([
                  {
                    filterId: filterItem.filterId,
                    titleContentId: filtersToolbarGroup.titleContentId,
                  },
                ]);
              }}
            >
              <Content id={filterItem.labelContentId} />
            </PosDropdownItem>
          );
        });
        return [
          <div key={`item-group-${filtersToolbarGroup.titleContentId}-${i}`}>
            <div className={styles.dropdownGroupContainer}>
              <PosDropdownItem
                keepOpenAfterSelection
                className={styles.dropdownItemParentLabel}
              >
                <Content id={filtersToolbarGroup.titleContentId} />
              </PosDropdownItem>
              {items}
            </div>
          </div>,
        ];
      })}

      {filteredFilters.length === 0 && (
        <PosDropdownItem
          keepOpenAfterSelection
          className={styles.notFoundOption}
        >
          <Content id={ContentId.NoResultFound} />
        </PosDropdownItem>
      )}
    </PosDropdown>
  );
};
