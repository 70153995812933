import { useMemo } from 'react';
import { FilterPillList } from 'src/components/common/FilterPill/FilterPillList';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Listing } from 'src/WebApiController';

import { useAutoPricingSettings } from '../useAutoPricingSettings';

/**
 * @deprecated - Do not use
 */
export const AutoPricingSectionRowIdFilter = ({
  listing,
  disabled,
}: {
  listing?: Listing | null;
  disabled?: boolean;
}) => {
  const { venueMapQuery } = useEventMapContext();
  const {
    onCompListingChange,
    onCompListingSectionIdFilterChange,
    compListingSelectedSectionSettings,
  } = useAutoPricingSettings(listing);

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapQuery.data?.sections.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [
    compListingSelectedSectionSettings?.sectionIdFilter,
    venueMapQuery.data?.sections,
  ]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const applyCompListingChanges = (
    rowIdFilters: number[],
    sectionRowIdFilters: { [x: string]: string }
  ) => {
    const compListingSelectedSectionSettingsNew = {
      ...compListingSelectedSectionSettings,
      sectionIdFilter:
        compListingSelectedSectionSettings?.sectionIdFilter ?? [],
      rowIdFilter: Array.from(new Set(rowIdFilters)),
      sectionRowIdFilter: sectionRowIdFilters,
    };

    onCompListingChange({
      compListingSelectedSectionSettingsNew,
      compListingOnlyForSelectedSectionsEnabledNew: true,
    });
  };

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <FilterPillList
        disabled={disabled}
        selectedSections={selectedSections}
        rowIdFilters={rowIdFilters}
        sectionRowIdFilters={sectionRowIdFilters}
        applyRowFilterChanges={applyCompListingChanges}
        applySelectedSectionChanges={onCompListingSectionIdFilterChange}
      />
    </div>
  );
};
