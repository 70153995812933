import {
  functionalUpdate,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { Table } from 'src/tables/Table';
import { SellerUserAccountInfo } from 'src/WebApiController';
import { useGetFilteredUsers } from './hooks/useGetFilteredUsers';
import { USERS_TABLE_COLUMNS_CONFIG } from './UsersTableColumnsConfig';

export const PAGE_SIZE = 10;

export const UsersTable = ({ searchText }: { searchText: string }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);
  const { allUserInfos } = useSellerAccountContext();

  const data = useMemo(() => {
    return Object.values(allUserInfos ?? {});
  }, [allUserInfos]);

  const filteredUsers = useGetFilteredUsers({
    users: data,
    searchText,
  });

  const options: Partial<TableOptions<SellerUserAccountInfo | null>> = {
    data: filteredUsers,
    columns: USERS_TABLE_COLUMNS_CONFIG,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: (sortingUpdaterFn) => {
      const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
      setSorting(newSortVal);
    },
  };

  return (
    <DialogProvider>
      <Table options={options} withOuterPadding={true} roundBorders={true} />
    </DialogProvider>
  );
};
