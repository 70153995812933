import { createColumnHelper } from '@tanstack/react-table';
import { ConnectedEventInfo } from 'src/components/Events/ConnectedEventInfo';
import {
  EventDateDisplay,
  VenueNameDisplay,
} from 'src/components/Events/EventInfo';
import {
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_DEFAULT_SIZE_XXL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOption, FormatOptionEntries } from 'src/modals/EditTableColumns';
import * as tableStyles from 'src/tables/Table/Table.css';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ListingTableFlattenedColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  compareEventsByDate,
  compareEventsByName,
} from 'src/utils/eventWithDataUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { SectionType } from 'src/utils/types/sectionType';
import { DateTimeRange, UserSetting, Venue } from 'src/WebApiController';

import {
  ListingWithEvent,
  SHIMMERING_DIV_HEIGHT_LISTING_GROUP,
  SHIMMERING_DIV_HEIGHT_SINGLE_LISTING,
} from '../ListingTable.types';

const columnHelper = createColumnHelper<ListingWithEvent | null>();

export const LISTING_TABLE_FLATTENED_COLUMNS_CONFIG = {
  [ListingTableFlattenedColumnId.Event]: columnHelper.accessor((data) => data, {
    id: ListingTableFlattenedColumnId.Event,
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Event} />
      </TableCellDiv>
    ),
    enableColumnFilter: false,
    size: COLUMN_DEFAULT_SIZE_XXL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original }, table }) {
      const listing = getValue<ListingWithEvent>();
      const { value: storedInventoryFlattenedColumnNumberPrecisions = {} } =
        useServerUserSetting<FormatOptionEntries>({
          id: UserSetting.InventoryFlattenedColumnNumberPrecision,
        });

      if (!listing?.listing) {
        return (
          <TableShimmeringDiv
            height={
              original?.isLtGrp
                ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
            }
          />
        );
      }

      if (!listing.event) {
        return null;
      }

      const customPrecision =
        table.options.meta?.formatOptionOverrides?.[
          ListingTableFlattenedColumnId.Event
        ];

      const eventNameOnly = customPrecision
        ? customPrecision === FormatOption.Event_EventName
        : storedInventoryFlattenedColumnNumberPrecisions[
            ListingTableFlattenedColumnId.Event
          ] === FormatOption.Event_EventName;

      return (
        <TableCellDiv align="left" title={listing.event?.name}>
          <ConnectedEventInfo
            showYear={true}
            showEventDateTbd
            event={listing.event}
            performer={listing.performer}
            venue={listing.venue}
            eventNameOnly={eventNameOnly}
            slimMode
            useShortName
          />
        </TableCellDiv>
      );
    },
    sortingFn: compareEventsByName,
  }),
  [ListingTableFlattenedColumnId.Venue]: columnHelper.accessor(
    (data) => data?.venue,
    {
      id: ListingTableFlattenedColumnId.Venue,
      header: () => (
        <TableCellDiv align="left">
          <Content id={ContentId.Venue} />
        </TableCellDiv>
      ),
      enableColumnFilter: false,
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const venue = getValue<Venue>();
        if (!original || !original?.listing) {
          return (
            <TableShimmeringDiv
              height={
                original?.isLtGrp
                  ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                  : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
              }
            />
          );
        }
        return (
          <TableCellDiv
            align="left"
            title={venue?.name}
            className={tableStyles.basicFont}
          >
            <VenueNameDisplay
              venueName={venue?.shortName || venue?.name}
              slimMode
              showTitle={false}
            />
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingTableFlattenedColumnId.Location]: columnHelper.accessor(
    (data) => data?.venue,
    {
      id: ListingTableFlattenedColumnId.Location,
      header: () => (
        <TableCellDiv align="left">
          <Content id={ContentId.Location} />
        </TableCellDiv>
      ),
      enableColumnFilter: false,
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const venue = getValue<Venue>();
        if (!original || !original?.listing) {
          return (
            <TableShimmeringDiv
              height={
                original?.isLtGrp
                  ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                  : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
              }
            />
          );
        }
        return (
          <TableCellDiv
            align="left"
            title={venue?.descr ?? undefined}
            className={tableStyles.basicFont}
          >
            <VenueNameDisplay venueLocation={venue?.descr} slimMode />
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingTableFlattenedColumnId.EventDate]: columnHelper.accessor(
    (data) => data?.event?.dates,
    {
      id: ListingTableFlattenedColumnId.EventDate,
      header: () => (
        <TableCellDiv align="left">
          <Content id={ContentId.EventDate} />
        </TableCellDiv>
      ),
      enableColumnFilter: false,
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original }, column, table }) {
        const columnId = column.id;

        const value = getValue<DateTimeRange | undefined>();
        const {
          userDefinedPrecision = FormatOption.DateTime_MonthDD_Time_Day,
        } = useColumnUserSetting(columnId, SectionType.Events);

        if (!original || !original?.listing) {
          return (
            <TableShimmeringDiv
              height={
                original?.isLtGrp
                  ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                  : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
              }
            />
          );
        }

        const customPrecision =
          table.options.meta?.formatOptionOverrides?.[
            ListingTableFlattenedColumnId.EventDate
          ];

        const date = applyNumberFormatting({
          precision: customPrecision ?? userDefinedPrecision,
          inputNumber: value?.start,
          isDateTime: true,
        }) as string;

        return (
          <TableCellDiv align="left">
            {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
              <EventDateDisplay
                eventDateString={value?.start ?? undefined}
                eventEndDateString={value?.end ?? undefined}
                showEventDateTbd={true}
                eventSubDateFormat={' - h:mm aaa, eee'}
                slimMode
                className={tableStyles.basicFont}
              />
            ) : (
              <div
                style={{
                  textTransform: 'none',
                }}
              >
                {date}
              </div>
            )}
          </TableCellDiv>
        );
      },
      sortingFn: compareEventsByDate,
    }
  ),
} as const;
