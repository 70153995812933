import clsx from 'clsx';
import { ReactNode } from 'react';
import { DropWrapper } from 'src/core/POS/DragAndDropSort/DragAndDrop';
import { DragHandleIcon } from 'src/svgs/DragHandleIcon';

import * as styles from './MetricsOptionsListItem.css';

type ColumnListItemProps = {
  id: string;
  children: ReactNode;
  actionButton?: ReactNode;
  highlighted?: boolean;
  disabled?: boolean;
};

export const SortableMetricsOptionsListItem = ({
  id,
  children,
  actionButton,
  highlighted,
  disabled,
}: ColumnListItemProps) => {
  return (
    <DropWrapper id={id} disabled={disabled}>
      <MetricsOptionsListItem
        id={id}
        highlighted={highlighted}
        actionButton={actionButton}
        disabled={disabled}
      >
        {children}
      </MetricsOptionsListItem>
    </DropWrapper>
  );
};

export const MetricsOptionsListItem = ({
  children,
  actionButton,
  highlighted,
  isDragging,
}: ColumnListItemProps & {
  isDragging?: boolean;
}) => {
  return (
    <div
      className={clsx(styles.root, {
        [styles.pulsedHighlight]: highlighted,
        [styles.draggedActive]: isDragging,
      })}
    >
      <div className={styles.itemContainer}>
        <DragHandleIcon className={styles.icon} />
        <span className={styles.title}>{children}</span>
      </div>
      {actionButton && <div>{actionButton}</div>}
    </div>
  );
};
