import { useState } from 'react';
import { ExportSalesButton } from 'src/components/Buttons/ExportSalesButton';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { useBulkEditCommissionAction } from 'src/components/Sales/SalesActionDropdown/components/BulkEditCommissionAction';
import { useBulkMarkSaleAsPaid } from 'src/components/Sales/SalesActionDropdown/components/BulkMarkSaleAsPaid';
import { useBulkUpdateInternalNotesAction } from 'src/components/Sales/SalesActionDropdown/components/BulkUpdateInternalNotesAction';
import { useBulkUpdateSalesSettings } from 'src/components/Sales/SalesActionDropdown/components/BulkUpdateSalesGeneralSettings';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { useSalesBulkActionsState } from '../../useSalesBulkActionsState';
import { useUpdateSalesSettingsPermissions } from '../../useUpdateSalesSettingsPermissions';

export const SettingsActions = () => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { filterQueryWithEventIds } = useSalesBulkActionsState();

  const bulkUpdateGeneralSettings = useBulkUpdateSalesSettings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkUpdateInternalNotes = useBulkUpdateInternalNotesAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditCommissionAction = useBulkEditCommissionAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkMarkSaleAsPaid = useBulkMarkSaleAsPaid(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const { hasUpdateSalesSettingsPermission } =
    useUpdateSalesSettingsPermissions();
  const {
    hasUpdatePurchasesPermission,
    hasPurchaseUpdateCommissionPermission,
  } = useUpdatePurchasesPermissions();

  if (
    !hasUpdateSalesSettingsPermission &&
    !hasUpdatePurchasesPermission &&
    !hasPurchaseUpdateCommissionPermission
  ) {
    return null;
  }

  return (
    <>
      <PosDropdown
        key="sales-settings-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Other} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateGeneralSettings.dropDown}
        {bulkUpdateInternalNotes.dropDown}
        {bulkEditCommissionAction.dropDown}
        {bulkMarkSaleAsPaid.dropDown}
        <PosDropdownItem key="ExportSales">
          <ExportSalesButton
            style={{ color: vars.color.textStrong }}
            variant="text"
            shape="rect"
            disabled={!areBulkActionsEnabled}
            textContentId={ContentId.ExportSales}
            textOnly
          />
        </PosDropdownItem>
      </PosDropdown>
    </>
  );
};
