import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
  DEFAULT_COMP_LISTING_FLOOR,
  DEFAULT_UNDERCUT_ABSOLUTE_AMT,
  DEFAULT_UNDERCUT_RELATIVE_AMT,
} from 'src/utils/autoPricingUtils';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import { coalesceUndefinedOnly } from 'src/utils/miscUtils';
import {
  mapToSelectedSectionSettings,
  SelectedSectionSettingsWithOptionals,
} from 'src/utils/types/selectedSectionSettingsWithOptionals';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  Feature,
  Permission,
} from 'src/WebApiController';

export const useEventAutoPricingSettings = () => {
  const { setValue, watch, clearErrors } =
    useFormContext<EventPricingSeatMapForm>();
  const hasEventPricingPermission = useUserHasAnyOfPermissions(
    Permission.Events_SetPrice
  );
  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  const defaultCompListingMode = useMemo(() => {
    if (hasAllowSeatScoreCompListingModeFeature) {
      return AutoPricingCompListingMode.QualityScore;
    }
    return AutoPricingCompListingMode.SameEvent;
  }, [hasAllowSeatScoreCompListingModeFeature]);

  const canAutoPrice =
    useUserHasFeature(Feature.AutoPricing) && hasEventPricingPermission;

  const autoPriceFrequency = watch('eventAutoPricing.autoPriceFrequency');

  const undercutMode = watch('eventAutoPricing.undercutMode');
  const undercutAbsoluteAmount = watch(
    'eventAutoPricing.undercutAbsoluteAmount'
  );
  const undercutRelativeAmount = watch(
    'eventAutoPricing.undercutRelativeAmount'
  );
  const compListingMode = watch('eventAutoPricing.compListingMode');
  const compListingFloor = watch('eventAutoPricing.compListingFloor');
  const compListingCeiling = watch('eventAutoPricing.compListingCeiling');
  const compListingQuantityScoreAdjustmentEnabled = watch(
    'eventAutoPricing.compListingQuantityScoreAdjustmentEnabled'
  );
  const compListingQuantityScoreAdjustmentOverrideJson = watch(
    'eventAutoPricing.compListingQuantityScoreAdjustmentOverrideJson'
  );
  const compListingOnlyForSameZoneEnabled = watch(
    'eventAutoPricing.compListingOnlyForSameZoneEnabled'
  );
  const compListingOnlyForSelectedSectionsEnabled = watch(
    'eventAutoPricing.compListingOnlyForSelectedSectionsEnabled'
  );
  const compListingExcludeFanInventory = watch(
    'eventAutoPricing.compListingExcludeFanInventory'
  );
  const compListingSelectedSectionSettings = watch(
    'eventAutoPricing.compListingSelectedSectionSettings'
  );
  const circuitBreakerRelativeCeiling = watch(
    'eventAutoPricing.circuitBreakerRelativeCeiling'
  );
  const circuitBreakerRelativeFloor = watch(
    'eventAutoPricing.circuitBreakerRelativeFloor'
  );
  const circuitBreakerMaxDiscountVelocityPercent = watch(
    'eventAutoPricing.circuitBreakerMaxDiscountVelocityPercent'
  );
  const circuitBreakerMaxDiscountVelocityTicksInHours = watch(
    'eventAutoPricing.circuitBreakerMaxDiscountVelocityTicksInHours'
  );
  const circuitBreakerMinCompListingCount = watch(
    'eventAutoPricing.circuitBreakerMinCompListingCount'
  );
  const outlierMode = watch('eventAutoPricing.outlierMode');
  const outlierStandardDeviations = watch(
    'eventAutoPricing.outlierStandardDeviations'
  );

  const { loginContext } = useAppContext();

  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = useMemo(
    () => getUiCurrency(loginContext?.user?.activeAccount?.currencyCode),
    [getUiCurrency, loginContext?.user?.activeAccount?.currencyCode]
  );
  const currencyNumberFormat = useMemo(
    () =>
      new Intl.NumberFormat(getCurrentLanguage(), {
        style: 'currency',
        currency: uiCurrency.code,
      }),
    [uiCurrency.code]
  );

  const { event } = useEventMapContext();
  const { pricingSettings: accountPricingSettings } =
    useGetAccountAutoPricingSettings();

  const autoPricingEnabled = watch('eventWideAutoPricing');

  const onResetSettings = useCallback(() => {
    setValue('eventAutoPricing.undercutMode', null);
    setValue('eventAutoPricing.undercutAbsoluteAmount', null);
    setValue('eventAutoPricing.undercutRelativeAmount', null);

    setValue('eventAutoPricing.compListingMode', null);
    setValue('eventAutoPricing.compListingFloor', null);
    setValue('eventAutoPricing.compListingCeiling', null);
    setValue('eventAutoPricing.compListingSelectedSectionSettings', null);
    setValue(
      'eventAutoPricing.compListingQuantityScoreAdjustmentOverrideJson',
      null
    );
    setValue('eventAutoPricing.compListingOnlyForSameZoneEnabled', null);
    setValue(
      'eventAutoPricing.compListingOnlyForSelectedSectionsEnabled',
      null
    );
  }, [setValue]);

  const onResetAdvanceSettings = useCallback(() => {
    setValue(
      'eventAutoPricing.compListingQuantityScoreAdjustmentEnabled',
      null
    );
    setValue('eventAutoPricing.compListingExcludeFanInventory', null);

    setValue('eventAutoPricing.circuitBreakerRelativeCeiling', null);
    setValue('eventAutoPricing.circuitBreakerRelativeFloor', null);
    setValue('eventAutoPricing.circuitBreakerMaxDiscountVelocityPercent', null);
    setValue(
      'eventAutoPricing.circuitBreakerMaxDiscountVelocityTicksInHours',
      null
    );
    setValue('eventAutoPricing.circuitBreakerMinCompListingCount', null);

    setValue('eventAutoPricing.outlierMode', null);
    setValue('eventAutoPricing.outlierStandardDeviations', null);
  }, [setValue]);

  const circuitBreakerSettings = useMemo(() => {
    if (
      circuitBreakerMaxDiscountVelocityPercent != null ||
      circuitBreakerMaxDiscountVelocityTicksInHours != null ||
      circuitBreakerMinCompListingCount != null ||
      circuitBreakerRelativeCeiling != null ||
      circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent,
        circuitBreakerMaxDiscountVelocityTicksInHours,
        circuitBreakerMinCompListingCount,
        circuitBreakerRelativeCeiling,
        circuitBreakerRelativeFloor,
      };
    }

    // if it has any of these set - use the entire area
    return {
      circuitBreakerMaxDiscountVelocityPercent:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
        null,
      circuitBreakerMaxDiscountVelocityTicksInHours:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
        null,
      circuitBreakerMinCompListingCount:
        accountPricingSettings?.circuitBreakerMinCompListingCount ?? null,
      circuitBreakerRelativeCeiling:
        accountPricingSettings?.circuitBreakerRelativeCeiling ?? null,
      circuitBreakerRelativeFloor:
        accountPricingSettings?.circuitBreakerRelativeFloor ?? null,
    };
  }, [
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    accountPricingSettings?.circuitBreakerMinCompListingCount,
    accountPricingSettings?.circuitBreakerRelativeCeiling,
    accountPricingSettings?.circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
  ]);

  const undercutSettings = useMemo(() => {
    if (
      undercutMode != null ||
      undercutAbsoluteAmount != null ||
      undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode,
        undercutAbsoluteAmount,
        undercutRelativeAmount,
      };
    }

    // if it has any of these set - use the entire area
    return {
      undercutMode:
        accountPricingSettings?.undercutMode ?? AutoPricingUndercutMode.Simple,
      undercutAbsoluteAmount:
        accountPricingSettings?.undercutAbsoluteAmount ??
        DEFAULT_UNDERCUT_ABSOLUTE_AMT,
      undercutRelativeAmount:
        accountPricingSettings?.undercutRelativeAmount ??
        DEFAULT_UNDERCUT_RELATIVE_AMT,
    };
  }, [
    accountPricingSettings?.undercutAbsoluteAmount,
    accountPricingSettings?.undercutMode,
    accountPricingSettings?.undercutRelativeAmount,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const compListingSettings = useMemo(() => {
    if (
      compListingMode != null ||
      compListingFloor != null ||
      compListingCeiling != null ||
      compListingOnlyForSameZoneEnabled != null ||
      compListingOnlyForSelectedSectionsEnabled != null ||
      compListingExcludeFanInventory != null ||
      compListingSelectedSectionSettings != null ||
      compListingQuantityScoreAdjustmentEnabled != null ||
      compListingQuantityScoreAdjustmentOverrideJson != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode,
        compListingFloor: compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          compListingCeiling ??
          (compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabled,
        compListingExcludeFanInventory,
        compListingSelectedSectionSettings,
        compListingQuantityScoreAdjustmentEnabled,
        compListingQuantityScoreAdjustmentOverrideJson,
      };
    }

    // if it has any of these set - use the entire area
    return {
      compListingMode:
        accountPricingSettings?.compListingMode ?? defaultCompListingMode,
      compListingFloor:
        accountPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
      compListingCeiling:
        accountPricingSettings?.compListingCeiling ??
        (accountPricingSettings?.compListingFloor ??
          DEFAULT_COMP_LISTING_FLOOR) +
          DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
      compListingOnlyForSameZoneEnabled:
        accountPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
      compListingOnlyForSelectedSectionsEnabled:
        accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
        null,
      compListingExcludeFanInventory:
        accountPricingSettings?.compListingExcludeFanInventory ?? null,
      compListingQuantityScoreAdjustmentEnabled:
        accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
        null,
      compListingQuantityScoreAdjustmentOverrideJson:
        accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
        null,
      compListingSelectedSectionSettings:
        accountPricingSettings?.compListingSelectedSectionSettings ?? null,
    };
  }, [
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingSelectedSectionSettings,
    compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,
    accountPricingSettings?.compListingMode,
    accountPricingSettings?.compListingFloor,
    accountPricingSettings?.compListingCeiling,
    accountPricingSettings?.compListingOnlyForSameZoneEnabled,
    accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    accountPricingSettings?.compListingExcludeFanInventory,
    accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    accountPricingSettings?.compListingSelectedSectionSettings,
    defaultCompListingMode,
  ]);

  const outlierSettings = useMemo(() => {
    if (outlierMode != null || outlierStandardDeviations != null) {
      // if it has any of these set - use the entire area
      return {
        outlierMode,
        outlierStandardDeviations,
      };
    }

    // if it has any of these set - use the entire area
    return {
      outlierMode: accountPricingSettings?.outlierMode ?? null,
      outlierStandardDeviations:
        accountPricingSettings?.outlierStandardDeviations ?? null,
    };
  }, [
    accountPricingSettings?.outlierMode,
    accountPricingSettings?.outlierStandardDeviations,
    outlierMode,
    outlierStandardDeviations,
  ]);

  const onUndercutChange = useCallback(
    (
      undercutModeNew?: AutoPricingUndercutMode | null,
      undercutAbsoluteAmountNew?: number | null,
      undercutRelativeAmountNew?: number | null
    ) => {
      if (
        undercutModeNew !== undefined ||
        undercutAbsoluteAmountNew !== undefined ||
        undercutRelativeAmountNew !== undefined
      ) {
        setValue(
          'eventAutoPricing.undercutMode',
          coalesceUndefinedOnly(undercutModeNew, undercutSettings.undercutMode)!
        );
        setValue(
          'eventAutoPricing.undercutAbsoluteAmount',
          coalesceUndefinedOnly(
            undercutAbsoluteAmountNew,
            undercutSettings.undercutAbsoluteAmount
          )!
        );
        setValue(
          'eventAutoPricing.undercutRelativeAmount',
          coalesceUndefinedOnly(
            undercutRelativeAmountNew,
            undercutSettings.undercutRelativeAmount
          )!
        );
      }
    },
    [
      setValue,
      undercutSettings.undercutAbsoluteAmount,
      undercutSettings.undercutMode,
      undercutSettings.undercutRelativeAmount,
    ]
  );

  const onCompListingChange = useCallback(
    (input: {
      compListingModeNew?: AutoPricingCompListingMode | null;
      compListingFloorNew?: number | null;
      compListingCeilingNew?: number | null;
      compListingOnlyForSameZoneEnabledNew?: boolean | null;
      compListingOnlyForSelectedSectionsEnabledNew?: boolean | null;
      compListingExcludeFanInventoryNew?: boolean | null;
      compListingQuantityScoreAdjustmentEnabledNew?: boolean | null;
      compListingQuantityScoreAdjustmentOverrideJsonNew?: string | null;
      compListingSelectedSectionSettingsNew?: SelectedSectionSettingsWithOptionals | null;
    }) => {
      const {
        compListingModeNew,
        compListingFloorNew,
        compListingCeilingNew,
        compListingOnlyForSameZoneEnabledNew,
        compListingOnlyForSelectedSectionsEnabledNew,
        compListingExcludeFanInventoryNew,
        compListingQuantityScoreAdjustmentEnabledNew,
        compListingQuantityScoreAdjustmentOverrideJsonNew,
        compListingSelectedSectionSettingsNew,
      } = input;

      if (
        compListingModeNew !== undefined ||
        compListingFloorNew !== undefined ||
        compListingCeilingNew !== undefined ||
        compListingOnlyForSameZoneEnabledNew !== undefined ||
        compListingOnlyForSelectedSectionsEnabledNew !== undefined ||
        compListingExcludeFanInventoryNew !== undefined ||
        compListingQuantityScoreAdjustmentEnabledNew !== undefined ||
        compListingQuantityScoreAdjustmentOverrideJsonNew !== undefined ||
        compListingSelectedSectionSettingsNew !== undefined
      ) {
        const sanitizedCompListingOnlyForSameZoneEnabledNew =
          compListingOnlyForSelectedSectionsEnabledNew
            ? false
            : compListingOnlyForSameZoneEnabledNew;
        const sanitizedCompListingOnlyForSelectedSectionsEnabledNew =
          compListingOnlyForSameZoneEnabledNew
            ? false
            : compListingOnlyForSelectedSectionsEnabledNew;
        setValue(
          'eventAutoPricing.compListingMode',
          coalesceUndefinedOnly(
            compListingModeNew,
            compListingSettings.compListingMode
          )!
        );
        setValue(
          'eventAutoPricing.compListingFloor',
          coalesceUndefinedOnly(
            compListingFloorNew,
            compListingSettings.compListingFloor
          )!
        );
        setValue(
          'eventAutoPricing.compListingCeiling',
          coalesceUndefinedOnly(
            compListingCeilingNew,
            compListingSettings.compListingCeiling
          )!
        );
        setValue(
          'eventAutoPricing.compListingOnlyForSameZoneEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSameZoneEnabledNew,
            compListingSettings.compListingOnlyForSameZoneEnabled
          )!
        );
        setValue(
          'eventAutoPricing.compListingOnlyForSelectedSectionsEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSelectedSectionsEnabledNew,
            compListingSettings.compListingOnlyForSelectedSectionsEnabled
          )!
        );
        setValue(
          'eventAutoPricing.compListingExcludeFanInventory',
          coalesceUndefinedOnly(
            compListingExcludeFanInventoryNew,
            compListingSettings.compListingExcludeFanInventory
          )!
        );
        setValue(
          'eventAutoPricing.compListingQuantityScoreAdjustmentEnabled',
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentEnabledNew,
            compListingSettings.compListingQuantityScoreAdjustmentEnabled
          )!
        );
        setValue(
          'eventAutoPricing.compListingQuantityScoreAdjustmentOverrideJson',
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentOverrideJsonNew,
            compListingSettings.compListingQuantityScoreAdjustmentOverrideJson
          )!
        );

        setValue(
          'eventAutoPricing.compListingSelectedSectionSettings',
          coalesceUndefinedOnly(
            mapToSelectedSectionSettings(compListingSelectedSectionSettingsNew),
            compListingSettings.compListingSelectedSectionSettings
          )!
        );
      }
    },
    [
      compListingSettings.compListingCeiling,
      compListingSettings.compListingFloor,
      compListingSettings.compListingMode,
      compListingSettings.compListingOnlyForSameZoneEnabled,
      compListingSettings.compListingOnlyForSelectedSectionsEnabled,
      compListingSettings.compListingExcludeFanInventory,
      compListingSettings.compListingQuantityScoreAdjustmentEnabled,
      compListingSettings.compListingQuantityScoreAdjustmentOverrideJson,
      compListingSettings.compListingSelectedSectionSettings,
      setValue,
    ]
  );

  const onCircuitBreakerChange = useCallback(
    (
      circuitBreakerRelativeCeilingNew?: number | null,
      circuitBreakerRelativeFloorNew?: number | null,
      circuitBreakerMaxDiscountVelocityPercentNew?: number | null,
      circuitBreakerMaxDiscountVelocityTicksInHoursNew?: number | null,
      circuitBreakerMinCompListingCountNew?: number | null
    ) => {
      if (
        circuitBreakerRelativeCeilingNew !== undefined ||
        circuitBreakerRelativeFloorNew !== undefined ||
        circuitBreakerMaxDiscountVelocityPercentNew !== undefined ||
        circuitBreakerMaxDiscountVelocityTicksInHoursNew !== undefined ||
        circuitBreakerMinCompListingCountNew !== undefined
      ) {
        setValue(
          'eventAutoPricing.circuitBreakerRelativeCeiling',
          coalesceUndefinedOnly(
            circuitBreakerRelativeCeilingNew,
            circuitBreakerSettings.circuitBreakerRelativeCeiling
          ) ?? null
        );
        setValue(
          'eventAutoPricing.circuitBreakerRelativeFloor',
          coalesceUndefinedOnly(
            circuitBreakerRelativeFloorNew,
            circuitBreakerSettings.circuitBreakerRelativeFloor
          ) ?? null
        );
        setValue(
          'eventAutoPricing.circuitBreakerMaxDiscountVelocityPercent',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityPercentNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent
          ) ?? null
        );
        setValue(
          'eventAutoPricing.circuitBreakerMaxDiscountVelocityTicksInHours',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityTicksInHoursNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours
          ) ?? null
        );
        setValue(
          'eventAutoPricing.circuitBreakerMinCompListingCount',
          coalesceUndefinedOnly(
            circuitBreakerMinCompListingCountNew,
            circuitBreakerSettings.circuitBreakerMinCompListingCount
          ) ?? null
        );
      }
    },
    [
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerSettings.circuitBreakerMinCompListingCount,
      circuitBreakerSettings.circuitBreakerRelativeCeiling,
      circuitBreakerSettings.circuitBreakerRelativeFloor,
      setValue,
    ]
  );

  const onOutlierChange = useCallback(
    (
      outlierModeNew?: AutoPricingOutlierMode | null,
      outlierStandardDeviationsNew?: number | null
    ) => {
      if (
        outlierModeNew !== undefined ||
        outlierStandardDeviationsNew !== undefined
      ) {
        setValue(
          'eventAutoPricing.outlierMode',
          coalesceUndefinedOnly(outlierModeNew, outlierSettings.outlierMode) ??
            null
        );
        setValue(
          'eventAutoPricing.outlierStandardDeviations',
          coalesceUndefinedOnly(
            outlierStandardDeviationsNew,
            outlierSettings.outlierStandardDeviations
          ) ?? null
        );
      }
    },
    [
      outlierSettings.outlierMode,
      outlierSettings.outlierStandardDeviations,
      setValue,
    ]
  );

  const onCompListingModeChange = useCallback(
    (mode: AutoPricingCompListingMode | null) => {
      clearErrors('eventAutoPricing.compListingFloor');

      if (!mode) {
        onCompListingChange({
          compListingModeNew: null,
          compListingFloorNew: null,
          compListingCeilingNew: null,
        });
        return;
      }

      onCompListingChange({
        compListingModeNew: mode,
        // Reset flag on mode change
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: false,
      });

      if (mode === AutoPricingCompListingMode.ParentListing) {
        onUndercutChange(AutoPricingUndercutMode.Simple);
      }
    },
    [clearErrors, onCompListingChange, onUndercutChange]
  );

  return {
    canAutoPrice,
    uiCurrency,
    currencyNumberFormat,
    showAutoPricingSettings: !(
      !autoPricingEnabled || !hasEventPricingPermission
    ),

    autoPriceFrequency,
    autoPricingEnabled,
    onResetSettings,
    onResetAdvanceSettings,
    accountPricingSettings,
    event,

    ...circuitBreakerSettings,
    ...compListingSettings,
    ...undercutSettings,
    ...outlierSettings,

    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
    onCompListingModeChange,
  };
};
