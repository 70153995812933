import {
  matchQuery,
  Query,
  QueryCache,
  QueryClient,
  QueryFunctionContext,
  QueryKey,
} from '@tanstack/react-query';
import {
  PERSISTER_KEY_PREFIX,
  StoragePersisterOptions,
} from '@tanstack/react-query-persist-client';
import { parse, stringify } from 'flatted';
import {
  clearIDBCache,
  createIDBAsyncStorage,
} from 'src/core/utils/createIndexDBPersister';
import { REACT_QUERY_CACHE_MAX_AGE_IN_MS } from 'src/utils/constants/constants';
import { ApiException } from 'src/WebApiController';

interface QueryMeta {
  onError?: (error: Error) => void;
}

/**
 * This is copied from https://github.com/TanStack/query/blob/683c85e02c58c988cb520958941fcd163bd00eee/packages/query-persist-client-core/src/createPersister.ts#L81
 * We need to override the fact that all query.fetch() regardless of Stale or not
 */
export function experimental_createPersister<TStorageValue = string>({
  storage,
  buster = '',
  maxAge = 1000 * 60 * 60 * 24,
  serialize = stringify as Required<
    StoragePersisterOptions<TStorageValue>
  >['serialize'],
  deserialize = parse as Required<
    StoragePersisterOptions<TStorageValue>
  >['deserialize'],
  prefix = PERSISTER_KEY_PREFIX,
  filters,
}: StoragePersisterOptions<TStorageValue>) {
  return async function persisterFn<T, TQueryKey extends QueryKey>(
    queryFn: (context: QueryFunctionContext<TQueryKey>) => T | Promise<T>,
    context: QueryFunctionContext<TQueryKey>,
    query: Query
  ) {
    const storageKey = `${prefix}-${query.queryHash}`;
    const matchesFilter = filters ? matchQuery(filters, query) : true;

    // Try to restore only if we do not have any data in the cache and we have persister defined
    if (matchesFilter && query.state.data === undefined && storage != null) {
      try {
        const storedData = await storage.getItem(storageKey);
        if (storedData) {
          const persistedQuery = await deserialize(storedData);

          if (persistedQuery.state.dataUpdatedAt) {
            const queryAge = Date.now() - persistedQuery.state.dataUpdatedAt;
            const expired = queryAge > maxAge;
            const busted = persistedQuery.buster !== buster;
            if (expired || busted) {
              await storage.removeItem(storageKey);
            } else {
              // Just after restoring we want to get fresh data from the server always
              setTimeout(() => {
                // Set proper updatedAt, since resolving in the first pass overrides those values
                query.setState({
                  dataUpdatedAt: persistedQuery.state.dataUpdatedAt,
                  errorUpdatedAt: persistedQuery.state.errorUpdatedAt,
                });

                // Fetch the latest data
                query.fetch();
              }, 0);
              // We must resolve the promise here, as otherwise we will have `loading` state in the app until `queryFn` resolves
              return Promise.resolve(persistedQuery.state.data as T);
            }
          } else {
            await storage.removeItem(storageKey);
          }
        }
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
          console.warn(
            'Encountered an error attempting to restore query cache from persisted location.'
          );
        }
        await storage.removeItem(storageKey);
      }
    }

    // If we did not restore, or restoration failed - fetch
    const queryFnResult = await queryFn(context);

    if (matchesFilter && storage != null) {
      // Persist if we have storage defined, we use timeout to get proper state to be persisted
      setTimeout(async () => {
        storage.setItem(
          storageKey,
          await serialize({
            state: query.state,
            queryKey: query.queryKey,
            queryHash: query.queryHash,
            buster: buster,
          })
        );
      }, 0);
    }

    return Promise.resolve(queryFnResult);
  };
}

const createClient = () => {
  const persister = experimental_createPersister({
    storage: createIDBAsyncStorage(),
    // this is the age of the persisted cache - if a stored cache is older than 24 hrs, it will not be loaded
    // only time this happens is if the user hasn't used the app for a prolonged period of time
    // Nuance: this needs to be less than or equal to the cacheTime above
    // https://tanstack.com/query/v5/docs/framework/react/plugins/createPersister
    maxAge: REACT_QUERY_CACHE_MAX_AGE_IN_MS,
    buster: '12', // NOTE - update this if you release some breaking change and want the app to start fresh (rather than loading from caches)
    filters: {
      predicate: (query) => {
        // Only include queries that satisfy the following
        return (
          (query.state.data != null && query.state.error == null) ||
          query.meta?.persist !== false
        );
      },
    },
  });

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        // gcTime is the time a query gets garbage collected if it hasn't been used for this amount of time
        gcTime: REACT_QUERY_CACHE_MAX_AGE_IN_MS,
        persister: persister,
        structuralSharing: (oldData, newData) => {
          if (stringify(oldData) === stringify(newData)) {
            return oldData;
          }
          return newData;
        },
      },
    },
    queryCache: new QueryCache({
      onError(error, query) {
        const meta = query.meta as QueryMeta;

        if (meta?.onError) {
          meta.onError(error);
        }

        if (ApiException.isApiException(error) && error.status === 401) {
          clearIDBCache(); // delete the cache storage before reloading
          window.location.reload();
        }
      },
    }),
  });

  return { client, persister };
};

export const MainQueryClient = createClient();
