import { Updater } from '@tanstack/react-table';
import { debounce, once } from 'lodash-es';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getColumnConfigById } from 'src/utils/columns/columnUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { UserSetting } from 'src/WebApiController';

import { useServerUserSetting } from '../../hooks/useUserSetting';
import {
  ColumnResizingContextType,
  CustomColumnWidthsType,
} from './ColumnResizingContext.types';

export const createColumnResizingContext = once(() =>
  React.createContext({} as ColumnResizingContextType)
);

export function useColumnResizingContext() {
  return useContext(createColumnResizingContext());
}

const USER_SETTING_TO_SECTION_TYPE = {
  [UserSetting.InventoryColumnWidths]: SectionType.Listings,
  [UserSetting.SaleColumnWidths]: SectionType.Sales,
  [UserSetting.PurchaseColumnWidths]: SectionType.Purchases,
  [UserSetting.PurchaseEventColumnWidths]: SectionType.PurchaseEvent,
  [UserSetting.InventoryReportColumnWidths]: SectionType.ListingsReport,
  [UserSetting.SaleReportColumnWidths]: SectionType.SalesReport,
  [UserSetting.EventsColumnWidths]: SectionType.Events,
  [UserSetting.MarketplacePaymentsColumnsWidth]:
    SectionType.MarketplacePaymentsTable,
  [UserSetting.OnSaleEventColumnsWidths]: SectionType.OnSaleEvents,
};

export const ColumnResizingContextProvider = ({
  userSettingId,
  children,
}: {
  userSettingId?:
    | UserSetting.InventoryColumnWidths
    | UserSetting.SaleColumnWidths
    | UserSetting.PurchaseColumnWidths
    | UserSetting.PurchaseEventColumnWidths
    | UserSetting.InventoryReportColumnWidths
    | UserSetting.SaleReportColumnWidths
    | UserSetting.EventsColumnWidths
    | UserSetting.MarketplacePaymentsColumnsWidth
    | UserSetting.OnSaleEventColumnsWidths;
  children: ReactNode;
}) => {
  const ColumnResizingContext = createColumnResizingContext();
  const sectionType =
    userSettingId != null
      ? USER_SETTING_TO_SECTION_TYPE[userSettingId]
      : undefined;

  const isInitializedRef = useRef(false);

  const {
    value: serverCustomColumnWidths,
    setUserSetting: setServerCustomColumnWidths,
  } = useServerUserSetting<CustomColumnWidthsType>({
    id: userSettingId,
  });

  const [customColumnWidths, setCustomColumnWidths] =
    useState<CustomColumnWidthsType>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetServerColumnWidths = useCallback(
    debounce(
      (newWidth: Record<string, number>) =>
        setServerCustomColumnWidths(newWidth),
      1000
    ),
    [setServerCustomColumnWidths]
  );

  const onColumnSizingChange = (
    updaterOrValue: Updater<CustomColumnWidthsType>
  ) => {
    if (!(updaterOrValue instanceof Function)) return;

    const state = updaterOrValue({});
    if (!Object.keys(state).length) return;

    const [key, width] = Object.entries<number>(
      state as Record<string, number>
    )[0];
    const definition =
      sectionType != null
        ? getColumnConfigById({
            id: key,
            sectionType,
          }).definition
        : null;

    const minSize = definition?.minSize ?? 0;
    const maxSize = definition?.maxSize;
    const currentSize = customColumnWidths[key] ?? 0;
    let updatedSize = Math.max(width, minSize);
    if (maxSize != null) {
      updatedSize = Math.min(updatedSize, maxSize);
    }

    if (updatedSize === currentSize) return;

    const newWidths = {
      ...customColumnWidths,
      [key]: updatedSize,
    };

    setCustomColumnWidths(newWidths);
    debouncedSetServerColumnWidths(newWidths);
  };

  useEffect(() => {
    if (isInitializedRef.current || !serverCustomColumnWidths) return;
    isInitializedRef.current = true;

    const serverCustomColumnWidthsWithoutZeroValues = Object.entries(
      serverCustomColumnWidths
    ).reduce(
      (acc, [key, value]) => {
        if (!value) return acc;
        return { ...acc, [key]: value };
      },
      {} as Record<string, number>
    );
    setCustomColumnWidths(serverCustomColumnWidthsWithoutZeroValues);
  }, [serverCustomColumnWidths]);

  return (
    <ColumnResizingContext.Provider
      value={{
        isResizeEnabled: true,
        customColumnWidths,
        onColumnSizingChange,
      }}
    >
      {children}
    </ColumnResizingContext.Provider>
  );
};
