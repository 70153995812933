import { isEmpty, noop, size } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { BUCKET_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { BucketType, Feature } from 'src/WebApiController';

import { GroupTypeToCID } from '../../GroupListingsV2.constants';
import * as commonStyles from '../../GroupListingsV2.css';
import {
  GroupListingsFormProps,
  GroupRange,
  ListingGroupBy,
  ListingGroupType,
} from '../../GroupListingsV2.types';
import { SettingsTitle } from '../SettingsTitle';
import * as styles from './GroupingMethods.css';
import { InputRowRanges } from './InputRowRanges';
import { ZoneMap } from './ZoneMap';

const getDefaultRanges = (type: ListingGroupType): GroupRange[] | undefined => {
  if (type === ListingGroupType.Row) {
    return [
      { min: 1, max: 1 },
      { min: 2, max: 5 },
      { min: 6, max: Number.MAX_VALUE },
    ];
  }
  if (type === ListingGroupType.Quantity) {
    return [
      { min: 1, max: 4 },
      { min: 5, max: Number.MAX_VALUE },
    ];
  }

  if (type === ListingGroupType.UnitCost) {
    return [
      { min: 1, max: 100 },
      { min: 100, max: Number.MAX_VALUE },
    ];
  }

  return undefined;
};

const BucketGroupingTypes = [
  ListingGroupType.Quantity,
  ListingGroupType.UnitCost,
];

type GroupingMethodsProps = {};

export const GroupingMethods: React.FC<GroupingMethodsProps> = () => {
  const { watch, setValue } = useFormContext<GroupListingsFormProps>();

  const rowRangesText = useContent(ContentId.RowRanges);
  const addAnotherSplitText = useContent(ContentId.AddAnotherSplit);

  const groupBys = watch('groupBys');

  const primaryGroupBy = useMemo(() => {
    if (size(groupBys) < 1) {
      return null;
    }
    return groupBys[0];
  }, [groupBys]);

  const onPrimaryGroupTypeUpdate = useCallback(
    (type: ListingGroupType | null) => {
      if (type === null) {
        setValue('groupBys', []);
      } else {
        if (type === ListingGroupType.ZoneMap) {
          const groupBys: ListingGroupBy[] = [
            {
              groupingType: type,
              ranges: [
                { min: 1, max: 1 },
                { min: 2, max: 5 },
                { min: 6, max: Number.MAX_VALUE },
              ],
            },
          ];
          setValue('groupBys', groupBys);
        } else {
          const groupBys: ListingGroupBy[] = [{ groupingType: type }];
          setValue('groupBys', groupBys);
        }
      }
    },
    [setValue]
  );

  const onUpdateZoneMapRowRange = useCallback(
    (ranges: GroupRange[]) => {
      if (!primaryGroupBy) {
        return;
      }
      const updated = [...groupBys];
      const newGroupBy: ListingGroupBy = { ...primaryGroupBy, ranges };
      updated[0] = newGroupBy;
      setValue('groupBys', updated);
    },
    [groupBys, primaryGroupBy, setValue]
  );

  const primaryGroupOptions = useMemo(() => {
    const { Quantity, UnitCost, Row, None, ...cloned } = GroupTypeToCID;

    return cloned as Record<ListingGroupType, ContentId>;
  }, []);

  const groupBySubtitle = useMemo(() => {
    return (
      <Button
        className={styles.anotherSplitButton}
        variant="textPlain"
        onClick={() =>
          setValue('groupBys', [
            ...groupBys,
            { groupingType: ListingGroupType.None },
          ])
        }
      >
        {addAnotherSplitText}
      </Button>
    );
  }, [addAnotherSplitText, groupBys, setValue]);

  const canAddSecondaryThenBy = useMemo(() => {
    return groupBys.length === 1;
  }, [groupBys.length]);

  const canAddThirdThenBy = useMemo(() => {
    if (primaryGroupBy?.groupingType === ListingGroupType.ZoneMap) {
      return false;
    }
    return groupBys.length === 2;
  }, [groupBys.length, primaryGroupBy?.groupingType]);

  return (
    <Stack direction="column" width="full" alignItems="center" gap="m">
      <Stack
        direction="row"
        width="full"
        height="fit"
        justifyContent="spaceBetween"
      >
        <SettingsTitle
          title={<Content id={ContentId.GroupBy} />}
          subtitle={
            primaryGroupBy?.groupingType !== ListingGroupType.ZoneMap &&
            canAddSecondaryThenBy &&
            groupBySubtitle
          }
        />
        <PosEnumSelect
          value={primaryGroupBy?.groupingType}
          onChange={onPrimaryGroupTypeUpdate}
          valueOptionsContent={primaryGroupOptions}
          className={commonStyles.inputStyle}
        />
      </Stack>
      {primaryGroupBy?.groupingType === ListingGroupType.ZoneMap && (
        <>
          <ZoneMap />
          <Stack direction="column" width="full" gap="s">
            <Stack
              direction="row"
              width="full"
              justifyContent="spaceBetween"
              alignItems="center"
            >
              <SettingsTitle
                title={<Content id={ContentId.SplitZonesBy} />}
                subtitle={
                  primaryGroupBy?.groupingType === ListingGroupType.ZoneMap &&
                  canAddSecondaryThenBy &&
                  groupBySubtitle
                }
              />
              <PosTextField
                value={rowRangesText}
                onChange={noop}
                rootProps={{ className: commonStyles.inputStyle }}
                disabled
              />
            </Stack>
            <InputRowRanges
              ranges={primaryGroupBy?.ranges}
              onRowRangesChange={onUpdateZoneMapRowRange}
            />
          </Stack>
        </>
      )}
      <>
        <ThenGroupBy
          groupBys={groupBys}
          groupLevel={1}
          onUpdateGroupBys={(values) => setValue('groupBys', values)}
          subtitle={canAddThirdThenBy && groupBySubtitle}
        />
        {primaryGroupBy?.groupingType !== ListingGroupType.ZoneMap && (
          <ThenGroupBy
            groupBys={groupBys}
            groupLevel={2}
            onUpdateGroupBys={(values) => setValue('groupBys', values)}
          />
        )}
      </>
    </Stack>
  );
};

type ThenGroupByProps = {
  groupBys: ListingGroupBy[];
  groupLevel: number;
  subtitle?: React.ReactNode;
  onUpdateGroupBys: (groupBys: ListingGroupBy[]) => void;
};
const ThenGroupBy: React.FC<ThenGroupByProps> = ({
  groupLevel,
  groupBys,
  subtitle,
  onUpdateGroupBys,
}) => {
  const hasGroupListingsWithRangedQuantityCostFeature = useUserHasFeature(
    Feature.GroupListingsWithRangedQuantityCost
  );
  const groupBy = useMemo(() => {
    if (groupBys.length <= groupLevel) {
      return undefined;
    }
    return groupBys[groupLevel];
  }, [groupBys, groupLevel]);

  const onUpdateGroupBy = useCallback(
    (type: ListingGroupType | null) => {
      if (type == null) {
        return;
      }
      if (type === ListingGroupType.None) {
        onUpdateGroupBys(groupBys.slice(0, groupLevel));
        return;
      }
      if (isEmpty(groupBy)) {
        onUpdateGroupBys([...groupBys, { groupingType: type }]);
      } else {
        const updated = [...groupBys];
        const newGroupBy: ListingGroupBy = {
          groupingType: type,
          numberOfBuckets: BucketGroupingTypes.includes(type) ? 2 : undefined,
          bucketOption: BucketGroupingTypes.includes(type)
            ? BucketType.Clusters
            : undefined,
        };

        if (hasGroupListingsWithRangedQuantityCostFeature) {
          const ranges = getDefaultRanges(type);
          Object.assign(newGroupBy, { ranges });
        }
        updated[groupLevel] = newGroupBy;
        onUpdateGroupBys(updated);
      }
    },
    [
      groupBy,
      groupBys,
      groupLevel,
      hasGroupListingsWithRangedQuantityCostFeature,
      onUpdateGroupBys,
    ]
  );

  const onUpdateGroupRanges = useCallback(
    (ranges: GroupRange[]) => {
      if (isEmpty(groupBy)) {
        return;
      }
      const updated = [...groupBys];
      const newGroupBy: ListingGroupBy = { ...groupBy, ranges };
      updated[groupLevel] = newGroupBy;
      onUpdateGroupBys(updated);
    },
    [groupBy, groupBys, groupLevel, onUpdateGroupBys]
  );

  const groupOptions = useMemo(() => {
    const { ZoneMap, ...cloned } = GroupTypeToCID;

    // Zone map is already splited by zone and section
    if (groupBys[0] && groupBys[0].groupingType === ListingGroupType.ZoneMap) {
      delete cloned[ListingGroupType.Zone];
      delete cloned[ListingGroupType.Section];
      delete cloned[ListingGroupType.Row]; // Row is included in the zone map ranges
    }
    for (let index = 0; index < groupBys.length; index++) {
      if (index < groupLevel) {
        const current = groupBys[index];
        if (
          current.groupingType !== ListingGroupType.ZoneMap &&
          current.groupingType !== ListingGroupType.None &&
          cloned[current.groupingType]
        ) {
          delete cloned[current.groupingType];
        }
      }
    }
    return cloned as Record<ListingGroupType, ContentId>;
  }, [groupBys, groupLevel]);

  const showBucketOption = useMemo(
    () =>
      groupBy?.groupingType &&
      [ListingGroupType.Quantity, ListingGroupType.UnitCost].includes(
        groupBy?.groupingType
      ),
    [groupBy?.groupingType]
  );

  if (groupLevel >= groupBys.length) {
    return undefined;
  }

  return (
    <Stack direction="column" width="full" gap="s">
      <Stack
        direction="row"
        width="full"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <SettingsTitle
          title={<Content id={ContentId.ThenSplitGroupsBy} />}
          subtitle={subtitle}
        />
        <PosEnumSelect
          value={groupBy?.groupingType ?? null}
          onChange={onUpdateGroupBy}
          valueOptionsContent={groupOptions}
          className={commonStyles.inputStyle}
        />
      </Stack>
      {hasGroupListingsWithRangedQuantityCostFeature &&
        groupBy?.groupingType &&
        groupBy.groupingType !== ListingGroupType.None && (
          <InputRowRanges
            ranges={groupBy?.ranges ?? []}
            onRowRangesChange={onUpdateGroupRanges}
          />
        )}
      {showBucketOption && !hasGroupListingsWithRangedQuantityCostFeature && (
        <Stack direction="row" justifyContent="end" width="full" gap="m">
          <Stack justifyContent="end" alignItems="center" gap="m">
            <SettingsTitle title={<Content id={ContentId.NumberOfBuckets} />} />
            <PosTextField
              rootProps={{ className: commonStyles.inputStyle }}
              value={groupBy?.numberOfBuckets ?? '1'}
              type="number"
              min={0}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseInt(e.target.value);
                if (v >= 1) {
                  if (v !== groupBy?.numberOfBuckets) {
                    const updated = [...groupBys];
                    updated[groupLevel] = {
                      ...updated[groupLevel],
                      numberOfBuckets: v,
                    };
                    onUpdateGroupBys(updated);
                  }
                }
              }}
            />
          </Stack>
          <Stack justifyContent="end" alignItems="center" gap="m">
            <SettingsTitle title={<Content id={ContentId.BucketOptions} />} />
            <PosEnumSelect
              className={commonStyles.inputStyle}
              valueOptionsContent={BUCKET_OPTIONS_TO_CID}
              value={groupBy?.bucketOption}
              onChange={(v) => {
                if (v != null) {
                  const updated = [...groupBys];
                  updated[groupLevel] = {
                    ...updated[groupLevel],
                    bucketOption: v,
                  };
                  onUpdateGroupBys(updated);
                }
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
