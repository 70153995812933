import { useCallback, useContext, useMemo } from 'react';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { AddToGroupListingsModal } from 'src/modals/GroupListings';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import {
  MoveListingGroupDialog,
  MoveListingGroupItem,
} from 'src/modals/GroupListingsV2/components/GroupsPreview/MoveListingGroupDialog';
import { ContentId } from 'src/utils/constants/contentId';
import {
  EventWithData,
  Listing,
  ListingGroup,
  ListingGroupItemInput,
} from 'src/WebApiController';

export const LaunchMoveToGroup = ({
  eventData,
}: {
  eventData: EventWithData;
}) => {
  const { setModal } = useContext(ModalContext);

  const { openDialog, closeDialog } = useDialog(
    DialogId.AddToGroup,
    MoveListingGroupDialog
  );

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(
    eventData.event
  );

  const selectedListingIds = useMemo(
    () => filterQueryWithEventIds.entityIds ?? [],
    [filterQueryWithEventIds]
  );

  const eventListings = useMemo(() => {
    return (
      (eventData?.entities?.listings?.flatMap(
        flattenListingGroup
      ) as Listing[]) ?? []
    );
  }, [eventData?.entities?.listings]);
  const eventListingGroups = useMemo(() => {
    return (
      eventData
        .entities!.listings!.filter(
          (l): l is ListingGroup => l.isLtGrp && l.ltGrpId != null
        )
        .map((group) => {
          const items = flattenListingGroup(group).map(
            (item) =>
              ({
                listingId: item.id,
                priority: item.ltGrpPrior,
                groupId: item.ltGrpId,
              }) as ListingGroupItemInput
          );
          return {
            listingGroupId: group.ltGrpId!,
            name: group.groupName,
            listingGroupItems: items,
          } as MoveListingGroupItem;
        }) ?? []
    );
  }, [eventData.entities]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      openDialog({
        listingGroups: eventListingGroups,
        listings: eventListings,
        canAddNewGroup: true,
        onMoveListingToGroup: (groupId: string, groupName?: string) => {
          const existingGroup = eventListingGroups.find(
            (group) => group.listingGroupId === groupId
          );
          const newListingGroupName = existingGroup ? undefined : groupName;
          closeDialog();
          setModal({
            children: (
              <AddToGroupListingsModal
                selectedListingIds={selectedListingIds}
                listingGroupId={groupId ?? undefined}
                viagVirtualId={eventData.event.viagVirtualId}
                newListingGroupName={newListingGroupName}
              />
            ),
            clickOutsideToClose: true,
            ...{ size: 'slide-in' },
          });
        },
        onClose: () => {
          closeDialog();
        },
      });
    },
    [
      closeDialog,
      eventData.event.viagVirtualId,
      eventListingGroups,
      eventListings,
      openDialog,
      selectedListingIds,
      setModal,
    ]
  );

  return (
    <PosDropdownItem key="MoveToGroup" onClick={onLaunchDialog}>
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.MoveTo} />
      </Stack>
    </PosDropdownItem>
  );
};
