import { Content, useContent } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Popover, Stack } from 'src/core/ui';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './ColumnHeader.css';

export const ColumnRangeFilterInput = ({
  filterValue,
  setFilterValue,
}: {
  filterValue: [number | undefined, number | undefined] | undefined;
  setFilterValue: (
    value: [number | undefined, number | undefined] | undefined
  ) => void;
}) => {
  const hasFilterValue = filterValue?.[0] != null || filterValue?.[1] != null;
  const minText = useContent(ContentId.Min);
  const maxText = useContent(ContentId.Max);

  return (
    <Stack
      direction="column"
      className={styles.columnRangeFilterInput}
      gap="m"
      onClick={(e) => e.stopPropagation()}
    >
      <Stack direction="row" justifyContent="spaceBetween" gap="m">
        <PosTextField
          type="number"
          value={filterValue?.[0]}
          onChange={(e) => {
            const v = parseInt(e.target.value);
            const oldValue = filterValue;

            const newMinValue = e.target.value ? v : undefined;
            const newMaxValue =
              oldValue?.[1] != null
                ? e.target.value
                  ? Math.max(v, oldValue[1])
                  : oldValue[1]
                : undefined;

            setFilterValue([newMinValue, newMaxValue]);
          }}
          placeholder={minText}
          onClick={(e) => e.stopPropagation()}
        />
        <PosTextField
          type="number"
          value={filterValue?.[1]}
          onChange={(e) => {
            const v = parseInt(e.target.value);

            const newMaxValue = e.target.value ? v : undefined;
            const newMinValue =
              filterValue?.[0] != null
                ? e.target.value
                  ? Math.min(v, filterValue[0])
                  : filterValue[0]
                : undefined;

            setFilterValue([newMinValue, newMaxValue]);
          }}
          placeholder={maxText}
          onClick={(e) => e.stopPropagation()}
        />
      </Stack>

      <Stack direction="row" justifyContent="spaceBetween">
        <Button
          variant="text"
          onClick={() => {
            setFilterValue(undefined);
          }}
          disabled={!hasFilterValue}
        >
          <Content id={ContentId.ClearAll} />
        </Button>

        <Popover.Close asChild>
          <CrossIcon withHoverEffect />
        </Popover.Close>
      </Stack>
    </Stack>
  );
};
