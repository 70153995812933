import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { styled } from 'src/core/utils';
import * as styles from './SimpleTable.css';

export const Table = styled('table', styles.table);

interface TheadProps {
  children?: ReactNode;
  showBottomBorder?: boolean;
}
export const Thead = ({ showBottomBorder = true, children }: TheadProps) => {
  return (
    <thead
      className={clsx(styles.thead, {
        [styles.theadBottomBorder]: showBottomBorder,
      })}
    >
      {children}
    </thead>
  );
};

export const Tbody = styled('tbody', styles.tbody);

export const Tr = styled('tr', styles.tr);

export const Th = styled('th', styles.th);

export const Td = styled('td', styles.td);
