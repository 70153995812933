import clsx from 'clsx';
import { FormEvent } from 'react';
import { vars } from 'src/core/themes';
import { ArrowDownIcon, ArrowUpIcon } from 'src/svgs/Viagogo';

import { Button } from '../Button';
import { Stack } from '../Stack';
import * as styles from './UpDownComboButton.css';

export const UpDownComboButton = ({
  disabled,
  onUpClick,
  onDownClick,
  isCondensedView,
}: {
  disabled?: boolean;
  onUpClick: (e: FormEvent<HTMLButtonElement>) => void;
  onDownClick: (e: FormEvent<HTMLButtonElement>) => void;
  isCondensedView?: boolean;
}) => {
  return (
    <Stack direction="column">
      <Button
        className={clsx(styles.upButtonStyle, {
          [styles.upCondensedView]: isCondensedView,
        })}
        variant="outline"
        disabled={disabled}
        onClick={onUpClick}
      >
        <ArrowUpIcon
          withHoverEffect
          size={isCondensedView ? vars.iconSize.xxs : vars.iconSize.xs}
          disabled={disabled}
        />
      </Button>
      <Button
        className={clsx(styles.downButtonStyle, {
          [styles.downCondensedView]: isCondensedView,
        })}
        variant="outline"
        disabled={disabled}
        onClick={onDownClick}
      >
        <ArrowDownIcon
          withHoverEffect
          size={isCondensedView ? vars.iconSize.xxs : vars.iconSize.xs}
          disabled={disabled}
        />
      </Button>
    </Stack>
  );
};
