import { useMemo } from 'react';
import { FilterPillList } from 'src/components/common/FilterPill/FilterPillList';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { Feature, Listing } from 'src/WebApiController';

import { SectionFilterRowNudger } from './SectionFilterRowNudger';

export const AutoPricingSectionRowIdFilter = ({
  listing,
  disabled,
}: {
  listing?: Listing | null;
  disabled?: boolean;
}) => {
  const { venueMapInfo } = useEventMapContext();
  const hasSimplifiedPricingV2Feature = useUserHasFeature(
    Feature.SimplifiedAutoPricingUiV2
  );

  const {
    onCompListingChange,
    onCompListingSectionIdFilterChange,
    compListingSelectedSectionSettings,
  } = useListingAutoPricingSettings(listing);

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [
    compListingSelectedSectionSettings?.sectionIdFilter,
    venueMapInfo?.sections,
  ]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const applyCompListingChanges = (
    rowIdFilters: number[],
    sectionRowIdFilters: { [x: string]: string }
  ) => {
    const compListingSelectedSectionSettingsNew = {
      ...compListingSelectedSectionSettings,
      sectionIdFilter:
        compListingSelectedSectionSettings?.sectionIdFilter ?? [],
      rowIdFilter: Array.from(new Set(rowIdFilters)),
      sectionRowIdFilter: sectionRowIdFilters,
    };

    onCompListingChange({
      compListingSelectedSectionSettingsNew,
      compListingOnlyForSelectedSectionsEnabledNew: true,
    });
  };

  return (
    <Stack width="full" gap="m">
      <FilterPillList
        disabled={disabled}
        selectedSections={selectedSections}
        rowIdFilters={rowIdFilters}
        sectionRowIdFilters={sectionRowIdFilters}
        applyRowFilterChanges={applyCompListingChanges}
        applySelectedSectionChanges={onCompListingSectionIdFilterChange}
        useCondensedBorders={true}
      />
      {hasSimplifiedPricingV2Feature &&
        !!Object.entries(sectionRowIdFilters).length && (
          <SectionFilterRowNudger
            disabled={disabled}
            selectedSections={selectedSections}
            rowIdFilters={rowIdFilters}
            sectionRowIdFilters={sectionRowIdFilters}
            applyRowFilterChanges={applyCompListingChanges}
          />
        )}
    </Stack>
  );
};
