import { Temporal } from '@js-temporal/polyfill';
import { useQuery } from '@tanstack/react-query';
import { max, min } from 'lodash-es';
import { useMemo } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import {
  roundRelativeDateDown,
  roundRelativeDateUp,
} from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  DateTimeRangeWithRelative,
  Feature,
  PurchaseClient,
  PurchaseOrderQuery,
  TimePeriodType,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { usePurchaseDataContext } from './PurchaseDataContext';
import { PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY } from './PurchaseDataContext.constants';

export const useSingleTimePeriodPurchasesQuery = (
  firstOfTimePeriod: string,
  isExpanded: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();
  const { trackError } = useErrorBoundaryContext();

  const { allEventIds } = useCatalogDataContext();

  const { purchaseGroupTimePeriod } = usePurchaseDataContext();

  const firstOfTimePeriodPlainDateTime =
    Temporal.PlainDateTime.from(firstOfTimePeriod);

  const timePeriodType = purchaseGroupTimePeriod;

  const lastOfTimePeriodPlainDateTime = (() => {
    switch (timePeriodType) {
      // TODO: To enable this we need to fix how we handle grouping time periods in the backend with timezones
      // case 'Daily':
      //   return firstOfTimePeriodPlainDateTime
      //     .with({
      //       hour: 23,
      //       minute: 59,
      //       second: 59,
      //       millisecond: 999,
      //     })
      //     .toString();
      case 'Weekly':
        return firstOfTimePeriodPlainDateTime
          .add({ days: 7 - firstOfTimePeriodPlainDateTime.dayOfWeek })
          .with({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .toString();

      case 'Monthly':
        return firstOfTimePeriodPlainDateTime
          .with({
            day: firstOfTimePeriodPlainDateTime.daysInMonth,
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .toString();

      default:
        throw new Error(`Unsupported time period type: ${timePeriodType}`);
    }
  })();

  const timePeriodDateRange: DateTimeRangeWithRelative = useMemo(() => {
    let filterDateStart = '';
    let filterDateEnd = '';
    if (filterQuery.purchaseDates) {
      if (filterQuery.purchaseDates.start) {
        filterDateStart = filterQuery.purchaseDates.start;
      }
      if (filterQuery.purchaseDates.relativeStartSecs != null) {
        const relativeDateStart = new Date(
          new Date().getTime() +
            filterQuery.purchaseDates.relativeStartSecs * 1000
        );

        filterDateStart = roundRelativeDateUp(
          relativeDateStart,
          filterQuery.purchaseDates.roundingMode
        ).toISOString();
      }

      if (filterQuery.purchaseDates.end) {
        filterDateEnd = filterQuery.purchaseDates.end;
      }
      if (filterQuery.purchaseDates.relativeEndSecs != null) {
        const relativeDateEnd = new Date(
          new Date().getTime() +
            filterQuery.purchaseDates.relativeEndSecs * 1000
        );

        filterDateEnd = roundRelativeDateDown(
          relativeDateEnd,
          filterQuery.purchaseDates.roundingMode
        ).toISOString();
      }
    }

    return {
      start:
        max([firstOfTimePeriodPlainDateTime.toString(), filterDateStart]) ??
        null,
      end:
        min([lastOfTimePeriodPlainDateTime.toString(), filterDateEnd]) ?? null,
    } as DateTimeRangeWithRelative;
  }, [
    filterQuery.purchaseDates,
    firstOfTimePeriodPlainDateTime,
    lastOfTimePeriodPlainDateTime,
  ]);

  const moddedFilterQuery = {
    ...filterQuery,
    purchaseDates: timePeriodDateRange,
  };

  const singleTimePeriodPurchaseQuery = useQuery({
    queryKey: [
      PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY,
      isExpanded,
      moddedFilterQuery,
      allEventIds,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      const filterQueryWithEventIds = {
        ...moddedFilterQuery,
        eventOrMappingIds: (allEventIds ?? []).map((ev) => ev.viagVirtualId),
        performerIds: (allEventIds ?? [])
          .filter((ev) => ev.performerId != null)
          .map((ev) => ev.performerId!),
        venueIds: (allEventIds ?? []).map((ev) => ev.venueId),
      };

      // XXX Workaround for the backend
      // because backend treats empty arrays as "no filter" for backwards compatibility
      if (allEventIds?.length === 0) {
        return [];
      }

      return tryInvokeApi(
        async () => {
          const client = new PurchaseClient(activeAccountWebClientConfig);
          const filterQuery = filterQueryWithEventIds;

          const data =
            await client.getPurchaseOrdersWithTicketGroups(filterQuery);
          return data;
        },
        (error) => {
          trackError(
            PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY,
            error,
            moddedFilterQuery
          );
        }
      );
    },
    enabled: isExpanded && activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return {
    singleTimePeriodPurchaseQuery,
    filterQuery: moddedFilterQuery as PurchaseOrderQuery,
  };
};
