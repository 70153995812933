import { MouseEvent, ReactNode } from 'react';
import { useToggle } from 'react-use';
import { ActiveSortTableColumnProvider } from 'src/contexts/ActiveSortTableColumnContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { EventsTableWithInnerGrouping } from 'src/tables/EventsTable/EventsTableWithInnerGrouping';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  EventWithData,
  UserSetting,
} from 'src/WebApiController';

type InventoryDetailedEventViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  items?: EventWithData[];
  highlightEvent?: string; // Event UUID
  /**
   * If provided, the list will scroll to the month containing the event with the given viagogo virtual id
   */
  scrollToEventId?: string;
};

export const InventoryDetailedEventView = ({
  items,
  highlightEvent,
  scrollToEventId,
  ...rest
}: InventoryDetailedEventViewProps) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);
  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <ColumnResizingContextProvider
      userSettingId={UserSetting.EventsColumnWidths}
    >
      <ActiveSortTableColumnProvider>
        {isColumnModalOpen ? (
          <ColumnSettingsModal<CustomEventsColumn>
            onClose={toggleColumnModal}
            sectionType={SectionType.Events}
          />
        ) : null}

        <CollapsableViewProvider isEnabled={true}>
          <EventsTableWithInnerGrouping
            events={items ?? []}
            before={rest.before}
            topListItemBefore={rest.topListItemBefore}
            columnSettingButton={
              <Button
                variant="textPlain"
                onClick={onColumnSettingButtonClickHandler}
              >
                <LayoutIcon size={vars.iconSize.m} />
                <Content id={ContentId.Columns} />
              </Button>
            }
          />
        </CollapsableViewProvider>
      </ActiveSortTableColumnProvider>
    </ColumnResizingContextProvider>
  );
};
