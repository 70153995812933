import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import {
  OnOutlierSettingsChangeArgs,
  useAutoPricingSettingsFormContext,
} from 'src/components/AutoPricing/useAutoPricingSettingsFormContext';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { ExpandIcon, FoldIcon, InfoOutlineIcon } from 'src/svgs/Viagogo';
import { MinusIcon, PlusIcon } from 'src/svgs/Viagogo';
import { calculatePercentile } from 'src/utils/arrayUtils';
import {
  getUndercutPriceRepresentingMode,
  OUTLIER_MODE_KTH_LOWEST_STRATEGY,
  OutlierModeKthLowestStrategy,
} from 'src/utils/autoPricingUtils';
import { UndercutPriceSettingsMode } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { STANDARD_DEVIATIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { numberToOrdinal, roundToPrecision } from 'src/utils/numberFormatter';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  UiCurrency,
} from 'src/WebApiController';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import * as styles from './ListingDetailPanel.css';

const UNDERCUT_PRICE_MODE_OPTIONS = [
  {
    value: UndercutPriceSettingsMode.Premium,
    children: <PlusIcon size={vars.iconSize.m} />,
  },
  {
    value: UndercutPriceSettingsMode.Discount,
    children: <MinusIcon size={vars.iconSize.m} />,
  },
];

const CheckPrice = (
  value: number | null,
  median: number,
  threshold: number
) => {
  if (!value || !median || threshold <= 0) {
    return null;
  }

  const percentDifference = ((value - median) / median) * 100;
  const absolutePercentDifference = Math.abs(percentDifference);

  if (absolutePercentDifference <= threshold) {
    return {
      isWithinThreshold: true,
      percentDifference: percentDifference,
      absolutePercentDifference: absolutePercentDifference,
    };
  }

  return {
    isWithinThreshold: false,
    isAbove: percentDifference > 0,
    isBelow: percentDifference < 0,
    percentDifference: percentDifference,
    absolutePercentDifference: absolutePercentDifference,
  };
};

type PricingSettingAutoPriceProps = {
  uiCurrency: UiCurrency;
  sellerFee: number | null;
  useVerticalView: boolean;
};

export const PricingSettingAutoPrice: React.FC<
  PricingSettingAutoPriceProps
> = ({ uiCurrency, sellerFee, useVerticalView }) => {
  const resetText = useContent(ContentId.Reset);
  const aboveText = useContent(ContentId.Above).toLocaleLowerCase();
  const belowText = useContent(ContentId.Below).toLocaleLowerCase();

  const { event, selectedListing, compListings } =
    useInventoryEventDetailContext();

  const { watch, reset, setValue, handleSubmit, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { activeAccountWebClientConfig } = useAppContext();

  const { updateActivePosEntityInfo } =
    useActivePosEntityContext<ListingDetails>();

  const { showErrorDialog } = useErrorBoundaryContext();

  const [isLoading, setLoading] = useToggle(false);

  const onSubmitStart = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const onSubmitDone = useCallback(
    async (success = true) => {
      if (selectedListing?.id && success) {
        const updatedListing = await new ListingClient(
          activeAccountWebClientConfig
        ).getListingByListingId(selectedListing.id, undefined, undefined);

        if (updatedListing) {
          updateActivePosEntityInfo({
            event,
            posEntity: updatedListing,
            posEntityId: updatedListing.id,
            posEntityDisplayId: updatedListing.idOnMkp,
          });
        }
      }
      setLoading(false);
    },
    [
      activeAccountWebClientConfig,
      event,
      selectedListing?.id,
      setLoading,
      updateActivePosEntityInfo,
    ]
  );

  const onSubmitHandler = useCallback(
    async (autoPricingSectionForm: ListingDetailsAutoPricingSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingAutoPricingSection(autoPricingSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            reset(autoPricingSectionForm);
            await onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingAutoPricingSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  autoPricingSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog(
            'ListingClient.updateListingAutoPricingSection',
            error,
            {
              trackErrorData: autoPricingSectionForm,
            }
          );
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      onSubmitDone,
      onSubmitStart,
      reset,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(onSubmitHandler)();
  }, [handleSubmit, onSubmitHandler]);

  const onReset = useCallback(() => {
    reset({ ...(formState.defaultValues ?? {}) });
  }, [formState.defaultValues, reset]);

  const input = watch();

  const hasChanges = useMemo(() => {
    const defaultValues = formState.defaultValues;
    return (
      input.netProceedsFloor !== defaultValues?.netProceedsFloor ||
      input.netProceedsCeiling !== defaultValues?.netProceedsCeiling ||
      input.currencyCode !== defaultValues?.currencyCode ||
      input.autoPricingEnabled !== defaultValues?.autoPricingEnabled ||
      input.compListingFloor !== defaultValues?.compListingFloor ||
      input.compListingCeiling !== defaultValues?.compListingCeiling ||
      input.compListingMode !== defaultValues?.compListingMode ||
      input.compListingOnlyForSameZoneEnabled !==
        defaultValues?.compListingOnlyForSameZoneEnabled ||
      input.compListingOnlyForSelectedSectionsEnabled !==
        defaultValues?.compListingOnlyForSelectedSectionsEnabled ||
      input.compListingExcludeFanInventory !==
        defaultValues?.compListingExcludeFanInventory ||
      input.compListingExcludeDefects !==
        defaultValues?.compListingExcludeDefects ||
      input.compListingQuantityScoreAdjustmentEnabled !==
        defaultValues?.compListingQuantityScoreAdjustmentEnabled ||
      input.compListingQuantityScoreAdjustmentOverrideJson !==
        defaultValues?.compListingQuantityScoreAdjustmentOverrideJson ||
      input.undercutMode !== defaultValues?.undercutMode ||
      input.undercutAbsoluteAmount !== defaultValues?.undercutAbsoluteAmount ||
      input.undercutRelativeAmount !== defaultValues?.undercutRelativeAmount ||
      input.circuitBreakerMaxDiscountVelocityPercent !==
        defaultValues?.circuitBreakerMaxDiscountVelocityPercent ||
      input.circuitBreakerMaxDiscountVelocityTicksInHours !==
        defaultValues?.circuitBreakerMaxDiscountVelocityTicksInHours ||
      input.circuitBreakerMinCompListingCount !==
        defaultValues?.circuitBreakerMinCompListingCount ||
      input.circuitBreakerRelativeCeiling !==
        defaultValues?.circuitBreakerRelativeCeiling ||
      input.circuitBreakerRelativeFloor !==
        defaultValues?.circuitBreakerRelativeFloor ||
      input.outlierMode !== defaultValues?.outlierMode ||
      input.outlierStandardDeviations !==
        defaultValues?.outlierStandardDeviations ||
      input.outlierKthLowestLimit !== defaultValues?.outlierKthLowestLimit ||
      input.outlierKthLowestLimitRelativeSpacing !==
        defaultValues?.outlierKthLowestLimitRelativeSpacing ||
      input.outlierKthLowestLimitAbsoluteSpacing !==
        defaultValues?.outlierKthLowestLimitAbsoluteSpacing ||
      input.skipAdvancePricing !== defaultValues?.skipAdvancePricing ||
      input.skipAutoPricing !== defaultValues?.skipAutoPricing ||
      input.skipCompListing !== defaultValues?.skipCompListing ||
      input.skipUndercut !== defaultValues?.skipUndercut ||
      !isEqual(
        input.compListingSelectedSectionSettings,
        defaultValues?.compListingSelectedSectionSettings
      ) ||
      !isEqual(
        input.compListingQuantityFilters,
        defaultValues?.compListingQuantityFilters
      )
    );
  }, [input, formState.defaultValues]);

  const { netProceedsFloor } = input;

  const setPriceFloorText = `${useContent(ContentId.SetPriceFloor)}: ${
    uiCurrency.sym
  }`;

  const medianListingPrice = useMemo(() => {
    const ticketPrices: number[] = [];
    for (const listing of compListings ?? []) {
      if ((listing.availableTickets ?? 0) > 0) {
        const prices = Array(listing.availableTickets ?? 0).fill(
          listing.sellerAllInPrice?.amt ?? 0
        );
        ticketPrices.push(...prices);
      }
    }
    return calculatePercentile(ticketPrices, 50);
  }, [compListings]);

  const priceComparisonResult = useMemo(() => {
    return CheckPrice(netProceedsFloor, medianListingPrice, 25);
  }, [medianListingPrice, netProceedsFloor]);

  const onPriceFloorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v < Number.MAX_VALUE) {
        const netProceedsValueNew = getListPriceFromAllinPrice(
          v,
          null,
          sellerFee
        )!;
        setValue('netProceedsFloor', netProceedsValueNew);
      } else {
        setValue('netProceedsFloor', null);
      }
    },
    [setValue, sellerFee]
  );

  return (
    <div className={styles.pricingSettingsBodyInnerLayout}>
      <div className={styles.autoPriceSettingsContainer}>
        <div className={styles.settingsWrapper}>
          <CompListingPrices
            useVerticalView={useVerticalView}
            uiCurrency={uiCurrency}
          />
          <PosCurrencyField
            rootProps={{ variant: 'small' }}
            className={styles.textAlignRight}
            prefixDisplay={
              <span className={styles.setPriceText}>{setPriceFloorText}</span>
            }
            value={
              getAllInPriceFromListPrice(netProceedsFloor, null, sellerFee) ??
              ''
            }
            onChange={onPriceFloorChange}
            uiCurrency={uiCurrency}
          />
          {priceComparisonResult &&
            !priceComparisonResult.isWithinThreshold && (
              <div className={styles.priceWarning}>
                <InfoOutlineIcon
                  fill={vars.color.textWarning}
                  size={vars.iconSize.m}
                />
                <span className={styles.statementText}>
                  <FormatContent
                    id={FormatContentId.MedianPriceWarning}
                    params={[
                      `${roundToPrecision(
                        priceComparisonResult.absolutePercentDifference,
                        0
                      )}`,
                      `${
                        priceComparisonResult.isAbove ? aboveText : belowText
                      }`,
                    ]}
                  />
                </span>
              </div>
            )}
        </div>

        <OutlierSettings useVerticalView={useVerticalView} />
      </div>
      <div className={styles.innerLayoutFooter}>
        <Button
          variant="text"
          textColor="primary"
          justifyContent="start"
          fontWeight={400}
          onClick={onReset}
          shape="none"
        >
          {resetText}
        </Button>
        <OkButton
          disabled={!hasChanges || isLoading}
          onClick={onSubmit}
          textContentId={ContentId.Save}
        />
      </div>
    </div>
  );
};

type CompListingPricesProps = {
  useVerticalView: boolean;
  uiCurrency: UiCurrency;
};

const CompListingPrices: React.FC<CompListingPricesProps> = ({
  useVerticalView,
  uiCurrency,
}) => {
  const { watch, setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const { undercutRelativeAmount, undercutAbsoluteAmount } = watch();

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const determinPriceText = useContent(ContentId.DeterminePrice);
  const movePriceText = useContent(ContentId.MovePrice);
  const thenText = useContent(ContentId.Then);
  const adjustPriceText = useContent(ContentId.AdjustPrice);

  const compListingPriceTextFirst = useMemo(
    () => `${determinPriceText}: ${movePriceText}`,
    [determinPriceText, movePriceText]
  );

  const compListingPriceTextThen = useMemo(
    () => `${thenText} ${adjustPriceText.toLowerCase()}`,
    [adjustPriceText, thenText]
  );

  if (useVerticalView) {
    return (
      <Stack
        direction="column"
        gap="m"
        width="full"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <div className={styles.compListingPriceInputText}>
          {compListingPriceTextFirst}
        </div>
        <Stack
          gap="m"
          justifyContent="spaceBetween"
          width="full"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <ToggleGroup
            options={UNDERCUT_PRICE_MODE_OPTIONS}
            value={undercutRelativeAmountMode}
            itemClassName={styles.toggleGroupItem}
            itemTransparent
            onValueChange={(value) => {
              if (!value) {
                return;
              }

              const undercutRelativeAmountModeNew =
                value as UndercutPriceSettingsMode;

              setUndercutRelativeAmountMode(undercutRelativeAmountModeNew);

              if (undercutRelativeAmount) {
                const absUndercutRelativeAmountNew = Math.abs(
                  undercutRelativeAmount
                );

                const underCutAbsoluteAmountNew =
                  getUndercutPriceRepresentingMode(
                    absUndercutRelativeAmountNew,
                    undercutRelativeAmountModeNew
                  );

                setValue('undercutRelativeAmount', underCutAbsoluteAmountNew);
              }
            }}
          />
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.By} />
          </div>
          <PosTextField
            type="number"
            className={styles.textAlignRight}
            rootProps={{
              className: styles.numberInput,
              variant: 'small',
            }}
            postfixDisplay="%"
            value={
              undercutRelativeAmount === null
                ? ''
                : roundToPrecision(Math.abs(undercutRelativeAmount) * 100, 8)
            }
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              const absUnderCutRelativeAmountNew =
                v >= 0 && v <= Number.MAX_VALUE ? Math.min(v, 100) / 100 : null;
              const underCutRelativeAmountNew =
                absUnderCutRelativeAmountNew != null
                  ? getUndercutPriceRepresentingMode(
                      absUnderCutRelativeAmountNew,
                      undercutRelativeAmountMode
                    )
                  : null;

              setValue('undercutRelativeAmount', underCutRelativeAmountNew);
            }}
          />
        </Stack>
        <div className={styles.compListingPriceInputText}>
          {compListingPriceTextThen}
        </div>
        <Stack
          gap="m"
          justifyContent="spaceBetween"
          width="full"
          alignItems="center"
          style={{ overflow: 'auto' }}
        >
          <ToggleGroup
            options={UNDERCUT_PRICE_MODE_OPTIONS}
            value={undercutAbsoluteAmountMode}
            itemClassName={styles.toggleGroupItem}
            itemTransparent
            onValueChange={(value) => {
              if (!value) {
                return;
              }

              const undercutAbsoluteAmountModeNew =
                value as UndercutPriceSettingsMode;

              setUndercutAbsoluteAmountMode(undercutAbsoluteAmountModeNew);

              if (undercutAbsoluteAmount) {
                const absUndercutAbsoluteAmountNew = Math.abs(
                  undercutAbsoluteAmount
                );

                const underCutAbsoluteAmountNew =
                  getUndercutPriceRepresentingMode(
                    absUndercutAbsoluteAmountNew,
                    undercutAbsoluteAmountModeNew
                  );

                setValue('undercutAbsoluteAmount', underCutAbsoluteAmountNew);
              }
            }}
          />
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.By} />
          </div>
          <PosCurrencyField
            rootProps={{
              className: styles.numberInput,
              variant: 'small',
            }}
            className={styles.textAlignRight}
            uiCurrency={uiCurrency ?? undefined}
            value={
              undercutAbsoluteAmount == null
                ? ''
                : Math.abs(undercutAbsoluteAmount)
            }
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const underCutRelativeAmountNew =
                  getUndercutPriceRepresentingMode(
                    Math.abs(value),
                    undercutAbsoluteAmountMode
                  );

                setValue('undercutAbsoluteAmount', underCutRelativeAmountNew);
              }
            }}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Content id={ContentId.AdjustPrice} />
      <Stack
        gap="m"
        justifyContent="spaceBetween"
        width="full"
        alignItems="center"
        style={{ overflow: 'auto' }}
      >
        <ToggleGroup
          options={UNDERCUT_PRICE_MODE_OPTIONS}
          value={undercutRelativeAmountMode}
          itemClassName={styles.toggleGroupItem}
          itemTransparent
          onValueChange={(value) => {
            if (!value) {
              return;
            }

            const undercutRelativeAmountModeNew =
              value as UndercutPriceSettingsMode;

            setUndercutRelativeAmountMode(undercutRelativeAmountModeNew);

            if (undercutRelativeAmount) {
              const absUndercutRelativeAmountNew = Math.abs(
                undercutRelativeAmount
              );

              const underCutAbsoluteAmountNew =
                getUndercutPriceRepresentingMode(
                  absUndercutRelativeAmountNew,
                  undercutRelativeAmountModeNew
                );

              setValue('undercutRelativeAmount', underCutAbsoluteAmountNew);
            }
          }}
        />
        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.Price} />
        </div>
        <PosTextField
          type="number"
          className={styles.textAlignRight}
          rootProps={{
            className: styles.numberInput,
            variant: 'small',
          }}
          postfixDisplay="%"
          value={
            undercutRelativeAmount === null
              ? ''
              : roundToPrecision(Math.abs(undercutRelativeAmount) * 100, 8)
          }
          onChange={(e) => {
            const v = parseFloat(e.target.value);
            const absUnderCutRelativeAmountNew =
              v >= 0 && v <= Number.MAX_VALUE ? Math.min(v, 100) / 100 : null;
            const underCutRelativeAmountNew =
              absUnderCutRelativeAmountNew != null
                ? getUndercutPriceRepresentingMode(
                    absUnderCutRelativeAmountNew,
                    undercutRelativeAmountMode
                  )
                : null;

            setValue('undercutRelativeAmount', underCutRelativeAmountNew);
          }}
        />
        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.Then} />
        </div>
        <ToggleGroup
          options={UNDERCUT_PRICE_MODE_OPTIONS}
          value={undercutAbsoluteAmountMode}
          itemClassName={styles.toggleGroupItem}
          itemTransparent
          onValueChange={(value) => {
            if (!value) {
              return;
            }

            const undercutAbsoluteAmountModeNew =
              value as UndercutPriceSettingsMode;

            setUndercutAbsoluteAmountMode(undercutAbsoluteAmountModeNew);

            if (undercutAbsoluteAmount) {
              const absUndercutAbsoluteAmountNew = Math.abs(
                undercutAbsoluteAmount
              );

              const underCutAbsoluteAmountNew =
                getUndercutPriceRepresentingMode(
                  absUndercutAbsoluteAmountNew,
                  undercutAbsoluteAmountModeNew
                );

              setValue('undercutAbsoluteAmount', underCutAbsoluteAmountNew);
            }
          }}
        />
        <PosCurrencyField
          rootProps={{
            className: styles.numberInput,
            variant: 'small',
          }}
          className={styles.textAlignRight}
          uiCurrency={uiCurrency ?? undefined}
          value={
            undercutAbsoluteAmount == null
              ? ''
              : Math.abs(undercutAbsoluteAmount)
          }
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            if (!isNaN(value)) {
              const underCutRelativeAmountNew =
                getUndercutPriceRepresentingMode(
                  Math.abs(value),
                  undercutAbsoluteAmountMode
                );

              setValue('undercutAbsoluteAmount', underCutRelativeAmountNew);
            }
          }}
        />
      </Stack>
    </>
  );
};

type OutlierSettingsProps = {
  useVerticalView: boolean;
};

const OutlierSettings: React.FC<OutlierSettingsProps> = ({
  useVerticalView,
}) => {
  const { watch } = useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const {
    outlierMode,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierStandardDeviations,
  } = watch();
  const [showOutlierSettings, setShowOutlierSettings] =
    useState<boolean>(false);
  const { onOutlierSettingsChange } = useAutoPricingSettingsFormContext();

  const { outlierModes } = useAvailableAutopricingModes(
    true,
    null,
    null,
    true,
    false,
    false,
    true
  );

  if (useVerticalView) {
    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            variant="textPlain"
            style={{
              padding: vars.spacing['xxs'],
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ fontWeight: 400 }}>
              <Content id={ContentId.Outlier} />{' '}
              <Content id={ContentId.Settings} />
            </div>
          </Button>
        </Popover.Trigger>
        <Popover.Content side="top">
          <Stack
            width="full"
            direction="column"
            gap="m"
            className={styles.outlierSettingsPopoverContainer}
          >
            <PosEnumSelect
              value={outlierMode}
              valueOptionsContent={outlierModes}
              enableEmptySelection={false}
              className={clsx(styles.inputInline, styles.selectContainer)}
              triggerContentClassName={styles.selectTriggerContent}
              prefixDisplay={
                <span className={styles.inputPrefix}>
                  <Content id={ContentId.DetectionMode} />
                </span>
              }
              onClick={(e) => e.stopPropagation()}
              onChange={(v) =>
                onOutlierSettingsChange({
                  outlierModeNew: v as AutoPricingOutlierMode,
                })
              }
            />
            {outlierMode === AutoPricingOutlierMode.KthLowest ? (
              <KthLowestOutlierDetectionSettingsInput
                outlierKthLowestLimit={outlierKthLowestLimit}
                outlierKthLowestLimitAbsoluteSpacing={
                  outlierKthLowestLimitAbsoluteSpacing
                }
                outlierKthLowestLimitRelativeSpacing={
                  outlierKthLowestLimitRelativeSpacing
                }
                onOutlierSettingsChange={onOutlierSettingsChange}
              />
            ) : outlierMode === AutoPricingOutlierMode.StandardDeviations ? (
              <StandardDeviationOutlierDetectionSettingsInput
                outlierStandardDeviations={outlierStandardDeviations}
                onOutlierSettingsChange={onOutlierSettingsChange}
              />
            ) : null}
          </Stack>
        </Popover.Content>
      </Popover.Root>
    );
  }

  return (
    <div className={styles.settingsWrapper}>
      <Stack
        justifyContent="spaceBetween"
        alignItems="center"
        className={clsx(styles.settingsHeaderContainer, {
          [styles.settingsHeaderContainerHide]: !showOutlierSettings,
        })}
        onClick={() => setShowOutlierSettings((prev) => !prev)}
      >
        <span>
          <Content id={ContentId.Outlier} /> <Content id={ContentId.Settings} />
        </span>
        {showOutlierSettings ? (
          <FoldIcon size={vars.iconSize.m} />
        ) : (
          <ExpandIcon size={vars.iconSize.m} />
        )}
      </Stack>
      {showOutlierSettings ? (
        <div className={styles.outlierSettingsInnerLayout}>
          <PosEnumSelect
            value={outlierMode}
            valueOptionsContent={outlierModes}
            enableEmptySelection={false}
            className={clsx(styles.inputInline, styles.selectContainer)}
            triggerContentClassName={styles.selectTriggerContent}
            prefixDisplay={
              <span className={styles.inputPrefix}>
                <Content id={ContentId.DetectionMode} />
              </span>
            }
            onClick={(e) => e.stopPropagation()}
            onChange={(v) =>
              onOutlierSettingsChange({
                outlierModeNew: v as AutoPricingOutlierMode,
              })
            }
          />
          {outlierMode === AutoPricingOutlierMode.KthLowest ? (
            <KthLowestOutlierDetectionSettingsInput
              outlierKthLowestLimit={outlierKthLowestLimit}
              outlierKthLowestLimitAbsoluteSpacing={
                outlierKthLowestLimitAbsoluteSpacing
              }
              outlierKthLowestLimitRelativeSpacing={
                outlierKthLowestLimitRelativeSpacing
              }
              onOutlierSettingsChange={onOutlierSettingsChange}
            />
          ) : outlierMode === AutoPricingOutlierMode.StandardDeviations ? (
            <StandardDeviationOutlierDetectionSettingsInput
              outlierStandardDeviations={outlierStandardDeviations}
              onOutlierSettingsChange={onOutlierSettingsChange}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const KthLowestOutlierDetectionSettingsInput = ({
  outlierKthLowestLimit,
  outlierKthLowestLimitRelativeSpacing,
  outlierKthLowestLimitAbsoluteSpacing,
  onOutlierSettingsChange,
}: {
  outlierKthLowestLimit: number | null;
  outlierKthLowestLimitRelativeSpacing: number | null;
  outlierKthLowestLimitAbsoluteSpacing: number | null;
  onOutlierSettingsChange: (args: OnOutlierSettingsChangeArgs) => void;
}) => {
  const [outlierKthLowestStrategy, setKthLowestOutlierStrategy] = useState(
    OutlierModeKthLowestStrategy.Relative
  );

  const ignoreListingsText = useContent(
    ContentId.OutlierSettingsIngoreListings
  );
  const belowTheText = useContent(ContentId.BelowThe);
  const cheapestText = useContent(ContentId.Cheapest);

  const lowestLimitOptions = useMemo(() => {
    const options: Record<string, string> = {};
    for (const [index, _] of Array.from({ length: 100 }).entries()) {
      if (index <= 0) continue;
      const value = index + 1;
      options[index] = `${numberToOrdinal(
        value
      )} ${cheapestText.toLocaleLowerCase()}`;
    }
    return options;
  }, [cheapestText]);

  return (
    <>
      <Stack gap="m" direction="column" alignItems="center" width="full">
        {outlierKthLowestStrategy == OutlierModeKthLowestStrategy.Relative ? (
          <PosTextField
            type="number"
            prefixDisplay={
              <span className={styles.inputPrefix}>{ignoreListingsText}</span>
            }
            postfixDisplay={
              <ToggleGroup
                options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
                value={outlierKthLowestStrategy}
                itemClassName={styles.toggleGroupItemInner}
                itemTransparent
                onValueChange={(value) => {
                  if (!value) {
                    return;
                  }

                  const strat = value as OutlierModeKthLowestStrategy;
                  setKthLowestOutlierStrategy(strat);
                  onOutlierSettingsChange({
                    outlierModeNew: AutoPricingOutlierMode.KthLowest,
                    outlierKthLowestLimitRelativeSpacingNew: null,
                    outlierKthLowestLimitAbsoluteSpacingNew: null,
                  });
                }}
              />
            }
            min={-1}
            max={100}
            rootProps={{ variant: 'small' }}
            className={clsx(styles.inputInline, styles.textAlignRight)}
            value={outlierKthLowestLimitRelativeSpacing ?? ''}
            onChange={(e) => {
              let v = parseInt(e.target.value);
              if (v < 0) {
                v = 0;
              } else if (v > 100) {
                v = 100;
              }

              if (!isNaN(v)) {
                onOutlierSettingsChange({
                  outlierModeNew: AutoPricingOutlierMode.KthLowest,
                  outlierKthLowestLimitRelativeSpacingNew: v,
                  outlierKthLowestLimitAbsoluteSpacingNew: null,
                });
              }
            }}
          />
        ) : (
          <PosTextField
            type="number"
            prefixDisplay={
              <span className={styles.inputPrefix}>{ignoreListingsText}</span>
            }
            postfixDisplay={
              <ToggleGroup
                options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
                value={outlierKthLowestStrategy}
                itemClassName={styles.toggleGroupItemInner}
                itemTransparent
                onValueChange={(value) => {
                  if (!value) {
                    return;
                  }

                  const strat = value as OutlierModeKthLowestStrategy;
                  setKthLowestOutlierStrategy(strat);
                  onOutlierSettingsChange({
                    outlierModeNew: AutoPricingOutlierMode.KthLowest,
                    outlierKthLowestLimitRelativeSpacingNew: null,
                    outlierKthLowestLimitAbsoluteSpacingNew: null,
                  });
                }}
              />
            }
            rootProps={{ variant: 'small' }}
            className={clsx(styles.inputInline, styles.textAlignRight)}
            value={outlierKthLowestLimitAbsoluteSpacing ?? 0}
            onChange={(e) => {
              const v = parseFloat(e.target.value);

              if (!isNaN(v)) {
                onOutlierSettingsChange({
                  outlierModeNew: AutoPricingOutlierMode.KthLowest,
                  outlierKthLowestLimitRelativeSpacingNew: null,
                  outlierKthLowestLimitAbsoluteSpacingNew: v,
                });
              }
            }}
          />
        )}
        <div className={styles.outlierSettingsInnerLayout}>
          <PosSelect
            value={outlierKthLowestLimit ? outlierKthLowestLimit + '' : ''}
            valueOptionsContent={lowestLimitOptions}
            className={clsx(styles.inputInline, styles.selectContainer)}
            triggerContentClassName={styles.selectTriggerContent}
            onClick={(e) => e.stopPropagation()}
            prefixDisplay={
              <span className={styles.inputPrefix}>{belowTheText}</span>
            }
            contentProps={{ style: { maxHeight: '400px' } }}
            onChange={(v) => {
              const value = parseInt(v);
              if (!isNaN(value)) {
                onOutlierSettingsChange({
                  outlierModeNew: AutoPricingOutlierMode.KthLowest,
                  outlierKthLowestLimitNew: value,
                });
              }
            }}
          />
        </div>
      </Stack>
    </>
  );
};

const StandardDeviationOutlierDetectionSettingsInput = ({
  outlierStandardDeviations,
  onOutlierSettingsChange,
}: {
  outlierStandardDeviations: number | null;
  onOutlierSettingsChange: (input: OnOutlierSettingsChangeArgs) => void;
}) => {
  return (
    <PosEnumSelect
      value={outlierStandardDeviations?.toString()}
      valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
      enableEmptySelection={true}
      onClick={(e) => e.stopPropagation()}
      onChange={(v) => {
        const value = parseInt(v ?? '');
        if (value != null && !isNaN(value)) {
          onOutlierSettingsChange({
            outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
            outlierStandardDeviationsNew: value,
          });
        } else {
          onOutlierSettingsChange({
            outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
            outlierStandardDeviationsNew: null,
          });
        }
      }}
    />
  );
};
