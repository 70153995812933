import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { Divider } from 'src/modals/common/Summary/Summary.styled';
import { calculatePercentile } from 'src/utils/arrayUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';

import * as styles from './SelectedListingMapStats.css';
import { StatLabel } from './StatLabel';

export interface ListingMapStats {
  listingCount: number;
  ticketCount: number;
  ticketPriceMin: number;
  ticketPriceP25: number;
  ticketPriceAvg: number;
}

export const SelectedListingMapStats = () => {
  const { loginContext } = useAppContext();
  const { selectedListing, compListings } = useInventoryEventDetailContext();

  const currencyCode = useMemo(() => {
    return (
      selectedListing?.currency ??
      loginContext?.user?.activeAccount.currencyCode ??
      null
    );
  }, [
    loginContext?.user?.activeAccount.currencyCode,
    selectedListing?.currency,
  ]);

  const listingQtyText = useContent(ContentId.ListingQty);
  const ticketQtyText = useContent(ContentId.TicketQty);
  const minText = useContent(ContentId.Min);
  const p25Text = useContent(ContentId.P25);
  const p50Text = useContent(ContentId.P50);

  const stats = useMemo(() => {
    const listingCount = (compListings ?? []).length;
    let ticketCount = 0;
    const ticketPrices: number[] = [];
    for (const listing of compListings ?? []) {
      if ((listing.availableTickets ?? 0) > 0) {
        ticketCount += listing.availableTickets ?? 0;
        const prices = Array(listing.availableTickets ?? 0).fill(
          listing.sellerAllInPrice?.amt ?? 0
        );
        ticketPrices.push(...prices);
      }
    }
    return {
      listingCount,
      ticketCount,
      ticketPriceMin: calculatePercentile(ticketPrices, 0),
      ticketPriceP25: calculatePercentile(ticketPrices, 25),
      ticketPriceAvg: calculatePercentile(ticketPrices, 50),
    };
  }, [compListings]);

  const formatPrice = useCallback(
    (price: number) => {
      return applyNumberFormatting({
        inputNumber: price,
        currencyCode,
        currencyDecimalDigits: 2,
      });
    },
    [currencyCode]
  );

  return (
    <div className={styles.statsContainer}>
      <StatLabel
        stat={stats.listingCount.toLocaleString()}
        title={listingQtyText}
      />
      <Divider height={100} />
      <StatLabel
        stat={stats.ticketCount.toLocaleString()}
        title={ticketQtyText}
      />
      <Divider height={100} />
      <StatLabel
        stat={formatPrice(stats.ticketPriceMin) ?? ''}
        title={minText}
      />
      <Divider height={100} />
      <StatLabel
        stat={formatPrice(stats.ticketPriceP25) ?? ''}
        title={p25Text}
      />
      <Divider height={100} />
      <StatLabel
        stat={formatPrice(stats.ticketPriceAvg) ?? ''}
        title={p50Text}
      />
    </div>
  );
};
