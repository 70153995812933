import {
  useQueries,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { cloneDeep, merge } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useListExpansion } from 'src/hooks/useListExpansion';
import {
  DATA_REFRESH_RATE_IN_MILLIS_LONG,
  DATA_REFRESH_RATE_IN_MILLIS_SHORT,
  MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS,
} from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import {
  EmptyEntityWithTicketsQuery,
  QueryWithViewMode,
} from 'src/utils/eventQueryUtils';
import { searchEvents, sortEvents } from 'src/utils/eventWithDataUtils';
import { getFlattenedListingIds } from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogCounts,
  CatalogResults,
  EntityWithTicketsQuery,
  EventEntityCounts,
  EventWithData,
  InventoryViewMode,
  Listing,
  ListingGroup,
  ListingQuery,
  PurchaseOrderQuery,
  PurchaseOrderTicketGroup,
  Sale,
  SaleQuery,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { NO_GROUP_ID } from '../MultiSelectionContext';
import {
  CatalogDataContextProviderProps,
  CatalogDataContextV2,
  ExpandedCallbackStatus,
  ExpandedEventData,
  ItemIdWithRowVersion,
} from './CatalogDataContext.types';
import { getEventsWithNonZeroCounts } from './CatalogDataContext.utils';

export function CatalogDataContextV2Provider<
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  entityType,
  getCatalogDataExpanded,
  onCatalogDataExpanded,
  transformEventData,
  children,
  disabled,
  disableAutoRefresh,
  ignoreMaxCount,
}: CatalogDataContextProviderProps<TQuery>) {
  const queryClient = useQueryClient();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const { filterQuery, isQueryInitialized } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();
  const eventsExpansion = useListExpansion<string>();
  const { expandedListItems } = eventsExpansion;

  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  // Flags to track the status of the expanded callback
  const [expandedCallbackStatus, setExpandedCallbackStatus] = useState<
    Record<string, ExpandedCallbackStatus>
  >({});
  const onExpandedCallbackStatusUpdate = useCallback(
    (stringQueryKey: string, status: ExpandedCallbackStatus) =>
      setExpandedCallbackStatus((prev) => ({
        ...prev,
        [stringQueryKey]: status,
      })),
    []
  );

  const {
    searchText,
    sortBy,
    isSortDescending,
    performerIds,
    venueIds,
    viewMode, // this is not used for server and keeping it in this cause refresh to happen 2x
    ...serverSideFilterQuery // Don't use it because it's a new object on every render
  } = filterQuery;

  const countFilterQuery = useMemo(() => {
    const query = Object.fromEntries(
      Object.entries(serverSideFilterQuery).filter(([_, v]) => v != null)
    );
    return {
      ...query,
      timezoneOffsetMins:
        query.timezoneOffsetMins ??
        EmptyEntityWithTicketsQuery.timezoneOffsetMins,
    } as TQuery;
  }, [serverSideFilterQuery]);

  const [catalogResults, setCatalogResults] = useState<CatalogResults>();
  const eventsFullyLoaded = useMemo(() => {
    if (catalogResults?.events == null) {
      return false; // we haven't loaded the events yet
    }

    const allEvents = Object.values(catalogResults.events);
    return allEvents.every((ev) => ev.counts != null && ev.event != null);
  }, [catalogResults?.events]);

  /* Arguments for the catalog queries */

  const eventIdsNeedingData = useMemo(() => {
    const eventCountsMissingDataIds = Object.values(
      catalogResults?.events ?? {}
    )
      .filter((ev) => ev.event == null && ev.counts.viagVirtId != null)
      .map((ev) => ev.counts.viagVirtId);

    const customEventIds =
      countFilterQuery.eventOrMappingIds?.filter(
        (id) => !catalogResults?.events[id]
      ) ?? [];

    return Array.from(
      new Set([...eventCountsMissingDataIds, ...customEventIds])
    ).toSorted();
  }, [catalogResults, countFilterQuery.eventOrMappingIds]);

  /* QueryKey for the catalog queries */

  const catalogCountQueryKey = useMemo(
    () => [queryKey + 'Count', entityType, countFilterQuery],
    [entityType, queryKey, countFilterQuery]
  );

  const catalogDataQueryKey = useMemo(
    () => [queryKey + 'Data', eventIdsNeedingData],
    [eventIdsNeedingData, queryKey]
  );

  /* Conditions for the catalog queries */

  const shouldQueryCounts = useMemo(
    () => activeAccountWebClientConfig.activeAccountId != null && !disabled,
    [activeAccountWebClientConfig.activeAccountId, disabled]
  );

  const shouldQueryEventData = useMemo(
    () =>
      Boolean(eventIdsNeedingData.length) &&
      activeAccountWebClientConfig.activeAccountId != null &&
      isQueryInitialized &&
      !disabled,
    [
      activeAccountWebClientConfig.activeAccountId,
      disabled,
      eventIdsNeedingData.length,
      isQueryInitialized,
    ]
  );

  const mergeCatalogCounts = useCallback(
    (newResults: CatalogResults, counts?: CatalogCounts | null) => {
      if (!counts) {
        return undefined;
      }

      newResults.purchaseCount = counts.purchaseCount;

      // Reset all the counts first
      Object.values(newResults.events).forEach((ev) => {
        ev.counts = {
          listCnt: 0,
          listGrpCnt: 0,
          ungrListCnt: 0,
          salesCnt: 0,
          pricedListCnt: 0,
          tktGrpsCnt: 0,
          poCnt: 0,
        } as EventEntityCounts;
      });

      Object.entries(counts.entityCnts).forEach(([id, cnts]) => {
        const ev = newResults.events[id] ?? ({} as EventWithData);
        ev.counts = cnts;

        newResults.events[id] = ev;
      });

      return newResults;
    },
    []
  );

  const combine = useCallback(
    (results: UseQueryResult<CatalogResults | CatalogCounts | null>[]) => {
      const eventQuery = results[0] as UseQueryResult<CatalogResults | null>;
      const countsQuery = results[1] as UseQueryResult<CatalogCounts | null>;

      if (
        (shouldQueryEventData && eventQuery.isLoading) ||
        (shouldQueryCounts && countsQuery.isLoading)
      ) {
        return;
      }

      const failureReason =
        (shouldQueryEventData && eventQuery.failureReason) ||
        (shouldQueryCounts && countsQuery.failureReason);

      if (failureReason) {
        const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
          null,
          failureReason?.toString()
        );
        setErrorInfo({
          errorHeader: headerDisplay,
          errorMessage: messageDisplay,
        });
      } else if (errorInfo) {
        setErrorInfo(undefined);
      }

      if (!eventQuery.data && !countsQuery.data) {
        return;
      }

      const newCatalogResults =
        cloneDeep(catalogResults) ??
        ({
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
          viagIdToLatestIdLookup: {},
          posIdToViagIdLookup: {},
          purchaseCount: 0,
        } as CatalogResults);
      if (eventQuery.data) {
        merge(newCatalogResults, eventQuery.data);
      }

      if (countsQuery.data) {
        mergeCatalogCounts(newCatalogResults, countsQuery.data);

        if (newCatalogResults && !isEqual(catalogResults, newCatalogResults)) {
          setCatalogResults(cloneDeep(newCatalogResults));
          console.debug('setCatalogResults updated with counts data');
        }
      }
    },
    [
      catalogResults,
      errorInfo,
      mergeCatalogCounts,
      shouldQueryCounts,
      shouldQueryEventData,
    ]
  );

  /* The catalog queries */

  const refetchIntervalLong = !disableAutoRefresh
    ? DATA_REFRESH_RATE_IN_MILLIS_LONG
    : false;

  const catalogQueries = useMemo(
    () => ({
      queries: [
        {
          queryKey: catalogDataQueryKey,
          queryFn: async (): Promise<CatalogResults | null> => {
            setErrorInfo(undefined);

            if (!shouldQueryEventData) {
              return null;
            }

            return tryInvokeApi(
              async () => {
                const data = await new CatalogClient(
                  activeAccountWebClientConfig
                ).getCatalog(eventIdsNeedingData);

                return data;
              },
              (error) => {
                const { headerDisplay, messageDisplay } =
                  getErrorInfoFromStatusCode(error?.status, error?.message);
                setErrorInfo({
                  errorHeader: headerDisplay,
                  errorMessage: messageDisplay,
                });
                trackError('CatalogClient.getCatalog', error);
              }
            );
          },
          enabled: shouldQueryEventData,
          staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
          refetchOnWindowFocus: false,
          refetchInterval: refetchIntervalLong as number | false,
          meta: {
            persist: false, // we do not want to persist the catalog data, we just need the count persisted, the catalog are obtained in C# cache (no SQL)
          },
        },
        {
          queryKey: catalogCountQueryKey,
          queryFn: async (): Promise<CatalogCounts | null> => {
            setErrorInfo(undefined);

            if (!shouldQueryCounts) {
              return null;
            }

            return tryInvokeApi(
              async () => {
                const client = new CatalogClient(activeAccountWebClientConfig);

                if (entityType === ActionOutboxEntityType.Listing) {
                  const counts = await client.getCatalogCountForListings(
                    countFilterQuery as unknown as ListingQuery
                  );

                  return counts;
                } else if (entityType === ActionOutboxEntityType.Sale) {
                  const counts = await client.getCatalogCountForSales(
                    countFilterQuery as unknown as SaleQuery
                  );

                  return counts;
                }
                if (
                  entityType === ActionOutboxEntityType.TicketGroup ||
                  entityType === ActionOutboxEntityType.Purchase
                ) {
                  const counts = await client.getCatalogCountForPurchaseOrders(
                    countFilterQuery as unknown as PurchaseOrderQuery
                  );

                  return counts;
                }

                return null;
              },
              (error) => {
                const { headerDisplay, messageDisplay } =
                  getErrorInfoFromStatusCode(error?.status, error?.message);
                setErrorInfo({
                  errorHeader: headerDisplay,
                  errorMessage: messageDisplay,
                });
                trackError(
                  queryKey + 'Count: ' + entityType,
                  error,
                  countFilterQuery
                );
              }
            );
          },
          enabled: shouldQueryCounts,
          staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
          refetchOnWindowFocus: false,
          refetchOnMount: true,
          refetchInterval: refetchIntervalLong as number | false,
        },
      ],
    }),
    [
      activeAccountWebClientConfig,
      catalogCountQueryKey,
      catalogDataQueryKey,
      entityType,
      eventIdsNeedingData,
      queryKey,
      refetchIntervalLong,
      countFilterQuery,
      shouldQueryCounts,
      shouldQueryEventData,
      trackError,
    ]
  );

  // Use the extracted variable in the useQueries hook
  const catalogResultsFromHook = useQueries(catalogQueries);

  useEffect(() => {
    combine(catalogResultsFromHook);
  }, [catalogResultsFromHook, combine]);

  const eventIdsToGetItemsFor = useMemo(() => {
    if (!catalogResults) {
      return [] as [string][];
    }
    let listingCount = 0;
    let salesCount = 0;

    const eventsToGetItemsFor = getEventsWithNonZeroCounts(
      entityType,
      searchEvents(catalogResults, searchText, performerIds, venueIds),
      true
    )
      .map((ev) => {
        if (ev) {
          const evCount = ev?.counts;
          listingCount += evCount?.listCnt ?? 0;
          salesCount += evCount?.salesCnt ?? 0;
          return ev;
        }
      })
      .filter((ev) => ev)
      .map((ev) => ev!);

    let isTooManyItems = false;
    if (!ignoreMaxCount && viewMode === InventoryViewMode.FlattenedView) {
      // Only one of these are non-zero
      if (listingCount) {
        isTooManyItems = listingCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;
      } else if (salesCount) {
        isTooManyItems = salesCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;
      }
    }
    const results =
      viewMode === InventoryViewMode.FlattenedView
        ? isTooManyItems &&
          // If the catalog shows a count of way too many items and we're actually not filtering to the individual items, don't query at all
          !countFilterQuery.entityIds?.length &&
          !countFilterQuery.marketplaceEntityIds?.length
          ? []
          : // For flatten view - we know we're gonna get all of them at once, so just bundle all the event ids in 1 array
            [eventsToGetItemsFor?.map((ev) => ev.event.viagVirtualId)]
        : // For tile view, we only want 1 event per query
          expandedListItems.map((id) => [id]);

    // Finally, we need to include the PosEventIds with all the ids, because in some cases
    // the ViagogoVirtualIds do not resolve properly due to the outdated cache, and missing items are returned
    // for merged events
    return results.map((idArray) => {
      const allEvents = eventsToGetItemsFor
        .filter((ev) => idArray.includes(ev.event.viagVirtualId))
        .flatMap((ev) => ev.event.posIds.map((posId) => `old:${posId}`));
      return idArray.concat(allEvents);
    });
  }, [
    catalogResults,
    entityType,
    searchText,
    performerIds,
    venueIds,
    ignoreMaxCount,
    viewMode,
    countFilterQuery.entityIds?.length,
    countFilterQuery.marketplaceEntityIds?.length,
    expandedListItems,
  ]);

  const realExpandedDataItemsQueryKey = useMemo(
    () => [queryKey + 'Expanded', countFilterQuery],
    [queryKey, countFilterQuery]
  );

  const shouldQueryExpandedItems = useMemo(
    () =>
      activeAccountWebClientConfig.activeAccountId != null &&
      countFilterQuery != null &&
      !disabled,
    [activeAccountWebClientConfig.activeAccountId, disabled, countFilterQuery]
  );

  const refreshInterval = !disableAutoRefresh
    ? DATA_REFRESH_RATE_IN_MILLIS_SHORT
    : false;

  const onCatalogDataExpandedCallbackCheck = useCallback(
    async (
      queryResults: UseQueryResult<
        ExpandedEventData | null | undefined,
        Error
      >[]
    ) => {
      if (!onCatalogDataExpanded) {
        return;
      }
      const queryIds = eventIdsToGetItemsFor ?? [];
      for (let index = 0; index < queryIds.length; index++) {
        const ids = queryIds[index];
        const queryKey = [...realExpandedDataItemsQueryKey, ...ids.toSorted()];
        const stringQueryKey = queryKey.toString();
        const queryResult = queryResults[index];
        if (
          expandedCallbackStatus[stringQueryKey] ===
          ExpandedCallbackStatus.Pending
        ) {
          onExpandedCallbackStatusUpdate(
            stringQueryKey,
            ExpandedCallbackStatus.InProgress
          );
          const newData = await onCatalogDataExpanded(
            ids,
            countFilterQuery as TQuery,
            queryResult?.data ?? undefined
          );
          queryClient.setQueryData(queryKey, newData);
          onExpandedCallbackStatusUpdate(
            stringQueryKey,
            ExpandedCallbackStatus.Done
          );
        }
      }
    },
    [
      eventIdsToGetItemsFor,
      expandedCallbackStatus,
      onCatalogDataExpanded,
      onExpandedCallbackStatusUpdate,
      queryClient,
      realExpandedDataItemsQueryKey,
      countFilterQuery,
    ]
  );

  const expandedItemsQueryOptions = useMemo(
    () => ({
      queries: (eventIdsToGetItemsFor ?? []).map((ids) => {
        const queryKey = [...realExpandedDataItemsQueryKey, ...ids.toSorted()];
        return {
          queryKey: queryKey,
          queryFn: async () => {
            if (!shouldQueryExpandedItems) {
              return null;
            }

            return tryInvokeApi(
              async () => {
                const data = await getCatalogDataExpanded?.(
                  ids[0] === NO_GROUP_ID ? [] : ids,
                  countFilterQuery as TQuery
                );
                if (onCatalogDataExpanded) {
                  onExpandedCallbackStatusUpdate(
                    queryKey.toString(),
                    ExpandedCallbackStatus.Pending
                  );
                }
                return data;
              },
              (error: ErrorTypes) => {
                showErrorDialog('CatalogClient.getCatalogDataExpanded', error, {
                  trackErrorData: {
                    eventIds: ids,
                    filterQuery,
                  },
                });
              }
            );
          },
          enabled: shouldQueryExpandedItems,
          staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
          refetchOnWindowFocus: false,
          refetchInterval: refreshInterval as number | false,
        };
      }),
      combine: (
        results: UseQueryResult<ExpandedEventData | null | undefined, Error>[]
      ) => {
        onCatalogDataExpandedCallbackCheck(results);

        return results;
      },
    }),
    [
      eventIdsToGetItemsFor,
      filterQuery,
      getCatalogDataExpanded,
      onCatalogDataExpanded,
      onCatalogDataExpandedCallbackCheck,
      onExpandedCallbackStatusUpdate,
      realExpandedDataItemsQueryKey,
      refreshInterval,
      countFilterQuery,
      shouldQueryExpandedItems,
      showErrorDialog,
    ]
  );

  const queryWithExpandedItems = useQueries(expandedItemsQueryOptions);

  const [eventsTransformed, setEventsTransformed] = useState<EventWithData[]>();

  // This to apply client-side filtering before transformation of the data
  useEffect(() => {
    if (!eventsFullyLoaded) {
      console.debug('eventsTransformed skipped due no complete event data');
      return;
    }

    const newExpandedData = merge(
      {},
      ...queryWithExpandedItems.map((r) => r.data ?? {})
    ) as ExpandedEventData;

    const newExpandedEvents = transformEventData(
      entityType,
      searchEvents(catalogResults!, searchText, performerIds, venueIds),
      newExpandedData,
      queryWithExpandedItems.some((q) => q.isLoading),
      true
    );

    const transformed = sortEvents(
      catalogResults!,
      newExpandedEvents,
      sortBy,
      isSortDescending
    );

    if (transformed && !isEqual(transformed, eventsTransformed)) {
      setEventsTransformed(transformed);
      console.debug(
        'eventsTransformed updated with transformed: ' + transformed.length
      );
    }
  }, [
    catalogResults,
    entityType,
    eventsFullyLoaded,
    eventsTransformed,
    isSortDescending,
    performerIds,
    queryWithExpandedItems,
    searchText,
    sortBy,
    transformEventData,
    venueIds,
  ]);

  const refreshCatalog = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [queryKey + 'Count'],
      refetchType: 'active',
    });
    return await queryClient.invalidateQueries({
      queryKey: [queryKey + 'Data'],
      refetchType: 'active',
    });
  }, [queryClient, queryKey]);

  const refreshExpandedListItems = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey + 'Expanded'],
      refetchType: 'none', // we don't want to refetch any, we'll manually refetch the active
    });

    return queryClient.invalidateQueries({
      queryKey: realExpandedDataItemsQueryKey,
    });
  }, [queryClient, queryKey, realExpandedDataItemsQueryKey]);

  /**
   * Refreshes the event data for a specific item
   */
  const refreshEventForItems = useCallback(
    (itemIds: ItemIdWithRowVersion[], entityType: ActionOutboxEntityType) => {
      const eventIds = eventsTransformed
        ?.filter((ev) => {
          if (entityType === ActionOutboxEntityType.Listing) {
            return ev.entities.listings?.some((l) =>
              itemIds.some((i) => i.id === l.id && i.rowVer > l.rowVer)
            );
          } else if (entityType === ActionOutboxEntityType.Sale) {
            return ev.entities.sales?.some((s) =>
              itemIds.some((i) => i.id === s.id && i.rowVer > s.rowVer)
            );
          } else if (entityType === ActionOutboxEntityType.Purchase) {
            return ev.entities.ticketGroups?.some((t) =>
              itemIds.some((i) => i.id === t.id && i.rowVer > t.rowVer)
            );
          }
          return false;
        })
        ?.map((ev) => ev.counts?.viagVirtId ?? ev.event?.viagVirtualId);

      if (eventIds?.length) {
        if (viewMode === InventoryViewMode.FlattenedView) {
          queryClient.invalidateQueries({
            queryKey: realExpandedDataItemsQueryKey,
          });
        } else {
          for (const eventId of eventIds) {
            queryClient.invalidateQueries({
              queryKey: [...realExpandedDataItemsQueryKey, eventId],
            });
          }
        }
      }
    },
    [eventsTransformed, queryClient, realExpandedDataItemsQueryKey, viewMode]
  );

  const updateExpandedListItems = useCallback(
    (updatedListingsByEvent: { [key: string]: Listing[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedListingsByEvent).forEach(([key, value]) => {
            const oldListingsByIds =
              newData[key]?.listings?.reduce(
                (r, l) => {
                  r[l.id] = l;
                  return r;
                },
                {} as Record<number, Listing>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newListings = [
              ...value.filter((l) => {
                const oldListing = oldListingsByIds[l.id];
                if (oldListing && oldListing.rowVer > l.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newListingIdsFlattened = getFlattenedListingIds(newListings);

            // Retain listings that are not updated
            newData[key]?.listings?.forEach((originalListing) => {
              let shouldKeep = true;
              if (!originalListing.isLtGrp) {
                if (newListingIdsFlattened.includes(originalListing.id))
                  shouldKeep = false;
              } else {
                // If `originalListing` is a listing group
                // there will only be two cases
                // 1. No one listing in `newListingIdsFlattened` show up in the group (keep the group), or
                // 2. All listings in `newListingIdsFlattened` show up in the group (don't keep it, group has been edited or removed)
                const originalListingIdsFlattened = getFlattenedListingIds([
                  originalListing,
                ]);
                if (
                  originalListingIdsFlattened.some((l) =>
                    newListingIdsFlattened.includes(l)
                  )
                ) {
                  shouldKeep = false;
                }
              }

              if (shouldKeep) {
                newListings.push(originalListing);
              }
            });

            newData[key] = {
              sales: null,
              listings: newListings,
              ticketGroups: null,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      // We should only check at first 2 query keys [PrimaryKey, QueryObject]
      const newData = newDatas.find((qk) =>
        isEqual((qk[0] ?? []).slice(0, 2), realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false,
            true
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
        console.debug(
          'eventsTransformed updated with updateExpandedListItems transformed: ' +
            transformed.length
        );
      }
    },
    [
      entityType,
      isSortDescending,
      performerIds,
      catalogResults,
      queryClient,
      queryKey,
      realExpandedDataItemsQueryKey,
      searchText,
      sortBy,
      transformEventData,
      venueIds,
    ]
  );

  const updateExpandedSaleItems = useCallback(
    (updatedListingsByEvent: { [key: string]: Sale[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedListingsByEvent).forEach(([key, value]) => {
            const oldSalesByIds =
              newData[key]?.sales?.reduce(
                (r, s) => {
                  r[s.id] = s;
                  return r;
                },
                {} as Record<number, Sale>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newSales = [
              ...value.filter((s) => {
                const oldSale = oldSalesByIds[s.id];
                if (oldSale && oldSale.rowVer > s.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newSaleIds = newSales.map((s) => s.id);

            // Retain sales that are not in the newSales
            newData[key]?.sales?.forEach((originalSale) => {
              if (!newSaleIds.includes(originalSale.id)) {
                // new sales doesn't have the original sale, keep it
                newSales.push(originalSale);
              }
            });

            newData[key] = {
              sales: newSales,
              listings: null,
              ticketGroups: null,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      const newData = newDatas.find((qk) =>
        isEqual((qk[0] ?? []).slice(0, 2), realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false,
            true
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
        console.debug(
          'eventsTransformed updated with updateExpandedSaleItems transformed: ' +
            transformed.length
        );
      }
    },
    [
      entityType,
      isSortDescending,
      performerIds,
      catalogResults,
      queryClient,
      queryKey,
      realExpandedDataItemsQueryKey,
      searchText,
      sortBy,
      transformEventData,
      venueIds,
    ]
  );

  const updateExpandedTicketGroupItems = useCallback(
    (updatedTgsByEvent: { [key: string]: PurchaseOrderTicketGroup[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedTgsByEvent).forEach(([key, value]) => {
            const oldTgsByIds =
              newData[key]?.ticketGroups?.reduce(
                (r, t) => {
                  r[t.tgId!] = t;
                  return r;
                },
                {} as Record<number, PurchaseOrderTicketGroup>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newTgs = [
              ...value.filter((t) => {
                const oldTg = oldTgsByIds[t.tgId!];
                if (oldTg && oldTg.rowVer > t.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newTgIds = newTgs.map((s) => s.tgId!);

            // Retain tgs that are not in the newTgs
            newData[key]?.ticketGroups?.forEach((originalTg) => {
              if (!newTgIds.includes(originalTg.tgId!)) {
                // new sales doesn't have the original sale, keep it
                newTgs.push(originalTg);
              }
            });

            newData[key] = {
              sales: null,
              listings: null,
              ticketGroups: newTgs,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      const newData = newDatas.find((qk) =>
        isEqual(qk[0], realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false,
            true
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
        console.debug(
          'eventsTransformed updated with updateExpandedTicketGroupItems transformed: ' +
            transformed.length
        );
      }
    },
    [
      entityType,
      isSortDescending,
      performerIds,
      catalogResults,
      queryClient,
      queryKey,
      realExpandedDataItemsQueryKey,
      searchText,
      sortBy,
      transformEventData,
      venueIds,
    ]
  );

  const updateItemInEvent = useCallback(
    async (
      item: Listing | Sale | PurchaseOrderTicketGroup,
      entityType: ActionOutboxEntityType
    ) => {
      // Now that we got the listing details, try to get the Event from existing data
      const catalogIdKey = item.viagVirtualId!;
      const eventQuery = queryWithExpandedItems.find(
        (r) => r.data?.[catalogIdKey]
      );

      // If we can't find it, go fetch it
      if (eventQuery?.data != null) {
        const event = eventQuery.data[catalogIdKey];

        // NOTE - after each refresh - the row version will be zero
        // and direct cache-update will trigger the row-version change until the next refresh
        // this is to make sure async updates to multiple listings/sales of an event do not overwrite each other
        if (entityType === ActionOutboxEntityType.Listing && event.listings) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.listings.forEach((l) => (l.rowVer = l.rowVer || 1));

          // If the event in the catalog has the listings expanded, we'll want to update it
          // with the latest info from the listing details above (this will help update state changes or seat allocations, etc.)
          const index = event.listings.findIndex(
            (s) =>
              s.id === item.id ||
              (s as ListingGroup).groupItems?.some(
                (l) =>
                  l.id === item.id ||
                  // this is the second level group
                  (l as ListingGroup).groupItems?.some(
                    (l2) => l2.id === item.id
                  )
              )
          );

          const oldItem = event.listings[index];
          const lg = oldItem as ListingGroup;

          if (oldItem.id === item.id) {
            item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently
            event.listings[index] = { ...oldItem, ...item } as Listing;
          } else if (lg.groupItems != null) {
            // The item is a listing under a group
            const groupListingIndex = lg.groupItems.findIndex(
              (s) => s.id === item.id
            );

            if (groupListingIndex < 0) {
              for (const subGroup of lg.groupItems) {
                if (subGroup.isLtGrp) {
                  const listingIndex = (
                    subGroup as ListingGroup
                  ).groupItems.findIndex((s) => s.id === item.id);

                  if (listingIndex >= 0) {
                    const oldItemInGroup = (subGroup as ListingGroup)
                      .groupItems[listingIndex];
                    item.rowVer = oldItemInGroup.rowVer + 1; // Increase rowVer to indicate that we updated this recently

                    (subGroup as ListingGroup).groupItems[listingIndex] = {
                      ...oldItem,
                      ...item,
                    } as Listing;
                  }
                }
              }
            } else {
              const oldItemInGroup = lg.groupItems[groupListingIndex];
              item.rowVer = oldItemInGroup.rowVer + 1; // Increase rowVer to indicate that we updated this recently

              lg.groupItems[groupListingIndex] = {
                ...oldItem,
                ...item,
              } as Listing;
            }
          }

          event.listings = [...event.listings]; // this so the table will forced to be re-rendered
          updateExpandedListItems({ [catalogIdKey]: event.listings });
        } else if (entityType === ActionOutboxEntityType.Sale && event.sales) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.sales.forEach((s) => (s.rowVer = s.rowVer || 1));

          // If the event in the catalog has the sales expanded, we'll want to update it
          // with the latest info from the sale details above (this will help update state changes or seat allocations, etc.)
          const index = event.sales.findIndex((s) => s.id === item.id);
          const oldItem = event.sales[index];
          item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently

          event.sales[index] = { ...oldItem, ...item } as Sale;
          event.sales = [...event.sales]; // this so the table will forced to be re-rendered

          updateExpandedSaleItems({ [catalogIdKey]: event.sales });
        } else if (
          entityType === ActionOutboxEntityType.Purchase &&
          event.ticketGroups
        ) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.ticketGroups.forEach((s) => (s.rowVer = s.rowVer || 1));

          // If the event in the catalog has the sales expanded, we'll want to update it
          // with the latest info from the sale details above (this will help update state changes or seat allocations, etc.)
          const index = event.ticketGroups.findIndex(
            (t) => t.tgId === (item as PurchaseOrderTicketGroup).tgId
          );
          const oldItem = event.ticketGroups[index];
          item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently

          event.ticketGroups[index] = {
            ...oldItem,
            ...item,
          } as PurchaseOrderTicketGroup;
          event.ticketGroups = [...event.ticketGroups]; // this so the table will forced to be re-rendered

          updateExpandedTicketGroupItems({
            [catalogIdKey]: event.ticketGroups,
          });
        }
      }
    },
    [
      queryWithExpandedItems,
      updateExpandedListItems,
      updateExpandedSaleItems,
      updateExpandedTicketGroupItems,
    ]
  );

  const allEventIds = useMemo(
    () =>
      eventsTransformed?.map((ev) => ({
        viagVirtualId: ev.counts?.viagVirtId ?? ev.event?.viagVirtualId,
        performerId: ev.counts?.perfId ?? ev.event?.perfId,
        venueId: ev.counts?.venId ?? ev.event?.venueId,
      })) ?? [],
    [eventsTransformed]
  );

  return (
    <CatalogDataContextV2.Provider
      value={{
        isLoading:
          catalogResultsFromHook.some((r) => r.isLoading) || // queries are loading
          !eventsFullyLoaded || // event data is not fully loaded
          eventsTransformed == null, // queries are fetching and prev there is no data
        isItemsLoading:
          queryWithExpandedItems.some((r) => r.isLoading) ||
          !eventsTransformed?.length, // whether the query to get items are loading
        data: catalogResults ?? undefined,
        errorInfo,
        eventsTransformed,
        allEventIds,
        refreshCatalog,
        updateItemInEvent,
        eventsExpansion: {
          ...eventsExpansion,
          refreshExpandedListItems,
        },
        updateExpandedListItems,
        refreshEventForItems,
        totalPurchaseCount: catalogResults?.purchaseCount,
      }}
    >
      {children}
    </CatalogDataContextV2.Provider>
  );
}
