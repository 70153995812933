import { isEmpty } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Button, Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EventPricingSeatMap } from 'src/modals/EventPricingSeatMap';
import { ContentId } from 'src/utils/constants/contentId';
import { isMissingRowData } from 'src/utils/venueConfigUtils';
import { Feature } from 'src/WebApiController';

import * as styles from '../InventoryEventPage.css';

export enum SidePanelTab {
  LISTINGS = 'listings',
  SALES = 'sales',
  NOTES = 'notes',
  PURCHASES = 'purchases',
}

const EventMarketListingTitle = () => {
  const hasHideNavAndFilterBarsFeature = useUserHasFeature(
    Feature.HideNavAndFilterBars
  );

  return (
    <Tabs.List>
      <Tabs.Trigger key={SidePanelTab.LISTINGS} value={SidePanelTab.LISTINGS}>
        <Content id={ContentId.MarketListings} />
      </Tabs.Trigger>
      {!hasHideNavAndFilterBarsFeature && (
        <Tabs.Trigger key={SidePanelTab.SALES} value={SidePanelTab.SALES}>
          <Content id={ContentId.Sales} />
        </Tabs.Trigger>
      )}

      {!hasHideNavAndFilterBarsFeature && (
        <Tabs.Trigger
          key={SidePanelTab.PURCHASES}
          value={SidePanelTab.PURCHASES}
        >
          <Content id={ContentId.Purchases} />
        </Tabs.Trigger>
      )}

      <Tabs.Trigger key={SidePanelTab.NOTES} value={SidePanelTab.NOTES}>
        <Content id={ContentId.Notes} />
      </Tabs.Trigger>
    </Tabs.List>
  );
};

type MarketListingHeaderProps = {
  includeSales?: boolean;
};

export const MarketListingHeader: React.FC<MarketListingHeaderProps> = () => {
  const { setModal } = useContext(ModalContext);

  const {
    event,
    venueMapInfo,
    isMapLoading,
    isGeneralAdmissionOnly,
    mapConfigOverridesQuery,
    venueMapsByScoreModelQuery,
  } = useEventMapContext();
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const isConfigurable = useMemo(() => {
    // Has score overrides
    if (mapConfigOverridesQuery.data?.length) {
      return true;
    }

    // Venue map has configured scores
    if ((venueMapInfo?.sectionScores?.findIndex((s) => s.score) ?? -1) >= 0) {
      return true;
    }

    // Has modeled scores
    const { AdvancedVenueGeometry, MergedVenueGeometry, VenueGeometry } =
      venueMapsByScoreModelQuery.data ?? {};
    if (
      !isEmpty(AdvancedVenueGeometry) ||
      !isEmpty(MergedVenueGeometry) ||
      !isEmpty(VenueGeometry)
    ) {
      return true;
    }

    return false;
  }, [
    mapConfigOverridesQuery.data?.length,
    venueMapInfo?.sectionScores,
    venueMapsByScoreModelQuery.data,
  ]);

  return (
    <Stack direction="column" gap="l">
      <div className={styles.pricingModelHeader}>
        <EventMarketListingTitle />
        {!hasAutoPricingFeature ? null : venueMapInfo?.venueCfgId &&
          !isGeneralAdmissionOnly ? (
          <Button
            variant="link"
            size="lg"
            style={{ minWidth: 'fit-content' }}
            onClick={() =>
              setModal({
                children: <EventPricingSeatMap event={event!} />,
                clickOutsideToClose: true,
                size: 'slide-in',
                keyboard: false,
              })
            }
          >
            <Content
              id={
                // If there is any score override model or the default venue map
                // has scores, then show "configure", otherwise, show "create new"
                isConfigurable ? ContentId.Configure : ContentId.CreateNew
              }
            />
          </Button>
        ) : isGeneralAdmissionOnly ? (
          <WarningMessage
            message={<Content id={ContentId.AutopricingNotAvailableForGA} />}
          />
        ) : !isMapLoading && !venueMapInfo?.venueCfgId ? (
          <WarningMessage
            message={
              <Content id={ContentId.AutopricingNotAvailableMissingMap} />
            }
          />
        ) : null}
      </div>
      {hasAutoPricingFeature &&
      !isGeneralAdmissionOnly &&
      !isMapLoading &&
      venueMapInfo?.venueCfgId &&
      isMissingRowData(venueMapInfo) ? (
        <WarningMessage
          message={<Content id={ContentId.MissingRowDataWarning} />}
        />
      ) : null}
    </Stack>
  );
};
