import { useCallback, useMemo } from 'react';
import { MinRowsForUsingVirtuoso } from 'src/components/Accordions';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useSingleTimePeriodPurchasesQuery } from 'src/contexts/PurchaseDataContext/useSingleTimePeriodPurchaseQuery';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import {
  PurchaseOrder,
  PurchaseOrderQuery,
  PurchaseOrderTicketGroup,
  TimePeriodContainingPurchaseOrderResult,
} from 'src/WebApiController';

type VirtualizedPurchaseAccordionItemProps =
  TimePeriodContainingPurchaseOrderResult & {
    isExpanded: boolean;
  };

export function PurchaseDateItemBody({
  firstOfTimePeriod,
  isExpanded,
  ...rest
}: VirtualizedPurchaseAccordionItemProps) {
  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();

  const filterPurchaseOrders = useCallback(
    (purchaseOrders: PurchaseOrder[] | null | undefined) => {
      if (purchaseOrders && filterQuery.purchaseOrderState) {
        return purchaseOrders.filter(
          (po) => po.poState === filterQuery.purchaseOrderState
        );
      }

      return purchaseOrders;
    },
    [filterQuery.purchaseOrderState]
  );

  const { singleTimePeriodPurchaseQuery } = useSingleTimePeriodPurchasesQuery(
    firstOfTimePeriod,
    true // isExpanded should be true - as long as this is rendered, it is expanded. Otherwise UI will be laggy
  );

  const purchaseOrders = useMemo(
    () => filterPurchaseOrders(singleTimePeriodPurchaseQuery.data),
    [filterPurchaseOrders, singleTimePeriodPurchaseQuery.data]
  );

  const usingVirtuoso =
    (purchaseOrders?.length ?? rest.purchaseCount) > MinRowsForUsingVirtuoso;

  return (
    <PurchaseOrderTable
      getDataFail={Boolean(
        singleTimePeriodPurchaseQuery.isError ||
          singleTimePeriodPurchaseQuery.failureReason
      )}
      groupId={firstOfTimePeriod}
      entities={(purchaseOrders ?? []).map(
        (p) => p as PurchaseOrderTicketGroup
      )}
      entityCount={rest.purchaseCount}
      useVirtuoso={usingVirtuoso}
      disablePagination
      enableColumnFilters
    />
  );
}
