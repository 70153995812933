import { format } from 'date-fns';
import { useCallback } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import {
  getFormattedEventName,
  getPerformerAndVenueForEvent,
} from 'src/utils/eventWithDataUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { EntityWithTicketsQuery, Event, EventSort } from 'src/WebApiController';

export const useGetGroupingTitle = () => {
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { data } = useCatalogDataContext();
  const groupBy = filterQuery.sortBy;

  const getMainDisplayFirstChar = useCallback(
    (event: Event) => {
      const { performer } = getPerformerAndVenueForEvent(event, data);
      const [boldText] = getFormattedEventName({ event, performer });

      return boldText.charAt(0);
    },
    [data]
  );

  const getEventGroupingText = useCallback(
    (event: Event) => {
      if (!groupBy || groupBy === EventSort.Date) {
        return format(new Date(event.dates.start!), 'MMMM yyyy', {
          locale: getLocaleFromLanguageOrCurrent(),
        });
      }
      if (!filterQuery || filterQuery.sortBy === EventSort.Date) {
        return '';
      }
      return getMainDisplayFirstChar(event);
    },
    [groupBy, filterQuery, getMainDisplayFirstChar]
  );

  return {
    getDividerTextForSorting: getMainDisplayFirstChar,
    getEventGroupingText,
  };
};
