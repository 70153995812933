import { useReportMetricsContextV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import SaleReportTableV2 from 'src/tables/SalesTable/SaleReportTableV2';

import { ReportsPageLayout } from '../Reports/ReportsPageLayout';
import * as styles from './ReportsSalePage.css';

export function ReportsSalePageV2({
  reportConfig,
}: {
  reportConfig: ReportConfigV2;
}) {
  const { reportMetrics, reportSummary, isLoading, reportValueTypes } =
    useReportMetricsContextV2();

  return (
    <ReportsPageLayout
      reportConfig={reportConfig}
      reportTable={
        <SaleReportTableV2
          className={styles.table}
          metricsData={reportMetrics}
          reportValueTypes={reportValueTypes}
          summaryData={reportSummary}
          report={reportConfig}
        />
      }
    />
  );
}
