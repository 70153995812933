import clsx from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMeasure } from 'react-use';
import { ConnectedComparableListings } from 'src/components/Listings/ConnectedComparableListings';
import { useAppContext } from 'src/contexts/AppContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Stack } from 'src/core/ui';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { getListPriceFromAllinPrice } from 'src/utils/inventoryUtils';
import {
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
  Marketplace,
} from 'src/WebApiController';

import * as styles from './ListingDetailPanel.css';
import { PricingSettingAutoPrice } from './PricingSettingAutoPrice';
import { PricingSettingHeader } from './PricingSettingHeader';
import { PricingSettingManualPrice } from './PricingSettingManualPrice';

type ListingDetailPanelProps = {
  useVerticalView: boolean;
};

export const ListingDetailPanel: React.FC<ListingDetailPanelProps> = ({
  useVerticalView,
}) => {
  const {
    selectedListing,
    selectedGroup,
    compListings,
    priceCalcQuery,
    isLoading,
  } = useInventoryEventDetailContext();

  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        selectedListing?.currency ??
          loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      selectedListing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const [detailsRef, { height }] = useMeasure<HTMLDivElement>();

  const shouldDetailsSideBySide = useMemo(() => height < 650, [height]);

  const { watch } = useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const { autoPricingEnabled } = watch();

  const flattenedListingGroup: Listing[] | undefined = useMemo(() => {
    if (!selectedGroup) {
      return;
    }

    return flattenListingGroup(selectedGroup).map((l) => l as Listing);
  }, [selectedGroup]);

  const listingWithUpdatedPrice = useMemo(() => {
    if (!selectedListing) return;

    const listPrice = getListPriceFromAllinPrice(
      priceCalcQuery?.data?.websitePrice?.amt,
      selectedListing
    );
    return {
      ...selectedListing,
      // This is to make sure we use the auto-preview price instead of the listing's price when showing comparables
      // And we have to override listPrice because allIn is calculated from listPrice (instead of being read from the listing)
      listPrice: listPrice ?? selectedListing.listPrice,
    };
  }, [priceCalcQuery?.data?.websitePrice?.amt, selectedListing]);

  return (
    <Stack
      ref={detailsRef}
      direction="column"
      width="full"
      height="full"
      gap="m"
      className={clsx(styles.listingDetailPanelContainer, {
        [styles.listingDetailPanelContainerRow]: shouldDetailsSideBySide,
      })}
    >
      {selectedListing ? (
        <div
          className={clsx(styles.pricingSettingsContainerBase, {
            [styles.pricingSettingsContainerBaseRow]: shouldDetailsSideBySide,
          })}
        >
          <Stack width="full" gap="s" direction="column">
            <div className={styles.pricingSettingsHeader}>
              <PricingSettingHeader listing={selectedListing} />
            </div>

            <div className={styles.pricingSettingsBody}>
              {autoPricingEnabled ? (
                <PricingSettingAutoPrice
                  uiCurrency={uiCurrency}
                  sellerFee={stubHubMkpSettings?.sellerFee ?? null}
                  useVerticalView={useVerticalView}
                />
              ) : (
                <PricingSettingManualPrice
                  uiCurrency={uiCurrency}
                  sellerFee={stubHubMkpSettings?.sellerFee ?? null}
                />
              )}
            </div>
          </Stack>
        </div>
      ) : null}
      <div className={styles.connectedComponentsContainer}>
        <ConnectedComparableListings
          currentListing={listingWithUpdatedPrice}
          currentListingFromListingGroup={flattenedListingGroup}
          comparableListings={compListings ?? []}
          sortBy={'price'}
          isSortDescending={false}
          isLoading={isLoading}
          listings={undefined}
          showAutoPriceResults={true}
          listingPriceCalc={priceCalcQuery?.data ?? undefined}
        />
      </div>
    </Stack>
  );
};
