import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { AdGroupTicketClassSelector } from 'src/components/Selectors/AdGroupTicketClassSelector';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon, SeatIcon } from 'src/svgs/Viagogo';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import { TicketClassInfo } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export type TicketClassSectionInput = {
  ticketClass: TicketClassInfo | null | undefined;
};

export interface TicketClassSectionProps {
  onSelectTicketClass: (
    ticketClass: TicketClassInfo | null | undefined
  ) => void;
  eventId?: number;
  ticketClass: TicketClassInfo | null | undefined;
  error?: string;
}

export const TicketClassSection = ({
  onSelectTicketClass,
  eventId,
  ticketClass,
  error,
}: TicketClassSectionProps) => {
  const addTicketClassDialog = useBasicDialog();
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const event = useMemo(() => {
    if (!eventId || !catalogData?.events?.[eventId.toString()]) {
      return undefined;
    }

    return catalogData.events[eventId.toString()].event;
  }, [catalogData, eventId]);

  const onAddTicketClassSave = useCallback(
    (ticketClass: TicketClassInfo | null | undefined) => {
      onSelectTicketClass(ticketClass);
      addTicketClassDialog.closeDialog();
    },
    [onSelectTicketClass, addTicketClassDialog]
  );

  const onAddTicketClassCancel = useCallback(() => {
    addTicketClassDialog.closeDialog();
  }, [addTicketClassDialog]);

  const onAddTicketClass = useCallback(() => {
    addTicketClassDialog.launchDialog();
  }, [addTicketClassDialog]);

  const contentNA = useContent(ContentId.NA);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (ticketClass !== undefined) {
      pills.push({
        value: 'ticketClass',
        display: ticketClass?.name ?? contentNA,
        icon: <SeatIcon />,
        onDelete: () => {
          onSelectTicketClass(undefined);
        },
      });
    }

    return pills;
  }, [ticketClass, contentNA, onSelectTicketClass]);

  const enableButton = event;

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.TicketClass} />
        </BodySectionTitle>
        {ticketClass !== undefined ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button
              variant={'link'}
              onClick={onAddTicketClass}
              disabled={!enableButton}
            >
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddTicketClass} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addTicketClassDialog.dialogProps.isOpen && (
        <EventMapContextProvider event={event}>
          <AddTicketClassDialog
            {...addTicketClassDialog.dialogProps}
            eventId={eventId}
            onSave={onAddTicketClassSave}
            onCancel={onAddTicketClassCancel}
          />
        </EventMapContextProvider>
      )}
    </>
  );
};

export type AddTicketClassFieldValues = {
  ticketClass: TicketClassInfo | null | undefined;
};

export type AddTicketClassDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (ticketClass: TicketClassInfo | null | undefined) => void;
  onCancel: () => void;
  eventId?: number;
};

export const AddTicketClassDialog = ({
  onSave,
  onCancel,
  eventId,
  ...genericDialogProps
}: AddTicketClassDialogProps) => {
  const addTicketClassForm = useForm<AddTicketClassFieldValues>({
    defaultValues: {
      ticketClass: undefined,
    },
  });

  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();
  const ticketClass = addTicketClassForm.watch('ticketClass');

  const onSubmit = useCallback(() => {
    onSave(ticketClass);
  }, [ticketClass, onSave]);

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const getListingsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.events[eventId]?.entities?.listings;
    },
    [catalogData]
  );

  const getSectionsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.venueMapByEventId[eventId].sections;
    },
    [catalogData]
  );

  const listingsData = getListingsForEvent(eventId);
  const sectionsData = getSectionsForEvent(eventId);

  const ticketClasses = useMemo(() => {
    if (!listingsData) {
      return null;
    }

    return (
      listingsData.reduce(
        (acc, listing) => {
          const row = getMatchingSectionRow(listing.seating, sectionsData);
          const ticketClass = row.section?.specRow?.tktClass;
          const ticketClassId = ticketClass?.ticketClassId;
          if (ticketClass != null && ticketClassId != null) {
            acc[ticketClassId] = {
              id: ticketClass.ticketClassId,
              name: ticketClass.ticketClassName,
              color: ticketClass.color,
              cssPostFix: ticketClass.cssPostFix,
            } as TicketClassInfo;
          }
          return acc;
        },
        {} as Record<string, TicketClassInfo>
      ) ?? {}
    );
  }, [listingsData, sectionsData]);

  const setTicketClass = useCallback(
    (ticketClassId: string) => {
      if (!ticketClasses) {
        return;
      }

      const ticketClass =
        ticketClassId == EmptySelectionKey
          ? null
          : ticketClasses[parseInt(ticketClassId, 10)];
      addTicketClassForm.clearErrors('ticketClass');
      addTicketClassForm.setValue('ticketClass', ticketClass);
    },
    [addTicketClassForm, ticketClasses]
  );

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.TicketClass} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addTicketClassForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addTicketClassForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.TicketClass} />}>
          <AdGroupTicketClassSelector
            ticketClasses={ticketClasses}
            value={
              ticketClass === null
                ? EmptySelectionKey
                : ticketClass?.id?.toString()
            }
            onChange={(ticketClassId) => {
              setTicketClass(ticketClassId);
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
