import { QueryObserverResult } from '@tanstack/react-query';
import { createContext, PropsWithChildren } from 'react';
import { IListExpansion } from 'src/hooks/useListExpansion';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogResults,
  EventWithData,
  Listing,
  PurchaseOrderTicketGroup,
  Sale,
} from 'src/WebApiController';

export const EmptyCatalogResults: CatalogResults = {
  events: {},
  performers: {},
  venues: {},
  venueCfgs: {},
  viagIdToLatestIdLookup: {},
  posIdToViagIdLookup: {},
  purchaseCount: 0,
};

export type EventWithDataMap = Record<string, EventWithData>;

export type ItemIdWithRowVersion = {
  id: number;
  rowVer: number;
};

export enum ExpandedCallbackStatus {
  Pending,
  InProgress,
  Done,
}

export type CatalogDataContextProviderProps<TQuery> = PropsWithChildren<{
  queryKey: string;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.TicketGroup
    | ActionOutboxEntityType.Purchase;
  getCatalogData: (
    client: CatalogClient,
    filterQuery: TQuery
  ) => Promise<CatalogResults>;
  getCatalogDataExpanded?: (
    ids: string[],
    filterQuery: TQuery
  ) => Promise<ExpandedEventData>;
  onCatalogDataExpanded?: (
    ids: string[],
    filterQuery: TQuery,
    data?: ExpandedEventData
  ) => Promise<ExpandedEventData>;
  transformEventData: (
    entityType:
      | ActionOutboxEntityType.Listing
      | ActionOutboxEntityType.Sale
      | ActionOutboxEntityType.TicketGroup
      | ActionOutboxEntityType.Purchase,
    data: EventWithData[],
    expandedEventData?: ExpandedEventData | null,
    isExpandedDataLoading?: boolean,
    filterZeroCounts?: boolean
  ) => EventWithData[];
  disabled?: boolean;
  disableAutoRefresh?: boolean;
  ignoreMaxCount?: boolean;
}>;

export type ICatalogDataContext = {
  isLoading: boolean;
  isItemsLoading: boolean;
  data?: CatalogResults;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  eventsTransformed?: EventWithData[];
  allEventIds?: {
    viagVirtualId: string;
    performerId: number | null;
    venueId: number;
  }[];
  refreshCatalog: () => Promise<unknown>;
  eventsExpansion: IListExpansion<string>;
  updateItemInEvent: (
    item: Listing | Sale | PurchaseOrderTicketGroup,
    entityType: ActionOutboxEntityType
  ) => void;
  updateExpandedListItems: (updatedListingsByEvent: {
    [key: string]: Listing[];
  }) => void;
  refreshEventForItems: (
    itemIds: ItemIdWithRowVersion[],
    entityType: ActionOutboxEntityType
  ) => void;
  /**
   * Only used for Purchase Flattened View Currently
   * Can be transformed to something more general such as `totalEntityCount` if needed
   */
  totalPurchaseCount?: number;
};

export type ExpandedEventData = {
  [key: string]: {
    sales: Sale[] | null;
    listings: Listing[] | null;
    ticketGroups: PurchaseOrderTicketGroup[] | null;
    failedToRetrieveData: boolean;
  };
};
const emptyContext: ICatalogDataContext = {
  isLoading: false,
  isItemsLoading: false,
  data: undefined,
  eventsTransformed: undefined,
  allEventIds: undefined,
  refreshCatalog: () =>
    Promise.resolve({} as QueryObserverResult<unknown, unknown>),
  updateItemInEvent: () => {},
  eventsExpansion: {
    expandedListItems: [],
    setExpandedListItems: () => {},
    toggleListItemExpansion: () => false,
    refreshExpandedListItems: () => Promise.resolve(),
    setListItemExpansion: () => {},
  },
  updateExpandedListItems: () => {},
  refreshEventForItems: () => {},
  totalPurchaseCount: undefined,
};

export const CatalogDataContextV1 =
  createContext<ICatalogDataContext>(emptyContext);

export const CatalogDataContextV2 =
  createContext<ICatalogDataContext>(emptyContext);
