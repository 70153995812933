import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { SaleLineItemModalWrapper } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog/SaleLineItemModalWrapper';
import { useGetRemainingBalance } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog/useGetRemainingBalance';
import { useGetSaleLineItemsBySaleId } from 'src/hooks/api/useGetSaleLineItemsBySaleId';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency } from 'src/utils/numberFormatter';
import {
  MarketplacePaymentLineType,
  MarketplaceSaleLineItem,
  SaleCostType,
  SaleLineItem,
} from 'src/WebApiController';

import * as styles from './LinkMarketplacePaymentToSaleDialog.css';
import { SaleChargesTable } from './SaleChargesTable';

type LinkMarketplacePaymentToSaleDialogProps = Omit<
  ComponentProps<typeof RSModal>,
  'onSubmit'
> & {
  onSubmit: (
    marketplacePaymentLineId: number,
    saleLineItems: SaleLineItem[]
  ) => void;
  marketplacePaymentLine: MarketplaceSaleLineItem;
};

export type LinkMarketplacePaymentToSaleInput = {
  saleLineItems: SaleLineItem[] | null;
  marketplacePaymentLine: MarketplaceSaleLineItem;
  isCredit: boolean;
};

export function LinkMarketplacePaymentToSaleDialog({
  onSubmit,
  onCancel,
  marketplacePaymentLine,
  ...dialogProps
}: LinkMarketplacePaymentToSaleDialogProps) {
  const { loginContext } = useAppContext();
  const methods = useForm<LinkMarketplacePaymentToSaleInput>({
    defaultValues: {
      marketplacePaymentLine,
    },
  });

  const { watch, setValue } = methods;

  const {
    data: existingSaleLineItems,
    isLoading,
    isFetching,
  } = useGetSaleLineItemsBySaleId(marketplacePaymentLine.saleId);

  const isCredit =
    marketplacePaymentLine.type === MarketplacePaymentLineType.Credit;
  const costDialog = useBasicDialog();
  const saleLineItems = watch('saleLineItems');

  useEffect(() => {
    setValue('saleLineItems', existingSaleLineItems ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingSaleLineItems]);

  const onAddNewCostSave = useCallback(
    (item: SaleLineItem) => {
      // Only allow credit to be linked to credit and charge to be linked to charge
      if (isCredit && item.costType !== SaleCostType.Credit) {
        costDialog.closeDialog();
      }
      if (!isCredit && item.costType !== SaleCostType.Charge) {
        costDialog.closeDialog();
      }

      setValue('saleLineItems', [
        ...(saleLineItems ?? []),
        {
          ...item,
          saleId: marketplacePaymentLine.saleId!,
          marketplacePaymentLineId: marketplacePaymentLine.id,
        },
      ]);

      costDialog.closeDialog();
    },
    [
      costDialog,
      isCredit,
      marketplacePaymentLine.id,
      marketplacePaymentLine.saleId,
      saleLineItems,
      setValue,
    ]
  );

  const onAddNewCostCancel = useCallback(() => {
    costDialog.closeDialog();
  }, [costDialog]);

  const onAddNewCost = useCallback(() => {
    costDialog.launchDialog();
  }, [costDialog]);

  const saleLineItemsToLink = useMemo(
    () =>
      (saleLineItems ?? []).filter(
        (s) => s.marketplacePaymentLineId === marketplacePaymentLine.id
      ),
    [marketplacePaymentLine.id, saleLineItems]
  );

  const canSubmit = useMemo(
    () => saleLineItemsToLink.length > 0,
    [saleLineItemsToLink.length]
  );

  return (
    <GenericDialog
      size="m"
      header={<Content id={ContentId.AddToSale} />}
      footer={
        <Stack direction="row" gap="m">
          <CancelButton onClick={onCancel} />
          <OkButton
            onClick={() =>
              onSubmit(marketplacePaymentLine.id, saleLineItemsToLink)
            }
            disabled={!canSubmit}
            textContentId={ContentId.Link}
          />
        </Stack>
      }
      {...dialogProps}
    >
      <FormProvider {...methods}>
        {isLoading || isFetching ? (
          <PosSpinner />
        ) : (
          <Stack direction="column" gap="xl" alignItems="start" width="full">
            <LinkMarketplacePaymentToSaleAmountDisplay />
            <Stack direction="column" gap="s" alignItems="start" width="full">
              <span className={styles.subtitleDisplay}>
                <Content
                  id={isCredit ? ContentId.SaleCredits : ContentId.SaleCharges}
                />
              </span>
              <Stack direction="column" gap="s" width="full">
                <SaleChargesTable />
              </Stack>
            </Stack>
            <Button
              size="unset"
              variant="link"
              style={{ padding: `${vars.spacing['lg']} 0` }}
              onClick={() => {
                onAddNewCost();
              }}
              tabIndex={0}
            >
              <PlusIcon
                size={vars.iconSize.s}
                fill={IconsFill.currentColor}
                withHoverEffect
              />
              <Content id={ContentId.AddChargeOrCredit} />
            </Button>
          </Stack>
        )}
        {costDialog.dialogProps.isOpen && (
          <SaleLineItemModalWrapper
            isOpen={costDialog.dialogProps.isOpen}
            onClose={onAddNewCostCancel}
            onSave={onAddNewCostSave}
            currencyCode={
              marketplacePaymentLine.amt.currency ??
              loginContext?.user?.activeAccount.currencyCode ??
              'USD'
            }
          />
        )}
      </FormProvider>
    </GenericDialog>
  );
}

function LinkMarketplacePaymentToSaleAmountDisplay() {
  const { watch } = useFormContext<LinkMarketplacePaymentToSaleInput>();
  const { getUiCurrency } = useLocalizationContext();

  const marketplacePaymentLine = watch('marketplacePaymentLine');
  const { remainingAmount } = useGetRemainingBalance();

  // Assuming marketplacePaymentLine and saleLineItems are in the same currency
  const uiCurrency = useMemo(
    () => getUiCurrency(marketplacePaymentLine.amt.currency),
    [getUiCurrency, marketplacePaymentLine.amt.currency]
  );

  return (
    <Stack direction="column" gap="m">
      <span style={{ color: vars.color.textPrimary }}>
        <Content id={ContentId.Amount} />
      </span>
      <Stack
        direction="row"
        alignItems="end"
        gap="m"
        className={styles.amountDisplay}
      >
        <h2>
          {formatCurrency(remainingAmount, uiCurrency.code, uiCurrency.dec)}
        </h2>
        <span style={{ textTransform: 'lowercase' }}>
          <Content id={ContentId.Of} />
        </span>
        <h2>{marketplacePaymentLine.amt.disp}</h2>
      </Stack>
    </Stack>
  );
}
