import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { EventAccordionItemProps } from 'src/components/Accordions';
import {
  AverageTicketPriceDisplay,
  SalesAndMarginDisplay,
  TicketsListedAndSoldDisplay,
} from 'src/components/Listings/InventorySummaryWidgets';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { hideMobile } from 'src/core/themes/shared.css';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getInventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';
import { EventWithData, Listing } from 'src/WebApiController';
import { Feature, ListingMetrics } from 'src/WebApiController';

import { LastReviewedDisplay } from '../../InventorySummaryWidgets/LastReviewedDisplay';
import * as styles from '../InventoryEventPage.css';
import { useCatchUpParams } from '../InventoryEventPage.hooks';

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps<Listing>;
  selectedListingIds: number[];
  setSelectedListingIds: Dispatch<SetStateAction<number[]>>;
};

export function InventoryEventInfoSection({
  accordionItemProps,
  inventoryAccordionItem,
  selectedListingIds,
  setSelectedListingIds,
}: InventoryEventContentProps) {
  const event = accordionItemProps?.event;
  const { BodyComponent, AfterHeaderComponent } = inventoryAccordionItem || {};
  const { onScroll, isCollapsedView, isEnabled } = useCollapsableViewContext();

  const hasEventListingTableHotkeysFeature = useUserHasFeature(
    Feature.EventListingTableHotkeys
  );
  const hasBlockInventorySummaryStatsFeature = useUserHasFeature(
    Feature.BlockInventorySummaryStats
  );

  const { loginContext } = useAppContext();
  const { selectionMode } = useMultiSelectionContext();
  const isSelectionModeOn = !!selectionMode?.mode;

  const { isCatchupMode } = useCatchUpParams();

  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<ListingMetrics>();

  const eventMetrics = eventMetricsMap?.[event?.viagVirtualId ?? ''];
  const formattedTotalMetrics = eventMetrics
    ? getInventorySummaryListingMetricsDisplayStrings(
        eventMetrics,
        loginContext?.user?.activeAccount?.currencyCode
      )
    : undefined;

  return (
    <div className={styles.tableSection}>
      {BodyComponent && accordionItemProps && (
        <div
          className={clsx(
            styles.tableContainer,
            styles.inventoryEventPageTableContainer
          )}
        >
          <Stack
            gap="xl"
            flexWrap="wrap"
            className={clsx(hideMobile, {
              [styles.metricsAnimationUncollapsed]: !isCollapsedView,
              [styles.metricsAnimationCollapsed]: isCollapsedView,
              [styles.metricsBlockStats]: hasBlockInventorySummaryStatsFeature,
            })}
          >
            {!hasBlockInventorySummaryStatsFeature && (
              <>
                <TicketsListedAndSoldDisplay
                  formattedTotalMetrics={formattedTotalMetrics}
                />
                <SalesAndMarginDisplay
                  formattedTotalMetrics={formattedTotalMetrics}
                />
                <AverageTicketPriceDisplay
                  formattedTotalMetrics={formattedTotalMetrics}
                />
              </>
            )}
            {!isCatchupMode && (
              <LastReviewedDisplay viagVirtualId={event?.viagVirtualId} />
            )}
          </Stack>

          <div className={styles.bulkActionsStickyWrapper}>
            {AfterHeaderComponent && <AfterHeaderComponent />}
          </div>
          <div
            className={clsx({
              [styles.inventoryEventDetailsBodyWrapper]: isEnabled,
              [styles.inventoryEventDetailsBodyPadding]:
                !isSelectionModeOn && !isCollapsedView,
            })}
          >
            <BodyComponent
              event={accordionItemProps.event}
              entities={accordionItemProps.entities.listings ?? []}
              entityCount={accordionItemProps.counts.listCnt ?? 0}
              listingGroupCount={accordionItemProps.counts.listGrpCnt}
              ungroupedListingCount={accordionItemProps.counts.ungrListCnt}
              selectedIds={selectedListingIds}
              onItemSelectionsChanged={setSelectedListingIds}
              selectionMode={{ mode: MultiSelectScope.SingleItem }}
              disablePagination
              useTableNavKeys={hasEventListingTableHotkeysFeature}
              useVirtuoso
              onVirtuosoTableScroll={onScroll}
            />
          </div>
        </div>
      )}
    </div>
  );
}
