/**
 * These values are associated with the available columns and tied in with the Edit Inventory Columns feature.
 */

export enum ListingTableColumnId {
  // Not Configurable
  Checkbox = 'checkbox',
  // Configurable
  TicketClassColor = 'ticketClassColor',
  Seating = 'seating',
  DeliveryType = 'deliveryType',
  ShownQuantity = 'shownQuantity',
  UnsoldQuantity = 'unsoldQuantity',
  TicketCount = 'ticketCount',
  InHandDate = 'inHandDate',
  UnitCost = 'unitCost',
  ListPrice = 'listPrice',
  AllInPrice = 'allInPrice',
  Actions = 'actions',
  // Default Visibility Off (optional columns)
  ListingId = 'listingId',
  AutoPricing = 'autoPricing',
  ProceedsFloor = 'priceFloor',
  ProceedsCeiling = 'priceCeiling',
  WebsiteFloor = 'priceWebsiteFloor',
  WebsiteCeiling = 'priceWebsiteCeiling',
  UndercutMode = 'undercutMode',
  UndercutAbsoluteAmount = 'undercutAbsoluteAmount',
  UndercutRelativeAmount = 'undercutRelativeAmount',
  UndercutNewPrice = 'undercutNewPrice',
  CompListingMode = 'compListingMode',
  CompListingCeiling = 'compListingCeiling',
  CompListingFloor = 'compListingFloor',
  CompListingMinimumQuantity = 'compListingMinimumQuantity',
  CompListingExcludeFanInventory = 'compListingExcludeFanInventory',
  OutlierMode = 'outlierMode',
  OutlierFiltering = 'outlierFiltering',
  OutlierLimit = 'outlierLimit',
  OutlierSpacing = 'outlierSpacing',
  CircuitBreakerMinCompListingCount = 'circuitBreakerMinCompListingCount',
  CircuitBreakerVelocity = 'circuitBreakerVelocity',
  CircuitBreakerVelocityHours = 'circuitBreakerVelocityHours',
  SeatingRow = 'seatingrow',
  SeatingSeats = 'seatingSeats',
  AutoPricingResultType = 'autoPricingResultType',
  LastPriceUpdatedDate = 'lastPriceUpdatedDate',
  WastedTickets = 'wastedTickets',
  WastedCost = 'wastedCost',
  PurchaseId = 'purchaseId',
  VendorAccount = 'vendor',
  AutoFulfill = 'autoFulfill',
  MarketplaceVisibility = 'marketplaceVisibility',
  PurchaseCreatedBy = 'purchaseCreatedBy',
  PurchasedBy = 'purchasedBy',
  PurchaseDate = 'purchaseDate',
  RelativeRankPremium = 'relativeRankPremium',
  AbsoluteRankPremium = 'absoluteRankPremium',
  UnsoldProceeds = 'unsoldProceeds',
  UnsoldCost = 'unsoldCost',
  SoldQty = 'soldQty',
  SoldCost = 'soldCost',
  TotalCost = 'totalCost',
  SeatTraits = 'seatTraits',
  SplitType = 'splitType',
}

export enum ListingTableFlattenedColumnId {
  Event = 'Event',
  Venue = 'Venue',
  Location = 'Location',
  EventDate = 'Date',
}

export enum ListingReportTableColumnId {
  // Group by types
  Performer = 'performer',
  Venue = 'venue',
  Event = 'event',
  EventGenre = 'eventGenre',
  EventCountry = 'eventCountry',
  EventCity = 'eventCity',
  SeatingSection = 'seatingSection',
  SeatingRow = 'seatingRow',
  // Configurable - shared precision with ListingTableColumnId
  UnsoldQuantity = ListingTableColumnId.UnsoldQuantity,
  TicketCount = ListingTableColumnId.TicketCount,
  SoldQuantity = 'soldQuantity',
  UnitCost = ListingTableColumnId.UnitCost,
  SoldCost = 'soldCost',
  SoldProceeds = 'soldProceeds',
  UnsoldCost = 'unsoldCost',
  ListPrice = ListingTableColumnId.ListPrice,
  AllInPrice = ListingTableColumnId.AllInPrice,
  // Auxiliary columns
  EventId = 'eventId',
  Category = 'category',
  EventDate = 'eventDate',
  EventStateProvince = 'eventStateProvince',
  ListingId = 'listingId',
  SaleId = 'saleId',
  HoldId = 'holdId',
  SeatingSeats = 'seatingSeats',
  DeliveryType = ListingTableColumnId.DeliveryType,
  InHandDate = ListingTableColumnId.InHandDate,
  PurchaseId = 'purchaseId',
  PurchasedBy = ListingTableColumnId.PurchasedBy,
  PurchaseDate = 'purchaseDate',
  PurchaseCreatedBy = ListingTableColumnId.PurchaseCreatedBy,
  Vendor = 'vendor',
  SalePaymentState = 'paymentState',
  Charges = 'charges',
  Credits = 'credits',
  Fulfiller = 'fulfiller',
  PaymentMethodType = 'paymentMethodType',
  PurchasePaymentStatus = 'purchasePaymentStatus',
  Zone = 'zone',
  PricedBy = 'pricedBy',
  InternalNotes = 'internalNotes',
  InternalNotesPurchase = 'internalNotesPurchase',
  VendorOrderId = 'vendorOrderId',
  VendorAccountEmail = 'vendorAccountEmail',
  CancellationDatePurchase = 'cancellationDatePurchase',
  PandL = 'PandL',
  Margin = 'margin',
}

// Subset of ReportInventoryColumnName from ReportingService
export enum ListingReportTableGroupingColumnId {
  PerformerName = 'performerName',
  VenueName = 'venueName',
  EventName = 'eventName',
  TopLevelCategoryName = 'topLevelCategoryName',
  CountryName = 'countryName',
  CityName = 'cityName',
  EventId = 'viagogoEventId',
  LocalEventDateTime = 'localEventDateTime',
  StateProvince = 'stateProvince',
  InternalNotes = 'internalNotes',
  ListingId = 'listingId',
  IsSeatSaver = 'isSeatSaver',
  IsNoFulfill = 'isNoFulfill',
  PricerSellerUserId = 'pricerSellerUserId',
  Section = 'section',
  SeatingRow = 'seatingRow',
  SeatFrom = 'seatFrom',
  SeatTo = 'seatTo',
}

export enum ListingReportTableValueColumnId {
  UnsoldQuantity = 'unsoldQuantity',
  SoldQuantity = 'soldQuantity',
  TotalQuantity = 'totalQuantity',
  SoldCost = 'soldCost',
  UnsoldCost = 'unsoldCost',
  UnitCost = 'unitCost',
  UnitNetProceeds = 'unitNetProceeds',
  UnitAllInPrice = 'unitAllInPrice',
}
