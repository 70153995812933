import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useToggle } from 'react-use';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData, Sale } from 'src/WebApiController';
import { Feature } from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';

type SaleEventContentProps = {
  accordionItemProps?: EventWithData;
  saleAccordionItem?: EventAccordionItemProps<Sale>;
  selectedSaleIds: number[];
  setSelectedSaleIds: Dispatch<SetStateAction<number[]>>;
};

export function SaleEventInfoSection({
  accordionItemProps,
  saleAccordionItem,
  selectedSaleIds,
  setSelectedSaleIds,
}: SaleEventContentProps) {
  const { BodyComponent, AfterHeaderComponent } = saleAccordionItem || {};
  const { onScroll, isCollapsedView, isEnabled } = useCollapsableViewContext();

  const hasEventListingTableHotkeysFeature = useUserHasFeature(
    Feature.EventListingTableHotkeys
  );
  const { selectionMode } = useMultiSelectionContext();
  const isSelectionModeOn = !!selectionMode?.mode;

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) toggleColumnModal(true);
    },
    [isColumnModalOpen, toggleColumnModal]
  );

  return (
    <div className={styles.tableSection}>
      {BodyComponent && accordionItemProps && (
        <div
          className={clsx(
            styles.tableContainer,
            styles.eventPageTableContainer
          )}
        >
          <Stack justifyContent="end" className={styles.hoistedColumnSettings}>
            {isColumnModalOpen ? (
              <ColumnSettingsModal<CustomSalesColumn>
                onClose={toggleColumnModal}
                sectionType={SectionType.Sales}
              />
            ) : null}
            <Stack width="full" direction="column" alignItems="end">
              <Button
                variant="textPlain"
                onClick={onColumnSettingButtonClickHandler}
              >
                <LayoutIcon size={vars.iconSize.m} />
                <Content id={ContentId.Columns} />
              </Button>
            </Stack>
          </Stack>

          <div className={styles.bulkActionsStickyWrapper}>
            {AfterHeaderComponent && <AfterHeaderComponent />}
          </div>
          <div
            className={clsx({
              [styles.eventDetailsBodyWrapper]: isEnabled,
              [styles.eventDetailsBodyPadding]:
                !isSelectionModeOn && !isCollapsedView,
            })}
          >
            <BodyComponent
              event={accordionItemProps.event}
              entities={accordionItemProps.entities.sales ?? []}
              entityCount={accordionItemProps.counts.salesCnt ?? 0}
              selectedIds={selectedSaleIds}
              onItemSelectionsChanged={setSelectedSaleIds}
              selectionMode={{ mode: MultiSelectScope.SingleItem }}
              disablePagination
              useTableNavKeys={hasEventListingTableHotkeysFeature}
              useVirtuoso
              onVirtuosoTableScroll={onScroll}
              withOuterPadding={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
