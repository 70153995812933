import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function HideIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M18.219 4.276l1.414 1.415-1.496 1.496A10.861 10.861 0 0122 11.647c-1.725 3.695-5.569 6.286-10 6.286-1.374 0-2.691-.249-3.906-.703l-1.66 1.66-1.414-1.414 1.171-1.171A10.877 10.877 0 012 11.646C3.725 7.95 7.53 5.36 12 5.36c1.526 0 2.974.302 4.289.846l1.93-1.929zm-1.19 4.02l-2.013 2.011.004.005-.017.008-1.478 1.478h1.808c-.038 1.656-1.428 3.027-3.148 3.12l-.185.005c-.473 0-.923-.095-1.331-.268l-1.37 1.37c.863.252 1.772.384 2.701.384 3.412 0 6.588-1.867 8.235-4.763a9.04 9.04 0 00-3.206-3.35zM12 6.883c-3.451 0-6.627 1.867-8.235 4.763a9.067 9.067 0 003.538 3.546l1.839-1.839a3.159 3.159 0 01-.475-1.669c0-1.79 1.49-3.239 3.333-3.239.595 0 1.15.152 1.632.418l1.471-1.47A9.674 9.674 0 0012 6.883z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
