import { useCallback, useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { PurchaseOrderTableProps } from 'src/tables/PurchaseTable/PurchaseTable.types';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import {
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

export function usePurchaseTableData({
  entities: purchaseOrders,
  entityCount: purchaseCount,
  viewMode,
  isItemsLoading,
  ignoreMaxCount,
}: Pick<
  PurchaseOrderTableProps,
  'entities' | 'entityCount' | 'viewMode' | 'isItemsLoading' | 'ignoreMaxCount'
>) {
  const { data: catalog } = useCatalogDataContext();

  const getCatalogDataFromVirtualId = useCallback(
    (viagVirtualId: string | null | undefined) => {
      if (!viagVirtualId) {
        return {};
      }

      const { event, performer, venue } = getEventPerformerVenue(
        viagVirtualId,
        catalog
      );
      return { event: event?.event, performer, venue };
    },
    [catalog]
  );

  const { data, hasNoDataLoad } = useMemo(() => {
    const shouldShowShimmeringRows =
      viewMode === PurchaseViewMode.FlattenedView
        ? purchaseOrders == null || isItemsLoading
        : !purchaseOrders?.length || isItemsLoading;

    const data = shouldShowShimmeringRows
      ? (generateShimmeringRows(purchaseCount) as null[])
      : (purchaseOrders as PurchaseOrderTicketGroup[]);

    if (viewMode === PurchaseViewMode.FlattenedView) {
      return {
        data: data,
        hasNoDataLoad:
          !ignoreMaxCount &&
          purchaseCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS,
      };
    }

    let dataWithEvent: (PurchaseOrderTicketGroup | null)[] = [];

    if (viewMode === PurchaseViewMode.EventTileView) {
      // Event Tile View - fill in the data, item is already flattened
      dataWithEvent = data?.map((tgItem) => {
        if (tgItem == null) {
          return null;
        }

        return {
          ...tgItem,
          ticketGroupItems: null,
          ...getCatalogDataFromVirtualId(tgItem.viagVirtualId),
        } as PurchaseOrderTicketGroup;
      });
    } else {
      // Tile View - just fill in the event data and keep the 2-level hierarchy
      dataWithEvent = data?.map((p) => {
        if (p == null) {
          return null;
        }

        const po = p as PurchaseOrderTicketGroup;
        const groupItems =
          po.ticketGroupItems?.map((tgItem) => {
            return {
              ...po,
              ...tgItem,
              ticketGroupItems: null,
              ...getCatalogDataFromVirtualId(tgItem.viagVirtualId),
            } as PurchaseOrderTicketGroup;
          }) ?? [];

        return {
          ...po,
          ...getCatalogDataFromVirtualId(po.viagVirtualId),
          ticketGroupItems: groupItems,
        } as PurchaseOrderTicketGroup;
      });
    }

    return { data: dataWithEvent, hasNoDataLoad: false };
  }, [
    getCatalogDataFromVirtualId,
    ignoreMaxCount,
    isItemsLoading,
    purchaseCount,
    purchaseOrders,
    viewMode,
  ]);

  return { data, hasNoDataLoad };
}
