import React from 'react';
import { useDebounce, useMeasure } from 'react-use';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { mergeRefs } from 'src/core/utils';
import { RecenterIcon } from 'src/svgs';
import { MinusIcon, PlusIcon } from 'src/svgs/Viagogo';

import { ColorBandProps } from './shared/components/ColorBand';
import { CondensedColorBand } from './shared/components/ColorBand/CondensedColorBand';
import { StyledDiv } from './VenueMap.styled';
import * as styles from './VenueMapContentCondensed.css';

export const VenueMapContentCondensed = ({
  localRef,
  ref,
  svg,
  blurMap,
  hideMap,
  colorBandProps,
  statsContent,
  mapActionContainerClassname,
  v3,
  onZoom,
  onReset,
}: {
  localRef:
    | React.RefObject<HTMLDivElement>
    | React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  ref: React.Ref<HTMLDivElement>;
  svg: string | TrustedHTML;
  blurMap: boolean;
  hideMap?: boolean;
  colorBandProps: ColorBandProps | undefined;
  compQuantityFilters?: string[];
  statsContent: React.ReactNode;
  mapActionContainerClassname?: string;
  v3?: boolean;
  onZoom: (zoomIn?: boolean) => void;
  onReset: () => void;
}) => {
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  useDebounce(() => onReset(), 100, [width]);

  return (
    <Stack
      ref={containerRef}
      direction="column"
      width="full"
      height="full"
      gap="s"
      className={styles.condensedContainer}
    >
      <StyledDiv
        id={'event-detail-map'}
        ref={mergeRefs(localRef, ref)}
        dangerouslySetInnerHTML={v3 ? undefined : { __html: svg }}
        setTransitionAnimation={true}
        blurMap={blurMap}
        hideMap={hideMap}
        style={{ minHeight: 0 }}
      />

      <Stack
        width="full"
        justifyContent="spaceBetween"
        className={mapActionContainerClassname}
      >
        <Stack width="full" alignItems="center" gap="m">
          <div className={styles.mapControlsWrapper}>
            <PlusIcon
              withHoverEffect
              size={vars.iconSize.xl}
              className={styles.plusIconWrapper}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onZoom(true);
              }}
            />
            <MinusIcon
              withHoverEffect
              size={vars.iconSize.xl}
              className={styles.minusIconWrapper}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onZoom(false);
              }}
            />
          </div>
          <div className={styles.mapControlsWrapper}>
            <RecenterIcon
              withHoverEffect
              size={vars.iconSize.xl}
              className={styles.recenterIconWrapper}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onReset();
              }}
            />
          </div>

          <div className={styles.statsContentWrapper}>{statsContent}</div>
        </Stack>
        <Stack alignItems="center">
          {colorBandProps && (
            <div className={styles.colorBandContainer}>
              <CondensedColorBand {...colorBandProps} />
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
