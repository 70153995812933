import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/ui';
import { Divider } from 'src/modals/common/Summary/Summary.styled';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { StatLabel } from '../Map/StatLabel';
import * as styles from './ListingDetailPanel.css';

export const PricingSettingHeader = ({ listing }: { listing: Listing }) => {
  const { watch, setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const { autoPricingEnabled } = watch();

  const sanitizedSeatDisplay = useMemo(() => {
    if (listing.seating?.seatFr === listing.seating?.seatTo) {
      return `${listing.seating?.seatFr}`;
    }
    return `${listing.seating?.seatFr} - ${listing.seating?.seatTo}`;
  }, [listing.seating?.seatFr, listing.seating?.seatTo]);

  return (
    <div className={styles.container}>
      <StatLabel
        stat={listing.seating?.section ?? ''}
        title={<Content id={ContentId.Section} />}
      />
      <Divider height={100} />
      <StatLabel
        stat={listing.seating?.row ?? ''}
        title={<Content id={ContentId.Row} />}
      />
      <Divider height={100} />
      <StatLabel
        stat={sanitizedSeatDisplay}
        title={<Content id={ContentId.Seats} />}
      />
      <Divider height={100} />
      <StatLabel
        stat={`${listing.availQty}`}
        title={<Content id={ContentId.QuantityAbbreviated} />}
      />
      <Divider height={100} />
      <Switch
        checked={autoPricingEnabled ?? false}
        onClick={(e) => e.stopPropagation()}
        onCheckedChange={(isChecked) => {
          const checked = isChecked;
          setValue('autoPricingEnabled', checked);
        }}
      />
    </div>
  );
};
