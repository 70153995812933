import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { OnSaleEventQuery } from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import { FilterToolbarGroup, FilterToolbarItemId } from '../Filters';

export const listingsMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
];

export function useDiscoveryFilters() {
  const { tempQuery, setTempQuery } = useFilterQueryContext<OnSaleEventQuery>();

  const eventFilter = useMemo<FilterToolbarGroup | undefined>(
    () => ({
      titleContentId: ContentId.Events,
      type: 'row',
      items: [
        {
          labelContentId: ContentId.OnSaleDate,
          filterId: 'onSaleDates',
          filterQueryKeys: ['onSaleDates'],
          filterItem: (
            <EventDateSelector
              useRelativePresets
              value={tempQuery.onSaleDates}
              onChange={(eventTimeFrameFilter, dateRange) => {
                if (!isEqual(dateRange, tempQuery.onSaleDates)) {
                  setTempQuery({
                    ...tempQuery,
                    onSaleDates: dateRange ?? null,
                  });
                }
              }}
            />
          ),
        },
      ],
    }),
    [tempQuery, setTempQuery]
  );

  const filters = useMemo<FilterToolbarGroup[]>(
    () => [eventFilter].filter((f) => f).map((f) => f!),
    [eventFilter]
  );

  return { filters };
}
