import {
  Event,
  OnSaleEventMetrics,
  Performer,
  Venue,
} from 'src/WebApiController';

export type OnSaleEventWithMetrics = {
  event: Event;
  performer?: Performer;
  venue?: Venue;
  metrics?: OnSaleEventMetrics;
};

export enum OnSaleEventsTableColumnId {
  Checkbox = 'checkbox',
  Event = 'event',
  Venue = 'venue',
  Location = 'location',
  OnSaleDate = 'onSaleDate',
  Date = 'date',
  RawPreSaleDate = 'rawPreSaleDate',
  ListingCount = 'listingCount',
  TicketCount = 'ticketCount',
  UnitNetProceedsMin = 'unitNetProceedsMin',
  UnitNetProceedsP25 = 'unitNetProceedsP25',
  UnitNetProceedsP50 = 'unitNetProceedsP50',
  VisitsLastHour = 'visitsLastHour',
  VisitsLastDay = 'visitsLastDay',
  VisitsLast3Days = 'visitsLast3Days',
  VisitsLast7Days = 'visitsLast7Days',
  TransactionsLastHour = 'transactionsLastHour',
  TransactionsLastDay = 'transactionsLastDay',
  TransactionsLast3Days = 'transactionsLast3Days',
  TransactionsLast7Days = 'transactionsLast7Days',
  Action = 'action',
}
