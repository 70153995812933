import {
  ColumnDef,
  functionalUpdate,
  InitialTableState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOptionEntries } from 'src/modals/EditTableColumns';
import { NoData, Table } from 'src/tables/Table';
import {
  defaultSalesFlattenedColumnsConfig,
  filterColumnsByFeatures,
  getSaleColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { SALE_MAIN_COLUMNS } from 'src/utils/columns/sales/salesColumnUtils.constants';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  Feature,
  Permission,
  Sale,
  UserSetting,
} from 'src/WebApiController';

import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from '../../utils/columns/sales/salesColumnUtils.types';
import { useSeatingColumnSorting } from '../common/hooks/useSeatingColumnSorting';
import { usePaginationSettings } from '../Table/hooks/usePaginationSettings';
import {
  customColumnDef,
  SALE_TABLE_COLUMNS_CONFIG,
  tagColumnDef,
} from './configs/SaleTableColumnsConfig';
import { SALE_TABLE_FLATTENED_COLUMNS_CONFIG } from './configs/SaleTableFlattenedColumnsConfig';
import { SaleWithEvent } from './SalesTable.type';

export type SalesTableFlattenedProps = {
  sales?: Sale[];
  salesCount: number;
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  disablePagination?: boolean;
  useVirtuoso?: boolean;
  columnVisibilityOverride?: (
    | SalesTableColumnId
    | SalesTableFlattenedColumnId
  )[];
  formatOptionOverrides?: FormatOptionEntries;
  withOuterPadding?: boolean;
  disableSelection?: boolean;
  alwaysShowCheckbox?: boolean;
  showSelectAllOnHeader?: boolean;
  enableColumnFilters?: boolean;
  ignoreMaxCount?: boolean;
};

const PAGE_SIZE = 100;

const tableStyle: CSSProperties = {
  maxHeight: '60vh',
};

const disabledPaginationStyles: CSSProperties = {
  position: 'sticky',
  top: '0',
  zIndex: '10',
};

const tableCellStyles: CSSProperties = {
  height: '1px', // Keep this to be able to use height: 100% inside td
};

export const SalesTableFlattened = ({
  sales,
  salesCount,
  failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
  disablePagination,
  useVirtuoso,
  columnVisibilityOverride,
  formatOptionOverrides,
  withOuterPadding = true,
  disableSelection,
  alwaysShowCheckbox,
  showSelectAllOnHeader,
  enableColumnFilters,
  ignoreMaxCount,
}: SalesTableFlattenedProps) => {
  const showMainColumnsOnly = false;
  const { data: catalog, isItemsLoading } = useCatalogDataContext();
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  const seatingColumnSorting = useSeatingColumnSorting<SaleWithEvent>(
    SalesTableColumnId.Seating,
    SectionType.SalesFlattened
  );

  const { data, hasNoDataLoad } = useMemo(() => {
    const nonNullSales = sales
      ?.map((sale) => {
        const { event, performer, venue } = getEventPerformerVenue(
          sale.viagVirtualId,
          catalog
        );

        if (event) {
          return {
            event: event.event,
            sale,
            performer,
            venue,
            isFlattenedMode: true,
          } as SaleWithEvent;
        }

        return null;
      })
      ?.filter((s) => s != null)
      ?.map((s) => s!);

    const data =
      nonNullSales == null || isItemsLoading
        ? (generateShimmeringRows(salesCount) as null[])
        : nonNullSales;

    const hasNoDataLoad =
      !ignoreMaxCount && salesCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;

    return { data, hasNoDataLoad };
  }, [sales, isItemsLoading, salesCount, ignoreMaxCount, catalog]);

  const hasViewProceeds = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  const { appContext, loginContext } = useAppContext();
  const {
    setGroupItems,
    getGroupToggleState,
    getSelection,
    selectionMode,
    updateGroupFilteredIds,
  } = useMultiSelectionContext();

  const saleSelection = getSelection(NO_GROUP_ID);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    -1,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  const rowSelection = useMemo(() => {
    return data
      ?.flatMap((d) => [d?.sale])
      ?.reduce<Record<string, boolean>>((result, sale) => {
        if (sale?.id) {
          result[sale.id] =
            (getGroupToggleState(NO_GROUP_ID).isGroupSelected ||
              (saleSelection?.items.itemIds ?? [])?.includes(
                String(sale?.id)
              )) ??
            false;
        }
        return result;
      }, {});
  }, [data, getGroupToggleState, saleSelection?.items.itemIds]);

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      if (disableSelection) return;
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      setGroupItems(NO_GROUP_ID, newSelections);
    },
    [disableSelection, rowSelection, setGroupItems]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const {
    value:
      storedSaleFlattenedColumnOrderSetting = defaultSalesFlattenedColumnsConfig,
  } = useServerUserSetting<SalesTableColumnId[]>({
    id: UserSetting.SaleFlattenedColumnOrder,
  });
  const { value: storedSaleColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: UserSetting.SaleFlattenedColumnsEnabled,
  });

  const { value: customSaleColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.SaleCustomColumns,
  });
  const { tagsMetadata, tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Sale,
    true
  );

  const customSaleColumnsFiltered = filterCustomColumnsForSale(
    customSaleColumns,
    tagsMetadataNumeric
  );

  const showCheckbox = useMemo(
    () =>
      alwaysShowCheckbox ||
      selectionMode?.mode === MultiSelectScope.AllGroups ||
      saleSelection.isSingleGroupMultiSelect,
    [
      alwaysShowCheckbox,
      saleSelection.isSingleGroupMultiSelect,
      selectionMode?.mode,
    ]
  );

  const displayedColumnsConfig = useMemo(() => {
    if (showMainColumnsOnly) {
      return SALE_MAIN_COLUMNS.filter((c) =>
        c === SalesTableColumnId.Checkbox ? showCheckbox : true
      ).reduce<ColumnDef<SaleWithEvent | null>[]>((acc, columnId) => {
        const columnConfig = Object.values(SALE_TABLE_COLUMNS_CONFIG).find(
          (column) => column.id === columnId
        );
        if (columnConfig) {
          acc.push(columnConfig);
        }
        return acc;
      }, []);
    }

    const saleColumnOrder = columnVisibilityOverride
      ? columnVisibilityOverride
      : storedSaleFlattenedColumnOrderSetting.filter(
          // If storedSaleColumnsEnabledSetting has never been set, default to true
          (c) => storedSaleColumnsEnabledSetting?.includes(c) ?? true
        );

    if (
      !columnVisibilityOverride &&
      !hasTablePinActionColumnFeature &&
      !saleColumnOrder.includes(SalesTableColumnId.Actions)
    ) {
      saleColumnOrder.push(SalesTableColumnId.Actions);
    }

    const saleTableFlattenedColumnsConfig = Object.values(
      SALE_TABLE_FLATTENED_COLUMNS_CONFIG
    );
    const saleTableColumnsConfig = Object.values(SALE_TABLE_COLUMNS_CONFIG);

    const columns = new Set(
      columnVisibilityOverride
        ? columnVisibilityOverride
            .map(
              (cid) =>
                saleTableColumnsConfig.find((c) => c.id === cid) ??
                saleTableFlattenedColumnsConfig.find((c) => c.id === cid)!
            )

            .map((item) => item.id!)
        : [
            // Add unconfigurable columns first
            ...saleTableFlattenedColumnsConfig
              .filter((column) => {
                const columnDef = getSaleColumnConfigById(
                  column.id as SalesTableFlattenedColumnId
                ).personalization;
                const hasFeatureForColumn =
                  columnDef.requiredFeatures.length === 0 ||
                  hasFeatures(
                    loginContext?.user,
                    appContext?.features,
                    columnDef.requiredFeatures
                  );

                return !columnDef.isConfigurable && hasFeatureForColumn;
                // return true;
                // return !columnDef.isConfigurable;
              })
              .map((item) => item.id as SalesTableFlattenedColumnId),
            ...saleTableColumnsConfig
              .filter((column) => {
                const columnDef = getSaleColumnConfigById(
                  column.id as SalesTableColumnId
                ).personalization;
                const hasFeatureForColumn =
                  columnDef.requiredFeatures.length === 0 ||
                  hasFeatures(
                    loginContext?.user,
                    appContext?.features,
                    columnDef.requiredFeatures
                  );

                let isConfigurable = columnDef.isConfigurable;
                if (
                  !hasTablePinActionColumnFeature &&
                  column.id === SalesTableColumnId.Actions
                ) {
                  isConfigurable = true;
                }

                return !isConfigurable && hasFeatureForColumn;
              })
              .map((item) => item.id as SalesTableColumnId),
            // Add user defined columns next
            ...filterColumnsByFeatures(
              saleColumnOrder,
              SectionType.SalesFlattened,
              customSaleColumnsFiltered,
              loginContext?.user,
              appContext?.features,
              tagsMetadata ?? []
            ),
          ]
    );

    if (!columnVisibilityOverride) {
      if (showCheckbox) {
        if (!alwaysShowCheckbox) {
          columns.delete(SalesTableColumnId.Actions);
        }
      } else {
        columns.delete(SalesTableColumnId.Checkbox);
      }
    }

    return Array.from(columns).reduce<ColumnDef<SaleWithEvent | null>[]>(
      (acc, columnId) => {
        if (tagsMetadata?.find((t) => t.key === columnId)) {
          acc.push(tagColumnDef(columnId));
        } else if (customSaleColumnsFiltered.find((c) => c.id === columnId)) {
          acc.push(
            customColumnDef(
              columnId,
              customSaleColumnsFiltered.find((c) => c.id === columnId)?.formula
            )
          );
        } else {
          const columnConfig1 = saleTableColumnsConfig.find(
            (column) => column.id === columnId
          );
          if (columnConfig1) acc.push(columnConfig1);

          const columnConfig2 = saleTableFlattenedColumnsConfig.find(
            (column) => column.id === columnId
          );
          if (columnConfig2) acc.push(columnConfig2);
        }

        return acc;
      },
      []
    );
  }, [
    showMainColumnsOnly,
    columnVisibilityOverride,
    storedSaleFlattenedColumnOrderSetting,
    hasTablePinActionColumnFeature,
    customSaleColumnsFiltered,
    loginContext?.user,
    appContext?.features,
    tagsMetadata,
    showCheckbox,
    storedSaleColumnsEnabledSetting,
    alwaysShowCheckbox,
  ]);

  const options: Partial<TableOptions<SaleWithEvent | null>> = useMemo(
    () => ({
      data,
      columns: displayedColumnsConfig.filter((c) =>
        c.id === 'proceeds' ? hasViewProceeds : true
      ),
      state: {
        pagination,
        rowSelection,
        sorting,
        columnVisibility: {
          checkbox: showCheckbox,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [SalesTableColumnId.Actions]
            : undefined,
        },
      },
      meta: {
        sectionType: SectionType.SalesFlattened,
        formatOptionOverrides,
        showSelectAllCheckbox: showSelectAllOnHeader,
      },
      getRowId: (row: SaleWithEvent | null, index: number) =>
        (row?.sale?.id ?? index).toString(),
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      enableRowSelection: showCheckbox,
      enableMultiRowSelection: showCheckbox,
      enableSubRowSelection: showCheckbox,
      onRowSelectionChange,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      displayedColumnsConfig,
      pagination,
      rowSelection,
      sorting,
      showCheckbox,
      hasTablePinActionColumnFeature,
      formatOptionOverrides,
      showSelectAllOnHeader,
      setPagination,
      onRowSelectionChange,
      seatingColumnSorting,
      hasViewProceeds,
    ]
  );

  const onFilteredRowsChange = useCallback(
    (filteredRows: Row<SaleWithEvent | null>[] | undefined) => {
      const filteredItemIds = filteredRows
        ?.map((r) => r.original?.sale?.id)
        .filter((lid) => lid != null) as number[] | undefined;
      updateGroupFilteredIds(NO_GROUP_ID, filteredItemIds);
    },
    [updateGroupFilteredIds]
  );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : hasNoDataLoad ? (
    <NoData>
      <WarningMessage
        message={
          <FormatContent
            id={FormatContentId.ApplyFilterToReduceCountsToSeeData}
            params={[MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS.toString()]}
          />
        }
      />
    </NoData>
  ) : data?.length > 0 ? (
    <ColumnResizingContextProvider userSettingId={UserSetting.SaleColumnWidths}>
      <Table
        options={options}
        tableLayout="fixed"
        withOuterPadding={withOuterPadding}
        tableStyle={disablePagination ? tableStyle : undefined}
        tableHeadStyle={
          disablePagination ? disabledPaginationStyles : undefined
        }
        tableCellStyle={tableCellStyles}
        useVirtuoso={useVirtuoso}
        enableColumnFilters={enableColumnFilters}
        onFilteredRowsChange={
          enableColumnFilters ? onFilteredRowsChange : undefined
        }
      />
    </ColumnResizingContextProvider>
  ) : null;
};
