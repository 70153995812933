import { useMemo } from 'react';
import { EventMetricsProvider } from 'src/contexts/EventMetricsContext/EventMetricsContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EventWithData, Feature } from 'src/WebApiController';

import * as styles from './DashboardSection.css';
import { EventSectionClearingPricesWidgetsContainer } from './Widgets/EventSectionClearingPrices';
import { EventSupplyWidgetsContainer } from './Widgets/EventSupply/EventSupplyContainer';
import { EventWebsiteMetricWidgetsContainer } from './Widgets/EventWebsiteMetric';
import { SellerEventPerformanceMetricWidgetsContainer } from './Widgets/SellerEventPerformanceMetric';

export const DashboardSection = ({
  event,
  isOnSaleEvent,
}: {
  event: EventWithData;
  isOnSaleEvent?: boolean;
}) => {
  const hasBlockInventorySummaryStatsFeature = useUserHasFeature(
    Feature.BlockInventorySummaryStats
  );

  const showYourStats = useMemo(() => {
    if (hasBlockInventorySummaryStatsFeature) {
      return false;
    }

    return !isOnSaleEvent;
  }, [hasBlockInventorySummaryStatsFeature, isOnSaleEvent]);

  return (
    <EventMetricsProvider event={event} isOnSaleEvent={isOnSaleEvent}>
      <Stack gap="xl" direction="column" className={styles.dashboardContainer}>
        {showYourStats && <SellerEventPerformanceMetricWidgetsContainer />}
        <EventSupplyWidgetsContainer />
        {/* Removed "Demand Factors" until we have more data to populate here. See SUP-639
       <EventDemandFactorWidgetsContainer event={event} />
        */}
        <EventWebsiteMetricWidgetsContainer />
        <EventSectionClearingPricesWidgetsContainer
          event={event.event}
          isOnSaleEvent={isOnSaleEvent}
        />
      </Stack>
    </EventMetricsProvider>
  );
};
