import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { FilterToolbarItemId } from 'src/components/Filters';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useMergedTagFilters } from 'src/components/MainFilterBar/Tags/useMergedTagFilters';
import { usePurchaseInfoFilters } from 'src/components/MainFilterBar/usePurchaseInfoFilters';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Slider } from 'src/core/ui/Slider';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchasePaymentMethodSelectionInput } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PAYMENT_METHOD_TYPE_TO_CID,
  PURCHASE_PAYMENT_STATUS_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { StandardDateRangePresetNames } from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import { formatNumber } from 'src/utils/numberFormatter';
import {
  ActionOutboxEntityType,
  Feature,
  PurchaseOrderQuery,
  PurchaseOrderState,
} from 'src/WebApiController';

import {
  CurrencyFilterMultiSelector,
  CurrencyFilterSelector,
} from '../Selectors/CurrencyFilterSelector';
import { useCommonEventFilters } from './useCommonEventFilters';

export const purchaseMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'purchaseDates',
];

interface PurchaseFiltersParams {
  isHorizontalLayout?: boolean;
  showCommissionerFilter?: boolean;
  showConsignmentFilter?: boolean;
  showCatalogFilters?: boolean;
  showMetricsIndicationOnFilters?: boolean;
}

export const usePurchaseFilters = ({
  isHorizontalLayout,
  showCommissionerFilter,
  showConsignmentFilter,
  showCatalogFilters,
  showMetricsIndicationOnFilters,
}: PurchaseFiltersParams) => {
  const hasFilterByCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByCurrencyCodeMulti
  );
  const hasFilterByCurrencyCodeFeature = !hasFilterByCurrencyCodeMultiFeature;
  const hasFilterByPaymentCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByPaymentCurrencyCodeMulti
  );
  const hasFilterPOByPaymentMethodIdFeature = useUserHasFeature(
    Feature.FilterPOByPaymentMethodId
  );

  const internalNotesFilter = useInternalNotesFilter<PurchaseOrderQuery>({
    entityType: ActionOutboxEntityType.Purchase,
    isHorizontalLayout,
  });

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const reportType = undefined;
  const purchaseInfoFilters = usePurchaseInfoFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    reportType,
    showCommissionerFilter,
    showMetricsIndicationOnFilters,
  });

  const tagsFilter = useMergedTagFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    entityType: ActionOutboxEntityType.Purchase,
    includeEventTags: false,
  });

  const eventFilter = useCommonEventFilters({ showCatalogFilters });

  return useMemo(() => {
    const costHandle: [number, number] = [
      tempQuery.totalCostLow || 0,
      tempQuery.totalCostHigh || 50000,
    ];

    return [
      {
        titleContentId: ContentId.WhenWhereWho,
        type: 'row',
        items: [
          ...purchaseInfoFilters.items,
          ...(showConsignmentFilter
            ? [
                {
                  filterId: 'isConsignment' as FilterToolbarItemId,
                  labelContentId: ContentId.Consignment,
                  filterQueryKeys: ['isConsignment'] as FilterToolbarItemId[],
                  filterItem: (
                    <PosEnumSelect
                      style={{ width: '100%' }}
                      value={ToYesNoEnum(tempQuery.isConsignment)}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(yesNoEnumValue) => {
                        if (
                          yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            isConsignment: FromYesNoEnum(yesNoEnumValue),
                          });
                        }
                      }}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        titleContentId: ContentId.Payments,
        type: 'row',
        items: [
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'paymentStatus' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentState,
            filterQueryKeys: ['paymentStatus'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.paymentStatus}
                placeholderText={ContentId.AllPaymentStates}
                enableEmptySelection
                onChange={(paymentStatus) => {
                  if (paymentStatus !== tempQuery.paymentStatus) {
                    setTempQuery({
                      ...tempQuery,
                      paymentStatus: paymentStatus,
                    });
                  }
                }}
                valueOptionsContent={PURCHASE_PAYMENT_STATUS_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentMethod' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentType,
            filterQueryKeys: ['paymentMethod'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.paymentMethod}
                placeholderText={ContentId.AllPaymentTypes}
                enableEmptySelection
                onChange={(paymentMethod) => {
                  if (paymentMethod !== tempQuery.paymentMethod) {
                    setTempQuery({
                      ...tempQuery,
                      paymentMethod: paymentMethod,
                    });
                  }
                }}
                valueOptionsContent={PAYMENT_METHOD_TYPE_TO_CID}
              />
            ),
          },
          ...(hasFilterByPaymentCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'paymentCurrencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.PaymentCurrency,
                  filterQueryKeys: [
                    'paymentCurrencyCodes',
                  ] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      values={tempQuery.paymentCurrencyCodes ?? []}
                      triggerProps={{ style: { width: '100%' } }}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(newPaymentCurrencyCodes) => {
                        if (
                          !isEqual(
                            tempQuery.paymentCurrencyCodes,
                            newPaymentCurrencyCodes
                          )
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            paymentCurrencyCodes:
                              newPaymentCurrencyCodes?.length
                                ? newPaymentCurrencyCodes
                                : null,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterPOByPaymentMethodIdFeature
            ? [
                {
                  filterId: 'paymentMethodId' as FilterToolbarItemId,
                  labelContentId: ContentId.PaymentMethodUsed,
                  filterQueryKeys: ['paymentMethodId'] as FilterToolbarItemId[],
                  filterItem: (
                    <PurchasePaymentMethodSelectionInput
                      style={{ width: '100%' }}
                      value={tempQuery.paymentMethodId?.toString()}
                      placeholderText={ContentId.AllPaymentMethods}
                      enableEmptySelection
                      hideAdditionalOptions
                      onChange={(paymentMethodId) => {
                        if (
                          paymentMethodId !==
                          tempQuery.paymentMethodId?.toString()
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            paymentMethodId: paymentMethodId?.length
                              ? parseInt(paymentMethodId)
                              : null,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      eventFilter,
      {
        titleContentId: ContentId.Delivery,
        type: 'row',
        items: [
          {
            filterId: 'deliveryType' as FilterToolbarItemId,
            labelContentId: ContentId.Delivery,
            filterQueryKeys: ['deliveryType'] as FilterToolbarItemId[],
            filterItem: (
              <DeliveryTypeSelector
                style={{ width: '100%' }}
                value={tempQuery.deliveryType}
                enableEmptySelection
                onChange={(deliveryType) => {
                  if (deliveryType !== tempQuery.deliveryType) {
                    setTempQuery({
                      ...tempQuery,
                      deliveryType: deliveryType,
                    });
                  }
                }}
                allowCustomDeliveryType
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Status,
        type: 'row',
        items: [
          {
            filterId: 'purchaseOrderState' as FilterToolbarItemId,
            labelContentId: ContentId.ShowVoidCancelledPurchases,
            filterQueryKeys: ['purchaseOrderState'] as FilterToolbarItemId[],
            filterItem: (
              <Checkbox
                checked={
                  tempQuery.purchaseOrderState !== PurchaseOrderState.Active
                }
                onChange={(e) => {
                  const showCancelledAndVoided = e.target.checked;
                  const newPurchaseOrderState = showCancelledAndVoided
                    ? null
                    : PurchaseOrderState.Active;

                  if (newPurchaseOrderState !== tempQuery.purchaseOrderState) {
                    setTempQuery({
                      ...tempQuery,
                      purchaseOrderState: newPurchaseOrderState,
                    });
                  }
                }}
                labelPosition="right"
                label={
                  isHorizontalLayout ? undefined : (
                    <Content id={ContentId.ShowVoidCancelledPurchases} />
                  )
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Cost,
        items: [
          {
            filterId: 'totalCostHigh' as FilterToolbarItemId,
            labelContentId: ContentId.TotalCost,
            filterQueryKeys: [
              'totalCostLow',
              'totalCostHigh',
            ] as FilterToolbarItemId[],
            filterItem: (
              <Slider.Root
                min={0}
                max={50000}
                step={1}
                value={costHandle}
                renderHandle={(n) => (
                  <Slider.Handle>{formatNumber(n)}</Slider.Handle>
                )}
                onValueChange={([low, high]: [number, number]) => {
                  if (
                    low !== (tempQuery.totalCostLow || 0) ||
                    high !== tempQuery.totalCostHigh
                  ) {
                    const newQuery = {
                      ...tempQuery,
                      totalCostLow: low === 0 ? null : low,
                      totalCostHigh: high === 50000 ? null : high,
                    };
                    setTempQuery(newQuery);
                  }
                }}
              />
            ),
          },
          ...(hasFilterByCurrencyCodeFeature
            ? [
                {
                  filterId: 'currencyCode' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCode'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterSelector
                      value={tempQuery.currencyCode ?? ''}
                      style={{ width: '100%' }}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCode) => {
                        if (tempQuery.currencyCode !== newCurrencyCode) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCode: newCurrencyCode?.length
                              ? newCurrencyCode
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterByCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'currencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      values={tempQuery.currencyCodes ?? []}
                      triggerProps={{ style: { width: '100%' } }}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCodes) => {
                        if (
                          !isEqual(tempQuery.currencyCodes, newCurrencyCodes)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCodes: newCurrencyCodes?.length
                              ? newCurrencyCodes
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      ...tagsFilter,
      ...(internalNotesFilter ? [internalNotesFilter] : []),
    ];
  }, [
    tempQuery,
    purchaseInfoFilters.items,
    showConsignmentFilter,
    initialQuery.paymentDates,
    hasFilterByPaymentCurrencyCodeMultiFeature,
    hasFilterPOByPaymentMethodIdFeature,
    eventFilter,
    isHorizontalLayout,
    hasFilterByCurrencyCodeFeature,
    hasFilterByCurrencyCodeMultiFeature,
    tagsFilter,
    internalNotesFilter,
    setTempQuery,
  ]);
};
