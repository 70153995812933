import { head, uniqBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { AutoPricingSectionRowIdFilter } from 'src/components/AutoPricing/components/AutoPricingSectionRowIdFilter';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { shared, vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { getSectionInfoDisplay } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { filterSectionByZone } from 'src/utils/venueConfigUtils';
import { ListingDetails, SectionInfo } from 'src/WebApiController';

export type SectionSelectorProps = {
  sectionIds: number[];
  disabledSectionIds?: number[];
  onSelectedSectionsChange: (selectedSections: SectionInfo[]) => void;
};

export const SectionSelector = ({
  sectionIds,
  disabledSectionIds,
  onSelectedSectionsChange,
}: SectionSelectorProps) => {
  const { venueMapInfo } = useEventMapContext();
  const { posEntity: listing } = useActivePosEntityContext<ListingDetails>();

  const selectedSections = useMemo(() => {
    if (venueMapInfo) {
      return venueMapInfo.sections
        .filter((section) => sectionIds.includes(section.id))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [sectionIds, venueMapInfo]);

  const selectedSectionIds = useMemo(() => {
    return selectedSections.map((s) => s.id);
  }, [selectedSections]);

  const getColor = useCallback(
    (info: {
      sectionId: number;
      rowId?: number;
      sectionName?: string;
      ticketClassId: number;
      isSelected: boolean;
    }) => {
      const isSectionDisabled = disabledSectionIds?.includes(info.sectionId);
      return {
        stroke: isSectionDisabled
          ? vars.color.backgroundDisabled
          : vars.color.textBrand,
        fill: isSectionDisabled
          ? vars.color.backgroundDisabled
          : info.isSelected
          ? vars.color.backgroundBrandSeatmap
          : vars.color.backgroundPrimary,
        textColor: isSectionDisabled
          ? vars.color.textDisabled
          : vars.color.textStrong,
      };
    },
    [disabledSectionIds]
  );

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => {
      if (disabledSectionIds?.includes(hoveredSection.id)) {
        return null;
      }

      return getSectionInfoDisplay(
        hoveredSection,
        [],
        venueMapInfo?.sectionScores
      );
    },
    [disabledSectionIds, venueMapInfo?.sectionScores]
  );

  const onShiftSelect = useCallback(
    (section: SectionInfo) => {
      // We are using the ticketClassId to group the sections
      const selectedClassId = head(
        section?.rows
          ?.map(({ tktClass: ticketClass }) => ticketClass?.id)
          .filter((id) => !!id)
      );
      const classSections =
        venueMapInfo?.sections?.filter(filterSectionByZone(selectedClassId)) ||
        [];

      const classSectionIds = classSections.map(({ id }) => id);
      if (selectedSections.findIndex(({ id }) => id === section.id) > -1) {
        const updated = selectedSections.filter(
          ({ id }) => !classSectionIds.includes(id)
        );
        onSelectedSectionsChange(updated);
      } else {
        const updated = uniqBy(
          selectedSections.concat(classSections),
          'id'
        ).sort((a, b) => a.name.localeCompare(b.name));
        onSelectedSectionsChange(updated);
      }
    },
    [onSelectedSectionsChange, selectedSections, venueMapInfo?.sections]
  );

  const onSingleSelect = useCallback(
    (section: SectionInfo) => {
      if (disabledSectionIds?.includes(section.id)) {
        return;
      }

      const idx = selectedSections.findIndex(({ id }) => id === section.id);

      const selectedSectionsNew =
        idx === -1
          ? [...selectedSections, section]
          : selectedSections.filter(({ id }) => id !== section.id);
      onSelectedSectionsChange(
        selectedSectionsNew.sort((a, b) => a.name.localeCompare(b.name))
      );
    },
    [disabledSectionIds, onSelectedSectionsChange, selectedSections]
  );

  const onSectionSelected = useCallback(
    (e: MouseEvent, section: SectionInfo) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.shiftKey) {
        onShiftSelect(section);
        return;
      }
      onSingleSelect(section);
    },
    [onSingleSelect, onShiftSelect]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      if (exclude) {
        const idsToExclude = mirrors.map(({ id }) => id);
        const updated = uniqBy(
          selectedSections.filter(({ id }) => !idsToExclude.includes(id)),
          'id'
        ).sort((a, b) => a.name.localeCompare(b.name));
        onSelectedSectionsChange(updated);
      } else {
        const updated = uniqBy(selectedSections.concat(mirrors), 'id').sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        onSelectedSectionsChange(updated);
      }
    },
    [onSelectedSectionsChange, selectedSections]
  );

  return (
    <>
      <span className={shared.typography.title6}>
        <Content id={ContentId.SelectSectionsToPriceAgainst} />
      </span>
      <div style={{ height: '40vh' }}>
        <EventVenueMap
          selectedSectionIds={selectedSectionIds}
          onSectionHovered={onSectionHovered}
          onSectionClicked={onSectionSelected}
          onToggleMirrors={onToggleMirrors}
          setSelectedSections={onSelectedSectionsChange}
          getColor={getColor}
        />
      </div>

      <Stack direction="column" gap="m">
        <span>
          <Content id={ContentId.SelectedSections} />
        </span>
        <AutoPricingSectionRowIdFilter disabled listing={listing} />
      </Stack>
    </>
  );
};
