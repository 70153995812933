import { isEmpty } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { GroupingType } from 'src/WebApiController';
import { ListingGroup, ListingGroupItemInput } from 'src/WebApiController';

import { MergeListingGroupInputListFields } from './components/groupingTypes';
import { flattenListingGroup } from './components/groupingUtils';
import { GroupListingsModalContent } from './GroupListings';

export const AddToGroupListingsModal = ({
  selectedListingIds,
  listingGroupId,
  viagVirtualId,
  newListingGroupName,
}: {
  selectedListingIds: number[];
  listingGroupId?: string | undefined;
  viagVirtualId: string;
  newListingGroupName?: string;
}) => {
  const { eventsTransformed } = useCatalogDataContext();

  const eventData = useMemo(() => {
    return eventsTransformed?.find(
      (e) => e.event.viagVirtualId === viagVirtualId
    );
  }, [eventsTransformed, viagVirtualId]);

  const listingsToAdd = useMemo(() => {
    return eventData?.entities?.listings
      ?.flatMap(flattenListingGroup)
      .filter((l) => selectedListingIds.includes(l.id));
  }, [eventData, selectedListingIds]);

  const listingGroup = useMemo(() => {
    if (!isEmpty(newListingGroupName)) {
      return {
        groupName: newListingGroupName,
        desiredActiveListings: null,
        minActiveListings: null,
        maxActiveListings: null,
        targetPercentage: null,
        deprioritizedQuantities: null,
        marketplaceSettings: null,
        absoluteRankPremium: null,
        relativeRankPremium: null,
      } as ListingGroup;
    }
    return eventData?.entities?.listings?.find(
      (l) => l.isLtGrp && l.ltGrpId === listingGroupId
    ) as ListingGroup;
  }, [eventData?.entities?.listings, listingGroupId, newListingGroupName]);

  const event = eventData?.event;

  const listings = useMemo(() => {
    return [
      ...(listingGroup?.groupItems ?? []),
      ...(listingGroup?.groupItems?.flatMap((gi) =>
        gi.isLtGrp ? (gi as ListingGroup).groupItems : []
      ) ?? []),
      ...(listingsToAdd ?? []),
    ];
  }, [listingsToAdd, listingGroup?.groupItems]);

  const newGroupName = useContent(ContentId.NewGroup);
  const methods = useForm<MergeListingGroupInputListFields>({
    defaultValues: {
      mergeListingGroupInputs: [
        {
          name: listingGroup?.groupName ?? `${newGroupName} 1`,
          listingGroupItems: listings.map(
            (l, idx) =>
              ({
                listingId: l.id,
                priority: l.ltGrpPrior ?? idx + 1,
                groupId: l.ltGrpId,
                undercutSetting: l.ltGrpUndercut,
              }) as ListingGroupItemInput
          ),
          viagogoEventId: event?.viagId,
          viagogoMappingId: event?.mappingId,
          desiredActiveListings: listingGroup?.desiredActiveListings ?? 1,
          minActiveListings: listingGroup?.minActiveListings,
          maxActiveListings: listingGroup?.maxActiveListings,
          targetPercentage: listingGroup?.targetPercentage,
          deprioritizedQuantities: listingGroup?.deprioritizedQuantities ?? [],
          marketplaceSettings: listingGroup?.marketplaceSettings,
          groupUndercutSetting: {
            undAbsAmt: listingGroup?.absoluteRankPremium,
            undRelAmt: listingGroup?.relativeRankPremium,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          },
        },
      ],
      templateSettings: {
        groupBy: {
          groupingType: GroupingType.Custom,
        },
        desiredActiveListings: 0,
        deprioritizedQuantities: [],
      },
      eventSettings: [],
    },
  });

  const { reset } = methods;

  useEffect(() => {
    reset({
      mergeListingGroupInputs: [
        {
          name: listingGroup?.groupName ?? `${newGroupName} 1`,
          listingGroupItems: listings.map(
            (l, idx) =>
              ({
                listingId: l.id,
                priority: l.ltGrpPrior ?? idx + 1,
                groupId: l.ltGrpId,
                undercutSetting: l.ltGrpUndercut,
              }) as ListingGroupItemInput
          ),
          viagogoEventId: event?.viagId,
          viagogoMappingId: event?.mappingId,
          desiredActiveListings: listingGroup?.desiredActiveListings ?? 1,
          minActiveListings: listingGroup?.minActiveListings,
          maxActiveListings: listingGroup?.maxActiveListings,
          targetPercentage: listingGroup?.targetPercentage,
          deprioritizedQuantities: listingGroup?.deprioritizedQuantities ?? [],
          marketplaceSettings: listingGroup?.marketplaceSettings,
          groupUndercutSetting: {
            undAbsAmt: listingGroup?.absoluteRankPremium,
            undRelAmt: listingGroup?.relativeRankPremium,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          },
        },
      ],
      templateSettings: {
        groupBy: {
          groupingType: GroupingType.Custom,
        },
        desiredActiveListings: 0,
        deprioritizedQuantities: [],
      },
      eventSettings: [],
    });
  }, [listings, listingGroup, event, newGroupName, reset]);
  return (
    <FormProvider {...methods}>
      <GroupListingsModalContent
        {...methods}
        events={event ? [event] : undefined}
        listingGroup={listingGroup}
      />
    </FormProvider>
  );
};
