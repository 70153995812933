import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { SortableMetricsOptionsListItem } from './MetricsOptionsListItem';
import * as styles from './ReportsTableEditor.css';

/**
 * The sections that represent the Add Columns and Current Columns for the Edit Columns experience
 */
export const MetricsOptionsList = ({
  id,
  titleContentId,
  availableValues,
  groupByOptionsContent,
  valueOptionsContent,
  disabled,
}: {
  id: string;
  titleContentId?: ContentId;
  availableValues: string[];
  groupByOptionsContent: Record<string, ContentId>;
  valueOptionsContent: Record<string, ContentId>;
  disabled?: boolean;
}) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext items={availableValues}>
      <div className={styles.metricsOptionsListContainer}>
        {titleContentId && <Content id={titleContentId} />}
        <Stack
          direction="column"
          height="full"
          width="full"
          ref={setNodeRef}
          gap="s"
        >
          {availableValues.map((value) => (
            <SortableMetricsOptionsListItem
              key={value}
              id={value}
              disabled={disabled}
              actionButton={
                <>
                  {Object.keys(valueOptionsContent).includes(value) && (
                    <div className={styles.badge}>
                      <Content id={ContentId.Value} />
                    </div>
                  )}
                </>
              }
            >
              <Content
                id={valueOptionsContent[value] || groupByOptionsContent[value]}
              />
            </SortableMetricsOptionsListItem>
          ))}
        </Stack>
      </div>
    </SortableContext>
  );
};
