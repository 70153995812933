import { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  SetCommissionsForm,
  SetCommissionsRefActions,
} from 'src/components/common/SetCommissions/SetCommissionsForm';
import { Content } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { MAX_COMMISSIONERS } from 'src/dialogs/SaleCommissionOverrideDialog/SaleCommissionOverrideDialog.constants';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { BodySectionTitle, TicketsDetailRow } from 'src/modals/common';
import { LockCommissions } from 'src/modals/common/Purchase/CommissionSection/LockCommissions';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { Feature } from 'src/WebApiController';

import * as styles from '../PurchaseModal.css';

export type PurchaseCommissionInput = Pick<
  PurchaseOrderDetailsInput,
  'commissions' | 'isCommissionsLocked'
>;

export function CommissionSection({
  disabled: disableProp,
  readonly,
  showLockCommissionsButton = true,
  canLockUnlockCommission = true,
}: {
  disabled?: boolean;
  readonly?: boolean;
  showLockCommissionsButton?: boolean;
  canLockUnlockCommission?: boolean;
}) {
  const ref = useRef<SetCommissionsRefActions | null>(null);
  const { setValue, watch, formState } =
    useFormContext<PurchaseCommissionInput>();

  const { allActiveUserInfos } = useSellerAccountContext();
  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const commissions = watch('commissions');
  const isCommissionsLocked = watch('isCommissionsLocked')?.value ?? false;
  const selectedCommissionsCount = (commissions?.value ?? []).length;
  const maxCommissionersReached = selectedCommissionsCount >= MAX_COMMISSIONERS;

  const userHasLockCommissionsOnPoFeature = useUserHasFeature(
    Feature.LockCommissionsOnPo
  );
  const disabled =
    disableProp || (userHasLockCommissionsOnPoFeature && isCommissionsLocked);

  const showAddCommission = useMemo(() => {
    if (!activeUserIds) return false;
    return (commissions?.value?.length ?? 0) < activeUserIds.length;
  }, [commissions, activeUserIds]);

  const onCommissionsLockChange = useCallback(
    (isCommissionsLocked: boolean) => {
      setValue('isCommissionsLocked', posChangedField(isCommissionsLocked));
    },
    [setValue]
  );

  return (
    <div className={styles.bodySection}>
      <BodySectionTitle>
        <Content id={ContentId.BuyerCommission} />
      </BodySectionTitle>
      <Stack direction="column" gap="m" className={styles.commissionList}>
        <SetCommissionsForm
          actionsRef={ref}
          disableForm={disabled}
          footerActions={
            <TicketsDetailRow>
              <Stack alignItems="center">
                {showAddCommission && !readonly && (
                  <Button
                    variant={'link'}
                    onClick={() => ref.current?.addCommissionAction()}
                    disabled={
                      maxCommissionersReached ||
                      (userHasLockCommissionsOnPoFeature && isCommissionsLocked)
                    }
                  >
                    <PlusIcon
                      size={vars.iconSize.s}
                      fill={IconsFill.currentColor}
                    />
                    <Content id={ContentId.AddCommission} />
                  </Button>
                )}
                {showLockCommissionsButton &&
                  userHasLockCommissionsOnPoFeature &&
                  selectedCommissionsCount > 0 && (
                    <LockCommissions
                      disabledLockButton={
                        !formState.isValid || !canLockUnlockCommission
                      }
                      disabledUnlockButton={!canLockUnlockCommission}
                      isCommissionsLocked={isCommissionsLocked}
                      onCommissionsLockChange={onCommissionsLockChange}
                    />
                  )}
              </Stack>
            </TicketsDetailRow>
          }
        />
      </Stack>
    </div>
  );
}
