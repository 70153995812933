import { useCallback, useEffect, useState } from 'react';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsPricingSectionUpdates,
  UiCurrency,
} from 'src/WebApiController';

import * as styles from './ListingDetailPanel.css';

type PricingSettingManualPriceProps = {
  uiCurrency: UiCurrency;
  sellerFee: number | null;
};

/**
 * Requires ListingLoadBySection to work
 */
export const PricingSettingManualPrice: React.FC<
  PricingSettingManualPriceProps
> = ({ uiCurrency, sellerFee }) => {
  const setPriceText = useContent(ContentId.Permission_SetPrice);
  const resetText = useContent(ContentId.Reset);

  const { activeAccountWebClientConfig } = useAppContext();
  const { selectedListing } = useInventoryEventDetailContext();
  const { updateItemInEvent } = useCatalogDataContext();
  const { onUpdateListing } = useEventHubContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const [listPrice, setListPrice] = useState<number | null>(
    selectedListing?.listPrice ?? null
  );

  // This is to ensure that we use the correct list price when switching selected listing
  useEffect(() => {
    if (selectedListing?.listPrice != null) {
      setListPrice(selectedListing?.listPrice);
    }
  }, [selectedListing?.listPrice]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submitPriceChange = useCallback(() => {
    if (!selectedListing) return;

    tryInvokeApi(
      async () => {
        setIsSubmitting(true);
        const client = new ListingClient(activeAccountWebClientConfig);

        const input: ListingDetailsPricingSectionUpdates = {
          id: selectedListing.id,
          rowVersion: null,
          listPrice,
          allInPrice: null, // will be inferred from list price
          buyerUserId: null,
          marketplacePriceUpdates: [],
          autoPricingEnabled: false, // updating manual price will disable auto price
          netProceedsFloor: selectedListing.procsFloor,
          pricerSellerUserId: selectedListing.pricerId,
          netProceedsCeiling: selectedListing.procsCeil,
          currencyCode: selectedListing.currency,
        };
        const result = await client.updateListingPricingSection(input);
        if (isSuccess(result)) {
          const updated = await client.getListingByListingId(
            selectedListing.id,
            selectedListing as ListingDetails,
            [ListingDetailDataField.Pricing]
          );
          if (updated) {
            updateItemInEvent(updated, ActionOutboxEntityType.Listing);
            onUpdateListing(updated.id);
            setIsSubmitting(false);
          }
        }
      },
      (error) => {
        showErrorDialog('ListingClient.updateListing(prices)', error, {
          trackErrorData: { listingId: selectedListing.id, listPrice },
        });
      }
    );
  }, [
    selectedListing,
    activeAccountWebClientConfig,
    listPrice,
    updateItemInEvent,
    onUpdateListing,
    showErrorDialog,
  ]);

  return (
    <div className={styles.pricingSettingsBodyInnerLayout}>
      <div className={styles.manualPricingContainer}>
        <PosCurrencyField
          className={styles.textAlignRight}
          uiCurrency={uiCurrency}
          prefixDisplay={
            <span
              className={styles.setPriceText}
            >{`${setPriceText}: ${uiCurrency.sym}`}</span>
          }
          value={getAllInPriceFromListPrice(listPrice, null, sellerFee) ?? ''}
          onChange={(e) => {
            const price = parseFloat(e.target.value);
            if (!isNaN(price)) {
              const listPriceNew = getListPriceFromAllinPrice(
                price,
                null,
                sellerFee
              )!;
              setListPrice(listPriceNew);
            }
          }}
        />
      </div>
      <div className={styles.innerLayoutFooter}>
        <Button
          disabled={isSubmitting}
          variant="link"
          textColor="primary"
          justifyContent="start"
          onClick={() => setListPrice(selectedListing?.listPrice ?? null)}
          shape="none"
        >
          {resetText}
        </Button>
        <OkButton
          disabled={isSubmitting}
          onClick={submitPriceChange}
          textContentId={ContentId.Save}
        />
      </div>
    </div>
  );
};
