import { HeaderContext } from '@tanstack/react-table';
import { ReactNode } from 'react';
import {
  Control,
  useWatch,
} from 'react-hook-form';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput } from 'src/WebApiController';

import * as styles from '../SpreadsheetTable.css';
import { isPosField } from '../utils';
import { CurrencyCell } from './CurrencyCell';
import { InputCell } from './InputCell';

export const SpreadsheetCell = {
  InputCell: InputCell,
  CurrencyCell: CurrencyCell,
  FooterAggregateCell: function FooterAggregateCell<T>({
    prefixDisplay,
    control,
    fieldName,
    type = 'number',
  }: HeaderContext<T, unknown> & {
    prefixDisplay?: ReactNode;
    control: Control<PurchaseTicketsInput, any>;
    fieldName: string;
    type?: 'number' | 'currency';
  }) {
    const values = useWatch({
      control,
      name: `ticketGroups`,
    });

    const total = values.reduce((sum, row) => {
      const value = row[fieldName as keyof TicketGroupInput];
      const isValuePosField = isPosField(value);
      if (!isValuePosField) {
        return sum;
      }

      if (typeof value.value === 'number') {
        return sum + (value.value || 0);
      } else if (typeof value.value === 'string') {
        return sum + parseFloat(value.value);
      }
      return sum;
    }, 0);

    return (
      <div className={styles.footerAggregateCellContainer}>
        {prefixDisplay && <div>{prefixDisplay}</div>}
        <div className={styles.footerAggregateCell}>
          {type === 'currency' ? total.toFixed(2) : total}
        </div>
      </div>
    );
  },
};
