import { isEmpty, size } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContentContext } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { newGuidId } from 'src/utils/idUtils';
import {
  Event,
  Listing,
  ListingGroupItemInput,
  MergeListingGroupInput,
  RowGroupSetting,
} from 'src/WebApiController';

import { flattenListingGroup } from '../GroupListings/components/groupingUtils';
import {
  groupPrioritySort,
  useGroupSortingCriteria,
} from '../GroupListings/components/ListingGroupInput.hook';
import {
  GroupListingsFormProps,
  GroupListingsState,
  GroupSortByType,
  ListingGroupType,
} from './GroupListingsV2.types';
import { ListingGroupsGenerator } from './ZoneMapGroupsGenerator';

const stringSortWrapper = (
  a: string | null | undefined,
  b: string | null | undefined
) => {
  const parsedA = parseFloat(a ?? '');
  const parsedB = parseFloat(b ?? '');
  if (isNaN(parsedA) || isNaN(parsedB)) {
    return (a ?? '').localeCompare(b ?? '');
  }
  return parsedA - parsedB;
};

const rowRangeFromGroupSetting = (rowGroupSetting: RowGroupSetting) => {
  switch (rowGroupSetting) {
    case RowGroupSetting.OneToFiveSixPlus:
      return [
        { min: 1, max: 5 },
        { min: 6, max: Number.MAX_VALUE },
      ];
    case RowGroupSetting.OneToTenElevenPlus:
      return [
        { min: 1, max: 10 },
        { min: 11, max: Number.MAX_VALUE },
      ];
    case RowGroupSetting.SingleTwoToFiveSixPlus:
      return [
        { min: 1, max: 1 },
        { min: 2, max: 5 },
        { min: 6, max: Number.MAX_VALUE },
      ];
    case RowGroupSetting.OneTwoToFiveSixToTwelveThirteenPlus:
      return [
        { min: 1, max: 1 },
        { min: 2, max: 5 },
        { min: 6, max: 12 },
        { min: 13, max: Number.MAX_VALUE },
      ];
    default:
      return [];
  }
};

const sortGroupListings = <T extends { listingId: number }>(
  groupListings: T[],
  eventListings: Listing[],
  groupSort?: GroupSortByType | null
) => {
  if (!groupSort) {
    return groupListings;
  }
  const groupListingIds = groupListings.map((l) => l.listingId);

  const isAssending = ![
    GroupSortByType.RowDesc,
    GroupSortByType.SectionDesc,
    GroupSortByType.UnsoldQuantityDesc,
  ].includes(groupSort);
  const orderFactor = isAssending ? 1 : -1;
  const sortedListingIds = eventListings
    .filter((l) => groupListingIds.includes(l.id))
    .sort((a, b) => {
      if (
        [GroupSortByType.SectionAsc, GroupSortByType.SectionDesc].includes(
          groupSort
        )
      ) {
        return (
          orderFactor *
          stringSortWrapper(a.seating?.section, b.seating?.section)
        );
      }
      if (
        [GroupSortByType.RowAsc, GroupSortByType.RowDesc].includes(groupSort)
      ) {
        return orderFactor * stringSortWrapper(a.seating?.row, b.seating?.row);
      }

      return orderFactor * (a.availQty - b.availQty);
    })
    .map((l) => l.id);

  return groupListings
    .sort((a, b) => {
      return (
        sortedListingIds.indexOf(a.listingId) -
        sortedListingIds.indexOf(b.listingId)
      );
    })
    .map((l, index) => ({ ...l, priority: index + 1 }));
};

export const useSetListingGroupValue = (listingGroupId: string) => {
  const { watch, setValue } = useFormContext<GroupListingsFormProps>();
  const listingGroups = watch('mergeListingGroupInputs');

  const setListingGroupValue = useCallback(
    (value: Partial<MergeListingGroupInput>) => {
      setValue(
        'mergeListingGroupInputs',
        listingGroups.map((group) => {
          if (group.listingGroupId === listingGroupId) {
            return {
              ...group,
              ...value,
            };
          }
          return group;
        })
      );
    },
    [listingGroupId, listingGroups, setValue]
  );

  return setListingGroupValue;
};

const useLoadListingGroups = (
  event: Event,
  listings: Listing[],
  input: GroupListingsFormProps
) => {
  const { venueMapInfo, venueZoneConfigs } = useEventMapContext();

  const idToSortingCriteria = useGroupSortingCriteria(listings);
  const contentContext = useContentContext();

  const loadFromZoneMap = useCallback((): MergeListingGroupInput[] => {
    if (isEmpty(venueZoneConfigs?.sectionSectionGroupLookup)) {
      return [];
    }
    const rowRanges = input.groupBys[0].ranges ?? [];

    let generator = new ListingGroupsGenerator(
      listings,
      venueMapInfo?.sections ?? [],
      Object.values(venueZoneConfigs?.sectionSectionGroupLookup ?? {})
    )
      .generateBaseZoneGroups()
      .splitByRanges(rowRanges, ListingGroupType.Row, contentContext);

    if (input.groupBys.length > 1) {
      const secondaryGroup = input.groupBys[1];
      if (isEmpty(secondaryGroup.ranges)) {
        generator = generator.splitWithGroupBy(secondaryGroup, contentContext);
      } else {
        generator = generator.splitByRanges(
          secondaryGroup.ranges ?? [],
          secondaryGroup.groupingType,
          contentContext
        );
      }
    }

    return generator.getListingGroups().map((group): MergeListingGroupInput => {
      const listingGroupId = newGuidId();
      const { undAbsAmt, undRelAmt } = input.groupUndercutSettings ?? {};

      const items = sortGroupListings(
        group.listings.map((l, i) => ({
          ...l,
          listingId: l.id,
          priority: i + 1,
        })),
        listings,
        input.groupSortBy
      )
        .sort(
          groupPrioritySort(idToSortingCriteria, input.deprioritizedQuantities)
        )
        .map((l, i): ListingGroupItemInput => {
          const undercutSetting = {
            undAbsAmt: undAbsAmt ? undAbsAmt * i : null,
            undRelAmt: undRelAmt ? Math.min(undRelAmt * i, 1) : null,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          };
          return {
            listingId: l.listingId,
            priority: i + 1,
            undercutSetting,
            groupId: l.ltGrpId,
          };
        });
      return {
        listingGroupId,
        name: group.name,
        minActiveListings: null,
        maxActiveListings: null,
        targetPercentage: null,
        viagogoEventId: event.viagId,
        viagogoMappingId: event.mappingId,
        desiredActiveListings: input.desiredActiveListings,
        deprioritizedQuantities: input.deprioritizedQuantities,
        marketplaceSettings: input.marketplaceSettings,
        groupUndercutSetting: input.groupUndercutSettings,
        listingGroupItems: items,
        groupingMethods: null,
      };
    });
  }, [
    venueZoneConfigs?.sectionSectionGroupLookup,
    input.groupBys,
    input.groupUndercutSettings,
    input.groupSortBy,
    input.deprioritizedQuantities,
    input.desiredActiveListings,
    input.marketplaceSettings,
    listings,
    venueMapInfo?.sections,
    contentContext,
    idToSortingCriteria,
    event.viagId,
    event.mappingId,
  ]);

  const loadFromGroupBys = useCallback(() => {
    let generator = new ListingGroupsGenerator(
      listings,
      venueMapInfo?.sections ?? [],
      Object.values(venueZoneConfigs?.sectionSectionGroupLookup ?? {})
    );

    for (const groupBy of input.groupBys) {
      if (isEmpty(groupBy.ranges)) {
        generator = generator.splitWithGroupBy(groupBy, contentContext);
      } else {
        generator = generator.splitByRanges(
          groupBy.ranges ?? [],
          groupBy.groupingType,
          contentContext
        );
      }
    }

    const { undAbsAmt, undRelAmt } = input.groupUndercutSettings ?? {};

    return generator.getListingGroups().map((group): MergeListingGroupInput => {
      const listingGroupId = newGuidId();
      const sortedItems = sortGroupListings(
        group.listings.map((l, i) => ({
          ...l,
          listingId: l.id,
          priority: i + 1,
        })),
        listings,
        input.groupSortBy
      )
        .sort(
          groupPrioritySort(idToSortingCriteria, input.deprioritizedQuantities)
        )
        .map((l, i): ListingGroupItemInput => {
          const undercutSetting = {
            undAbsAmt: undAbsAmt ? undAbsAmt * i : null,
            undRelAmt: undRelAmt ? Math.min(undRelAmt * i, 1) : null,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          };
          return {
            listingId: l.listingId,
            priority: i + 1,
            undercutSetting,
            groupId: l.ltGrpId,
          };
        });
      return {
        listingGroupId,
        name: group.name,
        minActiveListings: null,
        maxActiveListings: null,
        targetPercentage: null,
        viagogoEventId: event.viagId,
        viagogoMappingId: event.mappingId,
        desiredActiveListings: input.desiredActiveListings,
        deprioritizedQuantities: input.deprioritizedQuantities,
        marketplaceSettings: input.marketplaceSettings,
        groupUndercutSetting: input.groupUndercutSettings,
        listingGroupItems: sortedItems,
        groupingMethods: null,
      };
    });
  }, [
    contentContext,
    event.mappingId,
    event.viagId,
    idToSortingCriteria,
    input.deprioritizedQuantities,
    input.desiredActiveListings,
    input.groupBys,
    input.groupSortBy,
    input.groupUndercutSettings,
    input.marketplaceSettings,
    listings,
    venueMapInfo?.sections,
    venueZoneConfigs?.sectionSectionGroupLookup,
  ]);

  return {
    loadFromGroupBys,
    loadFromZoneMap,
  };
};

export const useEventListings = (event: Event) => {
  const { eventsTransformed } = useCatalogDataContext();

  const eventDate = useMemo(() => {
    return (
      eventsTransformed?.find(
        (e) => e.event.viagVirtualId === event.viagVirtualId
      ) ?? null
    );
  }, [event.viagVirtualId, eventsTransformed]);

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(event);

  const eventListings = useMemo(() => {
    const selectedListingIds = filterQueryWithEventIds.entityIds ?? [];
    if (
      selectedListingIds.length === 0 ||
      eventDate?.entities?.listings == null
    ) {
      return (
        (eventDate?.entities?.listings?.flatMap(
          flattenListingGroup
        ) as Listing[]) ?? []
      );
    }

    return (
      (eventDate?.entities?.listings
        .filter((l) => selectedListingIds.includes(l.id))
        .flatMap(flattenListingGroup) as Listing[]) ?? []
    );
  }, [filterQueryWithEventIds.entityIds, eventDate?.entities?.listings]);

  return eventListings;
};

export const useGroupListingsAction = (
  event: Event,
  onSubmit: (input: GroupListingsFormProps) => void
) => {
  const [state, setState] = useState<GroupListingsState>(
    GroupListingsState.Configure
  );

  const eventListings = useEventListings(event);

  const { watch, setValue, handleSubmit } =
    useFormContext<GroupListingsFormProps>();
  const input = watch();

  const primaryGroupType = useMemo(() => {
    if (size(input.groupBys) < 1) {
      return null;
    }
    return input.groupBys[0].groupingType;
  }, [input.groupBys]);

  const { loadFromGroupBys, loadFromZoneMap } = useLoadListingGroups(
    event,
    eventListings,
    input
  );

  const onFooterNav = useCallback(
    (back = false) => {
      // Move from configure to preview
      if (state === GroupListingsState.Configure) {
        if (primaryGroupType === ListingGroupType.ZoneMap) {
          const groups = loadFromZoneMap();
          setValue('mergeListingGroupInputs', groups);
        } else {
          const groups = loadFromGroupBys();
          setValue('mergeListingGroupInputs', groups);
        }
        setState(GroupListingsState.Review);
      }

      // Submit
      if (state === GroupListingsState.Review) {
        if (back) {
          setState(GroupListingsState.Configure);
        } else {
          handleSubmit(onSubmit)();
        }
      }
    },
    [
      state,
      primaryGroupType,
      loadFromZoneMap,
      setValue,
      loadFromGroupBys,
      handleSubmit,
      onSubmit,
    ]
  );

  return {
    state,
    onFooterNav,
    eventListings,
  };
};
