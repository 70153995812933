import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ChangeEvent, useMemo } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import {
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext/Content';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui/Button';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { DeleteIcon, DollarIcon, PlusIcon } from 'src/svgs/Viagogo';
import { SpreadsheetCell } from 'src/tables/SpreadsheetTable/SpreadsheetCell/SpreadsheetCell';
import { isPosField } from 'src/tables/SpreadsheetTable/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import {
  DeliveryType,
  ListingNote,
  TicketGroupInput,
} from 'src/WebApiController';

import { TicketGroupInputQuantity } from '../TableGroupInputQuantity';
import { TicketGroupInputDeliveryType } from '../TicketGroupInputDeliveryType';
import { TicketGroupInputRowSelector } from '../TicketGroupInputRowSelector';
import { TicketGroupInputSeatRange } from '../TicketGroupInputSeatRange';
import { TicketGroupInputSeatTrait } from '../TicketGroupInputSeatTrait';
import { TicketGroupInputVenueInput } from '../TicketGroupInputVenueInput';
import { useTicketGroupDeliveryTypes } from './useTicketGroupDeliveryTypes';

export const useTicketGroupInputTableColumns = ({
  isTotalCostChecked,
  purchaseTicketGroupInputField,
  valueOptionsContent,
  listingNotesMap,
  launchSeatRangeDialog,
}: {
  isTotalCostChecked: boolean;
  purchaseTicketGroupInputField: UseFieldArrayReturn<
    PurchaseTicketsInput,
    'ticketGroups',
    'id'
  >;
  valueOptionsContent: Record<string, ContentId | string>;
  listingNotesMap?: Record<string, ListingNote> | null;
  launchSeatRangeDialog: (rowIndex: number) => void;
}) => {
  const { control, register, getValues, setValue } =
    useFormContext<PurchaseTicketsInput>();

  const { loginContext } = useAppContext();

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );
  const {
    deliveryTypeMapping,
    deliveryTypeMappingOverride,
    deliverTypeOptionsAlwaysShow,
  } = useTicketGroupDeliveryTypes();

  const { insert, remove } = purchaseTicketGroupInputField;

  const columnHelper = createColumnHelper<TicketGroupInput>();

  const costColumn = useMemo<ColumnDef<TicketGroupInput>>(() => {
    if (isTotalCostChecked) {
      return {
        id: 'totalCost',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.TotalCost} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.totalCost?.value,
        size: 104,
        minSize: 104,
        maxSize: 104,
        cell: (context) => {
          const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            const totalCost = parseFloat(e.target.value);
            const qty = getValues(
              `ticketGroups.${context.row.index}.numberOfTickets.value`
            );

            if (!qty || !totalCost) {
              return;
            }

            const calculatedUnitCost = parseFloat((totalCost / qty).toFixed(2));

            setValue(
              `ticketGroups.${context.row.index}.unitCost`,
              posChangedField(calculatedUnitCost)
            );
          };

          return (
            <SpreadsheetCell.CurrencyCell
              {...context}
              control={control}
              fieldName={`ticketGroups.${context.row.index}.totalCost`}
              uiCurrency={uiCurrency}
              onChange={onChange}
            />
          );
        },
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'number',
        },
        footer: (context) => (
          <SpreadsheetCell.FooterAggregateCell
            {...context}
            fieldName="totalCost"
            control={control}
            prefixDisplay={uiCurrency.sym}
            type="currency"
          />
        ),
      };
    } else {
      return {
        id: 'unitCost',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.TicketCost} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.unitCost?.value,
        size: 104,
        minSize: 104,
        maxSize: 104,
        cell: (context) => {
          const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            const unitCost = parseFloat(e.target.value);

            const qty = getValues(
              `ticketGroups.${context.row.index}.numberOfTickets.value`
            );

            if (!qty || !unitCost) {
              return;
            }

            const calculatedTotalCost = parseFloat((unitCost * qty).toFixed(2));
            setValue(
              `ticketGroups.${context.row.index}.totalCost`,
              posChangedField(calculatedTotalCost)
            );
          };
          return (
            <SpreadsheetCell.CurrencyCell
              {...context}
              control={control}
              fieldName={`ticketGroups.${context.row.index}.unitCost`}
              uiCurrency={uiCurrency}
              onChange={onChange}
            />
          );
        },
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'number',
        },
        footer: (context) => (
          <SpreadsheetCell.FooterAggregateCell
            {...context}
            fieldName="unitCost"
            control={control}
            prefixDisplay={uiCurrency.sym}
            type="currency"
          />
        ),
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTotalCostChecked, register, control, uiCurrency.sym]);

  const columnConfigs = useMemo<ColumnDef<TicketGroupInput>[]>(
    () => [
      columnHelper.accessor((data) => data?.section, {
        id: 'section',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.Section} />
          </TableHeaderCellDiv>
        ),
        size: 200,
        cell: (context) => (
          <TicketGroupInputVenueInput {...context} control={control} />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'string',
        },
      }) as ColumnDef<TicketGroupInput>,
      {
        id: 'row',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.Row} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.row?.value,
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_DEFAULT_SIZE_SM,
        maxSize: COLUMN_DEFAULT_SIZE_SM,
        cell: (context) => (
          <TicketGroupInputRowSelector {...context} control={control} />
        ),
      },
      {
        id: 'numberOfTickets',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.QuantityAbbreviated} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.numberOfTickets?.value,
        size: 40,
        minSize: 40,
        maxSize: 40,
        cell: (context) => (
          <TicketGroupInputQuantity {...context} control={control} />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'number',
        },
        footer: (context) => (
          <SpreadsheetCell.FooterAggregateCell
            {...context}
            control={control}
            fieldName="numberOfTickets"
          />
        ),
      },
      {
        id: 'tickets',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.SeatRange} />
          </TableHeaderCellDiv>
        ),
        size: 100,
        cell: (context) => (
          <TicketGroupInputSeatRange
            {...context}
            control={control}
            launchSeatRangeDialog={launchSeatRangeDialog}
          />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) => {
            return (
              Array.isArray(value) &&
              value.every((item) => typeof item === 'object')
            );
          },
        },
      },
      costColumn,
      {
        id: 'faceValueCost',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.FaceValue} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.faceValueCost?.value,
        size: 104,
        minSize: 104,
        maxSize: 104,
        cell: (context) => (
          <SpreadsheetCell.CurrencyCell
            {...context}
            control={control}
            fieldName={`ticketGroups.${context.row.index}.faceValueCost`}
            uiCurrency={uiCurrency}
          />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'number',
        },
        footer: (context) => (
          <SpreadsheetCell.FooterAggregateCell
            {...context}
            fieldName="faceValueCost"
            control={control}
            prefixDisplay={uiCurrency.sym}
            type="currency"
          />
        ),
      },
      {
        id: 'expectedValue',
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.ListPrice} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data) => data?.expectedValue?.value,
        size: 104,
        minSize: 104,
        maxSize: 104,
        cell: (context) => (
          <SpreadsheetCell.CurrencyCell
            {...context}
            control={control}
            fieldName={`ticketGroups.${context.row.index}.expectedValue`}
            uiCurrency={uiCurrency}
          />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) =>
            typeof value === 'number',
        },
        footer: (context) => (
          <SpreadsheetCell.FooterAggregateCell
            {...context}
            fieldName="expectedValue"
            control={control}
            prefixDisplay={uiCurrency.sym}
            type="currency"
          />
        ),
      },
      {
        id: 'deliveryType',
        header: () => {},
        enableSorting: false,
        accessorFn: (data) => data?.deliveryType?.value,
        size: 48,
        cell: (context) => (
          <TicketGroupInputDeliveryType
            {...context}
            control={control}
            deliveryTypeMapping={deliveryTypeMapping}
            deliveryTypeMappingOverride={deliveryTypeMappingOverride}
            deliverTypeOptionsAlwaysShow={deliverTypeOptionsAlwaysShow}
          />
        ),
        meta: {
          pasteVerificationHandler: (value: unknown) => {
            return (
              typeof value === 'string' &&
              Object.values(DeliveryType).includes(value as DeliveryType)
            );
          },
        },
      },
      {
        id: 'listingNotes',
        enableSorting: false,
        header: () => (
          <TableHeaderCellDiv align="left" style={{ border: 'none' }}>
            <Content id={ContentId.SeatTraits} />
          </TableHeaderCellDiv>
        ),
        accessorFn: (data): ListingNote[] | null =>
          data?.listingNotes?.value ?? [],
        size: COLUMN_DEFAULT_SIZE_LG,
        cell: (context) => {
          return (
            <TicketGroupInputSeatTrait
              {...context}
              control={control}
              valueOptionsContent={valueOptionsContent}
              listingNotesMap={listingNotesMap}
            />
          );
        },
        meta: {
          pasteVerificationHandler: (value: unknown) => {
            return (
              Array.isArray(value) &&
              value.every((item) => typeof item === 'object')
            );
          },
        },
      },
      columnHelper.display({
        id: 'actions',
        enableSorting: false,
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_DEFAULT_SIZE_SM,
        maxSize: COLUMN_DEFAULT_SIZE_SM,
        cell: ({ row, column: { id: columnId }, table, cell }) => {
          const numRows = table.getRowModel().rows.length;
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '100%',
                padding: '0 4px',
              }}
            >
              <Button
                variant="textPlain"
                style={{ padding: 0 }}
                onClick={() => {
                  const curRowValues = getValues(
                    `ticketGroups.${cell.row.index}`
                  );
                  const newRow: TicketGroupInput = { ...curRowValues };

                  for (const key in curRowValues) {
                    const curRowKey = key as keyof TicketGroupInput;

                    const curRowValue = curRowValues[curRowKey];
                    const newRowValue = newRow[curRowKey];
                    if (
                      curRowValue &&
                      isPosField(curRowValue) &&
                      curRowValue.value
                    ) {
                      // Need to mark populated fields as changed so validation can work
                      if (newRowValue && isPosField(newRowValue)) {
                        newRowValue.hasChanged = true;
                      }
                    }
                  }

                  const newTicketGroupId = newBigIntId();
                  const newTickets = curRowValues.tickets?.value
                  ? curRowValues.tickets.value.map((ticket) => ({
                      ...ticket,
                      tgId: newTicketGroupId,
                      saleId: null,
                      listingId: null,
                      holdId: null,
                      barcode: null,
                      ticketUrl: null,
                      eTicket: null,
                      qrCode: null,
                      isWasted: null,
                      externalTicketId: null,
                    }))
                  : null;
                                    
                  insert(cell.row.index + 1, {  
                    ...newRow,
                    tickets: posChangedField(newTickets),
                    ticketGroupId: newTicketGroupId,
                  });
                }}
              >
                <PlusIcon size={vars.iconSize.s} fill={vars.color.textBrand} />
              </Button>
              {numRows > 1 && (
                <Button
                  variant="textPlain"
                  style={{ padding: 0 }}
                  onClick={() => {
                    remove(row.index);
                  }}
                >
                  <DeleteIcon
                    size={vars.iconSize.s}
                    fill={vars.color.textBrand}
                  />
                </Button>
              )}
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uiCurrency.sym, control, setValue, register, costColumn, listingNotesMap]
  );

  return columnConfigs;
};
