import { useCallback, useRef, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkChangeEventDialog } from 'src/dialogs/BulkEdits/BulkChangeEventDialog';
import { TicketGroupIntentionDialog } from 'src/dialogs/TicketGroupIntentionDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditTicketGroupClient,
  Event,
  PurchaseOrderQuery,
  TicketGroupEditReason,
} from 'src/WebApiController';

import { LaunchChangeEvent } from './LaunchChangeEvent';

export const useBulkChangeEventAction = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string,
  onSuccess?: () => void,
  showIcon?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = `ticket-group-changeEvent-event-${groupId}`;

  const [changeEventNote, setChangeEventNote] = useState<string | undefined>();
  const deallocateSalesRef = useRef(true);

  const { showErrorDialog } = useErrorBoundaryContext();

  const ticketGroupIntentionDialog = useBasicDialog();
  const {
    openDialog: openBulkChangeEventDialog,
    closeDialog: closeBulkChangeEventDialog,
  } = useDialog(DialogId.BulkChangeEvent, BulkChangeEventDialog);

  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems, setListItemExpansion },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext();
  const onSubmitChangeEvent = useCallback(
    async (
      newSelectedEvent: Event | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);

      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = new BulkEditTicketGroupClient(
              activeAccountWebClientConfig
            );

            const preview =
              await bulkEditClient.bulkChangeEventPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (newSelectedEvent) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditTicketGroupClient(
              activeAccountWebClientConfig
            ).bulkChangeEvent(
              preview!.preview,
              newSelectedEvent.posIds[0],
              newSelectedEvent.viagId,
              newSelectedEvent.mappingId,
              deallocateSalesRef.current,
              changeEventNote ?? '',
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                onSuccess?.();
                await refreshData(true);
                // Metrics/events may have changed with TGs being cancelled, so refresh the whole catalog
                refreshMetrics();
                await refreshCatalog();
                await refreshExpandedListItems();
              }

              closeBulkChangeEventDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitChangeEvent', error, {
              trackErrorData: { preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      changeEventNote,
      closeBulkChangeEventDialog,
      filterQuery,
      onSuccess,
      refreshCatalog,
      refreshData,
      refreshExpandedListItems,
      refreshMetrics,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const onChangeEventClick = useCallback(
    (changeEventNote?: string) => {
      setChangeEventNote(changeEventNote);
      openBulkChangeEventDialog({
        changeEventNote,
        updateKey,
        entityType: ActionOutboxEntityType.TicketGroup,
        onOkay: onSubmitChangeEvent,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkChangeEventDialog,
        doNotUseBackgroundProgress: onSuccess != null,
      });
    },
    [
      closeBulkChangeEventDialog,
      onSubmitChangeEvent,
      onSuccess,
      openBulkChangeEventDialog,
      setIsLoading,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchChangeEvent
        key="ChangeEvent"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          ticketGroupIntentionDialog.launchDialog();
        }}
        disabled={isLoading}
        showIcon={showIcon}
      />
    ),
    dialog: (
      <>
        <TicketGroupIntentionDialog
          {...ticketGroupIntentionDialog.dialogProps}
          intention={{
            ticketGroupId: -1, // negative since this will be applied to ticket groups in bulk
            reason: TicketGroupEditReason.EditEvent,
            additionalInfo: '',
          }}
          onClosed={ticketGroupIntentionDialog.closeDialog}
          onSave={(i, deallocateSales) => {
            deallocateSalesRef.current = deallocateSales == true;
            onChangeEventClick(i.additionalInfo);
            ticketGroupIntentionDialog.closeDialog();
          }}
          saveButtonContentId={ContentId.Save}
        />
      </>
    ),
  };
};
