import { clsx } from 'clsx';
import { useMemo } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack, Tooltip } from 'src/core/ui';
import { UseGetUserInfo, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { ListingLinksDetail } from 'src/modals/SaleDetails/components/ListingLinksDetail';
import { GenericDisclosuresIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { SaleDetails } from 'src/WebApiController';

import * as tableStyles from '../../../SaleDetails/SaleDetailsTable.css';
import * as styles from './SaleTickets.css';

interface AllocatedListingProps {
  sale: SaleDetails;
}

export const AllocatedListing = ({ sale }: AllocatedListingProps) => {
  const { ticketGroupInfos } = sale;

  const canRender = sale.originatedFromListingId;

  const buyerUserIds = useMemo<string[]>(() => {
    if (!canRender) {
      return []; // Don't fetch if it can't render
    }
    return ticketGroupInfos
      .filter((tkgInfo) => !!tkgInfo.buyerUserId)
      .map((tkgInfo) => tkgInfo.buyerUserId) as string[];
  }, [canRender, ticketGroupInfos]);

  const usersInfoQuery = useGetUserInfos(buyerUserIds);

  const usersMap: Record<string, UseGetUserInfo> = usersInfoQuery.data || {};

  if (!canRender) {
    return null;
  }

  return (
    <div>
      <Stack gap="xxl" alignItems="center">
        <span className={styles.tableTitle}>
          <Content id={ContentId.AllocatedListing} />
        </span>
        <ListingLinksDetail listingId={sale.listingId} showTitle={false} />
      </Stack>
      <div className={tableStyles.tableWrapper}>
        <SimpleTable.Table className={tableStyles.table}>
          <SimpleTable.Thead showBottomBorder={false}>
            <SimpleTable.Tr className={tableStyles.tableRow}>
              <SimpleTable.Th
                className={clsx(
                  tableStyles.tableHeader,
                  tableStyles.sectionCell
                )}
              >
                <Content id={ContentId.Section} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.Row} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.Seats} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.QuantityAbbreviated} />
              </SimpleTable.Th>
              <SimpleTable.Th className={tableStyles.tableHeader}>
                <Content id={ContentId.Purchase} />
              </SimpleTable.Th>
              <SimpleTable.Th className={tableStyles.tableHeader}>
                <Content id={ContentId.PurchasedBy} />
              </SimpleTable.Th>
              <SimpleTable.Th className={tableStyles.tableHeader}>
                <Content id={ContentId.Vendor} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.Notes} />
              </SimpleTable.Th>
            </SimpleTable.Tr>
          </SimpleTable.Thead>
          <SimpleTable.Tbody>
            {ticketGroupInfos.map((tkInfo, index) => {
              const { seating } = tkInfo;
              return (
                <SimpleTable.Tr
                  className={tableStyles.tableRow}
                  key={tkInfo.ticketGroupId}
                >
                  <SimpleTable.Td
                    className={clsx(
                      tableStyles.tableCell,
                      tableStyles.sectionCell
                    )}
                  >
                    {seating.section}
                  </SimpleTable.Td>
                  <SimpleTable.Td
                    className={clsx(
                      tableStyles.tableCell,
                      tableStyles.smallCell
                    )}
                  >
                    {seating.row}
                  </SimpleTable.Td>
                  <SimpleTable.Td
                    className={clsx(
                      tableStyles.tableCell,
                      tableStyles.smallCell
                    )}
                  >
                    {seating.seatFr} - {seating.seatTo}
                  </SimpleTable.Td>
                  <SimpleTable.Td
                    className={clsx(
                      tableStyles.tableCell,
                      tableStyles.smallCell
                    )}
                  >
                    {tkInfo.ticketCount}
                  </SimpleTable.Td>
                  <SimpleTable.Td className={tableStyles.tableCell}>
                    <LinkAndCopy
                      relativeUrl={getPurchaseOrderRelativeUrl(
                        tkInfo.purchaseOrderId
                      )}
                      linkChildren={tkInfo.vendorOrderId}
                      valueToCopy={
                        tkInfo.vendorOrderId ?? tkInfo.purchaseOrderId
                      }
                    />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={tableStyles.tableCell}>
                    {tkInfo.buyerUserId && usersMap?.[tkInfo.buyerUserId]?.name}
                  </SimpleTable.Td>
                  <SimpleTable.Td className={tableStyles.tableCell}>
                    <div title={tkInfo.vendor?.vendorName}>
                      {tkInfo.vendor?.vendorName}
                    </div>
                    <div
                      title={tkInfo.vendorAccount?.encryptedEmailAddress}
                      className={styles.vendorAccountEmail}
                    >
                      ({tkInfo.vendorAccount?.encryptedEmailAddress})
                    </div>
                  </SimpleTable.Td>
                  <SimpleTable.Td
                    className={clsx(
                      tableStyles.tableCell,
                      tableStyles.smallCell
                    )}
                  >
                    {tkInfo.internalNotes ? (
                      <Tooltip
                        triggerContent={
                          <div>
                            <GenericDisclosuresIcon
                              fill={vars.color.textStrong}
                            />
                          </div>
                        }
                      >
                        <div className={styles.notesTooltip}>
                          <div className={styles.notesTooltipHeader}>
                            <Content id={ContentId.Notes} />
                          </div>
                          <div className={styles.notesTooltipContent}>
                            {tkInfo.internalNotes}
                          </div>
                        </div>
                      </Tooltip>
                    ) : undefined}
                  </SimpleTable.Td>
                </SimpleTable.Tr>
              );
            })}
          </SimpleTable.Tbody>
        </SimpleTable.Table>
      </div>
    </div>
  );
};
