import clsx from 'clsx';
import { ComponentProps, ReactNode, useMemo } from 'react';
import {
  MultiSelectAll,
  MultiSelectAllProps,
} from 'src/components/common/MultiSelect/MultiSelectAll';
import * as styles from 'src/components/common/MultiSelect/MultiSelectAll.css';
import {
  childrenWrapperMobile,
  groupStyle,
  rootDesktop,
  rootMobile,
} from 'src/components/common/MultiSelect/MultiSelectAll.css';
import { MultiSelectCloseButton } from 'src/components/common/MultiSelect/MultiSelectCloseButton';
import { Content } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';

type MultiSelectContainerProps = {
  children: ReactNode;
  groupId?: string;
  showHideOnSelection?: boolean;
} & Pick<MultiSelectAllProps, 'flattenedIdsOverride' | 'displayMode'> &
  Pick<ComponentProps<'div'>, 'style' | 'className'>;

export const MultiSelectActionBar = ({
  children,
  groupId,
  flattenedIdsOverride,
  className,
  displayMode,
  showHideOnSelection,
  style,
}: MultiSelectContainerProps) => {
  const isMobile = useMatchMedia('mobile');
  const { selectionMode, totalItemsSelected } = useMultiSelectionContext();

  // IMPORTANT - it is vital that you do not return NULL in this component
  // We always need to render the children (bulk-actions) in there so that the bulk-action dialogs will always be there
  // If we do not render this bar - all the current bulk-edit actions will also be removed from the DOM and disappear before
  // they can be finished.
  const isHidden = useMemo(() => {
    if (showHideOnSelection) {
      return !totalItemsSelected;
    }

    let isHidden = false;
    if (!selectionMode) {
      isHidden = true;
    }

    if (groupId) {
      const canShowGroup =
        selectionMode?.groupId === groupId &&
        selectionMode?.mode === MultiSelectScope.SingleGroup;
      if (!canShowGroup) {
        isHidden = true;
      }
    } else {
      if (selectionMode?.mode !== MultiSelectScope.AllGroups) {
        isHidden = true;
      }
    }

    return isHidden;
  }, [groupId, selectionMode, showHideOnSelection, totalItemsSelected]);

  const bulkActionsText = (
    <span className={styles.bulKActionsLabel}>
      <Content id={ContentId.BulkActions} />
    </span>
  );

  if (isHidden) {
    return <div />;
  }

  const multiSelectAll = (
    <MultiSelectAll
      flattenedIdsOverride={flattenedIdsOverride}
      displayMode={displayMode}
    />
  );

  if (isMobile) {
    return (
      <Stack
        id="multiSelectActionBar"
        direction="column"
        gap="m"
        className={clsx(rootMobile)}
      >
        <Stack justifyContent="spaceBetween" alignItems="center">
          {bulkActionsText}
          {!showHideOnSelection && <MultiSelectCloseButton />}
        </Stack>
        <div className={childrenWrapperMobile}>{children}</div>
        {multiSelectAll}
      </Stack>
    );
  }

  return (
    <div
      id="multiSelectActionBar"
      className={clsx(rootDesktop, className)}
      style={style}
    >
      <Stack
        justifyContent="spaceBetween"
        alignItems="center"
        width="full"
        className={groupId ? groupStyle : undefined}
      >
        {multiSelectAll}
        <Stack justifyContent="end" alignItems="center" gap="m" flexWrap="wrap">
          {bulkActionsText}
          {children}
          {!showHideOnSelection && <MultiSelectCloseButton />}
        </Stack>
      </Stack>
    </div>
  );
};
