import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SellerUserClient } from 'src/WebApiController';

export const useGetReleaseNoteHtml = ({
  releaseNoteIds,
  currentLoginUserOnly,
  disabled,
}: {
  releaseNoteIds?: number[];
  currentLoginUserOnly?: boolean;
  disabled?: boolean;
}) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const [releaseNoteHtmlByReleaseNoteId, setReleaseNoteHtmlByReleaseNoteId] =
    useState<Record<number, string>>({});

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    !disabled &&
    !!releaseNoteIds?.length;

  const releaseNoteIdsSorted = releaseNoteIds?.sort((a, b) => a - b);

  const releaseNoteHtmlQuery = useQuery({
    queryKey: [
      'SellerUserClient.getAvailableReleaseNotes',
      activeAccountWebClientConfig.activeAccountId,
      currentLoginUserOnly,
      releaseNoteIdsSorted,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new SellerUserClient(
        activeAccountWebClientConfig
      ).getReleaseNoteHtmlByReleaseNoteIds(
        currentLoginUserOnly ?? false,
        releaseNoteIdsSorted ?? []
      );
    },

    enabled: shouldQuery,
    staleTime: Infinity, // seldom change data and we invalidate on reload
    refetchOnWindowFocus: false,
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError(
          'SellerUserClient.getReleaseNoteHtmlByReleaseNoteIds',
          error,
          { currentLoginUserOnly, releaseNoteIdsSorted }
        );
      },
    },
  });

  useEffect(() => {
    // Accumulate release note html
    if (releaseNoteHtmlQuery.data != null) {
      const alreadyContainsAllReleaseNoteIds = releaseNoteHtmlQuery.data.every(
        (r) => !!releaseNoteHtmlByReleaseNoteId[r.id]
      );

      if (!alreadyContainsAllReleaseNoteIds) {
        setReleaseNoteHtmlByReleaseNoteId((prev) => {
          return {
            ...prev,
            ...releaseNoteHtmlQuery.data!.reduce(
              (acc, curr) => {
                acc[curr.id] = curr.descriptionHtml ?? '';
                return acc;
              },
              {} as Record<number, string>
            ),
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseNoteHtmlQuery.data]);

  return {
    releaseNoteHtmlByReleaseNoteId: releaseNoteHtmlByReleaseNoteId,
    isLoading: releaseNoteHtmlQuery.isLoading,
  };
};
