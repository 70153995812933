import { useMutation } from '@tanstack/react-query';
import { cloneDeep, isEqual } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_PAYMENT_TERM_TO_CID,
  SPLIT_TYPE_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  Feature,
  Marketplace,
  MarketplacePaymentTerm,
  SellerAccountClient,
  SplitType,
  SyncCenterSettings,
} from 'src/WebApiController';

import { MainRoute } from '../../../MainRoute';
import { TicketTypeOverrideInputForm } from './Components/TicketTypeOverrideInputForm';
import * as styles from './SyncCenter.css';
import { SyncCenterFieldValues } from './SyncCenter.types';
import {
  setValueAsNumber,
  toSyncCenterFieldValues,
  toSyncCenterSettings,
} from './SyncCenter.utils';
import { TradeDeskApiResetInstructions } from './TradeDeskApiResetInstructions';

export function SyncCenterContent() {
  const query = useSyncCenterSettings();
  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.SyncCenter} />
        </h1>
        {query.isLoading ? (
          <PosSpinner />
        ) : (
          <SyncCenterForm
            disabled={query.isFetching}
            defaultValues={query.data}
            onSaved={() => query.refetch()}
          />
        )}
      </div>
    </div>
  );
}

export function SyncCenter() {
  const syncCenterTitle = useContent(ContentId.SyncCenter);
  return (
    <LayoutContent
      mainRoute={MainRoute.SyncCenter}
      routeTitle={syncCenterTitle}
    >
      <SyncCenterContent />
    </LayoutContent>
  );
}

function SyncCenterForm({
  disabled,
  defaultValues,
  onSaved,
}: {
  disabled?: boolean;
  defaultValues?: SyncCenterSettings | null;
  onSaved?: () => void;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const contentContext = useContentContext();
  const hasPurchaseSettingsTabFeature = useUserHasFeature(
    Feature.PurchaseSettingsTab
  );
  const hasMarketplaceTermFeature = useUserHasFeature(
    Feature.MarketplacePaymentTerms
  );
  const hasMarketplaceEnableDisableFeature = useUserHasFeature(
    Feature.EnableDisableMarketplaces
  );
  const hasSeatSaverFeature = useUserHasFeature(Feature.SeatSaver);

  const hasMarketplacePreventInitialBroadcastFeature = useUserHasFeature(
    Feature.PreventInitialBroadcastForXDays
  );

  const hasAutoPricingTabFeature = useUserHasAutopricingFeature(
    Feature.AutoPricingTabInAccountSettings
  );

  const mutation = useMutation({
    mutationFn: async (data: SyncCenterSettings) => {
      return new SellerAccountClient(activeAccountWebClientConfig)
        .mergeSyncCenterSettings(data)
        .then(() => onSaved?.());
    },
    onError: (err: ErrorTypes, data) => {
      showErrorDialog('SellerAccountClient.mergeSyncCenterSettings', err, {
        trackErrorData: { data },
      });
    },
  });

  const marketplaceEnableDisableConfirmDlg = useBasicDialog();

  const methods = useForm<SyncCenterFieldValues>({
    defaultValues: defaultValues
      ? toSyncCenterFieldValues(cloneDeep(defaultValues))
      : undefined,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState,
    reset,
  } = methods;

  // If the defaultValues changed - we need to ensure the form is reset - otherwise it will keep the old values
  // and it will re-submit with the old values
  useEffect(() => {
    const newDefaultValues =
      defaultValues && toSyncCenterFieldValues(cloneDeep(defaultValues));
    if (
      newDefaultValues &&
      !isEqual(newDefaultValues, formState.defaultValues)
    ) {
      reset(newDefaultValues);
    }
  }, [defaultValues, formState.defaultValues, reset]);

  const marketplaceSettings = watch('marketplaceSettings');

  const noneTxt = useContent(ContentId.None);
  const getMarketplaceEnableDisable = useCallback(
    (enable: boolean) => {
      const data = getValues('marketplaceSettings');
      const mkps = data?.filter((m) => m.isEnabled === enable);

      return mkps?.length
        ? mkps
            .map((m) => m.marketplace)
            .toSorted()
            .join(', ')
        : noneTxt;
    },
    [getValues, noneTxt]
  );

  return (
    <FormProvider {...methods}>
      <>
        <div>
          <div>
            {marketplaceSettings?.map((settings, i) => {
              return settings.marketplace === Marketplace.StubHub ||
                settings.marketplace === Marketplace.Offline ? null : (
                <div
                  key={settings.marketplace}
                  className={styles.sectionContainer}
                >
                  <div className={styles.sectionName}>
                    {settings.marketplace}
                  </div>
                  <div className={styles.form}>
                    <PosFormField
                      label={<Content id={ContentId.Markup} />}
                      className={styles.textField}
                    >
                      <PosTextField
                        disabled={disabled || mutation.isPending}
                        type="number"
                        postfixDisplay="%"
                        {...register(`marketplaceSettings.${i}.markup`, {
                          setValueAs: setValueAsNumber,
                        })}
                      />
                    </PosFormField>
                    <PosFormField
                      label={<Content id={ContentId.SellerFee} />}
                      className={styles.textField}
                    >
                      <PosTextField
                        disabled={disabled || mutation.isPending}
                        type="number"
                        postfixDisplay="%"
                        {...register(`marketplaceSettings.${i}.sellerFee`, {
                          setValueAs: setValueAsNumber,
                        })}
                      />
                    </PosFormField>
                    <PosFormField
                      label={<Content id={ContentId.MaxHoldDuration} />}
                      className={styles.textField}
                    >
                      <PosTextField
                        disabled={disabled || mutation.isPending}
                        type="number"
                        postfixDisplay={
                          <Content id={ContentId.MaxHoldDurationUnit} />
                        }
                        {...register(
                          `marketplaceSettings.${i}.maxHoldDurationInHours`,
                          {
                            setValueAs: setValueAsNumber,
                          }
                        )}
                      />
                    </PosFormField>

                    {hasMarketplaceTermFeature && (
                      <Controller
                        control={control}
                        name={`marketplaceSettings.${i}.paymentTerm`}
                        render={({ field: { ...field } }) => (
                          <PosFormField
                            label={
                              <Content id={ContentId.MarketplacePaymentTerms} />
                            }
                            className={styles.enumSelect}
                          >
                            <PosEnumSelect<MarketplacePaymentTerm>
                              disabled={disabled || mutation.isPending}
                              valueOptionsContent={
                                MARKETPLACE_PAYMENT_TERM_TO_CID
                              }
                              {...field}
                            />
                          </PosFormField>
                        )}
                      />
                    )}

                    {hasMarketplacePreventInitialBroadcastFeature && (
                      <PosFormField
                        label={
                          <Content
                            id={ContentId.DelayBroadcastAfterInitialCreation}
                          />
                        }
                        className={styles.textField}
                      >
                        <PosTextField
                          disabled={disabled || mutation.isPending}
                          type="number"
                          {...register(
                            `marketplaceSettings.${i}.broadcastHoldDurationInDays`,
                            {
                              setValueAs: setValueAsNumber,
                            }
                          )}
                        />
                      </PosFormField>
                    )}

                    {hasMarketplaceEnableDisableFeature ? (
                      <Controller
                        control={control}
                        name={`marketplaceSettings.${i}.isEnabled`}
                        render={({ field: { value, onChange, ...field } }) => (
                          <Checkbox
                            disabled={disabled || mutation.isPending}
                            labelPosition="right"
                            label={
                              <Content id={ContentId.DisableThisMarketplace} />
                            }
                            checked={!value}
                            onChange={(e) => {
                              onChange(!e.currentTarget.checked);
                            }}
                            {...field}
                          />
                        )}
                      />
                    ) : settings.isEnabled ? (
                      <Stack gap="m" alignItems="center">
                        <CheckIcon fill={IconsFill.textSuccess} />
                        <Content id={ContentId.Enabled} />
                      </Stack>
                    ) : (
                      <Stack gap="m" alignItems="center">
                        <CheckIcon fill={IconsFill.textDisabled} />
                        <Content id={ContentId.Disabled} />
                      </Stack>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionName}>
              <Content id={ContentId.AllMarketplaces} />
            </div>
            <div className={styles.form}>
              <Controller
                control={control}
                name="defaultSplitType"
                render={({ field: { ...field } }) => (
                  <PosFormField
                    label={<Content id={ContentId.DefaultSplitType} />}
                  >
                    <PosEnumSelect<SplitType>
                      disabled={disabled || mutation.isPending}
                      placeholderText={ContentId.SelectSplitType}
                      valueOptionsContent={SPLIT_TYPE_TO_CID}
                      {...field}
                    />
                  </PosFormField>
                )}
              />
              {hasSeatSaverFeature && (
                <Controller
                  control={control}
                  name="defaultSplitTypeForSeatSaver"
                  render={({ field: { ...field } }) => (
                    <PosFormField
                      label={
                        <Content id={ContentId.DefaultSplitTypeForSeatSaver} />
                      }
                    >
                      <PosEnumSelect<SplitType>
                        disabled={disabled || mutation.isPending}
                        placeholderText={ContentId.SelectSplitType}
                        valueOptionsContent={SPLIT_TYPE_TO_CID}
                        {...field}
                      />
                    </PosFormField>
                  )}
                />
              )}
              <Controller
                control={control}
                name="defaultHideSeatNumbers"
                render={({ field: { value, onChange } }) => (
                  <PosFormField
                    label={<Content id={ContentId.DefaultHideSeatNumbers} />}
                  >
                    <PosEnumSelect
                      disabled={disabled || mutation.isPending}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      value={ToYesNoEnum(value)}
                      onChange={(yesNoEnumValue) => {
                        onChange(FromYesNoEnum(yesNoEnumValue));
                      }}
                    />
                  </PosFormField>
                )}
              />
              <PosFormField
                label={<Content id={ContentId.DefaultInHandDateOffset} />}
                className={styles.textField}
              >
                <PosTextField
                  disabled={disabled || mutation.isPending}
                  type="number"
                  postfixDisplay={<Content id={ContentId.DaysPostfix} />}
                  {...register('inHandDatePaddingInDays', {
                    setValueAs: setValueAsNumber,
                  })}
                />
              </PosFormField>
              {!hasAutoPricingTabFeature && (
                <PosFormField
                  label={<Content id={ContentId.PriceFloor} />}
                  className={styles.textField}
                >
                  <PosTextField
                    disabled={disabled || mutation.isPending}
                    type="number"
                    postfixDisplay="%"
                    {...register('priceFloor', {
                      setValueAs: setValueAsNumber,
                    })}
                  />
                </PosFormField>
              )}
              <PosFormField
                label={<Content id={ContentId.ShownQuantity} />}
                className={styles.textField}
              >
                <PosTextField
                  disabled={disabled || mutation.isPending}
                  type="number"
                  {...register('defaultMaxQuantityDisplay', {
                    setValueAs: setValueAsNumber,
                  })}
                />
              </PosFormField>
              <TicketTypeOverrideInputForm
                disabled={disabled || mutation.isPending}
              />
            </div>
          </div>
          {!hasPurchaseSettingsTabFeature && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionName}>
                <Content id={ContentId.Purchase} />
              </div>
              <div className={styles.form}>
                <PosFormField
                  label={<Content id={ContentId.InHandDaysBeforeEvent} />}
                  className={styles.textField}
                >
                  <PosTextField
                    disabled={disabled || mutation.isPending}
                    type="number"
                    {...register('inHandDaysBeforeEvent', {
                      setValueAs: setValueAsNumber,
                    })}
                  />
                </PosFormField>
                <Controller
                  control={control}
                  name="isAutoPoQualityControlled"
                  render={({ field: { value, onChange, ...field } }) => (
                    <Checkbox
                      disabled={disabled || mutation.isPending}
                      labelPosition="right"
                      label={
                        <Content id={ContentId.EnableQualityControlForAutoPo} />
                      }
                      checked={Boolean(value)}
                      onChange={(e) => {
                        onChange(e.currentTarget.checked);
                      }}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          )}
          {marketplaceSettings?.map((settings) => {
            // Only show instructions for ticketmaster accounts where the token version is 1
            // and username exists
            if (
              settings.marketplace !== Marketplace.Ticketmaster ||
              settings.tokenVersion !== 1 ||
              !settings.userName
            ) {
              return null;
            }
            return (
              <div
                key={settings.marketplace}
                className={styles.sectionContainer}
              >
                <div className={styles.sectionName}>
                  <Content id={ContentId.TicketmasterTradeDeskApi} />
                </div>
                <TradeDeskApiResetInstructions
                  marketplaceUserName={settings.userName}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <Button
            disabled={disabled || mutation.isPending}
            onClick={handleSubmit((data) => {
              if (
                !isEqual(
                  data.marketplaceSettings?.map((m) => m.isEnabled),
                  formState.defaultValues?.marketplaceSettings?.map(
                    (m) => m?.isEnabled
                  )
                )
              ) {
                marketplaceEnableDisableConfirmDlg.launchDialog();
              } else {
                mutation.mutate(toSyncCenterSettings(data));
              }
            })}
          >
            <Content id={ContentId.Save} />
          </Button>
        </div>
        <ConfirmDialog
          {...marketplaceEnableDisableConfirmDlg.dialogProps}
          size="m"
          headerText={
            <Content id={ContentId.MarketplaceEnableDisableConfirm} />
          }
          bodyText={
            <Stack direction="column" gap="l">
              <Stack direction="column" gap="s">
                <span>
                  <Content id={ContentId.MarketplaceEnableConfirmMessage} />
                </span>
                <span>
                  <b>{getMarketplaceEnableDisable(true)}</b>
                </span>
              </Stack>
              <Stack direction="column" gap="s">
                <span>
                  <Content id={ContentId.MarketplaceDisableConfirmMessage} />
                </span>
                <span>
                  <b>{getMarketplaceEnableDisable(false)}</b>
                </span>
              </Stack>
              <span>
                <Content id={ContentId.DoYouWantToContinue} />
              </span>
            </Stack>
          }
          onOkay={handleSubmit((data) => {
            mutation.mutate(toSyncCenterSettings(data));
            marketplaceEnableDisableConfirmDlg.closeDialog();
          })}
          onCancel={marketplaceEnableDisableConfirmDlg.closeDialog}
          okText={ContentId.Yes}
        />
      </>
    </FormProvider>
  );
}
