import { useOnSaleEvents } from 'src/hooks/api/useOnSaleEvents';
import {
  EventWithData,
  OnSaleEventQuery,
  Performer,
  Venue,
} from 'src/WebApiController';

export const useInventoryDashboardEvent = (
  event?: EventWithData,
  isOnSaleEvent?: boolean,
  onSaleEventId?: number
): {
  event?: EventWithData;
  venue?: Venue;
  performer?: Performer;
} => {
  const onSaleEventQuery: OnSaleEventQuery = {
    eventIds: onSaleEventId ? [onSaleEventId] : [],
    onSaleDates: null,
    timezoneOffsetMins: null,
    viewMode: null,
    searchText: null,
  };

  const shouldLoadOnSaleEvent = isOnSaleEvent && onSaleEventId;
  const { onSaleEvents } = useOnSaleEvents(
    onSaleEventQuery,
    !shouldLoadOnSaleEvent,
    true
  );

  if (shouldLoadOnSaleEvent) {
    if (onSaleEvents?.events && onSaleEvents.events[onSaleEventId]) {
      const eventWithData = onSaleEvents.events[onSaleEventId];
      const event = eventWithData.event;
      const venue = onSaleEvents.venues[event.venueId];
      const performer = event.perfId
        ? onSaleEvents.performers[event.perfId]
        : undefined;
      return {
        event: eventWithData,
        venue: venue,
        performer: performer,
      };
    }
  }

  return {
    event: event,
    venue: undefined,
    performer: undefined,
  };
};
