import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProviderV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { EmptyReportV2SaleQuery } from 'src/utils/eventQueryUtils';
import { ReportV2Client, UIReportV2SaleQuery } from 'src/WebApiController';

export function ReportsSaleContextProviderV2({
  reportConfig,
  isExport,
  children,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  isExport?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (filters: UIReportV2SaleQuery, pageNumber: number) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportMetrics({
        ...reportConfig.request,
        pageNumber,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  const getReportMetricsSummary = useCallback(
    (filters: UIReportV2SaleQuery) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportSummary({
        ...reportConfig.request,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  return (
    <FilterQueryContextProvider<UIReportV2SaleQuery>
      initialQuery={reportConfig.request.filters as UIReportV2SaleQuery}
      emptyQuery={EmptyReportV2SaleQuery}
      loadQueryFromUrl={false}
    >
      <ReportMetricsContextProviderV2<UIReportV2SaleQuery>
        queryKey="getReportSaleMetricsV2"
        getReportMetrics={getReportMetrics}
        getReportMetricsSummary={getReportMetricsSummary}
        disabled={isExport}
        reportConfig={reportConfig}
      >
        {children}
      </ReportMetricsContextProviderV2>
    </FilterQueryContextProvider>
  );
}
