import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EntitySearchConfigsToolbar,
  EntitySearchRefetcher,
  useEntitySearch,
} from 'src/components/EntitySearch';
import { SalesFlattenedView } from 'src/components/Events/EventListing/SalesEventListing/SalesFlattenedView';
import { SaleSearchBox } from 'src/components/Sales/SaleFilterBar/SaleSearchBox';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import {
  MultiSelectionContextProvider,
  MultiSelectScope,
  NO_GROUP_ID,
} from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { DividerLine } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { DefaultSaleQuery, EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogResults,
  Feature,
  SaleClient,
  SaleDetails,
  SaleQuery,
  SaleSearchResult,
  SalesViewMode,
} from 'src/WebApiController';

import { getDefaultReturnUrl } from '../InventoryEvent/constants';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';
import {
  getCatalogData,
  getCatalogDataExpanded,
  SALE_USER_SETTINGS,
  salesQueryValueTransformFromUrl,
  salesQueryValueTransformToUrl,
} from '../Sales/Sales';
import * as styles from './SaleSearch.css';
import { SaleSearchFilterBar } from './SaleSearchFilterBar';
import { SaleSearchToolbar } from './SaleSearchToolbar';

export function SaleSearch() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SALE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<SaleQuery>
        initialQuery={{
          ...DefaultSaleQuery,
          viewMode: SalesViewMode.FlattenedView,
        }}
        emptyQuery={EmptySaleQuery}
        queryValueTransformToUrl={salesQueryValueTransformToUrl}
        queryValueTransformFromUrl={salesQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <SaleSearchPage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

function SaleSearchPage() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [activeSearchConfig, setActiveSearchConfig] =
    useState<SaleSearchResult>();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], f: SaleQuery) => {
      // Check to see if if is empty filter, query
      if (
        activeSearchConfig == null || // do not start query for items if there is no activeSearchConfig
        (f.searchConfigId && !f.entityIds?.length) // we don't want to query when the filter has the search config id but no entity ids
      ) {
        return Promise.resolve({});
      }

      // If we're querying by ids - we want to get the latest greatest
      // We're only using readonly-db if we're filtering
      const useReadOnlyDb = f.entityIds?.length ? false : true;
      return getCatalogDataExpanded(ids, f, useReadOnlyDb, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('SaleClient.getSalesForEvents', error, {
            ...f,
            eventIds: ids,
          });
        },
      });
    },
    [activeAccountWebClientConfig, activeSearchConfig, trackError]
  );

  const getCatalogDataCallback = useCallback(
    async (c: CatalogClient, f: SaleQuery) => {
      // Check to see if if is empty filter, query
      if (
        f.searchConfigId &&
        !f.entityIds?.length // we don't want to query when the filter has the search config id but no entity ids
      ) {
        // If there is no filter (empty query) then don't try to query for anything
        return {
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
        } as CatalogResults;
      }

      return await getCatalogData(c, f, true);
    },
    []
  );

  return (
    <CatalogDataContextProvider<SaleQuery>
      entityType={ActionOutboxEntityType.Sale}
      queryKey="getCatalogForSaleOrders"
      getCatalogData={getCatalogDataCallback}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
      disableAutoRefresh
      ignoreMaxCount
    >
      <CatalogMultiSelectionContextProvider type={'sale'}>
        <MultiSelectionContextProvider
          isFlattenedView
          forceSelectionMode={{
            mode: MultiSelectScope.AllGroups,
            groupId: NO_GROUP_ID,
          }}
        >
          <SaleSearchContent
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={setActiveSearchConfig}
          />
        </MultiSelectionContextProvider>
      </CatalogMultiSelectionContextProvider>
    </CatalogDataContextProvider>
  );
}

function SaleSearchContent({
  activeSearchConfig,
  setActiveSearchConfig,
}: {
  activeSearchConfig: SaleSearchResult | undefined;
  setActiveSearchConfig: (a: SaleSearchResult | undefined) => void;
}) {
  const searchTitle = useContent(ContentId.SearchSales);
  const hasColumnFilterSearchViewFeature = useUserHasFeature(
    Feature.ColumnFilterSearchView
  );

  const { activeAccountWebClientConfig } = useAppContext();
  const location = useLocation();
  const {
    errorInfo,
    isLoading: isCatalogDataLoading,
    isItemsLoading,
    eventsTransformed,
  } = useCatalogDataContext();

  const {
    selectedFilters,
    setSelectedFilters,
    onSetActiveSearchConfig,
    onSaveSearchResult,
    onDeleteSearchResult,
    onRequeryForTheResult,
    refetchData,
    disabled,
    setDisabled,
  } = useEntitySearch(
    activeSearchConfig,
    setActiveSearchConfig,
    SalesViewMode.FlattenedView,
    (client, id) => client.getSaleSearchResult(id),
    (client, r) => client.saveSaleSearchResult(r)
  );

  // Only use value provided at the first time (should be from '/purchases')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('purchases');

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      SaleDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state]);

  const getActivePosEntity = useCallback(
    async (saleId: number) => {
      const saleDetails = await new SaleClient(
        activeAccountWebClientConfig
      ).getSaleBySaleId(saleId);
      if (saleDetails) {
        return {
          posEntityId: saleDetails.id,
          posEntity: saleDetails,
          event: null,
          posEntityDisplayId: saleDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  const dataIsLoading = isCatalogDataLoading;

  return (
    <ActivePosEntityProvider<SaleDetails>
      entityType={ActionOutboxEntityType.Sale}
      getActivePosEntity={getActivePosEntity}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.SalesSearch}
          routeTitle={searchTitle}
          returnUrl={returnUrl}
          rightContent={
            <EntitySearchRefetcher
              activeSearchConfig={activeSearchConfig}
              disabled={disabled}
              onRequeryForTheResult={onRequeryForTheResult}
            />
          }
        >
          <EntitySearchConfigsToolbar
            disabled={disabled}
            setDisabled={setDisabled}
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={onSetActiveSearchConfig}
            saveActiveSearchConfig={onSaveSearchResult}
            deleteActiveSearchConfig={onDeleteSearchResult}
            getSearchConfigs={(client, includeAllUser) =>
              client.getSaleSearchConfigs(includeAllUser)
            }
            getSearchResult={(client, id) => client.getSaleSearchResult(id)}
            additionalActions={<SaleSearchBox />}
          />
          <DividerLine style={{ margin: 0 }} />
          <SaleSearchFilterBar
            activeSearchConfig={activeSearchConfig}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            onSubmitFilter={(filter) => {
              if (!activeSearchConfig) {
                // Setting an empty search config so it starts to filter
                onSetActiveSearchConfig({
                  id: null,
                  name: null,
                  query: filter,
                  ownerUserId: null,
                  count: null,
                  entityIds: null,
                  lastFetchDate: null,
                });
              }
              refetchData(filter);
            }}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <SaleSearchToolbar
            disabled={disabled}
            activeSearchConfig={activeSearchConfig}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <div className={styles.contentContainer}>
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {(dataIsLoading || isItemsLoading) && !eventsTransformed?.length ? (
              <PosSpinner />
            ) : (
              <SalesFlattenedView
                alwaysShowCheckbox
                showSelectAllOnHeader
                enableColumnFilters={hasColumnFilterSearchViewFeature}
                ignoreMaxCount
              />
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
