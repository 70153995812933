import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowData,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import React from 'react';
import { FieldPath, FieldValues, Path } from 'react-hook-form';

import { PosTableData } from '../Table';
import { useSelectionPos } from './hooks/useSelectionPos';
import { useSpreadsheetCopyPaste } from './hooks/useSpreadsheetCopyPaste';
import { useSpreadsheetSelection } from './hooks/useSpreadsheetSelection';
import { SpreadsheetSelectionBox } from './SpreadsheetSelectionBox';
import * as styles from './SpreadsheetTable.css';
import { SpreadsheetTableHeader } from './SpreadsheetTableHeader';
import { SpreadsheetTd } from './SpreadsheetTd';
import { isCellInRange } from './utils';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    pasteVerificationHandler?: (pastedValue: unknown) => boolean;
  }
  interface TableMeta<TData extends RowData> {
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
    addRow?: (rowIndex: number, row: TData) => void;
    deleteRow?: (rowIndex: number) => void;
  }
}

export const SpreadsheetTable = <T extends PosTableData>({
  options,
  nonInteractiveColumns,
  rowHeight = 40,
  fieldName,
  getFieldState,
  formState,
}: {
  options: Partial<TableOptions<T>>;
  nonInteractiveColumns?: string[];
  rowHeight?: number; // Columns that should not be interactible
  fieldName: Path<FieldValues>;
  getFieldState: any;
  formState: any;
}) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const table: Table<T> = useReactTable({
    ...options,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    sortingFns: {},
  } as TableOptions<T>);

  const rows = table.getRowModel().rows;

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => rowHeight,
    overscan: 10,
  });

  const items = virtualizer.getVirtualItems();

  // Padding for scroll to respect the height of the missing rows
  const [prePadding, afterPadding] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];

  const typedColumns = table.getAllColumns().map((col) => ({
    ...col.columnDef,
    id: col.id as keyof T,
  })) as (ColumnDef<T, unknown> & { id: keyof T })[];

  const {
    selectedCells,
    setSelectedCells,
    isAnchorSelected,
    setIsAnchorSelected,
    setAnchorDragged,
    handleMouseEnter,
    handleMouseDown,
    lastCellPosition,
  } = useSpreadsheetSelection({
    columns: typedColumns,
    nonInteractiveColumns,
  });
  const { isCopyActive, copiedCells } = useSpreadsheetCopyPaste({
    selectedCells,
    setSelectedCells,

    columns: typedColumns,
  });

  const selectionBoxPos = useSelectionPos(
    parentRef,
    selectedCells,
    virtualizer,
    rowHeight
  );
  const copiedBoxPos = useSelectionPos(
    parentRef,
    copiedCells,
    virtualizer,
    rowHeight
  );

  const isSingleCellSelected = useMemo(() => {
    return (
      selectedCells != null &&
      selectedCells.start.rowIndex === selectedCells.end.rowIndex &&
      selectedCells.start.colIndex === selectedCells.end.colIndex
    );
  }, [selectedCells]);

  const footerRow = table.getFooterGroups();
  const numCols = table.getAllColumns().length;
  return (
    <div
      ref={parentRef}
      style={{
        position: 'relative',
        maxHeight: '440px',
        overflowY: 'auto',
      }}
    >
      <table className={clsx(styles.tableBase)}>
        <thead className={styles.tableHeaderRow}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <SpreadsheetTableHeader key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {prePadding > 0 && (
            <tr>
              <td colSpan={numCols} style={{ height: prePadding }} />
            </tr>
          )}
          {items.map((virtualRow, rowIndex) => {
            const row = rows[virtualRow.index];
            return (
              <tr key={row.id} style={{ height: rowHeight }}>
                {row.getVisibleCells().map((cell) => {
                  const columnIndex = cell.column.getIndex();
                  const isInitialSelected =
                    selectedCells?.start?.colIndex === columnIndex &&
                    selectedCells?.start?.rowIndex === cell.row.index;
                  const isCellSelected = isCellInRange(
                    selectedCells,
                    cell.row.index,
                    columnIndex
                  );
                  const isCellCopied = isCellInRange(
                    copiedCells,
                    cell.row.index,
                    columnIndex
                  );
                  const showAnchor =
                    lastCellPosition?.colIndex == columnIndex &&
                    lastCellPosition?.rowIndex === cell.row.index &&
                    !(isCopyActive && isCellCopied);

                  const fieldError = getFieldState(
                    `ticketGroups.${cell.row.index}.${cell.column.id}`,
                    formState
                  );

                  return (
                    <SpreadsheetTd
                      key={cell.id}
                      cell={cell}
                      rowIndex={cell.row.index}
                      handleMouseDown={handleMouseDown}
                      handleMouseEnter={handleMouseEnter}
                      isInitialSelected={isInitialSelected}
                      isCellSelected={isCellSelected}
                      isCellCopied={isCellCopied}
                      isSingleCellSelected={isSingleCellSelected}
                      setAnchorDragged={setAnchorDragged}
                      setIsAnchorSelected={setIsAnchorSelected}
                      showAnchor={showAnchor}
                      fieldError={fieldError}
                    />
                  );
                })}
              </tr>
            );
          })}
          {afterPadding > 0 && (
            <tr>
              <td colSpan={numCols} style={{ height: afterPadding }} />
            </tr>
          )}
        </tbody>
        <tfoot className={styles.tableFooter}>
          {footerRow.map((row) => {
            const tableRow = () => (
              <tr
                key={`footer-${row.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {row.headers.map((cell) => (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.footer,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            );

            return tableRow();
          })}
        </tfoot>
      </table>
      {selectionBoxPos && (
        <SpreadsheetSelectionBox
          classNames={clsx(styles.borderSelected)}
          selectionBoxPos={selectionBoxPos}
          scrollerRef={parentRef}
        />
      )}
      {copiedBoxPos && (
        <SpreadsheetSelectionBox
          classNames={clsx(styles.borderCopied)}
          selectionBoxPos={copiedBoxPos}
          scrollerRef={parentRef}
        />
      )}
    </div>
  );
};
