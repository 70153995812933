import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { useContent } from 'src/contexts/ContentContext/Content';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { SeatIcon } from 'src/svgs/Viagogo';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { SEAT_RANGE_REGEX } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { Ticket, TicketGroupInput } from 'src/WebApiController';

import {
  assignTicketGroupSeats,
  validateSeatRange,
} from './utils/seatRangeUtils';

const extractSeatRange = (
  localValue: string,
  tickets: Ticket[]
): { seatFrom: string | number | null; seatTo: string | number | null } => {
  if (localValue) {
    const seatRangeInput = localValue.match(SEAT_RANGE_REGEX);
    return {
      seatFrom: seatRangeInput?.[1] || null,
      seatTo: seatRangeInput?.[2] || null,
    };
  }
  // Fallback: derive from existing tickets
  return {
    seatFrom: tickets?.[0]?.seat || null,
    seatTo: tickets?.[tickets.length - 1]?.seat || null,
  };
};

const convertSeatRange = (
  seatFrom: string | number | null,
  seatTo: string | number | null
): { seatFrom: number; seatTo: number } => {
  if (seatFrom == null || seatTo == null) {
    return { seatFrom: 0, seatTo: 0 };
  }
  if (!isNaN(Number(seatFrom)) && !isNaN(Number(seatTo))) {
    return { seatFrom: Number(seatFrom), seatTo: Number(seatTo) };
  } else if (typeof seatFrom === 'string' && typeof seatTo === 'string') {
    return {
      seatFrom: seatFrom.toUpperCase().charCodeAt(0),
      seatTo: seatTo.toUpperCase().charCodeAt(0),
    };
  }
  return { seatFrom: 0, seatTo: 0 };
};

function TicketGroupInputSeatRangeComponent({
  row: { index: rowIndex },
  cell,
  control,
  launchSeatRangeDialog,
  style,
}: CellContext<TicketGroupInput, unknown> & {
  control: Control<PurchaseTicketsInput, any>;
  launchSeatRangeDialog: (rowIndex: number) => void;
  style?: React.CSSProperties;
}) {
  const { setValue, getValues, setError, clearErrors, watch } =
    useFormContext<PurchaseTicketsInput>();
  const { fieldState } = useController({
    name: `ticketGroups.${rowIndex}.tickets`,
    control,
    rules: { required: true },
  });

  const qty = useWatch({
    control,
    name: `ticketGroups.${rowIndex}.numberOfTickets.value`,
  });

  const ticketsValue = watch(`ticketGroups.${rowIndex}.tickets.value`);

  const tickets = ticketsValue || [];
  const ticketsHasSeats = tickets?.some((t) => t.seat != '');

  const formattedValue = tickets
    ? ticketsHasSeats && tickets.length >= 1
      ? `${tickets[0].seat || ''} - ${tickets[tickets.length - 1].seat || ''}`
      : ''
    : '';

  const [localValue, setLocalValue] = useState<string>(formattedValue || '');
  const duplicateSeatErrorMsg = useContent(ContentId.DuplicateSeat);

  useEffect(() => {
    if (!qty) return;
    const { seatFrom, seatTo } = extractSeatRange(localValue, tickets);
    assignTicketGroupSeats(
      seatFrom,
      seatTo,
      qty,
      tickets,
      setValue,
      setError,
      clearErrors,
      fieldState,
      rowIndex,
      duplicateSeatErrorMsg
    );
  }, [qty]);

  useEffect(() => {
    if (localValue === formattedValue) return;
    setLocalValue(formattedValue || '');
  }, [formattedValue]);

  useEffect(() => {
    // Only run updateSeatRange when tickets are updated externally
    if (ticketsHasSeats) {
      const { seatFrom: rawSeatFrom, seatTo: rawSeatTo } = extractSeatRange(
        localValue,
        tickets
      );
      const { seatFrom, seatTo } = convertSeatRange(rawSeatFrom, rawSeatTo);
      validateSeatRange(
        seatFrom,
        seatTo,
        tickets,
        qty || 0,
        rowIndex,
        setError,
        clearErrors,
        fieldState,
        duplicateSeatErrorMsg
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, ticketsHasSeats]);

  const SeatRangeModalButton = useMemo(() => {
    return (
      <IconButton
        style={{ padding: 0 }}
        icon={<SeatIcon size={vars.iconSize.s} />}
        onClick={() => {
          launchSeatRangeDialog(rowIndex);
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowIndex]);

  return (
    <PosTextField
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value); // Only update local state
      }}
      onBlur={(e) => {
        const quantity = getValues(
          `ticketGroups.${cell.row.index}.numberOfTickets.value`
        );
        const tickets = getValues(
          `ticketGroups.${cell.row.index}.tickets.value`
        );

        const seatRangeInput = e.target.value.match(SEAT_RANGE_REGEX);
        const seatFrom = seatRangeInput?.[1] || null;
        const seatTo = seatRangeInput?.[2] || null;

        assignTicketGroupSeats(
          seatFrom,
          seatTo,
          quantity,
          tickets,
          setValue,
          setError,
          clearErrors,
          fieldState,
          rowIndex,
          duplicateSeatErrorMsg
        );
      }}
      rootProps={{
        className: clsx(
          styles.tableCell[localValue != null ? 'active' : 'disabled']
        ),
        style: style,
      }}
      postfixDisplay={SeatRangeModalButton}
    />
  );
}

export const TicketGroupInputSeatRange = React.memo(
  TicketGroupInputSeatRangeComponent
) as (
  props: CellContext<TicketGroupInput, unknown> & {
    control: Control<PurchaseTicketsInput, any>;
    launchSeatRangeDialog: (rowIndex: number) => void;
    style?: React.CSSProperties;
  }
) => JSX.Element;
