import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { TooltipPopover } from 'src/core/ui';
import { ColumnFilterButton } from 'src/core/ui/ColumnFilterButton';
import { ContentId } from 'src/utils/constants/contentId';

import { useColumnFilter } from '../hooks/useColumnFilter';
import { TableHeaderProps } from './ColumnHeader.types';
import { ColumnRangeFilterInput } from './ColumnRangeFilterInput';

export const ColumnHeaderFilter = ({ table, header }: TableHeaderProps) => {
  const {
    isColumnFilterActive,
    columnFilterOptionsContent,
    canMultiSelect,
    filterValue,
    setColumnFilterValue,
  } = useColumnFilter(table, header);

  return (
    <>
      {canMultiSelect ? (
        <PosMultiSelect
          values={(filterValue ?? []) as string[]}
          onChange={(values) => setColumnFilterValue(values)}
          onClick={(e) => e.stopPropagation()}
          enableEmptySelection
          valueOptionsContent={columnFilterOptionsContent}
          showSelectedOptionsFirst
          childrenAsTrigger
          placeholderText={ContentId.All}
          searchable={Object.keys(columnFilterOptionsContent).length > 10}
        >
          <ColumnFilterButton isActive={isColumnFilterActive} />
        </PosMultiSelect>
      ) : (
        <TooltipPopover
          triggerContent={
            <ColumnFilterButton isActive={isColumnFilterActive} />
          }
        >
          <ColumnRangeFilterInput
            filterValue={filterValue as [number, number]}
            setFilterValue={setColumnFilterValue}
          />
        </TooltipPopover>
      )}
    </>
  );
};
