import {
  TextFilter,
  TextFilterQuery,
} from 'src/components/Filters/TextFilter/TextFilter';

export const SectionFilter = <TQuery extends TextFilterQuery>({
  query,
  setQuery,
  isHorizontalLayout,
}: {
  query: TQuery;
  setQuery: (q: TQuery) => void;
  isHorizontalLayout?: boolean;
}) => {
  return (
    <TextFilter
      query={query}
      setQuery={setQuery}
      keys={{
        textContainsKey: 'sectionContains',
        textNotContainsKey: 'sectionNotContains',
        textEqualsKey: 'sectionEquals',
      }}
      isHorizontalLayout={isHorizontalLayout}
    />
  );
};
