import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { DndGlobalFileDrop } from 'src/core/POS/DnDFileUploader/components/DndGlobalFileDrop';
import { DndFilerUploaderContextProvider } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventListingConstraints } from 'src/hooks/useGetEventListingConstraints';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { EventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { PurchaseTicketFlowTitle } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/PurchaseTicketFlowTitle';
import { predeliverETicketsDnd } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/PurchaseTicketFlow.css';
import { ContentId } from 'src/utils/constants/contentId';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import {
  PurchaseTicketsInput,
  validateEventSelection,
  validateTicketGroups,
} from 'src/utils/purchaseUtils';
import { selectEventInfoFromTicketGroups } from 'src/utils/ticketUtils';
import {
  Feature,
  ListingActionType,
  TicketGroupEditReason,
  TicketGroupInput,
} from 'src/WebApiController';

import {
  PurchaseWizardFooter,
  PurchaseWizardFooterProps,
} from '../../common/Purchase/PurchaseWizardFooter';
import { ModalBody } from '../../Modal';
import PurchaseTicketGroupPage from '../PurchaseTicketsFlowV2/PurchaseTicketGroupPage';
import { TicketGroupEditContext } from '../PurchaseWizard.types';
import { PredeliverySection } from './components/PredeliverySection';
import { UploadPredeliveryAction } from './purchaseTicketsFlow.utils';
import { SearchPurchasedEvents } from './SearchPurchasedEvents';
import { PurchaseOrderInfoForTicketGroupInput } from './TicketGroupInput.types';

export type PurchaseTicketsFlowProps = {
  currencyCode: string;
  purchaseOrderInfo?: PurchaseOrderInfoForTicketGroupInput | null;
  ticketGroups: TicketGroupInput[];
  ticketGroupFromPartialPurchaseOrder?: TicketGroupInput | null;
  eventSearchTextFromPartialPurchaseOrder?: string | null;
  updateSalesOfListings?: boolean | null;
  startIndex: number;
  avoidPrefill?: boolean;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: (formData: PurchaseTicketsInput) => void;
  emailPreview?: JSX.Element | null;
  uploadPredeliveryAction?: UploadPredeliveryAction | undefined;
  ticketGroupEditContext: TicketGroupEditContext;
};

/**
 * Checks if there are multiple unique event IDs in the ticket groups array.
 * @param ticketGroups - Array of ticket group objects.
 * @returns True if more than one unique eventId is found, otherwise false.
 */
export function hasMultipleEventIds(ticketGroups: TicketGroupInput[]): boolean {
  let firstEventId: number | string | undefined;

  for (const tg of ticketGroups) {
    const eventId = tg.event?.viagId;
    if (eventId) {
      if (firstEventId === undefined) {
        firstEventId = eventId;
      } else if (firstEventId !== eventId) {
        return true;
      }
    }
  }

  return false;
}

export const PurchaseMultiTicketsFlow = ({
  ticketGroups,
  avoidPrefill,
  ticketGroupFromPartialPurchaseOrder,
  eventSearchTextFromPartialPurchaseOrder,
  updateSalesOfListings,
  ...rest
}: PurchaseTicketsFlowProps) => {
  const methods = useForm<PurchaseTicketsInput>({
    defaultValues: {
      ticketGroups: avoidPrefill
        ? selectEventInfoFromTicketGroups(ticketGroups)
        : ticketGroups.map((tg) => ({
            ...tg,
            tickets: posField(tg.tickets?.value?.sort((a, b) => a.ord - b.ord)),
          })),
      updateSalesOfListings,
      updatePredeliveryETicketArtifacts: false,
      ticketGroupFromPartialPurchaseOrder,
      eventSearchTextFromPartialPurchaseOrder,
    },
  });

  return (
    <FormProvider {...methods}>
      <PurchaseMultiTicketsFlowBody {...methods} {...rest} />
    </FormProvider>
  );
};

const EMPTY_EVENT_IDS: number[] = [];

function PurchaseMultiTicketsFlowBody({
  disabled,
  currencyCode,
  purchaseOrderInfo,
  startIndex,
  onCancel,
  onSubmit,
  formState,
  handleSubmit,
  setError,
  getValues,
  setValue,
  clearErrors,
  watch,
  emailPreview,
  uploadPredeliveryAction,
  ticketGroupEditContext,
}: Omit<PurchaseTicketsFlowProps, 'ticketGroups' | 'updateSalesOfListings'> &
  Omit<
    ComponentProps<typeof FormProvider<PurchaseTicketsInput, unknown>>,
    'children'
  >) {
  const formTicketGroups = getValues('ticketGroups');

  const daysBeforeEvent = watch('daysBeforeEvent');

  const requiredMsg = useContent(ContentId.Required);
  const duplicateSeatMsg = useContent(ContentId.DuplicateSeat);
  const eventRequiredMsg = useContent(ContentId.SearchAndSelectAnEvent);
  const inHandDateMustBeforeEventDateMsg = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );
  const autoBroadcastRequireFaceValueMsg = useContent(
    ContentId.AutoBroadcastRequiredFaceValue
  );

  const hasUploadTicketFilesFlowV2 = useUserHasFeature(
    Feature.UploadTicketFilesFlowV2
  );

  const multiEventInputsDialog = useBasicDialog();
  const [singleInputModeForMultiEvent, setSingleInputModeForMultiEvent] =
    useState(true);

  const isMultiEvent = hasMultipleEventIds(formTicketGroups);

  const isSingleInputModeForMultiEvent =
    singleInputModeForMultiEvent && isMultiEvent;

  // Index of a Group of TicketGroupInput
  const [curGroupIndex, setCurGroupIndex] = useState<number>(startIndex);

  const groupsOfTicketGroup = useMemo<TicketGroupInput[][]>(() => {
    const noVenueCfgIdGroup = 'no_venue_cfg_id';

    if (isSingleInputModeForMultiEvent) {
      const ticketGroupsMap = new Map<number | string, TicketGroupInput[]>();
      formTicketGroups.forEach((ticketGroup) => {
        const venueCfgId = ticketGroup?.event?.venueCfgId || noVenueCfgIdGroup;
        const group = ticketGroupsMap.get(venueCfgId) || [];
        group.push(ticketGroup);
        ticketGroupsMap.set(venueCfgId, group);
      });

      const noCfgId = ticketGroupsMap.get(noVenueCfgIdGroup);
      ticketGroupsMap.delete(noVenueCfgIdGroup);

      const groupOfTicketGroups = Array.from(ticketGroupsMap.values());
      if (noCfgId && noCfgId.length > 0) {
        noCfgId.forEach((ticketGroup) => {
          groupOfTicketGroups.push([ticketGroup]);
        });
      }

      return groupOfTicketGroups;
    }

    if (isMultiEvent) {
      const noViagIdGroup = 'no_viag_id';
      const ticketGroupsMap = new Map<number | string, TicketGroupInput[]>();
      formTicketGroups.forEach((ticketGroup) => {
        const viagId = ticketGroup?.event?.viagId || noViagIdGroup;
        const group = ticketGroupsMap.get(viagId) || [];
        group.push(ticketGroup);
        ticketGroupsMap.set(viagId, group);
      });

      const noViagId = ticketGroupsMap.get(noViagIdGroup);
      ticketGroupsMap.delete(noViagIdGroup);

      const groupOfTicketGroups = Array.from(ticketGroupsMap.values());
      if (noViagId && noViagId.length > 0) {
        noViagId.forEach((ticketGroup) => {
          groupOfTicketGroups.push([ticketGroup]);
        });
      }
      return groupOfTicketGroups;
    }

    if (
      (ticketGroupEditContext.editIntention ===
        TicketGroupEditReason.AddTickets &&
        !ticketGroupEditContext.startWithEventSearch) ||
      ticketGroupEditContext.editIntention ===
        TicketGroupEditReason.AmendTickets
    ) {
      return [formTicketGroups];
    }

    return formTicketGroups.map((ticketGroup) => [ticketGroup]);
  }, [
    formTicketGroups,
    isMultiEvent,
    isSingleInputModeForMultiEvent,
    ticketGroupEditContext,
  ]);

  const [showPredeliverySection, setShowPredeliverySection] = useState<
    UploadPredeliveryAction | undefined
  >(uploadPredeliveryAction);

  const curTicketGroups = useMemo(() => {
    return curGroupIndex >= 0
      ? // Pick first ticketGroup because:
        // If inputMode is grouping: all the items in the group will have the same config
        // If input mode is one by one: Each ticketGroup is a group of one item.
        groupsOfTicketGroup[curGroupIndex]
      : groupsOfTicketGroup.flatMap((group) => group);
  }, [groupsOfTicketGroup, curGroupIndex]);

  const showSearch = curGroupIndex < 0 || !curTicketGroups;

  const ticketGroupsEventIds = useMemo(
    () =>
      formTicketGroups
        .filter((tg) => tg.event?.viagId != null)
        .map((tg) => tg.event?.viagId!),
    [formTicketGroups]
  );
  const { eventListingConstraintsQuery } = useGetEventListingConstraints(
    showSearch ? EMPTY_EVENT_IDS : ticketGroupsEventIds
  );

  const currentFormTicketGroupIndex = useMemo(() => {
    return formTicketGroups.findIndex(
      (tg) => tg.ticketGroupId === formTicketGroups[0]?.ticketGroupId
    );
  }, [formTicketGroups, curTicketGroups]);

  const onSubmitWrapper = useCallback(() => {
    const newTgs = getValues('ticketGroups');
    if (
      validateEventSelection(clearErrors, setError, newTgs, eventRequiredMsg) &&
      validateTicketGroups(
        true,
        clearErrors,
        setError,
        newTgs,
        requiredMsg,
        duplicateSeatMsg,
        inHandDateMustBeforeEventDateMsg,
        autoBroadcastRequireFaceValueMsg
      )
    ) {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    clearErrors,
    setError,
    eventRequiredMsg,
    requiredMsg,
    duplicateSeatMsg,
    inHandDateMustBeforeEventDateMsg,
    autoBroadcastRequireFaceValueMsg,
    handleSubmit,
    onSubmit,
  ]);

  const onPrevFromSearchEventsStep = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onNextFromSearchEventsStep = useCallback(() => {
    if (
      validateEventSelection(
        clearErrors,
        setError,
        formTicketGroups,
        eventRequiredMsg
      )
    ) {
      const hasGroupedEvents = groupsOfTicketGroup.some(
        (group) => group.length > 1
      );
      if (hasGroupedEvents) {
        // If we have more than one event selected, we need to ask whether the user
        // wants to input tickets for each of them separately for together
        multiEventInputsDialog.launchDialog();
      } else {
        setSingleInputModeForMultiEvent(false);
        setCurGroupIndex(0);
      }
    }
  }, [
    clearErrors,
    setError,
    formTicketGroups,
    eventRequiredMsg,
    groupsOfTicketGroup,
    multiEventInputsDialog,
  ]);

  const onPrevFromTicketGroupInputStep = useCallback(() => {
    if (showPredeliverySection) {
      setShowPredeliverySection(undefined);
    } else if (curGroupIndex === startIndex) {
      onCancel();
    } else {
      setCurGroupIndex(curGroupIndex - 1);

      // Generate groups again to ask for confirmation for single input
      setSingleInputModeForMultiEvent(true);
    }
  }, [curGroupIndex, onCancel, showPredeliverySection, startIndex]);

  const onNextFromTicketGroupInputStep = useCallback(
    (tgs: TicketGroupInput[]) => {
      const formTicketGroups = getValues('ticketGroups');
      const isLastGroup = curGroupIndex === groupsOfTicketGroup.length - 1;

      // Input mode: One input of ticket groups for each venue config
      if (isSingleInputModeForMultiEvent) {
        const updatedTicketGroups: TicketGroupInput[] = [];

        // Copy the content of the previous ticket-Group to all the ticketGroups and submit
        for (let i = 0; i < curTicketGroups.length; i++) {
          const currTg = curTicketGroups[i];
          if (!currTg.inHandDate?.value && currTg.event?.dates?.start) {
            currTg.inHandDate = posChangedField(
              getInHandDateBasedOnEvent(currTg.event, daysBeforeEvent)
            );
          }

          const newTgsForEvent = tgs.map((tg, newTgIndex) => {
            const newTg: TicketGroupInput = {
              ...tg,
              ticketGroupId: currTg.ticketGroupId - newTgIndex,
              viagogoEventId: currTg.event?.viagId || null,
              viagogoVirtualId: currTg.event?.viagVirtualId || '',
              eventMappingId: currTg.event?.mappingId || null,
              posEventId: currTg.event?.posIds?.[0] || null,
              event: currTg.event,
              performer: currTg.performer,
              venue: currTg.venue,
              inHandDate: currTg.inHandDate,
            };

            return newTg;
          });

          updatedTicketGroups.push(...newTgsForEvent);
        }

        const finalTgs = [
          ...new Map(
            [...formTicketGroups, ...updatedTicketGroups].map((tg) => [
              tg.ticketGroupId,
              tg,
            ])
          ).values(),
        ];

        setValue('ticketGroups', [...finalTgs]);

        // Input mode: One input for each event for multi event
      } else if (isMultiEvent) {
        const formTicketGroups = getValues('ticketGroups');
        const notUpdatedTgs = formTicketGroups.filter(
          (tg) => tg.viagogoVirtualId !== curTicketGroups[0].viagogoVirtualId
        );
        const updatedTicketGroups = [
          ...new Map(
            [...tgs, ...notUpdatedTgs].map((tg) => [tg.ticketGroupId, tg])
          ).values(),
        ];

        setValue('ticketGroups', updatedTicketGroups);
        // Input mode: Single event
      } else {
        const updatedTicketGroups = tgs;
        setValue('ticketGroups', updatedTicketGroups);
      }
      if (isLastGroup) {
        onSubmitWrapper();
      } else {
        // Just increment the group, since each ticketGroup
        // represents a ticketGroup[] of one element.
        setCurGroupIndex(curGroupIndex + 1);
      }
    },
    [
      autoBroadcastRequireFaceValueMsg,
      clearErrors,
      curGroupIndex,
      curTicketGroups,
      daysBeforeEvent,
      duplicateSeatMsg,
      getValues,
      groupsOfTicketGroup.length,
      inHandDateMustBeforeEventDateMsg,
      isSingleInputModeForMultiEvent,
      onSubmitWrapper,
      requiredMsg,
      setError,
      setValue,
    ]
  );

  const getFooterActions = useCallback((): PurchaseWizardFooterProps => {
    return {
      hasChanges:
        !showPredeliverySection &&
        !isEqual(formState.defaultValues?.ticketGroups, formTicketGroups),
      onPreviousLabel: ContentId.Cancel,
      onPrevious: onPrevFromSearchEventsStep,
      onNextLabel: ContentId.Next,
      onNext: onNextFromSearchEventsStep,
    };
  }, [
    formState.defaultValues?.ticketGroups,
    onNextFromSearchEventsStep,
    onPrevFromSearchEventsStep,
    showPredeliverySection,
    formTicketGroups,
  ]);

  const finalTicketGroups = useMemo(
    () =>
      isSingleInputModeForMultiEvent ? [curTicketGroups[0]] : curTicketGroups,
    [curTicketGroups, isSingleInputModeForMultiEvent]
  );

  const footerActions = getFooterActions();
  const isPredeliverETicketsAction =
    showPredeliverySection === ListingActionType.PredeliverETickets;

  return (
    <>
      <CancellableFormHeader
        disabled={formState.isSubmitting}
        showDialogOnCancel={
          !showPredeliverySection &&
          !isEqual(formState.defaultValues, formTicketGroups)
        }
        onClose={
          showPredeliverySection
            ? () => setShowPredeliverySection(undefined)
            : onCancel
        }
      >
        <EventEntityHeader
          title={
            <PurchaseTicketFlowTitle
              showSearch={showSearch}
              isSingleInputModeForMultiEvent={isSingleInputModeForMultiEvent}
              currentGroupIndex={curGroupIndex}
              currentGroupTicketsCount={
                groupsOfTicketGroup[curGroupIndex]?.length
              }
            />
          }
        />
      </CancellableFormHeader>
      <DndFilerUploaderContextProvider>
        <DndGlobalFileDrop
          className={
            hasUploadTicketFilesFlowV2 && isPredeliverETicketsAction
              ? predeliverETicketsDnd
              : undefined
          }
        >
          <Stack
            direction="row"
            style={{ height: '100%', width: '100%', overflowX: 'auto' }}
          >
            {emailPreview}

            <ModalBody>
              {showSearch ? (
                <SearchPurchasedEvents currencyCode={currencyCode} />
              ) : showPredeliverySection ? (
                <ModalBodyDataContainer>
                  <PredeliverySection
                    action={showPredeliverySection}
                    ticketGroupIndex={currentFormTicketGroupIndex}
                    onCancel={() => setShowPredeliverySection(undefined)}
                  />
                </ModalBodyDataContainer>
              ) : (
                <PurchaseTicketGroupPage
                  ticketGroups={finalTicketGroups}
                  isSingleInputModeForMultiEvent={
                    isSingleInputModeForMultiEvent
                  }
                  listingConstraints={
                    finalTicketGroups[0].event?.viagId
                      ? eventListingConstraintsQuery.data?.[
                          finalTicketGroups[0].event?.viagId
                        ]
                      : undefined
                  }
                  purchaseOrderInfo={purchaseOrderInfo}
                  startIndex={startIndex}
                  onSubmitWrapper={onSubmitWrapper}
                  onNextFromTicketGroupInputStep={
                    onNextFromTicketGroupInputStep
                  }
                  currentGroupIndex={curGroupIndex}
                  currentGroupTicketsCount={
                    groupsOfTicketGroup[curGroupIndex]?.length
                  }
                  onPrevFromTicketGroupInputStep={
                    onPrevFromTicketGroupInputStep
                  }
                  showPredeliverySection={showPredeliverySection || false}
                />
              )}
            </ModalBody>
          </Stack>
          {!showPredeliverySection && showSearch && (
            <PurchaseWizardFooter
              {...footerActions}
              isSubmitting={formState.isSubmitting}
            />
          )}
        </DndGlobalFileDrop>
      </DndFilerUploaderContextProvider>
      <ConfirmDialog
        {...multiEventInputsDialog.dialogProps}
        size="m"
        cancelText={ContentId.No}
        okText={ContentId.Yes}
        headerText={
          <Content id={ContentId.SameEventsShareSameVenueConfigTitle} />
        }
        bodyText={
          <Stack direction="column" gap="l">
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigHeader} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage1} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage2} />
            </span>
          </Stack>
        }
        onCancel={() => {
          setCurGroupIndex(0);
          setSingleInputModeForMultiEvent(false);
          multiEventInputsDialog.closeDialog();
        }}
        onOkay={() => {
          setCurGroupIndex(0);
          setSingleInputModeForMultiEvent(true);
          multiEventInputsDialog.closeDialog();
        }}
      />
    </>
  );
}
