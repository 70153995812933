import { useContext } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import {
  createCatalogMetricsContextV1,
  createCatalogMetricsContextV2,
} from './CatalogMetrics.type';

export function useCatalogMetricsContext<
  TMetrics extends { currency: string | null },
>() {
  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  return useContext(
    hasMetricsV2Feature
      ? createCatalogMetricsContextV2<TMetrics>()
      : createCatalogMetricsContextV1<TMetrics>()
  );
}
