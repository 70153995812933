import { useCallback, useMemo } from 'react';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { SectionType } from 'src/utils/types/sectionType';
import { PurchaseViewMode } from 'src/WebApiController';

import { ColumnItem } from '../ColumnsSectionV2';

type ColumnGroupConfig = {
  id: string;
  columns: string[];
} | null;

function adaptColumnToGroups(
  columns: string[],
  ticketGroupColumns: ColumnGroupConfig
): ColumnItem[] {
  if (!ticketGroupColumns) {
    return columns.map((column) => ({ id: column, children: [] }));
  }

  const adaptedColumns: ColumnItem[] = [];
  const ticketGroup: ColumnItem = {
    id: ticketGroupColumns.id || '',
    children: [],
  };
  let firstTicketGroupColumnIndex = -1;
  columns.forEach((column, index) => {
    if (ticketGroupColumns.columns.includes(column)) {
      if (firstTicketGroupColumnIndex === -1) {
        firstTicketGroupColumnIndex = index;
      }
      ticketGroup.children?.push(column);
    } else {
      adaptedColumns.push({ id: column, children: [] });
    }
  });

  if (
    ticketGroup.id &&
    ticketGroup.children &&
    ticketGroup.children.length > 0
  ) {
    adaptedColumns.splice(firstTicketGroupColumnIndex, 0, ticketGroup);
  }

  return adaptedColumns;
}

/**
 * Hook to adapt columns to groups
 * @param columns
 * @param sectionType
 * @param viewMode Only supported for Purchase for now, used for filtering visible columns
 * @returns
 * - adaptedColumns: columns adapted to groups
 * - flattenColumns: flatten columns
 * - columnGroup: column group config
 **/
export const useAdaptedColumns = ({
  columns,
  sectionType,
  viewMode,
}: {
  columns: string[];
  sectionType: SectionType;
  viewMode?: string | null;
}) => {
  const COLUMN_GROUPS = useMemo(
    () => ({
      [SectionType.Listings]: null,
      [SectionType.Sales]: null,
      [SectionType.ListingsReport]: null,
      [SectionType.SalesReport]: null,
      [SectionType.Events]: null,
      [SectionType.ListingsFlattened]: null,
      [SectionType.SalesFlattened]: null,
      [SectionType.SalesSideTable]: null,
      [SectionType.InventorySideTable]: null,
      [SectionType.PurchaseSideTable]: null,
      [SectionType.Purchases]:
        viewMode === PurchaseViewMode.TileView
          ? {
              id: 'ticketGroup',
              columns: [
                PurchasesTableColumnId.Event,
                PurchasesTableColumnId.Venue,
                PurchasesTableColumnId.EventDate,
                PurchasesTableColumnId.Seating,
                PurchasesTableColumnId.Row,
                PurchasesTableColumnId.Seats,
                PurchasesTableColumnId.SeatTraits,
              ],
            }
          : null,
      [SectionType.PurchaseEvent]: null,
      [SectionType.MarketplacePaymentsTable]: null,
      [SectionType.OnSaleEvents]: null,
    }),
    [viewMode]
  );

  const adaptedColumns = useMemo(() => {
    return adaptColumnToGroups(columns, COLUMN_GROUPS[sectionType]);
  }, [columns, sectionType, COLUMN_GROUPS]);

  const flattenColumns = useCallback((columns: ColumnItem[]): string[] => {
    return columns.flatMap((column) =>
      column.children && column.children.length > 0
        ? column.children
        : column.id
    );
  }, []);

  return {
    adaptedColumns,
    flattenColumns,
    columnGroup: COLUMN_GROUPS[sectionType],
  };
};
