import { useQueries, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQuery } from 'src/utils/getServerSideOnlyMetricsQuery';
import {
  CatalogClient,
  EntityWithTicketsQuery,
  Feature,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import {
  CatalogMetricsContextProviderProps,
  createCatalogMetricsContextV1,
  ICatalogMetricsContext,
} from './CatalogMetrics.type';
import { getEventsToGetMetricsFor } from './CatalogMetrics.utils';
import { CatalogMetricsContextV2Provider } from './CatalogMetricsContextV2';

function CatalogMetricsContextV1Provider<
  TMetrics extends { currency: string | null },
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  entityType,
  getCatalogMetrics,
  addCatalogMetrics,
  children,
  disabled,
}: CatalogMetricsContextProviderProps<TMetrics, TQuery>) {
  const CatalogMetricsContext = createCatalogMetricsContextV1<TMetrics>();

  const { trackError } = useErrorBoundaryContext();

  const { data } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();

  const hasCatalogV2Feature = useUserHasFeature(Feature.CatalogCacheStrategyV2);

  const groupsToGetMetricsFor = useMemo(() => {
    if (!data?.events) {
      return [];
    }
    return getEventsToGetMetricsFor(
      Object.values(data.events),
      entityType,
      filterQuery.sortBy,
      filterQuery.isSortDescending,
      hasCatalogV2Feature
    );
  }, [
    data?.events,
    entityType,
    filterQuery.sortBy,
    filterQuery.isSortDescending,
    hasCatalogV2Feature,
  ]);

  const queries = useMemo(() => {
    // Removing the client-side queries
    const transformedQuery = getServerSideOnlyMetricsQuery(
      filterQuery
    ) as TQuery;
    return {
      queries: groupsToGetMetricsFor.map((idGroup) => {
        const shouldQuery =
          !disabled &&
          !(
            !idGroup.length ||
            !transformedQuery ||
            !activeAccountWebClientConfig.activeAccountId ||
            !getCatalogMetrics
          );

        return {
          queryKey: [
            queryKey,
            transformedQuery,
            idGroup,
            activeAccountWebClientConfig.activeAccountId,
          ],
          queryFn: async () => {
            if (!shouldQuery) {
              return null;
            }

            return await getCatalogMetrics!(
              new CatalogClient(activeAccountWebClientConfig),
              {
                ...transformedQuery,
                eventOrMappingIds: idGroup.map((ev) => ev.viagVirtualId),
                performerIds: idGroup
                  .filter((ev) => ev.performerId != null)
                  .map((ev) => ev.performerId!),
                venueIds: idGroup.map((ev) => ev.venueId),
              }
            );
          },
          enabled: shouldQuery,
          staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
          refetchOnWindowFocus: false,
          onError: (error: ErrorTypes) => {
            trackError('CatalogClient.getCatalogMetrics', error, {
              ...transformedQuery,
              eventIds: idGroup,
            });
            // Since this is just loading metrics, we have the data so we'll just ignore that metrics error
          },
          refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
        };
      }),
    };
  }, [
    activeAccountWebClientConfig,
    disabled,
    getCatalogMetrics,
    groupsToGetMetricsFor,
    queryKey,
    trackError,
    filterQuery,
  ]);

  const metricsQueriesFromHook = useQueries(queries);
  const [metricsQueries, setMetricsQueries] = useState(metricsQueriesFromHook);

  /**
   * IMPORTANT: This is needed because event that useQueries receives a memoized object,
   * on every render it returns a new array with metrics, causing the app
   * to re-render.
   */
  useEffect(() => {
    if (!isEqual(metricsQueries, metricsQueriesFromHook)) {
      setMetricsQueries(metricsQueriesFromHook);
      return;
    }
  }, [metricsQueriesFromHook, metricsQueries]);

  const refreshMetrics = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
    metricsQueries.forEach((q) => q.refetch());
  }, [metricsQueries, queryClient, queryKey]);

  const getMergedMetricsRecord = useCallback(
    (metricsRecords: (Record<string, TMetrics> | undefined)[]) => {
      const entries = metricsRecords.filter((m) => m != null) as Record<
        string,
        TMetrics
      >[];
      if (!entries.length) {
        return undefined;
      }

      return entries.reduce(
        (obj, m) => {
          Object.entries(m).forEach(([key, value]) => {
            if (addCatalogMetrics == null) {
              obj[key] = value;
            } else {
              obj[key] = obj[key] ? addCatalogMetrics(obj[key], value) : value;
            }
          });
          return obj;
        },
        {} as Record<string, TMetrics>
      );
    },
    [addCatalogMetrics]
  );

  const contextValues = useMemo<ICatalogMetricsContext<TMetrics>>(() => {
    return {
      isLoading: metricsQueries.every((q) => q.isLoading),
      eventDetailedMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.eventsDetailed)
      ),
      eventMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.events)
      ),
      performerMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.performers)
      ),
      venueMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.venues)
      ),
      refreshMetrics: refreshMetrics,
    };
  }, [getMergedMetricsRecord, metricsQueries, refreshMetrics]);

  return (
    <CatalogMetricsContext.Provider value={contextValues}>
      {children}
    </CatalogMetricsContext.Provider>
  );
}

export function CatalogMetricsContextProvider<
  TMetrics extends { currency: string | null },
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>(props: CatalogMetricsContextProviderProps<TMetrics, TQuery>) {
  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  return hasMetricsV2Feature ? (
    <CatalogMetricsContextV2Provider {...props} />
  ) : (
    <CatalogMetricsContextV1Provider {...props} />
  );
}
