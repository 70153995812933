import { ComponentProps, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ReportTypes } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ReportGroupBy } from 'src/WebApiController';

const DEFAULT_GROUP_BY_INVENTORY = ReportGroupBy.ListingId;
const DEFAULT_GROUP_BY_SALE = ReportGroupBy.SaleId;

const emptyReport: ReportConfig = {
  schemaVersion: '1',
  reportId: null,
  reportIdRedirect: null,
  reportName: '',
  reportType: ReportTypes.Inventory,
  ownerDisplayName: '',
  widgets: null,
  groupBy: ReportGroupBy.Performer,
  filter: null,
  metrics: [],
  numberOfItemsPerPage: null,
  sortBy: null,
  isSortDescending: null,
  globalReportTypeId: null,
  isOwner: true,
  roleIdsToShare: [],
  sellerUserIdsToShare: [],
  allowReaderEditMetrics: false,
  hasFilterOverride: null,
  hasMetricsOverride: null,
  arAgingTimeBuckets: null,
  viewableFilterItemIds: null,
  editableFilterItemIds: null,
  hiddenFilterItemIds: null,
};

export function useReportV1Actions() {
  const navigate = useNavigate();
  const { loginContext } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [currentReport, setCurrentReport] = useState<ReportConfig>();
  const reportBuilderDialogInventory = useBasicDialog();
  const reportBuilderDialogSale = useBasicDialog();
  const shareDialog = useBasicDialog();

  const deleteDialog = useBasicDialog();
  const reportHistoryDialog = useBasicDialog();

  const onAddNewReport = useCallback(
    (reportType: ReportTypes) => {
      const defaultMetrics =
        reportType === ReportTypes.Inventory
          ? [GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING[DEFAULT_GROUP_BY_INVENTORY]]
          : [GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES[DEFAULT_GROUP_BY_SALE]];

      setCurrentReport({
        ...emptyReport,
        reportType,
        ownerDisplayName: loginContext?.user?.displayName ?? '',
        groupBy:
          reportType === ReportTypes.Inventory
            ? DEFAULT_GROUP_BY_INVENTORY
            : DEFAULT_GROUP_BY_SALE,
        metrics: defaultMetrics,
      });

      if (reportType === ReportTypes.Inventory) {
        reportBuilderDialogInventory.launchDialog();
      } else if (reportType === ReportTypes.Sale) {
        reportBuilderDialogSale.launchDialog();
      }
    },
    [
      loginContext?.user?.displayName,
      reportBuilderDialogInventory,
      reportBuilderDialogSale,
    ]
  );

  const {
    deleteReportConfig: deleteReportConfigInventory,
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });

  const {
    deleteReportConfig: deleteReportConfigSale,
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigs<ReportTypes.Sale>({
    reportType: ReportTypes.Sale,
  });

  const invokeUpsertReport = useCallback(
    async (report: ReportConfig) => {
      await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypes.Inventory) {
            upsertReportConfigInventory(report.reportId, report);
          } else if (report.reportType === ReportTypes.Sale) {
            upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportClient.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...currentReport!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await invokeUpsertReport(updatedReport);
      shareDialog.closeDialog();
    },
    [currentReport, invokeUpsertReport, shareDialog]
  );

  const onReportSaveSubmit = useCallback(
    async (report: ReportConfig) => {
      await invokeUpsertReport(report);
      if (report.reportType === ReportTypes.Inventory) {
        reportBuilderDialogInventory.closeDialog();
      } else if (report.reportType === ReportTypes.Sale) {
        reportBuilderDialogSale.closeDialog();
      }
    },
    [reportBuilderDialogInventory, reportBuilderDialogSale, invokeUpsertReport]
  );

  const onReportAction = useCallback(
    (
      reportConfig: ReportConfig,
      actionType: GridActionType,
      event?: React.MouseEvent
    ) => {
      switch (actionType) {
        case GridActionType.Select:
          if (reportConfig.reportId) {
            let navUrl =
              reportConfig.reportType === ReportTypes.Inventory
                ? `/reports/inventory/${reportConfig.reportId}`
                : `/reports/sale/${reportConfig.reportId}`;

            if (reportConfig.reportIdRedirect) {
              navUrl =
                reportConfig.reportType === ReportTypes.Inventory
                  ? `/reports/inventory/v2/${reportConfig.reportIdRedirect}`
                  : reportConfig.globalReportTypeId === 9 // Commission, from GlobalReportType
                    ? `/reports/commission/v2/${reportConfig.reportIdRedirect}`
                    : `/reports/sale/v2/${reportConfig.reportIdRedirect}`;
            }

            if (event?.metaKey || event?.ctrlKey) {
              // Open in new tab when holding down the meta/control key
              window.open(navUrl, '_blank', 'ref=noopener noreferrer');
            } else {
              if (reportConfig.reportIdRedirect) {
                navigate(navUrl, {
                  state: { isFromV1Page: true },
                });
              } else {
                navigate(navUrl);
              }
            }
          }
          break;
        case GridActionType.Edit:
          setCurrentReport(reportConfig);
          if (reportConfig.reportType === ReportTypes.Inventory) {
            reportBuilderDialogInventory.launchDialog();
          } else if (reportConfig.reportType === ReportTypes.Sale) {
            reportBuilderDialogSale.launchDialog();
          }
          break;
        case GridActionType.Delete:
          setCurrentReport(reportConfig);
          deleteDialog.launchDialog();
          break;
        case GridActionType.Share:
          setCurrentReport(reportConfig);
          shareDialog.launchDialog();
          break;
        case GridActionType.Duplicate:
          invokeUpsertReport({
            ...reportConfig,
            reportId: null,
            reportName:
              reportConfig!.reportType === ReportTypes.Inventory
                ? getReportConfigNameForDuplicateInventory(
                    reportConfig.reportName
                  )
                : getReportConfigNameForDuplicateSale(reportConfig!.reportName),
            isOwner: true,
            globalReportTypeId: null,
          });
          break;
        case GridActionType.History:
          setCurrentReport(reportConfig);
          reportHistoryDialog.launchDialog();
          break;
        default:
          break;
      }
    },
    [
      deleteDialog,
      getReportConfigNameForDuplicateInventory,
      getReportConfigNameForDuplicateSale,
      invokeUpsertReport,
      navigate,
      reportBuilderDialogInventory,
      reportBuilderDialogSale,
      reportHistoryDialog,
      shareDialog,
    ]
  );

  const onReportDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        if (currentReport) {
          if (currentReport.reportType === ReportTypes.Inventory) {
            deleteReportConfigInventory(currentReport.reportId!);
          } else if (currentReport.reportType === ReportTypes.Sale) {
            deleteReportConfigSale(currentReport.reportId!);
          }
        }
        deleteDialog.closeDialog();
      },
      (error) => {
        showErrorDialog('UserSetting.deleteReport', error, {
          trackErrorData: currentReport,
        });
      }
    );
  }, [
    currentReport,
    deleteDialog,
    deleteReportConfigInventory,
    deleteReportConfigSale,
    showErrorDialog,
  ]);

  return {
    onAddNewReport,
    currentReport,
    setCurrentReport,
    onReportSaveSubmit,
    onReportShareSubmit,
    onReportDelete,
    onReportAction,
    reportBuilderDialogInventory,
    reportBuilderDialogSale,
    shareDialog,
    deleteDialog,
    reportHistoryDialog,
  };
}
