import { Header } from '@tanstack/react-table';
import type { Table } from '@tanstack/table-core';
import { useCallback, useEffect, useMemo } from 'react';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ListingNote, Seating } from 'src/WebApiController';

export const useColumnFilter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  header: Header<any, unknown>
) => {
  const canColumnFilter = header.column.getCanFilter();
  const columnFilterVariant = header.column.columnDef.meta?.columnFilterVariant;
  const uniqueValueVariant = header.column.columnDef.meta?.uniqueValueVariant;

  const canMultiSelect = columnFilterVariant == null;

  const { setSelectionMode, selectionMode } = useMultiSelectionContext();

  const tableFlatRows = table.getCoreRowModel().flatRows;

  // Values from PosSelect have to be string - so if comparing against number values we need weak equality
  const columnFilterOptionsContent = useMemo(() => {
    if (!canColumnFilter || !canMultiSelect) {
      // No need to calculate anything here
      return {};
    }

    let uniqueValues: [string, string][] = [];

    if (uniqueValueVariant == 'seatingSection') {
      const values = new Set<string>();
      tableFlatRows.forEach((row) => {
        // If the row has subrows, skip
        if (row.getLeafRows().length == 0) {
          const value = row.getValue(header.column.id);
          if (value) {
            const { seating } = value as { seating: Seating };
            if (seating) {
              values.add(seating.section);
            }
          }
        }
      });
      uniqueValues = Array.from(values).map((value) => [value, value]);
    } else if (uniqueValueVariant == 'seatTraits') {
      const values: ListingNote[] = [];
      tableFlatRows.forEach((row) => {
        // If the row has subrows, skip
        if (row.getLeafRows().length == 0) {
          const value = row.getValue(header.column.id);
          if (value) {
            const listingNotes = value as ListingNote[] | null | undefined;
            if (listingNotes) {
              listingNotes.forEach((note) => {
                if (
                  !values.some((v) => v.listingNoteId === note.listingNoteId)
                ) {
                  values.push(note);
                }
              });
            }
          }
        }
      });
      uniqueValues = values.map((value) => [
        value.listingNoteId.toString(),
        value.listingNoteText,
      ]);
    } else {
      const values: string[] = [];
      tableFlatRows.forEach((row) => {
        // If the row has subrows, skip
        if (row.getLeafRows().length == 0) {
          const value = row.getValue(header.column.id);
          if (
            value &&
            values.every(
              (v) =>
                v.toLocaleLowerCase() !== value.toString().toLocaleLowerCase()
            )
          ) {
            values.push(value.toString());
          }
        }
      });

      uniqueValues = values
        .filter((value) => !!value?.trim())
        .map((value) => [value, value]);
    }

    return uniqueValues.reduce(
      (acc, value) => {
        if (value) {
          acc[value[0]] = value[1];
        }
        return acc;
      },
      {} as Record<string, string>
    );
  }, [
    canColumnFilter,
    canMultiSelect,
    header.column,
    tableFlatRows,
    uniqueValueVariant,
  ]);

  const filterValue = header.column.getFilterValue();

  const isColumnFilterActive = useMemo(() => {
    if (!filterValue) {
      return false;
    }

    if (canMultiSelect) {
      return !!(filterValue as unknown[]).length;
    } else {
      return (
        (filterValue as [number, number])?.[0] != null ||
        (filterValue as [number, number])?.[1] != null
      );
    }
  }, [canMultiSelect, filterValue]);

  const setFilterValueWrapper = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (updater: any) => {
      // Clear the selection
      setSelectionMode(selectionMode);
      header.column.setFilterValue(updater);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [header.column]
  );

  // Consolidate filter value to undefined - no need to keep invalid values like [null, null]
  // Makes it easy for any potential consumers to check if the filter is active on a table level
  useEffect(() => {
    if (!isColumnFilterActive && filterValue != null) {
      header.column.setFilterValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, isColumnFilterActive]);

  return {
    columnFilterOptionsContent,
    canMultiSelect,
    isColumnFilterActive,
    setColumnFilterValue: setFilterValueWrapper,
    filterValue,
  };
};
