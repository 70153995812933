import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const DiskSaveIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="-75 -75 650 650">
      <g>
        <g>
          <path
            d="M416.814,0H0v512h512V95.186L416.814,0z M124.541,41.513h179.892v62.27h41.514v-62.27h41.513v96.865H124.541V41.513z
			 M387.459,470.487H124.541V262.919h262.919V470.487z M470.487,470.487h-41.514V221.405H83.027v249.081H41.513V41.513h41.514
			v138.378h345.946V70.868l41.514,41.513V470.487z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="172.973" y="304.432" width="166.054" height="41.514" />
        </g>
      </g>
      <g>
        <g>
          <rect x="172.973" y="387.459" width="166.054" height="41.513" />
        </g>
      </g>
    </SvgWrapper>
  );
};
