import Rainbow from 'rainbowvis.js';
import { useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './CondensedColorBand.css';

export type CondensedColorBandProps = {
  numOfSteps: number;
  spectrum: string[];
  labels?: ContentId[] | string[];
};

export const CondensedColorBand = ({
  numOfSteps,
  spectrum,
  labels,
}: CondensedColorBandProps) => {
  const colorSteps = useMemo(() => {
    const rainbow = new Rainbow();
    rainbow.setNumberRange(1, numOfSteps);
    rainbow.setSpectrumByArray(spectrum);

    const colorSteps = [];
    for (let i = 0; i < numOfSteps; i++) {
      const color = rainbow.colorAt(i + 1);
      colorSteps.push(<ColorStep key={i} color={`#${color}`} />);
    }
    return colorSteps;
  }, [numOfSteps, spectrum]);

  const startLabelContentId = useMemo(() => {
    if (labels?.length == 0 || labels == null) {
      return '';
    }

    return labels.at(0);
  }, [labels]);

  const endLabelContentId = useMemo(() => {
    if (labels?.length == 0 || labels == null) {
      return '';
    }
    return labels.at(-1);
  }, [labels]);

  const startText = useContent(startLabelContentId);
  const endText = useContent(endLabelContentId);

  return (
    <div className={styles.colorStepsWrapper}>
      <span>{startText}</span>
      <div className={styles.colorStepsContainer}>{colorSteps}</div>
      <span>{endText}</span>
    </div>
  );
};

const ColorStep = ({ color }: { color: string }) => {
  return (
    <div className={styles.colorStep} style={{ backgroundColor: color }}></div>
  );
};
