import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext/Content';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { Detail, DetailGroup } from 'src/modals/common';
import { OpenLinkIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { selectDistinctListingIds } from 'src/utils/ticketUtils';
import { Ticket } from 'src/WebApiController';

import * as styles from '../SaleDetails.css';

const renderListing = (
  contentId: string,
  listingId?: number | null,
  showTitle?: boolean
) => {
  if (!listingId) return null;

  return (
    <DetailGroup>
      <Detail
        label={showTitle && <Content id={contentId} />}
        detail={
          <NavLink
            title={getListingDetailsRelativeUrl(listingId)}
            tag={Link}
            className="nav-link"
            to={getListingDetailsRelativeUrl(listingId)}
          >
            <Stack style={{ alignItems: 'center' }}>
              <div className={styles.ticketListingId}>{'#' + listingId}</div>
              <OpenLinkIcon
                size={vars.iconSize.s}
                fill={vars.color.textBrand}
                withHoverEffect
                style={{ marginBottom: 2 }}
              />
            </Stack>
          </NavLink>
        }
      />
    </DetailGroup>
  );
};

export const ListingLinksDetail = ({
  listingId,
  originatedFromListingId,
  tickets,
  showTitle = true,
}: {
  listingId?: number | null;
  originatedFromListingId?: number | null;
  tickets?: Ticket[];
  showTitle?: boolean;
}) => {
  const distinctListingIds = tickets?.length
    ? selectDistinctListingIds(tickets)
    : [];

  const hasOriginatedListing =
    originatedFromListingId &&
    distinctListingIds.includes(originatedFromListingId);

  return (
    <>
      {tickets?.length ? (
        <>
          {!hasOriginatedListing &&
            renderListing(
              ContentId.OriginatedListing,
              originatedFromListingId,
              showTitle
            )}
          {distinctListingIds.map((listingId) =>
            renderListing(
              listingId === originatedFromListingId
                ? ContentId.OriginatedListing
                : ContentId.AllocatedListing,
              listingId,
              showTitle
            )
          )}
        </>
      ) : (
        <>
          {originatedFromListingId &&
            renderListing(
              ContentId.OriginatedListing,
              originatedFromListingId,
              showTitle
            )}
          {listingId !== originatedFromListingId &&
            renderListing(ContentId.AllocatedListing, listingId, showTitle)}
        </>
      )}
    </>
  );
};
