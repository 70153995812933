import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

export const getEditability = (
  editableFilterItemIds: string[] | undefined | null,
  viewableFilterItemIds: string[] | undefined | null,
  hiddenFilterItemIds: string[] | undefined | null,
  reportFilterId: string | null | undefined
) => {
  if (!reportFilterId) {
    return DEFAULT_REPORT_FILTER_EDITABILITY;
  }

  if (viewableFilterItemIds?.includes(reportFilterId)) {
    return ReportFilterEditability.View;
  }
  if (editableFilterItemIds?.includes(reportFilterId)) {
    return ReportFilterEditability.Edit;
  }
  if (hiddenFilterItemIds?.includes(reportFilterId)) {
    return ReportFilterEditability.Hidden;
  }
  return DEFAULT_REPORT_FILTER_EDITABILITY;
};

export const updateFilterItemIds = (
  editableFilterItemIds: string[] | undefined | null,
  viewableFilterItemIds: string[] | undefined | null,
  hiddenFilterItemIds: string[] | undefined | null,
  reportFilterIds: string[],
  editability: ReportFilterEditability
) => {
  const isNotIncluded = (id: string) => !reportFilterIds.includes(id);

  const newHiddenFilterItemIds = hiddenFilterItemIds
    ? hiddenFilterItemIds.filter(isNotIncluded)
    : [];
  const newViewableFilterItemIds = viewableFilterItemIds
    ? viewableFilterItemIds.filter(isNotIncluded)
    : [];
  const newEditableFilterItemIds = editableFilterItemIds
    ? editableFilterItemIds.filter(isNotIncluded)
    : [];

  if (editability === ReportFilterEditability.Hidden) {
    newHiddenFilterItemIds.push(...reportFilterIds);
  } else if (editability === ReportFilterEditability.View) {
    newViewableFilterItemIds.push(...reportFilterIds);
  } else if (editability === ReportFilterEditability.Edit) {
    newEditableFilterItemIds.push(...reportFilterIds);
  }

  return {
    newHiddenFilterItemIds: newHiddenFilterItemIds.toSorted(),
    newViewableFilterItemIds: newViewableFilterItemIds.toSorted(),
    newEditableFilterItemIds: newEditableFilterItemIds.toSorted(),
  };
};
