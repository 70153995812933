// Subset of ReportCommissionColumnName from ReportingService

export enum CommissionReportTableGroupingColumnId {
  SaleId = 'saleId',
  MarketplaceName = 'marketplaceName',
  MarketplaceSaleId = 'marketplaceSaleId',
  Commissioner = 'commissioner',
  EventName = 'eventName',
  EventDateLocal = 'eventDateLocal',
  VenueName = 'venueName',
  EventId = 'eventId',
  EventStateName = 'eventStateName',
  SaleStatusName = 'saleStatusName',
  Section = 'section',
  SeatingRow = 'seatingRow',
  SeatFrom = 'seatFrom',
  SeatTo = 'seatTo',
  FulfillmentDate = 'fulfillmentDate',
  LastPaymentDate = 'lastPaymentDate',
  NetCommissionRate = 'netCommissionRate',
  NetCommissionRateOverride = 'netCommissionRateOverride',
  SaleBasisShare = 'saleBasisShare',
  IsCommissionEligible = 'isCommissionEligible',
  CommissionEligibilityDate = 'commissionEligibilityDate',
}

export enum CommissionReportTableValueColumnId {
  TotalNetProceeds = 'totalNetProceeds',
  Credits = 'credits',
  TotalCost = 'totalCost',
  Charges = 'charges',
  CommissionerCostsAndCharges = 'commissionerCostsAndCharges',
  CommissionerProceedsAndCredits = 'commissionerProceedsAndCredits',
  CommissionerProfitAndLoss = 'commissionerProfitAndLoss',
  OriginalProceeds = 'originalProceeds',
}
