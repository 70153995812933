import {
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';
import { isEqual, merge } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useListExpansion } from 'src/hooks/useListExpansion';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DATA_REFRESH_RATE_IN_MILLIS_LONG,
  DATA_REFRESH_RATE_IN_MILLIS_SHORT,
  MAX_DATE,
  MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS,
  MIN_DATE,
} from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { searchEvents, sortEvents } from 'src/utils/eventWithDataUtils';
import { getFlattenedListingIds } from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  CatalogClient,
  EntityWithTicketsQuery,
  EventTimeFrameFilter,
  EventWithData,
  Feature,
  InventoryViewMode,
  Listing,
  ListingGroup,
  PurchaseOrderTicketGroup,
  Sale,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { NO_GROUP_ID } from '../MultiSelectionContext';
import {
  CatalogDataContextProviderProps,
  CatalogDataContextV1,
  ExpandedCallbackStatus,
  ExpandedEventData,
  ItemIdWithRowVersion,
} from './CatalogDataContext.types';
import { updateCatalogResults } from './CatalogDataContext.utils';
import { CatalogDataContextV2Provider } from './CatalogDataContextV2';

export function CatalogDataContextV1Provider<
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  entityType,
  queryKey,
  getCatalogData,
  getCatalogDataExpanded,
  onCatalogDataExpanded,
  transformEventData,
  children,
  disabled,
  disableAutoRefresh,
  ignoreMaxCount,
}: CatalogDataContextProviderProps<TQuery>) {
  const queryClient = useQueryClient();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const { filterQuery, isQueryInitialized } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();
  const eventsExpansion = useListExpansion<string>();
  const { expandedListItems } = eventsExpansion;

  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  // Flags to track the status of the expanded callback
  const [expandedCallbackStatus, setExpandedCallbackStatus] = useState<
    Record<string, ExpandedCallbackStatus>
  >({});
  const onExpandedCallbackStatusUpdate = useCallback(
    (stringQueryKey: string, status: ExpandedCallbackStatus) =>
      setExpandedCallbackStatus((prev) => ({
        ...prev,
        [stringQueryKey]: status,
      })),
    []
  );

  const {
    searchText,
    sortBy,
    isSortDescending,
    performerIds,
    venueIds,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewMode, // this is not used for server and keeping it in this cause refresh to happen 2x
    ...rest // Don't use it because it's a new object on every render
  } = filterQuery;

  const transformedQuery = useMemo<TQuery>(() => {
    const {
      searchText,
      sortBy,
      isSortDescending,
      performerIds,
      venueIds,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      viewMode, // this is not used for server and keeping it in this cause refresh to happen 2x
      ...rest
    } = filterQuery;
    return rest as TQuery;
  }, [filterQuery]);

  const realQueryKey = useMemo(
    () => [
      queryKey,
      transformedQuery,
      activeAccountWebClientConfig.activeAccountId,
    ],
    [queryKey, transformedQuery, activeAccountWebClientConfig.activeAccountId]
  );

  // The result for the catalog query can be tremedously huge -
  // this is an arbitrary rule to decide whether or not to keep this around
  // in the cache long or only for rendering
  const shouldBeCachedNormally =
    !transformedQuery.eventNotReviewedSinceDate &&
    (transformedQuery.eventTimeFrameFilter === EventTimeFrameFilter.Future ||
      // If difference in days is less than 1 year
      differenceInDays(
        transformedQuery.eventDates?.start == null
          ? MIN_DATE
          : new Date(transformedQuery.eventDates.start),
        transformedQuery.eventDates?.end == null
          ? MAX_DATE
          : new Date(transformedQuery.eventDates.end)
      ) < 365);

  const shouldQuery =
    transformedQuery != null &&
    activeAccountWebClientConfig.activeAccountId != null &&
    isQueryInitialized &&
    !disabled;

  const query = useQuery({
    queryKey: realQueryKey,
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const data = await getCatalogData(
            new CatalogClient(activeAccountWebClientConfig),
            transformedQuery
          );

          return data;
        },
        (error) => {
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError('CatalogClient.getCatalogData', error, transformedQuery);
        }
      );
    },

    enabled: shouldQuery,
    staleTime: shouldBeCachedNormally ? Infinity : 0, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    gcTime: shouldBeCachedNormally ? undefined /* use default */ : 0,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval:
      shouldBeCachedNormally && !disableAutoRefresh
        ? DATA_REFRESH_RATE_IN_MILLIS_LONG
        : false, // disable refetch if event timespan is over a year in the past
  });
  const catalogResults = query.data;

  const eventIdsToGetItemsFor = useMemo(() => {
    if (transformedQuery.entityIds?.length) {
      return [[NO_GROUP_ID]];
    }

    if (!catalogResults) {
      return [] as [string][];
    }

    const eventsToGetItemsFor = searchEvents(
      catalogResults,
      searchText,
      performerIds,
      venueIds
    );

    let listingCount = 0;
    let salesCount = 0;

    (eventsToGetItemsFor ?? []).forEach((ev) => {
      listingCount += ev.counts.listCnt ?? 0;
      salesCount += ev.counts.salesCnt ?? 0;
    });

    let isTooManyItems = false;
    if (!ignoreMaxCount && viewMode === InventoryViewMode.FlattenedView) {
      // Only one of these are non-zero
      if (listingCount) {
        isTooManyItems = listingCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;
      } else if (salesCount) {
        isTooManyItems = salesCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;
      }
    }
    const results =
      viewMode === InventoryViewMode.FlattenedView
        ? isTooManyItems &&
          // If the catalog shows a count of way too many items and we're actually not filtering to the individual items, don't query at all
          !rest.entityIds?.length &&
          !rest.marketplaceEntityIds?.length
          ? []
          : // For flatten view - we know we're gonna get all of them at once, so just bundle all the event ids in 1 array
            [eventsToGetItemsFor?.map((ev) => ev.event.viagVirtualId)]
        : // For tile view, we only want 1 event per query
          expandedListItems.map((id) => [id]);

    // Finally, we need to include the PosEventIds with all the ids, because in some cases
    // the ViagogoVirtualIds do not resolve properly due to the outdated cache, and missing items are returned
    // for merged events
    return results.map((idArray) => {
      const allEvents = eventsToGetItemsFor
        .filter((ev) => idArray.includes(ev.event.viagVirtualId))
        .flatMap((ev) => ev.event.posIds.map((posId) => `old:${posId}`));

      return idArray.concat(allEvents);
    });
  }, [
    transformedQuery.entityIds?.length,
    catalogResults,
    searchText,
    performerIds,
    venueIds,
    ignoreMaxCount,
    viewMode,
    rest.entityIds?.length,
    rest.marketplaceEntityIds?.length,
    expandedListItems,
  ]);

  const realExpandedDataItemsQueryKey = useMemo(
    () => [queryKey + 'Expanded', transformedQuery],
    [queryKey, transformedQuery]
  );

  const shouldQuery2 =
    activeAccountWebClientConfig.activeAccountId != null &&
    transformedQuery != null &&
    !disabled;

  const [queryWithExpandedItems, setQueryWithExpandedItems] = useState<
    UseQueryResult<ExpandedEventData | null | undefined, Error>[]
  >([]);

  const refreshInterval = !disableAutoRefresh
    ? DATA_REFRESH_RATE_IN_MILLIS_SHORT
    : false;

  const queryWithExpandedItemsFromHook = useQueries({
    queries: (eventIdsToGetItemsFor ?? []).map((ids) => {
      const queryKey = [...realExpandedDataItemsQueryKey, ...ids.toSorted()];
      return {
        queryKey: queryKey,
        queryFn: async () => {
          if (!shouldQuery2) {
            return null;
          }

          return tryInvokeApi(
            async () => {
              const data = await getCatalogDataExpanded?.(
                ids[0] === NO_GROUP_ID ? [] : ids,
                transformedQuery
              );
              if (onCatalogDataExpanded) {
                onExpandedCallbackStatusUpdate(
                  queryKey.toString(),
                  ExpandedCallbackStatus.Pending
                );
              }
              return data;
            },
            (error: ErrorTypes) => {
              showErrorDialog('CatalogClient.getCatalogDataExpanded', error, {
                trackErrorData: {
                  eventIds: ids,
                  filterQuery,
                },
              });
            }
          );
        },
        enabled: shouldQuery2,
        staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
        refetchOnWindowFocus: false,
        refetchInterval: refreshInterval as number | false,
      };
    }),
  });

  const onCatalogDataExpandedCallbackCheck = useCallback(
    async (
      queryResults: UseQueryResult<
        ExpandedEventData | null | undefined,
        Error
      >[]
    ) => {
      if (!onCatalogDataExpanded) {
        return;
      }
      const queryIds = eventIdsToGetItemsFor ?? [];
      for (let index = 0; index < queryIds.length; index++) {
        const ids = queryIds[index];
        const queryKey = [...realExpandedDataItemsQueryKey, ...ids.toSorted()];
        const stringQueryKey = queryKey.toString();
        const queryResult = queryResults[index];
        if (
          expandedCallbackStatus[stringQueryKey] ===
          ExpandedCallbackStatus.Pending
        ) {
          onExpandedCallbackStatusUpdate(
            stringQueryKey,
            ExpandedCallbackStatus.InProgress
          );
          const newData = await onCatalogDataExpanded(
            ids,
            transformedQuery,
            queryResult?.data ?? undefined
          );
          queryClient.setQueryData(queryKey, newData);
          onExpandedCallbackStatusUpdate(
            stringQueryKey,
            ExpandedCallbackStatus.Done
          );
        }
      }
    },
    [
      eventIdsToGetItemsFor,
      expandedCallbackStatus,
      onCatalogDataExpanded,
      onExpandedCallbackStatusUpdate,
      queryClient,
      realExpandedDataItemsQueryKey,
      transformedQuery,
    ]
  );

  /**
   * IMPORTANT: This is needed because queryWithExpandedItemsFromHook from useQueries
   * is not a memoized object, so on every render it returns a new array with queryWithExpandedItems,
   * causing the app to re-render.
   */
  useEffect(() => {
    if (!isEqual(queryWithExpandedItems, queryWithExpandedItemsFromHook)) {
      setQueryWithExpandedItems(queryWithExpandedItemsFromHook);
      onCatalogDataExpandedCallbackCheck(queryWithExpandedItemsFromHook);
      return;
    }
  }, [
    onCatalogDataExpandedCallbackCheck,
    queryWithExpandedItems,
    queryWithExpandedItemsFromHook,
  ]);

  useEffect(() => {
    if (!catalogResults) {
      return;
    }

    const expandedEventsWithData: ExpandedEventData[] = (
      queryWithExpandedItems ?? []
    ).map((r) => r.data ?? {});

    const updatedCatalaogResult = updateCatalogResults(
      catalogResults,
      expandedEventsWithData
    );
    if (!isEqual(catalogResults, updatedCatalaogResult)) {
      queryClient.setQueryData(realQueryKey, updatedCatalaogResult);
    }
  }, [queryWithExpandedItems, catalogResults, queryClient, realQueryKey]);

  const [eventsTransformed, setEventsTransformed] = useState<EventWithData[]>();

  // This to apply client-side filtering before transformation of the data
  useEffect(() => {
    if (catalogResults) {
      const newExpandedData = merge(
        {},
        ...queryWithExpandedItems.map((r) => r.data ?? {})
      ) as ExpandedEventData;
      const transformed = sortEvents(
        catalogResults,
        transformEventData(
          entityType,
          searchEvents(catalogResults, searchText, performerIds, venueIds),
          newExpandedData,
          queryWithExpandedItems.some((q) => q.isLoading)
        ),
        sortBy,
        isSortDescending
      );

      if (!isEqual(transformed, eventsTransformed)) {
        setEventsTransformed(transformed);
      }
    }
  }, [
    isSortDescending,
    searchText,
    sortBy,
    transformEventData,
    queryWithExpandedItems,
    eventsTransformed,
    catalogResults,
    performerIds,
    venueIds,
    query.isPending,
    entityType,
  ]);

  const refreshCatalog = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
      refetchType: 'none', // we don't want to refetch any, we'll manually refetch the active
    });
    return query.refetch();
  }, [query, queryClient, queryKey]);

  const refreshExpandedListItems = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey + 'Expanded'],
      refetchType: 'none', // we don't want to refetch any, we'll manually refetch the active
    });

    return queryClient.invalidateQueries({
      queryKey: realExpandedDataItemsQueryKey,
    });
  }, [queryClient, queryKey, realExpandedDataItemsQueryKey]);

  /**
   * Refreshes the event data for a specific item
   */
  const refreshEventForItems = useCallback(
    (itemIds: ItemIdWithRowVersion[], entityType: ActionOutboxEntityType) => {
      const eventIds = eventsTransformed
        ?.filter((ev) => {
          if (entityType === ActionOutboxEntityType.Listing) {
            return ev.entities.listings?.some((l) =>
              itemIds.some((i) => i.id === l.id && i.rowVer > l.rowVer)
            );
          } else if (entityType === ActionOutboxEntityType.Sale) {
            return ev.entities.sales?.some((s) =>
              itemIds.some((i) => i.id === s.id && i.rowVer > s.rowVer)
            );
          } else if (entityType === ActionOutboxEntityType.Purchase) {
            return ev.entities.ticketGroups?.some((t) =>
              itemIds.some((i) => i.id === t.id && i.rowVer > t.rowVer)
            );
          }
          return false;
        })
        ?.map((ev) => ev.event.viagVirtualId);

      if (eventIds?.length) {
        if (viewMode === InventoryViewMode.FlattenedView) {
          queryClient.invalidateQueries({
            queryKey: realExpandedDataItemsQueryKey,
          });
        } else {
          for (const eventId of eventIds) {
            queryClient.invalidateQueries({
              queryKey: [...realExpandedDataItemsQueryKey, eventId],
            });
          }
        }
      }
    },
    [eventsTransformed, queryClient, realExpandedDataItemsQueryKey, viewMode]
  );

  const updateExpandedListItems = useCallback(
    (updatedListingsByEvent: { [key: string]: Listing[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedListingsByEvent).forEach(([key, value]) => {
            const oldListingsByIds =
              newData[key]?.listings?.reduce(
                (r, l) => {
                  r[l.id] = l;
                  return r;
                },
                {} as Record<number, Listing>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newListings = [
              ...value.filter((l) => {
                const oldListing = oldListingsByIds[l.id];
                if (oldListing && oldListing.rowVer > l.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newListingIdsFlattened = getFlattenedListingIds(newListings);

            // Retain listings that are not updated
            newData[key]?.listings?.forEach((originalListing) => {
              let shouldKeep = true;
              if (!originalListing.isLtGrp) {
                if (newListingIdsFlattened.includes(originalListing.id))
                  shouldKeep = false;
              } else {
                // If `originalListing` is a listing group
                // there will only be two cases
                // 1. No one listing in `newListingIdsFlattened` show up in the group (keep the group), or
                // 2. All listings in `newListingIdsFlattened` show up in the group (don't keep it, group has been edited or removed)
                const originalListingIdsFlattened = getFlattenedListingIds([
                  originalListing,
                ]);
                if (
                  originalListingIdsFlattened.some((l) =>
                    newListingIdsFlattened.includes(l)
                  )
                ) {
                  shouldKeep = false;
                }
              }

              if (shouldKeep) {
                newListings.push(originalListing);
              }
            });

            newData[key] = {
              sales: null,
              listings: newListings,
              ticketGroups: null,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      // We should only check at first 2 query keys [PrimaryKey, QueryObject]
      const newData = newDatas.find((qk) =>
        isEqual((qk[0] ?? []).slice(0, 2), realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
      }
    },
    [
      queryClient,
      queryKey,
      catalogResults,
      realExpandedDataItemsQueryKey,
      transformEventData,
      entityType,
      searchText,
      performerIds,
      venueIds,
      sortBy,
      isSortDescending,
    ]
  );

  const updateExpandedSaleItems = useCallback(
    (updatedListingsByEvent: { [key: string]: Sale[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedListingsByEvent).forEach(([key, value]) => {
            const oldSalesByIds =
              newData[key]?.sales?.reduce(
                (r, s) => {
                  r[s.id] = s;
                  return r;
                },
                {} as Record<number, Sale>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newSales = [
              ...value.filter((s) => {
                const oldSale = oldSalesByIds[s.id];
                if (oldSale && oldSale.rowVer > s.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newSaleIds = newSales.map((s) => s.id);

            // Retain sales that are not in the newSales
            newData[key]?.sales?.forEach((originalSale) => {
              if (!newSaleIds.includes(originalSale.id)) {
                // new sales doesn't have the original sale, keep it
                newSales.push(originalSale);
              }
            });

            newData[key] = {
              sales: newSales,
              listings: null,
              ticketGroups: null,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      const newData = newDatas.find((qk) =>
        isEqual((qk[0] ?? []).slice(0, 2), realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
      }
    },
    [
      queryClient,
      queryKey,
      catalogResults,
      realExpandedDataItemsQueryKey,
      transformEventData,
      entityType,
      searchText,
      performerIds,
      venueIds,
      sortBy,
      isSortDescending,
    ]
  );

  const updateExpandedTicketGroupItems = useCallback(
    (updatedTgsByEvent: { [key: string]: PurchaseOrderTicketGroup[] }) => {
      const newDatas = queryClient.setQueriesData(
        {
          queryKey: [queryKey + 'Expanded'],
        },
        (oldData: ExpandedEventData | undefined) => {
          if (!oldData) {
            return oldData;
          }
          const newData = { ...oldData };
          Object.entries(updatedTgsByEvent).forEach(([key, value]) => {
            const oldTgsByIds =
              newData[key]?.ticketGroups?.reduce(
                (r, t) => {
                  r[t.tgId!] = t;
                  return r;
                },
                {} as Record<number, PurchaseOrderTicketGroup>
              ) ?? {};

            // Filter all the new ones that have row version < the old one
            const newTgs = [
              ...value.filter((t) => {
                const oldTg = oldTgsByIds[t.tgId!];
                if (oldTg && oldTg.rowVer > t.rowVer) {
                  return false;
                }

                return true;
              }),
            ];
            const newTgIds = newTgs.map((s) => s.tgId!);

            // Retain tgs that are not in the newTgs
            newData[key]?.ticketGroups?.forEach((originalTg) => {
              if (!newTgIds.includes(originalTg.tgId!)) {
                // new sales doesn't have the original sale, keep it
                newTgs.push(originalTg);
              }
            });

            newData[key] = {
              sales: null,
              listings: null,
              ticketGroups: newTgs,
              failedToRetrieveData: false,
            };
          });
          return newData;
        }
      );

      const newData = newDatas.find((qk) =>
        isEqual(qk[0], realExpandedDataItemsQueryKey)
      );
      if (catalogResults && newData) {
        const transformed = sortEvents(
          catalogResults,
          transformEventData(
            entityType,
            searchEvents(catalogResults, searchText, performerIds, venueIds),
            newData[1] as ExpandedEventData,
            false
          ),
          sortBy,
          isSortDescending
        );

        setEventsTransformed(transformed);
      }
    },
    [
      queryClient,
      queryKey,
      catalogResults,
      realExpandedDataItemsQueryKey,
      transformEventData,
      entityType,
      searchText,
      performerIds,
      venueIds,
      sortBy,
      isSortDescending,
    ]
  );

  const updateItemInEvent = useCallback(
    async (
      item: Listing | Sale | PurchaseOrderTicketGroup,
      entityType: ActionOutboxEntityType
    ) => {
      // Now that we got the listing details, try to get the Event from existing data
      const catalogIdKey = item.viagVirtualId!;
      const eventQuery = queryWithExpandedItems.find(
        (r) => r.data?.[catalogIdKey]
      );

      // If we can't find it, go fetch it
      if (eventQuery?.data != null) {
        const event = eventQuery.data[catalogIdKey];

        // NOTE - after each refresh - the row version will be zero
        // and direct cache-update will trigger the row-version change until the next refresh
        // this is to make sure async updates to multiple listings/sales of an event do not overwrite each other
        if (entityType === ActionOutboxEntityType.Listing && event.listings) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.listings.forEach((l) => (l.rowVer = l.rowVer || 1));

          // If the event in the catalog has the listings expanded, we'll want to update it
          // with the latest info from the listing details above (this will help update state changes or seat allocations, etc.)
          const index = event.listings.findIndex(
            (s) =>
              s.id === item.id ||
              (s as ListingGroup).groupItems?.some(
                (l) =>
                  l.id === item.id ||
                  // this is the second level group
                  (l as ListingGroup).groupItems?.some(
                    (l2) => l2.id === item.id
                  )
              )
          );

          const oldItem = event.listings[index];
          const lg = oldItem as ListingGroup;

          if (oldItem.id === item.id) {
            item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently
            event.listings[index] = { ...oldItem, ...item } as Listing;
          } else if (lg.groupItems != null) {
            // The item is a listing under a group
            const groupListingIndex = lg.groupItems.findIndex(
              (s) => s.id === item.id
            );

            if (groupListingIndex < 0) {
              for (const subGroup of lg.groupItems) {
                if (subGroup.isLtGrp) {
                  const listingIndex = (
                    subGroup as ListingGroup
                  ).groupItems.findIndex((s) => s.id === item.id);

                  if (listingIndex >= 0) {
                    const oldItemInGroup = (subGroup as ListingGroup)
                      .groupItems[listingIndex];
                    item.rowVer = oldItemInGroup.rowVer + 1; // Increase rowVer to indicate that we updated this recently

                    (subGroup as ListingGroup).groupItems[listingIndex] = {
                      ...oldItem,
                      ...item,
                    } as Listing;
                  }
                }
              }
            } else {
              const oldItemInGroup = lg.groupItems[groupListingIndex];
              item.rowVer = oldItemInGroup.rowVer + 1; // Increase rowVer to indicate that we updated this recently

              lg.groupItems[groupListingIndex] = {
                ...oldItem,
                ...item,
              } as Listing;
            }
          }

          event.listings = [...event.listings]; // this so the table will forced to be re-rendered
          updateExpandedListItems({ [catalogIdKey]: event.listings });
        } else if (entityType === ActionOutboxEntityType.Sale && event.sales) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.sales.forEach((s) => (s.rowVer = s.rowVer || 1));

          // If the event in the catalog has the sales expanded, we'll want to update it
          // with the latest info from the sale details above (this will help update state changes or seat allocations, etc.)
          const index = event.sales.findIndex((s) => s.id === item.id);
          const oldItem = event.sales[index];
          item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently

          event.sales[index] = { ...oldItem, ...item } as Sale;
          event.sales = [...event.sales]; // this so the table will forced to be re-rendered

          updateExpandedSaleItems({ [catalogIdKey]: event.sales });
        } else if (
          entityType === ActionOutboxEntityType.Purchase &&
          event.ticketGroups
        ) {
          // Because we're manually updating, set all rowVer here to be min 1, and the item to be updated to be min 2
          event.ticketGroups.forEach((s) => (s.rowVer = s.rowVer || 1));

          // If the event in the catalog has the sales expanded, we'll want to update it
          // with the latest info from the sale details above (this will help update state changes or seat allocations, etc.)
          const index = event.ticketGroups.findIndex(
            (t) => t.tgId === (item as PurchaseOrderTicketGroup).tgId
          );
          const oldItem = event.ticketGroups[index];
          item.rowVer = oldItem.rowVer + 1; // Increase rowVer to indicate that we updated this recently

          event.ticketGroups[index] = {
            ...oldItem,
            ...item,
          } as PurchaseOrderTicketGroup;
          event.ticketGroups = [...event.ticketGroups]; // this so the table will forced to be re-rendered

          updateExpandedTicketGroupItems({
            [catalogIdKey]: event.ticketGroups,
          });
        }
      }
    },
    [
      queryWithExpandedItems,
      updateExpandedListItems,
      updateExpandedSaleItems,
      updateExpandedTicketGroupItems,
    ]
  );

  const allEventIds = useMemo(
    () =>
      eventsTransformed?.map((ev) => ({
        viagVirtualId: ev.event.viagVirtualId,
        performerId: ev.event.perfId,
        venueId: ev.event.venueId,
      })),
    [eventsTransformed]
  );

  useEffect(() => {
    if (query.failureReason) {
      const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
        null,
        query.failureReason?.toString()
      );
      setErrorInfo({
        errorHeader: headerDisplay,
        errorMessage: messageDisplay,
      });
    } else if (errorInfo) {
      setErrorInfo(undefined);
    }
  }, [errorInfo, query.failureReason]);

  return (
    <CatalogDataContextV1.Provider
      value={{
        isLoading: query.isPending, // This one by default is never disabled, so using isPending here, only time it's disabled is when the environment is not ready
        isItemsLoading: queryWithExpandedItems.some((r) => r.isLoading), // whether the query to get items are loading
        data: catalogResults ?? undefined,
        errorInfo,
        eventsTransformed,
        allEventIds,
        refreshCatalog,
        updateItemInEvent,
        eventsExpansion: {
          ...eventsExpansion,
          refreshExpandedListItems,
        },
        updateExpandedListItems,
        refreshEventForItems,
        totalPurchaseCount: catalogResults?.purchaseCount,
      }}
    >
      {children}
    </CatalogDataContextV1.Provider>
  );
}

export function CatalogDataContextProvider<
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>(props: CatalogDataContextProviderProps<TQuery>) {
  const hasCatalogV2Feature = useUserHasFeature(Feature.CatalogCacheStrategyV2);

  return hasCatalogV2Feature ? (
    <CatalogDataContextV2Provider {...props} />
  ) : (
    <CatalogDataContextV1Provider {...props} />
  );
}
