import {useAppContext} from "src/contexts/AppContext";
import {ErrorTypes, useErrorBoundaryContext} from "src/contexts/ErrorBoundaryContext";
import {useSyncCenterSettings} from "src/hooks/api/useSyncCenterSetting";
import {useMutation} from "@tanstack/react-query";
import {SellerAccountClient} from "src/WebApiController";
import {PosSpinner} from "src/core/POS/PosSpinner";
import * as styles from "./AccountSetting.css";
import {Content} from "src/contexts/ContentContext";
import {ContentId} from "src/utils/constants/contentId";
import {Button, Stack} from "src/core/ui";
import {Switch} from "src/core/interim/Switch";
import {vars} from "src/core/themes";



export function AccountingSetting() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const query = useSyncCenterSettings();

  const mutation = useMutation({
    mutationFn: async (isAutoAddCancelledProceed: boolean) => {
      if (query.data == undefined) {
        showErrorDialog('SellerAccountClient.mergeSyncCenterSettings', null, {
          trackErrorData: query,
        });
        return;
      }
      return new SellerAccountClient(activeAccountWebClientConfig)
        .mergeSyncCenterSettings({
          ...query.data,
          isAutoAddCancelledProceedsEnabled: isAutoAddCancelledProceed
        })
        .then(() => query.refetch());
    },
    onError: (err: ErrorTypes, data) => {
      showErrorDialog('SellerAccountClient.mergeSyncCenterSettings', err, {
        trackErrorData: { data },
      });
    },
  });

  if (query.isLoading) {
    return <PosSpinner />;
  }

  return (
    <div
      className={styles.sectionContainer}
      style={{ gap: vars.spacing['xl'] }}
    >
      <h5 className={styles.sectionHeading}>
        <Content id={ContentId.CancelledOrders}/>
      </h5>
      <Stack direction="column" gap="m">
        <div className={styles.label}>
          <Content id={ContentId.CancelledProceeds}/>
        </div>
        <p className={styles.subtleText}>
          <Content id={ContentId.CancelledProceedsSettingDescription}/>
        </p>
        <Stack direction="row" gap="m" alignItems="center">
          <Switch
            disabled={mutation.isPending}
            checked={query.data?.isAutoAddCancelledProceedsEnabled ?? false}
            onChange={(e) => {
              mutation.mutate(e.currentTarget.checked);
            }}
          />
          <div className={styles.label}>
            <Content id={ContentId.CancelledProceedsSettingToggle}/>
          </div>
        </Stack>
      </Stack>
    </div>
  );
}
