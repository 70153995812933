import { useMemo } from 'react';
import { PurchasesSideTable } from 'src/components/Purchases/PurchaseEventPage/PurchasesSideTable';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { ListingQuery } from 'src/WebApiController';

export const PurchaseDetailPanel = () => {
  const { event, selectedListingIds } = useInventoryEventDetailContext();

  const { filterQuery } = useFilterQueryContext<ListingQuery>();

  const listingIdFilterQuery = useMemo(() => {
    const marketplaceEntityIds = filterQuery?.marketplaceEntityIds?.map(Number);

    return {
      listingIds:
        (selectedListingIds?.length > 0 ? selectedListingIds : null) ||
        marketplaceEntityIds,
    };
  }, [filterQuery?.marketplaceEntityIds, selectedListingIds]);

  return !event ? null : (
    <PurchasesSideTable
      viagVirtualId={event.viagVirtualId}
      queryInput={listingIdFilterQuery}
    />
  );
};
