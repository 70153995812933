import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { AdGroupListingSelector } from 'src/components/Selectors/AdGroupListingSelector';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ListingIcon } from 'src/svgs';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import {
  EmptySelectionKey,
  getListingDisplaytext,
} from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import { Listing, Seating, TicketClassInfo } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export type ListingSectionInput = {
  listing: Listing | null | undefined;
};

export interface ListingSectionProps {
  onSelectListing: (
    listing: Listing | null | undefined,
    seating: Seating | null | undefined,
    ticketClass: TicketClassInfo | null | undefined
  ) => void;
  eventId?: number;
  ticketClass?: TicketClassInfo;
  seating?: Seating;
  listing: Listing | null | undefined;
  error?: string;
}

export const ListingSection = ({
  onSelectListing,
  eventId,
  ticketClass,
  seating,
  listing,
  error,
}: ListingSectionProps) => {
  const addListingDialog = useBasicDialog();
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const event = useMemo(() => {
    if (!eventId || !catalogData?.events?.[eventId.toString()]) {
      return undefined;
    }

    return catalogData.events[eventId.toString()].event;
  }, [catalogData, eventId]);

  const onAddListingSave = useCallback(
    (
      listing: Listing | null | undefined,
      seating: Seating | null | undefined,
      ticketClass: TicketClassInfo | null | undefined
    ) => {
      onSelectListing(listing, seating, ticketClass);
      addListingDialog.closeDialog();
    },
    [onSelectListing, addListingDialog]
  );

  const onAddListingCancel = useCallback(() => {
    addListingDialog.closeDialog();
  }, [addListingDialog]);

  const onAddListing = useCallback(() => {
    addListingDialog.launchDialog();
  }, [addListingDialog]);

  const contentNA = useContent(ContentId.NA);
  const sectionText = useContent(ContentId.Section);
  const rowText = useContent(ContentId.Row);
  const seatText = useContent(ContentId.Seat);
  const seatsText = useContent(ContentId.Seats);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (listing !== undefined) {
      let listingDisplayText = undefined;

      if (listing) {
        listingDisplayText = getListingDisplaytext(
          listing,
          sectionText,
          rowText,
          seatText,
          seatsText
        );
      }

      pills.push({
        value: 'listing',
        display: listingDisplayText ?? contentNA,
        icon: <ListingIcon />,
        onDelete: () => {
          onSelectListing(undefined, undefined, undefined);
        },
      });
    }

    return pills;
  }, [
    listing,
    contentNA,
    sectionText,
    rowText,
    seatText,
    seatsText,
    onSelectListing,
  ]);

  const enableButton = event;

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Listing} />
        </BodySectionTitle>
        {listing !== undefined ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button
              variant={'link'}
              onClick={onAddListing}
              disabled={!enableButton}
            >
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddListings} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addListingDialog.dialogProps.isOpen && (
        <EventMapContextProvider event={event}>
          <AddListingDialog
            {...addListingDialog.dialogProps}
            eventId={eventId}
            ticketClass={ticketClass}
            seating={seating}
            onSave={onAddListingSave}
            onCancel={onAddListingCancel}
          />
        </EventMapContextProvider>
      )}
    </>
  );
};

export type AddListingFieldValues = {
  listing: Listing | null | undefined;
  ticketClass: TicketClassInfo | null | undefined;
  seating: Seating | null | undefined;
};

export type AddListingDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (
    listing: Listing | null | undefined,
    seating: Seating | null | undefined,
    ticketClass: TicketClassInfo | null | undefined
  ) => void;
  onCancel: () => void;
  eventId?: number;
  ticketClass?: TicketClassInfo;
  seating?: Seating;
};

export const AddListingDialog = ({
  onSave,
  onCancel,
  eventId,
  ticketClass,
  seating,
  ...genericDialogProps
}: AddListingDialogProps) => {
  const addListingForm = useForm<AddListingFieldValues>({
    defaultValues: {
      listing: undefined,
    },
  });

  const listing = addListingForm.watch('listing');

  const onSubmit = useCallback(() => {
    onSave(
      listing,
      addListingForm.getValues('seating'),
      addListingForm.getValues('ticketClass')
    );
  }, [addListingForm, listing, onSave]);

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const getListingsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.events[eventId]?.entities?.listings;
    },
    [catalogData]
  );

  const getSectionsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.venueMapByEventId[eventId].sections;
    },
    [catalogData]
  );

  const listingsData = getListingsForEvent(eventId);
  const sectionsData = getSectionsForEvent(eventId);

  const listings = useMemo(() => {
    if (!listingsData) {
      return null;
    }

    return (
      listingsData.reduce(
        (acc, listing) => {
          let match = false;
          if (seating) {
            // Filter by section
            if (seating?.sectionId == listing.seating.sectionId) {
              match = true;
            }
          } else if (ticketClass) {
            // Filter by ticket class
            const row = getMatchingSectionRow(listing.seating, sectionsData);

            const ticketClassId = row.section?.specRow?.tktClass?.ticketClassId;
            if (ticketClassId == ticketClass.id) {
              match = true;
            }
          } else {
            match = true;
          }

          if (match) {
            acc[listing.idOnMkp!] = listing;
          }
          return acc;
        },
        {} as Record<string, Listing>
      ) ?? []
    );
  }, [listingsData, seating, sectionsData, ticketClass]);

  const setListing = useCallback(
    (listingId: string) => {
      if (!listings) {
        return;
      }

      const listing =
        listingId == EmptySelectionKey ? null : listings[listingId];

      addListingForm.setValue('seating', null);
      addListingForm.setValue('ticketClass', null);

      if (listing?.seating.sectionId) {
        addListingForm.setValue('seating', listing.seating);

        const row = getMatchingSectionRow(listing.seating, sectionsData);

        const ticketClass = row.section?.specRow?.tktClass;
        if (ticketClass) {
          addListingForm.setValue('ticketClass', {
            id: ticketClass?.ticketClassId,
            name: ticketClass?.ticketClassName,
            color: ticketClass?.color,
            cssPostFix: ticketClass?.cssPostFix,
          });
        }
      }

      addListingForm.clearErrors('listing');
      addListingForm.setValue('listing', listing);
    },
    [addListingForm, listings, sectionsData]
  );

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.Listing} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addListingForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addListingForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Listing} />}>
          <AdGroupListingSelector
            listingsData={listings}
            value={
              listing === null
                ? EmptySelectionKey
                : listing
                ? listing.idOnMkp!
                : undefined
            }
            onChange={(listingId) => {
              setListing(listingId);
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
