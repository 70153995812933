import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useAutoPricingSettingsSelectedSections } from 'src/components/AutoPricing/AutoPricingSelectedSectionSettingsInput/AutoPricingSelectedSectionSettingsInput.hooks';
import { SectionFilterRowNudger } from 'src/components/AutoPricing/components/SectionFilterRowNudger';
import { FilterPillList } from 'src/components/common/FilterPill';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack, Switch, TooltipPopover } from 'src/core/ui';
import { useAutoPricingSettings } from 'src/modals/ListingDetails/ListingForms/AutoPricingForm/useAutoPricingSettings';
import { ExpandIcon, FoldIcon, InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { CompListingQuantity } from 'src/WebApiController';

import * as styles from './CompPricingSettings.css';

const quantitiesOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10+',
].reduce(
  (r, c) => {
    r[c] = c;
    return r;
  },
  {} as Record<string, string>
);

export const CompPricingSettings = ({
  useVerticalView,
}: {
  useVerticalView?: boolean;
}) => {
  const { selectedListing } = useInventoryEventDetailContext();
  const { venueMapInfo } = useEventMapContext();

  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false);

  const { updateInput: watchValues, setValue } =
    useAutoPricingSettings(selectedListing);

  const {
    compListingExcludeFanInventory,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingQuantityFilters,
    compListingExcludeDefects,
    compListingSelectedSectionSettings,
  } = watchValues;

  const { onRowIdFiltersChange, onSectionIdFiltersChange } =
    useAutoPricingSettingsSelectedSections(venueMapInfo?.sections);

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections?.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [compListingSelectedSectionSettings?.sectionIdFilter, venueMapInfo]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const toggleShowAdvancedSettings = () => {
    setShowAdvancedSettings((prev) => !prev);
  };

  const parsedQuantityOverridesJson = useMemo(() => {
    return compListingQuantityScoreAdjustmentOverrideJson != null
      ? JSON.parse(compListingQuantityScoreAdjustmentOverrideJson)
      : null;
  }, [compListingQuantityScoreAdjustmentOverrideJson]);

  const derivedCompListingQuantityFilters =
    useMemo((): CompListingQuantity | null => {
      if (compListingQuantityFilters != null) {
        return compListingQuantityFilters;
      }

      if (parsedQuantityOverridesJson == null) {
        return null;
      }

      const { exact, gte } =
        parsedQuantityOverridesJson?.quantitiesFilter ?? {};
      return {
        exactQuantities: exact ?? [],
        greaterThanOrEqualToQuantity: gte,
      };
    }, [compListingQuantityFilters, parsedQuantityOverridesJson]);

  const selectedQuantitiesValues = useMemo(() => {
    if (!derivedCompListingQuantityFilters) {
      return [];
    }

    const { exactQuantities: exact, greaterThanOrEqualToQuantity: gte } =
      derivedCompListingQuantityFilters;

    const eligibleQuantities = Object.values(quantitiesOptions)
      .filter((value) => !isNaN(parseInt(value)))
      .map((value) => parseInt(value));
    const selectedQuantitiesInts = eligibleQuantities.filter((quantity) => {
      if (((exact as number[]) ?? []).includes(quantity)) {
        return true;
      }
      if (gte != null && (gte as number) <= quantity) {
        return true;
      }
      return false;
    });
    return Object.values(quantitiesOptions).filter((value) =>
      selectedQuantitiesInts.includes(parseInt(value))
    );
  }, [derivedCompListingQuantityFilters]);

  const handleOnChange = useCallback(
    (values: string[]) => {
      const exact = [];
      let gte: number | null = null;
      for (const value of values) {
        const quantity = parseInt(value);
        if (value.indexOf('+') > -1) {
          gte = quantity;
        } else {
          if (!isNaN(quantity)) {
            exact.push(quantity);
          }
        }
      }

      const compListingQuantityFiltersNew = {
        exactQuantities: exact,
        greaterThanOrEqualToQuantity: gte,
      };
      const compListingQuantityScoreAdjustmentOverrideJsonNew = JSON.stringify({
        ...parsedQuantityOverridesJson,
        quantitiesFilter: { exact, gte },
      });

      setValue('compListingQuantityFilters', compListingQuantityFiltersNew);
      setValue(
        'compListingQuantityScoreAdjustmentOverrideJson',
        compListingQuantityScoreAdjustmentOverrideJsonNew
      );
    },
    [parsedQuantityOverridesJson, setValue]
  );

  return (
    <div className={styles.compSettingsContainer}>
      <Stack width="full" gap="m" alignItems="center">
        <FilterPillList
          selectedSections={selectedSections}
          rowIdFilters={rowIdFilters}
          sectionRowIdFilters={sectionRowIdFilters}
          scrollButtonClassName={styles.pillItemsScrollButton}
          applyRowFilterChanges={onRowIdFiltersChange}
          applySelectedSectionChanges={onSectionIdFiltersChange}
          useCondensedBorders={true}
        />
        {!!Object.entries(sectionRowIdFilters).length && (
          <SectionFilterRowNudger
            selectedSections={selectedSections}
            rowIdFilters={rowIdFilters}
            sectionRowIdFilters={sectionRowIdFilters}
            applyRowFilterChanges={onRowIdFiltersChange}
            isCondensedView={true}
          />
        )}
      </Stack>

      <Stack gap="s" alignItems="center" className={styles.qtyFilterWrapper}>
        <PosMultiSelect
          align="start"
          fullWidth
          leftIcon={
            <div className={styles.qtyText}>
              <Content id={ContentId.Quantity} />:
            </div>
          }
          values={selectedQuantitiesValues}
          onChange={handleOnChange}
          valueOptionsContent={quantitiesOptions}
          valuesDisplayText={selectedQuantitiesValues.join(', ')}
          triggerProps={{ style: { width: '100%' } }}
        />
        {useVerticalView && (
          <Popover.Root>
            <Popover.Trigger asChild>
              <Button
                variant="textPlain"
                style={{ padding: vars.spacing['xs'], whiteSpace: 'nowrap' }}
              >
                <div style={{ fontWeight: 400 }}>
                  <Content id={ContentId.Advanced} />
                </div>
              </Button>
            </Popover.Trigger>
            <Popover.Content>
              <Stack
                direction="column"
                gap="s"
                className={styles.advancedSettingsPopoverContainer}
              >
                <Stack direction="row" justifyContent="spaceBetween">
                  <div className={styles.toggleLabel}>
                    <TooltipPopover
                      triggerContent={
                        <InfoOutlineIcon
                          withHoverEffect
                          size={vars.iconSize.s}
                        />
                      }
                    >
                      <Content id={ContentId.ExcludeFanInventoryInfo} />
                    </TooltipPopover>
                    <Content id={ContentId.ExcludeFanInventory} />
                  </div>
                  <Switch
                    checked={compListingExcludeFanInventory ?? false}
                    onClick={(e) => e.stopPropagation()}
                    onCheckedChange={(isChecked) => {
                      const compListingExcludeFanInventoryNew = isChecked;
                      if (
                        compListingExcludeFanInventoryNew !==
                        compListingExcludeFanInventory
                      ) {
                        setValue(
                          'compListingExcludeFanInventory',
                          compListingExcludeFanInventoryNew
                        );
                      }
                    }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="spaceBetween">
                  <div className={styles.toggleLabel}>
                    <TooltipPopover
                      triggerContent={
                        <InfoOutlineIcon
                          withHoverEffect
                          size={vars.iconSize.s}
                        />
                      }
                    >
                      <Content id={ContentId.ExcludeDefectsInfo} />
                    </TooltipPopover>
                    <Content id={ContentId.IncludeDefects} />
                  </div>
                  <Switch
                    checked={!(compListingExcludeDefects ?? true)}
                    onClick={(e) => e.stopPropagation()}
                    onCheckedChange={(isChecked) => {
                      const compListingExcludeDefectsNew = !isChecked;

                      if (
                        compListingExcludeDefectsNew !==
                        compListingExcludeDefects
                      ) {
                        setValue(
                          'compListingExcludeDefects',
                          compListingExcludeDefectsNew
                        );
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </Popover.Content>
          </Popover.Root>
        )}
      </Stack>

      {!useVerticalView && (
        <>
          <Stack
            justifyContent="spaceBetween"
            alignItems="center"
            className={clsx(styles.advancedSettingsContainer, {
              [styles.advancedSettingsContainerHide]: !showAdvancedSettings,
            })}
            onClick={toggleShowAdvancedSettings}
          >
            {showAdvancedSettings ? (
              <>
                <Content id={ContentId.HideAdvancedSettings} />
                <FoldIcon size={vars.iconSize.m} />
              </>
            ) : (
              <>
                <Content id={ContentId.ShowAdvancedSettings} />
                <ExpandIcon size={vars.iconSize.m} />
              </>
            )}
          </Stack>

          {showAdvancedSettings && (
            <div className={styles.advancedSettingsRow}>
              <div className={styles.advancedSettingToggle}>
                <div className={styles.toggleLabel}>
                  <TooltipPopover
                    triggerContent={
                      <InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />
                    }
                  >
                    <Content id={ContentId.ExcludeFanInventoryInfo} />
                  </TooltipPopover>
                  <Content id={ContentId.ExcludeFanInventory} />
                </div>
                <Switch
                  checked={compListingExcludeFanInventory ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    const compListingExcludeFanInventoryNew = isChecked;
                    if (
                      compListingExcludeFanInventoryNew !==
                      compListingExcludeFanInventory
                    ) {
                      setValue(
                        'compListingExcludeFanInventory',
                        compListingExcludeFanInventoryNew
                      );
                    }
                  }}
                />
              </div>
              <div className={styles.advancedSettingToggle}>
                <div className={styles.toggleLabel}>
                  <TooltipPopover
                    triggerContent={
                      <InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />
                    }
                  >
                    <Content id={ContentId.ExcludeDefectsInfo} />
                  </TooltipPopover>
                  <Content id={ContentId.IncludeDefects} />
                </div>
                <Switch
                  checked={!(compListingExcludeDefects ?? true)}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    const compListingExcludeDefectsNew = !isChecked;

                    if (
                      compListingExcludeDefectsNew !== compListingExcludeDefects
                    ) {
                      setValue(
                        'compListingExcludeDefects',
                        compListingExcludeDefectsNew
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
