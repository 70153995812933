import clsx from 'clsx';
import { debounce } from 'lodash-es';
import {
  CSSProperties,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from 'react';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  SelectedFilters,
} from 'src/components/Filters';
import {
  AddFilterButtonPlain,
  dropdownGroupContainer,
  dropdownItemParentLabel,
  notFoundOption,
  searchDropdownItem,
} from 'src/components/FilterToolbar/FilterDialogV2/FiltersDropdown/FiltersDropdown.css';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

interface FiltersDropdownProps {
  filters: FilterToolbarGroup[];
  selectedDropdownItems: SelectedFilters;
  onFilterSelected: (filterDropdownItem: FilterDropdownItem[]) => void;
}

const triggerStyles: CSSProperties = {
  width: '100%',
};

export const FiltersDropdown = ({
  filters,
  selectedDropdownItems,
  onFilterSelected,
}: FiltersDropdownProps) => {
  const hasFiltersApplied = Object.keys(selectedDropdownItems).length > 0;
  const [searchText, setSearchText] = useState<string>('');
  const searchPlaceholder = useContent(ContentId.Search);
  const contentContext = useContentContext();
  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  // NOTE:
  // This is a wishy-washy way to do this, we already have debounce - so this should be be needed
  // but since this has been working - let's keep it like this
  // If the search drop-down ever not respond to text search - remove this line
  const searchDeferredValue = useDeferredValue(searchText);

  // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
  const updateSearchText = useCallback(
    debounce((value: string) => {
      setSearchText(value);
    }, 200),
    []
  );

  const filteredFilters = useMemo<FilterToolbarGroup[]>(() => {
    if (!searchDeferredValue) {
      return filters;
    }
    const lowercaseSearchText = searchDeferredValue.toLocaleLowerCase();
    return filters
      .map((filter) => {
        const items = filter.items.filter((filterItem) => {
          const labelValue = getContent(
            filterItem.labelContentId,
            contentContext
          );
          return (
            labelValue.toLocaleLowerCase().indexOf(lowercaseSearchText) !== -1
          );
        });
        return {
          ...filter,
          items,
        };
      })
      .filter((filter) => filter.items.length > 0);
  }, [contentContext, filters, searchDeferredValue]);

  const clearSearch = useCallback(() => {
    setSearchText('');
  }, []);

  return (
    <PosDropdown
      key="event-action-dropdown"
      trigger={
        <Button
          variant="textPlain"
          className={clsx({ [AddFilterButtonPlain]: hasFiltersApplied })}
          onClick={clearSearch}
        >
          <PlusIcon
            withHoverEffect
            size={vars.iconSize.m}
            fill={IconsFill.textBrand}
          />
          <Content id={ContentId.AddFilter} />
        </Button>
      }
      triggerProps={{
        style: triggerStyles,
      }}
      align="start"
    >
      <PosDropdownItem
        keepOpenAfterSelection={true}
        className={searchDropdownItem}
      >
        <PosSearchBox
          placeholder={searchPlaceholder}
          width="100%"
          onSearchChange={updateSearchText}
          rootProps={{
            shape: 'square',
          }}
          focusOnMount={true}
        />
      </PosDropdownItem>

      {filteredFilters.flatMap((filtersToolbarGroup, i) => {
        const items = filtersToolbarGroup.items.map((filterItem) => {
          return (
            <PosDropdownItem
              key={filterItem.filterId}
              onClick={() => {
                onFilterSelected([
                  {
                    filterId: filterItem.filterId,
                    titleContentId: filtersToolbarGroup.titleContentId,
                  },
                ]);
              }}
            >
              {filterItem.postfixIcon && hasMetricsV2Feature ? (
                <Stack
                  gap="m"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  width="full"
                >
                  <Content id={filterItem.labelContentId} />
                  {filterItem.postfixIcon}
                </Stack>
              ) : (
                <Content id={filterItem.labelContentId} />
              )}
            </PosDropdownItem>
          );
        });
        return [
          <div key={`item-group-${filtersToolbarGroup.titleContentId}-${i}`}>
            <div className={dropdownGroupContainer}>
              <PosDropdownItem
                keepOpenAfterSelection
                className={dropdownItemParentLabel}
              >
                <Content id={filtersToolbarGroup.titleContentId} />
              </PosDropdownItem>
              {items}
            </div>
          </div>,
        ];
      })}

      {filteredFilters.length === 0 && (
        <PosDropdownItem keepOpenAfterSelection className={notFoundOption}>
          <Content id={ContentId.NoResultFound} />
        </PosDropdownItem>
      )}
    </PosDropdown>
  );
};
