import { useMemo } from 'react';
import { SalesSideTable } from 'src/components/Sales/SaleEventPage/SaleSideTable';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import { ListingQuery } from 'src/WebApiController';


export const SaleDetailPanel = () => {
  const { event, selectedListingIds, filterSections } =
    useInventoryEventDetailContext();

  const { filterQuery } = useFilterQueryContext<ListingQuery>();

  const listingIdFilterQuery = useMemo(() => {
    const marketplaceEntityIds = filterQuery?.marketplaceEntityIds?.map(Number);

    return {
      listingIds:
        (selectedListingIds?.length > 0 ? selectedListingIds : null) ||
        marketplaceEntityIds,
    };
  }, [filterQuery?.marketplaceEntityIds, selectedListingIds]);

  return !event ? null : (
    <SalesSideTable
      viagVirtualId={event.viagVirtualId}
      selectedSections={filterSections}
      queryInput={listingIdFilterQuery}
    />
  );
};
