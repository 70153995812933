import { useFormContext } from 'react-hook-form';
import { LinkMarketplacePaymentToSaleInput } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog/LinkMarketplacePaymentToSaleDialog';
import { MarketplacePaymentLineType, SaleCostType } from 'src/WebApiController';

export const useGetRemainingBalance = () => {
  const { watch } = useFormContext<LinkMarketplacePaymentToSaleInput>();

  const saleLineItems = watch('saleLineItems') ?? [];
  const marketplacePaymentLine = watch('marketplacePaymentLine');

  const isCredit =
    marketplacePaymentLine?.type === MarketplacePaymentLineType.Credit;

  if (!marketplacePaymentLine) {
    return { remainingAmount: 0, isCredit };
  }

  // Only charge/credit that is linked to current payment id
  const saleLineItemsAmount = saleLineItems.reduce((acc, saleLineItem) => {
    let shouldCount = true;

    if (isCredit && saleLineItem.costType !== SaleCostType.Credit) {
      shouldCount = false;
    }

    if (!isCredit && saleLineItem.costType !== SaleCostType.Charge) {
      shouldCount = false;
    }

    if (saleLineItem.marketplacePaymentLineId !== marketplacePaymentLine.id) {
      shouldCount = false;
    }

    if (shouldCount) {
      acc += (isCredit ? 1 : -1) * saleLineItem.cost;
    }

    return acc;
  }, 0);

  return {
    remainingAmount: marketplacePaymentLine.amt.amt - saleLineItemsAmount,
    isCredit,
  };
};
