import { clsx } from 'clsx';
import { Content } from 'src/contexts/ContentContext';
import { SimpleTable, Stack } from 'src/core/ui';
import { ListingLinksDetail } from 'src/modals/SaleDetails/components/ListingLinksDetail';
import * as styles from 'src/modals/SaleDetailsV2/components/SaleTickets/SaleTickets.css';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

import * as tableStyles from '../../../SaleDetails/SaleDetailsTable.css';

interface OriginalListingProps {
  sale: SaleDetails;
}

export const OriginalListing = ({ sale }: OriginalListingProps) => {
  if (!sale.mkpSeating) {
    return null;
  }

  const { section, row, seatFr, seatTo } = sale.mkpSeating;

  return (
    <div>
      <Stack gap="xxl" alignItems="center">
        <span className={styles.tableTitle}>
          <Content id={ContentId.OriginatedListing} />
        </span>
        <ListingLinksDetail
          listingId={sale.originatedFromListingId}
          showTitle={false}
        />
      </Stack>
      <div className={tableStyles.tableWrapper}>
        <SimpleTable.Table className={tableStyles.table}>
          <SimpleTable.Thead showBottomBorder={false}>
            <SimpleTable.Tr className={tableStyles.tableRow}>
              <SimpleTable.Th
                className={clsx(
                  tableStyles.tableHeader,
                  tableStyles.sectionCell
                )}
              >
                <Content id={ContentId.Section} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.Row} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.Seats} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={clsx(tableStyles.tableHeader, tableStyles.smallCell)}
              >
                <Content id={ContentId.QuantityAbbreviated} />
              </SimpleTable.Th>
              <SimpleTable.Th className={tableStyles.tableHeader} />
              <SimpleTable.Th className={tableStyles.tableHeader} />
              <SimpleTable.Th className={tableStyles.tableHeader} />
              <SimpleTable.Th className={tableStyles.tableHeader} />
            </SimpleTable.Tr>
          </SimpleTable.Thead>
          <SimpleTable.Tbody>
            <SimpleTable.Tr className={tableStyles.tableRow}>
              <SimpleTable.Td
                className={clsx(tableStyles.tableCell, tableStyles.sectionCell)}
              >
                {section}
              </SimpleTable.Td>
              <SimpleTable.Td
                className={clsx(tableStyles.tableCell, tableStyles.smallCell)}
              >
                {row}
              </SimpleTable.Td>
              <SimpleTable.Td
                className={clsx(tableStyles.tableCell, tableStyles.smallCell)}
              >
                {seatFr} - {seatTo}
              </SimpleTable.Td>
              <SimpleTable.Td
                className={clsx(tableStyles.tableCell, tableStyles.smallCell)}
              >
                {sale.qtySold}
              </SimpleTable.Td>
              <SimpleTable.Td className={tableStyles.tableCell} />
              <SimpleTable.Td className={tableStyles.tableCell} />
              <SimpleTable.Td className={tableStyles.tableCell} />
              <SimpleTable.Td className={tableStyles.tableCell} />
            </SimpleTable.Tr>
          </SimpleTable.Tbody>
        </SimpleTable.Table>
      </div>
    </div>
  );
};
