import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { useAutoCompMapSectionHandler } from 'src/hooks/useAutoCompMapSectionHandler';
import {
  HeatMapColors,
  HeatMapColorSteps,
  useHeatMapColor,
} from 'src/hooks/useHeatMapColor';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventSectionClearingPrices,
  InventoryEventDashboardClient,
  InventoryMetricsQueryId,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import { HeaderWidgetContainer } from '../../HeaderWidgetContainer';
import { EventSectionClearingPriceSectionDisplay } from './EventSectionClearingPriceSectionDisplay';
import { EventSectionClearingPricesTable } from './EventSectionClearingPricesTable';

const reversedHeatMapColorsForALeftToRightLegend = [...HeatMapColors].reverse();

export const EventSectionClearingPricesWidgetsContainer = ({
  event,
  isOnSaleEvent,
}: {
  event: Event | undefined;
  isOnSaleEvent?: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const { isMapLoading } = useEventMapContext();

  const sectionClearingPricesText = useContent(ContentId.SectionClearingPrices);

  const posId = useMemo(() => {
    const posIds = event?.posIds;
    return posIds && posIds.length > 0 ? posIds[0] : undefined;
  }, [event]);
  const viagId = useMemo(() => event?.viagId ?? null, [event?.viagId]);

  const {
    selectedSectionIds,
    onSectionClicked,
    onToggleMirrors,
    onSelectedSectionsChange,
  } = useAutoCompMapSectionHandler(() => {});

  const {
    isPending,
    error,
    data: eventSectionClearingPrices,
  } = useQuery({
    queryKey: ['getEventSectionClearingPrices', posId, viagId, isOnSaleEvent],
    queryFn: () => {
      return tryInvokeApi(
        async () => {
          const client = new InventoryEventDashboardClient(
            activeAccountWebClientConfig
          );
          const queryId: InventoryMetricsQueryId = {
            posEventId: posId ?? null,
            viagogoEventId: isOnSaleEvent ? viagId : null,
          };
          const data = await client.getEventSectionClearingPrices(queryId);

          return data;
        },
        (error) => {
          trackError(
            'InventoryEventDashboardClient.getEventSectionClearingPrices',
            error,
            event
          );
        }
      );
    },
    refetchOnWindowFocus: false,
    meta: {
      persist: false, // we do not want this thing persisted between sessions
    },
  });

  const [sectionClearingPricesBySectionId, sectionScoreOverrides] =
    useMemo(() => {
      const sectionClearingPricesBySectionId: Record<
        number,
        EventSectionClearingPrices
      > = {};
      const sectionScoreOverrides: SectionScoreOverride[] = [];
      for (const s of eventSectionClearingPrices ?? []) {
        if (s.sectionId) {
          sectionClearingPricesBySectionId[s.sectionId] = s;
        }

        sectionScoreOverrides.push({
          sectionId: s.sectionId,
          rowId: 0,
          score: s.normalizedScore,
        } as SectionScoreOverride);
      }
      return [sectionClearingPricesBySectionId, sectionScoreOverrides];
    }, [eventSectionClearingPrices]);

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => (
      <EventSectionClearingPriceSectionDisplay
        section={hoveredSection}
        sectionClearingPricesBySectionId={sectionClearingPricesBySectionId}
      />
    ),
    [sectionClearingPricesBySectionId]
  );

  const { getColor } = useHeatMapColor(sectionScoreOverrides);

  const filteredEventSectionClearingPrices = useMemo(
    () =>
      selectedSectionIds?.length > 0
        ? eventSectionClearingPrices?.filter(
            (s) => s.sectionId && selectedSectionIds.includes(s.sectionId)
          )
        : eventSectionClearingPrices,
    [eventSectionClearingPrices, selectedSectionIds]
  );

  return (
    <>
      <HeaderWidgetContainer title={sectionClearingPricesText} />
      <Stack direction="responsive" gap="xl" width="full">
        {isPending || isMapLoading ? (
          <PosSpinner />
        ) : (
          <>
            <div style={{ flex: '1 1 60%' }}>
              <EventSectionClearingPricesTable
                sectionClearingPrices={
                  filteredEventSectionClearingPrices ?? undefined
                }
              />
            </div>
            <div style={{ minWidth: '40vh', minHeight: '40vh' }}>
              <EventVenueMap
                getColor={getColor}
                onSectionHovered={onSectionHovered}
                selectedSectionIds={Array.from(selectedSectionIds)}
                markedSectionIds={Array.from(selectedSectionIds)}
                onSectionClicked={onSectionClicked}
                onToggleMirrors={onToggleMirrors}
                setSelectedSections={onSelectedSectionsChange}
                colorBandProps={{
                  numOfSteps: HeatMapColorSteps,
                  spectrum: reversedHeatMapColorsForALeftToRightLegend,
                  labels: [ContentId.LessSales, ContentId.MoreSales],
                }}
              />
            </div>
          </>
        )}
      </Stack>
    </>
  );
};
