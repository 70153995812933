import { fromPairs, isEqual } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { getFlattenedListingIds } from 'src/utils/inventoryUtils';
import {
  EventWithData,
  Listing,
  PurchaseOrderTicketGroup,
  Sale,
  TimePeriodContainingPurchaseOrderResult,
} from 'src/WebApiController';

import { NO_GROUP_ID } from '../MultiSelectionContext';
import { packNumericIds } from '../MultiSelectionContext/MultiSelectionContext.utils';
import { usePurchaseDataContext } from '../PurchaseDataContext';

export type MultiSelectionType =
  | 'listing'
  | 'sale'
  | 'purchase'
  | 'ticketGroup';

/** Metadata of the selectable group
 * @prop id - ID of the group (Event ID or month)
 * @prop comboId - Combo ID for related items (ViagogoVirtualId for combined events)
 * @prop itemCount - count of total items in the group - used to 1) add to total selected count when group is selected and 2) determine if group is fully selected
 * @prop unfilteredItemCount - count of total items in the group - used to restore itemCount when column filter is removed
 * @prop filteredIds - list of filtered IDs in the group - if undefined, all items are considered selected. If defined, represents full list of ids available for selection
 */
export type SelectableGroupMetadata = {
  id: string;
  comboId?: string;
  itemCount: number;
  unfilteredItemCount: number;
  filteredIds?: (number | string)[];
};

export type ICatalogMultiSelectionContext = {
  flattenedIds: (number | string)[] | undefined;
  unfilteredFlattenedIds: (number | string)[] | undefined;
  groupsArray: SelectableGroupMetadata[];
  updateGroupFilteredIds: (
    groupId: string,
    filteredIds: (number | string)[] | undefined
  ) => void;
};

const emptyContext: ICatalogMultiSelectionContext = {
  flattenedIds: undefined,
  unfilteredFlattenedIds: undefined,
  groupsArray: [],
  updateGroupFilteredIds: () => {},
};

export const CatalogMultiSelectionContext =
  createContext<ICatalogMultiSelectionContext>(emptyContext);

export const useCatalogMultiSelectionContext = () =>
  useContext(CatalogMultiSelectionContext);

function transformGroupsArray(
  data: EventWithData[] | TimePeriodContainingPurchaseOrderResult[] | undefined,
  type: MultiSelectionType,
  isPurchaseFlattenedView?: boolean
): SelectableGroupMetadata[] {
  if (type === 'purchase' && !isPurchaseFlattenedView) {
    return ((data ?? []) as TimePeriodContainingPurchaseOrderResult[]).map(
      (monthData) => ({
        id: monthData.firstOfTimePeriod,
        itemCount: monthData.purchaseCount,
        unfilteredItemCount: monthData.purchaseCount,
      })
    );
  }
  return ((data ?? []) as EventWithData[]).map((event) => {
    const unfilteredItemCount =
      type === 'listing'
        ? event.counts.listCnt ?? 0
        : type === 'ticketGroup'
        ? event.counts.tktGrpsCnt ?? 0
        : type === 'purchase'
        ? event.counts.poCnt ?? 0
        : event.counts.salesCnt ?? 0;
    return {
      id: event.event.viagVirtualId,
      comboId: event.event.viagVirtualId,
      itemCount: unfilteredItemCount,
      unfilteredItemCount,
    };
  });
}

export function CatalogMultiSelectionContextProvider({
  children,
  type,
}: {
  children: ReactNode;
  type: MultiSelectionType;
}) {
  const { data, eventsTransformed } = useCatalogDataContext();
  const {
    timePeriodPurchasesQuery,
    allPurchasesFiltered,
    isFlattenedView: isPurchaseFlattenedView,
  } = usePurchaseDataContext();
  const inputArray = useMemo(
    () =>
      type === 'purchase' && !isPurchaseFlattenedView
        ? timePeriodPurchasesQuery?.data ?? []
        : eventsTransformed,
    [
      eventsTransformed,
      isPurchaseFlattenedView,
      timePeriodPurchasesQuery?.data,
      type,
    ]
  );
  const inputObject = useMemo(
    () =>
      type === 'purchase' && !isPurchaseFlattenedView
        ? fromPairs(
            (timePeriodPurchasesQuery?.data ?? []).map((monthData) => [
              monthData.firstOfTimePeriod,
              monthData,
            ])
          )
        : data?.events,
    [
      data?.events,
      isPurchaseFlattenedView,
      timePeriodPurchasesQuery?.data,
      type,
    ]
  );

  const [flattenedIds, setFlattenedIDs] = useState<
    (number | string)[] | undefined
  >();
  const [unfilteredFlattenedIds, setUnfilteredFlattenedIDs] = useState<
    (number | string)[] | undefined
  >();

  const [groupsArray, setGroupsArray] = useState<SelectableGroupMetadata[]>(
    transformGroupsArray(inputArray, type, isPurchaseFlattenedView)
  );

  useEffect(() => {
    const allItems =
      type === 'purchase' && allPurchasesFiltered
        ? allPurchasesFiltered
        : ((inputArray ?? []) as EventWithData[]).reduce(
            (all, current: EventWithData) => [
              ...all,
              ...(current?.entities?.listings ??
                current?.entities?.sales ??
                current?.entities?.ticketGroups ??
                []),
            ],
            [] as (Listing | Sale | PurchaseOrderTicketGroup)[]
          );
    const flattened =
      allItems.length > 0 && (allItems[0] as Listing).entityType === 'Listing'
        ? getFlattenedListingIds(allItems as Listing[])
        : type === 'ticketGroup'
        ? allItems.map((item) =>
            packNumericIds([
              item.id,
              (item as PurchaseOrderTicketGroup).tgId ?? 0,
            ])
          )
        : allItems.map((item) => item.id);
    setFlattenedIDs(flattened.length > 0 ? flattened : undefined);
    setUnfilteredFlattenedIDs(flattened.length > 0 ? flattened : undefined);
    setGroupsArray(
      transformGroupsArray(inputArray, type, isPurchaseFlattenedView)
    );
  }, [
    inputObject,
    inputArray,
    type,
    allPurchasesFiltered,
    isPurchaseFlattenedView,
  ]);

  const updateGroupFilteredIds = useCallback(
    (groupId: string, filteredIds: (number | string)[] | undefined) => {
      // Important - avoid calling set state if no change otherwise table can re-render unnecessarily
      if (groupId === NO_GROUP_ID) {
        if (filteredIds === undefined) {
          if (!isEqual(flattenedIds, unfilteredFlattenedIds)) {
            setFlattenedIDs(unfilteredFlattenedIds);
          }
        } else {
          if (!isEqual(flattenedIds, filteredIds)) {
            setFlattenedIDs(filteredIds);
          }
        }
      }

      const groupIndex = groupsArray.findIndex((group) => group.id === groupId);
      if (groupIndex === -1) {
        return;
      }

      if (filteredIds === undefined) {
        if (
          groupsArray[groupIndex].filteredIds === undefined &&
          groupsArray[groupIndex].itemCount ===
            groupsArray[groupIndex].unfilteredItemCount
        ) {
          return;
        }
      } else {
        if (isEqual(groupsArray[groupIndex].filteredIds, filteredIds)) {
          return;
        }
      }

      setGroupsArray((prev) => {
        const newGroupsArray = [...prev];

        if (filteredIds === undefined) {
          newGroupsArray[groupIndex] = {
            ...newGroupsArray[groupIndex],
            filteredIds: undefined,
            itemCount: newGroupsArray[groupIndex]?.unfilteredItemCount,
          };
        } else {
          newGroupsArray[groupIndex] = {
            ...newGroupsArray[groupIndex],
            filteredIds,
            itemCount: filteredIds.length,
          };
        }

        return newGroupsArray;
      });
    },
    [flattenedIds, groupsArray, unfilteredFlattenedIds]
  );

  return (
    <CatalogMultiSelectionContext.Provider
      value={{
        flattenedIds,
        unfilteredFlattenedIds,
        groupsArray,
        updateGroupFilteredIds,
      }}
    >
      {children}
    </CatalogMultiSelectionContext.Provider>
  );
}
