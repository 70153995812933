import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useColumnResizingContext } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { vars } from 'src/core/themes';
import { SortButton, SortButtonV2 } from 'src/core/ui/SortButton';
import { SortStatus } from 'src/core/ui/SortButton/SortButton.utils';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { pinnedCell } from 'src/tables/Table/components/TableTd.css';
import { PinnedCellGradient } from 'src/tables/Table/TableCells/PinnedCellGradient';
import { Feature } from 'src/WebApiController';
import styled from 'styled-components/macro';

import { getCommonPinningStyles } from '../Table.utils';
import * as styles from './ColumnHeader.css';
import { resizerWidth } from './ColumnHeader.css';
import { TableHeaderProps } from './ColumnHeader.types';
import { ColumnHeaderFilter } from './ColumnHeaderFilter';

function getSortStatus(canSort: boolean, sortOrder: string | boolean) {
  if (!canSort || !sortOrder) {
    return SortStatus.UNSORTED;
  }

  return sortOrder === 'asc' ? SortStatus.ASC : SortStatus.DESC;
}

export const ColumnHeader = ({ table, header, style }: TableHeaderProps) => {
  const headerRef = useRef<HTMLTableCellElement | null>(null);
  const canSort = header.column.getCanSort();
  const canColumnFilter = header.column.getCanFilter();
  const sortStatus = getSortStatus(canSort, header.column.getIsSorted());
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const { isResizeEnabled } = useColumnResizingContext();
  const isColumnSorted = Boolean(header.column.getIsSorted());
  const isPinned = header.column.getIsPinned();
  const hasCleanupWhiteSpaceFeature = useUserHasFeature(
    Feature.CleanupWhiteSpace
  );
  const hasTableColumnFilterFeature = useUserHasFeature(
    Feature.TableColumnFilter
  );
  const [isHovered, setIsHovered] = useState(false);

  const showSortIcon =
    (hasTableColumnFilterFeature && isHovered) || isColumnSorted;

  return (
    <ColumnHeaderTh
      ref={headerRef}
      className={clsx(styles.columnHeaderTh, {
        [styles.columnHeaderThSorted]: !!header.column.getIsSorted(),
        [pinnedCell]: isPinned,
      })}
      isSorted={isColumnSorted}
      hasCleanupWhiteSpaceFeature={hasCleanupWhiteSpaceFeature}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...{
        onClick: () => {
          if (canSort) {
            header.column.toggleSorting();
          }

          const canGroup = !!header.column.columnDef.getGroupingValue;

          if (!canGroup || header.column.getIsGrouped()) {
            return;
          }

          // If it's already grouped by this column, then return;
          if (table.getState().grouping?.includes(header.column.id)) {
            return;
          }

          // Reset grouping state to group by the new column
          table.resetGrouping();

          // toggle grouping
          header.column.toggleGrouping();
        },
      }}
      {...(canSort ? { cursor: 'pointer' } : {})}
      {...{
        paddingLeft: isLargeDesktop
          ? header.column.columnDef.meta?.paddingLeftLargeDesktop
          : header.column.columnDef.meta?.paddingLeft,
        // This is needed for text overflow to work
        width: header.getSize(),
        minWidth: isResizeEnabled
          ? header.getSize()
          : header.column.columnDef.minSize,
        maxWidth: isResizeEnabled
          ? header.getSize()
          : header.column.columnDef.maxSize,
        styleOverrides: {
          ...getCommonPinningStyles(header.column),
          ...header.column.columnDef.meta?.styleOverrides,
        },
      }}
      {...style}
    >
      {isPinned && (
        <PinnedCellGradient height={headerRef.current?.clientHeight ?? 0} />
      )}
      <HeaderContentWrapper>
        <LabelWrapper
          hasCleanupWhiteSpaceFeature={hasCleanupWhiteSpaceFeature}
          showSortIcon={showSortIcon}
          showFilterIcon={hasTableColumnFilterFeature && canColumnFilter}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
        </LabelWrapper>
      </HeaderContentWrapper>

      {hasTableColumnFilterFeature ? (
        <HeaderActionWrapper
          hasCleanupWhiteSpaceFeature={hasCleanupWhiteSpaceFeature}
        >
          {canColumnFilter && (
            <ColumnHeaderFilter header={header} table={table} />
          )}
          {canSort && (
            <IconWrapperV2 showSortIcon={showSortIcon}>
              <SortButtonV2 sortStatus={sortStatus} />
            </IconWrapperV2>
          )}
        </HeaderActionWrapper>
      ) : (
        <>
          {canSort && (
            <IconWrapper
              showSortIcon={showSortIcon}
              hasCleanupWhiteSpaceFeature={hasCleanupWhiteSpaceFeature}
            >
              <SortButton sortStatus={sortStatus} />
            </IconWrapper>
          )}
        </>
      )}

      {isResizeEnabled && header.column.getCanResize() ? (
        <div
          {...{
            onClick: (e) => e.stopPropagation(),
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `${styles.resizer} ${
              header.column.getIsResizing() ? styles.resizing : ''
            }`,
          }}
        />
      ) : null}
    </ColumnHeaderTh>
  );
};

const HeaderActionWrapper = styled.div<{
  hasCleanupWhiteSpaceFeature: boolean;
}>`
  right: ${({ hasCleanupWhiteSpaceFeature }) =>
    hasCleanupWhiteSpaceFeature ? 0 : resizerWidth};
  top: 0;
  height: 100%;
  padding: 0 2px;
  position: absolute;
  display: flex;
  flex-direction: row;
`;

const IconWrapperV2 = styled.span<{
  showSortIcon: boolean;
}>`
  height: 100%;
  padding: 0 2px;
  ${({ showSortIcon }) => ({
    display: showSortIcon ? 'flex' : 'none',
    alignItems: 'center',
  })};

  > div {
    height: 18px;
  }
`;

const IconWrapper = styled.span<{
  showSortIcon: boolean;
  hasCleanupWhiteSpaceFeature: boolean;
}>`
  right: ${({ hasCleanupWhiteSpaceFeature }) =>
    hasCleanupWhiteSpaceFeature ? 0 : resizerWidth};
  top: 0;
  height: 100%;
  padding: 0 2px;
  position: absolute;
  ${({ showSortIcon }) => ({
    display: showSortIcon ? 'flex' : 'none',
    alignItems: 'center',
  })};

  > div {
    height: 18px;
  }
`;

const ColumnHeaderTh = styled.th.attrs(
  (props: {
    paddingLeft?: string;
    paddingTop?: string;
    paddingBottom?: string;
    fontSize?: string;
    fontWeight?: number;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    cursor?: string;
    styleOverrides?: React.CSSProperties;
  }) => ({
    style: {
      paddingLeft: props.paddingLeft?.toString(),
      paddingTop: props.paddingTop?.toString(),
      paddingBottom: props.paddingBottom?.toString(),
      fontSize: props.fontSize?.toString(),
      fontWeight: props.fontWeight?.toString(),
      width: props.width ? `${props.width}px` : 'auto',
      minWidth: props.minWidth ? `${props.minWidth}px` : 'auto',
      maxWidth: props.maxWidth ? `${props.maxWidth}px` : 'auto',
      cursor: props.cursor,
      ...props.styleOverrides,
    },
  })
)<{ isSorted: boolean; hasCleanupWhiteSpaceFeature: boolean }>`
  user-select: none;
  position: ${({ hasCleanupWhiteSpaceFeature }) =>
    hasCleanupWhiteSpaceFeature ? 'relative' : 'static'};
  &:hover ${IconWrapper} {
    display: flex;
    align-items: center;
    background-color: ${vars.color.backgroundSecondary};
  }
`;

const LabelWrapper = styled.span<{
  showSortIcon: boolean;
  showFilterIcon: boolean;
  hasCleanupWhiteSpaceFeature: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: ${({
    hasCleanupWhiteSpaceFeature,
    showSortIcon,
    showFilterIcon,
  }) =>
    hasCleanupWhiteSpaceFeature
      ? '0px'
      : showSortIcon && showFilterIcon
      ? '26px'
      : '12px'};
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
