import { isEqual } from 'lodash-es';
import {
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
} from 'src/components/Filters';
import {
  CatalogResults,
  DateTimeRange,
  DateTimeRangeWithRelative,
  EntityWithTicketsQuery,
  EventGroupingQuery,
  EventGroupingSortBy,
  EventGroupingType,
  EventSort,
  EventTimeFrameFilter,
  EventWithData,
  InventoryViewMode,
  ListingQuery,
  OnSaleEventQuery,
  OnSaleEventViewMode,
  PointOfSaleSaleStatus,
  PurchaseOrderQuery,
  PurchaseOrderState,
  PurchaseViewMode,
  ReportEntityWithTicketsQuery,
  SaleQuery,
  SalesViewMode,
  UIReportCommissionQuery,
  UIReportV2ListingQuery,
  UIReportV2SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import {
  DateRangePresetName,
  getNextDateRange,
  getUiDateTimeRangeFromPreset,
  PurchaseDateRangePresetNames,
} from './dateTimeUtils';

export enum EventMappingStateFilter {
  All = 'All',
  Mapped = 'Mapped',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export const DefaultEventGroupingQuery = {
  searchText: '',
  groupingType: EventGroupingType.Performer,
  sortBy: EventGroupingSortBy.Name,
  isSortDescending: false,
} satisfies EventGroupingQuery;

export type QueryWithViewMode = {
  timezoneOffsetMins: number | null;
  viewMode?: string | null;
};

export const VIEW_MODE_SETTING_ID_TO_DEFAULT = {
  [UserSetting.InventoryPageViewMode]: InventoryViewMode.TileView,
  [UserSetting.SalePageViewMode]: SalesViewMode.TileView,
  [UserSetting.PurchasePageViewMode]: PurchaseViewMode.EventTileView,
};

export const EmptyEntityWithTicketsQuery = {
  entityIds: null,
  eventOrMappingIds: null,
  oldPosEventIds: null,
  marketplaceEntityIds: null,
  performerIds: null,
  venueIds: null,
  countryCodes: null,
  stateProvinceIds: null,
  cityIds: null,
  purchaseOrderId: null,
  purchaseOrderIds: null,
  saleIds: null,
  listingIds: null,
  purchaseVendorId: null,
  vendorAccessControlProviderId: null,
  purchaseVendorOrderId: null,
  purchaseVendorAccountIds: null,
  purchaseBuyerId: null,
  inHandDates: null,
  eventTimeFrameFilter: null,
  searchText: null,
  sortBy: null,
  isSortDescending: false,
  eventDates: null,
  eventNotReviewedSinceDate: null,
  sectionContains: null,
  sections: null,
  rows: null,
  sectionEquals: null,
  rowContains: null,
  rowEquals: null,
  sectionNotContains: null,
  rowNotContains: null,
  tagName: null,
  tagValue: null,
  eventTagName: null,
  eventTagValue: null,
  topLevelCategories: null,
  parentCategories: null,
  numberOfTicketsHeld: null,
  lastSaleDates: null,
  noSaleDates: null,
  isSeatSaver: null,
  timezoneOffsetMins: new Date().getTimezoneOffset(),
  purchaseDates: null,
  purchaseOrderCreatedBy: null,
  purchaseCommissionUserId: null,
  internalNotes: null,
  internalNotesContains: null,
  internalNotesEquals: null,
  internalNotesNotContains: null,
  currencyCode: null,
  eventStatuses: null,
  currencyCodes: null,
  searchConfigId: null,
  isMapped: null,
  isMappingRejected: null,
} satisfies EntityWithTicketsQuery;

export const EmptySaleQuery = {
  ...EmptyEntityWithTicketsQuery,
  saleStatus: null,
  saleStatuses: null,
  paymentState: null,
  marketplacePaymentState: null,
  saleDates: null,
  ticketType: null,
  soldOnMarketplace: null,
  fulfillerSellerUserId: null,
  lastSaleDates: null,
  noSaleDates: null,
  numberOfTicketsHeld: null,
  topLevelCategories: null,
  parentCategories: null,
  paymentDates: null,
  fulfillmentDates: null,
  cancellationDates: null,
  fulfillmentStates: null,
  paymentReceived: null,
  paymentReferenceId: null,
  listingInsertBy: null,
  isNoFulfill: null,
  viewMode: null,
} satisfies SaleQuery;

export const DefaultSaleQuery = {
  ...EmptySaleQuery,
  saleStatuses: [
    PointOfSaleSaleStatus.PendingConfirmation,
    PointOfSaleSaleStatus.PendingAllocation,
    PointOfSaleSaleStatus.PendingFulfillment,
    PointOfSaleSaleStatus.ProcessingFulfillment,
    PointOfSaleSaleStatus.Fulfilled,
    PointOfSaleSaleStatus.FulfillmentFailed,
    PointOfSaleSaleStatus.Hold,
    PointOfSaleSaleStatus.ProvisionalAllocation,
  ],
  sortBy: EventSort.Date,
  isSortDescending: false, // We want the nearest future event first
  eventTimeFrameFilter: EventTimeFrameFilter.Future,
} satisfies SaleQuery;

export const EmptyListingQuery = {
  ...EmptyEntityWithTicketsQuery,
  pricerSellerUserId: null,
  isPredelivered: null,
  isListed: null,
  deliveryType: null,
  hasUnsoldTickets: null,
  eventHasUnsoldTickets: null,
  hasBroadcastError: null,
  isAutoPricingEnabled: null,
  isPricedAboveZero: null,
  eventUnpricedStatus: null,
  autoPricingResultTypes: null,
  isSeatingUnmapped: null,
  lastSaleDates: null,
  numberOfTicketsHeld: null,
  topLevelCategories: null,
  parentCategories: null,
  isAdminHold: null,
  isDuplicate: null,
  lastPriceUpdateDates: null,
  lastPriceNoUpdateDates: null,
  isPricedByMarketplace: null,
  isPendingProof: null,
  purchaseCancellationDates: null,
  purchaseCancelledOnly: null,
  isNoFulfill: null,
  ticketType: null,
  viewMode: null,
} satisfies ListingQuery;

export const DefaultListingQuery = {
  ...EmptyListingQuery,
  sortBy: EventSort.Date,
  isSortDescending: false, // We want the nearest future event first
  eventTimeFrameFilter: EventTimeFrameFilter.Future,
} satisfies ListingQuery;

export const EmptyOnSaleEventQuery = {
  viewMode: null,
  onSaleDates: null,
  timezoneOffsetMins: null,
  searchText: null,
  eventIds: null,
} satisfies OnSaleEventQuery;

export const DefaultOnSaleEventQuery = {
  ...EmptyOnSaleEventQuery,
  viewMode: OnSaleEventViewMode.MetricView,
  onSaleDates: {
    start: getNextDateRange({ weeks: 2 }, new Date()).start.toISOString(),
    end: getNextDateRange({ weeks: 2 }, new Date()).end.toISOString(),
  } as DateTimeRangeWithRelative,
  searchText: null,
  eventIds: null,
} satisfies OnSaleEventQuery;

export const EmptyPurchaseQuery = {
  ...EmptyEntityWithTicketsQuery,
  ticketGroupIds: null,
  purchaseMonths: null,
  paymentDates: null,
  paymentMethod: null,
  paymentStatus: null,
  totalCostHigh: null,
  totalCostLow: null,
  deliveryType: null,
  purchaseOrderState: null,
  isSortDescending: null,
  tagName: null,
  tagValue: null,
  entityIds: null,
  purchaseOrderCreatedBy: null,
  purchaseCommissionUserId: null,
  viewMode: null,
  currencyCode: null,
  paymentMethodId: null,
  internalNotesContains: null,
  internalNotesEquals: null,
  internalNotesNotContains: null,
  paymentCurrencyCodes: null,
  isConsignment: null,
} satisfies PurchaseOrderQuery;

export const DefaultPurchaseQuery = {
  ...EmptyPurchaseQuery,
  purchaseDates:
    (getUiDateTimeRangeFromPreset(
      DateRangePresetName.ThisYear,
      PurchaseDateRangePresetNames
    ) as DateTimeRangeWithRelative) ?? null,
  sortBy: EventSort.Date,
  purchaseOrderState: PurchaseOrderState.Active,
  isSortDescending: true,
} satisfies PurchaseOrderQuery;

export const EmptyCommissionQuery = {
  eventTimeFrameFilter: null,
  eventDates: null,
  saleDates: null,
  saleStatuses: null,
  soldOnMarketplace: null,
  paymentDates: null,
  fulfillmentDates: null,
  purchaseCommissionUserId: null,
  isCommissionEligible: null,
  commissionEligibilityDates: null,
  timezoneOffsetMins: null,
  viewMode: null,
  isSortDescending: null,
} satisfies UIReportCommissionQuery;

export const DefaultCommissionQuery = {
  ...EmptyCommissionQuery,
  eventTimeFrameFilter: EventTimeFrameFilter.Future,
  isSortDescending: true,
} satisfies UIReportCommissionQuery;

const EmptyReportEntityWithTicketsQuery = {
  performerIds: null,
  venueIds: null,
  countryCodes: null,
  stateProvinceIds: null,
  cityIds: null,
  purchaseOrderId: null,
  purchaseVendorId: null,
  vendorAccessControlProviderId: null,
  purchaseVendorAccountIds: null,
  purchaseVendorOrderId: null,
  purchaseBuyerId: null,
  purchaseOrderCreatedBy: null,
  purchaseCommissionUserId: null,
  purchaseDates: null,
  inHandDates: null,
  eventTimeFrameFilter: null,
  sections: null,
  rows: null,
  sectionContains: null,
  sectionEquals: null,
  sectionNotContains: null,
  rowContains: null,
  rowEquals: null,
  rowNotContains: null,
  isSeatSaver: null,
  eventDates: null,
  noSaleDates: null,
  tagName: null,
  tagValue: null,
  eventTagName: null,
  eventTagValue: null,
  lastSaleDates: null,
  numberOfTicketsHeld: null,
  topLevelCategories: null,
  parentCategories: null,
  internalNotes: null,
  internalNotesContains: null,
  internalNotesEquals: null,
  internalNotesNotContains: null,
  timezoneOffsetMins: null,
  currencyCode: null,
  currencyCodes: null,
  eventStatuses: null,
} satisfies ReportEntityWithTicketsQuery;

export const EmptyReportV2SaleQuery = {
  ...EmptyReportEntityWithTicketsQuery,
  isNoFulfill: null,
  saleDates: null,
  saleStatuses: null,
  soldOnMarketplace: null,
  cancellationDates: null,
  fulfillerSellerUserId: null,
  fulfillmentDates: null,
  paymentDates: null,
  marketplacePaymentState: null,
  viewMode: null,
  isSortDescending: null,
} satisfies UIReportV2SaleQuery;

export const DefaultReportV2SaleQuery = {
  ...EmptyReportV2SaleQuery,
  eventTimeFrameFilter: EventTimeFrameFilter.Future,
  isSortDescending: true,
} satisfies UIReportV2SaleQuery;

export const EmptyReportV2ListingQuery = {
  ...EmptyReportEntityWithTicketsQuery,
  isPredelivered: null,
  isNoFulfill: null,
  lastPriceUpdateDates: null,
  isPricedByMarketplace: null,
  isAutoPricingEnabled: null,
  isListed: null,
  isMapped: null,
  isDuplicate: null,
  isSeatingUnmapped: null,
  isAdminHold: null,
  viewMode: null,
  isSortDescending: null,
} satisfies UIReportV2ListingQuery;

export const DefaultReportV2ListingQuery = {
  ...EmptyReportV2ListingQuery,
  eventTimeFrameFilter: EventTimeFrameFilter.Future,
  isSortDescending: true,
} satisfies UIReportV2ListingQuery;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterQueryType = Record<string, any>;

export enum EventUiSort {
  DateDesc = 'Sort By: Newest to Oldest',
  DateAsc = 'Sort By: Oldest to Newest',
  SortAtoZ = 'Sort By: A to Z',
  SortZtoA = 'Sort By: Z to A',
}

export const getEventUiSort = (
  sortBy: EventSort | null,
  isSortDescending: boolean | null
) => {
  switch (sortBy) {
    case EventSort.MainDisplay:
      return isSortDescending ? EventUiSort.SortZtoA : EventUiSort.SortAtoZ;
    case EventSort.Date:
    default:
      return isSortDescending ? EventUiSort.DateDesc : EventUiSort.DateAsc;
  }
};

export const getEventSort = (
  sort: EventUiSort | null
): [EventSort, boolean] => {
  switch (sort) {
    case EventUiSort.SortAtoZ:
      return [EventSort.MainDisplay, false];
    case EventUiSort.SortZtoA:
      return [EventSort.MainDisplay, true];
    case EventUiSort.DateAsc:
      return [EventSort.Date, false];
    case EventUiSort.DateDesc:
    default:
      return [EventSort.Date, true];
  }
};

export const getAppliedFilterCounts = (
  currentFilter: FilterQueryType,
  initialFilter: FilterQueryType,
  ignoreKeys: string[] = []
) => {
  const initKeys = Object.keys(initialFilter);

  let count = 0;
  for (let i = 0; i < initKeys.length; i++) {
    if (ignoreKeys.includes(initKeys[i])) {
      continue;
    }
    const initValue = initialFilter[initKeys[i]];
    const curValue = currentFilter[initKeys[i]];
    if (!isEqual(curValue, initValue) && initValue !== undefined) {
      count++;
    }
  }

  return count;
};

const isFilterApplied = (
  currentValue: unknown,
  initialValue: unknown
): boolean => {
  // No need to differentiate between null and undefined
  if (currentValue == null && initialValue == null) {
    return false;
  }
  return !isEqual(currentValue, initialValue);
};

/**
 * Returns the filters that match with:
 * * not undefined (null is a valid value)
 * * not in initial/default query
 * * values is different from initial/default query
 * * not in ignored Ids list
 * @param currentFilterQuery
 * @param initialFilterQuery
 * @param ignoreFilterIds Not shown in the UI
 * @param filtersGroups
 */
export const getAppliedFilters = (
  currentFilterQuery: FilterQueryType,
  initialFilterQuery: FilterQueryType,
  ignoreFilterIds: string[] = [],
  filtersGroups: FilterToolbarGroup[]
): FilterToolbarItemId[] => {
  // Sometimes, initialQuery has only default values, not the full query,
  // and the current query has more keys, so merge all together to make
  // the comparisons
  const initialKeys = [
    ...new Set([
      ...Object.keys(initialFilterQuery),
      ...Object.keys(currentFilterQuery),
    ]),
  ];

  const filtersMap: Partial<Record<FilterToolbarItemId, FilterToolbarItem>> =
    {};

  filtersGroups.forEach((filterGroup) => {
    filterGroup.items.forEach((filterItem) => {
      filtersMap[filterItem.filterId] = filterItem;
    });
  });

  const appliedFilters = new Set();
  for (let i = 0; i < initialKeys.length; i++) {
    if (ignoreFilterIds.includes(initialKeys[i])) {
      continue;
    }
    const filterId = initialKeys[i] as FilterToolbarItemId;

    // Some filters rely on more than one key of the filterQuery, so check if
    // the declared keys in the filter are used.
    const filterKeys = filtersMap[filterId]?.filterQueryKeys ?? [];
    const someApplied = filterKeys.some((fId) => {
      const initialValue = initialFilterQuery[fId];
      const currentValue = currentFilterQuery[fId];
      return isFilterApplied(currentValue, initialValue);
    });
    if (someApplied) {
      appliedFilters.add(filterId);
    }
  }

  return [...appliedFilters] as FilterToolbarItemId[];
};

export const MainFilterQueryParam = 'mainFilter';

const removeEntityIdsIfHasSearchId = (copy: FilterQueryType) => {
  const entityQuery = copy as EntityWithTicketsQuery;
  if (entityQuery.searchConfigId) {
    // If we have the search id, then we clear-out everything since only the search config id matter
    return {
      ...entityQuery,
      entityIds: null,
    } as EntityWithTicketsQuery;
  }

  return copy;
};

export const deleteDefaultFields = (
  baseQuery: FilterQueryType,
  query: FilterQueryType | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformToUrl?: (key: FilterToolbarItemId, value: any) => any
) => {
  if (!query) {
    return null;
  }

  let copy = Object.entries(baseQuery).reduce((result, baseEntry) => {
    const curValue = query[baseEntry[0]];
    if (!isEqual(baseEntry[1], curValue)) {
      result[baseEntry[0]] = queryValueTransformToUrl
        ? queryValueTransformToUrl(
            baseEntry[0] as FilterToolbarItemId,
            curValue
          )
        : curValue;
    }

    return result;
  }, {} as FilterQueryType);

  copy = removeEntityIdsIfHasSearchId(copy);

  return copy;
};

export const queryValueTransformFromUrlBase = (
  key: string,
  value: string | object
) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'object') {
    const obj = value as DateTimeRangeWithRelative;
    if (obj.relativeEndSecs || obj.relativeStartSecs) {
      return { ...obj, roundingMode: obj.roundingMode };
    }
  }

  return value;
};

export const mapToBaseQuery = <TQuery extends FilterQueryType>(
  baseQuery: TQuery,
  query: TQuery | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformFromUrl?: (key: string, value: any) => any
) => {
  if (!query) {
    return null;
  }

  let copy = Object.entries(baseQuery).reduce((result, baseEntry) => {
    const filterId = baseEntry[0];
    const queryValue = query?.[filterId];
    result[filterId] = queryValueTransformFromUrlBase(
      filterId,
      queryValue === undefined
        ? baseEntry[1] // Only use baseEntry's value if the queryValue is undefined (null is a valid value)
        : queryValueTransformFromUrl
          ? queryValueTransformFromUrl(filterId, queryValue)
          : queryValue
    );
    return result;
  }, {} as FilterQueryType);

  copy = removeEntityIdsIfHasSearchId(copy);

  return copy as TQuery;
};

export const isQueryEqual = <TQuery extends FilterQueryType>(
  baseQuery: TQuery,
  query1: TQuery | null,
  query2: TQuery | null
) => {
  const baseKeys = Object.keys(baseQuery);

  for (const i in baseKeys) {
    const v1 = query1?.[baseKeys[i]];
    const v2 = query2?.[baseKeys[i]];

    if (!isEqual(v1, v2)) {
      return false;
    }
  }

  return true;
};
export const getQueryFromUrl = <TQuery extends FilterQueryType>(
  baseQuery: TQuery,
  urlSearchString: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformFromUrl?: (key: string, value: any) => any
): TQuery | null => {
  if (urlSearchString) {
    const searchParams = new URLSearchParams(urlSearchString);
    const filterQueryParam = searchParams.get(MainFilterQueryParam);
    if (filterQueryParam) {
      try {
        const obj = JSON.parse(filterQueryParam);
        if (obj) {
          return mapToBaseQuery(baseQuery, obj, queryValueTransformFromUrl);
        }
      } catch (e) {
        console.warn(`Unable to parse as a filter obj: ${filterQueryParam}`);
        return null;
      }
    }
  }

  return baseQuery;
};

export const getUrlFilterQueryParam = <TQuery extends FilterQueryType>(
  baseQuery: TQuery,
  query: TQuery | null,
  baseSearchParams?: URLSearchParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformToUrl?: (key: FilterToolbarItemId, value: any) => any
) => {
  const searchParams = baseSearchParams || new URLSearchParams();
  searchParams.set(
    MainFilterQueryParam,
    JSON.stringify(
      deleteDefaultFields(baseQuery, query, queryValueTransformToUrl)
    )
  );

  return `?${searchParams}`;
};

export const isFilterQueryParamEmpty = (searchParams?: string): boolean => {
  if (!searchParams) {
    return true;
  }
  try {
    const urlSearchParams = new URLSearchParams(searchParams);
    const mainFilters = urlSearchParams.get(MainFilterQueryParam);
    return !mainFilters || mainFilters === '{}';
  } catch (e) {
    return false;
  }
};

export const removeFilters = <TQuery extends FilterQueryType>(
  urlSearchParams: string | undefined,
  filtersKeyToRemove: (keyof TQuery)[]
): string => {
  if (!urlSearchParams || isFilterQueryParamEmpty(urlSearchParams)) {
    return urlSearchParams ?? '';
  }

  try {
    const searchFilterParams = new URLSearchParams(urlSearchParams);
    const mainFilter = searchFilterParams.get(MainFilterQueryParam)!;
    const filters = JSON.parse(mainFilter) as TQuery;
    filtersKeyToRemove.forEach((filterKey) => {
      delete filters[filterKey];
    });

    searchFilterParams.set(MainFilterQueryParam, JSON.stringify(filters));
    return searchFilterParams.toString();
  } catch (e) {
    return urlSearchParams ?? '';
  }
};

export const getLastNDaysSaleQuery = (days: number): SaleQuery => {
  const today = new Date();
  const lastNDays = new Date();
  lastNDays.setDate(today.getDate() - days);
  return {
    ...DefaultSaleQuery,
    eventTimeFrameFilter: null, // all
    // cap the event time filter to filter with N look-back instead of all - which is perf hit
    eventDates: {
      start: lastNDays.toISOString(),
      end: null,
    } as DateTimeRangeWithRelative,
    saleDates: {
      start: lastNDays.toISOString(),
      end: today.toISOString(),
    } as DateTimeRangeWithRelative,
  };
};

export const getPurchaseOrderIdSaleQuery = (
  purchaseOrderId: number
): SaleQuery => {
  return {
    ...DefaultSaleQuery,
    purchaseOrderId,
    eventTimeFrameFilter: null, // we want all
  };
};

export const getPurchaseOrderIdListingQuery = (
  purchaseOrderId: number
): ListingQuery => {
  return {
    ...DefaultListingQuery,
    purchaseOrderId,
    eventTimeFrameFilter: null, // we want all
  };
};

export const getSaleStatusesSaleQuery = (
  saleStatuses: PointOfSaleSaleStatus[]
): SaleQuery => {
  return {
    ...DefaultSaleQuery,
    saleStatuses,
  };
};

export enum YesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum LastReviewedSinceDate {
  LastThreeDays = 'Last 3 Days',
  LastSevenDays = 'Last 7 Days',
  LastTwoWeeks = 'Last 2 weeks',
  LastThirtyDays = 'Last 30 Days',
}

export enum TextFilterEnum {
  Contains = 'Contains',
  Equals = 'Equals',
  NotContains = 'NotContains',
}

export const ToEventMappingStateFilter = (
  isMapped: boolean | null | undefined,
  isMappingRejected: boolean | null | undefined
) => {
  if (isMapped == null) {
    return EventMappingStateFilter.All;
  }
  if (isMapped === true) {
    return EventMappingStateFilter.Mapped;
  }

  // isMapped === false
  return isMappingRejected === true
    ? EventMappingStateFilter.Rejected
    : EventMappingStateFilter.Pending;
};

export const FromEventMappingStateFilter = (
  eventMappingStateFilter: EventMappingStateFilter
): [boolean | null, boolean | null] => {
  // [isMapped, isMappingRejected]
  switch (eventMappingStateFilter) {
    case EventMappingStateFilter.All:
      return [null, null];
    case EventMappingStateFilter.Mapped:
      return [true, null];
    case EventMappingStateFilter.Pending:
      return [false, false];
    case EventMappingStateFilter.Rejected:
      return [false, true];
  }
};

export const ToYesNoEnum = (value: boolean | null | undefined) => {
  return value == null ? null : value ? YesNoEnum.Yes : YesNoEnum.No;
};

export const FromYesNoEnum = (yesNoEnumValue: YesNoEnum | null) => {
  return yesNoEnumValue === YesNoEnum.Yes
    ? true
    : yesNoEnumValue === YesNoEnum.No
      ? false
      : null;
};

/**
 * Filters the `catalogResults` using the given date range.
 *
 * This mutates the original `catalogResults`.
 * @param catalogResults
 * @param dateRangeStrings
 * @returns
 */
export function filterCatalogResultsByEventDate(
  catalogResults: CatalogResults,
  dateRangeStrings: DateTimeRange
) {
  const dateRange = {
    start: dateRangeStrings?.start ? new Date(dateRangeStrings.start) : null,
    end: dateRangeStrings?.end ? new Date(dateRangeStrings.end) : null,
  };

  const filteredEvents: { [key: string]: EventWithData } = {};
  for (const id in catalogResults.events) {
    const { event } = catalogResults.events[id];
    if (event.dates.start != null) {
      const eventDate = new Date(event.dates.start);
      if (
        (dateRange.start == null || eventDate >= dateRange.start) &&
        (dateRange.end == null || eventDate <= dateRange.end)
      ) {
        filteredEvents[id] = catalogResults.events[id];
      }
    }
  }
  catalogResults.events = filteredEvents;
  return catalogResults;
}

export const isFilterUnchanged = <
  T extends ListingQuery | SaleQuery | PurchaseOrderQuery,
>(
  query: T,
  emptyQuery: T
) => {
  const {
    entityIds,
    performerIds,
    venueIds,
    searchText,
    sortBy,
    isSortDescending,
    searchConfigId,
    viewMode,
    ...rest1
  } = query;

  const {
    entityIds: x1,
    performerIds: x2,
    venueIds: x3,
    searchText: x4,
    sortBy: x5,
    isSortDescending: x6,
    searchConfigId: x7,
    viewMode: x8,
    ...rest2
  } = emptyQuery;

  return Object.entries(rest1).length === 0 || isEqual(rest1, rest2);
};
