import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';

import { SaleDetailsModalV2 } from '../SaleDetailsV2/SaleDetailsModalBody';

export const SaleDetailsModalConfig = {
  children: <SaleDetailsModalV2 />,
  clickOutsideToClose: true,
  deepLinkKey: SaleDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in',
};
