import { clsx } from 'clsx';
import { useEffect } from 'react';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { EventInfo } from 'src/components/Events/EventInfo';
import { Content } from 'src/contexts/ContentContext';
import { PosLink } from 'src/core/POS/PosLink';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { useGetSaleByAmbiguousIdWithCatalogData } from 'src/hooks/api/useGetSaleByAmbiguousIdWithCatalogData';
import { ContentId } from 'src/utils/constants/contentId';
import { SALES_STATUS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import { Sale } from 'src/WebApiController';

import * as styles from './SaleEventBox.css';

interface SaleEventBoxProps {
  lookupId: string | undefined | null;
  onSearchSaleChanged?: (sale: Sale | undefined | null) => void;
}

export const SaleEventBox = ({
  lookupId,
  onSearchSaleChanged,
}: SaleEventBoxProps) => {
  const { isLoading, data, isError } = useGetSaleByAmbiguousIdWithCatalogData(lookupId);

  useEffect(() => {
    if (isLoading || isError) {
      onSearchSaleChanged?.(undefined);
    }
    onSearchSaleChanged?.(data?.sale);
  }, [data?.sale, isError, isLoading, onSearchSaleChanged]);

  if (isLoading) {
    return <PosSpinner />;
  }

  if (isError) {
    return (
      <div>
        <Content id={ContentId.UnableToFindSale} />
      </div>
    );
  }

  if (data) {
    const sale = data.sale;
    const eventId = sale?.viagEvId;
    const event = eventId && data.catalog?.events[eventId]?.event;
    const seating = sale?.seating;

    if (!eventId || !event || !sale) {
      return (
        <div>
          <Content id={ContentId.UnableToFindSale} />
        </div>
      );
    }

    const venue = data.catalog.venues[event.venueId];
    const performer = event.perfId
      ? data.catalog.performers[event.perfId]
      : undefined;

    const [eventName, eventNameConnector, eventSubName] = getFormattedEventName(
      {
        event,
        performer,
        venue,
        slimMode: false,
      }
    );

    const venueLocation = venue && getFormattedLocation(venue);

    return (
      <Stack gap="m" className={styles.box}>
        <div className={styles.column}>
          <EventInfo
            eventName={eventName}
            eventNameConnector={eventNameConnector}
            eventSubName={eventSubName}
            venueName={venue?.name}
            venueLocation={venueLocation}
            eventDateString={event.dates.start!}
            slimMode={true}
          />
        </div>
        {seating && (
          <div className={clsx(styles.column, styles.justifyCenter)}>
            <SeatingInfo
              section={seating.section}
              row={seating.row}
              seatFr={seating.seatFr}
              seatTo={seating.seatTo}
            />
          </div>
        )}
        <div className={styles.column}>
          <Stack direction="column" alignItems="start">
            <PosLink
              to={getSaleDetailsRelativeUrl(sale.id)}
              variant="brand"
              className={styles.breakWord}
            >
              <span>#{sale.idOnMkp}</span>
            </PosLink>
            <MarketplaceLogo marketplace={sale.mkp} />
          </Stack>
        </div>
        <div
          className={clsx(
            styles.column,
            styles.justifyCenter,
            styles.breakWord
          )}
        >
          <Content id={SALES_STATUS_TO_CID[sale.status]} />
        </div>
      </Stack>
    );
  }

  return null;
};
