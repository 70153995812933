import { ReactNode, useCallback, useMemo, useRef } from 'react';
import {
  PosEventsAccordionBodyHeight,
  useSetEventsAccordionMaxHeight,
} from 'src/components/Accordions/useSetEventsAccordionMaxHeight';
import { EventVirtualizedList } from 'src/components/Events/EventListing/EventVirtualizedList';
import { EventCheckbox } from 'src/components/Events/EventListing/EventVirtualizedList/EventCheckbox';
import { EventStatus } from 'src/components/Events/EventStatus';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { EventAccordionItemProps } from './EventAccordionItem';
import * as styles from './EventsAccordion.css';
import { eventStatus } from './EventsAccordion.css';
import { useGetGroupingTitle } from './useGetGroupingTitle';

type EventItemProps<T> = {
  onSelect: (ids: string) => void;
  selectedIds?: string[];
  item: EventAccordionItemProps<T>;
  disablePagination: boolean;
};

export const EventItemCollapsible = <T,>({
  item,
  disablePagination,
  selectedIds = [],
  onSelect,
}: EventItemProps<T>) => {
  const {
    HeaderComponent,
    BodyComponent,
    AfterHeaderComponent,
    usingVirtuoso,
    ...rest
  } = item ?? {};
  const accordionBodyRef = useRef<HTMLDivElement | null>(null);
  const accordionHeaderRef = useRef<HTMLDivElement | null>(null);
  const { selectionMode, setSelectionMode } = useMultiSelectionContext();
  const hasEventStatusDisplay = useUserHasFeature(Feature.EventStatusDisplay);

  const isAccordionOpen = useMemo(
    () => selectedIds.includes(item.event.viagVirtualId),
    [item.event.viagVirtualId, selectedIds]
  );

  const isMobile = useMatchMedia('mobile');

  useSetEventsAccordionMaxHeight({
    enabled: usingVirtuoso,
    isExpanded: isAccordionOpen,
    accordionBodyRef,
    accordionHeaderRef,
  });

  const hideBulkActions = useCallback(() => {
    if (
      selectionMode &&
      selectionMode.groupId === item.itemId &&
      selectionMode.mode === MultiSelectScope.SingleGroup
    ) {
      setSelectionMode();
    }
  }, [item.itemId, selectionMode, setSelectionMode]);

  return (
    <div className={styles.itemRow}>
      {selectionMode?.mode === MultiSelectScope.AllGroups && (
        <div className={styles.checkbox}>
          <EventCheckbox eventId={item?.event?.viagVirtualId} />
        </div>
      )}
      <PosCollapsible.Root
        className={styles.collapsibleRoot}
        open={isAccordionOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            hideBulkActions();
          }
          onSelect(item?.event.viagVirtualId);
        }}
      >
        <div
          className={!isMobile ? styles.stickyCollapsibleTrigger : undefined}
          ref={accordionHeaderRef}
        >
          <PosCollapsible.Trigger>
            <div className={styles.itemHeader}>
              {hasEventStatusDisplay && item?.event.eventStatus && (
                <div className={eventStatus}>
                  <EventStatus status={item?.event.eventStatus} />
                </div>
              )}
              <HeaderComponent {...rest} hideYearFromDate={false} />
            </div>
          </PosCollapsible.Trigger>
          {AfterHeaderComponent && <AfterHeaderComponent />}
        </div>
        <PosCollapsible.Content>
          <InputPriceFocusProvider
            disablePagination={disablePagination}
            pageSize={disablePagination ? item.entityCount : 10}
          >
            <div
              id="test-expanded"
              ref={accordionBodyRef}
              style={{ height: `var(${PosEventsAccordionBodyHeight},100%)` }}
            >
              <BodyComponent disablePagination={disablePagination} {...rest} />
            </div>
          </InputPriceFocusProvider>
        </PosCollapsible.Content>
      </PosCollapsible.Root>
    </div>
  );
};

export type VirtualizedEventAccordionProps<T> = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  onSelect: (id: string) => void;
  selectedIds?: string[];
  items: EventAccordionItemProps<T>[];
  scrollToItemIndex?: number;
  tableActions?: React.ReactElement;
  onItemsRendered?: (items: EventAccordionItemProps<T>[]) => void;
  disablePagination: boolean;
};

export const EventsAccordion = <T,>(
  props: VirtualizedEventAccordionProps<T>
) => {
  const { getEventGroupingText } = useGetGroupingTitle();

  const getTableDividerText = useCallback(
    (item: EventAccordionItemProps<T>) => getEventGroupingText(item.event),
    [getEventGroupingText]
  );

  return (
    <EventVirtualizedList<EventAccordionItemProps<T>>
      {...props}
      getDividerText={getTableDividerText}
      ItemComponent={EventItemCollapsible}
    />
  );
};
