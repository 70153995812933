import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { PurchaseDatesAccordion } from 'src/components/Accordions/PurchaseDatesAccordion';
import {
  TicketGroupsEventsAccordion,
  translateEventToTicketGroupAccordionItem,
} from 'src/components/Accordions/TicketGroupsEventsAccordion';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { PurchasesGlobalBulkActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/PurchasesGlobalBulkActions';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import { PurchaseSearchBox } from 'src/components/Purchases/PurchaseFilterBar/PurchaseSearchBox';
import {
  SetPurchasesAccordionSelectionCountLabel,
  SetPurchasesFlattenedViewSelectionCountLabel,
  SetPurchasesTimeViewSelectionCountLabel,
} from 'src/components/Purchases/SelectedItemsLabel';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import {
  MultiSelectionContextProvider,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import {
  PurchaseDataContextProvider,
  usePurchaseDataContext,
} from 'src/contexts/PurchaseDataContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchaseColumnSettingsModal } from 'src/modals/ColumnSettings/PurchaseColumnSettings';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { LayoutIcon } from 'src/svgs';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { createPurchaseSearchPageUrl } from 'src/utils/advancedSearchUtils';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultPurchaseQuery,
  EmptyPurchaseQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  PURCHASE_USER_SETTINGS,
  purchaseQueryValueTransformFromUrl,
  purchaseQueryValueTransformToUrl,
} from 'src/utils/purchaseUtils';
import { addTicketGroupMetrics } from 'src/utils/ticketMetricUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  Feature,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
  TicketGroupMetrics,
  UserSetting,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import { PurchaseFlattenedView } from './PurchaseFlattenedView';
import * as styles from './Purchases.css';
import { PurchaseToolbar } from './PurchaseToolbar';

export function Purchases() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={PURCHASE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<PurchaseOrderQuery>
        initialQuery={DefaultPurchaseQuery}
        viewModeSettingId={UserSetting.PurchasePageViewMode}
        emptyQuery={EmptyPurchaseQuery}
        queryValueTransformToUrl={purchaseQueryValueTransformToUrl}
        queryValueTransformFromUrl={purchaseQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <PurchasePage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

function PurchasePage() {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();

  const getCatalogDataExpandedCallback = useCallback(
    (viagVirtualIds: string[], transformedFilterQuery: PurchaseOrderQuery) => {
      if (filterQuery.viewMode !== PurchaseViewMode.EventTileView) {
        return Promise.resolve({});
      }

      return getCatalogDataExpanded(viagVirtualIds, transformedFilterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('PurchaseClient.getTicketGroupsForEvents', error, {
            ...transformedFilterQuery,
            eventIds: viagVirtualIds,
          });
        },
      });
    },
    [activeAccountWebClientConfig, filterQuery.viewMode, trackError]
  );

  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  return (
    <CatalogDataContextProvider<PurchaseOrderQuery>
      entityType={ActionOutboxEntityType.Purchase}
      queryKey="getCatalogForPurchaseOrders"
      getCatalogData={(c, f) => getCatalogData(c, f, true)}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
    >
      <PurchaseDataContextProvider>
        <CatalogMultiSelectionContextProvider
          type={
            filterQuery.viewMode === PurchaseViewMode.EventTileView
              ? 'ticketGroup'
              : 'purchase'
          }
        >
          <MultiSelectionContextProvider
            isFlattenedView={
              filterQuery.viewMode === PurchaseViewMode.FlattenedView
            }
          >
            <CatalogMetricsContextProvider<
              TicketGroupMetrics,
              PurchaseOrderQuery
            >
              queryKey="getCatalogTicketGroupMetrics"
              entityType={ActionOutboxEntityType.Purchase}
              getCatalogMetrics={(c, f, r) =>
                getCatalogMetrics(c, f, r, hasMetricsV2Feature)
              }
              addCatalogMetrics={addTicketGroupMetrics}
              disabled={filterQuery.viewMode !== PurchaseViewMode.EventTileView}
            >
              <PurchasesContent />
            </CatalogMetricsContextProvider>
          </MultiSelectionContextProvider>
        </CatalogMultiSelectionContextProvider>
      </PurchaseDataContextProvider>
    </CatalogDataContextProvider>
  );
}

function PurchasesContent() {
  const purchasesTitle = useContent(ContentId.Purchases);
  const { filterQuery, isQueryInitialized } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const { activeAccountWebClientConfig } = useAppContext();

  const {
    eventsTransformed,
    isLoading: isCatalogDataLoading,
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();
  const onTicketGroupsEventExpanded = useCallback(
    async (viagVirtualId: string) => {
      toggleListItemExpansion([viagVirtualId]);
    },
    [toggleListItemExpansion]
  );

  const {
    timePeriodPurchasesQuery,
    allPurchasesQuery,
    purchaseCount,
    errorInfo,
    selectedIds,
    onSelect,
    scrollToId,
    setScrollToId,
    onSetActivePurchaseOrderCallback,
  } = usePurchaseDataContext();

  const isMobile = useMatchMedia('mobile');

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  const ticketGroupItems = useMemo(() => {
    // Don't transform events if they are not used in the current view mode
    if (
      !filterQuery.viewMode ||
      filterQuery.viewMode !== PurchaseViewMode.EventTileView
    ) {
      return [];
    }
    // Only for PurchaseViewMode.EventTileView
    return (
      eventsTransformed?.map((e, i) =>
        translateEventToTicketGroupAccordionItem(e, i)
      ) || []
    );
  }, [eventsTransformed, filterQuery.viewMode]);

  const { clearShiftKeySelection, setSelectionMode } =
    useMultiSelectionContext();

  useEffect(() => {
    clearShiftKeySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.viewMode]);

  const dataIsLoading =
    !isQueryInitialized ||
    filterQuery.viewMode === PurchaseViewMode.EventTileView
      ? isCatalogDataLoading || eventsTransformed == null
      : filterQuery.viewMode === PurchaseViewMode.FlattenedView
      ? isCatalogDataLoading ||
        eventsTransformed == null ||
        allPurchasesQuery.isLoading
      : timePeriodPurchasesQuery.isLoading ||
        timePeriodPurchasesQuery.data == null;

  const hasData =
    filterQuery.viewMode === PurchaseViewMode.EventTileView
      ? ticketGroupItems.length
      : filterQuery.viewMode === PurchaseViewMode.FlattenedView
      ? purchaseCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS ||
        allPurchasesQuery.data?.length
      : timePeriodPurchasesQuery.data?.length;

  // Reset and close selection mode when changing view
  useEffect(() => {
    setSelectionMode();
  }, [filterQuery.viewMode, setSelectionMode]);

  const hasNewSearchFeature = useUserHasFeature(Feature.SearchView_Purchase);
  const navigate = useNavigate();

  const onAdvancePurchaseSearch = useCallback(() => {
    const searchUrl = createPurchaseSearchPageUrl();

    setTimeout(() => {
      navigate(searchUrl, {
        state: {
          returnUrl: `${location.pathname}${location.search}`,
        },
      });
    }, 10);
  }, [navigate]);

  return (
    <ActivePosEntityProvider<PurchaseOrderDetails>
      entityType={ActionOutboxEntityType.Purchase}
      getActivePosEntity={getActivePosEntity}
      onSetActiveItemCompleteCallback={onSetActivePurchaseOrderCallback}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.Purchases}
          routeTitle={purchasesTitle}
          centerContent={
            hasNewSearchFeature && (
              <Stack gap="m" alignItems="center" justifyContent="center">
                <PurchaseSearchBox />
                <Button variant="text" onClick={onAdvancePurchaseSearch}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <Content id={ContentId.AdvancedSearch} />
                  </div>
                </Button>
              </Stack>
            )
          }
        >
          <MainFilterBar />
          <div className={styles.contentContainer}>
            {dataIsLoading && <PosSpinner />}
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {!dataIsLoading && !errorInfo && (
              <>
                {!isMobile && (
                  <PurchaseToolbar
                    viewMode={filterQuery.viewMode}
                    onColumnSettingButtonClickHandler={
                      onColumnSettingButtonClickHandler
                    }
                  />
                )}
                {hasData ? (
                  filterQuery.viewMode === PurchaseViewMode.FlattenedView ? (
                    <PurchaseFlattenedView
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesFlattenedViewSelectionCountLabel>
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions />
                            </MultiSelectActionBar>
                          </SetPurchasesFlattenedViewSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                      enableColumnFilters
                    />
                  ) : filterQuery.viewMode ===
                    PurchaseViewMode.EventTileView ? (
                    <TicketGroupsEventsAccordion
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesAccordionSelectionCountLabel
                            events={eventsTransformed}
                          >
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions isEventView />
                            </MultiSelectActionBar>
                          </SetPurchasesAccordionSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                      disablePagination
                      items={ticketGroupItems}
                      selectedIds={expandedListItems}
                      onSelect={onTicketGroupsEventExpanded}
                      tableActions={
                        <Button
                          variant="textPlain"
                          onClick={onColumnSettingButtonClickHandler}
                        >
                          <LayoutIcon size={vars.iconSize.m} />
                          <Content id={ContentId.Columns} />
                        </Button>
                      }
                    />
                  ) : (
                    <PurchaseDatesAccordion
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesTimeViewSelectionCountLabel>
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions />
                            </MultiSelectActionBar>
                          </SetPurchasesTimeViewSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                      selectedIds={selectedIds}
                      onSelect={onSelect}
                      items={timePeriodPurchasesQuery.data ?? []}
                      scrollToId={scrollToId}
                      onScroll={() => {
                        if (scrollToId) setScrollToId(undefined);
                      }}
                      tableActions={
                        <Button
                          variant="textPlain"
                          onClick={onColumnSettingButtonClickHandler}
                        >
                          <LayoutIcon size={vars.iconSize.m} />
                          <Content id={ContentId.Columns} />
                        </Button>
                      }
                    />
                  )
                ) : (
                  <EmptySectionContent.Root
                    icon={
                      <EmptySectionContent.SolidIconContainer>
                        <SearchSolidIcon />
                      </EmptySectionContent.SolidIconContainer>
                    }
                  >
                    <EmptySectionContent.Label>
                      <Content id={ContentId.NoResultFound} />
                    </EmptySectionContent.Label>
                    <EmptySectionContent.DetailMessage>
                      <Content id={ContentId.NoEventsMatching} />
                    </EmptySectionContent.DetailMessage>
                  </EmptySectionContent.Root>
                )}
                {isColumnModalOpen ? (
                  <PurchaseColumnSettingsModal
                    sectionType={
                      filterQuery.viewMode === PurchaseViewMode.EventTileView
                        ? SectionType.PurchaseEvent
                        : SectionType.Purchases
                    }
                    onClose={toggleColumnModal}
                    viewMode={filterQuery.viewMode}
                  />
                ) : null}
              </>
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
