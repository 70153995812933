import { vars } from 'src/core/themes';
import { Event, Listing, Performer, Venue } from 'src/WebApiController';

export type ListingWithEvent = {
  listing?: Listing | null;
  isLtGrp?: boolean;
  ltGroupName?: string;
  isCompListing?: boolean;
  event: Event;
  performer?: Performer | null;
  venue?: Venue | null;
  selectionDisabledReason?: string;
  rowIndex?: number;
  currentPageIndex?: number;
  ticketClassColor?: string | null;
};

export const SHIMMERING_DIV_HEIGHT_SINGLE_LISTING = vars.spacing.xxl;
export const SHIMMERING_DIV_HEIGHT_LISTING_GROUP = '110px';
