import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { DetailGroup } from 'src/modals/common';
import {
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPricingUndercutMode,
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { useAutoPricingSettings } from '../useAutoPricingSettings';
import { AutoPricingSectionRowIdFilter } from './AutoPricingSectionRowIdFilter';
import * as styles from './SimplifiedAutopricingGuidedPrice.css';

type SimplifiedAutopricingGuidedPriceProps = {
  listing?: Listing | null;
  listingHasParent?: boolean;
  disabled?: boolean;
  isMarketListingsView?: boolean;
};

/**
 * @deprecated - Do not use
 */
export const SimplifiedAutopricingGuidedPrice = ({
  listing,
  disabled,
  isMarketListingsView,
}: SimplifiedAutopricingGuidedPriceProps) => {
  const { clearErrors, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const {
    canAutoPrice,
    uiCurrency,
    eventPricingSettings,
    accountPricingSettings,
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,
    onUndercutChange,
    skipUndercut,
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange,
  } = useAutoPricingSettings(listing);

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange
  );

  const autopricingSettingsAvailable = canAutoPrice || isMarketListingsView;

  const parsedQuantityOverridesJson = JSON.parse(
    compListingQuantityScoreAdjustmentOverrideJson || 'null'
  );

  const quantityFilterValue = parsedQuantityOverridesJson?.quantityFilter;

  const { undercutAbsoluteAmountMode, setUndercutAbsoluteAmountMode } =
    useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const isDealScoreMode =
    (undercutMode ??
      eventPricingSettings?.undercutMode ??
      accountPricingSettings?.undercutMode) === AutoPricingUndercutMode.Deal;
  const undercutAbsoluteAmountError =
    formState.errors.undercutAbsoluteAmount?.message;

  return (
    <>
      {isDealScoreMode && (
        <div className={styles.inlineText}>
          <Content id={ContentId.BestDealScorePrompt} />
        </div>
      )}

      <DetailGroup className={styles.guidedPriceSectionContainer}>
        {canAutoPrice && (
          <Stack gap="l">
            <ToggleGroup
              disabled={
                !undercutMode ||
                !undercutAbsoluteAmount ||
                Boolean(skipUndercut)
              }
              options={UNDERCUT_PRICE_MODE_OPTIONS}
              value={undercutAbsoluteAmountMode}
              onValueChange={(value) => {
                if (!value) return;

                const mode = value as UndercutPriceSettingsMode;
                setUndercutAbsoluteAmountMode(mode);

                if (undercutAbsoluteAmount) {
                  const curAmountAbs = Math.abs(undercutAbsoluteAmount);
                  onUndercutChange(
                    undefined,
                    mode == UndercutPriceSettingsMode.Premium
                      ? -curAmountAbs
                      : curAmountAbs
                  );
                }
              }}
            />
            <div className={styles.inlineText}>
              <Content id={ContentId.By} />
            </div>
            <PosFormField
              errors={undercutAbsoluteAmountError}
              style={{ width: 'fit-content' }}
            >
              <PosCurrencyField
                rootProps={{
                  state: getTextFieldState(undercutAbsoluteAmountError),
                  style: { maxWidth: '150px' },
                  disabled: !undercutMode,
                }}
                uiCurrency={uiCurrency}
                disabled={!undercutMode || Boolean(skipUndercut)}
                value={
                  undercutMode == null
                    ? ''
                    : undercutAbsoluteAmount != null
                    ? Math.abs(undercutAbsoluteAmount)
                    : ''
                }
                placeholder={
                  undercutMode == null
                    ? eventPricingSettings?.undercutAbsoluteAmount?.toString() ??
                      accountPricingSettings?.undercutAbsoluteAmount?.toString() ??
                      undefined
                    : undefined
                }
                onChange={(e) => {
                  clearErrors('undercutAbsoluteAmount');
                  clearErrors('undercutRelativeAmount');
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    if (
                      undercutAbsoluteAmountMode ===
                      UndercutPriceSettingsMode.Premium
                    ) {
                      onUndercutChange(undefined, -v);
                    } else {
                      onUndercutChange(undefined, v);
                    }
                  } else {
                    onUndercutChange(undefined, null);
                  }
                }}
              />
            </PosFormField>
          </Stack>
        )}

        {autopricingSettingsAvailable && listing && (
          <Stack direction="row" gap="l" alignItems="center" flexWrap="grow">
            <div className={styles.inlineText}>
              <Content id={ContentId.QuantitiesFilter} />
            </div>
            <PosMultiSelect
              disabled={disabled}
              align="start"
              values={quantitiesFilterValue ?? []}
              onChange={onUpdateQuantitiesFilter}
              valueOptionsContent={quantitiesFilterOptions}
            />

            <AutoPricingSectionRowIdFilter
              listing={listing}
              disabled={disabled}
            />
          </Stack>
        )}
      </DetailGroup>
    </>
  );
};
