import { arrayMove } from '@dnd-kit/sortable';
import { castDraft, produce } from 'immer';
import { isEmpty, isEqual } from 'lodash-es';
import { nanoid } from 'nanoid';
import { ReactNode, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterToolbarProps } from 'src/components/FilterToolbar';
import {
  CustomQuickFilterOfListingQuery,
  CustomQuickFilterOfMarketplacePaymentQuery,
  CustomQuickFilterOfOnSaleEventQuery,
  CustomQuickFilterOfPurchaseOrderQuery,
  CustomQuickFilterOfSaleQuery,
  UserSetting,
} from 'src/WebApiController';

import { useServerUserSetting, UseUserSettingHook } from './useUserSetting';

type QuickFiltersState = {
  [id: string]: {
    /**
     * Whether to hide the filter in the quick filter bar.
     */
    isHidden?: boolean;
    order?: number;
  };
};

/**
 * Map quick filter settings to their data types.
 *
 * Add to this type if new settings are added.
 */
type CustomQuickFilterMap = {
  [UserSetting.QuickFiltersCustomInventory]: CustomQuickFilterOfListingQuery;
  [UserSetting.QuickFiltersCustomSales]: CustomQuickFilterOfSaleQuery;
  [UserSetting.QuickFiltersCustomPurchases]: CustomQuickFilterOfPurchaseOrderQuery;
  [UserSetting.QuickFiltersCustomMarketplacePayments]: CustomQuickFilterOfMarketplacePaymentQuery;
  [UserSetting.QuickFiltersCustomOnSaleEvent]: CustomQuickFilterOfOnSaleEventQuery;
};

type CustomQuickFilterUserSetting = keyof CustomQuickFilterMap;

type CustomQuickFilterData<S extends CustomQuickFilterUserSetting> =
  CustomQuickFilterMap[S]['data'];

// TODO add test to confirm that the server types are assignable to this
type CustomQuickFilter<D> = {
  // This should always match `schemaVersion` from server
  schemaVersion: '2';
  id: string;
  name: string;
  data: D;
};

export type DefaultQuickFilter<D> = Omit<
  CustomQuickFilter<D>,
  'schemaVersion' | 'name'
> & {
  name: ReactNode;
};

/**
 * `QuickFilter` augmented with extra fields. This is the type that is returned from the
 * `useQuickFilters` hook.
 */
export type ProcessedQuickFilter<D> = (
  | DefaultQuickFilter<D>
  | Omit<CustomQuickFilter<D>, 'schemaVersion'>
) & {
  isCustomFilter: boolean;
  isHidden: boolean;
};

/**
 * Helper type for consumers of this hook to use for typing default quick filters more easily.
 *
 * Do not use this within the `useQuickFilters` hook.
 */
export type QuickFilter<S extends CustomQuickFilterUserSetting> = Omit<
  CustomQuickFilterMap[S],
  'schemaVersion' | 'name'
> & {
  name: ReactNode;
};

// Remove eventIds from quick filters
const cleanCustomFilters = <S extends CustomQuickFilterUserSetting>(
  data: CustomQuickFilterData<S>
): CustomQuickFilterData<S> => {
  if ('eventIds' in data) {
    return {
      ...data,
      eventIds: null,
    };
  }
  if ('eventDates' in data && !('eventTimeFrameFilter' in data)) {
    // if the filter has eventDates but not eventTimeFrameFilter, we need to add eventTimeFrameFilter=null
    return {
      ...data,
      eventTimeFrameFilter: null,
    };
  }

  return data;
};

export type UseQuickFiltersOptions<
  S extends CustomQuickFilterUserSetting,
  D = CustomQuickFilterData<S>,
> = {
  /**
   * Id used to store quick filter state.
   *
   * This will be in the form `UserSetting.QuickFiltersState<PageName>`.
   *
   * eg. `UserSetting.QuickFiltersStateInventory`
   *
   * **NOTE**: This id cannot be changed or else saved user data for the page will be lost.
   */
  quickFiltersStateSetting:
    | UserSetting.QuickFiltersStateOnSaleEvent
    | UserSetting.QuickFiltersStateInventory
    | UserSetting.QuickFiltersStateSales
    | UserSetting.QuickFiltersStatePurchases
    | UserSetting.QuickFiltersStateMarketplacePayments;
  /**
   * Id used to store custom quick filters.
   *
   * This will be in the form `UserSetting.QuickFiltersCustom<PageName>`.
   *
   * eg. `UserSetting.QuickFiltersCustomInventory`
   *
   * **NOTE**: This id cannot be changed or else saved user data for the page will be lost.
   */
  customQuickFiltersSetting: S;
  /**
   * Array of default quick filters that cannot be deleted.
   */
  defaultQuickFilters: DefaultQuickFilter<D>[];
  useUserSetting?: UseUserSettingHook;
};

/**
 * Hook to help with implementing quick filters.
 * @param options
 * @returns
 */
export function useQuickFilters<
  S extends CustomQuickFilterUserSetting,
  D = CustomQuickFilterData<S>,
>({
  quickFiltersStateSetting,
  customQuickFiltersSetting,
  defaultQuickFilters,
  useUserSetting = useServerUserSetting,
}: UseQuickFiltersOptions<S, D>) {
  const defaultQuickFiltersState = useMemo<QuickFiltersState>(() => ({}), []);
  const {
    value: quickFiltersState = defaultQuickFiltersState,
    setUserSetting: setQuickFiltersState,
  } = useUserSetting<QuickFiltersState>({ id: quickFiltersStateSetting });

  const defaultCustomQuickFilters = useMemo<CustomQuickFilter<D>[]>(
    () => [],
    []
  );
  const {
    value: _customQuickFilters = defaultCustomQuickFilters,
    setUserSetting: setCustomQuickFilters,
  } = useUserSetting<CustomQuickFilter<D>[]>({
    id: customQuickFiltersSetting,
  });
  const customQuickFilters = _customQuickFilters.filter((filter) => {
    // XXX POS-1317 skip filters from the old version
    return filter.schemaVersion === '2';
  });

  const quickFilters = useMemo<ProcessedQuickFilter<D>[]>(() => {
    const processFilter = (
      isCustomFilter: boolean,
      { id, name, data }: DefaultQuickFilter<D> | CustomQuickFilter<D>
    ): ProcessedQuickFilter<D> => {
      return {
        id,
        name,
        isCustomFilter,
        isHidden: quickFiltersState[id]?.isHidden ?? false,
        data: cleanCustomFilters(data as CustomQuickFilterData<S>) as D,
      };
    };

    // merge default and custom quick filters
    const filterSortKeys: {
      order: number;
      originalIndex: number;
      id: string;
    }[] = [];
    const filterMap: { [id: string]: ProcessedQuickFilter<D> } = {};
    defaultQuickFilters.forEach((filter, i) => {
      filterMap[filter.id] = processFilter(false, filter);
      filterSortKeys.push({
        // we default to negative infinity because we want default quick filters to show at the
        // beginning if it has no order set
        order: quickFiltersState[filter.id]?.order ?? Number.NEGATIVE_INFINITY,
        originalIndex: i,
        id: filter.id,
      });
    });
    customQuickFilters.forEach((filter, i) => {
      filterMap[filter.id] = processFilter(true, filter);
      filterSortKeys.push({
        // we default to positive infinity because we want default quick filters to show at the
        // end if it has no order set
        order: quickFiltersState[filter.id]?.order ?? Number.POSITIVE_INFINITY,
        originalIndex: i,
        id: filter.id,
      });
    });

    return filterSortKeys
      .sort((a, b) => {
        if (a.order === b.order) {
          return a.originalIndex - b.originalIndex;
        }
        return a.order - b.order;
      })
      .map(({ id }) => filterMap[id]);
  }, [quickFiltersState, defaultQuickFilters, customQuickFilters]);

  type CustomQuickFilterInput = Omit<
    CustomQuickFilter<D>,
    'schemaVersion' | 'id'
  >;
  return {
    quickFilters,
    /**
     * Show or hide quick filter within the toolbar.
     * @param id
     * @param isShown
     */
    toggleQuickFilter: (id: string, isShown: boolean) => {
      const newState = produce(quickFiltersState, (draftState) => {
        if (!draftState[id]) {
          draftState[id] = {};
        }
        if (isShown) {
          delete draftState[id].isHidden;
          if (isEmpty(draftState[id])) {
            delete draftState[id];
          }
        } else {
          draftState[id].isHidden = true;
        }
      });

      setQuickFiltersState(newState);
    },
    /**
     * @param id The id of the quick filter that was moved.
     * @param overId The id of the quick filter that the moved quick filter should be placed into
     * the location of.
     */
    moveQuickFilter: (id: string, overId: string) => {
      if (id === overId) {
        return;
      }

      const ids = quickFilters.map(({ id }) => id);
      const oldIndex = ids.indexOf(id);
      const newIndex = ids.indexOf(overId);
      const movedIds = arrayMove(ids, oldIndex, newIndex);
      const newState = produce(quickFiltersState, (draftState) => {
        movedIds.forEach((id, i) => {
          if (!draftState[id]) {
            draftState[id] = {};
          }
          draftState[id].order = i;
        });
      });

      setQuickFiltersState(newState);
    },
    createCustomQuickFilter: ({ name, data }: CustomQuickFilterInput) => {
      const cleanData = cleanCustomFilters<S>(
        data as CustomQuickFilterData<S>
      ) as D;

      setCustomQuickFilters(
        produce(customQuickFilters, (draftState) => {
          draftState.push(
            castDraft({
              schemaVersion: '2',
              id: nanoid(),
              name,
              data: cleanData,
            })
          );
        })
      );
    },
    deleteCustomQuickFilter: (id: string) => {
      setCustomQuickFilters(
        customQuickFilters.filter((filter) => filter.id !== id)
      );
    },
    updateCustomQuickFilter: (
      id: string,
      updatedFilter: Partial<CustomQuickFilterInput>
    ) => {
      setCustomQuickFilters(
        produce(customQuickFilters, (draftState) => {
          const idx = draftState.findIndex((filter) => filter.id === id);
          if (idx !== -1) {
            if (updatedFilter.name != null) {
              draftState[idx].name = updatedFilter.name;
            }
            if (updatedFilter.data != null) {
              draftState[idx].data = castDraft(updatedFilter.data);
            }
          }
        })
      );
    },
  };
}

const QUICK_FILTER_SEARCH_PARAM_KEY = 'quickFilterId';

export function useQuickFilterSearchParam() {
  const [searchParams] = useSearchParams();
  return searchParams.get(QUICK_FILTER_SEARCH_PARAM_KEY);
}

export type UseFilterToolbarQuickFiltersOptions<
  S extends CustomQuickFilterUserSetting,
  TQuery = CustomQuickFilterData<S>,
> = UseQuickFiltersOptions<S, TQuery> & {
  currentQuery: TQuery;
  initialQuery: TQuery;
  onSelect: ({ id, query }: { id: string; query: TQuery }) => void;
};

export type UseFilterToolbarQuickFiltersReturn = Pick<
  FilterToolbarProps,
  | 'quickFilters'
  | 'isAllSelected'
  | 'onResetAll'
  | 'onToggleQuickFilter'
  | 'onMoveQuickFilter'
  | 'onCreateCustomQuickFilter'
  | 'onDeleteCustomQuickFilter'
  | 'onUpdateCustomQuickFilter'
>;

/**
 * Hook specifically for use with `FilterToolbar`.
 * @param param0
 * @param param1
 * @returns
 */
export function useFilterToolbarQuickFilters<
  S extends CustomQuickFilterUserSetting,
  TQuery = CustomQuickFilterData<S>,
>({
  currentQuery,
  initialQuery,
  onSelect,
  ...useQuickFiltersOptions
}: UseFilterToolbarQuickFiltersOptions<
  S,
  TQuery
>): UseFilterToolbarQuickFiltersReturn {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedQuickFilterId = searchParams.get(QUICK_FILTER_SEARCH_PARAM_KEY);

  const setQuickFilterParam = useCallback(
    (id: string) => {
      setSearchParams((params) => {
        params.set(QUICK_FILTER_SEARCH_PARAM_KEY, id);
        return params;
      });
    },
    [setSearchParams]
  );
  const resetQuickFilterParam = useCallback(() => {
    setSearchParams((params) => {
      params.delete(QUICK_FILTER_SEARCH_PARAM_KEY);
      return params;
    });
  }, [setSearchParams]);

  const {
    quickFilters,
    toggleQuickFilter,
    moveQuickFilter,
    createCustomQuickFilter,
    deleteCustomQuickFilter,
    updateCustomQuickFilter,
  } = useQuickFilters(useQuickFiltersOptions);
  const finalQuickFilters = useMemo(() => {
    return quickFilters.map(
      ({ id, name, isCustomFilter, isHidden, data: query }) => {
        return {
          id,
          name,
          isCustomFilter,
          isHidden,
          isSelected: selectedQuickFilterId === id,
          onClick: () => {
            setQuickFilterParam(id);
            onSelect?.({ id, query });
          },
        };
      }
    );
  }, [quickFilters, selectedQuickFilterId, onSelect, setQuickFilterParam]);

  return {
    quickFilters: finalQuickFilters,
    isAllSelected:
      selectedQuickFilterId == null && isEqual(currentQuery, initialQuery),
    onToggleQuickFilter: toggleQuickFilter,
    onMoveQuickFilter: moveQuickFilter,
    onCreateCustomQuickFilter: ({ name }) => {
      createCustomQuickFilter({ name, data: currentQuery });
    },
    onDeleteCustomQuickFilter: deleteCustomQuickFilter,
    onUpdateCustomQuickFilter: updateCustomQuickFilter,
    onResetAll: () => {
      resetQuickFilterParam();
      onSelect?.({ id: 'all', query: initialQuery });
    },
  };
}
