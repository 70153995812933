import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { ReportBuilderDialogV2 } from 'src/dialogs/ReportBuilderDialog/ReportBuilderDialogV2';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ReportsTableContainerV2 } from 'src/tables/ReportsTable/ReportsTableContainerV2';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { Feature, Permission } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Reports.css';
import { useReportV2Actions } from './useReportV2Actions';

export function ReportsV2() {
  const reportsTitle = 'Reports V2'; // no need to translate - once we release - we will need to use ContentId.Report again
  const hasCreateReportsPermission = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );
  const hasReportV2BuilderFeature = useUserHasFeature(Feature.ReportV2Builder);

  const canCreateReport =
    hasCreateReportsPermission && hasReportV2BuilderFeature;

  const {
    currentReport,
    setCurrentReport,
    onAddNewReport,
    onReportSaveSubmit,
    onReportAction,
    onReportShareSubmit,
    onReportDelete,
    reportBuilderDialog,
    deleteDialog,
    shareDialog,
  } = useReportV2Actions();

  return (
    <LayoutContent
      mainRoute={MainRoute.ReportsV2}
      routeTitle={reportsTitle}
      rightContent={
        canCreateReport && (
          <Button
            style={{ height: 'min-content', width: 'fit-content' }}
            onClick={onAddNewReport}
          >
            <Content id={ContentId.CreateNewReport} />
          </Button>
        )
      }
    >
      <ReportsPageV2 onReportAction={onReportAction} />
      {currentReport && (
        <>
          <ReportBuilderDialogV2
            {...reportBuilderDialog.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialog.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ShareReportDialog
            {...shareDialog.dialogProps}
            reportName={currentReport?.reportName ?? ''}
            roleIdsShared={currentReport?.roleIdsToShare ?? []}
            sellerUserIdsShared={currentReport?.sellerUserIdsToShare ?? []}
            onOkay={onReportShareSubmit}
            onClosed={() => {
              setCurrentReport(undefined);
              shareDialog.closeDialog();
            }}
          />
        </>
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteReport} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onReportDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
      {/* <ReportHistoryDialog
        {...reportHistoryDialog.dialogProps}
        reportConfig={currentReport}
        onClosed={() => {
          setCurrentReport(undefined);
          reportHistoryDialog.closeDialog();
        }}
      /> */}
    </LayoutContent>
  );
}

function ReportsPageV2(props: {
  onReportAction: (
    report: ReportConfigV2,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
}) {
  const {
    reportConfigs: reportConfigsInventory,
    isLoading: reportInventoryLoading,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });
  const { reportConfigs: reportConfigsSale, isLoading: reportSaleLoading } =
    useReportConfigsV2<ReportTypesV2.SaleV2>({
      reportType: ReportTypesV2.SaleV2,
    });
  const {
    reportConfigs: reportConfigsCommission,
    isLoading: reportCommissionLoading,
  } = useReportConfigsV2<ReportTypesV2.Commission>({
    reportType: ReportTypesV2.Commission,
  });

  const reports: ReportConfigV2[] = useMemo(() => {
    const reportsInventoryWithType = reportConfigsInventory.map((r) => ({
      ...r,
      reportType: ReportTypesV2.InventoryV2,
    }));
    const reportsSaleWithType = reportConfigsSale.map((r) => ({
      ...r,
      reportType: ReportTypesV2.SaleV2,
    }));
    const reportsCommissionWithType = reportConfigsCommission.map((r) => ({
      ...r,
      reportType: ReportTypesV2.Commission,
    }));
    return [
      ...reportsInventoryWithType,
      ...reportsSaleWithType,
      ...reportsCommissionWithType,
    ];
  }, [reportConfigsInventory, reportConfigsSale, reportConfigsCommission]);

  return (
    <div className={styles.root}>
      {reportInventoryLoading ||
      reportSaleLoading ||
      reportCommissionLoading ? (
        <PosSpinner />
      ) : (
        <ReportsTableContainerV2 reports={reports} {...props} />
      )}
    </div>
  );
}
