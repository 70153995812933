import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKET_PLACE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Marketplace,
  SaleDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export interface MarkSaleAsPaidForm {
  paymentDate: string;
  paymentReference: string;
  marketplace: Marketplace;
}

export type BulkMarkSaleAsPaidDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  onOkay: (
    markSaleAsPaidForm: MarkSaleAsPaidForm | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export const BulkMarkSaleAsPaidDialog = ({
  ...rest
}: BulkMarkSaleAsPaidDialogProps) => {
  const methods = useForm<MarkSaleAsPaidForm>();

  return (
    <FormProvider {...methods}>
      <BulkMarkSaleAsPaidDialogContent {...rest} {...methods} />
    </FormProvider>
  );
};

function BulkMarkSaleAsPaidDialogContent({
  updateKey,
  sales,
  event,
  onOkay,
  onCancel,
  handleSubmit,
  watch,
  reset,
  control,
  formState,
  setValue,
  register,
  trigger,
  ...rest
}: BulkMarkSaleAsPaidDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<MarkSaleAsPaidForm, unknown>>,
    'children'
  >) {
  const { loginContext } = useAppContext();
  const requiredText = useContent(ContentId.Required);
  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const paymentDate = watch('paymentDate');
  const paymentReference = watch('paymentReference');
  const marketplace = watch('marketplace');
  const { isValid: isFormValid } = formState;

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems(true);
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
      setMainDialogOpened(false);
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Sale,
    BulkActionType.MarkSaleAsPaid,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        handleSubmit((formValues) =>
          onOkay(formValues, hasBackgroundBulkEditFeature, undefined, preview)
        )();
      }
      setIsLoading(false);
    },
    [handleSubmit, hasBackgroundBulkEditFeature, onOkay, preview]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const isInputValid = useMemo(() => {
    return marketplace && paymentDate && paymentReference;
  }, [marketplace, paymentDate, paymentReference]);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.MarkSalesAsPaid} />}
          />
        }
        onOpened={() => {
          initJob();
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        onKeyUp={(e) => {
          if (submitButtonRef.current && e.key === 'Enter' && isInputValid) {
            submitButtonRef.current.click();
          }
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Sale}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            disabled={!isInputValid}
            submittButtonRef={submitButtonRef}
            onBeforeSubmit={() => {
              if (!isFormValid) {
                trigger();
              }
              return isFormValid;
            }}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.Sale}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Stack direction="column" width="full" gap="xxl">
            <PosFormField
              label={<Content id={ContentId.Marketplace} />}
              errors={formState.errors.marketplace?.message}
            >
              <PosEnumSelect
                style={{ width: '100%' }}
                value={marketplace}
                placeholderText={ContentId.Select}
                enableEmptySelection
                onChange={(marketplace) => {
                  if (marketplace) {
                    setValue('marketplace', marketplace);
                  }
                }}
                valueOptionsContent={(
                  loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                    (m) => m.mkp
                  ) ?? [Marketplace.StubHub]
                )
                  .concat(Marketplace.Offline)
                  .reduce(
                    (cur, m) => {
                      cur[m] = MARKET_PLACE_TO_CID[m];
                      return cur;
                    },
                    {} as Record<Marketplace, string>
                  )}
              />
            </PosFormField>
            <PosFormField
              label={<Content id={ContentId.PaymentDate} />}
              errors={formState.errors.paymentDate?.message}
            >
              <Controller
                name={'paymentDate'}
                rules={{ required: requiredText }}
                control={control}
                render={({ field: { ...field } }) => (
                  <DatePickerInput
                    fieldError={formState.errors.paymentDate?.message}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d.toISOString());
                    }}
                  />
                )}
              />
            </PosFormField>
            <PosFormField
              label={<Content id={ContentId.PaymentReference} />}
              errors={formState.errors.paymentReference?.message}
            >
              <PosTextField
                {...register('paymentReference', { required: requiredText })}
              />
            </PosFormField>
          </Stack>
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
