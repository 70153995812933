import { vars } from 'src/core/themes';
import { ArrowDownIcon, ArrowUpIcon } from 'src/svgs/Viagogo';

import * as styles from './SortButton.css';
import {
  ArrowType,
  getNextSortStatus,
  SortButtonProps,
  SortStatus,
} from './SortButton.utils';

type GetIconClass = (
  arrow: ArrowType,
  disabled: boolean,
  status: SortStatus
) => string;
const getIconClass: GetIconClass = (arrow, disabled, status) => {
  if (!disabled) {
    // If asc order, up arrow is active state
    if (status === SortStatus.ASC && arrow === ArrowType.UP) {
      return styles.sortActive;
    }

    // If desc order, down arrow is active state
    if (status === SortStatus.DESC && arrow === ArrowType.DOWN) {
      return styles.sortActive;
    }
  }

  return styles.sortInactive;
};

export function SortButton({
  onClick,
  sortStatus,
  disabled = false,
  allowUnsorted = true,
}: SortButtonProps) {
  const upArrowClass = getIconClass(ArrowType.UP, disabled, sortStatus);
  const downArrowClass = getIconClass(ArrowType.DOWN, disabled, sortStatus);

  return (
    <div
      onClick={() => {
        !disabled && onClick?.(getNextSortStatus(sortStatus, allowUnsorted));
      }}
      className={styles.sortIconContainer}
    >
      <ArrowUpIcon
        size={vars.iconSize.xs}
        withHoverEffect
        className={upArrowClass}
      />
      <ArrowDownIcon
        size={vars.iconSize.xs}
        withHoverEffect
        className={downArrowClass}
      />
    </div>
  );
}
