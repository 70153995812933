import { useCallback, useContext } from 'react';
import { UploadButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { ButtonProps } from 'src/core/ui';
import { TurnOnSaleAutoFulfillDialog } from 'src/dialogs/TurnOnSaleAutoFulfillDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { UploadETicketsSelector } from 'src/modals/PurchaseWizard/UploadETicketsSelector';
import { UploadETicketsModalProps } from 'src/modals/UploadETickets';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ListingActionType,
  SaleActionType,
  TicketType,
} from 'src/WebApiController';

export const LaunchUploadETickets = ({
  entityId,
  marketplaceSaleId,
  variant,
  iconOnlyMode,
  dropdownItemMode,
  disabled,
  action,
  ticketType,
  isSaleNoFulfill,
  ...modalProps
}: {
  entityId: number;
  marketplaceSaleId?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  dropdownItemMode?: boolean;
  disabled?: boolean;
  isSaleNoFulfill?: boolean | null;
  action:
    | ListingActionType.PredeliverETickets
    | ListingActionType.RedeliverETickets
    | ListingActionType.PredeliverQRCode
    | ListingActionType.RedeliverQRCode
    | SaleActionType.Fulfill
    | SaleActionType.Refulfill;
} & UploadETicketsModalProps) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext();

  const {
    launchDialog: launchConfirmDialog,
    closeDialog: closeConfirmDialog,
    dialogProps,
  } = useBasicDialog();

  const {
    openDialog: openTurnOnSaleAutoFulfillDialog,
    closeDialog: closeTurnOnSaleAutoFulfillDialog,
  } = useDialog(DialogId.Confirm, TurnOnSaleAutoFulfillDialog);

  const uploadETickets = useCallback(() => {
    setModal({
      children: (
        <UploadETicketsSelector {...modalProps} ticketType={ticketType} />
      ),
      clickOutsideToClose: true,
      size: 'slide-in',
    });
  }, [setModal, modalProps, ticketType]);

  const onLaunchDialog = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      setActivePosEntity(entityId, marketplaceSaleId);
      uploadETickets();
    },
    [setActivePosEntity, entityId, marketplaceSaleId, uploadETickets]
  );

  const onClickHandler = useCallback(
    (e?: React.MouseEvent) => {
      if (isSaleNoFulfill) {
        e?.preventDefault();
        e?.stopPropagation();

        openTurnOnSaleAutoFulfillDialog({
          saleId: entityId,
          onOkay: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
          onCancel: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
        });
      } else {
        onLaunchDialog(e);
      }
    },
    [
      closeTurnOnSaleAutoFulfillDialog,
      entityId,
      isSaleNoFulfill,
      onLaunchDialog,
      openTurnOnSaleAutoFulfillDialog,
    ]
  );

  const actionContentId =
    action === SaleActionType.Refulfill && isSaleNoFulfill
      ? ContentId.Fulfill
      : action === ListingActionType.PredeliverQRCode ||
        (ticketType === TicketType.QRCode && action === SaleActionType.Fulfill)
      ? ContentId.UploadQRCodes
      : action === ListingActionType.RedeliverQRCode ||
        (ticketType === TicketType.QRCode &&
          action === SaleActionType.Refulfill)
      ? ContentId.ReuploadQRCodes
      : action === ListingActionType.RedeliverETickets ||
        action === SaleActionType.Refulfill
      ? ContentId.ReuploadETickets
      : ContentId.UploadETickets;

  const onAttemptLaunchDialog = useCallback(() => {
    if (action === SaleActionType.Refulfill) {
      launchConfirmDialog();
    } else {
      onClickHandler();
    }
  }, [action, launchConfirmDialog, onClickHandler]);

  return (
    <>
      {dropdownItemMode ? (
        <PosDropdownItem disabled={disabled} onClick={onAttemptLaunchDialog}>
          <Content id={actionContentId} />
        </PosDropdownItem>
      ) : (
        <UploadButton
          variant={variant}
          disabled={disabled}
          iconOnlyMode={iconOnlyMode}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onAttemptLaunchDialog();
          }}
          textContentId={actionContentId}
        />
      )}
      <ConfirmDialog
        {...dialogProps}
        size={'md'}
        disabled={disabled}
        isLoading={disabled}
        headerText={<Content id={actionContentId} />}
        bodyText={<Content id={ContentId.ReuploadDocumentWarning} />}
        onOkay={onClickHandler}
        onCancel={closeConfirmDialog}
      />
    </>
  );
};
