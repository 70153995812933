import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useInputPriceFocusContext } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { CheckIcon, IconsFill, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { onPriceFieldChange } from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  InventoryViewMode,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingMetrics,
  ListingQuery,
  ListingStatus,
} from 'src/WebApiController';

import { TableCellDiv } from './ListingPriceCell.styled';
import { ListingPricingInput, PriceField } from './ListingPriceForm.types';

type ListingPriceCellProps = {
  listing: Listing;
  currencyCode: string;
  nextEditableListingId?: number | undefined;
  sellerFee?: number | null;
  field: PriceField;
  otherField: PriceField;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  viagVirtualId?: string;
};

export const ListingPriceCell = ({
  listing,
  currencyCode,
  sellerFee,
  field,
  otherField,
  onFocus,
  disabled,
  viagVirtualId,
  nextEditableListingId,
}: ListingPriceCellProps) => {
  const { id: listingId, idOnMkp, status } = listing;
  const { getUiCurrency } = useLocalizationContext();
  const { setValue, watch, clearErrors, getFieldState, formState } =
    useFormContext<ListingPricingInput>();
  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const price = watch(field) ?? 0;
  const otherPrice = watch(otherField) ?? 0;

  const isSubmitting = watch('isSubmitting');
  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const currentPageIndex = watch('currentPageIndex');

  const priceIsSubmitting =
    isSubmitting &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode),
    [currencyCode, getUiCurrency]
  );
  const {
    pageSize,
    disablePagination,
    inputPriceFocusContext,
    setInputPriceFocusContext,
    disabledFocusContexts,
  } = useInputPriceFocusContext();

  const { filterQuery } = useFilterQueryContext<ListingQuery>();

  const fieldError = getFieldState(field, formState)?.error?.message;

  const priceHasChanged =
    formState.defaultValues?.[field] !== price &&
    price != null &&
    price >= 0 &&
    !isSubmitting;

  // ListingTable don't always have these context wrappers - remember not to assume these are NOT NULL
  const findNextFocusIndex = useCallback(() => {
    // need to skip the disabled rows from disabledFocusContexts
    let newRowIndex = rowIndex + 1;
    let nextRowIndex =
      !disablePagination && pageSize && newRowIndex >= pageSize
        ? 0
        : newRowIndex;
    let nextPageIndex = disablePagination
      ? 0
      : nextRowIndex === 0
      ? currentPageIndex + 1
      : currentPageIndex;

    while (
      disabledFocusContexts?.some(
        (x) =>
          x?.currentPageIndex === nextPageIndex &&
          x?.rowIndex === nextRowIndex &&
          (x?.viagVirtualId === viagVirtualId ||
            filterQuery.viewMode === InventoryViewMode.FlattenedView)
      )
    ) {
      newRowIndex = nextRowIndex + 1;
      nextRowIndex =
        !disablePagination && pageSize && newRowIndex >= pageSize
          ? 0
          : newRowIndex;
      nextPageIndex = disablePagination
        ? 0
        : nextRowIndex === 0
        ? nextPageIndex + 1
        : nextPageIndex;
    }

    return { nextRowIndex, nextPageIndex };
  }, [
    currentPageIndex,
    disablePagination,
    disabledFocusContexts,
    filterQuery.viewMode,
    pageSize,
    rowIndex,
    viagVirtualId,
  ]);

  const inputIsFocus = useMemo(() => {
    if (!inputPriceFocusContext) {
      return false;
    }
    const {
      viagVirtualId: ctxEventId,
      rowIndex: ctxRowIndex,
      listingId,
      priceField,
    } = inputPriceFocusContext;

    if (priceField !== field) {
      return false;
    }

    if (
      ctxEventId !== viagVirtualId &&
      filterQuery.viewMode === InventoryViewMode.FlattenedView
    ) {
      return false;
    }

    // Listing id over rowIndex
    if (listingId) {
      return listingId === listing.id;
    }
    return ctxRowIndex === rowIndex;
  }, [
    viagVirtualId,
    field,
    filterQuery.viewMode,
    inputPriceFocusContext,
    listing.id,
    rowIndex,
  ]);

  const saveMsg = useContent(ContentId.Save);

  const { showErrorDialog } = useErrorBoundaryContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const isListed =
    status === ListingStatus.Listed || status === ListingStatus.ListingPending;

  const onBroadcastClick = useCallback(() => {
    tryInvokeApi(
      async () => {
        const client = new ListingClient(activeAccountWebClientConfig);

        const result = await client.deleteMarketplaceListings(listingId, []);
        if (result) {
          refreshMetrics?.();

          // Refresh the active data so the SaleDetail dialog and table will have the new content
          setActivePosEntity(listingId, idOnMkp, true, [
            ListingDetailDataField.Basic,
          ]);
        }
      },
      (error) => {
        showErrorDialog('ListingClient.deleteMarketplaceListings', error, {
          trackErrorData: { listingId, idOnMkp },
        });
      }
    );
  }, [
    activeAccountWebClientConfig,
    listingId,
    idOnMkp,
    refreshMetrics,
    setActivePosEntity,
    showErrorDialog,
  ]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value) ?? 0;
      onPriceFieldChange(
        newValue,
        field,
        price,
        otherField,
        otherPrice,
        // This is the otherToFieldRatio, so if field is allInPrice, the ratio os All-In-to-List
        field === 'allInPrice'
          ? 1 - (sellerFee ?? 0) // all-in-to-list
          : 1 / (1 - (sellerFee ?? 0)), // list-to-all-in
        clearErrors,
        setValue
      );
    },
    [clearErrors, field, otherField, otherPrice, price, setValue, sellerFee]
  );

  const onBlurHandler = useCallback(() => {
    if (
      rowIndex !== undefined &&
      inputPriceFocusContext?.rowIndex === rowIndex
    ) {
      setInputPriceFocusContext(undefined);
    }
  }, [rowIndex, inputPriceFocusContext?.rowIndex, setInputPriceFocusContext]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handlePriceSubmit = useCallback(() => {
    setValue('isSubmitting', true);

    if (price === 0 && otherPrice === 0 && isListed) {
      // If both price is zero and the listing is listed - ask
      // user if they want to unbroadcast
      onBroadcastClick();
    }
  }, [isListed, onBroadcastClick, otherPrice, price, setValue]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (fieldError) {
        return;
      }
      if (rowIndex !== undefined) {
        if (priceHasChanged) {
          e.stopPropagation();
          // Only submit if Enter is hit in the box that has changes
          handlePriceSubmit();
        } else {
          e.stopPropagation();
        }

        const { nextRowIndex, nextPageIndex } = findNextFocusIndex();
        setInputPriceFocusContext({
          viagVirtualId: viagVirtualId!,
          rowIndex: nextRowIndex,
          priceField: field,
          currentPageIndex: nextPageIndex,
          listingId: nextEditableListingId,
        });
      }
    }
  };

  useEffect(() => {
    if (inputIsFocus && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [inputIsFocus]);

  const canSetPrice = useUserCanSetPrice(listing, false);

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={disabled ? undefined : fieldError}
        showErrorsInline
        style={{ width: 'fit-content' }}
      >
        <PosCurrencyField
          onClickCapture={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          rootProps={{
            variant: 'small',
            state: getTextFieldState(fieldError),
            disabled:
              disabled ||
              priceIsSubmitting ||
              isSelectionMode ||
              isSubmittingPricingSettings,
          }}
          uiCurrency={uiCurrency}
          alignment="right"
          ref={inputRef}
          disabled={
            disabled ||
            priceIsSubmitting ||
            isSubmittingPricingSettings ||
            isSelectionMode ||
            !canSetPrice
          }
          postfixDisplay={
            !disabled && !fieldError && priceHasChanged ? (
              <CheckIcon
                title={saveMsg}
                withHoverEffect
                size={vars.iconSize.s}
                fill={IconsFill.textSuccess}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePriceSubmit();
                }}
              />
            ) : priceIsSubmitting ? (
              <div className="operation-button">
                <RotatingWrapper>
                  <ProcessingIcon size={vars.iconSize.l} />
                </RotatingWrapper>
              </div>
            ) : undefined
          }
          value={price}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onClick={() => inputRef?.current?.select()}
        />
      </PosFormField>
    </TableCellDiv>
  );
};
