import { useCallback } from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';

import { ReportConfigV2, useReportConfigsV2 } from './useReportConfigsV2';

export function useReportConfigActionsV2() {
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    deleteReportConfig: deleteReportConfigInventory,
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });

  const {
    deleteReportConfig: deleteReportConfigSale,
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigsV2<ReportTypesV2.SaleV2>({
    reportType: ReportTypesV2.SaleV2,
  });

  const upsertReportConfig = useCallback(
    async (report: ReportConfigV2) => {
      return await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypesV2.InventoryV2) {
            return await upsertReportConfigInventory(report.reportId, report);
          } else if (report.reportType === ReportTypesV2.SaleV2) {
            return await upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportV2Client.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const deleteReportConfig = useCallback(
    async (reportType: ReportTypesV2, reportId: number) => {
      await tryInvokeApi(
        async () => {
          if (reportType === ReportTypesV2.InventoryV2) {
            deleteReportConfigInventory(reportId);
          } else if (reportType === ReportTypesV2.SaleV2) {
            deleteReportConfigSale(reportId);
          }
        },
        (error) => {
          showErrorDialog('UserSetting.deleteReport', error, {
            trackErrorData: {
              reportType,
              reportId,
            },
          });
        }
      );
    },
    [deleteReportConfigInventory, deleteReportConfigSale, showErrorDialog]
  );
  const getReportConfigNameForDuplicate = useCallback(
    (reportType: ReportTypesV2, reportName: string) =>
      reportType === ReportTypesV2.InventoryV2
        ? getReportConfigNameForDuplicateInventory(reportName)
        : getReportConfigNameForDuplicateSale(reportName),
    [
      getReportConfigNameForDuplicateInventory,
      getReportConfigNameForDuplicateSale,
    ]
  );

  return {
    upsertReportConfig,
    deleteReportConfig,
    getReportConfigNameForDuplicate,
  };
}
