import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContentContext } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMenuState } from 'src/contexts/MenuContext/MenuContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { useFetchLoginContext } from 'src/hooks/useFetchLoginContext';
import { useMainAccessibleRoute } from 'src/hooks/useMainAccessibleRoute';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useNetworkState } from 'src/hooks/useNetworkState';
import { useRefreshListingOnNotification } from 'src/hooks/useRefreshListingOnNotification';
import { MainNavTopBar } from 'src/navigations/MainNav/MainNavTopBar';
import { Offline } from 'src/navigations/Routes/Offline';
import { ContentId } from 'src/utils/constants/contentId';
import { navigateToMainRoute } from 'src/utils/deepLinkUtils';
import { hasAccessToRoute } from 'src/utils/userUtils';
import { CoBrandId } from 'src/WebApiController';

import { MainNavSideBar, MobileTopBar } from '../MainNav';
import { MainRoute } from '../Routes/MainRoute';
import * as styles from './LayoutContent.css';
import {
  BottomNavBarContainer,
  UnauthorizedAccessMessageContainer,
} from './LayoutContent.styled';
import { LayoutContentHeader } from './LayoutContentHeader';

const OFFLINE_TIMEOUT_MS = 15 * 1000;

export function LayoutContent({
  children,
  mainRoute,
  routeTitle,
  centerContent,
  rightContent,
  returnUrl,
}: PropsWithChildren<{
  mainRoute: MainRoute;
  routeTitle?: string;
  centerContent?: ReactNode;
  rightContent?: ReactNode;
  returnUrl?: string;
}>) {
  const { appContext, cobrandId, loginContext, setLoginContext, setMainRoute } =
    useAppContext();
  const { isLoading } = useContentContext();
  const { isMenuOpened, toggleIsMenuOpened } = useMenuState();
  const isOnline = useNetworkState(OFFLINE_TIMEOUT_MS);
  const navigate = useNavigate();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { fetchLoginContext } = useFetchLoginContext();
  const isMobile = useMatchMedia('mobile');
  const [bottomNavbarRef, { height: bottomNavbarHeight }] =
    useMeasure<HTMLDivElement>();
  useRefreshListingOnNotification();

  // Set what view we're in
  useEffect(() => {
    setMainRoute(mainRoute);
    if (
      // Only get login context if the route is not one of the login/logout ones
      ![
        MainRoute.Login,
        MainRoute.LoginCallback,
        MainRoute.LoginFailure,
        MainRoute.Logout,
      ].includes(mainRoute)
    ) {
      fetchLoginContext(setLoginContext, (error) => {
        showErrorDialog('LoginClient.getLoginContext', error, {
          onDismissError: () => {
            navigate(`/login`, {
              state: { returnUrl: window.location.pathname },
            });
          },
          trackErrorData: {
            returnUrl: window.location.pathname,
            mainRoute,
            loginContext,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAccess = hasAccessToRoute(
    loginContext?.user,
    appContext?.features,
    mainRoute
  );

  const mainRouteAccess = useMainAccessibleRoute();

  if (!hasAccess) {
    if (loginContext?.user) {
      // If the user doesn't have access
      // Find the first route he has access to and direct him to that
      if (mainRouteAccess) {
        navigateToMainRoute(mainRouteAccess, navigate);
      }
    } else {
      // If the user is null, the login may not complete so just wait
      // until the login user is not null
      return isOnline ? (
        <div className={styles.spinnerContainer}>
          <PosSpinner />
        </div>
      ) : (
        <Offline />
      );
    }
  }

  let routeTitleDefault;
  switch (cobrandId) {
    case CoBrandId.TicketUtil:
      routeTitleDefault = 'TicketUtils';
      break;
    case CoBrandId.Starburst:
      routeTitleDefault = 'Starburst Pro';
      break;
    case CoBrandId.StubHub:
    default:
      routeTitleDefault = 'StubHub Pro';
  }

  return (
    <div className={styles.root}>
      {(!isMobile || isMenuOpened) && (
        <MainNavSideBar
          toggleMenu={toggleIsMenuOpened}
          isExpanded={isMenuOpened}
        />
      )}

      <main className={styles.content}>
        {(appContext?.version ?? '') < (loginContext?.version ?? '') && (
          // Using < so in case the client has higher version than the server
          // (when deploy happens client bits from SVC2 may be hitting api from SVC3, should be very rare)
          // To test this locally, change the < to <= to see the message/button
          <Stack
            gap="l"
            width="full"
            justifyContent="center"
            className={styles.refreshNowContainer}
          >
            <WarningMessage
              message={<Content id={ContentId.NewAppVersion} />}
            />
            <Button onClick={() => window.location.reload()}>
              <Content id={ContentId.RefreshNow} />
            </Button>
          </Stack>
        )}
        {routeTitle ? (
          <Helmet>
            <title>
              {routeTitleDefault} - {routeTitle}
            </title>
          </Helmet>
        ) : (
          <Helmet>
            <title>{routeTitleDefault}</title>
          </Helmet>
        )}
        {isLoading ? (
          isOnline ? (
            <div className={styles.spinnerContainer}>
              <PosSpinner />
            </div>
          ) : (
            <Offline />
          )
        ) : hasAccess ? (
          <>
            {isMobile && (
              <MobileTopBar toggleMenu={() => toggleIsMenuOpened(true)} />
            )}
            {!isMobile &&
              [
                MainRoute.Home,
                MainRoute.Inventory,
                MainRoute.InventoryEvent,
                MainRoute.InventorySearch,
                MainRoute.Sales,
                MainRoute.SalesEvent,
                MainRoute.SalesSearch,
                MainRoute.Purchases,
                MainRoute.PurchasesEvent,
                MainRoute.PurchaseSearch,
                MainRoute.Payments,
                MainRoute.Reports,
                MainRoute.ReportsV2,
                MainRoute.SponsoredListings,
                MainRoute.Settings,
                MainRoute.Discovery,
                MainRoute.HelpCenter,
              ].includes(mainRoute) &&
              routeTitle && (
                <LayoutContentHeader
                  routeTitle={routeTitle}
                  centerContent={centerContent}
                  rightContent={rightContent}
                  returnUrl={returnUrl}
                />
              )}
            {children}
          </>
        ) : (
          loginContext?.user && (
            <UnauthorizedAccessMessageContainer>
              <Content id={ContentId.AccessToViewUnauthorized} />
            </UnauthorizedAccessMessageContainer>
          )
        )}
        {isMobile && (
          <>
            <div
              style={{
                minHeight: `${bottomNavbarHeight}px`,
                maxHeight: `${bottomNavbarHeight}px`,
              }}
            />
            <BottomNavBarContainer ref={bottomNavbarRef}>
              <MainNavTopBar />
            </BottomNavBarContainer>
          </>
        )}
      </main>
    </div>
  );
}
