import { useCallback, useContext, useMemo, useState } from 'react';
import { EventAccordionItemBodyComponentType } from 'src/components/Accordions';
import { ListingDisplay } from 'src/components/Listings/ListingDisplay';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { GroupItemIcon } from 'src/svgs/GroupItemIcon';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ListingGroupActions } from 'src/tables/ListingTable/configs/ListingGroupActions';
import { ListingGroupStatusSummary } from 'src/tables/ListingTable/configs/ListingGroupStatusSummary';
import { ListingPriceCell } from 'src/tables/ListingTable/configs/ListingPriceCell';
import { ListingPricingForm } from 'src/tables/ListingTable/configs/ListingPriceForm';
import { SeatInfoContainer } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getListingActionButtons,
  getListingDetailsModalConfigWithDeepLink,
} from 'src/utils/inventoryUtils';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  Event,
  Listing,
  ListingActionType,
  ListingGroup,
  Marketplace,
} from 'src/WebApiController';

import {
  TileHeader,
  TileLeftLayout,
  TileRightLayout,
  TileWrapper,
  TileWrapperIndented,
} from '../Tile/Tile.styled';
import * as styles from './ListingTile.css';
import {
  CheckboxContainer,
  DetailLabel,
  DetailUnitCostValue,
  DetailValue,
  Divider,
  ListingTilesContainer,
  TextAlign,
} from './ListingTile.styled';

export type ListingTilesProps = EventAccordionItemBodyComponentType<Listing>;

const ListingTile = ({
  listing,
  event,
  indentLevel,
}: {
  listing: Listing;
  event: Event;
  indentLevel?: number;
}) => {
  const icon = getDeliveryTypeIcon(listing.delivType, listing.topPriTktType);

  const listingGroup = listing as ListingGroup;

  const subListings = useMemo(() => {
    if (!listing.isLtGrp) {
      return [];
    }

    return listingGroup.groupItems as Listing[];
  }, [listing.isLtGrp, listingGroup.groupItems]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { getSelection, setGroupItems } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const { setActivePosEntity, isLoading } =
    useActivePosEntityContext<Listing>();
  const { setModal } = useContext(ModalContext);

  const toggleListingSelection = useCallback(() => {
    const listingIdsToggled = [
      listing,
      ...(listingGroup.groupItems ?? []),
      ...(listingGroup.groupItems ?? []).flatMap(
        (lg) => (lg as ListingGroup).groupItems ?? []
      ),
    ].map((l) => l.id);

    const selectedListingIds = listingSelection.items.itemIds;

    // Current intended behavior: Either select all ids in listingGroup or none
    if (
      selectedListingIds.some((selected) =>
        listingIdsToggled.includes(parseInt(selected))
      )
    ) {
      // Deselect
      setGroupItems(
        event.viagVirtualId,
        selectedListingIds
          .map((id) => parseInt(id))
          .filter((id) => !listingIdsToggled.includes(id))
          .reduce(
            (r, l) => {
              r[l] = true;
              return r;
            },
            {} as Record<number, boolean>
          )
      );
    } else {
      // Add select
      selectedListingIds.push(...listingIdsToggled.map((lId) => String(lId)));
      setGroupItems(
        event.viagVirtualId,
        selectedListingIds
          .map((id) => parseInt(id))
          .reduce(
            (r, listingId) => {
              r[listingId] = true;
              return r;
            },
            {} as Record<number, boolean>
          )
      );
    }
  }, [
    event.viagVirtualId,
    listing,
    listingGroup.groupItems,
    listingSelection.items.itemIds,
    setGroupItems,
  ]);

  const isSelected = useMemo(() => {
    return listingSelection.items.itemIds.includes(String(listing.id));
  }, [listingSelection.items.itemIds, listing.id]);

  const onViewClick = useCallback(async () => {
    if (listingSelection.isSingleGroupMultiSelect) {
      toggleListingSelection();
      return;
    }
    if (isInputFocused) {
      setIsInputFocused(false);
      return;
    }

    if (!listing.isLtGrp) {
      // Only allow click when not a listing group
      setActivePosEntity(listing.id, listing.idOnMkp, true);
      setModal(getListingDetailsModalConfigWithDeepLink(listing.id, true));
    } else {
      setIsExpanded((prev) => !prev);
    }
  }, [
    listingSelection.isSingleGroupMultiSelect,
    isInputFocused,
    listing.isLtGrp,
    listing.id,
    listing.idOnMkp,
    toggleListingSelection,
    setActivePosEntity,
    setModal,
  ]);

  const broadcastActions = getListingActionButtons(
    true /* for table */,
    listing,
    listing.actions?.filter((a) =>
      [
        ListingActionType.Broadcast,
        ListingActionType.Unbroadcast,
        ListingActionType.FullySoldNoAction,
      ].includes(a)
    ) ?? [],
    event,
    'text',
    true,
    undefined,
    isLoading
  );
  const otherActions =
    getListingActionButtons(
      true /* for table */,
      listing,
      listing.actions?.filter((a) =>
        [
          ListingActionType.PredeliverExternalIds,
          ListingActionType.PredeliverTicketUrls,
          ListingActionType.PredeliverBarcodes,
          ListingActionType.PredeliverETickets,
          ListingActionType.Split,
        ].includes(a)
      ) ?? [],
      event,
      'text',
      false,
      undefined,
      isLoading
    ) ?? [];

  const onFocus = useCallback(() => {
    setIsInputFocused(true);
  }, [setIsInputFocused]);

  const sellerFee = listing.mkpListings?.find(
    (m) => m.mkp === Marketplace.StubHub
  )?.sellerFee;

  const TileWrapperDiv = indentLevel ? TileWrapperIndented : TileWrapper;

  return (
    <>
      <TileWrapperDiv
        onClick={onViewClick}
        style={
          indentLevel === 1
            ? { paddingLeft: vars.spacing['lg'] }
            : indentLevel === 2
            ? { paddingLeft: vars.spacing['xxl'] }
            : {}
        }
      >
        <TileHeader>
          <TileLeftLayout>
            <div className={styles.detailsDiv}>
              {listingSelection.isSingleGroupMultiSelect && (
                <CheckboxContainer
                  onClick={(e) => {
                    /* Prevent onViewClick from being called twice and interfering with selection */
                    e.preventDefault();
                  }}
                >
                  <Checkbox
                    checked={isSelected}
                    onChange={() => {
                      /* do nothing - as we already have the row listing to on-click */
                    }}
                  />
                </CheckboxContainer>
              )}
              {!listing.isLtGrp ? (
                icon
              ) : (
                <GroupItemIcon
                  style={{ marginLeft: vars.spacing['sm'] }}
                  stroke={vars.color.textBrand}
                  size={vars.iconSize.m}
                />
              )}
              <SeatInfoContainer>
                <ListingDisplay listing={listing} hideListingNotes />
              </SeatInfoContainer>
            </div>
          </TileLeftLayout>
          <TileRightLayout columns={3}>
            {!listingSelection.isSingleGroupMultiSelect && (
              <>{broadcastActions}</>
            )}
            {!listingSelection.isSingleGroupMultiSelect && (
              <span>
                {otherActions.length > 0 ? (
                  <PosDropdown
                    trigger={
                      <MoreIcon
                        withHoverEffect
                        fill={IconsFill.textBrand}
                        align="middle"
                      />
                    }
                    align="end"
                  >
                    {otherActions.map((a, i) => (
                      <PosDropdownItem key={i}>{a}</PosDropdownItem>
                    ))}
                  </PosDropdown>
                ) : listing.isLtGrp ? (
                  <ListingGroupActions
                    listingGroup={listingGroup}
                    event={event}
                  />
                ) : null}
              </span>
            )}
          </TileRightLayout>
        </TileHeader>
        <Divider />
        <div className={styles.listingDetailRow}>
          <DetailLabel>
            <Content id={ContentId.UnitCost} />
          </DetailLabel>
          <DetailLabel textAlign={TextAlign.Center}>
            {!listing.isLtGrp && <Content id={ContentId.Proceeds} />}
          </DetailLabel>
          <DetailLabel textAlign={TextAlign.Center}>
            {!listing.isLtGrp && <Content id={ContentId.WebsitePrice} />}
          </DetailLabel>
          <DetailUnitCostValue>
            {listing.unitCst?.disp ?? listing.faceValue?.disp ?? (
              <Content id={ContentId.NA}></Content>
            )}
          </DetailUnitCostValue>
          <DetailValue textAlign={TextAlign.Center}>
            {!listing.isLtGrp && (
              <ListingPriceCell
                onFocus={onFocus}
                disabled={
                  listingSelection.isSingleGroupMultiSelect || !listing.availQty
                }
                currencyCode={listing.currency}
                sellerFee={sellerFee}
                listing={listing}
                field="listPrice"
                otherField="allInPrice"
              />
            )}
          </DetailValue>
          <DetailValue
            textAlign={!listing.isLtGrp ? TextAlign.Center : TextAlign.Right}
          >
            {!listing.isLtGrp ? (
              <ListingPriceCell
                onFocus={onFocus}
                disabled={
                  listingSelection.isSingleGroupMultiSelect || !listing.availQty
                }
                currencyCode={listing.currency}
                sellerFee={sellerFee}
                listing={listing}
                field="allInPrice"
                otherField="listPrice"
              />
            ) : (
              <ListingGroupStatusSummary listingGroup={listingGroup} />
            )}
          </DetailValue>
        </div>
      </TileWrapperDiv>
      {isExpanded &&
        subListings.map((subListing) => (
          <ListingTile
            key={subListing.id}
            listing={subListing}
            event={event}
            indentLevel={(indentLevel ?? 0) + 1}
          />
        ))}
    </>
  );
};

export const ListingTiles = ({ entities, event }: ListingTilesProps) => {
  return entities ? (
    <ListingTilesContainer>
      {entities.map((listing) => {
        const tile = (
          <ListingTile key={listing.id} listing={listing} event={event} />
        );
        return (
          <ListingPricingForm
            key={listing.id}
            rowData={{
              event: event,
              listing: listing,
            }}
            tableRow={tile}
          />
        );
      })}
    </ListingTilesContainer>
  ) : (
    <PosSpinner size={vars.iconSize.s} />
  );
};
