import { useMemo } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { MapWithCompListingsPreview } from 'src/components/Listings/MapWithCompListingsPreview';
import { useAutoPricingPreview } from 'src/components/Listings/MapWithCompListingsPreview/MapWithCompListingsPreview.hooks';
import { Stack } from 'src/core/ui';
import { useComparableListingsVenueMapColor } from 'src/hooks/useComparableListingsVenueMapColor';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsPricingUpdates,
} from 'src/WebApiController';

import { useAutoPricingSidePanelContext } from '.';
import * as styles from './AutoPricingSidePanel.css';
import { SectionSelectorPanel } from './SectionSelectionPanel/SectionSelectionPanel';

export const AutoPricingSidePanel = ({
  event,
  listing,
  listingInput,
}: {
  event: Event;
  listing: ListingDetails;
  listingInput?: ListingDetailsAutoPricingSectionUpdates;
}) => {
  const { contentType, onCloseSidePanel, metadata } =
    useAutoPricingSidePanelContext();

  const showComparables =
    contentType === 'comparables' && listing && event?.viagId != null;

  const showSectionSelector = contentType === 'sectionSelection' && listing;

  const sanitizedListingInput = useMemo((): ListingDetailsPricingUpdates => {
    const {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } = listingInput ?? {};

    return {
      netProceedsFloor,
      netProceedsCeiling,
      autoPricingEnabled,
      currencyCode,

      compListingFloor,
      compListingCeiling,
      compListingMode,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
      compListingSelectedSectionSettings,
      compListingQuantityFilters,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } as ListingDetailsPricingUpdates;
  }, [listingInput]);

  const { priceCalcQuery } = useAutoPricingPreview(
    event.viagId,
    listing?.id,
    sanitizedListingInput
  );
  const { compListingsQuery } = useGetCompListings(
    event,
    listing?.id,
    listing?.currency
  );

  const getColor = useComparableListingsVenueMapColor(
    listing ? [listing] : [],
    priceCalcQuery.data?.compListings,
    compListingsQuery.data ?? undefined
  );

  if (!showComparables && !showSectionSelector) return null;

  return (
    <Stack direction="column" className={styles.mainContainerFull}>
      <div className={styles.buttonBar}>
        <ButtonWithIcon
          variant="textPlain"
          shape="none"
          icon={<CrossIcon withHoverEffect />}
          onClick={onCloseSidePanel}
          textContentId={ContentId.Close}
        />
      </div>
      <Stack
        direction="column"
        gap="xl"
        style={{ height: 'calc(100% - 52px)' }}
      >
        {showComparables && (
          <MapWithCompListingsPreview
            listing={listing}
            listingInput={sanitizedListingInput}
            pricingPreview={priceCalcQuery?.data ?? undefined}
            compListings={priceCalcQuery?.data?.compListings ?? []}
            onSelectedSectionsChange={metadata.onSelectedSectionsChange}
            isInModal
            getColor={getColor}
          />
        )}
        {showSectionSelector && (
          <SectionSelectorPanel
            sectionIds={metadata.sectionIds}
            disabledSectionIds={metadata.disabledSectionIds}
            onSelectedSectionsChange={metadata.onSelectedSectionsChange}
          />
        )}
      </Stack>
    </Stack>
  );
};
