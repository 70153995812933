import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import {
  REPORTS_TABLE_COLUMNS_CONFIG,
  ReportWithHandlers,
} from './configs/ReportsTableColumnsConfig';

export type ReportsTableDataProps = {
  reports?: ReportConfig[];
  reportsV2?: ReportConfigV2[];
};

export type ReportsTableProps = ReportsTableDataProps & {
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  isGlobalSection?: boolean;
};

export function ReportsTable({
  reports,
  reportsV2,
  onReportAction,
  onMount,
  onUnmount,
  initState,
  isGlobalSection,
}: ReportsTableProps & Pick<ReportWithHandlers, 'onReportAction'>) {
  const reportText = useContent(ContentId.Reports);
  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: (reports?.length ?? 0) + (reportsV2?.length ?? 0),
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  useEffect(() => {
    if (pagination.pageSize !== reports?.length) {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: (reports?.length ?? 0) + (reportsV2?.length ?? 0),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, reportsV2]);

  const data = useMemo(
    () =>
      (reports ?? [])
        .map(
          (r) =>
            ({
              report: r,
              onReportAction,
            }) as ReportWithHandlers
        )
        .concat(
          (reportsV2 ?? []).map(
            (r) =>
              ({
                reportV2: r,
                onReportAction,
              }) as ReportWithHandlers
          )
        ),
    [reports, reportsV2, onReportAction]
  );

  const options: Partial<TableOptions<ReportWithHandlers | null>> = useMemo(
    () => ({
      data,
      columns: REPORTS_TABLE_COLUMNS_CONFIG,
      state: {
        pagination,
        sorting,
        columnVisibility: {
          ownerDisplayName: !isGlobalSection,
        },
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      enableRowSelection: true,
    }),
    [data, isGlobalSection, pagination, sorting]
  );

  return data.length ? (
    <Table
      options={options}
      tableStyle={{ border: 'none' }}
      tableHeadStyle={{
        border: 'none',
        borderBottom: `1px solid ${vars.color.border}`,
      }}
      tableHeadCellStyle={{
        paddingTop: vars.spacing.m,
        paddingBottom: vars.spacing.m,
        fontSize: vars.typography.fontSize.sm,
        fontWeight: 600,
      }}
      tableCellStyle={{
        border: 'none',
        paddingTop: vars.spacing['lg'],
        paddingBottom: vars.spacing['lg'],
      }}
      tableRowStyle={{
        borderBottom: `1px solid ${vars.color.border}`,
      }}
    />
  ) : (
    <NoData>
      <FormatContent id={FormatContentId.NoDataAvailable} params={reportText} />
    </NoData>
  );
}
