import { Stack } from 'src/core/ui';

import * as styles from './BulkEditErrorList.css';

export function BulkEditErrorList({ errors }: { errors: string[] }) {
  return (
    <Stack className={styles.root} direction="column">
      {errors.toSorted().map((err, i) => (
        <span key={i}>{err}</span>
      ))}
    </Stack>
  );
}
