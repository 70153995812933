import { useCallback } from 'react';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  ExpandedEventData,
} from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import { ListingNotificationContextProvider } from 'src/contexts/ListingNotificationContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { SidePanelProvider } from 'src/contexts/SidePanelContext';
import { useGetActiveAccountClientConfig } from 'src/hooks/useGetActiveAccountClientConfig';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EVENT_DETAILED_METRICS_QUERY_KEY } from 'src/tables/EventsTable/EventsTable.constants';
import {
  DefaultListingQuery,
  EmptyListingQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  ListingCatalogMetricsQueryKey,
  ListingCatalogQueryKey,
} from 'src/utils/inventoryUtils';
import { addListingMetrics } from 'src/utils/ticketMetricUtils';
import {
  ActionOutboxEntityType,
  Feature,
  InventoryViewMode,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingMetrics,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';

import { INVENTORY_USER_SETTINGS } from '../InventoryEvent/constants';
import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  getCatalogSectionalDataExpanded,
  getEventDetailedMetrics,
  listingQueryValueTransformFromUrl,
  listingQueryValueTransformToUrl,
} from './Inventory.utils';
import { InventoryEventsExplorer } from './InventoryEventsExplorer';

export function Inventory() {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const hasSectionalLoadInListingTable = useUserHasFeature(
    Feature.SectionalLoadInListingTable
  );

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: ListingQuery) => {
      if (hasSectionalLoadInListingTable) {
        return getCatalogSectionalDataExpanded(
          ids,
          filterQuery,
          [
            ListingDetailDataField.Basic,
            ListingDetailDataField.Pricing,
            ListingDetailDataField.AutoPricing,
          ],
          {
            activeAccountWebClientConfig,
            onError: (error) => {
              trackError(
                'ListingClient.getListingsSectionalDataForEvents',
                error,
                {
                  ...filterQuery,
                  eventIds: ids,
                }
              );
            },
          }
        );
      }
      return getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('ListingClient.getListingsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      });
    },
    [activeAccountWebClientConfig, hasSectionalLoadInListingTable, trackError]
  );

  const onCatalogDataExpandedCallback = useCallback(
    (
      ids: string[],
      filterQuery: ListingQuery,
      currentData?: ExpandedEventData
    ) =>
      getCatalogSectionalDataExpanded(
        ids,
        filterQuery,
        [ListingDetailDataField.Purchase, ListingDetailDataField.Tags],
        {
          activeAccountWebClientConfig,
          onError: (error) => {
            trackError('ListingClient.getCatalogSectionalDataExpanded', error, {
              ...filterQuery,
              eventIds: ids,
            });
          },
        },
        currentData
      ),
    [activeAccountWebClientConfig, trackError]
  );

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={INVENTORY_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<ListingQuery>
        initialQuery={DefaultListingQuery}
        emptyQuery={EmptyListingQuery}
        viewModeSettingId={UserSetting.InventoryPageViewMode}
        queryValueTransformToUrl={listingQueryValueTransformToUrl}
        queryValueTransformFromUrl={listingQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <CatalogDataContextProvider<ListingQuery>
          entityType={ActionOutboxEntityType.Listing}
          queryKey={ListingCatalogQueryKey}
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          onCatalogDataExpanded={
            hasSectionalLoadInListingTable
              ? onCatalogDataExpandedCallback
              : undefined
          }
          transformEventData={transformData}
        >
          <ListingRouteBody />
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

const ListingRouteBody = () => {
  const { filterQuery } = useFilterQueryContext<ListingQuery>();
  const getActClientCfg = useGetActiveAccountClientConfig(
    'Inventory.ListingClient.getListingByListingId'
  );

  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const getActivePosEntity = useCallback(
    async (
      posEntityId: number,
      curEntity?: ListingDetails | null,
      dataToGet?: string[] | null
    ) => {
      const activeClientCf = await getActClientCfg();

      const listingDetail = await new ListingClient(
        activeClientCf
      ).getListingByListingId(
        posEntityId,
        hasSectionalLoadDataFeature ? curEntity ?? undefined : undefined,
        hasSectionalLoadDataFeature
          ? dataToGet?.map((s) => s as ListingDetailDataField) ?? []
          : undefined
      );

      if (listingDetail) {
        return {
          posEntityId: listingDetail.id,
          posEntity: listingDetail,
          posEntityDisplayId: listingDetail.idOnMkp,
        };
      }

      return {};
    },
    [getActClientCfg, hasSectionalLoadDataFeature]
  );

  const isSidePanelFlyover =
    filterQuery.viewMode === InventoryViewMode.MetricView;

  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);

  return (
    <CatalogMetricsContextProvider<ListingMetrics, ListingQuery>
      queryKey={
        isSidePanelFlyover
          ? EVENT_DETAILED_METRICS_QUERY_KEY
          : ListingCatalogMetricsQueryKey
      }
      entityType={ActionOutboxEntityType.Listing}
      isDetailed={isSidePanelFlyover}
      getCatalogMetrics={(c, f, r) =>
        isSidePanelFlyover
          ? getEventDetailedMetrics(c, f, r, hasMetricsV2Feature)
          : getCatalogMetrics(c, f, r, hasMetricsV2Feature)
      }
      addCatalogMetrics={addListingMetrics}
    >
      <ActivePosEntityProvider<ListingDetails>
        entityType={ActionOutboxEntityType.Listing}
        getActivePosEntity={getActivePosEntity}
      >
        <ListingNotificationContextProvider>
          <SidePanelProvider
            sidePanelId="main-pages"
            panelType={isSidePanelFlyover ? 'flyover' : 'content'}
          >
            <InventoryEventsExplorer />
          </SidePanelProvider>
        </ListingNotificationContextProvider>
      </ActivePosEntityProvider>
    </CatalogMetricsContextProvider>
  );
};
