import {
  InitialTableState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { NoData, Table, TableProps } from 'src/tables/Table';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { SomethingWentWrong } from 'src/views';

import { usePaginationSettings } from '../Table/hooks/usePaginationSettings';
import { AdGroupRow } from './configs/AdGroupsTableColumnConfig';
import { useGetAdGroupsTableColumns } from './useGetAdGroupsTableColumns';

export type AdGroupsTableDataProps = {
  adGroupEntities?: AdGroupRow[] | null;
  failedToRetrieveData: boolean;
  disablePagination?: boolean;
  useVirtuoso?: boolean;
};

type AdGroupsTableProps = AdGroupsTableDataProps & {
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  isFlattenedView?: boolean;
  isItemsLoading?: boolean;
  rowSupportsAccordion?: boolean;
} & Pick<TableProps<AdGroupRow | null>, 'RowComponent' | 'virtuosoItemSize'>;

const PAGE_SIZE = 10;

const getRowId = (originalRow: AdGroupRow | null, index: number) =>
  (originalRow?.id ?? index).toString();

export function AdGroupsTable({
  adGroupEntities,
  failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
  disablePagination,
  isItemsLoading,
  RowComponent,
  rowSupportsAccordion,
  virtuosoItemSize,
}: AdGroupsTableProps) {
  const adGroupsText = useContent(ContentId.AdGroups);

  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || [{ id: 'name', desc: true }]
  );

  const { pagination, setPagination } = usePaginationSettings(
    adGroupEntities?.length ?? 0,
    -1,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const { data, hasNoDataLoad } = useMemo(() => {
    const data = adGroupEntities?.length
      ? adGroupEntities
      : adGroupEntities == null || isItemsLoading
      ? (generateShimmeringRows(0) as null[])
      : adGroupEntities;

    const hasNoDataLoad =
      adGroupEntities == null
        ? true
        : adGroupEntities.length > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS;

    return { data, hasNoDataLoad };
  }, [isItemsLoading, adGroupEntities]);

  const columns = useGetAdGroupsTableColumns();

  const options = useMemo<Partial<TableOptions<AdGroupRow | null>>>(
    () => ({
      data,
      columns: columns,
      state: {
        pagination,
        sorting,
      },
      meta: {
        rowSupportsAccordion,
      },
      enableRowSelection: false,
      enableMultiRowSelection: false,
      enableSubRowSelection: false,
      getRowId: getRowId,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [data, columns, pagination, sorting, rowSupportsAccordion, setPagination]
  );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : hasNoDataLoad ? (
    <NoData>
      <WarningMessage
        message={
          <FormatContent
            id={FormatContentId.ApplyFilterToReduceCountsToSeeData}
            params={[MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS.toString()]}
          />
        }
      />
    </NoData>
  ) : data?.length > 0 ? (
    <Table
      options={options}
      tableLayout="fixed"
      withOuterPadding
      useVirtuoso
      tableHeadStyle={
        disablePagination
          ? {
              position: 'sticky',
              top: '0',
              zIndex: '10',
            }
          : undefined
      }
      RowComponent={RowComponent}
      virtuosoItemSize={virtuosoItemSize}
    />
  ) : isItemsLoading ? (
    <PosSpinner />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={adGroupsText}
      />
    </NoData>
  );
}
