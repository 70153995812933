import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadButton } from 'src/components/Buttons';
import {
  ExternalIdForm,
  UploadExternalIdsBody,
  UploadExternalIdsForm,
} from 'src/components/UploadArtifacts/UploadExternalIds';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import {
  PosClientConfig,
  PosUiActionResult,
  SaleMetrics,
  TicketExternalId,
} from 'src/WebApiController';

import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';

export type UploadExternalIdProps = {
  onUploadExternalIds?: (
    posClientConfig: PosClientConfig,
    entityId: number,
    externalIds: TicketExternalId[]
  ) => Promise<PosUiActionResult>;
  cancelTo?: ModalProps;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
};

export const UploadExternalIds = ({
  onUploadExternalIds,
  loadingContentId,
  searchingContentId,
  errorContentId,
  cancelTo,
}: UploadExternalIdProps) => {
  const { loadingState } = useEventItemLoadingDisplay<EntityWithRealTickets>(
    loadingContentId,
    searchingContentId,
    errorContentId
  );

  return (
    loadingState || (
      <UploadExternalIdsContent
        cancelTo={cancelTo}
        onUploadExternalIds={onUploadExternalIds}
      />
    )
  );
};

export type UploadExternalIdsContentProps = Omit<
  UploadExternalIdProps,
  'loadingContentId' | 'searchingContentId' | 'errorContentId'
>;

export const UploadExternalIdsContent = ({
  onUploadExternalIds,
  cancelTo,
  isReadOnly = false,
}: UploadExternalIdsContentProps) => {
  const { closeModal, setModal } = useContext(ModalContext);

  const { posEntity: posEntity, setActivePosEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();
  const { refreshMetrics } = useCatalogMetricsContext<SaleMetrics>();

  const goBack = useCallback(
    async (isCancelled: boolean) => {
      if (!isCancelled) {
        refreshMetrics?.();

        // Refresh the active data so the SaleDetail dialog will have the new content
        await setActivePosEntity(posEntity!.id, posEntity!.idOnMkp, true);
      }

      if (cancelTo) {
        setModal(cancelTo);
      } else {
        closeModal(true);
      }
    },
    [
      cancelTo,
      refreshMetrics,
      setActivePosEntity,
      posEntity,
      setModal,
      closeModal,
    ]
  );

  return (
    <UploadExternalIdsForm
      entityWithTickets={posEntity!}
      onComplete={goBack}
      onUpload={onUploadExternalIds}
      renderContent={(onUploadClick: () => void, disabled?: boolean) => (
        <UploadExternalIdsFormContent
          cancelTo={cancelTo}
          onUploadClick={onUploadClick}
          disabled={disabled || isReadOnly}
          goBack={goBack}
        />
      )}
    />
  );
};

const UploadExternalIdsFormContent = ({
  cancelTo,
  onUploadClick,
  disabled,
  goBack,
}: {
  cancelTo?: ModalProps;
  onUploadClick: () => void;
  disabled?: boolean;
  goBack: (isCancelled: boolean) => void;
}) => {
  const { formState } = useFormContext<ExternalIdForm>();
  const { isDirty, isSubmitting } = formState;

  const { event, posEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  return (
    <>
      <CancellableFormHeader
        disabled={isSubmitting}
        cancelTo={cancelTo}
        showDialogOnCancel={isDirty}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.SaveTicketIds} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={posEntity!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          {isSubmitting || !posEntity ? (
            <PosSpinner />
          ) : (
            <div className={modalDetails}>
              <UploadExternalIdsBody
                isReadOnly={disabled}
                ticketCnt={posEntity.ticketCnt}
                seating={posEntity.seating}
              />
            </div>
          )}
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        {/* We want to use isReadOnly here because isReadOnly is meant for this dialog is informational */}
        {!disabled && (
          <CancellableFormFooter
            disabled={disabled || isSubmitting}
            cancelTo={cancelTo}
            showDialogOnCancel={isDirty}
          >
            <UploadButton
              disabled={disabled || isSubmitting}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onUploadClick();
              }}
              textContentId={ContentId.SaveTicketIds}
            />
          </CancellableFormFooter>
        )}
        {disabled && (
          <Button onClick={() => goBack(true)} disabled={isSubmitting}>
            <Content id={ContentId.Back} />
          </Button>
        )}
      </ModalFooter>
    </>
  );
};
