import { PropsWithChildren, useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProvider } from 'src/contexts/ReportMetricsContext';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { isMetricConfigurableByGroupBy } from 'src/utils/columns/inventory/inventoryColumnUtils';
import {
  DefaultListingQuery,
  EmptyListingQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  isReportFilterOverriden,
  listingMetricsToReportMetricRequest,
} from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  ListingQuery,
  ListingReportMetricColumn,
  ListingReportMetrics,
  ReportClient,
  ReportGroupBy,
  UserSetting,
} from 'src/WebApiController';

import { getCatalogData } from '../Inventory';

export function ReportsInventoryContextProvider({
  reportConfig,
  children,
  applyMaxRowLimitByDefault = true,
}: PropsWithChildren<{
  reportConfig: ReportConfig;
  applyMaxRowLimitByDefault?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const reportMetricsRequested = useMemo(() => {
    return reportConfig.metrics
      .filter((m) => isMetricConfigurableByGroupBy(m, reportConfig.groupBy))
      .map((metric) => listingMetricsToReportMetricRequest(metric))
      .filter((m) => !!m) as string[];
  }, [reportConfig.metrics, reportConfig.groupBy]);

  const getReportMetrics = useCallback(
    (
      filterQuery: ListingQuery,
      groupBy: ReportGroupBy,
      reportMetricsRequested?: string[],
      maxResultSize?: number
    ) => {
      return new ReportClient(
        activeAccountWebClientConfig
      ).getListingReportMetrics(
        {
          query: filterQuery,
          groupBy,
          globalReportTypeId: reportConfig.globalReportTypeId,
          isUsingFilterOverride:
            reportConfig.hasFilterOverride ||
            isReportFilterOverriden(filterQuery, reportConfig.filter),
          listingReportMetricsRequested:
            reportMetricsRequested as ListingReportMetricColumn[],
        },
        maxResultSize
      );
    },
    [
      activeAccountWebClientConfig,
      reportConfig.filter,
      reportConfig.globalReportTypeId,
      reportConfig.hasFilterOverride,
    ]
  );

  return (
    <FilterQueryContextProvider<ListingQuery>
      initialQuery={
        (reportConfig.filter as ListingQuery) ?? DefaultListingQuery
      }
      emptyQuery={EmptyListingQuery}
      viewModeSettingId={UserSetting.InventoryPageViewMode}
    >
      <CatalogDataContextProvider<ListingQuery>
        entityType={ActionOutboxEntityType.Listing}
        queryKey="getCatalogForListing"
        getCatalogData={(c, f) => getCatalogData(c, f, false)}
        transformEventData={transformData}
      >
        <ReportMetricsContextProvider<ListingReportMetrics, ListingQuery>
          queryKey="getReportListingMetrics"
          getReportMetrics={getReportMetrics}
          groupBy={reportConfig.groupBy}
          reportMetricsRequested={reportMetricsRequested}
          applyMaxRowLimitByDefault={applyMaxRowLimitByDefault}
        >
          {children}
        </ReportMetricsContextProvider>
      </CatalogDataContextProvider>
    </FilterQueryContextProvider>
  );
}
