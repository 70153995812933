import { useLocation, useParams } from 'react-router-dom';
import { ReportsInventoryPageV2 } from 'src/components/ReportsInventoryPage/ReportsInventoryPageV2';
import { Content } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useReportConfigsV2 } from 'src/hooks/useReportConfigsV2';
import { useReportPermissions } from 'src/hooks/useReportPermissions';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';

import { MainRoute } from '../MainRoute';
import { ReportsInventoryContextProviderV2 } from './ReportsInventoryContextProviderV2';

export function ReportsInventoryV2() {
  const { hasAccessToReportingV2, hasReportSinglePagedV1V2Feature } =
    useReportPermissions();

  const location = useLocation();

  const isFromV1Page = location.state?.isFromV1Page === true;

  const mainRoute =
    !hasAccessToReportingV2 || isFromV1Page || hasReportSinglePagedV1V2Feature
      ? MainRoute.Reports
      : MainRoute.ReportsV2;

  return (
    <LayoutContent mainRoute={mainRoute}>
      <ReportsInventoryContentV2 />
    </LayoutContent>
  );
}

function ReportsInventoryContentV2() {
  const { reportId } = useParams();

  const { reportConfigs, isLoading, isFetching } =
    useReportConfigsV2<ReportTypesV2.InventoryV2>({
      reportType: ReportTypesV2.InventoryV2,
    });

  const reportConfig = reportConfigs?.find(
    (r) => r.reportId?.toString() === reportId
  );

  if (reportConfig == null) {
    return isLoading || isFetching ? (
      <PosSpinner />
    ) : (
      <Stack
        width="full"
        direction="column"
        alignItems="center"
        style={{ padding: vars.spacing.xxl }}
      >
        <WarningMessage
          message={<Content id={ContentId.ReportNotAvailable} />}
        />
      </Stack>
    );
  }

  return (
    <ReportsInventoryContextProviderV2 reportConfig={reportConfig}>
      <DialogProvider>
        <ReportsInventoryPageV2 reportConfig={reportConfig} />
      </DialogProvider>
    </ReportsInventoryContextProviderV2>
  );
}
