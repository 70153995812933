import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { translateEventToSaleAccordionItem } from 'src/components/Accordions';
import { useAccordionItem } from 'src/components/Events/EventPage/hooks/useAccordionItem';
import { SidePanelToolbar } from 'src/components/Events/EventPage/SidePanel/SidePanelToolbar';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { BulkEditHubContextProvider } from 'src/contexts/BulkEditHubContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  getCatalogData,
  getCatalogDataExpanded,
  SALE_USER_SETTINGS,
} from 'src/navigations/Routes/Sales';
import { NoData } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { DefaultSaleQuery, EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  Feature,
  SaleClient,
  SaleDetails,
  SaleQuery,
  SectionInfo,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';
import { SaleSideTableFilterBar } from '../SaleFilterBar/SaleSideTableFilterBar';

const Sales = ({
  viagVirtualId,
  selectedSections,
  queryInput,
}: {
  viagVirtualId: string;
  selectedSections?: SectionInfo[];
  queryInput?: Partial<Pick<SaleQuery, 'purchaseOrderIds' | 'listingIds'>>;
}) => {
  const { isLoading, isItemsLoading, accordionItemProps } =
    useAccordionItem(viagVirtualId);

  const { setFilterQuery, isQueryInitialized } =
    useFilterQueryContext<SaleQuery>();

  const hasCondensedTabsFeature = useUserHasFeature(
    Feature.CondensedPreviewTabsAndSingleListing
  );

  useEffect(() => {
    setFilterQuery({
      ...DefaultSaleQuery,
      ...queryInput,
      eventOrMappingIds: [viagVirtualId],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInput, setFilterQuery, isQueryInitialized]);

  const hasSelectedEntities =
    (queryInput?.listingIds && queryInput?.listingIds?.length > 0) ||
    (queryInput?.purchaseOrderIds && queryInput?.purchaseOrderIds?.length > 0);

  const { entities } = accordionItemProps || {};
  const sales = entities?.sales;

  const filteredEventSales = useMemo(() => {
    if (!selectedSections || isEmpty(selectedSections)) {
      return sales ?? null;
    }
    const sectionIds = new Set(selectedSections.map(({ id }) => id));
    const sectionNames = new Set(selectedSections.map(({ name }) => name));
    const rowIds = new Set<number>(
      selectedSections.flatMap(({ rows }) => rows.map(({ id }) => id))
    );

    return (
      sales?.filter(({ seating }) => {
        return (
          (seating?.sectionId && sectionIds.has(seating?.sectionId)) ||
          (seating?.section && sectionNames.has(seating?.section)) ||
          (seating?.rowId && rowIds.has(seating.rowId))
        );
      }) ?? null
    );
  }, [sales, selectedSections]);

  const salesAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToSaleAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  const saleIds = filteredEventSales?.map(({ id }) => id) ?? [];

  const { BodyComponent, AfterHeaderComponent } = salesAccordionItem || {};

  const noData =
    (!isLoading && !salesAccordionItem) || (!isItemsLoading && !sales);
  return (
    <>
      <div className={styles.sidePanelToolbar}>
        <SidePanelToolbar
          sectionType={SectionType.SalesSideTable}
          filterBar={<SaleSideTableFilterBar />}
          entityIds={hasSelectedEntities ? saleIds : undefined}
          noData={noData}
        />
        {!noData && AfterHeaderComponent && <AfterHeaderComponent />}
      </div>
      {isLoading && <PosSpinner />}
      {noData ? (
        <NoData>
          <FormatContent
            id={FormatContentId.NoDataAvailable}
            params={<Content id={ContentId.Sales} />}
          />
        </NoData>
      ) : (
        BodyComponent &&
        accordionItemProps && (
          <BodyComponent
            event={accordionItemProps.event}
            entities={filteredEventSales ?? []}
            entityCount={accordionItemProps.counts.salesCnt ?? 0}
            useTableNavKeys={true}
            useVirtuoso
            withOuterPadding={false}
            disablePagination={true}
            isSideTable={true}
          />
        )
      )}
    </>
  );
};
export const SalesSideTable = ({
  viagVirtualId,
  selectedSections,
  queryInput,
}: {
  viagVirtualId: string;
  selectedSections?: SectionInfo[];
  queryInput?: Partial<Pick<SaleQuery, 'purchaseOrderIds' | 'listingIds'>>;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: SaleQuery) =>
      getCatalogDataExpanded(ids, filterQuery, false, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('SaleClient.getSalesForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const getActivePosEntity = useCallback(
    async (saleId: number) => {
      if (activeAccountWebClientConfig.activeAccountId) {
        const saleDetail = await new SaleClient(
          activeAccountWebClientConfig
        ).getSaleBySaleId(saleId);

        if (saleDetail) {
          return {
            posEntityId: saleDetail.id,
            posEntity: saleDetail,
            posEntityDisplayId: saleDetail.idOnMkp,
          };
        }
      }
      return {};
    },
    [activeAccountWebClientConfig]
  );

  return (
    <BulkEditHubContextProvider>
      <SellerUserSettingsProvider
        initialUserSettingIds={SALE_USER_SETTINGS}
        currentLoginUserOnly={true}
      >
        <FilterQueryContextProvider<SaleQuery>
          initialQuery={{
            ...DefaultSaleQuery,
            eventOrMappingIds: [viagVirtualId],
          }}
          emptyQuery={EmptySaleQuery}
          viewModeSettingId={UserSetting.SalePageViewMode}
          loadQueryFromUrl={false}
        >
          <CatalogDataContextProvider<SaleQuery>
            entityType={ActionOutboxEntityType.Sale}
            queryKey={'getCatalogForSale'}
            getCatalogData={(c, f) => getCatalogData(c, f, true)}
            getCatalogDataExpanded={getCatalogDataExpandedCallback}
            transformEventData={transformData}
          >
            <CatalogMultiSelectionContextProvider type={'sale'}>
              <MultiSelectionContextProvider>
                <ActivePosEntityProvider<SaleDetails>
                  entityType={ActionOutboxEntityType.Sale}
                  getActivePosEntity={getActivePosEntity}
                >
                  <DialogProvider>
                    <CollapsableViewProvider isEnabled={true}>
                      <Sales
                        viagVirtualId={viagVirtualId}
                        selectedSections={selectedSections}
                        queryInput={queryInput}
                      />
                    </CollapsableViewProvider>
                  </DialogProvider>
                </ActivePosEntityProvider>
              </MultiSelectionContextProvider>
            </CatalogMultiSelectionContextProvider>
          </CatalogDataContextProvider>
        </FilterQueryContextProvider>
      </SellerUserSettingsProvider>
    </BulkEditHubContextProvider>
  );
};
