import { useCallback, useEffect, useState } from 'react';
import { UseFieldArrayReturn, useForm, useFormContext } from 'react-hook-form';
import { useDeliveryOverrideForNewTicketGroup } from 'src/hooks/api/useDeliveryOverrideForNewTicketGroup';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { SpreadsheetTable } from 'src/tables/SpreadsheetTable/SpreadsheetTable';
import { ContentId } from 'src/utils/constants/contentId';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { ListingNote } from 'src/WebApiController';

import { PurchaseOrderInfoForTicketGroupInput } from '../../PurchaseTicketsFlow/TicketGroupInput.types';
import { useTicketGroupInputTableColumns } from './hooks/useTicketGroupInputTableColumns';
import { SeatRangeInputModal } from './SeatRangeInputModal';
import * as styles from './TicketGroupInputTable.css';
import { TicketGroupInputTableFooter } from './TicketGroupInputTableFooter';
import { TicketGroupInputTableHeader } from './TicketGroupInputTableHeader';

export const TicketGroupInputTable = ({
  purchaseTicketGroupInputField,
  valueOptionsContent,
  listingNotesMap,
  purchaseOrderInfo,
}: {
  purchaseTicketGroupInputField: UseFieldArrayReturn<
    PurchaseTicketsInput,
    'ticketGroups',
    'id'
  >;
  valueOptionsContent: Record<string, ContentId | string>;
  listingNotesMap?: Record<string, ListingNote> | null;
  purchaseOrderInfo: PurchaseOrderInfoForTicketGroupInput | null | undefined;
}) => {
  const [isTotalCostChecked, setIsTotalCostChecked] = useState(false);
  const [selectedSeatRangeRowIndex, setSelectedSeatRangeRowIndex] = useState<
    number | null
  >(null);

  const { fields, append } = purchaseTicketGroupInputField;
  const { setValue, getValues, getFieldState, formState } =
    useFormContext<PurchaseTicketsInput>();
  const seatRangeDialog = useBasicDialog();

  const launchSeatRangeDialog = useCallback((rowIndex: number) => {
    setSelectedSeatRangeRowIndex(rowIndex);
    seatRangeDialog.launchDialog();
  }, []);

  const ticketGroupColumnConfigs = useTicketGroupInputTableColumns({
    isTotalCostChecked,
    purchaseTicketGroupInputField,
    valueOptionsContent,
    listingNotesMap,
    launchSeatRangeDialog: launchSeatRangeDialog,
  });

  const options = {
    data: fields,
    columns: ticketGroupColumnConfigs,
  };

  const {
    isLoading: deliveryOverrideDefaultIsLoading,
    hasDefaultValues: hasDeliveryOverrideValues,
    deliveryTypeDefault,
    ticketTypeRulesDefault,
    inHandAtDefault,
  } = useDeliveryOverrideForNewTicketGroup(
    fields[0].event?.viagId,
    purchaseOrderInfo?.vendor?.id,
    purchaseOrderInfo?.vendAcc?.id,
    true, // isManual
    fields[0].ticketGroupId > 0 /* disabled if not new ticket group */
  );

  // Used for setting value when the default value is loaded
  useEffect(() => {
    if (!deliveryOverrideDefaultIsLoading) {
      fields.forEach((_, number) => {
        const inHandAt = getValues(`ticketGroups.${number}.inHandDate.value`);
        const delivType = getValues(
          `ticketGroups.${number}.deliveryType.value`
        );
        const desiredDelivType = deliveryTypeDefault ?? delivType;
        const ticketTypeOverrideField = getValues(
          `ticketGroups.${number}.ticketTypeRules`
        );
        if (deliveryTypeDefault && delivType !== deliveryTypeDefault) {
          setValue(
            `ticketGroups.${number}.deliveryType`,
            posChangedField(deliveryTypeDefault)
          );
        }

        if (inHandAtDefault && inHandAt !== inHandAtDefault) {
          setValue(
            `ticketGroups.${number}.inHandDate`,
            posChangedField(inHandAtDefault)
          );
        }

        if (ticketTypeRulesDefault) {
          setValue(`ticketGroups.${number}.ticketTypeRules`, {
            overrides: ticketTypeRulesDefault.overrides.filter(
              (o) => o.deliveryType === desiredDelivType
            ),
          });
        }
      });
    }
  }, [
    fields,
    deliveryOverrideDefaultIsLoading,
    deliveryTypeDefault,
    getValues,
    inHandAtDefault,
    setValue,
    ticketTypeRulesDefault,
  ]);

  useEffect(() => {
    const daysBeforeEvent = getValues('daysBeforeEvent');
    fields.forEach((tg, number) => {
      const inHandAt = getValues(`ticketGroups.${number}.inHandDate.value`);

      if (!inHandAt && tg.event?.dates?.start) {
        const inHandDate = getInHandDateBasedOnEvent(tg.event, daysBeforeEvent);
        setValue(
          `ticketGroups.${number}.inHandDate`,
          posChangedField(inHandDate)
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <div className={styles.tableBase}>
      <TicketGroupInputTableHeader
        isTotalCostChecked={isTotalCostChecked}
        setIsTotalCostChecked={setIsTotalCostChecked}
      />
      <SpreadsheetTable
        options={options}
        nonInteractiveColumns={['actions']}
        rowHeight={40}
        fieldName="ticketGroups"
        getFieldState={getFieldState}
        formState={formState}
      />
      {seatRangeDialog.dialogProps.isOpen && (
        <SeatRangeInputModal
          {...seatRangeDialog.dialogProps}
          rowIndex={selectedSeatRangeRowIndex}
          onClosed={seatRangeDialog.closeDialog}
        />
      )}
      <TicketGroupInputTableFooter append={append} />
    </div>
  );
};
