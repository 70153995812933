import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { ReportExportOptionsV2 } from 'src/components/Reports/ReportsExport/ReportsExport.types';
import { ReportsInventoryExportV2 } from 'src/components/Reports/ReportsExport/ReportsInventoryExportV2';
import { ReportsSaleExportV2 } from 'src/components/Reports/ReportsExport/ReportsSaleExportV2';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { DropdownMenu, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CopyIcon } from 'src/svgs';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import {
  DeleteIcon,
  EditIcon,
  GenericDisclosuresIcon,
  IconsFill,
  MoreIcon,
  ProcessingIcon,
  ShareIcon,
} from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { Feature, Permission } from 'src/WebApiController';

export type ReportWithHandlersV2 = {
  report: ReportConfigV2;
  onReportAction?: (
    report: ReportConfigV2,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
};

export const REPORTS_TABLE_COLUMNS_CONFIG_V2: ColumnDef<ReportWithHandlersV2 | null>[] =
  [
    {
      id: 'reportName',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Name} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 200,
      accessorFn: (data) => data?.report?.reportName,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv
            style={{
              textTransform: 'unset',
              gap: vars.spacing['sm'],
              color: vars.color.textStrong,
              fontWeight: 500,
            }}
            align="left"
          >
            <GenericDisclosuresIcon fill={vars.color.textStrong} />
            <span>{value}</span>
          </TableCellDiv>
        );
      },
    },
    {
      id: 'ownerDisplayName',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Owner} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.report?.ownerDisplayName,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string | null | undefined;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv
            align="left"
            style={{ color: vars.color.textStrong, fontWeight: 500 }}
          >
            <>
              {original.report.globalReportTypeId ? (
                <Content id={ContentId.Default} />
              ) : (
                <>{value}</>
              )}
            </>
          </TableCellDiv>
        );
      },
    },
    {
      id: 'actions',
      enableSorting: false,
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 50,
      cell: function Cell({ row: { original } }) {
        const canCreateReport = useUserHasAnyOfPermissions(
          Permission.Reports_CreateReports
        );
        const canUpdateReport = useUserHasAnyOfPermissions(
          Permission.Reports_UpdateReports
        );
        const canUpdateOwnReports = useUserHasAnyOfPermissions(
          Permission.Reports_UpdateReportsOwned
        );
        const canViewHistory = useUserHasFeature(Feature.ReportSnapshot);
        const hasExportInventoryFeature = useUserHasFeature(
          Feature.ExportInventory
        );

        const [reportExportOptions, setReportExportOptions] =
          useState<ReportExportOptionsV2 | null>(null);

        const onReportExported = () => {
          setReportExportOptions(null);
        };

        if (!original) {
          return <TableShimmeringDiv />;
        }
        const canUpdate =
          original &&
          (canUpdateReport || (original.report.isOwner && canUpdateOwnReports));

        const actions = [
          original.report.isOwner && (
            <>
              <DropdownMenu.Item
                key="ShareReport"
                onClick={() =>
                  original.onReportAction &&
                  original.onReportAction(original.report, GridActionType.Share)
                }
              >
                <Stack alignItems="center" gap="m" width="full">
                  <ShareIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    size={vars.iconSize.m}
                  />
                  <Content id={ContentId.Share} />
                </Stack>
              </DropdownMenu.Item>
              {canUpdate && (
                <DropdownMenu.Item
                  key="EditReport"
                  onClick={() =>
                    original.onReportAction &&
                    original.onReportAction(
                      original.report,
                      GridActionType.Edit
                    )
                  }
                >
                  <Stack alignItems="center" gap="m" width="full">
                    <EditIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      size={vars.iconSize.m}
                    />
                    <Content id={ContentId.Edit} />
                  </Stack>
                </DropdownMenu.Item>
              )}
            </>
          ),
          // original &&
          //   (hasExportInventoryFeature ||
          //     original.report.request.rowGroupings?.some(
          //       (g) => g.columnName === ListingReportTableColumnId.ListingId
          //     )) && (
          //     <DropdownMenu.Group>
          //       <DropdownMenu.Label key="DownloadReport">
          //         <Stack alignItems="center" gap="m" width="full">
          //           <DownloadIcon
          //             withHoverEffect
          //             fill={IconsFill.textBrand}
          //             size={vars.iconSize.m}
          //           />
          //           <Content id={ContentId.Download} />
          //         </Stack>
          //       </DropdownMenu.Label>
          //       <DropdownMenu.Item
          //         key="DownloadCsv"
          //         onClick={() =>
          //           setReportExportOptions({
          //             reportToExport: original.report,
          //             fileType: ReportFileType.CSV,
          //           })
          //         }
          //       >
          //         <Content id={ContentId.CommaSeparatedValuesExt} />
          //       </DropdownMenu.Item>
          //       <DropdownMenu.Item
          //         key="DownloadXLSX"
          //         onClick={() =>
          //           setReportExportOptions({
          //             reportToExport: original.report,
          //             fileType: ReportFileType.XLSX,
          //           })
          //         }
          //       >
          //         <Content id={ContentId.MicrosoftExcelExt} />
          //       </DropdownMenu.Item>
          //       <DropdownMenu.Separator />
          //     </DropdownMenu.Group>
          //   ),
          canCreateReport && (
            <DropdownMenu.Item
              key="Duplicate"
              onClick={() =>
                original.onReportAction &&
                original.onReportAction(
                  original.report,
                  GridActionType.Duplicate
                )
              }
            >
              <Stack alignItems="center" gap="m" width="full">
                <CopyIcon
                  withHoverEffect
                  fill={vars.color.textBrand}
                  size={vars.iconSize.s}
                />
                <Content id={ContentId.Duplicate} />
              </Stack>
            </DropdownMenu.Item>
          ),
          original.report.isOwner && canUpdate && (
            <DropdownMenu.Item
              key="DeleteReport"
              onClick={() =>
                original.onReportAction &&
                original.onReportAction(original.report, GridActionType.Delete)
              }
            >
              <Stack alignItems="center" gap="m" width="full">
                <DeleteIcon
                  withHoverEffect
                  fill={IconsFill.textBrand}
                  size={vars.iconSize.m}
                />
                <Content id={ContentId.Remove} />
              </Stack>
            </DropdownMenu.Item>
          ),
          // canViewHistory && (
          //   <DropdownMenu.Item
          //     key="History"
          //     onClick={() =>
          //       original.onReportAction &&
          //       original.onReportAction(original.report, GridActionType.History)
          //     }
          //   >
          //     <Stack alignItems="center" gap="m" width="full">
          //       <HistoryIcon
          //         withHoverEffect
          //         fill={IconsFill.textBrand}
          //         size={vars.iconSize.m}
          //       />
          //       <Content id={ContentId.History} />
          //     </Stack>
          //   </DropdownMenu.Item>
          // ),
        ];

        const visibleActions = actions.filter((a) => !!a);

        return (
          <>
            <TableCellDiv
              align="right"
              style={{ gap: '16px' }}
              showTooltip={false}
            >
              {reportExportOptions && (
                <RotatingWrapper>
                  <ProcessingIcon
                    size={vars.iconSize.l}
                    fill={IconsFill.textBrand}
                  />
                </RotatingWrapper>
              )}
              <ButtonWithIcon
                variant="textPlain"
                shape="none"
                disabled={original.report.reportId == null}
                icon={<OpenLinkIcon withHoverEffect size={vars.iconSize.m} />}
                onClick={(event: React.MouseEvent) =>
                  original.onReportAction &&
                  original.onReportAction(
                    original.report,
                    GridActionType.Select,
                    event
                  )
                }
                textContentId={ContentId.OpenReport}
                style={{
                  fontSize: vars.typography.fontSize.xs,
                }}
                startWithText
              />
              {visibleActions.length > 0 &&
                original.report?.reportType !== ReportTypesV2.Commission && (
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger
                      asChild
                      disabled={original.report.reportId == null}
                    >
                      <div style={{ padding: '0 8px 0 4px' }}>
                        <MoreIcon
                          withHoverEffect
                          fill={IconsFill.textBrand}
                          align="middle"
                        />
                      </div>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                      <DropdownMenu.Content align="end">
                        {...visibleActions}
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                )}
            </TableCellDiv>
            {original.report.reportType === ReportTypesV2.InventoryV2 ? (
              <ReportsInventoryExportV2
                reportExportOptions={reportExportOptions}
                onReportExported={onReportExported}
              />
            ) : (
              <ReportsSaleExportV2
                reportExportOptions={reportExportOptions}
                onReportExported={onReportExported}
              />
            )}
          </>
        );
      },
    },
  ];
