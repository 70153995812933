import clsx from 'clsx';
import React, { memo } from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  useWatch,
} from 'react-hook-form';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosTextFieldProps } from 'src/core/POS/PosTextField';
import * as styles from 'src/tables/SpreadsheetTable/SpreadsheetTable.css';
import { posChangedField } from 'src/utils/posFieldUtils';
import { UiCurrency } from 'src/WebApiController';

type CurrencyCellProps<TFormValues extends FieldValues> = PosTextFieldProps & {
  control: Control<TFormValues>;
  fieldName: FieldPath<TFormValues>;
  uiCurrency: UiCurrency;
  style?: React.CSSProperties;
};

const CurrencyCellComponent = function CurrencyCell<
  T,
  TFormValues extends FieldValues,
>({
  control,
  fieldName,
  uiCurrency,
  onChange,
  style,
}: CurrencyCellProps<TFormValues>) {
  const currencyField = useWatch({
    control,
    name: fieldName as FieldPath<TFormValues>,
  });

  const value = currencyField?.value as number;
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <PosCurrencyField
            uiCurrency={uiCurrency}
            value={value}
            min={0}
            onChange={(e) => {
              field.onChange(posChangedField(parseFloat(e.target.value)));
              onChange?.(e);
            }}
            rootProps={{
              className: clsx(
                styles.tableCell[value != null ? 'active' : 'disabled']
              ),
              style: style,
            }}
            style={{
              textAlign: 'right',
            }}
          />
        );
      }}
    />
  );
};

export const CurrencyCell = memo(CurrencyCellComponent) as <
  T extends FieldValues,
>(
  props: CurrencyCellProps<T>
) => JSX.Element;
