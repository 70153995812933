import * as Sentry from '@sentry/react';
import { ColumnDef } from '@tanstack/react-table';
import { useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { EventDateDisplay } from 'src/components/Events/EventInfo';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_L,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_DEFAULT_SIZE_XXL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOption } from 'src/modals/EditTableColumns';
import { FullPageIcon } from 'src/svgs';
import { EventDisplayCell } from 'src/tables/EventsTable/configs/EventDisplayCell';
import { LocationDisplayCell } from 'src/tables/EventsTable/configs/LocationDisplayCell';
import { VenueDisplayCell } from 'src/tables/EventsTable/configs/VenueDisplayCell';
import { TableShimmeringDiv } from 'src/tables/Table';
import {
  DemandDashboardEventIdQueryParam,
  DemandDashboardIsOnSaleEventQueryParam,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  compareEventsByDate,
  compareEventsByDateStr,
  compareEventsByLocation,
  compareEventsByName,
  compareEventsByVenue,
  getFormattedEventName,
} from 'src/utils/eventWithDataUtils';
import {
  getGroupingLabelForString,
  getGroupLabelForDates,
  getGroupLabelForNumber,
} from 'src/utils/groupingUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { sortMoney } from 'src/utils/tableUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { DateTimeRange, Event, Feature, UiMoney } from 'src/WebApiController';

import {
  OnSaleEventsTableColumnId,
  OnSaleEventWithMetrics,
} from './OnSaleEventView.types';

const QUANTITY_COLUMN_MIN_SIZE = 50;
const QUANTITY_COLUMN_MAX_SIZE = 80;
const COST_COLUMN_MIN_SIZE = 50;
const COST_COLUMN_MAX_SIZE = 80;

export const ON_SALE_EVENTS_TABLE_COLUMNS_CONFIG: Partial<
  Record<OnSaleEventsTableColumnId, ColumnDef<OnSaleEventWithMetrics | null>>
> = {
  [OnSaleEventsTableColumnId.Checkbox]: {
    id: OnSaleEventsTableColumnId.Checkbox,
    header: '',
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_SM,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    enableSorting: false,
    enableResizing: false,
    cell: function Cell({ row: { original, getIsSelected } }) {
      const { setShiftKeyGroupSelectionState } = useMultiSelectionContext();
      const isSelected = getIsSelected();

      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }

      return (
        <TableCellDiv
          align="left"
          showTooltip={false}
          style={{ paddingLeft: vars.spacing.sm }}
        >
          <Checkbox
            checked={isSelected}
            onChange={() => {
              setShiftKeyGroupSelectionState(original.event.viagVirtualId);
            }}
          />
        </TableCellDiv>
      );
    },
  },
  [OnSaleEventsTableColumnId.Event]: {
    id: OnSaleEventsTableColumnId.Event,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XXL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const { metrics, ...rest } = getValue<OnSaleEventWithMetrics>();
      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }

      return <EventDisplayCell {...rest} forceDisplayEventName />;
    },
    sortingFn: compareEventsByName,
    getGroupingValue: (row) => {
      const eventName = getFormattedEventName({
        event: row?.event,
        performer: row?.performer,
        venue: row?.venue,
        useShortName: true,
      }).join(' ');
      return getGroupingLabelForString(eventName);
    },
  },

  [OnSaleEventsTableColumnId.Venue]: {
    id: OnSaleEventsTableColumnId.Venue,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.Venue} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const { metrics, ...rest } = getValue<OnSaleEventWithMetrics>();
      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }
      return <VenueDisplayCell {...rest} />;
    },
    sortingFn: compareEventsByVenue,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupingLabelForString(item?.venue?.name ?? ''),
  },
  [OnSaleEventsTableColumnId.Location]: {
    id: OnSaleEventsTableColumnId.Location,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const { metrics, ...rest } = getValue<OnSaleEventWithMetrics>();
      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }
      return <LocationDisplayCell {...rest} />;
    },
    sortingFn: compareEventsByLocation,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupingLabelForString(item?.venue?.descr ?? ''),
  },
  [OnSaleEventsTableColumnId.OnSaleDate]: {
    id: OnSaleEventsTableColumnId.OnSaleDate,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_L,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event?.rawOnSaleDate,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<string | undefined>();
      const { userDefinedPrecision = FormatOption.DateTime_Month_DD_YY_Time } =
        useColumnUserSetting(columnId, SectionType.OnSaleEvents);

      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value,
        isDateTime: true,
      }) as string;

      return (
        <TableCellDiv align="left">
          {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
            <EventDateDisplay
              eventDateString={value ?? undefined}
              eventEndDateString={undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
            />
          ) : (
            <div style={{ textTransform: 'none' }}>{date}</div>
          )}
        </TableCellDiv>
      );
    },
    sortingFn: compareEventsByDateStr,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForDates(item?.event.rawOnSaleDate),
  },
  [OnSaleEventsTableColumnId.Date]: {
    id: OnSaleEventsTableColumnId.Date,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_L,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event?.dates,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<DateTimeRange | undefined>();
      const { userDefinedPrecision = FormatOption.DateTime_Month_DD_YY_Time } =
        useColumnUserSetting(columnId, SectionType.OnSaleEvents);

      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value?.start,
        isDateTime: true,
      }) as string;

      return (
        <TableCellDiv align="left">
          {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
            <EventDateDisplay
              eventDateString={value?.start ?? undefined}
              eventEndDateString={value?.end ?? undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
            />
          ) : (
            <div style={{ textTransform: 'none' }}>{date}</div>
          )}
        </TableCellDiv>
      );
    },
    sortingFn: compareEventsByDate,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForDates(item?.event.dates.start),
  },
  [OnSaleEventsTableColumnId.RawPreSaleDate]: {
    id: OnSaleEventsTableColumnId.RawPreSaleDate,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_L,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event?.rawPreSaleDate,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<string | undefined>();
      const { userDefinedPrecision = FormatOption.DateTime_Month_DD_YY_Time } =
        useColumnUserSetting(columnId, SectionType.OnSaleEvents);

      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value,
        isDateTime: true,
      }) as string;

      return (
        <TableCellDiv align="left">
          {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
            <EventDateDisplay
              eventDateString={value ?? undefined}
              eventEndDateString={undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
            />
          ) : (
            <div style={{ textTransform: 'none' }}>{date}</div>
          )}
        </TableCellDiv>
      );
    },
    sortingFn: compareEventsByDateStr,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForDates(item?.event.rawPreSaleDate),
  },
  [OnSaleEventsTableColumnId.ListingCount]: {
    id: OnSaleEventsTableColumnId.ListingCount,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.listingCount,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.listingCount ?? 0),
  },
  [OnSaleEventsTableColumnId.TicketCount]: {
    id: OnSaleEventsTableColumnId.TicketCount,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.ticketCount,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.ticketCount ?? 0),
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsMin]: {
    id: OnSaleEventsTableColumnId.UnitNetProceedsMin,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.unitNetProceedsUsdMin,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<UiMoney | null>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value?.amt,
            currencyCode: value?.currency,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<OnSaleEventWithMetrics>,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(
        item?.metrics?.unitNetProceedsUsdMin?.amt ?? 0,
        item?.metrics?.unitNetProceedsUsdMin?.disp ?? '$'
      ),
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsP25]: {
    id: OnSaleEventsTableColumnId.UnitNetProceedsP25,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.unitNetProceedsUsdP25,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<UiMoney | null>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value?.amt,
            currencyCode: value?.currency,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<OnSaleEventWithMetrics>,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(
        item?.metrics?.unitNetProceedsUsdP25?.amt ?? 0,
        item?.metrics?.unitNetProceedsUsdP25?.disp ?? '$'
      ),
  },
  [OnSaleEventsTableColumnId.UnitNetProceedsP50]: {
    id: OnSaleEventsTableColumnId.UnitNetProceedsP50,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ON_SALE_EVENT_TABLE_COLUMN_ID_CONTENT_ID[
              columnId as OnSaleEventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.unitNetProceedsUsdP50,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<UiMoney | null>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value?.amt,
            currencyCode: value?.currency,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<OnSaleEventWithMetrics>,
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(
        item?.metrics?.unitNetProceedsUsdP50?.amt ?? 0,
        item?.metrics?.unitNetProceedsUsdP50?.disp ?? '$'
      ),
  },
  [OnSaleEventsTableColumnId.VisitsLastHour]: {
    id: OnSaleEventsTableColumnId.VisitsLastHour,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent id={FormatContentId.VisitsLastNHour} params={['1']} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventVisits?.lastHour,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventVisits?.lastHour ?? 0),
  },
  [OnSaleEventsTableColumnId.VisitsLastDay]: {
    id: OnSaleEventsTableColumnId.VisitsLastDay,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent id={FormatContentId.VisitsLastNHour} params={['24']} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventVisits?.lastDay,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventVisits?.lastDay ?? 0),
  },
  [OnSaleEventsTableColumnId.VisitsLast3Days]: {
    id: OnSaleEventsTableColumnId.VisitsLast3Days,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent id={FormatContentId.VisitsLastNDay} params={['3']} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventVisits?.lastThreeDays,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventVisits?.lastThreeDays ?? 0),
  },
  [OnSaleEventsTableColumnId.VisitsLast7Days]: {
    id: OnSaleEventsTableColumnId.VisitsLast7Days,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent id={FormatContentId.VisitsLastNDay} params={['7']} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventVisits?.lastSevenDays,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventVisits?.lastSevenDays ?? 0),
  },

  [OnSaleEventsTableColumnId.TransactionsLastHour]: {
    id: OnSaleEventsTableColumnId.TransactionsLastHour,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent
          id={FormatContentId.TransactionsLastNHour}
          params={['1']}
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventTransactions?.lastHour,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventTransactions?.lastHour ?? 0),
  },
  [OnSaleEventsTableColumnId.TransactionsLastDay]: {
    id: OnSaleEventsTableColumnId.TransactionsLastDay,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent
          id={FormatContentId.TransactionsLastNHour}
          params={['24']}
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventTransactions?.lastDay,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.eventTransactions?.lastDay ?? 0),
  },
  [OnSaleEventsTableColumnId.TransactionsLast3Days]: {
    id: OnSaleEventsTableColumnId.TransactionsLast3Days,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent
          id={FormatContentId.TransactionsLastNDay}
          params={['3']}
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventTransactions?.lastThreeDays,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(
        item?.metrics?.eventTransactions?.lastThreeDays ?? 0
      ),
  },
  [OnSaleEventsTableColumnId.TransactionsLast7Days]: {
    id: OnSaleEventsTableColumnId.TransactionsLast7Days,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="left">
        <FormatContent
          id={FormatContentId.TransactionsLastNDay}
          params={['7']}
        />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.eventTransactions?.lastSevenDays,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.OnSaleEvents
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: OnSaleEventWithMetrics | null) =>
      getGroupLabelForNumber(
        item?.metrics?.eventTransactions?.lastSevenDays ?? 0
      ),
  },
  [OnSaleEventsTableColumnId.Action]: {
    id: OnSaleEventsTableColumnId.Action,
    enableSorting: false,
    enableResizing: true,
    header: function Cell() {
      const hasTablePinActionColumnFeature = useUserHasFeature(
        Feature.TablePinActionColumn
      );

      if (hasTablePinActionColumnFeature) {
        return null;
      }

      return (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event ?? null,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<Event>();
      if (!original?.event) {
        return <TableShimmeringDiv height="30px" />;
      }
      return <OnSaleEventActionsCell event={value} />;
    },
  },
};

export const OnSaleEventActionsCell = ({ event }: { event: Event }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const eventUrlV2 = `/inventory/event/v2?${DemandDashboardEventIdQueryParam}=${event.viagVirtualId}&${DemandDashboardIsOnSaleEventQueryParam}=true`;

  const onViewClick = useCallback(
    (eventUrl: string) => {
      if (event) {
        const span = Sentry.startSpan(
          { name: 'Event Discovery', op: 'event_clicked' },
          (span: Sentry.Span) => {
            span.setAttribute('event_id', event.viagId ?? 0);
            return span;
          }
        );
        span.end();
        window.location.replace(`#${event.viagVirtualId}`);
        setTimeout(() => {
          navigate(eventUrl, {
            state: {
              returnUrl: `${location.pathname}${location.search}#${event.viagVirtualId}`,
            },
          });
        }, 10);
      }
    },
    [event, navigate, location.pathname, location.search]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <Link
        to={eventUrlV2}
        style={{ height: vars.iconSize.l }}
        onClick={(e) => {
          if (e.button === 0 && e.getModifierState('Control') === false) {
            // Perform like a button click when it's a left-click (and no Ctrl)
            // Else the click will be whatever <a /> does
            e.preventDefault();
            e.stopPropagation();

            onViewClick(eventUrlV2);
          }
        }}
      >
        <IconButton
          icon={
            <FullPageIcon
              fill={vars.color.textWarning}
              size={vars.iconSize.xs}
              withHoverEffect
            />
          }
          titleContentId={ContentId.OpenEventPageV2InDevelopment}
        />
      </Link>
    </TableCellDiv>
  );
};
