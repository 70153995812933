import { EventAccordionItemProps } from 'src/components/Accordions';
import { uppercasedMetric } from 'src/components/Events/EventPage/EventPage.css';
import { EventPageHeaderBase } from 'src/components/Events/EventPage/EventPageHeader';
import { PurchaseTileActionDropdown } from 'src/components/Purchases/PurchaseTileActionDropdown';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  Event,
  PurchaseOrderTicketGroup,
  TicketGroupMetrics,
} from 'src/WebApiController';

export const PurchaseEventPageHeader = ({
  event,
  purchaseOrderAccordionItem,
}: {
  event: Event;
  purchaseOrderAccordionItem?: EventAccordionItemProps<PurchaseOrderTicketGroup>;
}) => {
  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<TicketGroupMetrics>();

  const eventMetrics = eventMetricsMap?.[event?.viagVirtualId ?? ''];

  const formattedMetrics = [
    {
      name: (
        <div className={uppercasedMetric}>
          <Content id={ContentId.Tickets} />
        </div>
      ),
      value: eventMetrics?.tktQty,
    },
  ];

  return (
    <EventPageHeaderBase
      event={event}
      entityType={ActionOutboxEntityType.TicketGroup}
      eventMetrics={formattedMetrics}
      eventAccordionItem={purchaseOrderAccordionItem}
      extraActions={<PurchaseTileActionDropdown event={event} />}
    />
  );
};
