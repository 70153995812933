import { Content, FormatContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { ActionOutboxEntityType, BulkEditProgress } from 'src/WebApiController';

import * as styles from './BulkEditProgressDisplay.css';

export const CountsByEntityDisplay = ({
  countsByEntity,
}: {
  countsByEntity: BulkEditProgress['countsByEntity'];
}) => {
  return (
    <>
      {Object.entries(countsByEntity).map(([key, value], index) => {
        let entityContentId = '';
        switch (key) {
          case ActionOutboxEntityType.Listing:
            entityContentId = ContentId.Listings;
            break;
          case ActionOutboxEntityType.Purchase:
            entityContentId = ContentId.Purchases;
            break;
          case ActionOutboxEntityType.Sale:
            entityContentId = ContentId.Sales;
            break;
          case ActionOutboxEntityType.Document:
            entityContentId = ContentId.Document;
            break;
          case ActionOutboxEntityType.TicketGroup:
            entityContentId = ContentId.TicketGroups;
            break;
          default:
            break;
        }

        return (
          <>
            {value.ttlCnt > 0 && (
              <div className={styles.textSuccess} key={`success-${key}`}>
                <FormatContent
                  id={FormatContentId.NUpdated}
                  params={
                    <>
                      {value.succCnt} / {value.ttlCnt}{' '}
                      <div
                        style={{
                          textTransform: 'lowercase',
                          display: 'inline',
                        }}
                      >
                        <Content id={entityContentId} />
                      </div>
                    </>
                  }
                />
              </div>
            )}
            {value.warnCnt > 0 && (
              <div className={styles.textWarn} key={`warn-${key}`}>
                <FormatContent
                  id={FormatContentId.NWarnings}
                  params={`${value.warnCnt}`}
                />
              </div>
            )}
            {value.failCnt > 0 && (
              <div className={styles.textError} key={`error-${key}`}>
                <FormatContent
                  id={FormatContentId.NFailed}
                  params={`${value.failCnt}`}
                />
              </div>
            )}
          </>
        );
      })}
    </>
  );
};
