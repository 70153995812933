import { ReactNode, useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Stack } from 'src/core/ui';
import {
  SalesTableFlattened,
  SalesTableFlattenedProps,
} from 'src/tables/SalesTable/SalesTableFlattened';
import { SaleQuery } from 'src/WebApiController';

type SalesFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  /**
   * If provided, the list will scroll to the month containing the event with the given posEventId
   */
  scrollToEventId?: string;
} & Pick<
  SalesTableFlattenedProps,
  | 'alwaysShowCheckbox'
  | 'showSelectAllOnHeader'
  | 'enableColumnFilters'
  | 'ignoreMaxCount'
>;

export const SalesFlattenedView = ({
  before,
  topListItemBefore,
  ...rest
}: SalesFlattenedViewProps) => {
  const { filterQuery } = useFilterQueryContext<SaleQuery>();
  const { eventsTransformed } = useCatalogDataContext();
  const { salesCount } = useMemo(() => {
    let salesCount = 0;

    (eventsTransformed ?? []).forEach((ev) => {
      salesCount += ev.counts.salesCnt ?? 0;
    });

    return { salesCount };
  }, [eventsTransformed]);

  const sales = useMemo(
    () =>
      eventsTransformed?.every((ev) => ev.entities.sales == null)
        ? null
        : eventsTransformed
            ?.flatMap((ev) => ev.entities.sales)
            ?.filter((s) => s != null)
            ?.map((s) => s!),
    [eventsTransformed]
  );

  return (
    <Stack direction="column" height="full" width="full">
      {before}
      {topListItemBefore}

      <SalesTableFlattened
        {...rest}
        useVirtuoso
        disablePagination
        salesCount={
          filterQuery.entityIds?.length
            ? filterQuery.entityIds.length
            : filterQuery.marketplaceEntityIds?.length
            ? filterQuery.marketplaceEntityIds.length
            : salesCount
        }
        sales={sales?.filter((s) => s != null).map((s) => s!)}
        failedToRetrieveData={false}
      />
    </Stack>
  );
};
