import { format } from 'date-fns';
import { ReactNode } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { EventInfo } from 'src/components/Events/EventInfo';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { SummaryWrapper } from 'src/modals/common/Summary/SummaryWrapper';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { SeatSaverIcon } from 'src/svgs';
import { ToBeDeterminedIcon } from 'src/svgs/Viagogo';
import {
  InventoryDeeplinkQueryParam,
  SaleDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  DateTimeRange,
  Event,
  MarketplaceEntityWithTickets,
  Performer,
  Seating,
  Venue,
} from 'src/WebApiController';

import { ListingInfoDisplay } from '../EventEntityHeader/ListingInfoDisplay';
import { SaleInfoDisplay } from '../EventEntityHeader/SaleInfoDisplay';
import { ShareButton } from '../ShareButton/ShareButton';
import * as styles from './Summary.css';
import {
  Divider,
  MarketPlaceDetailsWrapper,
  SummaryColumn,
  SummaryDateSection,
  SummaryDetailsSection,
  SummaryMainText,
  SummaryMainTextPinkAccent,
  SummaryRow,
} from './Summary.styled';

export const Summary = ({
  event,
  eventNameLink,
  proposedVenue,
  proposedPerformer,
  posEntity,
  actions,
}: {
  event: Event;
  eventNameLink?: string;
  proposedPerformer?: Performer | null;
  proposedVenue?: Venue | null;
  posEntity?: MarketplaceEntityWithTickets;
  actions?: ReactNode;
}) => {
  const { performer, venue } = useGetEventFullInfo(
    event,
    proposedPerformer,
    proposedVenue
  );
  if (!event) {
    throw new Error('Event could not be loaded');
  }

  const { mainRoute } = useAppContext();

  const { mkp, id: entityId, idOnMkp, seating, ticketCnt } = posEntity ?? {};

  const isSeatSaver = (posEntity as { isSeatSaver?: boolean | null })
    ?.isSeatSaver;

  const isMobile = useMatchMedia('mobile');
  const [mainText, connectText, subText] = getFormattedEventName({
    event,
    performer,
  });
  const venueLocation = venue && getFormattedLocation(venue);
  return isMobile ? (
    <SummaryWrapper>
      <SummaryRow>
        <SummaryDateDisplay dates={event.dates} />
        <MarketPlaceDetails
          entityId={entityId!}
          idOnMkp={idOnMkp!}
          marketplace={mkp}
        />
        {actions}
      </SummaryRow>
      <SummaryDetailsSection>
        <SummaryColumn>
          <EventInfo
            eventName={mainText}
            eventNameConnector={connectText}
            eventSubName={subText}
            venueName={venue?.name}
            venueLocation={venueLocation}
            eventNameLink={eventNameLink}
          />
        </SummaryColumn>
        <EventDetails seating={seating!} />
      </SummaryDetailsSection>
    </SummaryWrapper>
  ) : (
    <SummaryWrapper>
      <SummaryDateDisplay dates={event.dates} />
      <Divider />
      <SummaryDetailsSection>
        <SummaryRow>
          <SummaryColumn>
            <EventInfo
              eventName={mainText}
              eventNameConnector={connectText}
              eventSubName={subText}
              venueName={venue?.name}
              venueLocation={venueLocation}
              eventNameLink={eventNameLink}
            />
            <EventDetails seating={seating!} />
            {isSeatSaver && (
              <Stack
                gap="m"
                alignItems="center"
                className={styles.seatSaverBadge}
              >
                <SeatSaverIcon
                  fill={vars.color.textWarning}
                  size={vars.iconSize.m}
                />
                <Content id={ContentId.Placeholder} />
              </Stack>
            )}
          </SummaryColumn>
          {posEntity &&
            (mainRoute === MainRoute.Sales ||
              mainRoute === MainRoute.SalesEvent) && (
              <SaleInfoDisplay
                entityId={entityId!}
                idOnMkp={idOnMkp!}
                marketplace={mkp}
              />
            )}
          {posEntity &&
            (mainRoute === MainRoute.Inventory ||
              mainRoute === MainRoute.InventoryEvent) && (
              <ListingInfoDisplay entityId={entityId!} ticketCnt={ticketCnt!} />
            )}
        </SummaryRow>
      </SummaryDetailsSection>
      {actions && <div className={styles.actionsWrapper}>{actions}</div>}
    </SummaryWrapper>
  );
};

const SummaryDateDisplay = ({
  dates,
}: {
  dates?: DateTimeRange | null;
  hideYear?: boolean;
}) => {
  const locale = getLocaleFromLanguageOrCurrent();

  const startDateObj = dates?.start;

  const startDate = startDateObj == null ? null : new Date(startDateObj);

  return (
    <SummaryDateSection>
      {!startDate && <ToBeDeterminedIcon size={vars.iconSize.m} />}
      {startDate && (
        <>
          <span className={styles.summaryDateDay}>
            {format(startDate, 'eee', { locale })}
          </span>
          <span className={styles.summaryDateTime}>
            {format(startDate, 'h:mm a', { locale })}
          </span>
          <span className={styles.summaryDateFull}>
            {format(startDate, 'MMM d, yyyy', {
              locale,
            })}
          </span>
        </>
      )}
    </SummaryDateSection>
  );
};

const MarketPlaceDetails = ({
  marketplace,
  entityId,
  idOnMkp,
}: {
  marketplace?: string | null;
  idOnMkp: string | null;
  entityId: number;
}) => {
  const { mainRoute } = useAppContext();
  return (
    <MarketPlaceDetailsWrapper>
      <div className={styles.orderIdShareButtonWrapper}>
        <ShareButton
          deepLinkQueryParam={
            mainRoute === MainRoute.Sales
              ? SaleDeeplinkQueryParam
              : InventoryDeeplinkQueryParam
          }
          usePopup
        />
        <SummaryMainTextPinkAccent>
          #{idOnMkp || entityId}
        </SummaryMainTextPinkAccent>
      </div>
      <SummaryMainText fontWeight={700}>{marketplace}</SummaryMainText>
    </MarketPlaceDetailsWrapper>
  );
};

const EventDetails = ({
  seating,
  isSeatSaver,
}: {
  seating: Seating;
  isSeatSaver?: boolean;
}) => {
  return (
    <SummaryColumn gap={4}>
      <SeatingInfo {...seating} isSeatSaver={isSeatSaver} />
    </SummaryColumn>
  );
};
