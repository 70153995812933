import { isEqual } from 'lodash-es';
import {
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
  useLocationFilter,
} from 'src/components/Filters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PARENT_CATEGORY_TO_CID,
  TOP_LEVEL_CATEGORY_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import {
  EntityWithTicketsQuery,
  EventTimeFrameFilter,
  Feature,
  TopLevelCategory,
} from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import { EventSelector } from '../Selectors/EventSelector';
import { useEventStateFilters } from './useEventStateFilters';

export type CommonEventFilter = {
  eventTimeFrameFilter: null;
  eventDates: null;
  countryCodes: null;
  stateProvinceIds: null;
  cityIds: null;
  topLevelCategories: null;
  parentCategories: null;
  eventStatuses: null;

  // there are only for the search-views
  eventOrMappingIds: null;
  performerIds: null;
  venueIds: null;
};

export const useCommonEventFilters = ({
  showCatalogFilters,
  additionalFilters,
}: {
  showCatalogFilters?: boolean;
  additionalFilters?: FilterToolbarItem[];
}): FilterToolbarGroup => {
  const { tempQuery, initialQuery, setTempQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();

  const hasCatalogFilters = useUserHasFeature(Feature.EventSelectorFilter);

  const locationFilters = useLocationFilter();
  const eventStateFilters = useEventStateFilters();

  const hideCatalogFilters = !showCatalogFilters || !hasCatalogFilters;

  return {
    titleContentId: ContentId.Events,
    items: [
      {
        filterId: 'eventDates' as FilterToolbarItemId,
        labelContentId: ContentId.EventDate,
        filterQueryKeys: [
          'eventDates',
          'eventTimeFrameFilter',
        ] as FilterToolbarItemId[],
        filterItem: (
          <EventDateSelector
            useRelativePresets
            eventTimeFrame={tempQuery.eventTimeFrameFilter}
            value={tempQuery.eventDates}
            onChange={(eventTimeFrameFilter, dateRange) => {
              if (
                eventTimeFrameFilter !== tempQuery.eventTimeFrameFilter ||
                !isEqual(dateRange, tempQuery.eventDates)
              ) {
                let isSortDescending = tempQuery.isSortDescending;
                if (eventTimeFrameFilter !== EventTimeFrameFilter.Future) {
                  // When sorting by past or by all - we do not want to sort by date asc (as that would put the oldest event first)
                  // Changing this to desc
                  isSortDescending = true;
                } else {
                  isSortDescending = initialQuery.isSortDescending;
                }

                setTempQuery({
                  ...tempQuery,
                  eventTimeFrameFilter: eventTimeFrameFilter ?? null,
                  eventDates: dateRange ?? null,
                  isSortDescending,
                });
              }
            }}
          />
        ),
      },
      ...(hideCatalogFilters
        ? []
        : [
            {
              filterId: 'eventOrMappingIds' as FilterToolbarItemId,
              labelContentId: ContentId.Event,
              filterQueryKeys: ['eventOrMappingIds'] as FilterToolbarItemId[],
              filterItem: (
                <EventSelector
                  values={tempQuery.eventOrMappingIds ?? []}
                  onChange={(values) => {
                    if (!isEqual(values, tempQuery.eventOrMappingIds)) {
                      setTempQuery({
                        ...tempQuery,
                        eventOrMappingIds: values ?? null,
                      });
                    }
                  }}
                />
              ),
            },
          ]),
      ...locationFilters,
      {
        filterId: 'topLevelCategories' as FilterToolbarItemId,
        filterQueryKeys: ['topLevelCategories'] as FilterToolbarItemId[],
        labelContentId: ContentId.Genre,
        filterItem: (
          <PosEnumMultiSelect
            triggerProps={{ style: { width: '100%' } }}
            values={
              // TODO: set up enum type for this prop
              (tempQuery.topLevelCategories as TopLevelCategory[]) || []
            }
            placeholderText={ContentId.AllGenres}
            enableEmptySelection
            onChange={(topLevelCategories) => {
              const isChanged = !isEqual(
                topLevelCategories,
                tempQuery.topLevelCategories
              );

              setTempQuery({
                ...tempQuery,
                topLevelCategories: isChanged ? topLevelCategories : [],
              });
            }}
            valueOptionsContent={TOP_LEVEL_CATEGORY_TO_CID}
          />
        ),
      },
      {
        filterId: 'parentCategories' as FilterToolbarItemId,
        labelContentId: ContentId.SubGenre,
        filterQueryKeys: ['parentCategories'] as FilterToolbarItemId[],
        filterItem: (
          <PosMultiSelect
            triggerProps={{ style: { width: '100%' } }}
            values={
              tempQuery.parentCategories
                ? tempQuery.parentCategories.map((c) => String(c))
                : []
            }
            onChange={(newValues: string[]) => {
              setTempQuery({
                ...tempQuery,
                parentCategories:
                  newValues.length > 0
                    ? newValues.map((value) => Number(value))
                    : null,
              });
            }}
            searchable={true}
            valueOptionsContent={PARENT_CATEGORY_TO_CID}
          />
        ),
      },
      ...eventStateFilters,
      ...(additionalFilters ?? []),
    ],
  };
};
