import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProviderV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { EmptyCommissionQuery } from 'src/utils/eventQueryUtils';
import { ReportV2Client, UIReportCommissionQuery } from 'src/WebApiController';

export function ReportsCommissionContextProviderV2({
  reportConfig,
  isExport,
  children,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  isExport?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (filters: UIReportCommissionQuery, pageNumber: number) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getCommissionReportMetrics({
        ...reportConfig.request,
        pageNumber,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  const getReportMetricsSummary = useCallback(
    (filters: UIReportCommissionQuery) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getCommissionReportSummary({
        ...reportConfig.request,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  return (
    <FilterQueryContextProvider<UIReportCommissionQuery>
      initialQuery={reportConfig.request.filters as UIReportCommissionQuery}
      emptyQuery={EmptyCommissionQuery}
      loadQueryFromUrl={false}
    >
      <ReportMetricsContextProviderV2<UIReportCommissionQuery>
        queryKey="getReportCommissionMetricsV2"
        getReportMetrics={getReportMetrics}
        getReportMetricsSummary={getReportMetricsSummary}
        disabled={isExport}
        reportConfig={reportConfig}
      >
        {children}
      </ReportMetricsContextProviderV2>
    </FilterQueryContextProvider>
  );
}
