import clsx from 'clsx';
import { isEmpty, pickBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { useVenueMapHandlers } from 'src/components/Listings/MapWithCompListingsPreview/MapWithCompListingsPreview.hooks';
import { useInventoryEventDetailContext } from 'src/contexts/InventoryEventDetailContext/InventoryEventDetailContext';
import {
  ListingCompsNumberRange,
  ListingCompsSpectrum,
} from 'src/hooks/useComparableListingsVenueMapColor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import { mapToSelectedSectionSettings } from 'src/utils/types/selectedSectionSettingsWithOptionals';
import {
  Feature,
  ListingDetailsAutoPricingSectionUpdates,
  SectionInfo,
  SelectedSectionSettings,
} from 'src/WebApiController';

import { SidePanelTab } from '../../../MarketListings/MarketListingHeader';
import { CondensedVenueMapQtyFilter } from './CondensedVenueMapQtyFilter';
import * as styles from './MapBody.css';

type MapBodayProps = {
  useVerticalView: boolean;
};

export const MapBody: React.FC<MapBodayProps> = ({ useVerticalView }) => {
  const {
    selectedListing,
    venueMapInfo,
    priceCalcQuery,
    filterSections,
    compListingSectionIds,
    shouldRepaint,
    shouldReset,
    selectedTab,
    getColor,
  } = useInventoryEventDetailContext();

  const hasCondensedInventory = useUserHasFeature(
    Feature.CondensedPreviewTabsAndSingleListing
  );

  const { setValue, watch } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { compListingSelectedSectionSettings } = watch();

  const onSelectedSectionsChange = useCallback(
    (selectedSections: SectionInfo[]) => {
      if (selectedTab !== SidePanelTab.LISTINGS) {
        return;
      }

      const sectionIdFilter = selectedSections.map((s) => s.id);
      const selectedRowIds = selectedSections
        .flatMap(({ rows }) => rows)
        .map(({ id }) => id);
      const rowIdFilter = [];
      if (isEmpty(compListingSelectedSectionSettings?.rowIdFilter)) {
        rowIdFilter.push(...selectedRowIds);
      } else {
        rowIdFilter.push(
          ...(compListingSelectedSectionSettings?.rowIdFilter?.filter((rowId) =>
            selectedRowIds.includes(rowId)
          ) ?? [])
        );
      }
      const sectionRowIdFilter = pickBy(
        Object.assign(
          {},
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
        ),
        (_, key) => sectionIdFilter.includes(parseInt(key))
      );
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter,
        rowIdFilter,
        sectionRowIdFilter,
      };
      setValue(
        'compListingSelectedSectionSettings',
        mapToSelectedSectionSettings(compListingSelectedSectionSettingsNew) ??
          null
      );
    },
    [compListingSelectedSectionSettings, selectedTab, setValue]
  );

  useEffect(() => {
    if (selectedListing?.id == undefined && shouldReset) {
      const compListingSelectedSectionSettingsNew: SelectedSectionSettings = {
        sectionIdFilterFromZoneMap: false,
        includeBetterZones: false,
        rowOffset: 0,
        sectionIdFilter: [],
        rowIdFilter: [],
        sectionRowIdFilter: {},
      };

      setValue(
        'compListingSelectedSectionSettings',
        mapToSelectedSectionSettings(compListingSelectedSectionSettingsNew) ??
          null
      );
    }
  }, [onSelectedSectionsChange, selectedListing?.id, setValue, shouldReset]);

  const { onSectionClicked, onToggleMirrors, onSectionHovered } =
    useVenueMapHandlers(
      filterSections,
      priceCalcQuery?.data ?? undefined,
      venueMapInfo,
      onSelectedSectionsChange
    );

  const selectedSectionIds = useMemo(() => {
    if (!selectedListing?.seating) {
      return [];
    }

    const { section } = getMatchingSectionRow(
      selectedListing.seating,
      venueMapInfo?.sections
    );
    return section ? [section.id] : [];
  }, [selectedListing?.seating, venueMapInfo?.sections]);

  return (
    <div className={styles.mapWrapper}>
      <EventVenueMap
        isCondensedView={hasCondensedInventory && true}
        selectedSectionIds={selectedSectionIds}
        markedSectionIds={compListingSectionIds}
        onSectionClicked={onSectionClicked}
        onSectionHovered={onSectionHovered}
        onToggleMirrors={onToggleMirrors}
        setSelectedSections={onSelectedSectionsChange}
        colorBandProps={
          !selectedListing?.id
            ? {
                numOfSteps: ListingCompsNumberRange,
                spectrum: ListingCompsSpectrum,
                labels: ['$', '$$$'],
              }
            : undefined
        }
        getColor={getColor}
        statsContent={
          selectedListing == null ? <CondensedVenueMapQtyFilter /> : null
        }
        shouldRepaint={shouldRepaint}
        mapActionContainerClassname={clsx({
          [styles.floatMapActionContainer]: useVerticalView,
        })}
      />
    </div>
  );
};
