import { Content } from 'src/contexts/ContentContext';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { AutoSourceTickets } from 'src/modals/SaleDetails/components/AutoSourceTickets';
import { ContentId } from 'src/utils/constants/contentId';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import { Seating, Ticket } from 'src/WebApiController';

import { ListingLinksDetail } from './ListingLinksDetail';

export const TicketsSection = ({
  section,
  row,
  seatFr,
  seatTo,
  quantity,
  tickets,
  listingId,
  originatedFromListingId,
  isSeatSaver,
}: Seating & {
  quantity: number;
  tickets?: Ticket[];
  listingId?: number | null;
  originatedFromListingId?: number | null;
  isSeatSaver?: boolean | null;
}) => {
  return (
    <SectionContent numOfColumns={4}>
      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Section} />}
          detail={section}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail label={<Content id={ContentId.Row} />} detail={row} />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Seats} />}
          detail={<>{formatSeatDisplay(seatFr, seatTo)}</>}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Quantity} />}
          detail={<strong>x {quantity}</strong>}
        />
      </DetailGroup>
      <ListingLinksDetail
        listingId={listingId}
        originatedFromListingId={originatedFromListingId}
        tickets={tickets ?? []}
      />

      <AutoSourceTickets isSeatSaver={isSeatSaver} />
    </SectionContent>
  );
};
