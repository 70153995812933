import { useMemo } from 'react';
import { FormatContent } from 'src/contexts/ContentContext';
import { QuickFilter } from 'src/hooks/useQuickFilters';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getNextDateRange } from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  OnSaleEventQuery,
  OnSaleEventViewMode,
  UserSetting,
} from 'src/WebApiController';

export const useDefaultOnSaleEventFilter = ({
  initialQuery,
}: {
  initialQuery: OnSaleEventQuery;
}) => {
  const defaultQuickFilters = useMemo(() => {
    const next7DaysRange = getNextDateRange({ days: 7 }, new Date());
    const next30DaysRange = getNextDateRange({ days: 30 }, new Date());
    const filters: QuickFilter<UserSetting.QuickFiltersCustomOnSaleEvent>[] = [
      {
        id: 'in7Days',
        name: <FormatContent id={FormatContentId.InNDays} params={[`${7}`]} />,
        data: {
          ...initialQuery,
          viewMode: OnSaleEventViewMode.MetricView,
          onSaleDates: {
            start: next7DaysRange.start.toISOString(),
            end: next7DaysRange.end.toISOString(),
          } as DateTimeRangeWithRelative,
        },
      },
      {
        id: 'in30Days',
        name: <FormatContent id={FormatContentId.InNDays} params={[`${30}`]} />,
        data: {
          ...initialQuery,
          viewMode: OnSaleEventViewMode.MetricView,
          onSaleDates: {
            start: next30DaysRange.start.toISOString(),
            end: next30DaysRange.end.toISOString(),
          } as DateTimeRangeWithRelative,
        },
      },
    ];
    return filters;
  }, [initialQuery]);

  return defaultQuickFilters;
};
