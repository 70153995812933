import { useState } from 'react';
import { useBulkPricingActionsPermissions } from 'src/components/common/BulkActions/BulkPricingActions/useBulkPricingActionsPermissions';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useBulkChangeListingPriceAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkChangeListingPriceAction';
import { useBulkUpdateMarketplacePrices } from 'src/components/Listings/InventoryActionDropdown/components/BulkChangeMarketplacePriceAction/indext';
import { useBulkEditAutoPricingSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditAutoPricingSettingsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useBulkChangeFloorCeilingActions } from 'src/hooks/useBulkChangeFloorCeilingActions';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { useInventoryEventBulkActionsState } from '../../InventoryEventBulkActions/useInventoryEventBulkActionsState';

export const PricingActions = () => {
  const { areBulkActionsEnabled } = useBulkActionsState();

  const { affectedVisibleListingIds, filterQueryWithEventIds } =
    useInventoryEventBulkActionsState();

  const [isLoading, setIsLoading] = useState(false);

  const { hasEditPricingPermission } = useBulkPricingActionsPermissions();

  const bulkUpdateListingPrice = useBulkChangeListingPriceAction(
    filterQueryWithEventIds,

    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditAutoPricingSettingsV2 = useBulkEditAutoPricingSettings(
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkChangeFloorCeilingActions = useBulkChangeFloorCeilingActions({
    setIsLoading,
    isLoading,
    filterQueryWithEventIds,
  });

  const bulkUpdateMarketplacePrices = useBulkUpdateMarketplacePrices(
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    undefined
  );

  return (
    <>
      <PosDropdown
        key="events-pricing-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Pricing} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateListingPrice.dropDown}
        {hasEditPricingPermission && bulkChangeFloorCeilingActions.dropDown}
        {bulkEditAutoPricingSettingsV2.dropDown}
        {bulkUpdateMarketplacePrices.dropDown}
      </PosDropdown>
    </>
  );
};
