// useGetPurchasesQuery.js
import { useQuery } from '@tanstack/react-query';
import { differenceInCalendarDays } from 'date-fns';
import { useMemo } from 'react';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import {
  MonthContainingPurchaseOrdersResult,
  PurchaseClient,
  PurchaseOrderQuery,
  PurchaseViewMode,
  TimePeriodContainingPurchaseOrderResult,
  TimePeriodType,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY } from './PurchaseDataContext.constants';

export const useGetTimePeriodPurchasesQuery = (
  selectedIds: string[],
  setSelectedIds: (value: React.SetStateAction<string[]>) => void,
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void,
  timePeriodType: TimePeriodType,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { filterQuery, isQueryInitialized } =
    useFilterQueryContext<PurchaseOrderQuery>();
  const { trackError } = useErrorBoundaryContext();
  const { allEventIds } = useCatalogDataContext();

  const queryKey = useMemo(
    () => [
      PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY,
      activeAccountWebClientConfig?.activeAccountId,
      filterQuery,
      allEventIds,
      timePeriodType,
    ],
    [
      activeAccountWebClientConfig?.activeAccountId,
      allEventIds,
      filterQuery,
      timePeriodType,
    ]
  );

  const shouldQuery = Boolean(
    filterQuery &&
      activeAccountWebClientConfig?.activeAccountId &&
      isQueryInitialized &&
      allEventIds != null &&
      filterQuery.viewMode === PurchaseViewMode.TileView &&
      !disabled
  );

  function getDateValue(
    item:
      | TimePeriodContainingPurchaseOrderResult
      | MonthContainingPurchaseOrdersResult
  ): string {
    if ('firstOfTimePeriod' in item) {
      return item.firstOfTimePeriod;
    } else if ('firstOfMonth' in item) {
      return item.firstOfMonth;
    } else {
      throw new Error('Unexpected item type');
    }
  }

  const purchasesQuery = useQuery({
    queryKey,
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      const client = new PurchaseClient(activeAccountWebClientConfig);

      const filterQueryWithEventIds = {
        ...filterQuery,
        eventOrMappingIds: (allEventIds ?? []).map((ev) => ev.viagVirtualId),
        performerIds: (allEventIds ?? [])
          .filter((ev) => ev.performerId != null)
          .map((ev) => ev.performerId!),
        venueIds: (allEventIds ?? []).map((ev) => ev.venueId),
      };

      // XXX Workaround for the backend
      // because backend treats empty arrays as "no filter" for backwards compatibility
      if (allEventIds?.length === 0) {
        return [];
      }

      const data = await client.getTimePeriodContainerPurchaseOrders(
        filterQueryWithEventIds,
        timePeriodType
      );

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(getDateValue(a));
        const dateB = new Date(getDateValue(b));
        return filterQuery.isSortDescending
          ? differenceInCalendarDays(dateB, dateA)
          : differenceInCalendarDays(dateA, dateB);
      });

      let newSelectedIds = selectedIds;
      // Always expanding the first item if none selected and we just got data
      if (!selectedIds.length && sortedData.length) {
        const firstItem = sortedData[0];
        const dateValue = getDateValue(firstItem);
        newSelectedIds = [dateValue];
        setSelectedIds(newSelectedIds);
      }

      const mappedData = sortedData.map((item) => {
        const dateValue = getDateValue(item);
        return {
          ...item,
          firstOfTimePeriod: dateValue, // Use 'firstOfTimePeriod' as a consistent key
        } as TimePeriodContainingPurchaseOrderResult;
      });

      return mappedData;
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
          error?.status,
          error?.message
        );
        setErrorInfo({
          errorHeader: headerDisplay,
          errorMessage: messageDisplay,
        });
        trackError(
          PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY,
          error,
          filterQuery
        );
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return purchasesQuery;
};
