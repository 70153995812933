import { useCallback, useMemo } from 'react';
import {
  bulKActionsHighlightLetter,
  checkboxStyles,
} from 'src/components/common/MultiSelect/MultiSelectAll.css';
import { useCatalogMultiSelectionContext } from 'src/contexts/CatalogMultiSelectionContext';
import { useContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosHotkeys } from 'src/core/POS/PosHotkeys';
import { ContentId } from 'src/utils/constants/contentId';
import { getCurrentLanguage } from 'src/utils/localeUtils';

export interface MultiSelectAllProps {
  displayMode?: 'full' | 'count-only' | 'checkbox-only';
  flattenedIdsOverride?: (number | string)[];
}

export const MultiSelectAll = ({
  flattenedIdsOverride,
  displayMode = 'full',
}: MultiSelectAllProps) => {
  const { flattenedIds: flattenedIdsFromContext } =
    useCatalogMultiSelectionContext();

  const flattenedIds = flattenedIdsOverride ?? flattenedIdsFromContext;

  const {
    isAllSelected,
    toggleSelectAll,
    toggleGroup,
    totalItemsSelected,
    getGroupToggleState,
    setGroupItems,
    selectionMode,
    selectedCountLabel,
    usingCustomSelectedCountLabel,
  } = useMultiSelectionContext();

  const isEnglishLanguage = getCurrentLanguage().startsWith('en-');
  const selectedText = useContent(ContentId.Selected);
  const selectAllText = useContent(ContentId.SelectAll);

  const toggleGroupWithOverride = useCallback(
    (groupId: string) => {
      if (flattenedIdsOverride) {
        if (totalItemsSelected >= flattenedIdsOverride.length) {
          setGroupItems(
            groupId,
            flattenedIdsOverride.reduce(
              (acc, id) => {
                acc[id] = false;
                return acc;
              },
              {} as Record<number | string, boolean>
            )
          );
        } else {
          setGroupItems(
            groupId,
            flattenedIdsOverride.reduce(
              (acc, id) => {
                acc[id] = true;
                return acc;
              },
              {} as Record<number | string, boolean>
            )
          );
        }
      }
    },
    [flattenedIdsOverride, setGroupItems, totalItemsSelected]
  );

  const onChange = useCallback(() => {
    if (
      selectionMode?.mode === MultiSelectScope.SingleGroup &&
      selectionMode?.groupId
    ) {
      if (flattenedIdsOverride != null) {
        toggleGroupWithOverride(selectionMode?.groupId);
      } else {
        toggleGroup(selectionMode?.groupId);
      }
    } else if (selectionMode?.mode === MultiSelectScope.AllGroups) {
      toggleSelectAll();
    }
  }, [
    flattenedIdsOverride,
    selectionMode,
    toggleGroup,
    toggleGroupWithOverride,
    toggleSelectAll,
  ]);

  const { isChecked, totalItemsSelectedCount } = useMemo(() => {
    let isChecked = isAllSelected;
    let totalItemsSelectedCount = totalItemsSelected;

    if (selectionMode?.groupId) {
      const groupToggleState = getGroupToggleState(selectionMode?.groupId);
      isChecked = groupToggleState.isGroupSelected;
      if (!groupToggleState.isGroupSelected) {
        totalItemsSelectedCount =
          Array.isArray(flattenedIds) && flattenedIds.length > 0
            ? groupToggleState.items.filter(
                (item) =>
                  flattenedIds.includes(parseInt(item)) ||
                  flattenedIds.includes(item)
              ).length
            : groupToggleState.items.length;
        if (flattenedIdsOverride) {
          if (
            totalItemsSelectedCount >= flattenedIdsOverride.length &&
            totalItemsSelectedCount > 0
          ) {
            isChecked = true;
          }
        }
      } else if (selectionMode?.groupId === NO_GROUP_ID) {
        totalItemsSelectedCount = flattenedIds?.length ?? 0;
      }
    }
    return { isChecked, totalItemsSelectedCount };
  }, [
    isAllSelected,
    totalItemsSelected,
    selectionMode?.groupId,
    getGroupToggleState,
    flattenedIds,
    flattenedIdsOverride,
  ]);

  const selectAllTextHighlighted = useMemo(() => {
    if (!isEnglishLanguage) {
      return selectAllText;
    }
    const parts = selectAllText.split(new RegExp('A', 'g'));
    return (
      <span>
        {parts[0]}
        <span className={bulKActionsHighlightLetter}>A</span>
        {parts[1]}
      </span>
    );
  }, [isEnglishLanguage, selectAllText]);

  if (displayMode === 'count-only') {
    return (
      <span>
        {usingCustomSelectedCountLabel
          ? `${selectedCountLabel}`
          : `${totalItemsSelectedCount} ${selectedText}`}
      </span>
    );
  }

  return (
    <PosHotkeys keyNames="ctrl+a, command+a" onHotkeyActivated={onChange}>
      <Checkbox
        className={checkboxStyles}
        labelPosition="right"
        checked={isChecked}
        onChange={onChange}
        label={
          displayMode === 'checkbox-only' ? (
            ''
          ) : (
            <span>
              {selectAllTextHighlighted}
              {usingCustomSelectedCountLabel
                ? ` (${selectedCountLabel})`
                : ` (${totalItemsSelectedCount} ${selectedText})`}
            </span>
          )
        }
      />
    </PosHotkeys>
  );
};
