import { ComponentProps } from 'react';

import { ScrollableToolbar } from '../ScrollableToolbar';
import { Stack } from '../Stack';
import { PillItem, PillItemProps } from './PillItem';

export const PillList = ({
  pills,
  disabled,
  condensed,
  scrollButtonClassName,
  onPillHover,
  onPillLeave,
  onPillClick,
  onPillDeleted,
  direction = 'row',
  flexWrap,
}: {
  pills: PillItemProps[];
  condensed?: boolean;
  disabled?: boolean;
  scrollButtonClassName?: Record<'up' | 'down' | 'left' | 'right', string>;
  onPillHover?: (e: React.MouseEvent<HTMLElement>) => void;
  onPillLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  onPillClick?: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  onPillDeleted?: (key: string) => void;
} & Pick<ComponentProps<typeof Stack>, 'flexWrap' | 'direction'>) => {
  return (
    <ScrollableToolbar scrollButtonClassName={scrollButtonClassName}>
      <Stack
        direction={direction}
        gap={condensed ? 's' : 'm'}
        style={{ flex: 1 }}
        flexWrap={flexWrap}
      >
        {pills.map((pill, i) => {
          return (
            <PillItem
              key={i}
              disabled={disabled}
              {...pill}
              onPillHover={onPillHover}
              onPillLeave={onPillLeave}
              onPillClick={onPillClick}
              onDelete={() => {
                onPillDeleted?.(pill.value);
                pill.onDelete?.();
              }}
            />
          );
        })}
      </Stack>
    </ScrollableToolbar>
  );
};
