import { PaymentSection } from 'src/modals/SaleDetails/components/PaymentSection';
import { PaymentSectionV2 } from 'src/modals/SaleDetails/components/PaymentSectionV2';
import { useHasPaymentsSectionsV2Feature } from 'src/modals/SaleDetails/hooks/useHasPaymentsSectionsV2Feature';
import { SaleDetails, SaleLineItem } from 'src/WebApiController';

interface PaymentSectionSelectorProps {
  sale?: SaleDetails | null;
  lineItems: SaleLineItem[] | null | undefined;
  onLineItemChange?: (item: SaleLineItem, isDelete?: boolean) => void;
}

export const PaymentSectionSelector = ({
  sale,
  lineItems,
  onLineItemChange,
}: PaymentSectionSelectorProps) => {
  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();

  return hasPaymentsSectionsV2Feature ? (
    <PaymentSectionV2
      lineItems={lineItems}
      onLineItemChange={onLineItemChange}
      sale={sale}
    />
  ) : (
    <PaymentSection
      lineItems={lineItems}
      onLineItemChange={onLineItemChange}
      sale={sale}
    />
  );
};
