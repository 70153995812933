import { debounce } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { ReportBuilderFilterInputV2 } from 'src/dialogs/ReportBuilderDialog/ReportBuilderFilterInputV2';
import { useReportConfigActionsV2 } from 'src/hooks/useReportConfigActionsV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { useReportPermissions } from 'src/hooks/useReportPermissions';

import * as styles from './ReportsFilterToolbar.css';

export const ReportsFilterToolbarV2 = ({
  reportConfig,
}: {
  reportConfig: ReportConfigV2;
}) => {
  const methods = useForm<ReportConfigV2>({
    defaultValues: reportConfig,
  });

  const { canUpdateReport } = useReportPermissions(reportConfig);

  // Report config can be changed elsewhere like the table editor
  useEffect(() => {
    if (!isEqual(methods.formState.defaultValues, reportConfig)) {
      methods.reset(reportConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportConfig]);

  const { upsertReportConfig } = useReportConfigActionsV2();

  const filters = methods.watch('request.filters');
  const hiddenFilterItemIds = methods.watch('hiddenFilterItemIds');
  const viewableFilterItemIds = methods.watch('viewableFilterItemIds');
  const editableFilterItemIds = methods.watch('editableFilterItemIds');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpsertReportConfig = useCallback(
    debounce((report: ReportConfigV2) => {
      upsertReportConfig(report);
    }, 500),
    [upsertReportConfig]
  );

  const onSubmit = (data: ReportConfigV2) => {
    if (
      canUpdateReport &&
      (!isEqual(data.request.filters, reportConfig.request.filters) ||
        !isEqual(data.hiddenFilterItemIds, reportConfig.hiddenFilterItemIds) ||
        !isEqual(
          data.viewableFilterItemIds,
          reportConfig.viewableFilterItemIds
        ) ||
        !isEqual(
          data.editableFilterItemIds,
          reportConfig.editableFilterItemIds
        ))
    ) {
      debouncedUpsertReportConfig(data);
    }
  };

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    hiddenFilterItemIds,
    viewableFilterItemIds,
    editableFilterItemIds,
  ]);

  return (
    <FormProvider {...methods}>
      <div className={styles.toolbarContainerV2}>
        <ReportBuilderFilterInputV2
          reportType={reportConfig.reportType}
          viewerMode={!canUpdateReport}
        />
      </div>
    </FormProvider>
  );
};
