import { ReactNode, useEffect } from 'react';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { ViewModeSelector } from 'src/components/MainFilterBar';
import { useDiscoveryFilters } from 'src/components/MainFilterBar/useDiscoveryFilters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { MetricViewIcon } from 'src/svgs/MetricViewIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { ON_SALE_EVENT_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  OnSaleEventQuery,
  OnSaleEventViewMode,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../../MainFilterBar/MainFilterBar.css';
import { OnSaleEventSearchBox } from '../OnSaleEventSearchBox';
import { useDefaultOnSaleEventFilter } from './hooks/useDefaultOnSaleEventFilter';

export const DISCOVERY_VIEW_MODE_TO_ICON: Record<string, ReactNode> = {
  [OnSaleEventViewMode.MetricView]: (
    <MetricViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
};

export function OnSaleEventFilterBar() {
  const isMobile = useMatchMedia('mobile');
  const { filters } = useDiscoveryFilters();

  const {
    filterQuery: onSaleEventQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<OnSaleEventQuery>();

  const defaultQuickFilters = useDefaultOnSaleEventFilter({
    initialQuery,
  });

  useEffect(() => {
    setTempQuery(onSaleEventQuery);
  }, [onSaleEventQuery, setTempQuery]);

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateOnSaleEvent,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomOnSaleEvent,
    defaultQuickFilters,
    currentQuery: onSaleEventQuery,
    initialQuery,
    onSelect: ({ query }) => {
      setFilterQuery(query);
    },
  });

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: onSaleEventQuery,
    mandatoryFiltersToShow: ['onSaleDates'],
    initialQuery,
  });

  const filterToolbar = onSaleEventQuery && (
    <FilterToolbar
      {...filterToolbarProps}
      filterAppliedCounts={appliedFilters.length}
      filterAppliedIds={appliedFilters}
      onSubmitFilter={() => setFilterQuery(tempQuery)}
      filters={filters}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      showCustomFilterButton={false}
      alwaysShowAllFilters={false}
    />
  );

  return (
    <div className={styles.mainFilterBarDiv}>
      {isMobile ? (
        filterToolbar
      ) : (
        <>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>{filterToolbar}</div>
          </div>
          <div className={styles.dockRightDiv}>
            <div className={styles.mainFilterBarRightChild}>
              <ViewModeSelector
                query={onSaleEventQuery}
                initialQuery={initialQuery}
                setFilterQuery={setFilterQuery}
                viewModeSettingsId={UserSetting.InventoryPageViewMode}
                viewModeCidMap={ON_SALE_EVENT_VIEW_MODE_TO_CID}
                viewModeIconMap={DISCOVERY_VIEW_MODE_TO_ICON}
              />
              {/* <MultiSelectionToggleGlobal /> */}
              <OnSaleEventSearchBox />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
