/* eslint-disable @typescript-eslint/no-unused-vars */
import { EntityWithTicketsQuery } from 'src/WebApiController';

import { QueryWithViewMode } from './eventQueryUtils';

export const getServerSideOnlyMetricsQuery = <
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>(
  query: TQuery
) => {
  const {
    // IMPORTANT: pulling out all the parts that do not affect server and if we leave them in
    // they will trigger a re-get unnecessarily and cause performance issue
    searchText,
    sortBy,
    isSortDescending,
    performerIds,
    venueIds,
    viewMode,
    ...rest
  } = query;

  return rest as TQuery;
};

export const getServerSideOnlyMetricsQueryV2 = <
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>(
  query: TQuery
) => {
  const {
    eventOrMappingIds,
    eventTimeFrameFilter,
    eventDates,
    purchaseDates,
    timezoneOffsetMins,
    topLevelCategories,
    parentCategories,
    eventStatuses,
    cityIds,
    stateProvinceIds,
    countryCodes,
    numberOfTicketsHeld,
    lastSaleDates,
    eventTagName,
    eventTagValue,
    purchaseBuyerId,
    purchaseOrderCreatedBy,
    isSeatSaver,
  } = query;

  let eventChangingFilter = {
    eventOrMappingIds,
    eventTimeFrameFilter,
    eventDates,
    purchaseDates,
    timezoneOffsetMins,
    topLevelCategories,
    parentCategories,
    eventStatuses,
    cityIds,
    stateProvinceIds,
    countryCodes,
    numberOfTicketsHeld,
    lastSaleDates,
    eventTagName,
    eventTagValue,
  } as TQuery;

  let dataChangingFilter = {
    purchaseBuyerId,
    purchaseOrderCreatedBy,
    isSeatSaver,
  } as TQuery;

  // Listing specific filters
  if (
    'eventHasUnsoldTickets' in query &&
    'lastPriceUpdateDates' in query &&
    'lastPriceNoUpdateDates' in query &&
    'pricerSellerUserId' in query
  ) {
    const {
      eventHasUnsoldTickets,
      lastPriceUpdateDates,
      lastPriceNoUpdateDates,
      pricerSellerUserId,
    } = query;

    eventChangingFilter = {
      ...dataChangingFilter,
      eventHasUnsoldTickets,
      lastPriceUpdateDates,
      lastPriceNoUpdateDates,
    } as TQuery;

    dataChangingFilter = {
      ...dataChangingFilter,
      pricerSellerUserId,
    } as TQuery;
  }

  // Sales specific filters
  if ('listingInsertBy' in query && 'fulfillerSellerUserId' in query) {
    const { listingInsertBy, fulfillerSellerUserId } = query;

    dataChangingFilter = {
      ...dataChangingFilter,
      listingInsertBy,
      fulfillerSellerUserId,
    } as TQuery;
  }

  return { eventChangingFilter, dataChangingFilter };
};
