import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EventAccordionItemProps,
  InventoryEventsAccordion,
  translateEventToInventoryAccordionItem,
} from 'src/components/Accordions';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { SetInventoryEventAccordionSelectionCountLabel } from 'src/components/Events/EventListing/InventoryEventListing/SetInventoryEventAccordionSelectionCountLabel';
import { SetInventoryMetricsSelectionCountLabel } from 'src/components/Events/EventListing/InventoryEventListing/SetInventoryMetricsViewSelectionCountLabel';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import {
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';
import { InventoryViewMode, Listing, ListingQuery } from 'src/WebApiController';

import * as styles from '../EventListing.css';
import { InventoryGlobalBulkActions } from './BulkActions';
import { InventoryDetailedEventView } from './InventoryDetailedEventView';
import { InventoryEventSummaryMetrics } from './InventoryEventSummaryMetrics';
import { InventoryFlattenedView } from './InventoryFlattenedView';

export function InventoryEventListing({
  onItemsRendered,
}: {
  onItemsRendered?: (items: EventAccordionItemProps<Listing>[]) => void;
}) {
  const {
    isLoading,
    errorInfo,
    eventsTransformed,
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<ListingQuery>();
  const location = useLocation();

  const onInventoryEventExpanded = useCallback(
    async (viagVirtualId: string) => {
      toggleListItemExpansion([viagVirtualId]);
    },
    [toggleListItemExpansion]
  );

  const [returningFromViagogoEventId, setReturningFromViagogoEventId] =
    useState<string>();

  useEffect(() => {
    const fromViagogoEventId = (
      location.state as { fromViagogoEventId?: string }
    )?.fromViagogoEventId;
    if (fromViagogoEventId) {
      setReturningFromViagogoEventId(fromViagogoEventId);
    }
  }, [location.state]);

  const scrollToItemIndex = useMemo(() => {
    if (returningFromViagogoEventId == null || eventsTransformed == null) {
      return undefined;
    }

    const scrollToIndex = eventsTransformed.findIndex(
      (e) => e.event.viagVirtualId === returningFromViagogoEventId
    );
    // Not found means the event is not in the list, so we don't want to scroll to it any more
    if (scrollToIndex === -1) {
      setReturningFromViagogoEventId(undefined);
    }

    return scrollToIndex === -1 ? undefined : scrollToIndex;
  }, [eventsTransformed, returningFromViagogoEventId]);

  const eventIdFromHash =
    location.hash.length === 37 ? location.hash.substring(1) : undefined; // UUID 36 chars

  useEffect(() => {
    if (eventIdFromHash) {
      toggleListItemExpansion([eventIdFromHash]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventIdFromHash]);

  const { setAllGroupsIds, clearShiftKeySelection, setSelectionMode } =
    useMultiSelectionContext();

  useEffect(() => {
    clearShiftKeySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.viewMode]);

  const listingItems = useMemo(() => {
    return (
      eventsTransformed?.map((e, i) =>
        translateEventToInventoryAccordionItem(e, i)
      ) || []
    );
  }, [eventsTransformed]);

  useEffect(() => {
    if (filterQuery.viewMode === InventoryViewMode.FlattenedView) {
      setAllGroupsIds([NO_GROUP_ID]);
    } else {
      const groupIds = listingItems.map((listingItem) => listingItem.itemId);
      setAllGroupsIds(groupIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingItems, filterQuery.viewMode]);

  // Reset and close selection mode when changing view
  useEffect(() => {
    setSelectionMode();
  }, [filterQuery.viewMode, setSelectionMode]);

  return (
    <>
      {isLoading && <PosSpinner />}
      {errorInfo && (
        <SomethingWentWrong
          header={errorInfo.errorHeader}
          message={<Content id={ContentId.FailToLoadListContent} />}
        />
      )}
      {!isLoading &&
        !errorInfo &&
        (eventsTransformed && eventsTransformed.length > 0 ? (
          <div className={styles.eventsListContainer}>
            {filterQuery.viewMode === InventoryViewMode.MetricView ? (
              <InventoryDetailedEventView
                before={<InventoryEventSummaryMetrics />}
                topListItemBefore={
                  <SetInventoryMetricsSelectionCountLabel
                    events={eventsTransformed}
                  >
                    <MultiSelectActionBar>
                      <InventoryGlobalBulkActions />
                    </MultiSelectActionBar>
                  </SetInventoryMetricsSelectionCountLabel>
                }
                items={eventsTransformed}
                highlightEvent={eventIdFromHash}
                scrollToEventId={returningFromViagogoEventId}
              />
            ) : filterQuery.viewMode === InventoryViewMode.FlattenedView ? (
              <CollapsableViewProvider isEnabled={true}>
                <InventoryFlattenedView
                  before={<InventoryEventSummaryMetrics />}
                  enableColumnFilters
                />
              </CollapsableViewProvider>
            ) : (
              <InventoryEventsAccordion
                before={<InventoryEventSummaryMetrics />}
                topListItemBefore={
                  <SetInventoryEventAccordionSelectionCountLabel>
                    <MultiSelectActionBar>
                      <InventoryGlobalBulkActions />
                    </MultiSelectActionBar>
                  </SetInventoryEventAccordionSelectionCountLabel>
                }
                items={listingItems}
                disablePagination
                scrollToItemIndex={scrollToItemIndex}
                selectedIds={expandedListItems}
                onSelect={onInventoryEventExpanded}
                onItemsRendered={onItemsRendered}
              />
            )}
          </div>
        ) : (
          <EmptySectionContent.Root
            icon={
              <EmptySectionContent.SolidIconContainer>
                <SearchSolidIcon />
              </EmptySectionContent.SolidIconContainer>
            }
          >
            <EmptySectionContent.Label>
              <Content id={ContentId.NoResultFound} />
            </EmptySectionContent.Label>
            <EmptySectionContent.DetailMessage>
              <Content id={ContentId.NoEventsMatching} />
            </EmptySectionContent.DetailMessage>
          </EmptySectionContent.Root>
        ))}
    </>
  );
}
