import { ColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { FormatOption } from 'src/modals/EditTableColumns';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { YES_NO_ENUM_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ToYesNoEnum } from 'src/utils/eventQueryUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import {
  getReportValueTypeDisplayConfig,
  sortMoney,
} from 'src/utils/tableUtils';
import { Money, ReportValueType } from 'src/WebApiController';

import { DateCell } from '../Table/TableCells';
import { SHIMMERING_DIV_HEIGHT_REPORT } from './ReportTableCommon.constants';
import { reportV2AccessorFn } from './ReportTableCommon.utils';
import { TextCell } from './TextCell';

export const defaultColumnHelperAccessor = (
  columnHelper: ColumnHelper<ReportMetricsResponse | null>,
  columnId: string,
  headerContentId: ContentId,
  valueType: ReportValueType | undefined
) => {
  const { isNumeric, isCurrency, isPercent, isDateTime, isBoolean } =
    getReportValueTypeDisplayConfig(valueType);

  const align = isNumeric ? 'right' : 'left';

  return columnHelper.accessor(reportV2AccessorFn(columnId), {
    id: columnId,
    header: () => (
      <TableHeaderCellDiv align={align}>
        <Content id={headerContentId} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell(params) {
      const value = params.getValue<string | null>();
      const valueAsMoney = params.getValue<Money>();

      if (!params.row.original) {
        return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
      }

      // Handle columns with personalization
      if (isCurrency || valueAsMoney?.display != null) {
        return (
          <TableCellDiv align={align}>
            {valueAsMoney.display ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      }

      if (isNumeric) {
        return (
          <TableCellDiv align={align}>
            {value != null && !isNaN(Number(value))
              ? applyNumberFormatting({
                  precision: FormatOption.Percent_RoundToTwo,
                  inputNumber: value,
                  isPercent: isPercent,
                })
              : (value ?? <Content id={ContentId.NA} />)}
          </TableCellDiv>
        );
      }

      if (isDateTime) {
        const dateValue = value ? new Date(value).toISOString() : value;
        return (
          <DateCell
            date={dateValue}
            align={align}
            hideRelativeTerms
            useSiteTimeZone={columnId !== ListingReportTableColumnId.EventDate}
          />
        );
      }

      if (isBoolean) {
        const yesNoEnum = ToYesNoEnum(value as boolean | null);

        return (
          <TableCellDiv align={'left'}>
            <Content
              id={
                yesNoEnum != null
                  ? YES_NO_ENUM_FILTER_TO_CID[yesNoEnum]
                  : ContentId.NA
              }
            />
          </TableCellDiv>
        );
      }

      return <TextCell {...params} align={align} />;
    },
    footer: function Footer(params) {
      const original = params.table.options.meta?.summaryData;

      if (!original) {
        return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_REPORT} />;
      }
      const value = params.column.accessorFn?.(original, -1);

      if (isCurrency) {
        const valueAsMoney = value as Money | null;
        return (
          <TableCellDiv align={align}>
            {valueAsMoney ? (
              <>{valueAsMoney.display}</>
            ) : (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      }

      if (isNumeric) {
        return (
          <TableCellDiv align={align}>
            {value != null
              ? applyNumberFormatting({
                  precision: FormatOption.Percent_RoundToTwo,
                  inputNumber: value,
                  isPercent: isPercent,
                })
              : (value ?? <Content id={ContentId.NA} />)}
          </TableCellDiv>
        );
      }

      return <TableCellDiv align={align}>{value}</TableCellDiv>;
    },
    sortingFn: isCurrency ? sortMoney<ReportMetricsResponse> : 'alphanumeric',
  });
};
