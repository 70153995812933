import { Stack } from 'src/core/ui';

import * as styles from './BulkEditWarningList.css';

export function BulkEditWarningList({ warnings }: { warnings: string[] }) {
  return (
    <Stack className={styles.root} direction="column">
      {warnings.toSorted().map((w, i) => (
        <span key={i}>{w}</span>
      ))}
    </Stack>
  );
}
