import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  EventSearch,
  OnEventsSelectCallback,
} from 'src/components/Events/EventSearch';
import { FieldError } from 'src/modals/common/Purchase';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { DeliveryType, TicketGroupInput } from 'src/WebApiController';

export function SearchPurchasedEvents({
  currencyCode,
}: {
  currencyCode: string;
}) {
  const { setValue, watch, formState, clearErrors } =
    useFormContext<PurchaseTicketsInput>();
  const ticketGroups = watch('ticketGroups');

  const ticketGroupFromPartialPurchase = watch(
    'ticketGroupFromPartialPurchaseOrder'
  );
  const searchTextPrefillForPartialPurchase = watch(
    'eventSearchTextFromPartialPurchaseOrder'
  );
  const selectedEventIds = useMemo(
    () =>
      ticketGroups
        ?.filter((tg) => tg.event?.viagVirtualId)
        .map((tg) => tg.event!.viagVirtualId),
    [ticketGroups]
  );

  const onEventsSelect = useCallback<OnEventsSelectCallback>(
    (data) => {
      clearErrors('ticketGroups');

      const selectedViagogoVirtualIds = new Set<string>(
        ticketGroups
          .map((tg) => tg.event?.viagVirtualId)
          .filter((id): id is string => id != null)
      );

      const unselectedViagogoVirtualIds = new Set<string>();
      let newTicketGroups = [...(ticketGroups || [])];
      const startId = newBigIntId();

      data.forEach(({ event, isSelected, performer, venue }, i) => {
        if (isSelected) {
          // skip events that are already selected
          if (!selectedViagogoVirtualIds.has(event.viagVirtualId)) {
            if (ticketGroupFromPartialPurchase == null) {
              newTicketGroups.push({
                ticketGroupId: startId - i,
                deliveryType: posChangedField(DeliveryType.InApp),
                currencyCode: posChangedField(currencyCode),
                viagogoVirtualId: event.viagVirtualId,
                eventMappingId: event.mappingId,
                viagogoEventId: event.viagId,
                posEventId: event.posIds[0],
                event: event,
                expectedValue: posChangedField(null),
                faceValueCost: posField(null),
                row: posChangedField(null),
                section: posChangedField(null),
                listingNotes: posChangedField(null),
                numberOfTickets: posChangedField(null),
                unitCost: posChangedField(null),
                totalCost: posChangedField(null),
                performer: performer,
                venue: venue,
                taxPaid: posChangedField(1), // This is to turn off taxExempt by default
              } as TicketGroupInput);
            } else {
              newTicketGroups.push({
                ...ticketGroupFromPartialPurchase,
                ticketGroupId: startId - i,
                deliveryType: posChangedField(DeliveryType.InApp),
                currencyCode: posChangedField(currencyCode),
                viagogoVirtualId: event.viagVirtualId,
                eventMappingId: event.mappingId,
                viagogoEventId: event.viagId,
                posEventId: event.posIds[0],
                event: event,
                performer: performer,
                venue: venue,
                taxPaid: posChangedField(1), // This is to turn off taxExempt by default
              } as TicketGroupInput);
            }
          }
        } else {
          unselectedViagogoVirtualIds.add(event.viagVirtualId);
        }
      });

      if (unselectedViagogoVirtualIds.size > 0) {
        // If we unselect an event, that just mean we don't want to add this new event
        // this time, so only remove new ticket groups with this event (eventId is null)
        newTicketGroups = newTicketGroups?.filter(
          (tg) =>
            tg.numberOfTickets?.value ||
            (tg.event?.viagVirtualId &&
              !unselectedViagogoVirtualIds.has(tg.event.viagVirtualId))
        );
      }

      setValue('ticketGroups', newTicketGroups);
    },
    [
      clearErrors,
      currencyCode,
      setValue,
      ticketGroups,
      ticketGroupFromPartialPurchase,
    ]
  );

  const onEventsSelectForEdit = useCallback<OnEventsSelectCallback>(
    (data) => {
      clearErrors('ticketGroups');

      const newTicketGroups: TicketGroupInput[] = [];
      const startId = newBigIntId();

      data.forEach(({ event, performer, venue }, i) => {
        newTicketGroups.push({
          // doing this guarantee uniqueness for all these ticket-groups in this batch
          // because the loop runs so fast, newBigIntId (which returns the current time in ms) would be the same for all these
          ticketGroupId: startId - i,
          deliveryType: posChangedField(DeliveryType.InApp),
          currencyCode: posChangedField(currencyCode),
          viagogoVirtualId: event.viagVirtualId,
          eventMappingId: event.mappingId,
          viagogoEventId: event.viagId,
          posEventId: event.posIds[0],
          event: event,
          performer: performer,
          venue: venue,
          taxPaid: posChangedField(1), // This is to turn off taxExempt by default
        } as TicketGroupInput);
      });

      setValue('ticketGroups', newTicketGroups);
    },
    [clearErrors, currencyCode, setValue]
  );

  const onDeleteNumEventsSelected = useCallback(() => {
    setValue('ticketGroups', []);
  }, [setValue]);

  return (
    <>
      <ModalBodyDataContainer>
        {formState.errors.ticketGroups && (
          <FieldError>{formState.errors.ticketGroups.message}</FieldError>
        )}
        <EventSearch
          onEventsSelect={onEventsSelect}
          selectedEvents={selectedEventIds}
          searchTextPrefill={searchTextPrefillForPartialPurchase ?? undefined}
          numEventsSelected={ticketGroups.length}
          onDeleteNumEventsSelected={onDeleteNumEventsSelected}
        />
      </ModalBodyDataContainer>
    </>
  );
}
