import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { Colors } from 'src/core/themes/constants/colors';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function TableFilterIcon({
  width,
  height,
  isActive,
  ...props
}: PosIconProps & {
  isActive?: boolean;
}) {
  const { isDarkMode } = useSiteTheme();

  const fill = isDarkMode
    ? isActive
      ? Colors.indigo600
      : Colors.neutral075
    : isActive
    ? Colors.indigo100
    : Colors.neutral500;

  const stroke = isDarkMode
    ? isActive
      ? Colors.neutral025
      : Colors.neutral100
    : isActive
    ? Colors.white
    : Colors.neutral100;
  return (
    <SvgWrapper
      {...props}
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="2" fill={fill} />
      <rect x="3" y="5" width="10" height="1" rx="0.5" fill={stroke} />
      <rect x="5" y="8" width="6" height="1" rx="0.5" fill={stroke} />
      <rect x="7" y="11" width="2" height="1" rx="0.5" fill={stroke} />
    </SvgWrapper>
  );
}
