import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProviderV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { EmptyReportV2ListingQuery } from 'src/utils/eventQueryUtils';
import { ReportV2Client, UIReportV2ListingQuery } from 'src/WebApiController';

export function ReportsInventoryContextProviderV2({
  reportConfig,
  isExport,
  children,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  isExport?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (filters: UIReportV2ListingQuery, pageNumber: number) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getInventoryReportMetrics({
        ...reportConfig.request,
        pageNumber,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  const getReportMetricsSummary = useCallback(
    (filters: UIReportV2ListingQuery) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getInventoryReportSummary({
        ...reportConfig.request,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  return (
    <FilterQueryContextProvider<UIReportV2ListingQuery>
      initialQuery={reportConfig.request.filters as UIReportV2ListingQuery}
      emptyQuery={EmptyReportV2ListingQuery}
      loadQueryFromUrl={false}
    >
      <ReportMetricsContextProviderV2<UIReportV2ListingQuery>
        queryKey="getReportListingMetricsV2"
        getReportMetrics={getReportMetrics}
        getReportMetricsSummary={getReportMetricsSummary}
        disabled={isExport}
        reportConfig={reportConfig}
      >
        {children}
      </ReportMetricsContextProviderV2>
    </FilterQueryContextProvider>
  );
}
