import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { FilterDropdownItem } from 'src/components/Filters';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMultiSelectionContext } from 'src/contexts/CatalogMultiSelectionContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { isFilterUnchanged } from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  InventorySearchResult,
  InventoryViewMode,
  ListingQuery,
  PurchaseOrderQuery,
  PurchaseSearchResult,
  PurchaseViewMode,
  SaleQuery,
  SaleSearchResult,
  SalesViewMode,
  SearchClient,
} from 'src/WebApiController';

export type UseEntitySearchReturnType<T, S> = {
  selectedFilters: FilterDropdownItem[] | undefined;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<FilterDropdownItem[] | undefined>
  >;
  onSetActiveSearchConfig: (searchConfig?: S) => void;
  onSaveSearchResult: (searchResult: S) => Promise<void>;
  onDeleteSearchResult: (searchResult: S) => void;
  refetchData: (
    filterQuery: ListingQuery | SaleQuery | PurchaseOrderQuery,
    customRefreshItems?: () => Promise<unknown>
  ) => Promise<void>;
  onRequeryForTheResult: () => void;
  disabled: boolean;
  setDisabled: (d: boolean) => void;
};

export const useEntitySearch = <
  T extends ListingQuery | SaleQuery | PurchaseOrderQuery,
  S extends InventorySearchResult | SaleSearchResult | PurchaseSearchResult,
>(
  activeSearchConfig: S | undefined,
  setActiveSearchConfig: (a: S | undefined) => void,
  viewMode:
    | InventoryViewMode.FlattenedView
    | SalesViewMode.FlattenedView
    | PurchaseViewMode.FlattenedView,
  getSearchResult: (client: SearchClient, id?: string) => Promise<S>,
  saveSearchResult: (client: SearchClient, searchResult: S) => Promise<S | null>
): UseEntitySearchReturnType<T, S> => {
  const { loginContext, activeAccountWebClientConfig } = useAppContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const [selectedFilters, setSelectedFilters] =
    useState<FilterDropdownItem[]>();
  const {
    filterQuery,
    setFilterQuery,
    setTempQuery,
    initialQuery,
    emptyQuery,
  } = useFilterQueryContext<T>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { flattenedIds: newEntityIds } = useCatalogMultiSelectionContext();
  const [disabled, setDisabled] = useState(false);

  const onSetActiveSearchConfig = useCallback(
    (searchConfig?: S) => {
      const defaultFilter = {
        ...initialQuery,
        viewMode,
      };
      // If this is already selected, clicking will do unselection
      if (searchConfig && !isEqual(searchConfig, activeSearchConfig)) {
        // Only update the query if the searchConfig has it
        if (searchConfig.query) {
          if (searchConfig.query.eventTimeFrameFilter === undefined) {
            // If the query comes from a search result and it doesn't have a time frame filter
            // that means the value was saved as "null" and we have to set it to null
            // otherwise it gets default to the Future one which is not correct
            // This is the only filter with this Nuance, others do not have this issue
            searchConfig.query.eventTimeFrameFilter = null;
          }

          // Only change filter when the query came back
          setFilterQuery({
            ...searchConfig.query,
            searchConfigId: searchConfig.id,
            entityIds: searchConfig.entityIds,
            viewMode,
          } as T);
          setTempQuery(searchConfig.query as T);
        }
        setActiveSearchConfig(searchConfig);
      } else {
        setFilterQuery(defaultFilter);
        setTempQuery(defaultFilter);
        setActiveSearchConfig(undefined);
      }

      setSelectedFilters(undefined);
      // Clear selections
      setSelectionMode(undefined);
    },
    [
      initialQuery,
      viewMode,
      activeSearchConfig,
      setSelectionMode,
      setActiveSearchConfig,
      setFilterQuery,
      setTempQuery,
    ]
  );

  // This use affect allow handling of the URL for a search-result to be pasted in
  // it will come in via the filterQuery.searchConfigId and we will process that
  useEffect(() => {
    if (!disabled && activeAccountWebClientConfig?.activeAccountId) {
      if (filterQuery.searchConfigId) {
        if (filterQuery.searchConfigId !== activeSearchConfig?.id) {
          setDisabled?.(true);

          // set a mock search result while waiting for the main result
          setActiveSearchConfig({
            id: filterQuery.searchConfigId,
            name: null,
            query: null,
            ownerUserId: null,
            count: null,
            entityIds: null,
            lastFetchDate: null,
          } as S);
          getSearchResult(
            new SearchClient(activeAccountWebClientConfig),
            filterQuery.searchConfigId
          )
            .then(
              (r) => {
                onSetActiveSearchConfig(r);
              },
              (e) => {
                onSetActiveSearchConfig(undefined);
                showErrorDialog('SearchClient.getSearchResult', e);
              }
            )
            .finally(() => setDisabled?.(false));
        } else if (
          !filterQuery.entityIds?.length &&
          activeSearchConfig?.entityIds?.length
        ) {
          setFilterQuery({
            ...filterQuery,
            entityIds: activeSearchConfig.entityIds,
          });
        }
      } else if (
        // If we don't have a search config yet and the filter query is different from the initial query
        // set it so we start querying for data (this usually comes from set query or URL query)
        activeSearchConfig == null &&
        !isFilterUnchanged(filterQuery, initialQuery)
      ) {
        onSetActiveSearchConfig({
          ...(activeSearchConfig ?? {
            id: null,
            name: null,
            query: null,
            ownerUserId: null,
            count: null,
            entityIds: null,
            lastFetchDate: null,
          }),
          query: { ...filterQuery, entityIds: null },
        } as S);
      }
    }
  }, [
    activeAccountWebClientConfig,
    activeSearchConfig,
    activeSearchConfig?.id,
    disabled,
    initialQuery,
    filterQuery,
    getSearchResult,
    onSetActiveSearchConfig,
    setDisabled,
    setFilterQuery,
    setTempQuery,
    showErrorDialog,
    setActiveSearchConfig,
  ]);

  const onSaveSearchResult = useCallback(
    async (searchResult: S) => {
      if (
        !searchResult?.id || // should not save if there is no ID
        !searchResult?.name || // should not save if there is no ID
        // should not save search-result not yours
        (searchResult.ownerUserId &&
          searchResult.ownerUserId !== loginContext?.user?.userId)
      ) {
        return;
      }

      const newQuery = {
        ...(searchResult.query ?? activeSearchConfig?.query ?? filterQuery),
        // we don't need to save these, only the raw filter is needed
        entityIds: null as number[] | null,
        marketplaceEntityIds: null as string[] | null,
        searchConfigId: null as string | null,
      };

      if (isFilterUnchanged(newQuery, emptyQuery)) {
        console.log('Cannot save search result with empty query.');
        return;
      }

      setDisabled?.(true);
      await tryInvokeApi(
        async () => {
          const newSearchConfig = {
            ...searchResult,
            entityIds:
              searchResult.entityIds ??
              activeSearchConfig?.entityIds ??
              newEntityIds ??
              null,
            query: newQuery,
          };

          const result = await saveSearchResult(
            new SearchClient(activeAccountWebClientConfig),
            newSearchConfig
          );

          if (result) {
            setActiveSearchConfig({
              ...newSearchConfig,
              ...result,
            });
          }
        },
        (error) => {
          showErrorDialog('SearchClient.saveSaleSearchResult', error, {
            trackErrorData: {
              searchResult,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      loginContext?.user?.userId,
      activeSearchConfig?.query,
      activeSearchConfig?.entityIds,
      filterQuery,
      emptyQuery,
      newEntityIds,
      saveSearchResult,
      activeAccountWebClientConfig,
      setActiveSearchConfig,
      showErrorDialog,
    ]
  );

  const [lastResultEntityIds, setLastResultEntityIds] = useState<number[]>();

  // This useEffect is for handling when the result list changes - if it's for the current search result
  // it will save the current search result automatically for the update ids
  useEffect(() => {
    if (newEntityIds?.length) {
      if (!isEqual(newEntityIds, lastResultEntityIds)) {
        setLastResultEntityIds(newEntityIds as number[]);
        setSelectionMode(undefined);

        const idsChanged =
          activeSearchConfig?.entityIds &&
          !isEqual(newEntityIds, activeSearchConfig.entityIds);

        if (activeSearchConfig?.id && activeSearchConfig?.name) {
          // If there is an active config - only save if the filter searchConfigId matches the active id
          // and either the filter or new entity ids changed
          if (
            (filterQuery.searchConfigId == null ||
              filterQuery.searchConfigId === activeSearchConfig?.id) &&
            (!isEqual(filterQuery, activeSearchConfig.query) || idsChanged)
          ) {
            // if there is current result
            // then save it
            onSaveSearchResult({
              ...activeSearchConfig,
              count: newEntityIds.length,
              lastFetchDate: idsChanged
                ? new Date().toISOString()
                : activeSearchConfig.lastFetchDate,
              query: filterQuery,
              entityIds: newEntityIds,
            });
          }
        } else {
          setActiveSearchConfig({
            ...(activeSearchConfig ?? {
              id: null,
              name: null,
              ownerUserId: null,
            }),
            count: newEntityIds.length,
            lastFetchDate:
              idsChanged || !activeSearchConfig?.lastFetchDate
                ? new Date().toISOString()
                : activeSearchConfig?.lastFetchDate,
            query: filterQuery,
            entityIds: newEntityIds,
          } as S);
        }
      }
    }
  }, [
    activeSearchConfig,
    filterQuery,
    lastResultEntityIds,
    newEntityIds,
    onSaveSearchResult,
    setActiveSearchConfig,
    setSelectionMode,
  ]);

  const onDeleteSearchResult = useCallback(
    (searchResult: S) => {
      if (!searchResult.id) return;

      setDisabled?.(true);
      tryInvokeApi(
        async () => {
          await new SearchClient(
            activeAccountWebClientConfig
          ).deleteSearchConfig(searchResult.id!);

          setActiveSearchConfig(undefined);
        },
        (error) => {
          showErrorDialog('SearchClient.deleteSearchConfig', error, {
            trackErrorData: {
              searchResult,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      activeAccountWebClientConfig,
      setActiveSearchConfig,
      setDisabled,
      showErrorDialog,
    ]
  );

  const {
    refreshCatalog,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const refetchData = useCallback(
    async (
      filterQuery: ListingQuery | SaleQuery | PurchaseOrderQuery,
      customRefreshItems?: () => Promise<unknown>
    ) => {
      setDisabled?.(true);

      try {
        if (activeSearchConfig) {
          // Update the last refetch date and save
          await onSaveSearchResult({
            ...activeSearchConfig,
            query: filterQuery,
            lastFetchDate: new Date().toISOString(),
          });
        }
        await refreshCatalog();
        customRefreshItems
          ? await customRefreshItems()
          : await refreshExpandedListItems(true);
      } finally {
        setDisabled?.(false);
      }
    },
    [
      activeSearchConfig,
      onSaveSearchResult,
      refreshCatalog,
      refreshExpandedListItems,
      setDisabled,
    ]
  );

  const onRequeryForTheResult = useCallback(() => {
    // First clear the entity ids so the query will re-query the DB
    // else you'll get the same result
    setFilterQuery({ ...filterQuery, searchConfigId: null, entityIds: null });
    refetchData(filterQuery);
  }, [filterQuery, refetchData, setFilterQuery]);

  return {
    selectedFilters,
    setSelectedFilters,
    onSetActiveSearchConfig,
    onSaveSearchResult,
    onDeleteSearchResult,
    refetchData,
    onRequeryForTheResult,
    disabled,
    setDisabled,
  };
};
