import { FilterToolbarGroup, FilterToolbarItem } from 'src/components/Filters';
import {
  filterGroupHeader,
  filterItemLabel,
  filterItemWrapper,
  RemoveFilterButton,
} from 'src/components/FilterToolbar/FilterDialogV2/FiltersList/FiltersList.css';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CrossIcon } from 'src/svgs/Viagogo';
import { Feature } from 'src/WebApiController';

import { FiltersListProp } from './FiltersList.types';
import { FiltersListEmbedded } from './FiltersListEmbedded';
import { ReportFilterEditabilityDropdown } from './ReportFilterEditabilityDropdown';

export const FiltersList = (props: FiltersListProp) => {
  if (props.embeddedDisplayOnly) {
    return <FiltersListEmbedded {...props} />;
  }
  if (props.listView) {
    return <FiltersListListView {...props} />;
  }

  return <FiltersListDefault {...props} />;
};

const FiltersListListView = ({
  filters,
  selectedDropdownItems,
  ...props
}: FiltersListProp) => {
  return (
    <Stack gap="xl" direction="column">
      {filters
        .filter(
          (filterToolbarGroup) =>
            filterToolbarGroup.titleContentId &&
            !!selectedDropdownItems[filterToolbarGroup.titleContentId]
        )
        .map((filterToolbarGroup, i) => (
          <FilterToolbarGroupListItem
            {...props}
            filterToolbarGroup={filterToolbarGroup}
            selectedDropdownItems={selectedDropdownItems}
            key={`fg-${filterToolbarGroup.titleContentId}-${i}`}
          />
        ))}
    </Stack>
  );
};

const FiltersListDefault = ({
  filters,
  selectedDropdownItems,
  ...props
}: FiltersListProp) => {
  return (
    <Stack gap="xl" direction="column">
      {filters
        .filter(
          (filterToolbarGroup) =>
            filterToolbarGroup.titleContentId &&
            !!selectedDropdownItems[filterToolbarGroup.titleContentId]
        )
        .map((filterToolbarGroup, i) => (
          <FilterToolbarGroupListItem
            {...props}
            filterToolbarGroup={filterToolbarGroup}
            selectedDropdownItems={selectedDropdownItems}
            key={`fg-${filterToolbarGroup.titleContentId}-${i}`}
          />
        ))}
    </Stack>
  );
};

const FilterToolbarGroupListItem = ({
  filterToolbarGroup,
  selectedDropdownItems,
  tempQuery,
  filtersMap,
  mandatoryFiltersToShow,
  onEditabilityChange,
  ...props
}: Omit<FiltersListProp, 'filters'> & {
  filterToolbarGroup: FilterToolbarGroup;
}) => {
  const titleContentId = filterToolbarGroup.titleContentId ?? '';
  const dropdownFilterIds = selectedDropdownItems[titleContentId].map(
    (dropdownFilter) => dropdownFilter.filterId
  );
  const itemsToShow = filterToolbarGroup.items.filter((filterItem) =>
    dropdownFilterIds.includes(filterItem.filterId)
  );
  return (
    <Stack gap="m" direction="column">
      {titleContentId && (
        <div className={filterGroupHeader}>
          <Content id={titleContentId} />
        </div>
      )}
      <Stack gap="m" direction={'column'}>
        {itemsToShow.map((item, itemIndex) => (
          <FilterToolbarListItem
            {...props}
            onEditabilityChange={onEditabilityChange}
            filterToolbarGroup={filterToolbarGroup}
            itemIndex={itemIndex}
            item={item}
            selectedDropdownItems={selectedDropdownItems}
            tempQuery={tempQuery}
            filtersMap={filtersMap}
            mandatoryFiltersToShow={mandatoryFiltersToShow}
            key={`fi-${titleContentId}-${itemIndex}`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const FilterToolbarListItem = ({
  filterToolbarGroup,
  item,
  itemIndex,
  selectedDropdownItems,
  onFilterRemoved,
  onEditabilityChange,
  mandatoryFiltersToShow,
}: Omit<FiltersListProp, 'filters'> & {
  filterToolbarGroup: FilterToolbarGroup;
  item: FilterToolbarItem;
  itemIndex: number;
}) => {
  const titleContentId = filterToolbarGroup.titleContentId ?? '';
  const hasMetricsV2Feature = useUserHasFeature(Feature.CatalogMetricsV2);
  const canBeRemoved = !mandatoryFiltersToShow.includes(item.filterId);

  const showEditabilityDropdown = onEditabilityChange != null;

  const onRemoveFilterHandler = () => {
    const filterDropdownItem = selectedDropdownItems[titleContentId].find(
      (filterDropdownItem) => filterDropdownItem.filterId === item.filterId
    );
    if (filterDropdownItem) {
      onFilterRemoved(filterDropdownItem);
    }
  };

  return (
    <Stack gap="m" direction="column" style={{ width: '100%' }}>
      <div
        className={filterItemWrapper}
        key={`fi-div-${titleContentId}-${itemIndex}`}
      >
        <span className={filterItemLabel}>
          {item.postfixIcon && hasMetricsV2Feature ? (
            <Stack
              gap="m"
              alignItems="center"
              justifyContent="spaceBetween"
              width="full"
            >
              <Content id={item.labelContentId} />
              {item.postfixIcon}
            </Stack>
          ) : (
            <Content id={item.labelContentId} />
          )}
        </span>
        <Stack gap="s">
          {item.filterItem}
          {canBeRemoved && (
            <Button
              variant="textPlain"
              onClick={onRemoveFilterHandler}
              className={RemoveFilterButton}
            >
              <CrossIcon withHoverEffect size={vars.iconSize.s} />
            </Button>
          )}
          {showEditabilityDropdown && (
            <ReportFilterEditabilityDropdown
              filterId={item.filterId}
              reportFilterEditability={item.reportFilterEditability}
              onEditabilityChange={onEditabilityChange}
            />
          )}
        </Stack>
      </div>
    </Stack>
  );
};
